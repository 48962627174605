import React, {useEffect, useMemo, useRef, useState} from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import {Button, Checkbox, Dropdown, InputNumber, Menu, Modal, Spin} from 'antd';
import classes from './InTransitLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTools} from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../../components/DataTable/DataTable';
import { isEmpty } from 'lodash';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import PickupIcon from "../../assets/img/pickUpMarker.png";
import DropOffIcon from "../../assets/img/dropOffMarker.png";
import GPS_ICON from "../../assets/img/GPS_ICON@1x.png";
import Note from "../../components/Note/Note";

const stringFormatter = new StringFormatter();

const InTransitLoads = props => {
    //#region props and constants

    let shipperId = props.shipperId;
    const carrierId = props.carrierId;
    const driverId = props.driverId;
    const assignedAccountRepId = props.assignedAccountRepId;
    const singularEntityName = "In Transit Load";
    const pluralEntityName = "In Transit Loads";
    const filter = "intransit";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const loadLists = useSelector(state => state.loadLists.loadLists);

    const mapRef = useRef(null);

    if (entityType === "SHIPPER") {
        shipperId = entityId
    }
    //#endregion
    //#region useStates

    const [selectedRoute, setSelectedRoute] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [coordinates, setCoordinates] = useState({
        longitude: -94.566234,
        latitude: 40.49944
    })
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [repeatDaysCount, setRepeatDaysCount] = useState(1);
    const [isRecurringLoad, setIsRecurringLoad] = useState(false);
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loads, setLoads] = useState([]);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: 'In Transit Loads',
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: null,
                duplicateLoadId: null,
                isRecurringLoad: isRecurringLoad,
                repeatDaysCount: repeatDaysCount
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
            let searchParams = {
                page: 1,
                size: 20,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                eta: true
            };

            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }
            } else if (entityType === "STAFF") {
                if (shipperId !== undefined && shipperId !== null) {
                    searchParams.shipperId = shipperId;
                }

                if (carrierId !== undefined && carrierId !== null) {
                    searchParams.assignedCarrierId = carrierId;
                }

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }

                if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                    searchParams.assignedAccountRepIds = assignedAccountRepId;
                }
            }

            let defaultLoadStatusFilter = ['IN_TRANSIT', 'AT_STOP'];
            searchParams.loadStatus = defaultLoadStatusFilter.join('|');

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadListSuccess({ listName: filter, params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            isDeleted: false,
            eta: true
        };

        if (filters.loadStatus !== undefined) {
            if (filters.loadStatus.length > 0) {
                searchParams.loadStatus = filters.loadStatus.join('|');
            }
        }

        if (entityType === "DRIVER") {
            searchParams.driverIds = entityId;
        } else if (entityType === "SHIPPER") {
            searchParams.shipperId = entityId;
        } else if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }
        } else if (entityType === "STAFF") {
            if (shipperId !== undefined && shipperId !== null) {
                searchParams.shipperId = shipperId;
            }

            if (carrierId !== undefined && carrierId !== null) {
                searchParams.assignedCarrierId = carrierId;
            }

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }

            if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                searchParams.assignedAccountRepIds = assignedAccountRepId;
            }
        }

        dispatch(actionCreators.fetchLoadList(filter, searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); goToLoad(record.id); }}>
                    <InfoOutlined />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            sorter: (a, b) => compareByAlph(a.irisId, b.irisId),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Length',
            dataIndex: ['equipmentNeeds', 'trailerLength'],
            key: 'equipmentNeeds.trailerLength',
            render: (text, record) => { return stringFormatter.toFormattedString("Length", text, record.equipmentNeeds.trailerLengthUnit); },
        },
        {
            title: 'Max Weight',
            dataIndex: ['equipmentNeeds', 'trailerWeightCapacity'],
            key: 'equipmentNeeds.trailerWeightCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.equipmentNeeds.trailerWeightCapacityUnit); },
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (record.loadStatus === "IN_TRANSIT" || record.loadStatus === "AT_STOP") {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        },
        {
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["IN_TRANSIT", "AT_STOP"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        },
    ];

    if ((shipperId !== undefined && shipperId !== null) || entityType === "SHIPPER") {
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
    } else if ((carrierId !== undefined && carrierId !== null) || entityType === "CARRIER") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
    } else if (entityType === "STAFF") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
        columns.push({
            title: 'Account Rep',
            dataIndex: 'assignedAccountRepIds',
            key: 'assignedAccountRepIds',
            render: (text, record) => {
                if (record.assignedAccountRepUsers !== undefined && record.assignedAccountRepUsers !== null && record.assignedAccountRepUsers.length > 0) {
                    return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                        return (
                            <div>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                        );
                    });
                } else {
                    return '';
                }
            },
        });
        columns.push({
            title: 'Drivers',
            dataIndex: 'drivers',
            key: 'drivers',
            render: (text, record) => {
                if ('drivers' in record && record.drivers) {
                    const driverNames = record.drivers.map(it => {
                        return it.firstName + " " + it.lastName
                    })
                    return driverNames.join(", ")
                } else {
                    return '';
                }
            },
        });
    }

    const getCustomClusterMarker = (map, elem) => {
        if (!!elem.current_location && elem.current_location.length) {

            const {longitude, latitude} = elem.current_location[elem.current_location.length - 1]
            const center = [longitude, latitude];
            const content = "<span><image style='width: 40px; height:40px;' src='"+GPS_ICON+"'/></span>";
            // MARKER
            const el = document.createElement('div');
            el.title = elem.origin.stopLocation.name;
            el.innerHTML = content;
            el.id = elem.irisId;

            // POPOVER
            const divLabel = document.createElement('div');
            divLabel.style.display = "none"
            divLabel.style.position = "absolute"
            divLabel.style.width = "200px"
            divLabel.style.background = "#fff"
            divLabel.style.borderRadius = "10px"
            divLabel.style.padding = "10px"
            divLabel.style.bottom = "-50px"
            divLabel.style.left = "105%"

            // POPOVER HTML (LOAD INFO)
            divLabel.innerHTML = `
            <div>
                <div>${elem.irisId}</div>
                <div>Current Status: ${elem.loadStatus}</div>
                <div>Origin: ${elem.origin.stopLocation.name}</div>
                <div>Destination: ${elem.destination.stopLocation.name}</div>
                <div>Shipper: ${elem.shipper.name}</div>
                <div>Carrier: ${elem.assignedCarrier.name}</div>
            </div>
        `

            el.appendChild(divLabel);

            // EVENT LISTENERS
            el.addEventListener('click', (e) => {
                setSelectedRoute(elem)
            })
            el.addEventListener('mouseenter', (e) => {
                divLabel.style.display = "block"
            })
            el.addEventListener('mouseleave', (e) => {
                divLabel.style.display = "none"
            })

            new TrimbleMaps.Marker({
                element: el
            })
                .setLngLat(center)
                .addTo(map);
        }
    }

    const setupMap = (loads) => {
        mapRef.current = new TrimbleMaps.Map({
            container: 'myMap',
            center: new TrimbleMaps.LngLat(coordinates.longitude, coordinates.latitude),
            zoom: zoom,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA,
            displayControlsDefault: true
        });
        mapRef.current.addControl(new TrimbleMaps.NavigationControl());
        mapRef.current.on('load', function() {
            // Setting up cluster markers, filtering selected route
            loads
                .filter(elem => !(selectedRoute && elem.id == selectedRoute.id))
                .forEach((elem, i) => {
                    getCustomClusterMarker(mapRef.current, elem)
                })

            if (selectedRoute) {
                selectedRoute.stops.forEach((elem) => {
                    const div = document.createElement('div');
                    const stopIcon = elem.stopType === 'PICK_UP' ? PickupIcon : (elem.stopType === 'DROP_OFF' ? DropOffIcon : null);

                    div.style.background = "url("+ stopIcon +")"
                    div.style.width = "40px"
                    div.style.height = "40px"
                    div.style.backgroundSize = "contain"
                    div.id = elem.stopLocationId;
                    new TrimbleMaps.Marker({
                        element: div
                    })
                        .setLngLat([elem.longitude, elem.latitude])
                        .addTo(mapRef.current);
                })

                const routeId = selectedRoute.irisId;
                const myRoute = new TrimbleMaps.Route({
                    routeId: routeId,
                    stops: [
                        new TrimbleMaps.LngLat(selectedRoute.origin.longitude, selectedRoute.origin.latitude),
                        new TrimbleMaps.LngLat(selectedRoute.destination.longitude, selectedRoute.destination.latitude),
                    ],
                    vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                    routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                    routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                    highwayOnly: false,
                    distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                    tollDiscourage: true,
                })
                myRoute.addTo(mapRef.current);
            }
        });
    }

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (loadLists !== undefined && loadLists !== null && !isEmpty(loadLists) && filter !== undefined && filter !== null) {
            if (loadLists[filter] !== undefined && loadLists[filter] !== null && !isEmpty(loadLists[filter])) {
                const copyOfLoadList = { ...loadLists[filter] };
                if (copyOfLoadList.records !== undefined && copyOfLoadList.records !== null) {
                    setLoads([...copyOfLoadList.records]);
                } else {
                    setLoads([]);
                }
                setPagination(copyOfLoadList.pagination ? copyOfLoadList.pagination : {});
                setIsLoading(copyOfLoadList.isLoading === true);
            } else {
                setLoads([]);
                setPagination({});
                setIsLoading(false);
            }
        } else {
            setLoads([]);
            setPagination({});
            setIsLoading(false);
        }
    }, [loadLists, filter]);

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, entityType, entityId]);

    useEffect(() => {
        setupMap(loads)
        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                mapRef.current.remove();
            }
        };
    }, [loads, selectedRoute])
    //#endregion
    //#region displays

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 100}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>

            <Checkbox
                onChange={e => setIsRecurringLoad(e.target.checked)}
                checked={isRecurringLoad}
                name={""}
            />
            <div>* Repeat this load(s) for days:</div>
            {!!isRecurringLoad && (
                <InputNumber
                    value={repeatDaysCount}
                    min={1}
                    step={1}
                    max={entityType === 'CARRIER' ? 100 : 100}
                    precision={0}
                    style={{ width: '100%' }}
                    onChange={(count) => {
                        setRepeatDaysCount(count)
                    }}
                />
            )}

        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    //#endregion

    return (
        <div>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={!!isLoading}>
                <div id={"myMap"} style={{height: "40vh", width: "100%", border: "1px solid black", marginBottom: "10px", position: "relative"}}>
                    {selectedRoute && (
                        <div style={{
                            position: "absolute",
                            width: "225px",
                            top: "10px",
                            left: "10px",
                            padding: "10px",
                            background: "#fff",
                            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                            zIndex: "1"
                        }}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <span className="label">Load: {selectedRoute.irisId}</span>
                                    <div>Current Status: {selectedRoute.loadStatus}</div>
                                    <div>Origin: {selectedRoute.origin.stopLocation.name}</div>
                                    <div>Destination: {selectedRoute.destination.stopLocation.name}</div>
                                    <div>Shipper: {selectedRoute.shipper.name}</div>
                                    <div>Carrier: {selectedRoute.assignedCarrier.name}</div>
                                </div>
                                <span onClick={() => setSelectedRoute(null)}><FontAwesomeIcon style={{transform: "rotate(45deg)"}} icon={faPlusCircle}/></span>
                            </div>
                        </div>
                    )}
                </div>
            </Spin>
            <DataTable
                dataSource={loads}
                columns={columns}
                pagination={pagination}
                loading={isLoading === true}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setSelectedRoute(record)
                        }
                    };
                }}
                onChange={handleTableChange}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {duplicateEntityComponents}
            </DataTable>
        </div>
    );
};

export default withRouter(InTransitLoads);
