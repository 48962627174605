import React from 'react';
import { Button, Row, Col } from "antd";
import { isEmpty } from "lodash";
import Fieldset from '../FormFieldset/FormFieldset';
import { useSelector } from 'react-redux';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet } from '@fortawesome/free-solid-svg-icons';
import classes from './NewLoadReview.module.scss';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import DataDateTimeRangeRow from '../../components/DataDateTimeRangeRow/DataDateTimeRangeRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';

const stringFormatter = new StringFormatter();

const NewLoadReview = props => {
    //#region props

    const pickUpStops = props.pickUpStops;
    const dropOffStops = props.dropOffStops;
    const equipmentData = props.equipmentData;
    const servicesData = props.servicesData;
    const previousStepAction = props.previousStepAction;
    const previousStepActionName = props.previousStepActionName;
    const nextStepAction = props.nextStepAction;
    const nextStepActionName = props.nextStepActionName;
    const practicalRouteMiles = props.practicalRouteMiles;
    const invoiceLineItems = props.invoiceLineItems;
    const hideLoadSpecificInformation = props.hideLoadSpecificInformation;

    //#endregion
    //#region useSelectors

    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region displays

    const getEquipmentData = () => {
        if (equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) {
            return (
                <>
                    {(equipmentData.trailerType && equipmentData.trailerType.description) ? (
                        <>
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <div><span className="load-summary-subtitle">Trailer Type</span></div>
                                    <div style={{ paddingLeft: 8 }}><span className="load-summary-data">{equipmentData.trailerType.description}</span></div>
                                </Col>
                            </Row>
                        </>
                    ) : null}
                    {((equipmentData.trailerLength && equipmentData.trailerLengthUnit) || (equipmentData.trailerWeightCapacity && equipmentData.trailerWeightCapacityUnit)) ? (
                        <Row gutter={[12, 12]}>
                            {(equipmentData.trailerLength && equipmentData.trailerLengthUnit) ? (
                                <Col span={12}>
                                    <div><span className="load-summary-subtitle">Trailer Length</span></div>
                                    <div style={{ paddingLeft: 8 }}><span className="load-summary-data">{stringFormatter.toFormattedString('Length', equipmentData.trailerLength, equipmentData.trailerLengthUnit)}</span></div>
                                </Col>
                            ) : null}
                            {(equipmentData.trailerWeightCapacity && equipmentData.trailerWeightCapacityUnit) ? (
                                <Col span={12}>
                                    <div><span className="load-summary-subtitle">Load Weight</span></div>
                                    <div style={{ paddingLeft: 8 }}><span className="load-summary-data">{stringFormatter.toFormattedString('Weight', equipmentData.trailerWeightCapacity, equipmentData.trailerWeightCapacityUnit)}</span></div>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}
                    {equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null && equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null && equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null ? (
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Pre-Cool Temp</span></div>
                                <div style={{ paddingLeft: 8 }}><span className="load-summary-data">{stringFormatter.toFormattedString('Temperature', equipmentData.preCoolReefer, equipmentData.preCoolReeferUnit)}</span></div>
                            </Col>
                            <Col span={12}>
                                <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Run Temp Range</span></div>
                                <div style={{ paddingLeft: 8 }}><span className="load-summary-data">{stringFormatter.toFormattedString('Temperature', equipmentData.minRunTemp, equipmentData.minRunTempUnit)} - {stringFormatter.toFormattedString('Temperature', equipmentData.maxRunTemp, equipmentData.maxRunTempUnit)}</span></div>
                            </Col>
                        </Row>
                    ) : null}
                    {((equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) || (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0)) ? (
                        <Row gutter={[12, 12]}>
                            {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                                <Col span={12}>
                                    <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Additional Equipment and Services</span></div>
                                    <DataListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                </Col>
                            ) : null}
                            {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                                <Col span={12}>
                                    <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Driver Requirements</span></div>
                                    <DataListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}
                </>
            );
        } else {
            return null;
        }
    };

    const getStopsData = () => {
        if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            let pickUpStopsDisplay = pickUpStops.filter(s => s.stopLocationId !== undefined && s.stopLocationId !== null).map((stop, index, arr) => {
                const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
                const stopLocation = stop.stopLocation !== undefined && stop.stopLocation !== null ? stringFormatter.toFormattedAddress(stop.stopLocation.address) : null;
                const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);

                return (
                    <div style={{ marginBottom: 18 }} key={`stop-details-${stop.id}`}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 30, marginRight: 12 }}>
                                {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                            </div>
                            <div style={{ flex: 1 }}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div><span className="load-summary-big-title">{stopName}</span></div>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div><span className="load-summary-subtitle">{stopLocation}</span></div>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <span className="load-summary-subtitle">
                                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                        </span>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Appt Details for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.apptType !== undefined && stop.apptType !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Type" value={stop.apptType} dataType="AppointmentType" /> : null}
                                        {stop.apptNumber !== undefined && stop.apptNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Number" value={stop.apptNumber} dataType="String" /> : null}
                                        {stop.pickUpNumber !== undefined && stop.pickUpNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
                                        {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Call Ahead" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                        {stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null ? (
                                            <DataContactInfoRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" valueStyle={{ paddingLeft: 8 }} title={`${Enums.StopTypes.getValueByName(stop.stopType)} Contact`} contact={stop.apptPointOfContact} separateLines={false} />
                                        ) : null}
                                        {stop.apptInstructions !== undefined && stop.apptInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Loading Details for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 ? <DataListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {stop.loadingType !== undefined && stop.loadingType !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Loading Type" value={stop.loadingType} dataType="LoadingType" /> : null}
                                        {stop.hasLumperFee !== undefined && stop.hasLumperFee !== null && stop.hasLumperFee === true ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {stop.hasDriverAssist !== undefined && stop.hasDriverAssist !== null && stop.hasDriverAssist === true ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {stop.specialInstructions !== undefined && stop.specialInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Commodities for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" data={stop.commodities} />
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            });

            let dropOffStopsDisplay = dropOffStops.filter(s => s.stopLocationId !== undefined && s.stopLocationId !== null).map((stop, index, arr) => {
                const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
                const stopLocation = stop.stopLocation !== undefined && stop.stopLocation !== null ? stringFormatter.toFormattedAddress(stop.stopLocation.address) : null;
                const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);

                return (
                    <div style={{ marginBottom: 18 }} key={`stop-details-${stop.id}`}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: 30, marginRight: 12 }}>
                                {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                            </div>
                            <div style={{ flex: 1 }}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div><span className="load-summary-big-title">{stopName}</span></div>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div><span className="load-summary-subtitle">{stopLocation}</span></div>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <span className="load-summary-subtitle">
                                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                        </span>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Appt Details for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.apptType !== undefined && stop.apptType !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Type" value={stop.apptType} dataType="AppointmentType" /> : null}
                                        {stop.apptNumber !== undefined && stop.apptNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Number" value={stop.apptNumber} dataType="String" /> : null}
                                        {stop.dropOffNumber !== undefined && stop.dropOffNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
                                        {stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                        {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Call Ahead" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                        {stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null ? (
                                            <DataContactInfoRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" valueStyle={{ paddingLeft: 8 }} title={`${Enums.StopTypes.getValueByName(stop.stopType)} Contact`} contact={stop.apptPointOfContact} separateLines={false} />
                                        ) : null}
                                        {stop.apptInstructions !== undefined && stop.apptInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Unloading Details for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 ? <DataListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {stop.loadingType !== undefined && stop.loadingType !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Unloading Type" value={stop.loadingType} dataType="UnloadingType" /> : null}
                                        {stop.hasLumperFee !== undefined && stop.hasLumperFee !== null && stop.hasLumperFee === true ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {stop.hasDriverAssist !== undefined && stop.hasDriverAssist !== null && stop.hasDriverAssist === true ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {stop.specialInstructions !== undefined && stop.specialInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">{`Commodities for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" data={stop.commodities} />
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            });

            return (
                <>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            {pickUpStopsDisplay}
                        </Col>
                        <Col span={12}>
                            {dropOffStopsDisplay}
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <DataRow title="Total Stops" valueClassName="load-summary-title" value={pickUpStops.length + dropOffStops.length} />
                        </Col>
                        <Col span={12}>
                            {practicalRouteMiles !== undefined && practicalRouteMiles !== null ? <DataRow title="Trip Miles" valueClassName="load-summary-title" value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                        </Col>
                    </Row>
                </>
            );
        } else {
            return null;
        }
    };

    const getPricingData = () => {
        if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            return (
                <div>
                    <div style={{ marginBottom: 12 }}><span className="load-summary-subtitle">Load Information</span></div>

                    {servicesData !== undefined && servicesData !== null && servicesData.parentName !== undefined && servicesData.parentName !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} value={servicesData.parentName} dataType="String" /> : null}
                    {servicesData !== undefined && servicesData !== null && servicesData.customerOrderNumber !== undefined && servicesData.customerOrderNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Customer Order Number" value={servicesData.customerOrderNumber} dataType="String" /> : null}
                    {entityType === "STAFF" && servicesData !== undefined && servicesData !== null && servicesData.serviceType !== undefined && servicesData.serviceType !== null ? <DataRow style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title="Service Type" value={servicesData.serviceType} dataType="LoadServiceType" /> : null}

                    <div style={{ marginBottom: 12, marginTop: 12 }}><span className="load-summary-subtitle">Stop Fees</span></div>

                    {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                        return (
                            <DataRow key={`subtotal-lineitem-${index}`} style={{ paddingLeft: 8 }} titleClassName="load-summary-data" valueClassName="load-summary-data" title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                        );
                    })}
                    {servicesData !== undefined && servicesData !== null && servicesData.totalAddOnCharge !== undefined && servicesData.totalAddOnCharge !== null ? (
                        <div style={{ paddingLeft: 8, marginBottom: 12 }}><span className="load-summary-subtitle"><i>Subtotal: {stringFormatter.toFormattedMoney(servicesData.totalAddOnCharge)}</i></span></div>
                    ) : null}

                    {(entityType === "STAFF" || entityType === "CARRIER") && servicesData !== undefined && servicesData !== null && servicesData.shipperAmount !== undefined && servicesData.shipperAmount !== null && servicesData.shipperAmount > 0 ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Total Charge to the Shipper: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {(entityType === "STAFF" || entityType === "SHIPPER") && servicesData.carrierAmount !== undefined && servicesData.carrierAmount !== null && servicesData.carrierAmount > 0 ? (
                        <div style={{ marginBottom: 12 }}><span className="load-summary-title">Total Pay to the Carrier: {stringFormatter.toFormattedMoney(servicesData.carrierAmount)}</span></div>
                    ) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <div className="load-creation-step-container">
            <div className="load-creation-step">
                <Header
                    titleStyle={{ fontSize: 24 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={"Review"}
                    title2={"Review the details below."}
                    icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                    iconStyle={{ background: 'transparent' }}
                />
                <Fieldset legend="Equipment Needs & Services">
                    {getEquipmentData()}
                </Fieldset>
                <Fieldset legend="Stop Details">
                    {getStopsData()}
                </Fieldset>
                <Fieldset legend={(entityType === 'SHIPPER' || entityType === 'STAFF') ? "Pricing & Tender" : "Invoice"}>
                    {getPricingData()}
                </Fieldset>
            </div>
            <Row gutter={[12, 12]} className="load-creation-step-buttons">
                <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                    {previousStepAction !== undefined && previousStepAction !== null ? (<Button type="default" block onClick={() => { previousStepAction('services'); }}>{previousStepActionName}</Button>) : null}
                </Col>
                <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                    {nextStepAction !== undefined && nextStepAction !== null ? (<Button type="primary" block onClick={() => { nextStepAction(); }}>{nextStepActionName}</Button>) : null}
                </Col>
            </Row>
        </div>
    );
};

export default NewLoadReview;