import React, { useMemo } from 'react';
import { Alert, Input, Select, Spin } from "antd";
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';

const { TextArea } = Input;

const NewContent = props => {
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.contents.isRecordAddLoading);
    const error = useSelector(state => state.contents.addRecordError);

    const cmsChannelOptions = Enums.CMSChannels.selectListOptions();

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (!isEmpty(cleanedData)) {
            dispatch(actionCreators.addContent(cleanedData));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddContent());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addContentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Event Type"
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Event Type" />}
                        rules={{ required: false }}
                        name="eventType"
                    />
                    <FormItem {...formItemLayout} label="Message"
                        render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                        rules={{ required: false }}
                        name="message"
                    />
                    <FormItem {...formItemLayout} label="Description"
                        render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                        rules={{ required: false }}
                        name="description"
                    />
                    <FormItem {...formItemLayout} label="Channel" required
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select a Channel"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {cmsChannelOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        defaultValue="ALL"
                        name="channel"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Content" />
            </Form>
        </FormProvider>
    );
};

export default NewContent;