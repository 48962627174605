import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Dropdown, Menu } from 'antd';
import EditDriver from "../../components/EditDriver/EditDriver";
import NewDriver from "../../components/NewDriver/NewDriver";
import classes from './Drivers.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faIdCard } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import DocumentList from "../../components/DocumentList/DocumentList";
import StringFormatter from '../../shared/stringFormatter';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { withRouter } from 'react-router-dom';

const stringFormatter = new StringFormatter();

const Drivers = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const singularEntityName = "Driver";
    const pluralEntityName = "Drivers";

    const columnSearchFilter = new ColumnSearchFilter();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isLoading);
    const records = useSelector(state => state.accountUsers.records);
    const pagination = useSelector(state => state.accountUsers.pagination);
    const updateIsLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const updateError = useSelector(state => state.accountUsers.updateRecordError);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [searchText, setSearchText] = useState({ 'driver.irisId': '', firstName: '', lastName: '' });
    const [isApprovingDriver, setIsApprovingDriver] = useState(false);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const approveDriver = (accountUserId) => {
        dispatch(actionCreators.approveDriver(accountUserId));
        setIsApprovingDriver(true);
    };

    const openEmployeeView = (id) => {
        props.history.push({ pathname: `/drivers/${id}` }, {
            previousPageTitle: "Drivers",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openEmployeeView(record.id); }}>
                    <span>Go to Driver Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const setRowClassName = (record) => {
        return (record.driver !== undefined && record.driver !== null && ((record.driver.isApproved === false && record.driver.documentMissingCount < 2) || record.driver.documentPendingApprovalCount > 0 || record.driver.documentExpiredCount > 0)) ? classes.unverifiedRow : classes.dataTableRow;
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDriver: true, 
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchAccountUsers(searchParams, true));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountUsersSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDriver: true, 
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchAccountUsers(searchParams, true));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Driver Id',
            dataIndex: ['driver', 'irisId'],
            key: 'driver.irisId',
            ...columnSearchFilter.getColumnSearchProps('driver.irisId', 'Driver Id', searchText, setSearchText)
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            ...columnSearchFilter.getColumnSearchProps('firstName', 'First Name', searchText, setSearchText)
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            ...columnSearchFilter.getColumnSearchProps('lastName', 'Last Name', searchText, setSearchText)
        },
        {
            title: 'Available For Loads',
            dataIndex: ['driver', 'isAvailable'],
            key: 'driver.isAvailable',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Total Load Completed',
            dataIndex: ['driver', 'totalLoads'],
            key: 'driver.totalLoads',
        },
        {
            title: 'Scheduled Loads',
            dataIndex: ['driver', 'scheduledLoads'],
            key: 'driver.scheduledLoads',
        },
    ];

    if (entityType === "STAFF" || entityType === "CARRIER") {
        columns.push({
            title: 'Is Approved',
            dataIndex: ['driver', 'isApproved'],
            key: 'driver.isApproved',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Documents Pending Approval',
            dataIndex: ['driver', 'documentPendingApprovalCount'],
            key: 'driver.documentPendingApprovalCount',
        });
        columns.push({
            title: 'Missing Documents',
            dataIndex: ['driver', 'documentMissingCount'],
            key: 'driver.documentMissingCount',
        });
        columns.push({
            title: 'Documents Expiring Soon',
            dataIndex: ['driver', 'documentExpiringSoonCount'],
            key: 'driver.documentExpiringSoonCount',
        });
        columns.push({
            title: 'Expired Documents',
            dataIndex: ['driver', 'documentExpiredCount'],
            key: 'driver.documentExpiredCount',
        });

        if (accountId === undefined || accountId === null) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewDriver cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {(selectedRecord && selectedRecord.driver) && <DocumentList accountUser={selectedRecord} entityType="DRIVER" entityId={selectedRecord.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
            {selectedRecord !== undefined && selectedRecord !== null && selectedRecord.driver !== undefined && selectedRecord.driver !== null && selectedRecord.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
            ) : null}
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    const finishedApproveDriver = () => {
        dispatch(actionCreators.cancelUpdateAccountUser());
        toggleDocumentsModal();
        setIsApprovingDriver(false);
    };

    useMemo(() => {
        if (updateIsLoading !== null && updateIsLoading === false && updateError === null && isApprovingDriver === true) {
            finishedApproveDriver();
        }
    }, [updateIsLoading, updateError, isApprovingDriver]);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={setRowClassName}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {documentsComponent}
        </DataTable>
    );
};

export default withRouter(Drivers);
