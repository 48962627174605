import { isEmpty } from 'lodash';
import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataAtByRow = ({ by, className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = null, noColon = false, valueStyle = null, at, entityType }) => {
    let name = [];
    let isByStaff = false;
    if (by !== undefined && by !== null && !isEmpty(by)) {
        if (by.firstName !== undefined && by.firstName !== null) {
            name.push(by.firstName);
        }
        if (by.middleName !== undefined && by.middleName !== null) {
            name.push(by.middleName);
        }
        if (by.lastName !== undefined && by.lastName !== null) {
            name.push(by.lastName);
        }
        if (by.isStaff !== undefined && by.isStaff !== null && by.isStaff === true) {
            isByStaff = true;
        }
    }

    // only show the By name to staff if it is by a staff member

    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            <span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString('MomentDate', at, null, null)}{((entityType !== undefined && entityType !== null && entityType === 'STAFF' && isByStaff === true) || isByStaff === false) ? ` by ${name.join(' ')}` : ''}</span>
        </div>
    );
};

export default DataAtByRow;