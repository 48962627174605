import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataRow from '../../components/DataRow/DataRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';

const { TabPane } = Tabs;

const LinkedLoadLane = props => {
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.linkedLoadLanes.isRecordLoading);
    const error = useSelector(state => state.linkedLoadLanes.recordError);
    const linkedLoadLane = useSelector(state => state.linkedLoadLanes.record);

    const [tabKey, setTabKey] = useState('snapshot');

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchLinkedLoadLane(id));
    }, [id]);


    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/linkedLoadLanes` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/linkedLoadLanes`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Lanes';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/linkedLoadLanes` },
        path: previousPath ? previousPath : `/linkedLoadLanes`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Lanes',
    },
    {
        path: `/linkedLoadLanes/${id ? id : null}`,
        breadcrumbName: `${linkedLoadLane && linkedLoadLane.customerLaneId ? linkedLoadLane.customerLaneId : (linkedLoadLane && linkedLoadLane.loadLane && linkedLoadLane.loadLane.customerLaneId ? linkedLoadLane.loadLane.customerLaneId : (linkedLoadLane && linkedLoadLane.loadLane && linkedLoadLane.loadLane.irisId ? linkedLoadLane.loadLane.irisId : ''))}`,
    },
    ];

    const createLocationsDisplay = (loadLane) => {
        let locationsDisplay = null
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            locationsDisplay = loadLane.locations.map((location) => {
                if (location.location !== undefined && location.location !== null && location.location.address !== undefined && location.location.address !== null) {
                    return (
                        <div key={`address-row-load-lane-${loadLane.id}-location-${location.locationId}-${location.sequence}`} style={{ marginBottom: 12 }}>
                            <DataAddressRow title={location.location.name} address={location.location.address} />
                            {(location.loadingType !== undefined && location.loadingType !== null) ? (<DataRow title={location.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} value={location.loadingType} dataType={location.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType'} />) : null}
                            {(location.commodities !== undefined && location.commodities !== null && location.commodities.length > 0) ? (<DataCommodityListRow title="Bids" data={location.commodities} />) : null}
                        </div>
                    );
                } else {
                    return null;
                }
            });
        }

        return locationsDisplay;
    };

    if (linkedLoadLane && linkedLoadLane.id === id) {
        let loadLane = linkedLoadLane.loadLane;
        let name = linkedLoadLane && linkedLoadLane.customerLaneId ? linkedLoadLane.customerLaneId : (linkedLoadLane && linkedLoadLane.loadLane && linkedLoadLane.loadLane.customerLaneId ? linkedLoadLane.loadLane.customerLaneId : (linkedLoadLane && linkedLoadLane.loadLane && linkedLoadLane.loadLane.irisId ? linkedLoadLane.loadLane.irisId : null));
        let customerLaneId = linkedLoadLane && linkedLoadLane.customerLaneId ? linkedLoadLane.customerLaneId : (loadLane && loadLane.customerLaneId ? loadLane.customerLaneId : null);
        let equipmentNeeds = linkedLoadLane && linkedLoadLane.equipmentNeeds ? linkedLoadLane.equipmentNeeds : (loadLane && loadLane.equipmentNeeds ? loadLane.equipmentNeeds : null);
        let equipmentSpecifics = linkedLoadLane && linkedLoadLane.equipmentSpecifics ? linkedLoadLane.equipmentSpecifics : (loadLane && loadLane.equipmentSpecifics ? loadLane.equipmentSpecifics : null);
        let driverRequirements = linkedLoadLane && linkedLoadLane.driverRequirements ? linkedLoadLane.driverRequirements : (loadLane && loadLane.driverRequirements ? loadLane.driverRequirements : null);
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={name ? name : 'Loading...'}
                    subtitle={equipmentNeeds && equipmentNeeds.trailerType && equipmentNeeds.trailerType.description ? 'Trailer Type: ' + equipmentNeeds.trailerType.description : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Lane Details"
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Lane ID": { value: loadLane && loadLane.irisId ? loadLane.irisId : null, dataType: 'String' },
                                            "Customer Lane ID": { value: customerLaneId ? customerLaneId : null, dataType: 'String' },
                                            "Approx Distance": { value: loadLane && loadLane.approxDistance ? loadLane.approxDistance : null, dataType: 'String' },
                                            "Approx Transit Time": { value: loadLane && loadLane.approxTransitTime ? loadLane.approxTransitTime : null, dataType: 'String' },
                                            "Per Mile Rate": { value: linkedLoadLane && linkedLoadLane.perMileRate ? linkedLoadLane.perMileRate : null, dataType: 'Money' }
                                        }}
                                    />
                                </Col>
                                {(linkedLoadLane && linkedLoadLane.locations && linkedLoadLane.locations.length > 0) && (
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Stops (In Order)"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {createLocationsDisplay(linkedLoadLane)}
                                        </ComplexCard>
                                    </Col>
                                )}
                                {(equipmentNeeds || driverRequirements || equipmentSpecifics) ? (
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Default Equipment Needs and Services"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {equipmentNeeds && equipmentNeeds.trailerType && equipmentNeeds.trailerType.description ? <DataRow title="Trailer Type" value={equipmentNeeds.trailerType.description} dataType="String" /> : null}
                                            {equipmentNeeds && equipmentNeeds.trailerLength && equipmentNeeds.trailerLengthUnit ? <DataRow title="Trailer Length" value={equipmentNeeds.trailerLength} units={equipmentNeeds.trailerLengthUnit} dataType="Length" /> : null}
                                            {equipmentNeeds && equipmentNeeds.trailerWeightCapacity && equipmentNeeds.trailerWeightCapacityUnit ? <DataRow title="Load Weight" value={equipmentNeeds.trailerWeightCapacity} units={equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" /> : null}
                                            {equipmentNeeds && equipmentNeeds.preCoolReefer ? <DataRow title="Pre-cool Temp" value={equipmentNeeds.preCoolReefer} units={equipmentNeeds.preCoolReeferUnit} dataType="Temperature" /> : null}
                                            {equipmentNeeds && equipmentNeeds.minRunTemp && equipmentNeeds.maxRunTemp ? <DataTemperatureRangeRow title="Temp Range" min={equipmentNeeds.minRunTemp} minUnits={equipmentNeeds.minRunTempUnit} max={equipmentNeeds.maxRunTemp} maxUnits={equipmentNeeds.maxRunTempUnit} /> : null}
                                            {equipmentSpecifics && equipmentSpecifics.length > 0 ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {driverRequirements && driverRequirements.length > 0 ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </ComplexCard>
                                    </Col>
                                ) : null}
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(LinkedLoadLane);