import React from 'react';
import { Table } from "antd";
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';

const DataTable = ({hidePaging = false, pagination = null, pageSize = null, bordered = true, dataSource, columns, 
    onChange = null, onRow = null, expandedRowRender, expandRowByClick, rowClassName, size = 'small', 
    title = null, singularEntityName = null, pluralEntityName = null, newEntityAction = null, style = null, rowKey,
    loading = false, footer = null, rowSelection = null, scroll = null, ...props }) => {

    const tableProps = {
        bordered: bordered,
        dataSource: dataSource,
        columns: columns,
        size: size,
        loading: loading
    };

    if (onChange !== undefined && onChange !== null) {
        tableProps.onChange = onChange;
    }
    if (onRow !== undefined && onRow !== null) {
        tableProps.onRow = onRow;
    }
    if (expandedRowRender !== undefined && expandedRowRender !== null) {
        tableProps.expandedRowRender = expandedRowRender;
    }
    if (expandRowByClick !== undefined && expandRowByClick !== null) {
        tableProps.expandRowByClick = expandRowByClick;
    }
    if (rowClassName !== undefined && rowClassName !== null) {
        tableProps.rowClassName = rowClassName;
    }
    if (rowSelection !== undefined && rowSelection !== null) {
        tableProps.rowSelection = rowSelection;
    }
    if (scroll !== undefined && scroll !== null) {
        tableProps.scroll = scroll;
    }
    if (hidePaging === false) {
        if (pagination !== undefined && pagination !== null) {
            tableProps.pagination = { ...pagination, showSizeChanger: true, pageSizeOptions: [5, 10, 20, 50, 100] };
        } else if (pageSize !== undefined && pageSize !== null) {
            tableProps.pagination = { pageSize: pageSize, showSizeChanger: false };
        } else {
            tableProps.pagination = false;
        }
    } else {
        tableProps.pagination = false;
    }
    if (title !== undefined && title !== null) {
        tableProps.title = title;
    } else if (singularEntityName !== undefined && singularEntityName !== null && pluralEntityName !== undefined && pluralEntityName !== null && newEntityAction !== undefined && newEntityAction !== null) {
        tableProps.title = () => (<DataTableTitle singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={newEntityAction} />);
    }
    if (style !== undefined && style !== null) {
        tableProps.style = style;
    }
    if (rowKey !== undefined && rowKey !== null) {
        tableProps.rowKey = rowKey;
    }
    if (footer !== undefined && footer !== null) {
        tableProps.footer = footer;
    }

    return (
        <>
            <Table {...tableProps} />
            {props.children}
        </>
    );
};

export default DataTable;