import React, { useMemo } from 'react';
import { Select, Alert, Spin } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const EditInvoiceLineItemStatus = props => {
    const load = props.load;
    const invoiceLineItem = props.invoiceLineItem;
    const invoiceStatusOptions = Enums.InvoiceStatuses.selectListOptions();

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.invoiceLineItems.isRecordUpdateLoading);
    const error = useSelector(state => state.invoiceLineItems.updateRecordError);

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            dispatch(actionCreators.updateInvoiceLineItem(invoiceLineItem.id, data, load.id));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateInvoiceLineItem());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateInvoiceLineItemErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Update Status of Invoice Line Item</legend>
                        <FormItem {...formItemLayout} label="Status" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceStatusOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="status"
                            defaultValue={invoiceLineItem.status}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Invoice Line Item" />
            </Form>
        </FormProvider>
    );
};

export default EditInvoiceLineItemStatus;