import React from 'react';
import { Card, Typography, Button } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import classes from './LoadRateCard.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import AddEditChangeRemoveButtons from '../AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import Pricing from '../../shared/pricing';
import DataAtByRow from '../DataAtByRow/DataAtByRow';

const { Title } = Typography;
const stringFormatter = new StringFormatter();

const LoadRateCard = props => {
    const load = props.load;
    const shipperId = props.load !== undefined && props.load !== null && props.load.shipperId !== undefined && props.load.shipperId !== null ? props.load.shipperId : null;
    const carrierId = props.load !== undefined && props.load !== null && props.load.assignedCarrierId !== undefined && props.load.assignedCarrierId !== null ? props.load.assignedCarrierId : null;
    const invoiceLineItems = props.invoiceLineItems !== undefined && props.invoiceLineItems !== null ? props.invoiceLineItems : [];
    const transactions = props.transactions !== undefined && props.transactions !== null ? props.transactions : [];
    const claims = props.claims !== undefined && props.claims !== null ? props.claims : [];
    const currentStatus = props.currentStatus;
    const entityType = props.entityType;

    let pricingSummary = Pricing.loadPricingSummary(invoiceLineItems, transactions);

    const extra = (entityType === "STAFF") ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={false} editText={'Edit Pricing for this Load'} editAction={(e) => { e.stopPropagation(); props.onTabChange('invoice'); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    return (
        <Card title={props.title} bordered={true} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={extra}>

            {props.updatePricingIcon !== undefined && props.updatePricingIcon !== null && props.updatePricingIcon !== undefined && props.updatePricingIcon !== null ? (
                <span style={{ marginRight: 12 }}>{props.updatePricingIcon}</span>
            ) : null}
            {props.updatePricingBtn !== undefined && props.updatePricingBtn !== null ? (
                <span className="ant-page-header-heading-sub-title" >{props.updatePricingBtn}</span>
            ) : null}

            {pricingSummary !== null && entityType === "STAFF" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", pricingSummary.IRISAmount, null)}</Title>
            ) : null}
            {pricingSummary !== null && entityType === "SHIPPER" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", Math.abs(pricingSummary.shipperAmountWithAddOns), null)}</Title>
            ) : null}
            {pricingSummary !== null && entityType === "CARRIER" ? (
                <Title level={1} style={{ marginBottom: '0.5em' }}>{stringFormatter.toFormattedString("Money", Math.abs(pricingSummary.carrierAmountWithAddOns), null)}</Title>
            ) : null}
            {entityType === "STAFF" ? (
                <>
                    {load.isPriceConfirmed && load.priceConfirmedByUser && load.priceConfirmedAt ? <DataAtByRow entityType={entityType} title="Price Confirmed" at={load.priceConfirmedAt} by={load.priceConfirmedByUser} /> : <DataRow title="Price Confirmed" value={'Pending'} dataType={'String'} />}
                    {load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmed" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            {entityType === "CARRIER" ? (
                <>
                    {load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmed" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            {entityType === "SHIPPER" ? (
                <>
                    {load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Price Confirmed" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Price Confirmed" value={'Pending'} dataType={'String'} />}
                    <hr />
                </>
            ) : null}
            <DataRow title="Trip Miles" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
            {pricingSummary !== null && entityType === "STAFF" ? (
                <>
                    {pricingSummary.shipperAccessorialAmount !== 0.00 ? (<DataRow title="Accessorials" value={pricingSummary.shipperAccessorialAmount} dataType="Money" />) : null}
                    {pricingSummary.addOnChargeAmount !== 0.00 ? (<DataRow title="Add-On Fees" value={pricingSummary.addOnChargeAmount} dataType="Money" />) : null}
                    {pricingSummary.shipperClaimAmount !== 0.00 ? (<DataRow title="Claim Adjustments" value={pricingSummary.shipperClaimAmount} dataType="Money" />) : null}
                    {!!pricingSummary.shipperRatePerMileAmount && <DataRow title="Shipper Rate/Mile" value={pricingSummary.shipperRatePerMileAmount} dataType="Money" />}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperAmountWithAddOns > 0 ? "Amount Owed by Shipper" : "Amount Owed to Shipper"} value={Math.abs(pricingSummary.shipperAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperBalance > 0 ? "Balance Owed by Shipper" : "Balance Owed to Shipper"} value={Math.abs(pricingSummary.shipperBalance)} dataType="Money" />
                    <hr />
                    {pricingSummary.carrierAccessorialAmount !== 0.00 ? (<DataRow title="Accessorials" value={pricingSummary.carrierAccessorialAmount} dataType="Money" />) : null}
                    {pricingSummary.addOnCostAmount !== 0.00 ? (<DataRow title="Add-On Fees" value={pricingSummary.addOnCostAmount} dataType="Money" />) : null}
                    {pricingSummary.carrierClaimAmount !== 0.00 ? (<DataRow title="Claim Adjustments" value={pricingSummary.carrierClaimAmount} dataType="Money" />) : null}
                    {!!pricingSummary.carrierRatePerMileAmount && <DataRow title="Carrier Rate/Mile" value={Math.abs(pricingSummary.carrierRatePerMileAmount)} dataType="Money" />}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierAmountWithAddOns < 0 ? "Amount Owed by Carrier" : "Amount Owed to Carrier"} value={Math.abs(pricingSummary.carrierAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierBalance < 0 ? "Balance Owed by Carrier" : "Balance Owed to Carrier"} value={Math.abs(pricingSummary.carrierBalance)} dataType="Money" />
                    {/*<hr />*/}
                    {/*
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.factoringAmount < 0 ? "Amount Owed by Factoring Company" : "Amount Owed to Factoring Company"} value={Math.abs(pricingSummary.factoringAmount)} dataType="Money" />
                    */}
                    {/*
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.factoringBalance < 0 ? "Balance Owed by Factoring Company" : "Balance Owed to Factoring Company"} value={Math.abs(pricingSummary.factoringBalance)} dataType="Money" />
                    */}
                    <hr />
                    <DataRow title="Service Fee/Mile" value={pricingSummary.feePerMileAmount} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title="Expected Revenue" value={pricingSummary.IRISAmount} dataType="Money" />
                </>
            ) : null}
            {pricingSummary !== null && entityType === "SHIPPER" ? (
                <>
                    {pricingSummary.shipperAccessorialAmount !== 0.00 ? (<DataRow title="Shipper Accessorials" value={Math.abs(pricingSummary.shipperAccessorialAmount)} dataType="Money" />) : null}
                    {pricingSummary.addOnChargeAmount !== 0.00 ? (<DataRow title="Shipper Add-Ons" value={Math.abs(pricingSummary.addOnChargeAmount)} dataType="Money" />) : null}
                    {pricingSummary.shipperClaimAmount !== 0.00 ? (<DataRow title="Shipper Claim Adjustments" value={Math.abs(pricingSummary.shipperClaimAmount)} dataType="Money" />) : null}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperAmountWithAddOns > 0 ? "Amount Due from Shipper" : "Amount Due to Shipper"} value={Math.abs(pricingSummary.shipperAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.shipperBalance > 0 ? "Balance Due from Shipper" : "Balance Due to Shipper"} value={Math.abs(pricingSummary.shipperBalance)} dataType="Money" />
                </>
            ) : null}
            {pricingSummary !== null && entityType === "CARRIER" ? (
                <>
                    {pricingSummary.carrierAccessorialAmount !== 0.00 ? (<DataRow title="Carrier Accessorials" value={Math.abs(pricingSummary.carrierAccessorialAmount)} dataType="Money" />) : null}
                    {pricingSummary.addOnCostAmount !== 0.00 ? (<DataRow title="Carrier Add-Ons Costs" value={Math.abs(pricingSummary.addOnCostAmount)} dataType="Money" />) : null}
                    {pricingSummary.carrierClaimAmount !== 0.00 ? (<DataRow title="Carrier Claim Adjustments" value={Math.abs(pricingSummary.carrierClaimAmount)} dataType="Money" />) : null}
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierAmountWithAddOns > 0 ? "Amount Due from Carrier" : "Amount Due to Carrier"} value={Math.abs(pricingSummary.carrierAmountWithAddOns)} dataType="Money" />
                    <DataRow valueStyle={{ fontWeight: 'bold' }} title={pricingSummary.carrierBalance > 0 ? "Balance Due from Carrier" : "Balance Due to Carrier"} value={Math.abs(pricingSummary.carrierBalance)} dataType="Money" />
                </>
            ) : null}
            {claims !== null && claims !== undefined && entityType === "STAFF" ? (
                <>
                    <hr />
                    <DataRow title="Open Claims" value={claims.filter(i => i.claimStatus !== 'CLOSED') !== undefined ? claims.filter(i => i.claimStatus !== 'CLOSED').length : 0} dataType="String" />
                </>
            ) : null}
            <hr />
            {((currentStatus === 'PENDING' || currentStatus === 'APPROVED' || currentStatus === 'PENDING_RATE_CONFIRMATION') && entityType === "STAFF") ? (
                <div><Button href={`/priceconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={shipperId === null || shipperId === undefined}>View Price Confirmation</Button></div>
            ) : null}
            {(currentStatus !== 'CANCELLED' && entityType === "SHIPPER") ? (
                <div><Button href={`/priceconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={shipperId === null || shipperId === undefined}>View Price Confirmation</Button></div>
            ) : null}
            {((currentStatus !== 'COMPLETED' && currentStatus !== 'CLOSED' && currentStatus !== 'REOPENED' && currentStatus !== 'CANCELLED') && (entityType === "STAFF" || entityType === "CARRIER")) ? (
                <div><Button href={`/rateconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={carrierId === null || carrierId === undefined}>View Rate Confirmation</Button></div>
            ) : null}
            {((currentStatus !== 'PENDING' && currentStatus !== 'APPROVED' && currentStatus !== 'PENDING_RATE_CONFIRMATION' && currentStatus !== 'CANCELLED') && entityType === "STAFF") ? (
                <div><Button href={`/shipperinvoice/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5 }} disabled={shipperId === null || shipperId === undefined}>View Shipper Invoice</Button></div>
            ) : null}
        </Card>
    );
};

export default LoadRateCard;
