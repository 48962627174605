import React from 'react';
import { withRouter } from 'react-router-dom';
import DocumentList from '../../components/DocumentList/DocumentList';

const Documents = props => {
    return (
        <DocumentList entityId={null} documentList={null} />
    );
};

export default withRouter(Documents);