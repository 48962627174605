import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {fetchLoadSuccess, loadEventUpdateTimeout} from "./loads";
import logger from "../../shared/logger";

//#region Carrier Rejection Functions

export const fetchCarrierRejectionsStart = () => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTIONS_START
    }
};

export const fetchCarrierRejectionsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTIONS_SUCCESS,
        payload: payload
    }
};

export const fetchCarrierRejectionsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CARRIER_REJECTIONS_FAIL,
        payload: payload
    }
};

export const clearCarrierRejections = () => {
    return {
        type: actionTypes.CLEAR_CARRIER_REJECTIONS
    }
};

export const addCarrierRejectionStart = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_START
    }
};

export const insertCarrierRejection = (payload) => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION,
        payload: payload
    }
};

export const addCarrierRejectionSuccess = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_SUCCESS
    }
};

export const addCarrierRejectionFail = (payload) => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_FAIL,
        payload: payload
    }
};

export const addCarrierRejectionErrorClear = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_ERROR_CLEAR
    }
};

export const addCarrierRejectionLoadingClear = () => {
    return {
        type: actionTypes.ADD_CARRIER_REJECTION_LOADING_CLEAR
    }
};

export const updateCarrierRejectionStart = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_START
    }
};

export const changeCarrierRejection = (payload) => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION,
        payload: payload
    }
};

export const changeSingleCarrierRejection = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CARRIER_REJECTION,
        payload: payload
    }
};

export const updateCarrierRejectionSuccess = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_SUCCESS
    }
};

export const updateCarrierRejectionFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_FAIL,
        payload: payload
    }
};

export const updateCarrierRejectionErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_ERROR_CLEAR
    }
};

export const updateCarrierRejectionLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CARRIER_REJECTION_LOADING_CLEAR
    }
};

export const cancelAddCarrierRejection = () => {
    return {
        type: actionTypes.CANCEL_ADD_CARRIER_REJECTION
    }
};

export const cancelUpdateCarrierRejection = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_CARRIER_REJECTION
    }
};

export const removeCarrierRejection = (payload) => {
    return {
        type: actionTypes.REMOVE_CARRIER_REJECTION,
        payload: payload
    }
};

//#endregion

//#region Carrier Rejection Methods

export const fetchCarrierRejections = (payload) => {
    return async (dispatch, getState) => {
        const carrierRejectionsPath = '/carrierRejections';
        try {
            dispatch(fetchCarrierRejectionsStart());

            const state = getState();
            const carrierRejectionsState = {...state.carrierRejections};
            let pagination = {...carrierRejectionsState.pagination};
            let searchParams = {...carrierRejectionsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const carrierRejectionsRes = await axiosAuthenticated.get(carrierRejectionsPath, {params: {...searchParams}});
            if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
                const carrierRejections = carrierRejectionsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                const transformedCarrierRejections = carrierRejections.map(item => {
                    // Get Carrier Info
                    let rejectedByCarrier = null;
                    if (item.rejectedByCarrierId !== undefined && item.rejectedByCarrierId !== null) {
                        let rejectedByCarrierObj = accounts.find(i => i.id === item.rejectedByCarrierId);
                        if (rejectedByCarrierObj !== undefined && rejectedByCarrierObj !== null) {
                            rejectedByCarrier = {...rejectedByCarrierObj};
                        }
                    }

                    return {
                        ...item,
                        rejectedByCarrier: rejectedByCarrier
                    };
                });

                // Read total count from server
                pagination.total = carrierRejectionsRes.data.totalCount;
                pagination.current = carrierRejectionsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchCarrierRejectionsSuccess({
                    records: transformedCarrierRejections,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchCarrierRejectionsFail({error: error.message}));
        }
    }
};

export const addCarrierRejection = (payload) => {
    return async (dispatch, getState) => {
        const carrierRejectionsPath = '/carrierRejections';
        try {

            dispatch(addCarrierRejectionStart());

            const carrierRejectionRes = await axiosAuthenticated.post(carrierRejectionsPath, {...payload});
            if (carrierRejectionRes && carrierRejectionRes.status === 201) {
                const carrierRejection = carrierRejectionRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                // Get Carrier Info
                let rejectedByCarrier = null;
                if (carrierRejection.rejectedByCarrierId !== undefined && carrierRejection.rejectedByCarrierId !== null) {
                    let rejectedByCarrierObj = accounts.find(i => i.id === carrierRejection.rejectedByCarrierId);
                    if (rejectedByCarrierObj !== undefined && rejectedByCarrierObj !== null) {
                        rejectedByCarrier = {...rejectedByCarrierObj};
                    }
                }

                let newCarrierRejection = {
                    ...carrierRejection,
                    rejectedByCarrier: rejectedByCarrier
                };

                dispatch(insertCarrierRejection(newCarrierRejection));

                dispatch(addCarrierRejectionSuccess());
                dispatch(addCarrierRejectionLoadingClear());
                dispatch(addCarrierRejectionErrorClear());
                dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                dispatch(loadEventUpdateTimeout(payload.loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_REJECTED'));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addCarrierRejectionFail({error: error.message}));
        }
    }
};

export const updateCarrierRejection = (carrierRejectionId, payload) => {
    return async (dispatch, getState) => {
        const carrierRejectionsPath = '/carrierRejections';
        try {
            dispatch(updateCarrierRejectionStart());

            const carrierRejectionsRes = await axiosAuthenticated.put(carrierRejectionsPath + `/${carrierRejectionId}`, {...payload});
            if (carrierRejectionsRes && carrierRejectionsRes.status === 200) {
                const carrierRejection = carrierRejectionsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                // Get Carrier Info
                let rejectedByCarrier = null;
                if (carrierRejection.rejectedByCarrierId !== undefined && carrierRejection.rejectedByCarrierId !== null) {
                    let rejectedByCarrierObj = accounts.find(i => i.id === carrierRejection.rejectedByCarrierId);
                    if (rejectedByCarrierObj !== undefined && rejectedByCarrierObj !== null) {
                        rejectedByCarrier = {...rejectedByCarrierObj};
                    }
                }

                let updatedCarrierRejection = {
                    ...carrierRejection,
                    rejectedByCarrier: rejectedByCarrier
                };

                dispatch(changeCarrierRejection(updatedCarrierRejection));
                dispatch(changeSingleCarrierRejection(updatedCarrierRejection));

                dispatch(updateCarrierRejectionSuccess());
                dispatch(updateCarrierRejectionLoadingClear());
                dispatch(updateCarrierRejectionErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateCarrierRejectionFail({error: error.message}));
        }
    }
};

//#endregion