import React from "react";
import { Col, Row, Alert } from "antd";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

const FormItemFile = (props) => {
    const methods = useFormContext();

    const label = props.label;
    const labelCol = props.labelCol;
    const wrapperCol = props.wrapperCol;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';
    const required = props.required !== undefined && props.required !== null ? props.required : false;
    const noColon = props.noColon !== undefined && props.noColon !== null ? props.noColon : false;
    const name = props.name;

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {label &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        {props.children}
                    </Col>
                </Row>
                {getError(methods.errors, name) !== undefined && (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (messages !== undefined && messages !== null) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" />));
                            } else if (message !== undefined && message !== null) {
                                return <Alert message={message} type="error" />;
                            } else {
                                return null;
                            }
                        }}
                    />
                )}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]}>
                    {label && <Col {...labelCol} style={{ textAlign: 'right' }}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>}
                    <Col {...wrapperCol}>
                        {props.children}
                    </Col>
                </Row>
                {getError(methods.errors, name) !== undefined && (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (messages !== undefined && messages !== null) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" />));
                            } else if (message !== undefined && message !== null) {
                                return <Alert message={message} type="error" />;
                            } else {
                                return null;
                            }
                        }}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemFile;