import React from 'react';
import classes from './InvoiceTable.module.scss';
import InvoiceHeaderRow from '../InvoiceHeaderRow/InvoiceHeaderRow';
import InvoiceRows from '../InvoiceRows/InvoiceRows';
import ValueFormatting from '../../shared/ValueFormatting';

const InvoiceTable = props => {
    const isPriceConfirmation = props.isPriceConfirmation !== undefined && props.isPriceConfirmation !== null && props.isPriceConfirmation === true ? true : false;
    const isRateConfirmation = props.isRateConfirmation !== undefined && props.isRateConfirmation !== null && props.isRateConfirmation === true ? true : false;
    const totalTitle = props.totalTitle !== undefined && props.totalTitle !== null ? props.totalTitle : 'Total';
    const totalTitleStyle = props.totalTitleStyle;
    const totalStyle = props.totalStyle;
    const data = props.data;
    const entityType = props.entityType;

    let lineItems = [];
    let irisFees = 0.00;
    let irisBaseFees = 0.00;
    let irisQuantity = 0;

    console.log("DATA", data);

    for (let i = 0; i < data.length; i++) {
        if (isPriceConfirmation === true) {
            if (data[i].isIrisFee === true || data[i].quantityUnit === 'PER_MILE') {
                if (data[i].fromEntityType === entityType) {
                    irisBaseFees += data[i].baseAmount;
                    irisFees += data[i].totalAmount;
                    irisQuantity = data[i].quantity;
                } else if (data[i].toEntityType === entityType) {
                    irisBaseFees -= data[i].baseAmount;
                    irisFees -= data[i].totalAmount;
                    irisQuantity = data[i].quantity;
                }
            } else {
                lineItems.push({
                    description: data[i].description,
                    baseAmount: data[i].fromEntityType === entityType ? data[i].baseAmount : -1.0 * data[i].baseAmount,
                    quantity: data[i].quantity,
                    totalAmount: data[i].fromEntityType === entityType ? data[i].totalAmount : -1.0 * data[i].totalAmount
                });
            }
        } else if (isRateConfirmation === true) {
            if (data[i].isIrisFee === true || data[i].quantityUnit === 'PER_MILE') {
                if (data[i].fromEntityType === entityType) {
                    irisBaseFees -= data[i].baseAmount;
                    irisFees -= data[i].totalAmount;
                    irisQuantity = data[i].quantity;
                } else if (data[i].toEntityType === entityType) {
                    irisBaseFees += data[i].baseAmount;
                    irisFees += data[i].totalAmount;
                    irisQuantity = data[i].quantity;
                }
            } else {
                lineItems.push({ 
                    description: data[i].description, 
                    baseAmount: data[i].toEntityType === entityType ? data[i].baseAmount : -1.0 * data[i].baseAmount, 
                    quantity: data[i].quantity, 
                    totalAmount: data[i].toEntityType === entityType ? data[i].totalAmount : -1.0 * data[i].totalAmount 
                });
            }
        } else {
            if (data[i].isIrisFee === true) {
                if (data[i].fromEntityType === entityType) {
                irisBaseFees += data[i].baseAmount;
                irisFees += data[i].totalAmount;
                irisQuantity = data[i].quantity;
                } else if (data[i].toEntityType === entityType) {
                    irisBaseFees -= data[i].baseAmount;
                irisFees -= data[i].totalAmount;
                irisQuantity = data[i].quantity;
                }
            } else {
                lineItems.push({ 
                    description: data[i].description, 
                    baseAmount: data[i].fromEntityType === entityType ? data[i].baseAmount : -1.0 * data[i].baseAmount, 
                    quantity: data[i].quantity, 
                    totalAmount: data[i].fromEntityType === entityType ? data[i].totalAmount : -1.0 * data[i].totalAmount 
                });
            }
        }
    }

    if (irisFees > 0) {
        lineItems.push({ description: "Pulogix Fees Per Mile", baseAmount: irisBaseFees > 0 ? irisBaseFees : -1.0 * irisBaseFees, quantity: irisQuantity, totalAmount: irisFees > 0 ? irisFees : -1.0 * irisFees });
    } else if (irisFees < 0) {
        lineItems.push({ description: "Pulogix Rate Per Mile", baseAmount: irisBaseFees > 0 ? irisBaseFees : -1.0 * irisBaseFees, quantity: irisQuantity, totalAmount: irisFees > 0 ? irisFees : -1.0 * irisFees });
    }

    lineItems = lineItems.map((it) => {
        it.description = it.description.replace('IRIS', '');
        return it;
    })
    const carrierAmount = () => {
        let amount = 0.00;
        props.data.forEach(item => {
            if (item.fromEntityType === 'CARRIER') {
                amount -= item.totalAmount
            } else if (item.toEntityType === 'CARRIER') {
                amount += item.totalAmount
            }
        });

        //console.log(amount)

        return amount;
    }

    const shipperAmount = () => {
        let amount = 0.00;
        props.data.forEach(item => {
            if (item.fromEntityType === 'SHIPPER') {
                amount += item.totalAmount
            } else if (item.toEntityType === 'SHIPPER') {
                amount -= item.totalAmount
            }
        });

        //console.log(amount)

        return amount;
    }

    return (
        <table className={classes.table}>
            <InvoiceHeaderRow columns={props.columns} />
            <tbody>
                <InvoiceRows data={lineItems} columns={props.columns} />
            </tbody>
            <tfoot>
                <tr>
                    <td className={classes.thickLine} colSpan={3} style={totalTitleStyle}><strong>{totalTitle}</strong></td>
                    <td className={[classes.textRight, classes.thickLine].join(' ')} style={totalStyle}><ValueFormatting.Money value={carrierAmount() > 0.0 ? carrierAmount() : shipperAmount()} /></td>
                </tr>
            </tfoot>
        </table>
    );
};

export default InvoiceTable;