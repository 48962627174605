import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, FileTextOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Drawer, Modal, Button, Menu, Dropdown } from 'antd';
import Claim from "../../components/Claim/Claim";
import EditClaim from "../../components/EditClaim/EditClaim";
import NewClaim from "../../components/NewClaim/NewClaim";
import classes from './LoadClaims.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import DocumentList from '../../components/DocumentList/DocumentList';
import NoteList from '../NoteList/NoteList';

const stringFormatter = new StringFormatter();

const LoadClaims = props => {
    const fullWidth = global.window.innerWidth;
    const loadId = props.loadId;
    const load = props.load;
    const claims = props.claims;
    const entityType = props.entityType;
    const documents = props.documents;
    const notes = props.notes;
    const currentStatus = props.currentStatus;

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [loadClaims, setLoadClaims] = useState([]);

    const singularEntityName = "Claim";
    const pluralEntityName = "Claims";

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={() => { setSelectedRecord(record); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="manageNotes" onClick={() => { setSelectedRecord(record); toggleNotesModal(); }}>
                    <FileTextOutlined style={{ marginRight: '10px' }} />
                    <span>Manage Notes</span>
                </Menu.Item>
                <Menu.Item key="editClaim" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faScroll} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Claim ID',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Claim Status',
            dataIndex: 'claimStatus',
            key: 'claimStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("ClaimStatus", text, null); },
        },
        {
            title: 'Is Against Carrier',
            dataIndex: 'isAgainstCarrier',
            key: 'isAgainstCarrier',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Against Shipper',
            dataIndex: 'isAgainstShipper',
            key: 'isAgainstShipper',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Claim Against Insurance',
            dataIndex: 'isInsuranceClaim',
            key: 'isInsuranceClaim',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Claim Against',
            dataIndex: ['account', 'name'],
            key: 'account.name',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackagingCount", text, record.unitPackaging); },
        },
        {
            title: 'BOL Status',
            dataIndex: 'bolStatus',
            key: 'bolStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("BOLStatus", text, null); },
        },
        {
            title: 'Adjustment Amount',
            dataIndex: 'adjustment',
            key: 'adjustment',
            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
        },
        {
            title: 'BOL #',
            dataIndex: 'bolNumber',
            key: 'bolNumber',
        }
    ];

    if (entityType === "STAFF" && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED') {
        columns.unshift({
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 150,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        });
    }

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewClaim cancel={toggleNewEntity} load={load} loadId={loadId} irisLoadId={load.irisId} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditClaim cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Claim Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="CLAIM" entityId={selectedRecord.id} documentList={documents.filter(document => document.entityId === selectedRecord.id)} loadId={loadId} />
        </Modal>
    );

    const notesComponent = (
        <Modal
            title="Claim Notes"
            visible={showNotesModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <NoteList entityId={selectedRecord.id} entityType="CLAIM" noteList={notes.filter(note => note.entityId === selectedRecord.id)} />
        </Modal>
    );

    useMemo(() => {
        if (claims !== undefined && claims !== null && claims.length > 0) {
            setLoadClaims(claims.filter(c => c.loadId === loadId));
        } else {
            setLoadClaims([]);
        }
    }, [claims]);

    return (
        <DataTable
            dataSource={loadClaims}
            columns={columns}
            pageSize={5}
            //loading={isLoading === true}
            expandedRowRender={record => (<Claim record={record} />)}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={entityType === 'STAFF' && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED' ? toggleNewEntity : null}
            rowKey={record => record.id}
        >
            {editEntityComponents}
            {newEntityComponents}
            {documentsComponent}
            {notesComponent}
        </DataTable>
    );
};

export default withRouter(LoadClaims);