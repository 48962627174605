import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Alert, Divider, Input, Spin, Checkbox, Select } from "antd";
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import { checkEmailAddressReducer, checkPhoneNumberReducer, checkEmailAddress, checkPhoneNumber, isValidEmailAddress } from '../../shared/formUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const NewEmployee = props => {
    const accountIdValue = props.accountId;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordAddLoading);
    const error = useSelector(state => state.accountUsers.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [accountId, setAccountId] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        //console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (emailAddress !== undefined && emailAddress !== null && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmailAddress(emailAddress, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("No longer latest query") || clearTimeout(timeOutId);
    }, [emailAddress]);

    useEffect(() => {
        //console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (phoneNumber !== undefined && phoneNumber !== null && phoneNumber.length === 10) {
                checkPhoneNumber(phoneNumber, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("No longer latest query") || clearTimeout(timeOutId);
    }, [phoneNumber]);

    useMemo(() => {
        if (accountIdValue !== undefined && accountIdValue !== null) {
            setAccountId(accountIdValue);
        }
    }, [accountIdValue, accounts]);

    const handleAccountChange = (value) => {
        //console.log(`selected ${value}`);
        setAccountId(value);
    };

    const onEmailAddressChange = (value) => {
        if (value !== undefined && value !== null && value.length >= 4) {
            if (methods.errors.email !== undefined && methods.errors.email !== null && !isEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            //console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (value !== undefined && value !== null && value.length === 10) {
            if (methods.errors.phone !== undefined && methods.errors.phone !== null && !isEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            //console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (emailAddress !== undefined && emailAddress !== null) {
            if (methods.errors.email === undefined || methods.errors.phone === null || isEmpty(methods.errors.phone)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (phoneNumber !== undefined && phoneNumber !== null) {
            if (methods.errors.phone === undefined || methods.errors.phone === null || isEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        let emailAndOrPhoneAreValid = false;
        if (cleanedData.email !== undefined && cleanedData.email !== null) {
            if (hasValidEmailAddress === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }
        if (cleanedData.phone !== undefined && cleanedData.phone !== null) {
            if (hasValidPhoneNumber === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }

        if (emailAndOrPhoneAreValid === true) {
            if (!isEmpty(cleanedData)) {
                cleanedData.isVerified = true;
                dispatch(actionCreators.addAccountUser(cleanedData, accountId));
            }
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddAccountUser());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addAccountUserErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {(accountIdValue === undefined || accountIdValue === null) &&
                        <Fieldset>
                            <legend>Choose an Account</legend>
                            <FormItem {...formItemLayout} label="Account" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select an Account"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { handleAccountChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {accounts.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address"
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Personal Phone #"
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    placeholder="Personal Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    {(hasValidEmailAddress === true || hasValidPhoneNumber === true) && (
                        <Fieldset>
                            <legend>Employee Details</legend>
                            <div>User Account will be created with these details if one doesn't already exist.</div>
                            <FormItem {...formItemLayout} label="First Name" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" />}
                                rules={{ required: 'Required Field' }}
                                name="firstName"
                            />
                            <FormItem {...formItemLayout} label="Middle Name"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" />}
                                rules={{ required: false }}
                                name="middleName"
                            />
                            <FormItem {...formItemLayout} label="Last Name" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" />}
                                rules={{ required: 'Required Field' }}
                                name="lastName"
                            />
                            <Divider />
                            <FormItem {...formItemLayout} label="Title" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Title" />}
                                rules={{ required: 'Required Field' }}
                                name="title"
                            />
                            <FormItem {...formItemLayout} label="Work Phone #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Work Phone #" />}
                                rules={{ required: false }}
                                name="workPhone"
                            />
                            <FormItem {...formItemLayout} label="Is Contractor"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isContractor"
                                defaultValue={false}
                            />
                            <FormItem {...formItemLayout} label="Send Invitation (email or text) to Employee"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Checkbox
                                            onBlur={onBlur}
                                            onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                            name={name}
                                        />
                                    )}
                                    name="isInvited"
                                    defaultValue={true}
                                />
                        </Fieldset>
                    )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} cancelDisabled={(isLoading === true && error === null)} submitDisabled={(isLoading === true && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Create Employee" />
            </Form>
        </FormProvider>
    );
};

export default NewEmployee;