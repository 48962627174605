import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined } from '@ant-design/icons';
import { Card, Col, message, Row, Affix, Alert, Button, Popconfirm, Spin, Collapse } from "antd";
import { withRouter } from "react-router";
import { borderColor } from "../../constants/theme";
import * as actionCreators from "../../store/actions/index";
import { useForm, FormProvider } from 'react-hook-form';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import { isEmpty } from 'lodash';
import NewAccountCompany from '../../components/NewAccountCompany/NewAccountCompany';
import NewAccountShipper from '../../components/NewAccountShipper/NewAccountShipper';
import NewAccountCarrier from '../../components/NewAccountCarrier/NewAccountCarrier';
import moment from 'moment';
import { removeEmpty } from '../../shared/objectUtils';
import Form from '../../components/Form/Form';
import NewAccountCompanyCarrier from "../../components/NewAccountCompanyCarrier/NewAccountCompanyCarrier";

const { Panel } = Collapse;

const NewAccount = props => {
    const accountRegistrationId = props.location && props.location.state && props.location.state.accountRegistrationId ? props.location.state.accountRegistrationId : null;
    const accountTypes = props.location && props.location.state && props.location.state.accountTypes ? props.location.state.accountTypes : null;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordAddLoading);
    const error = useSelector(state => state.accounts.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const addRecordId = useSelector(state => state.accounts.addRecordId);

    const [isStepsLoading, setIsStepsLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [companyData, setCompanyData] = useState({});
    const [shipperData, setShipperData] = useState({});
    const [carrierData, setCarrierData] = useState({});
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(null);
    const [w9File, setW9File] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [logoFileList, setLogoFileList] = useState([]);
    const [w9FileList, setW9FileList] = useState([]);
    const [insuranceFileList, setInsuranceFileList] = useState([]);
    const [companyStepIsComplete, setCompanyStepIsComplete] = useState(false);
    const [shipperStepIsComplete, setShipperStepIsComplete] = useState(false);
    const [carrierStepIsComplete, setCarrierStepIsComplete] = useState(false);

    const cancelCancel = (event) => {
        console.log('Cancelled cancel');
    };

    const confirmCancel = (event) => {
        dispatch(actionCreators.cancelAddAccount());
        props.history.push({ pathname: "/accounts" });
        message.error('Account Creation Cancelled');
    };

    const next = () => {
        setCurrent(current + 1);
        window.scrollTo(0, 0);
    };

    const prev = () => {
        setCurrent(current - 1);
        window.scrollTo(0, 0);
    };

    const onChangeStep = (currentStep) => {
        setCurrent(currentStep);
        window.scrollTo(0, 0);
    };

    const done = (newAccountId = null) => {
        if (entityType === 'STAFF') {
            if (newAccountId !== undefined && newAccountId !== null) {
                if (accountTypes.includes("SHIPPER") === true) {
                    props.history.push({ pathname: "/shippers/" + newAccountId });
                } else if (accountTypes.includes("CARRIER") === true) {
                    props.history.push({ pathname: "/carriers/" + newAccountId });
                } else if (accountTypes.includes("PRODUCER") === true) {
                    props.history.push({ pathname: "/producers/" + newAccountId });
                } else if (accountTypes.includes("RECEIVER") === true) {
                    props.history.push({ pathname: "/receivers/" + newAccountId });
                } else {
                    props.history.push({ pathname: "/accounts/" });
                }
            } else if (accountRegistrationId !== undefined && accountRegistrationId !== null) {
                props.history.push({ pathname: "/accountRegistrations/" });
            } else {
                props.history.goBack();
            }
        } else {
            props.history.goBack();
        }

        message.success('Successfully Created Account!');
        dispatch(actionCreators.cancelAddAccount());
    };

    const basicStep = {
        title: 'Company Setup',
        longTitle: 'Setup Company Details',
        instructions: 'Please fill out your company information below. When you are done, click the Next button to continue.',
        content: (
            <NewAccountCompany
                setData={setCompanyData}
                data={companyData}
                logoFile={logoFile}
                setLogoFile={setLogoFile}
                setLogoFileList={setLogoFileList}
                logoFileList={logoFileList}
                setStepIsComplete={setCompanyStepIsComplete}
                cancelButton={
                    <Popconfirm
                        title="Are you sure you want to cancel the creation of this account? You will lose your changes."
                        onConfirm={(e) => { confirmCancel(e); }}
                        onCancel={(e) => { cancelCancel(e); }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger" style={{ marginRight: 8 }}>Cancel Account Creation</Button>
                    </Popconfirm>
                }
                previousStepAction={null}
                nextStepAction={next} />
        ),
        isNextStepAvailable: companyStepIsComplete
    };

    let steps = [];

    if (accountTypes.includes('SHIPPER') === true) {
        steps.push(basicStep);
        steps.push({
            title: 'Shipper Setup',
            longTitle: 'Setup Shipper Details',
            instructions: 'Please fill out your shipper information below. When you are done, click the Next button to continue.',
            content: (
                <NewAccountShipper
                    setData={setShipperData}
                    companyData={companyData}
                    data={shipperData}
                    setStepIsComplete={setShipperStepIsComplete}
                    previousStepAction={prev}
                    nextStepAction={next}
                />
            ),
            isNextStepAvailable: shipperStepIsComplete
        });
    } else if (accountTypes.includes('CARRIER') === true) {
        steps.push({
            title: 'Company Setup',
            longTitle: 'Setup Company Details',
            instructions: 'Please fill out your company information below. When you are done, click the Next button to continue.',
            content: (
                <NewAccountCompanyCarrier
                    setData={setCompanyData}
                    data={companyData}
                    logoFile={logoFile}
                    setLogoFile={setLogoFile}
                    setLogoFileList={setLogoFileList}
                    logoFileList={logoFileList}
                    setStepIsComplete={setCompanyStepIsComplete}

                    cancelButton={
                        <Popconfirm
                            title="Are you sure you want to cancel the creation of this account? You will lose your changes."
                            onConfirm={(e) => { confirmCancel(e); }}
                            onCancel={(e) => { cancelCancel(e); }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger" style={{ marginRight: 8 }}>Cancel Account Creation</Button>
                        </Popconfirm>
                    }
                    previousStepAction={null}
                    nextStepAction={next}
                />
            ),
            isNextStepAvailable: companyStepIsComplete
        });

        steps.push({
            title: 'Carrier Setup',
            longTitle: 'Setup Carrier Details',
            instructions: 'Please fill out your carrier information below. When you are done, click the Next button to continue.',
            content: (
                <NewAccountCarrier
                    setData={setCarrierData}
                    companyData={companyData}
                    data={carrierData}
                    setInsuranceFile={setInsuranceFile}
                    insuranceFile={insuranceFile}
                    setInsuranceFileList={setInsuranceFileList}
                    insuranceFileList={insuranceFileList}
                    setInsuranceExpiryDate={setInsuranceExpiryDate}
                    insuranceExpiryDate={insuranceExpiryDate}
                    setW9File={setW9File}
                    w9File={w9File}
                    setW9FileList={setW9FileList}
                    w9FileList={w9FileList}
                    setStepIsComplete={setCarrierStepIsComplete}
                    previousStepAction={prev}
                    nextStepAction={next}
                />
            ),
            isNextStepAvailable: carrierStepIsComplete
        });

    } else {
        steps.push(basicStep);
    }

    const onSubmit = (data) => {
        if (companyData) {
            data = { ...companyData };
            if (data.accountServices && data.accountServices.length > 0) {
                let updatedAccountServices = [];
                data.accountServices.forEach((accountService) => {
                    delete accountService['service'];
                    updatedAccountServices.push(accountService);
                });
                data.accountServices = updatedAccountServices;
            }
        }

        if (shipperData) {
            data.shipper = { ...shipperData };
        }

        let insuranceExpiryDateValue = null;
        let w9ExpiryDate = null;

        if (carrierData) {
            data.carrier = { ...carrierData };

            data.carrier.EIN = data.mcNum;

            data.carrier.mcNum = data.mcNum;

            w9ExpiryDate = moment().year(moment().year() + 1).utc().toISOString();

            if (insuranceExpiryDate !== undefined && insuranceExpiryDate !== null) {
                insuranceExpiryDateValue = insuranceExpiryDate.utc().toISOString();
            }

            if (data.carrier.insuranceExpiryDate) {
                delete data.carrier['insuranceExpiryDate'];
            }
        }

        console.log(data);

        let cleanedData = removeEmpty(data);

        // console.log(cleanedData);
        // console.log(insuranceExpiryDateValue)
        // console.log(w9ExpiryDate)
        // console.log(insuranceFile)
        // console.log(w9File)

        dispatch(actionCreators.addAccount(cleanedData, logoFile, insuranceFile, insuranceExpiryDateValue, w9File, w9ExpiryDate, entityType, accountRegistrationId));
    };

    const getAccountRegistration = async (accountRegistrationId) => {
        let accountRegistration = null;
        try {
            const resp = await axiosAuthenticated.get(`/accountRegistrations/${accountRegistrationId}`);
            if (resp && resp.status === 200) {
                accountRegistration = resp.data;
            }
        } catch (err) {
            console.log(err);
        };

        return accountRegistration;
    };

    useEffect(() => {
        console.log(`The Account Registration ID is ${accountRegistrationId}`);
        console.log(accountTypes);

        if (accountTypes !== undefined && accountTypes !== null && accountTypes.length > 0) {
            if (isEmpty(companyData)) {
                setIsStepsLoading(true);
                let companyDataObj = {};
                if (accountTypes.includes("CARRIER") === true) {
                    companyDataObj.isCarrier = true;
                } else {
                    setCarrierStepIsComplete(true);
                    companyDataObj.isCarrier = false;
                }
                if (accountTypes.includes("PRODUCER") === true) {
                    companyDataObj.isProducer = true;
                } else {
                    companyDataObj.isProducer = false;
                }
                if (accountTypes.includes("RECEIVER") === true) {
                    companyDataObj.isReceiver = true;
                } else {
                    companyDataObj.isReceiver = false;
                }
                if (accountTypes.includes("SHIPPER") === true) {
                    companyDataObj.isShipper = true;
                } else {
                    setShipperStepIsComplete(true);
                    companyDataObj.isShipper = false;
                }

                if (accountRegistrationId !== undefined && accountRegistrationId !== null) {
                    getAccountRegistration(accountRegistrationId).then(accountRegistration => {
                        //console.log(accountRegistration);
                        if (accountRegistration !== null && accountRegistration !== undefined) {

                            let companyDataAccountRegistrationObj = {
                                name: accountRegistration.name,
                                email: accountRegistration.email,
                                phone: accountRegistration.phone,
                                phoneExt: accountRegistration.phoneExt,
                                afterHoursPhone: accountRegistration.afterHoursPhone,
                                afterHoursPhoneExt: accountRegistration.afterHoursPhoneExt,
                                fax: accountRegistration.fax,
                                ein: accountRegistration.ein,
                                pointOfContact: accountRegistration.pointOfContact,
                                companyAddress: accountRegistration.companyAddress
                            };

                            setCompanyData({ ...companyDataObj, ...companyDataAccountRegistrationObj });

                            if (accountRegistration.carrier !== undefined && accountRegistration.carrier !== null) {
                                setCarrierData({
                                    mcNum: accountRegistration.carrier.mcNum,
                                    dotNum: accountRegistration.carrier.dotNum,
                                    preferredPaymentMethod: accountRegistration.carrier.preferredPaymentMethod,
                                    accountsReceivable: accountRegistration.carrier.accountsReceivable,
                                    shippingAddress: accountRegistration.carrier.shippingAddress
                                });
                            }

                            setIsStepsLoading(false);
                        } else {
                            setCompanyData({ ...companyDataObj });
                            setIsStepsLoading(false);
                        }
                    }).catch(err => {
                        console.log(err);
                        setCompanyData({ ...companyDataObj });
                        setIsStepsLoading(false);
                    });
                } else {
                    setCompanyData({ ...companyDataObj });
                    setIsStepsLoading(false);
                }
            }
        }

        return () => {
            console.log("Cleaned up useEffect");
        };
    }, [accountRegistrationId, accountTypes, companyData]);

    useEffect(() => {
        // clear any previous errors if this is a new account creation
        dispatch(actionCreators.addAccountLoadingClear());
        dispatch(actionCreators.addAccountErrorClear());

        return () => {
            console.log("Cleaned up useEffect");
        };
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && addRecordId !== undefined && addRecordId !== null) {
            done(addRecordId);
        }
    }, [isLoading, error, addRecordId]);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={((isLoading === true && error === null) || isStepsLoading === true)}>
            <Card title="New Account" bordered={true} style={borderColor} size="small">
                <Row gutter={[4, 4]}>
                    <Col span={14}>
                        {steps && steps.length > 0 ? (
                            <>
                                <Collapse activeKey={(current !== null && current < steps.length) ? steps[current].title : null}>
                                    {steps.map((item, index) => (
                                        <Panel
                                            header={'Step ' + (index + 1) + ': ' + item.longTitle}
                                            key={item.title}
                                            extra={item.isNextStepAvailable ? (<EditOutlined
                                                onClick={(event) => {
                                                    // If you don't want click extra trigger collapse, you can prevent this:
                                                    onChangeStep(index);
                                                    event.stopPropagation();
                                                }} />) : null}
                                            disabled={!item.isNextStepAvailable}
                                            showArrow={false}>
                                            {item.instructions ? (<Alert
                                                message=""
                                                description={item.instructions}
                                                type="info"
                                                style={{ marginBottom: 10 }}
                                                showIcon
                                            />) : null}
                                            {(current !== null && current < steps.length) ? <div>{steps[current].content}</div> : null}
                                        </Panel>
                                    ))}
                                </Collapse>
                            </>
                        ) : null}
                    </Col>
                    <Col span={10}>
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Affix offsetTop={10}>
                                    <Card title="Account Summary" size="small" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
                                        {companyData !== null && !isEmpty(companyData) ? (
                                            <>
                                                <h3 style={{ textAlign: 'center' }}>Company Details</h3>
                                                {companyData.name !== undefined && companyData.name !== null ? <DataRow title="Company Name" value={companyData.name} dataType="String" /> : null}
                                                {companyData.ein !== undefined && companyData.ein !== null ? <DataRow title="EIN" value={companyData.ein} dataType="String" /> : null}
                                                {companyData.email !== undefined && companyData.email !== null ? <DataRow title="Company Email Address" value={companyData.email} dataType="String" /> : null}
                                                {companyData.phone !== undefined && companyData.phone !== null ? <DataPhoneNumberRow title="Company Day Time Phone #" phone={companyData.phone} phoneExt={companyData.phoneExt} /> : null}
                                                {companyData.afterHoursPhone !== undefined && companyData.afterHoursPhone !== null ? <DataPhoneNumberRow title="Company After Hours Phone #" phone={companyData.afterHoursPhone} phoneExt={companyData.afterHoursPhoneExt} /> : null}
                                                {companyData.fax !== undefined && companyData.fax !== null ? <DataRow title="Company Fax Number" value={companyData.fax} dataType="PhoneNumber" /> : null}
                                                {companyData.pointOfContact !== undefined && companyData.pointOfContact !== null ? <DataContactInfoRow title="Company Main Point of Contact" contact={companyData.pointOfContact} /> : null}
                                                {companyData.companyAddress !== undefined && companyData.companyAddress !== null ? <DataAddressRow title="Company Main Physical Address" address={companyData.companyAddress} /> : null}
                                                {companyData.shippingAddress !== undefined && companyData.shippingAddress !== null ? <DataAddressRow title="Company Mailing Address" address={companyData.shippingAddress} /> : null}
                                            </>
                                        ) : null}
                                        {shipperData !== null && !isEmpty(shipperData) ? (
                                            <>
                                                <hr />
                                                <h3 style={{ textAlign: 'center' }}>Shipper Details</h3>
                                                {shipperData.email !== undefined && shipperData.email !== null ? <DataRow title="Email Address" value={shipperData.email} dataType="String" /> : null}
                                                {shipperData.phone !== undefined && shipperData.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={shipperData.phone} phoneExt={shipperData.phoneExt} /> : null}
                                                {shipperData.fax !== undefined && shipperData.fax !== null ? <DataRow title="Fax Number" value={shipperData.fax} dataType="PhoneNumber" /> : null}
                                                {shipperData.dbNum !== undefined && shipperData.dbNum !== null ? <DataRow title="D&B #" value={shipperData.dbNum} dataType="String" /> : null}
                                                {shipperData.blueBookNum !== undefined && shipperData.blueBookNum !== null ? <DataRow title="Produce Blue Book #" value={shipperData.blueBookNum} dataType="String" /> : null}
                                                {shipperData.pointOfContact !== undefined && shipperData.pointOfContact !== null ? <DataContactInfoRow title="Shipper Operations Point of Contact" contact={shipperData.pointOfContact} /> : null}
                                                {shipperData.accountsPayable !== undefined && shipperData.accountsPayable !== null ? <DataContactInfoRow title="Contact Information for Accounts Payable" contact={shipperData.accountsPayable} /> : null}
                                                {shipperData.billingAddress !== undefined && shipperData.billingAddress !== null ? <DataAddressRow title="Accounts Payable Address" address={shipperData.billingAddress} /> : null}
                                            </>
                                        ) : null}
                                        {carrierData !== null && !isEmpty(carrierData) ? (
                                            <>
                                                <hr />
                                                <h3 style={{ textAlign: 'center' }}>Carrier Details</h3>
                                                {carrierData.email !== undefined && carrierData.email !== null ? <DataRow title="Email Address" value={carrierData.email} dataType="String" /> : null}
                                                {carrierData.phone !== undefined && carrierData.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={carrierData.phone} phoneExt={carrierData.phoneExt} /> : null}
                                                {carrierData.fax !== undefined && carrierData.fax !== null ? <DataRow title="Fax Number" value={carrierData.fax} dataType="PhoneNumber" /> : null}
                                                {carrierData.dotNum !== undefined && carrierData.dotNum !== null ? <DataRow title="DOT #" value={carrierData.dotNum} dataType="String" /> : null}
                                                {carrierData.mcNum !== undefined && carrierData.mcNum !== null ? <DataRow title="MC #" value={carrierData.mcNum} dataType="String" /> : null}
                                                {carrierData.preferredPaymentMethod !== undefined && carrierData.preferredPaymentMethod !== null ? <DataRow title="Preferred Payment Method" value={carrierData.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                                                {carrierData.pointOfContact !== undefined && carrierData.pointOfContact !== null ? <DataContactInfoRow title="Carrier Operations Point of Contact" contact={carrierData.pointOfContact} /> : null}
                                                {carrierData.accountsReceivable !== undefined && carrierData.accountsReceivable !== null ? <DataContactInfoRow title="Contact Information for Accounts Receivable" contact={carrierData.accountsReceivable} /> : null}
                                                {carrierData.shippingAddress !== undefined && carrierData.shippingAddress !== null ? <DataAddressRow title="Accounts Receivable Address" address={carrierData.shippingAddress} /> : null}
                                                {carrierData.smsBasic !== undefined && carrierData.smsBasic !== null ? <DataSMSBasicRow title="SMS Basic Scores" smsBasic={carrierData.smsBasic} /> : null}
                                            </>
                                        ) : null}
                                        {error && <Alert message={`${error}`} type="error" />}

                                    </Card>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            width: '100%',
                                            borderTop: '1px solid #e9e9e9',
                                            padding: '10px 16px',
                                            background: '#fff',
                                            textAlign: 'right',
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Popconfirm
                                            title="Are you sure you want to cancel the creation of this account? You will lose your changes."
                                            onConfirm={(e) => { confirmCancel(e); }}
                                            onCancel={(e) => { cancelCancel(e); }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="danger" style={{ marginRight: 8 }}>Cancel Account Creation</Button>
                                        </Popconfirm>
                                        <Button type="primary" htmlType="submit" disabled={carrierStepIsComplete === false || shipperStepIsComplete === false || companyStepIsComplete === false}>Create Account</Button>
                                    </div>
                                </Affix>
                            </Form>
                        </FormProvider>
                    </Col>
                </Row>
            </Card>
        </Spin>
    );
};

export default withRouter(NewAccount);