import React, { useMemo } from 'react';
import { Alert, Input, Select, Spin, Checkbox, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';

const { Option, OptGroup } = Select;

const EditLineItemType = props => {
    const lineItemType = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.lineItemTypes.isRecordUpdateLoading);
    const error = useSelector(state => state.lineItemTypes.updateRecordError);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);

    const lineItemTypeOptions = Enums.LineItemTypes.selectListOptions();
    const lineItemRuleOptions = Enums.LineItemRules.selectListOptions();
    const accountTypeOptions = Enums.AccountTypes.selectListOptions();
    const currencyOptions = Enums.Currencies.selectListOptions();
    const accessorialOptions = Enums.Accessorials.selectListOptions();
    const stopFeeOptions = Enums.StopFees.selectListOptions();
    const loadRequirementOptions = Enums.LoadRequirements.selectListOptions();
    const loadFeeOptions = Enums.LoadFees.selectListOptions();
    const irisFeeOptions = Enums.IRISFees.selectListOptions();
    const equipmentSpecificOptions = Enums.EquipmentSpecifics.selectListOptions();
    const adjustmentOptions = Enums.Adjustments.selectListOptions();

    const onSubmit = (data) => {
        let payload = {};
        let objectToUpdate = { ...lineItemType };
        for (let [key, value] of Object.entries(data)) {
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value !== oldValue) {
                //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                payload[key] = value;
            }
        }

        if (!isEmpty(payload)) {
            dispatch(actionCreators.updateLineItemType(lineItemType.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLineItemType());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLineItemTypeErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name" required
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select the Name"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                <OptGroup label="Accessorials">
                                    {accessorialOptions}
                                </OptGroup>
                                <OptGroup label="Stop Fees">
                                    {stopFeeOptions}
                                </OptGroup>
                                <OptGroup label="Load Requirements">
                                    {loadRequirementOptions}
                                </OptGroup>
                                <OptGroup label="Equipment Specifics">
                                    {equipmentSpecificOptions}
                                </OptGroup>
                                <OptGroup label="Load Fees">
                                    {loadFeeOptions}
                                </OptGroup>
                                <OptGroup label="IRIS Fees">
                                    {irisFeeOptions}
                                </OptGroup>
                                <OptGroup label="Adjustments">
                                    {adjustmentOptions}
                                </OptGroup>
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name="name"
                        defaultValue={lineItemType.name}
                    />
                    <FormItem {...formItemLayout} label="Description"
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" />}
                        rules={{ required: false }}
                        name="description"
                        defaultValue={lineItemType.description}
                    />
                    <FormItem {...formItemLayout} label="Type" required
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select the Type"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {lineItemTypeOptions}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        defaultValue={lineItemType.itemType}
                        name="itemType"
                    />
                    <FormItem {...formItemLayout} label="Trailer Types"
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select the Trailer Type(s)"
                                mode="multiple"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={lineItemType.trailerTypeIds}
                        name="trailerTypeIds"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label1="Up-Charge Amount"
                        render1={({ onChange, onBlur, value, name }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Up-Charge Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                            />
                        )}
                        rules1={{ required: false }}
                        name1="upCharge"
                        defaultValue1={lineItemType.upCharge}
                        render2={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: false }}
                        defaultValue2={lineItemType.upChargeUnit}
                        name2="upChargeUnit"
                    />
                    <FormItem {...formItemLayout} label="Up-Charge Rule"
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select the Up-Charge Rule"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {lineItemRuleOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={lineItemType.upChargeRule}
                        name="upChargeRule"
                    />
                    <FormItem {...formItemLayout} label="Up-Charge To"
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please select who the Up-Charge Goes To"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {accountTypeOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={lineItemType.upChargeTo}
                        name="upChargeTo"
                    />
                    <FormItemDouble {...formItemLayoutDouble} label="Cost Amount"
                        render1={({ onChange, onBlur, value, name }) => (
                            <InputNumber
                                min={0}
                                precision={2}
                                placeholder="Cost Amount"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={e => { onChange(e); }}
                                value={value}
                                name={name}
                            />
                        )}
                        rules1={{ required: false }}
                        name1="cost"
                        defaultValue1={lineItemType.cost}
                        render2={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {currencyOptions}
                            </Select>
                        )}
                        rules2={{ required: false }}
                        defaultValue2={lineItemType.costUnit}
                        name2="costUnit"
                    />
                    <FormItem {...formItemLayout} label="Cost Rule"
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select the Cost Rule"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {lineItemRuleOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={lineItemType.costRule}
                        name="costRule"
                    />
                    <FormItem {...formItemLayout} label="Cost To"
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please select who the Cost Goes To"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {accountTypeOptions}
                            </Select>
                        )}
                        rules={{ required: false }}
                        defaultValue={lineItemType.costTo}
                        name="costTo"
                    />
                    <FormItem {...formItemLayout} label="Is Open Amount"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                name={name}
                            />
                        )}
                        name="isOpen"
                        defaultValue={lineItemType.isOpen !== undefined ? lineItemType.isOpen : false}
                    />
                    <FormItem {...formItemLayout} label="Is Required"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => onChange(e.target.checked)}
                                checked={value}
                                name={name}
                            />
                        )}
                        name="isRequired"
                        defaultValue={lineItemType.isRequired !== undefined ? lineItemType.isRequired : false}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Line Item Type" />
            </Form>
        </FormProvider>
    );
};

export default EditLineItemType;