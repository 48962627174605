import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';

const RemoveInvoiceLineItem = props => {
    const invoiceLineItem = props.invoiceLineItem;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.invoiceLineItems.isRecordRemoveLoading);
    const error = useSelector(state => state.invoiceLineItems.removeRecordError);

    const onSubmit = (data) => {
        dispatch(actionCreators.removeInvoiceLineItem(invoiceLineItem.id, invoiceLineItem.loadId));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelRemoveInvoiceLineItem());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.removeInvoiceLineItemErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Delete Invoice Line Item</legend>
                    <FormItemDisplay {...formItemLayout} label="Delete Invoice Line Item">
                        <span>Are you sure you want to Delete this Invoice Line Item? This action cannot be undone.</span>
                    </FormItemDisplay>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Delete Invoice Line Item" />
        </Form>
    );
};

export default RemoveInvoiceLineItem;