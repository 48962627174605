import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {isEmpty} from "lodash";
import MomentDate from "../../shared/dateFormatter";
import {addFile} from "../../api/fileClient";
import {
    addInvoiceLineItems,
    deleteInvoiceLineItem,
    removeInvoiceLineItems,
    updateInvoiceLineItem
} from "./invoiceLineItems";
import {fetchLoadSuccess, loadEventUpdateTimeout} from "./loads";
import logger from "../../shared/logger";
import Enums from "../../shared/enums";
import Pricing from "../../shared/pricing";

//#region Load Stops Functions

export const fetchLoadStopsStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_STOPS_START
    }
};

export const fetchLoadStopsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_STOPS_SUCCESS,
        payload: payload
    }
};

export const fetchLoadStopsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_STOPS_FAIL,
        payload: payload
    }
};

export const clearLoadStops = () => {
    return {
        type: actionTypes.CLEAR_LOAD_STOPS
    }
};

export const addLoadStopStart = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_START
    }
};

export const addLoadStopSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_SUCCESS
    }
};

export const addLoadStopFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_STOP_FAIL,
        payload: payload
    }
};

export const addLoadStopErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_ERROR_CLEAR
    }
};

export const addLoadStopLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_STOP_LOADING_CLEAR
    }
};

export const updateLoadStopStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_START
    }
};

export const updateLoadStopSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_SUCCESS
    }
};

export const updateLoadStopFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_FAIL,
        payload: payload
    }
};

export const updateLoadStopErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_ERROR_CLEAR
    }
};

export const updateLoadStopLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP_LOADING_CLEAR
    }
};

export const cancelAddLoadStop = () => {
    return {
        type: actionTypes.CANCEL_ADD_LOAD_STOP
    }
};

export const cancelUpdateLoadStop = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOAD_STOP
    }
};

export const insertLoadStop = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_STOP,
        payload: payload
    }
};

export const changeLoadStop = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_STOP,
        payload: payload
    }
};

export const changeSingleLoadStop = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_STOP,
        payload: payload
    }
};

export const deleteLoadStop = (payload) => {
    return {
        type: actionTypes.REMOVE_LOAD_STOP,
        payload: payload
    }
};

//#endregion

//#region Load Stops Methods

export const fetchLoadStops = (loadId) => {
    return async (dispatch) => {
        const loadStopsPath = '/stops';
        try {
            dispatch(fetchLoadStopsStart());

            const loadStopsRes = await axiosAuthenticated.get(loadStopsPath, {
                params: {
                    page: 1,
                    size: 100000,
                    sort: 'sequence',
                    order: 'asc',
                    loadId: loadId,
                    isDeleted: false
                }
            });
            if (loadStopsRes && loadStopsRes.status === 200) {
                const loadStops = loadStopsRes.data.data;

                let locationIds = [];
                let locations = [];
                if (loadStops !== undefined && loadStops !== null && loadStops.length > 0) {
                    loadStops.forEach((stop) => {
                        if (!locationIds.includes(stop.stopLocationId)) {
                            locationIds.push(stop.stopLocationId);
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                const transformedLoadStops = loadStops.map((item) => {
                    let location = null;
                    if (item.stopLocationId !== undefined && item.stopLocationId !== null) {
                        location = locations.find(i => i.id === item.stopLocationId);
                    }

                    return {
                        ...item,
                        stopLocation: location
                    };
                });

                dispatch(fetchLoadStopsSuccess({records: transformedLoadStops}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadStopsFail({error: error.message}));
        }
    }
};

export const addLoadStop = (payload, docFiles, load) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadStopsPath = '/stops';
        const loadEventsPath = '/loadEvents';
        try {
            if (load !== undefined && load !== null && load.id !== undefined && load.id !== null) {
                dispatch(addLoadStopStart());

                // First, create the LoadStop
                // Backend: The stop order will inject this Stop as that number Stop in the trip sequence. You cannot inject a Stop before any Completed Stops for a Load that is in progress.
                let stopPayload = createLoadStopRequest(payload, load.id);

                const stopsRes = await axiosAuthenticated.post(loadStopsPath, stopPayload);
                if (stopsRes && stopsRes.status === 201) {
                    let stop = stopsRes.data;

                    // Second, if the stop has sequence 0 and stopType is PICK_UP, then we have a new pickUpDateTime for the load so update the load too
                    if (stop.sequence === 0 && stop.stopType === "PICK_UP") {
                        let pickUpDateTime = stop.requestedDateTime;
                        await axiosAuthenticated.put(loadsPath + `/${load.id}`, {pickUpDateTime: pickUpDateTime});
                    }

                    // Third, Add BOL Documents
                    if (docFiles !== undefined && docFiles !== null && docFiles.length > 0) {
                        for (let i = 0; i < docFiles.length; i++) {
                            await addFile(docFiles[i].file, "STOP", stop.id, "BOL", `BOL #${stop.bolNumber} - ${i + 1}`, "", "ALL");
                        }
                    }

                    // Fourth, Add Invoice Line Items if hasLumperFee === true or hasDriverAssist === true
                    if (stop.hasLumperFee === true || stop.hasDriverAssist === true) {
                        let newInvoiceLineItems = createInvoiceLineItemsRequest(stop.id, stop, stopPayload, load);
                        dispatch(addInvoiceLineItems(newInvoiceLineItems, load.id));
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'STOPS_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }

                    dispatch(addLoadStopSuccess());
                    dispatch(addLoadStopLoadingClear());
                    dispatch(addLoadStopErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadStopFail({error: error.message}));
        }
    }
};

export const updateLoadStop = (stopId, payload, originalStop, load) => {
    return async (dispatch) => {
        const loadStopsPath = '/stops';
        const loadsPath = '/loads';
        const invoiceLineItemPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            if (load !== undefined && load !== null && load.id !== undefined && load.id !== null) {
                dispatch(updateLoadStopStart());

                let timeZone = originalStop.timeZone ? originalStop.timeZone : null;

                let stopPayload = updateLoadStopRequest(payload, timeZone);

                const stopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, {...stopPayload});
                if (stopsRes && stopsRes.status === 200) {
                    let stop = stopsRes.data;

                    // if the stop has sequence 0 and stopType is PICK_UP, then we have a new pickUpDateTime for the load so update the load too
                    if (stop.sequence === 0 && stop.stopType === "PICK_UP") {
                        let pickUpDateTime = stop.requestedDateTime;

                        await axiosAuthenticated.put(loadsPath + `/${load.id}`, {pickUpDateTime: pickUpDateTime});
                    }

                    // get invoicelineitems for existing stop
                    let existingInvoiceLineItems = [];
                    const invoiceLineItemRes = await axiosAuthenticated.get(invoiceLineItemPath, {
                        params: {
                            page: 1,
                            size: 100000,
                            loadId: load.id,
                            stopId: stopId,
                            isDeleted: false
                        }
                    });
                    if (invoiceLineItemRes && invoiceLineItemRes.status === 200) {
                        existingInvoiceLineItems = invoiceLineItemRes.data.data;

                        // add/update/remove driver assist invoice line items
                        if (stop.hasDriverAssist === true) {
                            let driverAssistInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "DRIVER_ASSIST");
                            if (driverAssistInvoiceLineItems !== undefined && driverAssistInvoiceLineItems !== null && driverAssistInvoiceLineItems.length > 0) {
                                // check to see if the driver assist invoice line items need to be updated
                                driverAssistInvoiceLineItems.forEach((driverAssistInvoiceLineItem) => {
                                    if (driverAssistInvoiceLineItem.baseAmount !== stop.driverAssist) {
                                        dispatch(updateInvoiceLineItem(driverAssistInvoiceLineItem.id, {
                                            baseAmount: Number(stop.driverAssist),
                                            totalAmount: Number(stop.driverAssist)
                                        }, load.id));
                                    }
                                });
                            } else {
                                // add invoicelineitems for driver assist
                                let req = createDriverAssistInvoiceLineItemsRequest(stop.id, stop, originalStop, load);
                                dispatch(addInvoiceLineItems(req, load.id));
                            }
                        } else {
                            // check to see if invoice line items already exist, and if so remove them since they are no longer needed
                            let driverAssistInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "DRIVER_ASSIST");
                            if (driverAssistInvoiceLineItems !== undefined && driverAssistInvoiceLineItems !== null && driverAssistInvoiceLineItems.length > 0) {
                                dispatch(removeInvoiceLineItems(driverAssistInvoiceLineItems.map(i => i.id), load.id));
                            }
                        }

                        // add/update/remove lumper fee invoice line items
                        if (stop.hasLumperFee === true) {
                            let lumperFeeInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "LUMPER_FEE");
                            if (lumperFeeInvoiceLineItems !== undefined && lumperFeeInvoiceLineItems !== null && lumperFeeInvoiceLineItems.length > 0) {
                                // check to see if the lumper fee invoice line items need to be updated
                                lumperFeeInvoiceLineItems.forEach((lumperFeeInvoiceLineItem) => {
                                    if (lumperFeeInvoiceLineItem.baseAmount !== stop.lumperFee) {
                                        dispatch(updateInvoiceLineItem(lumperFeeInvoiceLineItem.id, {
                                            baseAmount: Number(stop.lumperFee),
                                            totalAmount: Number(stop.lumperFee)
                                        }, load.id));
                                    }
                                });
                            } else {
                                // add invoicelineitems for lumper fee
                                let req = createLumperFeeInvoiceLineItemsRequest(stop.id, stop, originalStop, load);
                                dispatch(addInvoiceLineItems(req, load.id));
                            }
                        } else {
                            // check to see if invoice line items already exist, and if so remove them since they are no longer needed
                            let lumperFeeInvoiceLineItems = existingInvoiceLineItems.filter(i => i.itemType === "LUMPER_FEE");
                            if (lumperFeeInvoiceLineItems !== undefined && lumperFeeInvoiceLineItems !== null && lumperFeeInvoiceLineItems.length > 0) {
                                dispatch(removeInvoiceLineItems(lumperFeeInvoiceLineItems.map(i => i.id), load.id));
                            }
                        }
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'STOPS_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }

                    dispatch(updateLoadStopSuccess());
                    dispatch(updateLoadStopLoadingClear());
                    dispatch(updateLoadStopErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadStopFail({error: error.message}));
        }
    }
};

export const updateLoadStopApptDetails = (stopId, loadId, payload, timeZone) => {
    return async (dispatch) => {
        const loadStopsPath = '/stops';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStopStart());

            let stopPayload = updateLoadStopRequest(payload, timeZone);

            const stopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, {...stopPayload});
            if (stopsRes && stopsRes.status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'STOPS_UPDATED'
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadStopSuccess());
                dispatch(updateLoadStopLoadingClear());
                dispatch(updateLoadStopErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadStopFail({error: error.message}));
        }
    }
};

export const removeLoadStop = (stopId, loadId) => {
    return async (dispatch, getState) => {
        const loadStopsPath = '/stops';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStopStart());

            const stopsRes = await axiosAuthenticated.put(loadStopsPath + `/${stopId}`, {isDeleted: true,});
            if (stopsRes && stopsRes.status === 200) {
                // backend will remove all invoice line items for that stop
                // remove invoice line items for that stop from front end and recalculate the pricing on the load

                const state = getState();
                const invoiceLineItemsState = {...state.invoiceLineItems};
                const transactionsState = {...state.transactions};
                let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                let updatedTransactions = [...transactionsState.records];

                let invoiceLineItemsToRemove = [];
                updatedInvoiceLineItems.forEach((updatedInvoiceLineItem) => {
                    if (updatedInvoiceLineItem.stopId !== undefined && updatedInvoiceLineItem.stopId !== null && updatedInvoiceLineItem.stopId === stopId) {
                        invoiceLineItemsToRemove.push(updatedInvoiceLineItem);
                        dispatch(deleteInvoiceLineItem(updatedInvoiceLineItem));
                    }
                });

                if (invoiceLineItemsToRemove.length > 0) {
                    invoiceLineItemsToRemove.forEach((updatedInvoiceLineItem) => {
                        const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                        if (index !== -1) {
                            // returns the deleted items
                            updatedInvoiceLineItems.splice(index, 1);
                        }
                    });

                    await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                }

                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'STOPS_UPDATED'
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadStopSuccess());
                dispatch(updateLoadStopLoadingClear());
                dispatch(updateLoadStopErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadStopFail({error: error.message}));
        }
    }
};

//#endregion
//#region helper api calls

export const updateLoadPricing = async (loadId, invoiceLineItems, transactions) => {
    try {
        let filteredInvoiceLineItems = invoiceLineItems.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);
        let filteredTransactions = transactions.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);

        let loadSummary = Pricing.loadPricingSummary(filteredInvoiceLineItems, filteredTransactions);

        let updatedLoadPayload = {};

        if (loadSummary.shipperRatePerMileAmount !== undefined && loadSummary.shipperRatePerMileAmount !== null) {
            updatedLoadPayload.shipperRatePerMile = loadSummary.shipperRatePerMileAmount;
            updatedLoadPayload.shipperRatePerMileUnit = 'USD';
        }
        if (loadSummary.shipperAmountWithAddOns !== undefined && loadSummary.shipperAmountWithAddOns !== null) {
            updatedLoadPayload.shipperAmount = loadSummary.shipperAmountWithAddOns;
            updatedLoadPayload.shipperAmountUnit = 'USD';
        }
        if (loadSummary.shipperBalance !== undefined && loadSummary.shipperBalance !== null) {
            updatedLoadPayload.shipperBalance = loadSummary.shipperBalance;
            updatedLoadPayload.shipperBalanceUnit = 'USD';
        }
        if (loadSummary.carrierRatePerMileAmount !== undefined && loadSummary.carrierRatePerMileAmount !== null) {
            updatedLoadPayload.carrierRatePerMile = loadSummary.carrierRatePerMileAmount;
            updatedLoadPayload.carrierRatePerMileUnit = 'USD';
        }
        if (loadSummary.carrierAmountWithAddOns !== undefined && loadSummary.carrierAmountWithAddOns !== null) {
            updatedLoadPayload.carrierAmount = loadSummary.carrierAmountWithAddOns;
            updatedLoadPayload.carrierAmountUnit = 'USD';
        }
        if (loadSummary.carrierBalance !== undefined && loadSummary.carrierBalance !== null) {
            updatedLoadPayload.carrierBalance = loadSummary.carrierBalance;
            updatedLoadPayload.carrierBalanceUnit = 'USD';
        }

        const loadsRes = await axiosAuthenticated.put('/loads' + `/${loadId}`, {...updatedLoadPayload});
        if (loadsRes && loadsRes.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);

        return false;
    }
};

//#endregion
//#region Helper Methods

const createLoadStopRequest = (payload, loadId) => {
    let momentDateUtil = new MomentDate();

    let stop = {
        unitOfMeasure: "ENGLISH",
        loadId: loadId
    };

    let timeZone = null;
    if (payload.stopLocation !== undefined && payload.stopLocation !== null) {
        if (payload.stopLocation.latitude !== undefined && payload.stopLocation.latitude !== null) {
            stop.latitude = payload.stopLocation.latitude;
        }

        if (payload.stopLocation.longitude !== undefined && payload.stopLocation.longitude !== null) {
            stop.longitude = payload.stopLocation.longitude;
        }

        if (payload.stopLocation.timeZone !== undefined && payload.stopLocation.timeZone !== null) {
            stop.timeZone = payload.stopLocation.timeZone;
            timeZone = payload.stopLocation.timeZone;
        }
    }

    if (payload.stopType !== undefined && payload.stopType !== null) {
        stop.stopType = payload.stopType;
    }

    if (payload.sequence !== undefined && payload.sequence !== null) {
        stop.sequence = payload.sequence;
    }

    if (payload.stopLocationId !== undefined && payload.stopLocationId !== null) {
        stop.stopLocationId = payload.stopLocationId;
    }

    if (payload.latitude === undefined || payload.latitude === null) {
        if (payload.stopLocation !== undefined && payload.stopLocation !== null) {
            stop.latitude = payload.stopLocation.latitude;
        }
    } else {
        stop.latitude = payload.latitude;
    }

    if (payload.longitude === undefined || payload.longitude === null) {
        if (payload.stopLocation !== undefined && payload.stopLocation !== null) {
            stop.longitude = payload.stopLocation.longitude;
        }
    } else {
        stop.longitude = payload.longitude;
    }

    if (payload.bolNumber !== undefined && payload.bolNumber !== null) {
        stop.bolNumber = payload.bolNumber;
    }

    if (payload.hasDriverAssist === true) {
        stop.hasDriverAssist = true;
    } else {
        stop.hasDriverAssist = false;
    }

    if (payload.hasDriverAssist === true && payload.driverAssist !== undefined && payload.driverAssist !== null && payload.driverAssist > 0) {
        stop.driverAssist = Number(payload.driverAssist);
        stop.driverAssistUnit = 'USD';
    } else {
        stop.driverAssist = 0;
        stop.driverAssistUnit = 'USD';
    }

    if (payload.hasLumperFee === true) {
        stop.hasLumperFee = true;
    } else {
        stop.hasLumperFee = false;
    }

    if (payload.hasLumperFee === true && payload.lumperFee !== undefined && payload.lumperFee !== null && payload.lumperFee > 0) {
        stop.lumperFee = Number(payload.lumperFee);
        stop.lumperFeeUnit = 'USD';
    } else {
        stop.lumperFee = 0;
        stop.lumperFeeUnit = 'USD';
    }

    if (payload.loadingType !== undefined && payload.loadingType !== null) {
        stop.loadingType = payload.loadingType;
    }

    if (payload.purchaseOrderNumber !== undefined && payload.purchaseOrderNumber !== null) {
        stop.purchaseOrderNumber = payload.purchaseOrderNumber;
    }

    if (payload.pickUpNumber !== undefined && payload.pickUpNumber !== null) {
        stop.pickUpNumber = payload.pickUpNumber;
    }

    if (payload.dropOffNumber !== undefined && payload.dropOffNumber !== null) {
        stop.dropOffNumber = payload.dropOffNumber;
    }

    if (payload.specialInstructions !== undefined && payload.specialInstructions !== null) {
        stop.specialInstructions = payload.specialInstructions;
    }

    if (payload.apptInstructions !== undefined && payload.apptInstructions !== null) {
        stop.apptInstructions = payload.apptInstructions;
    }

    if (payload.apptNumber !== undefined && payload.apptNumber !== null) {
        stop.apptNumber = payload.apptNumber;
    }

    if (payload.apptType !== undefined && payload.apptType !== null) {
        stop.apptType = payload.apptType;
    }

    if (payload.apptWindowStartDateTime !== undefined && payload.apptWindowStartDateTime !== null) {
        let momentDate = payload.apptWindowStartDateTime;
        stop.apptWindowStartDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.apptWindowEndDateTime !== undefined && payload.apptWindowEndDateTime !== null) {
        let momentDate = payload.apptWindowEndDateTime;
        stop.apptWindowEndDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.requestedDateTime !== undefined && payload.requestedDateTime !== null) {
        let momentDate = payload.requestedDateTime;
        stop.requestedDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.apptCallAheadDateTime !== undefined && payload.apptCallAheadDateTime !== null) {
        let momentDate = payload.apptCallAheadDateTime;
        stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.apptDateTime !== undefined && payload.apptDateTime !== null) {
        let momentDate = payload.apptDateTime;
        stop.apptDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.apptPointOfContact !== undefined && payload.apptPointOfContact !== null) {
        let apptPointOfContact = {};
        let apptPointOfContactData = payload.apptPointOfContact;

        if (apptPointOfContactData.name !== undefined && apptPointOfContactData.name !== null) {
            apptPointOfContact.name = apptPointOfContactData.name;
        }

        if (apptPointOfContactData.email !== undefined && apptPointOfContactData.email !== null) {
            apptPointOfContact.email = apptPointOfContactData.email;
        }

        if (apptPointOfContactData.phone !== undefined && apptPointOfContactData.phone !== null) {
            apptPointOfContact.phone = apptPointOfContactData.phone;
        }

        if (apptPointOfContactData.phoneExt !== undefined && apptPointOfContactData.phoneExt !== null) {
            apptPointOfContact.phoneExt = apptPointOfContactData.phoneExt;
        }

        if (!isEmpty(apptPointOfContact)) {
            stop.apptPointOfContact = apptPointOfContact;
        }
    }

    if (payload.commodities !== undefined && payload.commodities !== null) {
        let commodities = [];
        let commoditiesData = payload.commodities;
        commoditiesData.forEach(commodityData => {
            let commodity = {};

            if (commodityData.commodityId !== undefined && commodityData.commodityId !== null) {
                commodity.commodityId = commodityData.commodityId;
            }

            if (commodityData.name !== undefined && commodityData.name !== null) {
                commodity.name = commodityData.name;
            }

            if (commodityData.unitPackaging !== undefined && commodityData.unitPackaging !== null) {
                commodity.unitPackaging = commodityData.unitPackaging;
            }

            if (commodityData.unitPackagingInitCount !== undefined && commodityData.unitPackagingInitCount !== null) {
                commodity.unitPackagingInitCount = Number(commodityData.unitPackagingInitCount);
            }

            if (commodityData.unitPackagingActualCount !== undefined && commodityData.unitPackagingActualCount !== null) {
                commodity.unitPackagingActualCount = Number(commodityData.unitPackagingActualCount);
            }

            if (commodityData.bulkPackaging !== undefined && commodityData.bulkPackaging !== null) {
                commodity.bulkPackaging = commodityData.bulkPackaging
            }

            if (commodityData.bulkPackagingInitCount !== undefined && commodityData.bulkPackagingInitCount !== null) {
                commodity.bulkPackagingInitCount = Number(commodityData.bulkPackagingInitCount);
            }

            if (commodityData.bulkPackagingActualCount !== undefined && commodityData.bulkPackagingActualCount !== null) {
                commodity.bulkPackagingActualCount = Number(commodityData.bulkPackagingActualCount);
            }

            if (!isEmpty(commodity)) {
                commodities.push(commodity);
            }
        });

        if (!isEmpty(commodities)) {
            stop.commodities = commodities;
        }
    }

    return stop;
};

const updateLoadStopRequest = (payload, timeZone) => {
    let momentDateUtil = new MomentDate();

    let stop = {};

    if (payload.stopType !== undefined && payload.stopType !== null) {
        stop.stopType = payload.stopType;
    }

    if (payload.sequence !== undefined && payload.sequence !== null) {
        stop.sequence = payload.sequence;
    }

    if (payload.bolNumber !== undefined && payload.bolNumber !== null) {
        stop.bolNumber = payload.bolNumber;
    }

    if (payload.hasDriverAssist === true) {
        stop.hasDriverAssist = true;
    } else {
        stop.hasDriverAssist = false;
    }

    if (payload.hasDriverAssist === true && payload.driverAssist !== undefined && payload.driverAssist !== null && payload.driverAssist > 0) {
        stop.driverAssist = Number(payload.driverAssist);
        stop.driverAssistUnit = 'USD';
    } else {
        stop.driverAssist = 0;
        stop.driverAssistUnit = 'USD';
    }

    if (payload.hasLumperFee === true) {
        stop.hasLumperFee = true;
    } else {
        stop.hasLumperFee = false;
    }

    if (payload.hasLumperFee === true && payload.lumperFee !== undefined && payload.lumperFee !== null && payload.lumperFee > 0) {
        stop.lumperFee = Number(payload.lumperFee);
        stop.lumperFeeUnit = 'USD';
    } else {
        stop.lumperFee = 0;
        stop.lumperFeeUnit = 'USD';
    }

    if (payload.loadingType !== undefined && payload.loadingType !== null) {
        stop.loadingType = payload.loadingType;
    }

    if (payload.purchaseOrderNumber !== undefined && payload.purchaseOrderNumber !== null) {
        stop.purchaseOrderNumber = payload.purchaseOrderNumber;
    }

    if (payload.pickUpNumber !== undefined && payload.pickUpNumber !== null) {
        stop.pickUpNumber = payload.pickUpNumber;
    }

    if (payload.dropOffNumber !== undefined && payload.dropOffNumber !== null) {
        stop.dropOffNumber = payload.dropOffNumber;
    }

    if (payload.specialInstructions !== undefined && payload.specialInstructions !== null) {
        stop.specialInstructions = payload.specialInstructions;
    }

    if (payload.apptInstructions !== undefined && payload.apptInstructions !== null) {
        stop.apptInstructions = payload.apptInstructions;
    }

    if (payload.apptType !== undefined && payload.apptType !== null) {
        stop.apptType = payload.apptType;
        if (payload.apptType === 'FIRST_COME_FIRST_SERVE') {
            if (payload.apptWindowStartDateTime !== undefined && payload.apptWindowStartDateTime !== null) {
                let momentDate = payload.apptWindowStartDateTime;
                stop.apptWindowStartDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            if (payload.apptWindowEndDateTime !== undefined && payload.apptWindowEndDateTime !== null) {
                let momentDate = payload.apptWindowEndDateTime;
                stop.apptWindowEndDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptDateTime = null;
            stop.apptNumber = null;
            stop.apptCallAheadDateTime = null;
        } else if (payload.apptType === 'HAVE_APPOINTMENT') {
            if (payload.apptNumber !== undefined && payload.apptNumber !== null) {
                stop.apptNumber = payload.apptNumber;
            }

            if (payload.apptCallAheadDateTime !== undefined && payload.apptCallAheadDateTime !== null) {
                let momentDate = payload.apptCallAheadDateTime;
                stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            if (payload.apptDateTime !== undefined && payload.apptDateTime !== null) {
                let momentDate = payload.apptDateTime;
                stop.apptDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptWindowStartDateTime = null;
            stop.apptWindowEndDateTime = null;
        } else if (payload.apptType === 'NEED_APPOINTMENT') {
            if (payload.apptCallAheadDateTime !== undefined && payload.apptCallAheadDateTime !== null) {
                let momentDate = payload.apptCallAheadDateTime;
                stop.apptCallAheadDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
            }

            stop.apptDateTime = null;
            stop.apptNumber = null;
            stop.apptWindowStartDateTime = null;
            stop.apptWindowEndDateTime = null;
        }
    }

    if (payload.requestedDateTime !== undefined && payload.requestedDateTime !== null) {
        let momentDate = payload.requestedDateTime;
        stop.requestedDateTime = momentDateUtil.asTimeZoneUtcISOString(momentDate, timeZone);
    }

    if (payload.apptPointOfContact !== undefined && payload.apptPointOfContact !== null) {
        let apptPointOfContact = {};
        let apptPointOfContactData = payload.apptPointOfContact;

        if (apptPointOfContactData.name !== undefined && apptPointOfContactData.name !== null) {
            apptPointOfContact.name = apptPointOfContactData.name;
        }

        if (apptPointOfContactData.email !== undefined && apptPointOfContactData.email !== null) {
            apptPointOfContact.email = apptPointOfContactData.email;
        }

        if (apptPointOfContactData.phone !== undefined && apptPointOfContactData.phone !== null) {
            apptPointOfContact.phone = apptPointOfContactData.phone;
        }

        if (apptPointOfContactData.phoneExt !== undefined && apptPointOfContactData.phoneExt !== null) {
            apptPointOfContact.phoneExt = apptPointOfContactData.phoneExt;
        }

        if (!isEmpty(apptPointOfContact)) {
            stop.apptPointOfContact = apptPointOfContact;
        }
    }

    if (payload.commodities !== undefined && payload.commodities !== null) {
        let commodities = [];
        let commoditiesData = payload.commodities;
        commoditiesData.forEach(commodityData => {
            let commodity = {};

            if (commodityData.commodityId !== undefined && commodityData.commodityId !== null) {
                commodity.commodityId = commodityData.commodityId;
            }

            if (commodityData.name !== undefined && commodityData.name !== null) {
                commodity.name = commodityData.name;
            }

            if (commodityData.unitPackaging !== undefined && commodityData.unitPackaging !== null) {
                commodity.unitPackaging = commodityData.unitPackaging;
            }

            if (commodityData.unitPackagingInitCount !== undefined && commodityData.unitPackagingInitCount !== null) {
                commodity.unitPackagingInitCount = Number(commodityData.unitPackagingInitCount);
            }

            if (commodityData.unitPackagingActualCount !== undefined && commodityData.unitPackagingActualCount !== null) {
                commodity.unitPackagingActualCount = Number(commodityData.unitPackagingActualCount);
            }

            if (commodityData.bulkPackaging !== undefined && commodityData.bulkPackaging !== null) {
                commodity.bulkPackaging = commodityData.bulkPackaging
            }

            if (commodityData.bulkPackagingInitCount !== undefined && commodityData.bulkPackagingInitCount !== null) {
                commodity.bulkPackagingInitCount = Number(commodityData.bulkPackagingInitCount);
            }

            if (commodityData.bulkPackagingActualCount !== undefined && commodityData.bulkPackagingActualCount !== null) {
                commodity.bulkPackagingActualCount = Number(commodityData.bulkPackagingActualCount);
            }

            if (!isEmpty(commodity)) {
                // only want to add commodities to the stop if there are initial quantities provided for that commodity
                if (commodity.unitPackagingInitCount !== undefined && commodity.bulkPackagingInitCount !== undefined) {
                    commodities.push(commodity);
                }
            }
        });

        if (!isEmpty(commodities)) {
            stop.commodities = commodities;
        }
    }

    return stop;
};

const createDriverAssistInvoiceLineItemsRequest = (stopId, stop, originalStop, load) => {
    let newInvoiceLineItems = [];
    let shipperId = load.shipperId ? load.shipperId : null;
    let carrierId = load.assignedCarrierId ? load.assignedCarrierId : null;

    if (stop.hasDriverAssist === true) {
        let itemType = 'DRIVER_ASSIST';
        if (stop.stopStatus !== 'PENDING') {
            itemType = 'DRIVER_ASSIST_ACCESSORIAL';
        }

        let baseAmount = 0;
        if (stop.driverAssist !== undefined && stop.driverAssist !== null && stop.driverAssist > 0) {
            baseAmount = parseFloat(stop.driverAssist);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + originalStop.stopLocation.name,
            loadId: load.id,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'STAFF'
            });
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'STAFF',
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        }
    }

    return newInvoiceLineItems;
};

const createLumperFeeInvoiceLineItemsRequest = (stopId, stop, originalStop, load) => {
    let newInvoiceLineItems = [];
    let shipperId = load.shipperId ? load.shipperId : null;
    let carrierId = load.assignedCarrierId ? load.assignedCarrierId : null;

    if (stop.hasLumperFee === true) {
        let itemType = 'LUMPER_FEE';
        if (stop.stopStatus !== 'PENDING') {
            itemType = 'LUMPER_FEE_ACCESSORIAL';
        }

        let baseAmount = 0;
        if (stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0) {
            baseAmount = parseFloat(stop.lumperFee);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + originalStop.stopLocation.name,
            loadId: load.id,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'STAFF'
            });
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'STAFF',
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        }
    }

    return newInvoiceLineItems;
};

const createInvoiceLineItemsRequest = (stopId, stop, originalStop, load) => {
    let newInvoiceLineItems = [];
    let shipperId = load.shipperId ? load.shipperId : null;
    let carrierId = load.assignedCarrierId ? load.assignedCarrierId : null;

    // for adding new stops, create the invoice line items as accessorials if the load is no longer in the pending status
    if (stop.hasDriverAssist === true) {
        let itemType = 'DRIVER_ASSIST';

        let baseAmount = 0;
        if (stop.driverAssist !== undefined && stop.driverAssist !== null && stop.driverAssist > 0) {
            baseAmount = parseFloat(stop.driverAssist);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + originalStop.stopLocation.name,
            loadId: load.id,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'STAFF'
            });
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'STAFF',
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        }
    }

    if (stop.hasLumperFee === true) {
        let itemType = 'LUMPER_FEE';

        let baseAmount = 0;
        if (stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0) {
            baseAmount = parseFloat(stop.lumperFee);
        }
        let quantity = 1;
        let totalAmount = baseAmount * quantity;

        let newInvoiceLineItem = {
            description: Enums.LineItemNames.getValueByName(itemType) + ' for ' + originalStop.stopLocation.name,
            loadId: load.id,
            stopId: stopId,
            itemType: itemType,
            isIrisFee: false,
            baseAmount: baseAmount.toFixed(2),
            baseAmountUnit: 'USD',
            quantity: quantity,
            quantityUnit: 'PER_STOP',
            totalAmount: totalAmount.toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: false,
        };

        if (load.serviceType === 'TMS') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        } else if (load.serviceType === 'BROKERAGE') {
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'SHIPPER',
                fromEntityId: shipperId,
                toEntityType: 'STAFF'
            });
            newInvoiceLineItems.push({
                ...newInvoiceLineItem,
                fromEntityType: 'STAFF',
                toEntityType: 'CARRIER',
                toEntityId: carrierId
            });
        }
    }

    return newInvoiceLineItems;
};

//#endregion