import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { isEmpty } from "lodash";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';

const ResolveNote = props => {
    const note = props.note;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.notes.isRecordUpdateLoading);
    const error = useSelector(state => state.notes.updateRecordError);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        cleanedData.isResolved = true;
        
        if (!isEmpty(cleanedData)) {
            dispatch(actionCreators.updateNote(note.id, cleanedData));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateNote());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateNoteErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Resolve Note</legend>
                    <b>Are you sure you want to Resolve the Note?</b>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Resolve Note" />
        </Form>
    );
};

export default ResolveNote;