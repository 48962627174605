import React, { useState } from 'react';
import { Drawer } from "antd";
import EditLoadRepresentative from "../../components/EditLoadRepresentative/EditLoadRepresentative";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import { useSelector } from "react-redux";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataNameRow from '../DataNameRow/DataNameRow';

const LoadRepresentativeCard = props => {
    const fullWidth = global.window.innerWidth;

    const load = props.load;

    const staff = useSelector(state => state.orchestrator.staff);
    const entityType = useSelector(state => state.auth.entityType);

    const [showEditEntity, setShowEditEntity] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const editEntityComponents = (
        <Drawer
            title={"Change Representative(s) for Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadRepresentative cancel={toggleEditEntity} load={load} users={staff} />
        </Drawer>
    );

    return (
        <>
            <div>
                <b>Account Representative(s):</b>
                {(entityType === "STAFF") ? (
                    <AddEditChangeRemoveButtons
                        showAdd={false} addText='' addAction={null}
                        showEdit={false} editText='' editAction={null}
                        showChange={load && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'CANCELLED' ? true : false} changeText={"Change Representative(s) for Load"} changeAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
                        showRemove={false} removeText='' removeAction={null}
                    />
                ) : null}
            </div>
            {load.assignedAccountRepUsers && load.assignedAccountRepUsers.length > 0 ? load.assignedAccountRepUsers.map((assignedAccountRepUser, index) => {
                return (
                    <div key={`accountrep-${index}`}>
                        <DataNameRow firstName={assignedAccountRepUser.firstName} lastName={assignedAccountRepUser.lastName} />
                        {assignedAccountRepUser.phone !== undefined && assignedAccountRepUser.phone !== null ? <DataPhoneNumberRow phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} /> : null}
                    </div>
                );
            }) : (<div><i>No Account Representatives Assigned</i></div>)}
            {editEntityComponents}
        </>
    );
};

export default LoadRepresentativeCard;