
const calculateCounts = (documents, entityId, entityType, entitySubType, account = null, needsCarrierWashoutLog = false) => {
    //console.log(`documents: ${JSON.stringify(documents)}`);
    let pendingApprovalDocumentsList = [];
    let missingDocumentsList = [];
    let expiringSoonDocumentsList = [];
    let expiredDocumentsList = [];
    if (entityType !== undefined && entityType !== null && documents !== undefined && documents !== null) {
        if (entityType === 'DRIVER') {
            documents.forEach((item) => {
                if ((!item.isReviewed) && !item.isExpired) {
                    if (item.documentType === 'MSHA_LICENSE_FRONT' ||
                        item.documentType === 'MSHA_LICENSE_BACK' ||
                        item.documentType === 'EXPLOSIVE_CERTIFIED_LICENSE_FRONT' ||
                        item.documentType === 'EXPLOSIVE_CERTIFIED_LICENSE_BACK' ||
                        item.documentType === 'MC_CERTIFICATE_LICENSE_FRONT' ||
                        item.documentType === 'MC_CERTIFICATE_LICENSE_BACK' ||
                        item.documentType === 'PNEUMATIC_LICENSE_FRONT' ||
                        item.documentType === 'PNEUMATIC_LICENSE_BACK' ||
                        item.documentType === 'COMMERCIAL_DRIVERS_LICENSE_FRONT' ||
                        item.documentType === 'COMMERCIAL_DRIVERS_LICENSE_BACK' ||
                        item.documentType === 'DRIVERS_LICENSE_FRONT' ||
                        item.documentType === 'DRIVERS_LICENSE_BACK' ||
                        item.documentType === 'HAZMAT_LICENSE_FRONT' ||
                        item.documentType === 'HAZMAT_LICENSE_BACK') {
                        pendingApprovalDocumentsList.push(item);
                    }
                }
            });

            // check for missing documents that are required for drivers
            if (documents.find(item => item.documentType === 'COMMERCIAL_DRIVERS_LICENSE_FRONT') === undefined) {
                missingDocumentsList.push('Front of the Commercial Drivers License');
            }
            if (documents.find(item => item.documentType === 'COMMERCIAL_DRIVERS_LICENSE_BACK') === undefined) {
                missingDocumentsList.push('Back of the Commercial Drivers License');
            }
        } else if (entityType === 'ACCOUNT') {
            if (account !== undefined && account !== null) {
                if (account.isShipper === true) {
                    documents.forEach((item) => {
                        if ((!item.isReviewed) && !item.isExpired) {
                            if (item.documentType === 'SHIPPER_W9' ||
                                item.documentType === 'SHIPPER_INSURANCE') {
                                pendingApprovalDocumentsList.push(item);
                            }
                        }
                    });

                    // check for missing documents that are required for shipper
                    // if (documents.find(item => item.documentType === 'SHIPPER_W9') === undefined) {
                    //     missingDocumentsList.push('Shipper W9 Form');
                    // }
                    // if (documents.find(item => item.documentType === 'SHIPPER_INSURANCE') === undefined) {
                    //     missingDocumentsList.push('Shipper Insurance');
                    // }
                }

                if (account.isCarrier === true) {
                    documents.forEach((item) => {
                        if ((!item.isReviewed) && !item.isExpired) {
                            if (item.documentType === 'CARRIER_W9' ||
                                item.documentType === 'CARRIER_INSURANCE') {
                                pendingApprovalDocumentsList.push(item);
                            }
                        }
                    });

                    // check for missing documents that are required for carrier
                    if (documents.find(item => item.documentType === 'CARRIER_W9') === undefined) {
                        missingDocumentsList.push('Carrier W9 Form');
                    }
                    if (documents.find(item => item.documentType === 'CARRIER_INSURANCE') === undefined) {
                        missingDocumentsList.push('Carrier Insurance');
                    }
                }

                if (account.isReceiver === true) {
                    documents.forEach((item) => {
                        if ((!item.isReviewed) && !item.isExpired) {
                            if (item.documentType === 'RECEIVER_W9' ||
                                item.documentType === 'RECEIVER_INSURANCE') {
                                pendingApprovalDocumentsList.push(item);
                            }
                        }
                    });

                    // check for missing documents that are required for receiver
                    // if (documents.find(item => item.documentType === 'RECEIVER_W9') === undefined) {
                    //     missingDocumentsList.push('Receiver W9 Form');
                    // }
                    // if (documents.find(item => item.documentType === 'RECEIVER_INSURANCE') === undefined) {
                    //     missingDocumentsList.push('Receiver Insurance');
                    // }
                }

                if (account.isProducer === true) {
                    documents.forEach((item) => {
                        if ((!item.isReviewed) && !item.isExpired) {
                            if (item.documentType === 'PRODUCER_W9' ||
                                item.documentType === 'PRODUCER_INSURANCE') {
                                pendingApprovalDocumentsList.push(item);
                            }
                        }
                    });

                    // check for missing documents that are required for producer
                    // if (documents.find(item => item.documentType === 'PRODUCER_W9') === undefined) {
                    //     missingDocumentsList.push('Producer W9 Form');
                    // }
                    // if (documents.find(item => item.documentType === 'PRODUCER_INSURANCE') === undefined) {
                    //     missingDocumentsList.push('Producer Insurance');
                    // }
                }
            }
        } else if (entityType === 'STOP') {
            documents.forEach((item) => {
                if ((!item.isReviewed) && !item.isExpired) {
                    if (item.documentType === 'BOL' ||
                        item.documentType === 'LUMPER_FEE_RECEIPT' ||
                        item.documentType === 'SCALE_TICKET' ||
                        item.documentType === 'TRAILER_CONTROL_FILE' ||
                        item.documentType === 'FUEL_RECEIPT' ||
                        item.documentType === 'TOLL_RECEIPT' ||
                        item.documentType === 'RECEIPT' ||
                        item.documentType === 'OTHER') {
                        pendingApprovalDocumentsList.push(item);
                    }

                }
            });

            // check for missing documents that are required for stops
            if (entitySubType === 'PICK_UP') {
                if (documents.find(item => item.documentType === 'BOL') === undefined) {
                    missingDocumentsList.push('BOL');
                }
                if (documents.find(item => item.documentType === 'SCALE_TICKET') === undefined) {
                    missingDocumentsList.push('Scale Ticket');
                }
            } else if (entitySubType === 'DROP_OFF') {
                if (documents.find(item => item.documentType === 'BOL') === undefined) {
                    missingDocumentsList.push('BOL');
                }
            }
        } else if (entityType === 'LOAD') {
            documents.forEach((item) => {
                if ((!item.isReviewed) && !item.isExpired) {
                    if (item.documentType === 'CARRIER_WASHOUT_LOG') {
                        pendingApprovalDocumentsList.push(item);
                    }

                }
            });

            // check for missing documents that are required for loads
            if (documents.find(item => item.documentType === 'CARRIER_WASHOUT_LOG') === undefined && needsCarrierWashoutLog === true) {
                missingDocumentsList.push('Carrier Washout Log');
            }
        } else if (entityType === 'CLAIM') {
            documents.forEach((item) => {
                if ((!item.isReviewed) && !item.isExpired) {
                    if (item.documentType === 'CLAIM') {
                        pendingApprovalDocumentsList.push(item);
                    }

                }
            });
        }

        documents.forEach((doc) => {
            if (doc.isExpired === true) {
                if (documents.find(d => d.documentType === doc.documentType && d.isExpired === false) === undefined) {
                    expiredDocumentsList.push(doc);
                }
            }
            if (doc.isExpiringSoon === true) {
                expiringSoonDocumentsList.push(doc);
            }
        });
    }

    return {
        entityId,
        entityType,
        entitySubType,
        pendingApprovalDocumentsList,
        missingDocumentsList,
        expiringSoonDocumentsList,
        expiredDocumentsList
    };
};

const getVisibleDocuments = (documents, userEntityType, isAdmin) => {
    let filteredDocuments = [];
    if (documents !== undefined && documents !== null && documents.length > 0) {
        if (userEntityType === "STAFF" && isAdmin === true) {
            filteredDocuments = documents.filter(doc => doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "STAFF" && isAdmin === false) {
            filteredDocuments = documents.filter(doc => doc.visibleTo !== "ADMIN" && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "DRIVER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "DRIVER") && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "CARRIER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "DRIVER" || doc.visibleTo === "CARRIER") && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "PRODUCER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "PRODUCER") && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "RECEIVER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "RECEIVER") && doc.isExpired === false && doc.isDeleted === false);
        } else if (userEntityType === "SHIPPER") {
            filteredDocuments = documents.filter(doc => (doc.visibleTo === "ALL" || doc.visibleTo === "SHIPPER") && doc.isExpired === false && doc.isDeleted === false);
        }
    }

    return filteredDocuments;
};

export default {
    calculateCounts,
    getVisibleDocuments
}