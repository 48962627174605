import React, { useMemo, useState } from 'react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Select, Spin, Checkbox, Tooltip, InputNumber, Drawer, Row, Col } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import Form from '../Form/Form';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import DataRow from '../DataRow/DataRow';
import StringFormatter from '../../shared/stringFormatter';
import MomentDate from '../../shared/dateFormatter';
import TimePicker from '../TimePicker/TimePicker';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const NewLocation = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.locations.isRecordAddLoading);
    const error = useSelector(state => state.locations.addRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const states = useSelector(state => state.orchestrator.states);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const locationTypeOptions = Enums.LocationTypes.selectListOptions();
    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const currencyOptions = Enums.Currencies.selectListOptions();
    const dayOfWeekOptions = Enums.DayOfWeek.selectListOptions();
    const timeZoneOptions = Enums.USATimeZones.selectListOptions();

    const [dockHours, setDockHours] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [shipperId, setShipperId] = useState(null);
    const [showAddNewDockHours, setShowAddNewDockHours] = useState(false);
    const [endTimeBeginLimit, setEndTimeBeginLimit] = useState(null);

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            setShipperId(accountId);
        }
        const filteredAccounts = accounts.filter(a => a.isShipper === true);
        setShippers(filteredAccounts);
    }, [accountId, accounts]);

    const onChangeAccountId = (value) => {
        //console.log(`selected ${value}`);
        setShipperId(value);
    };

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (dockHours !== undefined && dockHours !== null && dockHours.length > 0) {
            let newDockHours = [];
            dockHours.forEach((hours) => {
                let newDockHour = {
                    dayOfWeek: hours.dayOfWeek
                };

                if (hours.startTime !== undefined && hours.startTime !== null) {
                    let startTime = moment(hours.startTime, 'HH:mm').set({ 'year': 1900, 'month': 0, 'date': 1 });
                    newDockHour.startTime = new MomentDate().asTimeZoneUtcISOString(startTime, 'UTC');
                }
                if (hours.endTime !== undefined && hours.endTime !== null) {
                    let endTime = moment(hours.endTime, 'HH:mm').set({ 'year': 1900, 'month': 0, 'date': 1 });
                    newDockHour.endTime = new MomentDate().asTimeZoneUtcISOString(endTime, 'UTC');
                }

                newDockHours.push(newDockHour);
            });
            cleanedData.dockHours = [...newDockHours];
        }

        //console.log(cleanedData);

        if (!isEmpty(cleanedData)) {
            cleanedData.accountId = shipperId;
            dispatch(actionCreators.addLocation(cleanedData));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddLocation());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLocationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);


    const addDockHours = (data) => {
        //console.log(data);
        let newDockHours = [];
        if (data.dayOfWeek !== undefined && data.dayOfWeek !== null && data.dayOfWeek.length > 0) {
            data.dayOfWeek.forEach((day) => {
                newDockHours.push({
                    startTime: data.startTime,
                    endTime: data.endTime,
                    dayOfWeek: day
                });
            });

            //console.log(newDockHours);
            setDockHours(oldList => [...oldList, ...newDockHours]);
            setShowAddNewDockHours(false);
        }
    };

    const removeDockHours = (index) => {
        let updatedDockHours = [...dockHours];
        updatedDockHours.splice(index, 1);
        setDockHours([...updatedDockHours]);
    };

    const toggleNewDockHours = () => {
        setEndTimeBeginLimit(null);
        setShowAddNewDockHours(!showAddNewDockHours);
    };

    const startTimeOnChange = ([event]) => {
        //console.log(event.target.value);
        let beginLimit = moment(event.target.value, 'HH:mm').add(15, 'minutes').format('HH:mm');
        setEndTimeBeginLimit(beginLimit);

        return event.target.value;
    };

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {(accountId === undefined || accountId === null) &&
                            <Fieldset legend="Choose a Shipper">
                                <FormItem {...formItemLayout} label="Shipper" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Shipper"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeAccountId(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                        >
                                            {shippers.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="accountId"
                                />
                            </Fieldset>
                        }
                        <Fieldset>
                            <legend>Location Details</legend>
                            <FormItem {...formItemLayout} label="Name" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                rules={{ required: "Required Field" }}
                                name="name"
                            />
                            <FormItem {...formItemLayout} label="Location Type(s)" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select the Location Type"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {locationTypeOptions}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="locationTypes"
                            />
                            <FormItem {...formItemLayout} label="Appointment Type" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select the Appointment Type"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {appointmentTypeOptions}
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="appointmentType"
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Lumper Fee" format="horizontal"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="lumperFee"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="lumperFeeUnit"
                            />
                            <FormItem {...formItemLayout} label="Special Instructions" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        {/*
                        <Fieldset>
                            <legend>Location Ammenities</legend>
                            <FormItem {...formItemLayout} label="Has Restrooms" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isRestroomAvailable"
                                defaultValue={false}
                            />
                            <FormItem {...formItemLayout} label="Has Driver Rest Area" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isDriverRestAreaAvailable"
                                defaultValue={false}
                            />
                            <FormItem {...formItemLayout} label="Has Parking" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isParkingAvailable"
                                defaultValue={false}
                            />
                            <FormItem {...formItemLayout} label="Has Overnight Parking" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isOvernightParkingAvailable"
                                defaultValue={false}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Location Bids</legend>
                            <FormItem {...formItemLayout} label="Bids" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select the Bids"
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="commodityIds"
                            />
                        </Fieldset>
                        */}
                        <Fieldset>
                            <legend>Point of Contact</legend>
                            <FormItem {...formItemLayout} label="Name" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                rules={{ required: false }}
                                name="pointOfContact.name"
                            />
                            <FormItem {...formItemLayout} label="Email" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                rules={{
                                    required: false,
                                    pattern: {
                                        value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                        message: "Please enter a valid email address"
                                    }
                                }}
                                name="pointOfContact.email"
                                defaultValue={''}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                rules1={{
                                    required: false,
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Please enter a valid 10 digit phone number with no special characters"
                                    }
                                }}
                                name1="pointOfContact.phone"
                                defaultValue1={''}
                                render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                rules2={{ required: false }}
                                name2="pointOfContact.phoneExt"
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Location Address and Time Zone</legend>
                            <FormItem {...formItemLayout} label="Street Address 1" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                rules={{ required: "Required Field" }}
                                name="address.streetAddress1"
                            />
                            <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                rules={{ required: false }}
                                name="address.streetAddress2"
                            />
                            <FormItem {...formItemLayout} label="City" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                rules={{ required: "Required Field" }}
                                name="address.city"
                            />
                            <FormItem {...formItemLayout} label="State" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a State"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="address.state"
                            />
                            <FormItem {...formItemLayout} label="Postal Code" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                rules={{ required: "Required Field" }}
                                name="address.postalCode"
                            />
                            <FormItem {...formItemLayout} label="Country" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Country"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="USA" key="USA">United States</Option>
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="address.country"
                            />
                            <FormItem {...formItemLayout} label="Time Zone" format="horizontal" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select the Time Zone for this Location"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {timeZoneOptions}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="timeZone"
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Location Dock Hours (if applicable)</legend>
                            {dockHours.map((item, index) => (
                                <Row gutter={16} key={`dock-hours-${index}`}>
                                    <Col span={18}>
                                        <FormItemDisplay label={stringFormatter.toFormattedString('DayOfWeek', item.dayOfWeek, null)} format="horizontal">
                                            <DataRow value={item.startTime + ' - ' + item.endTime} dataType="String" />
                                        </FormItemDisplay>
                                    </Col>
                                    <Col span={6}>
                                        <Button icon={<DeleteOutlined />} onClick={() => { removeDockHours(index); }} />
                                    </Col>
                                </Row>
                            ))}
                            <Tooltip placement="left" title="Add Dock Hours">
                                <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewDockHours} style={{ margin: "10px", float: "right" }} />
                            </Tooltip>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Location" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Dock Hours'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewDockHours}
                visible={showAddNewDockHours}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(addDockHours)}>
                        <FormItemDouble {...formItemLayoutDouble} label1="Time Window" format="horizontal" required1 required2
                            render1={({ onChange, onBlur, value, name }) => (
                                <TimePicker
                                    placeholder="Start Time"
                                    step={15}
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(time) => { startTimeOnChange(time); onChange(time); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="startTime"
                            render2={({ onChange, onBlur, value, name }) => (
                                <TimePicker
                                    placeholder="End Time"
                                    beginLimit={endTimeBeginLimit}
                                    step={15}
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(time) => { onChange(time); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="endTime"
                        />
                        <FormItem {...formItemLayout} label="Day(s) of Week" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select the Day(s) of the Week"
                                    mode="multiple"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {dayOfWeekOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="dayOfWeek"
                        />
                        <FormButtons cancel={toggleNewDockHours} submitText="Add Dock Hours" />
                    </Form>
                </FormProvider>
            </Drawer>
        </>
    );
};

export default NewLocation;