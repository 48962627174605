import React, { useMemo } from 'react';
import { Spin, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { isEmpty } from "lodash";
import * as actionCreators from "../../store/actions";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const RemoveNote = props => {
    const note = props.note;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.notes.isRecordUpdateLoading);
    const error = useSelector(state => state.notes.updateRecordError);

    const onSubmit = () => {
        let payload = {};
        payload.isDeleted = true;
        payload.entityId = note.entityId;
        payload.entityType = note.entityType;

        if (!isEmpty(payload)) {
            dispatch(actionCreators.updateNote(note.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateNote());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateNoteErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Remove Note (this will delete the note from the system)</legend>
                    <div><b>Are you sure you want to Delete this Note? This cannot be undone.</b></div>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Delete Note" />
        </Form>
    );
};

export default RemoveNote;