import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Button } from "antd";
import Drivers from "../../views/Drivers/Drivers";
import Employees from "../../views/Employees/Employees";
import CompanyCarrierSnapshot from '../../components/CompanyCarrierSnapshot/CompanyCarrierSnapshot';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { useLocation } from 'react-router';
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import LoadRequests from '../LoadRequests/LoadRequests';
import Assets from '../../views/Assets/Assets';
import Dispatch from '../Dispatch/Dispatch';
import DocumentList from '../../components/DocumentList/DocumentList';
import DispatchNew from "../DispatchNew/DispatchNew";

const { TabPane } = Tabs;

const Carrier = props => {
    const carrierId = props.match.params.carrierId;

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordLoading);
    const error = useSelector(state => state.accounts.recordError);
    const account = useSelector(state => state.accounts.record);
    const entityType = useSelector(state => state.auth.entityType);

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAccount(carrierId));
    }, [carrierId]);

    useEffect(() => {
        if (account && account.logoFileId) {
            axiosAuthenticated.get('/documents/' + account.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [account]);

    let breadcrumbs = [
        {
            path: `/carriers`,
            breadcrumbName: 'Carriers',
        },
        {
            path: `/carriers/${carrierId ? carrierId : null}`,
            breadcrumbName: `${account ? account.name : ''}`,
        },
    ];

    if (account && account.id === carrierId) {
        return (
            <>
                <Header
                    title={account ? account.name : 'Loading...'}
                    title2={account ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                    breadcrumbs={breadcrumbs}
                    highlights={[
                        {
                            title: 'DOT #',
                            value: `${account && account.carrier && account.carrier.dotNum ? account.carrier.dotNum : 'N/A'}`
                        },
                        {
                            title: 'MC #',
                            value: `${account && account.carrier && account.carrier.mcNum ? account.carrier.mcNum : 'N/A'}`
                        },
                        {
                            title: 'SMS Score',
                            value: `${account && account.carrier && account.carrier.smsBasic && account.carrier.smsBasic.weightedAvgSMSScore ? account.carrier.smsBasic.weightedAvgSMSScore : 'N/A'}`
                        },
                    ]}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(account !== undefined && account !== null && isLoading === false) ? <CompanyCarrierSnapshot record={account} /> : null}
                            </Spin>
                        </div>
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <div style={{ padding: 24 }}>
                            {account && <DocumentList entityType="ACCOUNT" account={account} entityId={account.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
                            {account !== undefined && account !== null && account.isVerified === false && entityType === 'STAFF' ? (
                                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { console.log(account); verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab="Load Requests" key="loadRequests">
                        <div style={{ padding: 24 }}>
                            <LoadRequests carrierId={carrierId} shipperId={null} accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Dispatch" key="dispatch">
                        <DispatchNew carrierId={carrierId} breadcrumbs={breadcrumbs} />
                    </TabPane>
                    <TabPane tab="Booked Loads" key="bookedLoads">
                        <div style={{ padding: 24 }}>
                            <BookedLoads carrierId={carrierId} shipperId={null} accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="In Transit Loads" key="intransitLoads">
                        <div style={{ padding: 24 }}>
                            <InTransitLoads carrierId={carrierId} shipperId={null} accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Completed Loads" key="completedLoads">
                        <div style={{ padding: 24 }}>
                            <CompletedLoads carrierId={carrierId} shipperId={null} accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Lanes" key="lanes">
                        <div style={{ padding: 24 }}>
                            Under Contruction
                        </div>
                    </TabPane>
                    <TabPane tab="RFP's & Bids" key="rfps">
                        <div style={{ padding: 24 }}>
                            Under Contruction
                        </div>
                    </TabPane>
                    <TabPane tab="Assets" key="assets">
                        <div style={{ padding: 24 }}>
                            <Assets accountId={carrierId} />
                        </div>
                    </TabPane>
                    <TabPane tab="Drivers" key="drivers">
                        <div style={{ padding: 24 }}>
                            <Drivers accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Employees" key="employees">
                        <div style={{ padding: 24 }}>
                            <Employees accountId={carrierId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                </Tabs>
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Carrier);
