import React from 'react';
import { Card } from "antd";
import classes from './ComplexDataLinkCard.module.scss';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const ComplexDataLinkCard = props => {

    const createDataItems = () => {
        let dataItems = [];
        const entries = Object.entries(props.data);

        for (const [key, data] of entries) {
            if (data.url !== undefined && data.url !== null && data.value !== undefined && data.value !== null) {
                dataItems.push(
                    <div key={`data-link-${key}`}>
                        {key ? (<span style={props.titleStyle ? { fontWeight: 'bold', ...props.titleStyle } : { fontWeight: 'bold' }}>{key}: </span>) : null}
                        <span style={props.valueStyle}><a href={data.url} style={{ textDecoration: 'underline' }}>{stringFormatter.toFormattedString(data.dataType, data.value, data.units, data.timeZone !== null && data.timeZone !== undefined ? data.timeZone : null)}</a></span>
                    </div>
                );
            } else if (data.value !== undefined && data.value !== null) {
                dataItems.push(
                    <div key={`data-link-${key}`}>
                        {key ? (<span style={props.titleStyle ? { ...props.titleStyle } : { fontWeight: 'bold' }}>{key}: </span>) : null}
                        <span style={props.valueStyle}>{stringFormatter.toFormattedString(data.dataType, data.value, data.units, data.timeZone !== null && data.timeZone !== undefined ? data.timeZone : null)}</span>
                    </div>
                );
            }
        }

        return dataItems;
    };

    return (
        <Card bordered={true} title={props.title} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={props.extra}>
            {createDataItems()}
            {props.children}
        </Card>
    );
};

export default ComplexDataLinkCard;