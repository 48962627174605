import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Notifications Functions

export const fetchNotificationsStart = () => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_START
    }
};

export const fetchNotificationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchNotificationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_NOTIFICATIONS_FAIL,
        payload: payload
    }
};

export const clearNotifications = () => {
    return {
        type: actionTypes.CLEAR_NOTIFICATIONS
    }
};

export const addNotificationStart = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_START
    }
};

export const insertNotification = (payload) => {
    return {
        type: actionTypes.ADD_NOTIFICATION,
        payload: payload
    }
};

export const addNotificationSuccess = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_SUCCESS
    }
};

export const addNotificationFail = (payload) => {
    return {
        type: actionTypes.ADD_NOTIFICATION_FAIL,
        payload: payload
    }
};

export const addNotificationErrorClear = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_ERROR_CLEAR
    }
};

export const addNotificationLoadingClear = () => {
    return {
        type: actionTypes.ADD_NOTIFICATION_LOADING_CLEAR
    }
};

export const updateNotificationStart = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_START
    }
};

export const changeNotification = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION,
        payload: payload
    }
};

export const changeSingleNotification = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_NOTIFICATION,
        payload: payload
    }
};

export const updateNotificationSuccess = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_SUCCESS
    }
};

export const updateNotificationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_FAIL,
        payload: payload
    }
};

export const updateNotificationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_ERROR_CLEAR
    }
};

export const updateNotificationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_LOADING_CLEAR
    }
};

export const cancelAddNotification = () => {
    return {
        type: actionTypes.CANCEL_ADD_NOTIFICATION
    }
};

export const cancelUpdateNotification = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_NOTIFICATION
    }
};

//#endregion

//#region Notifications Methods

export const fetchNotifications = (entityType, entityId, userId) => {
    return async (dispatch) => {
        const notificationsPath = '/loadNotifications';
        try {
            if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
                dispatch(fetchNotificationsStart());

                let searchParams = {
                    sort: 'createdAt',
                    order: 'desc',
                    page: 1,
                    size: 10
                };

                if (entityType === "STAFF") {
                    searchParams.toStaff = true;
                } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                    searchParams.accountIds = entityId;
                } else if (entityType === "DRIVER") {
                    searchParams.userIds = userId;
                }

                const notificationsRes = await axiosAuthenticated.get(notificationsPath, {params: {...searchParams}});
                if (notificationsRes && notificationsRes.status === 200) {
                    const notificationsData = notificationsRes.data.data;
                    const otherData = notificationsRes.data;

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;

                    // const unreadCount = data.filter(n => {
                    //     return !n.read.some(r => { return r.readBy === userId; });
                    // }).length;

                    dispatch(fetchNotificationsSuccess({
                        records: notificationsData,
                        currentPage: searchParams.page,
                        totalPages: totalPages,
                        totalCount: totalCount,
                        unreadCount: 0,
                        pageSize: searchParams.size
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchNotificationsFail({error: error.message}));
        }
    }
};

export const fetchMoreNotifications = (entityType, entityId, userId) => {
    return async (dispatch, getState) => {
        const notificationsPath = '/loadNotifications';
        try {
            if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
                const state = getState();
                const notificationsState = {...state.notifications};
                let updatedNotifications = [...notificationsState.records];

                dispatch(fetchNotificationsStart());

                let searchParams = {
                    sort: 'createdAt',
                    order: 'desc',
                    page: notificationsState.currentPage + 1,
                    size: notificationsState.pageSize
                };

                if (entityType === "STAFF") {
                    searchParams.toStaff = true;
                } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                    searchParams.accountIds = entityId;
                } else if (entityType === "DRIVER") {
                    searchParams.userIds = userId;
                }

                const notificationsRes = await axiosAuthenticated.get(notificationsPath, {params: {...searchParams}});
                if (notificationsRes && notificationsRes.status === 200) {
                    const notificationsData = notificationsRes.data.data;
                    const otherData = notificationsRes.data;

                    notificationsData.forEach((item) => {
                        if (updatedNotifications.find(i => i.id === item.id) === undefined) {
                            updatedNotifications.push(item);
                        }
                    });

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;

                    // const unreadCount = data.filter(n => {
                    //     return !n.read.some(r => { return r.readBy === userId; });
                    // }).length;

                    dispatch(fetchNotificationsSuccess({
                        records: updatedNotifications,
                        currentPage: searchParams.page,
                        totalPages: totalPages,
                        totalCount: totalCount,
                        unreadCount: 0,
                        pageSize: searchParams.size
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchNotificationsFail({error: error.message}));
        }
    }
};

export const sendNotification = (subject, message, userId, entityType, entityId, loadId, loadIrisId, loadStatus, eventType, toStaff = false) => {
    return async (dispatch) => {
        const notificationsPath = '/loadNotifications';
        try {

            let newNotification = {
                message: message,
                loadId: loadId,
                loadIrisId: loadIrisId,
                saveOnly: false,
                subject: subject,
                severity: "CRITICAL",
                loadStatus: loadStatus,
                eventType: eventType,
                toStaff: toStaff
            };

            if (entityType === "DRIVER") {
                newNotification.userIds = [userId];
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
                newNotification.accountIds = [entityId];
            }

            const notificationsRes = await axiosAuthenticated.post(notificationsPath, newNotification);
            if (notificationsRes && notificationsRes.status === 201) {
                dispatch(insertNotification(notificationsRes.data));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const addNotification = (payload) => {
    return async (dispatch) => {
        dispatch(insertNotification(payload));
    }
};

export const updateNotification = (notificationId, payload) => {
    return async (dispatch) => {
        const notificationsPath = '/loadNotifications';
        try {
            dispatch(updateNotificationStart());

            const notificationsRes = await axiosAuthenticated.put(notificationsPath + `/${notificationId}`, {...payload});
            if (notificationsRes && notificationsRes.status === 200) {
                let updatedNotification = notificationsRes.data;

                dispatch(changeNotification(updatedNotification));
                dispatch(changeSingleNotification(updatedNotification));

                dispatch(updateNotificationSuccess());
                dispatch(updateNotificationErrorClear());
                dispatch(updateNotificationLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateNotificationFail({error: error.message}));
        }
    }
};

export const readNotification = (notificationId, userId) => {
    return async (dispatch, getState) => {
        const notificationsPath = '/loadNotifications';
        try {
            dispatch(updateNotificationStart());

            const state = getState();
            const notificationsState = {...state.notifications};
            const existingNotifications = [...notificationsState.records];

            let readRecord = {readAt: Date.now().toString(), readBy: userId};

            let recordToUpdate = existingNotifications.find(i => i.id === notificationId);
            if (recordToUpdate !== undefined && recordToUpdate !== null) {
                if (!recordToUpdate.read.some(item => {
                    return item.readBy === userId
                })) {
                    recordToUpdate.read.push(readRecord);

                    const notificationsRes = await axiosAuthenticated.put(notificationsPath + `/${notificationId}`, {'add_read': [readRecord]});
                    if (notificationsRes && notificationsRes.status === 200) {
                        const state2 = getState();
                        const notificationsState2 = {...state2.notifications};

                        let unreadCount = notificationsState2.unreadCount;
                        if (unreadCount > 0) {
                            unreadCount = unreadCount - 1;
                        }

                        dispatch(fetchNotificationsSuccess({unreadCount: unreadCount}));

                        dispatch(changeNotification(recordToUpdate));
                        dispatch(changeSingleNotification(recordToUpdate));

                        dispatch(updateNotificationSuccess());
                        dispatch(updateNotificationErrorClear());
                        dispatch(updateNotificationLoadingClear());
                    }
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateNotificationFail({error: error.message}));
        }
    }
};

export const readMultipleNotifications = (notificationIds, userId) => {
    return async (dispatch, getState) => {
        const notificationsPath = '/loadNotificationsReadAll';
        try {
            if (notificationIds !== undefined && notificationIds !== null && notificationIds.length > 0) {
                dispatch(updateNotificationStart());

                const notificationsRes = await axiosAuthenticated.post(notificationsPath, {ids: notificationIds});
                if (notificationsRes && notificationsRes.status === 204) {
                    const state = getState();
                    const notificationsState = {...state.notifications};
                    const existingNotifications = [...notificationsState.records];
                    let unreadCount = notificationsState.unreadCount;

                    if (unreadCount >= notificationIds.length) {
                        unreadCount = unreadCount - notificationIds.length;
                    } else {
                        unreadCount = 0;
                    }

                    dispatch(fetchNotificationsSuccess({unreadCount: unreadCount}));

                    notificationIds.forEach((notificationId) => {
                        let readRecord = {readAt: Date.now().toString(), readBy: userId};

                        let recordToUpdate = existingNotifications.find(i => i.id === notificationId);
                        if (recordToUpdate !== undefined && recordToUpdate !== null) {
                            if (!recordToUpdate.read.some(item => {
                                return item.readBy === userId
                            })) {
                                recordToUpdate.read.push(readRecord);

                                dispatch(changeNotification(recordToUpdate));
                                dispatch(changeSingleNotification(recordToUpdate));
                            }
                        }
                    });

                    dispatch(updateNotificationSuccess());
                    dispatch(updateNotificationErrorClear());
                    dispatch(updateNotificationLoadingClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateNotificationFail({error: error.message}));
        }
    }
};

//#endregion