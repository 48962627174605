import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { without } from 'lodash';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';

const RemoveLoadDriver = props => {
    const driver = props.driver;
    const load = props.load;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;
    
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const sendEmailToOldDriver = () => {
        if (driver !== undefined && driver !== null && driver.driver !== undefined && driver.driver !== null) {
            if (driver.driver.isApproved === undefined || driver.driver.isApproved === null || driver.driver.isApproved === false) {
                let firstName = driver.firstName;
                let email = driver.email;
                const { body, subject } = driverUnAssignedEmailTemplate(firstName, load.irisId)
                let newEmail = {
                    body: body,
                    subject: subject,
                    email: email
                };
                axiosAuthenticated.post('/email', newEmail).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };

    const sendNotificationToOldDriver = () => {
        if (driver !== undefined && driver !== null && driver.driver !== undefined && driver.driver !== null) {
            let firstName = driver.firstName;
            let userId = driver.userId;

            let newNotification = {
                message: `Hi ${firstName}, you are no longer assigned to load: ${load.irisId}.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been unassigned from a load`,
                severity: "CRITICAL",
                userIds: [userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_UNASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const onSubmit = () => {
        const updatedDriverIds = without(load.driverIds, driver.id);
        //console.log(updatedDriverIds);

        let payload = {
            driverIds: updatedDriverIds
        };
        //console.log(payload);
        
        if (isBundledLoad === true) {
            dispatch(actionCreators.removeDriverFromBundledLoad(load.id, payload, load, driver));
        } else {
            dispatch(actionCreators.removeDriverFromLoad(load.id, payload, load, driver));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    const done = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            done();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && driver !== undefined && driver !== null && load !== undefined && load !== null) {
            sendNotificationToOldDriver();
            sendEmailToOldDriver();

            if (load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP') {
                dispatch(actionCreators.markDriverAvailable(driver.id));
            }

            cancel();
        }
    }, [isLoading, error, driver, load]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Remove Driver from this Load</legend>
                    {driver !== undefined && driver !== null ? (
                        <FormItemDisplay {...formItemLayout} label="Remove Driver">
                            <span>Are you sure you want to remove this {driver.firstName} {driver.lastName} from the Load?</span>
                        </FormItemDisplay>
                    ) : null}
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Remove Driver from Load" />
        </Form>
    );
};

export default RemoveLoadDriver;