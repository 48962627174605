import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import {getCommodities} from "./orchestrator";

//#region Commodities Functions

export const fetchCommoditiesStart = () => {
    return {
        type: actionTypes.FETCH_COMMODITIES_START
    }
};

export const fetchCommoditiesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_COMMODITIES_SUCCESS,
        payload: payload
    }
};

export const fetchCommoditiesFail = (payload) => {
    return {
        type: actionTypes.FETCH_COMMODITIES_FAIL,
        payload: payload
    }
};

export const clearCommodities = () => {
    return {
        type: actionTypes.CLEAR_COMMODITIES
    }
};

export const addCommodityStart = () => {
    return {
        type: actionTypes.ADD_COMMODITY_START
    }
};

export const addCommoditySuccess = () => {
    return {
        type: actionTypes.ADD_COMMODITY_SUCCESS
    }
};

export const addCommodityFail = (payload) => {
    return {
        type: actionTypes.ADD_COMMODITY_FAIL,
        payload: payload
    }
};

export const addCommodityErrorClear = () => {
    return {
        type: actionTypes.ADD_COMMODITY_ERROR_CLEAR
    }
};

export const addCommodityLoadingClear = () => {
    return {
        type: actionTypes.ADD_COMMODITY_LOADING_CLEAR
    }
};

export const updateCommodityStart = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_START
    }
};

export const updateCommoditySuccess = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_SUCCESS
    }
};

export const updateCommodityFail = (payload) => {
    return {
        type: actionTypes.UPDATE_COMMODITY_FAIL,
        payload: payload
    }
};

export const updateCommodityErrorClear = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_ERROR_CLEAR
    }
};

export const updateCommodityLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_COMMODITY_LOADING_CLEAR
    }
};

export const cancelAddCommodity = () => {
    return {
        type: actionTypes.CANCEL_ADD_COMMODITY
    }
};

export const cancelUpdateCommodity = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_COMMODITY
    }
};

export const insertCommodity = (payload) => {
    return {
        type: actionTypes.ADD_COMMODITY,
        payload: payload
    }
};

export const changeCommodity = (payload) => {
    return {
        type: actionTypes.UPDATE_COMMODITY,
        payload: payload
    }
};

export const changeSingleCommodity = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_COMMODITY,
        payload: payload
    }
};

export const removeCommodity = (payload) => {
    return {
        type: actionTypes.REMOVE_COMMODITY,
        payload: payload
    }
};

//#endregion

//#region Commodities Methods

export const fetchCommodities = (payload) => {
    return async (dispatch, getState) => {
        const commoditiesPath = '/commodities';
        try {
            dispatch(fetchCommoditiesStart());

            const state = getState();
            const commoditiesState = {...state.commodities};
            let pagination = {...commoditiesState.pagination};
            let searchParams = {...commoditiesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const commoditiesRes = await axiosAuthenticated.get(commoditiesPath, {params: {...searchParams}});
            if (commoditiesRes && commoditiesRes.status === 200) {
                const commodities = commoditiesRes.data.data;

                // Read total count from server
                pagination.total = commoditiesRes.data.totalCount;
                pagination.current = commoditiesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchCommoditiesSuccess({
                    records: commodities,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchCommoditiesFail({error: error.message}));
        }

    }
};

export const addCommodity = (payload) => {
    return async (dispatch) => {
        const commoditiesPath = '/commodities';
        try {
            dispatch(addCommodityStart());

            const commoditiesRes = await axiosAuthenticated.post(commoditiesPath, {...payload});

            if (commoditiesRes && commoditiesRes.status === 201) {
                let newCommodity = commoditiesRes.data;

                dispatch(insertCommodity(newCommodity));

                dispatch(addCommoditySuccess());
                dispatch(addCommodityLoadingClear());
                dispatch(addCommodityErrorClear());
                dispatch(getCommodities(true));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addCommodityFail({error: error.message}));
        }
    }
};

export const updateCommodity = (commodityId, payload) => {
    return async (dispatch) => {
        const commoditiesPath = '/commodities';
        try {
            dispatch(updateCommodityStart());

            const commoditiesRes = await axiosAuthenticated.put(commoditiesPath + `/${commodityId}`, {...payload});

            if (commoditiesRes && commoditiesRes.status === 200) {
                let updatedCommodity = commoditiesRes.data;

                if (updatedCommodity.isDeleted === true) {
                    dispatch(removeCommodity(updatedCommodity));
                } else {
                    dispatch(changeCommodity(updatedCommodity));
                    dispatch(changeSingleCommodity(updatedCommodity));
                }

                // refresh orchestrator
                dispatch(getCommodities(true));

                dispatch(updateCommoditySuccess());
                dispatch(updateCommodityLoadingClear());
                dispatch(updateCommodityErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateCommodityFail({error: error.message}));
        }
    }
};

//#endregion