import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DocumentList from '../../components/DocumentList/DocumentList';
import NoteList from '../../components/NoteList/NoteList';
import { useSelector } from "react-redux";
import StringFormatter from '../../shared/stringFormatter';
import DataDateTimeRangeRow from '../DataDateTimeRangeRow/DataDateTimeRangeRow';
import DataTable from '../DataTable/DataTable';
import DataListRow from '../DataListRow/DataListRow';

const stringFormatter = new StringFormatter();

const LoadStop = props => {
    const stop = props.stop;
    const stopAddress = props.stop.stopLocation !== undefined && props.stop.stopLocation !== null ? (props.stop.stopLocation.address !== null && props.stop.stopLocation.address !== undefined ? props.stop.stopLocation.address : null) : null;
    const stopName = props.stop.stopLocation !== null && props.stop.stopLocation !== undefined ? props.stop.stopLocation.name : null;
    const commodities = props.stop.commodities !== null && props.stop.commodities !== undefined && props.stop.commodities.length > 0 ? props.stop.commodities : null;
    const showDocuments = props.showDocuments !== null && props.showDocuments !== undefined && props.showDocuments === true ? true : false;
    const showNotes = props.showNotes !== null && props.showNotes !== undefined && props.showNotes === true ? true : false;
    const loadId = props.stop.loadId;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];
    const notes = props.notes !== undefined && props.notes !== null ? props.notes : [];

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    const columns = [
        {
            title: 'Commodity',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Init Unit Quantity',
            dataIndex: 'unitPackagingInitCount',
            key: 'unitPackagingInitCount',
        },
        {
            title: 'Actual Unit Quantity',
            dataIndex: 'unitPackagingActualCount',
            key: 'unitPackagingActualCount',
        },
        {
            title: 'Unit of Measure/Packaging',
            dataIndex: 'unitPackaging',
            key: 'unitPackaging',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackaging", text, null); },
        },
        {
            title: 'Init Bulk Quantity',
            dataIndex: 'bulkPackagingInitCount',
            key: 'bulkPackagingInitCount',
        },
        {
            title: 'Actual Bulk Quantity',
            dataIndex: 'bulkPackagingActualCount',
            key: 'bulkPackagingActualCount',
        },
        {
            title: 'Bulk Packaging',
            dataIndex: 'bulkPackaging',
            key: 'bulkPackaging',
            render: (text, record) => { return stringFormatter.toFormattedString("BulkPackaging", text, null); },
        },
    ];

    return (
        <>
            {stopName !== undefined && stopName !== null ? (<DataRow title="Stop Name" value={stopName} dataType="String" />) : null}
            <DataRow title="Stop Type" value={stop.stopType} dataType="StopType" />
            {stopAddress !== undefined && stopAddress !== null ? (<DataAddressRow title="Stop Address" address={stopAddress} />) : null}
            {stop.startDateTime !== undefined && stop.startDateTime !== null ? <DataRow title="Arrival Date/Time" value={stop.startDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
            {stop.endDateTime !== undefined && stop.endDateTime !== null ? <DataRow title="Departure Date/Time" value={stop.endDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
            {stop.stopDuration !== undefined && stop.stopDuration !== null && stop.stopDurationUnit !== undefined && stop.stopDurationUnit !== null ? <DataRow title="Stop Duration" value={stop.stopDuration} dataType="Time" units={stop.stopDurationUnit} /> : null}
            {stop.actualDistanceTo !== undefined && stop.actualDistanceTo !== null && stop.actualDistanceToUnit !== undefined && stop.actualDistanceToUnit !== null ? <DataRow title="Leg Distance" value={stop.actualDistanceTo} dataType="Distance" units={stop.actualDistanceToUnit} /> : null}
            {stop.specialInstructions !== undefined && stop.specialInstructions !== null ? (<DataRow title="Special Instructions" value={stop.specialInstructions} dataType="String" />) : null}
            {/* <DataRow title="DH-O" value="15 Mins" dataType="String" />
            <DataRow title="Approx Stop Time" value="2 Hrs" dataType="String" /> */}
            {stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 ? <DataListRow title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
            {stop.pickUpNumber !== undefined && stop.pickUpNumber !== null ? <DataRow title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
            {stop.dropOffNumber !== undefined && stop.dropOffNumber !== null ? <DataRow title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
            {stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
            {stop.hasDriverAssist === true ? <DataRow title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
            {stop.hasLumperFee === true ? <DataRow title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
            {stop.apptType !== undefined && stop.apptType !== null ? (
                <>
                    <DataRow title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                    {stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow title="Appointment Window" startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null}
                    {stop.apptNumber !== undefined && stop.apptNumber !== null ? <DataRow title="Appointment #" value={stop.apptNumber} dataType="String" /> : null}
                    {stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                    {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                    {stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null ? <DataContactInfoRow title="Appointment POC" contact={stop.apptPointOfContact} /> : null}
                    {stop.apptInstructions !== undefined && stop.apptInstructions !== null ? <DataRow title="Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                </>
            ) : null}
            {commodities !== null ? (
                <div style={{marginTop: 24}}>
                    <div><b>Stop Commodities</b></div>
                    <DataTable style={{ marginTop: 16 }} bordered={true} dataSource={commodities} columns={columns} size="small" pagination={false} rowKey={record => `${stop.id}-${record.id}`} />
                </div>
            ) : null}
            {isAuthenticated === true && showDocuments === true ? (
                <div style={{marginTop: 24}}>
                    <DocumentList tableTitle="Stop Documents" documentList={documents.filter(document => document.entityId === stop.id)} entityId={stop.id} entityType="STOP" entitySubType={stop.stopType} loadId={loadId} />
                </div>
            ) : null}
            {isAuthenticated === true && showNotes === true ? (
                <div style={{marginTop: 24}}>
                    <NoteList tableTitle="Stop Notes" noteList={notes.filter(note => note.entityId === stop.id)} entityId={stop.id} entityType="STOP" />
                </div>
            ) : null}
        </>
    );
};

export default LoadStop;