import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataTemperatureRangeRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, noColon = false, title = null, min, minUnits, max, maxUnits }) => {
    const minTemperature = stringFormatter.toFormattedString('Temperature', min, minUnits);
    const maxTemperature = stringFormatter.toFormattedString('Temperature', max, maxUnits);

    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            {minTemperature !== '' && maxTemperature !== '' ? (<span className={valueClassName} style={valueStyle}>{minTemperature} - {maxTemperature}</span>) : null}
        </div>
    );
};

export default DataTemperatureRangeRow;