import React, { useState } from 'react';
import { Button, Card, Modal } from "antd";
import classes from './DispatchAssetCard.module.scss';
import DispatchAddAsset from "../../components/DispatchAddAsset/DispatchAddAsset";
import DispatchChangeAsset from '../DispatchChangeAsset/DispatchChangeAsset';
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faPlus, faTimes, faTruck } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import StringFormatter from '../../shared/stringFormatter';
import ActionDoubleButton from '../ActionDoubleButton/ActionDoubleButton';

const stringFormatter = new StringFormatter();

const DispatchAssetCard = props => {
    //#region props

    const load = props.load !== undefined && props.load !== null ? props.load : {};
    const assets = props.assets !== undefined && props.assets !== null ? props.assets : [];
    const drivers = props.drivers !== undefined && props.drivers !== null ? props.drivers : [];
    const setAssetSideBar = props.setAssetSideBar;
    const setDriverSideBar = props.setDriverSideBar;
    const displayAsText = props.displayAsText !== undefined && props.displayAsText !== null && props.displayAsText === true ? true : false;
    const setSelectedLoadToDispatchId = props.setSelectedLoadToDispatchId;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;
    const style = props.style;

    const loadDriverIds = load !== undefined && load !== null && !isEmpty(load) && load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0 ? load.driverIds : [];
    const loadAssetIds = load !== undefined && load !== null && !isEmpty(load) && load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? load.assetIds : [];

    //#endregion
    //#region useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [showRemoveAssetModal, setShowRemoveAssetModal] = useState(false);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBundledLoad === true && load !== undefined && load !== null && !isEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Modal
            title={"Assign Asset to Load"}
            visible={true}
            onCancel={(e) => { setAssetSideBar(null); }}
            maskClosable={false}
            footer={null}
        >
            <DispatchAddAsset
                cancel={() => { setAssetSideBar(null); }}
                loadDriverIds={loadDriverIds}
                drivers={drivers}
                assets={assets}
                load={load}
                isBundledLoad={isBundledLoad}
            />
        </Modal>
    );

    const changeEntityComponents = (asset) => {
        return (
            <Modal
                title={"Change Asset"}
                visible={true}
                onCancel={(e) => { setAssetSideBar(null); }}
                maskClosable={false}
                footer={null}
            >
                <DispatchChangeAsset
                    cancel={() => { setAssetSideBar(null); }}
                    asset={asset}
                    assets={assets}
                    load={load}
                    isBundledLoad={isBundledLoad}
                />
            </Modal>
        );
    };

    const removeEntityComponents = (asset) => {
        return (
            <Modal
                title={null}
                visible={showRemoveAssetModal === true}
                maskClosable={false}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
            >
                <Card
                    title={"Confirm Cancellation"}
                    style={{ border: '1px solid #D8D8D8' }}
                    bodyStyle={{ padding: 12, minHeight: 400 }}
                    headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                    extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setShowRemoveAssetModal(false); }}></Button>}
                >
                    <RemoveLoadAsset
                        cancel={() => { setShowRemoveAssetModal(false); }}
                        asset={asset}
                        load={load}
                        isBundledLoad={isBundledLoad}
                    />
                </Card>
            </Modal>
        );
    };

    const renderAssetDetails = (loadAsset) => {
        if (loadAsset !== undefined && loadAsset !== null) {
            let assetType = stringFormatter.toFormattedString("AssetType", loadAsset.assetType, null);
            let trailerType = (loadAsset.assetType === 'TRAILER' || loadAsset.assetType === 'COMBINED') ? (loadAsset.trailerType !== undefined && loadAsset.trailerType !== null ? loadAsset.trailerType.description : '') : null;
            let licensePlate = `${loadAsset.licensePlateNum} ${loadAsset.licensePlateState}`;
            let assetTitle = loadAsset.name ? loadAsset.name : licensePlate;

            return (
                <span><strong>{assetTitle}</strong><br /><i>Type: {assetType}{trailerType ? ' - ' + trailerType : ''}</i></span>
            );
        } else {
            return null
        }
    };

    const renderSingleRowAssetDetails = (loadAsset) => {
        if (loadAsset !== undefined && loadAsset !== null) {
            let assetType = stringFormatter.toFormattedString("AssetType", loadAsset.assetType, null);
            let trailerType = (loadAsset.assetType === 'TRAILER' || loadAsset.assetType === 'COMBINED') ? (loadAsset.trailerType !== undefined && loadAsset.trailerType !== null ? loadAsset.trailerType.description : '') : null;
            let licensePlate = `${loadAsset.licensePlateNum} ${loadAsset.licensePlateState}`;
            let assetTitle = loadAsset.name ? loadAsset.name : licensePlate;
            let assetTypeTitle = loadAsset.assetType === 'TRACTOR' ? assetType : trailerType;

            return (
                <span>{assetTypeTitle}: {assetTitle}</span>
            );
        } else {
            return null
        }
    };

    //#endregion

    if (load !== undefined && load !== null && !isEmpty(load) && load.isDeleted === false && load.loadStatus !== 'CANCELLED') {
        if (displayAsText === true) {
            if (load.loadStatus === 'BOOKED') {
                return (
                    <>
                        {loadAssetIds !== undefined && loadAssetIds !== null && loadAssetIds.length > 0 ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (loadAsset !== undefined && loadAsset !== null) {
                                        return (
                                            <div key={`load-asset-text-${load.id}-${loadAssetId}`}>
                                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(changeEntityComponents(loadAsset)); setDriverSideBar(null); }}>{renderSingleRowAssetDetails(loadAsset)}</Button>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <div key={`load-asset-text-${load.id}-add-another`}>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}><span>Assign another Asset</span></Button>
                                </div>
                            </>
                        ) : (
                                <div key={`load-asset-text-${load.id}-add`}>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}><span>Assign as Asset</span></Button>
                                </div>
                            )}
                    </>
                );
            } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                return (
                    <>
                        {loadAssetIds !== undefined && loadAssetIds !== null && loadAssetIds.length > 0 ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (loadAsset !== undefined && loadAsset !== null) {
                                        return (
                                            <div key={`load-asset-text-${load.id}-${loadAssetId}`}>{renderSingleRowAssetDetails(loadAsset)}</div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </>
                        ) : null}
                    </>
                );
            } else {
                return null;
            }
        } else {
            if (load.loadStatus === 'BOOKED') {
                return (
                    <>
                        {loadAssetIds !== undefined && loadAssetIds !== null && loadAssetIds.length > 0 ? (
                            <>
                                {loadAssetIds.map((loadAssetId) => {
                                    let loadAsset = assets.find(i => i.id === loadAssetId);
                                    if (loadAsset !== undefined && loadAsset !== null) {
                                        return (
                                            <div style={style} key={`load-asset-${load.id}-${loadAssetId}`}>
                                                <ActionDoubleButton
                                                    iconPosition="left"
                                                    buttonGroupClassName={classes.buttonGroup}
                                                    buttonClassName={`${classes.buttonGroupButton} ${classes.yellowButton}`}
                                                    buttonIconClassName={classes.iconContainer}
                                                    buttonTextClassName={classes.textContainer}
                                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faTruck} />}
                                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faExchangeAlt} />}
                                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} style={{color: "#55babb"}} icon={faTimes} />}
                                                    buttonText={renderAssetDetails(loadAsset)}

                                                    onClick1={() => { setAssetSideBar(changeEntityComponents(loadAsset)); setDriverSideBar(null); }}
                                                    onClick2={() => { setShowRemoveAssetModal(true); }}
                                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                                    buttonGroupStyle={{ marginBottom: 12, background: "#334353" }}
                                                    buttonTextStyle={{ color: "#fff", background: "#334353" }}
                                                    buttonIconStyle={{ background: "#334353", color: "#ffffff" }}
                                                    buttonStyle={{background: "#334353"}}
                                                    button1Style={{width: '100%', background: "#334353"  }}
                                                    button2Style={{ minWidth: 'unset', background: "#334353"  }}
                                                    button2Icon1Style={{ background: "#334353"  }}
                                                    button1Icon2Style={{ background: "#334353"  }}
                                                    button1Icon1Style={{ background: "#334353"  }}
                                                />
                                                {removeEntityComponents(loadAsset)}
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                <div style={style} key={`load-asset-${load.id}-add-another`}>
                                    <ActionButton
                                        iconPosition="left"
                                        buttonClassName={`${classes.button}`}
                                        buttonIconClassName={classes.iconContainer}
                                        buttonTextClassName={classes.textContainer}
                                        buttonIcon={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faTruck} />}
                                        buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faPlus} />}
                                        buttonText={<span>Assign another Asset</span>}
                                        onClick={() => { setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}
                                        disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                        buttonTextStyle={{ color: "#fff", borderRadius: "10px", background: "#334353" }}
                                        buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                        buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                    />
                                </div>
                            </>
                        ) : (
                                <div style={style} key={`load-asset-${load.id}-add`}>
                                    <ActionButton
                                        iconPosition="left"
                                        buttonClassName={`${classes.button}`}
                                        buttonIconClassName={classes.iconContainer}
                                        buttonTextClassName={classes.textContainer}
                                        buttonIcon={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faTruck} />}
                                        buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faPlus} />}
                                        buttonText={<span>Assign an Asset</span>}
                                        onClick={() => { setAssetSideBar(newEntityComponents); setDriverSideBar(null); }}
                                        disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                        buttonTextStyle={{ color: "#fff", borderRadius: "10px", background: "#334353" }}
                                        buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                        buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                    />
                                </div>
                            )}
                    </>
                );
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export default DispatchAssetCard;
