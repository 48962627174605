import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";

//#region LoadLanes Functions

export const fetchLoadLanesStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_LANES_START
    }
};

export const fetchLoadLanesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANES_SUCCESS,
        payload: payload
    }
};

export const fetchLoadLanesFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANES_FAIL,
        payload: payload
    }
};

export const clearLoadLanes = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LANES
    }
};

export const fetchLoadLaneStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_START
    }
};

export const fetchLoadLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchLoadLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const clearLoadLane = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LANE
    }
};

export const addLoadLaneStart = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_START
    }
};

export const addLoadLaneSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_SUCCESS
    }
};

export const addLoadLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const addLoadLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_ERROR_CLEAR
    }
};

export const addLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LANE_LOADING_CLEAR
    }
};

export const updateLoadLaneStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_START
    }
};

export const updateLoadLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_SUCCESS
    }
};

export const updateLoadLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const updateLoadLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_ERROR_CLEAR
    }
};

export const updateLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE_LOADING_CLEAR
    }
};

export const cancelAddLoadLane = () => {
    return {
        type: actionTypes.CANCEL_ADD_LOAD_LANE
    }
};

export const cancelUpdateLoadLane = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOAD_LANE
    }
};

export const insertLoadLane = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_LANE,
        payload: payload
    }
};

export const changeLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_LANE,
        payload: payload
    }
};

export const changeSingleLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_LANE,
        payload: payload
    }
};

export const removeLoadLane = (payload) => {
    return {
        type: actionTypes.REMOVE_LOAD_LANE,
        payload: payload
    }
};

//#endregion

//#region LoadLanes Methods

export const fetchLoadLanes = (payload) => {
    return async (dispatch, getState) => {
        const loadLanesPath = '/loadLanes';
        try {
            dispatch(fetchLoadLanesStart());

            const state = getState();
            const loadLanesState = {...state.loadLanes};
            let pagination = {...loadLanesState.pagination};
            let searchParams = {...loadLanesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const loadLanesRes = await axiosAuthenticated.get(loadLanesPath, {params: {...searchParams}});
            if (loadLanesRes && loadLanesRes.status === 200) {
                const loadLanes = loadLanesRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (loadLanes !== undefined && loadLanes !== null && loadLanes.length > 0) {
                    loadLanes.forEach((loadLane) => {
                        if (loadLane !== undefined && loadLane !== null && loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                            loadLane.locations.forEach((location) => {
                                if (!locationIds.includes(location.locationId)) {
                                    locationIds.push(location.locationId);
                                }
                            });
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                const transformedLoadLanes = loadLanes.map((item) => {
                    // Get Location Info
                    let loadLaneLocations = [];
                    if (item.locations !== undefined && item.locations !== null && item.locations.length > 0) {
                        loadLaneLocations = item.locations.map((location) => {
                            let locationLocation = null;
                            if (location.locationId !== undefined && location.locationId !== null) {
                                let locationObj = locations.find(i => i.id === location.locationId);
                                if (locationObj !== undefined && locationObj !== null) {
                                    locationLocation = {...locationObj};
                                }
                            }

                            return {
                                ...location,
                                location: locationLocation
                            };
                        });
                    }

                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (item.equipmentNeeds !== undefined && item.equipmentNeeds !== null && item.equipmentNeeds.trailerTypeId !== undefined && item.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === item.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...item.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    return {
                        ...item,
                        account: account,
                        locations: loadLaneLocations,
                        equipmentNeeds: equipmentNeeds
                    };
                });

                // Read total count from server
                pagination.total = loadLanesRes.data.totalCount;
                pagination.current = loadLanesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLoadLanesSuccess({
                    records: transformedLoadLanes,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadLanesFail({error: error.message}));
        }
    }
};

export const fetchLoadLane = (id) => {
    return async (dispatch, getState) => {
        const loadLanesPath = '/loadLanes';
        try {
            dispatch(fetchLoadLaneStart());

            const loadLanesRes = await axiosAuthenticated.get(loadLanesPath + `/${id}`);
            if (loadLanesRes && loadLanesRes.status === 200) {
                let loadLane = loadLanesRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (loadLane !== undefined && loadLane !== null && loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                    loadLane.locations.forEach((location) => {
                        if (!locationIds.includes(location.locationId)) {
                            locationIds.push(location.locationId);
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                // Get Location Info
                let loadLaneLocations = [];
                if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                    loadLaneLocations = loadLane.locations.map(location => {
                        let locationLocation = null;
                        if (location.locationId !== undefined && location.locationId !== null) {
                            let locationObj = locations.find(i => i.id === location.locationId);
                            if (locationObj !== undefined && locationObj !== null) {
                                locationLocation = {...locationObj};
                            }
                        }

                        return {
                            ...location,
                            location: locationLocation
                        };
                    });
                }

                let trailerType = null;
                let equipmentNeeds = null;
                if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                    equipmentNeeds = {
                        ...loadLane.equipmentNeeds,
                        trailerType: trailerType
                    };
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(loadLane.accountId, accounts);

                const transformedLoadLane = {
                    ...loadLane,
                    account: account,
                    locations: loadLaneLocations,
                    equipmentNeeds: equipmentNeeds
                };

                dispatch(fetchLoadLaneSuccess({record: transformedLoadLane}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadLaneFail({error: error.message}));
        }
    }
};

export const addLoadLane = (payload) => {
    return async (dispatch, getState) => {
        const loadLanesPath = '/loadLanes';
        try {
            dispatch(addLoadLaneStart());

            const loadLanesRes = await axiosAuthenticated.post(loadLanesPath, {...payload});
            if (loadLanesRes && loadLanesRes.status === 201) {
                const loadLane = loadLanesRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (loadLane !== undefined && loadLane !== null && loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                    loadLane.locations.forEach((location) => {
                        if (!locationIds.includes(location.locationId)) {
                            locationIds.push(location.locationId);
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                // Get Location Info
                let loadLaneLocations = [];
                if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                    loadLaneLocations = loadLane.locations.map(location => {
                        let locationLocation = null;
                        if (location.locationId !== undefined && location.locationId !== null) {
                            let locationObj = locations.find(i => i.id === location.locationId);
                            if (locationObj !== undefined && locationObj !== null) {
                                locationLocation = {...locationObj};
                            }
                        }

                        return {
                            ...location,
                            location: locationLocation
                        };
                    });
                }

                let trailerType = null;
                let equipmentNeeds = null;
                if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                    equipmentNeeds = {
                        ...loadLane.equipmentNeeds,
                        trailerType: trailerType
                    };
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(loadLane.accountId, accounts);

                const newLoadLane = {
                    ...loadLane,
                    account: account,
                    locations: loadLaneLocations,
                    equipmentNeeds: equipmentNeeds
                };

                dispatch(insertLoadLane(newLoadLane));

                dispatch(addLoadLaneSuccess());
                dispatch(addLoadLaneLoadingClear());
                dispatch(addLoadLaneErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadLaneFail({error: error.message}));
        }
    }
};

export const updateLoadLane = (loadLaneId, payload) => {
    return async (dispatch, getState) => {
        const loadLanesPath = '/loadLanes';
        try {
            dispatch(updateLoadLaneStart());

            const loadLanesRes = await axiosAuthenticated.put(loadLanesPath + `/${loadLaneId}`, {...payload});
            if (loadLanesRes && loadLanesRes.status === 200) {
                const loadLane = loadLanesRes.data;

                if (loadLane.isDeleted === true) {
                    dispatch(removeLoadLane(loadLane));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];
                    const trailerTypes = [...orchestratorState.trailerTypes];

                    let locationIds = [];
                    let locations = [];
                    if (loadLane !== undefined && loadLane !== null && loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                        loadLane.locations.forEach((location) => {
                            if (!locationIds.includes(location.locationId)) {
                                locationIds.push(location.locationId);
                            }
                        });
                    }

                    if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                        const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                            page: 1,
                            size: 100000,
                            isDeleted: false,
                            id: [...locationIds]
                        });

                        if (locationsRes && locationsRes.status === 200) {
                            locations = locationsRes.data.data;
                        }
                    }

                    // Get Location Info
                    let loadLaneLocations = [];
                    if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                        loadLaneLocations = loadLane.locations.map(location => {
                            let locationLocation = null;
                            if (location.locationId !== undefined && location.locationId !== null) {
                                let locationObj = locations.find(i => i.id === location.locationId);
                                if (locationObj !== undefined && locationObj !== null) {
                                    locationLocation = {...locationObj};
                                }
                            }

                            return {
                                ...location,
                                location: locationLocation
                            };
                        });
                    }

                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...loadLane.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(loadLane.accountId, accounts);

                    const updatedLoadLane = {
                        ...loadLane,
                        account: account,
                        locations: loadLaneLocations,
                        equipmentNeeds: equipmentNeeds
                    };

                    dispatch(changeLoadLane(updatedLoadLane));
                    dispatch(changeSingleLoadLane(updatedLoadLane));
                }

                dispatch(updateLoadLaneSuccess());
                dispatch(updateLoadLaneLoadingClear());
                dispatch(updateLoadLaneErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadLaneFail({error: error.message}));
        }
    }
};

//#endregion