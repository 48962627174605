import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Menu, Dropdown, Modal, Drawer, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';
import { CloseCircleTwoTone, CheckCircleTwoTone, PaperClipOutlined, ControlOutlined, DownOutlined } from '@ant-design/icons';
import StringFormatter from '../../shared/stringFormatter';
import EditAsset from '../../components/EditAsset/EditAsset';
import DocumentList from '../../components/DocumentList/DocumentList';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Asset = props => {
    const fullWidth = global.window.innerWidth;
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.assets.isRecordLoading);
    const error = useSelector(state => state.assets.recordError);
    const asset = useSelector(state => state.assets.record);

    const [tabKey, setTabKey] = useState('snapshot');
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAsset(id));
    }, [id]);

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: '10px' }} />
                    <span>Edit Asset Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Employees';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/assets` },
        path: previousPath ? previousPath : `/assets`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Assets',
    },
    {
        path: `/assets/${id ? id : null}`,
        breadcrumbName: `${asset ? (asset.name ? asset.name : asset.irisId) : ''}`,
    },
    ];

    const editEntityComponents = (
        <Drawer
            title={"Edit Asset Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset cancel={toggleEditEntity} record={asset} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Asset Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {asset !== undefined && asset !== null &&
                <DocumentList entityType="ASSET" entityId={asset.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} />
            }
        </Modal>
    );

    if (asset && asset.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={asset ? (asset.name ? asset.name : asset.irisId) : 'Loading...'}
                    subtitle={asset && asset.assetType ? `Asset Type: ${stringFormatter.toFormattedString('AssetType', asset.assetType, null)}` : null}
                    subtitle2={asset && asset.trailerType ? `Trailer Type: ${asset.trailerType.description}` : null}
                    title2={asset && asset.truckOwnerType ? `Ownership Type: ${stringFormatter.toFormattedString('TruckOwnerType', asset.truckOwnerType, null)}` : null}
                    title3={asset && asset.isCarrierAsset === true ? `Is Carrier Asset: ${stringFormatter.toFormattedString('Boolean', asset.isCarrierAsset, null)}` : null}
                    title4={asset && asset.isContractorAsset === true ? `Is Contractor Asset: ${stringFormatter.toFormattedString('Boolean', asset.isContractorAsset, null)}` : null}
                    highlights={[
                        {
                            title: 'Status',
                            value: `${asset && asset.status ? stringFormatter.toFormattedString('AssetStatus', asset.status, null) : ''}`
                        },
                        {
                            title: 'Available',
                            value: asset && asset.isAvailable === true ? <CheckCircleTwoTone title="Available" alt="Available" twoToneColor="#16588E" /> : <CloseCircleTwoTone title="Not Available" alt="Not Available" twoToneColor="#EF5350" />
                        },
                        {
                            title: 'License Plate',
                            value: `${asset && asset.licensePlateNum && asset.licensePlateState ? asset.licensePlateNum + ' ' + asset.licensePlateState : ''}`
                        },
                    ]}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruckMoving} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            {(asset !== null && isLoading === false) ? (
                                <Row gutter={[16, 16]}>
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Asset Specs"
                                            headStyle={{ fontSize: 14 }}
                                            extra={extra}
                                        >
                                            {asset.make && <DataRow title="Make" value={asset.make} dataType="String" />}
                                            {asset.model && <DataRow title="Model" value={asset.model} dataType="String" />}
                                            {asset.year && <DataRow title="Year" value={asset.year} dataType="String" />}
                                            {asset.color && <DataRow title="Color" value={asset.color} dataType="String" />}
                                            {asset.vin && <DataRow title="VIN" value={asset.vin} dataType="String" />}
                                            {asset.dotNum && <DataRow title="Dot #" value={asset.dotNum} dataType="String" />}
                                            {asset.truckClass && <DataRow title="Truck Class" value={asset.truckClass} dataType="String" />}
                                        </ComplexCard>
                                    </Col>
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Asset Details"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {asset.axles && <DataRow title="Axles" value={asset.axles} dataType="String" />}
                                            {asset.range && <DataRow title="Range" value={asset.range} units={asset.rangeUnit} dataType="Distance" />}
                                            {asset.overallLength && <DataRow title="Overall Length" value={asset.overallLength} units={asset.overallLengthUnit} dataType="Length" />}
                                            {asset.length && <DataRow title="Length" value={asset.length} units={asset.lengthUnit} dataType="Length" />}
                                            {asset.height && <DataRow title="Height" value={asset.height} units={asset.heightUnit} dataType="Length" />}
                                            {asset.width && <DataRow title="Width" value={asset.width} units={asset.widthUnit} dataType="Length" />}
                                            {asset.weight && <DataRow title="Weight" value={asset.weight} units={asset.weightUnits} dataType="Weight" />}
                                            {asset.loadCapacity && <DataRow title="Load Capacity" value={asset.loadCapacity} units={asset.loadCapacityUnit} dataType="Weight" />}
                                            {asset.maxLoadWeight && <DataRow title="Max Load Weight" value={asset.maxLoadWeight} units={asset.maxLoadWeightUnit} dataType="Weight" />}
                                            {asset.fuelTankCapacity && <DataRow title="Fuel Tank Capacity" value={asset.fuelTankCapacity} units={asset.fuelTankCapacityUnit} dataType="Volume" />}
                                        </ComplexCard>
                                    </Col>
                                </Row>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <div style={{ padding: 24 }}>
                            {asset !== undefined && asset !== null ? (
                                <DocumentList entityType="ASSET" entityId={asset.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} />
                            ) : null}
                        </div>
                    </TabPane>
                </Tabs>
                {editEntityComponents}
                {documentsComponent}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Asset);
