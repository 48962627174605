import moment from 'moment';

export const removeEmpty = (obj) => {
    Object.keys(obj).forEach(key => {
        if (!moment.isMoment(obj[key])) {
            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) removeEmpty(obj[key]);
            else if (obj[key] === undefined) delete obj[key];
            else if (obj[key] === "") delete obj[key];

            if (obj[key] && typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) delete obj[key];
        }
    });

    return obj;
};

export const convertToObject = (data) => {
    let obj = {};
    let t;
    let parts;
    let part;

    for (let k in data) {
        t = obj;
        parts = k.split('.');
        var key = parts.pop();
        while (parts.length) {
            part = parts.shift();
            t = t[part] = t[part] || {};
        }
        t[key] = data[k]
    }

    return obj;
};

export const isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // console.log(aProps);
    // console.log(bProps);
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // console.log(a[propName]);
        // console.log(b[propName]);
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
};