import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataHoursOfOperationRow from '../../components/DataHoursOfOperationRow/DataHoursOfOperationRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataRow from '../../components/DataRow/DataRow';
import StringFormatter from '../../shared/stringFormatter';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Location = props => {
    const id = props.match.params.id;

    const routerLocation = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.locations.isRecordLoading);
    const error = useSelector(state => state.locations.recordError);
    const location = useSelector(state => state.locations.record);

    const [tabKey, setTabKey] = useState('snapshot');

    useMemo(() => {
        //console.log('location changed')
        //console.log(routerLocation)
        if (routerLocation.hash !== undefined && routerLocation.hash !== null && routerLocation.hash !== "") {
            //console.log(routerLocation.hash.replace('#', ''))
            setTabKey(routerLocation.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...routerLocation, hash: `#snapshot` });
        }
    }, [routerLocation]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchLocation(id));
    }, [id]);


    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/locations` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/locations`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Locations';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/locations` },
        path: previousPath ? previousPath : `/locations`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Locations',
    },
    {
        path: `/locations/${id ? id : null}`,
        breadcrumbName: `${location ? location.name : ''}`,
    },
    ];

    if (location && location.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={location ? location.name : 'Loading...'}
                    subtitle={location ? location.irisId : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faMapMarked} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                {location && location.address ? (
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Location Details"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            <DataAddressRow title="Address" address={location.address} />
                                            {location.locationTypes && location.locationTypes.length > 0 ? <DataRow title="Location Type(s)" value={location.locationTypes.map(item => stringFormatter.toFormattedString("LocationType", item, null)).join(', ')} dataType="String" /> : null}
                                            {location && location.timeZone ? <DataRow title="Time Zone" value={location.timeZone} dataType="String" /> : null}
                                            {location.pointOfContact ? <DataContactInfoRow title="Point of Contact" contact={location.pointOfContact} /> : null}
                                            {location.dockHours && location.dockHours.length > 0 ? <DataHoursOfOperationRow title="Dock Hours" data={location.dockHours} /> : null}
                                            {location.commodities && location.commodities.length > 0 ? <DataListRow title="Bids" data={location.commodities} /> : null}
                                        </ComplexCard>
                                    </Col>
                                ) : null}
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Additional Location Details"
                                        style={{ height: 300 }}
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Appointment Type": { value: location && location.appointmentType ? location.appointmentType : null, dataType: 'AppointmentType' },
                                            "Special Instructions": { value: location && location.specialInstructions ? location.specialInstructions : null, dataType: 'String' },
                                            "Lumper Fee": { value: location && location.lumperFee ? location.LumperFee : null, dataType: 'Money' },
                                            "Has Restrooms": { value: location && location.isRestroomAvailable ? location.isRestroomAvailable : null, dataType: 'Boolean' },
                                            "Has Driver Rest Area": { value: location && location.isDriverRestAreaAvailable ? location.isDriverRestAreaAvailable : null, dataType: 'Boolean' },
                                            "Has Parking": { value: location && location.isParkingAvailable ? location.isParkingAvailable : null, dataType: 'Boolean' },
                                            "Has Overnight Parking": { value: location && location.isOvernightParkingAvailable ? location.isOvernightParkingAvailable : null, dataType: 'Boolean' },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Location);