import React, { useMemo, useState, useEffect } from 'react';
import { Col, Input, Row, Select, Button, Radio, Alert, Spin, InputNumber, Switch, Modal } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import UploadFile from "../UploadFile/UploadFile";
import { useForm, FormProvider } from 'react-hook-form';
import Enums from '../../shared/enums';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItemTriple from '../FormItemTriple/FormItemTriple';
import DataListRow from '../DataListRow/DataListRow';
import StringFormatter from '../../shared/stringFormatter';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import { ErrorMessage } from '@hookform/error-message';
import { DeleteOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import MomentDate from '../../shared/dateFormatter';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const NewLoadStop = props => {
    //#region props

    const load = props.load;
    const shipperId = props.shipperId;
    const stops = props.stops;
    const stopType = props.stopType;
    const sequence = props.sequence;
    const stopTypeSequence = props.stopTypeSequence;
    const defaultBOLNumbers = props.defaultBOLNumbers;
    const defaultCommodities = props.defaultCommodities;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            existingBOLNumber: null,
            newBOLNumber: ''
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            loadingType: null,
            hasLumperFee: false,
            lumperFee: null,
            hasDriverAssist: false,
            driverAssist: null,
            specialInstructions: ''
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });
    const methods5 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null,
            stopType: null,
            stopNumber: null
        }
    });
    const methods6 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            apptType: null,
            name: '',
            phone: '',
            phoneExt: '',
            apptInstructions: '',
            apptWindowStartDateTime: null,
            apptWindowEndDateTime: null,
            requestedDateTime: null,
            apptDateTime: null,
            apptNumber: '',
            apptCallAheadDateTime: null,
            pickUpNumber: '',
            purchaseOrderNumber: '',
            dropOffNumber: ''
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const commodities = useSelector(state => state.orchestrator.commodities);
    const locations = useSelector(state => state.locations.records);
    const isLocationsLoading = useSelector(state => state.locations.isLoading);
    const linkedLocations = useSelector(state => state.linkedLocations.records);
    const isLinkedLocationsLoading = useSelector(state => state.linkedLocations.isLoading);
    const isLoading = useSelector(state => state.loadStops.isRecordAddLoading);
    const error = useSelector(state => state.loadStops.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region Enums

    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useStates

    const [loadStop, setLoadStop] = useState({
        id: sequence,
        sequence: sequence,
        stopTypeSequence: stopTypeSequence,
        stopType: stopType
    });
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);

    const [pickUpStopsCount, setPickUpStopsCount] = useState(0);
    const [dropOffStopsCount, setDropOffStopsCount] = useState(0);
    const [locationsList, setLocationsList] = useState([]);
    const [showEditStopAppointment, setShowEditStopAppointment] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showAddStopBOL, setShowAddStopBOL] = useState(false);
    const [showEditStopLoadingSpecifics, setShowEditStopLoadingSpecifics] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [bolNumberList, setBOLNumberList] = useState([]);
    const [bolDocFiles, setBOLDocFiles] = useState([]);
    const [showNewBOLNumber, setShowNewBOLNumber] = useState(false);
    const [docFiles, setDocFiles] = useState([]);
    const [showFirstComeFirstServeDetails, setShowFirstComeFirstServeDetails] = useState(false);
    const [showHaveAppointmentDetails, setShowHaveAppointmentDetails] = useState(false);
    const [showNeedAppointmentDetails, setShowNeedAppointmentDetails] = useState(false);
    const [lumperFeeDisabled, setLumperFeeDisabled] = useState(true);
    const [driverAssistDisabled, setDriverAssistDisabled] = useState(true);
    const [dontRequireLoadSpecificInformation, setDontRequireLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet

    //#endregion
    //#region toggles

    const toggleAddStopBOL = () => {
        // reset form
        setBOLDocFiles([]);
        setShowNewBOLNumber(false);
        methods2.setValue(`existingBOLNumber`, null);
        methods2.setValue(`newBOLNumber`, '');
        methods2.clearErrors();

        setShowAddStopBOL(!showAddStopBOL);
    };

    const toggleEditStopLoadingSpecifics = () => {
        setDriverAssistDisabled(true);
        setLumperFeeDisabled(true);

        // reset form
        methods3.setValue('loadingType', null);
        methods3.setValue('hasLumperFee', false);
        methods3.setValue('lumperFee', null);
        methods3.setValue('hasDriverAssist', false);
        methods3.setValue('driverAssist', null);
        methods3.setValue('specialInstructions', '');
        methods3.clearErrors();

        setShowEditStopLoadingSpecifics(!showEditStopLoadingSpecifics);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods4.setValue('commodityId', null);
        methods4.setValue('unitPackagingInitCount', null);
        methods4.setValue('unitPackaging', null);
        methods4.setValue('bulkPackagingInitCount', null);
        methods4.setValue('bulkPackaging', null);
        methods4.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    const toggleEditStopAppointment = () => {
        setShowFirstComeFirstServeDetails(false);
        setShowHaveAppointmentDetails(false);
        setShowNeedAppointmentDetails(false);

        // reset form
        methods6.setValue('apptType', null);
        methods6.setValue('name', '');
        methods6.setValue('phone', '');
        methods6.setValue('phoneExt', '');
        methods6.setValue('apptInstructions', '');
        methods6.setValue('apptWindowStartDateTime', null);
        methods6.setValue('apptWindowEndDateTime', null);
        methods6.setValue('requestedDateTime', null);
        methods6.setValue('apptDateTime', null);
        methods6.setValue('apptNumber', '');
        methods6.setValue('apptCallAheadDateTime', null);
        methods6.setValue('pickUpNumber', '');
        methods6.setValue('purchaseOrderNumber', '');
        methods6.setValue('dropOffNumber', '');
        methods6.clearErrors();

        setShowEditStopAppointment(!showEditStopAppointment);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods5.setValue('locationId', null);
        methods5.setValue('stopType', null);
        methods5.setValue('stopNumber', null);
        methods5.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    //#endregion
    //#region submits and validation

    const onEditStopLocation = (data) => {
        const stopLocationId = data.locationId;

        if (stopLocationId !== undefined && stopLocationId !== null) {
            let stopLocation = locationsList.find(l => l.id === stopLocationId);
            if (stopLocation !== undefined && stopLocation !== null) {
                const updatedValues = {
                    stopLocation: stopLocation,
                    stopLocationId: stopLocationId,
                    timeZone: stopLocation.timeZone
                };

                if (stopLocation.lumperFee !== undefined && stopLocation.lumperFee !== null && stopLocation.lumperFee > 0) {
                    updatedValues.lumperFee = stopLocation.lumperFee;
                    updatedValues.hasLumperFee = true;
                } else {
                    updatedValues.hasLumperFee = false;
                }
                if (stopLocation.specialInstructions !== undefined && stopLocation.specialInstructions !== null) {
                    updatedValues.specialInstructions = stopLocation.specialInstructions;
                }
                if (stopLocation.pointOfContact !== undefined && stopLocation.pointOfContact !== null) {
                    updatedValues.apptPointOfContact = stopLocation.pointOfContact;
                }

                const updatedRecord = {
                    ...loadStop,
                    ...updatedValues
                };

                setLoadStop({ ...updatedRecord });

                toggleEditStopLocation();
            } else {
                return;
            }
        } else {
            return;
        }
    };

    const onEditStopAppointment = (data) => {
        const apptType = data.apptType;
        const name = data.name;
        const phone = data.phone;
        const phoneExt = data.phoneExt;
        const apptInstructions = data.apptInstructions;
        const apptWindowStartDateTime = data.apptWindowStartDateTime;
        const apptWindowEndDateTime = data.apptWindowEndDateTime;
        const apptDateTime = data.apptDateTime;
        const apptNumber = data.apptNumber;
        const requestedDateTime = data.requestedDateTime;
        const apptCallAheadDateTime = data.apptCallAheadDateTime;
        const pickUpNumber = data.pickUpNumber;
        const purchaseOrderNumber = data.purchaseOrderNumber;
        const dropOffNumber = data.dropOffNumber;

        const apptPointOfContact = {
            name: name,
            phone: phone
        };

        if (phoneExt !== undefined && phoneExt !== null && phoneExt !== '') {
            apptPointOfContact.phoneExt = phoneExt;
        }

        const updatedValues = {
            apptType: apptType,
            apptPointOfContact: apptPointOfContact
        };

        if (pickUpNumber !== undefined && pickUpNumber !== null) {
            updatedValues.pickUpNumber = pickUpNumber;
        }
        if (purchaseOrderNumber !== undefined && purchaseOrderNumber !== null) {
            updatedValues.purchaseOrderNumber = purchaseOrderNumber;
        }
        if (dropOffNumber !== undefined && dropOffNumber !== null) {
            updatedValues.dropOffNumber = dropOffNumber;
        }

        if (apptInstructions !== undefined && apptInstructions !== null && apptInstructions !== '') {
            updatedValues.apptInstructions = apptInstructions;
        }

        if (apptType === 'FIRST_COME_FIRST_SERVE') {
            if (apptWindowEndDateTime < apptWindowStartDateTime) {
                // console.log(apptWindowEndDateTime);
                // console.log(apptWindowStartDateTime);
                methods6.setError("apptWindowEndDateTime", { type: "validate", message: "The End Date/Time cannot be before the Start Date/Time." });

                return;
            } else {
                methods6.clearErrors('apptWindowEndDateTime');
            }
            updatedValues.apptWindowStartDateTime = apptWindowStartDateTime;
            updatedValues.apptWindowEndDateTime = apptWindowEndDateTime;
            updatedValues.requestedDateTime = apptWindowStartDateTime;
        } else if (apptType === 'HAVE_APPOINTMENT') {
            updatedValues.apptDateTime = apptDateTime;
            updatedValues.apptNumber = apptNumber;
            updatedValues.requestedDateTime = apptDateTime;
        } else if (apptType === 'NEED_APPOINTMENT') {
            updatedValues.apptCallAheadDateTime = apptCallAheadDateTime;
            updatedValues.requestedDateTime = requestedDateTime;
        }

        const updatedRecord = {
            ...loadStop,
            ...updatedValues
        };

        setLoadStop({ ...updatedRecord });

        toggleEditStopAppointment();
    };

    const onAddStopBOL = (data) => {
        const newBOLNumber = data.newBOLNumber;
        const existingBOLNumber = data.existingBOLNumber;

        methods2.clearErrors();

        let bolNumber = null;
        if (existingBOLNumber !== undefined && existingBOLNumber !== null && existingBOLNumber !== 'NEW') {
            bolNumber = existingBOLNumber;
        } else if (newBOLNumber !== undefined && newBOLNumber !== null) {
            bolNumber = newBOLNumber;
        }

        if (bolNumber !== undefined && bolNumber !== null) {
            if (bolNumberList !== undefined && bolNumberList !== null && bolNumberList.length > 0) {
                if (bolNumberList.length > dropOffStopsCount) {
                    methods2.setError('existingBOLNumber', { type: 'validateBOLCount', message: 'You cannot add more BOL Numbers than the total number of Drop-Offs for this Load.' });
                }
            }

            let stopBOLNumberList = [];
            if (loadStop.bolNumbers !== undefined && loadStop.bolNumbers !== null && loadStop.bolNumbers.length > 0) {
                stopBOLNumberList = [...loadStop.bolNumbers];
                if (stopBOLNumberList.find(b => b === bolNumber) === undefined) {
                    stopBOLNumberList.push(bolNumber);
                } else {
                    methods2.setError('newBOLNumber', { type: "duplicate", message: 'This BOL Number has already been added to this location.' });
                    return;
                }
            } else {
                stopBOLNumberList.push(bolNumber);
            }

            if (bolDocFiles !== undefined && bolDocFiles !== null && bolDocFiles.length > 0) {
                let newFiles = [];
                bolDocFiles.forEach((file) => {
                    // console.log('file:');
                    // console.log(file);
                    let fileJson = { bolNumber: bolNumber, file: file };
                    newFiles.push(fileJson);
                });
                setDocFiles(oldFiles => [...oldFiles, ...newFiles]);
                setBOLDocFiles([]);
            }

            const updatedValues = {
                bolNumbers: [...stopBOLNumberList]
            };

            const updatedRecord = {
                ...loadStop,
                ...updatedValues
            };

            setLoadStop({ ...updatedRecord });

            toggleAddStopBOL();
        } else {
            methods2.setError('existingBOLNumber', { type: 'required', message: 'Required Field' });
            return;
        }
    };

    const onRemoveStopBOL = (bolNumber, index) => {
        let stopBOLNumberList = [];
        if (loadStop.bolNumbers !== undefined && loadStop.bolNumbers !== null && loadStop.bolNumbers.length > 0) {
            stopBOLNumberList = [...loadStop.bolNumbers];
            stopBOLNumberList.splice(index, 1);

            // also remove the BOL Files for this BOL Number for this stop
            let filteredArray = docFiles.filter(d => d.bolNumber !== bolNumber);
            setDocFiles([...filteredArray]);
        }

        const updatedRecord = {
            ...loadStop,
            bolNumbers: [...stopBOLNumberList]
        };

        setLoadStop({ ...updatedRecord });
    };

    const onEditStopLoadingSpecifics = (data) => {
        const loadingType = data.loadingType;
        const specialInstructions = data.specialInstructions;
        const hasLumperFee = data.hasLumperFee;
        const lumperFee = data.lumperFee;
        const hasDriverAssist = data.hasDriverAssist;
        const driverAssist = data.driverAssist;

        const updatedValues = {
            loadingType: loadingType,
            hasLumperFee: hasLumperFee,
            lumperFee: lumperFee,
            hasDriverAssist: hasDriverAssist,
            driverAssist: driverAssist,
            specialInstructions: specialInstructions
        };

        const updatedRecord = {
            ...loadStop,
            ...updatedValues
        };

        setLoadStop({ ...updatedRecord });

        toggleEditStopLoadingSpecifics();
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods4.clearErrors();

        let name = null;
        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (commodity !== undefined && commodity !== null) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (unitPackagingInitCount !== undefined && unitPackagingInitCount !== null && unitPackagingInitCount > 0) {
            if (unitPackaging === undefined || unitPackaging === null) {
                methods4.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((unitPackagingInitCount === undefined || unitPackagingInitCount === null || unitPackagingInitCount === 0) && (unitPackaging !== undefined && unitPackaging !== null)) {
            methods4.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (bulkPackagingInitCount !== undefined && bulkPackagingInitCount !== null && bulkPackagingInitCount > 0) {
            if (bulkPackaging === undefined || bulkPackaging === null) {
                methods4.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((bulkPackagingInitCount === undefined || bulkPackagingInitCount === null || bulkPackagingInitCount === 0) && (bulkPackaging !== undefined && bulkPackaging !== null)) {
            methods4.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        let stopCommodities = [];
        if (loadStop.commodities !== undefined && loadStop.commodities !== null && loadStop.commodities.length > 0) {
            stopCommodities = [...loadStop.commodities];
            if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                stopCommodities.push(newStopCommodity);
            } else {
                methods4.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                return;
            }
        } else {
            stopCommodities.push(newStopCommodity);
        }

        //console.log(stopCommodities);

        const updatedRecord = {
            ...loadStop,
            commodities: [...stopCommodities]
        };

        setLoadStop({ ...updatedRecord });

        toggleAddStopCommodity();
    };

    const onRemoveStopCommodity = (index) => {
        let stopCommodities = [];
        if (loadStop.commodities !== undefined && loadStop.commodities !== null && loadStop.commodities.length > 0) {
            stopCommodities = [...loadStop.commodities];
            stopCommodities.splice(index, 1);
        }

        const updatedRecord = {
            ...loadStop,
            commodities: [...stopCommodities]
        };

        setLoadStop({ ...updatedRecord });
    };

    const validateStop = (newStop, pickUps, dropOffs) => {
        let hasErrors = false;
        methods.clearErrors();

        if (newStop !== undefined && newStop !== null && !isEmpty(newStop)) {
            if (newStop.stopLocationId === undefined || newStop.stopLocationId === null) {
                methods.setError(`loadStop`, { type: 'missingStopLocationId', message: `Stop is missing Location.` });
                hasErrors = true;
            }

            if (newStop.apptType === undefined || newStop.apptType === null) {
                methods.setError(`loadStop`, { type: 'missingApptType', message: `Stop is missing Appointment Details.` });
                hasErrors = true;
            }

            if ((newStop.bolNumbers === undefined || newStop.bolNumbers === null || newStop.bolNumbers.length === 0) && dontRequireLoadSpecificInformation === false) {
                methods.setError(`loadStop`, { type: 'missingBOLNumber', message: `Stop is missing a BOL Number.` });
                hasErrors = true;
            }

            if (newStop.stopType === undefined || newStop.stopType === null) {
                methods.setError(`loadStop`, { type: 'missingStopType', message: `Stop is missing a Stop Type.` });
                hasErrors = true;
            } else if (newStop.stopType === 'PICK_UP') {
                if (newStop.requestedDateTime === undefined || newStop.requestedDateTime === null) {
                    methods.setError(`loadStop`, { type: 'missingRequestedDateTime', message: `Stop is missing a Pick-Up Date.` });
                    hasErrors = true;
                }

                // check to make sure new stop is in order with the other pick-ups if there are any and the drop-offs
                if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                    let updatedPickUps = pickUps.splice(newStop.stopTypeSequence, 0, newStop);
                    // re-index the pick-ups
                    let reIndexedUpdatedPickUps = updatedPickUps.map((stop, stopIndex) => {
                        return {
                            ...stop,
                            sequence: stopIndex,
                            id: stopIndex,
                            stopTypeSequence: stopIndex
                        }
                    });

                    // validate date to make sure pick-ups are in order
                    if (newStop.stopTypeSequence > 0) {
                        // make sure new stop's date is after the stop before it
                        if (newStop.requestedDateTime < reIndexedUpdatedPickUps[newStop.stopTypeSequence - 1].requestedDateTime) {
                            let stop1 = { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence - 1] };
                            let stop2 = { ...newStop };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be before the date for Pick-Up ${newStop.stopTypeSequence}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be before the date for Pick-Up ${newStop.stopTypeSequence}.` });
                                hasErrors = true;
                            }
                        }

                        // if there is a pick-up after the new stop, make sure the new stop's date is before it
                        if (newStop.stopTypeSequence < (reIndexedUpdatedPickUps.length - 1)) {
                            if (newStop.requestedDateTime > reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1].requestedDateTime) {
                                let stop1 = { ...newStop };
                                let stop2 = { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1] };
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.` });
                                        hasErrors = true;
                                    }
                                } else {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.` });
                                    hasErrors = true;
                                }
                            }
                        }
                    } else if (reIndexedUpdatedPickUps.length > 1) {
                        // new stop is the first pick-up
                        if (newStop.requestedDateTime > reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1].requestedDateTime) {
                            let stop1 = { ...newStop };
                            let stop2 = { ...reIndexedUpdatedPickUps[newStop.stopTypeSequence + 1] };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Pick-Up ${newStop.stopTypeSequence + 2}.` });
                                hasErrors = true;
                            }
                        }
                    }

                    // if new stop is the last stop in the pick-ups then also make sure it is before the first drop-off stop
                    if (newStop.stopTypeSequence === (reIndexedUpdatedPickUps.length - 1)) {
                        if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                            if (newStop.requestedDateTime > dropOffs[0].requestedDateTime) {
                                let stop1 = { ...newStop };
                                let stop2 = { ...dropOffs[0] };
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        if (dropOffs.length > 1) {
                                            methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off 1.` });
                                            hasErrors = true;
                                        } else {
                                            methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off.` });
                                            hasErrors = true;
                                        }
                                    }
                                } else {
                                    if (dropOffs.length > 1) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off 1.` });
                                        hasErrors = true;
                                    } else {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off.` });
                                        hasErrors = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // new stop is the only pick-up so compare it to the drop-offs
                    if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                        if (newStop.requestedDateTime > dropOffs[0].requestedDateTime) {
                            let stop1 = { ...newStop };
                            let stop2 = { ...dropOffs[0] };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    if (dropOffs.length > 1) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off 1.` });
                                        hasErrors = true;
                                    } else {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off.` });
                                        hasErrors = true;
                                    }
                                }
                            } else {
                                if (dropOffs.length > 1) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off 1.` });
                                    hasErrors = true;
                                } else {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithDropOff', message: `The date for this stop cannot be after the date for Drop-Off.` });
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }

                if (newStop.loadingType === undefined || newStop.loadingType === null) {
                    methods.setError(`loadStop`, { type: 'missingLoadingType', message: `Stop is missing a Loading Type.` });
                    hasErrors = true;
                }

                if ((newStop.pickUpNumber === undefined || newStop.pickUpNumber === null) && dontRequireLoadSpecificInformation === false) {
                    methods.setError(`loadStop`, { type: 'missingPickUpNumber', message: `Stop is missing a Pick-Up Number.` });
                    hasErrors = true;
                }
            } else if (newStop.stopType === 'DROP_OFF') {
                if (newStop.requestedDateTime === undefined || newStop.requestedDateTime === null) {
                    methods.setError(`loadStop`, { type: 'missingRequestedDateTime', message: `Stop is missing a Drop-Off Date.` });
                    hasErrors = true;
                }

                // check to make sure new stop is in order with the other drop-offs if there are any and the pick-ups
                if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                    let updatedDropOffs = dropOffs.splice(newStop.stopTypeSequence, 0, newStop);
                    // re-index the drop-offs
                    let reIndexedUpdatedDropOffs = updatedDropOffs.map((stop, stopIndex) => {
                        return {
                            ...stop,
                            sequence: stopIndex + pickUps.length,
                            id: stopIndex + pickUps.length,
                            stopTypeSequence: stopIndex
                        }
                    });

                    // validate date to make sure drop-offs are in order
                    if (newStop.stopTypeSequence > 0) {
                        // make sure new stop's date is after the stop before it
                        if (newStop.requestedDateTime < reIndexedUpdatedDropOffs[newStop.stopTypeSequence - 1].requestedDateTime) {
                            let stop1 = { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence - 1] };
                            let stop2 = { ...newStop };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be before the date for Drop-Off ${newStop.stopTypeSequence}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be before the date for Drop-Off ${newStop.stopTypeSequence}.` });
                                hasErrors = true;
                            }
                        }

                        // if there is a drop-off after the new stop, make sure the new stop's date is before it
                        if (newStop.stopTypeSequence < (reIndexedUpdatedDropOffs.length - 1)) {
                            if (newStop.requestedDateTime > reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1].requestedDateTime) {
                                let stop1 = { ...newStop };
                                let stop2 = { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1] };
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.` });
                                        hasErrors = true;
                                    }
                                } else {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.` });
                                    hasErrors = true;
                                }
                            }
                        }
                    } else if (reIndexedUpdatedDropOffs.length > 1) {
                        // new stop is the first drop-off
                        if (newStop.requestedDateTime > reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1].requestedDateTime) {
                            let stop1 = { ...newStop };
                            let stop2 = { ...reIndexedUpdatedDropOffs[newStop.stopTypeSequence + 1] };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`loadStop`, { type: 'validateRequestedDateTime', message: `The date for this stop cannot be after the date for Drop-Off ${newStop.stopTypeSequence + 2}.` });
                                hasErrors = true;
                            }
                        }
                    }

                    // if new stop is the first stop in the drop-offs then also make sure it is after the last pick-up stop
                    if (newStop.stopTypeSequence === 0) {
                        if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                            if (newStop.requestedDateTime < pickUps[pickUps.length - 1].requestedDateTime) {
                                let stop1 = { ...pickUps[pickUps.length - 1] };
                                let stop2 = { ...newStop };
                                if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                    let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                    let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                    if (moment(stop2DateString) < moment(stop1DateString)) {
                                        if (pickUps.length > 1) {
                                            methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.` });
                                            hasErrors = true;
                                        } else {
                                            methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up.` });
                                            hasErrors = true;
                                        }
                                    }
                                } else {
                                    if (pickUps.length > 1) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.` });
                                        hasErrors = true;
                                    } else {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up.` });
                                        hasErrors = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    // new stop is the only drop-off so compare it to the pick-ups
                    if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                        if (newStop.requestedDateTime < pickUps[pickUps.length - 1].requestedDateTime) {
                            let stop1 = { ...pickUps[pickUps.length - 1] };
                            let stop2 = { ...newStop };
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    if (pickUps.length > 1) {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.` });
                                        hasErrors = true;
                                    } else {
                                        methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up.` });
                                        hasErrors = true;
                                    }
                                }
                            } else {
                                if (pickUps.length > 1) {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up ${pickUps.length}.` });
                                    hasErrors = true;
                                } else {
                                    methods.setError(`loadStop`, { type: 'validateRequestedDateTimeWithPickUp', message: `The date for this stop cannot be before the date for Pick-Up.` });
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }

                if (newStop.loadingType === undefined || newStop.loadingType === null) {
                    methods.setError(`loadStop`, { type: 'missingLoadingType', message: `Stop is missing an Unloading Type.` });
                    hasErrors = true;
                }

                if ((newStop.dropOffNumber === undefined || newStop.dropOffNumber === null) && dontRequireLoadSpecificInformation === false) {
                    methods.setError(`loadStop`, { type: 'missingDropOffNumber', message: `Stop is missing a Drop-Off Number.` });
                    hasErrors = true;
                }

                if ((newStop.purchaseOrderNumber === undefined || newStop.purchaseOrderNumber === null) && dontRequireLoadSpecificInformation === false) {
                    methods.setError(`loadStop`, { type: 'missingPurchaseOrderNumber', message: `Stop is missing a Customer Purchase Order Number.` });
                    hasErrors = true;
                }
            }

            // validate BOL Numbers
            // the Add BOL functionality will force the numbr of BOL Numbers to match the number of drop-off stops
            if (bolNumberList.length !== dropOffStopsCount && dontRequireLoadSpecificInformation === false) {
                methods.setError('loadStop', { type: 'badBOLNumberCount', message: 'There does not appear to be a unique BOL Number for each Drop-Off. Please resolve the issue and try again.' });
                hasErrors = true;
            }
        }

        return !hasErrors;
    };

    const onSubmit = () => {
        if (validateStop(loadStop, pickUpStops, dropOffStops)) {
            let payload = { ...loadStop };
            dispatch(actionCreators.addLoadStop(payload, docFiles, load));
        } else {
            return;
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddLoadStop());
        props.cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopAppointment === true && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                if (loadStop.apptType !== undefined && loadStop.apptType !== null) {
                    methods6.setValue('apptType', loadStop.apptType);

                    if (loadStop.stopType !== undefined && loadStop.stopType !== null) {
                        if (loadStop.stopType === "PICK_UP") {
                            if (loadStop.pickUpNumber !== undefined && loadStop.pickUpNumber !== null) {
                                methods6.setValue('pickUpNumber', loadStop.pickUpNumber);
                            }
                        } else if (loadStop.stopType === "DROP_OFF") {
                            if (loadStop.purchaseOrderNumber !== undefined && loadStop.purchaseOrderNumber !== null) {
                                methods6.setValue('purchaseOrderNumber', loadStop.purchaseOrderNumber);
                            }
                            if (loadStop.dropOffNumber !== undefined && loadStop.dropOffNumber !== null) {
                                methods6.setValue('dropOffNumber', loadStop.dropOffNumber);
                            }
                        }
                    }

                    if (loadStop.apptType === "FIRST_COME_FIRST_SERVE") {
                        setShowFirstComeFirstServeDetails(true);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    } else if (loadStop.apptType === "HAVE_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(true);
                        setShowNeedAppointmentDetails(false);
                    } else if (loadStop.apptType === "NEED_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(true);
                    } else {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    }
                }
                if (loadStop.apptPointOfContact !== undefined && loadStop.apptPointOfContact !== null) {
                    if (loadStop.apptPointOfContact.name !== undefined && loadStop.apptPointOfContact.name !== null) {
                        methods6.setValue('name', loadStop.apptPointOfContact.name);
                    }
                    if (loadStop.apptPointOfContact.phone !== undefined && loadStop.apptPointOfContact.phone !== null) {
                        methods6.setValue('phone', loadStop.apptPointOfContact.phone);
                    }
                    if (loadStop.apptPointOfContact.phoneExt !== undefined && loadStop.apptPointOfContact.phoneExt !== null) {
                        methods6.setValue('phoneExt', loadStop.apptPointOfContact.phoneExt);
                    }
                }
                if (loadStop.apptInstructions !== undefined && loadStop.apptInstructions !== null) {
                    methods6.setValue('apptInstructions', loadStop.apptInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopAppointment, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showFirstComeFirstServeDetails === true && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                if (loadStop.apptType !== undefined && loadStop.apptType !== null && loadStop.apptType === "FIRST_COME_FIRST_SERVE") {
                    if (loadStop.apptWindowStartDateTime !== undefined && loadStop.apptWindowStartDateTime !== null) {
                        methods6.setValue(`apptWindowStartDateTime`, loadStop.apptWindowStartDateTime);
                    }
                    if (loadStop.apptWindowEndDateTime !== undefined && loadStop.apptWindowEndDateTime !== null) {
                        methods6.setValue(`apptWindowEndDateTime`, loadStop.apptWindowEndDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showFirstComeFirstServeDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showHaveAppointmentDetails === true && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                if (loadStop.apptType !== undefined && loadStop.apptType !== null && loadStop.apptType === "HAVE_APPOINTMENT") {
                    if (loadStop.apptNumber !== undefined && loadStop.apptNumber !== null) {
                        methods6.setValue(`apptNumber`, loadStop.apptNumber);
                    }
                    if (loadStop.apptDateTime !== undefined && loadStop.apptDateTime !== null) {
                        methods6.setValue(`apptDateTime`, loadStop.apptDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showHaveAppointmentDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showNeedAppointmentDetails === true && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                if (loadStop.apptType !== undefined && loadStop.apptType !== null && loadStop.apptType === "NEED_APPOINTMENT") {
                    if (loadStop.apptCallAheadDateTime !== undefined && loadStop.apptCallAheadDateTime !== null) {
                        methods6.setValue(`apptCallAheadDateTime`, loadStop.apptCallAheadDateTime);
                    }
                    if (loadStop.requestedDateTime !== undefined && loadStop.requestedDateTime !== null) {
                        methods6.setValue(`requestedDateTime`, loadStop.requestedDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showNeedAppointmentDetails, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                if (loadStop.stopLocationId !== undefined && loadStop.stopLocationId !== null) {
                    methods5.setValue('locationId', loadStop.stopLocationId);
                }
                if (loadStop.stopType !== undefined && loadStop.stopType !== null) {
                    methods5.setValue('stopType', loadStop.stopType);
                }
                if (loadStop.stopNumber !== undefined && loadStop.stopNumber !== null) {
                    methods5.setValue('stopNumber', loadStop.stopNumber);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, loadStop]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLoadingSpecifics === true) {
                if (loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
                    if (loadStop.loadingType !== undefined && loadStop.loadingType !== null) {
                        methods3.setValue(`loadingType`, loadStop.loadingType);
                    }

                    if (loadStop.hasLumperFee === true) {
                        methods3.setValue(`hasLumperFee`, true);
                        setLumperFeeDisabled(false);
                        if (loadStop.lumperFee !== undefined) {
                            methods3.setValue(`lumperFee`, loadStop.lumperFee);
                        } else if (loadStop.stopLocation !== undefined && loadStop.stopLocation !== null && loadStop.stopLocation.lumperFee !== undefined && loadStop.stopLocation.lumperFee !== null && loadStop.stopLocation.lumperFee > 0) {
                            methods3.setValue(`lumperFee`, loadStop.stopLocation.lumperFee);
                        }
                    } else {
                        setLumperFeeDisabled(true);
                        methods3.setValue(`hasLumperFee`, false);
                    }

                    if (loadStop.hasDriverAssist === true) {
                        methods3.setValue(`hasDriverAssist`, true);
                        setDriverAssistDisabled(false);
                        if (loadStop.driverAssist !== undefined) {
                            methods3.setValue(`driverAssist`, loadStop.driverAssist);
                        } else if (entityType === 'STAFF') {
                            methods3.setValue(`driverAssist`, 50);
                        }
                    } else {
                        setDriverAssistDisabled(true);
                        methods3.setValue(`hasDriverAssist`, false);
                    }

                    if (loadStop.specialInstructions !== undefined) {
                        methods3.setValue(`specialInstructions`, loadStop.specialInstructions);
                    } else if (loadStop.stopLocation !== undefined && loadStop.stopLocation !== null && loadStop.stopLocation.specialInstructions !== undefined && loadStop.stopLocation.specialInstructions !== null) {
                        methods3.setValue(`specialInstructions`, loadStop.stopLocation.specialInstructions);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLoadingSpecifics, loadStop]);

    useMemo(() => {
        if (shipperId !== undefined && shipperId !== null) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: shipperId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: shipperId, isDeleted: false }));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
            }
        }
    }, [shipperId, entityType, entityId]);

    useMemo(() => {
        if (locations !== undefined && locations !== null && linkedLocations !== undefined && linkedLocations !== null) {
            let transformedLocations = [];
            if (locations.length > 0) {
                locations.forEach((item) => {
                    transformedLocations.push({
                        ...item
                    });
                });
            }
            if (linkedLocations.length > 0) {
                linkedLocations.forEach((item) => {
                    transformedLocations.push({
                        ...item.location,
                        name: item.name ? item.name : (item.location ? item.location.name : null)
                    });
                });
            }

            setLocationsList(transformedLocations);
        }
    }, [locations, linkedLocations]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadStopErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    useMemo(() => {
        let loadBOLNumberList = [];
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let pickUpStopsList = [];
            let dropOffStopsList = [];
            stops.forEach((stop, stopIndex) => {
                if (stop.stopType === 'PICK_UP') {
                    pickUpStopsList.push(stop);
                } else if (stop.stopType === 'DROP_OFF') {
                    dropOffStopsList.push(stop);
                }

                if (stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) {
                    stop.bolNumbers.forEach((bolNumber) => {
                        if (loadBOLNumberList.find(b => b === bolNumber) === undefined) {
                            loadBOLNumberList.push(bolNumber);
                        }
                    });
                }
            });
            setPickUpStops(pickUpStopsList);
            setDropOffStops(dropOffStopsList);
            setPickUpStopsCount(pickUpStopsList.length + (stopType === 'PICK_UP' ? 1 : 0));
            setDropOffStopsCount(dropOffStopsList.length + (stopType === 'DROP_OFF' ? 1 : 0));
        }

        setBOLNumberList([...loadBOLNumberList]);
    }, [stops, stopType]);

    useMemo(() => {
        // if there are default bol numbers passed in then this must be the only drop off stop so the bol numbers need to match the pick up stop
        if (defaultBOLNumbers !== undefined && defaultBOLNumbers !== null && defaultBOLNumbers.length > 0 && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
            // make sure loadStop is initialized first
            if (loadStop.id !== undefined && loadStop.id !== null) {
                let updatedRecord = {
                    ...loadStop,
                    bolNumbers: [...defaultBOLNumbers]
                };

                setLoadStop(updatedRecord);
            }
        }
    }, [defaultBOLNumbers, loadStop]);

    useMemo(() => {
        // if there are default commodities passed in then this must be the only drop off stop so the commodities need to match the pick up stop
        if (defaultCommodities !== undefined && defaultCommodities !== null && defaultCommodities.length > 0 && loadStop !== undefined && loadStop !== null && !isEmpty(loadStop)) {
            // make sure loadStop is initialized first
            if (loadStop.id !== undefined && loadStop.id !== null) {
                let updatedRecord = {
                    ...loadStop,
                    commodities: [...defaultCommodities]
                };

                setLoadStop(updatedRecord);
            }
        }
    }, [defaultCommodities, loadStop]);

    useMemo(() => {
        if (entityType === 'CARRIER') {
            setDontRequireLoadSpecificInformation(true);
        } else {
            setDontRequireLoadSpecificInformation(false);
        }
    }, [entityType]);

    //#endregion
    //#region onChanges

    const handleAppointmentTypeChange = (value) => {
        if (value !== null && value !== undefined) {
            //console.log(`selected ${value}`);
            if (value === "FIRST_COME_FIRST_SERVE") {
                setShowFirstComeFirstServeDetails(true);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            } else if (value === "HAVE_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(true);
                setShowNeedAppointmentDetails(false);
            } else if (value === "NEED_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(true);
            } else {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            }
        } else {
            setShowFirstComeFirstServeDetails(false);
            setShowHaveAppointmentDetails(false);
            setShowNeedAppointmentDetails(false);
        }
    };

    const onBOLNumberChange = (value) => {
        console.log(value);

        if (value !== undefined && value !== null) {
            if (value === 'NEW') {
                setShowNewBOLNumber(true);
            } else {
                setShowNewBOLNumber(false);
            }
        } else {
            setShowNewBOLNumber(false);
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };
    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };
    const formItemLayoutDouble3 = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 4 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 20 },
    };
    const formItemLayoutTriple = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 8 },
        wrapperCol2: { span: 8 },
        labelCol3: { span: 4 },
        wrapperCol3: { span: 4 },
    };
    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (locationsList !== undefined && locationsList !== null && locationsList.length > 0) {
            // let usedLocationIds = [];
            // if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) {
            //     pickUpStops.forEach((pickUpStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (pickUpStop.id !== stop.id) {
            //                 if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                     usedLocationIds.push(pickUpStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                 usedLocationIds.push(pickUpStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // if (dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            //     dropOffStops.forEach((dropOffStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (dropOffStop.id !== stop.id) {
            //                 if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                     usedLocationIds.push(dropOffStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                 usedLocationIds.push(dropOffStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            locationsList.forEach((item) => {
                // if (!usedLocationIds.includes(item.id)) {
                options.push(<Option value={item.id} key={item.id}>{`${item.name ? item.name : ''} ${item.address !== undefined && item.address !== null ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
                // }
            });
        }

        return options;
    };

    const getStopLocationDisplay = (stop, stopIndex) => {
        return (
            <DataCollapsableRow
                key={`stop-display-${stop.stopType}-${stopIndex}`}
                isCollapsed={stop && stop.stopLocationId ? false : true}
                title={stop && stop.stopLocation && stop.stopLocation.name ? stop.stopLocation.name : (<Button style={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }} type='link' onClick={() => { toggleEditStopLocation(); }}>Select a Location <DownOutlined /></Button>)}
                titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                titleHasAction={!(stop && stop.stopLocation && stop.stopLocation.name)}
                subtitle={stop && stop.stopLocation && stop.stopLocation.address ? (<span style={{ fontSize: 14 }}>{stringFormatter.toFormattedAddress(stop.stopLocation.address)}</span>) : null}
                header={(stop.apptType !== undefined && stop.apptType !== null) ? (
                    <>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{(stop && stop.stopType ? `${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}` : '')}  </span>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>
                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                        </span>
                    </>
                ) : (stop && stop.stopType ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{`${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}`}</span> : null)}
                extra={(stop && stop.stopLocationId) ? (<Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { toggleEditStopLocation(); }}>edit</Button>) : null}
            >
                <div style={{ marginTop: 18 }}>
                    {(stop.apptType !== undefined && stop.apptType !== null) ? (
                        <>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Type</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('AppointmentType', stop.apptType, null)}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopAppointment(); }}>edit</Button></div>
                                </Col>
                            </Row>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Requested {Enums.StopTypes.getValueByName(stop.stopType)} Date & Appointment</span></div>
                                    <div>
                                        <span style={{ fontSize: 16 }}>
                                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {stop.apptNumber && (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Number</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptNumber}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {stop.apptCallAheadDateTime && (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Call Ahead Date/Time</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('MomentDateTime', stop.apptCallAheadDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null)}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {stop.apptPointOfContact && (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{Enums.StopTypes.getValueByName(stop.stopType)} Contact</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptPointOfContact.name}</span></div>
                                    </Col>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Phone Number</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {stop.apptInstructions && (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Instructions</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptInstructions}</span></div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : ((stop.apptType === undefined || stop.apptType === null) ? (
                        <Row gutter={[4, 4]} style={{ marginTop: 18, marginBottom: 18 }}>
                            <Col span={24}>
                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.stopLocationId !== undefined && stop.stopLocationId !== null)} onClick={() => { toggleEditStopAppointment(); }}>{`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details *`}</Button>
                            </Col>
                        </Row>
                    ) : null)}
                    {(stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) ? (
                        <>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>BOL Number</span></div>
                                    {stop.bolNumbers.map((stopBOLNumber, stopBOLNumberIndex) => {
                                        return (
                                            <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`bolNumber-${stopBOLNumberIndex}`}>
                                                <Col span={18}>
                                                    <div><span style={{ fontSize: 16 }}>{stopBOLNumber}</span></div>
                                                    {docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).length > 0 ? (
                                                        <DataListRow data={docFiles.filter(d => d.stopId === stop.id && d.bolNumber === stopBOLNumber).map((docFile) => { if (docFile.file !== undefined && docFile.file !== null) { return docFile.file.name; } else { return ''; } })} />
                                                    ) : null}
                                                </Col>
                                                <Col span={6}>
                                                    {(stop.stopType === 'PICK_UP' || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                                        <Button type="default" icon={<DeleteOutlined />} onClick={() => { onRemoveStopBOL(stopBOLNumber, stopBOLNumberIndex); }} />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                            {(stop.stopType === 'PICK_UP' && dropOffStopsCount > stop.bolNumbers.length) ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { toggleAddStopBOL(); }}>Add Another BOL Number</Button>
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    ) : (
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={!(stop.apptType !== undefined && stop.apptType !== null)} icon={<PlusOutlined />} onClick={() => { toggleAddStopBOL(); }}>{`BOL Number${dontRequireLoadSpecificInformation === false ? ' *' : ''}`}</Button>
                                </Col>
                            </Row>
                        )}
                    {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                        <>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Commodities</span></div>
                                    {stop.commodities.map((stopCommodity, index) => {
                                        //console.log(stopCommodity);

                                        return (
                                            <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`stopCommodity-${index}`}>
                                                <Col span={18}>
                                                    <span style={{ fontSize: 16 }}>{stopCommodity.name}: {stopCommodity.unitPackagingInitCount ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{stopCommodity.bulkPackagingInitCount ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                                </Col>
                                                <Col span={6}>
                                                    {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                                        <Button type="default" icon={<DeleteOutlined />} onClick={() => { onRemoveStopCommodity(index); }} />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>

                            {((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { toggleAddStopCommodity(); }}>Additional Commodity</Button>
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    ) : (((stop.stopType === 'PICK_UP') || (stop.stopType === 'DROP_OFF' && dropOffStopsCount > 1)) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.apptType !== undefined && stop.apptType !== null)} onClick={() => { toggleAddStopCommodity(); }}>Commodities</Button>
                            </Col>
                        </Row>
                    ) : null)}
                    {(stop.loadingType !== undefined && stop.loadingType !== null) ? (
                        <>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} *</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { toggleEditStopLoadingSpecifics(); }}>edit</Button></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span></div>
                                </Col>
                            </Row>
                            {(stop.hasLumperFee === true || stop.hasDriverAssist === true) ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    {stop.hasLumperFee === true ? (
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Lumper Fee</span></div>
                                            <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`}</span></div>
                                        </Col>
                                    ) : null}
                                    {stop.hasDriverAssist === true ? (
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Driver Assist</span></div>
                                            <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`}</span></div>
                                        </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                            {stop.pickUpNumber ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Pick-Up Number</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.pickUpNumber}</span></div>
                                    </Col>
                                </Row>
                            ) : null}
                            {(stop.purchaseOrderNumber || stop.dropOffNumber) ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    {stop.dropOffNumber ? (
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Drop-Off Number</span></div>
                                            <div><span style={{ fontSize: 16 }}>{stop.dropOffNumber}</span></div>
                                        </Col>
                                    ) : null}
                                    {stop.purchaseOrderNumber ? (
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Customer PO Number</span></div>
                                            <div><span style={{ fontSize: 16 }}>{stop.purchaseOrderNumber}</span></div>
                                        </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                            {stop.specialInstructions && (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Special Instructions</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.specialInstructions}</span></div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : (
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" disabled={!((stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 && dontRequireLoadSpecificInformation === false) || dontRequireLoadSpecificInformation === true)} icon={<PlusOutlined />} onClick={() => { toggleEditStopLoadingSpecifics(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics *' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics *' : 'Loading/Unloading Specifics *')}</Button>
                                </Col>
                            </Row>
                        )}
                </div>
            </DataCollapsableRow>
        );
    };

    const getStopDetails = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
            const stopLocation = `${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city + ', ' : ''}${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : ''}`;
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            let appointmentTypeToShow = null;
            switch (stop.apptType) {
                case "FIRST_COME_FIRST_SERVE":
                    appointmentTypeToShow = (stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null);
                    break;
                case "HAVE_APPOINTMENT":
                    appointmentTypeToShow = (stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null);
                    break;
                case "NEED_APPOINTMENT":
                    appointmentTypeToShow = (stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null);
                    break;
            }

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={12}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{stopName}</span><br />
                                    <span>{stopLocation}</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <span style={{ fontWeight: 'bold' }}>Appointment</span><br />
                            {appointmentTypeToShow}
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                if (stop.stopType === 'PICK_UP') {
                    // if pick-up, don't show commodities that have already been added to this stop
                    let usedCommodityIds = [];
                    if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                        stop.commodities.forEach((stopCommodity) => {
                            usedCommodityIds.push(stopCommodity.commodityId);
                        });
                    }

                    commodities.forEach((commodity) => {
                        if (!usedCommodityIds.includes(commodity.id)) {
                            options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                        }
                    });
                } else if (stop.stopType === 'DROP_OFF') {
                    // only show commodities that the pick-up stops have
                    let usedCommodityIds = [];
                    pickUpStops.forEach((pickUpStop) => {
                        if (pickUpStop.commodities !== undefined && pickUpStop.commodities !== null && pickUpStop.commodities.length > 0) {
                            pickUpStop.commodities.forEach((stopCommodity) => {
                                if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                    usedCommodityIds.push(stopCommodity.commodityId);
                                }
                            });
                        }
                    });

                    commodities.forEach((commodity) => {
                        if (usedCommodityIds.includes(commodity.id)) {
                            options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                        }
                    });
                }
            }
        }

        return options;
    };

    const getBOLNumberOptions = (stop = null) => {
        let options = [];
        if (bolNumberList !== undefined && bolNumberList !== null && bolNumberList.length > 0 && stop !== undefined && stop !== null && !isEmpty(stop)) {
            let usedBOLNumbers = [];

            if (stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers > 0) {
                stop.bolNumbers.forEach((stopBOLNumber) => {
                    usedBOLNumbers.push(stopBOLNumber);
                });
            }

            bolNumberList.forEach((bolNumber) => {
                if (!usedBOLNumbers.includes(bolNumber)) {
                    options.push({ key: bolNumber, value: bolNumber, label: bolNumber });
                }
            });

            if (options.length > 0 && stop.stopType === 'PICK_UP') {
                options.push({ key: 'NEW', value: 'NEW', label: 'Add a New BOL Number' });
            }
        }

        return options;
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true || (isLocationsLoading === true || isLinkedLocationsLoading === true)) && error === null}>
                        {getStopLocationDisplay(loadStop, stopTypeSequence)}
                        {getError(methods.errors, "loadStop") !== undefined && (
                            <ErrorMessage
                                errors={methods.errors}
                                name="loadStop"
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Add Stop" />
                </Form>
            </FormProvider>
            <Modal
                title={loadStop ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Location" : (loadStop.stopType === "DROP_OFF" ? "Drop-Off Location" : "Stop Location")) : 'Stop Location'}
                visible={showEditStopLocation}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopLocation}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods5}>
                    <Form onSubmit={methods5.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions()}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Save Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={loadStop ? (loadStop.stopType === "PICK_UP" ? "Date, Appointment, & Pick-Up Details" : "Date, Appointment, & Drop-Off Details") : 'Date, Appointment, & Stop Details'}
                visible={showEditStopAppointment === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopAppointment}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods6}>
                    <Form onSubmit={methods6.handleSubmit(onEditStopAppointment)}>
                        <FormItem {...formItemLayout} label="Appointment Type" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select an Appointment Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { handleAppointmentTypeChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {appointmentTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="apptType"
                            defaultValue={null}
                        />
                        {(showFirstComeFirstServeDetails === true || (loadStop && loadStop.apptType === 'FIRST_COME_FIRST_SERVE')) ? (
                            <>
                                <FormItemDouble {...formItemLayoutDouble} label1="Requested Pick-Up Date & Appointment Window" required1 required2 format="vertical"
                                    render1={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Start Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="MM/DD/YYYY HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : moment()}
                                            name={name}
                                        />
                                    )}
                                    rules1={{ required: 'Required Field' }}
                                    name1="apptWindowStartDateTime"
                                    defaultValue1={null}
                                    render2={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="End Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="MM/DD/YYYY HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : moment()}
                                            name={name}
                                        />
                                    )}
                                    rules2={{ required: 'Required Field' }}
                                    name2="apptWindowEndDateTime"
                                    defaultValue2={null}
                                />
                            </>
                        ) : null}
                        {(showHaveAppointmentDetails === true || (loadStop && loadStop.apptType === 'HAVE_APPOINTMENT')) ? (
                            <>
                                <FormItem {...formItemLayout} label="Appointment #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Appointment #" />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="apptNumber"
                                    defaultValue={''}
                                />
                                <FormItem {...formItemLayout} label="Requested Pick-Up Date & Appointment" required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Appointment Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="MM/DD/YYYY HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : moment()}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="apptDateTime"
                                    defaultValue={null}
                                />
                            </>
                        ) : null}
                        {(showNeedAppointmentDetails === true || (loadStop && loadStop.apptType === 'NEED_APPOINTMENT')) ? (
                            <>
                                <FormItem {...formItemLayout} label={loadStop ? (loadStop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Requested Date"
                                            format="MM/DD/YYYY"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : moment()}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="requestedDateTime"
                                    defaultValue={null}
                                />
                                <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Call Ahead Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="MM/DD/YYYY HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : moment()}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="apptCallAheadDateTime"
                                    defaultValue={null}
                                />
                            </>
                        ) : null}
                        {(loadStop && loadStop.stopType === 'PICK_UP') ? (
                            <FormItem {...formItemLayout} label="Pick-Up #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pick-Up #" />}
                                rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                name="pickUpNumber"
                            />
                        ) : null}
                        {(loadStop && loadStop.stopType === 'DROP_OFF') ? (
                            <>
                                <FormItem {...formItemLayout} label="Drop-Off #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drop-Off #" />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="dropOffNumber"
                                />
                                <FormItem {...formItemLayout} label="Customer PO #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer PO #" />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="purchaseOrderNumber"
                                />
                            </>
                        ) : null}
                        <FormItemTriple {...formItemLayoutTriple} label1={loadStop ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Contact" : "Drop-Off Contact") : 'Appointment Contact'} label2="Phone #" label3="Phone Ext" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules1={{ required: 'Required Field' }}
                            name1="name"
                            defaultValue1={''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules2={{
                                required: 'Required Field',
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name2="phone"
                            defaultValue2={''}
                            render3={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone Ext" />}
                            rules3={{ required: false }}
                            name3="phoneExt"
                            defaultValue3={''}
                        />
                        <FormItem {...formItemLayout} label="Appointment Instructions" format="vertical"
                            render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                            rules={{ required: false }}
                            name="apptInstructions"
                            defaultValue={''}
                        />
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopAppointment(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{(loadStop && loadStop.requestedDateTime) ? 'Save Date, Appointment, and Stop Details' : 'Add Date, Appointment, and Stop Details'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={loadStop ? (loadStop.stopType === "PICK_UP" ? "Pick-Up BOL Information" : "Drop-Off BOL Information") : 'Stop BOL Information'}
                visible={showAddStopBOL}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleAddStopBOL}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onAddStopBOL)}>
                        {(getBOLNumberOptions(loadStop).length > 0) ? (
                            <>
                                <FormItem {...formItemLayout} label="Choose an existing BOL Number" format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Radio.Group
                                            onBlur={onBlur}
                                            options={getBOLNumberOptions(loadStop)}
                                            onChange={e => { onBOLNumberChange(e.target.value); onChange(e.target.value); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="existingBOLNumber"
                                />
                                {showNewBOLNumber === true ? (
                                    <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL Number" />}
                                        rules={{ required: 'Required Field' }}
                                        name="newBOLNumber"
                                    />
                                ) : null}
                            </>
                        ) : (
                                <FormItem {...formItemLayout} label="BOL Number" required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="BOL Number" />}
                                    rules={{ required: 'Required Field' }}
                                    name="newBOLNumber"
                                />
                            )}
                        <FormItemFile {...formItemLayout} label="BOL File(s)" name="docFiles" format="vertical">
                            <UploadFile
                                beforeUpload={(file, fileList) => {
                                    if (file !== undefined && file !== null) {
                                        setBOLDocFiles(oldFiles => [...oldFiles, file]);
                                    }

                                    return false;
                                }}
                                onRemove={(file) => {
                                    let updatedBOLDocFiles = [...bolDocFiles];
                                    let fileToRemoveIndex = updatedBOLDocFiles.findIndex(d => d.uid === file.uid);
                                    if (fileToRemoveIndex !== -1) {
                                        updatedBOLDocFiles.splice(fileToRemoveIndex, 1);
                                    }
                                    // console.log(updatedDocFiles);
                                    setBOLDocFiles([...updatedBOLDocFiles]);
                                }}
                                message="Please upload a photo or pdf of the BOL."
                                multiple={true}
                            />
                        </FormItemFile>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopBOL(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add BOL Information</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={loadStop ? (loadStop.stopType === "PICK_UP" ? "Loading Specifics" : "Unloading Specifics") : 'Loading/Unloading Specifics'}
                visible={showEditStopLoadingSpecifics === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleEditStopLoadingSpecifics}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onEditStopLoadingSpecifics)}>
                        {(loadStop && loadStop.stopType === 'PICK_UP') ? (
                            <Fieldset legend="* Loading Type Options">
                                <div>How will the trailer be loaded?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        {(loadStop && loadStop.stopType === 'DROP_OFF') ? (
                            <Fieldset legend="* Unloading Type Options">
                                <div>How will the trailer be unloaded?</div>
                                <FormItem {...formItemLayout} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name}>
                                            <Radio style={radioStyle} key="LIVE" value="LIVE">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be unloaded upon arrival.</span>
                                            </Radio>
                                            <Radio style={radioStyle} key="DROP" value="DROP">
                                                <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="loadingType"
                                />
                            </Fieldset>
                        ) : null}
                        <Fieldset legend="Additional Fees">
                            <div>Are there any additional fees?</div>
                            <FormItemDouble {...formItemLayoutDouble3} label1="Lumper Fee" format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setLumperFeeDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasLumperFee"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Lumper Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={lumperFeeDisabled === true}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="lumperFee"
                            />
                            <FormItemDouble {...formItemLayoutDouble3} label1="Driver Assist" format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); setDriverAssistDisabled(!checked); }}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="hasDriverAssist"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Driver Assist Fee"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={driverAssistDisabled === true}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="driverAssist"
                            />
                        </Fieldset>
                        <Fieldset legend="Special Instructions">
                            <FormItem {...formItemLayout} label="Special Instructions" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                                rules={{ required: false }}
                                name="specialInstructions"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                right: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                                marginTop: '0px',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLoadingSpecifics(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{loadStop ? (loadStop.stopType === "PICK_UP" ? "Update Loading Specifics" : "Update Unloading Specifics") : 'Update Loading/Unloading Specifics'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={loadStop ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity === true}
                width="60%"
                style={{ top: 0 }}
                onCancel={toggleAddStopCommodity}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onAddStopCommodity)}>
                        <Fieldset legend={loadStop ? (loadStop.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}>
                            {getStopDetails(loadStop)}
                        </Fieldset>
                        <Fieldset legend="Commodity Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Search and Select a Commodity to Add"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {getCommodityOptions(loadStop)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="commodityId"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Unit Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: 'Required Field' }}
                                name1="unitPackagingInitCount"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Unit of Measure/Unit Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {unitPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: 'Required Field' }}
                                name2="unitPackaging"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Bulk Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="bulkPackagingInitCount"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Bulk Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {bulkPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="bulkPackaging"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopCommodity(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default NewLoadStop;
