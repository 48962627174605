//#region Bids Methods
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import * as actionTypes from "./actionTypes";

export const fetchBidsStart = () => {
    return {
        type: actionTypes.FETCH_BIDS_START
    }
};

export const fetchBidsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_BIDS_SUCCESS,
        payload: payload
    }
};

export const fetchBidsFail = (payload) => {
    return {
        type: actionTypes.FETCH_BIDS_FAIL,
        payload: payload
    }
};

export const clearBids = () => {
    return {
        type: actionTypes.CLEAR_BIDS
    }
};

export const addBidStart = () => {
    return {
        type: actionTypes.ADD_BID_START
    }
};

export const insertBid = (payload) => {
    return {
        type: actionTypes.ADD_BID,
        payload: payload
    }
};

export const addBidSuccess = () => {
    return {
        type: actionTypes.ADD_BID_SUCCESS
    }
};

export const addBidFail = (payload) => {
    return {
        type: actionTypes.ADD_BID_FAIL,
        payload: payload
    }
};

export const addBidErrorClear = () => {
    return {
        type: actionTypes.ADD_BID_ERROR_CLEAR
    }
};

export const addBidLoadingClear = () => {
    return {
        type: actionTypes.ADD_BID_LOADING_CLEAR
    }
};

export const updateBidStart = () => {
    return {
        type: actionTypes.UPDATE_BID_START
    }
};

export const changeBid = (payload) => {
    return {
        type: actionTypes.UPDATE_BID,
        payload: payload
    }
};

export const changeSingleBid = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_BID,
        payload: payload
    }
};

export const updateBidSuccess = () => {
    return {
        type: actionTypes.UPDATE_BID_SUCCESS
    }
};

export const updateBidFail = (payload) => {
    return {
        type: actionTypes.UPDATE_BID_FAIL,
        payload: payload
    }
};

export const updateBidErrorClear = () => {
    return {
        type: actionTypes.UPDATE_BID_ERROR_CLEAR
    }
};

export const updateBidLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_BID_LOADING_CLEAR
    }
};

export const cancelAddBid = () => {
    return {
        type: actionTypes.CANCEL_ADD_BID
    }
};

export const cancelUpdateBid = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_BID
    }
};

export const fetchCarrierBids = (payload) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid/carrier';
        try {
            dispatch(fetchBidsStart());

            const state = getState();
            const notesState = {...state.bids};
            let searchParams = {...notesState.searchParams};
            let pagination = {...notesState.pagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const notesRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});

            if (notesRes && notesRes.status === 200) {
                const notes = notesRes.data.data.list;

                pagination.total = notesRes.data.data.count;
                pagination.current = 1;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchBidsSuccess({
                    records: notes,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchBidsFail({error: error.message}));
        }
    }
};

export const postCarrierBid = (params) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid/carrier';
        try {
            dispatch(updateBidStart());

            const notesRes = await axiosAuthenticated.put(notesPath, {BidID: params.BidID, Amount: params.Amount});

            const state = getState();
            const notesState = {...state.bids};
            let searchParams = {...notesState.searchParams};
            let pagination = {...notesState.pagination};

            const notesGetRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});

            if (notesRes && notesRes.status === 200) {
                if (notesGetRes && notesGetRes.status === 200) {
                    const notes = notesGetRes.data.data.list;
                    dispatch(updateBidSuccess({
                        records: notes,
                        searchParams: searchParams
                    }));

                    pagination.total = notesGetRes.data.data.count;
                    pagination.current = 1;
                    pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                    dispatch(fetchBidsSuccess({
                        records: notes,
                        searchParams: searchParams,
                        pagination: pagination
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateBidFail({error: error.message}));
        }
    }
};

export const fetchShipperBids = (payload) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid';
        try {
            dispatch(fetchBidsStart());

            const state = getState();
            const notesState = {...state.bids};
            let searchParams = {...notesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const notesRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesRes.data.data;
                dispatch(fetchBidsSuccess({
                    records: notes,
                    searchParams: searchParams
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchBidsFail({error: error.message}));
        }
    }
};

export const createBidRequest = (params) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid';
        try {
            dispatch(updateBidStart());

            const notesRes = await axiosAuthenticated.post(notesPath, {
                LoadID: params.LoadID,
                Candidates: params.Candidates
            });
            const searchParams = {
                LoadID: params.LoadID
            }
            const notesGetRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesGetRes.data.data;
                if (notesGetRes && notesGetRes.status === 200) {
                    dispatch(updateBidSuccess({
                        records: notes,
                        searchParams: searchParams
                    }));
                    dispatch(fetchBidsSuccess({
                        records: notes,
                        searchParams: searchParams,
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateBidFail({error: error.message}));
        }
    }
};

export const updateBidRequest = (params) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid';
        try {
            dispatch(updateBidStart());

            const notesRes = await axiosAuthenticated.put(notesPath, {id: params.id, Candidates: params.Candidates});
            const searchParams = {
                LoadID: params.LoadID
            }
            const notesGetRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesGetRes.data.data;
                if (notesGetRes && notesGetRes.status === 200) {
                    dispatch(updateBidSuccess({
                        records: notes,
                        searchParams: searchParams
                    }));
                    dispatch(fetchBidsSuccess({
                        records: notes,
                        searchParams: searchParams,
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateBidFail({error: error.message}));
        }
    }
};

export const selectBidWinner = (params) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid';
        try {
            dispatch(updateBidStart());

            const notesRes = await axiosAuthenticated.patch(notesPath, {BidID: params.BidID});
            const searchParams = {
                LoadID: params.LoadID
            }
            const notesGetRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesGetRes.data.data;
                if (notesGetRes && notesGetRes.status === 200) {
                    dispatch(updateBidSuccess({
                        records: notes,
                        searchParams: searchParams
                    }));
                    dispatch(fetchBidsSuccess({
                        records: notes,
                        searchParams: searchParams,
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateBidFail({error: error.message}));
        }
    }
};

export const cancelBidRequest = (params) => {
    return async (dispatch, getState) => {
        const notesPath = '/bid';
        try {
            dispatch(updateBidStart());

            const notesRes = await axiosAuthenticated.delete(notesPath, {
                params: {
                    LoadID: params.LoadID
                }
            });
            const searchParams = {
                LoadID: params.LoadID
            }
            const notesGetRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesGetRes.data.data;
                if (notesGetRes && notesGetRes.status === 200) {
                    dispatch(updateBidSuccess({
                        records: notes,
                        searchParams: searchParams
                    }));
                    dispatch(fetchBidsSuccess({
                        records: notes,
                        searchParams: searchParams,
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateBidFail({error: error.message}));
        }
    }
};
