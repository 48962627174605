import React, { useMemo, useState } from 'react';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Card } from 'antd';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isEmpty } from 'lodash';

const Account = props => {
    const account = props.record;

    const [imageUrl, setImageUrl] = useState(null);

    useMemo(() => {
        // Get Document for Displaying
        if (account.logoFileId) {
            axiosAuthenticated.get('/documents/' + account.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [account]);

    return (
        <>
            <Card size="small" title="Company Details" style={{ marginTop: 16, marginBottom: 16 }}>
                <Row gutter={16}>
                    <Col xs={24} md={24} lg={12} xl={12}>
                        <Avatar shape="square" size={128} icon={<UserOutlined />} src={imageUrl} />
                        {account.irisId !== undefined && account.irisId !== null ? <DataRow title="Account ID" value={account.irisId} dataType="String" /> : null}
                        {account.name !== undefined && account.name !== null ? <DataRow title="Account Name" value={account.name} dataType="String" /> : null}
                        {account.ein !== undefined && account.ein !== null ? <DataRow title="EIN" value={account.ein} dataType="String" /> : null}
                        {account.email !== undefined && account.email !== null ? <DataRow title="Email Address" value={account.email} dataType="String" /> : null}
                        {account.phone !== undefined && account.phone !== null ? <DataPhoneNumberRow title="Phone Number" phone={account.phone} phoneExt={account.phoneExt} /> : null}
                        {account.afterHoursPhone !== undefined && account.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone Number" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                        {account.fax !== undefined && account.fax !== null ? <DataRow title="Fax Number" value={account.fax} dataType="PhoneNumber" /> : null}
                        <DataRow title="Is Active" value={account.isActive} dataType="Boolean" />
                        <DataRow title="Is Verified" value={account.isVerified} dataType="Boolean" />
                        <DataRow title="Is Carrier" value={account.isCarrier} dataType="Boolean" />
                        <DataRow title="Is Shipper" value={account.isShipper} dataType="Boolean" />
                        <DataRow title="Is Receiver" value={account.isReceiver} dataType="Boolean" />
                        <DataRow title="Is Producer" value={account.isProducer} dataType="Boolean" />
                    </Col>
                    <Col xs={24} md={24} lg={12} xl={12}>
                        {account.pointOfContact !== undefined && account.pointOfContact !== null ? <DataContactInfoRow title="Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.pointOfContact} /> : null}
                        {account.companyAddress !== undefined && account.companyAddress !== null ? <DataAddressRow title="Company Address" valueStyle={{ marginLeft: 16 }} address={account.companyAddress} /> : null}
                        {account.shippingAddress !== undefined && account.shippingAddress !== null ? <DataAddressRow title="Mailing Address" valueStyle={{ marginLeft: 16 }} address={account.shippingAddress} /> : null}
                    </Col>
                </Row>
            </Card>

            {account.shipper !== undefined && account.shipper !== null && !isEmpty(account.shipper) ? (
                <Card size="small" title="Shipper Details" style={{ marginTop: 16, marginBottom: 16 }}>
                    <Row gutter={16}>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            {account.shipper.irisId !== undefined && account.shipper.irisId !== null ? <DataRow title="Shipper ID" value={account.shipper.irisId} dataType="String" /> : null}
                            {account.shipper.email !== undefined && account.shipper.email !== null ? <DataRow title="Email Address" value={account.shipper.email} dataType="String" /> : null}
                            {account.shipper.phone !== undefined && account.shipper.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={account.shipper.phone} phoneExt={account.shipper.phoneExt} /> : null}
                            {account.shipper.fax !== undefined && account.shipper.fax !== null ? <DataRow title="Fax Number" value={account.shipper.fax} dataType="PhoneNumber" /> : null}
                            {account.shipper.dbNum !== undefined && account.shipper.dbNum !== null ? <DataRow title="D&B #" value={account.shipper.dbNum} dataType="String" /> : null}
                            {account.shipper.blueBookNum !== undefined && account.shipper.blueBookNum !== null ? <DataRow title="Produce Blue Book #" value={account.shipper.blueBookNum} dataType="String" /> : null}
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            {account.shipper.pointOfContact !== undefined && account.shipper.pointOfContact !== null ? <DataContactInfoRow title="Shipper Operations Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.shipper.pointOfContact} /> : null}
                            {account.shipper.accountsPayable !== undefined && account.shipper.accountsPayable !== null ? <DataContactInfoRow title="Contact Information for Accounts Payable" valueStyle={{ marginLeft: 16 }} contact={account.shipper.accountsPayable} /> : null}
                            {account.shipper.billingAddress !== undefined && account.shipper.billingAddress !== null ? <DataAddressRow title="Accounts Payable Address" valueStyle={{ marginLeft: 16 }} address={account.shipper.billingAddress} /> : null}
                        </Col>
                    </Row>
                </Card>
            ) : null}
            {account.carrier !== undefined && account.carrier !== null && !isEmpty(account.carrier) ? (
                <Card size="small" title="Carrier Details" style={{ marginTop: 16, marginBottom: 16 }}>
                    <Row gutter={16}>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            {account.carrier.irisId !== undefined && account.carrier.irisId !== null ? <DataRow title="Carrier ID" value={account.carrier.irisId} dataType="String" /> : null}
                            {account.carrier.email !== undefined && account.carrier.email !== null ? <DataRow title="Email Address" value={account.carrier.email} dataType="String" /> : null}
                            {account.carrier.phone !== undefined && account.carrier.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={account.carrier.phone} phoneExt={account.carrier.phoneExt} /> : null}
                            {account.carrier.fax !== undefined && account.carrier.fax !== null ? <DataRow title="Fax Number" value={account.carrier.fax} dataType="PhoneNumber" /> : null}
                            {account.carrier.dotNum !== undefined && account.carrier.dotNum !== null ? <DataRow title="DOT #" value={account.carrier.dotNum} dataType="String" /> : null}
                            {account.carrier.mcNum !== undefined && account.carrier.mcNum !== null ? <DataRow title="MC #" value={account.carrier.mcNum} dataType="String" /> : null}
                            {account.carrier.preferredPaymentMethod !== undefined && account.carrier.preferredPaymentMethod !== null ? <DataRow title="Preferred Payment Method" value={account.carrier.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                            {account.carrier.smsBasic !== undefined && account.carrier.smsBasic !== null ? <DataSMSBasicRow title="SMS Basic Scores" valueStyle={{ marginLeft: 16 }} smsBasic={account.carrier.smsBasic} /> : null}
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={12}>
                            {account.carrier.pointOfContact !== undefined && account.carrier.pointOfContact !== null ? <DataContactInfoRow title="Carrier Operations Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.carrier.pointOfContact} /> : null}
                            {account.carrier.accountsReceivable !== undefined && account.carrier.accountsReceivable !== null ? <DataContactInfoRow title="Contact Information for Accounts Receivable" valueStyle={{ marginLeft: 16 }} contact={account.carrier.accountsReceivable} /> : null}
                            {account.carrier.shippingAddress !== undefined && account.carrier.shippingAddress !== null ? <DataAddressRow title="Accounts Receivable Address" valueStyle={{ marginLeft: 16 }} address={account.carrier.shippingAddress} /> : null}
                        </Col>
                    </Row>
                </Card>
            ) : null}
        </>
    );
};

export default Account;