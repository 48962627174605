import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import StringFormatter from "../../shared/stringFormatter";
import {isEmpty} from 'lodash';

const stringFormatter = new StringFormatter();

//#region Messages Functions

export const fetchMessagesStart = () => {
    return {
        type: actionTypes.FETCH_MESSAGES_START
    }
};

export const fetchMessagesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_MESSAGES_SUCCESS,
        payload: payload
    }
};

export const fetchMessagesFail = (payload) => {
    return {
        type: actionTypes.FETCH_MESSAGES_FAIL,
        payload: payload
    }
};

export const clearMessages = () => {
    return {
        type: actionTypes.CLEAR_MESSAGES
    }
};

export const addMessageStart = () => {
    return {
        type: actionTypes.ADD_MESSAGE_START
    }
};

export const insertMessage = (payload) => {
    return {
        type: actionTypes.ADD_MESSAGE,
        payload: payload
    }
};

export const addMessageSuccess = () => {
    return {
        type: actionTypes.ADD_MESSAGE_SUCCESS
    }
};

export const addMessageFail = (payload) => {
    return {
        type: actionTypes.ADD_MESSAGE_FAIL,
        payload: payload
    }
};

export const addMessageErrorClear = () => {
    return {
        type: actionTypes.ADD_MESSAGE_ERROR_CLEAR
    }
};

export const addMessageLoadingClear = () => {
    return {
        type: actionTypes.ADD_MESSAGE_LOADING_CLEAR
    }
};

export const updateMessageStart = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_START
    }
};

export const changeMessage = (payload) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        payload: payload
    }
};

export const changeSingleMessage = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_MESSAGE,
        payload: payload
    }
};

export const updateMessageSuccess = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_SUCCESS
    }
};

export const updateMessageFail = (payload) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_FAIL,
        payload: payload
    }
};

export const updateMessageErrorClear = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_ERROR_CLEAR
    }
};

export const updateMessageLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_MESSAGE_LOADING_CLEAR
    }
};

export const cancelAddMessage = () => {
    return {
        type: actionTypes.CANCEL_ADD_MESSAGE
    }
};

export const cancelUpdateMessage = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_MESSAGE
    }
};

//#endregion

//#region Messages Methods

export const fetchMessages = (entityType, entityId, userId, pageSize = 10, loadId = null) => {
    return async (dispatch) => {
        const messagesPath = '/loadMessages';
        try {
            if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
                dispatch(fetchMessagesStart());

                let searchParams = {
                    sort: 'createdAt',
                    order: 'desc',
                    page: 1,
                    size: pageSize
                };

                if (entityType === "STAFF") {
                    //searchParams.toStaff = true;
                } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                    searchParams.accountIds = entityId;
                } else if (entityType === "DRIVER") {
                    searchParams.userIds = userId;
                }

                if (loadId !== undefined && loadId !== null) {
                    searchParams.loadId = loadId;
                }

                const messagesRes = await axiosAuthenticated.get(messagesPath, {params: {...searchParams}});
                if (messagesRes && messagesRes.status === 200) {
                    const messagesData = messagesRes.data.data;
                    const otherData = messagesRes.data;

                    const transformedMessagesData = messagesData.map(item => {
                        return {
                            ...item,
                            _id: item.id,
                            text: item.message,
                            createdAt: new Date(stringFormatter.toFormattedString("MomentDateTime", item.createdAt, null)),
                            user: {
                                _id: item.senderId,
                                name: item.senderFirstName + ' ' + item.senderLastName
                            },
                            loadId: item.loadId,
                            read: item.read
                        };
                    });

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;
                    let data = [...transformedMessagesData];

                    dispatch(fetchMessagesSuccess({
                        records: data,
                        loadId: loadId,
                        currentPage: searchParams.page,
                        totalPages: totalPages,
                        totalCount: totalCount,
                        pageSize: searchParams.size
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchMessagesFail({error: error.message}));
        }
    }
};

export const fetchMoreMessages = (entityType, entityId, userId, loadId = null) => {
    return async (dispatch, getState) => {
        const messagesPath = '/loadMessages';
        try {
            if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
                const state = getState();
                const messagesState = {...state.messages};

                dispatch(fetchMessagesStart());

                let currentPage = 1;
                let pageSize = 10;
                let updatedMessages = [];
                let existingRecord = {};
                if (loadId === null && messagesState.records['ALL'] !== undefined && messagesState.records['ALL'] !== null && !isEmpty(messagesState.records['ALL'])) {
                    existingRecord = {...messagesState.records['ALL']};
                    currentPage = existingRecord.currentPage + 1;
                    pageSize = existingRecord.pageSize;
                    updatedMessages = [...existingRecord.records];
                } else if (loadId !== null && messagesState.records[loadId] !== undefined && messagesState.records[loadId] !== null && !isEmpty(messagesState.records[loadId])) {
                    existingRecord = {...messagesState.records[loadId]};
                    currentPage = existingRecord.currentPage + 1;
                    pageSize = existingRecord.pageSize;
                    updatedMessages = [...existingRecord.records];
                }

                let searchParams = {
                    sort: 'createdAt',
                    order: 'desc',
                    page: currentPage,
                    size: pageSize
                };

                if (entityType === "STAFF") {
                    //searchParams.toStaff = true;
                } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                    searchParams.accountIds = entityId;
                } else if (entityType === "DRIVER") {
                    searchParams.userIds = userId;
                }

                if (loadId !== undefined && loadId !== null) {
                    searchParams.loadId = loadId;
                }

                const messagesRes = await axiosAuthenticated.get(messagesPath, {params: {...searchParams}});
                if (messagesRes && messagesRes.status === 200) {
                    const messagesData = messagesRes.data.data;
                    const otherData = messagesRes.data;

                    const transformedMessagesData = messagesData.map((item) => {
                        return {
                            ...item,
                            _id: item.id,
                            text: item.message,
                            createdAt: new Date(stringFormatter.toFormattedString("MomentDateTime", item.createdAt, null)),
                            user: {
                                _id: item.senderId,
                                name: item.senderFirstName + ' ' + item.senderLastName
                            },
                            loadId: item.loadId,
                            read: item.read
                        };
                    });

                    transformedMessagesData.forEach((item) => {
                        if (updatedMessages.find(i => i.id === item.id) === undefined) {
                            updatedMessages.push(item);
                        }
                    });

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;

                    dispatch(fetchMessagesSuccess({
                        records: updatedMessages,
                        loadId: loadId,
                        currentPage: currentPage,
                        totalPages: totalPages,
                        totalCount: totalCount,
                        pageSize: pageSize
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchMessagesFail({error: error.message}));
        }
    }
};

export const sendMessage = (msgs, userId, load) => {
    return async (dispatch) => {
        const messagesPath = '/loadMessages';
        try {
            if (load !== undefined && load !== null && load.id !== undefined && load.id !== null && msgs !== undefined && msgs !== null && msgs.length > 0 && userId !== undefined && userId !== null) {
                dispatch(addMessageStart());

                let accountIds = [];
                let userIds = [];
                if (load.assignedCarrierId !== undefined && load.assignedCarrierId !== null) {
                    accountIds.push(load.assignedCarrierId);
                }
                if (load.shipperId !== undefined && load.shipperId !== null) {
                    accountIds.push(load.shipperId);
                }
                if (load.drivers !== undefined && load.drivers !== null && load.drivers.length > 0) {
                    load.drivers.forEach((driver) => {
                        userIds.push(driver.userId);
                    });
                }
                if (load.assignedAccountRepUsers !== undefined && load.assignedAccountRepUsers !== null && load.assignedAccountRepUsers.length > 0) {
                    load.assignedAccountRepUsers.forEach((assignedAccountRepUser) => {
                        if (assignedAccountRepUser.user_id !== undefined && assignedAccountRepUser.user_id !== null) {
                            userIds.push(assignedAccountRepUser.user_id);
                        }
                    });
                }

                for (let i = 0; i < msgs.length; i++) {
                    let newMessage = {
                        message: msgs[i].text,
                        senderId: userId,
                        loadId: load.id,
                        loadIrisId: load.irisId,
                        accountIds: accountIds,
                        userIds: userIds
                    };

                    const messagesRes = await axiosAuthenticated.post(messagesPath, newMessage);
                    if (messagesRes && messagesRes.status === '201') {
                        dispatch(addMessage(messagesRes.data));
                    }
                }

                dispatch(addMessageSuccess());
                dispatch(addMessageErrorClear());
                dispatch(addMessageLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addMessageFail({error: error.message}));
        }
    }
};

// All API Calls should use Async/Await
export const addMessage = (payload) => {
    return async (dispatch) => {
        let newMessage = {
            ...payload,
            _id: payload.id,
            text: payload.message,
            createdAt: new Date(stringFormatter.toFormattedString("MomentDateTime", payload.createdAt, null)),
            user: {
                _id: payload.senderId,
                name: payload.senderFirstName + ' ' + payload.senderLastName
            },
            loadId: payload.loadId,
            read: payload.read
        };

        dispatch(insertMessage(newMessage));
    }
};

// All API Calls should use Async/Await
export const updateMessage = (messageId, payload) => {
    return async (dispatch) => {
        const messagesPath = '/loadMessages';
        try {
            dispatch(updateMessageStart());

            const messagesRes = await axiosAuthenticated.put(messagesPath + `/${messageId}`, {...payload});
            if (messagesRes && messagesRes.status === 200) {
                const message = messagesRes.data;

                let updatedMessage = {
                    ...message,
                    _id: message.id,
                    text: message.message,
                    createdAt: new Date(stringFormatter.toFormattedString("MomentDateTime", message.createdAt, null)),
                    user: {
                        _id: message.senderId,
                        name: message.senderFirstName + ' ' + message.senderLastName
                    },
                    loadId: message.loadId,
                    read: message.read
                };

                dispatch(changeMessage(updatedMessage));

                dispatch(updateMessageSuccess());
                dispatch(updateMessageErrorClear());
                dispatch(updateMessageLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateMessageFail({error: error.message}));
        }
    }
};

export const readMessage = (messageId, userId) => {
    return async (dispatch, getState) => {
        const messagesPath = '/loadMessages';
        try {
            dispatch(updateMessageStart());

            const state = getState();
            const messagesState = {...state.messages};

            let existingMessages = [];
            let existingRecord = {};
            if (messagesState.records['ALL'] !== undefined && messagesState.records['ALL'] !== null && !isEmpty(messagesState.records['ALL'])) {
                existingRecord = {...messagesState.records['ALL']};
                existingMessages = [...existingRecord.records];
            }

            let readRecord = {readAt: Date.now().toString(), readBy: userId};

            let recordToUpdateIndex = existingMessages.findIndex(item => {
                return item.id === messageId
            });
            if (recordToUpdateIndex !== -1) {
                let recordToUpdate = existingMessages[recordToUpdateIndex];
                //console.log(recordToUpdate);
                if (recordToUpdate !== undefined && recordToUpdate !== null) {
                    if (!recordToUpdate.read.some(item => {
                        return item.readBy === userId
                    })) {
                        recordToUpdate.read.push(readRecord);

                        const messagesRes = await axiosAuthenticated.put(messagesPath + `/${messageId}`, {'add_read': [readRecord]});
                        if (messagesRes && messagesRes.status === 200) {
                            dispatch(changeMessage(recordToUpdate));

                            dispatch(updateMessageSuccess());
                            dispatch(updateMessageErrorClear());
                            dispatch(updateMessageLoadingClear());
                        }
                    }
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateMessageFail({error: error.message}));
        }
    }
};

export const readMultipleMessages = (messageIds, userId) => {
    return async (dispatch, getState) => {
        const messagesPath = '/loadMessagesReadAll';
        try {
            if (messageIds !== undefined && messageIds !== null && messageIds.length > 0) {
                dispatch(updateMessageStart());

                const state = getState();
                const messagesState = {...state.messages};

                let existingMessages = [];
                let existingRecord = {};
                if (messagesState.records['ALL'] !== undefined && messagesState.records['ALL'] !== null && !isEmpty(messagesState.records['ALL'])) {
                    existingRecord = {...messagesState.records['ALL']};
                    existingMessages = [...existingRecord.records];
                }

                messageIds.forEach((messageId) => {
                    let readRecord = {readAt: Date.now().toString(), readBy: userId};

                    let recordToUpdateIndex = existingMessages.findIndex(item => {
                        return item.id === messageId
                    });
                    if (recordToUpdateIndex !== -1) {
                        let recordToUpdate = existingMessages[recordToUpdateIndex];
                        //console.log(recordToUpdate);
                        if (recordToUpdate !== undefined && recordToUpdate !== null) {
                            if (!recordToUpdate.read.some(item => {
                                return item.readBy === userId
                            })) {
                                recordToUpdate.read.push(readRecord);

                                dispatch(changeMessage(recordToUpdate));
                            }
                        }
                    }
                });

                const messagesRes = await axiosAuthenticated.post(messagesPath, {ids: messageIds});

                if (messagesRes && messagesRes.status === 204) {
                    dispatch(updateMessageSuccess());
                    dispatch(updateMessageErrorClear());
                    dispatch(updateMessageLoadingClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateMessageFail({error: error.message}));
        }
    }
};

//#endregion