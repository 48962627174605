import axios from 'axios';

export const onRadioButtonChange = (event) => {
    console.log(event.target.value);
    return event.target.value;
};

export const onCheckBoxChange = (event) => {
    console.log(event.target.checked);
    return event.target.checked;
};

export const checkEmailAddressReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckEmailAddressLoading: true,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
                emailAddressExists: action.payload.exists,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: true,
                checkEmailAddressError: action.payload.error,
                emailAddressExists: action.payload.exists,
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckEmailAddressLoading: false,
                checkEmailAddressHasError: false,
                checkEmailAddressError: null,
                emailAddressExists: null,
            };
        default:
            throw new Error();
    }
};

export const checkPhoneNumberReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isCheckPhoneNumberLoading: true,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
                phoneNumberExists: action.payload.exists,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: true,
                checkPhoneNumberError: action.payload.error,
                phoneNumberExists: action.payload.exists,
            };
        case "FETCH_CLEAR":
            return {
                ...state,
                isCheckPhoneNumberLoading: false,
                checkPhoneNumberHasError: false,
                checkPhoneNumberError: null,
                phoneNumberExists: null,
            };
        default:
            throw new Error();
    }
};

export const checkEmailAddress = async (emailAddress, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        const result = await axios(`/rest-auth/user/checker/?email=${emailAddress}`, { cancelToken });
        if (result && result.status === 200) {
            if (result.data.exist === false) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'A user account already exists with this Email Address.', exists: true } });
            }
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const checkPhoneNumber = async (phoneNumber, dispatch, cancelToken) => {
    dispatch({ type: "FETCH_START" });
    try {
        const result = await axios(`/rest-auth/user/checker/?phone=${phoneNumber}`, { cancelToken });
        if (result && result.status === 200) {
            if (result.data.exist === false) {
                dispatch({ type: "FETCH_SUCCESS", payload: { exists: false } });
            } else {
                dispatch({ type: "FETCH_FAILURE", payload: { error: 'A user account already exists with this Phone Number.', exists: true } });
            }
        }
    } catch (err) {
        dispatch({ type: "FETCH_FAILURE", payload: { error: err.message, exists: null } });
    }
};

export const isValidEmailAddress = (emailAddress) => {
    console.log(emailAddress)
    var pattern = /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i;
    console.log(pattern.test(emailAddress))
    if (pattern.test(emailAddress)) {
        return true;
    } else {
        return false;
    }
};