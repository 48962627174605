import React, { useState } from 'react';
import { Card, Drawer } from "antd";
import classes from './LoadAssetsCard.module.scss';
import EditAsset from "../../components/EditAsset/EditAsset";
import AssignLoadAsset from "../../components/AssignLoadAsset/AssignLoadAsset";
import ChangeLoadAsset from "../../components/ChangeLoadAsset/ChangeLoadAsset";
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import DataInfoActionRow from '../../components/DataInfoActionRow/DataInfoActionRow';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadAssetsCard = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Asset";
    const load = props.load;
    const carrierId = props.load.assignedCarrierId !== undefined ? props.load.assignedCarrierId : null;
    const assets = props.load.assets !== undefined && props.load.assets !== null ? props.load.assets : null;
    const entityType = props.entityType;

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadAsset cancel={toggleNewEntity} load={load} carrierId={carrierId} />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadAsset cancel={toggleChangeEntity} asset={selectedRecord} load={load} carrierId={carrierId} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadAsset cancel={toggleRemoveEntity} asset={selectedRecord} load={load} carrierId={carrierId} />
        </Drawer>
    );

    const extra = (entityType === "STAFF" || entityType === "CARRIER") ? (
        <AddEditChangeRemoveButtons
            showAdd={carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'CREATED' && load.loadStatus !== 'PENDING' && load.loadStatus !== 'APPROVED' && load.loadStatus !== 'PENDING_RATE_CONFIRMATION' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'CANCELLED') ? true : false} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
            showEdit={false} editText='' editAction={null}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    const actionButtons = (record) => {
        if (entityType === "STAFF") {
            return (
                <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                    showAdd={false} addText='' addAction={null}
                    showEdit={carrierId !== null ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                    showChange={carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CANCELLED') ? true : false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleChangeEntity(); }}
                    showRemove={carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CANCELLED') ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
                />
            );
        } else {
            return null;
        }
    };

    const renderAssetDetails = () => {
        if (assets !== undefined && assets !== null && assets.length > 0) {
            return (
                <>
                    {assets.map((asset, index) => (
                        <DataInfoActionRow key={`asset-row-${index}`} title={`Asset ${index + 1}`} value={`${stringFormatter.toFormattedString("AssetType", asset.assetType, null)}${asset.trailerType !== undefined && asset.trailerType !== null ? ' (' + asset.trailerType.description + ')' : ''} (${asset.licensePlateNum} ${asset.licensePlateState})`} dataType="String" actions={actionButtons(asset)} />
                    ))}
                </>
            );
        } else {
            return (
                <i>No Assets Assigned.</i>
            );
        }
    };

    if (load !== undefined && load !== null && (entityType === 'STAFF' || entityType === 'CARRIER') && (load.loadStatus === 'BOOKED' || load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED')) {
        return (
            <>
                <Card title={props.title} bordered={true} className={classes.card} style={assets !== null && assets.length > 0 ? props.style : { ...props.style, ...missingEntityStyle }} headStyle={assets !== null && assets.length > 0 ? props.headStyle : { ...props.headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    {renderAssetDetails()}
                </Card>
                {editEntityComponents}
                {newEntityComponents}
                {changeEntityComponents}
                {removeEntityComponents}
            </>
        );
    } else {
        return null;
    }
};

export default LoadAssetsCard;