import * as actionTypes from "../actions/actionTypes";
import {
    clearRecords,
    clearUpdateRecordError,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordLoading,
    cancelUpdateRecord,
    cancelAddRecord,
    addRecordStart,
    addRecordSuccess,
    addRecordFail,
    clearAddRecordError,
    clearAddRecordLoading,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    addRecordToBottom,
    updateRecord,
    removeRecord,
    updateSingleRecord
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    searchParams: {},
    pagination: {},
    addRecordId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNTS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_ACCOUNTS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_ACCOUNTS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_ACCOUNTS: return clearRecords(state);
        case actionTypes.FETCH_ACCOUNT_START: return fetchRecordStart(state);
        case actionTypes.FETCH_ACCOUNT_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_ACCOUNT_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_ACCOUNT: return clearRecord(state);
        case actionTypes.ADD_ACCOUNT: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_ACCOUNT_START: return addRecordStart(state);
        case actionTypes.ADD_ACCOUNT_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_ACCOUNT_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_ACCOUNT_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_ACCOUNT_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.UPDATE_ACCOUNT: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_ACCOUNT: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_ACCOUNT_START: return updateRecordStart(state);
        case actionTypes.UPDATE_ACCOUNT_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_ACCOUNT_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_ACCOUNT_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_ACCOUNT_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.CANCEL_ADD_ACCOUNT: return cancelAddRecord(state);
        case actionTypes.CANCEL_UPDATE_ACCOUNT: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_ACCOUNT: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;