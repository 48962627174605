import React from "react";
import { Col, Row } from "antd";

const FormItemDisplay = (props) => {
    const label = props.label;
    const labelCol = props.labelCol;
    const wrapperCol = props.wrapperCol;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';
    const required = props.required !== undefined && props.required !== null ? props.required : false;
    const noColon = props.noColon !== undefined && props.noColon !== null ? props.noColon : false;
    const style = props.style;

    if (format === 'vertical') {
        return (
            <>
                {label &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col span={24} style={style}>
                        {props.children}
                    </Col>
                </Row>
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <Row gutter={[8, 8]}>
                {label && <Col {...labelCol} style={{ textAlign: 'right' }}>{required ? '* ' : ''}{label}{noColon ? '' : ':'}</Col>}
                <Col {...wrapperCol} style={props.style}>
                    {props.children}
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default FormItemDisplay;