import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Modal, Row, Col, Spin, Select, Empty, Card, message } from 'antd';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { isEmpty } from 'lodash';
import classes from './DispatchAddDriver.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import MomentDate from '../../shared/dateFormatter';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import TitleBlockWithIcon from '../TitleBlockWithIcon/TitleBlockWithIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faPallet, faRoute } from '@fortawesome/free-solid-svg-icons';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import DataTemperatureRangeRow from '../DataTemperatureRangeRow/DataTemperatureRangeRow';
import { driverAssignedEmailTemplate } from "../../shared/emailTemplates";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const DispatchAddDriver = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const carrierId = props.carrierId;
    const load = props.load;
    const stops = props.stops;
    const drivers = props.drivers;
    const bundledLoads = props.bundledLoads;
    const driverLoads = props.driverLoads;
    const shifts = props.shifts;
    const onDone = props.cancel;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useRefs

    const schedulerRef = useRef(null);

    //#endregion
    //#region useStates

    const [selectedEvent, setSelectedEvent] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState([]);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loads, setLoads] = useState([]);
    const [isLoadingLoads, setIsLoadingLoads] = useState(false);
    const [scheduledShifts, setScheduledShifts] = useState([]);
    const [isLoadingScheduledShifts, setIsLoadingScheduledShifts] = useState(false);
    const [events, setEvents] = useState([]);
    const [driverMap, setDriverMap] = useState([]);
    const [duration, setDuration] = useState(null);

    //#endregion
    //#region toggles

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    //#endregion
    //#region calendar methods

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate

        setSelectedEvent(clickInfo.event.extendedProps);

        setShowDetails(true);
    };

    const prev = () => {
        if (schedulerRef.current !== null && load !== undefined && load !== null && !isEmpty(load) && duration !== undefined && duration !== null) {
            let calendarApi = schedulerRef.current.getApi();

            calendarApi.prev();
            // console.log(calendarApi);
            let getDate = calendarApi.getDate();
            // console.log(moment(getDate));

            let startDate = moment(getDate);
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(duration, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    };

    const next = () => {
        if (schedulerRef.current !== null && load !== undefined && load !== null && !isEmpty(load) && duration !== undefined && duration !== null) {
            let calendarApi = schedulerRef.current.getApi();

            calendarApi.next();
            // console.log(calendarApi);
            let getDate = calendarApi.getDate();
            // console.log(moment(getDate));

            let startDate = moment(getDate);
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(duration, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    };

    //#endregion
    //#region driver methods

    const sendEmailToDriver = (accountUserId) => {
        let accountUser = drivers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                let firstName = accountUser.firstName;
                let email = accountUser.email;
                const { body, subject } = driverAssignedEmailTemplate(firstName, load.irisId)
                let newEmail = {
                    body: body,
                    subject: subject,
                    email: email
                };
                axiosAuthenticated.post('/email', newEmail).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };

    const sendNotificationToDriver = (accountUserId) => {
        let accountUser = drivers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            let firstName = accountUser.firstName;
            let userId = accountUser.userId;

            let newNotification = {
                message: `Hi ${firstName}, you have been assigned to a new load: ${load.irisId}. Please open up the Pulogix app for more details.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been assigned to a new load`,
                severity: "CRITICAL",
                userIds: [userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_ASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const onAddDriver = (accountUserId) => {
        if (accountUserId !== undefined && accountUserId !== null && load !== undefined && load !== null && !isEmpty(load)) {
            let driverIds = load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0 ? load.driverIds : [];

            // check to see if it is a duplicate and if it is found in the driverIds array already, throw an error
            if (driverIds.length > 0) {
                if (driverIds.find(i => i === accountUserId) !== undefined) {
                    message.error('The driver you chose is already assigned to this load.', 5);
                    return;
                } else {
                    driverIds.push(accountUserId);
                }
            } else {
                driverIds.push(accountUserId);
            }

            let payload = {
                driverIds: driverIds
            };
            //console.log(payload);

            if (isBundledLoad === true) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
            sendNotificationToDriver(accountUserId);
            sendEmailToDriver(accountUserId);
            //toggleShowDetails();
        }
    };

    const done = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        onDone();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (drivers !== undefined && drivers !== null && drivers.length > 0) {
            if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                let resourceMapArray = drivers.filter(i => selectedAccountUserIds.includes(i.id)).map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}, Available: ${accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            } else {
                let resourceMapArray = drivers.map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}, Available: ${accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            }
        } else {
            setResourceMap([]);
        }
    }, [drivers, selectedAccountUserIds]);

    // useMemo(() => {
    //     if (startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null && resourceMap !== undefined && resourceMap !== null && resourceMap.length > 0) {
    //         let driverIds = resourceMap.map((item) => item.id);
    //         let searchParams = {
    //             page: 1,
    //             size: 100000,
    //             isDeleted: false,
    //             eta: true,
    //             driverIds: driverIds,
    //             loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
    //         };

    //         searchParams["pickUpDateTime:gte"] = startDate;
    //         searchParams["pickUpDateTime:lte"] = endDate;

    //         setIsLoadingLoads(true);
    //         axiosAuthenticated.post('/orch/loads', { ...searchParams }).then((loadsRes) => {
    //             // console.log(loadsRes.data.data);
    //             setLoads(loadsRes.data.data);
    //             setIsLoadingLoads(false);
    //         }).catch((error) => {
    //             // console.log(error.message);
    //             setLoads([]);
    //             setIsLoadingLoads(false);
    //         });
    //     } else {
    //         setLoads([]);
    //     }
    // }, [startDate, endDate, resourceMap]);

    useMemo(() => {
        let loadsArray = [];
        if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
            driverLoads.forEach((loadObj) => {
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
            bundledLoads.forEach((loadObj, loadIndex) => {
                loadObj.loadName = loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name + ' (' + (loadIndex + 1) + ')' : loadObj.irisId);
                loadObj.loadNumber = loadIndex + 1;
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        setLoads(loadsArray);
        // console.log(loadsArray);
    }, [driverLoads, bundledLoads]);

    useMemo(() => {
        if (startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null && carrierId !== undefined && carrierId !== null) {
            let searchParams = {
                page: 1,
                size: 10000,
                sort: 'startDateTime',
                order: 'asc',
                isDeleted: false
            };

            searchParams["startDateTime:gte"] = startDate;
            searchParams["startDateTime:lte"] = endDate;

            if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                searchParams.accountUserIds = selectedAccountUserIds;
            }

            if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                searchParams.shiftId = selectedShiftIds;
            }

            if (carrierId !== undefined && carrierId !== null) {
                searchParams.accountId = carrierId;
            }

            setIsLoadingScheduledShifts(true);
            axiosAuthenticated.post('/scheduledShifts/bulk/get', { ...searchParams }).then((scheduledShiftsRes) => {
                // console.log(scheduledShiftsRes.data.data);
                setScheduledShifts(scheduledShiftsRes.data.data);
                setIsLoadingScheduledShifts(false);
            }).catch((error) => {
                // console.log(error.message);
                setScheduledShifts([]);
                setIsLoadingScheduledShifts(false);
            });
        } else {
            setScheduledShifts([]);
        }
    }, [carrierId, selectedAccountUserIds, selectedShiftIds, startDate, endDate]);

    useMemo(() => {
        let allEvents = [];
        if (scheduledShifts && scheduledShifts.length > 0) {
            scheduledShifts.forEach((scheduledShift) => {
                allEvents.push({
                    id: scheduledShift.id,
                    title: scheduledShift.shift.name,
                    start: moment(scheduledShift.startDateTime).toDate(),
                    end: moment(scheduledShift.endDateTime).toDate(),
                    backgroundColor: scheduledShift.shift.backgroundColor,
                    textColor: scheduledShift.shift.textColor,
                    timeZone: scheduledShift.shift.timeZone,
                    resourceIds: scheduledShift.accountUserIds && scheduledShift.accountUserIds.length > 0 ? [...scheduledShift.accountUserIds] : [],
                    extendedProps: scheduledShift,
                    allDay: false,
                    eventType: 'ScheduledShift'
                });
            });
        }

        if (loads && loads.length > 0) {
            loads.forEach((load) => {
                allEvents.push({
                    id: load.id,
                    title: load.parentName ? load.parentName : (load.name ? load.name : load.irisId),
                    start: moment(load.pickUpDateTime).toDate(),
                    end: moment(load.eta).toDate(),
                    backgroundColor: '#ffffff',
                    textColor: '#000000',
                    timeZone: load.etaTimeZone,
                    resourceIds: load.driverIds && load.driverIds.length > 0 ? [...load.driverIds] : [],
                    extendedProps: load,
                    allDay: false,
                    eventType: 'Load'
                });
            });
        }

        // console.log(allEvents);
        setEvents(allEvents);
    }, [scheduledShifts, loads]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            done();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (load !== undefined && load !== null && !isEmpty(load)) {
            let approxLoadDuration = load ? load.approxLoadDuration : 1; // in minutes
            let approxLoadDurationInDays = Math.ceil((approxLoadDuration / 60) / 24); // in days 
            setDuration(approxLoadDurationInDays);
            // console.log(`duration: ${approxLoadDurationInDays}`);
            let startDate = load ? moment(load.pickUpDateTime) : moment();
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(approxLoadDurationInDays, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    }, [load]);

    useMemo(() => {
        if (drivers !== undefined && drivers !== null && drivers.length > 0) {
            let driversArray = [];
            if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                drivers.filter(i => selectedAccountUserIds.includes(i.id)).forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = scheduledShifts.filter(i => i.accountUserIds && i.accountUserIds.length > 0 && i.accountUserIds.includes(accountUser.id));

                    if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (driverScheduledShifts && driverScheduledShifts.length > 0) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            } else {
                drivers.forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = scheduledShifts.filter(i => i.accountUserIds && i.accountUserIds.length > 0 && i.accountUserIds.includes(accountUser.id));

                    if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (driverScheduledShifts && driverScheduledShifts.length > 0) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            }

            setDriverMap(driversArray);
            // console.log(driversArray);
        } else {
            setDriverMap([]);
        }
    }, [drivers, bundledLoads, driverLoads, scheduledShifts, selectedShiftIds, selectedAccountUserIds]);

    //#endregion
    //#region stop display methods

    const getStopNameDisplay = (stop) => {
        let stopNameDisplay = null;
        if (stop.stopLocation && stop.stopLocation.name) {
            stopNameDisplay = `${stop.stopLocation.name}`;
        } else if (stop.stopType) {
            stopNameDisplay = `${Enums.StopTypes.getValueByName(stop.stopType)}`;
        }
        return stopNameDisplay;
    };

    const getStopLocationDisplay = (stop) => {
        let stopLocationDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address && stop.stopLocation.address.city && stop.stopLocation.address.state) {
            stopLocationDisplay = `${stop.stopLocation.address.city}, ${stop.stopLocation.address.state}`;
        } else {
            stopLocationDisplay = getStopNameDisplay(stop);
        }

        return stopLocationDisplay;
    };

    const getStopAddressDisplay = (stop) => {
        let stopAddressDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address) {
            stopAddressDisplay = `${stringFormatter.toFormattedAddress(stop.stopLocation.address)}`;
        }

        return stopAddressDisplay;
    };

    const getAppointmentDetailsDisplay = (stop) => {
        let appointmentDetailsDisplay = null;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (startDateObj !== null && endDateObj !== null) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (apptDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (requestedDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${requestedDateObj.dateString}, TBD`;
            }
        }

        return appointmentDetailsDisplay;
    };

    const getAppointmentContactDisplay = (stop) => {
        let appointmentContactDisplay = null;

        if (stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null && !isEmpty(stop.apptPointOfContact)) {
            appointmentContactDisplay = `Appt Contact: ${stop.apptPointOfContact.name ? stop.apptPointOfContact.name + ', ' : ''}${stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}`;
        }

        return appointmentContactDisplay;
    };

    const getAppointmentCallAheadDisplay = (stop) => {
        let appointmentCallAheadDisplay = null;
        let apptCallAheadDateObj = stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptCallAheadDateTime, stop.timeZone) : null;
        if (apptCallAheadDateObj !== null) {
            appointmentCallAheadDisplay = `Appt Call Ahead: ${apptCallAheadDateObj.dateString}, ${apptCallAheadDateObj.timeString} ${apptCallAheadDateObj.timeZone}`;
        }

        return appointmentCallAheadDisplay;
    };

    const renderStop = (stop, showApptContact = false) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            const stopLocation = getStopLocationDisplay(stop);
            const stopAddress = getStopAddressDisplay(stop);
            const apptDetails = getAppointmentDetailsDisplay(stop);
            const apptCallAhead = getAppointmentCallAheadDisplay(stop);
            const apptContact = getAppointmentContactDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopIconContainer}>
                            {stopIcon ? (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} className={classes.stopIcon} />) : null}
                        </div>
                        <div className={classes.stopLocation}>{stopLocation}</div>
                    </div>
                    {stopAddress ? (<div className={classes.stopAddress}>{stopAddress}</div>) : null}
                    {apptDetails ? (<div className={classes.apptDetails}>{apptDetails}</div>) : null}
                    {apptCallAhead ? (<div className={classes.apptDetails}>{apptCallAhead}</div>) : null}
                    {apptContact && showApptContact === true ? (<div className={classes.apptDetails}>{apptContact}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = () => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (pickUpAndDropOffStops.length > 0) {
                return pickUpAndDropOffStops.map((stop) => { return renderStop(stop, false); });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    const getOriginAndDestinationTimes = (origin, destination) => {
        let timeDisplay = '';
        let originStartObj = getStopStartDateTime(origin);
        let destinationEndObj = getStopEndDateTime(destination);
        let startDateObj = originStartObj.startDateTime;
        let endDateObj = destinationEndObj.endDateTime;
        if (startDateObj !== null && endDateObj !== null) {
            let startTime = originStartObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateObj.timeString;
            let endTime = destinationEndObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateObj.timeString;
            if (startDateObj.dateString === endDateObj.dateString) {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endTime} ${startDateObj.timeZone}`;
            } else {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endDateObj.dateString}, ${endTime} ${startDateObj.timeZone}`;
            }
        }

        return timeDisplay;
    };

    //#endregion
    //#region load display methods

    const getLoadStatusDisplay = (status) => {
        if (entityType === 'STAFF') {
            return stringFormatter.toFormattedString("LoadDisplayStatus", status, null);
        } else if (entityType === 'SHIPPER') {
            return stringFormatter.toFormattedString("ShipperLoadDisplayStatus", status, null);
        } else if (entityType === 'CARRIER' || entityType === 'DRIVER') {
            return stringFormatter.toFormattedString("CarrierLoadDisplayStatus", status, null);
        } else {
            return null;
        }
    };

    const renderLoadDetails = (load, stops = null) => {
        return (
            <Card
                title="Load & Route Details"
                style={{ border: '1px solid #D8D8D8' }}
                bodyStyle={{ minHeight: 400, padding: 0 }}
                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
            >
                <Row>
                    <Col span={12} style={{ padding: 12 }}>
                        <TitleBlockWithIcon
                            style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                            icon={<FontAwesomeIcon className={classes.icon} icon={faPallet} />}
                            title={
                                <span>Load ID: {load.irisId}<br />Status: {getLoadStatusDisplay(load.loadStatus)}</span>
                            }
                        />
                        <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.preCoolReefer !== null && load.equipmentNeeds.preCoolReefer !== undefined ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                            {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                            ) : null}
                            {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={load.driverRequirements} dataType="DriverRequirement" />
                            ) : null}
                        </div>
                    </Col>
                    <Col span={12} style={{ borderLeft: '1px solid #D8D8D8', padding: 12 }}>
                        <TitleBlockWithIcon
                            style={{ margin: '40px auto 40px auto' }}
                            icon={<FontAwesomeIcon className={classes.icon} icon={faRoute} />}
                            title={
                                <span>Trip Miles: {stringFormatter.toFormattedString('Distance', load.practicalDistance, load.practicalDistanceUnit)}<br />
                                Number of Stops: {stops !== null ? stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').length : load.numberOfStops}</span>
                            }
                        />
                        <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                            {stops !== null ? (renderStops(stops)) : (load.origin !== undefined && load.origin !== null && load.destination !== undefined && load.destination !== null ? (
                                <>
                                    {renderStop(load.origin, false)}
                                    {renderStop(load.destination, false)}
                                </>
                            ) : null)}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    };

    //#endregion
    //#region resource display methods

    const renderResourceContent = (info) => {
        //console.log(info);
        let resource = info.resource;
        let accountUser = resource.extendedProps;

        return (
            <div>
                <div>
                    <span><strong>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</strong></span>
                </div>
                <div>
                    <span><i>Available: {accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}</i></span>
                </div>
                <div>
                    <Button type="primary" onClick={(e) => { e.preventDefault(); e.stopPropagation(); onAddDriver(accountUser.id); }}>Assign Driver</Button>
                </div>
            </div>
        );
    };

    const getDriverLoadsDisplay = (accountUser, driverBundledLoads, driverOtherLoads) => {
        let driverLoadRows = [];
        let loadsToShow = [];

        if (driverBundledLoads !== undefined && driverBundledLoads !== null && driverBundledLoads.length > 0) {
            driverBundledLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (driverOtherLoads !== undefined && driverOtherLoads !== null && driverOtherLoads.length > 0) {
            driverOtherLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (loadsToShow !== undefined && loadsToShow !== null && loadsToShow.length > 0) {
            loadsToShow.forEach((loadObj) => {
                let title = loadObj.loadName ? loadObj.loadName : (loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId));
                let time = getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                driverLoadRows.push(
                    <div key={`driver-load-${accountUser.id}-${loadObj.id}`}>
                        <span><strong>{`${title} (${time})`}</strong></span>
                    </div>
                );
            });
        }

        return (
            <div key={`driver-loads-${accountUser.id}`} style={{ marginTop: 24, marginBottom: 24 }}>
                <div><strong>Loads Assigned:</strong></div>
                {driverLoadRows && driverLoadRows.length > 0 ? driverLoadRows : <div><span>No Other Loads</span></div>}
            </div>
        );
    };

    const getDriverScheduledShiftsDisplay = (accountUser, driverScheduledShifts) => {
        let driverScheduledShiftRows = [];
        if (driverScheduledShifts !== undefined && driverScheduledShifts !== null && driverScheduledShifts.length > 0) {
            driverScheduledShifts.forEach((scheduledShift) => {
                let shift = scheduledShift.shift;
                let title = shift && shift.name ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let timeZone = moment.tz(shift.timeZone).zoneAbbr();
                let time = `${scheduledDate}, ${shift ? shift.startTime : ''} - ${shift ? shift.endTime : ''}${timeZone ? ' ' + timeZone : ''}`;

                driverScheduledShiftRows.push(
                    <div key={`driver-scheduled-shift-${accountUser.id}-${title}-${time}`}>
                        <span><strong>{`${title} (${time})`}</strong></span>
                    </div>
                );
            });
        }

        return (
            <div key={`driver-scheduled-shifts-${accountUser.id}`}>
                <div><strong>Shift(s):</strong></div>
                {driverScheduledShiftRows && driverScheduledShiftRows.length > 0 ? driverScheduledShiftRows : <div><span>--</span></div>}
            </div>
        );
    };

    //#endregion
    //#region event display methods

    const eventDetailsComponents = (eventObj) => {
        if (eventObj !== undefined && eventObj !== null && !isEmpty(eventObj)) {
            if (eventObj.eventType === 'Load') {
                let loadObj = eventObj;
                let title = loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId);
                return (
                    <Modal
                        title={`${title}`}
                        visible={showDetails}
                        width="80%"
                        style={{ top: 0 }}
                        onCancel={(e) => { toggleShowDetails(); }}
                        onOk={(e) => { toggleShowDetails(); }}
                        footer={[
                            <Button type="default" onClick={(e) => { toggleShowDetails(); }}>Close</Button>
                        ]}
                    >
                        {renderLoadDetails(loadObj)}
                    </Modal>
                );
            } else if (eventObj.eventType === 'ScheduledShift') {
                let scheduledShift = eventObj;
                let shift = scheduledShift.shift;
                let title = shift && shift.name ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let time = `${scheduledDate}, ${shift ? shift.startTime : ''} - ${shift ? shift.endTime : ''}`;

                return (
                    <Modal
                        title={`${title}: ${time}`}
                        visible={showDetails}
                        onCancel={(e) => { toggleShowDetails(); }}
                        onOk={(e) => { toggleShowDetails(); }}
                        footer={[
                            <Button type="default" onClick={(e) => { toggleShowDetails(); }}>Close</Button>
                        ]}
                    >
                        <div>
                            <div>
                                <span><strong>{scheduledShift.title ? scheduledShift.title : ''}</strong></span>
                            </div>
                            {scheduledShift.accountUsers && scheduledShift.accountUsers.length > 0 ? scheduledShift.accountUsers.map((accountUser) => {
                                return (
                                    <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                                );
                            }) : null}
                        </div>
                    </Modal>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const renderEventContent = (eventInfo) => {
        //console.log(eventInfo);
        let eventObj = eventInfo && eventInfo.event ? eventInfo.event.extendedProps : {};
        if (eventObj !== undefined && eventObj !== null && !isEmpty(eventObj)) {
            if (eventObj.eventType === 'Load') {
                let load = eventObj;
                let title = load.parentName ? load.parentName : (load.name ? load.name : load.irisId);
                let pickUp = load.origin ? momentDate.fromUtcToTimeZone(load.origin.requestedDateTime, load.origin.timeZone) : '';
                //let dropOff = load.destination ? momentDate.fromUtcToTimeZone(load.destination.requestedDateTime, load.destination.timeZone) : '';

                return (
                    <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', whiteSpace: 'normal' }}>
                        <div>
                            <span style={{ whiteSpace: 'normal' }}><b>{pickUp}</b> <i>{title}</i></span>
                        </div>
                    </div>
                );
            } else if (eventObj.eventType === 'ScheduledShift') {
                let scheduledShift = eventObj;
                let shift = scheduledShift && scheduledShift.shift ? scheduledShift.shift : null;
                if (scheduledShift && shift) {
                    return (
                        <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', whiteSpace: 'normal' }}>
                            <div>
                                <span style={{ whiteSpace: 'normal' }}><b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i></span>
                            </div>
                        </div>
                    );
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingScheduledShifts === true || isLoadingLoads === true}>
            {/* <Row gutter={[24, 24]}>
                <Col span={24}>
                    <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                        {renderLoadDetails(load, stops)}
                    </div>
                </Col>
            </Row> */}
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Select
                        placeholder="Drivers: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                    >
                        {drivers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                    </Select>
                </Col>
                <Col span={24}>
                    <Select
                        placeholder="Shifts: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedShiftIds(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                    >
                        {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                    </Select>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {driverMap && driverMap.length > 0 ? (
                        <>
                            <div style={{ height: 500, overflowY: 'auto' }}>
                                {driverMap.map((driver) => {
                                    let accountUser = driver.accountUser;
                                    return (
                                        <Card
                                            key={`dispatch-add-driver-card-${accountUser.id}`}
                                            style={{ marginBottom: 12, borderRadius: '15px' }}
                                            title={
                                                <div style={{ display: 'flex' }}>
                                                    <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faIdCard} /></div>
                                                    <div className={classes.textContainer}>
                                                        <span><strong>{accountUser.firstName} {accountUser.lastName}</strong><br /><i>Available: {accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}</i></span>
                                                    </div>
                                                </div>
                                            }
                                            extra={<Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={(e) => { onAddDriver(driver.id); }} />}
                                        >
                                            {getDriverScheduledShiftsDisplay(accountUser, driver.scheduledShifts)}
                                            {getDriverLoadsDisplay(accountUser, driver.driverBundledLoads, driver.driverOtherLoads)}
                                        </Card>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}
                    {/* {duration !== undefined && duration !== null ? (
                        <FullCalendar
                            ref={schedulerRef}
                            plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                            headerToolbar={{
                                left: 'prevButton,nextButton',
                                center: 'title',
                                right: ''
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            views={{
                                resourceTimelineWeek: {
                                    type: 'resourceTimeline',
                                    duration: { days: duration },
                                    slotDuration: { days: 1 },
                                    buttonText: 'week'
                                }
                            }}
                            slotLabelFormat={[
                                { month: '2-digit', year: 'numeric', day: '2-digit' }, // top level of text
                                { weekday: 'short' } // lower level of text
                            ]}
                            resourceAreaHeaderContent="Employees"
                            initialView='resourceTimelineWeek'
                            initialDate={load ? load.pickUpDateTime : null}
                            resources={resourceMap}
                            resourceOrder={'firstName,LastName'}
                            editable={false}
                            selectable={false}
                            allDaySlot={false}
                            dayMaxEventRows={false}
                            dayMaxEvents={false}
                            nextDayThreshold={'06:00:00'}
                            nowIndicator={true}
                            now={load ? load.pickUpDateTime : null}
                            //slotMinWidth={100}
                            weekends={true}
                            expandRows={true}
                            height="500px"
                            eventsSet={(events) => {
                                // console.log(events);

                                if (load !== undefined && load !== null && !isEmpty(load)) {
                                    // console.log(load);
                                    let loadPickUpDate = moment(load.pickUpDateTime);
                                    let loadDropOffDate = moment(load.eta);
                                    // console.log(loadPickUpDate);
                                    // console.log(loadDropOffDate);
                                    let days = document.getElementsByClassName('fc-day');

                                    for (let i = 0; i < days.length; i++) {
                                        let el = days.item(i);
                                        // console.log(el);
                                        let thisDate = el.getAttribute('data-date');
                                        if (moment(thisDate).isBetween(loadPickUpDate, loadDropOffDate, "day", "[]")) {
                                            // console.log(thisDate);


                                            if (el !== undefined && el !== null && el.className !== undefined && el.className !== null) {
                                                let classNames = el.className.split(' ');
                                                // console.log(classNames)
                                                if (!classNames.includes(classes['fc-load-highlight'])) {
                                                    el.className = classNames.join(' ') + ' ' + classes['fc-load-highlight'];
                                                }
                                            }
                                        } else {
                                            if (el !== undefined && el !== null && el.classNames !== undefined && el.classNames !== null) {
                                                let classNames = el.classNames.split(' ');
                                                if (classNames.includes(classes['fc-load-highlight'])) {
                                                    el.className = classNames.filter(i => i !== classes['fc-load-highlight']).join(' ');
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                            // resourcesSet={(resources) => { console.log(resources) }}
                            events={events}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            resourceLabelContent={renderResourceContent}
                            customButtons={{
                                nextButton: {
                                    text: 'next',
                                    click: next
                                },
                                prevButton: {
                                    text: 'prev',
                                    click: prev
                                }
                            }}
                        />
                    ) : null} */}
                </Col>
            </Row>
            {eventDetailsComponents(selectedEvent)}
        </Spin>
    );
};

export default DispatchAddDriver;