import React, { useMemo, useState } from 'react';
import {Alert, Spin, Input, Checkbox} from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset'
import StringFormatter from "../../shared/stringFormatter";

const stringFormatter = new StringFormatter();

const EditLoadRejectPostpone = props => {
    const load = props.load;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = (data) => {
        // validate to make sure file was attached
        dispatch(actionCreators.updateLoadRejectPostponeDate({}, load.id));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Proposed New Date</legend>
                        <FormItem {...formItemLayout}
                                  label={`I reject the new proposed date ${stringFormatter.toFormattedString("MomentDateTime", load.suggestedPostponeDate, null)}` } required format="horizontal"
                              render={({ onChange, onBlur, value, name }) => (
                                  <Checkbox
                                      onBlur={onBlur}
                                      onChange={e => onChange(e.target.checked)}
                                      checked={value}
                                      name={name}
                                  />
                              )}
                              rules={{
                                  validate: {
                                      mustBeTrue: checked => checked === true || 'Reject must be checked' // value must be true
                                  }
                              }}
                              name="approvedRateConfirmation"
                              defaultValue={false}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Reject" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadRejectPostpone;