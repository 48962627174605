import React, { Fragment } from "react";
import { Row, Col, Avatar, Divider, Breadcrumb } from 'antd';
import { withRouter } from "react-router-dom";
import classes from "./Header.module.scss";

const IconLink = ({ src, text }) => (
    <a className="example-link">
        <img className="example-link-icon" src={src} alt={text} />
        {text}
    </a>
);

const TopContent = ({ title, titleHasAction = false, title2, title2Icon, title3, title3Icon, title4, title4Icon, subtitle, subtitle2, subtitle3, logo, icon, highlights, actions, titleStyle, title2Style, title3Style, title4Style, subtitleStyle, subtitle2Style, subtitle3Style, iconStyle, logoStyle }) => {
    return (
        <Row>
            {(icon !== undefined && icon !== null && (logo === undefined || logo === null)) ? (
                <div style={{ height: 64, width: 64, marginRight: 12 }}>
                    <Avatar icon={icon} shape="circle" size={64} style={iconStyle ? { color: '#000000', ...iconStyle } : { color: '#000000' }} />
                </div>
            ) : ((logo !== undefined && logo !== null) ? (
                <div style={{ height: 64, width: 64, marginRight: 12 }}>
                    <Avatar src={logo} shape="circle" size={64} style={logoStyle} />
                </div>
            ) : null)}
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}>
                <Row>
                    <Col span={24}>
                        {title !== undefined && title !== null && titleHasAction === false ? (
                            <span className="ant-page-header-heading-title" style={titleStyle} title={title}>{title}</span>
                        ) : null}
                        {title !== undefined && title !== null && titleHasAction === true ? (
                            <span className="ant-page-header-heading-title" style={titleStyle}>{title}</span>
                        ) : null}
                        {actions}
                        {subtitle !== undefined && subtitle !== null && (
                            <span className="ant-page-header-heading-sub-title" style={subtitleStyle} title={subtitle}>{subtitle}</span>
                        )}
                        {subtitle2 !== undefined && subtitle2 !== null && (
                            <>
                                <Divider type="vertical" />
                                <span className="ant-page-header-heading-sub-title" style={subtitle2Style} title={subtitle2}>{subtitle2}</span>
                            </>
                        )}
                        {subtitle3 !== undefined && subtitle3 !== null && (
                            <>
                                <Divider type="vertical" />
                                <span className="ant-page-header-heading-sub-title" style={subtitle3Style} title={subtitle3}>{subtitle3}</span>
                            </>
                        )}
                    </Col>
                </Row>
                {title2 !== undefined && title2 !== null ? (
                    <Row>
                        <div>
                            {title2 !== undefined && title2 !== null && title2Icon !== undefined && title2Icon !== null ? (
                                <span style={{ marginRight: 12 }}>{title2Icon}</span>
                            ) : null}
                            {title2 !== undefined && title2 !== null ? (
                                <span className="ant-page-header-heading-sub-title" style={title2Style} title={title2}>{title2}</span>
                            ) : null}
                            {title3 !== undefined && title3 !== null && title3Icon !== undefined && title3Icon !== null ? (
                                <span style={{ marginRight: 12 }}>{title3Icon}</span>
                            ) : null}
                            {title3 !== undefined && title3 !== null ? (
                                <span className="ant-page-header-heading-sub-title" style={title3Style} title={title3}>{title3}</span>
                            ) : null}
                            {title4 !== undefined && title4 !== null && title4Icon !== undefined && title4Icon !== null ? (
                                <span style={{ marginRight: 12 }}>{title4Icon}</span>
                            ) : null}
                            {title4 !== undefined && title4 !== null ? (
                                <span className="ant-page-header-heading-sub-title" style={title4Style} title={title4}>{title4}</span>
                            ) : null}
                        </div>
                    </Row>
                ) : null}
            </div>

            {highlights !== undefined && highlights !== null && highlights.length > 0 && (
                <div style={{ flex: 1 }}>
                    <Row style={{ alignItems: 'center', float: 'right' }}>
                        {highlights.filter(i => i.value !== undefined && i.value !== null).map((highlight, index, arr) => {
                            if (index < (arr.length - 1)) {
                                if (highlight.value) {
                                    return (
                                        <Fragment key={`highlight-fragment-${index}`}>
                                            <div className={classes.highlight} key={`highlight-${index}`}>
                                                <div className={classes.highlightTitle}>{highlight.title}</div>
                                                <div className={classes.highlightContent}>{highlight.url ? (<a href={highlight.url} style={{ textDecoration: 'underline' }}>{highlight.value}</a>) : `${highlight.value}`}</div>
                                            </div>
                                            <Divider type="vertical" style={{ height: 24 }} />
                                        </Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            } else {
                                if (highlight.value) {
                                    return (
                                        <div className={classes.highlight} key={`highlight-${index}`}>
                                            <div className={classes.highlightTitle}>{highlight.title}</div>
                                            <div className={classes.highlightContent}>{highlight.url ? (<a href={highlight.url} style={{ textDecoration: 'underline' }}>{highlight.value}</a>) : `${highlight.value}`}</div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        })}
                    </Row>
                </div>
            )}
        </Row>
    );
};

const Content = ({ children, extra }) => {
    return (
        <div className="content">
            <div className="main">{children}</div>
            <div className="extra">{extra}</div>
        </div>
    );
};

const Header = ({ breadcrumbs, footer, style, ...props }) => {
    return (
        <div className={classes.header} style={style}>
            {breadcrumbs &&
                <Breadcrumb style={{ marginBottom: 8 }}>
                    {breadcrumbs.map((breadcrumb, index) => {
                        if (breadcrumb.location !== undefined && breadcrumb.location !== null) {
                            return (
                                <Breadcrumb.Item key={`breadcrumb-${index}`}><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.history.push(breadcrumb.location); }}>{breadcrumb.breadcrumbName}</a></Breadcrumb.Item>
                            );
                        } else {
                            return (
                                <Breadcrumb.Item key={`breadcrumb-${index}`}><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.history.push({ pathname: breadcrumb.path }); }}>{breadcrumb.breadcrumbName}</a></Breadcrumb.Item>
                            );
                        }
                    })}
                </Breadcrumb>
            }
            <TopContent
                {...props}
            />
            {footer ? footer : null}
        </div>
    );
};

export default withRouter(Header);