import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearUpdateRecordError,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearAddRecordLoading,
    clearUpdateRecordLoading,
    updateObject,
    cancelAddRecord,
    cancelUpdateRecord
} from "../utility";
import { isEmpty } from "lodash";

const fetchMessagesSuccess = (state, action) => {
    if (action.records !== undefined && action.records !== null) {
        //console.log(`action: ${JSON.stringify(action.records)}`);
        const copyOfState = { ...state };
        let newMessages = {};
        if (action.loadId === null) {
            if (copyOfState.records['ALL'] !== undefined && copyOfState.records['ALL'] !== null && !isEmpty(copyOfState.records['ALL'])) {
                newMessages['ALL'] = {
                    records: [...action.records],
                    currentPage: action.currentPage,
                    totalPages: action.totalPages,
                    totalCount: action.totalCount,
                    pageSize: action.pageSize
                };
            } else {
                newMessages['ALL'] = {
                    records: [...action.records],
                    currentPage: action.currentPage,
                    totalPages: action.totalPages,
                    totalCount: action.totalCount,
                    pageSize: action.pageSize
                };
            }
        } else {
            if (copyOfState.records[action.loadId] !== undefined && copyOfState.records[action.loadId] !== null && !isEmpty(copyOfState.records[action.loadId])) {
                newMessages[action.loadId] = {
                    records: [...action.records],
                    currentPage: action.currentPage,
                    totalPages: action.totalPages,
                    totalCount: action.totalCount,
                    pageSize: action.pageSize
                };
            } else {
                newMessages[action.loadId] = {
                    records: [...action.records],
                    currentPage: action.currentPage,
                    totalPages: action.totalPages,
                    totalCount: action.totalCount,
                    pageSize: action.pageSize
                };
            }
        }

        let existingMessages = { ...copyOfState.records };
        //console.log(`existingMessages: ${JSON.stringify(existingMessages)}`);
        return updateObject(state, {
            records: { ...existingMessages, ...newMessages },
            isLoading: false,
            error: null
        });
    }

    return updateObject(state, {
        isLoading: false,
        error: null
    });
};

const addMessageToTop = (state, newRecord) => {
    const copyOfState = { ...state };
    let newMessages = {};
    if (copyOfState.records['ALL'] !== undefined && copyOfState.records['ALL'] !== null && !isEmpty(copyOfState.records['ALL'])) {
        let existingList = [...copyOfState.records['ALL'].records];
        const index = existingList.findIndex(obj => obj.id === newRecord.id);

        // only add the record if it doesn't exist in the list already
        if (index === -1) {
            newMessages['ALL'] = {
                records: [newRecord, ...copyOfState.records['ALL'].records],
                currentPage: copyOfState.records['ALL'].currentPage,
                totalPages: copyOfState.records['ALL'].totalPages,
                totalCount: copyOfState.records['ALL'].totalCount + 1,
                pageSize: copyOfState.records['ALL'].pageSize
            };
        }
    } else {
        newMessages['ALL'] = {
            records: [newRecord],
            currentPage: 1,
            totalPages: 1,
            totalCount: 1,
            pageSize: 10
        };
    }

    if (copyOfState.records[newRecord.loadId] !== undefined && copyOfState.records[newRecord.loadId] !== null && !isEmpty(copyOfState.records[newRecord.loadId])) {
        let existingList = [...copyOfState.records[newRecord.loadId].records];
        const index = existingList.findIndex(obj => obj.id === newRecord.id);

        // only add the record if it doesn't exist in the list already
        if (index === -1) {
            newMessages[newRecord.loadId] = {
                records: [newRecord, ...copyOfState.records[newRecord.loadId].records],
                currentPage: copyOfState.records[newRecord.loadId].currentPage,
                totalPages: copyOfState.records[newRecord.loadId].totalPages,
                totalCount: copyOfState.records[newRecord.loadId].totalCount,
                pageSize: copyOfState.records[newRecord.loadId].pageSize
            };
        }
    } else {
        newMessages[newRecord.loadId] = {
            records: [newRecord],
            currentPage: 1,
            totalPages: 1,
            totalCount: 1,
            pageSize: 10
        };
    }

    let existingMessages = { ...copyOfState.records };
    return updateObject(state, {
        records: { ...existingMessages, ...newMessages }
    });
};

const updateMessage = (state, updatedRecord) => {
    const copyOfState = { ...state };
    let newMessages = {};
    if (copyOfState.records['ALL'] !== undefined && copyOfState.records['ALL'] !== null && !isEmpty(copyOfState.records['ALL'])) {
        let existingList = [...copyOfState.records['ALL'].records];
        const index = existingList.findIndex(obj => obj.id === updatedRecord.id);

        // only update if the record exists in the list
        if (index !== -1) {
            const updatedRecords = [
                ...existingList.slice(0, index), // everything before current obj
                updatedRecord,
                ...existingList.slice(index + 1), // everything after current obj
            ];

            newMessages['ALL'] = {
                records: [...updatedRecords],
                currentPage: copyOfState.records['ALL'].currentPage,
                totalPages: copyOfState.records['ALL'].totalPages,
                totalCount: copyOfState.records['ALL'].totalCount,
                pageSize: copyOfState.records['ALL'].pageSize
            };
        }
    }

    if (copyOfState.records[updatedRecord.loadId] !== undefined && copyOfState.records[updatedRecord.loadId] !== null && !isEmpty(copyOfState.records[updatedRecord.loadId])) {
        let existingList = [...copyOfState.records[updatedRecord.loadId].records];
        const index = existingList.findIndex(obj => obj.id === updatedRecord.id);

        // only update if the record exists in the list
        if (index !== -1) {
            const updatedRecords = [
                ...existingList.slice(0, index), // everything before current obj
                updatedRecord,
                ...existingList.slice(index + 1), // everything after current obj
            ];

            newMessages[updatedRecord.loadId] = {
                records: [...updatedRecords],
                currentPage: copyOfState.records[updatedRecord.loadId].currentPage,
                totalPages: copyOfState.records[updatedRecord.loadId].totalPages,
                totalCount: copyOfState.records[updatedRecord.loadId].totalCount,
                pageSize: copyOfState.records[updatedRecord.loadId].pageSize
            };
        }
    }

    if (!isEmpty(newMessages)) {
        let existingMessages = { ...copyOfState.records };
        return updateObject(state, {
            records: { ...existingMessages, ...newMessages }
        });
    } else {
        return state;
    }
};

const removeMessage = (state, recordToRemove) => {
    const copyOfState = { ...state };
    let newMessages = {};
    if (copyOfState.records['ALL'] !== undefined && copyOfState.records['ALL'] !== null && !isEmpty(copyOfState.records['ALL'])) {
        let existingList = [...copyOfState.records['ALL'].records];
        const index = existingList.findIndex(obj => obj.id === recordToRemove.id);

        // only update if the record exists in the list
        if (index !== -1) {
            existingList.splice(index, 1);

            newMessages['ALL'] = {
                records: [...existingList],
                currentPage: copyOfState.records['ALL'].currentPage,
                totalPages: copyOfState.records['ALL'].totalPages,
                totalCount: copyOfState.records['ALL'].totalCount - 1,
                pageSize: copyOfState.records['ALL'].pageSize
            };
        }
    }

    if (copyOfState.records[recordToRemove.loadId] !== undefined && copyOfState.records[recordToRemove.loadId] !== null && !isEmpty(copyOfState.records[recordToRemove.loadId])) {
        let existingList = [...copyOfState.records[recordToRemove.loadId].records];
        const index = existingList.findIndex(obj => obj.id === recordToRemove.id);

        // only update if the record exists in the list
        if (index !== -1) {
            existingList.splice(index, 1);

            newMessages[recordToRemove.loadId] = {
                records: [...existingList],
                currentPage: copyOfState.records[recordToRemove.loadId].currentPage,
                totalPages: copyOfState.records[recordToRemove.loadId].totalPages,
                totalCount: copyOfState.records[recordToRemove.loadId].totalCount - 1,
                pageSize: copyOfState.records[recordToRemove.loadId].pageSize
            };
        }
    }

    if (!isEmpty(newMessages)) {
        let existingMessages = { ...copyOfState.records };
        return updateObject(state, {
            records: { ...existingMessages, ...newMessages }
        });
    } else {
        return state;
    }
};

const clearMessages = (state) => {
    return updateObject(state, {
        records: {},
        isLoading: false,
        error: null
    });
};

const initialState = {
    records: {},
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MESSAGES_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_MESSAGES_SUCCESS: return fetchMessagesSuccess(state, action.payload);
        case actionTypes.FETCH_MESSAGES_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_MESSAGES: return clearMessages(state);
        case actionTypes.ADD_MESSAGE: return addMessageToTop(state, action.payload);
        case actionTypes.ADD_MESSAGE_START: return addRecordStart(state);
        case actionTypes.ADD_MESSAGE_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_MESSAGE_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_MESSAGE_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_MESSAGE_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.CANCEL_ADD_MESSAGE: return cancelAddRecord(state);
        case actionTypes.UPDATE_MESSAGE: return updateMessage(state, action.payload);
        case actionTypes.UPDATE_MESSAGE_START: return updateRecordStart(state);
        case actionTypes.UPDATE_MESSAGE_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_MESSAGE_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_MESSAGE_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_MESSAGE_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.CANCEL_UPDATE_MESSAGE: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_MESSAGE: return removeMessage(state, action.payload);
        default:
            return state;
    }
};

export default reducer;