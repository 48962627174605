import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataPhoneNumberRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, title = null, phone, phoneExt = null, noColon = false }) => {
    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            {phone !== undefined && phone !== null ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedPhoneNumber(phone, phoneExt)}</span>) : null}
        </div>
    );
};

export default DataPhoneNumberRow;