import React from 'react';
import DataRow from '../../components/DataRow/DataRow';

const Note = props => {
    const note = props.note;

    return (
        <DataRow value={note.body} dataType="String" />
    );
};

export default Note;