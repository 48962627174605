import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';

const { TabPane } = Tabs;

const Claim = props => {
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.claims.isRecordLoading);
    const error = useSelector(state => state.claims.recordError);
    const claim = useSelector(state => state.claims.record);

    const [tabKey, setTabKey] = useState('snapshot');

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchClaim(id));
    }, [id]);


    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Employees';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/claims` },
        path: previousPath ? previousPath : `/claims`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Claims',
    },
    {
        path: `/claims/${id ? id : null}`,
        breadcrumbName: `${claim ? claim.irisId : ''}`,
    },
    ];

    if (claim && claim.id === id) {
        return (
            <>
                <Header
                    title={claim ? claim.irisId : 'Loading...'}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruckMoving} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(claim !== null && isLoading === false) &&
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Claim Details"
                                                headStyle={{ fontSize: 14 }}
                                            >
                                                <Row>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {claim.irisId && <DataRow title="Claim ID" value={claim.irisId} dataType="String" />}
                                                        <DataRow title="Is Against Carrier" value={claim.isAgainstCarrier} dataType="Boolean" />
                                                        <DataRow title="Is Against Shipper" value={claim.isAgainstShipper} dataType="Boolean" />
                                                        {claim.claimStatus && <DataRow title="Claim Status" value={claim.claimStatus} dataType="ClaimStatus" />}
                                                        {claim.comments && <DataRow title="Comments" value={claim.comments} dataType="String" />}
                                                        {claim.count && <DataRow title="Count" value={claim.count} dataType="String" />}
                                                    </Col>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {claim.bolStatus && <DataRow title="BOL Status" value={claim.bolStatus} dataType="BOLStatus" />}
                                                        {claim.irisLoadId && <DataRow title="Load ID" value={claim.irisLoadId} dataType="String" />}
                                                        {claim.adjustment && <DataRow title="Adjustment Amount" value={claim.adjustment} dataType="Money" />}
                                                        {claim.bolNumber && <DataRow title="BOL #" value={claim.bolNumber} dataType="String" />}
                                                    </Col>
                                                </Row>
                                            </ComplexCard>
                                        </Col>
                                    </Row>
                                }
                            </Spin>
                        </div>
                    </TabPane>
                </Tabs>
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Claim);