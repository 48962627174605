import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import StringFormatter from '../../shared/stringFormatter';
import {
    cancelBidRequest,
    createBidRequest,
    fetchShipperBids,
    selectBidWinner,
    updateBidRequest
} from "../../store/actions/bids";
import {Button, Checkbox, Spin} from "antd";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import classes from './LoadBids.module.scss';
import {DeleteOutlined} from "@ant-design/icons";
const stringFormatter = new StringFormatter();

const LoadBids = props => {
    const loadId = props.loadId;
    const bids = props.bids;
    const passedCarriers = props.carriers;

    const entityId = useSelector(state => state.auth.entityId);
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.bids.isLoading);
    const isRecordUpdateLoading = useSelector(state => state.bids.isRecordUpdateLoading);
    const [carriers, setCarriers] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState({});

    useEffect(() => {
        refreshTable();
        if (!!passedCarriers) {
            const linkedCarriersTransformed = passedCarriers.map((item) => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
            setCarriers(linkedCarriersTransformed);
        } else {
            axiosAuthenticated.get('/linkedAccounts', {
                params: {
                    page: 1,
                    size: 10000,
                    isCarrier: true,
                    isDeleted: false,
                    accountId: entityId
                }
            }).then((linkedAccountsRes) => {
                if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                    const linkedCarriers = linkedAccountsRes.data.data;
                    if (linkedCarriers !== undefined && linkedCarriers !== null && linkedCarriers.length > 0) {
                        const linkedCarriersTransformed = linkedCarriers.map((item) => {
                            return {
                                id: item.linkedAccountId,
                                name: item.name ? item.name : (item.linkedAccount ? item.linkedAccount.name : '')
                            };
                        });
                        setCarriers(linkedCarriersTransformed);
                    } else {
                        setCarriers([]);
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        }
        return () => {

        };
    }, []);

    const refreshTable = () => {
        let searchParams = {

        };

        searchParams.LoadID = loadId;

        dispatch(fetchShipperBids(searchParams));
    };

    const selectWinner = (id) => {
        dispatch(selectBidWinner({
            BidID: id,
            LoadID: loadId
        }));
    };

    const createPostRequest = () => {
        dispatch(createBidRequest({
            Candidates: Object.keys(selectedCarriers).map((it) => {
                return {
                    AccountID: selectedCarriers[it].id,
                    Name: selectedCarriers[it].Name
                }
            }),
            LoadID: loadId
        }));
        setSelectedCarriers({})
    };

    const updatePostRequest = () => {
        const previousCandidates = !!bids.list && !!bids.list.length && bids.list[0].bids.map(it => {
            return {
                AccountID: it.carrier._id,
                Name: it.carrier.name,
            }
        })

        const currentCarriers = Object.keys(selectedCarriers).map((it) => {
            return {
                AccountID: selectedCarriers[it].id,
                Name: selectedCarriers[it].name
            }
        })

        dispatch(updateBidRequest({
            Candidates: [...previousCandidates, ...currentCarriers],
            id: !!bids.list && !!bids.list.length && bids.list[0]._id,
            LoadID: loadId
        }));
        setSelectedCarriers({})
    };

    const deleteBidRequest = (id) => {
        const Candidates = !!bids.list && !!bids.list.length && bids.list[0].bids
            .filter(it => it.carrier._id != id)
            .map(it => {
            return {
                AccountID: it.carrier._id,
                Name: it.carrier.name,
            }
        })

        dispatch(updateBidRequest({
            Candidates: Candidates,
            id: !!bids.list && !!bids.list.length && bids.list[0]._id,
            LoadID: loadId
        }));
    };

    const cancelBid = () => {
        dispatch(cancelBidRequest({
            LoadID: loadId
        }));
    }

    const haveBids = !!bids && !!bids.list && bids.list[0];
    console.log('haveBids', haveBids)
    const bidsHtml = haveBids ? bids.list[0].bids.map((it) => {
        return (
            <li className="table-row">
                <div className="col col-3">
                    {it.carrier.name} (MC Num {it.carrier.mcNum})
                </div>
                <div className="col col-3">
                    {it.IsBidSent ? it.Amount : "Did not received bid yet."}
                </div>

                <div className="col col-3">
                    {!haveBids.IsAccepted && (
                        <Button type={"primary"}
                                onClick={(e) => {
                                    selectWinner(it._id)
                                }}
                        >Select winner
                        </Button>
                    )}
                    {haveBids.WinnerAccountID === it.carrier._id && (
                        <Button onClick={(e) => {
                                }}>WINNER
                        </Button>
                    )}
                    {!haveBids.WinnerAccountID && (
                        <Button style={{marginLeft: "10px"}} type="default" icon={<DeleteOutlined />} onClick={() => {
                            deleteBidRequest(it.carrier._id)
                        }}>
                            DELETE
                        </Button>
                    )}
                </div>
            </li>
        )
    }) : null;

    const carriersHtml = carriers
        .filter(it => {
            if (!!bids.list && !!bids.list.length && bids.list[0].bids.find(elem => elem.carrier._id == it.id)) return false
            return true
        })
        .map((it) => {
        return (
            <Checkbox
                onChange={e => {
                    let selectedCarr = Object.assign({}, selectedCarriers);
                    if (!!e.target.checked) {
                        selectedCarr[it.id] = it;
                    } else {
                        delete selectedCarr[it.id];
                    }
                    setSelectedCarriers(selectedCarr)
                }}
                checked={!!selectedCarriers[it.id]}
                name={it.id}
                style={{marginLeft: "0"}}
            >{it.name}</Checkbox>
        )
    });

    return (
        <>
            {(isLoading || isRecordUpdateLoading)  && (
                <Spin size="large" />
            )}

            {(!isLoading && !isRecordUpdateLoading) && !!haveBids && (
                <>
                    <h1>Bids for this load {haveBids.IsAccepted ? " (Pool closed)" : ""}</h1>
                    <div className={""}>
                        <ul className="responsive-table">
                            <li className="table-header">
                                <div className="col col-3">
                                    Carrier
                                </div>
                                <div className="col col-3">
                                    Amount
                                </div>
                                <div className="col col-3">
                                    Actions
                                </div>
                            </li>
                        </ul>
                        <ul className="responsive-table">
                            {bidsHtml}
                        </ul>
                        {!haveBids.WinnerAccountID && (
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', gap: "5px" }}>
                                {carriersHtml}
                            </div>
                        )}
                    </div>
                </>
            )}

            {(!isLoading && !isRecordUpdateLoading) && !haveBids && (
                <>
                    <h1>No bid request sent for this load</h1>
                    <p>Select one or more carriers and send request</p>
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', marginBottom: '10px' }}>
                        <div className=""><Button onClick={(e) => {
                            let selectedCarr = carriers.reduce((memo, it) => {
                                memo[it.id] = it;
                                return memo;
                            }, {});
                            setSelectedCarriers(selectedCarr)
                        }}>Select all</Button></div>
                        <div style={{ marginLeft: "10px" }}><Button onClick={(e) => {
                            setSelectedCarriers({})
                        }}>Clear selection</Button></div>
                    </div>

                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', gap: "5px" }}>
                        {carriersHtml}
                    </div>
                    <Button type="primary"
                            className={classes.mt2}
                            onClick={(e) => {
                                createPostRequest()
                            }}
                            disabled={Object.keys(selectedCarriers).length === 0}>
                        Send bid request
                    </Button>
                </>
            )}

            {(!isLoading && !isRecordUpdateLoading) && !!haveBids && !haveBids.WinnerAccountID && (
                <>
                    <Button type="danger"
                            className={classes.mt2}
                            onClick={(e) => {
                                cancelBid()
                            }}>
                        Cancel bids
                    </Button>

                    {!!carriersHtml.length && (
                        <Button style={{ marginLeft: "10px" }} type="primary"
                                className={classes.mt2}
                                onClick={(e) => {
                                    updatePostRequest()
                                }}
                                disabled={Object.keys(selectedCarriers).length === 0}>
                            Send bid request
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

export default LoadBids;
