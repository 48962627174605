import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleDataCard.module.scss';

const { Title } = Typography;

const SimpleDataCard = props => {

    return (
        <Card bordered={true} title={props.title} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={props.extra}>
            <div><Title level={props.dataSize ? props.dataSize : 3}>{props.data}</Title></div>
        </Card>
    );
};

export default SimpleDataCard;