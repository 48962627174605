import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, InputNumber } from 'antd';
import classes from './Loads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataTable from '../../components/DataTable/DataTable';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();

const Loads = props => {
    //#region props and constants

    const shipperId = props.shipperId;
    const carrierId = props.carrierId;
    const driverId = props.driverId;
    const assignedAccountRepId = props.assignedAccountRepId;
    const singularEntityName = "Load";
    const pluralEntityName = "Loads";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isLoading);
    const loads = useSelector(state => state.loads.records);
    const pagination = useSelector(state => state.loads.pagination);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: shipperId !== null || carrierId !== null ? 'Loads' : 'All Loads',
            previousPageLocation: props.location,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: null,
                duplicateLoadId: null,
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    //#endregion
    //#region toggles

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
            let searchParams = {
                page: 1,
                size: 20,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                eta: true
            };

            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;

                let defaultLoadStatusFilter = ['BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;

                let defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }

                let defaultLoadStatusFilter = ['PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "STAFF") {
                if (shipperId !== undefined && shipperId !== null) {
                    searchParams.shipperId = shipperId;
                }

                if (carrierId !== undefined && carrierId !== null) {
                    searchParams.assignedCarrierId = carrierId;
                }

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }

                if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                    searchParams.assignedAccountRepIds = assignedAccountRepId;
                }

                let defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            }

            dispatch(actionCreators.fetchLoadsWithIncludes(searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadsSuccess({ pagination: pager }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            isDeleted: false,
            eta: true
        };

        if (filters.loadStatus !== undefined) {
            if (filters.loadStatus.length > 0) {
                searchParams.loadStatus = filters.loadStatus.join('|');
            }
        }

        if (entityType === "DRIVER") {
            searchParams.driverIds = entityId;
        } else if (entityType === "SHIPPER") {
            searchParams.shipperId = entityId;
        } else if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }
        } else if (entityType === "STAFF") {
            if (shipperId !== undefined && shipperId !== null) {
                searchParams.shipperId = shipperId;
            }

            if (carrierId !== undefined && carrierId !== null) {
                searchParams.assignedCarrierId = carrierId;
            }

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }

            if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                searchParams.assignedAccountRepIds = assignedAccountRepId;
            }
        }

        dispatch(actionCreators.fetchLoadsWithIncludes(searchParams));
    };

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); goToLoad(record.id); }}>
                    <InfoOutlined />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            sorter: (a, b) => compareByAlph(a.irisId, b.irisId),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Length',
            dataIndex: ['equipmentNeeds', 'trailerLength'],
            key: 'equipmentNeeds.trailerLength',
            render: (text, record) => { return stringFormatter.toFormattedString("Length", text, record.equipmentNeeds.trailerLengthUnit); },
        },
        {
            title: 'Max Weight',
            dataIndex: ['equipmentNeeds', 'trailerWeightCapacity'],
            key: 'equipmentNeeds.trailerWeightCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.equipmentNeeds.trailerWeightCapacityUnit); },
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (record.loadStatus === "IN_TRANSIT" || record.loadStatus === "AT_STOP") {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        }
    ];

    if (entityType === "STAFF" || entityType === "SHIPPER") {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'Created',
                    value: 'CREATED',
                },
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Pending Rate Confirmation',
                    value: 'PENDING_RATE_CONFIRMATION',
                },
                {
                    text: 'Booked',
                    value: 'BOOKED',
                },
                {
                    text: 'Scheduled',
                    value: 'SCHEDULED',
                },
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                },
                {
                    text: 'Completed',
                    value: 'COMPLETED',
                },
                {
                    text: 'Closed',
                    value: 'CLOSED',
                },
                {
                    text: 'Re-Opened',
                    value: 'REOPENED',
                },
                {
                    text: 'Cancelled',
                    value: 'CANCELLED',
                },
            ],
            filterMultiple: true,
            defaultFilteredValue: ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "IN_TRANSIT", "AT_STOP"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        });
    } else if (entityType === "CARRIER") {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'Pending Rate Confirmation',
                    value: 'PENDING_RATE_CONFIRMATION',
                },
                {
                    text: 'Booked',
                    value: 'BOOKED',
                },
                {
                    text: 'Scheduled',
                    value: 'SCHEDULED',
                },
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                },
                {
                    text: 'Completed',
                    value: 'COMPLETED',
                },
                {
                    text: 'Closed',
                    value: 'CLOSED',
                },
                {
                    text: 'Re-Opened',
                    value: 'REOPENED',
                },
            ],
            filterMultiple: true,
            defaultFilteredValue: ["PENDING_RATE_CONFIRMATION", "BOOKED", "SCHEDULED", "IN_TRANSIT", "AT_STOP"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        });
    }

    if ((shipperId !== undefined && shipperId !== null) || entityType === "SHIPPER") {
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
    } else if ((carrierId !== undefined && carrierId !== null) || entityType === "CARRIER") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
    } else if (entityType === "STAFF") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
        columns.push({
            title: 'Account Rep',
            dataIndex: 'assignedAccountRepIds',
            key: 'assignedAccountRepIds',
            render: (text, record) => {
                if (record.assignedAccountRepUsers !== undefined && record.assignedAccountRepUsers !== null && record.assignedAccountRepUsers.length > 0) {
                    return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                        return (
                            <div>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                        );
                    });
                } else {
                    return '';
                }
            },
        });
    }

    //#endregion
    //#region useEffects

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, entityType, entityId]);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    //#endregion

    return (
        <DataTable
            dataSource={loads}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {duplicateEntityComponents}
        </DataTable>
    );
};

export default withRouter(Loads);
