import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Modal, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import classes from './LoadMessages.module.scss';
import MessageReceived from '../MessageReceived/MessageReceived';
import MessageSent from '../MessageSent/MessageSent';
import MessageReceivedWithFile from '../MessageReceivedWithFile/MessageReceivedWithFile';
import MessageSentWithFile from '../MessageSentWithFile/MessageSentWithFile';
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Document from '../Document/Document';
import Form from '../Form/Form';
import { isEmpty } from 'lodash';

const scrollToRef = (ref) => {
    var element = document.getElementById("messages");

    // needed due to the case where the user switches tabs before the messageThread is updated and then the ref is no longer in the dom
    if (ref.current) {
        if (ref.current.offsetTop) {
            //console.log('current: ' + ref.current.offsetTop);
            element.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' });
            console.log('executed scroll');
        }
    }
};

const LoadMessages = props => {
    const load = props.load;
    const loadId = props.loadId;

    const scrollParentRef = useRef(null);

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const firstName = useSelector(state => state.auth.firstName);
    const lastName = useSelector(state => state.auth.lastName);
    const allMessages = useSelector(state => state.messages.records);
    const isLoading = useSelector(state => state.messages.isLoading);
    const error = useSelector(state => state.messages.error);
    const isAddMessageLoading = useSelector(state => state.messages.isRecordAddLoading);
    const addMessageError = useSelector(state => state.messages.addRecordError);

    const [showModal, setShowModal] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [messages, setMessages] = useState([]);
    const [documentId, setDocumentId] = useState(null);
    const [documentDisplayName, setDocumentDisplayName] = useState(null);
    const [atBottom, setAtBottom] = useState(true);
    const [currentHeight, setCurrentHeight] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [previousMessageCount, setPreviousMessageCount] = useState(0);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const onScroll = () => {
        if (scrollParentRef.current) {
            const bottom = scrollParentRef.current.scrollHeight;
            const scrollTop = scrollParentRef.current.scrollTop;
            const scrollBottom = (scrollParentRef.current.scrollTop + scrollParentRef.current.offsetHeight);
            if (scrollTop === 0 && isLoading === false) {
                setCurrentHeight(scrollParentRef.current.scrollHeight);
                console.log('set current height');
                onLoadEarlier();
            }

            if (scrollBottom === bottom) {
                //console.log('reached bottom');
                setAtBottom(true);
            } else {
                setAtBottom(false);
            }
        }
    };

    const createMessages = (messages) => {
        let messageRows = [];

        let count = 0;
        for (const message of messages) {
            let isSent = false;
            if (message.senderId === userId) {
                isSent = true;
            }

            if (isSent == true && message.documentFileIds !== undefined && message.documentFileIds !== null && message.documentFileIds.length > 0) {
                messageRows.push(<MessageSentWithFile key={count++} message={message} />);
            } else if (isSent == true && (message.documentFileIds === undefined || message.documentFileIds === null || message.documentFileIds.length === 0)) {
                messageRows.push(<MessageSent key={count++} message={message} />);
            } else if (isSent == false && message.documentFileIds !== undefined && message.documentFileIds !== null && message.documentFileIds.length > 0) {
                messageRows.push(<MessageReceivedWithFile key={count++} message={message} />);
            } else if (isSent == false && (message.documentFileIds === undefined || message.documentFileIds === null || message.documentFileIds.length === 0)) {
                messageRows.push(<MessageReceived key={count++} message={message} />);
            }
        }

        return messageRows;
    };

    const getMessages = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null && loadId !== undefined && loadId !== null) {
            dispatch(actionCreators.fetchMessages(entityType, entityId, userId, 10, loadId));
        }
    };

    const onLoadEarlier = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null && loadId !== undefined && loadId !== null) {
            if (currentPage + 1 <= totalPages && isLoading === false) {
                //console.log('get more');
                dispatch(actionCreators.fetchMoreMessages(entityType, entityId, userId, loadId));
            } else {
                //console.log('no more items to get');
            }
        }
    };

    const refreshMessages = () => {
        getMessages();
    };

    const sendMessage = (event) => {
        event.preventDefault();

        const formElements = event.target.elements;
        let messageText = formElements[0].value;

        if (userId !== undefined && userId !== null && load !== undefined && load !== null && load.id !== undefined && load.id !== null) {
            dispatch(actionCreators.sendMessage([{ text: messageText }], userId, load));
            formElements[0].value = '';
        }
    };

    useMemo(() => {
        if (isAddMessageLoading !== null && isAddMessageLoading === false && addMessageError === null) {
            executeScroll();
        }
    }, [isAddMessageLoading, addMessageError]);

    useEffect(() => {
        getMessages();
    }, [entityType, entityId, userId, loadId]);

    const onLoadEarlierIsComplete = () => {
        const newHeight = scrollParentRef.current.scrollHeight;
        if (currentHeight !== newHeight) {
            const loadMoreOffset = newHeight - currentHeight;
            scrollParentRef.current.scrollTo({ left: 0, top: loadMoreOffset, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        // console.log(allMessages);
        if (allMessages !== undefined && allMessages !== null && !isEmpty(allMessages) && loadId !== undefined && loadId !== null) {
            if (allMessages[loadId] !== undefined && allMessages[loadId] !== null && !isEmpty(allMessages[loadId]) && allMessages[loadId].records !== undefined && allMessages[loadId].records !== null) {
                const copyOfRecord = { ...allMessages[loadId] };
                // console.log(copyOfRecord.records.length);
                setMessages([...copyOfRecord.records]);
                setTotalPages(copyOfRecord.totalPages);
                setCurrentPage(copyOfRecord.currentPage);
            }
        }
    }, [allMessages, loadId]);

    useEffect(() => {

        if (atBottom === true) {
            executeScroll();
        } else if (messages.length > previousMessageCount) {
            if (messages.length - previousMessageCount === 10) {
                onLoadEarlierIsComplete();
            }
            setPreviousMessageCount(messages.length);
        } else if (isInitialLoad === false) {
        } else if (isInitialLoad === true) {
            // set the initial height if the screen just loaded
            setCurrentHeight(scrollParentRef.current.scrollHeight);
            setIsInitialLoad(false);
        }
    }, [messages, atBottom, currentHeight, scrollParentRef.current, isInitialLoad, previousMessageCount]);

    return (
        <>
            <div className={classes.messages}>
                <div className={classes.messageHistory}>
                    <div id="messages" className={classes.messagesContainer} onScroll={onScroll} ref={scrollParentRef}>
                        {createMessages(messages.slice().reverse())}
                        <div style={{ float: "left", clear: "both" }} ref={myRef}></div>
                    </div>
                </div>

                <Form onSubmit={sendMessage}>
                    <div className={classes.typeMessage}>
                        <div className={classes.inputMessageWrite}>
                            <input type="text" id="newMessage" className={classes.writeMessage} name="message" placeholder="Type a message" />
                            <Button className={classes.messageSendButton} type="primary" shape="circle" htmlType="submit"><FontAwesomeIcon icon={faPaperPlane} size="sm" /></Button>
                        </div>
                    </div>
                </Form>
            </div>
            <Modal
                title={documentDisplayName}
                visible={showModal}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Document fileId={documentId} />
            </Modal>
        </>
    );
};

export default LoadMessages;
