import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Spin, Button, Input, Select, Divider } from "antd";
import classes from "./ShipperInvoice.module.scss";
import InvoiceTable from '../../components/InvoiceTable/InvoiceTable';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from "../../components/DataRow/DataRow";
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import irisFullLogo from '../../assets/img/BannerIrisFreight@5x.png';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import Fieldset from '../../components/FormFieldset/FormFieldset';
import { removeEmpty } from '../../shared/objectUtils';
import { isEmpty } from 'lodash';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import { ScissorOutlined } from '@ant-design/icons';
import Pricing from '../../shared/pricing';

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const ShipperInvoice = props => {
    const loadId = props.match.params.loadId;

    const phoneNumber = '(480) 485-5055';

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const load = useSelector(state => state.loads.record);
    const invoiceLineItems = useSelector(state => state.invoiceLineItems.records);
    const isInvoiceLineItemsLoading = useSelector(state => state.invoiceLineItems.isLoading);
    const stops = useSelector(state => state.loadStops.records);
    const isStopsLoading = useSelector(state => state.loadStops.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const states = useSelector(state => state.orchestrator.states);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [origin, setOrigin] = useState([]);
    const [destination, setDestination] = useState([]);
    const [shipper, setShipper] = useState(null);
    const [remitName, setRemitName] = useState(null);
    const [remitAddress, setRemitAddress] = useState({});
    const [paymentTerms, setPaymentTerms] = useState(null);
    const [accountNumber, setAccountNumber] = useState(null);
    const [invoiceNotes, setInvoiceNotes] = useState(null);
    const [pricingSummary, setPricingSummary] = useState({});

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);
        if (!isEmpty(cleanedData)) {
            setRemitAddress(cleanedData.remitAddress);
            setRemitName(cleanedData.remitName);
            setAccountNumber(cleanedData.accountNumber);
            setPaymentTerms(Number(cleanedData.paymentTerms));
            setInvoiceNotes(cleanedData.invoiceNotes);
        }
    };

    const invoiceColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
            style: {
                boxSizing: 'border-box',
                padding: 4,
                margin: 0,
                backgroundColor: '#333333',
                color: '#ffffff',
                border: '2px solid #ffffff',
                fontSize: 14,
                fontVariant: 'tabular-nums',
                lineHeight: 1.5,
                listStyle: 'none',
                position: 'relative',
                textAlign: 'center'
            }
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
            style: {
                boxSizing: 'border-box',
                padding: 4,
                margin: 0,
                backgroundColor: '#333333',
                color: '#ffffff',
                border: '2px solid #ffffff',
                fontSize: 14,
                fontVariant: 'tabular-nums',
                lineHeight: 1.5,
                listStyle: 'none',
                position: 'relative',
                textAlign: 'center'
            }
        },
        {
            title: 'Rate',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
            style: {
                boxSizing: 'border-box',
                padding: 4,
                margin: 0,
                backgroundColor: '#333333',
                color: '#ffffff',
                border: '2px solid #ffffff',
                fontSize: 14,
                fontVariant: 'tabular-nums',
                lineHeight: 1.5,
                listStyle: 'none',
                position: 'relative',
                textAlign: 'center'
            }
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
            style: {
                boxSizing: 'border-box',
                padding: 4,
                margin: 0,
                backgroundColor: '#333333',
                color: '#ffffff',
                border: '2px solid #ffffff',
                fontSize: 14,
                fontVariant: 'tabular-nums',
                lineHeight: 1.5,
                listStyle: 'none',
                position: 'relative',
                textAlign: 'center'
            }
        },
    ];

    useMemo(() => {
        dispatch(actionCreators.fetchLoadWithIncludes(loadId));
    }, [loadId]);

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    useMemo(() => {
        if (load !== undefined && load !== null) {
            let hasAccess = entityType === 'STAFF';

            if (loadId === load.id && hasAccess === true) {
                dispatch(actionCreators.fetchInvoiceLineItems({ page: 1, size: 100000, loadId: load.id }));
                dispatch(actionCreators.fetchLoadStops(load.id));
            } else if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [load, entityType]);

    useMemo(() => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let pickUpStopList = stops.filter(stop => stop.stopType === 'PICK_UP');
            if (pickUpStopList !== undefined && pickUpStopList !== null && pickUpStopList.length > 0) {
                setPickUpStops([...pickUpStopList]);
                let originStop = pickUpStopList[pickUpStopList.length - 1];
                if (originStop !== undefined && originStop !== null) {
                    setOrigin({ ...originStop });
                }
            }
            let dropOffStopList = stops.filter(stop => stop.stopType === 'DROP_OFF');
            if (dropOffStopList !== undefined && dropOffStopList !== null && dropOffStopList.length > 0) {
                setDropOffStops([...dropOffStopList]);
                let destinationStop = dropOffStopList[dropOffStopList.length - 1];
                if (destinationStop !== undefined && destinationStop !== null) {
                    setDestination({ ...destinationStop });
                }
            }
        }
    }, [stops]);

    useMemo(() => {
        if (load !== undefined && load !== null && accounts !== undefined && accounts !== null && accounts.length > 0) {
            let shipperAccount = accounts.find(i => i.id === load.shipperId);
            if (shipperAccount !== undefined && shipperAccount !== null) {
                //console.log(shipperAccount);
                setShipper({ ...shipperAccount });
            }
        }
    }, [accounts, load]);

    useMemo(() => {
        const pricingSummaryValue = Pricing.loadPricingSummary(invoiceLineItems, []);
        setPricingSummary(pricingSummaryValue);
    }, [invoiceLineItems]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (paymentTerms !== undefined && paymentTerms !== null && remitName !== undefined && remitName !== null && remitAddress !== undefined && remitAddress !== null && !isEmpty(remitAddress) && accountNumber !== undefined && accountNumber !== null) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isInvoiceLineItemsLoading === true || isStopsLoading === true}>
                {load !== undefined && load !== null && load.id !== undefined && load.id !== null && load.id === loadId ? (
                    <Layout style={{ minHeight: '100vh', backgroundColor: 'white', margin: 0, padding: 0 }}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <img src={irisFullLogo} alt="Pulogix" style={{ width: 200, marginBottom: 12 }} />
                                <DataAddressRow
                                    street1="4445 North 36th Street #312"
                                    city="Phoenix"
                                    state="AZ"
                                    postalCode="85018"
                                />
                                <DataRow value={phoneNumber} dataType="PhoneNumber" />
                            </Col>
                            <Col span={6}>
                                <h1 style={{ textAlign: 'center' }}>INVOICE</h1>
                            </Col>
                            <Col span={12}>
                                <Row gutter={4}>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>INVOICE #</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>INVOICE DATE</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>ORDER #</div></Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col span={8}><div className={classes.invoiceHeaderData}><DataRow dataType="String" value={load.irisId} /></div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderData}><DataRow dataType="MomentDate" value={moment()} /></div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderData}>{load.customerOrderNumber !== undefined && load.customerOrderNumber !== null ? <DataRow dataType="String" value={load.customerOrderNumber} /> : null}</div></Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col span={12}><div className={classes.invoiceHeaderTitle}>BOL #</div></Col>
                                    <Col span={12}><div className={classes.invoiceHeaderTitle}>PO #</div></Col>
                                </Row>
                                {dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0 ? (
                                    <>
                                        {dropOffStops.map((stop) => (
                                            <Row gutter={4}>
                                                <Col span={12}><div className={classes.invoiceHeaderData}>{stop.bolNumber !== undefined && stop.bolNumber !== null ? <DataRow dataType="String" value={stop.bolNumber} /> : null}</div></Col>
                                                <Col span={12}><div className={classes.invoiceHeaderData}>{stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow dataType="String" value={stop.purchaseOrderNumber} /> : null}</div></Col>
                                            </Row>
                                        ))}
                                    </>
                                ) : null}
                                <Row gutter={4}>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>SHIP DATE</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>DELIVER DATE</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderTitle}>LOAD ID</div></Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col span={8}><div className={classes.invoiceHeaderData}>{origin !== undefined && origin !== null ? <DataRow dataType="MomentDate" value={origin.endDateTime} timeZone={origin.timeZone} /> : null}</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderData}>{destination !== undefined && destination !== null ? <DataRow dataType="MomentDate" value={destination.endDateTime} timeZone={destination.timeZone} /> : null}</div></Col>
                                    <Col span={8}><div className={classes.invoiceHeaderData}><DataRow dataType="String" value={load.irisId} /></div></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginTop: 30, marginBottom: 30 }}>
                            <Col span={9} offset={3}>
                                <h3>BILLED TO:</h3>
                                {shipper !== undefined && shipper !== null && shipper.name !== undefined && shipper.name !== null ? <DataRow valueStyle={{ textTransform: 'uppercase' }} dataType="String" value={shipper.name} /> : null}
                                {shipper !== undefined && shipper !== null && shipper.shipper !== undefined && shipper.shipper !== null && shipper.shipper.accountsPayable !== undefined && shipper.shipper.accountsPayable !== null && shipper.shipper.accountsPayable.name !== undefined && shipper.shipper.accountsPayable.name !== null ? <DataRow dataType="String" value={shipper.shipper.accountsPayable.name} /> : null}
                                {shipper !== undefined && shipper !== null && shipper.shipper !== undefined && shipper.shipper !== null && shipper.shipper.billingAddress !== undefined && shipper.shipper.billingAddress !== null ? <DataAddressRow address={shipper.shipper.billingAddress} /> : null}
                            </Col>
                            <Col span={9}>
                                <h3>CONSIGNEE:</h3>
                                {dropOffStops.map((stop) => (
                                    <>
                                        {stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.name !== undefined && stop.stopLocation.name !== null ? <DataRow valueStyle={{ textTransform: 'uppercase' }} dataType="String" value={stop.stopLocation.name} /> : null}
                                        {stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.address !== undefined && stop.stopLocation.address !== null ? <DataAddressRow address={stop.stopLocation.address} /> : null}
                                        <br />
                                    </>
                                ))}
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>INVOICE:</h3>

                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <InvoiceTable
                                        isPriceConfirmation={true}
                                        data={invoiceLineItems.filter(item => (item.fromEntityType === 'SHIPPER' || item.toEntityType === 'SHIPPER') && item.approvalStatus === 'APPROVED')}
                                        columns={invoiceColumns}
                                        totalTitle="AMOUNT DUE"
                                        totalTitleStyle={{ fontWeight: 'bold', textAlign: 'right' }}
                                        totalStyle={{ textAlign: 'center', border: '2px solid #333333' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {invoiceNotes !== undefined && invoiceNotes !== null ? (
                            <Row gutter={20}>
                                <Col span={24}>
                                    <h3 style={{ paddingLeft: 10, paddingRight: 10 }}>Notes:</h3>

                                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        {invoiceNotes}
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            <h4 style={{ textAlign: 'center' }}>PLEASE DETACH AND RETURN THE PORTION BELOW WITH YOUR PAYMENT <ScissorOutlined /></h4>
                            <Divider dashed style={{ height: 2, borderColor: '#333333', margin: '0 0 20px 0' }} />
                            <Row gutter={20}>
                                <Col span={6}>
                                    <img src={irisFullLogo} alt="Pulogix" style={{ width: 200, marginBottom: 12 }} />
                                    <DataRow value="PULOGIX, INC." dataType="String" />
                                </Col>
                                <Col span={16} offset={2}>
                                    <Row gutter={4}>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>ACCOUNT #</div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>INVOICE #</div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>INVOICE DATE</div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>ORDER #</div></Col>
                                    </Row>
                                    <Row gutter={4}>
                                        <Col span={6}><div className={classes.invoiceHeaderData}><DataRow dataType="String" value={accountNumber} /></div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderData}><DataRow dataType="String" value={load.irisId} /></div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderData}><DataRow dataType="MomentDate" value={moment()} /></div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderData}>{load.customerOrderNumber !== undefined && load.customerOrderNumber !== null ? <DataRow dataType="String" value={load.customerOrderNumber} /> : null}</div></Col>
                                    </Row>
                                    <Row gutter={4}>
                                        <Col span={6} offset={6}><div className={classes.invoiceHeaderTitle}>PAYMENT TERMS</div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>PAYMENT DUE DATE</div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderTitle}>AMOUNT DUE</div></Col>
                                    </Row>
                                    <Row gutter={4}>
                                        <Col span={6} offset={6}><div className={classes.invoiceHeaderData}><DataRow dataType="String" value={paymentTerms > 0 ? `NET ${paymentTerms}` : 'DUE IMMEDIATELY'} /></div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderData}><DataRow dataType="MomentDate" value={moment().add(paymentTerms, 'days')} /></div></Col>
                                        <Col span={6}><div className={classes.invoiceHeaderData}><DataRow dataType="Money" value={pricingSummary ? pricingSummary.carrierBalance : 0} /></div></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={20} style={{ marginTop: 30, marginBottom: 0 }}>
                                <Col span={9} offset={15}>
                                    <h3>PLEASE REMIT PAYMENT TO:</h3>
                                    <DataAddressRow valueStyle={{ textTransform: 'uppercase' }} name={remitName} address={remitAddress} />
                                </Col>
                            </Row>
                        </div>
                    </Layout>
                ) : null
                }
            </Spin >
        );
    } else if (shipper !== undefined && shipper !== null) {
        return (
            <Layout style={{ minHeight: '100vh', backgroundColor: 'white', margin: 10, padding: 40 }}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Fieldset>
                            <legend>Please specify the details below before generating the Shipper Invoice</legend>
                            <FormItem {...formItemLayout} label="Payment Terms" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select the Payment Terms"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        <Option key='0' value='0'>NET 0</Option>
                                        <Option key='15' value='15'>NET 15</Option>
                                        <Option key='30' value='30'>NET 30</Option>
                                        <Option key='45' value='45'>NET 45</Option>
                                        <Option key='60' value='60'>NET 60</Option>
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="paymentTerms"
                            />
                            <FormItem {...formItemLayout} label="Account Number" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Account Number" />}
                                rules={{ required: 'Required Field' }}
                                name="accountNumber"
                                defaultValue={shipper && shipper.shipper && shipper.shipper.irisId ? shipper.shipper.irisId : ''}
                            />
                            <FormItemDisplay {...formItemLayout} label="Remit Address" format="horizontal">
                                <FormItem {...formItemLayout} label="Remit Name" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Remit Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="remitName"
                                    defaultValue={'PULOGIX, INC.'}
                                />
                                <FormItem {...formItemLayout} label="Street Address 1" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                    rules={{ required: "Required Field" }}
                                    name="remitAddress.streetAddress1"
                                    defaultValue={'4445 N. 36TH STREET #312'}
                                />
                                <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                    rules={{ required: false }}
                                    name="remitAddress.streetAddress2"
                                    defaultValue={''}
                                />
                                <FormItem {...formItemLayout} label="City" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                    rules={{ required: "Required Field" }}
                                    name="remitAddress.city"
                                    defaultValue={'PHOENIX'}
                                />
                                <FormItem {...formItemLayout} label="State" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="remitAddress.state"
                                    defaultValue={'AZ'}
                                />
                                <FormItem {...formItemLayout} label="Postal Code" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                    rules={{ required: "Required Field" }}
                                    name="remitAddress.postalCode"
                                    defaultValue={'85018'}
                                />
                                <FormItem {...formItemLayout} label="Country" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Country"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="USA" key="USA">United States</Option>
                                        </Select>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="remitAddress.country"
                                    defaultValue={'USA'}
                                />
                            </FormItemDisplay>
                            <FormItem {...formItemLayout} label="Notes" format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} placeholder="Notes" />}
                                rules={{ required: false }}
                                name="invoiceNotes"
                            />
                        </Fieldset>
                        <div className={classes.buttonContainer}>
                            <Button type="primary" htmlType="submit">Generate Shipper Invoice</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Layout>
        );
    } else {
        return null;
    }
};

export default withRouter(ShipperInvoice);
