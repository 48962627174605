import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// Reducer imports
import authReducer from './reducers/auth';
import yourAccountUsersReducer from './reducers/yourAccountUsers';
import yourAccountsReducer from './reducers/yourAccounts';
import statesReducer from './reducers/states';
import lineItemTypesReducer from './reducers/lineItemTypes';
import trailerTypesReducer from './reducers/trailerTypes';
import claimsReducer from './reducers/claims';
import loadsReducer from './reducers/loads';
import accountsReducer from './reducers/accounts';
import usersReducer from './reducers/users';
import commoditiesReducer from './reducers/commodities';
import locationsReducer from './reducers/locations';
import accountUsersReducer from './reducers/accountUsers';
import assetsReducer from './reducers/assets';
import loadLanesReducer from './reducers/loadLanes';
import linkedLoadLanesReducer from './reducers/linkedLoadLanes';
import contentsReducer from './reducers/contents';
import documentsReducer from './reducers/documents';
import notificationsReducer from './reducers/notifications';
import invoiceLineItemsReducer from './reducers/invoiceLineItems';
import transactionsReducer from './reducers/transactions';
import notesReducer from './reducers/notes';
import loadStopsReducer from './reducers/loadStops';
import loadCancellationsReducer from './reducers/loadCancellations';
import carrierRejectionsReducer from './reducers/carrierRejections';
import orchestratorReducer from './reducers/orchestrator';
import keysReducer from './reducers/keys';
import configurationsReducer from './reducers/configurations';
import loadListsReducer from './reducers/loadLists';
import metricsReducer from './reducers/metrics';
import accountRegistrationsReducer from './reducers/accountRegistrations';
import messagesReducer from './reducers/messages';
import linkedAccountsReducer from './reducers/linkedAccounts';
import linkedLocationsReducer from './reducers/linkedLocations';
import featuresReducer from './reducers/features';
import servicesReducer from './reducers/services';
import shiftsReducer from './reducers/shifts';
import scheduledShiftsReducer from './reducers/scheduledShifts';
import bidsReducer from './reducers/bids';

// Redux Configuration
// One Root Reducer with multiple slices
const rootReducer = combineReducers({
    auth: authReducer,
    yourAccountUsers: yourAccountUsersReducer,
    yourAccounts: yourAccountsReducer,
    states: statesReducer,
    lineItemTypes: lineItemTypesReducer,
    trailerTypes: trailerTypesReducer,
    claims: claimsReducer,
    loads: loadsReducer,
    accounts: accountsReducer,
    users: usersReducer,
    commodities: commoditiesReducer,
    locations: locationsReducer,
    loadLanes: loadLanesReducer,
    linkedLoadLanes: linkedLoadLanesReducer,
    accountUsers: accountUsersReducer,
    assets: assetsReducer,
    contents: contentsReducer,
    documents: documentsReducer,
    notifications: notificationsReducer,
    invoiceLineItems: invoiceLineItemsReducer,
    transactions: transactionsReducer,
    notes: notesReducer,
    bids: bidsReducer,
    loadStops: loadStopsReducer,
    loadCancellations: loadCancellationsReducer,
    carrierRejections: carrierRejectionsReducer,
    orchestrator: orchestratorReducer,
    keys: keysReducer,
    configurations: configurationsReducer,
    loadLists: loadListsReducer,
    metrics: metricsReducer,
    accountRegistrations: accountRegistrationsReducer,
    messages: messagesReducer,
    linkedAccounts: linkedAccountsReducer,
    linkedLocations: linkedLocationsReducer,
    features: featuresReducer,
    services: servicesReducer,
    shifts: shiftsReducer,
    scheduledShifts: scheduledShiftsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// The ONE Global Redux Store
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;