import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { CheckOutlined, ControlOutlined, DownOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu, Modal } from 'antd';
import Document from "../../components/Document/Document";
import EditDocument from "../../components/EditDocument/EditDocument";
import NewDocument from "../../components/NewDocument/NewDocument";
import ReviewDocument from "../../components/ReviewDocument/ReviewDocument";
import RemoveDocument from "../../components/RemoveDocument/RemoveDocument";
import classes from './DocumentList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';
import DocumentUtils from '../../shared/documentUtils';

const stringFormatter = new StringFormatter();

const DocumentList = props => {
    const fullWidth = global.window.innerWidth;
    const documentList = props.documentList;
    const entityType = props.entityType;
    const entityId = props.entityId;
    const entitySubType = props.entitySubType;
    const account = props.account;
    const accountUser = props.accountUser;
    const loadId = props.loadId;
    const needsCarrierWashoutLog = props.needsCarrierWashoutLog !== null && props.needsCarrierWashoutLog !== undefined && props.needsCarrierWashoutLog === true ? true : false;
    const tableTitle = props.tableTitle;

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.documents.isLoading);
    const entityDocuments = useSelector(state => state.documents.records);
    const pagination = useSelector(state => state.documents.pagination);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const userEntityType = useSelector(state => state.auth.entityType);
    const userEntityId = useSelector(state => state.auth.entityId);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showReviewEntity, setShowReviewEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showViewEntity, setShowViewEntity] = useState(false);
    const [pendingApproval, setPendingApproval] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);
    const [expiringSoonDocuments, setExpiringSoonDocuments] = useState([]);
    const [expiredDocuments, setExpiredDocuments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [visibleDocuments, setVisibleDocuments] = useState([]);

    const singularEntityName = "Document";
    const pluralEntityName = "Documents";

    useEffect(() => {
        //console.log(`entityDocuments: ${JSON.stringify(entityDocuments)}`);
        //console.log(`documentList: ${JSON.stringify(documentList)}`);

        if (entityDocuments !== undefined && entityDocuments !== null && entityDocuments.length > 0 && (documentList === undefined || documentList === null)) {
            let documentsToInclude = [];
            if (entityId !== undefined && entityId !== null) {
                documentsToInclude = entityDocuments.filter(d => d.entityId === entityId);
            } else {
                documentsToInclude = entityDocuments;
            }
            setDocuments(documentsToInclude);
        } else if ((documentList === undefined || documentList === null)) {
            setDocuments([]);
        } else if (documentList !== undefined && documentList !== null && documentList.length > 0) {
            setDocuments(documentList);
        } else if (documentList !== undefined && documentList !== null) {
            setDocuments([]);
        } else {
            setDocuments([]);
        }

        return () => {
        };
    }, [entityDocuments, entityId, documentList]);

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            sort: 'createdAt',
            order: 'desc',
            isDeleted: false
        };

        if (entityId !== undefined && entityId !== null) {
            searchParams.entityId = entityId;
        }

        dispatch(actionCreators.fetchDocuments(searchParams));
    };

    useMemo(() => {
        if (entityId !== undefined && (documentList === undefined || documentList === null)) {
            refreshTable();
        }
    }, [entityId, userEntityId, documentList]);

    useEffect(() => {
        //console.log(`documents: ${JSON.stringify(documents)}`);
        let filteredDocuments = DocumentUtils.getVisibleDocuments(documents, userEntityType, isAdmin);
        setVisibleDocuments(filteredDocuments);

        return () => {
        };
    }, [documents, userEntityType, isAdmin]);

    useEffect(() => {
        //console.log(`documents: ${JSON.stringify(documents)}`);
        setPendingApproval([]);
        setMissingDocuments([]);
        setExpiredDocuments([]);
        setExpiringSoonDocuments([]);

        let results = DocumentUtils.calculateCounts(documents, entityId, entityType, entitySubType, account, needsCarrierWashoutLog);

        setExpiringSoonDocuments(results.expiringSoonDocumentsList);
        setExpiredDocuments(results.expiredDocumentsList);
        setPendingApproval(results.pendingApprovalDocumentsList);
        setMissingDocuments(results.missingDocumentsList);

        return () => {
        };
    }, [entityId, entityType, entitySubType, documents, account, needsCarrierWashoutLog]);

    const reviewableDocumentTypes = ['BOL', 'SCALE_TICKET', 'LUMPER_FEE_RECEIPT', 'FUEL_RECEIPT', 'TOLL_RECEIPT', 'RECEIPT', 'OTHER', 'MSHA_LICENSE_FRONT',
        'MSHA_LICENSE_BACK', 'EXPLOSIVE_CERTIFIED_LICENSE_FRONT', 'EXPLOSIVE_CERTIFIED_LICENSE_BACK', 'MC_CERTIFICATE_LICENSE_FRONT', 'MC_CERTIFICATE_LICENSE_BACK',
        'PNEUMATIC_LICENSE_FRONT', 'PNEUMATIC_LICENSE_BACK', 'COMMERCIAL_DRIVERS_LICENSE_FRONT', 'COMMERCIAL_DRIVERS_LICENSE_BACK', 'DRIVERS_LICENSE_FRONT',
        'DRIVERS_LICENSE_BACK', 'HAZMAT_LICENSE_FRONT', 'HAZMAT_LICENSE_BACK', 'SHIPPER_W9', 'SHIPPER_INSURANCE', 'CARRIER_W9', 'CARRIER_INSURANCE',
        'PRODUCER_W9', 'PRODUCER_INSURANCE', 'RECEIVER_W9', 'RECEIVER_INSURANCE', 'CARRIER_WASHOUT_LOG', 'RATE_CONFIRMATION', 'PRICE_CONFIRMATION', 'ACCOUNT_LOGO', 'CLAIM'
    ];

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="viewDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleViewEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: '10px' }} />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
                <Menu.Item key="reviewDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleReviewEntity(); }}>
                    <CheckOutlined />
                    <span>Review {singularEntityName}</span>
                </Menu.Item>
                <Menu.Item key="editDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                {(isAdmin === true) ? (
                    <Menu.Item key="deleteDocument" onClick={(e) => { e.domEvent.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: '10px' }} />
                        <span>Delete {singularEntityName}</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Document Type',
            dataIndex: 'documentType',
            key: 'documentType',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentType", text, null); },
        },
        {
            title: 'File Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }
    ];

    columns.unshift({
        title: <FontAwesomeIcon icon={faTools} size="lg" />,
        key: 'actions',
        width: 100,
        render: (text, record) => {
            return (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
                </Dropdown>
            );
        },
        align: 'center',
    });

    columns.push({
        title: 'Is Reviewed',
        dataIndex: 'isReviewed',
        key: 'isReviewed',
        render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
    });
    columns.push({
        title: 'Review Status',
        dataIndex: 'reviewStatus',
        key: 'reviewStatus',
        render: (text, record) => { return stringFormatter.toFormattedString("DocumentReviewStatus", text, null); },
    });
    columns.push({
        title: 'Reviewed At',
        dataIndex: 'reviewedAt',
        key: 'reviewedAt',
        render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
    });
    columns.push({
        title: 'Reviewed By',
        dataIndex: 'reviewedByName',
        key: 'reviewedByName',
    });
    columns.push({
        title: 'Visbility',
        dataIndex: 'visibleTo',
        key: 'visibleTo',
        render: (text, record) => { return stringFormatter.toFormattedString("DocumentVisibility", text, null); },
    });

    if (entityType === undefined || entityType === null) {
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Entity Type',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("DocumentEntityType", text, null); },
        });
        columns.push({
            title: 'Entity',
            dataIndex: 'entityName',
            key: 'entityName',
        });
    } else if (entityType === 'DRIVER') {
        columns.push({
            title: 'License Number',
            dataIndex: 'licenseNumber',
            key: 'licenseNumber',
        });
        columns.push({
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        });
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
    } else if (entityType === 'ACCOUNT_CARRIER') {
        columns.push({
            title: 'Expiration Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        });
        columns.push({
            title: 'Is Expiring Soon',
            dataIndex: 'isExpiringSoon',
            key: 'isExpiringSoon',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Is Expired',
            dataIndex: 'isExpired',
            key: 'isExpired',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
    }

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleReviewEntity = () => {
        setShowReviewEntity(!showReviewEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleViewEntity = () => {
        setShowViewEntity(!showViewEntity);
    };

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewDocument cancel={toggleNewEntity} entityType={entityType} entitySubType={entitySubType} entityId={entityId} loadId={loadId} account={account} />
        </Drawer>
    );

    const reviewEntityComponents = (
        <Drawer
            title={"Review " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleReviewEntity}
            visible={showReviewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ReviewDocument cancel={toggleReviewEntity} record={selectedRecord}
                documentType={selectedRecord.documentType} entityType={entityType} entitySubType={entitySubType}
                fileId={selectedRecord.id} displayName={selectedRecord.displayName} loadId={loadId}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDocument cancel={toggleEditEntity} record={selectedRecord} entityType={entityType} loadId={loadId} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveDocument cancel={toggleRemoveEntity} record={selectedRecord} entityType={entityType} loadId={loadId} />
        </Drawer>
    );

    const viewEntityComponents = (
        <Modal
            title={(selectedRecord && selectedRecord.displayName) ? selectedRecord.displayName : 'File'}
            visible={showViewEntity}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleViewEntity}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Document record={selectedRecord} fileId={selectedRecord ? selectedRecord.id : null} displayName={selectedRecord ? selectedRecord.displayName : null} />
        </Modal>
    );

    const createMissingDocumentList = () => {
        if (missingDocuments !== undefined && missingDocuments !== null && missingDocuments.length > 0) {
            return (
                <div>
                    <b>Missing Documents ({missingDocuments.length}):</b> {missingDocuments.join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createPendingApprovalList = () => {
        if (pendingApproval !== undefined && pendingApproval !== null && pendingApproval.length > 0) {
            return (
                <div>
                    <b>Documents Pending Review ({pendingApproval.length}):</b> {pendingApproval.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createExpiringSoonDocumentList = () => {
        if (expiringSoonDocuments !== undefined && expiringSoonDocuments !== null && expiringSoonDocuments.length > 0) {
            return (
                <div>
                    <b>The following Documents are expiring within the next 30 days ({expiringSoonDocuments.length}):</b> {expiringSoonDocuments.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createExpiredDocumentList = () => {
        if (expiredDocuments !== undefined && expiredDocuments !== null && expiredDocuments.length > 0) {
            return (
                <div>
                    <b>Expired Documents ({expiredDocuments.length}):</b> {expiredDocuments.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const getPendingApprovalCount = () => {
        let count = 0;
        if (pendingApproval !== undefined && pendingApproval !== null) {
            count = pendingApproval.length;
        }

        if (props.updatePendingApprovalCount) {
            props.updatePendingApprovalCount(count);
        }

        //console.log(`count: ${count}`);

        if (account !== undefined && account !== null) {
            let updatedAccount = { ...account, documentPendingApprovalCount: count };
            dispatch(actionCreators.changeAccount(updatedAccount));
        }

        if (accountUser !== undefined && accountUser !== null) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentPendingApprovalCount = count;
            dispatch(actionCreators.changeAccountUser(updatedAccountUser));
        }

        return count;
    };

    const getMissingDocumentCount = () => {
        let count = 0;
        if (missingDocuments !== undefined && missingDocuments !== null) {
            count = missingDocuments.length;
        }

        if (props.updateMissingDocumentsCount) {
            props.updateMissingDocumentsCount(count);
        }

        if (account !== undefined && account !== null) {
            let updatedAccount = { ...account, documentMissingCount: count };
            dispatch(actionCreators.changeAccount(updatedAccount));
        }

        if (accountUser !== undefined && accountUser !== null) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentMissingCount = count;
            dispatch(actionCreators.changeAccountUser(updatedAccountUser));
        }

        return count;
    };

    const getExpiringSoonDocumentCount = () => {
        let count = 0;
        if (expiringSoonDocuments !== undefined && expiringSoonDocuments !== null) {
            count = expiringSoonDocuments.length;
        }

        if (props.updateExpiringSoonDocumentsCount) {
            props.updateExpiringSoonDocumentsCount(count);
        }

        if (account !== undefined && account !== null) {
            let updatedAccount = { ...account, documentExpiringSoonCount: count };
            dispatch(actionCreators.changeAccount(updatedAccount));
        }

        if (accountUser !== undefined && accountUser !== null) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentExpiringSoonCount = count;
            dispatch(actionCreators.changeAccountUser(updatedAccountUser));
        }

        return count;
    };

    const getExpiredDocumentCount = () => {
        let count = 0;
        if (expiredDocuments !== undefined && expiredDocuments !== null) {
            count = expiredDocuments.length;
        }

        if (props.updateExpiredDocumentsCount) {
            props.updateExpiredDocumentsCount(count);
        }

        if (account !== undefined && account !== null) {
            let updatedAccount = { ...account, documentExpiredCount: count };
            dispatch(actionCreators.changeAccount(updatedAccount));
        }

        if (accountUser !== undefined && accountUser !== null) {
            let updatedAccountUser = { ...accountUser };
            updatedAccountUser.driver.documentExpiredCount = count;
            dispatch(actionCreators.changeAccountUser(updatedAccountUser));
        }

        return count;
    };

    useMemo(() => {
        getMissingDocumentCount();
    }, [missingDocuments]);

    useMemo(() => {
        getPendingApprovalCount();
    }, [pendingApproval]);

    useMemo(() => {
        getExpiringSoonDocumentCount();
    }, [expiringSoonDocuments]);

    useMemo(() => {
        getExpiredDocumentCount();
    }, [expiredDocuments]);

    return (
        <DataTable
            dataSource={visibleDocuments}
            columns={columns}
            loading={isLoading === true}
            pageSize={10}
            rowClassName={classes.dataTableRow}
            title={() => (<DataTableTitle tableTitle={tableTitle} singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={entityType !== undefined && entityType !== null && ((entityType === "DRIVER" && (userEntityType === "STAFF" || userEntityType === "DRIVER" || userEntityType === "CARRIER")) || entityType !== "DRIVER") && ((entityType === "ASSET" && (userEntityType === "STAFF" || userEntityType === "DRIVER" || userEntityType === "CARRIER")) || entityType !== "ASSET") ? toggleNewEntity : null} />)}
            footer={() => (<>{(userEntityType === "STAFF" || (entityType === "DRIVER" && userEntityType === "CARRIER")) ? createPendingApprovalList() : null}{createMissingDocumentList()}{createExpiredDocumentList()}{createExpiringSoonDocumentList()}</>)}
            style={{ backgroundColor: '#ffffff' }}
            rowKey={record => record.id}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        setSelectedRecord(record);
                        toggleViewEntity();
                    }, // click row
                };
            }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {reviewEntityComponents}
            {removeEntityComponents}
            {viewEntityComponents}
        </DataTable>
    );
};

export default withRouter(DocumentList);