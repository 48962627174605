import React, { useMemo } from 'react';
import { Select, Alert, Spin, Checkbox, InputNumber, Input, Radio, Row, Col, Typography } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const { Paragraph } = Typography;
const { Option } = Select;

const EditAccountRegistration = props => {
    const accountRegistration = props.record;
    const accountRegistrationReviewStatusOptions = Enums.AccountRegistrationReviewStatuses.selectListOptions();

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountRegistrations.isRecordUpdateLoading);
    const error = useSelector(state => state.accountRegistrations.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            if (data.reviewStatus !== undefined && data.reviewStatus !== null && data.reviewStatus !== '' && data.reviewStatus !== 'PENDING') {
                data.isReviewed = true;
            }

            dispatch(actionCreators.updateAccountRegistration(accountRegistration.id, data));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateAccountRegistration());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountRegistrationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutHorizontal = {
        labelCol: { span: 20 },
        wrapperCol: { span: 4 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 16 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 8 },
        wrapperCol2: { span: 8 },
    };
    const formItemLayoutDoubleHorizontal = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 1 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 5 },
    };

    if (accountRegistration !== undefined && accountRegistration !== null) {
        if (accountRegistration.isCarrier === true && accountRegistration.carrier !== undefined && accountRegistration.carrier !== null) {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset>
                                <legend>Company Contact Information (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="name"
                                            defaultValue={accountRegistration.name ? accountRegistration.name : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Contact Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Contact Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                            defaultValue={accountRegistration.pointOfContact && accountRegistration.pointOfContact.name ? accountRegistration.pointOfContact.name : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Email" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="email"
                                            defaultValue={accountRegistration && accountRegistration.email ? accountRegistration.email : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" />}
                                            rules={{ required: 'Required Field' }}
                                            name="ein"
                                            defaultValue={accountRegistration.ein ? accountRegistration.ein : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="phone"
                                            defaultValue1={accountRegistration.phone ? accountRegistration.phone : ''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="phoneExt"
                                            defaultValue2={accountRegistration.phoneExt ? accountRegistration.phoneExt : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="afterHoursPhone"
                                            defaultValue1={accountRegistration.afterHoursPhone ? accountRegistration.afterHoursPhone : ''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="afterHoursPhoneExt"
                                            defaultValue2={accountRegistration.afterHoursPhoneExt ? accountRegistration.afterHoursPhoneExt : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Fax #" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" />}
                                            rules={{ required: false }}
                                            name="fax"
                                            defaultValue={accountRegistration.fax ? accountRegistration.fax : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Company Address (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.streetAddress1"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.streetAddress1 ? accountRegistration.companyAddress.streetAddress1 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="City" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.city"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.city ? accountRegistration.companyAddress.city : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Postal Code" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.postalCode"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.postalCode ? accountRegistration.companyAddress.postalCode : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                            rules={{ required: false }}
                                            name="companyAddress.streetAddress2"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.streetAddress2 ? accountRegistration.companyAddress.streetAddress2 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="State" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a State"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.state"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.state ? accountRegistration.companyAddress.state : undefined}
                                        />
                                        <FormItem {...formItemLayout} label="Country" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a Country"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="USA" key="USA">United States</Option>
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.country"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.country ? accountRegistration.companyAddress.country : undefined}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Carrier Information (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Number" />}
                                            rules={{ required: 'Required Field' }}
                                            name="carrier.mcNum"
                                            defaultValue={accountRegistration.carrier.mcNum ? accountRegistration.carrier.mcNum : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="US DOT #" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" />}
                                            rules={{ required: 'Required Field' }}
                                            name="carrier.dotNum"
                                            defaultValue={accountRegistration.carrier.dotNum ? accountRegistration.carrier.dotNum : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Power Units" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.numPowerUnits"
                                            defaultValue={accountRegistration.carrier.numPowerUnits ? accountRegistration.carrier.numPowerUnits : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Trailers" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.numTrailers"
                                            defaultValue={accountRegistration.carrier.numTrailers ? accountRegistration.carrier.numTrailers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.numDrivers"
                                            defaultValue={accountRegistration.carrier.numDrivers ? accountRegistration.carrier.numDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of HazMat Drivers" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.numHazMatDrivers"
                                            defaultValue={accountRegistration.carrier.numHazMatDrivers ? accountRegistration.carrier.numHazMatDrivers : ''}
                                        />
                                    </Col>
                                    <Col xxl={{ span: 4 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                        <FormItem {...formItemLayout} label="# of Teams" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.numTeams"
                                            defaultValue={accountRegistration.carrier.numTeams ? accountRegistration.carrier.numTeams : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Accounts Receivable (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Preferred Payment Method" format="vertical" required
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} buttonStyle="solid">
                                                    <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                                    <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                                    <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                                </Radio.Group>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="carrier.preferredPaymentMethod"
                                            defaultValue={accountRegistration.carrier.preferredPaymentMethod ? accountRegistration.carrier.preferredPaymentMethod : undefined}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col span={24}>
                                        <Paragraph>If your accounts receivable department is different than your physical address, or if you are using a factoring company, please complete below:</Paragraph>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Person or Company Name" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Company Name" />}
                                            rules={{ required: false }}
                                            name="carrier.accountsReceivable.name"
                                            defaultValue={accountRegistration.carrier.accountsReceivable && accountRegistration.carrier.accountsReceivable.name ? accountRegistration.carrier.accountsReceivable.name : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="carrier.accountsReceivable.email"
                                            defaultValue={accountRegistration.carrier.accountsReceivable && accountRegistration.carrier.accountsReceivable.email ? accountRegistration.carrier.accountsReceivable.email : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="carrier.accountsReceivable.phone"
                                            defaultValue1={accountRegistration.carrier.accountsReceivable && accountRegistration.carrier.accountsReceivable.phone ? accountRegistration.carrier.accountsReceivable.phone : ''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="carrier.accountsReceivable.phoneExt"
                                            defaultValue2={accountRegistration.carrier.accountsReceivable && accountRegistration.carrier.accountsReceivable.phoneExt ? accountRegistration.carrier.accountsReceivable.phoneExt : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 1" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.streetAddress1"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.streetAddress1 ? accountRegistration.carrier.shippingAddress.streetAddress1 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="City" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.city"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.city ? accountRegistration.carrier.shippingAddress.city : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Postal Code" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.postalCode"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.postalCode ? accountRegistration.carrier.shippingAddress.postalCode : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.streetAddress2"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.streetAddress2 ? accountRegistration.carrier.shippingAddress.streetAddress2 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="State" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a State"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                                </Select>
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.state"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.state ? accountRegistration.carrier.shippingAddress.state : undefined}
                                        />
                                        <FormItem {...formItemLayout} label="Country" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a Country"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="USA" key="USA">United States</Option>
                                                </Select>
                                            )}
                                            rules={{ required: false }}
                                            name="carrier.shippingAddress.country"
                                            defaultValue={accountRegistration.carrier.shippingAddress && accountRegistration.carrier.shippingAddress.country ? accountRegistration.carrier.shippingAddress.country : undefined}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Trailer Types</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has48Reefer"
                                            defaultValue1={accountRegistration.carrier.has48Reefer ? accountRegistration.carrier.has48Reefer : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num48Reefer"
                                            defaultValue2={accountRegistration.carrier.num48Reefer ? accountRegistration.carrier.num48Reefer : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Reefers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has53Reefer"
                                            defaultValue1={accountRegistration.carrier.has53Reefer ? accountRegistration.carrier.has53Reefer : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num53Reefer"
                                            defaultValue2={accountRegistration.carrier.num53Reefer ? accountRegistration.carrier.num53Reefer : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul frozen?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="carrier.hasReeferFrozen"
                                            defaultValue={accountRegistration.carrier.hasReeferFrozen ? accountRegistration.carrier.hasReeferFrozen : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has48DryVan"
                                            defaultValue1={accountRegistration.carrier.has48DryVan ? accountRegistration.carrier.has48DryVan : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num48DryVan"
                                            defaultValue2={accountRegistration.carrier.num48DryVan ? accountRegistration.carrier.num48DryVan : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Dry Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has53DryVan"
                                            defaultValue1={accountRegistration.carrier.has53DryVan ? accountRegistration.carrier.has53DryVan : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num53DryVan"
                                            defaultValue2={accountRegistration.carrier.num53DryVan ? accountRegistration.carrier.num53DryVan : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="carrier.hasDryVanLiftGates"
                                            defaultValue={accountRegistration.carrier.hasDryVanLiftGates ? accountRegistration.carrier.hasDryVanLiftGates : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have roller beds for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="carrier.hasDryVanRollerBed"
                                            defaultValue={accountRegistration.carrier.hasDryVanRollerBed ? accountRegistration.carrier.hasDryVanRollerBed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you haul over dimensional/overweight loads?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="carrier.hasOverSized"
                                            defaultValue={accountRegistration.carrier.hasOverSized ? accountRegistration.carrier.hasOverSized : false}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 48' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has48Flatbed"
                                            defaultValue1={accountRegistration.carrier.has48Flatbed ? accountRegistration.carrier.has48Flatbed : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num48Flatbed"
                                            defaultValue2={accountRegistration.carrier.num48Flatbed ? accountRegistration.carrier.num48Flatbed : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 53' Flatbeds?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has53Flatbed"
                                            defaultValue1={accountRegistration.carrier.has53Flatbed ? accountRegistration.carrier.has53Flatbed : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num53Flatbed"
                                            defaultValue2={accountRegistration.carrier.num53Flatbed ? accountRegistration.carrier.num53Flatbed : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Low Boys?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasLowBoy"
                                            defaultValue1={accountRegistration.carrier.hasLowBoy ? accountRegistration.carrier.hasLowBoy : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numLowBoy"
                                            defaultValue2={accountRegistration.carrier.numLowBoy ? accountRegistration.carrier.numLowBoy : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have RGNs?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasRGN"
                                            defaultValue1={accountRegistration.carrier.hasRGN ? accountRegistration.carrier.hasRGN : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numRGN"
                                            defaultValue2={accountRegistration.carrier.numRGN ? accountRegistration.carrier.numRGN : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks with Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasStepDeckRamps"
                                            defaultValue1={accountRegistration.carrier.hasStepDeckRamps ? accountRegistration.carrier.hasStepDeckRamps : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numStepDeckRamps"
                                            defaultValue2={accountRegistration.carrier.numStepDeckRamps ? accountRegistration.carrier.numStepDeckRamps : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Step Decks without Ramps?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasStepDeckNoRamps"
                                            defaultValue1={accountRegistration.carrier.hasStepDeckNoRamps ? accountRegistration.carrier.hasStepDeckNoRamps : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numStepDeckNoRamps"
                                            defaultValue2={accountRegistration.carrier.numStepDeckNoRamps ? accountRegistration.carrier.numStepDeckNoRamps : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Single Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasTankersSingleCompartment"
                                            defaultValue1={accountRegistration.carrier.hasTankersSingleCompartment ? accountRegistration.carrier.hasTankersSingleCompartment : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numTankersSingleCompartment"
                                            defaultValue2={accountRegistration.carrier.numTankersSingleCompartment ? accountRegistration.carrier.numTankersSingleCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Multi Compartment Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasTankersMultiCompartment"
                                            defaultValue1={accountRegistration.carrier.hasTankersMultiCompartment ? accountRegistration.carrier.hasTankersMultiCompartment : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numTankersMultiCompartment"
                                            defaultValue2={accountRegistration.carrier.numTankersMultiCompartment ? accountRegistration.carrier.numTankersMultiCompartment : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Fiber Glass Tankers?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasTankersFiberGlass"
                                            defaultValue1={accountRegistration.carrier.hasTankersFiberGlass ? accountRegistration.carrier.hasTankersFiberGlass : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numTankersFiberGlass"
                                            defaultValue2={accountRegistration.carrier.numTankersFiberGlass ? accountRegistration.carrier.numTankersFiberGlass : ''}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Cubed Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasCubedStraightTruck"
                                            defaultValue1={accountRegistration.carrier.hasCubedStraightTruck ? accountRegistration.carrier.hasCubedStraightTruck : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numCubedStraightTruck"
                                            defaultValue2={accountRegistration.carrier.numCubedStraightTruck ? accountRegistration.carrier.numCubedStraightTruck : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have 26' Straight Trucks?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.has26StraightTruck"
                                            defaultValue1={accountRegistration.carrier.has26StraightTruck ? accountRegistration.carrier.has26StraightTruck : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.num26StraightTruck"
                                            defaultValue2={accountRegistration.carrier.num26StraightTruck ? accountRegistration.carrier.num26StraightTruck : ''}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you have lift gates for the straight trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="carrier.hasStraightTruckLiftGates"
                                            defaultValue={accountRegistration.carrier.hasStraightTruckLiftGates ? accountRegistration.carrier.hasStraightTruckLiftGates : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                        <FormItemDouble {...formItemLayoutDoubleHorizontal} label1="Do you have Sprinter Vans?" noColon1 label2="If so, how many?" noColon2 format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name1="carrier.hasSprinterVan"
                                            defaultValue1={accountRegistration.carrier.hasSprinterVan ? accountRegistration.carrier.hasSprinterVan : false}
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={0}
                                                    min={0}
                                                    step={1}
                                                    placeholder="How many?"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            name2="carrier.numSprinterVan"
                                            defaultValue2={accountRegistration.carrier.numSprinterVan ? accountRegistration.carrier.numSprinterVan : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Update Account Application Review Status</legend>
                                <FormItem {...formItemLayout} label="Review Status" format="vertical" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please select a Review Status"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {accountRegistrationReviewStatusOptions}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="reviewStatus"
                                    defaultValue={accountRegistration.reviewStatus}
                                />
                            </Fieldset>
                            {error && <Alert message={error} type="error" />}
                        </Spin>
                        <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Account Application" />
                    </Form>
                </FormProvider>
            );
        } else if (accountRegistration.isShipper === true && accountRegistration !== undefined && accountRegistration !== null && accountRegistration.shipper !== undefined && accountRegistration.shipper !== null) {
            return (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset>
                                <legend>Company Contact Information (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="name"
                                            defaultValue={accountRegistration.name ? accountRegistration.name : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Contact Name" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Contact Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                            defaultValue={accountRegistration.pointOfContact && accountRegistration.pointOfContact.name ? accountRegistration.pointOfContact.name : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Email" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="email"
                                            defaultValue={accountRegistration.pointOfContact && accountRegistration.pointOfContact.email ? accountRegistration.pointOfContact.email : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Federal Id/EIN" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" />}
                                            rules={{ required: 'Required Field' }}
                                            name="ein"
                                            defaultValue={accountRegistration.ein ? accountRegistration.ein : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Day Time Phone #" required1 format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="phone"
                                            defaultValue1={accountRegistration.phone ? accountRegistration.phone : ''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="phoneExt"
                                            defaultValue2={accountRegistration.phoneExt ? accountRegistration.phoneExt : ''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="After Hours Phone #" required1 format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="afterHoursPhone"
                                            defaultValue1={accountRegistration.afterHoursPhone ? accountRegistration.afterHoursPhone : ''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="afterHoursPhoneExt"
                                            defaultValue2={accountRegistration.afterHoursPhoneExt ? accountRegistration.afterHoursPhoneExt : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Fax #" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" />}
                                            rules={{ required: false }}
                                            name="fax"
                                            defaultValue={accountRegistration.fax ? accountRegistration.fax : ''}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Company Address (* indicates a required field)</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 1" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.streetAddress1"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.streetAddress1 ? accountRegistration.companyAddress.streetAddress1 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="City" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.city"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.city ? accountRegistration.companyAddress.city : ''}
                                        />
                                        <FormItem {...formItemLayout} label="Postal Code" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.postalCode"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.postalCode ? accountRegistration.companyAddress.postalCode : ''}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                            rules={{ required: false }}
                                            name="companyAddress.streetAddress2"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.streetAddress2 ? accountRegistration.companyAddress.streetAddress2 : ''}
                                        />
                                        <FormItem {...formItemLayout} label="State" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a State"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.state"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.state ? accountRegistration.companyAddress.state : undefined}
                                        />
                                        <FormItem {...formItemLayout} label="Country" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please Select a Country"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="USA" key="USA">United States</Option>
                                                </Select>
                                            )}
                                            rules={{ required: 'Required Field' }}
                                            name="companyAddress.country"
                                            defaultValue={accountRegistration.companyAddress && accountRegistration.companyAddress.country ? accountRegistration.companyAddress.country : undefined}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>What are your equipment needs based on the commodities you ship?</legend>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Reefers</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Reefers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has48Reefer"
                                            defaultValue={accountRegistration.shipper.has48Reefer ? accountRegistration.shipper.has48Reefer : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Reefers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has53Reefer"
                                            defaultValue={accountRegistration.shipper.has53Reefer ? accountRegistration.shipper.has53Reefer : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship frozen?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasReeferFrozen"
                                            defaultValue={accountRegistration.shipper.hasReeferFrozen ? accountRegistration.shipper.hasReeferFrozen : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Dry Vans</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Dry Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has48DryVan"
                                            defaultValue={accountRegistration.shipper.has48DryVan ? accountRegistration.shipper.has48DryVan : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Dry Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has53DryVan"
                                            defaultValue={accountRegistration.shipper.has53DryVan ? accountRegistration.shipper.has53DryVan : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasDryVanLiftGates"
                                            defaultValue={accountRegistration.shipper.hasDryVanLiftGates ? accountRegistration.shipper.hasDryVanLiftGates : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need roller beds for the dry vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasDryVanRollerBed"
                                            defaultValue={accountRegistration.shipper.hasDryVanRollerBed ? accountRegistration.shipper.hasDryVanRollerBed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Over-Sized Loads</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need to ship over dimensional/overweight loads?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasOverSized"
                                            defaultValue={accountRegistration.shipper.hasOverSized ? accountRegistration.shipper.hasOverSized : false}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 16 }}>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Flatbeds</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 48' Flatbeds?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has48Flatbed"
                                            defaultValue={accountRegistration.shipper.has48Flatbed ? accountRegistration.shipper.has48Flatbed : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 53' Flatbeds?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has53Flatbed"
                                            defaultValue={accountRegistration.shipper.has53Flatbed ? accountRegistration.shipper.has53Flatbed : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Low Boys</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Low Boys?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasLowBoy"
                                            defaultValue={accountRegistration.shipper.hasLowBoy ? accountRegistration.shipper.hasLowBoy : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>RGNs</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need RGNs?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasRGN"
                                            defaultValue={accountRegistration.shipper.hasRGN ? accountRegistration.shipper.hasRGN : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Step Decks</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks with Ramps?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasStepDeckRamps"
                                            defaultValue={accountRegistration.shipper.hasStepDeckRamps ? accountRegistration.shipper.hasStepDeckRamps : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Step Decks without Ramps?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasStepDeckNoRamps"
                                            defaultValue={accountRegistration.shipper.hasStepDeckNoRamps ? accountRegistration.shipper.hasStepDeckNoRamps : false}
                                        />
                                    </Col>
                                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                                        <h4 style={{ textAlign: 'center' }}>Tankers</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Single Compartment Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasTankersSingleCompartment"
                                            defaultValue={accountRegistration.shipper.hasTankersSingleCompartment ? accountRegistration.shipper.hasTankersSingleCompartment : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Multi Compartment Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasTankersMultiCompartment"
                                            defaultValue={accountRegistration.shipper.hasTankersMultiCompartment ? accountRegistration.shipper.hasTankersMultiCompartment : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Fiber Glass Tankers?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasTankersFiberGlass"
                                            defaultValue={accountRegistration.shipper.hasTankersFiberGlass ? accountRegistration.shipper.hasTankersFiberGlass : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Straight Trucks</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Cubed Straight Trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasCubedStraightTruck"
                                            defaultValue={accountRegistration.shipper.hasCubedStraightTruck ? accountRegistration.shipper.hasCubedStraightTruck : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need 26' Straight Trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.has26StraightTruck"
                                            defaultValue={accountRegistration.shipper.has26StraightTruck ? accountRegistration.shipper.has26StraightTruck : false}
                                        />
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need lift gates for the straight trucks?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasStraightTruckLiftGates"
                                            defaultValue={accountRegistration.shipper.hasStraightTruckLiftGates ? accountRegistration.shipper.hasStraightTruckLiftGates : false}
                                        />
                                        <h4 style={{ textAlign: 'center' }}>Sprinter Vans</h4>
                                        <FormItem {...formItemLayoutHorizontal} label="Do you need Sprinter Vans?" noColon format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Checkbox
                                                    onBlur={onBlur}
                                                    onChange={e => onChange(e.target.checked)}
                                                    checked={value}
                                                    name={name}
                                                />
                                            )}
                                            name="shipper.hasSprinterVan"
                                            defaultValue={accountRegistration.shipper.hasSprinterVan ? accountRegistration.shipper.hasSprinterVan : false}
                                        />
                                    </Col>
                                </Row>
                            </Fieldset>
                            <Fieldset>
                                <legend>Update Account Application Review Status</legend>
                                <FormItem {...formItemLayout} label="Review Status" format="vertical" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please select a Review Status"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {accountRegistrationReviewStatusOptions}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="reviewStatus"
                                    defaultValue={accountRegistration.reviewStatus ? accountRegistration.reviewStatus : undefined}
                                />
                            </Fieldset>
                            {error && <Alert message={error} type="error" />}
                        </Spin>
                        <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Account Application" />
                    </Form>
                </FormProvider>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default EditAccountRegistration;