import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleStatsLinkCard.module.scss';

const { Title } = Typography;

const SimpleStatsLinkCard = props => {
    return (
        <Card bordered={true} title={props.title} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={props.extra}>
            {props.date ? (<div><span>{props.date}</span></div>) : null}
            <div><a href={props.url} style={{ textDecoration: 'underline' }}><Title level={props.amountSize ? props.amountSize : 3} style={{ color: '#16588E' }}>{props.amount}</Title></a></div>
        </Card>
    );
};

export default SimpleStatsLinkCard;
