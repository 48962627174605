import React, { useMemo } from 'react';
import './App.scss';
import DrawerLayout from "./hoc/DrawerLayout/DrawerLayout";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./views/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "./store/actions/index";
import Track from './views/Track/Track';
import Map from './views/Map/Map';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ResetPasswordConfirmation from './views/ResetPasswordConfirmation/ResetPasswordConfirmation';
import PriceConfirmation from './views/PriceConfirmation/PriceConfirmation';
import RateConfirmation from './views/RateConfirmation/RateConfirmation';
import ShipperInvoice from './views/ShipperInvoice/ShipperInvoice';
import Terms from "./views/Terms/Terms";

const RoleProtectedRoute = ({ rolesCheck, isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                rolesCheck === true ? (
                    <Component {...props} />
                ) : (isAuthenticated === true ? (
                    <Redirect
                        to={{
                            pathname: "/noaccess",
                            state: { from: props.location }
                        }}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    ))
            }
        />
    );
};

const EntityProtectedRoute = ({ entitiesCheck, isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                entitiesCheck === true ? (
                    <Component {...props} />
                ) : (isAuthenticated === true ? (
                    <Redirect
                        to={{
                            pathname: "/noaccess",
                            state: { from: props.location }
                        }}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    ))
            }
        />
    );
};

const App = () => {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isStaff = useSelector(state => state.auth.isStaff);
    const entityType = useSelector(state => state.auth.entityType);

    useMemo(() => {
        // Auto Login if Token is Available
        dispatch(actionCreators.autoLogin());
    }, []);

    useMemo(() => {
        console.log(`isAuthenticated: ${isAuthenticated}`);

        if (isAuthenticated === true) {
            dispatch(actionCreators.getOrchestrator());
            dispatch(actionCreators.fetchKeys());
        }
    }, [isAuthenticated]);

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={Login} />
                {/* <Route exact path="/register" component={Register} /> */}
                <Route exact path="/forgotPassword" component={ForgotPassword} />
                <Route exact path="/resetPassword/:uuid/:token/:entityType?" component={ResetPassword} />
                <Route exact path="/resetPasswordConfirmation/:entityType?" component={ResetPasswordConfirmation} />
                <Route exact path="/track/:loadId" component={Track} />
                <Route exact path="/map/:loadId" component={Map} />
                <Route exact path="/terms" component={Terms} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={true} path="/priceConfirmation/:loadId" component={PriceConfirmation} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(isStaff === true)} path="/shipperInvoice/:loadId" component={ShipperInvoice} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={(entityType === "STAFF" || entityType === "CARRIER")} path="/rateConfirmation/:loadId" component={RateConfirmation} />
                <Route path="/" component={DrawerLayout} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;