import React, { useMemo } from 'react';
import { Alert, Spin, Checkbox, Input } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { withRouter } from 'react-router';

const { TextArea } = Input;

const EditLoadStatusRejectRateConfirmation = props => {
    const load = props.load;
    const carrierId = props.load.assignedCarrierId;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.carrierRejections.isRecordAddLoading);
    const error = useSelector(state => state.carrierRejections.addRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const onSubmit = (data) => {
        let payload = {};
        payload.loadId = load.id;
        payload.loadLaneId = load.loadLaneId;
        payload.rejectedReason = data.rejectedReason;
        payload.rejectedByCarrierId = carrierId;

        dispatch(actionCreators.addCarrierRejection(payload));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddCarrierRejection());
        props.cancel();
    };

    const done = () => {
        dispatch(actionCreators.cancelAddCarrierRejection());
        props.history.push({ pathname: "/openLoads/" });
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addCarrierRejectionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            if (entityType === 'CARRIER') {
                done();
            } else {
                cancel();
            }
        }
    }, [isLoading, error, entityType]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Rejecte Rate Confirmation</legend>
                        {entityType === 'STAFF' && (
                            <b>The Carrier rejected the Rate Confirmation? This will remove the Carrier (and drivers and assets) from the Load and change the Status of the Load back to Approved.</b>
                        )}
                        {entityType === 'CARRIER' && (
                            <b>Would you like to reject this Rate Confirmation? This will remove the load request from your queue.</b>
                        )}
                        <FormItem {...formItemLayout} label="Reason for Rejection" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                            rules={{ required: 'Required Field' }}
                            name="rejectedReason"
                        />
                        <FormItem {...formItemLayout} label={`I Reject the Rate Confirmation${entityType === 'STAFF' ? ' on behalf of the Carrier' : ''}`} required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                validate: {
                                    mustBeTrue: checked => checked === true || 'Rate Confirmation must be Rejected' // value must be true
                                }
                            }}
                            name="rejectedRateConfirmation"
                            defaultValue={false}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Reject Rate Confirmation" />
            </Form>
        </FormProvider>
    );
};

export default withRouter(EditLoadStatusRejectRateConfirmation);