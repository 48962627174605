import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, Typography, Card, Spin } from 'antd';
import classes from './QueueDriversPendingApproval.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faExternalLinkAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import DocumentList from "../DocumentList/DocumentList";
import Queue from '../Queue/Queue';

const { Title } = Typography;

const QueueDriversPendingApproval = props => {
    const title = 'Drivers Awaiting Approval';

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.metrics.driversPendingApprovalIsLoading);
    const data = useSelector(state => state.metrics.driversPendingApproval);
    const pagination = useSelector(state => state.metrics.driversPendingApprovalPagination);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [showTableModal, setShowTableModal] = useState(false);

    const toggleTableModal = () => {
        setShowTableModal(!showTableModal);
    };

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const approveDriver = (accountUserId) => {
        dispatch(actionCreators.approveDriver(accountUserId));
    };

    const refreshQueue = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false,
            'driver.isApproved:or': false,
            'driver.documentPendingApprovalCount:gt:or': 0
        };

        dispatch(actionCreators.fetchDriversPendingApproval(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchDriversPendingApprovalSuccess({ driversPendingApprovalPagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false,
            'driver.isApproved:or': false,
            'driver.documentPendingApprovalCount:gt:or': 0
        };

        dispatch(actionCreators.fetchDriversPendingApproval(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Tooltip placement="top" title="Open Documents"><Button key="5" type="primary" icon={<PaperClipOutlined />} shape='circle' onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleDocumentsModal(); }} style={{ marginRight: '8px' }} /></Tooltip>
                );
            },
            align: 'center',
        },
        {
            title: 'Driver ID',
            dataIndex: ['driver', 'irisId'],
            key: 'driver.irisId',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        }
    ];

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {selectedRecord && <DocumentList entityType="DRIVER" entityId={selectedRecord.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
            {selectedRecord !== undefined && selectedRecord !== null && selectedRecord.driver !== undefined && selectedRecord.driver !== null && selectedRecord.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
            ) : null}
        </Modal>
    );

    const buttons = (
        <>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={refreshQueue} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faRedoAlt} size="lg" />
                </Button>
            </Tooltip>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={toggleTableModal}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        refreshQueue();
    }, []);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Card bordered={true} className={classes.card} style={props.style ? props.style : null} title={title} extra={buttons} size="small">
                {pagination !== undefined && pagination !== null ? (
                    <Typography>
                        <div><Title level={3}>{pagination.total}</Title></div>
                    </Typography>
                ) : (
                        <Typography>
                            <div><Title level={3}>0</Title></div>
                        </Typography>
                    )}
            </Card>
            <Modal
                title={title}
                visible={showTableModal}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleTableModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <Queue
                    dataSource={data}
                    columns={columns}
                    loading={isLoading === true}
                    pagination={pagination}
                    rowClassName={classes.dataTableRow}
                    onChange={handleTableChange}
                >
                    {documentsComponent}
                </Queue>
            </Modal>
        </Spin>
    );
};

export default QueueDriversPendingApproval;
