import React, { useMemo } from 'react';
import { Alert, Input, Spin } from "antd";
import { isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';

const NewConfiguration = props => {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.configurations.isRecordAddLoading);
    const error = useSelector(state => state.configurations.addRecordError);
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (!isEmpty(cleanedData)) {
            dispatch(actionCreators.addConfiguration(cleanedData));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddConfiguration());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addConfigurationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <FormItem {...formItemLayout} label="Name" required
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                        rules={{ required: 'Required Field' }}
                        name="name"
                    />
                    <FormItem {...formItemLayout} label="Customer Support Phone #" required
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Support Phone #" />}
                        rules={{ required: 'Required Field' }}
                        name="companyPhoneNumber"
                    />
                    <FormItem {...formItemLayout} label="Geo Fence Radius (in miles)" required
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Geo Fence Radius" />}
                        rules={{ required: 'Required Field' }}
                        name="geoFenceRadius"
                    />
                    <FormItem {...formItemLayout} label="Location Distance Interval (in miles)" required
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Location Distance Interval" />}
                        rules={{ required: 'Required Field' }}
                        name="locationDistanceInterval"
                    />
                    <FormItem {...formItemLayout} label="Location Time Interval (in seconds)" required
                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Location Time Interval" />}
                        rules={{ required: 'Required Field' }}
                        name="locationTimeInterval"
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Configuration" />
            </Form>
        </FormProvider>
    );
};

export default NewConfiguration;