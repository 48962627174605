import React, { useState, useMemo, useEffect } from 'react';
import { Input, Select, Alert, Spin, InputNumber } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import moment from 'moment';
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemDouble from '../FormItemDouble/FormItemDouble';

const EditTransaction = props => {
    const transaction = props.transaction;
    const invoiceLineItems = props.invoiceLineItems;
    const shipperId = props.load.shipperId;
    const carrierId = props.load.assignedCarrierId;
    const loadId = props.load.id;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.transactions.isRecordUpdateLoading);
    const error = useSelector(state => state.transactions.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const paymentMethodOptions = Enums.PaymentMethods.selectListOptions();
    const paymentStatusOptions = Enums.PaymentStatuses.selectListOptions();
    const invoiceSourceAccountTypeOptions = entityType === "CARRIER" ? Enums.InvoiceSourceAccountTypes.selectListOptionsWithExclusions(["STAFF"]) : Enums.InvoiceSourceAccountTypes.selectListOptions();
    const invoiceAccountTypeOptions = entityType === "CARRIER" ? Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF"]) : Enums.InvoiceAccountTypes.selectListOptions();

    const [amount, setAmount] = useState(0);
    const [transactionFee, setTransactionFee] = useState(0);

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            if (data.fromEntityType === "SHIPPER") {
                if (shipperId !== undefined && shipperId !== null) {
                    data.fromEntityId = shipperId;
                }
            } else if (data.fromEntityType === "CARRIER") {
                if (carrierId !== undefined && carrierId !== null) {
                    data.fromEntityId = carrierId;
                }
            }

            if (data.toEntityType === "SHIPPER") {
                if (shipperId !== undefined && shipperId !== null) {
                    data.toEntityId = shipperId;
                }
            } else if (data.toEntityType === "CARRIER") {
                if (carrierId !== undefined && carrierId !== null) {
                    data.toEntityId = carrierId;
                }
            }

            dispatch(actionCreators.updateTransaction(transaction.id, data, transaction, invoiceLineItems, loadId));
        }
    };

    const onFromSourceChange = (value) => {
        if (value !== null && value !== undefined) {
            if (value === "SHIPPER") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("fromEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("fromEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("fromEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const onToSourceChange = (value) => {
        if (value !== null && value !== undefined) {
            if (value === "SHIPPER") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "CARRIER") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "FACTORING") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "INSURANCE") {
                methods.setValue("toEntityType", "CARRIER");
            } else if (value === "CREDIT") {
                methods.setValue("toEntityType", "SHIPPER");
            } else if (value === "STAFF") {
                methods.setValue("toEntityType", "STAFF");
            }
        } else {
            methods.setValue("fromEntityType", undefined);
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateTransaction());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateTransactionErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    useEffect(() => {
        // console.log(amount);
        // console.log(transactionFee);

        if (!isNaN(amount) && !isNaN(transactionFee)) {
            methods.setValue("netAmount", amount - transactionFee);
        } else {
            methods.setValue("netAmount", '');
        }
    }, [amount, transactionFee]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Update Transaction</legend>
                        <FormItem {...formItemLayout} label="Quick Books Transaction Id" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Quick Books Transaction Id" />}
                            rules={{ required: false }}
                            name="qbTransactionId"
                            defaultValue={transaction.qbTransactionId}
                        />
                        <FormItem {...formItemLayout} label="Payment Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Payment Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { setAmount(e); onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{ required: "Required Field" }}
                            name="amount"
                            defaultValue={transaction.amount}
                        />
                        <FormItem {...formItemLayout} label="Transaction Fee (if any)" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Transaction Fee (if any)"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { setTransactionFee(e); onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{ required: false }}
                            name="transactionFee"
                            defaultValue={transaction.transactionFee}
                        />
                        <FormItem {...formItemLayout} label="Net Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Net Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    disabled={true}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="netAmount"
                            defaultValue={transaction.netAmount}
                        />
                        <FormItem {...formItemLayout} label="Payment Method" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Method"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {paymentMethodOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="paymentMethod"
                            defaultValue={transaction.paymentMethod}
                        />
                        <FormItem {...formItemLayout} label="Payment Status" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {paymentStatusOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="paymentStatus"
                            defaultValue={transaction.paymentStatus}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="From" label2="On Behalf Of" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Source"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onFromSourceChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceSourceAccountTypeOptions}
                                </Select>
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="fromSource"
                            defaultValue1={transaction.fromSource}
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Beneficiary"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceAccountTypeOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="fromEntityType"
                            defaultValue2={transaction.fromEntityType}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="To" label2="On Behalf Of" required1 required2 format="vertical"
                            render1={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Source"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onToSourceChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceSourceAccountTypeOptions}
                                </Select>
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="toSource"
                            defaultValue1={transaction.toSource}
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Payment Beneficiary"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceAccountTypeOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="toEntityType"
                            defaultValue2={transaction.toEntityType}
                        />
                        <FormItem {...formItemLayout} label="Paid At" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <DatePicker
                                    placeholder="Paid At"
                                    format="YYYY-MM-DD"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={(date, dateString) => { onChange(date); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{ required: false }}
                            name="paidAt"
                            defaultValue={transaction.paidAt ? moment(transaction.paidAt) : null}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Transaction" />
            </Form>
        </FormProvider>
    );
};

export default EditTransaction;