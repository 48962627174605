import React from 'react';
import { Input, Typography, Button, Row, Col, Select } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from "react-redux";
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { removeEmpty, convertToObject } from '../../shared/objectUtils';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';

const { Paragraph } = Typography;
const { Option } = Select;

const NewAccountShipper = props => {
    const shipperData = props.data;
    const companyData = props.companyData;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const states = useSelector(state => state.orchestrator.states);

    const onSubmit = data => {
        let cleanedData = removeEmpty(data);
        //console.log(cleanedData);

        props.setStepIsComplete(true);
        props.setData(cleanedData);
        props.nextStepAction();
    };

    const saveForm = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        props.setData(data);
    };

    const copyCompanyPointOfContactToPointOfContact = () => {
        if (companyData !== undefined && companyData !== null && companyData.pointOfContact !== undefined && companyData.pointOfContact !== null) {
            methods.setValue('pointOfContact.name', companyData.pointOfContact.name);
            methods.setValue('pointOfContact.email', companyData.pointOfContact.email);
            methods.setValue('pointOfContact.phone', companyData.pointOfContact.phone);
            methods.setValue('pointOfContact.phoneExt', companyData.pointOfContact.phoneExt);
        }
    };

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Fieldset legend="Shipper Details">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label="D&B #" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="D&B #" />}
                                rules={{ required: false }}
                                name="dbNum"
                                defaultValue={shipperData.dbNum ? shipperData.dbNum : ''}
                            />
                            <FormItem {...formItemLayout} label="Produce Blue Book #" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Produce Blue Book #" />}
                                rules={{ required: false }}
                                name="blueBookNum"
                                defaultValue={shipperData.blueBookNum ? shipperData.blueBookNum : ''}
                            />
                        </Col>
                    </Row>
                </Fieldset>
                <Fieldset legend="Accounts Payable (* indicates a required field)">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label="Name" format="vertical" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                rules={{ required: "Required Field" }}
                                name="accountsPayable.name"
                                defaultValue={shipperData.accountsPayable && shipperData.accountsPayable.name ? shipperData.accountsPayable.name : ""}
                            />
                            <FormItem {...formItemLayout} label="Email" format="vertical" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                rules={{
                                    required: "Required Field",
                                    pattern: {
                                        value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                        message: "Please enter a valid email address"
                                    }
                                }}
                                name="accountsPayable.email"
                                defaultValue={shipperData.accountsPayable && shipperData.accountsPayable.email ? shipperData.accountsPayable.email : ""}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical" required1
                                render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                rules1={{
                                    required: "Required Field",
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Please enter a valid 10 digit phone number with no special characters"
                                    }
                                }}
                                name1="accountsPayable.phone"
                                defaultValue1={shipperData.accountsPayable && shipperData.accountsPayable.phone ? shipperData.accountsPayable.phone : ""}
                                render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                rules2={{ required: false }}
                                name2="accountsPayable.phoneExt"
                                defaultValue2={shipperData.accountsPayable && shipperData.accountsPayable.phoneExt ? shipperData.accountsPayable.phoneExt : ""}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the shipper's accounts payable department's address is different than the company's main physical address or mailing address, please provide it below.</Paragraph>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label="Street Address 1" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                rules={{ required: false }}
                                name="billingAddress.streetAddress1"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.streetAddress1 ? shipperData.billingAddress.streetAddress1 : ""}
                            />
                            <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                rules={{ required: false }}
                                name="billingAddress.streetAddress2"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.streetAddress2 ? shipperData.billingAddress.streetAddress2 : ""}
                            />
                            <FormItem {...formItemLayout} label="City" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                rules={{ required: false }}
                                name="billingAddress.city"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.city ? shipperData.billingAddress.city : ""}
                            />
                            <FormItem {...formItemLayout} label="State" format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a State"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="billingAddress.state"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.state ? shipperData.billingAddress.state : undefined}
                            />
                            <FormItem {...formItemLayout} label="Postal Code" format="vertical"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                rules={{ required: false }}
                                name="billingAddress.postalCode"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.postalCode ? shipperData.billingAddress.postalCode : ""}
                            />
                            <FormItem {...formItemLayout} label="Country" format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Country"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="USA" key="USA">United States</Option>
                                    </Select>
                                )}
                                rules={{ required: false }}
                                name="billingAddress.country"
                                defaultValue={shipperData.billingAddress && shipperData.billingAddress.country ? shipperData.billingAddress.country : undefined}
                            />
                        </Col>
                    </Row>
                </Fieldset>
                <Fieldset legend="Point of Contact for Shipper Operations Related Purposes (* indicates a required field)">
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Button type="primary" onClick={() => { copyCompanyPointOfContactToPointOfContact(); }}>Copy from Company Main Point of Contact</Button>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FormItem {...formItemLayout} label="Name" format="vertical" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                rules={{ required: 'Required Field' }}
                                name="pointOfContact.name"
                                defaultValue={shipperData.pointOfContact && shipperData.pointOfContact.name ? shipperData.pointOfContact.name : ""}
                            />
                            <FormItem {...formItemLayout} label="Email" format="vertical" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                rules={{
                                    required: 'Required Field',
                                    pattern: {
                                        value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                        message: "Please enter a valid email address"
                                    }
                                }}
                                name="pointOfContact.email"
                                defaultValue={shipperData.pointOfContact && shipperData.pointOfContact.email ? shipperData.pointOfContact.email : ""}
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical" required1
                                render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                rules1={{
                                    required: 'Required Field',
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: "Please enter a valid 10 digit phone number with no special characters"
                                    }
                                }}
                                name1="pointOfContact.phone"
                                defaultValue1={shipperData.pointOfContact && shipperData.pointOfContact.phone ? shipperData.pointOfContact.phone : ""}
                                render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                rules2={{ required: false }}
                                name2="pointOfContact.phoneExt"
                                defaultValue2={shipperData.pointOfContact && shipperData.pointOfContact.phoneExt ? shipperData.pointOfContact.phoneExt : ""}
                            />
                        </Col>
                    </Row>
                </Fieldset>
                <div
                    style={{
                        position: 'relative',
                        right: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 0px',
                        background: '#fff',
                        textAlign: 'right',
                        marginTop: '0px',
                    }}
                >
                    <Button onClick={() => { props.previousStepAction(); }} style={{ marginRight: 8 }}>Go to Previous Step</Button>
                    <Button onClick={() => { saveForm(); }} style={{ marginRight: 8 }}>Save Changes</Button>
                    <Button type="primary" htmlType="submit">{(companyData !== undefined && companyData !== null && companyData.isCarrier !== undefined && companyData.isCarrier !== null && companyData.isCarrier === true) ? 'Go to Next Step' : 'Review Account Details'}</Button>
                </div>
            </Form>
        </FormProvider>
    );
};

export default NewAccountShipper;