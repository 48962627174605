import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Configurations Functions

export const fetchConfigurationsStart = () => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_START
    }
};

export const fetchConfigurationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchConfigurationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CONFIGURATIONS_FAIL,
        payload: payload
    }
};

export const clearConfigurations = () => {
    return {
        type: actionTypes.CLEAR_CONFIGURATIONS
    }
};

export const addConfigurationStart = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_START
    }
};

export const addConfigurationSuccess = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_SUCCESS
    }
};

export const addConfigurationFail = (payload) => {
    return {
        type: actionTypes.ADD_CONFIGURATION_FAIL,
        payload: payload
    }
};

export const addConfigurationErrorClear = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_ERROR_CLEAR
    }
};

export const addConfigurationLoadingClear = () => {
    return {
        type: actionTypes.ADD_CONFIGURATION_LOADING_CLEAR
    }
};

export const updateConfigurationStart = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_START
    }
};

export const updateConfigurationSuccess = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_SUCCESS
    }
};

export const updateConfigurationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_FAIL,
        payload: payload
    }
};

export const updateConfigurationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_ERROR_CLEAR
    }
};

export const updateConfigurationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION_LOADING_CLEAR
    }
};

export const cancelAddConfiguration = () => {
    return {
        type: actionTypes.CANCEL_ADD_CONFIGURATION
    }
};

export const cancelUpdateConfiguration = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_CONFIGURATION
    }
};

export const insertConfiguration = (payload) => {
    return {
        type: actionTypes.ADD_CONFIGURATION,
        payload: payload
    }
};

export const changeConfiguration = (payload) => {
    return {
        type: actionTypes.UPDATE_CONFIGURATION,
        payload: payload
    }
};

export const changeSingleConfiguration = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CONFIGURATION,
        payload: payload
    }
};

export const removeConfiguration = (payload) => {
    return {
        type: actionTypes.REMOVE_CONFIGURATION,
        payload: payload
    }
};

//#endregion

//#region Configurations Methods

export const fetchConfigurations = (payload) => {
    return async (dispatch, getState) => {
        const configurationsPath = '/configurations';
        try {
            dispatch(fetchConfigurationsStart());

            const state = getState();
            const configurationsState = {...state.configurations};
            let pagination = {...configurationsState.pagination};
            let searchParams = {...configurationsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const configurationsRes = await axiosAuthenticated.get(configurationsPath, {params: {...searchParams}});
            if (configurationsRes && configurationsRes.status === 200) {
                const configurations = configurationsRes.data.data;

                // Read total count from server
                pagination.total = configurationsRes.data.totalCount;
                pagination.current = configurationsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchConfigurationsSuccess({
                    records: configurations,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchConfigurationsFail({error: error.message}));
        }
    }
};

export const addConfiguration = (payload) => {
    return async (dispatch) => {
        const configurationsPath = '/configurations';
        try {
            dispatch(addConfigurationStart());

            if (payload.geoFenceRadius !== undefined && payload.geoFenceRadius !== null) {
                payload.geoFenceRadius = Number(payload.geoFenceRadius);
                payload.geoFenceRadiusUnit = "MI";
            }
            if (payload.locationDistanceInterval !== undefined && payload.locationDistanceInterval !== null) {
                payload.locationDistanceInterval = Number(payload.locationDistanceInterval);
                payload.locationDistanceIntervalUnit = "MI";
            }
            if (payload.locationTimeInterval !== undefined && payload.locationTimeInterval !== null) {
                payload.locationTimeInterval = Number(payload.locationTimeInterval);
                payload.locationTimeIntervalUnit = "SECS";
            }

            const configurationsRes = await axiosAuthenticated.post(configurationsPath, {...payload});
            if (configurationsRes && configurationsRes.status === 201) {
                let newConfiguration = configurationsRes.data;

                dispatch(insertConfiguration(newConfiguration));

                dispatch(addConfigurationSuccess());
                dispatch(addConfigurationLoadingClear());
                dispatch(addConfigurationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addConfigurationFail({error: error.message}));
        }
    }
};

export const updateConfiguration = (configurationId, payload) => {
    return async (dispatch) => {
        const configurationsPath = '/configurations';
        try {
            dispatch(updateConfigurationStart());

            if (payload.geoFenceRadius !== undefined && payload.geoFenceRadius !== null) {
                payload.geoFenceRadius = Number(payload.geoFenceRadius);
                payload.geoFenceRadiusUnit = "MI";
            }
            if (payload.locationDistanceInterval !== undefined && payload.locationDistanceInterval !== null) {
                payload.locationDistanceInterval = Number(payload.locationDistanceInterval);
                payload.locationDistanceIntervalUnit = "MI";
            }
            if (payload.locationTimeInterval !== undefined && payload.locationTimeInterval !== null) {
                payload.locationTimeInterval = Number(payload.locationTimeInterval);
                payload.locationTimeIntervalUnit = "SECS";
            }

            const configurationsRes = await axiosAuthenticated.put(configurationsPath + `/${configurationId}`, {...payload});
            if (configurationsRes && configurationsRes.status === 200) {
                let updatedConfiguration = configurationsRes.data;

                if (updatedConfiguration.isDeleted === true) {
                    dispatch(removeConfiguration(updatedConfiguration));
                } else {
                    dispatch(changeConfiguration(updatedConfiguration));
                    dispatch(changeSingleConfiguration(updatedConfiguration));
                }

                dispatch(updateConfigurationSuccess());
                dispatch(updateConfigurationLoadingClear());
                dispatch(updateConfigurationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateConfigurationFail({error: error.message}));
        }
    }
};

//#endregion