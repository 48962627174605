import React, {useState, useEffect, useMemo} from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import {Alert, Button, Drawer, Input, Spin} from 'antd';
import classes from './Bids.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import {cancelUpdateBid, fetchCarrierBids, postCarrierBid, updateBidLoadingClear} from "../../store/actions/bids";
import Form from "../../components/Form/Form";
import FormItem from "../../components/FormItem/FormItem";
import FormButtons from "../../components/FormButtons/FormButtons";
import {FormProvider, useForm} from "react-hook-form";

const Bids = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Bid";
    const pluralEntityName = "Bids";
    const entityId = useSelector(state => state.auth.entityId);

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const error = useSelector(state => state.bids.updateRecordError);

    const dispatch = useDispatch();
    const isRecordUpdateLoading = useSelector(state => state.bids.isRecordUpdateLoading);
    const isLoading = useSelector(state => state.bids.isLoading);
    const records = useSelector(state => state.bids.records);
    const pagination = useSelector(state => state.bids.pagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const onSubmit = (data) => {
        dispatch(postCarrierBid({
            Amount: data.Amount,
            BidID: selectedRecord._id
        }));
        toggleEditEntity();
    };

    const cancel = () => {
        dispatch(cancelUpdateBid());
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(updateBidLoadingClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            sort: 'name', 
            order: 'asc',
            isDeleted: false,
            AccountID: entityId
        };

        dispatch(fetchCarrierBids(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchCommoditiesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchCommodities(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <>
                    <span>{!!record.IsBidSent && ("Sent bid with amount: $" + record.Amount)}</span>
                    {!record.bid.IsAccepted && !record.IsBidSent && (
                        <Button
                            type={"primary"}
                            onClick={(e) => {
                            e.stopPropagation(); setSelectedRecord(record);
                            toggleEditEntity(true);
                        }}>Send bid </Button>
                    )}
                    </>
                );
            },
            align: 'center',
        },
        {
            title: 'Shipper',
            render: (text, record) => {
                return (
                    <span>{record.shipper[0].name}</span>
                );
            },
            key: 'shipper',
        },
        {
            title: 'Load',
            render: (text, record) => {
                return (
                    <span>{record.load[0].irisId}</span>
                );
            },
            key: 'load',
        },
        {
            title: 'Stops',
            render: (text, record) => {
                return (
                    <span>{record.load[0]._id}</span>
                );
            },
            key: 'stops',
        },
        {
            title: 'Bid Status',
            render: (text, record) => {
                return (
                    <span>{!record.bid.IsAccepted ? "Pending answer" : (
                        record.bid.WinnerAccountID === entityId ? "BID WON!" : "BID REJECTED!"
                    )}</span>
                );
            },
            key: 'status',
        },

    ];

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <FormItem {...formItemLayout} label="Amount" required
                                  render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Amount" />}
                                  rules={{ required: "Required Field" }}
                                  name="Amount"
                                  defaultValue={0}
                        />
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={toggleEditEntity} disabled={isLoading === true && error === null} submitText="Send bid amount" />
                </Form>
            </FormProvider>
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    console.log("records", records);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(Bids);
