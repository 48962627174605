import React, { useState, useEffect } from 'react';
import ImageCard from "../../components/ImageCard/ImageCard";
import DataRow from '../../components/DataRow/DataRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataNameRow from '../../components/DataNameRow/DataNameRow';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip, Button, Drawer, Spin } from 'antd';
import DetailsList from '../../components/DetailsList/DetailsList';
import DetailsListDataRow from '../../components/DetailsListDataRow/DetailsListDataRow';
import DetailsListHeaderActionRow from '../../components/DetailsListHeaderActionRow/DetailsListHeaderActionRow';
import { tableBackgroundStyle } from "../../constants/theme";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import EditProfile from '../../components/EditProfile/EditProfile';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

const Profile = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Profile";

    const CancelToken = axios.CancelToken;
    let cancel;

    const dispatch = useDispatch();
    const userProfileId = useSelector(state => state.auth.userProfileId);
    const user = useSelector(state => state.users.record);
    const isLoading = useSelector(state => state.users.isRecordLoading);
    const profileImageId = useSelector(state => state.auth.profileImageId);

    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [roles, setRoles] = useState([]);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    let extra = (
        <Tooltip placement="top" title={'Edit ' + singularEntityName}>
            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); toggleEditEntity(); }} />
        </Tooltip>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditProfile cancel={toggleEditEntity} />
        </Drawer>
    );

    useEffect(() => {
        if (userProfileId !== undefined && userProfileId !== null) {
            dispatch(actionCreators.fetchUser(userProfileId));
        }
    }, [userProfileId]);

    useEffect(() => {
        if (user !== undefined && user !== null) {
            let rolesList = [];
            if (user.isAdmin === true) {
                rolesList.push("Admin");
            } else if (user.isVP === true) {
                rolesList.push("VP");
            } else if (user.isDirector === true) {
                rolesList.push("Director");
            } else if (user.isManager === true) {
                rolesList.push("Manager");
            } else if (user.isStaff === true) {
                rolesList.push("Staff");
            }
            setRoles([...rolesList]);
        }
    }, [user]);

    useEffect(() => {
        if (profileImageId !== undefined && profileImageId !== null) {
            axiosAuthenticated.get('/documents/' + profileImageId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [profileImageId]);

    if (user !== undefined && user !== null) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                <DetailsList style={tableBackgroundStyle}>
                    <DetailsListHeaderActionRow label="My Profile" colSpan="2" extra={extra} />
                    <DetailsListDataRow label="Profile Image">
                        <ImageCard imageUrl={imageUrl} />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="Name">
                        <DataNameRow firstName={user.firstName} middleName={user.middleName} lastName={user.lastName} />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="Roles">
                        <DataRow value={roles.length > 0 ? roles.join(', ') : ''} dataType="String" />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="UserName">
                        <DataRow value={user.isDummyEmail ? null : user.user} dataType="String" />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="Contact Information">
                        <DataRow title="Email Address" value={user.isDummyEmail ? null : user.user} dataType="String" />
                        <DataRow title="Phone Number" value={user.phone} dataType="PhoneNumber" />
                        <DataAddressRow title="Address" street1={user.streetAddress1} city={user.city} state={user.state} postalCode={user.postalCode} country={user.country} />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="Bio">
                        <DataRow value={user.bio} dataType="String" />
                    </DetailsListDataRow>
                    <DetailsListDataRow label="Date Joined">
                        <DataRow value={user.createdAt} dataType="MomentDate" />
                    </DetailsListDataRow>
                </DetailsList>
                {editEntityComponents}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}></Spin>
        );
    }
};

export default withRouter(Profile);
