import * as actionTypes from "../actions/actionTypes";
import { 
    fetchRecordsSuccess, 
    clearRecords, 
    fetchRecordsStart,
    fetchRecordsFail
} from "../utility";

const initialState = {
    records: [],
    isLoading: false,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_YOUR_ACCOUNTS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_YOUR_ACCOUNTS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_YOUR_ACCOUNTS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_YOUR_ACCOUNTS: return clearRecords(state);
        default:
            return state;
    }
};

export default reducer;