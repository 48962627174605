import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Row, Col, Spin, Select, Card, message } from 'antd';
import moment from 'moment';
import { isEmpty, without } from 'lodash';
import classes from './DispatchChangeAssets.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import MomentDate from '../../shared/dateFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExchangeAlt, faPlus, faTimes, faTruck} from '@fortawesome/free-solid-svg-icons';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const DispatchChangeAssets = props => {
    //#region props and constants

    const load = props.load;
    const assets = props.assets;
    const asset = props.asset;
    const onDone = props.cancel;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;

    //#endregion
    //#region Enums

    const assetTypeOptions = Enums.AssetTypes.selectListOptions();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
    const [loads, setLoads] = useState([]);
    const [isLoadingLoads, setIsLoadingLoads] = useState(false);
    const [assetMap, setAssetMap] = useState([]);

    //#endregion
    //#region asset methods

    const onChangeAsset = (assetId) => {
        if (assetId !== undefined && assetId !== null) {
            let payload = {
                load_ids: props.loads.map(it => it.id),
                assetIds: [assetId]
            };

            dispatch(actionCreators.assignBulkLoadsToAssets(payload));

            onDone();
        }
    };


    //#endregion
    //#region useMemos


    useMemo(() => {
        if (assets !== undefined && assets !== null && assets.length > 0 ) {
            let assetsArray = [];

            assets.forEach((otherAsset) => {

                assetsArray.push({
                    ...otherAsset,
                    asset: otherAsset
                });
            });

            setAssetMap(assetsArray);
            // console.log(assetsArray);
        } else {
            setAssetMap([]);
        }
    }, [assets, selectedAssetTypes]);

    //#endregion
    //#region stop display methods

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    const getOriginAndDestinationTimes = (origin, destination) => {
        let timeDisplay = '';
        let originStartObj = getStopStartDateTime(origin);
        let destinationEndObj = getStopEndDateTime(destination);
        let startDateObj = originStartObj.startDateTime;
        let endDateObj = destinationEndObj.endDateTime;
        if (startDateObj !== null && endDateObj !== null) {
            let startTime = originStartObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateObj.timeString;
            let endTime = destinationEndObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateObj.timeString;
            if (startDateObj.dateString === endDateObj.dateString) {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endTime} ${startDateObj.timeZone}`;
            } else {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endDateObj.dateString}, ${endTime} ${startDateObj.timeZone}`;
            }
        }

        return timeDisplay;
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingLoads === true}>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Select
                        placeholder="Asset Types: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAssetTypes(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {assetTypeOptions}
                    </Select>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {assetMap && assetMap.length > 0 ? (
                        <>
                            {assetMap.map((asset) => {
                                let assetType = stringFormatter.toFormattedString("AssetType", asset.assetType, null);
                                let trailerType = (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') ? (asset.trailerType !== undefined && asset.trailerType !== null ? asset.trailerType.description : '') : null;
                                let licensePlate = `${asset.licensePlateNum} ${asset.licensePlateState}`;
                                let title = asset.name ? asset.name : licensePlate;

                                return (
                                    <Card
                                        key={`dispatch-change-asset-card-${asset.id}`}
                                        style={{ marginBottom: 12, borderRadius: '15px' }}
                                        title={
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                                <div className={classes.textContainer}>
                                                    <span><strong>{title}</strong><br /><i>Type: {assetType}{trailerType ? ' - ' + trailerType : ''}</i></span>
                                                </div>
                                            </div>
                                        }
                                        extra={<Button type="primary" icon={<FontAwesomeIcon className="anticon" icon={faPlus} />} shape="circle" onClick={(e) => { onChangeAsset(asset.id); }}></Button>}
                                    >
                                        <div><span><strong>Available: {asset ? stringFormatter.toYesNo(asset.isAvailable) : 'No'}</strong></span></div>
                                    </Card>
                                );
                            })}
                        </>
                    ) : null}
                </Col>
            </Row>
        </Spin>
    );
};

export default DispatchChangeAssets;