import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {addFile, deleteFile, getFilesMetaData, updateFileMetaData} from "../../api/fileClient";
import moment from 'moment';
import {getAccounts} from "./orchestrator";
import {updateAccountRegistration} from "./accountRegistrations";
import logger from "../../shared/logger";
import EntityUtils from '../entityUtils';

//#region Accounts Functions

export const fetchAccountsStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_START
    }
};

export const fetchAccountsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_SUCCESS,
        payload: payload
    }
};

export const fetchAccountsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_FAIL,
        payload: payload
    }
};

export const clearAccounts = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNTS
    }
};

export const fetchAccountStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_START
    }
};

export const fetchAccountSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_SUCCESS,
        payload: payload
    }
};

export const fetchAccountFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_FAIL,
        payload: payload
    }
};

export const clearAccount = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT
    }
};

export const addAccountStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_START
    }
};

export const addAccountSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_SUCCESS
    }
};

export const addAccountFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_FAIL,
        payload: payload
    }
};

export const addAccountErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_ERROR_CLEAR
    }
};

export const addAccountLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_LOADING_CLEAR
    }
};

export const updateAccountStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_START
    }
};

export const updateAccountSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_SUCCESS
    }
};

export const updateAccountFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_FAIL,
        payload: payload
    }
};

export const updateAccountErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_ERROR_CLEAR
    }
};

export const updateAccountLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_LOADING_CLEAR
    }
};

export const cancelAddAccount = () => {
    return {
        type: actionTypes.CANCEL_ADD_ACCOUNT
    }
};

export const cancelUpdateAccount = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_ACCOUNT
    }
};

export const insertAccount = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT,
        payload: payload
    }
};

export const changeAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT,
        payload: payload
    }
};

export const changeSingleAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT,
        payload: payload
    }
};

export const removeAccount = (payload) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT,
        payload: payload
    }
};

//#endregion

//#region Accounts Methods

export const fetchAccounts = (payload) => {
    return async (dispatch, getState) => {
        const accountsPath = '/accounts';
        try {
            dispatch(fetchAccountsStart());

            const state = getState();
            const accountsState = {...state.accounts};
            let pagination = {...accountsState.pagination};
            let searchParams = {...accountsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const accountsRes = await axiosAuthenticated.get(accountsPath, {params: {...searchParams}});
            if (accountsRes && accountsRes.status === 200) {
                const accounts = accountsRes.data.data;

                let accountIds = accounts.map(item => {
                    return item.id;
                });

                let accountDocuments = [];
                const accountDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [...accountIds]
                });
                if (accountDocumentsRes && accountDocumentsRes.status === 200) {
                    accountDocuments = accountDocumentsRes.data.data;
                }

                const transformedAccounts = accounts.map((item) => {

                    let fileMetaData = [];
                    if (accountDocuments.length > 0) {
                        fileMetaData = accountDocuments.filter(document => document.entityId === item.id);
                    }

                    item.documents = fileMetaData;

                    let {
                        logoFileId,
                        insuranceFileId,
                        w9FileId,
                        insuranceExpiryDate,
                        w9ExpiryDate
                    } = EntityUtils.getAccountFileIdsAndDates(fileMetaData);

                    if (item.carrier) {
                        item.carrier.insuranceFileId = insuranceFileId ? insuranceFileId : null;
                        item.carrier.insuranceExpiryDate = insuranceExpiryDate ? insuranceExpiryDate : null;
                        item.carrier.w9FileId = w9FileId ? w9FileId : null;
                        item.carrier.w9ExpiryDate = w9ExpiryDate ? w9ExpiryDate : null;
                    }

                    return {
                        ...item,
                        logoFileId: logoFileId ? logoFileId : null
                    };
                });

                // Read total count from server
                pagination.total = accountsRes.data.totalCount;
                pagination.current = accountsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchAccountsSuccess({
                    records: transformedAccounts,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountsFail({error: error.message}));
        }
    }
};

export const fetchAccount = (id) => {
    return async (dispatch) => {
        const accountsPath = '/accounts';
        try {
            // Fetch Data From Server
            dispatch(fetchAccountStart());

            const accountRes = await axiosAuthenticated.get(accountsPath + `/${id}`);

            if (accountRes && accountRes.status === 200) {
                let account = accountRes.data;

                const fileMetaData = await getFilesMetaData(account.id);

                account.documents = fileMetaData;

                let {
                    logoFileId,
                    insuranceFileId,
                    w9FileId,
                    insuranceExpiryDate,
                    w9ExpiryDate
                } = EntityUtils.getAccountFileIdsAndDates(fileMetaData);

                if (account.carrier) {
                    account.carrier.insuranceFileId = insuranceFileId ? insuranceFileId : null;
                    account.carrier.insuranceExpiryDate = insuranceExpiryDate ? insuranceExpiryDate : null;
                    account.carrier.w9FileId = w9FileId ? w9FileId : null;
                    account.carrier.w9ExpiryDate = w9ExpiryDate ? w9ExpiryDate : null;
                }

                if (logoFileId) {
                    account.logoFileId = logoFileId ? logoFileId : null;
                }

                dispatch(fetchAccountSuccess({record: account}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountFail({error: error.message}));
        }
    }
};

export const addAccount = (payload, logoFile, insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, entityType, accountRegistrationId = null) => {
    return async (dispatch) => {
        const accountsPath = '/accounts';
        try {
            dispatch(addAccountStart());

            if (entityType && entityType === "STAFF") {
                payload.isVerified = true;
                payload.isActive = true;
            }

            const accountsRes = await axiosAuthenticated.post(accountsPath, {...payload});
            if (accountsRes && accountsRes.status === 201) {
                let newAccount = accountsRes.data;
                let logoFileId = null;
                let insuranceFileId = null;
                let w9FileId = null;
                if (logoFile !== undefined && logoFile !== null) {
                    const fileData = await addAccountFiles(logoFile, newAccount.id, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING");
                    logoFileId = fileData.logoFileId;
                }
                if (insuranceFile !== undefined && insuranceFile !== null && insuranceExpiryDate !== undefined && insuranceExpiryDate !== null && w9File !== undefined && w9File !== null && w9ExpiryDate !== undefined && w9ExpiryDate !== null) {
                    const fileData = await addCarrierFiles(insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, newAccount.id, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING");
                    insuranceFileId = fileData.insuranceFileId;
                    w9FileId = fileData.w9FileId;
                }

                if (accountRegistrationId !== undefined && accountRegistrationId !== null) {
                    dispatch(updateAccountRegistration(accountRegistrationId, {reviewStatus: 'IMPORTED'}));
                }

                const fileMetaData = await getFilesMetaData(newAccount.id);
                newAccount.documents = fileMetaData;

                if (newAccount.carrier) {
                    newAccount.carrier.insuranceFileId = insuranceFileId ? insuranceFileId : null;
                    newAccount.carrier.insuranceExpiryDate = insuranceExpiryDate ? insuranceExpiryDate : null;
                    newAccount.carrier.w9FileId = w9FileId ? w9FileId : null;
                    newAccount.carrier.w9ExpiryDate = w9ExpiryDate ? w9ExpiryDate : null;
                }

                if (logoFileId) {
                    newAccount.logoFileId = logoFileId ? logoFileId : null;
                }

                dispatch(insertAccount(newAccount));
                dispatch(fetchAccountSuccess({addRecordId: newAccount.id}));

                dispatch(addAccountSuccess());
                dispatch(addAccountErrorClear());
                dispatch(addAccountLoadingClear());
                dispatch(getAccounts(true));
            }
        } catch (error) {
            console.log("error", error)
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAccountFail({error: error.response.data.error ? error.response.data.error : (error.response.data.message ? error.response.data.message : error.message),}));
        }
    }
};

export const updateAccount = (accountId, payload, logoFile, logoFileId, insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, entityType) => {
    return async (dispatch) => {
        const accountsPath = '/accounts';
        try {
            dispatch(updateAccountStart());

            if (logoFile !== undefined && logoFile !== null) {
                const fileData = await updateAccountFiles(logoFile, logoFileId, accountId, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING");
                logoFileId = fileData.logoFileId;
            }

            if (((insuranceFile !== undefined && insuranceFile !== null) || (insuranceFileId !== undefined && insuranceFileId !== null)) || ((w9File !== undefined && w9File !== null) || (w9FileId !== undefined && w9FileId !== null))) {
                const fileData = await updateCarrierFiles(insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, accountId, entityType && entityType === "STAFF" ? true : false, entityType && entityType === "STAFF" ? "APPROVED" : "PENDING");
                w9FileId = fileData.w9FileId;
                w9ExpiryDate = fileData.w9ExpiryDate;
                insuranceFileId = fileData.insuranceFileId;
                insuranceExpiryDate = fileData.insuranceExpiryDate;
            }

            const accountsRes = await axiosAuthenticated.put(accountsPath + `/${accountId}`, {...payload});
            if (accountsRes && accountsRes.status === 200) {
                let updatedAccount = accountsRes.data;

                if (updatedAccount.isDeleted === true) {
                    dispatch(removeAccount(updatedAccount));
                } else {
                    const fileMetaData = await getFilesMetaData(updatedAccount.id);
                    updatedAccount.documents = fileMetaData;

                    if (updatedAccount.carrier) {
                        updatedAccount.carrier.insuranceFileId = insuranceFileId ? insuranceFileId : null;
                        updatedAccount.carrier.insuranceExpiryDate = insuranceExpiryDate ? insuranceExpiryDate : null;
                        updatedAccount.carrier.w9FileId = w9FileId ? w9FileId : null;
                        updatedAccount.carrier.w9ExpiryDate = w9ExpiryDate ? w9ExpiryDate : null;
                    }

                    if (logoFileId) {
                        updatedAccount.logoFileId = logoFileId ? logoFileId : null;
                    }

                    dispatch(changeAccount(updatedAccount));
                    dispatch(changeSingleAccount(updatedAccount));
                }

                dispatch(updateAccountSuccess());
                dispatch(updateAccountLoadingClear());
                dispatch(updateAccountErrorClear());
                dispatch(getAccounts(true));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountFail({error: error.message}));
        }
    }
};

export const verifyAccount = (accountId) => {
    return async (dispatch) => {
        const accountsPath = '/accounts';
        try {
            dispatch(updateAccountStart());

            const accountsRes = await axiosAuthenticated.put(accountsPath + `/${accountId}`, {isVerified: true});
            if (accountsRes && accountsRes.status === 200) {
                let updatedAccount = accountsRes.data;

                const fileMetaData = await getFilesMetaData(updatedAccount.id);
                updatedAccount.documents = fileMetaData;

                let {
                    logoFileId,
                    insuranceFileId,
                    w9FileId,
                    insuranceExpiryDate,
                    w9ExpiryDate
                } = EntityUtils.getAccountFileIdsAndDates(fileMetaData);

                if (updatedAccount.carrier) {
                    updatedAccount.carrier.insuranceFileId = insuranceFileId ? insuranceFileId : null;
                    updatedAccount.carrier.insuranceExpiryDate = insuranceExpiryDate ? insuranceExpiryDate : null;
                    updatedAccount.carrier.w9FileId = w9FileId ? w9FileId : null;
                    updatedAccount.carrier.w9ExpiryDate = w9ExpiryDate ? w9ExpiryDate : null;
                }

                if (logoFileId) {
                    updatedAccount.logoFileId = logoFileId ? logoFileId : null;
                }

                dispatch(changeAccount(updatedAccount));
                dispatch(changeSingleAccount(updatedAccount));

                dispatch(updateAccountSuccess());
                dispatch(updateAccountLoadingClear());
                dispatch(updateAccountErrorClear());
                dispatch(getAccounts(true));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountFail({error: error.message}));
        }
    }
};

const addAccountFiles = async (logoFile, accountId, isReviewed = false, reviewStatus = "PENDING") => {
    let logoFileIdValue = null;

    if (logoFile !== undefined && logoFile !== null) {
        const logoFileData = await addFile(logoFile, "ACCOUNT", accountId, "ACCOUNT_LOGO", "Account Logo", "", "ALL", null, isReviewed, reviewStatus, null);
        logoFileIdValue = logoFileData ? logoFileData.id : null;
    }

    return {logoFileId: logoFileIdValue};
};

const updateAccountFiles = async (logoFile, logoFileId, accountId, isReviewed = false, reviewStatus = "PENDING") => {
    let logoFileIdValue = logoFileId;

    if (logoFile !== undefined && logoFile !== null) {
        const isLogoFileDeleted = await deleteFile(logoFileId);
        const logoFileData = await addFile(logoFile, "ACCOUNT", accountId, "ACCOUNT_LOGO", "Account Logo", "", "ALL", null, isReviewed, reviewStatus, null);
        logoFileIdValue = logoFileData ? logoFileData.id : null;
    }

    return {logoFileId: logoFileIdValue};
};

const addCarrierFiles = async (insuranceFile, insuranceExpiryDate, w9File, w9ExpiryDate, accountId, isReviewed = false, reviewStatus = "PENDING") => {
    let insuranceFileIdValue = null;
    let insuranceExpiryDateValue = null;
    let w9FileIdValue = null;
    let w9ExpiryDateValue = null;

    if (insuranceFile !== undefined && insuranceFile !== null && insuranceExpiryDate !== undefined && insuranceExpiryDate !== null) {
        const insuranceFileData = await addFile(insuranceFile, "ACCOUNT_CARRIER", accountId, "CARRIER_INSURANCE", "Carrier Insurance", "", "CARRIER", insuranceExpiryDate, isReviewed, reviewStatus, null);
        insuranceFileIdValue = insuranceFileData ? insuranceFileData.id : null;
        insuranceExpiryDateValue = insuranceFileData ? insuranceFileData.expiryDate : null;
    }

    if (w9File !== undefined && w9File !== null && w9ExpiryDate !== undefined && w9ExpiryDate !== null) {
        const w9FileData = await addFile(w9File, "ACCOUNT_CARRIER", accountId, "CARRIER_W9", "Carrier W9", "", "CARRIER", w9ExpiryDate, isReviewed, reviewStatus, null);
        w9FileIdValue = w9FileData ? w9FileData.id : null;
        w9ExpiryDateValue = w9FileData ? w9FileData.expiryDate : null;
    }

    return {
        w9FileId: w9FileIdValue,
        w9ExpiryDate: w9ExpiryDateValue,
        insuranceFileId: insuranceFileIdValue,
        insuranceExpiryDate: insuranceExpiryDateValue
    };
};

const updateCarrierFiles = async (insuranceFile, insuranceFileId, insuranceExpiryDate, w9File, w9FileId, w9ExpiryDate, accountId, isReviewed = false, reviewStatus = "PENDING") => {
    let insuranceFileIdValue = insuranceFileId;
    let insuranceExpiryDateValue = insuranceExpiryDate;
    let w9FileIdValue = w9FileId;
    let w9ExpiryDateValue = w9ExpiryDate;

    if (insuranceExpiryDate !== undefined && insuranceExpiryDate !== null) {
        if (moment.isMoment(insuranceExpiryDate)) {
            insuranceExpiryDate = insuranceExpiryDate.utc().toISOString();
        }
        insuranceExpiryDateValue = insuranceExpiryDate;
    }
    if (w9ExpiryDate !== undefined && w9ExpiryDate !== null) {
        if (moment.isMoment(w9ExpiryDate)) {
            w9ExpiryDate = w9ExpiryDate.utc().toISOString();
        }
        w9ExpiryDateValue = w9ExpiryDate;
    }

    if (insuranceFile) {
        const isInsuranceFileDeleted = await deleteFile(insuranceFileId);
        const insuranceFileData = await addFile(insuranceFile, "ACCOUNT_CARRIER", accountId, "CARRIER_INSURANCE", "Carrier Insurance", "", "CARRIER", insuranceExpiryDate, isReviewed, reviewStatus, null);
        insuranceFileIdValue = insuranceFileData ? insuranceFileData.id : null;
        insuranceExpiryDateValue = insuranceFileData ? insuranceFileData.expiryDate : null;
    } else if (insuranceFileId) {
        if (insuranceExpiryDate !== undefined && insuranceExpiryDate !== null) {
            await updateFileMetaData(insuranceFileId, {
                expiryDate: insuranceExpiryDate,
                isReviewed: isReviewed,
                reviewStatus: reviewStatus
            });
        }
    }

    if (w9File) {
        const isW9FileDeleted = await deleteFile(w9FileId);
        const w9FileData = await addFile(w9File, "ACCOUNT_CARRIER", accountId, "CARRIER_W9", "Carrier W9", "", "CARRIER", w9ExpiryDate, isReviewed, reviewStatus, null);
        w9FileIdValue = w9FileData ? w9FileData.id : null;
        w9ExpiryDateValue = w9FileData ? w9FileData.expiryDate : null;
    } else if (w9FileId) {
        if (w9ExpiryDate !== undefined && w9ExpiryDate !== null) {
            await updateFileMetaData(w9FileId, {
                expiryDate: w9ExpiryDate,
                isReviewed: isReviewed,
                reviewStatus: reviewStatus
            });
        }
    }

    return {
        w9FileId: w9FileIdValue,
        w9ExpiryDate: w9ExpiryDateValue,
        insuranceFileId: insuranceFileIdValue,
        insuranceExpiryDate: insuranceExpiryDateValue
    };
};

//#endregion