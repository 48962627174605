import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button } from "antd";

const DataTableTitle = props => {
    const tableTitle = props.tableTitle !== undefined && props.tableTitle !== null ? props.tableTitle : null;

    if (props.action !== null) {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                {tableTitle === null ? (
                    <Col xs={{ span: 22 }}>
                        <b>{'Manage ' + props.pluralEntityName}</b> <span>(Select a row to view more details)</span>
                    </Col>
                ) : (
                        <Col xs={{ span: 22 }}>
                            <b>{tableTitle}</b> <span>(Select a row to view more details)</span>
                        </Col>
                    )}
                <Col xs={{ span: 2 }} style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={'New ' + props.singularEntityName}><Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={props.action} /></Tooltip>
                </Col>
            </Row>
        );
    } else {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                {tableTitle === null ? (
                    <Col xs={{ span: 24 }}>
                        <b>{'Manage ' + props.pluralEntityName}</b> <span>(Select a row to view more details)</span>
                    </Col>
                ) : (
                        <Col xs={{ span: 24 }}>
                            <b>{tableTitle}</b> <span>(Select a row to view more details)</span>
                        </Col>
                    )}
            </Row>
        );
    }
};

export default DataTableTitle;