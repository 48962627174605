import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Button, Modal, Drawer, Row, Col, Menu, Dropdown, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faUserAlt, faIdBadge, faTools } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { useLocation } from 'react-router';
import { CheckCircleTwoTone, PaperClipOutlined, ControlOutlined, DownOutlined, PlusOutlined, FormOutlined, CheckOutlined } from '@ant-design/icons';
import DocumentList from '../../components/DocumentList/DocumentList';
import EditDriver from "../../components/EditDriver/EditDriver";
import EditEmployee from "../../components/EditEmployee/EditEmployee";
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataTable from '../../components/DataTable/DataTable';
import Thumbnail from '../../components/Thumbnail/Thumbnail';
import classes from './Employee.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import EditLicense from '../../components/EditLicense/EditLicense';
import Document from '../../components/Document/Document';
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import Calendar from '../../components/Calendar/Calendar';
import Scheduler from '../../components/Scheduler/Scheduler';
import Agenda from '../../components/Agenda/Agenda';
import axios from 'axios';

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Employee = props => {
    const fullWidth = global.window.innerWidth;
    const accountUserId = props.match.params.accountUserId;

    const CancelToken = axios.CancelToken;
    let cancel;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.accountUsers.isRecordLoading);
    const error = useSelector(state => state.accountUsers.recordError);
    const accountUser = useSelector(state => state.accountUsers.record);
    const updateIsLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const updateError = useSelector(state => state.accountUsers.updateRecordError);

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEmployee, setShowEditEmployee] = useState(false);
    const [showEditDriver, setShowEditDriver] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [isApprovingDriver, setIsApprovingDriver] = useState(false);
    const [showAddDocument, setShowAddDocument] = useState(false);
    const [showEditDocument, setShowEditDocument] = useState(false);
    const [showUpdateDocument, setShowUpdateDocument] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [selectedDocument, setSelectedDocument] = useState({});
    const [showDocumentModal, setShowDocumentModal] = useState(false);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleEditEmployee = () => {
        setShowEditEmployee(!showEditEmployee);
    };

    const toggleEditDriver = () => {
        setShowEditDriver(!showEditDriver);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleAddDocument = () => {
        setShowAddDocument(!showAddDocument);
    };

    const toggleEditDocument = () => {
        setShowEditDocument(!showEditDocument);
    };

    const toggleUpdateDocument = () => {
        setShowUpdateDocument(!showUpdateDocument);
    };

    const toggleDocumentModal = () => {
        setShowDocumentModal(!showDocumentModal);
    };

    const approveDriver = (accountUserId) => {
        dispatch(actionCreators.approveDriver(accountUserId));
        setIsApprovingDriver(true);
    };

    const checkForActiveLoad = () => {
        axiosAuthenticated.get('/activeLoad' + `?driverId=${accountUser.id}`).then(res => {
            let results = res.data;
            //console.log(results);
            if (results.loadId !== undefined && results.loadId !== null) {
                window.open(`/track/${results.loadId}`);
            } else {
                message.info('No active load was found');
            }
            //props.history.push({ pathname: "/track/" + results.loadId });
        }).catch(err => {
            console.log(err);
        });
    };

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAccountUser(accountUserId));
    }, [accountUserId]);

    useEffect(() => {
        if (accountUser !== undefined && accountUser !== null && accountUser.profileImageId !== undefined && accountUser.profileImageId !== null) {
            axiosAuthenticated.get('/documents/' + accountUser.profileImageId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [accountUser]);

    const finishedApproveDriver = () => {
        dispatch(actionCreators.cancelUpdateAccountUser());
        if (showDocumentsModal === true) {
            toggleDocumentsModal();
        }
        setIsApprovingDriver(false);
    };

    useMemo(() => {
        if (updateIsLoading !== null && updateIsLoading === false && updateError === null && isApprovingDriver === true) {
            finishedApproveDriver();
        }
    }, [updateIsLoading, updateError, isApprovingDriver, showDocumentsModal]);

    const editEmployeeComponents = (
        <Drawer
            title={"Edit Employee Profile"}
            onClose={toggleEditEmployee}
            visible={showEditEmployee}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditEmployee cancel={toggleEditEmployee} record={accountUser} />
        </Drawer>
    );

    const editDriverComponents = (
        <Drawer
            title={"Edit Driver Profile"}
            onClose={toggleEditDriver}
            visible={showEditDriver}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver cancel={toggleEditDriver} record={accountUser} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {(accountUser && accountUser.driver) && <DocumentList accountUser={accountUser} entityType="DRIVER" entityId={accountUser.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
            {accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null && accountUser.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(accountUser.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
            ) : null}
        </Modal>
    );

    const documentComponent = (
        <Modal
            title={selectedDocument.displayName}
            visible={showDocumentModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Document record={selectedDocument} fileId={selectedDocument.id} displayName={selectedDocument.displayName} />
        </Modal>
    );

    const addDocumentComponents = (
        <Drawer
            title={"Add License"}
            onClose={toggleAddDocument}
            visible={showAddDocument}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense cancel={toggleAddDocument} license={selectedRecord} actionText="Add License" accountUserId={accountUser ? accountUser.id : null} />
        </Drawer>
    );

    const editDocumentComponents = (
        <Drawer
            title={"Update License Details"}
            onClose={toggleEditDocument}
            visible={showEditDocument}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense cancel={toggleEditDocument} license={selectedRecord} actionText="Update License Details" accountUserId={accountUser ? accountUser.id : null} />
        </Drawer>
    );

    const updateDocumentComponents = (
        <Drawer
            title={"Update Expired License"}
            onClose={toggleUpdateDocument}
            visible={showUpdateDocument}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLicense cancel={toggleUpdateDocument} license={selectedRecord} actionText="Update Expired License" accountUserId={accountUser ? accountUser.id : null} />
        </Drawer>
    );

    const menu = () => {
        return (
            <Menu>
                {accountUser.isDriver === true ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                        <PaperClipOutlined />
                        <span>Manage Driver Documents</span>
                    </Menu.Item>
                ) : null}
                <Menu.Item key="editEmployee" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEmployee(); }}>
                    <FontAwesomeIcon className="anticon" icon={faIdBadge} style={{ marginRight: '10px' }} />
                    <span>Edit Employee Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const driverMenu = () => {
        return (
            <Menu>
                {(accountUser && accountUser.isDriver === true && accountUser.driver && accountUser.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER")) ? (
                    <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                        <CheckOutlined />
                        <span>Approve Driver</span>
                    </Menu.Item>
                ) : null}
                <Menu.Item key="checkForActiveLoad" onClick={(e) => { e.domEvent.stopPropagation(); checkForActiveLoad(); }}>
                    <span>Check for Active Load</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const driverExtra = (
        <Dropdown overlay={driverMenu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Employees';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/employees` },
        path: previousPath ? previousPath : `/employees`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Employees',
    },
    {
        path: `/employee/${accountUserId ? accountUserId : null}`,
        breadcrumbName: `${accountUser ? accountUser.firstName + ' ' + accountUser.lastName : ''}`,
    },
    ];

    const setRowClassName = (record) => {
        if (record.isExpired !== undefined && record.isExpired !== null && record.isExpired === true) {
            return classes.expiredRow;
        } else if (record.isRequired === true && ((record.expirationDate === undefined || record.expirationDate === null) || (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === '') || (record.frontImageId === undefined || record.frontImageId === null) || (record.backImageId === undefined || record.backImageId === null))) {
            return classes.expiredRow;
        } else if (record.isExpiringSoon !== undefined && record.isExpiringSoon !== null && record.isExpiringSoon === true) {
            return classes.expiringSoonRow;
        } else {
            return classes.dataTableRow;
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'licenseTypeDisplayName',
            key: 'licenseTypeDisplayName',
            render: (text, record) => {
                if (record.isExpired !== undefined && record.isExpired !== null && record.isExpired === true) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}<i>(Expired - Please Update)</i></strong>,
                    };
                } else if (record.isRequired === true && ((record.expirationDate === undefined || record.expirationDate === null) || (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === '') || (record.frontImageId === undefined || record.frontImageId === null) || (record.backImageId === undefined || record.backImageId === null))) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}<i>(Information is Missing)</i></strong>,
                    };
                } else if (record.isExpiringSoon !== undefined && record.isExpiringSoon !== null && record.isExpiringSoon === true) {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}<i>(Expiring Soon - Please Update)</i></strong>,
                    };
                } else {
                    return {
                        props: {
                            className: setRowClassName(record)
                        },
                        children: <strong>{text} {record.isRequired === true ? '(Required) ' : null}</strong>,
                    };
                }
            },
        },
        {
            title: 'Front',
            dataIndex: 'frontImageId',
            key: 'frontImageId',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: <Thumbnail alt={record.licenseTypeDisplayName} url={record.frontImage ? record.frontImage.link : null} action={(e) => { e.stopPropagation(); setSelectedDocument(record.frontImage); toggleDocumentModal(); }} />,
                };
            }
        },
        {
            title: 'Back',
            dataIndex: 'backImageId',
            key: 'backImageId',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: <Thumbnail alt={record.licenseTypeDisplayName} url={record.backImage ? record.backImage.link : null} action={(e) => { e.stopPropagation(); setSelectedDocument(record.backImage); toggleDocumentModal(); }} />,
                };
            }
        },
        {
            title: 'Number',
            dataIndex: 'licenseNumber',
            key: 'licenseNumber',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: text,
                };
            }
        },
        {
            title: 'Expiration',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (text, record) => {
                return {
                    props: {
                        className: setRowClassName(record)
                    },
                    children: stringFormatter.toFormattedString("MomentDate", text, null),
                };
            }
        },
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                if (record.isExpired === true) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleUpdateDocument(); }} />,
                    };
                } else if (record.isExpiringSoon === true) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleUpdateDocument(); }} />,
                    };
                } else if (record.isRequired === true && ((record.frontImageId === undefined || record.frontImageId === null) && (record.backImageId === undefined || record.backImageId === null) && (record.expirationDate === undefined || record.expirationDate === null) && (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === ''))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                } else if (record.isRequired === true && ((record.frontImageId === undefined || record.frontImageId === null) || (record.backImageId === undefined || record.backImageId === null) || (record.expirationDate === undefined || record.expirationDate === null) || (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === ''))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="danger" shape="circle" icon={<FormOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditDocument(); }} />,
                    };
                } else if (record.isRequired === false && ((record.frontImageId === undefined || record.frontImageId === null) && (record.backImageId === undefined || record.backImageId === null) && (record.expirationDate === undefined || record.expirationDate === null) && (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === ''))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                } else if (record.isRequired === false && ((record.frontImageId === undefined || record.frontImageId === null) || (record.backImageId === undefined || record.backImageId === null) || (record.expirationDate === undefined || record.expirationDate === null) || (record.licenseNumber === undefined || record.licenseNumber === null || record.licenseNumber === ''))) {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<FormOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditDocument(); }} />,
                    };
                } else {
                    return {
                        props: {
                            className: [setRowClassName(record), classes.columnNoBreak]
                        },
                        children: <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleAddDocument(); }} />,
                    };
                }
            },
            align: 'center',
        }
    ];

    if (accountUser && accountUser.id === accountUserId) {
        return (
            <>
                <Header
                    title={accountUser ? accountUser.firstName + ' ' + accountUser.lastName : 'Loading...'}
                    subtitle={accountUser ? accountUser.title : null}
                    subtitle2={accountUser && accountUser.isVerified === true ? (<CheckCircleTwoTone title="Verified" alt="Verified" twoToneColor="#52c41a" />) : (entityType === 'STAFF' ? (<a onClick={(e) => { e.stopPropagation(); toggleDocumentsModal(); }}>Verify</a>) : null)}
                    title2={accountUser ? accountUser.email : ''}
                    title2Icon={<FontAwesomeIcon className="anticon" icon={faEnvelope} />}
                    title3={accountUser ? <ValueFormatting.PhoneNumber value={accountUser.workPhone} /> : ''}
                    title3Icon={<FontAwesomeIcon className="anticon" icon={faPhoneAlt} />}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faUserAlt} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(accountUser !== null && isLoading === false) &&
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="Employee Details"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Employee ID": { value: accountUser ? accountUser.irisId : '', dataType: 'String' },
                                                    "Email": { value: accountUser && accountUser.email ? accountUser.email : null, dataType: 'String' },
                                                    "Work Phone": { value: accountUser && accountUser.workPhone ? accountUser.workPhone : null, dataType: 'PhoneNumber' },
                                                    "Is Driver": { value: accountUser ? accountUser.isDriver : false, dataType: 'Boolean' },
                                                    "Is Contractor": { value: accountUser ? accountUser.isContractor : false, dataType: 'Boolean' },
                                                    "Is Verified": { value: accountUser ? accountUser.isVerified : false, dataType: 'Boolean' },
                                                    "Verified By": { value: accountUser && accountUser.isVerified ? accountUser.verifiedByUser : null, dataType: 'FirstNameLastName' }
                                                }}
                                                extra={extra}
                                            />
                                        </Col>
                                        {accountUser && accountUser.driver && accountUser.isDriver === true &&
                                            <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                                <ComplexDataLinkCard
                                                    title="Driver Details"
                                                    style={{ height: 300 }}
                                                    headStyle={{ fontSize: 14 }}
                                                    data={{
                                                        "Driver ID": { value: accountUser && accountUser.driver ? accountUser.driver.irisId : '', dataType: 'String' },
                                                        "Is Approved": { value: accountUser && accountUser.driver ? accountUser.driver.isApproved : false, dataType: 'Boolean' },
                                                        "Is Available": { value: accountUser && accountUser.driver ? accountUser.driver.isAvailable : false, dataType: 'Boolean' },
                                                        "Total Loads Completed": { value: accountUser && accountUser.driver ? accountUser.driver.totalLoads : 0, dataType: 'Integer' },
                                                        "Scheduled Loads": { value: accountUser && accountUser.driver ? accountUser.driver.scheduledLoads : 0, dataType: 'Integer' },
                                                        "IRIS Rating": { value: accountUser && accountUser.driver && accountUser.driver.totalRating ? accountUser.driver.totalRating : null, dataType: 'String' },
                                                        "Documents Pending Approval": { value: accountUser && accountUser.driver && accountUser.driver.documentPendingApprovalCount > 0 ? accountUser.driver.documentPendingApprovalCount : null, dataType: 'Integer' },
                                                        "Documents Missing": { value: accountUser && accountUser.driver && accountUser.driver.documentMissingCount > 0 ? accountUser.driver.documentMissingCount : null, dataType: 'Integer' },
                                                        "Documents Expiring Soon": { value: accountUser && accountUser.driver && accountUser.driver.documentExpiringSoonCount > 0 ? accountUser.driver.documentExpiringSoonCount : null, dataType: 'Integer' },
                                                        "Documents Expired": { value: accountUser && accountUser.driver && accountUser.driver.documentExpiredCount > 0 ? accountUser.driver.documentExpiredCount : null, dataType: 'Integer' },
                                                    }}
                                                    extra={driverExtra}
                                                />
                                            </Col>
                                        }
                                        {accountUser && accountUser.driver && accountUser.isDriver === true &&
                                            <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                                <ComplexCard
                                                    title="Driver Licenses and Certificates"
                                                    style={{ height: 500 }}
                                                    headStyle={{ fontSize: 14 }}
                                                >
                                                    <DataTable
                                                        dataSource={accountUser && accountUser.driver && accountUser.driver.licenses ? accountUser.driver.licenses : []}
                                                        columns={columns}
                                                        hidePaging={true}
                                                        loading={isLoading === true}
                                                        rowClassName={setRowClassName}
                                                        rowKey={record => record.licenseType}
                                                        style={{ backgroundColor: '#ffffff' }}
                                                    />
                                                </ComplexCard>
                                            </Col>
                                        }
                                    </Row>
                                }
                            </Spin>
                        </div>
                    </TabPane>
                    {(accountUser && accountUser.isDriver === true) ? (
                        <TabPane tab="Driver Documents" key="driverDocuments">
                            <div style={{ padding: 24 }}>
                                {(accountUser && accountUser.driver) && <DocumentList accountUser={accountUser} entityType="DRIVER" entityId={accountUser.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
                                {accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null && accountUser.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (
                                    <Button style={{ marginTop: 20 }} type='primary' onClick={() => { approveDriver(accountUser.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
                                ) : null}
                            </div>
                        </TabPane>
                    ) : null}
                    {(accountUser && accountUser.isDriver === true) ? (
                        <TabPane tab="Booked Loads" key="bookedLoads">
                            <div style={{ padding: 24 }}>
                                <BookedLoads carrierId={accountUser.accountId} shipperId={null} accountId={accountUser.accountId} driverId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(accountUser && accountUser.isDriver === true) ? (
                        <TabPane tab="In Transit Loads" key="intransitLoads">
                            <div style={{ padding: 24 }}>
                                <InTransitLoads carrierId={accountUser.accountId} shipperId={null} accountId={accountUser.accountId} driverId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(accountUser && accountUser.isDriver === true) ? (
                        <TabPane tab="Completed Loads" key="completedLoads">
                            <div style={{ padding: 24 }}>
                                <CompletedLoads carrierId={accountUser.accountId} shipperId={null} accountId={accountUser.accountId} driverId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    <TabPane tab="Calendar" key="calendar">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Calendar accountId={accountUser.accountId} accountUserId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Scheduler" key="scheduler">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Scheduler accountId={accountUser.accountId} accountUserId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Agenda" key="agenda">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Agenda accountId={accountUser.accountId} accountUserId={accountUser.id} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                </Tabs>
                {editEmployeeComponents}
                {editDriverComponents}
                {documentsComponent}
                {addDocumentComponents}
                {editDocumentComponents}
                {updateDocumentComponents}
                {documentComponent}
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Employee);