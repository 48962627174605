import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Drawer, Modal, Row, Col, Spin, Select, Empty, Tabs, Checkbox } from 'antd';
import EditScheduledShift from "../../components/EditScheduledShift/EditScheduledShift";
import NewScheduledShift from "../../components/NewScheduledShift/NewScheduledShift";
import { useLocation, withRouter } from 'react-router-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { isEmpty } from 'lodash';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import classes from './ScheduledShifts.module.scss';
import Header from '../../components/Header/Header';

const { Option } = Select;
const { TabPane } = Tabs;

const ScheduledShifts = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const singularEntityName = "Scheduled Shift";

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isLoading);
    const error = useSelector(state => state.scheduledShifts.error);
    const scheduledShifts = useSelector(state => state.scheduledShifts.records);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const shifts = useSelector(state => state.shifts.records);
    const isLoadingShifts = useSelector(state => state.shifts.isLoading);
    const accountUsers = useSelector(state => state.accountUsers.records);
    const isLoadingAccountUsers = useSelector(state => state.accountUsers.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const calendarRef = useRef(null);
    const schedulerRef = useRef(null);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('calendar');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAccountUserId, setSelectedAccountUserId] = useState(null);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState([]);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [driversOnly, setDriversOnly] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    //#endregion
    //#region toggles

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    const closeNewEntity = () => {
        setShowNewEntity(false);
        setSelectedAccountUserId(null);
    };

    const closeEditEntity = () => {
        setShowEditEntity(false);
        setSelectedAccountUserId(null);
    };

    const closeRemoveEntity = () => {
        setShowRemoveEntity(false);
        setSelectedAccountUserId(null);
    };

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            sort: 'startDateTime',
            order: 'asc',
            isDeleted: false
        };

        if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
            searchParams.accountUserIds = selectedAccountUserIds;
        } else if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0 && driversOnly === true) {
            searchParams.accountUserIds = accountUsers.filter(i => i.isDriver === true).map(i => i.id);
        }

        if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
            searchParams.shiftId = selectedShiftIds;
        }

        if (selectedAccountId !== undefined && selectedAccountId !== null) {
            searchParams.accountId = selectedAccountId;
        }

        dispatch(actionCreators.fetchScheduledShifts(searchParams));
    };

    //#endregion
    //#region calendar methods

    const handleDateSelect = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        setShowNewEntity(true);
    };

    const handleEmployeeDateSelect = (selectInfo) => {
        // console.log(selectInfo);
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        if (selectInfo.resource && selectInfo.resource.id) {
            setSelectedAccountUserId(selectInfo.resource.id);
        }

        setShowNewEntity(true);
    };

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        setSelectedRecord(clickInfo.event.extendedProps);
        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        setShowDetails(true);
    };

    const handleEmployeeEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        // console.log(clickInfo);
        // console.log(clickInfo.event);
        // console.log(clickInfo.event._def.resourceIds);
        // console.log(clickInfo.jsEvent.path)
        let pathsArray = clickInfo.jsEvent.path;
        let node = pathsArray.find(i => i.className === 'fc-timeline-lane fc-resource');
        let resourceId = node.getAttribute('data-resource-id');

        setSelectedRecord(clickInfo.event.extendedProps);

        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        if (resourceId) {
            setSelectedAccountUserId(resourceId);
        }

        setShowDetails(true);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        // console.log('location changed')
        // console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('calendar');
            props.history.replace({ ...location, hash: `#calendar` });
        }
    }, [location]);

    useMemo(() => {
        if (selectedAccountId !== undefined && selectedAccountId !== null) {
            dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShifts({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        let resourceArray = [];
        if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            if (driversOnly === true) {
                if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                    accountUsers.filter(i => selectedAccountUserIds.includes(i.id) && i.isDriver === true).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                } else {
                    accountUsers.filter(i => i.isDriver === true).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });
                        //     console.log(isInShift)

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                }
            } else {
                if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                    accountUsers.filter(i => selectedAccountUserIds.includes(i.id)).forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                } else {
                    accountUsers.forEach((accountUser) => {
                        // let resourceScheduledShifts = scheduledShifts.filter(i => i.extendedProps.accountUserIds && i.extendedProps.accountUserIds.length > 0 && i.extendedProps.accountUserIds.includes(accountUser.id));
                        // if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        //     let isInShift = false;
                        //     selectedShiftIds.forEach((selectedShiftId) => {
                        //         if (resourceScheduledShifts && resourceScheduledShifts.length > 0) {
                        //             if (resourceScheduledShifts.filter(i => i.extendedProps.shiftId === selectedShiftId).length > 0) {
                        //                 isInShift = true;
                        //             }
                        //         }
                        //     });
                        //     console.log(isInShift)

                        //     if (isInShift === true) {
                        //         resourceArray.push({
                        //             ...accountUser,
                        //             title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                        //             scheduledShifts: resourceScheduledShifts
                        //         });
                        //     }
                        // } else {
                        resourceArray.push({
                            ...accountUser,
                            title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`,
                            // scheduledShifts: resourceScheduledShifts
                        });
                        // }
                    });
                }
            }
        }

        setResourceMap(resourceArray);
    }, [accountUsers, selectedAccountUserIds, driversOnly]);

    useMemo(() => {
        if (accounts !== undefined && accounts !== null && accounts.length > 0) {
            if (accountId !== undefined && accountId !== null) {
                setSelectedAccountId(accountId);
                let account = accounts.find(i => i.id === accountId);
                setSelectedAccount(account);
            } else if (entityType !== 'STAFF' && entityId !== undefined && entityId !== null) {
                setSelectedAccountId(entityId);
                let account = accounts.find(i => i.id === entityId);
                setSelectedAccount(account);
            }
        } else {
            setSelectedAccount(null);
        }
    }, [accountId, entityType, entityId, accounts]);

    useMemo(() => {
        if (selectedAccountId !== undefined && selectedAccountId !== null) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserIds, selectedShiftIds, driversOnly, accountUsers]);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={closeNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewScheduledShift
                cancel={closeNewEntity}
                accountId={selectedAccountId}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={closeEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeEditEntity}
                record={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={false}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Employee from " + singularEntityName}
            onClose={closeRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeRemoveEntity}
                record={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={true}
            />
        </Drawer>
    );

    const getAccountUser = (acctUserId) => {
        if (acctUserId !== undefined && acctUserId !== null && accountUsers && accountUsers.length > 0) {
            let accountUser = accountUsers.find(i => i.id === acctUserId);
            if (accountUser !== undefined && accountUser !== null) {
                return (
                    <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                );
            }
        }

        return null;
    };

    const eventDetailsComponents = (scheduledShift) => {
        // console.log(`scheduledShift: ${JSON.stringify(scheduledShift)}`);

        if (scheduledShift !== undefined && scheduledShift !== null && !isEmpty(scheduledShift)) {
            let shift = scheduledShift.shift;
            let title = shift && shift.name ? shift.name : '';
            let time = `${selectedDate ? selectedDate + ', ' : ''}${shift ? shift.startTime : ''} - ${shift ? shift.endTime : ''}`;

            let footerButtons = [
                <Button onClick={(e) => { toggleShowDetails(); }}>Close</Button>
            ];
            if (selectedAccountUserId !== undefined && selectedAccountUserId !== null) {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Swap Shifts</Button>);
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowRemoveEntity(true); }}>Remove</Button>);
            } else {
                footerButtons.push(<Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Edit</Button>);
            }

            return (
                <Modal
                    title={`${title}: ${time}`}
                    visible={showDetails}
                    onCancel={(e) => { toggleShowDetails(); }}
                    onOk={(e) => { toggleShowDetails(); }}
                    footer={footerButtons}
                >
                    <div>
                        <div>
                            <span><strong>{scheduledShift.title ? scheduledShift.title : ''}</strong></span>
                        </div>
                        {scheduledShift.accountUsers && scheduledShift.accountUsers.length > 0 && (selectedAccountUserId !== undefined && selectedAccountUserId !== null) === false ? scheduledShift.accountUsers.map((accountUser) => {
                            return (
                                <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                            );
                        }) : ((selectedAccountUserId !== undefined && selectedAccountUserId !== null) ? (getAccountUser(selectedAccountUserId)) : null)}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const renderEventContent = (eventInfo) => {
        let scheduledShift = eventInfo && eventInfo.event ? eventInfo.event.extendedProps : null;
        let shift = scheduledShift && scheduledShift.shift ? scheduledShift.shift : null;
        if (scheduledShift && shift) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'normal', wordWrap: 'normal' }}>
                    <b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i>

                    {/* {scheduledShift.accountUsers ? scheduledShift.accountUsers.map((accountUser) => {
                        return (
                            <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                        );
                    }) : null} */}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderEmployeeEventContent = (eventInfo) => {
        let scheduledShift = eventInfo && eventInfo.event ? eventInfo.event.extendedProps : null;
        let shift = scheduledShift && scheduledShift.shift ? scheduledShift.shift : null;
        if (scheduledShift && shift) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%' }}>
                    <div>
                        <b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (scheduledShifts) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Calendar" key="calendar">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Header
                                        title="Calendar"
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>To setup a shift, click on a cell in the calendar to add a shift for that date and then choose which Employees you want to assign to that shift.</span></div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <span><strong>Filters: </strong></span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {((accountId === undefined || accountId === null) && entityType === 'STAFF') ? (
                                            <Col span={8}>
                                                <Select
                                                    placeholder="Filter by Account"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onChange={(selected) => { setSelectedAccountId(selected); }}
                                                    value={selectedAccountId}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                                >
                                                    {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                </Select>
                                            </Col>
                                        ) : null}
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                value={selectedShiftIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                value={selectedAccountUserIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(entityType === 'CARRIER' || (selectedAccount !== undefined && selectedAccount !== null && selectedAccount.isCarrier === true)) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ComplexCard>
                                <FullCalendar
                                    ref={calendarRef}
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    headerToolbar={{
                                        left: 'today prev,next',
                                        center: 'title',
                                        right: 'dayGridMonth,dayGridWeek'
                                    }}
                                    views={{
                                        dayGridMonth: { buttonText: 'month', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                                        dayGridWeek: { buttonText: 'week', slotDuration: { days: 1 }, eventContent: renderEventContent, select: handleDateSelect, eventClick: handleEventClick },
                                    }}
                                    titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                    eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                                    initialView='dayGridMonth'
                                    editable={false}
                                    selectable={true}
                                    dayMaxEvents={false}
                                    dayMaxEventRows={false}
                                    nextDayThreshold={'06:00:00'}
                                    weekends={true}
                                    events={scheduledShifts}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Scheduler" key="scheduler">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Header
                                        title="Scheduler"
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>To schedule an Employee on a shift, click on a cell in the Scheduler below in the Employee's row to choose a date. You will then be prompted to choose a shift to schedule the Employee on for that date.</span></div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <span><strong>Filters: </strong></span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {((accountId === undefined || accountId === null) && entityType === 'STAFF') ? (
                                            <Col span={8}>
                                                <Select
                                                    placeholder="Filter by Account"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onChange={(selected) => { setSelectedAccountId(selected); }}
                                                    value={selectedAccountId}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                                >
                                                    {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                </Select>
                                            </Col>
                                        ) : null}
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                value={selectedShiftIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                value={selectedAccountUserIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(entityType === 'CARRIER' || (selectedAccount !== undefined && selectedAccount !== null && selectedAccount.isCarrier === true)) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ComplexCard>
                                <FullCalendar
                                    ref={schedulerRef}
                                    plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                                    headerToolbar={{
                                        left: 'today prev,next',
                                        center: 'title',
                                        right: 'resourceTimelineWeek,resourceTimelineTenDay,resourceTimelineMonth,resourceTimelineYear'
                                    }}
                                    titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                    views={{
                                        resourceTimelineWeek: {
                                            type: 'resourceTimeline',
                                            duration: { days: 7 },
                                            slotDuration: { days: 1 },
                                            buttonText: 'week'
                                        },
                                        resourceTimelineTenDay: {
                                            type: 'resourceTimeline',
                                            duration: { days: 10 },
                                            slotDuration: { days: 1 },
                                            buttonText: '10 days'
                                        }
                                    }}
                                    slotLabelFormat={[
                                        { month: '2-digit', year: 'numeric', day: '2-digit' }, // top level of text
                                        { weekday: 'short' } // lower level of text
                                    ]}
                                    resourceAreaHeaderContent="Employees"
                                    initialView='resourceTimelineWeek'
                                    resources={resourceMap}
                                    resourceOrder={'firstName,LastName'}
                                    editable={false}
                                    selectable={true}
                                    allDaySlot={true}
                                    dayMaxEventRows={false}
                                    dayMaxEvents={false}
                                    nextDayThreshold={'06:00:00'}
                                    slotMinWidth={100}
                                    weekends={true}
                                    expandRows={false}
                                    contentHeight={"auto"}
                                    // eventsSet={(events) => { console.log(events) }}
                                    // resourcesSet={(resources) => { console.log(resources) }}
                                    events={scheduledShifts}
                                    select={handleEmployeeDateSelect}
                                    eventContent={renderEmployeeEventContent}
                                    eventClick={handleEmployeeEventClick}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Agenda" key="agenda">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Header
                                        title="Agenda"
                                        footer={
                                            <div><span style={{ fontSize: 16, fontWeight: 500 }}>The Agenda view is used to view the shifts and edit shifts. You cannot add shifts from this view.</span></div>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <span><strong>Filters: </strong></span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {((accountId === undefined || accountId === null) && entityType === 'STAFF') ? (
                                            <Col span={8}>
                                                <Select
                                                    placeholder="Filter by Account"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onChange={(selected) => { setSelectedAccountId(selected); }}
                                                    value={selectedAccountId}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                                >
                                                    {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                                </Select>
                                            </Col>
                                        ) : null}
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                value={selectedShiftIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                value={selectedAccountUserIds}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.join("").toLowerCase().includes(input.toLowerCase())
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(entityType === 'CARRIER' || (selectedAccount !== undefined && selectedAccount !== null && selectedAccount.isCarrier === true)) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ComplexCard>
                                <FullCalendar
                                    ref={calendarRef}
                                    plugins={[interactionPlugin, listPlugin]}
                                    headerToolbar={{
                                        left: 'today prev,next',
                                        center: 'title',
                                        right: 'listMonth,listWeek'
                                    }}
                                    views={{
                                        listWeek: { buttonText: 'weekly', slotDuration: { days: 1 }, selectable: false, eventClick: handleEventClick },
                                        listMonth: { buttonText: 'monthly', slotDuration: { days: 1 }, selectable: false, eventClick: handleEventClick },
                                    }}
                                    titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                                    eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                                    initialView='listMonth'
                                    editable={false}
                                    dayMaxEvents={false}
                                    dayMaxEventRows={false}
                                    nextDayThreshold={'06:00:00'}
                                    weekends={true}
                                    events={scheduledShifts}
                                    contentHeight={"auto"}
                                />
                            </ComplexCard>
                        </div>
                    </TabPane>
                </Tabs>
                {newEntityComponents}
                {editEntityComponents}
                {removeEntityComponents}
                {eventDetailsComponents(selectedRecord)}
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
            </Spin>
        );
    }
};

export default withRouter(ScheduledShifts);
