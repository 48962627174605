import React from 'react';
import StringFormatter from '../../shared/stringFormatter';
import { Row, Col } from 'antd';
import classes from './DataInfoActionRow.module.scss';

const stringFormatter = new StringFormatter();

const DataInfoActionRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, noColon = false, title = null, value, units = null, timeZone = null, dataType = 'String', actions = null }) => {
    return (
        <Row gutter={[4, 4]} className={className} style={style}>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
                {value !== undefined && value !== null ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString(dataType, value, units, timeZone)}</span>) : null}
            </Col>
            {actions !== undefined && actions !== null ? (<Col lg={{ span: 12 }} xs={{ span: 24 }} className={classes.actionButtonContainer}>{actions}</Col>) : null}
        </Row>
    );
};

export default DataInfoActionRow;