import React from 'react';
import DocumentList from "../../components/DocumentList/DocumentList";
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const LoadDocuments = props => {
    const load = props.load;
    const stops = props.stops;
    const loadId = props.loadId;
    const documents = props.documents;

    return (
        <>
            <DocumentList tableTitle="Load Documents" documentList={documents.filter(document => document.entityId === loadId)} entityType="LOAD" entityId={loadId} needsCarrierWashoutLog={load.equipmentNeeds.hasCarrierWashout} loadId={loadId} />
            {stops.map(stop => {
                return (
                    <div key={`stop-documents-fragment-${stop.id}`} style={{ marginTop: 24 }}>
                        <DocumentList tableTitle={`Documents for Stop # ${stop.sequence + 1} - ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}${(stop.stopLocation !== undefined && stop.stopLocation !== null ? ' - ' + stop.stopLocation.name : "")}${(stop.stopLocation !== undefined && stop.stopLocation !== null) ? " - " + stop.stopLocation.address.city + ", " + stop.stopLocation.address.state : ""}`} documentList={documents.filter(document => document.entityType === 'STOP' && document.entityId === stop.id)} entityId={stop.id} entityType="STOP" entitySubType={stop.stopType} loadId={loadId} key={`stop-documents-${stop.id}`} />
                    </div>
                );
            })}
        </>
    );
};

export default LoadDocuments;