import React, { useMemo, useState } from 'react';
import { Alert, Input, Spin, Checkbox } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import UploadFile from "../UploadFile/UploadFile";
import moment from 'moment';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';

const EditDriver = props => {
    const accountUser = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);

    const [hazmatLicenseFrontFile, setHazmatLicenseFrontFile] = useState(null);
    const [hazmatLicenseBackFile, setHazmatLicenseBackFile] = useState(null);
    const [driverLicenseFrontFile, setDriverLicenseFrontFile] = useState(null);
    const [driverLicenseBackFile, setDriverLicenseBackFile] = useState(null);
    const [commercialLicenseFrontFile, setCommercialLicenseFrontFile] = useState(null);
    const [commercialLicenseBackFile, setCommercialLicenseBackFile] = useState(null);
    const [pneumaticLicenseFrontFile, setPneumaticLicenseFrontFile] = useState(null);
    const [pneumaticLicenseBackFile, setPneumaticLicenseBackFile] = useState(null);
    const [mcCertificateLicenseFrontFile, setMcCertificateLicenseFrontFile] = useState(null);
    const [mcCertificateLicenseBackFile, setMcCertificateLicenseBackFile] = useState(null);
    const [explosiveCertifiedLicenseFrontFile, setExplosiveCertifiedLicenseFrontFile] = useState(null);
    const [explosiveCertifiedLicenseBackFile, setExplosiveCertifiedLicenseBackFile] = useState(null);
    const [mshaLicenseFrontFile, setMshaLicenseFrontFile] = useState(null);
    const [mshaLicenseBackFile, setMshaLicenseBackFile] = useState(null);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        if (hazmatLicenseFrontFile) {
            if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                    methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("hazmatLicenseExpiryDate");
                methods.clearErrors("hazmatLicenseNumber");
            }
            cleanedData.hazmatLicenseFrontFile = hazmatLicenseFrontFile;
            cleanedData.hazmatLicenseFrontFileId = accountUser.driver.hazmatLicenseFrontFileId;
        } else if (accountUser.driver.hazmatLicenseFrontFileId) {
            if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                    methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("hazmatLicenseExpiryDate");
                methods.clearErrors("hazmatLicenseNumber");
            }
            cleanedData.hazmatLicenseFrontFileId = accountUser.driver.hazmatLicenseFrontFileId;
        }
        if (hazmatLicenseBackFile) {
            if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                    methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("hazmatLicenseExpiryDate");
                methods.clearErrors("hazmatLicenseNumber");
            }
            cleanedData.hazmatLicenseBackFile = hazmatLicenseBackFile;
            cleanedData.hazmatLicenseBackFileId = accountUser.driver.hazmatLicenseBackFileId;
        } else if (accountUser.driver.hazmatLicenseBackFileId) {
            if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                    methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("hazmatLicenseExpiryDate");
                methods.clearErrors("hazmatLicenseNumber");
            }
            cleanedData.hazmatLicenseBackFileId = accountUser.driver.hazmatLicenseBackFileId;
        }

        if (driverLicenseFrontFile) {
            if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                    methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("driverLicenseExpiryDate");
                methods.clearErrors("driverLicenseNumber");
            }
            cleanedData.driverLicenseFrontFile = driverLicenseFrontFile;
            cleanedData.driverLicenseFrontFileId = accountUser.driver.driverLicenseFrontFileId;
        } else if (accountUser.driver.driverLicenseFrontFileId) {
            if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                    methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("driverLicenseExpiryDate");
                methods.clearErrors("driverLicenseNumber");
            }
            cleanedData.driverLicenseFrontFileId = accountUser.driver.driverLicenseFrontFileId;
        }
        if (driverLicenseBackFile) {
            if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                    methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("driverLicenseExpiryDate");
                methods.clearErrors("driverLicenseNumber");
            }
            cleanedData.driverLicenseBackFile = driverLicenseBackFile;
            cleanedData.driverLicenseBackFileId = accountUser.driver.driverLicenseBackFileId;
        } else if (accountUser.driver.driverLicenseBackFileId) {
            if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                    methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("driverLicenseExpiryDate");
                methods.clearErrors("driverLicenseNumber");
            }
            cleanedData.driverLicenseBackFileId = accountUser.driver.driverLicenseBackFileId;
        }

        if (commercialLicenseFrontFile) {
            if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                    methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("commercialLicenseExpiryDate");
                methods.clearErrors("commercialLicenseNumber");
            }
            cleanedData.commercialLicenseFrontFile = commercialLicenseFrontFile;
            cleanedData.commercialLicenseFrontFileId = accountUser.driver.commercialLicenseFrontFileId;
        } else if (accountUser.driver.commercialLicenseFrontFileId) {
            if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                    methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("commercialLicenseExpiryDate");
                methods.clearErrors("commercialLicenseNumber");
            }
            cleanedData.commercialLicenseFrontFileId = accountUser.driver.commercialLicenseFrontFileId;
        }
        if (commercialLicenseBackFile) {
            if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                    methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrorss("commercialLicenseExpiryDate");
                methods.clearErrors("commercialLicenseNumber");
            }
            cleanedData.commercialLicenseBackFile = commercialLicenseBackFile;
            cleanedData.commercialLicenseBackFileId = accountUser.driver.commercialLicenseBackFileId;
        } else if (accountUser.driver.commercialLicenseBackFileId) {
            if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                    methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("commercialLicenseExpiryDate");
                methods.clearErrors("commercialLicenseNumber");
            }
            cleanedData.commercialLicenseBackFileId = accountUser.driver.commercialLicenseBackFileId;
        }

        if (pneumaticLicenseFrontFile) {
            if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                    methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("pneumaticLicenseExpiryDate");
                methods.clearErrors("pneumaticLicenseNumber");
            }
            cleanedData.pneumaticLicenseFrontFile = pneumaticLicenseFrontFile;
            cleanedData.pneumaticLicenseFrontFileId = accountUser.driver.pneumaticLicenseFrontFileId;
        } else if (accountUser.driver.pneumaticLicenseFrontFileId) {
            if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                    methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("pneumaticLicenseExpiryDate");
                methods.clearErrors("pneumaticLicenseNumber");
            }
            cleanedData.pneumaticLicenseFrontFileId = accountUser.driver.pneumaticLicenseFrontFileId;
        }
        if (pneumaticLicenseBackFile) {
            if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                    methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("pneumaticLicenseExpiryDate");
                methods.clearErrors("pneumaticLicenseNumber");
            }
            cleanedData.pneumaticLicenseBackFile = pneumaticLicenseBackFile;
            cleanedData.pneumaticLicenseBackFileId = accountUser.driver.pneumaticLicenseBackFileId;
        } else if (accountUser.driver.pneumaticLicenseBackFileId) {
            if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                    methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("pneumaticLicenseExpiryDate");
                methods.clearErrors("pneumaticLicenseNumber");
            }
            cleanedData.pneumaticLicenseBackFileId = accountUser.driver.pneumaticLicenseBackFileId;
        }

        if (mcCertificateLicenseFrontFile) {
            if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                    methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mcCertificateLicenseExpiryDate");
                methods.clearErrors("mcCertificateLicenseNumber");
            }
            cleanedData.mcCertificateLicenseFrontFile = mcCertificateLicenseFrontFile;
            cleanedData.mcCertificateLicenseFrontFileId = accountUser.driver.mcCertificateLicenseFrontFileId;
        } else if (accountUser.driver.mcCertificateLicenseFrontFileId) {
            if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                    methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mcCertificateLicenseExpiryDate");
                methods.clearErrors("mcCertificateLicenseNumber");
            }
            cleanedData.mcCertificateLicenseFrontFileId = accountUser.driver.mcCertificateLicenseFrontFileId;
        }
        if (mcCertificateLicenseBackFile) {
            if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                    methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mcCertificateLicenseExpiryDate");
                methods.clearErrors("mcCertificateLicenseNumber");
            }
            cleanedData.mcCertificateLicenseBackFile = mcCertificateLicenseBackFile;
            cleanedData.mcCertificateLicenseBackFileId = accountUser.driver.mcCertificateLicenseBackFileId;
        } else if (accountUser.driver.mcCertificateLicenseBackFileId) {
            if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                    methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mcCertificateLicenseExpiryDate");
                methods.clearErrors("mcCertificateLicenseNumber");
            }
            cleanedData.mcCertificateLicenseBackFileId = accountUser.driver.mcCertificateLicenseBackFileId;
        }

        if (explosiveCertifiedLicenseFrontFile) {
            if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                    methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                methods.clearErrors("explosiveCertifiedLicenseNumber");
            }
            cleanedData.explosiveCertifiedLicenseFrontFile = explosiveCertifiedLicenseFrontFile;
            cleanedData.explosiveCertifiedLicenseFrontFileId = accountUser.driver.explosiveCertifiedLicenseFrontFileId;
        } else if (accountUser.driver.explosiveCertifiedLicenseFrontFileId) {
            if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                    methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                methods.clearErrors("explosiveCertifiedLicenseNumber");
            }
            cleanedData.explosiveCertifiedLicenseFrontFileId = accountUser.driver.explosiveCertifiedLicenseFrontFileId;
        }
        if (explosiveCertifiedLicenseBackFile) {
            if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                    methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                methods.clearErrors("explosiveCertifiedLicenseNumber");
            }
            cleanedData.explosiveCertifiedLicenseBackFile = explosiveCertifiedLicenseBackFile;
            cleanedData.explosiveCertifiedLicenseBackFileId = accountUser.driver.explosiveCertifiedLicenseBackFileId;
        } else if (accountUser.driver.explosiveCertifiedLicenseBackFileId) {
            if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                    methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                methods.clearErrors("explosiveCertifiedLicenseNumber");
            }
            cleanedData.explosiveCertifiedLicenseBackFileId = accountUser.driver.explosiveCertifiedLicenseBackFileId;
        }

        if (mshaLicenseFrontFile) {
            if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                    methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mshaLicenseExpiryDate");
                methods.clearErrors("mshaLicenseNumber");
            }
            cleanedData.mshaLicenseFrontFile = mshaLicenseFrontFile;
            cleanedData.mshaLicenseFrontFileId = accountUser.driver.mshaLicenseFrontFileId;
        } else if (accountUser.driver.mshaLicenseFrontFileId) {
            if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                    methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mshaLicenseExpiryDate");
                methods.clearErrors("mshaLicenseNumber");
            }
            cleanedData.mshaLicenseFrontFileId = accountUser.driver.mshaLicenseFrontFileId;
        }
        if (mshaLicenseBackFile) {
            if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                    methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mshaLicenseExpiryDate");
                methods.clearErrors("mshaLicenseNumber");
            }
            cleanedData.mshaLicenseBackFile = mshaLicenseBackFile;
            cleanedData.mshaLicenseBackFileId = accountUser.driver.mshaLicenseBackFileId;
        } else if (accountUser.driver.mshaLicenseBackFileId) {
            if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                    methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                }
                return;
            } else {
                methods.clearErrors("mshaLicenseExpiryDate");
                methods.clearErrors("mshaLicenseNumber");
            }
            cleanedData.mshaLicenseBackFileId = accountUser.driver.mshaLicenseBackFileId;
        }

        let payload = {};
        let objectToUpdate = { ...accountUser.driver };
        for (let [key, value] of Object.entries(cleanedData)) {
            if (key.includes('ExpiryDate') || key.includes('LicenseNumber')) {
                let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
                if (value !== oldValue) {
                    //console.log(`${key}: new value - ${value}, old value - ${oldValue}`);
                    payload[key] = value;
                }
            } else {
                payload[key] = value;
            }
        }

        if (!isEmpty(payload)) {
            let accountUserPayload = {};
            accountUserPayload.driver = payload;
            //console.log(accountUserPayload);
            dispatch(actionCreators.updateAccountUser(accountUser.id, accountUserPayload, true));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateAccountUser());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountUserErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>Driver Details</legend>
                            <FormItem {...formItemLayout} label="Is Available"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Checkbox
                                        onBlur={onBlur}
                                        onChange={e => onChange(e.target.checked)}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isAvailable"
                                defaultValue={accountUser.driver.isAvailable !== null && accountUser.driver.isAvailable !== undefined && accountUser.driver.isAvailable === true}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Commercial Drivers License</legend>
                            <FormItem {...formItemLayout} label="Commercial Drivers License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Commercial Drivers License #" />}
                                rules={{ required: false }}
                                name="commercialLicenseNumber"
                                defaultValue={accountUser.driver.commercialLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="commercialLicenseExpiryDate"
                                defaultValue={accountUser.driver.commercialLicenseExpiryDate ? moment(accountUser.driver.commercialLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="commercialLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setCommercialLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setCommercialLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.commercialLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="commercialLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setCommercialLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setCommercialLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.commercialLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>Personal Drivers License</legend>
                            <FormItem {...formItemLayout} label="Drivers License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drivers License #" />}
                                rules={{ required: false }}
                                name="driverLicenseNumber"
                                defaultValue={accountUser.driver.driverLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="driverLicenseExpiryDate"
                                defaultValue={accountUser.driver.driverLicenseExpiryDate ? moment(accountUser.driver.driverLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="driverLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setDriverLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setDriverLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.driverLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="driverLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setDriverLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setDriverLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.driverLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>Hazmat License</legend>
                            <FormItem {...formItemLayout} label="HazMat License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="HazMat License #" />}
                                rules={{ required: false }}
                                name="hazmatLicenseNumber"
                                defaultValue={accountUser.driver.hazmatLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="hazmatLicenseExpiryDate"
                                defaultValue={accountUser.driver.hazmatLicenseExpiryDate ? moment(accountUser.driver.hazmatLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="hazmatLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setHazmatLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setHazmatLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.hazmatLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="hazmatLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setHazmatLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setHazmatLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.hazmatLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>Pneumatic License</legend>
                            <FormItem {...formItemLayout} label="Pneumatic License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pneumatic License #" />}
                                rules={{ required: false }}
                                name="pneumaticLicenseNumber"
                                defaultValue={accountUser.driver.pneumaticLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="pneumaticLicenseExpiryDate"
                                defaultValue={accountUser.driver.pneumaticLicenseExpiryDate ? moment(accountUser.driver.pneumaticLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="pneumaticLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setPneumaticLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setPneumaticLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.pneumaticLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="pneumaticLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setPneumaticLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setPneumaticLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.pneumaticLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>MC Certificate License</legend>
                            <FormItem {...formItemLayout} label="MC Certificate License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Certificate License #" />}
                                rules={{ required: false }}
                                name="mcCertificateLicenseNumber"
                                defaultValue={accountUser.driver.mcCertificateLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="mcCertificateLicenseExpiryDate"
                                defaultValue={accountUser.driver.mcCertificateLicenseExpiryDate ? moment(accountUser.driver.mcCertificateLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="mcCertificateLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setMcCertificateLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setMcCertificateLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.mcCertificateLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="mcCertificateLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setMcCertificateLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setMcCertificateLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.mcCertificateLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>Explosive Certified License</legend>
                            <FormItem {...formItemLayout} label="Explosive Certified License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Explosive Certified License #" />}
                                rules={{ required: false }}
                                name="explosiveCertifiedLicenseNumber"
                                defaultValue={accountUser.driver.explosiveCertifiedLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="explosiveCertifiedLicenseExpiryDate"
                                defaultValue={accountUser.driver.explosiveCertifiedLicenseExpiryDate ? moment(accountUser.driver.explosiveCertifiedLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="explosiveCertifiedLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setExplosiveCertifiedLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setExplosiveCertifiedLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.explosiveCertifiedLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="explosiveCertifiedLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setExplosiveCertifiedLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setExplosiveCertifiedLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.explosiveCertifiedLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset>
                            <legend>MSHA License</legend>
                            <FormItem {...formItemLayout} label="MSHA License #"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MSHA License #" />}
                                rules={{ required: false }}
                                name="mshaLicenseNumber"
                                defaultValue={accountUser.driver.mshaLicenseNumber}
                            />
                            <FormItem {...formItemLayout} label="Expiration Date"
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: false }}
                                name="mshaLicenseExpiryDate"
                                defaultValue={accountUser.driver.mshaLicenseExpiryDate ? moment(accountUser.driver.mshaLicenseExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Front Image" name="mshaLicenseFrontFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setMshaLicenseFrontFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setMshaLicenseFrontFile(null);
                                    }}
                                    message="Please upload a photo of the front of the license."
                                    fileId={accountUser.driver.mshaLicenseFrontFileId}
                                />
                            </FormItemFile>
                            <FormItemFile {...formItemLayout} label="Back Image" name="mshaLicenseBackFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setMshaLicenseBackFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setMshaLicenseBackFile(null);
                                    }}
                                    message="Please upload a photo of the back of the license."
                                    fileId={accountUser.driver.mshaLicenseBackFileId}
                                />
                            </FormItemFile>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Driver" />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default EditDriver;