import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { PaperClipOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Dropdown, Menu } from 'antd';
import EditAsset from "../../components/EditAsset/EditAsset";
import NewAsset from "../../components/NewAsset/NewAsset";
import classes from './Assets.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import DocumentList from "../../components/DocumentList/DocumentList";
import StringFormatter from '../../shared/stringFormatter';
import { withRouter } from 'react-router-dom';

const stringFormatter = new StringFormatter();

const Assets = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const singularEntityName = "Asset";
    const pluralEntityName = "Assets";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.assets.isLoading);
    const records = useSelector(state => state.assets.records);
    const pagination = useSelector(state => state.assets.pagination);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const openAssetView = (id) => {
        props.history.push({ pathname: `/assets/${id}` }, {
            previousPageTitle: "Assets",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.carrierId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.carrierId = entityId;
        }

        dispatch(actionCreators.fetchAssets(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAssetsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.carrierId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.carrierId = entityId;
        }

        dispatch(actionCreators.fetchAssets(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={() => { setSelectedRecord(record); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openAssetView(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Asset Type',
            dataIndex: 'assetType',
            key: 'assetType',
            render: (text, record) => { return stringFormatter.toFormattedString("AssetType", text, null); },
        },
        {
            title: 'Trailer Type',
            dataIndex: ['trailerType', 'description'],
            key: 'trailerType.description',
        },
        {
            title: 'Available',
            dataIndex: 'isAvailable',
            key: 'isAvailable',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => { return stringFormatter.toFormattedString("AssetStatus", text, null); },
        },
        {
            title: 'License #',
            dataIndex: 'licensePlateNum',
            key: 'licensePlateNum',
        },
        {
            title: 'License State',
            dataIndex: 'licensePlateState',
            key: 'licensePlateState',
        },
        {
            title: 'Dot #',
            dataIndex: 'dotNum',
            key: 'dotNum',
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
        },
        {
            title: 'Truck Class',
            dataIndex: 'truckClass',
            key: 'truckClass',
        },
        {
            title: 'Load Capacity',
            dataIndex: 'loadCapacity',
            key: 'loadCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", record.loadCapacity, record.loadCapacityUnit); },
        },
        {
            title: 'Max Load Weight',
            dataIndex: 'maxLoadWeight',
            key: 'maxLoadWeight',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", record.maxLoadWeight, record.maxLoadWeightUnit); },
        },
        {
            title: 'Contractor Asset',
            dataIndex: 'isContractorAsset',
            key: 'isContractorAsset',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Carrier Asset',
            dataIndex: 'isCarrierAsset',
            key: 'isCarrierAsset',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
    ];

    if (entityType === "STAFF") {
        if (accountId === undefined || accountId === null) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewAsset cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset cancel={toggleEditEntity} record={selectedRecord} accountId={selectedRecord.carrierId} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Asset Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="ASSET" entityId={selectedRecord.id} />
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {documentsComponent}
        </DataTable>
    );
};

export default withRouter(Assets);
