import React, { useMemo, useState } from 'react';
import {Alert, Spin, Input} from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset'
import DatePicker from "../DatePickerAutoAccept/DatePickerAutoAccept";

const EditLoadStatusPostpone = props => {
    const load = props.load;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = (data) => {
        // validate to make sure file was attached
        dispatch(actionCreators.updateLoadPostponeDate(data, load.id));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Propose New Date</legend>
                        <FormItem {...formItemLayout} label="Postpone Date"
                                  render={({ onChange, onBlur, value, name }) => (
                                      <DatePicker
                                          placeholder="Postpone Date"
                                          style={{ width: '100%' }}
                                          onBlur={onBlur}
                                          onChange={(date, dateString) => { onChange(date); }}
                                          value={value}
                                          name={name}
                                      />
                                  )}
                                  rules={{ required: 'Required Field' }}
                                  name="suggestedPostponeDate"
                        />
                        <FormItem {...formItemLayout} label="Note"
                                  render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postpone Note" />}
                                  rules={{ required: false }}
                                  name="postponeNote"
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Send postpone proposal" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusPostpone;