import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {addFile, updateFileMetaData} from "../../api/fileClient";
import moment from 'moment';
import logger from "../../shared/logger";

//#region Documents Functions

export const fetchDocumentsStart = () => {
    return {
        type: actionTypes.FETCH_DOCUMENTS_START
    }
};

export const fetchDocumentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DOCUMENTS_SUCCESS,
        payload: payload
    }
};

export const fetchDocumentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_DOCUMENTS_FAIL,
        payload: payload
    }
};

export const clearDocuments = () => {
    return {
        type: actionTypes.CLEAR_DOCUMENTS
    }
};

export const addDocumentStart = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_START
    }
};

export const addDocumentSuccess = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_SUCCESS
    }
};

export const addDocumentFail = (payload) => {
    return {
        type: actionTypes.ADD_DOCUMENT_FAIL,
        payload: payload
    }
};

export const addDocumentErrorClear = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_ERROR_CLEAR
    }
};

export const addDocumentLoadingClear = () => {
    return {
        type: actionTypes.ADD_DOCUMENT_LOADING_CLEAR
    }
};

export const updateDocumentStart = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_START
    }
};

export const updateDocumentSuccess = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_SUCCESS
    }
};

export const updateDocumentFail = (payload) => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_FAIL,
        payload: payload
    }
};

export const updateDocumentErrorClear = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_ERROR_CLEAR
    }
};

export const updateDocumentLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_DOCUMENT_LOADING_CLEAR
    }
};

export const cancelAddDocument = () => {
    return {
        type: actionTypes.CANCEL_ADD_DOCUMENT
    }
};

export const cancelUpdateDocument = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_DOCUMENT
    }
};

export const insertDocument = (payload) => {
    return {
        type: actionTypes.ADD_DOCUMENT,
        payload: payload
    }
};

export const changeDocument = (payload) => {
    return {
        type: actionTypes.UPDATE_DOCUMENT,
        payload: payload
    }
};

export const changeSingleDocument = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_DOCUMENT,
        payload: payload
    }
};

export const removeDocument = (payload) => {
    return {
        type: actionTypes.REMOVE_DOCUMENT,
        payload: payload
    }
};

//#endregion

//#region Documents Methods

export const fetchDocuments = (payload) => {
    return async (dispatch, getState) => {
        const documentsPath = '/documents';
        try {
            dispatch(fetchDocumentsStart());

            const state = getState();
            const documentsState = {...state.documents};
            let pagination = {...documentsState.pagination};
            let searchParams = {...documentsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const documentsRes = await axiosAuthenticated.get(documentsPath, {params: {...searchParams}});
            if (documentsRes && documentsRes.status === 200) {
                const documents = documentsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                const transformedDocuments = documents.map(item => {
                    // Get Account Info
                    let entityName = null;
                    const entityType = item.entityType ? item.entityType : null;
                    if (entityType === "ACCOUNT" || entityType === "ACCOUNT_SHIPPER" || entityType === "ACCOUNT_CARRIER" || entityType === "ACCOUNT_PRODUCER" || entityType === "ACCOUNT_RECEIVER") {
                        const entityId = item.entityId;
                        if (entityId !== undefined && entityId !== null) {
                            let account = accounts.find(i => i.id === entityId);
                            if (account !== undefined) {
                                entityName = account.name;
                            }
                        }
                    }

                    let reviewedByName = null;
                    const reviewedBy = item.reviewedBy;
                    if (reviewedBy !== undefined && reviewedBy !== null) {
                        let user = staff.find(i => i.user_id === reviewedBy);
                        if (user !== undefined && user !== null) {
                            if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                                reviewedByName = user.firstName + ' ' + user.lastName;
                            }
                        }
                    }

                    return {
                        ...item,
                        entityType: item.entityType ? item.entityType : null,
                        visibleTo: item.visibleTo ? item.visibleTo : null,
                        reviewStatus: item.reviewStatus ? item.reviewStatus : null,
                        documentType: item.documentType ? item.documentType : null,
                        entityName: entityName,
                        reviewedByName: reviewedByName
                    };
                });

                // Read total count from server
                pagination.total = documentsRes.data.totalCount;
                pagination.current = documentsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchDocumentsSuccess({
                    records: transformedDocuments,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchDocumentsFail({error: error.message}));
        }
    }
};

export const fetchBulkDocuments = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(fetchDocumentsStart());

            const documentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                page: 1,
                size: 10000, ...payload
            });
            if (documentsRes && documentsRes.status === 200) {
                const documents = documentsRes.data.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                const transformedDocuments = documents.map(item => {
                    // Get Account Info
                    let entityName = null;
                    const entityType = item.entityType ? item.entityType : null;
                    if (entityType === "ACCOUNT" || entityType === "ACCOUNT_SHIPPER" || entityType === "ACCOUNT_CARRIER" || entityType === "ACCOUNT_PRODUCER" || entityType === "ACCOUNT_RECEIVER") {
                        const entityId = item.entityId;
                        if (entityId !== undefined && entityId !== null) {
                            let account = accounts.find(i => i.id === entityId);
                            if (account !== undefined) {
                                entityName = account.name;
                            }
                        }
                    }

                    let reviewedByName = null;
                    const reviewedBy = item.reviewedBy;
                    if (reviewedBy !== undefined && reviewedBy !== null) {
                        let user = staff.find(i => i.user_id === reviewedBy);
                        if (user !== undefined && user !== null) {
                            if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                                reviewedByName = user.firstName + ' ' + user.lastName;
                            }
                        }
                    }

                    return {
                        ...item,
                        entityType: item.entityType ? item.entityType : null,
                        visibleTo: item.visibleTo ? item.visibleTo : null,
                        reviewStatus: item.reviewStatus ? item.reviewStatus : null,
                        documentType: item.documentType ? item.documentType : null,
                        entityName: entityName,
                        reviewedByName: reviewedByName
                    };
                });

                dispatch(fetchDocumentsSuccess({records: transformedDocuments}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchDocumentsFail({error: error.message}));
        }
    }
};

export const addDocument = (payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(addDocumentStart());

            if (payload.expiryDate !== undefined && payload.expiryDate !== null && moment.isMoment(payload.expiryDate)) {
                payload.expiryDate = payload.expiryDate.utc().toISOString();
            }

            const document = await addFile(payload.file, payload.entityType, payload.entityId, payload.documentType, payload.displayName, payload.description, payload.visibleTo ? payload.visibleTo : "ALL", payload.expiryDate ? payload.expiryDate : null, payload.isReviewed, payload.reviewStatus);

            // TODO: change to just add the document to whatever list is already in the document store
            if (document !== undefined && document !== null) {
                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                let entityName = null;
                if (document.entityType === "ACCOUNT" || document.entityType === "ACCOUNT_SHIPPER" || document.entityType === "ACCOUNT_CARRIER" || document.entityType === "ACCOUNT_PRODUCER" || document.entityType === "ACCOUNT_RECEIVER") {
                    if (document.entityId !== undefined && document.entityId !== null) {
                        let account = accounts.find(i => i.id === document.entityId);
                        if (account !== undefined) {
                            entityName = account.name;
                        }
                    }
                }

                let reviewedByName = null;
                if (document.reviewedBy !== undefined && document.reviewedBy !== null) {
                    let user = staff.find(i => i.user_id === document.reviewedBy);
                    if (user !== undefined && user !== null) {
                        if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                            reviewedByName = user.firstName + ' ' + user.lastName;
                        }
                    }
                }

                let newDocument = {
                    ...document,
                    entityName: entityName,
                    reviewedByName: reviewedByName
                };

                dispatch(insertDocument(newDocument));

                dispatch(addDocumentSuccess());
                dispatch(addDocumentLoadingClear());
                dispatch(addDocumentErrorClear());
            } else {
                dispatch(addDocumentFail({error: 'Failed to upload the file'}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addDocumentFail({error: error.message}));
        }
    }
};

export const updateDocument = (documentId, payload) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateDocumentStart());

            const document = await updateFileMetaData(documentId, payload);

            if (document !== undefined && document !== null) {
                if (document.isDeleted === true) {
                    dispatch(removeDocument(document));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];
                    const staff = [...orchestratorState.staff];

                    let entityName = null;
                    if (document.entityType === "ACCOUNT" || document.entityType === "ACCOUNT_SHIPPER" || document.entityType === "ACCOUNT_CARRIER" || document.entityType === "ACCOUNT_PRODUCER" || document.entityType === "ACCOUNT_RECEIVER") {
                        if (document.entityId !== undefined && document.entityId !== null) {
                            let account = accounts.find(i => i.id === document.entityId);
                            if (account !== undefined) {
                                entityName = account.name;
                            }
                        }
                    }

                    let reviewedByName = null;
                    if (document.reviewedBy !== undefined && document.reviewedBy !== null) {
                        let user = staff.find(i => i.user_id === document.reviewedBy);
                        if (user !== undefined && user !== null) {
                            if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                                reviewedByName = user.firstName + ' ' + user.lastName;
                            }
                        }
                    }

                    let updatedDocument = {
                        ...document,
                        entityName: entityName,
                        reviewedByName: reviewedByName
                    };

                    dispatch(changeDocument(updatedDocument));
                    dispatch(changeSingleDocument(updatedDocument));
                }
                dispatch(updateDocumentSuccess());
                dispatch(updateDocumentLoadingClear());
                dispatch(updateDocumentErrorClear());
            } else {
                dispatch(updateDocumentFail({error: 'Failed to update the document.'}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateDocumentFail({error: error.message}));
        }
    }
};

//#endregion