import React from 'react';
import classes from './DetailsListHeaderActionRow.module.scss';
import { Row, Col } from 'antd';

const DetailsListHeaderActionRow = props => {
    return (
        <tr className={classes.dataRow}>
            <th className={classes.dataRowContent} colSpan={props.colSpan}>
                <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col xs={{ span: 12 }}>
                        <b>{props.label}</b>
                    </Col>
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        {props.extra}
                    </Col>
                </Row>
            </th>
        </tr>
    );
};

export default DetailsListHeaderActionRow;