import React, { useMemo, useState } from 'react';
import { Collapse, Drawer, Col, Row, Timeline, Button } from 'antd';
import NewLoadStop from '../../components/NewLoadStop/NewLoadStop';
import EditLoadStop from '../../components/EditLoadStop/EditLoadStop';
import RemoveLoadStop from '../../components/RemoveLoadStop/RemoveLoadStop';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import DocumentList from '../../components/DocumentList/DocumentList';
import NoteList from '../../components/NoteList/NoteList';
import { PlusOutlined } from '@ant-design/icons';
import LoadStop from '../../components/LoadStop/LoadStop';

const stringFormatter = new StringFormatter();
const { Panel } = Collapse;

const LoadStops = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Stop";

    const load = props.load;
    const stops = props.stops;
    const currentStatus = props.currentStatus;
    const entityType = props.entityType;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];
    const notes = props.notes !== undefined && props.notes !== null ? props.notes : [];
    const bols = props.bols;
    const commodities = props.commodities;

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [newStopProps, setNewStopProps] = useState({});
    const [loadStops, setLoadStops] = useState([]);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"Add New Stop to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={(e) => { toggleNewEntity(); }}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLoadStop cancel={toggleNewEntity} load={load} shipperId={load.shipperId} {...newStopProps} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Stop Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadStop
                cancel={toggleEditEntity}
                load={load}
                stop={selectedRecord}
                shipperId={load.shipperId}
                carrierId={load.assignedCarrierId}
                currentStatus={currentStatus}
                stops={stops}
                stopType={selectedRecord && selectedRecord.stopType ? selectedRecord.stopType : null}
                sequence={selectedRecord && selectedRecord.sequence ? selectedRecord.sequence : null}
                stopTypeSequence={selectedRecord && selectedRecord.stopTypeSequence ? selectedRecord.stopTypeSequence : null}
            // defaultBOLNumbers={bols && bols.length > 0 ? bols : []}
            // defaultCommodities={commodities && commodities.length > 0 ? commodities : []}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Stop from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadStop cancel={toggleRemoveEntity} load={load} stop={selectedRecord} />
        </Drawer>
    );

    // only allow the stop to be edited if its status is Pending
    const header = (stop, stopName, stopCity, stopState, stopStatus) => {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col md={{ span: 18 }} xs={{ span: 12 }}>
                    <span style={{ fontWeight: 500 }}>{(stop && stop.stopType ? `${Enums.StopTypes.getValueByName(stop.stopType)} ${stop.stopTypeSequence ? (stop.stopTypeSequence + 1) : ''}` : '')} - </span>
                    <span style={{ fontWeight: 500 }}>
                        {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                        {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                        {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                    </span>
                    <span style={{ fontWeight: 500 }}>
                        {stopName ? " - " + stopName : ""}{stopCity && stopState ? " - " + stopCity + ", " + stopState : ""} - Status: {stopStatus}
                    </span>
                </Col>
                {(entityType === "STAFF" || (entityType === "SHIPPER" && load.createdByEntityType === "SHIPPER") || (entityType === "CARRIER" && load.createdByEntityType === "CARRIER")) ? (
                    <Col md={{ span: 6 }} xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        <AddEditChangeRemoveButtons key={`buttons-${stop.id}`}
                            showAdd={false} addText='' addAction={null}
                            showEdit={(stop.stopStatus === "PENDING" || stop.stopStatus === "LAYOVER") && currentStatus !== 'CANCELLED' ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(stop); toggleEditEntity(stop); }}
                            showChange={false} changeText='' changeAction={null}
                            showRemove={(stop.stopStatus === "PENDING" && currentStatus !== 'CANCELLED' && ((stop.stopType === 'PICK_UP' && pickUpStops.length > 1) || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1))) ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(stop); toggleRemoveEntity(stop); }}
                        />
                    </Col>
                ) : null}
            </Row>
        );
    };

    const getStopLocationDisplay = (stop, stopIndex, stopTypeIndex) => {
        return (
            <DataCollapsableRow
                key={`stop-display-${stop.stopType}-${stopIndex}`}
                isCollapsed={true}
                title={stop && stop.stopLocation && stop.stopLocation.name ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType)}
                titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                subtitle={stop && stop.stopLocation && stop.stopLocation.address ? (<span style={{ fontSize: 14 }}>{stringFormatter.toFormattedAddress(stop.stopLocation.address)}</span>) : null}
                header={(stop.apptType !== undefined && stop.apptType !== null) ? (
                    <>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{(stop && stop.stopType ? `${Enums.StopTypes.getValueByName(stop.stopType)} ${stopTypeIndex ? (stopTypeIndex + 1) : ''}` : '')}  </span>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>
                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                        </span>
                    </>
                ) : (stop && stop.stopType ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: '#A9A9A9' }}>{`${Enums.StopTypes.getValueByName(stop.stopType)} ${stopTypeIndex ? (stopTypeIndex + 1) : ''}`}</span> : null)}
                extra={<>
                    {(((stop.stopStatus === "PENDING" || stop.stopStatus === "LAYOVER") && currentStatus !== 'CANCELLED') && (stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF')) ? (
                        <Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { setSelectedRecord(stop); toggleEditEntity(stop); }}>edit</Button>
                    ) : null}
                    {(stop.stopStatus === "PENDING" && currentStatus !== 'CANCELLED' && ((stop.stopType === 'PICK_UP' && pickUpStops.length > 1) || (stop.stopType === 'DROP_OFF' && dropOffStops.length > 1))) ? (
                        <Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { setSelectedRecord(stop); toggleRemoveEntity(stop); }}>remove</Button>
                    ) : null}
                </>}
            >
                <div style={{ marginTop: 18 }}>
                    {((stop.startDateTime !== undefined && stop.startDateTime !== null) || (stop.endDateTime !== undefined && stop.endDateTime !== null)) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            {(stop.startDateTime !== undefined && stop.startDateTime !== null) ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Arrival Date/Time</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('MomentDateTime', stop.startDateTime, null, stop.timeZone)}</span></div>
                                </Col>
                            ) : null}
                            {(stop.endDateTime !== undefined && stop.endDateTime !== null) ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Departure Date/Time</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('MomentDateTime', stop.endDateTime, null, stop.timeZone)}</span></div>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}

                    {((stop.stopDuration !== undefined && stop.stopDuration !== null && stop.stopDurationUnit !== undefined && stop.stopDurationUnit !== null) || (stop.actualDistanceTo !== undefined && stop.actualDistanceTo !== null && stop.actualDistanceToUnit !== undefined && stop.actualDistanceToUnit !== null)) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            {(stop.stopDuration !== undefined && stop.stopDuration !== null && stop.stopDurationUnit !== undefined && stop.stopDurationUnit !== null) ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Stop Duration</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('Time', stop.stopDuration, stop.stopDurationUnit, null)}</span></div>
                                </Col>
                            ) : null}
                            {(stop.actualDistanceTo !== undefined && stop.actualDistanceTo !== null && stop.actualDistanceToUnit !== undefined && stop.actualDistanceToUnit !== null) ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Leg Distance</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('Distance', stop.actualDistanceTo, stop.actualDistanceToUnit, null)}</span></div>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}

                    {(stop.apptType !== undefined && stop.apptType !== null) ? (
                        <>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Type</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('AppointmentType', stop.apptType, null)}</span></div>
                                </Col>
                            </Row>
                            <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Requested {Enums.StopTypes.getValueByName(stop.stopType)} Date & Appointment</span></div>
                                    <div>
                                        <span style={{ fontSize: 16 }}>
                                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? stringFormatter.toFormattedTimeRange(stop.apptWindowStartDateTime, stop.apptWindowEndDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {stop.apptNumber ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Number</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptNumber}</span></div>
                                    </Col>
                                </Row>
                            ) : null}
                            {stop.apptCallAheadDateTime ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Call Ahead Date/Time</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString('MomentDateTime', stop.apptCallAheadDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null)}</span></div>
                                    </Col>
                                </Row>
                            ) : null}
                            {stop.apptPointOfContact ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{Enums.StopTypes.getValueByName(stop.stopType)} Contact</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptPointOfContact.name}</span></div>
                                    </Col>
                                    <Col span={12}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Phone Number</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}</span></div>
                                    </Col>
                                </Row>
                            ) : null}
                            {stop.apptInstructions ? (
                                <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                                    <Col span={24}>
                                        <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Appointment Instructions</span></div>
                                        <div><span style={{ fontSize: 16 }}>{stop.apptInstructions}</span></div>
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    ) : null}
                    {(stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>BOL Number</span></div>
                                {stop.bolNumbers.map((stopBOLNumber, stopBOLNumberIndex) => {
                                    return (
                                        <Row gutter={[4, 4]} style={{ marginTop: 12, marginBottom: 12 }} key={`bolNumber-${stopBOLNumberIndex}`}>
                                            <Col span={24}>
                                                <div><span style={{ fontSize: 16 }}>{stopBOLNumber}</span></div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                    ) : null}
                    {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Commodities</span></div>
                                {stop.commodities.map((stopCommodity, index) => {
                                    //console.log(stopCommodity);

                                    return (
                                        <Row gutter={[12, 12]} key={`stopCommodity-${index}`}>
                                            <Col span={24}>
                                                <span style={{ fontSize: 16 }}>{stopCommodity.name}: {stopCommodity.unitPackagingInitCount ? `(Init: ${stopCommodity.unitPackagingInitCount}, Actual: ${stopCommodity.unitPackagingActualCount ? stopCommodity.unitPackagingActualCount : 'N/A'}) ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{stopCommodity.bulkPackagingInitCount ? `, (Init: ${stopCommodity.bulkPackagingInitCount}, Actual: ${stopCommodity.bulkPackagingActualCount ? stopCommodity.bulkPackagingActualCount : 'N/A'}) ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>
                    ) : null}
                    {(stop.loadingType !== undefined && stop.loadingType !== null) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : (stop.stopType === 'DROP_OFF' ? 'Unloading Type' : 'Loading/Unloading Type')}</span></div>
                                <div><span style={{ fontSize: 16 }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span></div>
                            </Col>
                        </Row>
                    ) : null}
                    {(stop.hasLumperFee === true || stop.hasDriverAssist === true) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            {stop.hasLumperFee === true ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Lumper Fee</span></div>
                                    <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`}</span></div>
                                </Col>
                            ) : null}
                            {stop.hasDriverAssist === true ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Driver Assist</span></div>
                                    <div><span style={{ fontSize: 16 }}>{`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`}</span></div>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}
                    {stop.pickUpNumber ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Pick-Up Number</span></div>
                                <div><span style={{ fontSize: 16 }}>{stop.pickUpNumber}</span></div>
                            </Col>
                        </Row>
                    ) : null}
                    {(stop.purchaseOrderNumber || stop.dropOffNumber) ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            {stop.dropOffNumber ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Drop-Off Number</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stop.dropOffNumber}</span></div>
                                </Col>
                            ) : null}
                            {stop.purchaseOrderNumber ? (
                                <Col span={12}>
                                    <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Customer PO Number</span></div>
                                    <div><span style={{ fontSize: 16 }}>{stop.purchaseOrderNumber}</span></div>
                                </Col>
                            ) : null}
                        </Row>
                    ) : null}
                    {stop.specialInstructions ? (
                        <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>Special Instructions</span></div>
                                <div><span style={{ fontSize: 16 }}>{stop.specialInstructions}</span></div>
                            </Col>
                        </Row>
                    ) : null}
                    <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                        <Col span={24}>
                            <DocumentList tableTitle="Stop Documents" documentList={documents.filter(document => document.entityId === stop.id)} entityId={stop.id} entityType="STOP" entitySubType={stop.stopType} loadId={stop.loadId} />
                        </Col>
                    </Row>
                    <Row gutter={[4, 4]} style={{ marginBottom: 18 }}>
                        <Col span={24}>
                            <NoteList tableTitle="Stop Notes" noteList={notes.filter(note => note.entityId === stop.id)} entityId={stop.id} entityType="STOP" />
                        </Col>
                    </Row>
                </div>
            </DataCollapsableRow>
        );
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let pickUpStopsList = [];
            let pickUpIndex = 0;
            let dropOffStopsList = [];
            let dropOffIndex = 0;
            let loadStopsList = [];
            stops.forEach((stop, stopIndex) => {
                if (stop.stopType === 'PICK_UP') {
                    pickUpStopsList.push(stop);
                    loadStopsList.push({ ...stop, stopTypeSequence: pickUpIndex });
                    pickUpIndex++;
                } else if (stop.stopType === 'DROP_OFF') {
                    dropOffStopsList.push(stop);
                    loadStopsList.push({ ...stop, stopTypeSequence: dropOffIndex });
                    dropOffIndex++;
                }
            });
            setPickUpStops(pickUpStopsList);
            setDropOffStops(dropOffStopsList);
            setLoadStops(loadStopsList);
        }
    }, [stops]);

    //#endregion

    return (
        <>
            {loadStops.length > 0 ? loadStops.map((stop, stopIndex, arr) => {
                let beforeStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                let afterStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                return (
                    <div style={{ marginTop: 6, marginBottom: 6 }}>
                        {((stop.stopTypeSequence === 0 && stop.stopStatus === 'PENDING') && (currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED')) ? (
                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: beforeStopType, sequence: stop.sequence === 0 ? 0 : stop.sequence - 1, stopTypeSequence: stop.sequence === 0 ? 0 : stop.stopTypeSequence - 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(beforeStopType)} Before</Button>
                            </div>
                        ) : null}
                        <Collapse key={`stop-panel-${stop.stopType}-${stop.sequence}`}>
                            <Panel header={header(stop, (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null), (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city : null), (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : null), stop.stopStatus)}>
                                <LoadStop
                                    stop={stop}
                                    showDocuments={true}
                                    showNotes={true}
                                    key={`stop-${stop.id}`}
                                    documents={documents}
                                    notes={notes} />
                            </Panel>
                        </Collapse>
                        {((stop.stopStatus === 'PENDING') && (currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED')) ? (
                            <div style={{ marginTop: 18, marginBottom: 18 }}>
                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: afterStopType, sequence: stop.sequence + 1, stopTypeSequence: stop.stopTypeSequence + 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(afterStopType)} After</Button>
                            </div>
                        ) : null}
                    </div>
                );
            }) : null}
            {/* <Timeline style={{ padding: '10px 24px' }}>
                {loadStops.map((stop, stopIndex, arr) => {
                    let beforeStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                    let afterStopType = stop.stopType !== 'PICK_UP' && stop.stopType !== 'DROP_OFF' ? ((arr.find(i => i.stopType === 'DROP_OFF' && i.sequence < stopIndex) === undefined) ? 'PICK_UP' : 'DROP_OFF') : stop.stopType;
                    return (
                        <Timeline.Item className="customTimelineItem" key={`${stop.stopType}-${stop.sequence}`} color="#23BEBB">
                            <div style={{ paddingLeft: 50 }}>
                                {((stop.stopTypeSequence === 0 && stop.stopStatus === 'PENDING') && (currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED')) ? (
                                    <div style={{ marginTop: 18, marginBottom: 18 }}>
                                        <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: beforeStopType, sequence: stop.sequence === 0 ? 0 : stop.sequence - 1, stopTypeSequence: stop.sequence === 0 ? 0 : stop.stopTypeSequence - 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(beforeStopType)} Before</Button>
                                    </div>
                                ) : null}
                                {getStopLocationDisplay(stop, stop.sequence, stop.stopTypeSequence)}
                                {((stop.stopStatus === 'PENDING') && (currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CLOSED' && currentStatus !== 'CANCELLED')) ? (
                                    <div style={{ marginTop: 18, marginBottom: 18 }}>
                                        <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setNewStopProps({ stopType: afterStopType, sequence: stop.sequence + 1, stopTypeSequence: stop.stopTypeSequence + 1 }); toggleNewEntity(); }}>Add New {Enums.StopTypes.getValueByName(afterStopType)} After</Button>
                                    </div>
                                ) : null}
                            </div>
                        </Timeline.Item>
                    );
                })}
            </Timeline> */}
            {newEntityComponents}
            {editEntityComponents}
            {removeEntityComponents}
        </>
    );
};

export default LoadStops;