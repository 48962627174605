import React, { useState, useMemo } from 'react';
import { Card, Drawer, Modal, Button, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import DataContactInfoRow from "../../components/DataContactInfoRow/DataContactInfoRow";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import classes from './LoadCarrierCard.module.scss';
import EditCarrier from "../../components/EditCarrier/EditCarrier";
import AssignLoadCarrier from "../../components/AssignLoadCarrier/AssignLoadCarrier";
import ChangeLoadCarrier from "../../components/ChangeLoadCarrier/ChangeLoadCarrier";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import RemoveLoadCarrier from '../RemoveLoadCarrier/RemoveLoadCarrier';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadCarrierCard = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Carrier";
    const load = props.load;
    const stops = props.stops;
    const loadId = props.load.id;
    const assignedCarrier = props.load !== undefined && props.load !== null && props.load.assignedCarrier !== undefined && props.load.assignedCarrier !== null ? props.load.assignedCarrier : null;
    const isAssignedCarrierActive = props.load !== undefined && props.load !== null && props.load.assignedCarrier !== undefined && props.load.assignedCarrier !== null && props.load.assignedCarrier.isActive !== undefined && props.load.assignedCarrier.isActive !== null && props.load.assignedCarrier.isActive === true ? true : false;
    const isAssignedCarrierVerified = props.load !== undefined && props.load !== null && props.load.assignedCarrier !== undefined && props.load.assignedCarrier !== null && props.load.assignedCarrier.isVerified !== undefined && props.load.assignedCarrier.isVerified !== null && props.load.assignedCarrier.isVerified === true ? true : false;
    const entityType = props.entityType;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];
    const currentStatus = props.currentStatus;
    const invoiceLineItems = props.invoiceLineItems;

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const extra = (entityType === "STAFF" || (entityType === "SHIPPER" && load.serviceType === 'TMS')) ? (
        <AddEditChangeRemoveButtons
            showAdd={load.assignedCarrierId || currentStatus === 'CANCELLED' ? false : true} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
            showEdit={load.assignedCarrierId ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={(load.assignedCarrierId ? true : false) && (load.loadStatus === "PENDING" || load.loadStatus === "APPROVED" || load.loadStatus === "PENDING_RATE_CONFIRMATION")} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); toggleChangeEntity(); }}
            showRemove={(load.assignedCarrierId ? true : false) && (load.loadStatus === "PENDING" || load.loadStatus === "APPROVED" || load.loadStatus === "PENDING_RATE_CONFIRMATION")} removeText={"Remove " + singularEntityName} removeAction={(e) => { e.stopPropagation(); toggleRemoveEntity(); }}
        />
    ) : null;

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier
                cancel={toggleNewEntity}
                load={load}
                stops={stops}
                invoiceLineItems={invoiceLineItems}
            />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadCarrier cancel={toggleChangeEntity} load={load} history={props.history}/>
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditCarrier cancel={toggleEditEntity} record={load.assignedCarrier} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadCarrier cancel={toggleRemoveEntity} load={load} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Carrier Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="ACCOUNT" entityId={load.assignedCarrierId} account={load.assignedCarrier} documentList={documents.filter(document => document.entityId === load.assignedCarrierId)} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} loadId={load.ic} />
            {isAssignedCarrierVerified === false && entityType === "STAFF" ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(load.assignedCarrierId); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the carrier is updated
            toggleDocumentsModal();
            // fetch the load to refresh the load with the updated carrier information
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    }, [isLoading]);

    const renderCarrierDetails = () => {
        if (load.assignedCarrier !== undefined && load.assignedCarrier !== null && load.assignedCarrier.carrier !== undefined && load.assignedCarrier.carrier !== null) {
            return (
                <>
                    <DataRow title='Carrier ID' value={load.assignedCarrier.carrier.irisId} dataType='String' />
                    <DataRow title='Name' value={load.assignedCarrier.name} dataType='String' />
                    <DataRow title='DOT #' value={load.assignedCarrier.carrier.dotNum} dataType='String' />
                    <DataRow title='MC #' value={load.assignedCarrier.carrier.mcNum} dataType='String' />
                    {load.assignedCarrier.carrier.pointOfContact !== undefined && load.assignedCarrier.carrier.pointOfContact !== null && load.assignedCarrier.carrier.pointOfContact.name !== undefined && load.assignedCarrier.carrier.pointOfContact.name !== null ? <DataRow title="Operations Contact" value={load.assignedCarrier.carrier.pointOfContact.name} /> : null}
                    {load.assignedCarrier.carrier.pointOfContact !== undefined && load.assignedCarrier.carrier.pointOfContact !== null && load.assignedCarrier.carrier.pointOfContact.phone !== undefined && load.assignedCarrier.carrier.pointOfContact.phone !== null ? <DataPhoneNumberRow title="Operations Phone" phone={load.assignedCarrier.carrier.pointOfContact.phone} phoneExt={load.assignedCarrier.carrier.pointOfContact.phoneExt} /> : null}
                    {load.assignedCarrier.carrier.pointOfContact !== undefined && load.assignedCarrier.carrier.pointOfContact !== null && load.assignedCarrier.carrier.pointOfContact.email !== undefined && load.assignedCarrier.carrier.pointOfContact.email !== null ? <DataRow title="Operations Email" value={load.assignedCarrier.carrier.pointOfContact.email} /> : null}
                    {load.assignedCarrier.afterHoursPhone !== undefined && load.assignedCarrier.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone" phone={load.assignedCarrier.afterHoursPhone} phoneExt={load.assignedCarrier.afterHoursPhoneExt} /> : null}
                    {load.assignedCarrier.carrier.smsBasic && load.assignedCarrier.carrier.smsBasic.weightedAvgSMSScore && <DataRow title='SMS Basic Avg' value={load.assignedCarrier.carrier.smsBasic.weightedAvgSMSScore} dataType='String' />}
                    {/* <DataRow title='IRIS Rating' value={props.irisRating} dataType='String' /> */}
                    {entityType === "STAFF" &&
                        <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                    }
                    {(entityType === "STAFF" || entityType === "CARRIER") && (
                        <>
                            <DataContactInfoRow valueStyle={{ marginLeft: 16 }} title="Accounts Receivable" contact={load.assignedCarrier.carrier.accountsReceivable} />
                            <DataAddressRow valueStyle={{ marginLeft: 16 }} title="Accounts Receivable Address" address={load.assignedCarrier.carrier.shippingAddress ? load.assignedCarrier.carrier.shippingAddress : load.assignedCarrier.shippingAddress} />
                        </>
                    )}
                    {isAssignedCarrierVerified === false && entityType === "STAFF" ? (<Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Verify Account</Button>) : null}
                </>
            );
        } else {
            return <i>No Carrier Assigned.</i>
        }
    };

    if (load !== undefined && load !== null && (entityType === 'STAFF' || entityType === 'CARRIER' || (entityType === 'SHIPPER' && load.serviceType === 'TMS') ||
        load.loadStatus === 'BOOKED' || load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' ||
        load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED')) {
        return (
            <>
                <Card title={props.title} bordered={true} className={classes.card} style={assignedCarrier !== undefined && assignedCarrier !== null && isAssignedCarrierActive === true && isAssignedCarrierVerified === true ? props.style : { ...props.style, ...missingEntityStyle }} headStyle={assignedCarrier !== undefined && assignedCarrier !== null && isAssignedCarrierActive === true && isAssignedCarrierVerified === true ? props.headStyle : { ...props.headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {renderCarrierDetails()}
                    </Spin>
                </Card>
                {editEntityComponents}
                {newEntityComponents}
                {changeEntityComponents}
                {removeEntityComponents}
                {documentsComponent}
            </>
        );
    } else {
        return null;
    }
};

export default LoadCarrierCard;