import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import classes from './States.module.scss';
import DataTable from '../../components/DataTable/DataTable';

const States = props => {
    const singularEntityName = "State";
    const pluralEntityName = "States";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.states.isLoading);
    const records = useSelector(state => state.states.records);
    const pagination = useSelector(state => state.states.pagination);

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20
        };

        dispatch(actionCreators.fetchStates(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchStatesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchStates(searchParams));
    };

    const columns = [
        {
            title: 'State',
            dataIndex: 'stateName',
            key: 'stateName',
        },
        {
            title: 'State Abbr',
            dataIndex: 'stateAbbr',
            key: 'stateAbbr',
        },
        {
            title: 'Country',
            dataIndex: 'countryName',
            key: 'countryName',
        },
        {
            title: 'Country Abbr',
            dataIndex: 'countryAbbr',
            key: 'countryAbbr',
        }
    ];

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        />
    );
};

export default withRouter(States);
