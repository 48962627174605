import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearUpdateRecordError,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecord,
    updateRecordSuccess,
    clearAddRecordLoading,
    clearUpdateRecordLoading,
    updateObject,
    cancelAddRecord,
    cancelUpdateRecord,
    removeRecord,
    addObjectToTop
} from "../utility";

const addNotificationToTop = (state, newRecord) => {
    // check to see if record already exists in the state, update if so, insert if not
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    let totalCount = copyOfState.totalCount1;
    let unreadCount = copyOfState.unreadCount;

    const index = existingList.findIndex(obj => obj.id === newRecord.id);
    let updatedRecords = [];
    // only update if the record exists in the list
    if (index !== -1) {
        updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            newRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];

        return updateObject(state, {
            records: updatedRecords
        });
    } else {
        updatedRecords = addObjectToTop(existingList, newRecord);

        return updateObject(state, {
            records: updatedRecords,
            totalCount: totalCount + 1,
            unreadCount: unreadCount + 1
        });
    }
};

export const clearNotifications = (state) => {
    return updateObject(state, {
        records: [],
        isLoading: false,
        error: null,
        currentPage: 0,
        totalPages: 0,
        pageSize: 10
    });
};

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
    unreadCount: 0,
    pageSize: 10
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_NOTIFICATIONS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_NOTIFICATIONS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_NOTIFICATIONS: return clearNotifications(state);
        case actionTypes.ADD_NOTIFICATION: return addNotificationToTop(state, action.payload);
        case actionTypes.ADD_NOTIFICATION_START: return addRecordStart(state);
        case actionTypes.ADD_NOTIFICATION_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_NOTIFICATION_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_NOTIFICATION_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.ADD_NOTIFICATION_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.CANCEL_ADD_NOTIFICATION: return cancelAddRecord(state);
        case actionTypes.UPDATE_NOTIFICATION: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_NOTIFICATION_START: return updateRecordStart(state);
        case actionTypes.UPDATE_NOTIFICATION_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_NOTIFICATION_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_NOTIFICATION_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.UPDATE_NOTIFICATION_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.CANCEL_UPDATE_NOTIFICATION: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_NOTIFICATION: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;