import React from 'react';
import { Button } from "antd";
import classes from './DispatchScheduleLoadCard.module.scss';
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const DispatchScheduleLoadCard = props => {
    //#region props and constants

    const load = props.load !== undefined && props.load !== null ? props.load : {};
    const displayAsText = props.displayAsText !== undefined && props.displayAsText !== null && props.displayAsText === true ? true : false;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;
    const setSelectedLoadToDispatchId = props.setSelectedLoadToDispatchId;
    const setShowDispatchLoadModal = props.setShowDispatchLoadModal;
    const setShowCancelLoadModal = props.setShowCancelLoadModal;
    const setAssetSideBar = props.setAssetSideBar;
    const setDriverSideBar = props.setDriverSideBar;
    const style = props.style;

    const hasTeamDriving = load !== undefined && load !== null && !isEmpty(load) && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;
    const hasDrivers = load !== undefined && load !== null && !isEmpty(load) && load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0 && ((hasTeamDriving === true && load.driverIds.length === 2) || (hasTeamDriving === false && load.driverIds.length === 1)) ? true : false;
    const hasAssets = load !== undefined && load !== null && !isEmpty(load) && load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? true : false;

    //#endregion
    //#region useDispatch and useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBundledLoad === true && load !== undefined && load !== null && !isEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    //#endregion

    if (load !== undefined && load !== null && !isEmpty(load)) {
        if (displayAsText === true) {
            if (isBundledLoad === true) {
                if (load.loadStatus === 'CANCELLED' || load.isDeleted === true) {
                    return (
                        <span>Cancelled</span>
                    );
                } else if (load.loadStatus === 'BOOKED') {
                    if (hasDrivers === false || hasAssets === false) {
                        if ((load.driverIds === undefined || load.driverIds === null || load.driverIds.length === 0) && (load.assetIds === undefined || load.assetIds === null || load.assetIds.length === 0) && (load.isParentLoad === false || (load.isParentLoad === true && (load.childLoadInitCount === undefined || load.childLoadInitCount === null || load.childLoadInitCount === 0)))) {
                            return (
                                <div>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setShowCancelLoadModal(true); }}>Cancel Load <FontAwesomeIcon className="anticon" icon={faTimesCircle} style={{ marginLeft: 8 }} /></Button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setShowDispatchLoadModal(true); }}>Dispatch <FontAwesomeIcon className="anticon" icon={faArrowCircleRight} style={{ marginLeft: 8, color: '#16588E' }} /></Button>
                            </div>
                        );
                    }
                } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                    return (
                        <span>Dispatched</span>
                    );
                } else {
                    return null;
                }
            } else {
                if (load.loadStatus === 'CANCELLED' || load.isDeleted === true) {
                    return (
                        <span>Cancelled</span>
                    );
                } else if (load.loadStatus === 'BOOKED') {
                    if (hasDrivers === false || hasAssets === false) {
                        if ((load.driverIds === undefined || load.driverIds === null || load.driverIds.length === 0) && (load.assetIds === undefined || load.assetIds === null || load.assetIds.length === 0) && (load.isParentLoad === false || (load.isParentLoad === true && (load.childLoadInitCount === undefined || load.childLoadInitCount === null || load.childLoadInitCount === 0)))) {
                            return (
                                <div>
                                    <Button type="link" onClick={() => { setShowCancelLoadModal(true); }}>Cancel Load <FontAwesomeIcon className="anticon" icon={faTimesCircle} style={{ marginLeft: 8 }} /></Button>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    } else {
                        return (
                            <div>
                                <Button type="link" onClick={() => { setShowDispatchLoadModal(true); }}>Dispatch <FontAwesomeIcon className="anticon" icon={faArrowCircleRight} style={{ marginLeft: 8, color: '#16588E' }} /></Button>
                            </div>
                        );
                    }
                } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                    return (
                        <span>Dispatched</span>
                    );
                } else {
                    return null;
                }
            }
        } else {
            if (isBundledLoad === true) {
                if (load.loadStatus === 'CANCELLED' || load.isDeleted === true) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} />}
                                buttonText={<span>Cancelled</span>}
                                onClick={() => { return; }}
                                disabled={true}

                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#55babb" }}
                            />
                        </div>
                    );
                } else if (load.loadStatus === 'BOOKED') {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${(hasDrivers === false || hasAssets === false) ? classes.grayButtonSolidBorder : classes.blueButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight} style={{ color: "55babb" }} />}
                                buttonText={<span>Dispatch</span>}
                                onClick={() => { onSelectLoadToDispatch(); setShowDispatchLoadModal(true); }}
                                disabled={(hasDrivers === false || hasAssets === false) || ((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}
                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                            />
                        </div>
                    );
                } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faCheckCircle} style={{color: "#55babb"}} />}
                                buttonText={<span>Dispatched</span>}
                                onClick={() => { return; }}
                                disabled={true}

                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#55babb" }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            } else {
                if (load.loadStatus === 'CANCELLED' || load.isDeleted === true) {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${classes.yellowButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faCheckCircle} />}
                                buttonText={<span>Cancelled</span>}
                                onClick={() => { return; }}
                                disabled={true}

                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#55babb" }}
                            />
                        </div>
                    );
                } else if (load.loadStatus === 'BOOKED') {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button} ${(hasDrivers === false || hasAssets === false) ? classes.grayButtonSolidBorder : classes.blueButton}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight}  style={{color: "#55babb"}} />}
                                buttonText={<span>Dispatch</span>}
                                onClick={() => { setShowDispatchLoadModal(true); }}
                                disabled={(hasDrivers === false || hasAssets === false) || ((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#55babb" }}
                            />
                        </div>
                    );
                } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                    return (
                        <div style={style}>
                            <ActionButton
                                iconPosition="right"
                                buttonClassName={`${classes.button}`}
                                buttonIconClassName={classes.iconContainer}
                                buttonTextClassName={classes.textContainer}
                                buttonIcon={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faCheckCircle} />}
                                buttonText={<span>Dispatched</span>}
                                onClick={() => { return; }}
                                disabled={true}

                                buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#55babb" }}
                            />
                        </div>
                    );
                } else {
                    return null;
                }
            }
        }
    } else {
        return null;
    }
};

export default DispatchScheduleLoadCard;
