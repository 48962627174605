import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Row, Col, Spin, Select, Empty, Checkbox } from 'antd';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import Header from '../Header/Header';

const { Option } = Select;

const Agenda = props => {
    //#region props and constants

    const accountId = props.accountId;
    const accountUserId = props.accountUserId;
    const shiftId = props.shiftId;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isLoading);
    const error = useSelector(state => state.scheduledShifts.error);
    const records = useSelector(state => state.scheduledShifts.records);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const shifts = useSelector(state => state.shifts.records);
    const isLoadingShifts = useSelector(state => state.shifts.isLoading);
    const accountUsers = useSelector(state => state.accountUsers.records);
    const isLoadingAccountUsers = useSelector(state => state.accountUsers.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const calendarRef = useRef(null);

    //#endregion
    //#region useStates

    const [selectedShiftIds, setSelectedShiftIds] = useState(null);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    const [driversOnly, setDriversOnly] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            sort: 'startDateTime',
            order: 'asc',
            isDeleted: false
        };

        if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
            searchParams.accountUserIds = selectedAccountUserIds;
        } else if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0 && driversOnly === true) {
            searchParams.accountUserIds = accountUsers.filter(i => i.isDriver === true).map(i => i.id);
        }

        if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
            searchParams.shiftId = selectedShiftIds;
        }

        if (selectedAccountId !== undefined && selectedAccountId !== null) {
            searchParams.accountId = selectedAccountId;
        }

        dispatch(actionCreators.fetchScheduledShifts(searchParams));
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if ((selectedAccountId !== undefined && selectedAccountId !== null)) {
            dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShifts({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        if (accounts !== undefined && accounts !== null && accounts.length > 0) {
            if (accountId !== undefined && accountId !== null) {
                setSelectedAccountId(accountId);
                let account = accounts.find(i => i.id === accountId);
                setSelectedAccount(account);
            } else if (entityType !== 'STAFF' && entityId !== undefined && entityId !== null) {
                setSelectedAccountId(entityId);
                let account = accounts.find(i => i.id === entityId);
                setSelectedAccount(account);
            }
        } else {
            setSelectedAccount(null);
        }
    }, [accountId, entityType, entityId, accounts]);

    useMemo(() => {
        if (accountUserId !== undefined && accountUserId !== null) {
            setSelectedAccountUserIds([accountUserId]);
        } else {
            setSelectedAccountUserIds([]);
        }
    }, [accountUserId]);

    useMemo(() => {
        if (shiftId !== undefined && shiftId !== null) {
            setSelectedShiftIds([shiftId]);
        } else {
            setSelectedShiftIds([]);
        }
    }, [shiftId]);

    useMemo(() => {
        if (selectedAccountId !== undefined && selectedAccountId !== null && selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedShiftIds !== undefined && selectedShiftIds !== null) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserIds, selectedShiftIds, driversOnly, accountUsers]);

    //#endregion

    if (records) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Header
                            title="Agenda"
                            footer={
                                <div><span style={{ fontSize: 16, fontWeight: 500 }}>The Agenda view is read-only. You cannot add shifts, edit shifts, or see details of shifts from this view.</span></div>
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        {((accountUserId === undefined || accountUserId === null) || (shiftId === undefined || shiftId === null) || ((accountId === undefined || accountId === null) && entityType === 'STAFF')) ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <span><strong>Filters: </strong></span>
                                    </Col>
                                </Row>
                                <Row>
                                    {((accountId === undefined || accountId === null) && entityType === 'STAFF') ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {(shiftId === undefined || shiftId === null) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {(accountUserId === undefined || accountUserId === null) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Employee"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                            >
                                                {driversOnly === true ? accountUsers.filter(i => i.isDriver === true).map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>) : accountUsers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                                            </Select>
                                            {(entityType === 'CARRIER' || (selectedAccount !== undefined && selectedAccount !== null && selectedAccount.isCarrier === true)) ? (
                                                <div>
                                                    <Checkbox
                                                        onChange={e => { setDriversOnly(e.target.checked); return e.target.checked; }}
                                                        checked={driversOnly}
                                                        name={'driversOnly'}
                                                    >Drivers Only</Checkbox>
                                                </div>
                                            ) : null}
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        ) : null}
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <FullCalendar
                            ref={calendarRef}
                            plugins={[interactionPlugin, listPlugin]}
                            headerToolbar={{
                                left: 'today prev,next',
                                center: 'title',
                                right: 'listMonth,listWeek'
                            }}
                            views={{
                                listWeek: { buttonText: 'weekly', slotDuration: { days: 1 }, selectable: false },
                                listMonth: { buttonText: 'monthly', slotDuration: { days: 1 }, selectable: false },
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            eventTimeFormat={{ hour: '2-digit', minute: '2-digit', hour12: false }}
                            initialView='listMonth'
                            editable={false}
                            dayMaxEvents={false}
                            dayMaxEventRows={false}
                            nextDayThreshold={'06:00:00'}
                            weekends={true}
                            events={records}
                            contentHeight={"auto"}
                        />
                    </Col>
                </Row>
            </Spin>
        );
    } else {
        return null;
    }
};

export default Agenda;