import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { FileTextOutlined, PaperClipOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Menu, Dropdown } from 'antd';
import EditClaim from "../../components/EditClaim/EditClaim";
import NewClaim from "../../components/NewClaim/NewClaim";
import classes from './Claims.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faScroll } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import DocumentList from '../../components/DocumentList/DocumentList';
import StringFormatter from '../../shared/stringFormatter';
import NoteList from '../../components/NoteList/NoteList';

const stringFormatter = new StringFormatter();

const Claims = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Claim";
    const pluralEntityName = "Claims";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.claims.isLoading);
    const records = useSelector(state => state.claims.records);
    const pagination = useSelector(state => state.claims.pagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const openClaimView = (id) => {
        props.history.push({ pathname: `/claims/${id}` }, {
            previousPageTitle: "Claims",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        dispatch(actionCreators.fetchClaims(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchClaimsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchClaims(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>

                <Menu.Item key="manageDocuments" onClick={() => { setSelectedRecord(record); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="manageNotes" onClick={() => { setSelectedRecord(record); toggleNotesModal(); }}>
                    <FileTextOutlined />
                    <span>Manage Notes</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faScroll} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openClaimView(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Claim ID',
            dataIndex: 'irisId',
            key: 'irisId',
        },
        {
            title: 'Claim Status',
            dataIndex: 'claimStatus',
            key: 'claimStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("ClaimStatus", text, null); },
        },
        {
            title: 'Is Against Carrier',
            dataIndex: 'isAgainstCarrier',
            key: 'isAgainstCarrier',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Against Shipper',
            dataIndex: 'isAgainstShipper',
            key: 'isAgainstShipper',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Is Claim Against Insurance',
            dataIndex: 'isInsuranceClaim',
            key: 'isInsuranceClaim',
            render: (text, record) => { return stringFormatter.toYesNo(text); },
        },
        {
            title: 'Claim Against',
            dataIndex: ['account', 'name'],
            key: 'account.name',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackagingCount", text, record.unitPackaging); },
        },
        {
            title: 'BOL Status',
            dataIndex: 'bolStatus',
            key: 'bolStatus',
            render: (text, record) => { return stringFormatter.toFormattedString("BOLStatus", text, null); },
        },
        {
            title: 'Load ID',
            dataIndex: 'irisLoadId',
            key: 'irisLoadId',
        },
        {
            title: 'Adjustment Amount',
            dataIndex: 'adjustment',
            key: 'adjustment',
            render: (text, record) => { return stringFormatter.toFormattedMoney(text); },
        },
        {
            title: 'BOL #',
            dataIndex: 'bolNumber',
            key: 'bolNumber',
        }
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewClaim cancel={toggleNewEntity} load={null} loadId={null} irisLoadId={null} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditClaim cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Claim Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="CLAIM" entityId={selectedRecord.id} />
        </Modal>
    );

    const notesComponent = (
        <Modal
            title="Claim Notes"
            visible={showNotesModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <NoteList entityType="CLAIM" entityId={selectedRecord.id} />
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {documentsComponent}
            {notesComponent}
        </DataTable>
    );
};

export default withRouter(Claims);
