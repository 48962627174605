import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getStates} from "./orchestrator";
import logger from "../../shared/logger";

//#region States Functions

export const fetchStatesStart = () => {
    return {
        type: actionTypes.FETCH_STATES_START
    }
};

export const fetchStatesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_STATES_SUCCESS,
        payload: payload
    }
};

export const fetchStatesFail = (payload) => {
    return {
        type: actionTypes.FETCH_STATES_FAIL,
        payload: payload
    }
};

export const clearStates = () => {
    return {
        type: actionTypes.CLEAR_STATES
    }
};

export const addStateStart = () => {
    return {
        type: actionTypes.ADD_STATE_START
    }
};

export const addStateSuccess = () => {
    return {
        type: actionTypes.ADD_STATE_SUCCESS
    }
};

export const addStateFail = (payload) => {
    return {
        type: actionTypes.ADD_STATE_FAIL,
        payload: payload
    }
};

export const addStateErrorClear = () => {
    return {
        type: actionTypes.ADD_STATE_ERROR_CLEAR
    }
};

export const addStateLoadingClear = () => {
    return {
        type: actionTypes.ADD_STATE_LOADING_CLEAR
    }
};

export const updateStateStart = () => {
    return {
        type: actionTypes.UPDATE_STATE_START
    }
};

export const updateStateSuccess = () => {
    return {
        type: actionTypes.UPDATE_STATE_SUCCESS
    }
};

export const updateStateFail = (payload) => {
    return {
        type: actionTypes.UPDATE_STATE_FAIL,
        payload: payload
    }
};

export const updateStateErrorClear = () => {
    return {
        type: actionTypes.UPDATE_STATE_ERROR_CLEAR
    }
};

export const updateStateLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_STATE_LOADING_CLEAR
    }
};

export const cancelAddState = () => {
    return {
        type: actionTypes.CANCEL_ADD_STATE
    }
};

export const cancelUpdateState = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_STATE
    }
};

export const insertState = (payload) => {
    return {
        type: actionTypes.ADD_STATE,
        payload: payload
    }
};

export const changeState = (payload) => {
    return {
        type: actionTypes.UPDATE_STATE,
        payload: payload
    }
};

export const changeSingleState = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_STATE,
        payload: payload
    }
};

export const removeState = (payload) => {
    return {
        type: actionTypes.REMOVE_STATE,
        payload: payload
    }
};

//#endregion

//#region States Methods

export const fetchStates = (payload) => {
    return async (dispatch, getState) => {
        const statesPath = '/states';
        try {
            dispatch(fetchStatesStart());

            const state = getState();
            const statesState = {...state.states};
            let pagination = {...statesState.pagination};
            let searchParams = {...statesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const statesRes = await axiosAuthenticated.get(statesPath, {params: {...searchParams}});
            if (statesRes && statesRes.status === 200) {
                const states = statesRes.data.data;

                // Read total count from server
                pagination.total = statesRes.data.totalCount;
                pagination.current = statesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchStatesSuccess({
                    records: states,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchStatesFail({error: error.message}));
        }
    }
};

export const addState = (payload) => {
    return async (dispatch) => {
        const statesPath = '/states';
        try {
            dispatch(addStateStart());

            const statesRes = await axiosAuthenticated.post(statesPath, {...payload});
            if (statesRes && statesRes.status === 201) {
                let newState = statesRes.data;

                dispatch(insertState(newState));

                // refresh the states orchestrator
                dispatch(getStates(true));

                dispatch(addStateSuccess());
                dispatch(addStateLoadingClear());
                dispatch(addStateErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addStateFail({error: error.message}));
        }
    }
};

export const updateState = (stateId, payload) => {
    return async (dispatch) => {
        const statesPath = '/states';
        try {
            dispatch(updateStateStart());

            // there is no isDeleted field for states so only updating states here
            const statesRes = await axiosAuthenticated.put(statesPath + `/${stateId}`, {...payload});
            if (statesRes && statesRes.status === 200) {
                let updatedState = statesRes.data;

                dispatch(changeState(updatedState));
                dispatch(changeSingleState(updatedState));

                // refresh the states orchestrator
                dispatch(getStates(true));

                dispatch(updateStateSuccess());
                dispatch(updateStateLoadingClear());
                dispatch(updateStateErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateStateFail({error: error.message}));
        }
    }
};

//#endregion