import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreators from "../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faRedoAlt, faExternalLinkAlt, faPallet, faRoute, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import LoadsList from '../../components/LoadsList/LoadsList';
import {
    Col,
    InputNumber,
    Modal,
    Row,
    Dropdown,
    Button,
    Menu,
    Spin,
    Drawer,
    Card,
    Checkbox,
    Alert,
    Empty,
    Tooltip
} from 'antd';
import { isEmpty } from 'lodash';
import { CloseOutlined, DownOutlined, FilterFilled, InfoOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import TitleBlockWithIcon from '../../components/TitleBlockWithIcon/TitleBlockWithIcon';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataAtByRow from '../../components/DataAtByRow/DataAtByRow';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import LoadMap from "../../components/LoadMap/LoadMap";
import classes from './Dispatch.module.scss';
import LoadDocuments from '../../components/LoadDocuments/LoadDocuments';
import MomentDate from '../../shared/dateFormatter';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import DispatchDriverCard from '../../components/DispatchDriverCard/DispatchDriverCard';
import moment from 'moment';
import DispatchAssetCard from '../../components/DispatchAssetCard/DispatchAssetCard';
import DispatchScheduleLoadCard from '../../components/DispatchScheduleLoadCard/DispatchScheduleLoadCard';
import DataTable from '../../components/DataTable/DataTable';
import DispatchSelectedLoadCard from '../../components/DispatchSelectedLoadCard/DispatchSelectedLoadCard';
import FormItem from '../../components/FormItem/FormItem';
import Form from '../../components/Form/Form';
import { useForm, FormProvider } from 'react-hook-form';
import FormButtons from '../../components/FormButtons/FormButtons';

const momentDate = new MomentDate();
const stringFormatter = new StringFormatter();

const Dispatch = props => {
    //#region constants

    const carrierId = props.carrierId;

    //#endregion
    //#region useForms

    const methods1 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const loadLists = useSelector(state => state.loadLists.loadLists);

    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);
    const stops = useSelector(state => state.loadStops.records);
    const isLoadingStops = useSelector(state => state.loadStops.isLoading);
    const documents = useSelector(state => state.documents.records);
    const isLoadingDocuments = useSelector(state => state.documents.isLoading);
    const assets = useSelector(state => state.assets.records);
    const isLoadingAssets = useSelector(state => state.assets.isLoading);
    const drivers = useSelector(state => state.accountUsers.records);
    const isLoadingDrivers = useSelector(state => state.accountUsers.isLoading);
    const shifts = useSelector(state => state.shifts.records);
    const isLoadingShifts = useSelector(state => state.shifts.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const isLoadingLoadUpdate = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorLoadUpdate = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);
    const bundledLoads = useSelector(state => state.loads.bundledLoads);
    const isBundledLoadsLoading = useSelector(state => state.loads.isBundledLoadsLoading);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showEntity, setShowEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showListFilterDrawer, setShowListFilterDrawer] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [filters, setFilters] = useState({});
    const [dispatchCarrierId, setDispatchCarrierId] = useState(null);
    const [loadsToDispatch, setLoadsToDispatch] = useState([]);
    const [driverLoads, setDriverLoads] = useState(null);
    const [isLoadingDriverLoads, setIsLoadingDriverLoads] = useState(false);
    const [scheduledShifts, setScheduledShifts] = useState(null);
    const [isLoadingScheduledShifts, setIsLoadingScheduledShifts] = useState(false);
    const [driverSideBar, setDriverSideBar] = useState(null);
    const [assetSideBar, setAssetSideBar] = useState(null);
    const [selectedLoadToDispatchId, setSelectedLoadToDispatchId] = useState(null);
    const [showDispatchLoadModal, setShowDispatchLoadModal] = useState(false);
    const [overrideScheduled, setOverrideScheduled] = useState(false);
    const [showCancelLoadModal, setShowCancelLoadModal] = useState(false);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: "Dispatch",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: null,
                loadLaneId: null,
                duplicateLoadId: null,
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    //#endregion
    //#region refreshes

    const refreshLoad = () => {
        if (load !== undefined && load !== null) {
            dispatch(actionCreators.fetchLoadWithIncludes(load.id));
        }
    };

    const refreshList = () => {
        let searchParams = {
            page: 1,
            size: 10,
            sort: 'pickUpDateTime',
            order: 'asc',
            isParentLoad: true,
            'loadStatus:or': 'BOOKED',
            'childLoads.status:or': 'BOOKED'
        };

        if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;
        } else if (entityType === "STAFF" && carrierId !== undefined && carrierId !== null) {
            searchParams.assignedCarrierId = carrierId;
        }

        dispatch(actionCreators.fetchLoadList('carrier-dispatch', searchParams));
    };

    const retrieveMore = () => {
        let currentPage = 1;
        if (loadLists !== undefined && loadLists !== null && !isEmpty(loadLists)) {
            if (loadLists['carrier-dispatch'] !== undefined && loadLists['carrier-dispatch'] !== null && !isEmpty(loadLists['carrier-dispatch'])) {
                const copyOfLoadList = { ...loadLists['carrier-dispatch'] };
                currentPage = copyOfLoadList.currentPage ? copyOfLoadList.currentPage : 1;
            }
        }

        let searchParams = {
            page: currentPage + 1,
            size: 10,
            sort: 'pickUpDateTime',
            order: 'asc',
            isParentLoad: true,
            'loadStatus:or': 'BOOKED',
            'childLoads.status:or': 'BOOKED'
        };

        if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;
        } else if (entityType === "STAFF" && carrierId !== undefined && carrierId !== null) {
            searchParams.assignedCarrierId = carrierId;
        }

        dispatch(actionCreators.fetchMoreLoadList('carrier-dispatch', searchParams));
    };

    //#endregion
    //#region fetch methods

    const getDocuments = () => {
        if (load !== null && load !== undefined && stops !== null && stops !== undefined && stops.length > 0) {
            let ids = [];
            ids.push(load.id);

            if (stops !== null && stops !== undefined && stops.length > 0) {
                stops.forEach(stop => {
                    ids.push(stop.id);
                });
            }

            dispatch(actionCreators.fetchBulkDocuments({ entityId: ids }));
        }
    };

    const getDriverLoads = () => {
        if (drivers !== undefined && drivers !== null && drivers.length > 0 && dispatchCarrierId !== undefined && dispatchCarrierId !== null) {
            let driverIds = drivers.map((driver) => { return driver.id });
            let searchParams = {
                page: 1,
                size: 100000,
                isDeleted: false,
                eta: false,
                driverIds: driverIds,
                assignedCarrierId: dispatchCarrierId,
                loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
            };
            setIsLoadingDriverLoads(true);
            axiosAuthenticated.post('/orch/loads', { ...searchParams }).then((loadsRes) => {
                setDriverLoads(loadsRes.data.data);
                setIsLoadingDriverLoads(false);
            }).catch((error) => {
                console.log(error.message);
                setDriverLoads(null);
                setIsLoadingDriverLoads(false);
            });
        } else {
            setDriverLoads([]);
        }
    };

    const getAssetsLoads = async (assetIds = []) => {
        let assetLoads = [];
        if (assetIds !== undefined && assetIds !== null && assetIds.length > 0 && dispatchCarrierId !== undefined && dispatchCarrierId !== null) {
            let searchParams = {
                page: 1,
                size: 100000,
                isDeleted: false,
                eta: false,
                assignedCarrierId: dispatchCarrierId,
                assetIds: assetIds,
                loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
            };

            const loadsRes = await axiosAuthenticated.post('/orch/loads', { params: { ...searchParams } });

            if (loadsRes && loadsRes.status === 200) {
                assetLoads = loadsRes.data.data;
            }
        }

        return assetLoads;
    };

    const getScheduledShifts = (selectedLoad, selectedCarrier) => {
        if (selectedLoad !== undefined && selectedLoad !== null && !isEmpty(selectedLoad) && selectedCarrier !== undefined && selectedCarrier !== null) {
            let searchParams = {
                page: 1,
                size: 10000,
                sort: 'startDateTime',
                order: 'asc',
                accountId: selectedCarrier,
                isDeleted: false
            };

            searchParams["endDateTime:gte"] = selectedLoad.pickUpDateTime;
            searchParams["startDateTime:lte"] = selectedLoad.pickUpDateTime;

            setIsLoadingScheduledShifts(true);
            axiosAuthenticated.post('/scheduledShifts/bulk/get', { ...searchParams }).then((scheduledShiftsRes) => {
                setScheduledShifts(scheduledShiftsRes.data.data);
                setIsLoadingScheduledShifts(false);
            }).catch((error) => {
                console.log(error.message);
                setScheduledShifts(null);
                setIsLoadingScheduledShifts(false);
            });
        } else {
            setScheduledShifts([]);
        }
    };

    //#endregion
    //#region toggles

    const openShowEntity = () => {
        setShowEntity(true);
    };

    const closeShowEntity = () => {
        setShowEntity(false);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleListFilterDrawer = () => {
        setShowListFilterDrawer(!showListFilterDrawer);
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region submits and cancels

    const onDispatchLoad = () => {
        if (selectedLoadToDispatchId !== undefined && selectedLoadToDispatchId !== null && loadsToDispatch !== undefined && loadsToDispatch !== null && loadsToDispatch.length > 0) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (selectedLoadToDispatch !== undefined && selectedLoadToDispatch !== null && !isEmpty(selectedLoadToDispatch)) {
                dispatch(actionCreators.dispatchBundledLoad(selectedLoadToDispatch));
            }
        } else if (load !== undefined && load !== null && !isEmpty(load)) {
            dispatch(actionCreators.sendLoadEvent({
                loadId: load.id,
                eventType: 'LOAD_SCHEDULED'
            }));
        }
    };

    const onCancelLoad = () => {
        if (selectedLoadToDispatchId !== undefined && selectedLoadToDispatchId !== null && loadsToDispatch !== undefined && loadsToDispatch !== null && loadsToDispatch.length > 0) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            let parentLoad = loadsToDispatch.find(i => i.isParentLoad === true);
            if (selectedLoadToDispatch !== undefined && selectedLoadToDispatch !== null && !isEmpty(selectedLoadToDispatch) && parentLoad !== undefined && parentLoad !== null && !isEmpty(parentLoad)) {
                dispatch(actionCreators.deleteBundledLoad(selectedLoadToDispatch.id, selectedLoadToDispatch, parentLoad.id, parentLoad));
            }
        } else if (load !== undefined && load !== null && !isEmpty(load)) {
            dispatch(actionCreators.deleteLoad(load.id, load));
        }
    };

    const onDoneDispatchLoad = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        setShowDispatchLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    const onCancelDispatchLoad = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        setShowDispatchLoadModal(false);
    };

    const onDoneCancelLoad = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        setShowCancelLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    const onCancelCancelLoad = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        setShowCancelLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (showDispatchLoadModal === true && isLoadingLoadUpdate !== null && isLoadingLoadUpdate === false && errorLoadUpdate === null) {
            onDoneDispatchLoad();
        }
    }, [isLoadingLoadUpdate, errorLoadUpdate, showDispatchLoadModal]);

    useMemo(() => {
        if (showCancelLoadModal === true && isLoadingLoadUpdate !== null && isLoadingLoadUpdate === false && errorLoadUpdate === null) {
            onDoneCancelLoad();
        }
    }, [isLoadingLoadUpdate, errorLoadUpdate, showCancelLoadModal]);

    useEffect(() => {
        if (carrierId !== undefined && carrierId !== null) {
            setDispatchCarrierId(carrierId);
        } else if (entityType === "CARRIER") {
            setDispatchCarrierId(entityId);
        }
    }, [carrierId, entityType, entityId]);

    useEffect(() => {
        if (dispatchCarrierId !== undefined && dispatchCarrierId !== null) {
            if (accounts && accounts.length > 0 && trailerTypes && trailerTypes.length > 0) {
                dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, accountId: dispatchCarrierId, isDriver: true, isDeleted: false }));
                dispatch(actionCreators.fetchShifts({ page: 1, size: 100000, accountId: dispatchCarrierId, isDeleted: false }));
                dispatch(actionCreators.fetchAssets({ page: 1, size: 100000, carrierId: dispatchCarrierId, isDeleted: false }));
            }
        }
    }, [dispatchCarrierId, accounts, trailerTypes]);

    useEffect(() => {
        getDriverLoads();
    }, [drivers, dispatchCarrierId]);

    useEffect(() => {
        if (showEntity === true && dispatchCarrierId !== undefined && dispatchCarrierId !== null && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null) {
            if (selectedRecord.isParentLoad === true && selectedRecord.childLoadInitCount && selectedRecord.childLoadInitCount > 0) {
                dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
                dispatch(actionCreators.clearLoad()); // reset load
                dispatch(actionCreators.clearLoadStops()); // reset load stops
                setSelectedLoadToDispatchId(null); // reset selected load
                setScheduledShifts(null); // reset scheduled shifts
                setDriverSideBar(null); // reset side bar
                setAssetSideBar(null); // reset side bar
                dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
                dispatch(actionCreators.fetchBundledLoadsWithIncludes(selectedRecord.id, dispatchCarrierId));
                dispatch(actionCreators.fetchLoadStops(selectedRecord.id));
            } else {
                dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
                dispatch(actionCreators.clearLoad()); // reset load
                dispatch(actionCreators.clearLoadStops()); // reset load stops
                setSelectedLoadToDispatchId(null); // reset selected load
                setScheduledShifts(null); // reset scheduled shifts
                setDriverSideBar(null); // reset side bar
                setAssetSideBar(null); // reset side bar
                dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
                dispatch(actionCreators.fetchLoadStops(selectedRecord.id));
            }
        } else {
            dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
            dispatch(actionCreators.clearLoad()); // reset load
            dispatch(actionCreators.clearLoadStops()); // reset load stops
            setSelectedLoadToDispatchId(null); // reset selected load
            setScheduledShifts(null); // reset scheduled shifts
            setDriverSideBar(null); // reset side bar
            setAssetSideBar(null); // reset side bar
        }
    }, [showEntity, selectedRecord, dispatchCarrierId]);

    useEffect(() => {
        if (load !== null && load !== undefined && !isEmpty(load) && load.childLoadInitCount !== undefined && load.childLoadInitCount !== null && stops !== null && stops !== undefined && stops.length > 0) {
            getDocuments();
        }
    }, [load, stops]);

    // useMemo(() => {
    //     if (load !== undefined && load !== null && !isEmpty(load)) {
    //         let approxLoadDuration = load ? load.approxLoadDuration : 1; // in minutes
    //         let approxLoadDurationInDays = Math.ceil((approxLoadDuration / 60) / 24); // in days 
    //         setDuration(approxLoadDurationInDays);
    //         // console.log(`duration: ${approxLoadDurationInDays}`);
    //         let startDate = load ? moment(load.pickUpDateTime) : moment();
    //         let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
    //         let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
    //         let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(approxLoadDurationInDays, 'days'), time, "UTC");
    //         // console.log(startDateValue);
    //         // console.log(endDateValue);

    //         setStartDate(startDateValue);
    //         setEndDate(endDateValue);
    //     }
    // }, [load]);

    useEffect(() => {
        getScheduledShifts(load, dispatchCarrierId);
    }, [load, dispatchCarrierId]);

    useEffect(() => {
        // console.log(bundledLoads);
        // console.log(driverLoads);
        // console.log(scheduledShifts);
        if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0 && driverLoads !== undefined && driverLoads !== null && scheduledShifts !== undefined && scheduledShifts !== null) {
            let loadsToDispatchArray = [];

            bundledLoads.filter(i => i.isDeleted === false).forEach((bundledLoad, index) => {
                let bundledLoadDriverScheduledShifts = [];
                let bundledLoadDriverBundledLoads = [];
                let bundledLoadDriverOtherLoads = [];
                if (bundledLoad.driverIds && bundledLoad.driverIds.length > 0) {
                    bundledLoad.driverIds.forEach((driverId) => {
                        if (scheduledShifts !== undefined && scheduledShifts !== null && scheduledShifts.length > 0) {
                            scheduledShifts.forEach((driverScheduledShift) => {
                                if (driverScheduledShift.accountUserIds !== undefined && driverScheduledShift.accountUserIds !== null && driverScheduledShift.accountUserIds.length > 0 && driverScheduledShift.accountUserIds.includes(driverId)) {
                                    if (bundledLoadDriverScheduledShifts.find(i => i.id === driverScheduledShift.id) === undefined) {
                                        bundledLoadDriverScheduledShifts.push(driverScheduledShift);
                                    }
                                }
                            });
                        }
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(driverId)) {
                                driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (bundledLoadDriverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    bundledLoadDriverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                        if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                            driverLoads.forEach((driverOtherLoad) => {
                                if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(driverId)) {
                                    if (bundledLoadDriverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                        bundledLoadDriverOtherLoads.push(driverOtherLoad);
                                    }
                                }
                            });
                        }
                    });
                }

                let dispatchStatus = 'PENDING';
                if (bundledLoad.loadStatus === 'SCHEDULED' || bundledLoad.loadStatus === 'IN_TRANSIT' || bundledLoad.loadStatus === 'AT_STOP' || bundledLoad.loadStatus === 'COMPLETED' || bundledLoad.loadStatus === 'CLOSED' || bundledLoad.loadStatus === 'REOPENED') {
                    dispatchStatus = 'DISPATCHED';
                } else if (bundledLoad.loadStatus === 'CANCELLED') {
                    dispatchStatus = 'CANCELLED';
                }

                let loadNumber = index + 1;

                let hasTeamDriving = bundledLoad.driverRequirements && bundledLoad.driverRequirements.length > 0 && bundledLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                let hasDrivers = bundledLoad.driverIds !== undefined && bundledLoad.driverIds !== null && bundledLoad.driverIds.length > 0 && ((hasTeamDriving === true && bundledLoad.driverIds.length === 2) || (hasTeamDriving === false && bundledLoad.driverIds.length === 1)) ? true : false;
                let hasAssets = bundledLoad.assetIds !== undefined && bundledLoad.assetIds !== null && bundledLoad.assetIds.length > 0 ? true : false;

                loadsToDispatchArray.push({
                    ...bundledLoad,
                    hasTeamDriving: hasTeamDriving,
                    hasDrivers: hasDrivers,
                    hasAssets: hasAssets,
                    loadNumber: loadNumber,
                    loadName: bundledLoad.parentName ? bundledLoad.parentName : (bundledLoad.name ? bundledLoad.name + ' (' + loadNumber + ')' : bundledLoad.irisId),
                    dispatchStatus: dispatchStatus,
                    driverBundledLoads: bundledLoadDriverBundledLoads,
                    driverOtherLoads: bundledLoadDriverOtherLoads,
                    driverScheduledShifts: bundledLoadDriverScheduledShifts
                });
            });

            setLoadsToDispatch(loadsToDispatchArray);
            // console.log(loadsToDispatchArray);
        } else {
            setLoadsToDispatch([]);
        }
    }, [bundledLoads, driverLoads, scheduledShifts]);

    //#endregion
    //#region display components

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const documentsComponent = (
        <Modal
            title="Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadDocuments
                loadId={load ? load.id : null}
                load={load}
                stops={stops}
                entityType={entityType}
                entityId={entityId}
                documents={documents}
                currentStatus={load ? load.loadStatus : null}
            />
        </Modal>
    );

    const filterDrawer = (
        <Drawer
            title="Open Loads"
            placement="left"
            closable={true}
            closeIcon={<FilterFilled />}
            onClose={toggleListFilterDrawer}
            visible={showListFilterDrawer === true}
            getContainer={false}
            headerStyle={{ backgroundColor: '#f0f2f5' }}
            bodyStyle={{ backgroundColor: '#f0f2f5' }}
            drawerStyle={{ border: '1px solid #16588E' }}
            style={{ position: showListFilterDrawer === true ? 'absolute' : 'relative' }}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            Sort and Filter will go here
        </Drawer>
    );

    const dispatchLoadComponents = () => {
        let loadObj = null
        if (selectedLoadToDispatchId !== undefined && selectedLoadToDispatchId !== null && loadsToDispatch !== undefined && loadsToDispatch !== null && loadsToDispatch.length > 0) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (selectedLoadToDispatch !== undefined && selectedLoadToDispatch !== null && !isEmpty(selectedLoadToDispatch)) {
                loadObj = selectedLoadToDispatch;
            }
        } else if (load !== undefined && load !== null && !isEmpty(load)) {
            loadObj = load;
        }

        if (loadObj !== undefined && loadObj !== null && !isEmpty(loadObj)) {
            let hasApprovedDriversCheck = false;
            let hasDriversCheck = false;
            let hasAssetsCheck = false;
            let hasActiveCarrierCheck = false;
            let hasVerifiedCarrierCheck = false;
            let hasTeamDriving = loadObj.driverRequirements && loadObj.driverRequirements.length > 0 && loadObj.driverRequirements.includes('TEAM_DRIVING') ? true : false;
            let loadName = loadObj.loadName ? loadObj.loadName : (loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId));

            if (loadObj.driverIds !== undefined && loadObj.driverIds !== null && loadObj.driverIds.length > 0) {
                // if team driving is selected then make sure there are 2 drivers assigned, otherwise make sure there is only 1 driver assigned to the load
                if ((hasTeamDriving === true && loadObj.driverIds.length === 2) || (hasTeamDriving === false && loadObj.driverIds.length === 1)) {
                    hasDriversCheck = true;

                    if (drivers !== undefined && drivers !== null && drivers.length > 0) {
                        // make sure all drivers assigned to the load are approved
                        let allDriversAreApproved = true;
                        loadObj.driverIds.forEach((loadDriverId) => {
                            let loadDriver = drivers.find(i => i.id === loadDriverId);
                            if (loadDriver !== undefined && loadDriver !== null && loadDriver.driver !== undefined && loadDriver.driver !== null) {
                                if (loadDriver.driver.isApproved === false) {
                                    allDriversAreApproved = false;
                                }
                            } else {
                                allDriversAreApproved = false;
                            }
                        });
                        hasApprovedDriversCheck = allDriversAreApproved;
                    }
                }
            }

            if (loadObj.assetIds !== undefined && loadObj.assetIds !== null && loadObj.assetIds.length > 0) {
                hasAssetsCheck = true;
            }

            if (accounts !== undefined && accounts !== null && accounts.length > 0 && loadObj.assignedCarrierId !== undefined && loadObj.assignedCarrierId !== null) {
                let loadCarrier = accounts.find(i => i.id === loadObj.assignedCarrierId);
                if (loadCarrier !== undefined && loadCarrier !== null && !isEmpty(loadCarrier)) {
                    // console.log(loadCarrier);
                    // make sure the carrier assigned to the load is active and verified
                    hasActiveCarrierCheck = loadCarrier.isActive !== undefined && loadCarrier.isActive !== null && loadCarrier.isActive === true;
                    hasVerifiedCarrierCheck = loadCarrier.isVerified !== undefined && loadCarrier.isVerified !== null && loadCarrier.isVerified === true;
                }
            }

            // console.log(loadObj);
            // console.log(hasDriversCheck)
            // console.log(hasApprovedDriversCheck)
            // console.log(hasAssetsCheck)
            // console.log(hasActiveCarrierCheck)
            // console.log(hasVerifiedCarrierCheck)

            return (
                <Modal
                    title={null}
                    visible={showDispatchLoadModal === true}
                    maskClosable={false}
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <Card
                        title={"Confirm Dispatch"}
                        style={{ border: '1px solid #D8D8D8' }}
                        bodyStyle={{ padding: 12, minHeight: 400 }}
                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                        extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { onCancelDispatchLoad(); }}></Button>}
                    >
                        <FormProvider {...methods1}>
                            <Form onSubmit={methods1.handleSubmit(onDispatchLoad)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingLoadUpdate === true && errorLoadUpdate === null}>
                                    <div>Are you sure you want to dispatch the load {loadName}?</div>
                                    {hasDriversCheck === false && hasTeamDriving === false ? (<Alert message="You must assign a driver to this load." type="error" />) : null}
                                    {hasDriversCheck === false && hasTeamDriving === true ? (<Alert message="You must assign two drivers to this load." type="error" />) : null}
                                    {hasAssetsCheck === false ? (<Alert message="You must assign at least one asset to this load." type="error" />) : null}
                                    {hasDriversCheck === true && hasApprovedDriversCheck === false && hasTeamDriving === false ? (<Alert message="The driver assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                                    {hasDriversCheck === true && hasApprovedDriversCheck === false && hasTeamDriving === true ? (<Alert message="Both drivers assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                                    {hasActiveCarrierCheck === false ? (<Alert message="Your Carrier Account must be activated first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}
                                    {hasVerifiedCarrierCheck === false ? (<Alert message="Your Carrier Account must be verified first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}

                                    {entityType === "STAFF" && (
                                        <div style={{ marginTop: 60 }}>
                                            <FormItem {...formItemLayout} label={`Over-Ride Scheduling of this Load without Drivers or Assets due to Carrier Not Cooperating.`} format="horizontal"
                                                render={({ onChange, onBlur, value, name }) => (
                                                    <Checkbox
                                                        onBlur={onBlur}
                                                        onChange={e => { setOverrideScheduled(e.target.checked); onChange(e.target.checked); }}
                                                        checked={value}
                                                        name={name}
                                                    />
                                                )}
                                                name="overrideScheduled"
                                                defaultValue={false}
                                            />
                                        </div>
                                    )}

                                    {errorLoadUpdate && <Alert message={`${errorLoadUpdate}`} type="error" />}
                                </Spin>
                                <FormButtons cancel={onCancelDispatchLoad} cancelDisabled={isLoadingLoadUpdate === true && errorLoadUpdate === null} submitDisabled={((hasDriversCheck === false || hasApprovedDriversCheck === false || hasAssetsCheck === false || hasActiveCarrierCheck === false || hasVerifiedCarrierCheck === false) && overrideScheduled === false) || (isLoadingLoadUpdate === true && errorLoadUpdate === null)} submitText="Dispatch Load" />
                            </Form>
                        </FormProvider>
                    </Card>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const cancelLoadComponents = () => {
        let loadObj = null
        if (selectedLoadToDispatchId !== undefined && selectedLoadToDispatchId !== null && loadsToDispatch !== undefined && loadsToDispatch !== null && loadsToDispatch.length > 0) {
            let selectedLoadToDispatch = loadsToDispatch.find(i => i.id === selectedLoadToDispatchId);
            if (selectedLoadToDispatch !== undefined && selectedLoadToDispatch !== null && !isEmpty(selectedLoadToDispatch)) {
                loadObj = selectedLoadToDispatch;
            }
        } else if (load !== undefined && load !== null && !isEmpty(load)) {
            loadObj = load;
        }

        if (loadObj !== undefined && loadObj !== null && !isEmpty(loadObj)) {
            let loadName = loadObj.loadName ? loadObj.loadName : (loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId));

            return (
                <Modal
                    title={null}
                    visible={showCancelLoadModal === true}
                    maskClosable={false}
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <Card
                        title={"Confirm Cancellation"}
                        style={{ border: '1px solid #D8D8D8' }}
                        bodyStyle={{ padding: 12, minHeight: 400 }}
                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                        extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { onCancelCancelLoad(); }}></Button>}
                    >
                        <FormProvider {...methods2}>
                            <Form onSubmit={methods2.handleSubmit(onCancelLoad)}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoadingLoadUpdate === true && errorLoadUpdate === null}>
                                    <div>Are you sure you want to cancel the load {loadName}?</div>
                                    {errorLoadUpdate && <Alert message={`${errorLoadUpdate}`} type="error" />}
                                </Spin>
                                <FormButtons cancel={onCancelCancelLoad} cancelDisabled={isLoadingLoadUpdate === true && errorLoadUpdate === null} submitDisabled={(isLoadingLoadUpdate === true && errorLoadUpdate === null)} submitText="Cancel Load" />
                            </Form>
                        </FormProvider>
                    </Card>
                </Modal>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region stop displays

    const getStopNameDisplay = (stop) => {
        let stopNameDisplay = null;
        if (stop.stopLocation && stop.stopLocation.name) {
            stopNameDisplay = `${stop.stopLocation.name}`;
        } else if (stop.stopType) {
            stopNameDisplay = `${Enums.StopTypes.getValueByName(stop.stopType)}`;
        }
        return stopNameDisplay;
    };

    const getStopLocationDisplay = (stop) => {
        let stopLocationDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address && stop.stopLocation.address.city && stop.stopLocation.address.state) {
            stopLocationDisplay = `${stop.stopLocation.address.city}, ${stop.stopLocation.address.state}`;
        } else {
            stopLocationDisplay = getStopNameDisplay(stop);
        }

        return stopLocationDisplay;
    };

    const getStopAddressDisplay = (stop) => {
        let stopAddressDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address) {
            stopAddressDisplay = `${stringFormatter.toFormattedAddress(stop.stopLocation.address)}`;
        }

        return stopAddressDisplay;
    };

    const getAppointmentDetailsDisplay = (stop) => {
        let appointmentDetailsDisplay = null;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (startDateObj !== null && endDateObj !== null) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (apptDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (requestedDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${requestedDateObj.dateString}, TBD`;
            }
        }

        return appointmentDetailsDisplay;
    };

    const getAppointmentContactDisplay = (stop) => {
        let appointmentContactDisplay = null;

        if (stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null && !isEmpty(stop.apptPointOfContact)) {
            appointmentContactDisplay = `Appt Contact: ${stop.apptPointOfContact.name ? stop.apptPointOfContact.name + ', ' : ''}${stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}`;
        }

        return appointmentContactDisplay;
    };

    const getAppointmentCallAheadDisplay = (stop) => {
        let appointmentCallAheadDisplay = null;
        let apptCallAheadDateObj = stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptCallAheadDateTime, stop.timeZone) : null;
        if (apptCallAheadDateObj !== null) {
            appointmentCallAheadDisplay = `Appt Call Ahead: ${apptCallAheadDateObj.dateString}, ${apptCallAheadDateObj.timeString} ${apptCallAheadDateObj.timeZone}`;
        }

        return appointmentCallAheadDisplay;
    };

    const renderStop = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            const stopLocation = getStopLocationDisplay(stop);
            const stopAddress = getStopAddressDisplay(stop);
            const apptDetails = getAppointmentDetailsDisplay(stop);
            const apptCallAhead = getAppointmentCallAheadDisplay(stop);
            const apptContact = getAppointmentContactDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopIconContainer}>
                            {stopIcon ? (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} className={classes.stopIcon} />) : null}
                        </div>
                        <div className={classes.stopLocation}>{stopLocation}</div>
                    </div>
                    {stopAddress ? (<div className={classes.stopAddress}>{stopAddress}</div>) : null}
                    {apptDetails ? (<div className={classes.apptDetails}>{apptDetails}</div>) : null}
                    {apptCallAhead ? (<div className={classes.apptDetails}>{apptCallAhead}</div>) : null}
                    {apptContact ? (<div className={classes.apptDetails}>{apptContact}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = () => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (pickUpAndDropOffStops.length > 0) {
                return pickUpAndDropOffStops.map((stop) => { return renderStop(stop); });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    const getOriginAndDestinationTimes = (origin, destination) => {
        let timeDisplay = '';
        let originStartObj = getStopStartDateTime(origin);
        let destinationEndObj = getStopEndDateTime(destination);
        let startDateObj = originStartObj.startDateTime;
        let endDateObj = destinationEndObj.endDateTime;
        if (startDateObj !== null && endDateObj !== null) {
            let startTime = originStartObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateObj.timeString;
            let endTime = destinationEndObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateObj.timeString;
            if (startDateObj.dateString === endDateObj.dateString) {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endTime} ${startDateObj.timeZone}`;
            } else {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endDateObj.dateString}, ${endTime} ${startDateObj.timeZone}`;
            }
        }

        return timeDisplay;
    };

    //#endregion
    //#region load displays

    const getLoadStatusDisplay = (status) => {
        if (entityType === 'STAFF') {
            return stringFormatter.toFormattedString("LoadDisplayStatus", status, null);
        } else if (entityType === 'SHIPPER') {
            return stringFormatter.toFormattedString("ShipperLoadDisplayStatus", status, null);
        } else if (entityType === 'CARRIER' || entityType === 'DRIVER') {
            return stringFormatter.toFormattedString("CarrierLoadDisplayStatus", status, null);
        } else {
            return null;
        }
    };

    //#endregion
    //#region bundled loads displays

    const getDriverScheduledShiftsDisplay = (record, driverScheduledShifts) => {
        let driverScheduledShiftRows = [];
        if (driverScheduledShifts !== undefined && driverScheduledShifts !== null && driverScheduledShifts.length > 0) {
            driverScheduledShifts.forEach((scheduledShift) => {
                let shift = scheduledShift.shift;
                let title = shift && shift.name ? shift.name : '';
                let scheduledDate = moment(scheduledShift.startDateTime).format('MM/DD/YYYY');
                let timeZone = moment.tz(shift.timeZone).zoneAbbr();
                let time = `${scheduledDate}, ${shift ? shift.startTime : ''} - ${shift ? shift.endTime : ''}${timeZone ? ' ' + timeZone : ''}`;

                driverScheduledShiftRows.push(
                    <div key={`${title}-${time}-driver-scheduled-shift-row-${record.id}`}><span>{title}</span><br /><span>({time})</span></div>
                );
            });
        }

        return record.driverIds !== undefined && record.driverIds !== null && record.driverIds.length > 0 ? (driverScheduledShiftRows.length > 0 ? driverScheduledShiftRows : '--') : '--';
    };

    const getDriverLoadsDisplay = (record, driverBundledLoads, driverOtherLoads) => {
        let driverLoadRows = [];
        let loadsToShow = [];

        if (driverBundledLoads !== undefined && driverBundledLoads !== null && driverBundledLoads.length > 0) {
            driverBundledLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== record.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (driverOtherLoads !== undefined && driverOtherLoads !== null && driverOtherLoads.length > 0) {
            driverOtherLoads.forEach((loadObj) => {
                if (loadsToShow.find(i => i.id === loadObj.id) === undefined && loadObj.id !== record.id) {
                    loadsToShow.push(loadObj);
                }
            });
        }

        if (loadsToShow !== undefined && loadsToShow !== null && loadsToShow.length > 0) {
            loadsToShow.forEach((loadObj) => {
                let title = loadObj.loadName ? loadObj.loadName : (loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId));
                let time = getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);

                driverLoadRows.push(
                    <div key={`${loadObj.id}-driver-load-row-${record.id}`}><span>{title}</span><br /><span>({time})</span></div>
                );
            });
        }

        return record.driverIds !== undefined && record.driverIds !== null && record.driverIds.length > 0 ? (driverLoadRows.length > 0 ? driverLoadRows : 'No Other Loads') : '--';
    };

    //#endregion
    //#region displays

    const menu = (record) => {
        let childLoadInitCount = record.childLoadInitCount && record.childLoadInitCount > 0 ? record.childLoadInitCount : 0;
        let childLoadActualCount = record.childLoadActualCount && record.childLoadActualCount > 0 ? record.childLoadActualCount : 0;
        return (
            <Menu>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER')) &&
                    <Menu.Item key="shipperTracker">
                        <a href={`/track/${record.id}`} target='_blank'>
                            <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} style={{ marginRight: 8 }} />
                            <span>Shipper Tracker</span>
                        </a>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record.id); }}>
                    <InfoOutlined style={{ marginRight: 8 }} />
                    <span>Go To Load Dashboard</span>
                </Menu.Item>
                {((entityType === 'STAFF' && isAdmin === true) && childLoadActualCount < childLoadInitCount) &&
                    <Menu.Item key="generateMissingChildLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); dispatch(actionCreators.addMissingChildLoads(record.id)); }}>
                        <span>Generate Missing Child Loads</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const loadMenu = (load) => {
        return (
            <Menu>
                <Menu.Item key="refreshLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); refreshLoad(); }}>
                    <FontAwesomeIcon className="anticon" icon={faRedoAlt} style={{ marginRight: 8 }} />
                    <span>Refresh Load</span>
                </Menu.Item>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && load.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                {((entityType === 'STAFF' || (entityType === 'SHIPPER' && load.createdByEntityType === 'SHIPPER')) && load.id) &&
                    <Menu.Item key="shipperTracker">
                        <a href={`/track/${load.id}`} target='_blank'>
                            <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} style={{ marginRight: 8 }} />
                            <span>Shipper Tracker</span>
                        </a>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(load.id); }}>
                    <InfoOutlined style={{ marginRight: 8 }} />
                    <span>Go To Load Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    }

    const loadExtras = (record) => {
        return (
            <Dropdown overlay={menu(record)}>
                <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<MoreOutlined />} />
            </Dropdown>
        );
    };

    const loadActions = (load) => {
        if (load !== undefined && load !== null) {
            return (
                <Dropdown overlay={loadMenu(load)}>
                    <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<DownOutlined />} />
                </Dropdown>
            );
        } else {
            return null;
        }
    };

    const listActions = (
        <>
            {/* <div style={{ marginTop: 4 }}>
                <Button type="default" icon={<FilterFilled />} shape="circle" onClick={toggleListFilterDrawer} style={{ marginRight: 8 }} />
            </div> */}
            <div style={{ marginTop: 4 }}>
                <Button type="default" icon={<FontAwesomeIcon className="anticon" icon={faRedoAlt} />} shape="circle" onClick={refreshList} />
            </div>
            {/* <div style={{ marginTop: 4 }}>
                <Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={toggleNewEntity} />
            </div> */}
        </>
    );

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        hideSelectAll: true,
        columnTitle: 'Pick',
        columnWidth: 50,
        type: 'radio',
        selectedRowKeys: selectedLoadToDispatchId ? [selectedLoadToDispatchId] : [],
        renderCell: (checked, record, index, originNode) => {
            // console.log(originNode);
            return {
                props: {
                    className: getRowClassName(record)
                },
                children: originNode
            };
        },
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            if (selectedRowKeys !== undefined && selectedRowKeys !== null && selectedRowKeys.length > 0) {
                setSelectedLoadToDispatchId(selectedRowKeys[0]);
                setAssetSideBar(null);
                setDriverSideBar(null);
            } else {
                setSelectedLoadToDispatchId(null);
                setAssetSideBar(null);
                setDriverSideBar(null);
            }
        },
        getCheckboxProps: record => ({
            name: record.id,
        }),
    };

    const getRowClassName = (record) => {
        if (record.isDeleted === true) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (selectedLoadToDispatchId && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getDriverColumnClassName = (record) => {
        if (record.hasDrivers === false && record.hasAssets === true) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (selectedLoadToDispatchId && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getAssetColumnClassName = (record) => {
        if (record.hasDrivers === true && record.hasAssets === false) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (selectedLoadToDispatchId && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const getDispatchColumnClassName = (record) => {
        if (record.dispatchStatus === 'CANCELLED' || record.isDeleted === true) {
            return classes.missingRow;
        } else if (record.dispatchStatus === 'DISPATCHED') {
            return classes.dispatchedRow;
        } else if (record.hasDrivers === true && record.hasAssets === true && record.dispatchStatus === 'PENDING') {
            return classes.readyToDispatchRow;
        } else if (selectedLoadToDispatchId && selectedLoadToDispatchId === record.id) {
            return classes.selectedRow;
        } else {
            return classes.undispatchedRow;
        }
    };

    const loadsToDispatchColumns = [
        {
            align: 'center',
            title: 'Load',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: <DispatchSelectedLoadCard
                        key={`dispatch-selected-load-card-${index}-${record.id}`}
                        load={record}
                        loadsToDispatch={loadsToDispatch}
                        displayAsText={true}
                        isBundledLoad={true}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Driver(s)',
            dataIndex: 'drivers',
            key: 'drivers',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getDriverColumnClassName(record), classes.cell]
                    },
                    children: <DispatchDriverCard
                        key={`dispatch-driver-card-${index}-${record.id}`}
                        load={record}
                        stops={stops}
                        drivers={drivers}
                        shifts={shifts}
                        bundledLoads={loadsToDispatch}
                        driverLoads={driverLoads}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        displayAsText={true}
                        isBundledLoad={true}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Driver Shifts',
            dataIndex: 'driverScheduledShifts',
            key: 'driverScheduledShifts',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: getDriverScheduledShiftsDisplay(record, record.driverScheduledShifts),
                };
            },
        },
        {
            align: 'center',
            title: 'Driver Loads',
            dataIndex: 'driverOtherLoads',
            key: 'driverOtherLoads',
            render: (text, record) => {
                return {
                    props: {
                        className: [getRowClassName(record), classes.cell]
                    },
                    children: getDriverLoadsDisplay(record, record.driverBundledLoads, record.driverOtherLoads),
                };
            },
        },
        {
            align: 'center',
            title: 'Asset(s)',
            dataIndex: 'assets',
            key: 'assets',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getAssetColumnClassName(record), classes.cell]
                    },
                    children: <DispatchAssetCard
                        key={`dispatch-asset-card-${index}-${record.id}`}
                        load={record}
                        assets={assets}
                        drivers={drivers}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        displayAsText={true}
                        isBundledLoad={true}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    />,
                };
            },
        },
        {
            align: 'center',
            title: 'Status',
            dataIndex: 'dispatchStatus',
            key: 'dispatchStatus',
            render: (text, record, index) => {
                return {
                    props: {
                        className: [getDispatchColumnClassName(record), classes.cell]
                    },
                    children: record ? <DispatchScheduleLoadCard
                        key={`dispatch-schedule-load-card-${index}-${record.id}`}
                        load={record}
                        displayAsText={true}
                        isBundledLoad={true}
                        setShowDispatchLoadModal={setShowDispatchLoadModal}
                        setShowCancelLoadModal={setShowCancelLoadModal}
                        setAssetSideBar={setAssetSideBar}
                        setDriverSideBar={setDriverSideBar}
                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                    /> : null,
                };
            },
        },
    ];

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion

    if (dispatchCarrierId !== undefined && dispatchCarrierId !== null) {
        return (
            <div>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <Tooltip placement="left" title={'New Load'}><Button key="NewLoad" type="primary" icon={<PlusOutlined />} shape="circle" onClick={toggleNewEntity} /></Tooltip>
                </div>
                <LoadsList
                    filter={"carrier-dispatch"}
                    data={loadLists}
                    additionalIsLoading={isLoadingDrivers === true || isLoadingAssets === true || isLoadingDriverLoads === true || isLoadingShifts === true}
                    showStatus={false}
                    showStatusToStaff={false}
                    showCompletedDate={false}
                    entityType={entityType}
                    entityId={entityId}
                    title="Open"
                    goToLoad={goToLoad}
                    icon={<FontAwesomeIcon className="anticon" icon={faDesktop} />}
                    selectedRecord={selectedRecord}
                    setSelectedRecord={setSelectedRecord}
                    openShowEntity={openShowEntity}
                    closeShowEntity={closeShowEntity}
                    showEntity={showEntity}
                    loadExtras={loadExtras}
                    carrierId={dispatchCarrierId}
                    listActions={listActions}
                    //filterDrawer={filterDrawer}
                    getList={refreshList}
                    getMoreList={retrieveMore}
                />
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingStops === true || isLoadingDocuments === true || isLoadingScheduledShifts === true}>
                    {(showEntity === true && load !== undefined && load !== null && !isEmpty(load) && load.id !== undefined && load.id !== null && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null && selectedRecord.id === load.id) ? (
                        <Row gutter={[12, 12]} style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, borderTop: '4px solid #D8D8D8' }}>
                            {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? (
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                                    <DispatchSelectedLoadCard
                                        key={`dispatch-selected-load-card-parent-load`}
                                        load={load ? load : null}
                                        loadsToDispatch={null}
                                        displayAsText={false}
                                        isBundledLoad={false}
                                        style={{ marginBottom: 12 }}
                                    />
                                    <DispatchDriverCard
                                        key={`dispatch-driver-card-parent-load`}
                                        load={load ? load : null}
                                        stops={stops}
                                        drivers={drivers}
                                        shifts={shifts}
                                        bundledLoads={[]}
                                        driverLoads={driverLoads}
                                        setAssetSideBar={setAssetSideBar}
                                        setDriverSideBar={setDriverSideBar}
                                        displayAsText={false}
                                        isBundledLoad={false}
                                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                        style={{ marginBottom: 12 }}
                                    />
                                    {driverSideBar ? driverSideBar : null}
                                    <DispatchAssetCard
                                        key={`dispatch-asset-card-parent-load`}
                                        load={load ? load : null}
                                        assets={assets}
                                        drivers={drivers}
                                        setAssetSideBar={setAssetSideBar}
                                        setDriverSideBar={setDriverSideBar}
                                        displayAsText={false}
                                        isBundledLoad={false}
                                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                        style={{ marginBottom: 12 }}
                                    />
                                    {assetSideBar ? assetSideBar : null}
                                    <DispatchScheduleLoadCard
                                        key={`dispatch-schedule-load-card-parent-load`}
                                        load={load ? load : null}
                                        displayAsText={false}
                                        isBundledLoad={false}
                                        setShowDispatchLoadModal={setShowDispatchLoadModal}
                                        setShowCancelLoadModal={setShowCancelLoadModal}
                                        setAssetSideBar={setAssetSideBar}
                                        setDriverSideBar={setDriverSideBar}
                                        setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                        style={{ marginBottom: 12 }}
                                    />
                                </Col>
                            ) : (
                                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                                        <DispatchSelectedLoadCard
                                            key={`dispatch-selected-load-card-load-to-dispatch`}
                                            load={selectedLoadToDispatchId && loadsToDispatch && loadsToDispatch.length > 0 ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                            loadsToDispatch={loadsToDispatch}
                                            displayAsText={false}
                                            isBundledLoad={true}
                                            style={{ marginBottom: 12 }}
                                        />
                                        <DispatchDriverCard
                                            key={`dispatch-driver-card-load-to-dispatch`}
                                            load={selectedLoadToDispatchId && loadsToDispatch && loadsToDispatch.length > 0 ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                            stops={stops}
                                            drivers={drivers}
                                            shifts={shifts}
                                            bundledLoads={loadsToDispatch}
                                            driverLoads={driverLoads}
                                            setAssetSideBar={setAssetSideBar}
                                            setDriverSideBar={setDriverSideBar}
                                            displayAsText={false}
                                            isBundledLoad={true}
                                            setLoadsToDispatch={setLoadsToDispatch}
                                            style={{ marginBottom: 12 }}
                                        />
                                        {driverSideBar ? driverSideBar : null}
                                        <DispatchAssetCard
                                            key={`dispatch-asset-card-load-to-dispatch`}
                                            load={selectedLoadToDispatchId && loadsToDispatch && loadsToDispatch.length > 0 ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                            assets={assets}
                                            drivers={drivers}
                                            setAssetSideBar={setAssetSideBar}
                                            setDriverSideBar={setDriverSideBar}
                                            displayAsText={false}
                                            isBundledLoad={true}
                                            setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                            style={{ marginBottom: 12 }}
                                        />
                                        {assetSideBar ? assetSideBar : null}
                                        <DispatchScheduleLoadCard
                                            key={`dispatch-schedule-load-card-load-to-dispatch`}
                                            load={selectedLoadToDispatchId && loadsToDispatch && loadsToDispatch.length > 0 ? loadsToDispatch.find(i => i.id === selectedLoadToDispatchId) : null}
                                            displayAsText={false}
                                            isBundledLoad={true}
                                            setShowDispatchLoadModal={setShowDispatchLoadModal}
                                            setShowCancelLoadModal={setShowCancelLoadModal}
                                            setAssetSideBar={setAssetSideBar}
                                            setDriverSideBar={setDriverSideBar}
                                            setSelectedLoadToDispatchId={setSelectedLoadToDispatchId}
                                            style={{ marginBottom: 12 }}
                                        />
                                    </Col>
                                )}
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 16 }}>
                                {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) ? (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <Card
                                                title={`Loads to Dispatch for ${load.parentName}`}
                                                style={{ border: '1px solid #D8D8D8' }}
                                                bodyStyle={{ minHeight: 400, padding: 0 }}
                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                                            >
                                                <DataTable
                                                    scroll={{ x: '100%' }}
                                                    rowSelection={rowSelection}
                                                    dataSource={loadsToDispatch}
                                                    columns={loadsToDispatchColumns}
                                                    hidePaging={true}
                                                    loading={(isBundledLoadsLoading === true || isLoadingDriverLoads === true || isLoadingScheduledShifts === true || (isLoadingLoadUpdate === true && errorLoadUpdate === null) || loadEventUpdateStatus === 'PENDING')}
                                                    rowClassName={getRowClassName}
                                                    title={false}
                                                    style={{ backgroundColor: '#ffffff' }}
                                                    rowKey={record => record.id}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                ) : null}
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Card
                                            title="Map & Route Details"
                                            style={{ border: '1px solid #D8D8D8' }}
                                            bodyStyle={{ minHeight: 400, padding: 0 }}
                                            headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                                            extra={loadActions(load)}
                                        >
                                            <Row>
                                                <Col span={12} style={{ padding: '0px 12px' }}>
                                                    <TitleBlockWithIcon
                                                        style={{ margin: '40px auto 40px auto' }}
                                                        icon={<FontAwesomeIcon className={classes.icon} icon={faRoute} />}
                                                        title={
                                                            <span>Trip Miles: {stringFormatter.toFormattedString('Distance', load.practicalDistance, load.practicalDistanceUnit)}<br />Number of Stops: {stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').length}</span>
                                                        }
                                                    />
                                                    <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                                                        {renderStops()}
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ borderLeft: '1px solid #D8D8D8' }}>
                                                    <LoadMap
                                                        height="100%"
                                                        width="100%"
                                                        key={load.id}
                                                        loadId={load.id}
                                                        irisLoadId={load.irisId}
                                                        load={load}
                                                        stops={stops}
                                                        entityType={entityType}
                                                        entityId={entityId}
                                                        containerName={`load-details-map-${load.id}`}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Card
                                            title="Load & Rate Details"
                                            style={{ border: '1px solid #D8D8D8' }}
                                            bodyStyle={{ minHeight: 200, padding: 0 }}
                                            headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                                        >
                                            <Row>
                                                <Col span={12} style={{ padding: '0px 12px' }}>
                                                    <TitleBlockWithIcon
                                                        style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                        icon={<FontAwesomeIcon className={classes.icon} icon={faPallet} />}
                                                        title={
                                                            <span>Load ID: {load.irisId}<br />Status: {getLoadStatusDisplay(load.loadStatus)}</span>
                                                        }
                                                    />
                                                    <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                        {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                                        {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                                        {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                                        {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.preCoolReefer !== null && load.equipmentNeeds.preCoolReefer !== undefined ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                                        {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                                        {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                                            <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                        ) : null}
                                                        {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                                            <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={load.driverRequirements} dataType="DriverRequirement" />
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{ borderLeft: '1px solid #D8D8D8', padding: '0px 12px' }}>
                                                    <TitleBlockWithIcon
                                                        style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                        icon={<FontAwesomeIcon className={classes.icon} icon={faDollarSign} />}
                                                        title={
                                                            <span>Trip Rate: {stringFormatter.toFormattedMoney(load.carrierAmount)}<br />Rate Per Mile: {stringFormatter.toFormattedMoney(load.carrierRatePerMile)}</span>
                                                        }
                                                    />
                                                    <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                        {load.shipper && load.shipper && load.shipper.name ? <DataRow title="Shipper" value={load.shipper.name} /> : null}
                                                        {load.shipper && load.shipper.shipper && load.shipper.shipper.pointOfContact ? <DataContactInfoRow separateLines={true} title="Shipper Contact" name={load.shipper.shipper.pointOfContact.name} phone={load.shipper.shipper.pointOfContact.phone} phoneExt={load.shipper.shipper.pointOfContact.phoneExt} /> : null}
                                                        {load.shipper && load.shipper.afterHoursPhone ? <DataPhoneNumberRow title="Shipper After Hours Phone" phone={load.shipper.afterHoursPhone} phoneExt={load.shipper.afterHoursPhoneExt} /> : null}
                                                        <br />
                                                        {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? (load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmation" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmation" value="Pending" dataType="String" />) : null}
                                                        {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal} style={{ textDecoration: 'underline' }}>view</Button></div> : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : (
                            <Empty style={{ backgroundColor: '#ffffff', margin: 0, border: '2px dashed #979797', minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 18, fontWeight: 500 }} image={<FontAwesomeIcon className="anticon" size="4x" icon={faPallet} />} description={'Select a Load above to Dispatch the Load.'} />
                        )}
                </Spin>
                {newEntityComponents}
                {duplicateEntityComponents}
                {documentsComponent}
                {dispatchLoadComponents()}
                {cancelLoadComponents()}
            </div>
        );
    } else {
        return null;
    }
};

export default withRouter(Dispatch);
