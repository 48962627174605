import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {fetchLoadSuccess, loadEventUpdateTimeout} from "./loads";
import logger from "../../shared/logger";
import Pricing from "../../shared/pricing";

//#region Invoice Line Item Functions

export const fetchInvoiceLineItemsStart = () => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEMS_START
    }
};

export const fetchInvoiceLineItemsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEMS_SUCCESS,
        payload: payload
    }
};

export const fetchInvoiceLineItemsFail = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_LINE_ITEMS_FAIL,
        payload: payload
    }
};

export const clearInvoiceLineItems = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_LINE_ITEMS
    }
};

export const addInvoiceLineItemStart = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_START
    }
};

export const addInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const addInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const addInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const addInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const updateInvoiceLineItemStart = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_START
    }
};

export const updateInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const updateInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const updateInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const updateInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const removeInvoiceLineItemStart = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_START
    }
};

export const removeInvoiceLineItemSuccess = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_SUCCESS
    }
};

export const removeInvoiceLineItemFail = (payload) => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_FAIL,
        payload: payload
    }
};

export const removeInvoiceLineItemErrorClear = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR
    }
};

export const removeInvoiceLineItemLoadingClear = () => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR
    }
};

export const cancelAddInvoiceLineItem = () => {
    return {
        type: actionTypes.CANCEL_ADD_INVOICE_LINE_ITEM
    }
};

export const cancelUpdateInvoiceLineItem = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_INVOICE_LINE_ITEM
    }
};

export const cancelRemoveInvoiceLineItem = () => {
    return {
        type: actionTypes.CANCEL_REMOVE_INVOICE_LINE_ITEM
    }
};

export const insertInvoiceLineItem = (payload) => {
    return {
        type: actionTypes.ADD_INVOICE_LINE_ITEM,
        payload: payload
    }
};

export const changeInvoiceLineItem = (payload) => {
    return {
        type: actionTypes.UPDATE_INVOICE_LINE_ITEM,
        payload: payload
    }
};

export const changeSingleInvoiceLineItem = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_INVOICE_LINE_ITEM,
        payload: payload
    }
};

export const deleteInvoiceLineItem = (payload) => {
    return {
        type: actionTypes.REMOVE_INVOICE_LINE_ITEM,
        payload: payload
    }
};

//#endregion

//#region Invoice Line Item Methods

export const fetchInvoiceLineItems = (payload) => {
    return async (dispatch, getState) => {
        const invoiceLineItemsPath = '/invoiceLineItems';
        try {
            dispatch(fetchInvoiceLineItemsStart());

            const state = getState();
            const invoiceLineItemsState = {...state.invoiceLineItems};
            let pagination = {...invoiceLineItemsState.pagination};
            let searchParams = {...invoiceLineItemsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const invoiceLineItemsRes = await axiosAuthenticated.get(invoiceLineItemsPath, {params: {...searchParams}});
            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                const invoiceLineItems = invoiceLineItemsRes.data.data;

                // Read total count from server
                pagination.total = invoiceLineItemsRes.data.totalCount;
                pagination.current = invoiceLineItemsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchInvoiceLineItemsSuccess({
                    records: invoiceLineItems,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchInvoiceLineItemsFail({error: error.message}));
        }
    }
};

export const addInvoiceLineItem = (payload, loadId = null) => {
    return async (dispatch, getState) => {
        const invoiceLineItemsPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(addInvoiceLineItemStart());

            let invoiceLineItemPayload = {...payload};

            if (invoiceLineItemPayload.baseAmount !== undefined && invoiceLineItemPayload.baseAmount !== null) {
                invoiceLineItemPayload.baseAmount = Number(invoiceLineItemPayload.baseAmount);
            }

            if (invoiceLineItemPayload.quantity !== undefined && invoiceLineItemPayload.quantity !== null) {
                invoiceLineItemPayload.quantity = Number(invoiceLineItemPayload.quantity);
            }

            if (invoiceLineItemPayload.totalAmount !== undefined && invoiceLineItemPayload.totalAmount !== null) {
                invoiceLineItemPayload.totalAmount = Number(invoiceLineItemPayload.totalAmount);
            }

            const invoiceLineItemRes = await axiosAuthenticated.post(invoiceLineItemsPath, {...invoiceLineItemPayload});

            if (invoiceLineItemRes && invoiceLineItemRes.status === 201) {
                let newInvoiceLineItem = invoiceLineItemRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];

                    updatedInvoiceLineItems.push(newInvoiceLineItem);

                    let updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    let changeType = 'INVOICE_LINE_ITEMS_UPDATED';
                    if (updateLoadSuccess === true) {
                        changeType = 'LOAD_UPDATED'; // will update load, loads, and invoicelineitems
                    }
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(insertInvoiceLineItem(newInvoiceLineItem));

                dispatch(addInvoiceLineItemSuccess());
                dispatch(addInvoiceLineItemLoadingClear());
                dispatch(addInvoiceLineItemErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addInvoiceLineItemFail({error: error.message}));
        }
    }
};

export const addInvoiceLineItems = (payload, loadId = null) => {
    return async (dispatch, getState) => {
        const invoiceLineItemsPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(addInvoiceLineItemStart());

            let invoiceLineItemArray = [];

            for (let key in payload) {
                // console.log(key);
                // console.log(payload[key]);
                let invoiceLineItem = payload[key];

                if (invoiceLineItem.baseAmount !== undefined && invoiceLineItem.baseAmount !== null) {
                    invoiceLineItem.baseAmount = Number(invoiceLineItem.baseAmount);
                }

                if (invoiceLineItem.quantity !== undefined && invoiceLineItem.quantity !== null) {
                    invoiceLineItem.quantity = Number(invoiceLineItem.quantity);
                }

                if (invoiceLineItem.totalAmount !== undefined && invoiceLineItem.totalAmount !== null) {
                    invoiceLineItem.totalAmount = Number(invoiceLineItem.totalAmount);
                }

                //console.log(invoiceLineItem);
                invoiceLineItemArray.push(invoiceLineItem);
            }

            const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, [...invoiceLineItemArray]);

            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
                let newInvoiceLineItems = invoiceLineItemsRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];

                    if (newInvoiceLineItems !== undefined && newInvoiceLineItems !== null && newInvoiceLineItems.length > 0) {
                        newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                            updatedInvoiceLineItems.push(newInvoiceLineItem);
                        });
                    }

                    let updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    let changeType = 'INVOICE_LINE_ITEMS_UPDATED';
                    if (updateLoadSuccess === true) {
                        changeType = 'LOAD_UPDATED'; // will update load, loads, and invoicelineitems
                    }
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                if (newInvoiceLineItems !== undefined && newInvoiceLineItems !== null && newInvoiceLineItems.length > 0) {
                    newInvoiceLineItems.forEach((newInvoiceLineItem) => {
                        dispatch(insertInvoiceLineItem(newInvoiceLineItem));
                    });
                }

                dispatch(addInvoiceLineItemSuccess());
                dispatch(addInvoiceLineItemLoadingClear());
                dispatch(addInvoiceLineItemErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addInvoiceLineItemFail({error: error.message}));
        }
    }
};

export const updateInvoiceLineItem = (invoiceLineItemId, payload, loadId = null) => {
    return async (dispatch, getState) => {
        const invoiceLineItemsPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateInvoiceLineItemStart());

            if (payload.baseAmount !== undefined && payload.baseAmount !== null) {
                payload.baseAmount = Number(payload.baseAmount);
            }

            if (payload.quantity !== undefined && payload.quantity !== null) {
                payload.quantity = Number(payload.quantity);
            }

            if (payload.totalAmount !== undefined && payload.totalAmount !== null) {
                payload.totalAmount = Number(payload.totalAmount);
            }

            const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemsPath + `/${invoiceLineItemId}`, {...payload});

            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                let updatedInvoiceLineItem = invoiceLineItemsRes.data;

                const state = getState();
                const invoiceLineItemsState = {...state.invoiceLineItems};
                const transactionsState = {...state.transactions};
                let existingInvoiceLineItems = [...invoiceLineItemsState.records];
                let updatedTransactions = [...transactionsState.records];
                let updateLoadSuccess = false;
                const index = existingInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                if (index !== -1) {
                    const updatedInvoiceLineItems = [
                        ...existingInvoiceLineItems.slice(0, index), // everything before current obj
                        updatedInvoiceLineItem,
                        ...existingInvoiceLineItems.slice(index + 1), // everything after current obj
                    ];

                    updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                }
                let changeType = 'INVOICE_LINE_ITEMS_UPDATED';
                if (updateLoadSuccess === true) {
                    changeType = 'LOAD_UPDATED'; // will update load, loads, and invoicelineitems
                }

                if (loadId !== undefined && loadId !== null) {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(changeInvoiceLineItem(updatedInvoiceLineItem));

                dispatch(updateInvoiceLineItemSuccess());
                dispatch(updateInvoiceLineItemLoadingClear());
                dispatch(updateInvoiceLineItemErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateInvoiceLineItemFail({error: error.message}));
        }
    }
};

export const updateInvoiceLineItemsStatus = (invoiceLineItemIds, status, loadId = null) => {
    return async (dispatch, getState) => {
        const loadEventsPath = '/loadEvents';
        try {
            if (invoiceLineItemIds !== undefined && invoiceLineItemIds !== null && invoiceLineItemIds.length > 0) {
                dispatch(updateInvoiceLineItemStart());

                const invoiceLineItemsRes = await axiosAuthenticated.post('/invoiceLineItemsUpdateStatus', {
                    ids: invoiceLineItemIds,
                    status: status
                });

                if (invoiceLineItemsRes && invoiceLineItemsRes.status === 204) {
                    if (loadId !== undefined && loadId !== null) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: loadId,
                            eventType: 'LOAD_UPDATED',
                            changeType: 'INVOICE_LINE_ITEMS_UPDATED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const existingRecords = [...invoiceLineItemsState.records];
                    invoiceLineItemIds.forEach((invoiceLineItemId) => {
                        let existingRecord = existingRecords.find(i => i.id === invoiceLineItemId);
                        if (existingRecord !== undefined && existingRecord !== null) {
                            existingRecord.status = status;
                            dispatch(changeInvoiceLineItem(existingRecord));
                        }
                    });

                    dispatch(updateInvoiceLineItemSuccess());
                    dispatch(updateInvoiceLineItemLoadingClear());
                    dispatch(updateInvoiceLineItemErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateInvoiceLineItemFail({error: error.message}));
        }
    }
};

export const removeInvoiceLineItem = (invoiceLineItemId, loadId = null) => {
    return async (dispatch, getState) => {
        const invoiceLineItemPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(removeInvoiceLineItemStart());

            const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemPath + `/${invoiceLineItemId}`, {isDeleted: true});

            if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                let updatedInvoiceLineItem = invoiceLineItemsRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];
                    let updateLoadSuccess = false;
                    const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                    if (index !== -1) {
                        // returns the deleted items
                        updatedInvoiceLineItems.splice(index, 1);

                        updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    }
                    let changeType = 'INVOICE_LINE_ITEMS_UPDATED';
                    if (updateLoadSuccess === true) {
                        changeType = 'LOAD_UPDATED'; // will update load, loads, and invoicelineitems
                    }
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(deleteInvoiceLineItem(updatedInvoiceLineItem));

                dispatch(removeInvoiceLineItemSuccess());
                dispatch(removeInvoiceLineItemLoadingClear());
                dispatch(removeInvoiceLineItemErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(removeInvoiceLineItemFail({error: error.message}));
        }
    }
};

export const removeInvoiceLineItems = (invoiceLineItemIds, loadId = null) => {
    return async (dispatch, getState) => {
        const invoiceLineItemPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';
        try {
            if (invoiceLineItemIds !== undefined && invoiceLineItemIds !== null && invoiceLineItemIds.length > 0) {
                dispatch(removeInvoiceLineItemStart());

                let invoiceLineItemsToRemove = [];
                for (let i = 0; i < invoiceLineItemIds.length; i++) {
                    const invoiceLineItemsRes = await axiosAuthenticated.put(invoiceLineItemPath + `/${invoiceLineItemIds[i]}`, {isDeleted: true});
                    if (invoiceLineItemsRes && invoiceLineItemsRes.status === 200) {
                        let updatedInvoiceLineItem = invoiceLineItemsRes.data;

                        invoiceLineItemsToRemove.push(updatedInvoiceLineItem);

                        dispatch(deleteInvoiceLineItem(updatedInvoiceLineItem));
                    }
                }

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];
                    let updateLoadSuccess = false;
                    if (invoiceLineItemsToRemove.length > 0) {
                        invoiceLineItemsToRemove.forEach((updatedInvoiceLineItem) => {
                            const index = updatedInvoiceLineItems.findIndex(obj => obj.id === updatedInvoiceLineItem.id);
                            if (index !== -1) {
                                // returns the deleted items
                                updatedInvoiceLineItems.splice(index, 1);
                            }
                        });

                        updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    }
                    let changeType = 'INVOICE_LINE_ITEMS_UPDATED';
                    if (updateLoadSuccess === true) {
                        changeType = 'LOAD_UPDATED'; // will update load, loads, and invoicelineitems
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(removeInvoiceLineItemSuccess());
                dispatch(removeInvoiceLineItemLoadingClear());
                dispatch(removeInvoiceLineItemErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(removeInvoiceLineItemFail({error: error.message}));
        }
    }
};

//#endregion
//#region helper api calls

export const updateLoadPricing = async (loadId, invoiceLineItems, transactions) => {
    try {
        let filteredInvoiceLineItems = invoiceLineItems.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);
        let filteredTransactions = transactions.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);

        let loadSummary = Pricing.loadPricingSummary(filteredInvoiceLineItems, filteredTransactions);

        let updatedLoadPayload = {};

        if (loadSummary.shipperRatePerMileAmount !== undefined && loadSummary.shipperRatePerMileAmount !== null) {
            updatedLoadPayload.shipperRatePerMile = loadSummary.shipperRatePerMileAmount;
            updatedLoadPayload.shipperRatePerMileUnit = 'USD';
        }
        if (loadSummary.shipperAmountWithAddOns !== undefined && loadSummary.shipperAmountWithAddOns !== null) {
            updatedLoadPayload.shipperAmount = loadSummary.shipperAmountWithAddOns;
            updatedLoadPayload.shipperAmountUnit = 'USD';
        }
        if (loadSummary.shipperBalance !== undefined && loadSummary.shipperBalance !== null) {
            updatedLoadPayload.shipperBalance = loadSummary.shipperBalance;
            updatedLoadPayload.shipperBalanceUnit = 'USD';
        }
        if (loadSummary.carrierRatePerMileAmount !== undefined && loadSummary.carrierRatePerMileAmount !== null) {
            updatedLoadPayload.carrierRatePerMile = loadSummary.carrierRatePerMileAmount;
            updatedLoadPayload.carrierRatePerMileUnit = 'USD';
        }
        if (loadSummary.carrierAmountWithAddOns !== undefined && loadSummary.carrierAmountWithAddOns !== null) {
            updatedLoadPayload.carrierAmount = loadSummary.carrierAmountWithAddOns;
            updatedLoadPayload.carrierAmountUnit = 'USD';
        }
        if (loadSummary.carrierBalance !== undefined && loadSummary.carrierBalance !== null) {
            updatedLoadPayload.carrierBalance = loadSummary.carrierBalance;
            updatedLoadPayload.carrierBalanceUnit = 'USD';
        }

        const loadsRes = await axiosAuthenticated.put('/loads' + `/${loadId}`, {...updatedLoadPayload});
        if (loadsRes && loadsRes.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);

        return false;
    }
};

//#endregion