import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Row, Col, Spin, Select, Empty, Card } from 'antd';
import classes from './DispatchChangeDrivers.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import MomentDate from '../../shared/dateFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faIdCard, faPlus} from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;
const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const DispatchChangeDrivers = props => {
    //#region props and constants
    const drivers = props.drivers;
    const bundledLoads = props.bundledLoads;
    const driverLoads = props.driverLoads;
    const driver = props.driver;
    const onDone = props.cancel;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useRefs

    //#endregion
    //#region useStates

    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState([]);
    const [selectedAccountUserIds, setSelectedAccountUserIds] = useState([]);
    const [loads, setLoads] = useState([]);
    const [isLoadingLoads, setIsLoadingLoads] = useState(false);
    const [isLoadingScheduledShifts, setIsLoadingScheduledShifts] = useState(false);
    const [driverMap, setDriverMap] = useState([]);

    //#endregion
    //#region driver methods

    const onChangeDriver = (accountUserId) => {
        if (accountUserId !== undefined && accountUserId !== null) {

            let payload = {
                load_ids: props.loads.map(it => it.id),
                driverIds: [accountUserId]
            };
            dispatch(actionCreators.assignBulkLoadsToDriver(payload));

            onDone();
        }
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (drivers !== undefined && drivers !== null && drivers.length > 0 && driver !== undefined && driver !== null) {
            if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                let resourceMapArray = drivers.filter(i => selectedAccountUserIds.includes(i.id)).map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}, Available: ${accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            } else {
                let resourceMapArray = drivers.map((accountUser) => {
                    return ({
                        id: accountUser.id,
                        extendedProps: accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}, Available: ${accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}`
                    });
                });

                setResourceMap(resourceMapArray);
            }
        } else {
            setResourceMap([]);
        }
    }, [drivers, selectedAccountUserIds]);

    useMemo(() => {
        let loadsArray = [];
        if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
            driverLoads.forEach((loadObj) => {
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
            bundledLoads.forEach((loadObj, loadIndex) => {
                loadObj.loadName = loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name + ' (' + (loadIndex + 1) + ')' : loadObj.irisId);
                loadObj.loadNumber = loadIndex + 1;
                if (loadsArray.find(i => i.id === loadObj.id) === undefined) {
                    loadsArray.push(loadObj);
                }
            });
        }

        setLoads(loadsArray);
    }, [driverLoads, bundledLoads]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (drivers !== undefined && drivers !== null && drivers.length > 0) {
            let driversArray = [];

            drivers.forEach((accountUser) => {
                let driverBundledLoads = [];
                let driverOtherLoads = [];
                if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                    bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                        if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(accountUser.id)) {
                            driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                            driverBundledLoad.loadNumber = loadIndex + 1;
                            if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                driverBundledLoads.push(driverBundledLoad);
                            }
                        }
                    });
                }
                if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                    driverLoads.forEach((driverOtherLoad) => {
                        if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(accountUser.id)) {
                            if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                driverOtherLoads.push(driverOtherLoad);
                            }
                        }
                    });
                }

                let driverScheduledShifts = [];

                driversArray.push({
                    ...accountUser,
                    accountUser: accountUser,
                    driverBundledLoads: driverBundledLoads,
                    driverOtherLoads: driverOtherLoads,
                    scheduledShifts: driverScheduledShifts
                });
            });

            if (selectedAccountUserIds !== undefined && selectedAccountUserIds !== null && selectedAccountUserIds.length > 0) {
                drivers.filter(i => selectedAccountUserIds.includes(i.id)).forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = [];

                    if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (driverScheduledShifts && driverScheduledShifts.length > 0) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            } else {
                drivers.forEach((accountUser) => {
                    let driverBundledLoads = [];
                    let driverOtherLoads = [];
                    if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                        bundledLoads.forEach((driverBundledLoad, loadIndex) => {
                            if (driverBundledLoad.isDeleted === false && driverBundledLoad.loadStatus !== 'CANCELLED' && driverBundledLoad.driverIds !== undefined && driverBundledLoad.driverIds !== null && driverBundledLoad.driverIds.length > 0 && driverBundledLoad.driverIds.includes(accountUser.id)) {
                                driverBundledLoad.loadName = driverBundledLoad.parentName ? driverBundledLoad.parentName : (driverBundledLoad.name ? driverBundledLoad.name + ' (' + (loadIndex + 1) + ')' : driverBundledLoad.irisId);
                                driverBundledLoad.loadNumber = loadIndex + 1;
                                if (driverBundledLoads.find(i => i.id === driverBundledLoad.id) === undefined) {
                                    driverBundledLoads.push(driverBundledLoad);
                                }
                            }
                        });
                    }
                    if (driverLoads !== undefined && driverLoads !== null && driverLoads.length > 0) {
                        driverLoads.forEach((driverOtherLoad) => {
                            if (driverOtherLoad.isDeleted === false && driverOtherLoad.loadStatus !== 'CANCELLED' && driverOtherLoad.driverIds !== undefined && driverOtherLoad.driverIds !== null && driverOtherLoad.driverIds.length > 0 && driverOtherLoad.driverIds.includes(accountUser.id)) {
                                if (driverOtherLoads.find(i => i.id === driverOtherLoad.id) === undefined) {
                                    driverOtherLoads.push(driverOtherLoad);
                                }
                            }
                        });
                    }

                    let driverScheduledShifts = [];

                    if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
                        let isInShift = false;
                        selectedShiftIds.forEach((selectedShiftId) => {
                            if (driverScheduledShifts && driverScheduledShifts.length > 0) {
                                if (driverScheduledShifts.filter(i => i.shiftId === selectedShiftId).length > 0) {
                                    isInShift = true;
                                }
                            }
                        });

                        if (isInShift === true) {
                            driversArray.push({
                                ...accountUser,
                                accountUser: accountUser,
                                driverBundledLoads: driverBundledLoads,
                                driverOtherLoads: driverOtherLoads,
                                scheduledShifts: driverScheduledShifts
                            });
                        }
                    } else {
                        driversArray.push({
                            ...accountUser,
                            accountUser: accountUser,
                            driverBundledLoads: driverBundledLoads,
                            driverOtherLoads: driverOtherLoads,
                            scheduledShifts: driverScheduledShifts
                        });
                    }
                });
            }

            setDriverMap(driversArray);
        } else {
            setDriverMap([]);
        }
    }, [drivers, loads, driver, selectedAccountUserIds, selectedShiftIds]);

    //#endregion
    //#region stop display methods

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    //#endregion
    //#region resource display methods `g

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingScheduledShifts === true || isLoadingLoads === true}>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Select
                        placeholder="Drivers: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAccountUserIds(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                    >
                        {drivers.map(i => <Option value={i.id} key={i.id}>{i.firstName} {i.lastName}{i.title ? ' (' + i.title + ')' : ''}</Option>)}
                    </Select>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {driverMap && driverMap.length > 0 ? (
                        <>
                            <div style={{ height: 500, overflowY: 'auto' }}>
                                {driverMap.map((driver) => {
                                    let accountUser = driver.accountUser;
                                    return (
                                        <Card
                                            key={`dispatch-change-driver-card-${accountUser.id}`}
                                            style={{ marginBottom: 12, borderRadius: '15px' }}
                                            title={
                                                <div style={{ display: 'flex' }}>
                                                    <div className={classes.iconContainer}><FontAwesomeIcon className="anticon" icon={faIdCard} /></div>
                                                    <div className={classes.textContainer}>
                                                        <span><strong>{accountUser.firstName} {accountUser.lastName}</strong><br /><i>Available: {accountUser.driver ? stringFormatter.toYesNo(accountUser.driver.isAvailable) : 'No'}</i></span>
                                                    </div>
                                                </div>
                                            }
                                            extra={<Button type="primary" icon={<FontAwesomeIcon className="anticon" icon={faPlus} />} shape="circle" onClick={(e) => { onChangeDriver(driver.id); }} />}
                                        >
                                        </Card>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}`
                </Col>
            </Row>
        </Spin>
    );
};

export default DispatchChangeDrivers;