import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataHoursOfOperationRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, noColon = false, title = null, data }) => {
    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            {(data !== undefined && data !== null && data.length > 0) ? (
                <>
                    {data.map((dataItem, index) => {
                        return (
                            <div className={className} style={style} key={`data-time-range-row-${index}`}>
                                {dataItem.dayOfWeek !== undefined && dataItem.dayOfWeek !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{stringFormatter.toFormattedString('DayOfWeek', dataItem.dayOfWeek, null)}{noColon === false ? ':' : ''} </span>) : null}
                                {dataItem.startTime !== undefined && dataItem.startTime !== null && dataItem.endTime !== undefined && dataItem.endTime !== null ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentTimeHideTimeZone", dataItem.startTime, null, 'UTC')} - {stringFormatter.toFormattedString("MomentTimeHideTimeZone", dataItem.endTime, null, 'UTC')}</span>) : null}
                            </div>
                        );
                    })}
                </>
            ) : null}
        </div>
    );
};

export default DataHoursOfOperationRow;