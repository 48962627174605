import { isEmpty } from 'lodash';
import React from 'react';

const DataNameRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, title = null, noColon = false, user = null, firstName = null, middleName = null, lastName = null }) => {
    let name = [];
    if (user !== undefined && user !== null && !isEmpty(user)) {
        if (user.firstName !== undefined && user.firstName !== null) {
            name.push(user.firstName);
        }
        if (user.middleName !== undefined && user.middleName !== null) {
            name.push(user.middleName);
        }
        if (user.lastName !== undefined && user.lastName !== null) {
            name.push(user.lastName);
        }
    }

    if (firstName !== undefined && firstName !== null) {
        name.push(firstName);
    }
    if (middleName !== undefined && middleName !== null) {
        name.push(middleName);
    }
    if (lastName !== undefined && lastName !== null) {
        name.push(lastName);
    }

    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            {name !== undefined && name !== null && name.length > 0 ? (<span className={valueClassName} style={valueStyle}>{name.join(' ')}</span>) : null}
        </div>
    );
};

export default DataNameRow;