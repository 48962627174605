import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Row, Col, Spin, Select, Card, message } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import classes from './DispatchAddAsset.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import Enums from '../../shared/enums';
import MomentDate from '../../shared/dateFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { PlusOutlined } from '@ant-design/icons';

const stringFormatter = new StringFormatter();
const momentDate = new MomentDate();

const DispatchAddAsset = props => {
    //#region props and constants

    const load = props.load;
    const assets = props.assets;
    const drivers = props.drivers;
    const loadDriverIds = props.loadDriverIds;
    const onDone = props.cancel;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;

    //#endregion
    //#region Enums

    const assetTypeOptions = Enums.AssetTypes.selectListOptions();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    //#endregion
    //#region useStates

    const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loads, setLoads] = useState([]);
    const [isLoadingLoads, setIsLoadingLoads] = useState(false);
    const [assetMap, setAssetMap] = useState([]);
    const [transportUnits, setTransportUnits] = useState([]);

    //#endregion
    //#region asset methods

    const onAddAsset = (assetId) => {
        if (assetId !== undefined && assetId !== null && load !== undefined && load !== null && !isEmpty(load)) {
            let loadAssetIds = load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? load.assetIds : [];

            if (loadAssetIds.find(i => i === assetId) !== undefined) {
                message.error('The asset you chose is already assigned to this load.', 5);
                return;
            } else {
                loadAssetIds.push(assetId);
            }

            let payload = {
                assetIds: loadAssetIds
            };
            //console.log(payload);

            if (isBundledLoad === true) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
        }
    };

    const onAddTransportUnit = (assets) => {
        if (assets !== undefined && assets !== null && assets.length > 0 && load !== undefined && load !== null && !isEmpty(load)) {
            let loadAssetIds = load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? load.assetIds : [];

            assets.forEach((asset) => {
                if (loadAssetIds.find(i => i === asset.id) === undefined) {
                    if (!loadAssetIds.includes(asset.id)) {
                        loadAssetIds.push(asset.id);
                    }
                }
            });

            let payload = {
                assetIds: loadAssetIds
            };
            //console.log(payload);

            if (isBundledLoad === true) {
                dispatch(actionCreators.updateBundledLoad(load.id, payload, load));
            } else {
                dispatch(actionCreators.updateLoad(load.id, payload));
            }
        }
    };

    const done = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        onDone();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null) {
            let searchParams = {
                page: 1,
                size: 100000,
                isDeleted: false,
                eta: true,
                loadStatus: ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION', 'BOOKED', 'SCHEDULED', 'IN_TRANSIT', 'AT_STOP']
            };

            searchParams["pickUpDateTime:gte"] = startDate;
            searchParams["pickUpDateTime:lte"] = endDate;

            setIsLoadingLoads(true);
            axiosAuthenticated.post('/orch/loads', { ...searchParams }).then((loadsRes) => {
                // console.log(loadsRes.data.data);
                setLoads(loadsRes.data.data);
                setIsLoadingLoads(false);
            }).catch((error) => {
                // console.log(error.message);
                setLoads([]);
                setIsLoadingLoads(false);
            });
        } else {
            setLoads([]);
        }
    }, [startDate, endDate]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            done();
        }
    }, [isLoading, error]);

    useMemo(() => {
        if (load !== undefined && load !== null && !isEmpty(load)) {
            let approxLoadDuration = load ? load.approxLoadDuration : 1; // in minutes
            let approxLoadDurationInDays = Math.ceil((approxLoadDuration / 60) / 24); // in days 
            // console.log(`duration: ${approxLoadDurationInDays}`);
            let startDate = load ? moment(load.pickUpDateTime) : moment();
            let time = moment().set({ hours: 0, minutes: 0, seconds: 0 });
            let startDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate, time, "UTC");
            let endDateValue = momentDate.combineDateAndTimeAsTimeZoneUtcISOString(startDate.add(approxLoadDurationInDays, 'days'), time, "UTC");
            // console.log(startDateValue);
            // console.log(endDateValue);

            setStartDate(startDateValue);
            setEndDate(endDateValue);
        }
    }, [load]);

    useMemo(() => {
        if (assets !== undefined && assets !== null && assets.length > 0 && load !== undefined && load !== null && !isEmpty(load)) {
            let assetsArray = [];

            let existingLoadAssetIds = load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? [...load.assetIds] : [];

            if (selectedAssetTypes !== undefined && selectedAssetTypes !== null && selectedAssetTypes.length > 0) {
                if (existingLoadAssetIds.length > 0) {
                    assets.filter(i => !existingLoadAssetIds.includes(i.id) && selectedAssetTypes.includes(i.assetType)).forEach((asset) => {
                        let assetLoads = loads.filter(i => i.assetIds && i.assetIds.length > 0 && i.assetIds.includes(asset.id));

                        assetsArray.push({
                            ...asset,
                            asset: asset,
                            loads: assetLoads
                        });
                    });
                } else {
                    assets.filter(i => selectedAssetTypes.includes(i.assetType)).forEach((asset) => {
                        let assetLoads = loads.filter(i => i.assetIds && i.assetIds.length > 0 && i.assetIds.includes(asset.id));

                        assetsArray.push({
                            ...asset,
                            asset: asset,
                            loads: assetLoads
                        });
                    });
                }
            } else {
                if (existingLoadAssetIds.length > 0) {
                    assets.filter(i => !existingLoadAssetIds.includes(i.id)).forEach((asset) => {
                        let assetLoads = loads.filter(i => i.assetIds && i.assetIds.length > 0 && i.assetIds.includes(asset.id));

                        assetsArray.push({
                            ...asset,
                            asset: asset,
                            loads: assetLoads
                        });
                    });
                } else {
                    assets.forEach((asset) => {
                        let assetLoads = loads.filter(i => i.assetIds && i.assetIds.length > 0 && i.assetIds.includes(asset.id));

                        assetsArray.push({
                            ...asset,
                            asset: asset,
                            loads: assetLoads
                        });
                    });
                }
            }

            setAssetMap(assetsArray);
            // console.log(assetsArray);
        } else {
            setAssetMap([]);
        }
    }, [assets, loads, selectedAssetTypes, load]);

    useMemo(() => {
        // put together transport units if loadDriverIds is provided
        if (loads !== undefined && loads !== null && loads.length > 0 && drivers !== undefined && drivers !== null && drivers.length > 0 && assets !== undefined && assets !== null && assets.length > 0 && loadDriverIds !== undefined && loadDriverIds !== null && loadDriverIds.length > 0) {
            let transportUnitArray = [];
            loadDriverIds.forEach((loadDriverId) => {
                let loadDriver = drivers.find(i => i.id === loadDriverId);
                if (loadDriver !== undefined && loadDriver !== null) {
                    loads.filter(i => i.driverIds !== undefined && i.driverIds !== null && i.driverIds.length > 0 && i.driverIds.includes(loadDriverId)).forEach((driverLoad) => {
                        if (driverLoad.assets && driverLoad.assets.length > 0) {
                            let driverLoadDrivers = [];
                            let driverLoadAssets = [];
                            if (driverLoad.driverIds && driverLoad.driverIds.length > 0) {
                                driverLoad.driverIds.forEach((driverLoadDriverId) => {
                                    let driverLoadDriver = drivers.find(i => i.id === driverLoadDriverId);
                                    if (driverLoadDriver !== undefined && driverLoadDriver !== null) {
                                        if (!driverLoadDrivers.includes(driverLoadDriver)) {
                                            driverLoadDrivers.push(driverLoadDriver);
                                        }
                                    }
                                });
                            }
                            if (driverLoad.assetIds && driverLoad.assetIds.length > 0) {
                                driverLoad.assetIds.forEach((driverLoadAssetId) => {
                                    let driverLoadAsset = drivers.find(i => i.id === driverLoadAssetId);
                                    if (driverLoadAsset !== undefined && driverLoadAsset !== null) {
                                        if (!driverLoadAssets.includes(driverLoadAsset)) {
                                            driverLoadAssets.push(driverLoadAsset);
                                        }
                                    }
                                });
                            }
                            transportUnitArray.push({
                                driver: loadDriver,
                                driverId: loadDriverId,
                                assets: driverLoadAssets,
                                drivers: driverLoadDrivers,
                                load: driverLoad,
                                loadId: driverLoad.id
                            });
                        }
                    });
                }
            });

            setTransportUnits(transportUnitArray);
            // console.log(transportUnitArray);
        } else {
            setTransportUnits([]);
        }
    }, [loads, loadDriverIds, drivers, assets]);

    //#endregion
    //#region stop display methods

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    const getOriginAndDestinationTimes = (origin, destination) => {
        let timeDisplay = '';
        let originStartObj = getStopStartDateTime(origin);
        let destinationEndObj = getStopEndDateTime(destination);
        let startDateObj = originStartObj.startDateTime;
        let endDateObj = destinationEndObj.endDateTime;
        if (startDateObj !== null && endDateObj !== null) {
            let startTime = originStartObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateObj.timeString;
            let endTime = destinationEndObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateObj.timeString;
            if (startDateObj.dateString === endDateObj.dateString) {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endTime} ${startDateObj.timeZone}`;
            } else {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endDateObj.dateString}, ${endTime} ${startDateObj.timeZone}`;
            }
        }

        return timeDisplay;
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingLoads === true}>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Select
                        placeholder="Asset Types: All"
                        mode="multiple"
                        allowClear={true}
                        style={{ width: '100%' }}
                        virtual={false}
                        onChange={(selected) => { setSelectedAssetTypes(selected); }}
                        showSearch={true}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {assetTypeOptions}
                    </Select>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {assetMap && assetMap.length > 0 ? (
                        <>
                            {transportUnits && transportUnits.length > 0 ? (
                                <>
                                    <div style={{ marginBottom: 12 }}><span><strong>Assets already associated with the Driver(s)</strong></span></div>
                                    {transportUnits.map((transportUnit) => {
                                        let loadObj = transportUnit.load;
                                        let loadTitle = loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId);
                                        let loadTime = getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);
                                        let driver = transportUnit.driver;

                                        return (
                                            <Card
                                                style={{ marginBottom: 12, borderRadius: '15px' }}
                                                title={
                                                    <div style={{ display: 'flex' }}>
                                                        <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                                        <div className={classes.textContainer}>
                                                            <span><strong>Driver: {driver.firstName} {driver.lastName}</strong><br /><i>{`Load: ${loadTitle} (${loadTime})`}</i></span>
                                                        </div>
                                                    </div>
                                                }
                                                extra={<Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={(e) => { onAddTransportUnit(transportUnit.assets); }}></Button>}
                                            >
                                                {transportUnit.assets.map((asset) => {
                                                    let assetType = stringFormatter.toFormattedString("AssetType", asset.assetType, null);
                                                    let trailerType = (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') ? (asset.trailerType !== undefined && asset.trailerType !== null ? asset.trailerType.description : '') : null;
                                                    let licensePlate = `${asset.licensePlateNum} ${asset.licensePlateState}`;
                                                    let assetTitle = asset.name ? asset.name : licensePlate;

                                                    return (
                                                        <div style={{ marginBottom: 12 }}><span><strong>{assetTitle}</strong><br /><i>Type: {assetType}{trailerType ? ' - ' + trailerType : ''}</i></span></div>
                                                    );
                                                })}
                                            </Card>
                                        );
                                    })}
                                </>
                            ) : null}
                            <div style={{ height: 500, overflowY: 'auto' }}>
                                {assetMap.map((asset) => {
                                    let assetType = stringFormatter.toFormattedString("AssetType", asset.assetType, null);
                                    let trailerType = (asset.assetType === 'TRAILER' || asset.assetType === 'COMBINED') ? (asset.trailerType !== undefined && asset.trailerType !== null ? asset.trailerType.description : '') : null;
                                    let licensePlate = `${asset.licensePlateNum} ${asset.licensePlateState}`;
                                    let title = asset.name ? asset.name : licensePlate;

                                    return (
                                        <Card
                                            key={`dispatch-add-asset-card-${asset.id}`}
                                            style={{ marginBottom: 12, borderRadius: '15px' }}
                                            title={
                                                <div style={{ display: 'flex' }}>
                                                    <div className={classes.iconContainer}><FontAwesomeIcon className={classes.icon} icon={faTruck} /></div>
                                                    <div className={classes.textContainer}>
                                                        <span><strong>{title}</strong><br /><i>Type: {assetType}{trailerType ? ' - ' + trailerType : ''}</i></span>
                                                    </div>
                                                </div>
                                            }
                                            extra={<Button type="primary" icon={<PlusOutlined />} shape="circle" onClick={(e) => { onAddAsset(asset.id); }}></Button>}
                                        >
                                            <div><span><strong>Available: {asset ? stringFormatter.toYesNo(asset.isAvailable) : 'No'}</strong></span></div>
                                            {asset.loads && asset.loads.length > 0 ? (
                                                <>
                                                    <div style={{ marginTop: 24 }}><strong>Loads Assigned:</strong></div>
                                                    {asset.loads.map((loadObj) => {
                                                        let title = loadObj.parentName ? loadObj.parentName : (loadObj.name ? loadObj.name : loadObj.irisId);
                                                        let time = getOriginAndDestinationTimes(loadObj.origin, loadObj.destination);
                                                        return (
                                                            <div>
                                                                <span><strong>{`${title} (${time})`}</strong></span>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                        </Card>
                                    );
                                })}
                            </div>
                        </>
                    ) : null}
                </Col>
            </Row>
        </Spin>
    );
};

export default DispatchAddAsset;