import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import moment from 'moment';

//#region Scheduled Shifts Functions

export const fetchScheduledShiftsStart = () => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFTS_START
    }
};

export const fetchScheduledShiftsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFTS_SUCCESS,
        payload: payload
    }
};

export const fetchScheduledShiftsFail = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFTS_FAIL,
        payload: payload
    }
};

export const clearScheduledShifts = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULED_SHIFTS
    }
};

export const fetchScheduledShiftStart = () => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_START
    }
};

export const fetchScheduledShiftSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_SUCCESS,
        payload: payload
    }
};

export const fetchScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.FETCH_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const clearScheduledShift = () => {
    return {
        type: actionTypes.CLEAR_SCHEDULED_SHIFT
    }
};

export const addScheduledShiftStart = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_START
    }
};

export const addScheduledShiftSuccess = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_SUCCESS
    }
};

export const addScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const addScheduledShiftErrorClear = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_ERROR_CLEAR
    }
};

export const addScheduledShiftLoadingClear = () => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT_LOADING_CLEAR
    }
};

export const updateScheduledShiftStart = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_START
    }
};

export const updateScheduledShiftSuccess = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_SUCCESS
    }
};

export const updateScheduledShiftFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_FAIL,
        payload: payload
    }
};

export const updateScheduledShiftErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_ERROR_CLEAR
    }
};

export const updateScheduledShiftLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT_LOADING_CLEAR
    }
};

export const cancelAddScheduledShift = () => {
    return {
        type: actionTypes.CANCEL_ADD_SCHEDULED_SHIFT
    }
};

export const cancelUpdateScheduledShift = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_SCHEDULED_SHIFT
    }
};

export const insertScheduledShift = (payload) => {
    return {
        type: actionTypes.ADD_SCHEDULED_SHIFT,
        payload: payload
    }
};

export const changeScheduledShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SCHEDULED_SHIFT,
        payload: payload
    }
};

export const changeSingleScheduledShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SCHEDULED_SHIFT,
        payload: payload
    }
};

export const removeScheduledShift = (payload) => {
    return {
        type: actionTypes.REMOVE_SCHEDULED_SHIFT,
        payload: payload
    }
};

//#endregion

//#region Scheduled Shifts Methods

export const fetchScheduledShifts = (payload) => {
    return async (dispatch, getState) => {
        const scheduledShiftsPath = '/scheduledShifts/bulk/get';
        try {
            dispatch(fetchScheduledShiftsStart());

            const state = getState();
            const scheduledShiftsState = {...state.scheduledShifts};
            let pagination = {...scheduledShiftsState.pagination};
            let searchParams = {...scheduledShiftsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsPath, {...searchParams});
            if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
                const scheduledShifts = scheduledShiftsRes.data.data;

                const transformedScheduledShifts = scheduledShifts.map((item) => {
                    return {
                        id: item.id,
                        title: item.shift.name,
                        start: moment(item.startDateTime).toDate(),
                        end: moment(item.endDateTime).toDate(),
                        backgroundColor: item.shift.backgroundColor,
                        borderColor: item.shift.backgroundColor,
                        textColor: item.shift.textColor,
                        timeZone: item.shift.timeZone,
                        resourceIds: item.accountUserIds && item.accountUserIds.length > 0 ? [...item.accountUserIds] : [],
                        extendedProps: item,
                        allDay: false
                    };
                });

                // Read total count from server
                pagination.total = scheduledShiftsRes.data.totalCount;
                pagination.current = scheduledShiftsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchScheduledShiftsSuccess({
                    records: transformedScheduledShifts,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchScheduledShiftsFail({error: error.message}));
        }
    }
};

export const fetchScheduledShift = (id) => {
    return async (dispatch) => {
        const scheduledShiftsPath = '/scheduledShifts';
        try {
            dispatch(fetchScheduledShiftStart());

            const scheduledShiftsRes = await axiosAuthenticated.get(scheduledShiftsPath + `/${id}`);
            if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
                const scheduledShift = scheduledShiftsRes.data.data;

                const transformedScheduledShift = {
                    id: scheduledShift.id,
                    title: scheduledShift.shift.name,
                    start: moment(scheduledShift.startDateTime).toDate(),
                    end: moment(scheduledShift.endDateTime).toDate(),
                    backgroundColor: scheduledShift.shift.backgroundColor,
                    borderColor: scheduledShift.shift.backgroundColor,
                    textColor: scheduledShift.shift.textColor,
                    timeZone: scheduledShift.shift.timeZone,
                    resourceIds: scheduledShift.accountUserIds && scheduledShift.accountUserIds.length > 0 ? [...scheduledShift.accountUserIds] : [],
                    extendedProps: scheduledShift,
                    allDay: false
                };

                dispatch(fetchScheduledShiftSuccess({record: transformedScheduledShift}));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchScheduledShiftFail({error: error.message}));
        }
    }
};

export const addScheduledShift = (payload) => {
    return async (dispatch) => {
        const scheduledShiftsPath = '/scheduledShifts';
        try {
            dispatch(addScheduledShiftStart());

            const scheduledShiftsRes = await axiosAuthenticated.post(scheduledShiftsPath, {...payload});
            if (scheduledShiftsRes && scheduledShiftsRes.status === 201) {
                const scheduledShift = scheduledShiftsRes.data;

                let newScheduledShift = {
                    id: scheduledShift.id,
                    title: scheduledShift.shift.name,
                    start: moment(scheduledShift.startDateTime).toDate(),
                    end: moment(scheduledShift.endDateTime).toDate(),
                    backgroundColor: scheduledShift.shift.backgroundColor,
                    borderColor: scheduledShift.shift.backgroundColor,
                    textColor: scheduledShift.shift.textColor,
                    timeZone: scheduledShift.shift.timeZone,
                    resourceIds: scheduledShift.accountUserIds && scheduledShift.accountUserIds.length > 0 ? [...scheduledShift.accountUserIds] : [],
                    extendedProps: scheduledShift,
                    allDay: false
                };

                dispatch(insertScheduledShift(newScheduledShift));

                dispatch(addScheduledShiftSuccess());
                dispatch(addScheduledShiftLoadingClear());
                dispatch(addScheduledShiftErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addScheduledShiftFail({error: error.message}));
        }
    }
};

export const updateScheduledShift = (scheduledShiftId, payload) => {
    return async (dispatch) => {
        const scheduledShiftsPath = '/scheduledShifts';
        try {
            dispatch(updateScheduledShiftStart());

            const scheduledShiftsRes = await axiosAuthenticated.put(scheduledShiftsPath + `/${scheduledShiftId}`, {...payload});
            if (scheduledShiftsRes && scheduledShiftsRes.status === 200) {
                const scheduledShift = scheduledShiftsRes.data;

                if (scheduledShift.isDeleted === true) {
                    dispatch(removeScheduledShift(scheduledShift));
                } else {
                    let updatedScheduledShift = {
                        id: scheduledShift.id,
                        title: scheduledShift.shift.name,
                        start: moment(scheduledShift.startDateTime).toDate(),
                        end: moment(scheduledShift.endDateTime).toDate(),
                        backgroundColor: scheduledShift.shift.backgroundColor,
                        borderColor: scheduledShift.shift.backgroundColor,
                        textColor: scheduledShift.shift.textColor,
                        timeZone: scheduledShift.shift.timeZone,
                        resourceIds: scheduledShift.accountUserIds && scheduledShift.accountUserIds.length > 0 ? [...scheduledShift.accountUserIds] : [],
                        extendedProps: scheduledShift,
                        allDay: false
                    };

                    dispatch(changeScheduledShift(updatedScheduledShift));
                    dispatch(changeSingleScheduledShift(updatedScheduledShift));
                }

                dispatch(updateScheduledShiftSuccess());
                dispatch(updateScheduledShiftLoadingClear());
                dispatch(updateScheduledShiftErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateScheduledShiftFail({error: error.message}));
        }
    }
};

//#endregion