import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, Checkbox, InputNumber, Drawer, Button, Row, Col, Tooltip, Switch } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import { removeEmpty } from '../../shared/objectUtils';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const EditAccount = props => {
    const fullWidth = global.window.innerWidth;
    const account = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const services = useSelector(state => state.orchestrator.services);
    const entityType = useSelector(state => state.auth.entityType);
    const isAdmin = useSelector(state => state.auth.isAdmin);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const billingFrequencyOptions = Enums.BillingFrequencies.selectListOptions();

    const [logoFile, setLogoFile] = useState(null);
    const [accountServices, setAccountServices] = useState([]);
    const [showAddNewService, setShowAddNewService] = useState(false);
    const [showOverridePrice, setShowOverridePrice] = useState(false);

    useMemo(() => {
        if (account.accountServices !== undefined && account.accountServices !== null && account.accountServices.length > 0) {
            let existingAccountServices = [];
            account.accountServices.forEach((accountService) => {
                let service = services.find(i => i.name === accountService.serviceName);
                existingAccountServices.push({
                    ...accountService,
                    service: service
                });
            });
            setAccountServices([...existingAccountServices]);
        }
    }, [account]);

    const onSubmit = (data) => {
        let payload = removeEmpty(data);

        if (accountServices !== undefined && accountServices !== null && accountServices.length > 0) {
            let newServices = [];
            accountServices.forEach((accountService) => {
                delete accountService['service'];
                newServices.push({ ...accountService });
            });

            payload.accountServices = [...newServices];
        } else {
            payload.accountServices = [];
        }

        //console.log(payload);

        if (!isEmpty(payload)) {
            dispatch(actionCreators.updateAccount(account.id, payload, logoFile, account.logoFileId, null, null, null, null, null, null, entityType));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateAccount());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const addService = (data) => {
        //console.log(data);
        let newService = {
            serviceName: data.serviceName
        };

        let service = services.find(i => i.name === data.serviceName);
        if (service !== undefined) {
            newService.service = service;
        }

        if (data.isPriceOverride === true) {
            newService.isPriceOverride = true;
            newService.priceAmount = data.priceAmount;
            newService.priceAmountUnit = data.priceAmountUnit;
            newService.billingFrequency = data.billingFrequency;
        } else {
            newService.isPriceOverride = false;
            newService.priceAmount = 0;
        }

        //console.log(newServices);
        setAccountServices(oldList => [...oldList, { ...newService }]);
        setShowAddNewService(false);
        setShowOverridePrice(false);
    };

    const removeService = (index) => {
        let updatedServices = [...accountServices];
        updatedServices.splice(index, 1);
        setAccountServices([...updatedServices]);
    };

    const toggleNewService = () => {
        if (showAddNewService === true) {
            setShowAddNewService(false);
            setShowOverridePrice(false);
        } else {
            setShowAddNewService(true);
        }
    };

    const onIsPriceOverrideChange = (value) => {
        //console.log(value);
        if (value === true) {
            setShowOverridePrice(true);
        } else {
            setShowOverridePrice(false);
        }
    };

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <>
            {account !== undefined && account !== null ? (
                <>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                                <Fieldset legend="Company Details (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                        rules={{ required: 'Required Field' }}
                                        name="name"
                                        defaultValue={account.name ? account.name : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Federal Id/EIN" required format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" />}
                                        rules={{ required: 'Required Field' }}
                                        name="ein"
                                        defaultValue={account.ein ? account.ein : ""}
                                    />
                                    <FormItemFile {...formItemLayout} label="Company Logo" name="logoFile" format="horizontal">
                                        <UploadFile
                                            beforeUpload={(file) => {
                                                setLogoFile(file);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                setLogoFile(null);
                                            }}
                                            message="Please upload a photo of your company's logo."
                                            fileId={account.logoFileId}
                                        />
                                    </FormItemFile>
                                </Fieldset>
                                <Fieldset legend="Company Contact Information (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Company Email" required format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="email"
                                        defaultValue={account.email ? account.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Company Day Time Phone #" required1 format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="phone"
                                        defaultValue1={account.phone ? account.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="phoneExt"
                                        defaultValue2={account.phoneExt ? account.phoneExt : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Company After Hours Phone #" required1 format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="afterHoursPhone"
                                        defaultValue1={account.afterHoursPhone ? account.afterHoursPhone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="afterHoursPhoneExt"
                                        defaultValue2={account.afterHoursPhoneExt ? account.afterHoursPhoneExt : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Company Fax #" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" />}
                                        rules={{ required: false }}
                                        name="fax"
                                        defaultValue={account.fax ? account.fax : ""}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Main Point of Contact (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                        rules={{ required: "Required Field" }}
                                        name="pointOfContact.name"
                                        defaultValue={account.pointOfContact && account.pointOfContact.name ? account.pointOfContact.name : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="pointOfContact.email"
                                        defaultValue={account.pointOfContact && account.pointOfContact.email ? account.pointOfContact.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal" required1
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="pointOfContact.phone"
                                        defaultValue1={account.pointOfContact && account.pointOfContact.phone ? account.pointOfContact.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="pointOfContact.phoneExt"
                                        defaultValue2={account.pointOfContact && account.pointOfContact.phoneExt ? account.pointOfContact.phoneExt : ""}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Main Physical Address (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.streetAddress1"
                                        defaultValue={account.companyAddress && account.companyAddress.streetAddress1 ? account.companyAddress.streetAddress1 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="companyAddress.streetAddress2"
                                        defaultValue={account.companyAddress && account.companyAddress.streetAddress2 ? account.companyAddress.streetAddress2 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.city"
                                        defaultValue={account.companyAddress && account.companyAddress.city ? account.companyAddress.city : ""}
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.state"
                                        defaultValue={account.companyAddress && account.companyAddress.state ? account.companyAddress.state : undefined}
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.postalCode"
                                        defaultValue={account.companyAddress && account.companyAddress.postalCode ? account.companyAddress.postalCode : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal" required
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.country"
                                        defaultValue={account.companyAddress && account.companyAddress.country ? account.companyAddress.country : undefined}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Mailing Address (if different from the Company's Physical Address)">
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.streetAddress1"
                                        defaultValue={account.shippingAddress && account.shippingAddress.streetAddress1 ? account.shippingAddress.streetAddress1 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.streetAddress2"
                                        defaultValue={account.shippingAddress && account.shippingAddress.streetAddress2 ? account.shippingAddress.streetAddress2 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.city"
                                        defaultValue={account.shippingAddress && account.shippingAddress.city ? account.shippingAddress.city : ""}
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="shippingAddress.state"
                                        defaultValue={account.shippingAddress && account.shippingAddress.state ? account.shippingAddress.state : undefined}
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.postalCode"
                                        defaultValue={account.shippingAddress && account.shippingAddress.postalCode ? account.shippingAddress.postalCode : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="shippingAddress.country"
                                        defaultValue={account.shippingAddress && account.shippingAddress.country ? account.shippingAddress.country : undefined}
                                    />
                                </Fieldset>
                                <Fieldset legend="Other Company Details">
                                    <FormItem {...formItemLayout} label="Is Active" format="horizontal"
                                        name="isActive"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                                name={name}
                                            />
                                        )}
                                        defaultValue={account.isActive}
                                    />
                                </Fieldset>
                                {(entityType === "STAFF" && isAdmin === true) ? (
                                    <Fieldset legend="Services (if applicable)">
                                        {accountServices.map((item, index) => (
                                            <Row gutter={16} key={`services-${index}`}>
                                                <Col span={18}>
                                                    {item.service && (
                                                        <FormItemDisplay label={item.service && item.service.displayName ? item.service.displayName : item.serviceName} format="horizontal">
                                                            <DataRow title="Price" value={item.isPriceOverride ? item.priceAmount : item.service.priceAmount} units={item.isPriceOverride ? item.priceAmountUnit : item.service.priceAmountUnit} dataType="Money" />
                                                            <DataRow title="Billing Frequency" value={item.isPriceOverride ? item.billingFrequency : item.service.billingFrequency} dataType="BillingFrequency" />
                                                        </FormItemDisplay>
                                                    )}
                                                </Col>
                                                <Col span={6}>
                                                    <Button icon={<DeleteOutlined />} onClick={() => { removeService(index); }} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Tooltip placement="left" title="Add Service">
                                            <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewService} style={{ margin: "10px", float: "right" }} />
                                        </Tooltip>
                                    </Fieldset>
                                ) : null}
                                {error && <Alert message={`${error}`} type="error" />}
                            </Spin>
                            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Account" />
                        </Form>
                    </FormProvider>
                    <Drawer
                        title={'Add New Service'}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        onClose={toggleNewService}
                        visible={showAddNewService}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormProvider {...methods2}>
                            <Form onSubmit={methods2.handleSubmit(addService)}>
                                <FormItem {...formItemLayout} label="Service" format="vertical" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Service"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {services.map(a => <Option value={a.name} key={a.name}>{`${a.displayName} - ${stringFormatter.toFormattedMoney(a.priceAmount)}${a.billingFrequency !== 'NONE' ? ' billed ' + stringFormatter.toFormattedString('BillingFrequency', a.billingFrequency, null) : ''}`}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="serviceName"
                                />
                                <FormItem {...formItemLayout} label="Over-Ride Price?" format="vertical"
                                    name="isPriceOverride"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onBlur={onBlur}
                                            onChange={(checked, event) => { onChange(checked); onIsPriceOverrideChange(checked); }}
                                            checked={value}
                                            name={name}
                                        />
                                    )}
                                    defaultValue={false}
                                />
                                {(showOverridePrice === true) && (
                                    <>
                                        <FormItemDouble {...formItemLayoutDouble} label1="Over-Ride Price" required1 required2 format="vertical"
                                            render1={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    precision={2}
                                                    min={0}
                                                    placeholder="Enter the Over-Ride Price"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules1={{ required: "Required Field" }}
                                            name1="priceAmount"
                                            render2={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please select the Price Units"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {currencyOptions}
                                                </Select>
                                            )}
                                            rules2={{ required: "Required Field" }}
                                            name2="priceAmountUnit"
                                        />
                                        <FormItem {...formItemLayout} label="Over-Ride Billing Frequency" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <Select
                                                    placeholder="Please select the Over-Ride Billing Frequency"
                                                    allowClear={true}
                                                    style={{ width: '100%' }}
                                                    virtual={false}
                                                    onBlur={onBlur}
                                                    onChange={(selected) => { onChange(selected); }}
                                                    value={value}
                                                    name={name}
                                                    showSearch={true}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {billingFrequencyOptions}
                                                </Select>
                                            )}
                                            rules={{ required: "Required Field" }}
                                            name="billingFrequency"
                                        />
                                    </>
                                )}
                                <FormButtons cancel={toggleNewService} submitText="Add Service" />
                            </Form>
                        </FormProvider>
                    </Drawer>
                </>
            ) : null}
        </>
    );
};

export default EditAccount;