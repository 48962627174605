import React, { useMemo, useState, useEffect } from 'react';
import { Input, Select, Spin, Alert } from "antd";
import { isEmpty } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Form from '../Form/Form';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const { Option } = Select;
const { Search } = Input;

const NewLinkedAccount = props => {
    const isShipper = props.isShipper === true;
    const isCarrier = props.isCarrier === true;
    const isReceiver = props.isReceiver === true;
    const isProducer = props.isProducer === true;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordAddLoading);
    const error = useSelector(state => state.linkedAccounts.addRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const account = useSelector(state => state.accounts.record);

    const [showAccountSetup, setShowAccountSetup] = useState(false);
    const [linkedAccount, setLinkedAccount] = useState(null);
    const [doesAccountExist, setDoesAccountExist] = useState(false);
    const [searchError, setSearchError] = useState(null);

    const onSubmit = (data) => {
        console.log(data);
        let payload = removeEmpty(data);

        payload.accountId = entityId;
        payload.isShipper = isShipper;
        payload.isCarrier = isCarrier;
        payload.isReceiver = isReceiver;
        payload.isProducer = isProducer;

        if (entityType === "SHIPPER") {
            payload.isInvited = true;
        }

        if (linkedAccount !== undefined && linkedAccount !== null) {
            payload.linkedAccountId = linkedAccount.id;
        }

        if (!isEmpty(payload)) {
            dispatch(actionCreators.addLinkedAccount(payload));
        }
    };

    const onSearchByMCNumber = (value) => {
        if (value && value !== '' && value.length > 0) {
            let mcNum = account && account.carrier ? account.carrier.mcNum : undefined;
            if (value !== mcNum) {
                setSearchError(null);
                axiosAuthenticated.get('/accounts?carrier.mcNum=' + value).then(res => {
                    console.log(res);
                    const existingAccounts = res.data.data;
                    if (existingAccounts !== undefined && existingAccounts !== null && existingAccounts.length > 0) {
                        setLinkedAccount(existingAccounts[0]);
                        setDoesAccountExist(true);
                        setShowAccountSetup(true);
                    } else {
                        setLinkedAccount(null);
                        setDoesAccountExist(false);
                        setShowAccountSetup(true);
                    }
                }).catch(err => {
                    console.log(err);
                    setLinkedAccount(null);
                    setDoesAccountExist(false);
                    setShowAccountSetup(true);
                });
            } else {
                setSearchError('The MC Number you provided matches the MC Number tied to your account. You cannot link to your own account.');
            }
        }
    };

    const onSearchByEIN = (value) => {
        if (value && value !== '' && value.length > 0) {
            let ein = account ? account.ein : undefined;
            console.log(ein)
            console.log(value)
            if (value !== ein) {
                setSearchError(null);
                axiosAuthenticated.get('/accounts?ein:contains=' + value).then(res => {
                    console.log(res);
                    const existingAccounts = res.data.data;
                    if (existingAccounts !== undefined && existingAccounts !== null && existingAccounts.length > 0) {
                        setLinkedAccount(existingAccounts[0]);
                        setDoesAccountExist(true);
                        setShowAccountSetup(true);
                    } else {
                        setLinkedAccount(null);
                        setDoesAccountExist(false);
                        setShowAccountSetup(true);
                    }
                }).catch(err => {
                    console.log(err);
                    setLinkedAccount(null);
                    setDoesAccountExist(false);
                    setShowAccountSetup(true);
                });
            } else {
                setSearchError('The EIN you provided matches the EIN tied to your account. You cannot link to your own account.');
            }
        }
    };

    useEffect(() => {
        // console.log(`linkedAccount: ${JSON.stringify(linkedAccount)}`);
        // console.log(`showAccountSetup: ${showAccountSetup}`);
        // console.log(`doesAccountExist: ${doesAccountExist}`);
        if (doesAccountExist === true && showAccountSetup === true) {
            if (linkedAccount !== undefined && linkedAccount !== null) {
                methods.setValue('name', linkedAccount.name);
            }
        }
    }, [linkedAccount, showAccountSetup, doesAccountExist]);

    const cancel = () => {
        dispatch(actionCreators.cancelAddLinkedAccount());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLinkedAccountErrorClear());
        setLinkedAccount(null);
        setDoesAccountExist(false);
        setShowAccountSetup(false);
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    if (isCarrier === true) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>First Search for the Carrier by MC # to see if they Already Exist in the System</legend>
                            <FormItem {...formItemLayout} label="MC #" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) =>
                                    <Search
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="Search by MC #"
                                        onSearch={onSearchByMCNumber}
                                    />}
                                rules={{ required: "Required Field" }}
                                name="mcNum"
                                help={
                                    <div>
                                        <strong>Enter the MC # and click the search button to continue</strong>
                                    </div>
                                }
                            />
                        </Fieldset>
                        {showAccountSetup === true && doesAccountExist === false && (
                            <Fieldset>
                                <legend>Carrier Details</legend>
                                <FormItem {...formItemLayout} label="US DOT #" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" />}
                                    rules={{ required: "Required Field" }}
                                    name="dotNum"
                                />
                            </Fieldset>
                        )}
                        {showAccountSetup === true && (
                            <>
                                <Fieldset>
                                    <legend>Company Details</legend>
                                    {doesAccountExist === true && (
                                        <FormItem {...formItemLayout} label="Company Name" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: false }}
                                            name="name"
                                        />
                                    )}
                                    {doesAccountExist === false && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: "Required Field" }}
                                            name="name"
                                        />
                                    )}
                                    <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" />}
                                        rules={{ required: false }}
                                        name="customerId"
                                    />
                                    {doesAccountExist === false && (
                                        <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="EIN" />}
                                            rules={{ required: "Required Field" }}
                                            name="ein"
                                        />
                                    )}
                                </Fieldset>
                                {(entityType !== "SHIPPER" || doesAccountExist === true) && (
                                    <Fieldset>
                                        <legend>Point of Contact</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: false }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                {(entityType === "SHIPPER" && doesAccountExist === false) && (
                                    <Fieldset>
                                        <legend>Point of Contact (we will send an invite to the contact listed below so they can setup their account)</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                <Fieldset isCollapsable legend="Accounts Payable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsPayable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsPayable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsPayable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsPayable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress2" format="horizontal"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.country"
                                    />
                                </Fieldset>
                                <Fieldset isCollapsable legend="Accounts Receivable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsReceivable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsReceivable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsReceivable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsReceivable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.country"
                                    />
                                </Fieldset>
                            </>
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                        {searchError && <Alert message={`${searchError}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} submitDisabled={showAccountSetup === false} disabled={isLoading === true && error === null} submitText="Add Carrier" />
                </Form>
            </FormProvider>
        );
    } else if (isShipper === true) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>First Search for the Company by EIN to see if they Already Exist in the System</legend>
                            <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) =>
                                    <Search
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="EIN"
                                        onSearch={onSearchByEIN}
                                    />}
                                rules={{ required: "Required Field" }}
                                name="ein"
                                help={
                                    <div>
                                        <strong>Enter the EIN and click the search button to continue</strong>
                                    </div>
                                }
                            />
                        </Fieldset>
                        {showAccountSetup === true && (
                            <>
                                <Fieldset>
                                    <legend>Company Details</legend>
                                    {doesAccountExist === true && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: false }}
                                            name="name"
                                        />
                                    )}
                                    {doesAccountExist === false && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: "Required Field" }}
                                            name="name"
                                        />
                                    )}
                                    <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" />}
                                        rules={{ required: false }}
                                        name="customerId"
                                    />
                                </Fieldset>
                                {(entityType !== "SHIPPER" || doesAccountExist === true) && (
                                    <Fieldset>
                                        <legend>Point of Contact</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: false }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                {(entityType === "SHIPPER" && doesAccountExist === false) && (
                                    <Fieldset>
                                        <legend>Point of Contact (we will send an invite to the contact listed below so they can setup their account)</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                <Fieldset isCollapsable legend="Accounts Payable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsPayable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsPayable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsPayable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsPayable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.country"
                                    />
                                </Fieldset>
                                <Fieldset isCollapsable legend="Accounts Receivable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsReceivable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsReceivable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsReceivable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsReceivable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.country"
                                    />
                                </Fieldset>
                            </>
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                        {searchError && <Alert message={`${searchError}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} submitDisabled={showAccountSetup === false} disabled={isLoading === true && error === null} submitText="Add Shipper" />
                </Form>
            </FormProvider>
        );
    } else if (isReceiver === true) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>First Search for the Company by EIN to see if they Already Exist in the System</legend>
                            <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) =>
                                    <Search
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="EIN"
                                        onSearch={onSearchByEIN}
                                    />}
                                rules={{ required: "Required Field" }}
                                name="ein"
                                help={
                                    <div>
                                        <strong>Enter the EIN and click the search button to continue</strong>
                                    </div>
                                }
                            />
                        </Fieldset>
                        {showAccountSetup === true && (
                            <>
                                <Fieldset>
                                    <legend>Company Details</legend>
                                    {doesAccountExist === true && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: false }}
                                            name="name"
                                        />
                                    )}
                                    {doesAccountExist === false && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: "Required Field" }}
                                            name="name"
                                        />
                                    )}
                                    <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" />}
                                        rules={{ required: false }}
                                        name="customerId"
                                    />
                                </Fieldset>
                                {(entityType !== "SHIPPER" || doesAccountExist === true) && (
                                    <Fieldset>
                                        <legend>Point of Contact</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: false }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                {(entityType === "SHIPPER" && doesAccountExist === false) && (
                                    <Fieldset>
                                        <legend>Point of Contact (we will send an invite to the contact listed below so they can setup their account)</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                <Fieldset isCollapsable legend="Accounts Payable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsPayable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsPayable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsPayable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsPayable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.country"
                                    />
                                </Fieldset>
                                <Fieldset isCollapsable legend="Accounts Receivable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsReceivable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsReceivable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsReceivable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsReceivable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.country"
                                    />
                                </Fieldset>
                            </>
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                        {searchError && <Alert message={`${searchError}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} submitDisabled={showAccountSetup === false} disabled={isLoading === true && error === null} submitText="Add Receiver" />
                </Form>
            </FormProvider>
        );
    } else if (isProducer === true) {
        return (
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>First Search for the Company by EIN to see if they Already Exist in the System</legend>
                            <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) =>
                                    <Search
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="EIN"
                                        onSearch={onSearchByEIN}
                                    />}
                                rules={{ required: "Required Field" }}
                                name="ein"
                                help={
                                    <div>
                                        <strong>Enter the EIN and click the search button to continue</strong>
                                    </div>
                                }
                            />
                        </Fieldset>
                        {showAccountSetup === true && (
                            <>
                                <Fieldset>
                                    <legend>Company Details</legend>
                                    {doesAccountExist === true && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: false }}
                                            name="name"
                                        />
                                    )}
                                    {doesAccountExist === false && (
                                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                            rules={{ required: "Required Field" }}
                                            name="name"
                                        />
                                    )}
                                    <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" />}
                                        rules={{ required: false }}
                                        name="customerId"
                                    />
                                </Fieldset>
                                {(entityType !== "SHIPPER" || doesAccountExist === true) && (
                                    <Fieldset>
                                        <legend>Point of Contact</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: false }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                {(entityType === "SHIPPER" && doesAccountExist === false) && (
                                    <Fieldset>
                                        <legend>Point of Contact (we will send an invite to the contact listed below so they can setup their account)</legend>
                                        <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                            rules={{ required: 'Required Field' }}
                                            name="pointOfContact.name"
                                        />
                                        <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                            rules={{
                                                required: 'Required Field',
                                                pattern: {
                                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            }}
                                            name="pointOfContact.email"
                                            defaultValue={''}
                                        />
                                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                            rules1={{
                                                required: false,
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                                }
                                            }}
                                            name1="pointOfContact.phone"
                                            defaultValue1={''}
                                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                            rules2={{ required: false }}
                                            name2="pointOfContact.phoneExt"
                                        />
                                    </Fieldset>
                                )}
                                <Fieldset isCollapsable legend="Accounts Payable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsPayable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsPayable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsPayable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsPayable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsPayableAddress.country"
                                    />
                                </Fieldset>
                                <Fieldset isCollapsable legend="Accounts Receivable">
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: false }}
                                        name="accountsReceivable.name"
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: false,
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsReceivable.email"
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: false,
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsReceivable.phone"
                                        defaultValue1={''}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsReceivable.phoneExt"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress1"
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.streetAddress2"
                                    />
                                    <FormItem {...formItemLayout} label="City" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.city"
                                    />
                                    <FormItem {...formItemLayout} label="State" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.state"
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.postalCode"
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="accountsReceivableAddress.country"
                                    />
                                </Fieldset>
                            </>
                        )}
                        {error && <Alert message={`${error}`} type="error" />}
                        {searchError && <Alert message={`${searchError}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} submitDisabled={showAccountSetup === false} disabled={isLoading === true && error === null} submitText="Add Producer" />
                </Form>
            </FormProvider>
        );
    } else {
        return null;
    }
};

export default NewLinkedAccount;