import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import LinkedLocations from '../LinkedLocations/LinkedLocations';
import LinkedLoadLanes from '../LinkedLoadLanes/LinkedLoadLanes';

const { TabPane } = Tabs;

const LinkedAccount = props => {
    const id = props.match.params.id;
    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordLoading);
    const error = useSelector(state => state.linkedAccounts.recordError);
    const linkedAccount = useSelector(state => state.linkedAccounts.record);
    const entityType = useSelector(state => state.auth.entityType);

    const [tabKey, setTabKey] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useMemo(() => {
        //console.log('location changed');
        //console.log(location);
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            if (linkedAccount) {
                if (linkedAccount.isCarrier) {
                    setTabKey('assets');
                    props.history.replace({ ...location, hash: '#assets' });
                } else {
                    setTabKey('locations');
                    props.history.replace({ ...location, hash: '#locations' });
                }
            }
        }
    }, [location, linkedAccount]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchLinkedAccount(id));
    }, [id]);

    useEffect(() => {
        if (linkedAccount && linkedAccount.logoFileId) {
            axiosAuthenticated.get('/documents/' + linkedAccount.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [linkedAccount]);

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/linkedAccounts` },
        path: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/linkedAccounts`,
        breadcrumbName: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'All Accounts',
    },
    {
        path: `/linkedAccounts/${id ? id : null}`,
        breadcrumbName: `${linkedAccount ? linkedAccount.name : ''}`,
    },
    ];

    if (linkedAccount !== undefined && linkedAccount !== null && !isEmpty(linkedAccount) && linkedAccount.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={linkedAccount && linkedAccount.name ? linkedAccount.name : (linkedAccount && linkedAccount.linkedAccount ? linkedAccount.linkedAccount.name : 'Loading...')}
                    title2={linkedAccount && linkedAccount.linkedAccount ? (<ValueFormatting.Address address={linkedAccount.linkedAccount.companyAddress} />) : ''}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                    breadcrumbs={breadcrumbs}
                    highlights={linkedAccount && linkedAccount.isCarrier ? [
                        {
                            title: 'DOT #',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.carrier && linkedAccount.linkedAccount.carrier.dotNum ? linkedAccount.linkedAccount.carrier.dotNum : 'N/A'}`
                        },
                        {
                            title: 'MC #',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.carrier && linkedAccount.linkedAccount.carrier.mcNum ? linkedAccount.linkedAccount.carrier.mcNum : 'N/A'}`
                        },
                        {
                            title: 'SMS Score',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.carrier && linkedAccount.linkedAccount.carrier.smsBasic && linkedAccount.linkedAccount.carrier.smsBasic.weightedAvgSMSScore ? linkedAccount.linkedAccount.carrier.smsBasic.weightedAvgSMSScore : 'N/A'}`
                        },
                    ] : (linkedAccount && linkedAccount.isShipper ? [
                        {
                            title: 'EIN #',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.ein ? linkedAccount.linkedAccount.ein : 'N/A'}`
                        },
                        {
                            title: 'D&B #',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.shipper && linkedAccount.linkedAccount.shipper.dbNum ? linkedAccount.linkedAccount.shipper.dbNum : 'N/A'}`
                        },
                        {
                            title: 'PBB #',
                            value: `${linkedAccount && linkedAccount.linkedAccount && linkedAccount.linkedAccount.shipper && linkedAccount.linkedAccount.shipper.blueBookNum ? linkedAccount.linkedAccount.shipper.blueBookNum : 'N/A'}`
                        },
                    ] : [])}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    {linkedAccount && (linkedAccount.isShipper || linkedAccount.isProducer || linkedAccount.isReceiver) && (
                        <TabPane tab="Locations" key="locations">
                            <div style={{ padding: 24 }}>
                                <LinkedLocations accountId={linkedAccount.accountId} linkedAccountId={linkedAccount.linkedAccountId} />
                            </div>
                        </TabPane>
                    )}
                    {linkedAccount && linkedAccount.isShipper && (
                        <TabPane tab="Lanes" key="lanes">
                            <div style={{ padding: 24 }}>
                                <LinkedLoadLanes accountId={linkedAccount.accountId} linkedAccountId={linkedAccount.linkedAccountId} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    )}
                </Tabs>
            </Spin>
        );
    } else {
        return (<Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />);
    }
};

export default withRouter(LinkedAccount);