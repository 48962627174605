import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Drawer, Modal, Dropdown, Menu } from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from 'lodash';
import DataRow from '../DataRow/DataRow';
import ComplexDataLinkCard from '../ComplexDataLinkCard/ComplexDataLinkCard';
import SimpleDataLinkCard from "../../components/SimpleDataLinkCard/SimpleDataLinkCard";
import SimpleStatsLinkCard from "../../components/SimpleStatsLinkCard/SimpleStatsLinkCard";
import ComplexCard from '../ComplexCard/ComplexCard';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import { DownOutlined, PaperClipOutlined, ControlOutlined } from '@ant-design/icons';
import EditShipper from "../../components/EditShipper/EditShipper";
import DocumentList from "../../components/DocumentList/DocumentList";
import EditAccount from "../../components/EditAccount/EditAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckLoading, faUsers } from '@fortawesome/free-solid-svg-icons';

const stringFormatter = new StringFormatter();

const CompanyShipperSnapshot = props => {
    const fullWidth = global.window.innerWidth;
    const account = props.record;

    const [metrics, setMetrics] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showEditShipper, setShowEditShipper] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

    const dispatch = useDispatch();
    const allMetrics = useSelector(state => state.metrics.metrics);
    const entityType = useSelector(state => state.auth.entityType);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditShipper = () => {
        setShowEditShipper(!showEditShipper);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: '10px' }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
                <Menu.Item key="editShipper" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditShipper(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: '10px' }} />
                    <span>Edit Shipper Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount cancel={toggleEditEntity} record={account} />
        </Drawer>
    );

    const editShipperComponents = (
        <Drawer
            title={"Edit Shipper Details"}
            onClose={toggleEditShipper}
            visible={showEditShipper}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditShipper cancel={toggleEditShipper} record={account} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Shipper Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {account !== undefined && account !== null &&
                <DocumentList entityType="ACCOUNT" account={account} entityId={account.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} />
            }
            {account !== undefined && account !== null && account.isVerified === false && entityType === 'STAFF' ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    useEffect(() => {
        if (account !== undefined && account !== null) {
            if (allMetrics !== undefined && allMetrics !== null && !isEmpty(allMetrics)) {
                //console.log(allMetrics[account.id]);
                if (allMetrics[account.id] !== undefined && allMetrics[account.id] !== null && !isEmpty(allMetrics[account.id])) {
                    setMetrics(allMetrics[account.id]);
                } else {
                    dispatch(actionCreators.fetchMetrics('SHIPPER', account.id));
                }
            } else {
                dispatch(actionCreators.fetchMetrics('SHIPPER', account.id));
            }
        }
    }, [allMetrics, account]);

    if (account && account.shipper) {
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexDataLinkCard
                            title="Shipper Details"
                            headStyle={{ fontSize: 14 }}
                            data={{
                                "Account ID": { value: account.irisId, dataType: 'String' },
                                "Shipper ID": { value: account.shipper.irisId, dataType: 'String' },
                                "Locations": { value: metrics && metrics.totalLocations ? metrics.totalLocations : 0, dataType: 'Integer', url: entityType === 'SHIPPER' ? '/locations' : '#locations' },
                                "Employees": { value: metrics && metrics.totalEmployees ? metrics.totalEmployees : 0, dataType: 'Integer', url: entityType === 'SHIPPER' ? '/employees' : '#employees' }
                            }}
                            extra={extra}
                        />
                    </Col>
                    <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexDataLinkCard
                            title="Freight Spend"
                            style={{ border: '1px solid #b7eb8f' }}
                            headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                            data={{
                                "Total Spend": { value: metrics && metrics.totalFreightSpend ? Math.abs(metrics.totalFreightSpend) : 0, units: metrics && metrics.totalFreightSpend && metrics.totalFreightSpendUnit ? metrics.totalFreightSpendUnit : null, dataType: 'Money' },
                                "Total Completed Loads": { value: metrics && metrics.totalCompletedLoads ? metrics.totalCompletedLoads : 0, dataType: 'Integer', url: entityType === 'SHIPPER' ? '/completedLoads' :  '#completedLoads' },
                                "Spend This Month": { value: metrics && metrics.totalFreightSpendThisMonth ? Math.abs(metrics.totalFreightSpendThisMonth) : 0, units: metrics && metrics.totalFreightSpendThisMonth && metrics.totalFreightSpendThisMonthUnit ? metrics.totalFreightSpendThisMonthUnit : null, dataType: 'Money' },
                                "Completed Loads This Mo": { value: metrics && metrics.completedLoadsThisMonth ? metrics.completedLoadsThisMonth : 0, dataType: 'Integer', url: entityType === 'SHIPPER' ? '/completedLoads' :  '#completedLoads' }
                            }}
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 12 }}>
                        <SimpleStatsLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="Loads To Cover"
                            amountSize={1}
                            amount={metrics && metrics.loadsNeedingCoverage ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage, 0, null, null) : 0}
                            url={entityType === 'SHIPPER' ? '/openLoads' : '#openLoads'}
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 12 }}>
                        <SimpleDataLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="RFP's"
                            dataSize={3}
                            data={'Coming Soon'}
                            url="#lanes"
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 12 }}>
                        <SimpleDataLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="Requests to Haul"
                            dataSize={3}
                            data={'Coming Soon'}
                            url="#requestsToHaul"
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Shipper Operations"
                            headStyle={{ fontSize: 14 }}
                        >
                            {account.shipper.pointOfContact !== undefined && account.shipper.pointOfContact !== null && account.shipper.pointOfContact.name !== undefined && account.shipper.pointOfContact.name !== null ? <DataRow title="Operations Contact" value={account.shipper.pointOfContact.name} /> : null}
                            {account.shipper.pointOfContact !== undefined && account.shipper.pointOfContact !== null && account.shipper.pointOfContact.phone !== undefined && account.shipper.pointOfContact.phone !== null ? <DataPhoneNumberRow title="Operations Phone" phone={account.shipper.pointOfContact.phone} phoneExt={account.shipper.pointOfContact.phoneExt} /> : null}
                            {account.shipper.pointOfContact !== undefined && account.shipper.pointOfContact !== null && account.shipper.pointOfContact.email !== undefined && account.shipper.pointOfContact.email !== null ? <DataRow title="Operations Email" value={account.shipper.pointOfContact.email} /> : null}
                            {account.afterHoursPhone !== undefined && account.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                        </ComplexCard>
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 12 }}>
                        <SimpleDataLinkCard
                            title="Active Loads"
                            headStyle={{ fontSize: 14 }}
                            dataSize={1}
                            data={metrics && metrics.totalActiveLoads ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                            url={entityType === 'SHIPPER' ? '/intransitLoads' : '#intransitLoads'}
                        />
                    </Col>
                    <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Inventory Information"
                            style={{ border: '1px solid #ffe58f' }}
                            headStyle={{ backgroundColor: '#fffbe6', borderBottom: '1px solid #ffe58f', fontSize: 14 }}
                        >
                            <div><b>Commodities:</b> <a style={{ textDecoration: 'underline' }} href={entityType === 'SHIPPER' ? '/commodities' : '#commodities'}>{metrics && metrics.totalCommodities ? metrics.totalCommodities : 0}</a></div>
                        </ComplexCard>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Shipper Accounts Payable"
                            headStyle={{ fontSize: 14 }}
                        >
                            {account.shipper.accountsPayable !== undefined && account.shipper.accountsPayable !== null ? <DataContactInfoRow title="A/P" separateLines={true} contact={account.shipper.accountsPayable} /> : null}
                            {account.shipper.billingAddress !== undefined && account.shipper.billingAddress !== null ? <DataAddressRow title="A/P Address" oneLine={true} address={account.shipper.billingAddress} /> : null}
                        </ComplexCard>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Additional Information"
                            headStyle={{ fontSize: 14 }}
                        >
                            {entityType === "STAFF" &&
                                <div><b>Documents:</b> <a style={{ textDecoration: 'underline' }} onClick={toggleDocumentsModal}>view</a></div>
                            }
                        </ComplexCard>
                    </Col>
                </Row>
                {editEntityComponents}
                {editShipperComponents}
                {documentsComponent}
            </>
        );
    } else {
        return null;
    }
};

export default CompanyShipperSnapshot;