import * as Sentry from '@sentry/browser';

const logReduxErrorEvent = (error, errorMessage, writeToConsole = false) => {
    try {
        Sentry.captureException(error);
    } catch (ex) {
        console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(errorMessage);
        } catch (ex) {
            console.log(ex);
        }
    }
};

const logErrorEvent = (eventName, error, errorMessage, writeToConsole = false) => {
    try {
        Sentry.captureException(error);
    } catch (ex) {
        console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(errorMessage);
            console.log(error);
        } catch (ex) {
            console.log(ex);
        }
    }
};

const logEvent = (eventName, message, writeToConsole = false) => {
    try {
        Sentry.captureMessage(message, Sentry.Severity.Debug);
    } catch (ex) {
        console.log(ex);
    }

    if (writeToConsole === true) {
        try {
            console.log(message);
        } catch (ex) {
            console.log(ex);
        }
    }
};

export default {
    logReduxErrorEvent,
    logErrorEvent,
    logEvent
};