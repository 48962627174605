import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from "antd";
import LoadMap from "../../components/LoadMap/LoadMap";
import axios from 'axios';

const Map = props => {
    const loadId = props.match.params.loadId;

    const [load, setLoad] = useState(null);

    const CancelToken = axios.CancelToken;
    let cancel;

    useEffect(() => {
        if (loadId !== undefined && loadId !== null) {
            axios.get('/shipperTracker?loadId=' + loadId, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                setLoad(res.data);
                console.log(res.data)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [loadId]);

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
            {load !== undefined && load !== null ? (
                <LoadMap
                    key={`fullscreenmap-${loadId}`}
                    loadId={load.id}
                    irisLoadId={load.irisId}
                    stops={load.stops}
                    load={load}
                    notAuthenticated={true}
                    latitude={load.latitude}
                    longitude={load.longitude}
                    fullScreen={true}
                    showZoomControl={false} />
            ) : null}
        </Layout>
    );
};

export default withRouter(Map);