import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {fetchLoadSuccess, loadEventUpdateTimeout} from "./loads";
import moment from 'moment';
import {updateInvoiceLineItemsStatus} from "./invoiceLineItems";
import logger from "../../shared/logger";
import Pricing from "../../shared/pricing";

//#region Transactions Functions

export const fetchTransactionsStart = () => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_START
    }
};

export const fetchTransactionsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_SUCCESS,
        payload: payload
    }
};

export const fetchTransactionsFail = (payload) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_FAIL,
        payload: payload
    }
};

export const clearTransactions = () => {
    return {
        type: actionTypes.CLEAR_TRANSACTIONS
    }
};

export const addTransactionStart = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_START
    }
};

export const addTransactionSuccess = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_SUCCESS
    }
};

export const addTransactionFail = (payload) => {
    return {
        type: actionTypes.ADD_TRANSACTION_FAIL,
        payload: payload
    }
};

export const addTransactionErrorClear = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_ERROR_CLEAR
    }
};

export const addTransactionLoadingClear = () => {
    return {
        type: actionTypes.ADD_TRANSACTION_LOADING_CLEAR
    }
};

export const updateTransactionStart = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_START
    }
};

export const updateTransactionSuccess = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_SUCCESS
    }
};

export const updateTransactionFail = (payload) => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_FAIL,
        payload: payload
    }
};

export const updateTransactionErrorClear = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_ERROR_CLEAR
    }
};

export const updateTransactionLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_TRANSACTION_LOADING_CLEAR
    }
};

export const removeTransactionStart = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_START
    }
};

export const removeTransactionSuccess = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_SUCCESS
    }
};

export const removeTransactionFail = (payload) => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_FAIL,
        payload: payload
    }
};

export const removeTransactionErrorClear = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_ERROR_CLEAR
    }
};

export const removeTransactionLoadingClear = () => {
    return {
        type: actionTypes.REMOVE_TRANSACTION_LOADING_CLEAR
    }
};

export const cancelAddTransaction = () => {
    return {
        type: actionTypes.CANCEL_ADD_TRANSACTION
    }
};

export const cancelUpdateTransaction = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_TRANSACTION
    }
};

export const cancelRemoveTransaction = () => {
    return {
        type: actionTypes.CANCEL_REMOVE_TRANSACTION
    }
};

export const insertTransaction = (payload) => {
    return {
        type: actionTypes.ADD_TRANSACTION,
        payload: payload
    }
};

export const changeTransaction = (payload) => {
    return {
        type: actionTypes.UPDATE_TRANSACTION,
        payload: payload
    }
};

export const changeSingleTransaction = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_TRANSACTION,
        payload: payload
    }
};

export const deleteTransaction = (payload) => {
    return {
        type: actionTypes.REMOVE_TRANSACTION,
        payload: payload
    }
};

//#endregion

//#region Transactions Methods

export const fetchTransactions = (payload) => {
    return async (dispatch, getState) => {
        const transactionsPath = '/transactions';
        try {
            dispatch(fetchTransactionsStart());

            const state = getState();
            const transactionsState = {...state.transactions};
            let pagination = {...transactionsState.pagination};
            let searchParams = {...transactionsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const transactionsRes = await axiosAuthenticated.get(transactionsPath, {params: {...searchParams}});
            if (transactionsRes && transactionsRes.status === 200) {
                const transactions = transactionsRes.data.data;

                // Read total count from server
                pagination.total = transactionsRes.data.totalCount;
                pagination.current = transactionsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchTransactionsSuccess({
                    records: transactions,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchTransactionsFail({error: error.message}));
        }
    }
};

export const addTransaction = (payload, invoiceLineItems, loadId = null) => {
    return async (dispatch, getState) => {
        const transactionsPath = '/transactions';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(addTransactionStart());

            if (payload.fromEntityId === null || payload.fromEntityId === undefined || payload.fromEntityId === '') {
                if (payload.fromEntityType === 'SHIPPER') {
                    throw new Error('No ShipperId was provided');
                } else if (payload.fromEntityType === 'CARRIER') {
                    throw new Error('No AssignedCarrierId was provided');
                } else {
                    throw new Error('No FromEntityId was provided');
                }
            }

            if (payload.toEntityId === null || payload.toEntityId === undefined || payload.toEntityId === '') {
                if (payload.toEntityType === 'SHIPPER') {
                    throw new Error('No ShipperId was provided');
                } else if (payload.toEntityType === 'CARRIER') {
                    throw new Error('No AssignedCarrierId was provided');
                } else {
                    throw new Error('No ToEntityId was provided');
                }
            }

            if (payload.amount !== undefined && payload.amount !== null) {
                payload.amount = Number(payload.amount);
                payload.amountUnit = 'USD';
            }

            if (payload.transactionFee !== undefined && payload.transactionFee !== null) {
                payload.transactionFee = Number(payload.transactionFee);
                payload.transactionFeeUnit = 'USD';
            }

            if (payload.netAmount !== undefined && payload.netAmount !== null) {
                payload.netAmount = Number(payload.netAmount);
                payload.netAmountUnit = 'USD';
            }

            if (payload.paidAt !== undefined && payload.paidAt !== null && moment.isMoment(payload.paidAt)) {
                payload.paidAt = payload.paidAt.utc().toISOString(true);
            }

            const transactionsRes = await axiosAuthenticated.post(transactionsPath, {...payload});
            if (transactionsRes && transactionsRes.status === 201) {
                let newTransaction = transactionsRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];

                    updatedTransactions.push(newTransaction);

                    let updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    if (updateLoadSuccess === true) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: loadId,
                            eventType: 'LOAD_UPDATED',
                            changeType: 'LOAD_UPDATED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    if (newTransaction.paymentStatus === 'COMPLETED') {
                        let invoiceLineItemIds = [];
                        let remainder = newTransaction.netAmount;
                        let fromEntityType = newTransaction.fromEntityType;
                        let toEntityType = newTransaction.toEntityType;
                        if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                            invoiceLineItems.filter(i => i.status === 'PENDING' && i.fromEntityType === fromEntityType && i.toEntityType === toEntityType && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                if (invoiceLineItem.totalAmount <= remainder) {
                                    invoiceLineItemIds.push(invoiceLineItem.id);
                                    remainder = remainder - invoiceLineItem.totalAmount;
                                }
                            });
                            if (invoiceLineItemIds.length > 0) {
                                dispatch(updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED', loadId));
                            }
                        }
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'TRANSACTIONS_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(insertTransaction(newTransaction));

                dispatch(addTransactionSuccess());
                dispatch(addTransactionLoadingClear());
                dispatch(addTransactionErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addTransactionFail({error: error.message}));
        }
    }
};

export const updateTransaction = (transactionId, payload, oldTransaction, invoiceLineItems, loadId = null) => {
    return async (dispatch, getState) => {
        const transactionsPath = '/transactions';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateTransactionStart());

            if (payload.amount !== undefined && payload.amount !== null) {
                payload.amount = Number(payload.amount);
                payload.amountUnit = 'USD';
            }

            if (payload.transactionFee !== undefined && payload.transactionFee !== null) {
                payload.transactionFee = Number(payload.transactionFee);
                payload.transactionFeeUnit = 'USD';
            }

            if (payload.netAmount !== undefined && payload.netAmount !== null) {
                payload.netAmount = Number(payload.netAmount);
                payload.netAmountUnit = 'USD';
            }

            if (payload.paidAt !== undefined && payload.paidAt !== null && moment.isMoment(payload.paidAt)) {
                payload.paidAt = payload.paidAt.utc().toISOString(true);
            }

            const transactionsRes = await axiosAuthenticated.put(transactionsPath + `/${transactionId}`, {...payload});
            if (transactionsRes && transactionsRes.status === 200) {
                let updatedTransaction = transactionsRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let existingTransactions = [...transactionsState.records];
                    let updateLoadSuccess = false;
                    const index = existingTransactions.findIndex(obj => obj.id === updatedTransaction.id);
                    if (index !== -1) {
                        const updatedTransactions = [
                            ...existingTransactions.slice(0, index), // everything before current obj
                            updatedTransaction,
                            ...existingTransactions.slice(index + 1), // everything after current obj
                        ];

                        updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    }

                    if (updateLoadSuccess === true) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: loadId,
                            eventType: 'LOAD_UPDATED',
                            changeType: 'LOAD_UPDATED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    // if payment status changed
                    if (updatedTransaction.paymentStatus !== oldTransaction.paymentStatus) {
                        if (updatedTransaction.paymentStatus === 'COMPLETED') {
                            let invoiceLineItemIds = [];
                            let remainder = updatedTransaction.netAmount;
                            let fromEntityType = updatedTransaction.fromEntityType;
                            let toEntityType = updatedTransaction.toEntityType;
                            if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                                invoiceLineItems.filter(i => i.status === 'PENDING' && i.fromEntityType === fromEntityType && i.toEntityType === toEntityType && i.totalAmount > 0).forEach((invoiceLineItem) => {
                                    // console.log(remainder);
                                    // console.log(invoiceLineItem.totalAmount);
                                    if (invoiceLineItem.totalAmount <= remainder) {
                                        invoiceLineItemIds.push(invoiceLineItem.id);
                                        remainder = Number((remainder - invoiceLineItem.totalAmount).toFixed(2));
                                    }
                                });
                                if (invoiceLineItemIds.length > 0) {
                                    dispatch(updateInvoiceLineItemsStatus(invoiceLineItemIds, 'COMPLETED', loadId));
                                }
                            }
                        }
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'TRANSACTIONS_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(changeTransaction(updatedTransaction));

                dispatch(updateTransactionSuccess());
                dispatch(updateTransactionLoadingClear());
                dispatch(updateTransactionErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateTransactionFail({error: error.message}));
        }
    }
};

export const removeTransaction = (transactionId, loadId) => {
    return async (dispatch, getState) => {
        const transactionsPath = '/transactions';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(removeTransactionStart());

            const transactionsRes = await axiosAuthenticated.put(transactionsPath + `/${transactionId}`, {isDeleted: true});
            if (transactionsRes && transactionsRes.status === 200) {
                const updatedTransaction = transactionsRes.data;

                if (loadId !== undefined && loadId !== null) {
                    const state = getState();
                    const invoiceLineItemsState = {...state.invoiceLineItems};
                    const transactionsState = {...state.transactions};
                    let updatedInvoiceLineItems = [...invoiceLineItemsState.records];
                    let updatedTransactions = [...transactionsState.records];
                    let updateLoadSuccess = false;
                    const index = updatedTransactions.findIndex(obj => obj.id === updatedTransaction.id);
                    if (index !== -1) {
                        // returns the deleted items
                        updatedTransactions.splice(index, 1);

                        updateLoadSuccess = await updateLoadPricing(loadId, updatedInvoiceLineItems, updatedTransactions);
                    }
                    if (updateLoadSuccess === true) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: loadId,
                            eventType: 'LOAD_UPDATED',
                            changeType: 'LOAD_UPDATED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'TRANSACTIONS_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                dispatch(deleteTransaction(updatedTransaction));

                dispatch(removeTransactionSuccess());
                dispatch(removeTransactionLoadingClear());
                dispatch(removeTransactionErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(removeTransactionFail({error: error.message}));
        }
    }
};

//#endregion
//#region helper api calls

export const updateLoadPricing = async (loadId, invoiceLineItems, transactions) => {
    try {
        let filteredInvoiceLineItems = invoiceLineItems.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);
        let filteredTransactions = transactions.filter(i => i.loadId !== undefined && i.loadId !== null && i.loadId === loadId);

        let loadSummary = Pricing.loadPricingSummary(filteredInvoiceLineItems, filteredTransactions);

        let updatedLoadPayload = {};

        if (loadSummary.shipperRatePerMileAmount !== undefined && loadSummary.shipperRatePerMileAmount !== null) {
            updatedLoadPayload.shipperRatePerMile = loadSummary.shipperRatePerMileAmount;
            updatedLoadPayload.shipperRatePerMileUnit = 'USD';
        }
        if (loadSummary.shipperAmountWithAddOns !== undefined && loadSummary.shipperAmountWithAddOns !== null) {
            updatedLoadPayload.shipperAmount = loadSummary.shipperAmountWithAddOns;
            updatedLoadPayload.shipperAmountUnit = 'USD';
        }
        if (loadSummary.shipperBalance !== undefined && loadSummary.shipperBalance !== null) {
            updatedLoadPayload.shipperBalance = loadSummary.shipperBalance;
            updatedLoadPayload.shipperBalanceUnit = 'USD';
        }
        if (loadSummary.carrierRatePerMileAmount !== undefined && loadSummary.carrierRatePerMileAmount !== null) {
            updatedLoadPayload.carrierRatePerMile = loadSummary.carrierRatePerMileAmount;
            updatedLoadPayload.carrierRatePerMileUnit = 'USD';
        }
        if (loadSummary.carrierAmountWithAddOns !== undefined && loadSummary.carrierAmountWithAddOns !== null) {
            updatedLoadPayload.carrierAmount = loadSummary.carrierAmountWithAddOns;
            updatedLoadPayload.carrierAmountUnit = 'USD';
        }
        if (loadSummary.carrierBalance !== undefined && loadSummary.carrierBalance !== null) {
            updatedLoadPayload.carrierBalance = loadSummary.carrierBalance;
            updatedLoadPayload.carrierBalanceUnit = 'USD';
        }

        const loadsRes = await axiosAuthenticated.put('/loads' + `/${loadId}`, {...updatedLoadPayload});
        if (loadsRes && loadsRes.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);

        return false;
    }
};

//#endregion