import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { CheckOutlined } from '@ant-design/icons';
import { Drawer, Tooltip, Button, Table } from 'antd';
import Note from "../../components/Note/Note";
import EditNote from "../../components/EditNote/EditNote";
import NewNote from "../../components/NewNote/NewNote";
import ResolveNote from "../../components/ResolveNote/ResolveNote";
import RemoveNote from "../../components/RemoveNote/RemoveNote";
import classes from './NoteList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DataTableTitle from '../../components/DataTableTitle/DataTableTitle';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';

const stringFormatter = new StringFormatter();

const NoteList = props => {
    const fullWidth = global.window.innerWidth;

    const noteList = props.noteList;
    const entityType = props.entityType;
    const entityId = props.entityId;
    const tableTitle = props.tableTitle;

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.notes.isLoading);
    const entityNotes = useSelector(state => state.notes.records);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const userEntityType = useSelector(state => state.auth.entityType);
    const userEntityId = useSelector(state => state.auth.entityId);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showResolveEntity, setShowResolveEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);

    const [notes, setNotes] = useState([]);
    const [visibleNotes, setVisibleNotes] = useState([]);

    const singularEntityName = "Note";
    const pluralEntityName = "Notes";

    useEffect(() => {
        //console.log(`entityNotes: ${JSON.stringify(entityNotes)}`);
        if (entityNotes !== undefined && entityNotes !== null && entityNotes.length > 0 && (noteList === undefined || noteList === null)) {
            let notesToInclude = entityNotes.filter(d => d.entityId === entityId);
            setNotes(notesToInclude);
        } else if ((noteList === undefined || noteList === null)) {
            setNotes([]);
        } else if (noteList !== undefined && noteList !== null && noteList.length > 0) {
            setNotes(noteList);
        } else if (noteList !== undefined && noteList !== null) {
            setNotes([]);
        } else {
            setNotes([]);
        }

        return () => {
        };
    }, [entityNotes, entityId, noteList]);

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            sort: 'createdAt',
            order: 'desc'
        };

        if (entityId !== undefined && entityId !== null) {
            searchParams.entityId = entityId;
        }

        dispatch(actionCreators.fetchNotes(searchParams));
    };

    useMemo(() => {
        if (entityId !== undefined && (noteList === undefined || noteList === null)) {
            refreshTable();
        }
    }, [entityId, userEntityId, noteList]);


    useEffect(() => {
        //console.log(notes);
        if (notes !== undefined && notes !== null && notes.length > 0) {
            if (userEntityType === "STAFF" && isAdmin === true) {
                let filteredNotes = notes;
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "STAFF" && isAdmin === false) {
                let filteredNotes = notes.filter(i => i.visibleTo !== "ADMIN");
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "DRIVER") {
                let filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "DRIVER"));
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "CARRIER") {
                let filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "DRIVER" || i.visibleTo === "CARRIER"));
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "PRODUCER") {
                let filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "PRODUCER"));
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "RECEIVER") {
                let filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "RECEIVER"));
                setVisibleNotes(filteredNotes);
            } else if (userEntityType === "SHIPPER") {
                let filteredNotes = notes.filter(i => (i.visibleTo === "ALL" || i.visibleTo === "SHIPPER"));
                setVisibleNotes(filteredNotes);
            } else {
                setVisibleNotes([]);
            }
        } else {
            setVisibleNotes([]);
        }

        return () => {
        };
    }, [notes, userEntityType, isAdmin]);

    const actionColumnWidth = (isAdmin ? 150 : 100);

    const columns = [
        {
            title: 'Note',
            dataIndex: 'body',
            key: 'body',
        }
    ];

    if (userEntityType === "STAFF") {
        columns.unshift({
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: actionColumnWidth,
            render: (text, record) => {
                return (
                    <>
                        <Tooltip placement="top" title={"Resolve " + singularEntityName}>
                            <Button key="1" type="primary" icon={<CheckOutlined />} shape='circle' onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleResolveEntity(); }} style={{ marginRight: '8px' }} />
                        </Tooltip>
                        <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                            showAdd={false} addText='' addAction={null}
                            showEdit={true} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                            showChange={false} changeText='' changeAction={null}
                            showRemove={isAdmin ? true : false} removeText={"Delete " + singularEntityName} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
                        />
                    </>
                );
            },
            align: 'center',
        });

        columns.push({
            title: 'Is Resolved',
            dataIndex: 'isResolved',
            key: 'isResolved',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        });
        columns.push({
            title: 'Resolved At',
            dataIndex: 'resolvedAt',
            key: 'resolvedAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        });
        columns.push({
            title: 'Resolved By',
            dataIndex: 'resolvedBy',
            key: 'resolvedBy',
        });
    }

    if (entityType === undefined || entityType === null) {
        columns.push({
            title: 'Entity Type',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("NoteEntityType", text, null); },
        });
        columns.push({
            title: 'Entity Id',
            dataIndex: 'entityId',
            key: 'entityId',
        });
    }

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleResolveEntity = () => {
        setShowResolveEntity(!showResolveEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewNote cancel={toggleNewEntity} entityType={entityType} entityId={entityId} />
        </Drawer>
    );

    const resolveEntityComponents = (
        <Drawer
            title={"Resolve " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleResolveEntity}
            visible={showResolveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ResolveNote cancel={toggleResolveEntity} note={selectedRecord} entityType={entityType} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditNote cancel={toggleEditEntity} note={selectedRecord} entityType={entityType} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveNote cancel={toggleRemoveEntity} note={selectedRecord} entityType={entityType} />
        </Drawer>
    );

    return (
        <DataTable
            bordered={true}
            dataSource={visibleNotes}
            columns={columns}
            loading={isLoading}
            pagination={{ pageSize: 10 }}
            rowClassName={classes.dataTableRow}
            expandedRowRender={record => (<Note note={record} />)}
            expandRowByClick={true}
            title={() => (<DataTableTitle tableTitle={tableTitle} singularEntityName={singularEntityName} pluralEntityName={pluralEntityName} action={entityType !== undefined && entityType !== null ? toggleNewEntity : null} />)}
            footer={false}
            style={{ backgroundColor: '#ffffff' }}
            rowKey={record => record.id}
        >
            {newEntityComponents}
            {editEntityComponents}
            {resolveEntityComponents}
            {removeEntityComponents}
        </DataTable>
    );
};

export default NoteList;