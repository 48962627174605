import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, Checkbox, InputNumber } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import UploadFile from "../UploadFile/UploadFile";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';

const { Option } = Select;

const NewAsset = props => {
    const accountId = props.accountId;

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.assets.isRecordAddLoading);
    const error = useSelector(state => state.assets.addRecordError);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const states = useSelector(state => state.orchestrator.states);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const lengthUnitOptions = Enums.LengthUnits.selectListOptions();
    const volumeUnitOptions = Enums.VolumeUnits.selectListOptions();
    const distanceUnitOptions = Enums.DistanceUnits.selectListOptions();
    const weightUnitOptions = Enums.WeightUnits.selectListOptions();
    const assetStatusOptions = Enums.AssetStatuses.selectListOptions();
    const assetTypeOptions = Enums.AssetTypes.selectListOptions();
    const truckOwnerTypeOptions = Enums.TruckOwnerTypes.selectListOptions();

    const [photoFile, setPhotoFile] = useState(null);
    const [licensePlateFile, setLicensePlateFile] = useState(null);
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [registrationFile, setRegistrationFile] = useState(null);
    const [carriers, setCarriers] = useState([]);
    const [carrierId, setCarrierId] = useState(null);
    const [showTrailerTypes, setShowTrailerTypes] = useState(false);

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            setCarrierId(accountId);
        }
        const filteredAccounts = accounts.filter(a => a.isCarrier === true);
        setCarriers(filteredAccounts);
    }, [accountId, accounts]);

    const handleAccountChange = (value) => {
        //console.log(`selected ${value}`);
        setCarrierId(value);
    };

    const handleAssetTypeChange = (value) => {
        //console.log(`selected ${value}`);
        if (value === 'TRAILER' || value === 'COMBINED') {
            setShowTrailerTypes(true);
        } else {
            setShowTrailerTypes(false);
        }
    };

    const onSubmit = (data) => {
        let payload = removeEmpty(data);

        if (photoFile) {
            payload.photoFile = photoFile;
        }
        if (licensePlateFile) {
            payload.licensePlateFile = licensePlateFile;
        }
        if (insuranceFile) {
            payload.insuranceFile = insuranceFile;
        }
        if (registrationFile) {
            payload.registrationFile = registrationFile;
        }

        payload.carrierId = carrierId;

        // console.log(payload);
        if (!isEmpty(payload)) {
            dispatch(actionCreators.addAsset(payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddAsset());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addAssetErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
        helpCol: { offset: 8, span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {(accountId === undefined || accountId === null) &&
                        <Fieldset>
                            <legend>Choose a Carrier</legend>
                            <FormItem {...formItemLayout} label="Carrier" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Carrier"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { handleAccountChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {carriers.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="carrierId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Asset Name, Asset Type, and License Plate Information">
                        <FormItem {...formItemLayout} label="Asset Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Asset Name (optional)" />}
                            rules={{ required: false }}
                            name="name"
                        />
                        <FormItem {...formItemLayout} label="Asset Type" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select an Asset Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { handleAssetTypeChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {assetTypeOptions}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="assetType"
                            help={
                                <div>
                                    <b>What to choose:</b>
                                    <ul>
                                        <li>Tractor: Power Unit</li>
                                        <li>Trailer: Trailer Only - you will then specify what type of trailer it is.</li>
                                        <li>Truck: Vehicle that has a power unit and a trailer/deck but is not detachable.</li>
                                    </ul>
                                </div>
                            }
                        />
                        {(showTrailerTypes === true) ? (
                            <FormItem {...formItemLayout} label="Trailer Type" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please select a Trailer Type"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: "Required Field" }}
                                name="trailerTypeId"
                            />
                        ) : null}
                        <FormItem {...formItemLayout} label="License Plate #" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="License Plate #" />}
                            rules={{ required: "Required Field" }}
                            name="licensePlateNum"
                        />
                        <FormItem {...formItemLayout} label="License Plate State" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="licensePlateState"
                        />
                    </Fieldset>
                    <Fieldset legend="Additional Details (optional)" isCollapsable={true} isCollapsed={false}>
                        <FormItem {...formItemLayout} label="Truck Class" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Truck Class" />}
                            rules={{ required: false }}
                            name="truckClass"
                        />
                        <FormItem {...formItemLayout} label="Truck Owner Type" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Truck Owner Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {truckOwnerTypeOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="truckOwnerType"
                        />
                        <FormItem {...formItemLayout} label="VIN" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="VIN" />}
                            rules={{ required: false }}
                            name="vin"
                        />
                        <FormItem {...formItemLayout} label="DOT #" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" />}
                            rules={{ required: false }}
                            name="dotNum"
                        />
                        <FormItem {...formItemLayout} label="Is Available" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    name={name}
                                />
                            )}
                            name="isAvailable"
                            defaultValue={true}
                        />
                        {/* <FormItem {...formItemLayout} label="Status" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {assetStatusOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="status"
                        /> */}
                        {/* <FormItem {...formItemLayout} label="Out of Order Reason" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select an Out of Order Reason"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {assetOutOfOrderReasonOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="outOfOrderReason"
                        /> */}
                        <FormItem {...formItemLayout} label="Asset belongs to Carrier" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    name={name}
                                />
                            )}
                            name="isCarrierAsset"
                            defaultValue={false}
                        />
                        <FormItem {...formItemLayout} label="Asset belongs to Contractor" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    name={name}
                                />
                            )}
                            name="isContractorAsset"
                            defaultValue={false}
                        />
                    </Fieldset>
                    <Fieldset legend="Asset Specifications (optional)" isCollapsable={true} isCollapsed={true}>
                        <FormItem {...formItemLayout} label="Make" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Make" />}
                            rules={{ required: false }}
                            name="make"
                        />
                        <FormItem {...formItemLayout} label="Model" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Model" />}
                            rules={{ required: false }}
                            name="model"
                        />
                        <FormItem {...formItemLayout} label="Year" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Year" />}
                            rules={{ required: false }}
                            name="year"
                        />
                        <FormItem {...formItemLayout} label="Color" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Color" />}
                            rules={{ required: false }}
                            name="color"
                        />
                        <FormItem {...formItemLayout} label="Number of Axles" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={0}
                                    min={1}
                                    max={14}
                                    placeholder="Number of Axles"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{ required: false }}
                            name="axles"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Fuel Tank Capacity" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Fuel Tank Capacity"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="fuelTankCapacity"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {volumeUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="fuelTankCapacityUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Fuel Range" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Fuel Range"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="range"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {distanceUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="rangeUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Overall Length" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Overall Length"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="overallLength"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="overallLengthUnit"
                            help={
                                <div>
                                    <strong>The Overall Length is the total length of the asset including any hitches or other attachments that may overlap with another asset.</strong>
                                </div>
                            }
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Length" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Length"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="length"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="lengthUnit"
                            help={
                                <div>
                                    <strong>The Length is the effective length without overlap with another asset.</strong>
                                </div>
                            }
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Height" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Height"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="height"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="heightUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Width" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Width"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="width"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {lengthUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="widthUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Weight" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Weight"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="weight"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {weightUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="weightUnit"
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Max Load Weight" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    placeholder="Max Load Weight"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="maxLoadWeight"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {weightUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="maxLoadWeightUnit"
                        />
                    </Fieldset>
                    <Fieldset legend="Asset Documents" isCollapsable={true} isCollapsed={true}>
                        <FormItemFile {...formItemLayout} label="Asset Photo" name="phoneFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setPhotoFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setPhotoFile(null);
                                }}
                                message="Please upload a photo of the asset."
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="License Plate Photo" name="licensePlateFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setLicensePlateFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setLicensePlateFile(null);
                                }}
                                message="Please upload a photo of the license plate."
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Insurance Photo" name="insuranceFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setInsuranceFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setInsuranceFile(null);
                                }}
                                message="Please upload a photo of the asset's insurance."
                            />
                        </FormItemFile>
                        <FormItemFile {...formItemLayout} label="Registration Photo" name="registrationFile" format="horizontal">
                            <UploadFile
                                beforeUpload={(file) => {
                                    setRegistrationFile(file);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    setRegistrationFile(null);
                                }}
                                message="Please upload a photo of the registration."
                            />
                        </FormItemFile>
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Asset" />
            </Form>
        </FormProvider>
    );
};

export default NewAsset;