import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import * as actionCreators from "../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPallet, faTruck, faTruckLoading, faDesktop, faRedoAlt, faExternalLinkAlt, faTable, faList } from '@fortawesome/free-solid-svg-icons';
import LoadsList from '../../components/LoadsList/LoadsList';
import { Col, InputNumber, Modal, Row, Tabs, Drawer, Dropdown, Button, Menu, Spin, Empty } from 'antd';
import { isEmpty } from 'lodash';
import LoadDetails from '../../components/LoadDetails/LoadDetails';
import Pricing from '../../shared/pricing';
import AssignLoadCarrier from '../../components/AssignLoadCarrier/AssignLoadCarrier';
import { DownOutlined, InfoOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import LoadRequests from '../LoadRequests/LoadRequests';
import CompletedLoads from '../CompletedLoads/CompletedLoads';
import InTransitLoads from '../InTransitLoads/InTransitLoads';
import BookedLoads from '../BookedLoads/BookedLoads';
import OpenLoads from '../OpenLoads/OpenLoads';

const { TabPane } = Tabs;

const LoadList = props => {
    //#region constants

    const fullWidth = global.window.innerWidth;
    const shipperId = props.shipperId;
    const carrierId = props.carrierId;
    const driverId = props.driverId;

    //#endregion

    const location = useLocation();

    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const loadLists = useSelector(state => state.loadLists.loadLists);
    // const loadRequestsIsLoading = useSelector(state => state.loadLists.loadRequestsIsLoading);
    // const loadRequestsCurrentPage = useSelector(state => state.loadLists.loadRequestsCurrentPage);
    // const loadRequestsTotalPages = useSelector(state => state.loadLists.loadRequestsTotalPages);
    // const loadRequestsTotalCount = useSelector(state => state.loadLists.loadRequestsTotalCount);
    // const loadRequestsError = useSelector(state => state.loadLists.loadRequestsError);
    // const openLoads = useSelector(state => state.loadLists.openLoads);
    // const openLoadsIsLoading = useSelector(state => state.loadLists.openLoadsIsLoading);
    // const openLoadsCurrentPage = useSelector(state => state.loadLists.openLoadsCurrentPage);
    // const openLoadsTotalPages = useSelector(state => state.loadLists.openLoadsTotalPages);
    // const openLoadsTotalCount = useSelector(state => state.loadLists.openLoadsTotalCount);
    // const openLoadsError = useSelector(state => state.loadLists.openLoadsError);
    // const bookedLoads = useSelector(state => state.loadLists.bookedLoads);
    // const bookedLoadsIsLoading = useSelector(state => state.loadLists.bookedLoadsIsLoading);
    // const bookedLoadsCurrentPage = useSelector(state => state.loadLists.bookedLoadsCurrentPage);
    // const bookedLoadsTotalPages = useSelector(state => state.loadLists.bookedLoadsTotalPages);
    // const bookedLoadsTotalCount = useSelector(state => state.loadLists.bookedLoadsTotalCount);
    // const bookedLoadsError = useSelector(state => state.loadLists.bookedLoadsError);
    // const intransitLoads = useSelector(state => state.loadLists.intransitLoads);
    // const intransitLoadsIsLoading = useSelector(state => state.loadLists.intransitLoadsIsLoading);
    // const intransitLoadsCurrentPage = useSelector(state => state.loadLists.intransitLoadsCurrentPage);
    // const intransitLoadsTotalPages = useSelector(state => state.loadLists.intransitLoadsTotalPages);
    // const intransitLoadsTotalCount = useSelector(state => state.loadLists.intransitLoadsTotalCount);
    // const intransitLoadsError = useSelector(state => state.loadLists.intransitLoadsError);
    // const completedLoads = useSelector(state => state.loadLists.completedLoads);
    // const completedLoadsIsLoading = useSelector(state => state.loadLists.completedLoadsIsLoading);
    // const completedLoadsCurrentPage = useSelector(state => state.loadLists.completedLoadsCurrentPage);
    // const completedLoadsTotalPages = useSelector(state => state.loadLists.completedLoadsTotalPages);
    // const completedLoadsTotalCount = useSelector(state => state.loadLists.completedLoadsTotalCount);
    // const completedLoadsError = useSelector(state => state.loadLists.completedLoadsError);

    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);
    const claims = useSelector(state => state.claims.records);
    const isClaimsLoading = useSelector(state => state.claims.isLoading);
    const transactions = useSelector(state => state.transactions.records);
    const isTransactionsLoading = useSelector(state => state.transactions.isLoading);
    const invoiceLineItems = useSelector(state => state.invoiceLineItems.records);
    const isInvoiceLineItemsLoading = useSelector(state => state.invoiceLineItems.isLoading);
    const stops = useSelector(state => state.loadStops.records);
    const isStopsLoading = useSelector(state => state.loadStops.isLoading);
    const loadCancellations = useSelector(state => state.loadCancellations.records);
    const isLoadCancellationsLoading = useSelector(state => state.loadCancellations.isLoading);
    const carrierRejections = useSelector(state => state.carrierRejections.records);
    const isCarrierRejectionsLoading = useSelector(state => state.carrierRejections.isLoading);
    const documents = useSelector(state => state.documents.records);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('open');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showEntity, setShowEntity] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [showTableView, setShowTableView] = useState(true);
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopType, setCurrentStopType] = useState('Stop');
    const [isDeadHeading, setIsDeadHeading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('PENDING');
    const [showAssignCarrier, setShowAssignCarrier] = useState(false);
    const [BOLList, setBOLList] = useState([]);
    const [commodityList, setCommodityList] = useState([]);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: shipperId !== null || carrierId !== null ? 'Loads' : 'All Loads',
            previousPageLocation: props.location,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: null,
                duplicateLoadId: null,
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    //#endregion
    //#region toggles

    const openShowEntity = () => {
        setShowEntity(true);
    };

    const closeShowEntity = () => {
        setShowEntity(false);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    const toggleAssignCarrier = () => {
        setShowAssignCarrier(!showAssignCarrier);
    };

    const toggleToListView = () => {
        setShowTableView(false);
    };

    const toggleToTableView = () => {
        setShowTableView(true);
    };

    //#endregion
    //#region refreshes

    const refreshLoad = () => {
        if (load !== undefined && load !== null) {
            dispatch(actionCreators.fetchLoadWithIncludes(load.id));
        }
    };

    const refreshList = () => {
        dispatch(actionCreators.fetchLoadList(entityType, entityId, tabKey));
    };

    //#endregion
    //#region onChanges

    const onChangeCurrentStop = (stop) => {
        setCurrentStop(stop);
    };

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    const onChangeTab = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });

        closeShowEntity();
        setSelectedRecord({});
    };

    //#endregion
    //#region displays

    const menu = (record) => {
        return (
            <Menu>
                {(record && !record.assignedCarrierId && currentStatus !== 'CANCELLED') &&
                    <Menu.Item key="assignCarrier" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleAssignCarrier(); }}>
                        <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: 8 }} />
                        <span>Assign Carrier</span>
                    </Menu.Item>
                }
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record.id); }}>
                    <InfoOutlined style={{ marginRight: 8 }} />
                    <span>Go To Load Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const loadExtras = (record) => {
        return (
            <Dropdown overlay={menu(record)}>
                <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<DownOutlined />} />
            </Dropdown>
        );
    };

    const getStatus = (load, stops, currentStop, loadId) => {
        if (load !== null && load !== undefined && load.id !== undefined && load.id !== null) {
            if (load.id === loadId) {
                if (stops !== null && stops !== undefined && stops.length > 0) {
                    let isDeadHeadingValue = false;
                    let currentStopValue = null;
                    let currentStopTypeValue = "STOP";
                    let currentStopStatusValue = "PENDING";

                    let firstPickUpStop = stops.find(stop => stop.stopType === 'PICK_UP');
                    let currentStopVal = stops.find(stop => stop.stopStatus !== 'COMPLETED');
                    if (currentStopVal !== undefined) {
                        currentStopValue = currentStopVal;
                        onChangeCurrentStop(currentStopValue);
                    } else {
                        currentStopValue = null;
                        onChangeCurrentStop(null);
                    }

                    if (firstPickUpStop !== undefined) {
                        if (firstPickUpStop.stopStatus !== 'COMPLETED') {
                            isDeadHeadingValue = true;
                            setIsDeadHeading(isDeadHeadingValue);
                        } else {
                            isDeadHeadingValue = false;
                            setIsDeadHeading(isDeadHeadingValue);
                        }
                    } else {
                        isDeadHeadingValue = false;
                        setIsDeadHeading(isDeadHeadingValue);
                    }

                    if (currentStopValue !== null) {
                        currentStopTypeValue = currentStopValue.stopType;
                        setCurrentStopType(currentStopTypeValue);
                        currentStopStatusValue = currentStopValue.stopStatus;
                    }

                    switch (load.loadStatus) {
                        case 'CREATED':
                            setCurrentStatus('PENDING');
                            break;
                        case 'PENDING':
                            setCurrentStatus('PENDING');
                            break;
                        case 'APPROVED':
                            setCurrentStatus('APPROVED');
                            break;
                        case 'PENDING_RATE_CONFIRMATION':
                            setCurrentStatus('PENDING_RATE_CONFIRMATION');
                            break;
                        case 'BOOKED':
                            setCurrentStatus('BOOKED');
                            break;
                        case 'SCHEDULED':
                            setCurrentStatus('SCHEDULED');
                            break;
                        case 'IN_TRANSIT':
                            if (isDeadHeadingValue === true && currentStopStatusValue === 'PENDING') {
                                setCurrentStatus('DEAD_HEADING');
                            } else if (currentStopStatusValue === 'ARRIVING') {
                                setCurrentStatus('ARRIVING');
                            } else if (currentStopStatusValue === 'LAYOVER') {
                                setCurrentStatus('LAYOVER');
                            } else if (currentStopStatusValue !== 'PENDING' && currentStopStatusValue !== 'COMPLETED') {
                                setCurrentStatus(currentStopStatusValue); // should never happen based on state machine
                            } else {
                                setCurrentStatus('IN_TRANSIT');
                            }
                            break;
                        case 'AT_STOP':
                            if (currentStopTypeValue === 'PICK_UP' || currentStopTypeValue === 'DROP_OFF') {
                                if (currentStopStatusValue === 'PENDING') {
                                    setCurrentStatus('IN_TRANSIT'); // should never happen based on state machine
                                } else if (currentStopStatusValue === 'LAYOVER') {
                                    setCurrentStatus('LAYOVER');
                                } else {
                                    setCurrentStatus(currentStopStatusValue);
                                }
                            } else {
                                setCurrentStatus('AT_STOP');
                            }
                            break;
                        case 'COMPLETED':
                            setCurrentStatus('COMPLETED');
                            break;
                        case 'CLOSED':
                            setCurrentStatus('CLOSED');
                            break;
                        case 'CANCELLED':
                            setCurrentStatus('CANCELLED');
                            break;
                        case 'REOPENED':
                            setCurrentStatus('REOPENED');
                            break;
                        default:
                            setCurrentStatus('PENDING');
                    }
                }
            }
        }
    };

    const actions = () => {
        if (entityType === 'STAFF') {
            return (
                <>
                    {load && load.id && <a href={`/track/${load.id}`} target='_blank' style={{ textDecoration: 'underline', marginRight: 12 }}>Shipper Tracker <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} /></a>}
                    <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
                </>
            );
        } else {
            return (
                <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
            );
        }
    };

    const assignCarrierComponent = (
        <Drawer
            title={"Assign Carrier to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleAssignCarrier}
            visible={showAssignCarrier}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier cancel={toggleAssignCarrier} load={selectedRecord} stops={stops} />
        </Drawer>
    );

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        if (showEntity === true && load !== null && load !== undefined && !isEmpty(load) && load.id !== undefined && load.id !== null && stops !== null && stops !== undefined && stops.length > 0) {
            let ids = [];
            ids.push(load.id);

            if (load.shipperId !== undefined && load.shipperId !== null) {
                ids.push(load.shipperId);
            }

            if (load.assignedCarrierId !== undefined && load.assignedCarrierId !== null) {
                ids.push(load.assignedCarrierId);
            }

            if (load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0) {
                load.driverIds.forEach(driverId => {
                    ids.push(driverId);
                });
            }

            if (load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0) {
                load.assetIds.forEach(assetId => {
                    ids.push(assetId);
                });
            }

            if (stops !== null && stops !== undefined && stops.length > 0) {
                stops.forEach(stop => {
                    ids.push(stop.id);
                });
            }

            if (claims !== null && claims !== undefined && claims.length > 0) {
                claims.forEach(claim => {
                    ids.push(claim.id);
                });
            }

            dispatch(actionCreators.fetchBulkDocuments({ entityId: ids }));
            dispatch(actionCreators.fetchBulkNotes({ entityId: ids }));
        }
    }, [showEntity, load, stops, claims]);

    useMemo(() => {
        if (showEntity === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null) {
            dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
        }
    }, [showEntity, selectedRecord]);

    useMemo(() => {
        if (showEntity === true && load !== undefined && load !== null && !isEmpty(load) && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
            let hasAccess = (entityType === 'STAFF' || (entityType === 'CARRIER' && load.assignedCarrierId === entityId) || (entityType === 'SHIPPER' && load.shipperId === entityId));

            if (selectedRecord.id === load.id && hasAccess === true) {
                if (entityType === 'STAFF' || load.serviceType === 'BROKERAGE') {
                    dispatch(actionCreators.fetchClaims({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                }
                dispatch(actionCreators.fetchLoadStops(load.id));
                dispatch(actionCreators.fetchLoadCancellations({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchCarrierRejections({ page: 1, size: 100000, loadId: load.id }));
            } else if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [showEntity, load, selectedRecord, entityType, entityId]);

    useEffect(() => {
        if (showEntity === true && load !== undefined && load !== null && !isEmpty(load) && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && stops !== undefined && stops !== null && stops.length > 0) {
            getStatus(load, stops, currentStop, selectedRecord.id);
        }
    }, [showEntity, load, currentStop, stops, selectedRecord]);

    useMemo(() => {
        if (showEntity === true && stops !== undefined && stops !== null && stops.length > 0) {
            let listOfBOLs = [];
            let listOfCommodities = [];
            stops.forEach(function (stop) {
                if (stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) {
                    stop.bolNumbers.forEach((bolNum) => {
                        if (!listOfBOLs.includes(bolNum)) {
                            listOfBOLs.push(bolNum);
                        }
                    });
                }

                if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                    stop.commodities.forEach((commodity) => {
                        if (!listOfCommodities.includes(commodity.name)) {
                            listOfCommodities.push(commodity.name);
                        }
                    });
                }
            });
            setBOLList([...listOfBOLs]);
            setCommodityList([...listOfCommodities]);
        }
    }, [showEntity, stops]);

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('open');
            props.history.replace({ ...location, hash: `#open` });
        }
    }, [location]);

    //#endregion

    return (
        <>
            <Row>
                <Col span={24}>
                    <Tabs
                        activeKey={tabKey}
                        onChange={onChangeTab}
                        destroyInactiveTabPane={true}
                        tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}
                    >
                        <TabPane tab="Load Requests" key="loadrequest">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"loadrequest"}
                                        data={loadLists}
                                        // currentPage={loadRequestsCurrentPage}
                                        // totalPages={loadRequestsTotalPages}
                                        // totalCount={loadRequestsTotalCount}
                                        // isLoading={loadRequestsIsLoading}
                                        // error={loadRequestsError}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        entityType={entityType}
                                        entityId={entityId}
                                        title="Load Requests"
                                        goToLoad={goToLoad}
                                        icon={<FontAwesomeIcon className="anticon" icon={faDesktop} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <LoadRequests carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Open Loads" key="open">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"open"}
                                        data={loadLists}
                                        // currentPage={openLoadsCurrentPage}
                                        // totalPages={openLoadsTotalPages}
                                        // totalCount={openLoadsTotalCount}
                                        // isLoading={openLoadsIsLoading}
                                        // error={openLoadsError}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        entityType={entityType}
                                        entityId={entityId}
                                        title="Open"
                                        goToLoad={goToLoad}
                                        icon={<FontAwesomeIcon className="anticon" icon={faDesktop} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <OpenLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Booked Loads" key="booked">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"booked"}
                                        data={loadLists}
                                        // currentPage={bookedLoadsCurrentPage}
                                        // totalPages={bookedLoadsTotalPages}
                                        // totalCount={bookedLoadsTotalCount}
                                        // isLoading={bookedLoadsIsLoading}
                                        // error={bookedLoadsError}
                                        showStatus={false}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        entityType={entityType}
                                        entityId={entityId}
                                        title="Booked"
                                        goToLoad={goToLoad}
                                        icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <BookedLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="In Transit Loads" key="intransit">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"intransit"}
                                        data={loadLists}
                                        // currentPage={intransitLoadsCurrentPage}
                                        // totalPages={intransitLoadsTotalPages}
                                        // totalCount={intransitLoadsTotalCount}
                                        // isLoading={intransitLoadsIsLoading}
                                        // error={intransitLoadsError}
                                        showStatus={true}
                                        showStatusToStaff={false}
                                        showCompletedDate={false}
                                        entityType={entityType}
                                        entityId={entityId}
                                        title="In Transit"
                                        goToLoad={goToLoad}
                                        icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <InTransitLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="Completed Loads" key="completed">
                            <div style={{ padding: 24 }}>
                                {showTableView === false ?
                                    <LoadsList
                                        filter={"completed"}
                                        data={loadLists}
                                        // currentPage={completedLoadsCurrentPage}
                                        // totalPages={completedLoadsTotalPages}
                                        // totalCount={completedLoadsTotalCount}
                                        // isLoading={completedLoadsIsLoading}
                                        // error={completedLoadsError}
                                        showStatus={true}
                                        showStatusToStaff={true}
                                        showCompletedDate={true}
                                        entityType={entityType}
                                        entityId={entityId}
                                        title="Completed"
                                        goToLoad={goToLoad}
                                        icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                                        selectedRecord={selectedRecord}
                                        setSelectedRecord={setSelectedRecord}
                                        openShowEntity={openShowEntity}
                                        closeShowEntity={closeShowEntity}
                                        showEntity={showEntity}
                                        toggleAssignCarrier={toggleAssignCarrier}
                                        loadExtras={loadExtras}
                                        carrierId={carrierId}
                                        shipperId={shipperId}
                                        driverId={driverId}
                                    /> : <CompletedLoads carrierId={carrierId} shipperId={shipperId} driverId={driverId} />
                                }
                            </div>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isClaimsLoading === true || isTransactionsLoading === true || isInvoiceLineItemsLoading === true || isStopsLoading === true || isLoadCancellationsLoading === true || isCarrierRejectionsLoading === true}>
                        {(showEntity === true && load !== undefined && load !== null && !isEmpty(load) && load.id !== undefined && load.id !== null && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null && selectedRecord.id === load.id) ? (
                            <div style={{ paddingLeft: 24, paddingRight: 24 }}>
                                <LoadDetails
                                    loadId={load.id}
                                    load={load}
                                    stops={stops}
                                    invoiceLineItems={invoiceLineItems}
                                    transactions={transactions}
                                    claims={claims}
                                    loadCancellations={loadCancellations}
                                    carrierRejections={carrierRejections}
                                    isDeadHeading={isDeadHeading}
                                    currentStatus={currentStatus}
                                    currentStop={currentStop}
                                    currentStopType={currentStopType}
                                    refreshLoad={refreshLoad}
                                    entityType={entityType}
                                    entityId={entityId}
                                    documents={documents}
                                    bols={BOLList}
                                    commodities={commodityList}
                                />
                            </div>
                        ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Please select a Load above to see more details." />
                            )}
                    </Spin>
                </Col>
            </Row>
            {newEntityComponents}
            {duplicateEntityComponents}
            {assignCarrierComponent}
        </>
    );
};

export default withRouter(LoadList);