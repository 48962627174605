import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataRow from '../../components/DataRow/DataRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';

const { TabPane } = Tabs;

const LoadLane = props => {
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loadLanes.isRecordLoading);
    const error = useSelector(state => state.loadLanes.recordError);
    const loadLane = useSelector(state => state.loadLanes.record);

    const [tabKey, setTabKey] = useState('snapshot');

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchLoadLane(id));
    }, [id]);


    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/loadLanes` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/loadLanes`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Lanes';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/loadLanes` },
        path: previousPath ? previousPath : `/loadLanes`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Lanes',
    },
    {
        path: `/loadLanes/${id ? id : null}`,
        breadcrumbName: `${loadLane && loadLane.customerLaneId ? loadLane.customerLaneId : (loadLane && loadLane.irisId ? loadLane.irisId : '')}`,
    },
    ];

    const createLocationsDisplay = (loadLane) => {
        let locationsDisplay = null
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            locationsDisplay = loadLane.locations.map((location) => {
                if (location.location !== undefined && location.location !== null && location.location.address !== undefined && location.location.address !== null) {
                    return (
                        <div key={`address-row-load-lane-${loadLane.id}-location-${location.locationId}-${location.sequence}`} style={{ marginBottom: 12 }}>
                            <DataAddressRow title={location.location.name} address={location.location.address} />
                            {(location.loadingType !== undefined && location.loadingType !== null) ? (<DataRow title={location.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'} value={location.loadingType} dataType={location.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType'} />) : null}
                            {(location.commodities !== undefined && location.commodities !== null && location.commodities.length > 0) ? (<DataCommodityListRow title="Bids" data={location.commodities} />) : null}
                        </div>
                    );
                } else {
                    return null;
                }
            });
        }

        return locationsDisplay;
    };

    if (loadLane && loadLane.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={loadLane && loadLane.customerLaneId ? loadLane.customerLaneId : (loadLane && loadLane.irisId ? loadLane.irisId : 'Loading...')}
                    subtitle={loadLane && loadLane.equipmentNeeds && loadLane.equipmentNeeds.trailerType && loadLane.equipmentNeeds.trailerType.description ? 'Trailer Type: ' + loadLane.equipmentNeeds.trailerType.description : null}
                    icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Lane Details"
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Lane ID": { value: loadLane && loadLane.irisId ? loadLane.irisId : null, dataType: 'String' },
                                            "Customer Lane ID": { value: loadLane && loadLane.customerLaneId ? loadLane.customerLaneId : null, dataType: 'String' },
                                            "Approx Distance": { value: loadLane && loadLane.approxDistance ? loadLane.approxDistance : null, dataType: 'String' },
                                            "Approx Transit Time": { value: loadLane && loadLane.approxTransitTime ? loadLane.approxTransitTime : null, dataType: 'String' },
                                            "Per Mile Rate": { value: loadLane && loadLane.perMileRate && entityType === 'STAFF' ? loadLane.perMileRate : null, dataType: 'Money' }
                                        }}
                                    />
                                </Col>
                                {(loadLane && loadLane.locations && loadLane.locations.length > 0) ? (
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Stops (In Order)"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {createLocationsDisplay(loadLane)}
                                        </ComplexCard>
                                    </Col>
                                ) : null}
                                {(loadLane && (loadLane.equipmentNeeds || loadLane.driverRequirements || loadLane.equipmentSpecifics)) ? (
                                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Default Equipment Needs and Services"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            {loadLane.equipmentNeeds && loadLane.equipmentNeeds.trailerType && loadLane.equipmentNeeds.trailerType.description ? <DataRow title="Trailer Type" value={loadLane.equipmentNeeds.trailerType.description} dataType="String" /> : null}
                                            {loadLane.equipmentNeeds && loadLane.equipmentNeeds.trailerLength && loadLane.equipmentNeeds.trailerLengthUnit ? <DataRow title="Trailer Length" value={loadLane.equipmentNeeds.trailerLength} units={loadLane.equipmentNeeds.trailerLengthUnit} dataType="Length" /> : null}
                                            {loadLane.equipmentNeeds && loadLane.equipmentNeeds.trailerWeightCapacity && loadLane.equipmentNeeds.trailerWeightCapacityUnit ? <DataRow title="Load Weight" value={loadLane.equipmentNeeds.trailerWeightCapacity} units={loadLane.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" /> : null}
                                            {loadLane.equipmentNeeds && loadLane.equipmentNeeds.preCoolReefer ? <DataRow title="Pre-cool Temp" value={loadLane.equipmentNeeds.preCoolReefer} units={loadLane.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" /> : null}
                                            {loadLane.equipmentNeeds && loadLane.equipmentNeeds.minRunTemp && loadLane.equipmentNeeds.maxRunTemp ? <DataTemperatureRangeRow title="Temp Range" min={loadLane.equipmentNeeds.minRunTemp} minUnits={loadLane.equipmentNeeds.minRunTempUnit} max={loadLane.equipmentNeeds.maxRunTemp} maxUnits={loadLane.equipmentNeeds.maxRunTempUnit} /> : null}
                                            {loadLane.equipmentSpecifics && loadLane.equipmentSpecifics.length > 0 ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={loadLane.equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {loadLane.driverRequirements && loadLane.driverRequirements.length > 0 ? (
                                                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={loadLane.driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </ComplexCard>
                                    </Col>
                                ) : null}
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(LoadLane);