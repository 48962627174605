import React, { useMemo, useState } from 'react';
import { Checkbox, Alert, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const EditLoadStatusApproveRateConfirmation = props => {
    const load = props.load;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const [file, setFile] = useState(null);

    const onSubmit = (data) => {
        // validate to make sure file was attached
        if (file === null && entityType === 'STAFF') {
            methods.setError("file", { type: "required", message: "Rate Confirmation Document Must Be Attached." });
            return;
        } else {
            methods.clearErrors("file");
        }

        if (file && entityType === 'STAFF') {
            data.file = file;
        }

        dispatch(actionCreators.updateRateConfirmation(data, load.id, entityType === 'CARRIER' ? true : false));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Approve Rate Confirmation</legend>
                        {entityType === "STAFF" && (
                            <FormItemFile {...formItemLayout} label="Rate Confirmation" required name="file" format="horizontal">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                    }}
                                    message="Please upload a photo of the carrier rate confirmation."
                                />
                            </FormItemFile>
                        )}
                        <FormItem {...formItemLayout} label={`I Approve the Rate Confirmation${entityType === 'STAFF' ? ' on behalf of the Carrier' : ''}`} required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                validate: {
                                    mustBeTrue: checked => checked === true || 'Rate Confirmation must be Approved' // value must be true
                                }
                            }}
                            name="approvedRateConfirmation"
                            defaultValue={false}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Approve Rate Confirmation" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusApproveRateConfirmation;