import React, { useMemo } from 'react';
import { Select, Alert, Spin, Empty } from "antd";
import { isEmpty, without } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import StringFormatter from '../../shared/stringFormatter';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const ChangeLoadAsset = props => {
    const load = props.load;
    const carrierId = props.carrierId;
    const asset = props.asset;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const isAssetsLoading = useSelector(state => state.assets.isLoading);
    const assets = useSelector(state => state.assets.records);

    useMemo(() => {
        if (carrierId !== null) {
            dispatch(actionCreators.fetchAssets({ page: 1, size: 100000, carrierId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    const onSubmit = data => {
        if (!isEmpty(data)) {
            //console.log(data);

            const updatedAssetIds = without(load.assetIds, asset.id);
            //console.log(updatedAssetIds);

            // check to see if it is a duplicate and if it is found in the updatedAssetIds array already, throw an error
            if (updatedAssetIds.length > 0) {
                if (updatedAssetIds.find(i => i === data.assetId) !== undefined) {
                    methods.setError("assetId", { type: "validate", message: "The asset you chose is already assigned to this load." });
                    return;
                } else {
                    methods.clearErrors("assetId");
                }
            }

            let updatedAssetIdsWithNewAsset = [...updatedAssetIds, data.assetId];
            //console.log(updatedAssetIdsWithNewAsset);

            // removes duplicates
            const updatedAssetIdsWithNewAsset2 = [...new Set(updatedAssetIdsWithNewAsset)];
            //console.log(updatedAssetIdsWithNewAsset2);
            let payload = {};
            payload.assetIds = updatedAssetIdsWithNewAsset2;
            //console.log(payload);
            dispatch(actionCreators.updateLoad(load.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Change the Asset Assigned to this Load</legend>
                        <FormItem {...formItemLayout} label="Choose an Asset" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Search and Select an Asset"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    loading={isAssetsLoading === true}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Assets for you to choose from." />}
                                >
                                    {assets.map(a => <Option value={a.id} key={a.id}>{stringFormatter.toFormattedString("AssetType", a.assetType, null)}{a.trailerType !== undefined && a.trailerType !== null ? ' (' + a.trailerType.description + ')' : ''} ({a.licensePlateNum} {a.licensePlateState}) - Currently Available: {stringFormatter.toYesNo(a.isAvailable)}</Option>)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="assetId"
                            defaultValue={asset.id}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Change Asset" />
            </Form>
        </FormProvider>
    );
};

export default ChangeLoadAsset;