import React from 'react';
import { Card, Typography } from "antd";
import classes from './SimpleDataLinkCard.module.scss';

const { Title } = Typography;

const SimpleDataLinkCard = props => {

    return (
        <Card bordered={true} title={props.title} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={props.extra}>
            <div><a href={props.url} style={{ textDecoration: 'underline' }}><Title level={props.dataSize ? props.dataSize : 3} style={{ color: '#16588E' }}>{props.data}</Title></a></div>
        </Card>
    );
};

export default SimpleDataLinkCard;
