import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";

//#region LinkedLoadLanes Functions

export const fetchLinkedLoadLanesStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANES_START
    }
};

export const fetchLinkedLoadLanesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANES_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedLoadLanesFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANES_FAIL,
        payload: payload
    }
};

export const clearLinkedLoadLanes = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOAD_LANES
    }
};

export const fetchLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_START
    }
};

export const fetchLinkedLoadLaneSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const clearLinkedLoadLane = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOAD_LANE
    }
};

export const addLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_START
    }
};

export const addLinkedLoadLaneSuccess = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_SUCCESS
    }
};

export const addLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const addLinkedLoadLaneErrorClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_ERROR_CLEAR
    }
};

export const addLinkedLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE_LOADING_CLEAR
    }
};

export const updateLinkedLoadLaneStart = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_START
    }
};

export const updateLinkedLoadLaneSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_SUCCESS
    }
};

export const updateLinkedLoadLaneFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_FAIL,
        payload: payload
    }
};

export const updateLinkedLoadLaneErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_ERROR_CLEAR
    }
};

export const updateLinkedLoadLaneLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE_LOADING_CLEAR
    }
};

export const cancelAddLinkedLoadLane = () => {
    return {
        type: actionTypes.CANCEL_ADD_LINKED_LOAD_LANE
    }
};

export const cancelUpdateLinkedLoadLane = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LINKED_LOAD_LANE
    }
};

export const insertLinkedLoadLane = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_LOAD_LANE,
        payload: payload
    }
};

export const changeLinkedLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_LOAD_LANE,
        payload: payload
    }
};

export const changeSingleLinkedLoadLane = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINKED_LOAD_LANE,
        payload: payload
    }
};

export const removeLinkedLoadLane = (payload) => {
    return {
        type: actionTypes.REMOVE_LINKED_LOAD_LANE,
        payload: payload
    }
};

//#endregion

//#region LinkedLoadLanes Methods

export const fetchLinkedLoadLanes = (payload) => {
    return async (dispatch, getState) => {
        const linkedLoadLanesPath = '/linkedLoadLanes';
        try {
            dispatch(fetchLinkedLoadLanesStart());

            const state = getState();
            const linkedLoadLanesState = {...state.linkedLoadLanes};
            let pagination = {...linkedLoadLanesState.pagination};
            let searchParams = {...linkedLoadLanesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const linkedLoadLanesRes = await axiosAuthenticated.get(linkedLoadLanesPath, {params: {...searchParams}});
            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
                const linkedLoadLanes = linkedLoadLanesRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (linkedLoadLanes !== undefined && linkedLoadLanes !== null && linkedLoadLanes.length > 0) {
                    linkedLoadLanes.forEach((linkedLoadLane) => {
                        if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                            linkedLoadLane.locations.forEach((location) => {
                                if (!locationIds.includes(location.locationId)) {
                                    locationIds.push(location.locationId);
                                }
                            });
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                const transformedLinkedLoadLaneData = linkedLoadLanes.map((item) => {
                    let loadLane = item.loadLane;
                    if (loadLane !== undefined && loadLane !== null) {
                        let trailerType = null;
                        let equipmentNeeds = null;
                        if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                            trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                            equipmentNeeds = {
                                ...loadLane.equipmentNeeds,
                                trailerType: trailerType
                            };
                        }

                        loadLane = {
                            ...loadLane,
                            equipmentNeeds: equipmentNeeds
                        };
                    }

                    // Get Location Info
                    let loadLaneLocations = [];
                    if (item.locations !== undefined && item.locations !== null && item.locations.length > 0) {
                        loadLaneLocations = item.locations.map((location) => {
                            let locationLocation = null;
                            if (location.locationId !== undefined && location.locationId !== null) {
                                let locationObj = locations.find(i => i.id === location.locationId);
                                if (locationObj !== undefined && locationObj !== null) {
                                    locationLocation = {...locationObj};
                                }
                            }

                            return {
                                ...location,
                                location: locationLocation
                            };
                        });
                    }

                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (item.equipmentNeeds !== undefined && item.equipmentNeeds !== null && item.equipmentNeeds.trailerTypeId !== undefined && item.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === item.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...item.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    return {
                        ...item,
                        account: account,
                        loadLane: loadLane,
                        equipmentNeeds: equipmentNeeds,
                        locations: loadLaneLocations
                    };
                });

                // Read total count from server
                pagination.total = linkedLoadLanesRes.data.totalCount;
                pagination.current = linkedLoadLanesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLinkedLoadLanesSuccess({
                    records: transformedLinkedLoadLaneData,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedLoadLanesFail({error: error.message}));
        }
    }
};

export const fetchLinkedLoadLane = (id) => {
    return async (dispatch, getState) => {
        const linkedLoadLanesPath = '/linkedLoadLanes';
        try {
            dispatch(fetchLinkedLoadLaneStart());

            const linkedLoadLanesRes = await axiosAuthenticated.get(linkedLoadLanesPath + `/${id}`);

            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
                let linkedLoadLane = linkedLoadLanesRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                    linkedLoadLane.locations.forEach((location) => {
                        if (!locationIds.includes(location.locationId)) {
                            locationIds.push(location.locationId);
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });

                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                let loadLane = linkedLoadLane.loadLane;
                if (loadLane !== undefined && loadLane !== null) {
                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...loadLane.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    loadLane = {
                        ...loadLane,
                        equipmentNeeds: equipmentNeeds
                    };
                }

                // Get Location Info
                let loadLaneLocations = [];
                if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                    loadLaneLocations = linkedLoadLane.locations.map((location) => {
                        let locationLocation = null;
                        if (location.locationId !== undefined && location.locationId !== null) {
                            let locationObj = locations.find(i => i.id === location.locationId);
                            if (locationObj !== undefined && locationObj !== null) {
                                locationLocation = {...locationObj};
                            }
                        }

                        return {
                            ...location,
                            location: locationLocation
                        };
                    });
                }

                let trailerType = null;
                let equipmentNeeds = null;
                if (linkedLoadLane.equipmentNeeds !== undefined && linkedLoadLane.equipmentNeeds !== null && linkedLoadLane.equipmentNeeds.trailerTypeId !== undefined && linkedLoadLane.equipmentNeeds.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                    equipmentNeeds = {
                        ...linkedLoadLane.equipmentNeeds,
                        trailerType: trailerType
                    };
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(linkedLoadLane.accountId, accounts);

                const transformedLinkedLoadLane = {
                    ...linkedLoadLane,
                    account: account,
                    loadLane: loadLane,
                    equipmentNeeds: equipmentNeeds,
                    locations: loadLaneLocations
                };

                dispatch(fetchLinkedLoadLaneSuccess({record: transformedLinkedLoadLane}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedLoadLaneFail({error: error.message}));
        }
    }
};

export const addLinkedLoadLane = (payload) => {
    return async (dispatch, getState) => {
        const linkedLoadLanesPath = '/linkedLoadLanes';
        try {
            dispatch(addLinkedLoadLaneStart());

            const linkedLoadLanesRes = await axiosAuthenticated.post(linkedLoadLanesPath, {...payload});
            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 201) {
                const linkedLoadLane = linkedLoadLanesRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const trailerTypes = [...orchestratorState.trailerTypes];

                let locationIds = [];
                let locations = [];
                if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                    linkedLoadLane.locations.forEach((location) => {
                        if (!locationIds.includes(location.locationId)) {
                            locationIds.push(location.locationId);
                        }
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                        page: 1,
                        size: 100000,
                        isDeleted: false,
                        id: [...locationIds]
                    });
                    if (locationsRes && locationsRes.status === 200) {
                        locations = locationsRes.data.data;
                    }
                }

                let loadLane = linkedLoadLane.loadLane;
                if (loadLane !== undefined && loadLane !== null) {
                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...loadLane.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    loadLane = {
                        ...loadLane,
                        equipmentNeeds: equipmentNeeds
                    };
                }

                // Get Location Info
                let loadLaneLocations = [];
                if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                    loadLaneLocations = linkedLoadLane.locations.map((location) => {
                        let locationLocation = null;
                        if (location.locationId !== undefined && location.locationId !== null) {
                            let locationObj = locations.find(i => i.id === location.locationId);
                            if (locationObj !== undefined && locationObj !== null) {
                                locationLocation = {...locationObj};
                            }
                        }

                        return {
                            ...location,
                            location: locationLocation
                        };
                    });
                }

                let trailerType = null;
                let equipmentNeeds = null;
                if (linkedLoadLane.equipmentNeeds !== undefined && linkedLoadLane.equipmentNeeds !== null && linkedLoadLane.equipmentNeeds.trailerTypeId !== undefined && linkedLoadLane.equipmentNeeds.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                    equipmentNeeds = {
                        ...linkedLoadLane.equipmentNeeds,
                        trailerType: trailerType
                    };
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(linkedLoadLane.accountId, accounts);

                const newLinkedLoadLane = {
                    ...linkedLoadLane,
                    account: account,
                    loadLane: loadLane,
                    equipmentNeeds: equipmentNeeds,
                    locations: loadLaneLocations
                };

                dispatch(insertLinkedLoadLane(newLinkedLoadLane));

                dispatch(addLinkedLoadLaneSuccess());
                dispatch(addLinkedLoadLaneLoadingClear());
                dispatch(addLinkedLoadLaneErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLinkedLoadLaneFail({error: error.message}));
        }
    }
};

export const updateLinkedLoadLane = (linkedLoadLaneId, payload) => {
    return async (dispatch, getState) => {
        const linkedLoadLanesPath = '/linkedLoadLanes';
        try {
            dispatch(updateLinkedLoadLaneStart());

            const linkedLoadLanesRes = await axiosAuthenticated.put(linkedLoadLanesPath + `/${linkedLoadLaneId}`, {...payload});
            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
                const linkedLoadLane = linkedLoadLanesRes.data;

                if (linkedLoadLane.isDeleted === true) {
                    dispatch(removeLinkedLoadLane(linkedLoadLane));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];
                    const trailerTypes = [...orchestratorState.trailerTypes];

                    let locationIds = [];
                    let locations = [];
                    if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                        linkedLoadLane.locations.forEach((location) => {
                            if (!locationIds.includes(location.locationId)) {
                                locationIds.push(location.locationId);
                            }
                        });
                    }

                    if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                        const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', {
                            page: 1,
                            size: 100000,
                            isDeleted: false,
                            id: [...locationIds]
                        });
                        if (locationsRes && locationsRes.status === 200) {
                            locations = locationsRes.data.data;
                        }
                    }

                    let loadLane = linkedLoadLane.loadLane;
                    if (loadLane !== undefined && loadLane !== null) {
                        let trailerType = null;
                        let equipmentNeeds = null;
                        if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                            trailerType = trailerTypes.find(i => i.id === loadLane.equipmentNeeds.trailerTypeId);
                            equipmentNeeds = {
                                ...loadLane.equipmentNeeds,
                                trailerType: trailerType
                            };
                        }

                        loadLane = {
                            ...loadLane,
                            equipmentNeeds: equipmentNeeds
                        };
                    }

                    // Get Location Info
                    let loadLaneLocations = [];
                    if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                        loadLaneLocations = linkedLoadLane.locations.map((location) => {
                            let locationLocation = null;
                            if (location.locationId !== undefined && location.locationId !== null) {
                                let locationObj = locations.find(i => i.id === location.locationId);
                                if (locationObj !== undefined && locationObj !== null) {
                                    locationLocation = {...locationObj};
                                }
                            }

                            return {
                                ...location,
                                location: locationLocation
                            };
                        });
                    }

                    let trailerType = null;
                    let equipmentNeeds = null;
                    if (linkedLoadLane.equipmentNeeds !== undefined && linkedLoadLane.equipmentNeeds !== null && linkedLoadLane.equipmentNeeds.trailerTypeId !== undefined && linkedLoadLane.equipmentNeeds.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                        equipmentNeeds = {
                            ...linkedLoadLane.equipmentNeeds,
                            trailerType: trailerType
                        };
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(linkedLoadLane.accountId, accounts);

                    const updatedLinkedLoadLane = {
                        ...linkedLoadLane,
                        account: account,
                        loadLane: loadLane,
                        equipmentNeeds: equipmentNeeds,
                        locations: loadLaneLocations
                    };

                    dispatch(changeLinkedLoadLane(updatedLinkedLoadLane));
                    dispatch(changeSingleLinkedLoadLane(updatedLinkedLoadLane));
                }

                dispatch(updateLinkedLoadLaneSuccess());
                dispatch(updateLinkedLoadLaneLoadingClear());
                dispatch(updateLinkedLoadLaneErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLinkedLoadLaneFail({error: error.message}));
        }
    }
};

//#endregion