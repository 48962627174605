import React, { useMemo, useState } from 'react';
import { LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { Layout, Alert, Button, Card, Col, Input, Row, Spin, Typography, message } from "antd";
import { Redirect, Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import irisBackground from '../../assets/img/backgrounds/background-1.jpg';
import Logo from "../../components/Logo/Logo";
import classes from "./Login.module.scss";
import IRISFloatingFooter from '../../hoc/IRISFloatingFooter/IRISFloatingFooter';
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import axios from "axios";

const { Title, Paragraph } = Typography;

const Login = props => {
    const { from } = props.location.state || { from: { pathname: '/' } };

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);
    const isAuthComplete = useSelector(state => state.auth.isAuthComplete);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const error = useSelector(state => state.auth.error);
    const token = useSelector(state => state.auth.token);

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [showRegularLogin, setShowRegularLogin] = useState(false);
    const [showPasswordlessLogin, setShowPasswordlessLogin] = useState(false);
    const [showPasswordlessLoginValidation, setShowPasswordlessLoginValidation] = useState(false);
    const [isPasswordlessLoading, setIsPasswordlessLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const onSubmit = (data) => {
        const email = data.email;
        const password = data.password;
        dispatch(actionCreators.login(email, password));
    };

    const onPasswordlessLogin = (data) => {
        const resetRequestPath = "/rest-auth/passwordless/login/";
        const phone = data.phoneNumber;
        setIsPasswordlessLoading(true);
        axios.post(resetRequestPath, {
            phone: phone
        }).then(res => {
            if (res && res.status === 200) {
                message.success('Please check your text messages for your one-time use code.');
                
                setPhoneNumber(phone);
                setIsPasswordlessLoading(false);
                setShowPasswordlessLogin(false);
                setShowPasswordlessLoginValidation(true);
            }
        }).catch((error) => {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (error.response.data !== undefined && error.response.data !== null && error.response.data.message !== undefined && error.response.data.message !== null) {
                    errorMessage = error.response.data.message;
                    dispatch(actionCreators.authFail({ errorType: 'OTHER', error: errorMessage }));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(actionCreators.authFail({ errorType: 'OTHER', error: errorMessage }));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(actionCreators.authFail({ errorType: 'OTHER', error: errorMessage }));
            }
            setIsPasswordlessLoading(false);
        });
    };

    const onPasswordlessLoginValidation = (data) => {
        const phoneNumber = data.phoneNumber;
        const code = data.code;
        dispatch(actionCreators.passwordlessLoginValidation(phoneNumber, code));
    };

    const resetErrors = () => {
        dispatch(actionCreators.authClearError());
    };

    // TODO: does isAuthenticated not need to be in the useMemo dependencies?
    useMemo(() => {
        if (isAuthComplete === true && isAuthenticated === true) {
            setRedirectToReferrer(true);
        }
    }, [isAuthComplete]);

    if ((isLoading === true && (isAuthComplete === false || isAuthenticated === null) && error === null) || isAuthenticated === true || token !== null) {
        return (
            <Layout className={classes.loginBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
                {redirectToReferrer === true && <Redirect to={from} />}
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}></Spin>
            </Layout>
        );
    } else {
        return (
            <Layout className={classes.loginBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
                {redirectToReferrer === true && <Redirect to={from} />}
                <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
                <Card bordered={true} className={classes.card} size="small">
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isPasswordlessLoading === true}>
                        {(showRegularLogin === false && showPasswordlessLogin === false && showPasswordlessLoginValidation === false) && (
                            <>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Title level={2} style={{ textAlign: 'center' }}>Choose a Sign In Method</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Button type="primary" style={{ height: 60, marginBottom: 24 }} block onClick={() => { setShowRegularLogin(true); }}>Email and Password</Button>
                                        <Button type="primary" style={{ height: 60, marginBottom: 24 }} block onClick={() => { setShowPasswordlessLogin(true); }}>Phone Number</Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {(showRegularLogin === true) && (
                            <>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Title level={2} style={{ textAlign: 'center' }}>Sign-In</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <FormProvider {...methods}>
                                            <Form onSubmit={methods.handleSubmit(onSubmit)} className="login-form">
                                                <FormItem
                                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />}
                                                    rules={{
                                                        required: 'Required Field',
                                                        minLength: { value: 4, message: 'Email Address must be at least 4 characters long' }
                                                    }}
                                                    name="email"
                                                />
                                                <FormItem
                                                    render={({ onChange, onBlur, value, name }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />}
                                                    rules={{ required: 'Required Field' }}
                                                    name="password"
                                                />
                                                <div>
                                                    <Link to="/forgotpassword"><Button type="link" className="login-form-forgot" style={{ padding: 0 }}>Forgot Password</Button></Link>
                                                </div>
                                                <div>
                                                    <Button block type="primary" htmlType="submit" className="login-form-button">
                                                        Sign In
                                                    </Button>
                                                </div>
                                                {/* <div>
                                                    Not a member? <Link to="/register"><Button type="link">Register</Button></Link>
                                                </div> */}
                                                <div>
                                                    <Button type="link" onClick={() => { resetErrors(); setPhoneNumber(null); setShowRegularLogin(false); }} style={{ padding: 0 }}>Change Sign In Method</Button>
                                                </div>
                                            </Form>
                                        </FormProvider>
                                        {error && <Alert message={`${error}`} type="error" />}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {(showPasswordlessLogin === true) && (
                            <>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Title level={2} style={{ textAlign: 'center' }}>Sign-In</Title>
                                        <Paragraph style={{ textAlign: 'center' }}>Submit your Phone Number below to receive a text message with a one-time use pass code to use to Sign In. You'll have 15 minutes to submit that code, otherwise you will need to request another code.</Paragraph>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <FormProvider {...methods}>
                                            <Form onSubmit={methods.handleSubmit(onPasswordlessLogin)} className="login-form">
                                                <FormItem
                                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Phone Number" />}
                                                    rules={{
                                                        required: 'Required Field',
                                                        pattern: {
                                                            value: /^\d{10}$/,
                                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                                        }
                                                    }}
                                                    name="phoneNumber"
                                                    defaultValue={phoneNumber ? phoneNumber : ''}
                                                />
                                                <div>
                                                    <Button block type="primary" htmlType="submit" className="login-form-button">
                                                        Request Code
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button type="link" onClick={() => { resetErrors(); setPhoneNumber(null); setShowPasswordlessLogin(false); }} style={{ padding: 0 }}>Change Sign In Method</Button>
                                                </div>
                                            </Form>
                                        </FormProvider>
                                        {error && <Alert message={`${error}`} type="error" />}
                                    </Col>
                                </Row>
                            </>
                        )}
                        {(showPasswordlessLoginValidation === true) && (
                            <>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Title level={2} style={{ textAlign: 'center' }}>Sign-In</Title>
                                        <Title level={4} style={{ textAlign: 'center' }}>You should have received a text message with a code. You'll have 15 minutes to submit that code, otherwise you will need to request another code (press back button to submit your phone number again).</Title>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <FormProvider {...methods}>
                                            <Form onSubmit={methods.handleSubmit(onPasswordlessLoginValidation)} className="login-form">
                                                <FormItem
                                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Phone Number" />}
                                                    rules={{
                                                        required: 'Required Field',
                                                        pattern: {
                                                            value: /^\d{10}$/,
                                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                                        }
                                                    }}
                                                    name="phoneNumber"
                                                    defaultValue={phoneNumber ? phoneNumber : ''}
                                                />
                                                <FormItem
                                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Code" />}
                                                    rules={{ required: 'Required Field' }}
                                                    name="code"
                                                />
                                                <div>
                                                    <Button block type="primary" htmlType="submit" className="login-form-button">
                                                        Sign In
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button type="link" onClick={() => { resetErrors(); setShowPasswordlessLogin(true); setShowPasswordlessLoginValidation(false); }} style={{ padding: 0 }}>Request a New Code</Button>
                                                </div>
                                                <div>
                                                    <Button type="link" onClick={() => { resetErrors(); setPhoneNumber(null); setShowPasswordlessLogin(false); setShowPasswordlessLoginValidation(false); }} style={{ padding: 0 }}>Change Sign In Method</Button>
                                                </div>
                                            </Form>
                                        </FormProvider>
                                        {error && <Alert message={`${error}`} type="error" />}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Spin>
                    <IRISFloatingFooter />
                </Card>
            </Layout>
        );
    }
};

export default withRouter(Login);