import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getLineItemTypes} from "./orchestrator";
import logger from "../../shared/logger";

//#region Line Item Types Functions

export const fetchLineItemTypesStart = () => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_START
    }
};

export const fetchLineItemTypesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_SUCCESS,
        payload: payload
    }
};

export const fetchLineItemTypesFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINE_ITEM_TYPES_FAIL,
        payload: payload
    }
};

export const clearLineItemTypes = () => {
    return {
        type: actionTypes.CLEAR_LINE_ITEM_TYPES
    }
};

export const addLineItemTypeStart = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_START
    }
};

export const addLineItemTypeSuccess = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_SUCCESS
    }
};

export const addLineItemTypeFail = (payload) => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_FAIL,
        payload: payload
    }
};

export const addLineItemTypeErrorClear = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_ERROR_CLEAR
    }
};

export const addLineItemTypeLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE_LOADING_CLEAR
    }
};

export const updateLineItemTypeStart = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_START
    }
};

export const updateLineItemTypeSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_SUCCESS
    }
};

export const updateLineItemTypeFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_FAIL,
        payload: payload
    }
};

export const updateLineItemTypeErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_ERROR_CLEAR
    }
};

export const updateLineItemTypeLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE_LOADING_CLEAR
    }
};

export const cancelAddLineItemType = () => {
    return {
        type: actionTypes.CANCEL_ADD_LINE_ITEM_TYPE
    }
};

export const cancelUpdateLineItemType = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LINE_ITEM_TYPE
    }
};

export const insertLineItemType = (payload) => {
    return {
        type: actionTypes.ADD_LINE_ITEM_TYPE,
        payload: payload
    }
};

export const changeLineItemType = (payload) => {
    return {
        type: actionTypes.UPDATE_LINE_ITEM_TYPE,
        payload: payload
    }
};

export const changeSingleLineItemType = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINE_ITEM_TYPE,
        payload: payload
    }
};

export const removeLineItemType = (payload) => {
    return {
        type: actionTypes.REMOVE_LINE_ITEM_TYPE,
        payload: payload
    }
};

//#endregion

//#region Line Item Types Methods

export const fetchLineItemTypes = (payload) => {
    return async (dispatch, getState) => {
        const lineItemTypesPath = '/lineItemTypes';
        try {
            dispatch(fetchLineItemTypesStart());

            const state = getState();
            const lineItemTypesState = {...state.lineItemTypes};
            let pagination = {...lineItemTypesState.pagination};
            let searchParams = {...lineItemTypesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const lineItemTypesRes = await axiosAuthenticated.get(lineItemTypesPath, {params: {...searchParams}});
            if (lineItemTypesRes && lineItemTypesRes.status === 200) {
                const lineItemtypes = lineItemTypesRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];

                const transformedLineItemTypes = lineItemtypes.map((item) => {
                    let trailerTypesList = [];
                    if (item.trailerTypeIds !== undefined && item.trailerTypeIds !== null && item.trailerTypeIds.length > 0) {
                        for (let i = 0; i < item.trailerTypeIds.length; i++) {
                            const trailerTypeId = item.trailerTypeIds[i];
                            let trailerType = trailerTypes.find(i => i.id === trailerTypeId);
                            if (trailerType !== undefined && trailerType !== null) {
                                trailerTypesList.push(trailerType);
                            }
                        }
                    }

                    return {
                        ...item,
                        trailerTypes: trailerTypesList
                    };
                });

                // Read total count from server
                pagination.total = lineItemTypesRes.data.totalCount;
                pagination.current = lineItemTypesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLineItemTypesSuccess({
                    records: transformedLineItemTypes,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLineItemTypesFail({error: error.message}));
        }
    }
};

export const addLineItemType = (payload) => {
    return async (dispatch, getState) => {
        const lineItemTypesPath = '/lineItemTypes';
        try {
            dispatch(addLineItemTypeStart());

            if (payload.upCharge !== undefined && payload.upCharge !== null) {
                payload.upCharge = Number(payload.upCharge);
            }

            if (payload.cost !== undefined && payload.cost !== null) {
                payload.cost = Number(payload.cost);
            }

            const lineItemTypeRes = await axiosAuthenticated.post(lineItemTypesPath, {...payload});
            if (lineItemTypeRes && lineItemTypeRes.status === 201) {
                let newLineItemType = lineItemTypeRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];

                let trailerTypesList = [];
                if (newLineItemType.trailerTypeIds !== undefined && newLineItemType.trailerTypeIds !== null && newLineItemType.trailerTypeIds.length > 0) {
                    for (let i = 0; i < newLineItemType.trailerTypeIds.length; i++) {
                        const trailerTypeId = newLineItemType.trailerTypeIds[i];
                        let trailerType = trailerTypes.find(i => i.id === trailerTypeId);
                        if (trailerType !== undefined && trailerType !== null) {
                            trailerTypesList.push(trailerType);
                        }
                    }
                }

                newLineItemType.trailerTypes = trailerTypesList;

                dispatch(insertLineItemType(newLineItemType));

                // refresh lineItemTypes orchestrator
                dispatch(getLineItemTypes(true));

                dispatch(addLineItemTypeSuccess());
                dispatch(addLineItemTypeLoadingClear());
                dispatch(addLineItemTypeErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLineItemTypeFail({error: error.response.data.message ? error.response.data.message : error.message}));
        }
    }
};

export const updateLineItemType = (lineItemTypeId, payload) => {
    return async (dispatch, getState) => {
        const lineItemTypesPath = '/lineItemTypes';
        try {
            dispatch(updateLineItemTypeStart());

            if (payload.upCharge !== undefined && payload.upCharge !== null) {
                payload.upCharge = Number(payload.upCharge);
            }

            if (payload.cost !== undefined && payload.cost !== null) {
                payload.cost = Number(payload.cost);
            }

            const lineItemTypeRes = await axiosAuthenticated.put(lineItemTypesPath + `/${lineItemTypeId}`, {...payload});
            if (lineItemTypeRes && lineItemTypeRes.status === 200) {
                let updatedLineItemType = lineItemTypeRes.data;

                if (updatedLineItemType.isDeleted === true) {
                    dispatch(removeLineItemType(updatedLineItemType));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const trailerTypes = [...orchestratorState.trailerTypes];

                    let trailerTypesList = [];
                    if (updatedLineItemType.trailerTypeIds !== undefined && updatedLineItemType.trailerTypeIds !== null && updatedLineItemType.trailerTypeIds.length > 0) {
                        for (let i = 0; i < updatedLineItemType.trailerTypeIds.length; i++) {
                            const trailerTypeId = updatedLineItemType.trailerTypeIds[i];
                            let trailerType = trailerTypes.find(i => i.id === trailerTypeId);
                            if (trailerType !== undefined && trailerType !== null) {
                                trailerTypesList.push(trailerType);
                            }
                        }
                    }

                    updatedLineItemType.trailerTypes = trailerTypesList;

                    dispatch(changeLineItemType(updatedLineItemType));
                    dispatch(changeSingleLineItemType(updatedLineItemType));
                }

                // refresh lineItemTypes orchestrator
                dispatch(getLineItemTypes(true));

                dispatch(updateLineItemTypeSuccess());
                dispatch(updateLineItemTypeLoadingClear());
                dispatch(updateLineItemTypeErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLineItemTypeFail({error: error.message}));
        }
    }
};

//#endregion
