import React, { useState, useMemo } from 'react';
import { Alert, Spin, Checkbox } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItem from '../FormItem/FormItem';
import { isEmpty } from 'lodash';

const EditLoadStatusScheduled = props => {
    //#region props and constants

    const load = props.load;
    const accounts = props.accounts;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [hasDrivers, setHasDrivers] = useState(false);
    const [hasApprovedDrivers, setHasApprovedDrivers] = useState(false);
    const [hasAssets, setHasAssets] = useState(false);
    const [hasActiveCarrier, setHasActiveCarrier] = useState(false);
    const [hasVerifiedCarrier, setHasVerifiedCarrier] = useState(false);
    const [overrideScheduled, setOverrideScheduled] = useState(false);

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = () => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            eventType: 'LOAD_SCHEDULED'
        }));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        let hasDriver1ApprovedValue = false;
        let hasDriver2ApprovedValue = false;
        let hasDriversValue = false;
        let hasAssetsValue = false;
        if (load !== undefined && load !== null && !isEmpty(load)) {
            const hasTeamDriving = load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;

            if (load.drivers !== null && load.drivers !== undefined && load.drivers.length > 0) {
                // if team driving is selected then make sure there are 2 drivers assigned, otherwise make sure there is only 1 driver assigned to the load
                if (hasTeamDriving === true && load.drivers.length === 2) {
                    hasDriversValue = true;
                    let loadDriver1 = load.drivers[0];
                    let loadDriver2 = load.drivers[1];
                    // make sure all drivers assigned to the load are approved
                    hasDriver1ApprovedValue = loadDriver1 && loadDriver1.driver && loadDriver1.driver.isApproved && loadDriver1.driver.isApproved === true ? true : false;
                    hasDriver2ApprovedValue = loadDriver2 && loadDriver2.driver && loadDriver2.driver.isApproved && loadDriver2.driver.isApproved === true ? true : false;
                } else if (hasTeamDriving === false && load.drivers.length === 1) {
                    hasDriversValue = true;
                    let loadDriver1 = load.drivers[0];
                    // make sure all drivers assigned to the load are approved
                    hasDriver1ApprovedValue = loadDriver1 && loadDriver1.driver && loadDriver1.driver.isApproved && loadDriver1.driver.isApproved === true ? true : false;
                    hasDriver2ApprovedValue = true;
                }
            }
            if (load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0) {
                hasAssetsValue = true;
            }
        }

        setHasDrivers(hasDriversValue === true);
        setHasApprovedDrivers(hasDriver1ApprovedValue === true && hasDriver2ApprovedValue === true);
        setHasAssets(hasAssetsValue === true);
    }, [load]);

    useMemo(() => {
        let hasActiveCarrierValue = false;
        let hasVerifiedCarrierValue = false;
        if (accounts !== undefined && accounts !== null && accounts.length > 0 && load !== undefined && load !== null && !isEmpty(load) && load.assignedCarrierId !== undefined && load.assignedCarrierId !== null) {
            let assignedCarrier = accounts.find(i => i.id === load.assignedCarrierId);
            if (assignedCarrier !== undefined && assignedCarrier !== null) {
                hasActiveCarrierValue = assignedCarrier.isActive !== undefined && assignedCarrier.isActive !== null && assignedCarrier.isActive === true ? true : false;
                hasVerifiedCarrierValue = assignedCarrier.isVerified !== undefined && assignedCarrier.isVerified !== null && assignedCarrier.isVerified === true ? true : false;
            }
        }

        setHasActiveCarrier(hasActiveCarrierValue === true);
        setHasVerifiedCarrier(hasVerifiedCarrierValue === true);
    }, [accounts, load])

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Schedule the Load</legend>

                        {hasDrivers === false && (load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING')) === false ? (<Alert message="You must assign a driver to this load." type="error" />) : null}
                        {hasDrivers === false && (load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING')) === true ? (<Alert message="You must assign two drivers to this load." type="error" />) : null}
                        {hasAssets === false ? (<Alert message="You must assign at least one asset to this load." type="error" />) : null}
                        {hasDrivers === true && hasApprovedDrivers === false && (load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING')) === false ? (<Alert message="The driver assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                        {hasDrivers === true && hasApprovedDrivers === false && (load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING')) === true ? (<Alert message="Both drivers assigned to this load must be approved before this load can be dispatched." type="error" />) : null}
                        {hasActiveCarrier === false ? (<Alert message="The Carrier Account must be activated first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}
                        {hasVerifiedCarrier === false ? (<Alert message="The Carrier Account must be verified first before dispatching this load. Please contact Customer Support for assistance." type="error" />) : null}

                        {entityType === "STAFF" && (
                            <div style={{ marginTop: 60 }}>
                                <FormItem {...formItemLayout} label={`Over-Ride Scheduling of this Load without Drivers or Assets due to Carrier Not Cooperating.`} format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Checkbox
                                            onBlur={onBlur}
                                            onChange={e => { setOverrideScheduled(e.target.checked); onChange(e.target.checked); }}
                                            checked={value}
                                            name={name}
                                        />
                                    )}
                                    name="overrideScheduled"
                                    defaultValue={false}
                                />
                            </div>
                        )}
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} cancelDisabled={isLoading === true && error === null} submitDisabled={((hasDrivers === false || hasApprovedDrivers === false || hasAssets === false || hasActiveCarrier === false || hasVerifiedCarrier === false) && overrideScheduled === false) || (isLoading === true && error === null)} submitText="Schedule the Load" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusScheduled;