import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs } from 'antd';
import Locations from "../../views/Locations/Locations";
import Employees from "../../views/Employees/Employees";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTractor } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { useLocation } from 'react-router';
import OpenLoads from "../../views/OpenLoads/OpenLoads";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import CompanyProducerSnapshot from '../../components/CompanyProducerSnapshot/CompanyProducerSnapshot';

const { TabPane } = Tabs;

const Producer = props => {
    const producerId = props.match.params.producerId;

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordLoading);
    const error = useSelector(state => state.accounts.recordError);
    const account = useSelector(state => state.accounts.record);

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAccount(producerId));
    }, [producerId]);

    useEffect(() => {
        if (account && account.logoFileId) {
            axiosAuthenticated.get('/documents/' + account.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [account]);

    let breadcrumbs = [
        {
            path: `/producers`,
            breadcrumbName: 'Producers',
        },
        {
            path: `/producers/${producerId ? producerId : null}`,
            breadcrumbName: `${account ? account.name : ''}`,
        },
    ];

    if (account && account.id === producerId) {
        return (
            <>
                <Header
                    title={account ? account.name : 'Loading...'}
                    title2={account ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faTractor} />}
                    breadcrumbs={breadcrumbs}
                    highlights={[
                        {
                            title: 'EIN #',
                            value: `${account ? account.ein : ''}`
                        }
                    ]}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(account !== null && isLoading === false) && <CompanyProducerSnapshot record={account} />}
                            </Spin>
                        </div>
                    </TabPane>
                    <TabPane tab="Open Loads" key="openLoads">
                        <div style={{ padding: 24 }}>
                            <OpenLoads shipperId={null} carrierId={null} accountId={producerId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Booked Loads" key="bookedLoads">
                        <div style={{ padding: 24 }}>
                            <BookedLoads shipperId={null} carrierId={null} accountId={producerId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="In Transit Loads" key="intransitLoads">
                        <div style={{ padding: 24 }}>
                            <InTransitLoads shipperId={null} carrierId={null} accountId={producerId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Completed Loads" key="completedLoads">
                        <div style={{ padding: 24 }}>
                            <CompletedLoads shipperId={null} carrierId={null} accountId={producerId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                    <TabPane tab="Locations" key="locations">
                        <div style={{ padding: 24 }}>
                            <Locations accountId={producerId} />
                        </div>
                    </TabPane>
                    <TabPane tab="Employees" key="employees">
                        <div style={{ padding: 24 }}>
                            <Employees accountId={producerId} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                </Tabs>
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Producer);