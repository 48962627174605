import React from 'react';
import { Modal } from "antd";
import TermsOfUse from "../../components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "../CookiePolicy/CookiePolicy";

const TermsOfUseModal = props => {
    return (
        <Modal
            title={'Terms of Use and Privacy Policy'}
            visible={props.showModal}
            width="90%"
            style={{ top: 0 }}
            onCancel={props.toggleModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <TermsOfUse />
            <PrivacyPolicy />
            <CookiePolicy />
        </Modal>
    );
};

export default TermsOfUseModal;
