import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearRecords,
    clearUpdateRecordError,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordLoading,
    clearAddRecordLoading,
    clearRemoveRecordError,
    clearRemoveRecordLoading,
    removeRecordFail,
    removeRecordStart,
    removeRecordSuccess,
    cancelAddRecord,
    cancelUpdateRecord,
    cancelRemoveRecord,
    addRecordToBottom,
    removeRecord,
    updateRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    isRecordRemoveLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    removeRecordError: null,
    searchParams: {},
    pagination: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INVOICE_LINE_ITEMS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_INVOICE_LINE_ITEMS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEMS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_INVOICE_LINE_ITEMS: return clearRecords(state);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_START: return fetchRecordStart(state);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_INVOICE_LINE_ITEM_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_INVOICE_LINE_ITEM: return clearRecord(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_INVOICE_LINE_ITEM_START: return addRecordStart(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.CANCEL_ADD_INVOICE_LINE_ITEM: return cancelAddRecord(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_INVOICE_LINE_ITEM: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_START: return updateRecordStart(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.CANCEL_UPDATE_INVOICE_LINE_ITEM: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM: return removeRecord(state, action.payload);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_START: return removeRecordStart(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_SUCCESS: return removeRecordSuccess(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_FAIL: return removeRecordFail(state, action.payload);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_ERROR_CLEAR: return clearRemoveRecordError(state);
        case actionTypes.REMOVE_INVOICE_LINE_ITEM_LOADING_CLEAR: return clearRemoveRecordLoading(state);
        case actionTypes.CANCEL_REMOVE_INVOICE_LINE_ITEM: return cancelRemoveRecord(state);
        default:
            return state;
    }
};

export default reducer;