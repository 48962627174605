import React, { useState } from 'react';
import { CheckOutlined, PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer, Tooltip, Card } from 'antd';
import classes from './LoadInvoice.module.scss';
import NewInvoiceLineItem from '../../components/NewInvoiceLineItem/NewInvoiceLineItem';
import EditInvoiceLineItem from '../../components/EditInvoiceLineItem/EditInvoiceLineItem';
import RemoveInvoiceLineItem from '../../components/RemoveInvoiceLineItem/RemoveInvoiceLineItem';
import NewTransaction from '../../components/NewTransaction/NewTransaction';
import EditTransaction from '../../components/EditTransaction/EditTransaction';
import RemoveTransaction from '../../components/RemoveTransaction/RemoveTransaction';
import ValueFormatting from '../../shared/ValueFormatting';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import Pricing from '../../shared/pricing';
import StringFormatter from '../../shared/stringFormatter';
import EditInvoiceLineItemStatus from '../EditInvoiceLineItemStatus/EditInvoiceLineItemStatus';
import EditInvoiceLineItemApprovalStatus from '../EditInvoiceLineItemApprovalStatus/EditInvoiceLineItemApprovalStatus';
import EditTransactionPaymentStatus from '../EditTransactionPaymentStatus/EditTransactionPaymentStatus';
import DataTable from '../DataTable/DataTable';
import Enums from '../../shared/enums';
import { useSelector } from 'react-redux';

const stringFormatter = new StringFormatter();

const LoadInvoice = props => {
    const fullWidth = global.window.innerWidth;
    const load = props.load;
    const stops = props.stops;
    const shipperId = props.load !== undefined && props.load !== null ? props.load.shipperId : null;
    const carrierId = props.load !== undefined && props.load !== null ? props.load.assignedCarrierId : null;
    const invoiceLineItems = props.invoiceLineItems !== undefined && props.invoiceLineItems !== null ? props.invoiceLineItems : [];
    const transactions = props.transactions !== undefined && props.transactions !== null ? props.transactions : [];
    const currentStatus = props.currentStatus;
    const entityType = props.entityType;

    const singularEntityName = "Invoice Line Item";
    const pluralEntityName = "Invoice Line Items";
    const singularEntityName2 = "Transaction";
    const pluralEntityName2 = "Transactions";

    const isAdmin = useSelector(state => state.auth.isAdmin);

    const invoiceColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (load.loadStatus !== 'CLOSED' && load.loadStatus !== 'CANCELLED') {
                    return (
                        <>
                            {record.approvalStatus !== "APPROVED" ? (
                                <Tooltip placement="top" title={'Approve Invoice Line Item'}>
                                    <Button type="primary" shape="circle" icon={<CheckOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditApprovalStatusEntity(); }} />
                                </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={'Update Status of Invoice Line Item'}>
                                <Button type="primary" shape="circle" icon={<QuestionOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditStatusEntity(); }} style={{ marginLeft: 8, marginRight: 8 }} />
                            </Tooltip>
                            <AddEditChangeRemoveButtons
                                showAdd={false} addText='' addAction={null}
                                showEdit={(record.itemType !== undefined && record.itemType !== null && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType) && record.approvalStatus !== "APPROVED") || isAdmin === true ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                                showChange={false} changeText='' changeAction={null}
                                showRemove={(record.itemType !== undefined && record.itemType !== null && !Enums.StopFees.doesNameExist(record.itemType) && !Enums.EquipmentSpecifics.doesNameExist(record.itemType) && !Enums.LoadRequirements.doesNameExist(record.itemType)) || isAdmin === true ? true : false} removeText={"Delete " + singularEntityName} removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
                            />
                        </>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Base Price',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
        },
        {
            title: 'Total',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Owed By',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Owed To',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceStatus", text, null); },
        },
        {
            title: 'Approval',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            textAlign: 'right',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceApprovalStatus", text, null); },
        },
    ];

    const transactionColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                if (load.loadStatus !== 'CLOSED' && load.loadStatus !== 'CANCELLED') {
                    return (
                        <>
                            {record.paymentStatus !== 'COMPLETED' ? (
                                <Tooltip placement="top" title={'Update Payment Status of Transaction'}>
                                    <Button type="primary" shape="circle" icon={<QuestionOutlined />} onClick={(e) => { e.stopPropagation(); setSelectedRecord2(record); toggleEditPaymentStatusEntity2(); }} />
                                </Tooltip>
                            ) : null}
                            <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                                showAdd={false} addText='' addAction={null}
                                showEdit={record.paymentStatus !== 'COMPLETED'} editText={"Edit " + singularEntityName2 + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord2(record); toggleEditEntity2(); }}
                                showChange={false} changeText='' changeAction={null}
                                showRemove={record.paymentStatus !== 'COMPLETED'} removeText={"Delete " + singularEntityName2} removeAction={(e) => { e.stopPropagation(); setSelectedRecord2(record); toggleRemoveEntity2(); }}
                            />
                        </>
                    );
                } else {
                    return '';
                }
            },
            align: 'center',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentMethod", text, null); },
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("PaymentStatus", text, null); },
        },
        {
            title: 'From',
            dataIndex: 'fromSource',
            key: 'fromSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'fromEntityType',
            key: 'fromEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'To',
            dataIndex: 'toSource',
            key: 'toSource',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceSourceAccountType", text, null); },
        },
        {
            title: 'For',
            dataIndex: 'toEntityType',
            key: 'toEntityType',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("InvoiceAccountType", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Transaction Fee',
            dataIndex: 'transactionFee',
            key: 'transactionFee',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Net Amount',
            dataIndex: 'netAmount',
            key: 'netAmount',
            textAlign: 'center',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Paid At',
            dataIndex: 'paidAt',
            key: 'paidAt',
            textAlign: 'left',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
        {
            title: 'Quick Books TransactionId',
            dataIndex: 'qbTransactionId',
            key: 'qbTransactionId',
            textAlign: 'center',
        },
    ];

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showEditStatusEntity, setShowEditStatusEntity] = useState(false);
    const [showEditApprovalStatusEntity, setShowEditApprovalStatusEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord2, setSelectedRecord2] = useState({});
    const [showEditEntity2, setShowEditEntity2] = useState(false);
    const [showEditPaymentStatusEntity2, setShowEditPaymentStatusEntity2] = useState(false);
    const [showNewEntity2, setShowNewEntity2] = useState(false);
    const [showRemoveEntity2, setShowRemoveEntity2] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditStatusEntity = () => {
        setShowEditStatusEntity(!showEditStatusEntity);
    };

    const toggleEditApprovalStatusEntity = () => {
        setShowEditApprovalStatusEntity(!showEditApprovalStatusEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleEditEntity2 = () => {
        setShowEditEntity2(!showEditEntity2);
    };

    const toggleEditPaymentStatusEntity2 = () => {
        setShowEditPaymentStatusEntity2(!showEditPaymentStatusEntity2);
    };

    const toggleNewEntity2 = () => {
        setShowNewEntity2(!showNewEntity2);
    };

    const toggleRemoveEntity2 = () => {
        setShowRemoveEntity2(!showRemoveEntity2);
    };

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewInvoiceLineItem cancel={toggleNewEntity} load={load} stops={stops} shipperId={shipperId} carrierId={carrierId} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItem cancel={toggleEditEntity} invoiceLineItem={selectedRecord} load={load} stops={stops} shipperId={shipperId} carrierId={carrierId} />
        </Drawer>
    );

    const editStatusEntityComponents = (
        <Drawer
            title={"Update Status of " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditStatusEntity}
            visible={showEditStatusEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemStatus cancel={toggleEditStatusEntity} invoiceLineItem={selectedRecord} load={load} />
        </Drawer>
    );

    const editApprovalStatusEntityComponents = (
        <Drawer
            title={"Approve " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditApprovalStatusEntity}
            visible={showEditApprovalStatusEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditInvoiceLineItemApprovalStatus cancel={toggleEditApprovalStatusEntity} invoiceLineItem={selectedRecord} load={load} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Delete " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveInvoiceLineItem cancel={toggleRemoveEntity} invoiceLineItem={selectedRecord} load={load} shipperId={shipperId} carrierId={carrierId} />
        </Drawer>
    );

    const newEntityComponents2 = (
        <Drawer
            title={"New " + singularEntityName2}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity2}
            visible={showNewEntity2}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewTransaction cancel={toggleNewEntity2} load={load} shipperId={shipperId} carrierId={carrierId} invoiceLineItems={invoiceLineItems} />
        </Drawer>
    );

    const editEntityComponents2 = (
        <Drawer
            title={"Edit " + singularEntityName2}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity2}
            visible={showEditEntity2}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransaction cancel={toggleEditEntity2} transaction={selectedRecord2} load={load} shipperId={shipperId} carrierId={carrierId} invoiceLineItems={invoiceLineItems} />
        </Drawer>
    );

    const editPaymentStatusEntityComponents2 = (
        <Drawer
            title={"Update Payment Status of " + singularEntityName2}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditPaymentStatusEntity2}
            visible={showEditPaymentStatusEntity2}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTransactionPaymentStatus cancel={toggleEditPaymentStatusEntity2} transaction={selectedRecord2} load={load} invoiceLineItems={invoiceLineItems} />
        </Drawer>
    );

    const removeEntityComponents2 = (
        <Drawer
            title={"Delete " + singularEntityName2}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity2}
            visible={showRemoveEntity2}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveTransaction cancel={toggleRemoveEntity2} transaction={selectedRecord2} load={load} shipperId={shipperId} carrierId={carrierId} />
        </Drawer>
    );

    const invoiceTitle = (forAccount) => (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{'Manage ' + pluralEntityName + ' for ' + forAccount}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {load.loadStatus !== 'CLOSED' &&
                    <Tooltip placement="top" title={'New ' + singularEntityName}><Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={toggleNewEntity} /></Tooltip>
                }
            </Col>
        </Row>
    );

    const transactionTitle = (
        <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
            <Col xs={{ span: 12 }}>
                <b>{'Manage ' + pluralEntityName2}</b>
            </Col>
            <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                {carrierId !== undefined && carrierId !== null && shipperId !== undefined && shipperId !== null && load.loadStatus !== 'CLOSED' ? (
                    <Tooltip placement="top" title={'New ' + singularEntityName2}><Button key="1" type="primary" icon={<PlusOutlined />} shape="circle" onClick={toggleNewEntity2} /></Tooltip>
                ) : null}
            </Col>
        </Row>
    );

    const calculateInvoiceTotals = () => {
        let invoiceRows = [];
        let totals = Pricing.calculateSourceTotals(invoiceLineItems, transactions);

        invoiceRows.push(
            <tr className={classes.thickLine} key="shipper-totals">
                <td className={classes.textRight}><b>Shipper</b></td>
                <td className={classes.textRight}>{totals.owedByShipper > 0 && <ValueFormatting.Money value={totals.owedByShipper} />}</td>
                <td className={classes.textRight}>{totals.owedToShipper > 0 && <ValueFormatting.Money value={totals.owedToShipper} />}</td>
                <td className={classes.textRight}>{totals.paidByShipper > 0 && <ValueFormatting.Money value={totals.paidByShipper} />}</td>
                <td className={classes.textRight}>{totals.paidToShipper > 0 && <ValueFormatting.Money value={totals.paidToShipper} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedByShipper - totals.owedToShipper - totals.paidByShipper + totals.paidToShipper} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr key="carrier-totals">
                <td className={classes.textRight}><b>Carrier</b></td>
                <td className={classes.textRight}>{totals.owedByCarrier > 0 && <ValueFormatting.Money value={totals.owedByCarrier} />}</td>
                <td className={classes.textRight}>{totals.owedToCarrier > 0 && <ValueFormatting.Money value={totals.owedToCarrier} />}</td>
                <td className={classes.textRight}>{totals.paidByCarrier > 0 && <ValueFormatting.Money value={totals.paidByCarrier} />}</td>
                <td className={classes.textRight}>{totals.paidToCarrier > 0 && <ValueFormatting.Money value={totals.paidToCarrier} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedToCarrier - totals.owedByCarrier - totals.paidToCarrier + totals.paidByCarrier} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr key="factoring-totals">
                <td className={classes.textRight}><b>Factoring Company</b></td>
                <td className={classes.textRight}>{totals.owedByFactoring > 0 && <ValueFormatting.Money value={totals.owedByFactoring} />}</td>
                <td className={classes.textRight}>{totals.owedToFactoring > 0 && <ValueFormatting.Money value={totals.owedToFactoring} />}</td>
                <td className={classes.textRight}>{totals.paidByFactoring > 0 && <ValueFormatting.Money value={totals.paidByFactoring} />}</td>
                <td className={classes.textRight}>{totals.paidToFactoring > 0 && <ValueFormatting.Money value={totals.paidToFactoring} />}</td>
                <td className={classes.textRight}><ValueFormatting.Money value={totals.owedToFactoring- totals.owedByFactoring - totals.paidToFactoring + totals.paidByFactoring} /></td>
            </tr>
        );
        invoiceRows.push(
            <tr className={classes.thickLine} key="iris-totals">
                <th className={classes.textRight}><b>Pulogix</b></th>
                <th className={classes.textRight}>{totals.owedByIRIS > 0 && <ValueFormatting.Money value={totals.owedByIRIS} />}</th>
                <th className={classes.textRight}>{totals.owedToIRIS > 0 && <ValueFormatting.Money value={totals.owedToIRIS} />}</th>
                <th className={classes.textRight}>{totals.paidByIRIS > 0 && <ValueFormatting.Money value={totals.paidByIRIS} />}</th>
                <th className={classes.textRight}>{totals.paidToIRIS > 0 && <ValueFormatting.Money value={totals.paidToIRIS} />}</th>
                <th className={classes.textRight}><ValueFormatting.Money value={totals.owedToIRIS - totals.owedByIRIS - totals.paidToIRIS + totals.paidByIRIS} /></th>
            </tr>
        );

        return (
            <table style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                <thead>
                    <tr>
                        <th></th>
                        <th className={classes.textRight}>Owed By</th>
                        <th className={classes.textRight}>Owed To</th>
                        <th className={classes.textRight}>Paid By</th>
                        <th className={classes.textRight}>Paid To</th>
                        <th className={classes.textRight}>Balance Due</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceRows}
                </tbody>
            </table>
        );
    };

    if (entityType === "STAFF") {
        return (
            <>
                <Card bordered={true} className={classes.card} style={{ marginBottom: 24 }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            {(currentStatus === 'PENDING') ? (
                                <Button href={`/priceconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5, marginLeft: 8 }} disabled={shipperId === null || shipperId === undefined}>Generate Price Confirmation</Button>
                            ) : null}
                            {(currentStatus !== 'COMPLETED' && currentStatus !== 'CLOSED' && currentStatus !== 'REOPENED' && currentStatus !== 'CANCELLED') ? (
                                <Button href={`/rateconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5, marginLeft: 8 }} disabled={carrierId === null || carrierId === undefined}>Generate Rate Confirmation</Button>
                            ) : null}
                            {(currentStatus !== 'PENDING' && currentStatus !== 'APPROVED' && currentStatus !== 'PENDING_RATE_CONFIRMATION') ? (
                                <Button href={`/shipperinvoice/${load.id}`} target='_blank' type="primary" style={{ marginTop: 5, marginBottom: 5, marginLeft: 8 }} disabled={shipperId === null || shipperId === undefined}>Generate Shipper Invoice</Button>
                            ) : null}
                        </Col>
                    </Row>

                    {calculateInvoiceTotals()}

                </Card>

                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'SHIPPER' || i.toEntityType === 'SHIPPER'))}
                    columns={invoiceColumns}
                    hidePaging={true}
                    rowClassName={classes.dataTableRow}
                    title={() => (invoiceTitle('Shipper'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                />
                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'CARRIER' || i.toEntityType === 'CARRIER'))}
                    columns={invoiceColumns}
                    hidePaging={true}
                    rowClassName={classes.dataTableRow}
                    title={() => (invoiceTitle('Carrier'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                />
                <DataTable
                    dataSource={invoiceLineItems.filter(i => (i.fromEntityType === 'FACTORING' || i.toEntityType === 'FACTORING'))}
                    columns={invoiceColumns}
                    hidePaging={true}
                    rowClassName={classes.dataTableRow}
                    title={() => (invoiceTitle('Factoring Company'))}
                    style={{ backgroundColor: '#ffffff', marginBottom: 24 }}
                    rowKey={record => record.id}
                />
                <DataTable
                    dataSource={transactions}
                    columns={transactionColumns}
                    hidePaging={true}
                    rowClassName={classes.dataTableRow}
                    title={() => (transactionTitle)}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.id}
                />
                {newEntityComponents}
                {editEntityComponents}
                {editStatusEntityComponents}
                {editApprovalStatusEntityComponents}
                {removeEntityComponents}
                {newEntityComponents2}
                {editEntityComponents2}
                {editPaymentStatusEntityComponents2}
                {removeEntityComponents2}
            </>
        );
    } else {
        return null;
    }
};

export default LoadInvoice;