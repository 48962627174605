import React from 'react';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';

const stringFormatter = new StringFormatter();

const LoadCarrierRejectionList = props => {
    const carrierRejections = props.carrierRejections;
    const entityType = props.entityType;

    const singularEntityName = "Carrier Rejection";
    const pluralEntityName = "Carrier Rejections";

    const columns = [
        {
            title: 'Reason',
            dataIndex: 'rejectedReason',
            key: 'rejectedReason',
        },
        {
            title: 'Rejected By Carrier',
            dataIndex: ['rejectedByCarrier', 'name'],
            key: 'rejectedByCarrier.name',
        },
        {
            title: 'Rejected At',
            dataIndex: 'rejectedAt',
            key: 'rejectedAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    if (entityType === "STAFF") {
        return (
            <DataTable
                dataSource={carrierRejections}
                columns={columns}
                hidePaging={true}
                footer={false}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={null}
                rowKey={record => record.id}
            />
        );
    } else {
        return null;
    }
};

export default LoadCarrierRejectionList;