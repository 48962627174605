import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Button, Drawer, Modal, Row, Col, Spin, Select, Empty } from 'antd';
import EditScheduledShift from "../../components/EditScheduledShift/EditScheduledShift";
import NewScheduledShift from "../../components/NewScheduledShift/NewScheduledShift";
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { isEmpty } from 'lodash';
import Header from '../Header/Header';

const { Option } = Select;

const Scheduler = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const accountUserId = props.accountUserId;
    const shiftId = props.shiftId;
    const singularEntityName = "Scheduled Shift";

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isLoading);
    const error = useSelector(state => state.scheduledShifts.error);
    const records = useSelector(state => state.scheduledShifts.records);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const shifts = useSelector(state => state.shifts.records);
    const isLoadingShifts = useSelector(state => state.shifts.isLoading);
    const accountUsers = useSelector(state => state.accountUsers.records);
    const isLoadingAccountUsers = useSelector(state => state.accountUsers.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useRefs

    const schedulerRef = useRef(null);

    //#endregion
    //#region useStates

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [resourceMap, setResourceMap] = useState([]);
    const [selectedShiftIds, setSelectedShiftIds] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedAccountUserId, setSelectedAccountUserId] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState(null);

    //#endregion
    //#region toggles

    const closeNewEntity = () => {
        setShowNewEntity(false);
        if (!(accountUserId !== undefined && accountUserId !== null)) {
            setSelectedAccountUserId(null);
        }
    };

    const closeEditEntity = () => {
        setShowEditEntity(false);
        if (!(accountUserId !== undefined && accountUserId !== null)) {
            setSelectedAccountUserId(null);
        }
    };

    const closeRemoveEntity = () => {
        setShowRemoveEntity(false);
        if (!(accountUserId !== undefined && accountUserId !== null)) {
            setSelectedAccountUserId(null);
        }
    };

    const toggleShowDetails = () => {
        setShowDetails(!showDetails);
    };

    //#endregion
    //#region fetch methods

    const getScheduledShifts = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            sort: 'startDateTime',
            order: 'asc',
            isDeleted: false
        };

        if (selectedAccountUserId !== undefined && selectedAccountUserId !== null && selectedAccountUserId.length > 0) {
            searchParams.accountUserIds = [selectedAccountUserId];
        }

        if (selectedShiftIds !== undefined && selectedShiftIds !== null && selectedShiftIds.length > 0) {
            searchParams.shiftId = selectedShiftIds;
        }

        if (selectedAccountId !== undefined && selectedAccountId !== null) {
            searchParams.accountId = selectedAccountId;
        }

        dispatch(actionCreators.fetchScheduledShifts(searchParams));
    };

    //#endregion
    //#region calendar methods

    const handleEmployeeDateSelect = (selectInfo) => {
        // console.log(selectInfo);
        let calendarApi = selectInfo.view.calendar;

        calendarApi.unselect(); // clear date selection

        let selectedDateValue = moment(selectInfo.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);
        if (selectInfo.resource && selectInfo.resource.id) {
            setSelectedAccountUserId(selectInfo.resource.id);
        }

        setShowNewEntity(true);
    };

    const handleEmployeeEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault(); // don't let the browser navigate
        // console.log(clickInfo);
        // console.log(clickInfo.event);
        // console.log(clickInfo.event._def.resourceIds);
        // console.log(clickInfo.jsEvent.path)
        let pathsArray = clickInfo.jsEvent.path;
        let node = pathsArray.find(i => i.className === 'fc-timeline-lane fc-resource');
        let resourceId = node.getAttribute('data-resource-id');

        setSelectedRecord(clickInfo.event.extendedProps);

        let selectedDateValue = moment(clickInfo.event.startStr).format('MM/DD/YYYY');
        setSelectedDate(selectedDateValue);

        if (resourceId) {
            setSelectedAccountUserId(resourceId);
        }

        setShowDetails(true);
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if ((selectedAccountId !== undefined && selectedAccountId !== null)) {
            dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
            dispatch(actionCreators.fetchShifts({ page: 1, size: 100000, accountId: selectedAccountId, isDeleted: false }));
        }
    }, [selectedAccountId]);

    useMemo(() => {
        if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            if (accountUserId !== undefined && accountUserId !== null) {
                let resourceMapArray = accountUsers.filter(i => i.id === accountUserId).map((accountUser) => {
                    return ({
                        ...accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`
                    });
                });

                setResourceMap(resourceMapArray);
            } else {
                let resourceMapArray = accountUsers.map((accountUser) => {
                    return ({
                        ...accountUser,
                        title: `${accountUser.firstName} ${accountUser.lastName}${accountUser.title ? ' (' + accountUser.title + ')' : ''}`
                    });
                });

                setResourceMap(resourceMapArray);
            }
        } else {
            setResourceMap([]);
        }
    }, [accountUsers, accountUserId]);

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            setSelectedAccountId(accountId);
        } else if (entityType !== 'STAFF' && entityId !== undefined && entityId !== null) {
            setSelectedAccountId(entityId);
        }
    }, [accountId, entityType, entityId]);

    useMemo(() => {
        if (accountUserId !== undefined && accountUserId !== null) {
            setSelectedAccountUserId(accountUserId);
        }
    }, [accountUserId]);

    useMemo(() => {
        if (shiftId !== undefined && shiftId !== null) {
            setSelectedShiftIds([shiftId]);
        } else {
            setSelectedShiftIds([]);
        }
    }, [shiftId]);

    useMemo(() => {
        if (selectedAccountId !== undefined && selectedAccountId !== null && selectedAccountUserId !== undefined && selectedAccountUserId !== null && selectedShiftIds !== undefined && selectedShiftIds !== null) {
            getScheduledShifts();
        }
    }, [selectedAccountId, selectedAccountUserId, selectedShiftIds]);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={closeNewEntity}
            visible={showNewEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewScheduledShift
                cancel={closeNewEntity}
                accountId={selectedAccountId}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                selectedShiftId={shiftId ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
            />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={closeEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeEditEntity}
                record={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                selectedShiftId={shiftId ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={false}
            />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove Employee from " + singularEntityName}
            onClose={closeRemoveEntity}
            visible={showRemoveEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditScheduledShift
                cancel={closeRemoveEntity}
                record={selectedRecord}
                selectedDate={selectedDate}
                selectedAccountUserId={selectedAccountUserId ? selectedAccountUserId : null}
                selectedShiftId={shiftId ? shiftId : null}
                accountUsers={accountUsers}
                shifts={shifts}
                isRemove={true}
            />
        </Drawer>
    );

    const getAccountUser = (acctUserId) => {
        if (acctUserId !== undefined && acctUserId !== null && accountUsers && accountUsers.length > 0) {
            let accountUser = accountUsers.find(i => i.id === acctUserId);
            if (accountUser !== undefined && accountUser !== null) {
                return (
                    <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                );
            }
        }

        return null;
    };

    const eventDetailsComponents = (scheduledShift) => {
        //console.log(`scheduledShift: ${JSON.stringify(scheduledShift)}`);

        if (scheduledShift !== undefined && scheduledShift !== null && !isEmpty(scheduledShift)) {
            let shift = scheduledShift.shift;
            let title = shift && shift.name ? shift.name : '';
            let time = `${selectedDate ? selectedDate + ', ' : ''}${shift ? shift.startTime : ''} - ${shift ? shift.endTime : ''}`;

            return (
                <Modal
                    title={`${title}: ${time}`}
                    visible={showDetails}
                    onCancel={(e) => { toggleShowDetails(); }}
                    onOk={(e) => { toggleShowDetails(); }}
                    footer={[
                        <Button type="default" onClick={(e) => { toggleShowDetails(); }}>Close</Button>,
                        <Button type="primary" onClick={(e) => { toggleShowDetails(); setShowEditEntity(true); }}>Swap Shifts</Button>,
                        <Button type="danger" onClick={(e) => { toggleShowDetails(); setShowRemoveEntity(true); }}>Remove</Button>
                    ]}
                >
                    <div>
                        <div>
                            <span><strong>{scheduledShift.title ? scheduledShift.title : ''}</strong></span>
                        </div>
                        {scheduledShift.accountUsers && scheduledShift.accountUsers.length > 0 && (accountUserId !== undefined && accountUserId !== null) === false ? scheduledShift.accountUsers.map((accountUser) => {
                            return (
                                <div>{accountUser.firstName} {accountUser.lastName}{accountUser.title ? ' (' + accountUser.title + ')' : ''}</div>
                            );
                        }) : ((accountUserId !== undefined && accountUserId !== null) ? (getAccountUser(accountUserId)) : null)}
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    };

    const renderEmployeeEventContent = (eventInfo) => {
        //console.log(eventInfo);
        let scheduledShift = eventInfo && eventInfo.event ? eventInfo.event.extendedProps : null;
        let shift = scheduledShift && scheduledShift.shift ? scheduledShift.shift : null;
        if (scheduledShift && shift) {
            return (
                <div style={{ backgroundColor: eventInfo.backgroundColor, color: eventInfo.textColor, width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'normal', wordWrap: 'normal' }}>
                    <div>
                        <b>{shift.startTime} - {shift.endTime}</b> <i>{shift.name}</i>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion

    if (records) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingAccountUsers === true || isLoadingShifts === true}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Header
                            title="Scheduler"
                            footer={
                                <div><span style={{ fontSize: 16, fontWeight: 500 }}>To schedule an Employee on a shift, click on a cell in the Scheduler below in the Employee's row to choose a date. You will then be prompted to choose a shift to schedule the Employee on for that date.</span></div>
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        {((shiftId === undefined || shiftId === null) || ((accountId === undefined || accountId === null) && entityType === 'STAFF')) ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <span><strong>Filters: </strong></span>
                                    </Col>
                                </Row>
                                <Row>
                                    {((accountId === undefined || accountId === null) && entityType === 'STAFF') ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Account"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedAccountId(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Accounts for you to choose from." />}
                                            >
                                                {accounts.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                    {(shiftId === undefined || shiftId === null) ? (
                                        <Col span={8}>
                                            <Select
                                                placeholder="Filter by Shift"
                                                mode="multiple"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onChange={(selected) => { setSelectedShiftIds(selected); }}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                            >
                                                {shifts.map(i => <Option value={i.id} key={i.id}>{i.name}: {i.startTime} - {i.endTime}</Option>)}
                                            </Select>
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        ) : null}
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <FullCalendar
                            ref={schedulerRef}
                            plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                            headerToolbar={{
                                left: 'today prev,next',
                                center: 'title',
                                right: 'resourceTimelineWeek,resourceTimelineTenDay,resourceTimelineMonth,resourceTimelineYear'
                            }}
                            titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }}
                            views={{
                                resourceTimelineWeek: {
                                    type: 'resourceTimeline',
                                    duration: { days: 7 },
                                    slotDuration: { days: 1 },
                                    buttonText: 'week'
                                },
                                resourceTimelineTenDay: {
                                    type: 'resourceTimeline',
                                    duration: { days: 10 },
                                    slotDuration: { days: 1 },
                                    buttonText: '10 days'
                                }
                            }}
                            slotLabelFormat={[
                                { month: '2-digit', year: 'numeric', day: '2-digit' }, // top level of text
                                { weekday: 'short' } // lower level of text
                            ]}
                            resourceAreaHeaderContent="Employees"
                            initialView='resourceTimelineWeek'
                            resources={resourceMap}
                            resourceOrder={'firstName,LastName'}
                            editable={false}
                            selectable={true}
                            allDaySlot={true}
                            dayMaxEventRows={false}
                            dayMaxEvents={false}
                            nextDayThreshold={'06:00:00'}
                            slotMinWidth={100}
                            weekends={true}
                            contentHeight={"auto"}
                            expandRows={false}
                            // eventsSet={(events) => { console.log(events) }}
                            // resourcesSet={(resources) => { console.log(resources) }}
                            events={records}
                            select={handleEmployeeDateSelect}
                            eventContent={renderEmployeeEventContent}
                            eventClick={handleEmployeeEventClick}
                        />
                    </Col>
                </Row>
                {newEntityComponents}
                {editEntityComponents}
                {removeEntityComponents}
                {eventDetailsComponents(selectedRecord)}
            </Spin>
        );
    } else {
        return null;
    }
};

export default Scheduler;