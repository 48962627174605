import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Metrics Functions

export const fetchAccountsPendingVerificationStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_START
    }
};

export const fetchAccountsPendingVerificationSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_SUCCESS,
        payload: payload
    }
};

export const fetchAccountsPendingVerificationFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_FAIL,
        payload: payload
    }
};

export const clearAccountsPendingVerification = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNTS_PENDING_VERIFICATION
    }
};

export const fetchDriversMissingDocumentsStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_START
    }
};

export const fetchDriversMissingDocumentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_SUCCESS,
        payload: payload
    }
};

export const fetchDriversMissingDocumentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_FAIL,
        payload: payload
    }
};

export const clearDriversMissingDocuments = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_MISSING_DOCUMENTS
    }
};

export const fetchDriversPendingApprovalStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_START
    }
};

export const fetchDriversPendingApprovalSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_SUCCESS,
        payload: payload
    }
};

export const fetchDriversPendingApprovalFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_FAIL,
        payload: payload
    }
};

export const clearDriversPendingApproval = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_PENDING_APPROVAL
    }
};

export const fetchDriversWithExpiringLicensesStart = () => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_START
    }
};

export const fetchDriversWithExpiringLicensesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_SUCCESS,
        payload: payload
    }
};

export const fetchDriversWithExpiringLicensesFail = (payload) => {
    return {
        type: actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_FAIL,
        payload: payload
    }
};

export const clearDriversWithExpiringLicenses = () => {
    return {
        type: actionTypes.CLEAR_DRIVERS_WITH_EXPIRING_LICENSES
    }
};

export const fetchInTransitLoadsWithoutLocationDataStart = () => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_START
    }
};

export const fetchInTransitLoadsWithoutLocationDataSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_SUCCESS,
        payload: payload
    }
};

export const fetchInTransitLoadsWithoutLocationDataFail = (payload) => {
    return {
        type: actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_FAIL,
        payload: payload
    }
};

export const clearInTransitLoadsWithoutLocationData = () => {
    return {
        type: actionTypes.CLEAR_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA
    }
};

export const fetchCriticalLoadsStart = () => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_START
    }
};

export const fetchCriticalLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchCriticalLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CRITICAL_LOADS_FAIL,
        payload: payload
    }
};

export const clearCriticalLoads = () => {
    return {
        type: actionTypes.CLEAR_CRITICAL_LOADS
    }
};

export const fetchMetricsStart = () => {
    return {
        type: actionTypes.FETCH_METRICS_START
    }
};

export const fetchMetricsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_METRICS_SUCCESS,
        payload: payload
    }
};

export const fetchMetricsFail = (payload) => {
    return {
        type: actionTypes.FETCH_METRICS_FAIL,
        payload: payload
    }
};

export const clearMetrics = () => {
    return {
        type: actionTypes.CLEAR_METRICS
    }
};

//#endregion

//#region Metrics Methods

export const fetchAccountsPendingVerification = (payload) => {
    return async (dispatch, getState) => {
        const accountsPath = '/accounts';
        try {
            dispatch(fetchAccountsPendingVerificationStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let searchParams = {...metricsState.accountsPendingVerificationSearchParams};
            let pagination = {...metricsState.accountsPendingVerificationPagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(accountsPath, {params: {...searchParams}});
            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchAccountsPendingVerificationSuccess({
                    accountsPendingVerification: data,
                    accountsPendingVerificationSearchParams: searchParams,
                    accountsPendingVerificationPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountsPendingVerificationFail({error: error.message}));
        }
    }
};

export const fetchDriversMissingDocuments = (payload) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(fetchDriversMissingDocumentsStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let searchParams = {...metricsState.driversMissingDocumentsSearchParams};
            let pagination = {...metricsState.driversMissingDocumentsPagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(accountUsersPath, {params: {...searchParams}});
            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchDriversMissingDocumentsSuccess({
                    driversMissingDocuments: data,
                    driversMissingDocumentsSearchParams: searchParams,
                    driversMissingDocumentsPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchDriversMissingDocumentsFail({error: error.message}));
        }
    }
};

export const fetchDriversPendingApproval = (payload) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(fetchDriversPendingApprovalStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let pagination = {...metricsState.driversPendingApprovalPagination};
            let searchParams = {...metricsState.driversPendingApprovalSearchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(accountUsersPath, {params: {...searchParams}});

            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchDriversPendingApprovalSuccess({
                    driversPendingApproval: data,
                    driversPendingApprovalSearchParams: searchParams,
                    driversPendingApprovalPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchDriversPendingApprovalFail({error: error.message}));
        }
    }
};

export const fetchDriversWithExpiringLicenses = (payload) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(fetchDriversWithExpiringLicensesStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let pagination = {...metricsState.driversWithExpiringLicensesPagination};
            let searchParams = {...metricsState.driversWithExpiringLicensesSearchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(accountUsersPath, {params: {...searchParams}});
            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchDriversWithExpiringLicensesSuccess({
                    driversWithExpiringLicenses: data,
                    driversWithExpiringLicensesSearchParams: searchParams,
                    driversWithExpiringLicensesPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchDriversWithExpiringLicensesFail({error: error.message}));
        }
    }
};

export const fetchInTransitLoadsWithoutLocationData = (payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/activeLoadLocations';
        try {
            dispatch(fetchInTransitLoadsWithoutLocationDataStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let pagination = {...metricsState.inTransitLoadsWithoutLocationDataPagination};
            let searchParams = {...metricsState.inTransitLoadsWithoutLocationDataSearchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchInTransitLoadsWithoutLocationDataSuccess({
                    inTransitLoadsWithoutLocationData: data,
                    inTransitLoadsWithoutLocationDataSearchParams: searchParams,
                    inTransitLoadsWithoutLocationDataPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchInTransitLoadsWithoutLocationDataFail({error: error.message}));
        }
    }
};

export const fetchCriticalLoads = (payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/loads';
        try {
            dispatch(fetchCriticalLoadsStart());

            const state = getState();
            const metricsState = {...state.metrics};
            let pagination = {...metricsState.criticalLoadsPagination};
            let searchParams = {...metricsState.criticalLoadsSearchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const resp = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
            if (resp && resp.status === 200) {
                const data = resp.data.data;

                // Read total count from server
                pagination.total = resp.data.totalCount;
                pagination.current = resp.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchCriticalLoadsSuccess({
                    criticalLoads: data,
                    criticalLoadsSearchParams: searchParams,
                    criticalLoadsPagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchCriticalLoadsFail({error: error.message}));
        }
    }
};

export const fetchMetrics = (entityType, entityId = null) => {
    return async (dispatch) => {
        const metricsPath = '/metrics';
        try {
            dispatch(fetchMetricsStart());

            let searchParams = {
                entityType: entityType
            };

            if (entityId !== null) {
                searchParams.entityId = entityId;
            }
            const resp = await axiosAuthenticated.get(metricsPath, {params: {...searchParams}});

            if (resp && resp.status === 200) {
                const data = resp.data;
                data.entityId = entityId;
                data.entityType = entityType;

                dispatch(fetchMetricsSuccess({metrics: data}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchMetricsFail({error: error.message}));
        }
    }
};

//#endregion