import React, { useState, useMemo } from 'react';
import { Input, Select, Alert, Spin } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const { TextArea } = Input;

const EditLoadStatusCancelled = props => {
    const load = props.load;
    const shipperId = props.load.shipperId;
    const carrierId = props.load.assignedCarrierId;

    const cancellationEntityTypeOptions = Enums.CancellationEntityTypes.selectListOptions();
    const shipperCancellationReasonOptions = Enums.ShipperCancellationReasons.selectListOptions();
    const carrierCancellationReasonOptions = Enums.CarrierCancellationReasons.selectListOptions();
    const staffCancellationReasonOptions = Enums.StaffCancellationReasons.selectListOptions();

    const [showShipperReasons, setShowShipperReasons] = useState(false);
    const [showCarrierReasons, setShowCarrierReasons] = useState(false);
    const [showStaffReasons, setShowStaffReasons] = useState(false);
    const [showCancellationDetails, setShowCancellationDetails] = useState(false);

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadCancellations.isRecordAddLoading);
    const error = useSelector(state => state.loadCancellations.addRecordError);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const sendEmailToOldDrivers = (accountUsers) => {
        if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            accountUsers.forEach((accountUser) => {
                if (accountUser.driver !== undefined && accountUser.driver !== null) {
                    if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                        let firstName = accountUser.firstName;
                        let email = accountUser.email;
                        const { body, subject } = driverUnAssignedEmailTemplate(firstName, load.irisId)
                        let newEmail = {
                            body: body,
                            subject: subject,
                            email: email
                        };
                        axiosAuthenticated.post('/email', newEmail).then(res => {
                            //console.log(res);
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                }
            });
        }
    };

    const sendNotificationToOldDrivers = (accountUsers) => {
        if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            accountUsers.forEach((accountUser) => {
                if (accountUser.driver !== undefined && accountUser.driver !== null) {
                    let firstName = accountUser.firstName;
                    let userId = accountUser.userId;

                    let newNotification = {
                        message: `Hi ${firstName}, you are no longer assigned to load: ${load.irisId}.`,
                        loadId: load.id,
                        loadIrisId: load.irisId,
                        saveOnly: false,
                        subject: `${load.irisId} You have been unassigned from a load`,
                        severity: "CRITICAL",
                        userIds: [userId],
                        loadStatus: load.loadStatus,
                        eventType: 'DRIVER_UNASSIGNED'
                    };

                    axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                        //console.log(res);
                    }).catch(err => {
                        console.log(err);
                    });
                }
            });
        }
    };

    const sendNotificationsToOldCarrier = (carrierId) => {
        if (carrierId !== undefined && carrierId !== null) {
            let newNotification = {
                message: `Hi, your account is no longer assigned to load: ${load.irisId}.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} This load has been removed from your account.`,
                severity: "CRITICAL",
                accountIds: [carrierId],
                loadStatus: load.loadStatus,
                eventType: 'CARRIER_UNASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const onSubmit = (data) => {
        if (data.entityType === "SHIPPER") {
            if (shipperId !== undefined && shipperId !== null) {
                methods.clearErrors("entityType");
                data.entityId = shipperId;
            } else {
                methods.setError("entityType", { type: "required", message: "Cannot choose Shipper if there is no Shipper assigned to the Load" });
                return;
            }
        } else if (data.entityType === "CARRIER") {
            if (carrierId !== undefined && carrierId !== null) {
                methods.clearErrors("entityType");
                // send notification to driver and carrier 
                sendNotificationToOldDrivers(load.drivers);
                sendEmailToOldDrivers(load.drivers);
                sendNotificationsToOldCarrier(carrierId);
                data.entityId = carrierId;
            } else {
                methods.setError("entityType", { type: "required", message: "Cannot choose Carrier if there is no Carrier assigned to the Load" });
                return;
            }
        } else if (data.entityType === "STAFF" && entityType === 'STAFF' && entityId !== undefined && entityId !== null) {
            methods.clearErrors("entityType");
            data.entityId = entityId;
        }

        data.loadId = load.id;

        dispatch(actionCreators.addLoadCancellation(data));
    };

    const onCancelledByChange = (value) => {
        if (value !== null && value !== undefined) {
            switch (value) {
                case 'SHIPPER':
                    setShowShipperReasons(true);
                    setShowCarrierReasons(false);
                    setShowStaffReasons(false);
                    setShowCancellationDetails(false);
                    break;
                case 'CARRIER':
                    setShowShipperReasons(false);
                    setShowCarrierReasons(true);
                    setShowStaffReasons(false);
                    setShowCancellationDetails(false);
                    break;
                case 'STAFF':
                    setShowShipperReasons(false);
                    setShowCarrierReasons(false);
                    setShowStaffReasons(true);
                    setShowCancellationDetails(false);
                    break;
            }
        } else {
            setShowShipperReasons(false);
            setShowCarrierReasons(false);
            setShowStaffReasons(false);
            setShowCancellationDetails(false);
        }
    };

    const onCancellationReasonChange = (value) => {
        if (value !== null && value !== undefined) {
            if (value === "OTHER") {
                setShowCancellationDetails(true);
            } else {
                setShowCancellationDetails(false);
            }
        } else {
            setShowCancellationDetails(false);
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddLoadCancellation());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadCancellationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Load Cancellation Details</legend>
                        <FormItem {...formItemLayout} label="Who Cancelled the Load" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select Who the Load is Cancelled By"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onCancelledByChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {cancellationEntityTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="entityType"
                        />
                        {showShipperReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onCancellationReasonChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {shipperCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showCarrierReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onCancellationReasonChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {carrierCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showStaffReasons === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Reason" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Reason"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onCancellationReasonChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {staffCancellationReasonOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reason"
                            />
                        ) : null}
                        {showCancellationDetails === true ? (
                            <FormItem {...formItemLayout} label="Cancellation Explanation" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                                rules={{ required: 'Required Field' }}
                                name="reasonDetail"
                            />
                        ) : null}
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Cancel Load" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadStatusCancelled;