import React from 'react';
import InvoiceCell from '../InvoiceCell/InvoiceCell';
import InvoiceRow from '../InvoiceRow/InvoiceRow';

const InvoiceRows = props => {

    const createDataRows = (data, columns) => {
        let dataRows = [];

        let i = 0;
        for (const row of data) {
            let dataRow = [];
            columns.forEach((column, index) => {
                let key = column.key;
                dataRow.push(<InvoiceCell key={`invoice-cell-${index}`} value={row[key]} colSpan={(column.colSpan ? column.colSpan : 1)} columnKey={column.key} dataType={(column.dataType ? column.dataType : 'String')} textAlign={(column.textAlign ? column.textAlign : 'left')} />);
            });

            dataRows.push(<InvoiceRow key={`invoice-row-${i}`} dataRow={dataRow} />);
            i++;
        }

        return dataRows;
    };

    return createDataRows(props.data, props.columns);
};

export default InvoiceRows;