import React, { useMemo, useState } from 'react';
import { Input, Typography, Button, Row, Col, Select, Radio, Alert } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from "react-redux";
import UploadFile from "../UploadFile/UploadFile";
import moment from 'moment';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItemFile from '../FormItemFile/FormItemFile';
import Form from '../Form/Form';
import { removeEmpty, convertToObject } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isEmpty } from 'lodash';

const { Paragraph } = Typography;
const { Option } = Select;
const { Search } = Input;

const NewAccountCarrier = props => {
    const carrierData = props.data;
    const companyData = props.companyData;
    const insuranceExpiryDate = props.insuranceExpiryDate;
    const w9File = props.w9File;
    const setW9File = props.setW9File;
    const w9FileList = props.w9FileList;
    const setW9FileList = props.setW9FileList;
    const insuranceFile = props.insuranceFile;
    const setInsuranceFile = props.setInsuranceFile;
    const insuranceFileList = props.insuranceFileList;
    const setInsuranceFileList = props.setInsuranceFileList;
    const setInsuranceExpiryDate = props.setInsuranceExpiryDate;

    const states = useSelector(state => state.orchestrator.states);
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const [showAccountSetup, setShowAccountSetup] = useState(true);
    const [searchError, setSearchError] = useState(null);

    const onSubmit = (data) => {
        //console.log(w9File);
        if (w9File !== undefined && w9File !== null) {
            methods.clearErrors('w9File');
        } else {
            methods.setError('w9File', { type: "required", message: "Required File" });
        }

        //console.log(insuranceFile);
        if (insuranceFile !== undefined && insuranceFile !== null) {
            methods.clearErrors('insuranceFile');
        } else {
            methods.setError('insuranceFile', { type: "required", message: "Required File" });
        }

        if (w9File === undefined || w9File === null || insuranceFile === undefined || insuranceFile === null) {
            return;
        }

        delete data['insuranceExpiryDate'];

        let cleanedData = removeEmpty(data);
        //console.log(cleanedData);

        props.setStepIsComplete(true);
        props.setData(cleanedData);
        props.nextStepAction();
    };

    const saveForm = () => {
        let values = methods.getValues();
        delete values['insuranceExpiryDate'];
        let payload = removeEmpty(values);

        //console.log(payload);

        let data = convertToObject(payload);

        //console.log(data);

        props.setData(data);
    };

    const copyCompanyPointOfContactToPointOfContact = () => {
        if (companyData !== undefined && companyData !== null && companyData.pointOfContact !== undefined && companyData.pointOfContact !== null) {
            methods.setValue('pointOfContact.name', companyData.pointOfContact.name);
            methods.setValue('pointOfContact.email', companyData.pointOfContact.email);
            methods.setValue('pointOfContact.phone', companyData.pointOfContact.phone);
            methods.setValue('pointOfContact.phoneExt', companyData.pointOfContact.phoneExt);
        }
    };

    useMemo(() => {
        if (carrierData !== undefined && carrierData !== null && !isEmpty(carrierData)) {
            if (carrierData.mcNum !== undefined && carrierData.mcNum !== null) {
                setShowAccountSetup(true);
                setSearchError(null);
            }
        }
    }, [carrierData]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {showAccountSetup === true ? (
                    <>
                        <Fieldset legend="Carrier Details (* indicates a required field)">
                            <FormItem {...formItemLayout} label="US DOT #" required
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" />}
                                rules={{ required: "Required Field" }}
                                name="dotNum"
                                defaultValue={carrierData.dotNum ? carrierData.dotNum : ''}
                            />
                        </Fieldset>
                        <Fieldset>
                            <legend>Carrier Documents (* indicates a required field or file)</legend>
                            <FormItemFile {...formItemLayout} label="W9 File" required name="w9File">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setW9File(file);
                                        methods.clearErrors('w9File');
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setW9File(null);
                                    }}
                                    message="Please upload a photo of the document."
                                    setFileList={setW9FileList}
                                    fileList={w9FileList}
                                />
                            </FormItemFile>
                            <FormItem {...formItemLayout} label="Insurance Expiration Date" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <DatePicker
                                        placeholder="Expiration Date"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={(date, dateString) => { setInsuranceExpiryDate(date); onChange(date); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules={{ required: "Required Field" }}
                                name="insuranceExpiryDate"
                                defaultValue={insuranceExpiryDate ? moment(insuranceExpiryDate) : null}
                            />
                            <FormItemFile {...formItemLayout} label="Insurance File" required name="insuranceFile">
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setInsuranceFile(file);
                                        methods.clearErrors('insuranceFile');
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setInsuranceFile(null);
                                    }}
                                    message="Please upload a photo of the document."
                                    setFileList={setInsuranceFileList}
                                    fileList={insuranceFileList}
                                />
                            </FormItemFile>
                        </Fieldset>
                        <Fieldset legend="Accounts Receivable (* indicates a required field)">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Preferred Payment Method" required
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} buttonStyle="solid">
                                                <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                                <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                                <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                            </Radio.Group>
                                        )}
                                        rules={{ required: "Required Field" }}
                                        name="preferredPaymentMethod"
                                        defaultValue={carrierData.preferredPaymentMethod ? carrierData.preferredPaymentMethod : undefined}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Person or Factoring Company Name" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                        rules={{ required: "Required Field" }}
                                        name="accountsReceivable.name"
                                        defaultValue={carrierData.accountsReceivable && carrierData.accountsReceivable.name ? carrierData.accountsReceivable.name : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Email" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="accountsReceivable.email"
                                        defaultValue={carrierData.accountsReceivable && carrierData.accountsReceivable.email ? carrierData.accountsReceivable.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" required1
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="accountsReceivable.phone"
                                        defaultValue1={carrierData.accountsReceivable && carrierData.accountsReceivable.phone ? carrierData.accountsReceivable.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="accountsReceivable.phoneExt"
                                        defaultValue2={carrierData.accountsReceivable && carrierData.accountsReceivable.phoneExt ? carrierData.accountsReceivable.phoneExt : ""}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the carrier's accounts receivable department's address is different than the company's main physical address or mailing address, or if they are using a factoring company, please provide it below.</Paragraph>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Street Address 1"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.streetAddress1"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.streetAddress1 ? carrierData.shippingAddress.streetAddress1 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.streetAddress2"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.streetAddress2 ? carrierData.shippingAddress.streetAddress2 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="City"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.city"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.city ? carrierData.shippingAddress.city : ""}
                                    />
                                    <FormItem {...formItemLayout} label="State"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="shippingAddress.state"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.state ? carrierData.shippingAddress.state : undefined}
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: false }}
                                        name="shippingAddress.postalCode"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.postalCode ? carrierData.shippingAddress.postalCode : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Country"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: false }}
                                        name="shippingAddress.country"
                                        defaultValue={carrierData.shippingAddress && carrierData.shippingAddress.country ? carrierData.shippingAddress.country : undefined}
                                    />
                                </Col>
                            </Row>
                        </Fieldset>
                        <Fieldset legend="Point of Contact for Carrier Operations Related Purposes (* indicates a required field)">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Button type="primary" onClick={() => { copyCompanyPointOfContactToPointOfContact(); }}>Copy from Company Main Point of Contact</Button>
                                </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormItem {...formItemLayout} label="Name" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                        rules={{ required: 'Required Field' }}
                                        name="pointOfContact.name"
                                        defaultValue={carrierData.pointOfContact && carrierData.pointOfContact.name ? carrierData.pointOfContact.name : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Email" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="pointOfContact.email"
                                        defaultValue={carrierData.pointOfContact && carrierData.pointOfContact.email ? carrierData.pointOfContact.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" required1
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="pointOfContact.phone"
                                        defaultValue1={carrierData.pointOfContact && carrierData.pointOfContact.phone ? carrierData.pointOfContact.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="pointOfContact.phoneExt"
                                        defaultValue2={carrierData.pointOfContact && carrierData.pointOfContact.phoneExt ? carrierData.pointOfContact.phoneExt : ""}
                                    />
                                </Col>
                            </Row>
                        </Fieldset>
                        <Fieldset legend="SMS Basic Scores" isCollapsable={true} isCollapsed={carrierData.smsBasic ? false : true}>
                            <FormItem {...formItemLayout} label="Hours of Service Compliance"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Hours of Service Compliance" />}
                                rules={{ required: false }}
                                name="smsBasic.hosCompliance"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.hosCompliance ? carrierData.smsBasic.hosCompliance : ""}
                            />
                            <FormItem {...formItemLayout} label="Unsafe Driving"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Unsafe Driving" />}
                                rules={{ required: false }}
                                name="smsBasic.unsafeDriving"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.unsafeDriving ? carrierData.smsBasic.unsafeDriving : ""}
                            />
                            <FormItem {...formItemLayout} label="Vehicle Maintenance"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Vehicle Maintenance" />}
                                rules={{ required: false }}
                                name="smsBasic.vehicleMaintenance"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.vehicleMaintenance ? carrierData.smsBasic.vehicleMaintenance : ""}
                            />
                            <FormItem {...formItemLayout} label="Controlled Substance Abuse"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Controlled Substance Abuse" />}
                                rules={{ required: false }}
                                name="smsBasic.controlledSA"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.controlledSA ? carrierData.smsBasic.controlledSA : ""}
                            />
                            <FormItem {...formItemLayout} label="Driver Fitness"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Driver Fitness" />}
                                rules={{ required: false }}
                                name="smsBasic.driverFitness"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.driverFitness ? carrierData.smsBasic.driverFitness : ""}
                            />
                            <FormItem {...formItemLayout} label="Average SMS Score"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Average SMS Score" />}
                                rules={{ required: false }}
                                name="smsBasic.avgSMSScore"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.avgSMSScore ? carrierData.smsBasic.avgSMSScore : ""}
                            />
                            <FormItem {...formItemLayout} label="Weighted Average SMS Score"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Weighted Average SMS Score" />}
                                rules={{ required: false }}
                                name="smsBasic.weightedAvgSMSScore"
                                defaultValue={carrierData.smsBasic && carrierData.smsBasic.weightedAvgSMSScore ? carrierData.smsBasic.weightedAvgSMSScore : ""}
                            />
                        </Fieldset>
                    </>
                ) : null}
                <div
                    style={{
                        position: 'relative',
                        right: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 0px',
                        background: '#fff',
                        textAlign: 'right',
                        marginTop: '0px',
                    }}
                >
                    {props.cancelButton}

                    {!!props.previousStepAction && (
                        <Button onClick={() => { props.previousStepAction(); }} style={{ marginRight: 8 }}>Go to Previous Step</Button>
                    )}

                    <Button onClick={() => { saveForm(); }} style={{ marginRight: 8 }} disabled={showAccountSetup === false}>Save Changes</Button>
                    <Button type="primary" htmlType="submit" disabled={showAccountSetup === false}>Review Account Details</Button>
                </div>
            </Form>
        </FormProvider>
    );
};

export default NewAccountCarrier;