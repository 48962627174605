import React, { useMemo } from 'react';
import { Alert, Spin, Checkbox } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import DataRow from '../DataRow/DataRow';
import { isEmpty } from 'lodash';

const EditLoadCompletionChecklist = props => {
    const load = props.load;
    const hasOpenClaims = props.hasOpenClaims;
    const hasBalance = props.hasBalance;
    const hasPendingInvoiceLineItems = props.hasPendingInvoiceLineItems;
    const hasPendingTransactions = props.hasPendingTransactions;
    const hasUnreviewedBOLs = props.hasUnreviewedBOLs;
    const hasInvoiceBeenSent = props.hasInvoiceBeenSent;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = (data) => {
        let loadPayload = {};
        let completionCheckList = {};
        if (load !== undefined && load !== null && !isEmpty(load) && load.completionCheckList !== undefined && load.completionCheckList !== null && !isEmpty(load.completionCheckList)) {
            completionCheckList = load.completionCheckList;
        }
        completionCheckList.hasInvoiceBeenSent = data.hasInvoiceBeenSent;
        completionCheckList.hasOpenClaims = data.hasOpenClaims;
        completionCheckList.hasBalance = data.hasBalance;
        completionCheckList.hasPendingInvoiceLineItems = data.hasPendingInvoiceLineItems;
        completionCheckList.hasPendingTransactions = data.hasPendingTransactions;
        completionCheckList.hasUnreviewedBOLs = data.hasUnreviewedBOLs;

        loadPayload.completionCheckList = completionCheckList;

        dispatch(actionCreators.updateLoad(load.id, loadPayload));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#region styles

    const formItemLayout = {
        labelCol: { span: 16 },
        wrapperCol: { span: 8 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true) && error === null}>
                    <FormItem {...formItemLayout} label={`Has Invoice Been Sent?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                            />
                        )}
                        name="hasInvoiceBeenSent"
                        defaultValue={hasInvoiceBeenSent}
                    />
                    <FormItem {...formItemLayout} label={`Has Open Claims?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                                disabled={true}
                            />
                        )}
                        name="hasOpenClaims"
                        defaultValue={hasOpenClaims}
                    />
                    <FormItem {...formItemLayout} label={`Has Pending Invoice Line Items?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                                disabled={true}
                            />
                        )}
                        name="hasPendingInvoiceLineItems"
                        defaultValue={hasPendingInvoiceLineItems}
                    />
                    <FormItem {...formItemLayout} label={`Has Pending Transactions?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                                disabled={true}
                            />
                        )}
                        name="hasPendingTransactions"
                        defaultValue={hasPendingTransactions}
                    />
                    <FormItem {...formItemLayout} label={`Has Outstanding Balance?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                                disabled={true}
                            />
                        )}
                        name="hasBalance"
                        defaultValue={hasBalance}
                    />
                    <FormItem {...formItemLayout} label={`Has Unreviewed Documents?`} format="horizontal"
                        render={({ onChange, onBlur, value, name }) => (
                            <Checkbox
                                onBlur={onBlur}
                                onChange={e => { onChange(e.target.checked); }}
                                checked={value}
                                name={name}
                                disabled={true}
                            />
                        )}
                        name="hasUnreviewedBOLs"
                        defaultValue={hasUnreviewedBOLs}
                    />
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} cancelDisabled={isLoading === true && error === null} submitDisabled={(isLoading === true && error === null)} submitText="Update Checklist" />
            </Form>
        </FormProvider>
    );
};

export default EditLoadCompletionChecklist;