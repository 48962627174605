import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';

const TrailerType = props => {
    const trailerType = props.record;

    return (
        <Row>
            <Col xs={24} md={24} lg={24} xl={24}>
                {trailerType.name && <DataRow title="Name" value={trailerType.name} dataType="String" />}
                {trailerType.description && <DataRow title="Display Name" value={trailerType.description} dataType="String" />}
            </Col>
        </Row>
    );
};

export default TrailerType;