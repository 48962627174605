import React, { useMemo } from 'react';
import { Input, Spin, Typography, Select, Alert } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';

const { Paragraph } = Typography;
const { Option } = Select;

const EditReceiver = props => {
    const account = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const entityType = useSelector(state => state.auth.entityType);

    const onSubmit = (data) => {
        //let cleanedData = removeEmpty(data);

        let updatedAccount = {};
        updatedAccount.receiver = { ...data };

        if (!isEmpty(updatedAccount)) {
            dispatch(actionCreators.updateAccount(account.id, updatedAccount, null, null, null, null, null, null, null, null, entityType));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateAccount());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <>
            {account !== undefined && account !== null && account.receiver !== undefined && account.receiver !== null ? (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset>
                                <legend>Accounts Payable (* indicates a required field)</legend>
                                <FormItem {...formItemLayout} label="Name" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="accountsPayable.name"
                                    defaultValue={account.receiver.accountsPayable && account.receiver.accountsPayable.name ? account.receiver.accountsPayable.name : ""}
                                />
                                <FormItem {...formItemLayout} label="Email" format="horizontal" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                    rules={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                            message: "Please enter a valid email address"
                                        }
                                    }}
                                    name="accountsPayable.email"
                                    defaultValue={account.receiver.accountsPayable && account.receiver.accountsPayable.email ? account.receiver.accountsPayable.email : ""}
                                />
                                <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal" required1
                                    render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                    rules1={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                        }
                                    }}
                                    name1="accountsPayable.phone"
                                    defaultValue1={account.receiver.accountsPayable && account.receiver.accountsPayable.phone ? account.receiver.accountsPayable.phone : ""}
                                    render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                    rules2={{ required: false }}
                                    name2="accountsPayable.phoneExt"
                                    defaultValue2={account.receiver.accountsPayable && account.receiver.accountsPayable.phoneExt ? account.receiver.accountsPayable.phoneExt : ""}
                                />

                                <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the receiver's accounts payable department's address is different than the company's main physical address or mailing address, please provide it below.</Paragraph>

                                <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                    rules={{ required: false }}
                                    name="billingAddress.streetAddress1"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.streetAddress1 ? account.receiver.billingAddress.streetAddress1 : ""}
                                />
                                <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                    rules={{ required: false }}
                                    name="billingAddress.streetAddress2"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.streetAddress2 ? account.receiver.billingAddress.streetAddress2 : ""}
                                />
                                <FormItem {...formItemLayout} label="City" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                    rules={{ required: false }}
                                    name="billingAddress.city"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.city ? account.receiver.billingAddress.city : ""}
                                />
                                <FormItem {...formItemLayout} label="State" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="billingAddress.state"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.state ? account.receiver.billingAddress.state : undefined}
                                />

                                <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                    rules={{ required: false }}
                                    name="billingAddress.postalCode"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.postalCode ? account.receiver.billingAddress.postalCode : ""}
                                />
                                <FormItem {...formItemLayout} label="Country" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Country"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="USA" key="USA">United States</Option>
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="billingAddress.country"
                                    defaultValue={account.receiver.billingAddress && account.receiver.billingAddress.country ? account.receiver.billingAddress.country : undefined}
                                />
                            </Fieldset>
                            <Fieldset>
                                <legend>Point of Contact for Receiver Operations Related Purposes</legend>
                                <FormItem {...formItemLayout} label="Name" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                    rules={{ required: false }}
                                    name="pointOfContact.name"
                                    defaultValue={account.receiver.pointOfContact && account.receiver.pointOfContact.name ? account.receiver.pointOfContact.name : ""}
                                />
                                <FormItem {...formItemLayout} label="Email" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                    rules={{
                                        required: false,
                                        pattern: {
                                            value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                            message: "Please enter a valid email address"
                                        }
                                    }}
                                    name="pointOfContact.email"
                                    defaultValue={account.receiver.pointOfContact && account.receiver.pointOfContact.email ? account.receiver.pointOfContact.email : ""}
                                />
                                <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                                    render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                    rules1={{
                                        required: false,
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                        }
                                    }}
                                    name1="pointOfContact.phone"
                                    defaultValue1={account.receiver.pointOfContact && account.receiver.pointOfContact.phone ? account.receiver.pointOfContact.phone : ""}
                                    render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                    rules2={{ required: false }}
                                    name2="pointOfContact.phoneExt"
                                    defaultValue2={account.receiver.pointOfContact && account.receiver.pointOfContact.phoneExt ? account.receiver.pointOfContact.phoneExt : ""}
                                />
                            </Fieldset>
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Receiver" />
                    </Form>
                </FormProvider>
            ) : null}
        </>
    );
};

export default EditReceiver;