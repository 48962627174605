import React, { useState, useEffect } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classes from './FormFieldset.module.scss';

const Fieldset = ({legend, isCollapsable = false, isCollapsed = false, ...props }) => {
    const [isRowCollapsed, setIsRowCollapsed] = useState(false);

    useEffect(() => {
        setIsRowCollapsed(isCollapsed);
    }, [isCollapsed]);

    const onCollapse = () => {
        setIsRowCollapsed(!isRowCollapsed);
    };

    const getCollapseIcon = () => {
        if (isCollapsable === true) {
            if (isRowCollapsed === true) {
                return (
                    <Button type="link" icon={<RightOutlined />} onClick={onCollapse} style={{ marginLeft: 8 }} />
                );
            } else {
                return (
                    <Button type="link" icon={<DownOutlined />} onClick={onCollapse} style={{ marginLeft: 8 }} />
                );
            }
        } else {
            return undefined;
        }
    };

    if (isCollapsable === true) {
        return (
            <fieldset>
                {legend && <legend className={classes.legend}>{legend}{getCollapseIcon()}</legend>}
                <div style={{ display: isRowCollapsed ? 'none' : 'inherit' }}>
                    {props.children}
                </div>
            </fieldset>
        );
    } else {
        return (
            <fieldset>
                {legend && <legend className={classes.legend}>{legend}</legend>}
                {props.children}
            </fieldset>
        );
    }
};

export default Fieldset;