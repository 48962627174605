import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {isEmpty, orderBy} from "lodash";
import MomentDate from "../../shared/dateFormatter";
import {addFile} from "../../api/fileClient";
import moment from "moment";
import logger from "../../shared/logger";
import Enums from "../../shared/enums";
import axios from "axios";

//#region Loads Functions

export const fetchLoadsStart = () => {
    return {
        type: actionTypes.FETCH_LOADS_START
    }
};

export const fetchLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOADS_FAIL,
        payload: payload
    }
};

export const clearLoads = () => {
    return {
        type: actionTypes.CLEAR_LOADS
    }
};

export const fetchBundledLoadsStart = () => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_START
    }
};

export const fetchBundledLoadsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_SUCCESS,
        payload: payload
    }
};

export const fetchBundledLoadsFail = (payload) => {
    return {
        type: actionTypes.FETCH_BUNDLED_LOADS_FAIL,
        payload: payload
    }
};

export const clearBundledLoads = () => {
    return {
        type: actionTypes.CLEAR_BUNDLED_LOADS
    }
};

export const fetchLoadStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_START
    }
};

export const fetchLoadSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_SUCCESS,
        payload: payload
    }
};

export const fetchLoadFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_FAIL,
        payload: payload
    }
};

export const clearLoad = () => {
    return {
        type: actionTypes.CLEAR_LOAD
    }
};

export const addLoadStart = () => {
    return {
        type: actionTypes.ADD_LOAD_START
    }
};

export const addLoadSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_SUCCESS
    }
};

export const addLoadFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_FAIL,
        payload: payload
    }
};

export const addLoadErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_ERROR_CLEAR
    }
};

export const addLoadLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_LOADING_CLEAR
    }
};

export const updateLoadStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_START
    }
};

export const updateLoadSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_SUCCESS
    }
};

export const updateLoadFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_FAIL,
        payload: payload
    }
};

export const updateLoadErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_ERROR_CLEAR
    }
};

export const updateLoadLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_LOADING_CLEAR
    }
};

export const changeLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD,
        payload: payload
    }
};

export const changeSingleLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD,
        payload: payload
    }
};

export const changeBundledLoad = (payload) => {
    return {
        type: actionTypes.UPDATE_BUNDLED_LOAD,
        payload: payload
    }
};

export const updateLoadStatusStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_START
    }
};

export const updateLoadStatusSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_SUCCESS
    }
};

export const updateLoadStatusFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_FAIL,
        payload: payload
    }
};

export const updateLoadStatusErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_ERROR_CLEAR
    }
};

export const updateLoadStatusLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_STATUS_LOADING_CLEAR
    }
};

export const cancelAddLoad = () => {
    return {
        type: actionTypes.CANCEL_ADD_LOAD
    }
};

export const cancelUpdateLoad = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOAD
    }
};

export const cancelUpdateLoadStatus = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOAD_STATUS
    }
};

export const insertLoad = (payload) => {
    return {
        type: actionTypes.ADD_LOAD,
        payload: payload
    }
};

export const removeLoad = (payload) => {
    return {
        type: actionTypes.REMOVE_LOAD,
        payload: payload
    }
};

//#endregion

//#region Loads Methods

export const loadEventUpdateTimeout = (loadId, stopId, loadStatus, stopStatus, eventType) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const authState = {...state.auth};
            const entityType = authState.entityType;
            const entityId = authState.entityId;

            //console.log('load event update timeout started');
            setTimeout(() => {
                // get the current status after 60 seconds and check to see if it is completed
                // if it is not completed then fetch the activeload to refresh things to the current state of the load according to the database
                const state2 = getState();
                const loadsState = {...state2.loads};
                const loadEventUpdateStatus = loadsState.loadEventUpdateStatus;
                let currentLoad = null;
                if (loadsState.record !== undefined && loadsState.record !== null) {
                    currentLoad = {...loadsState.record};
                }
                //console.log(loadEventUpdateStatus);

                if (loadEventUpdateStatus !== 'COMPLETED') {
                    if (currentLoad !== undefined && currentLoad !== null) {
                        if (currentLoad.id === loadId) {
                            dispatch(fetchLoadWithIncludes(loadId));
                        }
                    }
                    //console.log('load event update timeout executed');
                } else {
                    //console.log('load event update timeout cancelled');
                }
            }, 60000);
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    };
};

export const fetchLoads = (payload, path = '') => {
    return async (dispatch) => {
        const loadsPath = path ? path : '/loads';
        try {
            dispatch(fetchLoadsStart());

            const loadsRes = await axiosAuthenticated.get(loadsPath, {
                params: {
                    ...payload,
                    isDeleted: false,
                    sort: 'pickUpDateTime',
                    order: 'asc'
                }
            });
            if (loadsRes && loadsRes.status === 200) {
                const loads = loadsRes.data.data;

                dispatch(fetchLoadsSuccess({records: loads}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadsFail({error: error.message}));
        }
    }
};

export const fetchLoadsWithIncludes = (payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/orch/loads';
        try {
            dispatch(fetchLoadsStart());

            const state = getState();
            const loadsState = {...state.loads};
            let searchParams = {...loadsState.searchParams};
            let pagination = {...loadsState.pagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
            if (loadsRes && loadsRes.status === 200) {
                const loads = loadsRes.data.data;
                const otherData = loadsRes.data;

                // Read total count from server
                pagination.total = otherData.totalCount;
                pagination.current = otherData.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLoadsSuccess({
                    records: loads,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadsFail({error: error.message}));
        }
    }
};

export const fetchAllLoadsWithIncludes = (payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/orch/loads';
        try {
            dispatch(fetchLoadsStart());

            const state = getState();
            const loadsState = {...state.loads};
            let searchParams = {...loadsState.searchParams};
            let pagination = {...loadsState.pagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
            if (loadsRes && loadsRes.status === 200) {
                const loads = loadsRes.data.data;
                const otherData = loadsRes.data;

                // Read total count from server
                pagination.total = otherData.totalCount;
                pagination.current = otherData.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLoadsSuccess({
                    records: loads,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadsFail({error: error.message}));
        }
    }
};

export const fetchLoadWithIncludes = (id) => {
    return async (dispatch) => {
        const loadsPath = '/orch/loads';
        try {
            if (id !== undefined && id !== null) {
                dispatch(fetchLoadStart());

                const loadRes = await axiosAuthenticated.get(loadsPath + `/${id}`, {params: {eta: true}});
                if (loadRes && loadRes.status === 200) {
                    const load = loadRes.data;

                    dispatch(fetchLoadSuccess({record: load, loadEventUpdateStatus: 'COMPLETED'}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadFail({error: error.message}));
        }
    }
};

export const addLoad = (loadPayload, stopsPayload, invoiceLineItemsPayload, docFiles) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const invoiceLineItemsPath = '/invoiceLineItems';

        try {
            dispatch(addLoadStart());

            if (stopsPayload !== undefined && stopsPayload !== null && stopsPayload.length > 0) {
                loadPayload.stops = [...stopsPayload];
            }

            const loadsRes = await axiosAuthenticated.post(loadsPath, {...loadPayload, isParentLoad: true});
            if (loadsRes && loadsRes.status === 201) {
                let parentLoad = loadsRes.data;
                let stops = parentLoad.stops;

                // Add BOL Documents
                if (docFiles !== undefined && docFiles !== null && docFiles.length > 0) {
                    if (stops !== undefined && stops !== null && stops.length > 0) {
                        for (let stop of stops) {
                            let sequence = stop.sequence;
                            let stopDocFiles = docFiles.filter(doc => doc.sequence === sequence);
                            if (stopDocFiles !== undefined && stopDocFiles !== null && stopDocFiles.length > 0) {
                                for (let i = 0; i < stopDocFiles.length; i++) {
                                    let docFile = stopDocFiles[i];
                                    if (docFile !== undefined && docFile !== null) {
                                        await addFile(docFile.file, "STOP", stop.id, "BOL", `BOL #${stop.bolNumber} - ${i + 1}`, "", "ALL");
                                    }
                                }
                            }
                        }
                    }
                }

                let newInvoiceLineItemsPayload = [];
                // Add Invoice Line Items
                if (invoiceLineItemsPayload !== undefined && invoiceLineItemsPayload !== null && invoiceLineItemsPayload.length > 0) {
                    invoiceLineItemsPayload.forEach((invoiceLineItem) => {
                        let newInvoiceLineItem = {
                            ...invoiceLineItem,
                            loadId: parentLoad.id
                        };

                        // Get the right StopId, the Sequence is already subtracted
                        if (invoiceLineItem.stopId !== undefined && invoiceLineItem.stopId !== null) {
                            let filteredStop = stops.find(stop => stop.sequence === invoiceLineItem.stopId);
                            if (filteredStop !== undefined && filteredStop !== null) {
                                newInvoiceLineItem.stopId = filteredStop.id;
                            }
                        }

                        newInvoiceLineItemsPayload.push({...newInvoiceLineItem});
                    });
                }

                if ((parentLoad.childLoadInitCount !== undefined && parentLoad.childLoadInitCount !== null && parentLoad.childLoadInitCount > 0) || !!loadPayload.isRecurringLoad) {
                    console.log('isRecurringLoad', parentLoad.childLoadInitCount, loadPayload.repeatCount)
                    let generatedChildLoadCount = 0;
                    // generate the child loads as well
                    try {
                        let isRecurringLoad = !!loadPayload.isRecurringLoad;

                        // Create child loads for first date
                        let childLoadsPayload = [];
                        for (let i = (loadPayload.isRecurringLoad ? 1 : 0); i < parentLoad.childLoadInitCount; i++) {
                            let childLoadPayload = {
                                ...loadPayload,
                                parentLoadId: parentLoad.id,
                                isParentLoad: false
                            };

                            childLoadPayload.childLoadInitCount = undefined;

                            if (childLoadPayload.parentName) {
                                childLoadPayload.name = childLoadPayload.parentName;
                                childLoadPayload.parentName = undefined;
                            }

                            if (stopsPayload !== undefined && stopsPayload !== null && stopsPayload.length > 0) {
                                childLoadPayload.stops = [...stopsPayload];
                            }
                            childLoadsPayload.push(childLoadPayload);
                        }

                        // Create child loads for a repeating load
                        for (let j = 0; j < loadPayload.repeatCount; j++) {
                            let childPayloadBase = Object.assign({}, loadPayload);
                            if (isRecurringLoad) {
                                let origin = stopsPayload.find(stop => stop.sequence === 0 && stop.stopType === "PICK_UP");
                                let momentDate = origin.apptWindowStartDateTime;
                                let timeZone = origin.timeZone;
                                childPayloadBase.pickUpDateTime = new MomentDate().asTimeZoneUtcISOString(moment(momentDate).add(j + 1, 'day').format('YYYY-MM-DD[T]HH:mm'), timeZone);
                                console.log('repeatDay', origin, momentDate, childPayloadBase.pickUpDateTime)
                            }
                            let childLoadInitCount = parentLoad.childLoadInitCount;
                            for (let i = 0; i < childLoadInitCount; i++) {
                                let childLoadPayload = {
                                    ...childPayloadBase,
                                    parentLoadId: parentLoad.id,
                                    isParentLoad: false
                                };

                                childLoadPayload.childLoadInitCount = undefined;

                                if (childLoadPayload.parentName) {
                                    childLoadPayload.name = childLoadPayload.parentName;
                                    childLoadPayload.parentName = undefined;
                                }

                                if (stopsPayload !== undefined && stopsPayload !== null && stopsPayload.length > 0) {
                                    childLoadPayload.stops = [...stopsPayload];
                                }
                                childLoadsPayload.push(childLoadPayload);
                            }
                        }

                        console.log("childLoadsPayload", childLoadsPayload);

                        const childLoadsRes = await axiosAuthenticated.post(loadsPath, [...childLoadsPayload]);
                        console.log(childLoadsRes)
                        if (childLoadsRes && childLoadsRes.status === 201) {
                            let childLoads = childLoadsRes.data;
                            if (childLoads !== undefined && childLoads !== null && childLoads.length > 0) {
                                generatedChildLoadCount = childLoads.length;

                                for (let i = 0; i < childLoads.length; i++) {
                                    let childLoad = childLoads[i];
                                    if (childLoad !== undefined && childLoad !== null && !isEmpty(childLoad)) {
                                        let childLoadStops = childLoad.stops;

                                        // Add Invoice Line Items
                                        if (invoiceLineItemsPayload !== undefined && invoiceLineItemsPayload !== null && invoiceLineItemsPayload.length > 0) {
                                            invoiceLineItemsPayload.forEach((invoiceLineItem) => {
                                                let newInvoiceLineItem = {
                                                    ...invoiceLineItem,
                                                    loadId: childLoad.id
                                                };

                                                // Get the right StopId, the Sequence is already subtracted
                                                if (invoiceLineItem.stopId !== undefined && invoiceLineItem.stopId !== null) {
                                                    let filteredStop = childLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                                                    if (filteredStop !== undefined && filteredStop !== null) {
                                                        newInvoiceLineItem.stopId = filteredStop.id;
                                                    }
                                                }

                                                newInvoiceLineItemsPayload.push({...newInvoiceLineItem});
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    } catch (childLoadError) {
                        console.log(childLoadError)
                        logger.logReduxErrorEvent(childLoadError, `Error fetching Requests: ${childLoadError.message}`, true);
                        dispatch(addLoadFail({error: childLoadError.message}));
                    }

                    if (newInvoiceLineItemsPayload !== undefined && newInvoiceLineItemsPayload !== null && newInvoiceLineItemsPayload.length > 0) {
                        const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, newInvoiceLineItemsPayload);
                        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
                            dispatch(fetchLoadSuccess({addRecordId: parentLoad.id}));
                            dispatch(addLoadSuccess());
                            dispatch(addLoadLoadingClear());
                            dispatch(addLoadErrorClear());
                        }
                    } else {
                        dispatch(fetchLoadSuccess({addRecordId: parentLoad.id}));
                        dispatch(addLoadSuccess());
                        dispatch(addLoadLoadingClear());
                        dispatch(addLoadErrorClear());
                    }
                } else {
                    if (newInvoiceLineItemsPayload !== undefined && newInvoiceLineItemsPayload !== null && newInvoiceLineItemsPayload.length > 0) {
                        const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, newInvoiceLineItemsPayload);
                        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
                            dispatch(fetchLoadSuccess({addRecordId: parentLoad.id}));
                            dispatch(addLoadSuccess());
                            dispatch(addLoadLoadingClear());
                            dispatch(addLoadErrorClear());
                        } else {
                            throw new Error(`Failed to create invoice line items for Load ${parentLoad.irisId}.`);
                        }
                    } else {
                        dispatch(fetchLoadSuccess({addRecordId: parentLoad.id}));
                        dispatch(addLoadSuccess());
                        dispatch(addLoadLoadingClear());
                        dispatch(addLoadErrorClear());
                    }
                }
            }
        } catch (error) {
            console.log(error)
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadFail({error: error.message}));
        }
    }
};

export const updateLoads = (payload, changeType = 'LOAD_UPDATED') => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            console.log("PUT", payload);
            const loadsRes = await axiosAuthenticated.put(loadsPath, payload);
            if (loadsRes && loadsRes.status === 200) {
                /*
                let changeType = 'LOAD_UPDATED';
                if (payload.driverIds !== undefined) {
                    changeType = 'DRIVERS_UPDATED';
                } else if (payload.assetIds !== undefined) {
                    changeType = 'ASSETS_UPDATED';
                } else if (payload.assignedCarrierId !== undefined) {
                    changeType = 'CARRIER_UPDATED';
                }
                */

                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, payload.map(it => {
                    return {
                        eventType: 'LOAD_UPDATED',
                        changeType: changeType,
                        "loadId": it['id']
                    }
                }));
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateLoad = (id, payload) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {...payload});
            if (loadsRes && loadsRes.status === 200) {
                console.log("updateLoad", payload);
                let changeType = 'LOAD_UPDATED';
                if (payload.driverIds !== undefined) {
                    changeType = 'DRIVERS_UPDATED';
                } else if (payload.assetIds !== undefined) {
                    changeType = 'ASSETS_UPDATED';
                } else if (payload.assignedCarrierId !== undefined) {
                    changeType = 'CARRIER_UPDATED';
                }

                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: id,
                    eventType: 'LOAD_UPDATED',
                    changeType: changeType
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const deleteLoad = (id) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {isDeleted: true});
            if (loadsRes && loadsRes.status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: id,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'LOAD_UPDATED'
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const undeleteLoad = (id) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {isDeleted: false});
            if (loadsRes && loadsRes.status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: id,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'LOAD_UPDATED'
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const assignBulkLoadsToAssets = (payload) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';

        try {
            dispatch(updateLoadStart());

            const loadsRes = await axios.all(payload.load_ids.map((it) => {
                return axiosAuthenticated.put(loadsPath + `/${it}`, {assetIds: payload.assetIds});
            }));

            if (loadsRes[0] && loadsRes[0].status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, payload.load_ids.map(it => {
                    return {
                        eventType: 'LOAD_UPDATED',
                        changeType: 'ASSETS_UPDATED',
                        "loadId": it
                    }
                }));
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(payload.load_ids, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const assignBulkLoadsToDriver = (payload) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        // load_ids, assignedCarrierId

        try {
            dispatch(updateLoadStart());

            const loadsRes = await axios.all(payload.load_ids.map((it) => {
                return axiosAuthenticated.put(loadsPath + `/${it}`, {driverIds: payload.driverIds});
            }));

            if (loadsRes[0] && loadsRes[0].status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, payload.load_ids.map(it => {
                    return {
                        eventType: 'LOAD_UPDATED',
                        changeType: 'DRIVERS_UPDATED',
                        "loadId": it
                    }
                }));
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(payload.load_ids, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const assignBulkLoadsToCarrier = (payload) => {
    return async (dispatch) => {
        const loadsPath = '/orch/loads/assign';
        const loadEventsPath = '/loadEvents';
        // load_ids, assignedCarrierId
        try {
            dispatch(updateLoadStart());

            const loadsRes = await axiosAuthenticated.post(loadsPath, {...payload});
            if (loadsRes && loadsRes.status === 200) {

                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, payload.load_ids.map(it => {
                    return {
                        "eventType": "LOAD_APPROVED",
                        "loadId": it
                    }
                }));
                console.log("loadEventsRes", loadEventsRes)
                if (loadEventsRes && loadEventsRes.status === 202) {
                    const loadEventsRequestRes = await axiosAuthenticated.post(loadEventsPath, payload.load_ids.map(it => {
                        return {
                            "eventType": "LOAD_REQUEST_TO_CARRIER",
                            "loadId": it
                        }
                    }));
                    console.log("loadEventsRequestRes", loadEventsRequestRes)
                    if (loadEventsRequestRes && loadEventsRequestRes.status === 202) {
                        const loadEventsConfirmRes = await axiosAuthenticated.post(loadEventsPath, payload.load_ids.map(it => {
                            return {
                                "eventType": "LOAD_REQUEST_TO_CARRIER_APPROVED",
                                "loadId": it
                            }
                        }));
                        console.log("loadEventsConfirmRes", loadEventsConfirmRes)
                        if (loadEventsConfirmRes && loadEventsConfirmRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(payload.load_ids, null, null, null, 'LOAD_REQUEST_TO_CARRIER_APPROVED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const changeCarrierInLoad = (id, payload, load) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            // Update the Load to switch the assigned carrier
            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {...payload});
            if (loadsRes && loadsRes.status === 200) {
                // If the Load is pending rate confirmation, then set the load status back to approved
                if (load.loadStatus !== undefined && load.loadStatus !== null && load.loadStatus === "PENDING_RATE_CONFIRMATION") {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        eventType: 'LOAD_APPROVED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_APPROVED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                } else {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'CARRIER_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_APPROVED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }
                }

                // dispatch(fetchLoadWithIncludes(id));
                // dispatch(fetchLoadsWithIncludes(null));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const removeDriverFromLoad = (id, payload, load, accountUser) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const accountUsersPath = '/accountUsers';
        const loadEventsPath = '/loadEvents';
        try {
            if (load !== undefined && load !== null && accountUser !== undefined && accountUser !== null) {
                dispatch(updateLoadStart());

                // Update the Load to remove the driver
                const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {...payload});
                if (loadsRes && loadsRes.status === 200) {
                    // If the Load is scheduled or beyond, fire off the load event to set the load back to booked
                    if (load.loadStatus !== undefined && load.loadStatus !== null && (load.loadStatus !== "PENDING" || load.loadStatus !== "APPROVED" || load.loadStatus !== "PENDING_RATE_CONFIRMATION" || load.loadStatus !== "BOOKED")) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: load.id,
                            eventType: 'LOAD_BOOKED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_BOOKED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    try {
                        const scheduledLoads = accountUser && accountUser.driver && accountUser.driver.scheduledLoads ? accountUser.driver.scheduledLoads : 0;
                        // If the Load is scheduled then decrement the scheduled load count of the removed driver
                        if (load.loadStatus !== undefined && load.loadStatus !== null && load.loadStatus === 'SCHEDULED') {
                            if (accountUser.id !== undefined && accountUser.id !== null && scheduledLoads !== undefined && scheduledLoads !== null && Number.isInteger(scheduledLoads)) {
                                let accountUserPayload = {};
                                accountUserPayload.driver = {};
                                accountUserPayload.driver.scheduledLoads = scheduledLoads > 0 ? scheduledLoads - 1 : 0;
                                await axiosAuthenticated.put(accountUsersPath + `/${accountUser.id}`, {...accountUserPayload});
                            }
                        }
                    } catch (error) {
                        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
                    }

                    // dispatch(fetchLoadWithIncludes(id));
                    // dispatch(fetchLoadsWithIncludes(null));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updatePriceConfirmation = (payload, loadId) => {
    return async (dispatch, getState) => {
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            let isReviewed = null;
            let reviewStatus = null;
            if (payload.approvedPriceConfirmation === true) {
                isReviewed = true;
                reviewStatus = "APPROVED";
            }
            const document = await addFile(payload.file, 'LOAD', loadId, "PRICE_CONFIRMATION", "Shipper Price Confirmation", "", "SHIPPER", null, isReviewed, reviewStatus);

            if (document !== null) {
                // Now that price confirmation document has been added, approve the load
                const loadsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_APPROVED'
                });

                if (loadsRes && loadsRes.status === 202) {
                    const state = getState();
                    const loadsState = {...state.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the load in state
                    if (loadsState.record.id === loadId) {
                        let priceConfirmationDetails = {
                            isPriceConfirmed: true,
                            priceConfirmedAt: moment().utc().toISOString(),
                            priceConfirmedBy: null
                        };
                        let singleLoadToUpdate = {...loadsState.record, ...priceConfirmationDetails};
                        dispatch(changeSingleLoad(singleLoadToUpdate));
                    }

                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_APPROVED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            } else {
                dispatch(updateLoadFail({error: 'Failed to upload the Price Confirmation document.'}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateLoadAcceptPostponeDate = (payload, loadId) => {
    return async (dispatch, getState) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, {
                ...payload,
                isAskingForPostpone: false,
                isPostponeApproved: true,
                suggestedPostponeDate: null,
                pickUpDateTime: payload.suggestedPostponeDate
            });
            if (loadRes && loadRes.status === 200) {
                const loadsEventRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_REQUEST_TO_CARRIER_ACCEPT_POSTPONE'
                });

                if (loadsEventRes) {
                    const state = getState();
                    const loadsState = {...state.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the load in state
                    if (loadsState.record.id === loadId) {
                        let singleLoadToUpdate = {...loadsState.record, ...payload};
                        dispatch(changeSingleLoad(singleLoadToUpdate));
                    }

                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_ACCEPT_POSTPONE'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateLoadRejectPostponeDate = (payload, loadId) => {
    return async (dispatch, getState) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, {
                isAskingForPostpone: false,
                isPostponeApproved: false,
                suggestedPostponeDate: null,
                assignedCarrierId: null,
                loadStatus: 'APPROVED'
            });
            if (loadRes && loadRes.status === 200) {
                const loadsEventRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_REQUEST_TO_CARRIER_REJECT_POSTPONE'
                });

                if (loadsEventRes) {
                    const state = getState();
                    const loadsState = {...state.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the load in state
                    if (loadsState.record.id === loadId) {
                        let singleLoadToUpdate = {...loadsState.record, ...payload};
                        dispatch(changeSingleLoad(singleLoadToUpdate));
                    }

                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_REJECT_POSTPONE'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateLoadPostponeDate = (payload, loadId) => {
    return async (dispatch, getState) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, {
                ...payload,
                isAskingForPostpone: true
            });
            if (loadRes && loadRes.status === 200) {
                const loadsEventRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_REQUEST_TO_CARRIER_POSTPONE'
                });

                if (loadsEventRes) {
                    const state = getState();
                    const loadsState = {...state.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the load in state
                    if (loadsState.record.id === loadId) {
                        let singleLoadToUpdate = {...loadsState.record, ...payload};
                        dispatch(changeSingleLoad(singleLoadToUpdate));
                    }

                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_POSTPONE'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateRateConfirmation = (payload, loadId, isCarrier = false) => {
    return async (dispatch, getState) => {
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            let isReviewed = null;
            let reviewStatus = null;
            if (payload.approvedRateConfirmation === true) {
                isReviewed = true;
                reviewStatus = "APPROVED";
            }

            let document = null;
            if (payload.file) {
                document = await addFile(payload.file, 'LOAD', loadId, "RATE_CONFIRMATION", "Carrier Rate Confirmation", "", "CARRIER", null, isReviewed, reviewStatus);
            }

            if (document !== null || isCarrier === true) {
                // Now that price confirmation document has been added, approve the load
                const loadsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_REQUEST_TO_CARRIER_APPROVED'
                });

                if (loadsRes && loadsRes.status === 202) {
                    const state = getState();
                    const loadsState = {...state.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the load in state
                    if (loadsState.record.id === loadId) {
                        let rateConfirmationDetails = {
                            isRateConfirmed: true,
                            rateConfirmedAt: moment().utc().toISOString(),
                            rateConfirmedBy: null
                        };
                        let singleLoadToUpdate = {...loadsState.record, ...rateConfirmationDetails};
                        dispatch(changeSingleLoad(singleLoadToUpdate));
                    }

                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_REQUEST_TO_CARRIER_APPROVED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            } else {
                dispatch(updateLoadFail({error: 'Failed to upload the Rate Confirmation document.'}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const sendLoadEvent = (payload) => {
    return async (dispatch) => {
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            const loadsRes = await axiosAuthenticated.post(loadEventsPath, {...payload});
            if (loadsRes && loadsRes.status === 202) {
                let loadId = payload.loadId !== undefined && payload.loadId !== null ? payload.loadId : null;
                let stopId = payload.stopId !== undefined && payload.stopId !== null ? payload.stopId : null;
                let loadStatus = payload.loadStatus !== undefined && payload.loadStatus !== null ? payload.loadStatus : null;
                let stopStatus = payload.stopStatus !== undefined && payload.stopStatus !== null ? payload.stopStatus : null;
                let eventType = payload.eventType !== undefined && payload.eventType !== null ? payload.eventType : null;
                dispatch(loadEventUpdateTimeout(loadId, stopId, loadStatus, stopStatus, eventType));
                dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const completeLoadStop = (payload, stop, load) => {
    return async (dispatch) => {
        const loadEventsPath = '/loadEvents';
        try {
            let docFiles = [];
            let scaleTicketFiles = [];
            let bolFiles = [];

            dispatch(updateLoadStart());

            if (payload.docFiles !== undefined && payload.docFiles !== null) {
                docFiles = [...payload.docFiles];
            }
            if (payload.scaleTicketFiles !== undefined && payload.scaleTicketFiles !== null) {
                scaleTicketFiles = [...payload.scaleTicketFiles];
            }
            if (payload.bolFiles !== undefined && payload.bolFiles !== null) {
                bolFiles = [...payload.bolFiles];
            }

            let haveAllBolFilesBeenAdded = true;
            for (let i = 0; i < bolFiles.length; i++) {
                let file = bolFiles[i];
                let displayName = payload[`bolDocumentDisplayName${i}`];
                let doc = await addFile(file, "STOP", stop.id, "BOL", displayName, "", "ALL");
                if (doc === null) {
                    haveAllBolFilesBeenAdded = false;
                }
            }

            let haveAllScaleTicketsFilesBeenAdded = true;
            for (let i = 0; i < scaleTicketFiles.length; i++) {
                let file = scaleTicketFiles[i];
                let displayName = payload[`scaleTicketDocumentDisplayName${i}`];
                let doc = await addFile(file, "STOP", stop.id, "SCALE_TICKET", displayName, "", "ALL");
                if (doc === null) {
                    haveAllScaleTicketsFilesBeenAdded = false;
                }
            }

            let haveAllDocFilesBeenAdded = true;
            for (let i = 0; i < docFiles.length; i++) {
                let file = docFiles[i];
                let documentType = payload[`otherDocumentType${i}`];
                let displayName = payload[`otherDocumentDisplayName${i}`];
                let description = payload[`otherDocumentDescription${i}`];
                let doc = await addFile(file, "STOP", stop.id, documentType, displayName, description, "ALL");
                if (doc === null) {
                    haveAllDocFilesBeenAdded = false;
                }
            }

            if (stop.stopType === "DROP_OFF") {
                if (haveAllBolFilesBeenAdded === true && haveAllScaleTicketsFilesBeenAdded === true && haveAllDocFilesBeenAdded === true) {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        stopId: stop.id,
                        eventType: 'LOAD_DROP_OFF_COMPLETED'
                    });

                    if (loadEventsRes && loadEventsRes.status === 201) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_DROP_OFF_COMPLETED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                        dispatch(updateLoadSuccess());
                        dispatch(updateLoadLoadingClear());
                        dispatch(updateLoadErrorClear());
                    }
                } else {
                    throw new Error('Failed to upload all the files');
                }
            } else if (stop.stopType === "PICK_UP") {
                if (haveAllBolFilesBeenAdded === true && haveAllScaleTicketsFilesBeenAdded === true && haveAllDocFilesBeenAdded === true) {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: load.id,
                        stopId: stop.id,
                        eventType: 'LOAD_PICK_UP_COMPLETED'
                    });

                    if (loadEventsRes && loadEventsRes.status === 201) {
                        dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_PICK_UP_COMPLETED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                        dispatch(updateLoadSuccess());
                        dispatch(updateLoadLoadingClear());
                        dispatch(updateLoadErrorClear());
                    }
                } else {
                    throw new Error('Failed to upload all the files');
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadFail({error: error.message}));
        }
    }
};

export const updateLoadDetails = (loadId, payload, invoiceLineItems, lineItemTypes) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const invoiceLineItemPath = '/invoiceLineItems';
        const loadEventsPath = '/loadEvents';

        try {
            dispatch(updateLoadStart());

            let updatedLoad = updateLoadRequest(payload, invoiceLineItems, lineItemTypes);
            let newInvoiceLineItems = transformInvoiceLineItemsRequest(invoiceLineItems);

            const loadRes = await axiosAuthenticated.put(loadsPath + `/${loadId}`, {...updatedLoad});
            if (loadRes && loadRes.status === 200) {

                // get invoicelineitems for load so we can add/update/remove them based on the changes
                const invoiceLineItemRes = await axiosAuthenticated.get(invoiceLineItemPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        loadId: loadId,
                        isDeleted: false
                    }
                });

                if (invoiceLineItemRes && invoiceLineItemRes.status === 200) {
                    const existingInvoiceLineItems = invoiceLineItemRes.data.data;

                    let invoiceLineItemsToAdd = [];

                    // add/update new invoiceLineItems
                    for (let newInvoiceLineItem of newInvoiceLineItems) {
                        // check to see if it exists in the existing list
                        const existingInvoiceLineItem = existingInvoiceLineItems.find(i => i.itemType === newInvoiceLineItem.itemType && i.fromEntityType === newInvoiceLineItem.fromEntityType && i.toEntityType === newInvoiceLineItem.toEntityType);
                        if (existingInvoiceLineItem === undefined) {
                            // doesn't exist yet so add the new invoice line item
                            invoiceLineItemsToAdd.push(newInvoiceLineItem);
                        } else {
                            // exists already so check to see if anything changed with it and if so, then update it
                            if (existingInvoiceLineItem.baseAmount !== newInvoiceLineItem.baseAmount || existingInvoiceLineItem.quantity !== newInvoiceLineItem.quantity || existingInvoiceLineItem.totalAmount !== newInvoiceLineItem.totalAmount) {
                                const invoiceLineItemRes = await axiosAuthenticated.put(invoiceLineItemPath + `/${existingInvoiceLineItem.id}`, {
                                    baseAmount: newInvoiceLineItem.baseAmount,
                                    quantity: newInvoiceLineItem.quantity,
                                    totalAmount: newInvoiceLineItem.totalAmount
                                });
                                if (invoiceLineItemRes && invoiceLineItemRes.status !== 200) {
                                    logger.logReduxErrorEvent(invoiceLineItemRes, `Error updating invoice line item`, true);
                                }
                            }
                        }
                    }

                    // if there are any new line items to add then add them
                    if (invoiceLineItemsToAdd.length > 0) {
                        const invoiceLineItemRes2 = await axiosAuthenticated.post(invoiceLineItemPath, [...invoiceLineItemsToAdd]);
                        if (invoiceLineItemRes2 && invoiceLineItemRes2.status !== 201) {
                            logger.logReduxErrorEvent(invoiceLineItemRes2, `Error adding invoice line items`, true);
                        }
                    }

                    // remove any that shouldn't exist
                    for (let existingInvoiceLineItem of existingInvoiceLineItems) {
                        // check to see if it exists in the new list
                        const newInvoiceLineItem = newInvoiceLineItems.find(i => i.itemType === existingInvoiceLineItem.itemType && i.fromEntityType === existingInvoiceLineItem.fromEntityType && i.toEntityType === existingInvoiceLineItem.toEntityType);
                        if (newInvoiceLineItem === undefined) {
                            // isn't in the new list so it needs to be removed
                            const invoiceLineItemRes3 = await axiosAuthenticated.put(invoiceLineItemPath + `/${existingInvoiceLineItem.id}`, {isDeleted: true});
                            if (invoiceLineItemRes3 && invoiceLineItemRes3.status !== 200) {
                                logger.logReduxErrorEvent(invoiceLineItemRes3, `Error removing invoice line item`, true);
                            }
                        }
                    }
                }

                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: loadId,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'LOAD_UPDATED'
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(loadId, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const updateLoadStatus = (loadId, loadStatus) => {
    return async (dispatch, getState) => {
        try {
            if (loadId !== undefined && loadId !== null && loadStatus !== undefined && loadStatus !== null) {
                const state = getState();
                const loadsState = {...state.loads};
                const updatedLoads = [...loadsState.records];

                let loadToUpdate = updatedLoads.find(i => i.id === loadId);
                if (loadToUpdate !== undefined && loadToUpdate !== null) {
                    // loads in load list don't have stops attached to them so no need to update the stops on the load in the list
                    loadToUpdate.loadStatus = loadStatus;
                    dispatch(changeLoad(loadToUpdate));
                }

                dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'COMPLETED'}));
            } else if (loadId !== undefined && loadId !== null) {
                dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'COMPLETED'}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

//#endregion

//#region Bundled Load Methods

export const fetchBundledLoadsWithIncludes = (parentLoadId, carrierId = null) => {
    return async (dispatch) => {
        const loadsPath = '/orch/loads';
        try {
            dispatch(fetchBundledLoadsStart());

            let searchParams = {
                page: 1,
                size: 100000,
                sort: 'createdAt',
                order: 'asc',
                isDeleted: false,
                eta: false,
                assignedCarrierId: carrierId,
                'parentLoadId:or': parentLoadId,
                'id:or': parentLoadId
            };

            if (!carrierId) {
                delete searchParams['assignedCarrierId'];
            }

            const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
            if (loadsRes && loadsRes.status === 200) {
                const bundledLoads = loadsRes.data.data;

                let orderedBundledLoads = [];
                if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
                    orderedBundledLoads = orderBy(bundledLoads, ['isParentLoad', 'createdAt'], ['desc', 'asc']);
                }

                dispatch(fetchBundledLoadsSuccess({bundledLoads: orderedBundledLoads}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchBundledLoadsFail({error: error.message}));
        }
    }
};

export const addMissingChildLoads = (id) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const invoiceLineItemsPath = '/invoiceLineItems';

        try {
            dispatch(addLoadStart());

            const getLoadsRes = await axiosAuthenticated.get(`/orch/loads/${id}`, {params: {eta: true}});
            if (getLoadsRes && getLoadsRes.status === 200) {
                const parentLoad = getLoadsRes.data;

                let loadPayload = {
                    unitOfMeasure: "ENGLISH",
                    equipmentNeeds: parentLoad.equipmentNeeds,
                    practicalDistance: parentLoad.practicalDistance,
                    practicalDistanceUnit: parentLoad.practicalDistanceUnit,
                    shipperId: parentLoad.shipperId,
                    createdByEntityType: parentLoad.createdByEntityType,
                    assignedCarrierId: parentLoad.assignedCarrierId,
                    hasTeamDriving: parentLoad.hasTeamDriving, // TODO: depricate
                    pickUpDateTime: parentLoad.pickUpDateTime,
                    name: parentLoad.parentName,
                    serviceType: parentLoad.serviceType,
                    shipperRatePerMile: parentLoad.shipperRatePerMile,
                    shipperRatePerMileUnit: parentLoad.shipperRatePerMileUnit,
                    shipperAmount: parentLoad.shipperAmount,
                    shipperAmountUnit: parentLoad.shipperAmountUnit,
                    shipperBalance: parentLoad.shipperBalance,
                    shipperBalanceUnit: parentLoad.shipperBalanceUnit,
                    carrierRatePerMile: parentLoad.carrierRatePerMile,
                    carrierRatePerMileUnit: parentLoad.carrierRatePerMileUnit,
                    carrierAmount: parentLoad.carrierAmount,
                    carrierAmountUnit: parentLoad.carrierAmountUnit,
                    carrierBalance: parentLoad.carrierBalance,
                    carrierBalanceUnit: parentLoad.carrierBalanceUnit
                };

                if (parentLoad.driverRequirements && parentLoad.driverRequirements.length > 0) {
                    loadPayload.driverRequirements = parentLoad.driverRequirements;
                }

                if (parentLoad.equipmentSpecifics && parentLoad.equipmentSpecifics.length > 0) {
                    loadPayload.equipmentSpecifics = parentLoad.equipmentSpecifics;
                }
                let stopsPayload = [];
                let invoiceLineItemsArray = [];
                let newInvoiceLineItemsPayload = [];

                const loadStopsRes = await axiosAuthenticated.get('/stops', {
                    params: {
                        page: 1,
                        size: 100000,
                        sort: 'sequence',
                        order: 'asc',
                        loadId: parentLoad.id,
                        isDeleted: false
                    }
                });
                if (loadStopsRes && loadStopsRes.status === 200) {
                    const stopsArray = loadStopsRes.data.data;

                    if (stopsArray !== undefined && stopsArray !== null && stopsArray.length > 0) {
                        stopsArray.forEach((stop, index) => {
                            let newStop = {
                                unitOfMeasure: "ENGLISH",
                                latitude: stop.latitude,
                                longitude: stop.longitude,
                                timeZone: stop.timeZone,
                                stopType: stop.stopType,
                                sequence: stop.sequence,
                                stopLocationId: stop.stopLocationId,
                                hasDriverAssist: stop.hasDriverAssist,
                                driverAssist: stop.driverAssist,
                                driverAssistUnit: stop.driverAssistUnit,
                                hasLumperFee: stop.hasLumperFee,
                                lumperFee: stop.lumperFee,
                                lumperFeeUnit: stop.lumperFeeUnit,
                                bolNumber: stop.bolNumber,
                                apptType: stop.apptType,
                                loadingType: stop.loadingType,
                                specialInstructions: stop.specialInstructions,
                                apptInstructions: stop.apptInstructions,
                                apptNumber: stop.apptNumber,
                                apptWindowStartDateTime: stop.apptWindowStartDateTime,
                                apptWindowEndDateTime: stop.apptWindowEndDateTime,
                                requestedDateTime: stop.requestedDateTime,
                                apptCallAheadDateTime: stop.apptCallAheadDateTime,
                                apptDateTime: stop.apptDateTime,
                                apptPointOfContact: stop.apptPointOfContact
                            };

                            if (stop.commodities && stop.commodities.length > 0) {
                                newStop.commodities = stop.commodities;
                            }

                            stopsPayload.push({...newStop});
                        });

                        const loadInvoiceLineItemsRes = await axiosAuthenticated.get('/invoiceLineItems', {
                            params: {
                                page: 1,
                                size: 100000,
                                loadId: parentLoad.id,
                                isDeleted: false
                            }
                        });
                        if (loadInvoiceLineItemsRes && loadInvoiceLineItemsRes.status === 200) {
                            const invoiceLineItems = loadInvoiceLineItemsRes.data.data;
                            if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                                invoiceLineItems.forEach((invoiceLineItem) => {
                                    let newInvoiceLineItem = {
                                        description: invoiceLineItem.description,
                                        itemType: invoiceLineItem.itemType,
                                        isIrisFee: invoiceLineItem.isIrisFee,
                                        fromEntityType: invoiceLineItem.fromEntityType,
                                        fromEntityId: invoiceLineItem.fromEntityId,
                                        toEntityType: invoiceLineItem.toEntityType,
                                        toEntityId: invoiceLineItem.toEntityId,
                                        baseAmount: invoiceLineItem.baseAmount,
                                        baseAmountUnit: invoiceLineItem.baseAmountUnit,
                                        totalAmount: invoiceLineItem.totalAmount,
                                        totalAmountUnit: invoiceLineItem.totalAmountUnit,
                                        quantity: invoiceLineItem.quantity,
                                        quantityUnit: invoiceLineItem.quantityUnit,
                                        status: invoiceLineItem.status,
                                        approvalStatus: invoiceLineItem.approvalStatus,
                                        isOpen: invoiceLineItem.isOpen
                                    };

                                    if (invoiceLineItem.stopId !== undefined && invoiceLineItem.stopId !== null) {
                                        let filteredStop = stopsArray.find(i => i.id === invoiceLineItem.stopId);
                                        if (filteredStop !== undefined && filteredStop !== null) {
                                            newInvoiceLineItem.stopId = filteredStop.sequence;
                                        }
                                    }

                                    invoiceLineItemsArray.push(newInvoiceLineItem);
                                });
                            }
                        }
                    }
                }

                let childLoadInitCount = parentLoad.childLoadInitCount !== undefined && parentLoad.childLoadInitCount !== null && parentLoad.childLoadInitCount > 0 ? parentLoad.childLoadInitCount : 0;
                if (childLoadInitCount > 0) {
                    // generate the child loads as well
                    let generatedChildLoadCount = parentLoad.childLoadActualCount !== undefined && parentLoad.childLoadActualCount !== null && parentLoad.childLoadActualCount > 0 ? parentLoad.childLoadActualCount : 0;
                    if (generatedChildLoadCount < childLoadInitCount) {
                        try {
                            let childLoadsPayload = [];
                            for (let i = generatedChildLoadCount; i < childLoadInitCount; i++) {
                                let childLoadPayload = {
                                    ...loadPayload,
                                    parentLoadId: parentLoad.id,
                                    isParentLoad: false
                                };
                                if (childLoadPayload.parentName) {
                                    childLoadPayload.name = childLoadPayload.parentName;
                                    childLoadPayload.parentName = undefined;
                                }

                                if (stopsPayload !== undefined && stopsPayload !== null && stopsPayload.length > 0) {
                                    childLoadPayload.stops = [...stopsPayload];
                                }

                                childLoadsPayload.push(childLoadPayload);
                            }

                            const childLoadsRes = await axiosAuthenticated.post(loadsPath, [...childLoadsPayload]);
                            if (childLoadsRes && childLoadsRes.status === 201) {
                                let childLoads = childLoadsRes.data;
                                if (childLoads !== undefined && childLoads !== null && childLoads.length > 0) {
                                    generatedChildLoadCount = generatedChildLoadCount + childLoads.length;

                                    for (let i = 0; i < childLoads.length; i++) {
                                        let childLoad = childLoads[i];
                                        if (childLoad !== undefined && childLoad !== null && !isEmpty(childLoad)) {
                                            let childLoadStops = childLoad.stops;

                                            // Add Invoice Line Items
                                            if (invoiceLineItemsArray !== undefined && invoiceLineItemsArray !== null && invoiceLineItemsArray.length > 0) {
                                                invoiceLineItemsArray.forEach((invoiceLineItem) => {
                                                    let newInvoiceLineItem = {
                                                        ...invoiceLineItem,
                                                        loadId: childLoad.id
                                                    };

                                                    // Get the right StopId, the Sequence is already subtracted
                                                    if (invoiceLineItem.stopId !== undefined && invoiceLineItem.stopId !== null) {
                                                        let filteredStop = childLoadStops.find(stop => stop.sequence === invoiceLineItem.stopId);
                                                        if (filteredStop !== undefined && filteredStop !== null) {
                                                            newInvoiceLineItem.stopId = filteredStop.id;
                                                        }
                                                    }

                                                    newInvoiceLineItemsPayload.push({...newInvoiceLineItem});
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        } catch (childLoadError) {
                            logger.logReduxErrorEvent(childLoadError, `Error fetching Requests: ${childLoadError.message}`, true);
                            dispatch(addLoadFail({error: childLoadError.message}));
                        }
                    }

                    if (newInvoiceLineItemsPayload !== undefined && newInvoiceLineItemsPayload !== null && newInvoiceLineItemsPayload.length > 0) {
                        const invoiceLineItemsRes = await axiosAuthenticated.post(invoiceLineItemsPath, newInvoiceLineItemsPayload);
                        if (invoiceLineItemsRes && invoiceLineItemsRes.status === 201) {
                            if (generatedChildLoadCount === childLoadInitCount) {
                                dispatch(addLoadSuccess());
                                dispatch(addLoadLoadingClear());
                                dispatch(addLoadErrorClear());
                            } else {
                                throw new Error(`Failed to create all the loads for this load bundle. Please contact Customer Support for assistance.`);
                            }
                        }
                    } else {
                        if (generatedChildLoadCount === childLoadInitCount) {
                            dispatch(addLoadSuccess());
                            dispatch(addLoadLoadingClear());
                            dispatch(addLoadErrorClear());
                        } else {
                            throw new Error(`Failed to create all the loads for this load bundle. Please contact Customer Support for assistance.`);
                        }
                    }
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadFail({error: error.message}));
        }
    }
};

export const updateBundledLoad = (id, payload, load) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            let updatedLoad = {...load};

            let changeType = 'LOAD_UPDATED';
            if (payload.driverIds !== undefined) {
                updatedLoad.driverIds = payload.driverIds;
                changeType = 'DRIVERS_UPDATED';
            } else if (payload.assetIds !== undefined) {
                updatedLoad.assetIds = payload.assetIds;
                changeType = 'ASSETS_UPDATED';
            } else if (payload.assignedCarrierId !== undefined) {
                updatedLoad.assignedCarrierId = payload.assignedCarrierId;
                changeType = 'CARRIER_UPDATED';
            }

            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {...payload});
            if (loadsRes && loadsRes.status === 200) {
                const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: id,
                    eventType: 'LOAD_UPDATED',
                    changeType: changeType
                });
                if (loadEventsRes && loadEventsRes.status === 202) {
                    dispatch(loadEventUpdateTimeout(id, null, null, null, 'LOAD_UPDATED'));
                    dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                }

                dispatch(changeBundledLoad(updatedLoad));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const dispatchBundledLoad = (load) => {
    return async (dispatch) => {
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            let updatedLoad = {...load, loadStatus: 'SCHEDULED'};

            const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                loadId: load.id,
                eventType: 'LOAD_SCHEDULED'
            });

            if (loadEventsRes && loadEventsRes.status === 202) {
                dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_SCHEDULED'));
                dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));

                dispatch(changeBundledLoad(updatedLoad));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const deleteBundledLoad = (id, load, parentLoadId, parentLoad) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const loadEventsPath = '/loadEvents';
        try {
            dispatch(updateLoadStart());

            let updatedLoad = {...load, isDeleted: true};

            let updatedParentLoad = {...parentLoad};
            let parentLoadPayload = {};
            let newChildLoadInitCount = updatedParentLoad.childLoadInitCount;
            let newChildLoadActualCount = updatedParentLoad.childLoadActualCount;
            let updatedChildLoads = updatedParentLoad.childLoads !== undefined && updatedParentLoad.childLoads !== null && updatedParentLoad.childLoads.length > 0 ? [...updatedParentLoad.childLoads] : [];

            if (updatedParentLoad.childLoadInitCount !== undefined && updatedParentLoad.childLoadInitCount !== null && updatedParentLoad.childLoadInitCount > 0) {
                newChildLoadInitCount = updatedParentLoad.childLoadInitCount - 1;
                updatedParentLoad.childLoadInitCount = newChildLoadInitCount;
                parentLoadPayload.childLoadInitCount = newChildLoadInitCount;
            }

            if (updatedChildLoads.length > 0) {
                let childLoadToRemoveIndex = updatedChildLoads.findIndex(i => i.childLoadId === id);
                if (childLoadToRemoveIndex !== -1) {
                    updatedChildLoads.splice(childLoadToRemoveIndex, 1);
                    updatedParentLoad.childLoads = updatedChildLoads;
                    parentLoadPayload.childLoads = updatedChildLoads;

                    // set the actual count to be the length of the new childLoads array
                    newChildLoadActualCount = updatedChildLoads.length;
                    updatedParentLoad.childLoadActualCount = newChildLoadActualCount;
                    parentLoadPayload.childLoadActualCount = newChildLoadActualCount;
                }
            }

            if (!isEmpty(parentLoadPayload)) {
                const parentLoadsRes = await axiosAuthenticated.put(loadsPath + `/${parentLoadId}`, {...parentLoadPayload});

                if (parentLoadsRes && parentLoadsRes.status === 200) {
                    const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                        loadId: parentLoadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'LOAD_UPDATED'
                    });
                    if (loadEventsRes && loadEventsRes.status === 202) {
                        dispatch(loadEventUpdateTimeout(parentLoadId, null, null, null, 'LOAD_UPDATED'));
                        dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                    }

                    dispatch(changeBundledLoad(updatedParentLoad));
                }
            }

            const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {isDeleted: true});
            if (loadsRes && loadsRes.status === 200) {
                const childLoadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                    loadId: id,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'LOAD_UPDATED'
                });

                dispatch(changeBundledLoad(updatedLoad));

                dispatch(updateLoadSuccess());
                dispatch(updateLoadLoadingClear());
                dispatch(updateLoadErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

export const removeDriverFromBundledLoad = (id, payload, load, accountUser) => {
    return async (dispatch) => {
        const loadsPath = '/loads';
        const accountUsersPath = '/accountUsers';
        const loadEventsPath = '/loadEvents';
        try {
            if (load !== undefined && load !== null && accountUser !== undefined && accountUser !== null) {
                dispatch(updateLoadStart());

                let updatedLoad = {...load};

                if (payload.driverIds !== undefined) {
                    updatedLoad.driverIds = payload.driverIds;
                } else {
                    updatedLoad.driverIds = [];
                }

                // Update the Load to remove the driver
                const loadsRes = await axiosAuthenticated.put(loadsPath + `/${id}`, {...payload});
                if (loadsRes && loadsRes.status === 200) {
                    // If the Load is scheduled or beyond, fire off the load event to set the load back to booked
                    if (load.loadStatus !== undefined && load.loadStatus !== null && (load.loadStatus !== "PENDING" || load.loadStatus !== "APPROVED" || load.loadStatus !== "PENDING_RATE_CONFIRMATION" || load.loadStatus !== "BOOKED")) {
                        const loadEventsRes = await axiosAuthenticated.post(loadEventsPath, {
                            loadId: load.id,
                            eventType: 'LOAD_BOOKED'
                        });
                        if (loadEventsRes && loadEventsRes.status === 202) {
                            dispatch(loadEventUpdateTimeout(load.id, null, null, null, 'LOAD_BOOKED'));
                            dispatch(fetchLoadSuccess({loadEventUpdateStatus: 'PENDING'}));
                        }
                    }

                    try {
                        const scheduledLoads = accountUser && accountUser.driver && accountUser.driver.scheduledLoads ? accountUser.driver.scheduledLoads : 0;

                        // If the Load is scheduled then decrement the scheduled load count of the removed driver
                        if (load.loadStatus !== undefined && load.loadStatus !== null && load.loadStatus === 'SCHEDULED') {
                            if (accountUser.id !== undefined && accountUser.id !== null && scheduledLoads !== undefined && scheduledLoads !== null && Number.isInteger(scheduledLoads)) {
                                let accountUserPayload = {};
                                accountUserPayload.driver = {};
                                accountUserPayload.driver.scheduledLoads = scheduledLoads > 0 ? scheduledLoads - 1 : 0;
                                await axiosAuthenticated.put(accountUsersPath + `/${accountUser.id}`, {...accountUserPayload});
                            }
                        }
                    } catch (error) {
                        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
                    }

                    dispatch(changeBundledLoad(updatedLoad));

                    dispatch(updateLoadSuccess());
                    dispatch(updateLoadLoadingClear());
                    dispatch(updateLoadErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

//#endregion

//#region Helper Methods

const updateLoadRequest = (payload, invoiceLineItems, lineItemTypes) => {
    let load = {};

    if (payload.equipmentData !== undefined && payload.equipmentData !== null) {
        let equipmentNeeds = {};
        let lineItemTypeIds = [];
        let equipmentData = payload.equipmentData;

        equipmentNeeds.hasCarrierWashout = false; // set default value to false in case the lineItemType wasn't selected

        if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
            equipmentNeeds.trailerTypeId = equipmentData.trailerTypeId;
        }

        if (equipmentData.trailerLength !== undefined && equipmentData.trailerLength !== null) {
            equipmentNeeds.trailerLength = equipmentData.trailerLength;
            equipmentNeeds.trailerLengthUnit = "FT";
        }

        if (equipmentData.trailerWeightCapacity !== undefined && equipmentData.trailerWeightCapacity !== null) {
            equipmentNeeds.trailerWeightCapacity = equipmentData.trailerWeightCapacity;
            equipmentNeeds.trailerWeightCapacityUnit = "LB";
        }

        if (equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null) {
            equipmentNeeds.preCoolReefer = Number(equipmentData.preCoolReefer);
            equipmentNeeds.preCoolReeferUnit = "F";
        } else {
            equipmentNeeds.preCoolReefer = null;
            equipmentNeeds.preCoolReeferUnit = null;
        }

        if (equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null) {
            equipmentNeeds.minRunTemp = Number(equipmentData.minRunTemp);
            equipmentNeeds.minRunTempUnit = "F";
        } else {
            equipmentNeeds.minRunTemp = null;
            equipmentNeeds.minRunTempUnit = null;
        }

        if (equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null) {
            equipmentNeeds.maxRunTemp = Number(equipmentData.maxRunTemp);
            equipmentNeeds.maxRunTempUnit = "F";
        } else {
            equipmentNeeds.maxRunTemp = null;
            equipmentNeeds.maxRunTempUnit = null;
        }

        if (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) {
            load.driverRequirements = equipmentData.driverRequirements;

            // TODO: depricate
            if (equipmentData.driverRequirements.includes('TWIC_REQUIRED')) {
                lineItemTypes.forEach((lineItem) => {
                    if (lineItem.name === "TWIC_REQUIRED") {
                        lineItemTypeIds.push(lineItem.id);
                    }
                });
            }

            // TODO: depricate
            if (equipmentData.driverRequirements.includes('TEAM_DRIVING')) {
                load.hasTeamDriving = true;
                lineItemTypes.forEach((lineItem) => {
                    if (lineItem.name === "TEAM_DRIVING") {
                        lineItemTypeIds.push(lineItem.id);
                    }
                });
            } else {
                load.hasTeamDriving = false;
            }
        }

        // Get all the Line Item Types from Equipment Needs
        if (equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) {
            load.equipmentSpecifics = equipmentData.equipmentSpecifics;

            // TODO: depricate
            equipmentData.equipmentSpecifics.forEach((equipmentSpecific) => {
                lineItemTypes.forEach((lineItem) => {
                    if (lineItem.name === equipmentSpecific) {
                        lineItemTypeIds.push(lineItem.id);
                    }
                    if (lineItem.name === "CARRIER_WASHOUT") {
                        equipmentNeeds.hasCarrierWashout = true;
                    }
                });
            });
        }

        // TODO: depricate
        // Line Item Type Ids from Pricing Data but don't include stop fees in the line of line item types in equipment needs
        if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
            invoiceLineItems.forEach((invoiceLineItem) => {
                lineItemTypes.forEach((lineItem) => {
                    if (lineItem.name === invoiceLineItem.itemType && Enums.StopFees.doesNameExist(invoiceLineItem.itemType)) {
                        lineItemTypeIds.push(invoiceLineItem.id);
                    }
                });
            });
        }

        equipmentNeeds.lineItemTypeIds = [...new Set(lineItemTypeIds)];

        if (!isEmpty(equipmentNeeds)) {
            load.equipmentNeeds = equipmentNeeds;
        }
    }

    return load;
};

const transformInvoiceLineItemsRequest = (invoiceLineItemsData) => {
    let invoiceLineItems = [];

    invoiceLineItemsData.forEach(invoiceLineData => {
        let invoiceLineItem = {};

        if (invoiceLineData.description !== undefined && invoiceLineData.description !== null) {
            invoiceLineItem.description = invoiceLineData.description;
        }

        if (invoiceLineData.loadId !== undefined && invoiceLineData.loadId !== null) {
            invoiceLineItem.loadId = invoiceLineData.loadId;
        }

        if (invoiceLineData.stopId !== undefined && invoiceLineData.stopId !== null) {
            invoiceLineItem.stopId = invoiceLineData.stopId;
        }

        if (invoiceLineData.claimId !== undefined && invoiceLineData.claimId !== null) {
            invoiceLineItem.claimId = invoiceLineData.claimId;
        }

        if (invoiceLineData.itemType !== undefined && invoiceLineData.itemType !== null) {
            invoiceLineItem.itemType = invoiceLineData.itemType;
        }

        if (invoiceLineData.isIrisFee !== undefined && invoiceLineData.isIrisFee !== null) {
            invoiceLineItem.isIrisFee = invoiceLineData.isIrisFee;
        }

        if (invoiceLineData.isOpen !== undefined && invoiceLineData.isOpen !== null) {
            invoiceLineItem.isOpen = invoiceLineData.isOpen;
        }

        if (invoiceLineData.fromEntityType !== undefined && invoiceLineData.fromEntityType !== null) {
            invoiceLineItem.fromEntityType = invoiceLineData.fromEntityType;
        }

        if (invoiceLineData.fromEntityId !== undefined && invoiceLineData.fromEntityId !== null) {
            invoiceLineItem.fromEntityId = invoiceLineData.fromEntityId;
        }

        if (invoiceLineData.toEntityType !== undefined && invoiceLineData.toEntityType !== null) {
            invoiceLineItem.toEntityType = invoiceLineData.toEntityType;
        }

        if (invoiceLineData.toEntityId !== undefined && invoiceLineData.toEntityId !== null) {
            invoiceLineItem.toEntityId = invoiceLineData.toEntityId;
        }

        if (invoiceLineData.baseAmount !== undefined && invoiceLineData.baseAmount !== null) {
            invoiceLineItem.baseAmount = Number(invoiceLineData.baseAmount);
        }

        if (invoiceLineData.baseAmountUnit !== undefined && invoiceLineData.baseAmountUnit !== null) {
            invoiceLineItem.baseAmountUnit = invoiceLineData.baseAmountUnit;
        }

        if (invoiceLineData.quantity !== undefined && invoiceLineData.quantity !== null) {
            invoiceLineItem.quantity = Number(invoiceLineData.quantity);
        }

        if (invoiceLineData.quantityUnit !== undefined && invoiceLineData.quantityUnit !== null) {
            invoiceLineItem.quantityUnit = invoiceLineData.quantityUnit;
        }

        if (invoiceLineData.totalAmount !== undefined && invoiceLineData.totalAmount !== null) {
            invoiceLineItem.totalAmount = Number(invoiceLineData.totalAmount);
        }

        if (invoiceLineData.totalAmountUnit !== undefined && invoiceLineData.totalAmountUnit !== null) {
            invoiceLineItem.totalAmountUnit = invoiceLineData.totalAmountUnit;
        }

        if (invoiceLineData.status !== undefined && invoiceLineData.status !== null) {
            invoiceLineItem.status = invoiceLineData.status;
        }

        if (invoiceLineData.approvalStatus !== undefined && invoiceLineData.approvalStatus !== null) {
            invoiceLineItem.approvalStatus = invoiceLineData.approvalStatus;
        }

        invoiceLineItems.push(invoiceLineItem);
    });

    return invoiceLineItems;
};

//#endregion
