import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";

//#region Locations Functions

export const fetchLocationsStart = () => {
    return {
        type: actionTypes.FETCH_LOCATIONS_START
    }
};

export const fetchLocationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchLocationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_FAIL,
        payload: payload
    }
};

export const clearLocations = () => {
    return {
        type: actionTypes.CLEAR_LOCATIONS
    }
};

export const fetchLocationStart = () => {
    return {
        type: actionTypes.FETCH_LOCATION_START
    }
};

export const fetchLocationSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_SUCCESS,
        payload: payload
    }
};

export const fetchLocationFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOCATION_FAIL,
        payload: payload
    }
};

export const clearLocation = () => {
    return {
        type: actionTypes.CLEAR_LOCATION
    }
};

export const addLocationStart = () => {
    return {
        type: actionTypes.ADD_LOCATION_START
    }
};

export const addLocationSuccess = () => {
    return {
        type: actionTypes.ADD_LOCATION_SUCCESS
    }
};

export const addLocationFail = (payload) => {
    return {
        type: actionTypes.ADD_LOCATION_FAIL,
        payload: payload
    }
};

export const addLocationErrorClear = () => {
    return {
        type: actionTypes.ADD_LOCATION_ERROR_CLEAR
    }
};

export const addLocationLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOCATION_LOADING_CLEAR
    }
};

export const updateLocationStart = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_START
    }
};

export const updateLocationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_SUCCESS
    }
};

export const updateLocationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOCATION_FAIL,
        payload: payload
    }
};

export const updateLocationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_ERROR_CLEAR
    }
};

export const updateLocationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_LOADING_CLEAR
    }
};

export const cancelAddLocation = () => {
    return {
        type: actionTypes.CANCEL_ADD_LOCATION
    }
};

export const cancelUpdateLocation = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOCATION
    }
};

export const insertLocation = (payload) => {
    return {
        type: actionTypes.ADD_LOCATION,
        payload: payload
    }
};

export const changeLocation = (payload) => {
    return {
        type: actionTypes.UPDATE_LOCATION,
        payload: payload
    }
};

export const changeSingleLocation = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOCATION,
        payload: payload
    }
};

export const removeLocation = (payload) => {
    return {
        type: actionTypes.REMOVE_LOCATION,
        payload: payload
    }
};

//#endregion

//#region Locations Methods

export const fetchLocations = (payload) => {
    return async (dispatch, getState) => {
        const locationsPath = '/locations';
        try {
            dispatch(fetchLocationsStart());

            const state = getState();
            const locationsState = {...state.locations};
            let searchParams = {...locationsState.searchParams};
            let pagination = {...locationsState.pagination};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const locationsRes = await axiosAuthenticated.get(locationsPath, {params: {...searchParams}});
            if (locationsRes && locationsRes.status === 200) {
                const locations = locationsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                let transformedLocations = locations.map((item) => {
                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    // Get Commodities Info
                    let commoditiesList = [];
                    if (item.commodityIds !== undefined && item.commodityIds !== null && item.commodityIds.length > 0) {
                        for (let i = 0; i < item.commodityIds.length; i++) {
                            const commodityId = item.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    commoditiesList.push(commodity.name);
                                }
                            }
                        }
                    }

                    return {
                        ...item,
                        account: account,
                        commodities: commoditiesList,
                    };
                });

                // Read total count from server
                pagination.total = locationsRes.data.totalCount;
                pagination.current = locationsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLocationsSuccess({
                    records: transformedLocations,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLocationsFail({error: error.message}));
        }
    }
};

export const fetchLocation = (id) => {
    return async (dispatch, getState) => {
        const locationsPath = '/locations';
        try {
            dispatch(fetchLocationStart());

            const locationsRes = await axiosAuthenticated.get(locationsPath + `/${id}`);

            if (locationsRes && locationsRes.status === 200) {
                const location = locationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(location.accountId, accounts);

                // Get Commodities Info
                let commoditiesList = [];
                if (location.commodityIds !== undefined && location.commodityIds !== null && location.commodityIds.length > 0) {
                    for (let i = 0; i < location.commodityIds.length; i++) {
                        const commodityId = location.commodityIds[i];
                        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                            const commodity = commodities.find(i => i.id === commodityId);
                            if (commodity !== undefined && commodity !== null) {
                                commoditiesList.push(commodity.name);
                            }
                        }
                    }
                }

                const transformedLocation = {
                    ...location,
                    account: account,
                    commodities: commoditiesList,
                };

                dispatch(fetchLocationSuccess({record: transformedLocation}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLocationFail({error: error.message}));
        }
    }
};

export const addLocation = (payload) => {
    return async (dispatch, getState) => {
        const locationsPath = '/locations';
        try {
            dispatch(addLocationStart());

            if (payload.lumperFee !== undefined && payload.lumperFee !== null) {
                payload.lumperFee = Number(payload.lumperFee);
                if (payload.lumperFeeUnit === undefined || payload.lumperFeeUnit === null) {
                    payload.lumperFeeUnit = 'USD';
                }
            }

            const locationsRes = await axiosAuthenticated.post(locationsPath, {...payload});
            if (locationsRes && locationsRes.status === 201) {
                const location = locationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(location.accountId, accounts);

                // Get Commodities Info
                let commoditiesList = [];
                if (location.commodityIds !== undefined && location.commodityIds !== null && location.commodityIds.length > 0) {
                    for (let i = 0; i < location.commodityIds.length; i++) {
                        const commodityId = location.commodityIds[i];
                        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                            const commodity = commodities.find(i => i.id === commodityId);
                            if (commodity !== undefined && commodity !== null) {
                                commoditiesList.push(commodity.name);
                            }
                        }
                    }
                }

                let newLocation = {
                    ...location,
                    account: account,
                    commodities: commoditiesList
                };

                dispatch(insertLocation(newLocation));

                dispatch(addLocationSuccess());
                dispatch(addLocationLoadingClear());
                dispatch(addLocationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLocationFail({error: error.message}));
        }
    }
};

export const updateLocation = (locationId, payload) => {
    return async (dispatch, getState) => {
        const locationsPath = '/locations';
        try {
            dispatch(updateLocationStart());

            if (payload.lumperFee !== undefined && payload.lumperFee !== null) {
                payload.lumperFee = Number(payload.lumperFee);
                if (payload.lumperFeeUnit === undefined || payload.lumperFeeUnit === null) {
                    payload.lumperFeeUnit = 'USD';
                }
            }

            const locationsRes = await axiosAuthenticated.put(locationsPath + `/${locationId}`, {...payload});
            if (locationsRes && locationsRes.status === 200) {
                const location = locationsRes.data;

                if (location.isDeleted === true) {
                    dispatch(removeLocation(location));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];
                    const commodities = [...orchestratorState.commodities];

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(location.accountId, accounts);

                    // Get Commodities Info
                    let commoditiesList = [];
                    if (location.commodityIds !== undefined && location.commodityIds !== null && location.commodityIds.length > 0) {
                        for (let i = 0; i < location.commodityIds.length; i++) {
                            const commodityId = location.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    commoditiesList.push(commodity.name);
                                }
                            }
                        }
                    }

                    let updatedLocation = {
                        ...location,
                        account: account,
                        commodities: commoditiesList
                    };

                    dispatch(changeLocation(updatedLocation));
                    dispatch(changeSingleLocation(updatedLocation));
                }

                dispatch(updateLocationSuccess());
                dispatch(updateLocationLoadingClear());
                dispatch(updateLocationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLocationFail({error: error.message}));
        }
    }
};

//#endregion