import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, ExpandAltOutlined, LeftOutlined, RightOutlined, ShrinkOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Card, Timeline, Spin, Badge } from 'antd';
import classes from './LoadsList.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import { isEmpty } from 'lodash';
import ReactList from 'react-list';
import MomentDate from '../../shared/dateFormatter';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

const momentDate = new MomentDate();
const stringFormatter = new StringFormatter();

const LoadsList = props => {
    //#region props

    const shipperId = props.shipperId;
    const carrierId = props.carrierId;
    const driverId = props.driverId;
    const filter = props.filter;
    const iconToShow = props.icon;
    const filterTitle = props.title;
    const entityId = props.entityId;
    const entityType = props.entityType;
    const showCompletedDate = props.showCompletedDate !== undefined && props.showCompletedDate !== null && props.showCompletedDate === true ? true : false;
    const showStatus = props.showStatus !== undefined && props.showStatus !== null && props.showStatus === true ? true : false;
    const showStatusToStaff = props.showStatusToStaff !== undefined && props.showStatusToStaff !== null && props.showStatusToStaff === true ? true : false;
    const data = props.data !== undefined && props.data !== null ? props.data : [];
    const additionalIsLoading = props.additionalIsLoading !== undefined && props.additionalIsLoading !== null && props.additionalIsLoading === true ? true : false;
    const selectedRecord = props.selectedRecord;
    const setSelectedRecord = props.setSelectedRecord;
    const loadExtras = props.loadExtras;
    const openShowEntity = props.openShowEntity;
    const closeShowEntity = props.closeShowEntity;
    const toggleAssignCarrier = props.toggleAssignCarrier;
    const showEntity = props.showEntity;
    const listActions = props.listActions;
    const filterDrawer = props.filterDrawer;
    const getList = props.getList;
    const getMoreList = props.getMoreList;

    //#endregion
    //#region useRefs

    const scrollParentRef = useRef(null);

    //#endregion
    //#region useStates

    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [collapseCards, setCollapseCards] = useState(false);

    //#endregion
    //#region useDispatch

    const dispatch = useDispatch();

    //#endregion
    //#region listMethods

    const refreshList = () => {
        if (getList !== undefined && getList !== null) {
            getList();
        } else {
            if (filter !== undefined && filter !== null && entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
                let searchParams = {
                    page: 1,
                    size: 20,
                    sort: 'pickUpDateTime',
                    order: 'asc',
                    isDeleted: false,
                    eta: true
                };

                if (entityType === "DRIVER") {
                    searchParams.driverIds = entityId;
                } else if (entityType === "SHIPPER") {
                    searchParams.shipperId = entityId;
                } else if (entityType === "CARRIER") {
                    searchParams.assignedCarrierId = entityId;

                    if (driverId !== undefined && driverId !== null) {
                        searchParams.driverIds = driverId;
                    }
                } else if (entityType === "STAFF") {
                    if (shipperId !== undefined && shipperId !== null) {
                        searchParams.shipperId = shipperId;
                    }

                    if (carrierId !== undefined && carrierId !== null) {
                        searchParams.assignedCarrierId = carrierId;
                    }

                    if (driverId !== undefined && driverId !== null) {
                        searchParams.driverIds = driverId;
                    }
                }

                if (filter === 'carrier-dispatch') {
                    searchParams.loadStatus = 'BOOKED';
                } else if (filter === 'loadrequest') {
                    searchParams.loadStatus = 'PENDING_RATE_CONFIRMATION';
                } else if (filter === 'open') {
                    if (entityType === "DRIVER" || entityType === "CARRIER" || (carrierId !== undefined && carrierId !== null)) {
                        searchParams.loadStatus = 'BOOKED';
                    } else if (entityType === "SHIPPER" || entityType === "STAFF") {
                        searchParams.loadStatus = 'CREATED|PENDING|APPROVED|PENDING_RATE_CONFIRMATION';
                    }
                } else if (filter === 'booked') {
                    searchParams.loadStatus = (entityType === "CARRIER" || (carrierId !== null && carrierId !== undefined)) ? 'SCHEDULED' : 'BOOKED|SCHEDULED';
                } else if (filter === 'intransit') {
                    searchParams.loadStatus = 'IN_TRANSIT|AT_STOP';
                } else if (filter === 'completed') {
                    searchParams.loadStatus = 'COMPLETED|CLOSED|REOPENED';
                    searchParams.order = 'desc';
                    searchParams.sort = 'loadEndDateTime';
                }

                dispatch(actionCreators.fetchLoadList(filter, searchParams));
            }
        }
    };

    const retrieveMore = () => {
        //console.log(`Current Page: ${currentPage}, Total Pages: ${totalPages}`);
        if (currentPage + 1 <= totalPages && isLoading === false) {
            //console.log('get more');

            if (getMoreList !== undefined && getMoreList !== null) {
                getMoreList();
            } else {
                if (filter !== undefined && filter !== null && entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
                    let searchParams = {
                        page: currentPage + 1,
                        size: 20,
                        sort: 'pickUpDateTime',
                        order: 'asc',
                        isDeleted: false,
                        eta: true
                    };

                    if (entityType === "DRIVER") {
                        searchParams.driverIds = entityId;
                    } else if (entityType === "SHIPPER") {
                        searchParams.shipperId = entityId;
                    } else if (entityType === "CARRIER") {
                        searchParams.assignedCarrierId = entityId;

                        if (driverId !== undefined && driverId !== null) {
                            searchParams.driverIds = driverId;
                        }
                    } else if (entityType === "STAFF") {
                        if (shipperId !== undefined && shipperId !== null) {
                            searchParams.shipperId = shipperId;
                        }

                        if (carrierId !== undefined && carrierId !== null) {
                            searchParams.assignedCarrierId = carrierId;
                        }

                        if (driverId !== undefined && driverId !== null) {
                            searchParams.driverIds = driverId;
                        }
                    }

                    if (filter === 'carrier-dispatch') {
                        searchParams.loadStatus = 'BOOKED';
                    } else if (filter === 'loadrequest') {
                        searchParams.loadStatus = 'PENDING_RATE_CONFIRMATION';
                    } else if (filter === 'open') {
                        if (entityType === "DRIVER" || entityType === "CARRIER" || (carrierId !== undefined && carrierId !== null)) {
                            searchParams.loadStatus = 'BOOKED';
                        } else if (entityType === "SHIPPER" || entityType === "STAFF") {
                            searchParams.loadStatus = 'CREATED|PENDING|APPROVED|PENDING_RATE_CONFIRMATION';
                        }
                    } else if (filter === 'booked') {
                        searchParams.loadStatus = (entityType === "CARRIER" || (carrierId !== null && carrierId !== undefined)) ? 'SCHEDULED' : 'BOOKED|SCHEDULED';
                    } else if (filter === 'intransit') {
                        searchParams.loadStatus = 'IN_TRANSIT|AT_STOP';
                    } else if (filter === 'completed') {
                        searchParams.loadStatus = 'COMPLETED|CLOSED|REOPENED';
                        searchParams.order = 'desc';
                        searchParams.sort = 'loadEndDateTime';
                    }

                    dispatch(actionCreators.fetchMoreLoadList(filter, searchParams));
                }
            }
        } else {
            //console.log('no more items to get');
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        // console.log(data);
        // console.log(filter);
        if (data !== undefined && data !== null && !isEmpty(data) && filter !== undefined && filter !== null) {
            if (data[filter] !== undefined && data[filter] !== null && !isEmpty(data[filter])) {
                const copyOfLoadList = { ...data[filter] };
                // console.log(copyOfLoadList);
                if (copyOfLoadList.records !== undefined && copyOfLoadList.records !== null) {
                    // console.log(copyOfLoadList.records.length);
                    setFilteredData([...copyOfLoadList.records]);
                } else {
                    setFilteredData([]);
                }
                setTotalPages(copyOfLoadList.totalPages ? copyOfLoadList.totalPages : 1);
                setCurrentPage(copyOfLoadList.currentPage ? copyOfLoadList.currentPage : 1);
                setTotalCount(copyOfLoadList.totalCount ? copyOfLoadList.totalCount : 0);
                setIsLoading(copyOfLoadList.isLoading === true);
                setError(copyOfLoadList.error);
            } else {
                setFilteredData([]);
                setTotalPages(1);
                setCurrentPage(1);
                setTotalCount(0);
                setIsLoading(false);
                setError(null);
            }
        } else {
            setFilteredData([]);
            setTotalPages(1);
            setCurrentPage(1);
            setTotalCount(0);
            setIsLoading(false);
            setError(null);
        }
    }, [data, filter]);

    useEffect(() => {
        refreshList();
    }, [filter, entityType, entityId]);

    useMemo(() => {
        if (!isEmpty(selectedRecord)) {
            setCollapseCards(true);
        }
    }, [selectedRecord]);

    //#endregion
    //#region scroll methods

    const scrollToLeft = () => {
        if (scrollParentRef.current) {
            const scrollLeft = scrollParentRef.current.scrollLeft;
            // console.log(scrollLeft);
            // console.log(scrollLeft - 400 > 0 ? scrollLeft - 400 : 0);
            scrollParentRef.current.scrollTo({ left: scrollLeft - 400 > 0 ? scrollLeft - 400 : 0, behavior: 'smooth' });
        }
    };

    const scrollToRight = () => {
        if (scrollParentRef.current) {
            const scrollLeft = scrollParentRef.current.scrollLeft;
            const scrollWidth = scrollParentRef.current.scrollWidth;
            // console.log(scrollLeft);
            // console.log(scrollLeft + 400 > scrollWidth ? scrollWidth : scrollLeft + 400);
            scrollParentRef.current.scrollTo({ left: scrollLeft + 400, behavior: 'smooth' });
        }
    };

    const handleScroll = () => {
        if (scrollParentRef.current) {
            const right = scrollParentRef.current.scrollWidth;
            const scrollWidth = scrollParentRef.current.scrollWidth;
            const clientWidth = scrollParentRef.current.clientWidth;
            const scrollLeft = scrollParentRef.current.scrollLeft;
            const scrollRight = (scrollParentRef.current.scrollLeft + scrollParentRef.current.offsetWidth);
            const offsetWidth = scrollParentRef.current.offsetWidth;
            const offsetLeft = scrollParentRef.current.offsetLeft;
            // console.log(`scrollWidth: ${scrollWidth}`);
            // console.log(`offsetWidth: ${offsetWidth}`);
            // console.log(`clientWidth: ${clientWidth}`);
            // console.log(`offsetLeft: ${offsetLeft}`);
            // console.log(`scrollLeft: ${scrollLeft}`);
            // console.log(`scrollRight: ${scrollRight}`);

            // const widthPerItem = totalCount > 0 ? (scrollWidth / 10) : 0;
            // console.log(`widthPerItem: ${widthPerItem}`);
            // console.log(`right/scrollWidth: ${right}`);
            // console.log(`current scroll position/scrollLeft: ${scrollLeft}`);
            // if (scrollLeft === 0 && isLoading === false) {
            //     console.log('load more');
            //     retrieveMore();
            // }
            // console.log(`currentPage: ${currentPage}`);
            const threshold = (scrollWidth - 200);
            // console.log(`threshold: ${threshold}`);
            if ((scrollRight > threshold) && isLoading === false) {
                // console.log('reached right threshold');
                // console.log('load more');
                retrieveMore();
            }
        }
    };

    //#endregion
    //#region displays

    const getStopLocationDisplay = (stop) => {
        let stopLocationDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address && stop.stopLocation.address.city && stop.stopLocation.address.state) {
            stopLocationDisplay = `${stop.stopLocation.address.city}, ${stop.stopLocation.address.state}`;
        } else if (stop.stopLocation && stop.stopLocation.name) {
            stopLocationDisplay = `${stop.stopLocation.name}`;
        } else if (stop.stopType) {
            stopLocationDisplay = `${Enums.StopTypes.getValueByName(stop.stopType)}`;
        }

        return stopLocationDisplay;
    };

    const getAppointmentDetailsDisplay = (stop) => {
        let appointmentDetailsDisplay = null;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (startDateObj !== null && endDateObj !== null) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (apptDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (requestedDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${requestedDateObj.dateString}, TBD`;
            }
        }

        return appointmentDetailsDisplay;
    };

    const renderStop = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            return (
                <div className="stopDetails" key={`stop-details-${stop.id}`}>
                    <div><span className="locationDetails">{getStopLocationDisplay(stop)}</span></div>
                    <div><span className="apptDetails">{getAppointmentDetailsDisplay(stop)}</span></div>
                </div>
            );
        } else {
            return null;
        }
    };

    // render empty list component
    const renderError = (error) => {
        if (error !== undefined && error !== null) {
            return (
                <Card className={classes.emptyCard}>
                    <div>Error: {error}</div>
                </Card>
            );
        } else {
            return null;
        }
    };

    // render empty list component
    const renderEmptyList = () => {
        if (isLoading === false) {
            return (
                <div className={classes.emptyList}>
                    <div className={classes.emptyIconContainer}>{iconToShow}</div>
                    <div className={classes.emptyTextContainer}>No {filterTitle} Loads have been found.</div>
                </div>
            );
        } else {
            return (
                <div className={classes.emptyList}>
                    <div className={classes.emptyIconContainer}>{iconToShow}</div>
                    <div className={classes.emptyTextContainer}>Searching for {filterTitle} Loads.</div>
                </div>
            );
        }
    };

    // render card container
    const renderContainer = (items, ref) => {
        return (
            <div ref={ref}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || additionalIsLoading === true}>
                    {error !== null ? renderError(error) : ((items && items.length > 0) ? (
                        <>
                            {(listActions !== undefined && listActions !== null && isLoading === false && filterDrawer) ? filterDrawer : null}
                            {(listActions !== undefined && listActions !== null && isLoading === false) ? (
                                <div className={classes.buttonContainer}>
                                    {listActions}
                                </div>
                            ) : null}
                            <div id="left" className={classes.leftButtonContainer}>
                                {isLoading === false ? (
                                    <Button type="primary" className={classes.scrollButton} icon={<LeftOutlined style={{ fontSize: 24, lineHeight: '32px' }} />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); scrollToLeft(); }} />
                                ) : null}
                            </div>
                            <div id="infinite-scroll-container" className={classes.cardItemInfiniteScrollContainer} onScroll={handleScroll} ref={(ref) => scrollParentRef.current = ref}>
                                {items}
                            </div>
                            <div id="right" className={classes.rightButtonContainer}>
                                {isLoading === false ? (
                                    <Button type="primary" className={classes.scrollButton} icon={<RightOutlined style={{ fontSize: 24, lineHeight: '32px' }} />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); scrollToRight(); }} />
                                ) : null}
                            </div>
                        </>
                    ) : renderEmptyList()
                    )}
                </Spin>
            </div>
        );
    };

    const getLoadStatusDisplay = (status) => {
        if (entityType === 'STAFF') {
            return stringFormatter.toFormattedString("LoadDisplayStatus", status, null);
        } else if (entityType === 'SHIPPER') {
            return stringFormatter.toFormattedString("ShipperLoadDisplayStatus", status, null);
        } else if (entityType === 'CARRIER' || entityType === 'DRIVER') {
            return stringFormatter.toFormattedString("CarrierLoadDisplayStatus", status, null);
        } else {
            return null;
        }
    };

    const getBundleCount = (load) => {
        let dispatchedLoadStatuses = ['SCHEDULED', 'IN_TRANSIT', 'AT_STOP', 'COMPLETED', 'CLOSED', 'REOPENED', 'CANCELLED'];
        let remainingCount = 0;
        if (load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) {
            let totalCount = load.childLoadInitCount + 1; // adding one for the parent load
            let generatedChildLoadsCount = load.childLoadActualCount ? load.childLoadActualCount : 0;
            let dispatchedCount = 0;
            // console.log(`totalCount: ${totalCount}`);
            // if there are any child loads that have been dispatched already, include them in the dispatched count
            if (generatedChildLoadsCount > 0) {
                if (load.childLoads !== undefined && load.childLoads !== null && load.childLoads.length > 0) {
                    let dispatchedChildLoads = load.childLoads.filter(cl => dispatchedLoadStatuses.includes(cl.status));
                    dispatchedCount += dispatchedChildLoads.length;
                }
            }

            // if the parent load is already dispatched then include it in the dispatched count as well
            if (dispatchedLoadStatuses.includes(load.loadStatus)) {
                dispatchedCount += 1;
            }

            remainingCount = totalCount - dispatchedCount;
        }
        // console.log(remainingCount);
        return remainingCount;
    };

    const renderCard = (load) => {
        const pickUpDateObj = load.pickUpDateTime !== undefined && load.pickUpDateTime !== null && load.origin !== undefined && load.origin !== null ? new MomentDate().fromUtcToTimeZoneDateObject(load.pickUpDateTime, load.origin.timeZone) : null;


        let cardClassNames = [];
        if (load.id === selectedRecord.id && showEntity === true) {
            cardClassNames.push('selectedLoadsListCard');
        } else {
            cardClassNames.push('unselectedLoadsListCard');
        }

        if (collapseCards === true) {
            cardClassNames.push('collapsedListCard');
        }

        return (
            <Card
                size="small"
                bordered={true}
                onClick={(e) => { e.stopPropagation(); e.preventDefault(); if (selectedRecord.id === load.id) { setSelectedRecord({}); closeShowEntity(); } else { setSelectedRecord(load); openShowEntity(); } }}
                className={cardClassNames.join(' ')}
                hoverable={true}
            >
                <div className="title">{load.parentName ? `Load: ${load.parentName}` : (load.name ? `Load: ${load.name}` : `Load ID: ${load.irisId}`)}</div>
                {(entityType === 'STAFF' || (entityType === 'CARRIER' && load && load.shipper)) ? (<div className="subtitle"><FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: 8 }} />{load && load.shipper ? load.shipper.name : 'TBD'}</div>) : null}
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && load && load.assignedCarrier)) ? (<div className="subtitle"><FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: 8 }} />{load && load.assignedCarrier ? load.assignedCarrier.name : (load.status !== 'CANCELLED' && toggleAssignCarrier !== undefined && toggleAssignCarrier !== null ? (<a onClick={(e) => { e.stopPropagation(); e.preventDefault(); setSelectedRecord(load); toggleAssignCarrier(); }}>Assign Carrier</a>) : '')}</div>) : null}

                <div className={collapseCards === true ? classes.hidden : classes.loadDetails}>
                    {pickUpDateObj !== null ? (
                        <div className={classes.loadDate}>
                            <div className={classes.calendarDate}>
                                <div className={classes.monthContainer}><span>{pickUpDateObj.monthAbbr}</span></div>
                                <div className={classes.calendarContainer}><FontAwesomeIcon icon={faCalendar} className={classes.calendarIcon} /></div>
                                <div className={classes.dayContainer}><span>{pickUpDateObj.day}</span></div>
                            </div>
                        </div>
                    ) : null}
                    <div className={classes.loadRoute}>
                        <Timeline className="loadsListTimeline">
                            {(load.origin !== undefined && load.origin !== null && load.origin.stopLocation !== undefined && load.origin.stopLocation !== null) ? (
                                <Timeline.Item className="loadsListTimelineItem" color='#16588E'>
                                    {renderStop(load.origin)}
                                </Timeline.Item>
                            ) : null}
                            {(load.destination !== undefined && load.destination !== null && load.destination.stopLocation !== undefined && load.destination.stopLocation !== null) ? (
                                <Timeline.Item className="loadsListTimelineItem" color='#16588E'>
                                    {renderStop(load.destination)}
                                </Timeline.Item>
                            ) : null}
                        </Timeline>
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 0 }}><Button style={{ border: 0 }} type="link" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setCollapseCards(!collapseCards); }} icon={collapseCards ? <ExpandAltOutlined /> : <ShrinkOutlined />} /></div>
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>{loadExtras(load)}</div>
            </Card>
        );
    };

    // render card item
    const renderCardItem = (index, key) => {
        let cardContainerClassNames = [];
        cardContainerClassNames.push(classes.cardItemContainer);
        if (collapseCards === true) {
            cardContainerClassNames.push(classes.cardItemContainerCollapsed);
        }

        if (filteredData !== undefined && filteredData !== null && filteredData.length > 0) {
            let load = filteredData[index];
            if (load !== undefined && load !== null) {
                const completedDateObj = load.loadEndDateTime !== undefined && load.loadEndDateTime !== null && load.destination !== undefined && load.destination !== null ? new MomentDate().fromUtcToTimeZoneDateObject(load.loadEndDateTime, load.destination.timeZone) : null;
                if (load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) {
                    let remainingCount = getBundleCount(load);
                    if (remainingCount > 0) {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge count={`x${getBundleCount(load)}`} offset={[-20, 20]} style={{ backgroundColor: (load.id === selectedRecord.id && showEntity === true) ? '#334353' : '#FFFFFF', color: (load.id === selectedRecord.id && showEntity === true) ? '#FFFFFF' : '#000000', border: '2px solid #16588E', width: 65, height: 65, borderRadius: 50, lineHeight: '60px', fontSize: 20, fontWeight: 600 }} title="Loads Remaining to DispatchAdmin">
                                    {renderCard(load)}
                                </Badge>
                            </div>
                        );
                    } else if (showStatus === true || (entityType === 'STAFF' && showStatusToStaff === true) || (showCompletedDate === true && completedDateObj !== null)) {
                        let ribbonHeight = 24;
                        let ribbonContent = null;
                        if (showStatus === true || (entityType === 'STAFF' && showStatusToStaff === true)) {
                            if (showCompletedDate === true && completedDateObj !== null) {
                                ribbonHeight = 48;
                                ribbonContent = <span>{getLoadStatusDisplay(load.loadStatus)}<br />{completedDateObj.dateString}</span>
                            } else {
                                ribbonContent = (<span>{getLoadStatusDisplay(load.loadStatus)}</span>)
                            }
                        } else {
                            if (showCompletedDate === true && completedDateObj !== null) {
                                ribbonContent = <span>{completedDateObj.dateString}</span>
                            }
                        }

                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge.Ribbon text={ribbonContent} offset={[0, 0]} style={{ backgroundColor: '#16588E', fontSize: 14, height: ribbonHeight }}>
                                    {renderCard(load)}
                                </Badge.Ribbon>
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                {renderCard(load)}
                            </div>
                        );
                    }
                } else {
                    if (showStatus === true || (entityType === 'STAFF' && showStatusToStaff === true) || (showCompletedDate === true && completedDateObj !== null)) {
                        let ribbonHeight = 24;
                        let ribbonContent = null;
                        if (showStatus === true || (entityType === 'STAFF' && showStatusToStaff === true)) {
                            if (showCompletedDate === true && completedDateObj !== null) {
                                ribbonHeight = 48;
                                ribbonContent = <span>{getLoadStatusDisplay(load.loadStatus)}<br />{completedDateObj.dateString}</span>
                            } else {
                                ribbonContent = (<span>{getLoadStatusDisplay(load.loadStatus)}</span>)
                            }
                        } else {
                            if (showCompletedDate === true && completedDateObj !== null) {
                                ribbonContent = <span>{completedDateObj.dateString}</span>
                            }
                        }

                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                <Badge.Ribbon text={ribbonContent} offset={[0, 0]} style={{ backgroundColor: '#16588E', fontSize: 14, height: ribbonHeight }}>
                                    {renderCard(load)}
                                </Badge.Ribbon>
                            </div>
                        );
                    } else {
                        return (
                            <div key={key} className={cardContainerClassNames.join(' ')}>
                                {renderCard(load)}
                            </div>
                        );
                    }
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    //#endregion

    if ((filteredData !== undefined && filteredData !== null && filteredData.length > 0) || (isLoading === true && error === null) || additionalIsLoading === true) {
        return (
            <ReactList
                axis="x"
                itemRenderer={renderCardItem}
                itemsRenderer={renderContainer}
                length={totalCount}
                threshold={0}
                type="simple"
            />
        );
    } else if (filteredData.length === 0) {
        return renderEmptyList();
    } else {
        return renderError(error);
    }
};

export default withRouter(LoadsList);
