import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {omit} from "lodash";
import {addFile, deleteFile} from "../../api/fileClient";
import {updateLoad} from "./loads";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";

//#region Assets Functions

export const fetchAssetsStart = () => {
    return {
        type: actionTypes.FETCH_ASSETS_START
    }
};

export const fetchAssetsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ASSETS_SUCCESS,
        payload: payload
    }
};

export const fetchAssetsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ASSETS_FAIL,
        payload: payload
    }
};

export const clearAssets = () => {
    return {
        type: actionTypes.CLEAR_ASSETS
    }
};

export const fetchAssetStart = () => {
    return {
        type: actionTypes.FETCH_ASSET_START
    }
};

export const fetchAssetSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ASSET_SUCCESS,
        payload: payload
    }
};

export const fetchAssetFail = (payload) => {
    return {
        type: actionTypes.FETCH_ASSET_FAIL,
        payload: payload
    }
};

export const clearAsset = () => {
    return {
        type: actionTypes.CLEAR_ASSET
    }
};

export const addAssetStart = () => {
    return {
        type: actionTypes.ADD_ASSET_START
    }
};

export const addAssetSuccess = () => {
    return {
        type: actionTypes.ADD_ASSET_SUCCESS
    }
};

export const addAssetFail = (payload) => {
    return {
        type: actionTypes.ADD_ASSET_FAIL,
        payload: payload
    }
};

export const addAssetErrorClear = () => {
    return {
        type: actionTypes.ADD_ASSET_ERROR_CLEAR
    }
};

export const addAssetLoadingClear = () => {
    return {
        type: actionTypes.ADD_ASSET_LOADING_CLEAR
    }
};

export const updateAssetStart = () => {
    return {
        type: actionTypes.UPDATE_ASSET_START
    }
};

export const updateAssetSuccess = () => {
    return {
        type: actionTypes.UPDATE_ASSET_SUCCESS
    }
};

export const updateAssetFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ASSET_FAIL,
        payload: payload
    }
};

export const updateAssetErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ASSET_ERROR_CLEAR
    }
};

export const updateAssetLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ASSET_LOADING_CLEAR
    }
};

export const cancelAddAsset = () => {
    return {
        type: actionTypes.CANCEL_ADD_ASSET
    }
};

export const cancelUpdateAsset = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_ASSET
    }
};

export const insertAsset = (payload) => {
    return {
        type: actionTypes.ADD_ASSET,
        payload: payload
    }
};

export const changeAsset = (payload) => {
    return {
        type: actionTypes.UPDATE_ASSET,
        payload: payload
    }
};

export const changeSingleAsset = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ASSET,
        payload: payload
    }
};

export const removeAsset = (payload) => {
    return {
        type: actionTypes.REMOVE_ASSET,
        payload: payload
    }
};

//#endregion

//#region Assets Methods

export const fetchAssets = (payload) => {
    return async (dispatch, getState) => {
        const assetsPath = '/assets';

        try {
            dispatch(fetchAssetsStart());

            const state = getState();
            const assetsState = {...state.assets};
            let pagination = {...assetsState.pagination};
            let searchParams = {...assetsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const assetsRes = await axiosAuthenticated.get(assetsPath, {params: {...searchParams}});
            if (assetsRes && assetsRes.status === 200) {
                const assets = assetsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                let assetIds = assets.map(item => {
                    return item.id;
                });

                let assetDocuments = [];
                const assetDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [...assetIds]
                });
                if (assetDocumentsRes && assetDocumentsRes.status === 200) {
                    assetDocuments = assetDocumentsRes.data.data;
                }

                const transformedAssets = assets.map((item) => {

                    // Get Trailer Type Info
                    let trailerType = null;
                    if (item.trailerTypeId !== undefined && item.trailerTypeId !== null) {
                        trailerType = trailerTypes.find(i => i.id === item.trailerTypeId);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.carrierId, accounts);

                    // Get Files associated to this asset
                    let fileMetaData = [];
                    if (assetDocuments.length > 0) {
                        fileMetaData = assetDocuments.filter(document => document.entityId === item.id);
                    }

                    let {
                        photoFileId,
                        licensePlateFileId,
                        insuranceFileId,
                        registrationFileId
                    } = EntityUtils.getAssetFileIds(fileMetaData);

                    return {
                        ...item,
                        trailerType: trailerType,
                        documents: fileMetaData,
                        account: account,
                        photoFileId: photoFileId ? photoFileId : null,
                        licensePlateFileId: licensePlateFileId ? licensePlateFileId : null,
                        insuranceFileId: insuranceFileId ? insuranceFileId : null,
                        registrationFileId: registrationFileId ? registrationFileId : null
                    };
                });

                // Read total count from server
                pagination.total = assetsRes.data.totalCount;
                pagination.current = assetsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchAssetsSuccess({
                    records: transformedAssets,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAssetsFail({error: error.message}));
        }
    }
};

export const fetchAsset = (id) => {
    return async (dispatch, getState) => {
        const assetsPath = '/assets';
        try {
            dispatch(fetchAssetStart());

            const assetsRes = await axiosAuthenticated.get(assetsPath + `/${id}`);
            if (assetsRes && assetsRes.status === 200) {
                let asset = assetsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                let assetDocuments = [];
                const assetDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [asset.id]
                });
                if (assetDocumentsRes && assetDocumentsRes.status === 200) {
                    assetDocuments = assetDocumentsRes.data.data;
                }
                // Get Trailer Type Info
                let trailerType = null;
                if (asset.trailerTypeId !== undefined && asset.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === asset.trailerTypeId);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(asset.carrierId, accounts);

                // Get Files associated to this asset
                let fileMetaData = [];
                if (assetDocuments.length > 0) {
                    fileMetaData = assetDocuments.filter(document => document.entityId === asset.id);
                }

                let {
                    photoFileId,
                    licensePlateFileId,
                    insuranceFileId,
                    registrationFileId
                } = EntityUtils.getAssetFileIds(fileMetaData);

                const transformedAsset = {
                    ...asset,
                    trailerType: trailerType,
                    documents: fileMetaData,
                    account: account,
                    photoFileId: photoFileId ? photoFileId : null,
                    licensePlateFileId: licensePlateFileId ? licensePlateFileId : null,
                    insuranceFileId: insuranceFileId ? insuranceFileId : null,
                    registrationFileId: registrationFileId ? registrationFileId : null
                };

                dispatch(fetchAssetSuccess({record: transformedAsset}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAssetFail({error: error.message}));
        }
    }
};

export const addAsset = (payload) => {
    return async (dispatch, getState) => {
        const assetsPath = '/assets';
        try {
            dispatch(addAssetStart());

            const photoFile = payload.photoFile;
            const licensePlateFile = payload.licensePlateFile;
            const insuranceFile = payload.insuranceFile;
            const registrationFile = payload.registrationFile;

            transFormDataForRequest(payload);

            payload = omit(payload, ["photoFile", "licensePlateFile", "insuranceFile", "registrationFile"]);

            const assetsRes = await axiosAuthenticated.post(assetsPath, {...payload});
            if (assetsRes && assetsRes.status === 201) {
                const asset = assetsRes.data;

                addAssetFiles(assetsRes, photoFile, licensePlateFile, insuranceFile, registrationFile);

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                let assetDocuments = [];
                const assetDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [asset.id]
                });
                if (assetDocumentsRes && assetDocumentsRes.status === 200) {
                    assetDocuments = assetDocumentsRes.data.data;
                }

                // Get Trailer Type Info
                let trailerType = null;
                if (asset.trailerTypeId !== undefined && asset.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === asset.trailerTypeId);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(asset.carrierId, accounts);

                // Get Files associated to this asset
                let fileMetaData = [];
                if (assetDocuments.length > 0) {
                    fileMetaData = assetDocuments.filter(document => document.entityId === asset.id);
                }

                let {
                    photoFileId,
                    licensePlateFileId,
                    insuranceFileId,
                    registrationFileId
                } = EntityUtils.getAssetFileIds(fileMetaData);

                const newAsset = {
                    ...asset,
                    trailerType: trailerType,
                    documents: fileMetaData,
                    account: account,
                    photoFileId: photoFileId ? photoFileId : null,
                    licensePlateFileId: licensePlateFileId ? licensePlateFileId : null,
                    insuranceFileId: insuranceFileId ? insuranceFileId : null,
                    registrationFileId: registrationFileId ? registrationFileId : null
                };

                dispatch(insertAsset(newAsset));
                dispatch(addAssetSuccess());
                dispatch(addAssetLoadingClear());
                dispatch(addAssetErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAssetFail({error: error.message}));
        }
    }
};

export const addAssetAndAddToLoad = (payload, accountId, load) => {
    return async (dispatch, getState) => {
        const assetsPath = '/assets';
        try {
            dispatch(addAssetStart());

            transFormDataForRequest(payload);

            const assetsRes = await axiosAuthenticated.post(assetsPath, {
                ...payload,
                carrierId: accountId,
                isAvailable: true
            });
            if (assetsRes && assetsRes.status === 201) {
                const asset = assetsRes.data;
                const assetId = assetsRes.data.id;

                let assetIdList = load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0 ? [...load.assetIds] : [];

                if (assetIdList.length > 0) {
                    if (assetIdList.find(i => i === assetId) !== undefined) {
                        throw new Error("The asset you chose is already assigned to this load.");
                    }
                }

                let updatedAssetIds = [...assetIdList, assetId];

                // removes duplicates
                const updatedAssetIds2 = [...new Set(updatedAssetIds)];
                //console.log(updatedAssetIds2);
                let payload = {};
                payload.assetIds = updatedAssetIds2;

                dispatch(updateLoad(load.id, payload)); // this call re-fetches the load

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                let assetDocuments = [];
                const assetDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [asset.id]
                });
                if (assetDocumentsRes && assetDocumentsRes.status === 200) {
                    assetDocuments = assetDocumentsRes.data.data;
                }

                // Get Trailer Type Info
                let trailerType = null;
                if (asset.trailerTypeId !== undefined && asset.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === asset.trailerTypeId);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(asset.carrierId, accounts);

                // Get Files associated to this asset
                let fileMetaData = [];
                if (assetDocuments.length > 0) {
                    fileMetaData = assetDocuments.filter(document => document.entityId === asset.id);
                }

                let {
                    photoFileId,
                    licensePlateFileId,
                    insuranceFileId,
                    registrationFileId
                } = EntityUtils.getAssetFileIds(fileMetaData);

                const newAsset = {
                    ...asset,
                    trailerType: trailerType,
                    documents: fileMetaData,
                    account: account,
                    photoFileId: photoFileId ? photoFileId : null,
                    licensePlateFileId: licensePlateFileId ? licensePlateFileId : null,
                    insuranceFileId: insuranceFileId ? insuranceFileId : null,
                    registrationFileId: registrationFileId ? registrationFileId : null
                };

                dispatch(insertAsset(newAsset));

                dispatch(addAssetSuccess());
                dispatch(addAssetLoadingClear());
                dispatch(addAssetErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAssetFail({error: error.message}));
        }
    }
};

export const updateAsset = (assetId, payload) => {
    return async (dispatch, getState) => {
        const assetsPath = '/assets';
        try {
            dispatch(updateAssetStart());

            transFormDataForRequest(payload);

            payload = await updateAssetFiles(payload, assetId);

            const assetsRes = await axiosAuthenticated.put(assetsPath + `/${assetId}`, {...payload});
            if (assetsRes && assetsRes.status === 200) {
                const asset = assetsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const trailerTypes = [...orchestratorState.trailerTypes];
                const accounts = [...orchestratorState.accounts];

                let assetDocuments = [];
                const assetDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [asset.id]
                });
                if (assetDocumentsRes && assetDocumentsRes.status === 200) {
                    assetDocuments = assetDocumentsRes.data.data;
                }

                // Get Trailer Type Info
                let trailerType = null;
                if (asset.trailerTypeId !== undefined && asset.trailerTypeId !== null) {
                    trailerType = trailerTypes.find(i => i.id === asset.trailerTypeId);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(asset.carrierId, accounts);

                // Get Files associated to this asset
                let fileMetaData = [];
                if (assetDocuments.length > 0) {
                    fileMetaData = assetDocuments.filter(document => document.entityId === asset.id);
                }

                let {
                    photoFileId,
                    licensePlateFileId,
                    insuranceFileId,
                    registrationFileId
                } = EntityUtils.getAssetFileIds(fileMetaData);

                const updatedAsset = {
                    ...asset,
                    trailerType: trailerType,
                    documents: fileMetaData,
                    account: account,
                    photoFileId: photoFileId ? photoFileId : null,
                    licensePlateFileId: licensePlateFileId ? licensePlateFileId : null,
                    insuranceFileId: insuranceFileId ? insuranceFileId : null,
                    registrationFileId: registrationFileId ? registrationFileId : null
                };

                dispatch(changeAsset(updatedAsset));
                dispatch(changeSingleAsset(updatedAsset));

                dispatch(updateAssetSuccess());
                dispatch(updateAssetLoadingClear());
                dispatch(updateAssetErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAssetFail({error: error.message}));
        }
    }
};

//#endregion

//#region Helper Methods

const transFormDataForRequest = (payload) => {
    if (payload.range !== undefined && payload.range !== null) {
        payload.range = Number(payload.range);
    }

    if (payload.overallLength !== undefined && payload.overallLength !== null) {
        payload.overallLength = Number(payload.overallLength);
    }

    if (payload.fuelTankCapacity !== undefined && payload.fuelTankCapacity !== null) {
        payload.fuelTankCapacity = Number(payload.fuelTankCapacity);
    }

    if (payload.height !== undefined && payload.height !== null) {
        payload.height = Number(payload.height);
    }

    if (payload.length !== undefined && payload.length !== null) {
        payload.length = Number(payload.length);
    }

    if (payload.width !== undefined && payload.width !== null) {
        payload.width = Number(payload.width);
    }

    if (payload.weight !== undefined && payload.weight !== null) {
        payload.weight = Number(payload.weight);
    }

    if (payload.loadCapacity !== undefined && payload.loadCapacity !== null) {
        payload.loadCapacity = Number(payload.loadCapacity);
    }

    if (payload.maxLoadWeight !== undefined && payload.maxLoadWeight !== null) {
        payload.maxLoadWeight = Number(payload.maxLoadWeight);
    }

    if (payload.axles !== undefined && payload.axles !== null) {
        payload.axles = Number(payload.axles);
    }
};

const addAssetFiles = async (res, photoFile, licensePlateFile, insuranceFile, registrationFile) => {
    const entityId = res.data.id;

    if (photoFile !== undefined && photoFile !== null) {
        await addFile(photoFile, "ASSET", entityId, "ASSET_PHOTO", "Asset Photo", "", "ALL");
    }

    if (licensePlateFile !== undefined && licensePlateFile !== null) {
        await addFile(licensePlateFile, "ASSET", entityId, "ASSET_LICENSE_PLATE", "Asset License Plate", "", "ALL");
    }

    if (insuranceFile !== undefined && insuranceFile !== null) {
        await addFile(insuranceFile, "ASSET", entityId, "ASSET_INSURANCE", "Asset Insurance", "", "ALL");
    }

    if (registrationFile !== undefined && registrationFile !== null) {
        await addFile(registrationFile, "ASSET", entityId, "ASSET_REGISTRATION", "Asset Registration", "", "ALL");
    }
};

const updateAssetFiles = async (payload, entityId) => {
    const photoFile = payload.photoFile;
    const licensePlateFile = payload.licensePlateFile;
    const insuranceFile = payload.insuranceFile;
    const registrationFile = payload.registrationFile;
    const photoFileId = payload.photoFileId;
    const licensePlateFileId = payload.licensePlateFileId;
    const insuranceFileId = payload.insuranceFileId;
    const registrationFileId = payload.registrationFileId;

    if (photoFile) {
        await deleteFile(photoFileId);
        await addFile(photoFile, "ASSET", entityId, "ASSET_PHOTO", "Asset Photo", "", "ALL");
    }

    if (licensePlateFile) {
        await deleteFile(licensePlateFileId);
        await addFile(licensePlateFile, "ASSET", entityId, "ASSET_LICENSE_PLATE", "Asset License Plate", "", "ALL");
    }

    if (insuranceFile) {
        await deleteFile(insuranceFileId);
        await addFile(insuranceFile, "ASSET", entityId, "ASSET_INSURANCE", "Asset Insurance", "", "ALL");
    }

    if (registrationFile) {
        await deleteFile(registrationFileId);
        await addFile(registrationFile, "ASSET", entityId, "ASSET_REGISTRATION", "Asset Registration", "", "ALL");
    }

    return omit(payload, ["photoFile", "licensePlateFile", "insuranceFile", "registrationFile", "photoFileId", "licensePlateFileId", "insuranceFileId", "registrationFileId"]);
};

//#endregion