import React from 'react';
import classes from './MessageSent.module.scss';
import MomentDate from '../../shared/dateFormatter';

const MessageSent = props => {
    const message = props.message;

    console.log(message);
    /*
    read: [{readAt: "2023-01-09T16:59:48.7400+00:00", readBy: "94e859d1-01e8-4114-8c71-f28350c03335"}]
0: {readAt: "2023-01-09T16:59:48.7400+00:00", readBy: "94e859d1-01e8-4114-8c71-f28350c03335"}
readAt: "2023-01-09T16:59:48.7400+00:00"
readBy: "94e859d1-01e8-4114-8c71-f28350c03335"
     */
    return (
        <div className={classes.outgoingMessage} key={message.id}>
            <div className={classes.sentMessage}>
                <p>{message.message}</p>
                <span className={classes.timeDate}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(message.modifiedAt)}
                    {(!message.read[0] || !message.read[0].readAt) && (
                        <span
                            className={classes.unreadDot}
                        >
                    </span>
                    )}
                </span>

            </div>
        </div>
    );
};

export default MessageSent;
