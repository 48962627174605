import React, { useMemo, useState } from 'react';
import { Alert, Select, Spin, Drawer, Row, Col } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import NewClaim from '../../components/NewClaim/NewClaim';
import DataRow from '../DataRow/DataRow';
import Document from '../../components/Document/Document';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';

const { Option } = Select;

const ReviewDocument = props => {
    const fullWidth = global.window.innerWidth;

    const document = props.record;
    const documentType = props.documentType;
    const loadId = props.loadId;
    const entityType = props.entityType;
    const entitySubType = props.entitySubType;

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.documents.isRecordUpdateLoading);
    const error = useSelector(state => state.documents.updateRecordError);

    const [showClaimDrawer, setShowClaimDrawer] = useState(false);

    const closeClaimDrawer = () => {
        setShowClaimDrawer(false);
    };

    const methods = useForm();

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        cleanedData.isReviewed = true;
        cleanedData.entityId = document.entityId;
        cleanedData.entityType = document.entityType;

        if (!isEmpty(cleanedData)) {
            dispatch(actionCreators.updateDocument(document.id, cleanedData));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateDocument());
        props.cancel();
    };

    const getReviewStatuses = () => {
        let children = [];
        if (documentType !== undefined && documentType !== null) {
            if (entitySubType === 'PICK_UP') {
                // only show approved as an option for BOLs if they belong to a pick up stop
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else if (entitySubType === 'DROP_OFF') {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            }
        } else {
            children.push(<Option value="APPROVED" key="APPROVED">APPROVED</Option>);
        }

        return children;
    };

    const onReviewStatusChange = (value) => {
        if (value !== null && value !== undefined && loadId !== undefined && loadId !== null) {
            if (value === 'CLEAN' || value === 'APPROVED') {
                setShowClaimDrawer(false);
            } else if (value === 'OSDR') {
                setShowClaimDrawer(true);
            }
        } else {
            setShowClaimDrawer(false);
        }
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateDocumentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        {document && document.licenseNumber && <DataRow valueStyle={{ fontWeight: 'bold' }} title="License Number" value={document.licenseNumber} dataType="String" />}
                        {document && document.expiryDate && <DataRow valueStyle={{ fontWeight: 'bold' }} title="Expiration Date" value={document.expiryDate} dataType="MomentDate" />}
                        <FormItem {...formItemLayout} label="Review Status"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Review Status"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onReviewStatusChange(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {getReviewStatuses()}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="reviewStatus"
                        />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24} lg={24} xl={24}>
                                <Document fileId={props.fileId} />
                            </Col>
                        </Row>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Review Status for Document" />
                </Form>
            </FormProvider>
            <Drawer
                title="File Claim"
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                closable={false}
                onClose={closeClaimDrawer}
                visible={showClaimDrawer}
                destroyOnClose={true}
                maskClosable={false}
            >
                <NewClaim cancel={closeClaimDrawer} loadId={loadId} />
            </Drawer>
        </>
    );
};

export default ReviewDocument;