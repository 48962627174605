import React from 'react';

const InvoiceRow = props => {

    return (
            <tr>
                {props.dataRow}
            </tr>
    );
};

export default InvoiceRow;