import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Button, Card, Col, message, Row, Popconfirm, Collapse, Alert, Spin, Affix, Steps } from "antd";
import { withRouter } from "react-router";
import NewLoadSchedule from "../../components/NewLoadSchedule/NewLoadSchedule";
import NewLoadServices from "../../components/NewLoadServices/NewLoadServices";
import NewLoadStops from "../../components/NewLoadStops/NewLoadStops";
import NewLoadEquipment from "../../components/NewLoadEquipment/NewLoadEquipment";
import { isEmpty, sortBy } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from '../../components/DataRow/DataRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataCommodityListRow from '../../components/DataCommodityListRow/DataCommodityListRow';
import classes from './NewLoad.module.scss';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import DataDateTimeRangeRow from '../../components/DataDateTimeRangeRow/DataDateTimeRangeRow';
import MomentDate from '../../shared/dateFormatter';
import Form from '../../components/Form/Form';
import { removeEmpty } from '../../shared/objectUtils';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { faPallet, faRoute, faTruck, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../components/Header/Header';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import logger from '../../shared/logger';
import NewLoadReview from '../../components/NewLoadReview/NewLoadReview';

const momentDate = new MomentDate();

TrimbleMaps.APIKey = process.env.REACT_APP_TMS_API_KEY;

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();

const lon = -77.0365, lat = 38.8977, zoom = 8;

const NewLoad = props => {
    //#region props

    const shipperIdValue = props.location && props.location.state && props.location.state.shipperId ? props.location.state.shipperId : null;
    const loadLaneId = props.location && props.location.state && props.location.state.loadLaneId ? props.location.state.loadLaneId : null;
    const linkedLoadLaneId = props.location && props.location.state && props.location.state.linkedLoadLaneId ? props.location.state.linkedLoadLaneId : null;
    const duplicateLoadId = props.location && props.location.state && props.location.state.duplicateLoadId ? props.location.state.duplicateLoadId : null;
    const bulkCount = props.location && props.location.state && props.location.state.bulkCount ? props.location.state.bulkCount : 1;
    const isRecurringLoad = props.location && props.location.state && props.location.state.isRecurringLoad ? props.location.state.isRecurringLoad : false;
    const repeatDaysCount = props.location && props.location.state && props.location.state.repeatDaysCount ? props.location.state.repeatDaysCount : 1;

    console.log("bulkCount", bulkCount, isRecurringLoad, repeatDaysCount);

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useRefs

    const mapRef = useRef(null);
    const routingLayer = useRef(null);
    const stopLayerGroupRef = useRef(null);

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordAddLoading);
    const error = useSelector(state => state.loads.addRecordError);
    const addRecordId = useSelector(state => state.loads.addRecordId);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const yourAccountUsers = useSelector(state => state.yourAccountUsers.records);
    const services = useSelector(state => state.orchestrator.services);

    //#endregion
    //#region useStates

    const [completedSteps, setCompletedSteps] = useState({ schedule: false, stops: false, equipment: false, services: false });
    const [isStepsLoading, setIsStepsLoading] = useState(false);
    const [current, setCurrent] = useState('schedule');
    const [currentStepNumber, setCurrentStepNumber] = useState(0);
    const [shipperData, setShipperData] = useState({});
    const [equipmentData, setEquipmentData] = useState({});
    const [pickUpStops, setPickUpStops] = useState([]);
    const [dropOffStops, setDropOffStops] = useState([]);
    const [servicesData, setServicesData] = useState({});
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [docFiles, setDocFiles] = useState([]);
    const [practicalRouteMiles, setPracticalRouteMiles] = useState(null);
    const [practicalRouteMinutes, setPracticalRouteMinutes] = useState(null);
    const [carrier, setCarrier] = useState(null);
    const [isMapReady, setIsMapReady] = useState(false);
    const [hideLoadSpecificInformation, setHideLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet
    const [dontRequireLoadSpecificInformation, setDontRequireLoadSpecificInformation] = useState(null); // use null as 3rd/initial state so we know if the value has been set yet
    const [loadLane, setLoadLane] = useState({});

    //#endregion
    //#region fetch async methods

    const getLoadWithStops = async (loadId) => {
        let existingLoad = null;
        try {
            const loadRes = await axiosAuthenticated.get(`/orch/loads/${loadId}`);
            const loadStopsRes = await axiosAuthenticated.get('/stops', { params: { page: 1, size: 100000, sort: 'sequence', order: 'asc', loadId: loadId, isDeleted: false } });

            if (loadRes && loadRes.status === 200 && loadStopsRes && loadStopsRes.status === 200) {
                existingLoad = loadRes.data;
                const loadStops = loadStopsRes.data.data;
                const locationIds = loadStops.map((item) => { return item.stopLocationId; });
                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] });
                    if (locationsRes && locationsRes.status === 200) {
                        const locations = locationsRes.data.data;
                        const transformedLoadStops = loadStops.map((loadStop) => {
                            let location = null;
                            if (loadStop.stopLocationId !== undefined && loadStop.stopLocationId !== null) {
                                const locationObj = locations.find(i => i.id === loadStop.stopLocationId);
                                if (locationObj !== undefined && locationObj !== null) {
                                    location = { ...locationObj };
                                }
                            }

                            return {
                                ...loadStop,
                                stopLocation: location
                            };
                        });

                        existingLoad.stops = transformedLoadStops;
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

        return existingLoad;
    };

    const getLoadLane = async (existingLoadLaneId) => {
        let existingLoadLane = null;
        try {
            const loadLanesRes = await axiosAuthenticated.get(`/loadLanes/${existingLoadLaneId}`);
            if (loadLanesRes && loadLanesRes.status === 200) {
                const loadLane = loadLanesRes.data;
                let locationIds = [];
                if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                    locationIds = loadLane.locations.map((item) => {
                        return item.locationId;
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] });
                    if (locationsRes && locationsRes.status === 200) {
                        const locations = locationsRes.data.data;

                        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                            const loadLaneLocations = loadLane.locations.map((loadLanelocation) => {
                                let locationLocation = null;
                                if (loadLanelocation.locationId !== undefined && loadLanelocation.locationId !== null) {
                                    let locationObj = locations.find(i => i.id === loadLanelocation.locationId);
                                    if (locationObj !== undefined && locationObj !== null) {
                                        locationLocation = { ...locationObj };
                                    }
                                }

                                return {
                                    ...loadLanelocation,
                                    location: locationLocation
                                };
                            });

                            existingLoadLane = {
                                ...loadLane,
                                locations: loadLaneLocations
                            };
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

        return existingLoadLane;
    };

    const getLinkedLoadLane = async (existingLinkedLoadLaneId) => {
        let existingLinkedLoadLane = null;
        try {
            const linkedLoadLanesRes = await axiosAuthenticated.get(`/linkedLoadLanes/${existingLinkedLoadLaneId}`);
            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
                const linkedLoadLane = linkedLoadLanesRes.data;
                let locationIds = [];
                if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                    locationIds = linkedLoadLane.locations.map((item) => {
                        return item.locationId;
                    });
                }

                if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                    const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] });
                    if (locationsRes && locationsRes.status === 200) {
                        const locations = locationsRes.data.data;

                        if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                            const loadLaneLocations = linkedLoadLane.locations.map((loadLanelocation) => {
                                let locationLocation = null;
                                if (loadLanelocation.locationId !== undefined && loadLanelocation.locationId !== null) {
                                    let locationObj = locations.find(i => i.id === loadLanelocation.locationId);
                                    if (locationObj !== undefined && locationObj !== null) {
                                        locationLocation = { ...locationObj };
                                    }
                                }

                                return {
                                    ...loadLanelocation,
                                    location: locationLocation
                                };
                            });

                            existingLinkedLoadLane = {
                                ...linkedLoadLane,
                                locations: loadLaneLocations
                            };
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }

        return existingLinkedLoadLane;
    };

    //#endregion
    //#region map functions

    const addStopMarker = (markerIcon, stopId, stop, latitude, longitude) => {
        if (mapRef.current !== null) {
            if (stopLayerGroupRef.current !== undefined && stopLayerGroupRef.current !== null) {
                stopLayerGroupRef.current.features.push({
                    type: 'Feature',
                    properties: {
                        name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                        stopId: stopId,
                        stopType: stop.stopType,
                        iconSize: [30, 30],
                        icon: markerIcon
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude]
                    }
                });
            } else {
                let geoJsonStopData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                            stopId: stopId,
                            stopType: stop.stopType,
                            iconSize: [30, 30],
                            icon: markerIcon
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude]
                        }
                    }]
                };

                stopLayerGroupRef.current = geoJsonStopData;
            }

            const stops = mapRef.current.getSource('stops');
            if (stops === undefined) {
                mapRef.current.addSource('stops', {
                    type: 'geojson',
                    data: stopLayerGroupRef.current
                });

                mapRef.current.addLayer({
                    id: 'stopsLayer',
                    type: 'symbol',
                    source: 'stops',
                    minzoom: 0,
                    maxzoom: 24,
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 0.5,
                        'icon-allow-overlap': true
                    }
                });
            } else {
                stops.setData(stopLayerGroupRef.current);
            }
        }
    };

    //#endregion
    //#region submits, cancels

    const cancelCancel = () => {
        return;
    };

    const confirmCancel = () => {
        dispatch(actionCreators.cancelAddLoad());
        props.history.goBack();
        message.error('Load Creation Cancelled');
    };

    const done = (newLoadId = null) => {
        if (entityType === 'STAFF') {
            if (newLoadId !== undefined && newLoadId !== null && bulkCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                props.history.goBack();
            }
        } else if (entityType === 'SHIPPER') {
            if (newLoadId !== undefined && newLoadId !== null && bulkCount === 1) {
                props.history.push({ pathname: "/loads/" + newLoadId });
            } else {
                props.history.goBack();
            }
        } else if (entityType === 'CARRIER') {
            props.history.goBack();
        } else {
            props.history.goBack();
        }

        message.success('Successfully Created Load!');
        dispatch(actionCreators.cancelAddLoad());
    };

    const generateTMSInvoiceLineItem = (accountEntityId, accountEntityType) => {
        let newInvoiceLineItem = null;
        // if the serviceType is TMS then add the service fees
        if (lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0 &&
            services !== undefined && services !== null && services.length > 0 &&
            accounts !== undefined && accounts !== null && accounts.length > 0 &&
            practicalRouteMiles !== undefined && practicalRouteMiles !== null) {
            let lineItemType = lineItemTypes.find(j => j.name === 'TMS_SERVICE_FEE');
            let tmsService = services.find(j => j.serviceType === 'LOAD' && j.loadServiceType === 'TMS'); // get the default tms service
            let account = accounts.find(j => j.id === accountEntityId);
            if (lineItemType !== null && lineItemType !== undefined &&
                tmsService !== undefined && tmsService !== null &&
                account !== undefined && account !== null) {
                newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'TMS_SERVICE_FEE',
                    isIrisFee: true,
                    fromEntityType: accountEntityType,
                    fromEntityId: accountEntityId,
                    toEntityType: "STAFF",
                    status: "PENDING",
                    approvalStatus: "APPROVED",
                    isOpen: true
                };

                if (tmsService.billingFrequency === 'PER_LOAD') {
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.baseAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.totalAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else if (tmsService.billingFrequency === 'PER_MILE') {
                    let baseAmount = Number(tmsService.priceAmount);
                    let quantity = Number(practicalRouteMiles);
                    let totalAmount = baseAmount * quantity;

                    newInvoiceLineItem.baseAmount = Number(baseAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = quantity;
                    newInvoiceLineItem.quantityUnit = 'PER_MILE';
                    newInvoiceLineItem.totalAmount = totalAmount;
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else {
                    newInvoiceLineItem.baseAmount = Number(0);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.totalAmount = Number(0);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                }

                // check to see if pricing for service should be over-riden
                let accountTMSService = null;
                if (account.accountServices !== undefined && account.accountServices !== null && account.accountServices.length > 0) {
                    accountTMSService = account.accountServices.find(j => j.serviceName === tmsService.name);
                    if (accountTMSService !== undefined && accountTMSService !== null) {
                        if (accountTMSService.isPriceOverride === true) {
                            if (accountTMSService.billingFrequency === 'PER_LOAD') {
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.baseAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.totalAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else if (accountTMSService.billingFrequency === 'PER_MILE') {
                                let baseAmount = Number(accountTMSService.priceAmount);
                                let quantity = Number(practicalRouteMiles);
                                let totalAmount = baseAmount * quantity;

                                newInvoiceLineItem.baseAmount = Number(baseAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = quantity;
                                newInvoiceLineItem.quantityUnit = 'PER_MILE';
                                newInvoiceLineItem.totalAmount = totalAmount;
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else {
                                newInvoiceLineItem.baseAmount = Number(0);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.totalAmount = Number(0);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            }
                        }
                    }
                }
            }
        }

        return newInvoiceLineItem;
    };

    const onSubmit = () => {
        let loadPayload = {
            unitOfMeasure: "ENGLISH",
            equipmentNeeds: {}
        };
        let stopsPayload = [];
        let invoiceLineItemsPayload = [];

        if (practicalRouteMiles !== undefined && practicalRouteMiles !== null) {
            loadPayload.practicalDistance = practicalRouteMiles;
            loadPayload.practicalDistanceUnit = "MI";
        }

        if (shipperData !== undefined && shipperData !== null && shipperData.shipperId !== undefined && shipperData.shipperId !== null) {
            loadPayload.shipperId = shipperData.shipperId;
        }

        loadPayload.createdByEntityType = entityType;

        if (entityType === 'CARRIER' && carrier !== undefined && carrier !== null) {
            loadPayload.assignedCarrierId = carrier.id;
        } else if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            if (servicesData.assignedCarrierId !== undefined && servicesData.assignedCarrierId !== null) {
                loadPayload.assignedCarrierId = servicesData.assignedCarrierId;
            }
        }

        loadPayload.isRecurringLoad = !!isRecurringLoad;
        if (!!isRecurringLoad) {
            loadPayload.repeatCount = repeatDaysCount - 1;
        } else {
            loadPayload.repeatCount = 0;
        }

        if (bulkCount > 1) {
            loadPayload.childLoadInitCount = bulkCount + (!!isRecurringLoad ? 0 : - 1);
        } else if (!!isRecurringLoad) {
            loadPayload.childLoadInitCount = 1;
        }

        // TODO: depricate
        loadPayload.hasTeamDriving = false; // set default value to false in case the driver requirement wasn't selected

        // TODO: depricate
        loadPayload.equipmentNeeds.hasCarrierWashout = false; // set default value to false in case the lineItemType wasn't selected

        let stopsArray = [];
        if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            pickUpStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });

            dropOffStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });
        }

        if (stopsArray !== undefined && stopsArray !== null && stopsArray.length > 1) {
            methods.clearErrors('stops');
            let origin = stopsArray.find(stop => stop.sequence === 0 && stop.stopType === "PICK_UP");
            if (origin !== undefined && origin !== null) {
                if (origin.apptWindowStartDateTime !== undefined && origin.apptWindowStartDateTime !== null) {
                    let momentDate = origin.apptWindowStartDateTime;
                    let timeZone = origin.stopLocation.timeZone;
                    loadPayload.pickUpDateTime = new MomentDate().asTimeZoneUtcISOString(momentDate, timeZone);
                } else if (origin.apptDateTime !== undefined) {
                    let momentDate = origin.apptDateTime;
                    let timeZone = origin.stopLocation.timeZone;
                    loadPayload.pickUpDateTime = new MomentDate().asTimeZoneUtcISOString(momentDate, timeZone);
                } else if (origin.requestedDateTime !== undefined) {
                    let momentDate = origin.requestedDateTime;
                    let timeZone = origin.stopLocation.timeZone;
                    loadPayload.pickUpDateTime = new MomentDate().asTimeZoneUtcISOString(momentDate, timeZone);
                }
            } else {
                methods.setError('stops', { type: 'required', message: 'Cannot create a load without an origin stop.' });
                return;
            }
        } else {
            methods.setError('stops', { type: 'required', message: 'Cannot create a load without at least 2 stops.' });
            return;
        }

        if (equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) {
            let lineItemTypeIds = [];

            if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                loadPayload.equipmentNeeds.trailerTypeId = equipmentData.trailerTypeId;
            }

            if (equipmentData.trailerLength !== undefined && equipmentData.trailerLength !== null) {
                loadPayload.equipmentNeeds.trailerLength = Number(equipmentData.trailerLength);
                loadPayload.equipmentNeeds.trailerLengthUnit = "FT";
            }

            if (equipmentData.trailerWeightCapacity !== undefined && equipmentData.trailerWeightCapacity !== null) {
                loadPayload.equipmentNeeds.trailerWeightCapacity = Number(equipmentData.trailerWeightCapacity);
                loadPayload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
            }

            if (equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null) {
                loadPayload.equipmentNeeds.preCoolReefer = Number(equipmentData.preCoolReefer);
                loadPayload.equipmentNeeds.preCoolReeferUnit = "F";
            }

            if (equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null) {
                loadPayload.equipmentNeeds.minRunTemp = Number(equipmentData.minRunTemp);
                loadPayload.equipmentNeeds.minRunTempUnit = "F";
            }

            if (equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null) {
                loadPayload.equipmentNeeds.maxRunTemp = Number(equipmentData.maxRunTemp);
                loadPayload.equipmentNeeds.maxRunTempUnit = "F";
            }

            if (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) {
                loadPayload.driverRequirements = equipmentData.driverRequirements;

                // TODO: depricate
                if (equipmentData.driverRequirements.includes('TWIC_REQUIRED')) {
                    lineItemTypes.forEach((lineItem) => {
                        if (lineItem.name === "TWIC_REQUIRED") {
                            lineItemTypeIds.push(lineItem.id);
                        }
                    });
                }

                // TODO: depricate
                if (equipmentData.driverRequirements.includes('TEAM_DRIVING')) {
                    loadPayload.hasTeamDriving = true;
                    lineItemTypes.forEach((lineItem) => {
                        if (lineItem.name === "TEAM_DRIVING") {
                            lineItemTypeIds.push(lineItem.id);
                        }
                    });
                } else {
                    loadPayload.hasTeamDriving = false;
                }
            }

            // Get all the Line Item Types from Equipment Needs
            if (equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) {
                loadPayload.equipmentSpecifics = equipmentData.equipmentSpecifics;

                // TODO: depricate
                equipmentData.equipmentSpecifics.forEach((equipmentSpecific) => {
                    lineItemTypes.forEach((lineItem) => {
                        if (lineItem.name === equipmentSpecific) {
                            lineItemTypeIds.push(lineItem.id);
                        }
                        if (lineItem.name === "CARRIER_WASHOUT") {
                            loadPayload.equipmentNeeds.hasCarrierWashout = true;
                        }
                    });
                });
            }

            // TODO: depricate
            // Line Item Type Ids from Pricing Data but don't include stop fees in the line of line item types in equipment needs
            if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                invoiceLineItems.forEach((invoiceLineItem) => {
                    lineItemTypes.forEach((lineItem) => {
                        if (lineItem.name === invoiceLineItem.itemType && !Enums.StopFees.doesNameExist(invoiceLineItem.itemType)) {
                            lineItemTypeIds.push(lineItem.id);
                        }
                    });
                });
            }

            loadPayload.equipmentNeeds.lineItemTypeIds = [...new Set(lineItemTypeIds)];
        }

        if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            if (servicesData.customerOrderNumber !== undefined && servicesData.customerOrderNumber !== null) {
                loadPayload.customerOrderNumber = servicesData.customerOrderNumber;
            }

            if (servicesData.parentName !== undefined && servicesData.parentName !== null) {
                loadPayload.parentName = servicesData.parentName;
            }

            if (servicesData.serviceType !== undefined && servicesData.serviceType !== null) {
                loadPayload.serviceType = servicesData.serviceType;

                if (servicesData.serviceType === 'TMS') {
                    let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
                    if (tmsInvoiceLineItem !== undefined && tmsInvoiceLineItem !== null) {
                        invoiceLineItemsPayload.push(tmsInvoiceLineItem);
                    }
                }
            }

            if (servicesData.shipperRatePerMile !== undefined && servicesData.shipperRatePerMile !== null) {
                loadPayload.shipperRatePerMile = servicesData.shipperRatePerMile;
                loadPayload.shipperRatePerMileUnit = 'USD';
            }
            if (servicesData.shipperAmount !== undefined && servicesData.shipperAmount !== null) {
                loadPayload.shipperAmount = servicesData.shipperAmount;
                loadPayload.shipperAmountUnit = 'USD';
                loadPayload.shipperBalance = servicesData.shipperAmount;
                loadPayload.shipperBalanceUnit = 'USD';
            }
            if (servicesData.carrierRatePerMile !== undefined && servicesData.carrierRatePerMile !== null) {
                loadPayload.carrierRatePerMile = servicesData.carrierRatePerMile;
                loadPayload.carrierRatePerMileUnit = 'USD';
            }
            if (servicesData.carrierAmount !== undefined && servicesData.carrierAmount !== null) {
                loadPayload.carrierAmount = servicesData.carrierAmount;
                loadPayload.carrierAmountUnit = 'USD';
                loadPayload.carrierBalance = servicesData.carrierAmount;
                loadPayload.carrierBalanceUnit = 'USD';
            }
        }

        if (stopsArray !== undefined && stopsArray !== null && stopsArray.length > 0) {
            stopsArray.forEach((stop, index) => {
                if (stop.stopLocation !== undefined && stop.stopLocation !== null) {
                    let newStop = {
                        unitOfMeasure: "ENGLISH",
                        latitude: stop.stopLocation.latitude,
                        longitude: stop.stopLocation.longitude,
                        timeZone: stop.stopLocation.timeZone,
                        stopType: stop.stopType,
                        sequence: index,
                        stopLocationId: stop.stopLocationId,
                        hasDriverAssist: stop.hasDriverAssist,
                        hasLumperFee: stop.hasLumperFee,
                        bolNumber: stop.bolNumber,
                        apptType: stop.apptType,
                        loadingType: stop.loadingType,
                        apptWindowStartDateTime: momentDate.getCurrentDateTimeInRange(),
                        apptWindowEndDateTime: momentDate.getCurrentDateTimeInRange(),
                    };

                    let timeZone = stop.stopLocation.timeZone;

                    if (stop.hasLumperFee === true && stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0) {
                        newStop.lumperFee = Number(stop.lumperFee);
                        newStop.lumperFeeUnit = 'USD';
                    } else {
                        newStop.lumperFee = 0;
                        newStop.lumperFeeUnit = 'USD';
                    }

                    if (stop.hasDriverAssist === true && stop.driverAssist !== undefined && stop.driverAssist !== null && stop.driverAssist > 0) {
                        newStop.driverAssist = Number(stop.driverAssist);
                        newStop.driverAssistUnit = 'USD';
                    } else {
                        newStop.driverAssist = 0;
                        newStop.driverAssistUnit = 'USD';
                    }

                    if (stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null) {
                        newStop.purchaseOrderNumber = stop.purchaseOrderNumber;
                    }

                    if (stop.pickUpNumber !== undefined && stop.pickUpNumber !== null) {
                        newStop.pickUpNumber = stop.pickUpNumber;
                    }

                    if (stop.dropOffNumber !== undefined && stop.dropOffNumber !== null) {
                        newStop.dropOffNumber = stop.dropOffNumber;
                    }

                    if (stop.specialInstructions !== undefined && stop.specialInstructions !== null) {
                        newStop.specialInstructions = stop.specialInstructions;
                    }

                    if (stop.apptInstructions !== undefined && stop.apptInstructions !== null) {
                        newStop.apptInstructions = stop.apptInstructions;
                    }

                    if (stop.apptNumber !== undefined && stop.apptNumber !== null) {
                        newStop.apptNumber = stop.apptNumber;
                    }

                    if (stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null) {
                        newStop.apptWindowStartDateTime = new MomentDate().asTimeZoneUtcISOString(stop.apptWindowStartDateTime, timeZone);
                    }

                    if (stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null) {
                        newStop.apptWindowEndDateTime = new MomentDate().asTimeZoneUtcISOString(stop.apptWindowEndDateTime, timeZone);
                    }

                    if (stop.requestedDateTime !== undefined && stop.requestedDateTime !== null) {
                        newStop.requestedDateTime = new MomentDate().asTimeZoneUtcISOString(stop.requestedDateTime, timeZone);
                    }

                    if (stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null) {
                        newStop.apptCallAheadDateTime = new MomentDate().asTimeZoneUtcISOString(stop.apptCallAheadDateTime, timeZone);
                    }

                    if (stop.apptDateTime !== undefined && stop.apptDateTime !== null) {
                        newStop.apptDateTime = new MomentDate().asTimeZoneUtcISOString(stop.apptDateTime, timeZone);
                    }

                    if (stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null && !isEmpty(stop.apptPointOfContact)) {
                        newStop.apptPointOfContact = stop.apptPointOfContact;
                    }

                    if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            let newCommodity = {
                                commodityId: commodity.commodityId,
                                name: commodity.name,
                                unitPackaging: commodity.unitPackaging,
                                unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                            };

                            if (commodity.bulkPackaging !== undefined && commodity.bulkPackaging !== null) {
                                newCommodity.bulkPackaging = commodity.bulkPackaging;
                                newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                            }

                            newCommodities.push(newCommodity);
                        });

                        if (!isEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    stopsPayload.push({ ...newStop });
                }
            });
        }

        if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
            invoiceLineItems.forEach((invoiceLineItem) => {
                let newInvoiceLineItem = {
                    description: invoiceLineItem.description,
                    itemType: invoiceLineItem.itemType,
                    isIrisFee: invoiceLineItem.isIrisFee
                };

                if (invoiceLineItem.stopId !== undefined && invoiceLineItem.stopId !== null) {
                    newInvoiceLineItem.stopId = invoiceLineItem.stopId;
                }

                if (invoiceLineItem.fromEntityType !== undefined && invoiceLineItem.fromEntityType !== null) {
                    newInvoiceLineItem.fromEntityType = invoiceLineItem.fromEntityType;
                    if (invoiceLineItem.fromEntityType === 'SHIPPER') {
                        if (loadPayload.shipperId !== undefined && loadPayload.shipperId !== null) {
                            newInvoiceLineItem.fromEntityId = loadPayload.shipperId;
                        }
                    } else if (invoiceLineItem.fromEntityType === 'CARRIER') {
                        if (loadPayload.assignedCarrierId !== undefined && loadPayload.assignedCarrierId !== null) {
                            newInvoiceLineItem.fromEntityId = loadPayload.assignedCarrierId;
                        }
                    }
                }

                if (invoiceLineItem.toEntityType !== undefined && invoiceLineItem.toEntityType !== null) {
                    newInvoiceLineItem.toEntityType = invoiceLineItem.toEntityType;
                    if (invoiceLineItem.toEntityType === 'SHIPPER') {
                        if (loadPayload.shipperId !== undefined && loadPayload.shipperId !== null) {
                            newInvoiceLineItem.toEntityId = loadPayload.shipperId;
                        }
                    } else if (invoiceLineItem.toEntityType === 'CARRIER') {
                        if (loadPayload.assignedCarrierId !== undefined && loadPayload.assignedCarrierId !== null) {
                            newInvoiceLineItem.toEntityId = loadPayload.assignedCarrierId;
                        }
                    }
                }

                if (invoiceLineItem.baseAmount !== undefined && invoiceLineItem.baseAmount !== null) {
                    newInvoiceLineItem.baseAmount = Number(invoiceLineItem.baseAmount);
                }

                if (invoiceLineItem.baseAmountUnit !== undefined && invoiceLineItem.baseAmountUnit !== null) {
                    newInvoiceLineItem.baseAmountUnit = invoiceLineItem.baseAmountUnit;
                }

                if (invoiceLineItem.totalAmount !== undefined && invoiceLineItem.totalAmount !== null) {
                    newInvoiceLineItem.totalAmount = Number(invoiceLineItem.totalAmount);
                }

                if (invoiceLineItem.totalAmountUnit !== undefined && invoiceLineItem.totalAmountUnit !== null) {
                    newInvoiceLineItem.totalAmountUnit = invoiceLineItem.totalAmountUnit;
                }

                if (invoiceLineItem.quantity !== undefined && invoiceLineItem.quantity !== null) {
                    newInvoiceLineItem.quantity = Number(invoiceLineItem.quantity);
                }

                if (invoiceLineItem.quantityUnit !== undefined && invoiceLineItem.quantityUnit !== null) {
                    newInvoiceLineItem.quantityUnit = invoiceLineItem.quantityUnit;
                }

                if (invoiceLineItem.status !== undefined && invoiceLineItem.status !== null) {
                    newInvoiceLineItem.status = invoiceLineItem.status;
                }

                if (invoiceLineItem.approvalStatus !== undefined && invoiceLineItem.approvalStatus !== null) {
                    newInvoiceLineItem.approvalStatus = invoiceLineItem.approvalStatus;
                }

                if (invoiceLineItem.isOpen !== undefined && invoiceLineItem.isOpen !== null) {
                    newInvoiceLineItem.isOpen = invoiceLineItem.isOpen;
                }

                invoiceLineItemsPayload.push(newInvoiceLineItem);
            });
        }

        let newLoadPayload = removeEmpty(loadPayload);
        let newStopsPayload = removeEmpty(stopsPayload);

        console.log("newLoadPayload", newLoadPayload);

        dispatch(actionCreators.addLoad(newLoadPayload, newStopsPayload, invoiceLineItemsPayload, docFiles));
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        if (entityType === 'CARRIER' && yourAccountUsers !== undefined && yourAccountUsers !== null && yourAccountUsers.length > 0) {
            let yourAccountUser = yourAccountUsers[0];
            if (yourAccountUser.account !== undefined && yourAccountUser.account !== null) {
                setCarrier(yourAccountUser.account);
            } else {
                setCarrier(null);
            }
        } else {
            setCarrier(null);
        }
    }, [yourAccountUsers, entityType]);

    useEffect(() => {
        mapRef.current = new TrimbleMaps.Map({
            container: `map`,
            center: new TrimbleMaps.LngLat(lon, lat),
            zoom: zoom,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA
        });

        mapRef.current.on('load', function () {
            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//current_location_mark.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('currentLocationMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//pickUpMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('pickUpMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//dropOffMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('dropOffMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//restStopMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('restStopMarker', image);
                }
            });

            setIsMapReady(true);

            mapRef.current.resize();
        });

        let mapDiv = document.getElementById('map');
        window.onresize = () => {
            //console.log('window resized');

            if (mapRef.current !== undefined && mapRef.current !== null) {
                mapRef.current.resize();
            }
        };
        mapDiv.onresize = () => {
            //console.log('map container resized');

            if (mapRef.current !== undefined && mapRef.current !== null) {
                mapRef.current.resize();
            }
        };

        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, []);

    useMemo(() => {
        let stopsArray = [];
        if (isMapReady === true && mapRef.current !== null && pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            pickUpStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });

            dropOffStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });

            if (stopsArray.find(stop => stop.stopLocation === undefined || stop.stopLocation.latitude === undefined || stop.stopLocation.longitude === undefined) === undefined) {
                const routePostBody = ({
                    'stops': stopsArray.map((stop) => ({ latitude: stop.stopLocation.latitude, longitude: stop.stopLocation.longitude })),
                    'options': {
                        'distanceUnit': 'MI',
                        'vehicleType': 'TRUCK',
                        'routingType': 'PRACTICAL',
                        'routeOptimization': 'NONE',
                        'isHighwayOnly': false,
                        'isTollDiscouraged': true
                    },
                });

                // get practical route miles
                axiosAuthenticated.post('/tms/route', routePostBody).then(res => {
                    //console.log(res.data);
                    if (res.data !== null && res.data !== undefined && res.data.distance !== null && res.data.distance !== undefined && res.data.minutes !== null && res.data.minutes !== undefined) {
                        setPracticalRouteMiles(res.data.distance);
                        setPracticalRouteMinutes(res.data.minutes);
                    }
                }).catch(err => {
                    console.log(err);
                });

                let mapStops = stopsArray.filter(stop => stop.stopLocation !== undefined && stop.stopLocation.latitude !== undefined && stop.stopLocation.longitude !== undefined).map((stop) => {
                    return new TrimbleMaps.LngLat(stop.stopLocation.longitude, stop.stopLocation.latitude);
                });
                let mapStopsWithStopType = stopsArray.filter(stop => stop.stopLocation !== undefined && stop.stopLocation.latitude !== undefined && stop.stopLocation.longitude !== undefined).map((stop) => {
                    return {
                        stop: stop,
                        longitude: stop.stopLocation.longitude,
                        latitude: stop.stopLocation.latitude,
                        stopType: stop.stopType,
                        id: stop.id
                    };
                });

                if (routingLayer.current === null) {
                    if (mapStops.length > 0) {
                        routingLayer.current = new TrimbleMaps.Route({
                            routeId: 'newRoute',
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                        routingLayer.current.addTo(mapRef.current);
                    }
                } else {
                    if (mapStops.length > 0) {
                        routingLayer.current.update({
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                    }
                }

                stopLayerGroupRef.current = null;

                if (mapStopsWithStopType.length > 0) {
                    mapStopsWithStopType.forEach((stop) => {
                        if (stop.stopType === 'PICK_UP') {
                            addStopMarker('pickUpMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else if (stop.stopType === 'DROP_OFF') {
                            addStopMarker('dropOffMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else {
                            addStopMarker('restStopMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        }
                    });
                }
            }
        }
    }, [isMapReady, pickUpStops, dropOffStops, stopLayerGroupRef, routingLayer, mapRef]);

    useMemo(() => {
        // clear any previous errors if this is a new load creation
        dispatch(actionCreators.addLoadLoadingClear());
        dispatch(actionCreators.addLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && addRecordId !== undefined && addRecordId !== null) {
            done(addRecordId);
        }
    }, [isLoading, error, addRecordId]);

    useMemo(() => {
        if (entityType === 'CARRIER') {
            setDontRequireLoadSpecificInformation(true);
        } else {
            setDontRequireLoadSpecificInformation(false);
        }

        if (bulkCount > 1) {
            setHideLoadSpecificInformation(true);
        } else {
            setHideLoadSpecificInformation(false);
        }
    }, [bulkCount, entityType]);

    useMemo(() => {
        // console.log(`The Shipper ID is ${shipperIdValue}`);
        if (accounts !== undefined && accounts !== null && accounts.length > 0) {
            if (shipperIdValue !== undefined && shipperIdValue !== null) {
                if (isEmpty(shipperData)) {
                    let selectedShipperAccount = accounts.find(s => s.id === shipperIdValue);
                    if (selectedShipperAccount !== undefined && selectedShipperAccount !== null) {
                        const shipperDataObj = {
                            shipperId: shipperIdValue,
                            shipperName: selectedShipperAccount.name
                        };
                        setShipperData(shipperDataObj);
                    }
                }
            }
        }
    }, [shipperIdValue, accounts]);

    useMemo(() => {
        // console.log(`The Load ID is ${duplicateLoadId}`);
        // console.log(`The Load Lane ID is ${loadLaneId}`);
        // console.log(`The Linked Load Lane ID is ${linkedLoadLaneId}`);
        if (trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0 && lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0) {
            if (duplicateLoadId !== undefined && duplicateLoadId !== null) {
                if (isEmpty(equipmentData) && isEmpty(pickUpStops) && isEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLoadWithStops(duplicateLoadId).then((load) => {
                        //console.log(load);
                        let equipmentDataObj = {};
                        if (load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && !isEmpty(load.equipmentNeeds)) {
                            equipmentDataObj.trailerTypeId = load.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = load.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = load.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = load.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = load.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = load.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = load.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = load.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = load.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = load.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = load.equipmentNeeds.maxRunTempUnit;

                            if (load.equipmentNeeds.trailerTypeId !== undefined && load.equipmentNeeds.trailerTypeId !== null) {
                                let selectedTrailerType = trailerTypes.find(s => s.id === load.equipmentNeeds.trailerTypeId);
                                if (selectedTrailerType !== null && selectedTrailerType !== undefined) {
                                    equipmentDataObj.trailerType = selectedTrailerType;
                                }
                            }
                        }

                        if (load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0) {
                            equipmentDataObj.equipmentSpecifics = load.equipmentSpecifics;
                        }
                        if (load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0) {
                            equipmentDataObj.driverRequirements = load.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (load.stops !== undefined && load.stops !== null && load.stops.length > 0) {
                            let pickUps = load.stops.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = load.stops.filter(s => s.stopType === 'DROP_OFF');
                            if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                                let orderedPickUpStops = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpStops);
                                orderedPickUpStops.forEach((stop, stopIndex, arr) => {
                                    pickUpStopsArray.push({
                                        sequence: stopIndex,
                                        stopType: stop.stopType,
                                        stopTypeSequence: stopIndex,
                                        id: stopIndex,
                                        stopLocation: stop.stopLocation,
                                        stopLocationId: stop.stopLocationId,
                                        specialInstructions: stop.specialInstructions,
                                        hasLumperFee: stop.hasLumperFee,
                                        lumperFee: stop.lumperFee,
                                        hasDriverAssist: stop.hasDriverAssist,
                                        driverAssist: stop.driverAssist,
                                        apptType: stop.apptType,
                                        apptPointOfContact: stop.apptPointOfContact,
                                        commodities: stop.commodities,
                                        loadingType: stop.loadingType
                                    });
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((stop, stopIndex, arr) => {
                                    dropOffStopsArray.push({
                                        sequence: stopIndex + pickUpStopsArray.length,
                                        stopType: stop.stopType,
                                        stopTypeSequence: stopIndex,
                                        id: stopIndex + pickUpStopsArray.length,
                                        stopLocation: stop.stopLocation,
                                        stopLocationId: stop.stopLocationId,
                                        specialInstructions: stop.specialInstructions,
                                        hasLumperFee: stop.hasLumperFee,
                                        lumperFee: stop.lumperFee,
                                        hasDriverAssist: stop.hasDriverAssist,
                                        driverAssist: stop.driverAssist,
                                        apptType: stop.apptType,
                                        apptPointOfContact: stop.apptPointOfContact,
                                        commodities: stop.commodities,
                                        loadingType: stop.loadingType
                                    });
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        console.log(err);
                        setIsStepsLoading(false);
                    });
                }
            } else if (loadLaneId !== undefined && loadLaneId !== null) {
                console.log("loadLaneId", loadLaneId);
                if (isEmpty(pickUpStops) && isEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLoadLane(loadLaneId).then((loadLane) => {
                        //console.log(loadLane);

                        setLoadLane(loadLane);

                        let servicesDataObj = {};
                        if (isEmpty(servicesData) && loadLane.customerLaneId) {
                            if (bulkCount === 1) {
                                servicesDataObj.parentName = loadLane.customerLaneId;
                            } else if (bulkCount > 1) {
                                servicesDataObj.parentName = loadLane.customerLaneId;
                            }

                            console.log("loadLane", loadLane);
                            if (!!loadLane.perMileRate) {
                                servicesDataObj.rateOption = 'CONTRACT_RATE';
                                servicesDataObj.contractRateAmount = loadLane.perMileRate;
                            }
                            setServicesData(servicesDataObj);
                        }

                        let equipmentDataObj = {};
                        if (loadLane.equipmentNeeds !== undefined && loadLane.equipmentNeeds !== null && !isEmpty(loadLane.equipmentNeeds)) {
                            equipmentDataObj.trailerTypeId = loadLane.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = loadLane.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = loadLane.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = loadLane.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = loadLane.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = loadLane.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = loadLane.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = loadLane.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = loadLane.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = loadLane.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = loadLane.equipmentNeeds.maxRunTempUnit;

                            if (loadLane.equipmentNeeds.trailerTypeId !== undefined && loadLane.equipmentNeeds.trailerTypeId !== null) {
                                let selectedTrailerType = trailerTypes.find(s => s.id === loadLane.equipmentNeeds.trailerTypeId);
                                if (selectedTrailerType !== null && selectedTrailerType !== undefined) {
                                    equipmentDataObj.trailerType = selectedTrailerType;
                                }
                            }
                        }

                        if (loadLane.equipmentSpecifics !== undefined && loadLane.equipmentSpecifics !== null && loadLane.equipmentSpecifics.length > 0) {
                            equipmentDataObj.equipmentSpecifics = loadLane.equipmentSpecifics;
                        }
                        if (loadLane.driverRequirements !== undefined && loadLane.driverRequirements !== null && loadLane.driverRequirements.length > 0) {
                            equipmentDataObj.driverRequirements = loadLane.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                            let pickUps = loadLane.locations.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = loadLane.locations.filter(s => s.stopType === 'DROP_OFF');
                            if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                                let orderedPickUpLocations = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpLocations);
                                orderedPickUpLocations.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false,
                                        apptWindowStartDateTime: momentDate.getCurrentDateTimeInRange(),
                                        apptWindowEndDateTime: momentDate.getCurrentDateTimeInRange(),
                                    };

                                    if (laneLocation.location !== undefined && laneLocation.location !== null) {
                                        if (laneLocation.location.specialInstructions !== undefined && laneLocation.location.specialInstructions !== null) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (laneLocation.location.lumperFee !== undefined && laneLocation.location.lumperFee !== null && laneLocation.location.lumperFee > 0) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (laneLocation.location.pointOfContact !== undefined && laneLocation.location.pointOfContact !== null && !isEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (laneLocation.location.appointmentType !== undefined && laneLocation.location.appointmentType !== null) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (laneLocation.location.timeZone !== undefined && laneLocation.location.timeZone !== null) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (laneLocation.loadingType !== undefined && laneLocation.loadingType !== null) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (laneLocation.commodities !== undefined && laneLocation.commodities !== null && laneLocation.commodities.length > 0) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    pickUpStopsArray.push(newStop);
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex + pickUpStopsArray.length,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex + pickUpStopsArray.length,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false,
                                        apptWindowStartDateTime: momentDate.getCurrentDateTimeInRange(),
                                        apptWindowEndDateTime: momentDate.getCurrentDateTimeInRange(),
                                    };

                                    if (laneLocation.location !== undefined && laneLocation.location !== null) {
                                        if (laneLocation.location.specialInstructions !== undefined && laneLocation.location.specialInstructions !== null) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (laneLocation.location.lumperFee !== undefined && laneLocation.location.lumperFee !== null && laneLocation.location.lumperFee > 0) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (laneLocation.location.pointOfContact !== undefined && laneLocation.location.pointOfContact !== null && !isEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (laneLocation.location.appointmentType !== undefined && laneLocation.location.appointmentType !== null) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (laneLocation.location.timeZone !== undefined && laneLocation.location.timeZone !== null) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (laneLocation.loadingType !== undefined && laneLocation.loadingType !== null) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (laneLocation.commodities !== undefined && laneLocation.commodities !== null && laneLocation.commodities.length > 0) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    dropOffStopsArray.push(newStop);
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        console.log(err);
                        setIsStepsLoading(false);
                    });
                }
            } else if (linkedLoadLaneId !== undefined && linkedLoadLaneId !== null) {
                if (isEmpty(pickUpStops) && isEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    getLinkedLoadLane(linkedLoadLaneId).then((linkedLoadLane) => {
                        console.log(linkedLoadLane);

                        setLoadLane(linkedLoadLane);

                        let servicesDataObj = {};
                        console.log(bulkCount);
                        console.log(servicesData);
                        if (isEmpty(servicesData) && linkedLoadLane.customerLaneId) {
                            if (bulkCount === 1) {
                                servicesDataObj.parentName = linkedLoadLane.customerLaneId;
                            } else if (bulkCount > 1) {
                                servicesDataObj.parentName = linkedLoadLane.customerLaneId;
                            }
                            console.log(servicesDataObj);
                            setServicesData(servicesDataObj);
                        }

                        let equipmentDataObj = {};
                        if (linkedLoadLane.equipmentNeeds !== undefined && linkedLoadLane.equipmentNeeds !== null && !isEmpty(linkedLoadLane.equipmentNeeds)) {
                            equipmentDataObj.trailerTypeId = linkedLoadLane.equipmentNeeds.trailerTypeId;
                            equipmentDataObj.trailerLength = linkedLoadLane.equipmentNeeds.trailerLength;
                            equipmentDataObj.trailerLengthUnit = linkedLoadLane.equipmentNeeds.trailerLengthUnit;
                            equipmentDataObj.trailerWeightCapacity = linkedLoadLane.equipmentNeeds.trailerWeightCapacity;
                            equipmentDataObj.trailerWeightCapacityUnit = linkedLoadLane.equipmentNeeds.trailerWeightCapacityUnit;
                            equipmentDataObj.preCoolReefer = linkedLoadLane.equipmentNeeds.preCoolReefer;
                            equipmentDataObj.preCoolReeferUnit = linkedLoadLane.equipmentNeeds.preCoolReeferUnit;
                            equipmentDataObj.minRunTemp = linkedLoadLane.equipmentNeeds.minRunTemp;
                            equipmentDataObj.minRunTempUnit = linkedLoadLane.equipmentNeeds.minRunTempUnit;
                            equipmentDataObj.maxRunTemp = linkedLoadLane.equipmentNeeds.maxRunTemp;
                            equipmentDataObj.maxRunTempUnit = linkedLoadLane.equipmentNeeds.maxRunTempUnit;

                            if (linkedLoadLane.equipmentNeeds.trailerTypeId !== undefined && linkedLoadLane.equipmentNeeds.trailerTypeId !== null) {
                                let selectedTrailerType = trailerTypes.find(s => s.id === linkedLoadLane.equipmentNeeds.trailerTypeId);
                                if (selectedTrailerType !== null && selectedTrailerType !== undefined) {
                                    equipmentDataObj.trailerType = selectedTrailerType;
                                }
                            }
                        }

                        if (linkedLoadLane.equipmentSpecifics !== undefined && linkedLoadLane.equipmentSpecifics !== null && linkedLoadLane.equipmentSpecifics.length > 0) {
                            equipmentDataObj.equipmentSpecifics = linkedLoadLane.equipmentSpecifics;
                        }
                        if (linkedLoadLane.driverRequirements !== undefined && linkedLoadLane.driverRequirements !== null && linkedLoadLane.driverRequirements.length > 0) {
                            equipmentDataObj.driverRequirements = linkedLoadLane.driverRequirements;
                        }

                        setEquipmentData({ ...equipmentDataObj });

                        let pickUpStopsArray = [];
                        let dropOffStopsArray = [];
                        if (linkedLoadLane.locations !== undefined && linkedLoadLane.locations !== null && linkedLoadLane.locations.length > 0) {
                            let pickUps = linkedLoadLane.locations.filter(s => s.stopType === 'PICK_UP');
                            let dropOffs = linkedLoadLane.locations.filter(s => s.stopType === 'DROP_OFF');
                            if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
                                let orderedPickUpLocations = sortBy(pickUps, [function (o) { return o.sequence; }]);
                                // console.log(orderedPickUpLocations);
                                orderedPickUpLocations.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false,
                                        apptWindowStartDateTime: momentDate.getCurrentDateTimeInRange(),
                                        apptWindowEndDateTime: momentDate.getCurrentDateTimeInRange(),
                                    };

                                    if (laneLocation.location !== undefined && laneLocation.location !== null) {
                                        if (laneLocation.location.specialInstructions !== undefined && laneLocation.location.specialInstructions !== null) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (laneLocation.location.lumperFee !== undefined && laneLocation.location.lumperFee !== null && laneLocation.location.lumperFee > 0) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (laneLocation.location.pointOfContact !== undefined && laneLocation.location.pointOfContact !== null && !isEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (laneLocation.location.appointmentType !== undefined && laneLocation.location.appointmentType !== null) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (laneLocation.location.timeZone !== undefined && laneLocation.location.timeZone !== null) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (laneLocation.loadingType !== undefined && laneLocation.loadingType !== null) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (laneLocation.commodities !== undefined && laneLocation.commodities !== null && laneLocation.commodities.length > 0) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    pickUpStopsArray.push(newStop);
                                });

                                setPickUpStops(pickUpStopsArray);
                            }
                            if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
                                let orderedDropOffStops = sortBy(dropOffs, [function (o) { return o.sequence; }]);
                                // console.log(orderedDropOffStops);
                                orderedDropOffStops.forEach((laneLocation, laneLocationIndex, arr) => {
                                    let newStop = {
                                        sequence: laneLocationIndex + pickUpStopsArray.length,
                                        stopType: laneLocation.stopType,
                                        stopTypeSequence: laneLocationIndex,
                                        id: laneLocationIndex + pickUpStopsArray.length,
                                        stopLocation: laneLocation.location,
                                        stopLocationId: laneLocation.locationId,
                                        hasDriverAssist: false
                                    };

                                    if (laneLocation.location !== undefined && laneLocation.location !== null) {
                                        if (laneLocation.location.specialInstructions !== undefined && laneLocation.location.specialInstructions !== null) {
                                            newStop.specialInstructions = laneLocation.location.specialInstructions;
                                        }

                                        if (laneLocation.location.lumperFee !== undefined && laneLocation.location.lumperFee !== null && laneLocation.location.lumperFee > 0) {
                                            newStop.hasLumperFee = true;
                                            newStop.lumperFee = laneLocation.location.lumperFee;
                                        } else {
                                            newStop.hasLumperFee = false;
                                        }

                                        if (laneLocation.location.pointOfContact !== undefined && laneLocation.location.pointOfContact !== null && !isEmpty(laneLocation.location.pointOfContact)) {
                                            newStop.apptPointOfContact = laneLocation.location.pointOfContact;
                                        }

                                        if (laneLocation.location.appointmentType !== undefined && laneLocation.location.appointmentType !== null) {
                                            newStop.apptType = laneLocation.location.appointmentType;
                                        }

                                        if (laneLocation.location.timeZone !== undefined && laneLocation.location.timeZone !== null) {
                                            newStop.timeZone = laneLocation.location.timeZone;
                                        }
                                    }

                                    if (laneLocation.loadingType !== undefined && laneLocation.loadingType !== null) {
                                        newStop.loadingType = laneLocation.loadingType;
                                    }

                                    if (laneLocation.commodities !== undefined && laneLocation.commodities !== null && laneLocation.commodities.length > 0) {
                                        newStop.commodities = laneLocation.commodities;
                                    }

                                    dropOffStopsArray.push(newStop);
                                });

                                setDropOffStops(dropOffStopsArray);
                            }
                        }

                        setIsStepsLoading(false);
                    }).catch(err => {
                        console.log(err);
                        setIsStepsLoading(false);
                    });
                }
            } else if (duplicateLoadId === undefined || duplicateLoadId === null || loadLaneId === undefined || loadLaneId === null || linkedLoadLaneId === undefined || linkedLoadLaneId === null) {
                if (isEmpty(pickUpStops) && isEmpty(dropOffStops)) {
                    setIsStepsLoading(true);
                    setPickUpStops([{
                        stopType: 'PICK_UP',
                        id: 0,
                        sequence: 0,
                        stopTypeSequence: 0
                    }]);

                    setDropOffStops([{
                        stopType: 'DROP_OFF',
                        id: 1,
                        sequence: 1,
                        stopTypeSequence: 0
                    }]);
                }
                setIsStepsLoading(false);
            }
        }
    }, [loadLaneId, linkedLoadLaneId, duplicateLoadId, lineItemTypes, trailerTypes]);

    //#endregion
    //#region displays

    const getEquipmentData = () => {
        if (equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) {
            let trailerLength = stringFormatter.toFormattedString('Length', equipmentData.trailerLength, equipmentData.trailerLengthUnit);
            let trailerWeightCapacity = stringFormatter.toFormattedString('Weight', equipmentData.trailerWeightCapacity, equipmentData.trailerWeightCapacityUnit);
            return (
                <>
                    {(equipmentData.trailerType !== undefined && equipmentData.trailerType !== null && equipmentData.trailerType.description !== undefined && equipmentData.trailerType.description !== null) ? (
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Trailer: {equipmentData.trailerType.description} &#8212; {trailerLength}, {trailerWeightCapacity}</span></div>
                                {(equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null) ? (
                                    <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Pre-Cool Temp: {stringFormatter.toFormattedString('Temperature', equipmentData.preCoolReefer, equipmentData.preCoolReeferUnit)}</span></div>
                                ) : null}
                                {(equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null && equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null) ? (
                                    <div style={{ paddingLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>Run Temp Range: {stringFormatter.toFormattedString('Temperature', equipmentData.minRunTemp, equipmentData.minRunTempUnit)} - {stringFormatter.toFormattedString('Temperature', equipmentData.maxRunTemp, equipmentData.maxRunTempUnit)}</span></div>
                                ) : null}
                            </Col>
                        </Row>
                    ) : null}
                    {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Additional Equipment and Services</span></div>
                                <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                            </Col>
                        </Row>
                    ) : null}
                    {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Driver Requirements</span></div>
                                <DataListRow style={{ paddingLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                            </Col>
                        </Row>
                    ) : null}
                </>
            );
        } else {
            return null;
        }
    };

    const getRouteData = () => {
        if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            const pickUps = pickUpStops.filter(s => s.stopLocationId !== undefined && s.stopLocationId !== null);
            const dropOffs = dropOffStops.filter(s => s.stopLocationId !== undefined && s.stopLocationId !== null);
            if (pickUps.length > 0 && dropOffs.length > 0) {
                let pickUpStopsDisplay = pickUps.map((stop) => {
                    const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
                    const stopLocation = `${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city + ', ' : ''}${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : ''}`;
                    const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
                    let appointmentTypeToShow = null;
                    switch (stop.apptType) {
                        case "FIRST_COME_FIRST_SERVE":
                            appointmentTypeToShow = (stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow key={`stop-appt-datetime-${stop.id}`} separateLines={true} startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null);
                            break;
                        case "HAVE_APPOINTMENT":
                            appointmentTypeToShow = (stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow key={`stop-appt-datetime-${stop.id}`} value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null);
                            break;
                        case "NEED_APPOINTMENT":
                            appointmentTypeToShow = (
                                <>
                                    {stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? <DataRow key={`stop-requested-datetime-${stop.id}`} value={stop.requestedDateTime} dataType="MomentDate" timeZone={stop.timeZone} /> : null}
                                    {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow key={`stop-callahead-datetime-${stop.id}`} title="Call Ahead" titleStyle={{ fontWeight: 'normal' }} value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                </>
                            );
                            break;
                    }

                    return (
                        <div style={{ marginBottom: 24 }} key={`stop-details-${stop.id}`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div><span style={{ fontWeight: 'bold' }}>{stopLocation}</span></div>
                                    {appointmentTypeToShow}
                                </div>
                            </div>
                        </div>
                    );
                });
                let dropOffStopsDisplay = dropOffs.map((stop) => {
                    const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
                    const stopLocation = `${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city + ', ' : ''}${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : ''}`;
                    const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
                    let appointmentTypeToShow = null;
                    switch (stop.apptType) {
                        case "FIRST_COME_FIRST_SERVE":
                            appointmentTypeToShow = (stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow key={`stop-appt-datetime-${stop.id}`} separateLines={true} startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null);
                            break;
                        case "HAVE_APPOINTMENT":
                            appointmentTypeToShow = (stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow key={`stop-appt-datetime-${stop.id}`} value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null);
                            break;
                        case "NEED_APPOINTMENT":
                            appointmentTypeToShow = (
                                <>
                                    {stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? <DataRow key={`stop-requested-datetime-${stop.id}`} value={stop.requestedDateTime} dataType="MomentDate" timeZone={stop.timeZone} /> : null}
                                    {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow key={`stop-callahead-datetime-${stop.id}`} title="Call Ahead" titleStyle={{ fontWeight: 'normal' }} value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                </>
                            );
                            break;
                    }

                    return (
                        <div style={{ marginBottom: 24 }} key={`stop-details-${stop.id}`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div><span style={{ fontWeight: 'bold' }}>{stopLocation}</span></div>
                                    {appointmentTypeToShow}
                                </div>
                            </div>
                        </div>
                    );
                });

                return (
                    <div>
                        <Row style={{ marginTop: 24 }}>
                            <Col span={12}>
                                {pickUpStopsDisplay}
                            </Col>
                            <Col span={12}>
                                {dropOffStopsDisplay}
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 24 }}>
                            <Col span={12}>
                                <DataRow title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={pickUpStops.length + dropOffStops.length} />
                            </Col>
                            <Col span={12}>
                                {practicalRouteMiles !== undefined && practicalRouteMiles !== null ? <DataRow title="Trip Miles" valueStyle={{ fontWeight: 'bold' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                            </Col>
                        </Row>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const getStopsData = () => {
        if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            return (
                <>
                    {pickUpStops.map((stop, index, arr) => {
                        return (
                            <div key={`pick-up-stop-details-${index}`}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {stop.pickUpNumber !== undefined && stop.pickUpNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Pick-Up Number" value={stop.pickUpNumber} dataType="String" /> : null}
                                        {stop.loadingType !== undefined && stop.loadingType !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Loading Type" value={stop.loadingType} dataType="LoadingType" /> : null}
                                        {stop.hasLumperFee !== undefined && stop.hasLumperFee !== null && stop.hasLumperFee === true ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {stop.hasDriverAssist !== undefined && stop.hasDriverAssist !== null && stop.hasDriverAssist === true ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {stop.specialInstructions !== undefined && stop.specialInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Commodities for Pick-Up${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={stop.commodities} />
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        );
                    })}
                    {dropOffStops.map((stop, index, arr) => {
                        return (
                            <div key={`drop-off-stop-details-${index}`}>
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Details for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>

                                        {stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0 ? <DataListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} oneLine={true} title="BOL Number(s)" data={stop.bolNumbers} dataType="String" /> : null}
                                        {stop.dropOffNumber !== undefined && stop.dropOffNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Drop-Off Number" value={stop.dropOffNumber} dataType="String" /> : null}
                                        {stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer PO Number" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                        {stop.loadingType !== undefined && stop.loadingType !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Unloading Type" value={stop.loadingType} dataType="UnloadingType" /> : null}
                                        {stop.hasLumperFee !== undefined && stop.hasLumperFee !== null && stop.hasLumperFee === true ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Lumper Fee" value={`Yes, ${stringFormatter.toFormattedMoney(stop.lumperFee)}`} dataType="String" /> : null}
                                        {stop.hasDriverAssist !== undefined && stop.hasDriverAssist !== null && stop.hasDriverAssist === true ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Driver Assist" value={`Yes, ${stringFormatter.toFormattedMoney(stop.driverAssist)}`} dataType="String" /> : null}
                                        {stop.specialInstructions !== undefined && stop.specialInstructions !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Special Instructions" value={stop.specialInstructions} dataType="String" /> : null}
                                    </Col>
                                </Row>
                                {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                                    <Row gutter={[12, 12]}>
                                        <Col span={24}>
                                            <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>{`Commodities for Drop-Off${arr.length > 1 ? ' ' + (stop.stopTypeSequence + 1) : ''}`}</span></div>
                                            <DataCommodityListRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={stop.commodities} />
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    };

    const getPricingData = () => {
        if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            return (
                <div>
                    <div style={{ marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Load Information</span></div>

                    {servicesData !== undefined && servicesData !== null && servicesData.parentName !== undefined && servicesData.parentName !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} value={servicesData.parentName} dataType="String" /> : null}
                    {servicesData !== undefined && servicesData !== null && servicesData.customerOrderNumber !== undefined && servicesData.customerOrderNumber !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Customer Order Number" value={servicesData.customerOrderNumber} dataType="String" /> : null}
                    {entityType === "STAFF" && servicesData !== undefined && servicesData !== null && servicesData.serviceType !== undefined && servicesData.serviceType !== null ? <DataRow style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title="Service Type" value={servicesData.serviceType} dataType="LoadServiceType" /> : null}

                    <div style={{ marginBottom: 12, marginTop: 12 }}><span style={{ fontSize: 14, color: '#000000' }}>Stop Fees</span></div>

                    {invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                        return (
                            <DataRow key={`subtotal-lineitem-${index}`} style={{ paddingLeft: 8 }} titleStyle={{ fontSize: 14, color: '#A9A9A9' }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                        );
                    })}
                    {servicesData !== undefined && servicesData !== null && servicesData.totalAddOnCharge !== undefined && servicesData.totalAddOnCharge !== null ? (
                        <div style={{ paddingLeft: 8, marginBottom: 12 }}><span style={{ fontSize: 14, color: '#000000' }}><i>Subtotal: {stringFormatter.toFormattedMoney(servicesData.totalAddOnCharge)}</i></span></div>
                    ) : null}

                    {(entityType === "STAFF" || entityType === "CARRIER") && servicesData !== undefined && servicesData !== null && servicesData.shipperAmount !== undefined && servicesData.shipperAmount !== null && servicesData.shipperAmount > 0 ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Charge to the Shipper: {stringFormatter.toFormattedMoney(servicesData.shipperAmount)}</span></div>
                    ) : null}
                    {(entityType === "STAFF" || entityType === "SHIPPER") && servicesData.carrierAmount !== undefined && servicesData.carrierAmount !== null && servicesData.carrierAmount > 0 ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Total Pay to the Carrier: {stringFormatter.toFormattedMoney(servicesData.carrierAmount)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && servicesData !== undefined && servicesData !== null && servicesData.IRISProfit !== undefined && servicesData.IRISProfit !== null && servicesData.IRISProfit > 0 ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Profit: {stringFormatter.toFormattedMoney(servicesData.IRISProfit)}</span></div>
                    ) : null}
                    {entityType === "STAFF" && servicesData !== undefined && servicesData !== null && servicesData.commissionPercentage !== undefined && servicesData.commissionPercentage !== null && servicesData.commissionPercentage > 0 ? (
                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 500, fontSize: 14, color: '#000000' }}>Commission: {stringFormatter.toFormattedPercentage(servicesData.commissionPercentage)}</span></div>
                    ) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region onChanges

    const onChangeStep = (key) => {
        console.log(`step key: ${key}`);
        setCurrent(key);
        window.scrollTo(0, 0);

        if (key === 'schedule') {
            setCurrentStepNumber(0);
        } else if (key === 'equipment') {
            setCurrentStepNumber(1);
        } else if (key === 'stops') {
            setCurrentStepNumber(2);
        } else if (key === 'services') {
            setCurrentStepNumber(2);
            // setCurrentStepNumber(3);
        } else if (key === 'review') {
            setCurrentStepNumber(4);
        } else {
            setCurrentStepNumber(- 1);
        }
    };

    const onChangeStepNumber = (step) => {
        console.log(`step number: ${step}`);
        if (step === 0) {
            setCurrent('schedule');
        } else if (step === 1) {
            setCurrent('equipment');
        } else if (step === 2) {
            setCurrent('stops');
        } else if (step === 3) {
            setCurrent('services');
        } else if (step === 4) {
            setCurrent('review');
        } else {
            setCurrent(null);
        }

        setCurrentStepNumber(step);
        window.scrollTo(0, 0);
    };

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isStepsLoading === true}>
            <Row gutter={[4, 4]}>
                <Col xs={{ span: 0 }} md={{ span: 6 }} lg={{ span: 3, offset: 1 }}>
                    <Affix offsetTop={44} style={{ position: 'absolute', marginTop: 92 }}>
                        <div>
                            <Steps size="default" direction="vertical" current={currentStepNumber} onChange={onChangeStepNumber}>
                                <Steps.Step title="Route" />
                                <Steps.Step title="Equipment" disabled={completedSteps.schedule === false} />
                                {/*<Steps.Step title="Details" disabled={completedSteps.equipment === false} />*/}
                                <Steps.Step title={entityType && entityType === 'CARRIER' ? "Invoice" : "Price & Tender"} disabled={completedSteps.stops === false} />
                                <Steps.Step title="Review" disabled={completedSteps.services === false} />
                            </Steps>
                        </div>
                    </Affix>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 12 }}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Header
                                title="New Load"
                                icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                                title2={shipperData && shipperData.shipperName ? shipperData.shipperName : 'TBD'}
                                title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                title3={entityType === "CARRIER" && carrier ? carrier.name : 'TBD'}
                                title3Icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                                title4={loadLane ? (loadLane.customerLaneId ? loadLane.customerLaneId : (loadLane.loadLane ? (loadLane.loadLane.customerLaneId ? loadLane.loadLane.customerLaneId : loadLane.loadLane.irisId) : loadLane.irisId)) : null}
                                title4Icon={<FontAwesomeIcon className="anticon" icon={faRoute} />}
                            />
                        </Col>
                    </Row>
                    {(current === 'schedule') ? (
                        <NewLoadSchedule
                            shipperData={shipperData}
                            setShipperData={setShipperData}
                            pickUpStops={pickUpStops}
                            setPickUpStops={setPickUpStops}
                            dropOffStops={dropOffStops}
                            setDropOffStops={setDropOffStops}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            docFiles={docFiles}
                            setDocFiles={setDocFiles}
                            nextStepAction={onChangeStep}
                            nextStepActionName="Continue to Equipment"
                            cancelButton={
                                <Popconfirm
                                    title="Are you sure you want to cancel the creation of this load? You will lose your changes."
                                    onConfirm={confirmCancel}
                                    onCancel={cancelCancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger={true} type="link" block>Cancel Load Creation</Button>
                                </Popconfirm>
                            }
                            dontRequireLoadSpecificInformation={dontRequireLoadSpecificInformation}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}
                    {(current === 'equipment') ? (
                        <NewLoadEquipment
                            equipmentData={equipmentData}
                            setEquipmentData={setEquipmentData}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            previousStepAction={onChangeStep}
                            previousStepActionName="Back to Route"
                            nextStepAction={onChangeStep}
                            nextStepActionName="Continue to Details"
                        />
                    ) : null}
                    {(current === 'stops') ? (
                        <NewLoadStops
                            pickUpStops={pickUpStops}
                            setPickUpStops={setPickUpStops}
                            dropOffStops={dropOffStops}
                            setDropOffStops={setDropOffStops}
                            docFiles={docFiles}
                            setDocFiles={setDocFiles}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            previousStepAction={onChangeStep}
                            previousStepActionName="Back to Equipment"
                            nextStepAction={onChangeStep}
                            nextStepActionName={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Continue to Price & Tender" : "Continue to Invoice"}
                            dontRequireLoadSpecificInformation={dontRequireLoadSpecificInformation}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}
                    {(current === 'services') ? (
                        <NewLoadServices
                            servicesData={servicesData}
                            setServicesData={setServicesData}
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            previousStepAction={onChangeStep}
                            previousStepActionName="Back to Details"
                            nextStepAction={onChangeStep}
                            nextStepActionName="Review Load Summary"
                            pickUpStops={pickUpStops}
                            dropOffStops={dropOffStops}
                            equipmentData={equipmentData}
                            practicalRouteMiles={practicalRouteMiles}
                            setInvoiceLineItems={setInvoiceLineItems}
                            setCarrier={setCarrier}
                            dontRequireLoadSpecificInformation={dontRequireLoadSpecificInformation}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}
                    {(current === 'review') ? (
                        <NewLoadReview
                            pickUpStops={pickUpStops}
                            dropOffStops={dropOffStops}
                            equipmentData={equipmentData}
                            servicesData={servicesData}
                            previousStepAction={onChangeStep}
                            previousStepActionName={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Back to Price & Tender" : "Back to Invoice"}
                            nextStepAction={onSubmit}
                            nextStepActionName="Create Load"
                            practicalRouteMiles={practicalRouteMiles}
                            invoiceLineItems={invoiceLineItems}
                            hideLoadSpecificInformation={hideLoadSpecificInformation}
                        />
                    ) : null}

                </Col>
                <Col xs={{ span: 0 }} md={{ span: 8 }} xl={{ span: 8 }}>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Affix offsetTop={4}>
                                <div className={classes.loadSummaryContainer}>
                                    <Row gutter={[12, 12]} className={classes.loadSummary}>
                                        <Col span={24}>
                                            <Card title={
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                    <div>{(bulkCount !== undefined && bulkCount !== null && bulkCount > 1) ?
                                                        `Load Summary (x ${bulkCount})` :
                                                        "Load Summary"}</div>
                                                    <div>
                                                        <Popconfirm
                                                            title="Are you sure you want to cancel the creation of this load? You will lose your changes."
                                                            onConfirm={confirmCancel}
                                                            onCancel={cancelCancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            placement="left"
                                                        >
                                                            <Button type="danger" >Cancel Load</Button>
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            } headStyle={{ textAlign: 'center', fontSize: 20 }} size="small" bordered={false}>
                                                {!!isRecurringLoad && <span>
                                                        Repeat daily for {repeatDaysCount} days
                                                    </span>}
                                                <div id="map" style={{ width: "100%", height: "300px", margin: '0px auto' }}></div>
                                                {(completedSteps.schedule === true) && getRouteData()}
                                                <Collapse expandIconPosition={'right'} style={{ marginLeft: '-12px', marginRight: '-12px', marginTop: 24, marginBottom: 24, borderLeft: 0, borderRight: 0 }}>
                                                    {(completedSteps.equipment === true) ? (
                                                        <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Equipment Needs & Services</span>} key="equipment">
                                                            {getEquipmentData()}
                                                        </Panel>
                                                    ) : null}
                                                    {(completedSteps.schedule === true || completedSteps.stops === true) ? (
                                                        <Panel style={{ background: '#ffffff' }} header={<span style={{ fontWeight: 'bold', fontSize: 14 }}>Stop Details</span>} key="stops">
                                                            {getStopsData()}
                                                        </Panel>
                                                    ) : null}
                                                    {(completedSteps.services === true) ? (
                                                        <Panel style={{ background: '#ffffff' }} header={(entityType === 'SHIPPER' || entityType === 'STAFF') ? <span style={{ fontWeight: 'bold', fontSize: 14 }}>Pricing & Tender</span> : <span style={{ fontWeight: 'bold', fontSize: 14 }}>Invoice</span>} key="services">
                                                            {getPricingData()}
                                                        </Panel>
                                                    ) : null}
                                                </Collapse>
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name="stops"
                                                    render={({ message, messages }) => {
                                                        if (messages !== undefined && messages !== null) {
                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`stops-${type}`} message={message} type="error" />));
                                                        } else if (message !== undefined && message !== null) {
                                                            return <Alert message={message} type="error" />;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                />
                                                {error && <Alert message={`${error}`} type="error" />}
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Affix>
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Spin>
    );
};

export default withRouter(NewLoad);
