import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Load Cancellation Functions

export const fetchLoadCancellationsStart = () => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATIONS_START
    }
};

export const fetchLoadCancellationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchLoadCancellationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_CANCELLATIONS_FAIL,
        payload: payload
    }
};

export const clearLoadCancellations = () => {
    return {
        type: actionTypes.CLEAR_LOAD_CANCELLATIONS
    }
};

export const addLoadCancellationStart = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_START
    }
};

export const insertLoadCancellation = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION,
        payload: payload
    }
};

export const addLoadCancellationSuccess = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_SUCCESS
    }
};

export const addLoadCancellationFail = (payload) => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_FAIL,
        payload: payload
    }
};

export const addLoadCancellationErrorClear = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_ERROR_CLEAR
    }
};

export const addLoadCancellationLoadingClear = () => {
    return {
        type: actionTypes.ADD_LOAD_CANCELLATION_LOADING_CLEAR
    }
};

export const updateLoadCancellationStart = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_START
    }
};

export const changeLoadCancellation = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION,
        payload: payload
    }
};

export const changeSingleLoadCancellation = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LOAD_CANCELLATION,
        payload: payload
    }
};

export const updateLoadCancellationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_SUCCESS
    }
};

export const updateLoadCancellationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_FAIL,
        payload: payload
    }
};

export const updateLoadCancellationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_ERROR_CLEAR
    }
};

export const updateLoadCancellationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LOAD_CANCELLATION_LOADING_CLEAR
    }
};

export const cancelAddLoadCancellation = () => {
    return {
        type: actionTypes.CANCEL_ADD_LOAD_CANCELLATION
    }
};

export const cancelUpdateLoadCancellation = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LOAD_CANCELLATION
    }
};

export const removeLoadCancellation = (payload) => {
    return {
        type: actionTypes.REMOVE_LOAD_CANCELLATION,
        payload: payload
    }
};

//#endregion

//#region Load Cancellations Methods

export const fetchLoadCancellations = (payload) => {
    return async (dispatch, getState) => {
        const loadCancellationsPath = '/loadCancellations';
        try {
            dispatch(fetchLoadCancellationsStart());

            const state = getState();
            const loadCancellationsState = {...state.loadCancellations};
            let pagination = {...loadCancellationsState.pagination};
            let searchParams = {...loadCancellationsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const loadCancellationsRes = await axiosAuthenticated.get(loadCancellationsPath, {params: {...searchParams}});

            if (loadCancellationsRes && loadCancellationsRes.status === 200) {
                const loadCancellations = loadCancellationsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                const transformedLoadTransformations = loadCancellations.map((item) => {
                    let cancelledByAccount = null;
                    let cancelledByUser = null;
                    let cancelledByName = null;
                    if (item.entityType === 'SHIPPER' || item.entityType === 'CARRIER') {
                        if (item.entityId !== undefined && item.entityId !== null) {
                            const entityObj = accounts.find(i => i.id === item.entityId);
                            if (entityObj !== undefined && entityObj !== null) {
                                cancelledByAccount = entityObj;
                                cancelledByName = entityObj.name;
                            }
                        }
                    } else if (item.entityType === 'STAFF') {
                        if (item.entityId !== undefined && item.entityId !== null) {
                            const entityObj = staff.find(i => i.id === item.entityId);
                            if (entityObj !== undefined && entityObj !== null) {
                                cancelledByUser = entityObj;
                                cancelledByName = entityObj.firstName + ' ' + entityObj.lastName;
                            }
                        }
                    }

                    return {
                        ...item,
                        cancelledByAccount: cancelledByAccount,
                        cancelledByUser: cancelledByUser,
                        cancelledByName: cancelledByName
                    };
                });

                // Read total count from server
                pagination.total = loadCancellationsRes.data.totalCount;
                pagination.current = loadCancellationsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLoadCancellationsSuccess({
                    records: transformedLoadTransformations,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLoadCancellationsFail({error: error.message}));
        }
    }
};

export const addLoadCancellation = (payload) => {
    return async (dispatch, getState) => {
        const loadCancellationsPath = '/loadCancellations';
        try {
            dispatch(addLoadCancellationStart());

            const loadCancellationsRes = await axiosAuthenticated.post(loadCancellationsPath, {...payload});
            if (loadCancellationsRes && loadCancellationsRes.status === 201) {
                const loadCancellation = loadCancellationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                let cancelledByAccount = null;
                let cancelledByUser = null;
                let cancelledByName = null;
                if (loadCancellation.entityType === 'SHIPPER' || loadCancellation.entityType === 'CARRIER') {
                    if (loadCancellation.entityId !== undefined && loadCancellation.entityId !== null) {
                        const entityObj = accounts.find(i => i.id === loadCancellation.entityId);
                        if (entityObj !== undefined && entityObj !== null) {
                            cancelledByAccount = entityObj;
                            cancelledByName = entityObj.name;
                        }
                    }
                } else if (loadCancellation.entityType === 'STAFF') {
                    if (loadCancellation.entityId !== undefined && loadCancellation.entityId !== null) {
                        const entityObj = staff.find(i => i.id === loadCancellation.entityId);
                        if (entityObj !== undefined && entityObj !== null) {
                            cancelledByUser = entityObj;
                            cancelledByName = entityObj.firstName + ' ' + entityObj.lastName;
                        }
                    }
                }

                let newLoadCancellation = {
                    ...loadCancellation,
                    cancelledByAccount: cancelledByAccount,
                    cancelledByUser: cancelledByUser,
                    cancelledByName: cancelledByName
                };

                dispatch(insertLoadCancellation(newLoadCancellation));

                dispatch(addLoadCancellationSuccess());
                dispatch(addLoadCancellationLoadingClear());
                dispatch(addLoadCancellationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLoadCancellationFail({error: error.message}));
        }
    }
};

export const updateLoadCancellation = (loadCancellationId, payload) => {
    return async (dispatch, getState) => {
        const loadCancellationsPath = '/loadCancellations';
        try {
            dispatch(updateLoadCancellationStart());

            const loadCancellationsRes = await axiosAuthenticated.put(loadCancellationsPath + `/${loadCancellationId}`, {...payload});
            if (loadCancellationsRes && loadCancellationsRes.status === 200) {
                const loadCancellation = loadCancellationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const staff = [...orchestratorState.staff];

                let cancelledByAccount = null;
                let cancelledByUser = null;
                let cancelledByName = null;
                if (loadCancellation.entityType === 'SHIPPER' || loadCancellation.entityType === 'CARRIER') {
                    if (loadCancellation.entityId !== undefined && loadCancellation.entityId !== null) {
                        const entityObj = accounts.find(i => i.id === loadCancellation.entityId);
                        if (entityObj !== undefined && entityObj !== null) {
                            cancelledByAccount = entityObj;
                            cancelledByName = entityObj.name;
                        }
                    }
                } else if (loadCancellation.entityType === 'STAFF') {
                    if (loadCancellation.entityId !== undefined && loadCancellation.entityId !== null) {
                        const entityObj = staff.find(i => i.id === loadCancellation.entityId);
                        if (entityObj !== undefined && entityObj !== null) {
                            cancelledByUser = entityObj;
                            cancelledByName = entityObj.firstName + ' ' + entityObj.lastName;
                        }
                    }
                }

                let updatedLoadCancellation = {
                    ...loadCancellation,
                    cancelledByAccount: cancelledByAccount,
                    cancelledByUser: cancelledByUser,
                    cancelledByName: cancelledByName
                };

                dispatch(changeLoadCancellation(updatedLoadCancellation));
                dispatch(changeSingleLoadCancellation(updatedLoadCancellation));

                dispatch(updateLoadCancellationSuccess());
                dispatch(updateLoadCancellationLoadingClear());
                dispatch(updateLoadCancellationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadCancellationFail({error: error.message}));
        }
    }
};

//#endregion