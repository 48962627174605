import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import {isEmpty, omit} from "lodash";
import {sendLoadEvent, updateLoad} from "./loads";
import moment from 'moment';
import {addFile, deleteFile, updateFileMetaData} from "../../api/fileClient";
import EntityUtils from '../entityUtils';
import enums from "../../shared/enums";

//#region Account Users Functions

export const fetchAccountUsersStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USERS_START
    }
};

export const fetchAccountUsersSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USERS_SUCCESS,
        payload: payload
    }
};

export const fetchAccountUsersFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USERS_FAIL,
        payload: payload
    }
};

export const clearAccountUsers = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_USERS
    }
};

export const fetchAccountUserStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_START
    }
};

export const fetchAccountUserSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_SUCCESS,
        payload: payload
    }
};

export const fetchAccountUserFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const clearAccountUser = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_USER
    }
};

export const addAccountUserStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_START
    }
};

export const addAccountUserSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_SUCCESS
    }
};

export const addAccountUserFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const addAccountUserErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_ERROR_CLEAR
    }
};

export const addAccountUserLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER_LOADING_CLEAR
    }
};

export const updateAccountUserStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_START
    }
};

export const updateAccountUserSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_SUCCESS
    }
};

export const updateAccountUserFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_FAIL,
        payload: payload
    }
};

export const updateAccountUserErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_ERROR_CLEAR
    }
};

export const updateAccountUserLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER_LOADING_CLEAR
    }
};

export const cancelAddAccountUser = () => {
    return {
        type: actionTypes.CANCEL_ADD_ACCOUNT_USER
    }
};

export const cancelUpdateAccountUser = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_ACCOUNT_USER
    }
};

export const insertAccountUser = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_USER,
        payload: payload
    }
};

export const changeAccountUser = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_USER,
        payload: payload
    }
};

export const changeSingleAccountUser = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT_USER,
        payload: payload
    }
};

export const removeAccountUser = (payload) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_USER,
        payload: payload
    }
};

//#endregion

//#region Account Users Methods

export const fetchAccountUsers = (payload) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(fetchAccountUsersStart());

            const state = getState();
            const accountUsersState = {...state.accountUsers};
            let pagination = {...accountUsersState.pagination};
            let searchParams = {...accountUsersState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const accountUsersRes = await axiosAuthenticated.get(accountUsersPath, {params: {...searchParams}});

            if (accountUsersRes && accountUsersRes.status === 200) {
                const accountUsers = accountUsersRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let accountUserIds = accountUsers.map(item => {
                    return !!item && item.id;
                });
                let userProfileIds = accountUsers.map(item => {
                    return !!item.userProfile && item.userProfile.id;
                });
                let verifiedUserIds = accountUsers.filter(item => item.verifiedBy !== undefined && item.verifiedBy !== null).map(item => {
                    return item.verifiedBy;
                });

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [...accountUserIds, ...userProfileIds]
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                let uniqueUserIds = [...new Set([...verifiedUserIds])];
                const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [...uniqueUserIds]});
                if (usersRes && usersRes.status === 200) {
                    users = usersRes.data;
                }

                const transformedAccountUsers = accountUsers.map(item => {
                    // Get Verified By User for the Account User
                    let verifiedByUser = EntityUtils.getUserProfileInfo(item.verifiedBy, users);

                    // Get driver info for the selected Account Users.
                    if (item.isDriver === true) {
                        item = EntityUtils.getDriverInfo(item.id, item, driverDocuments);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    let userFileMetaData = [];
                    if (driverDocuments.length > 0) {
                        userFileMetaData = driverDocuments.filter(document => (!!item.userProfile && (document.entityId === item.userProfile.id)));
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);

                    return {
                        ...item,
                        verifiedByUser: verifiedByUser,
                        account: account,
                        profileImageId: profileImageId
                    };
                });

                // Read total count from server
                pagination.total = accountUsersRes.data.totalCount;
                pagination.current = accountUsersRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchAccountUsersSuccess({
                    records: transformedAccountUsers,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountUsersFail({error: error.message}));
        }
    }
};

export const fetchAccountUser = (id) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(fetchAccountUserStart());

            const accountUsersRes = await axiosAuthenticated.get(accountUsersPath + `/${id}`);
            if (accountUsersRes && accountUsersRes.status === 200) {
                let accountUser = accountUsersRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                    params: {
                        page: 1,
                        size: 10000,
                        entityId: accountUser.id + '|' + (accountUser.userProfile ? accountUser.userProfile.id : "")
                    }
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                if (accountUser.verifiedBy !== undefined && accountUser.verifiedBy !== null) {
                    const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                    if (usersRes && usersRes.status === 200) {
                        users = usersRes.data;
                    }
                }

                // Get Verified By User for the Account User
                let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                // Get driver info for the selected Account User
                if (accountUser.isDriver === true) {
                    accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                accountUser.verifiedByUser = verifiedByUser;
                accountUser.account = account;

                let userFileMetaData = [];
                if (driverDocuments.length > 0) {
                    userFileMetaData = driverDocuments.filter(document => document.entityId === (accountUser.userProfile ? accountUser.userProfile.id : ""));
                }

                let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                accountUser.profileImageId = profileImageId;

                dispatch(fetchAccountUserSuccess({record: accountUser}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountUserFail({error: error.message}));
        }
    }
};

export const addAccountUser = (payload, accountId) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(addAccountUserStart());

            if (payload.isDriver === true && payload.driver !== undefined && payload.driver !== null) {
                let copyOfDriverPayload = {...payload.driver};
                let driver = transformDriverRequest(copyOfDriverPayload);

                if (driver !== undefined && driver !== null) {

                    const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
                    const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
                    const driverLicenseFrontFile = driver.driverLicenseFrontFile;
                    const driverLicenseBackFile = driver.driverLicenseBackFile;
                    const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
                    const commercialLicenseBackFile = driver.commercialLicenseBackFile;
                    const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
                    const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
                    const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
                    const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
                    const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
                    const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
                    const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
                    const mshaLicenseBackFile = driver.mshaLicenseBackFile;

                    const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate;
                    const driverLicenseExpiryDate = driver.driverLicenseExpiryDate;
                    const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate;
                    const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate;
                    const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate;
                    const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate;
                    const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate;

                    const hazmatLicenseNumber = driver.hazmatLicenseNumber;
                    const driverLicenseNumber = driver.driverLicenseNumber;
                    const commercialLicenseNumber = driver.commercialLicenseNumber;
                    const pneumaticLicenseNumber = driver.pneumaticLicenseNumber;
                    const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber;
                    const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber;
                    const mshaLicenseNumber = driver.mshaLicenseNumber;

                    driver = omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
                        "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
                        "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
                        "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
                        "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
                        "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
                        "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseNumber", "driverLicenseNumber",
                        "commercialLicenseNumber", "pneumaticLicenseNumber", "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber",
                        "mshaLicenseNumber");

                    // set the driver as available by default when creating a driver
                    driver.isAvailable = true;

                    payload.driver = driver;

                    // automatically set isVerified to true since a staff member is creating the employee
                    // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
                    const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, {
                        ...payload,
                        accountId: accountId,
                        isVerified: true
                    });
                    if (accountUsersRes && accountUsersRes.status === 201) {
                        let accountUser = accountUsersRes.data;

                        await addDriverFiles(accountUser.id, hazmatLicenseFrontFile, hazmatLicenseBackFile, driverLicenseFrontFile,
                            driverLicenseBackFile, commercialLicenseFrontFile, commercialLicenseBackFile, pneumaticLicenseFrontFile,
                            pneumaticLicenseBackFile, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile,
                            explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, mshaLicenseFrontFile,
                            mshaLicenseBackFile, hazmatLicenseExpiryDate, driverLicenseExpiryDate, commercialLicenseExpiryDate,
                            pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
                            explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber,
                            driverLicenseNumber, commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber,
                            explosiveCertifiedLicenseNumber, mshaLicenseNumber);

                        const state = getState();
                        const orchestratorState = {...state.orchestrator};
                        const accounts = [...orchestratorState.accounts];

                        let driverDocuments = [];
                        const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                            params: {
                                page: 1,
                                size: 10000,
                                entityId: accountUser.id + '|' + accountUser.userProfile.id
                            }
                        });
                        if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                            driverDocuments = driverDocumentsRes.data.data;
                        }

                        let users = [];
                        const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                        if (usersRes && usersRes.status === 200) {
                            users = usersRes.data;
                        }

                        // Get Verified By User for the Account User
                        let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                        // Get driver info for the selected Account User
                        if (accountUser.isDriver === true) {
                            accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                        }

                        // Get Account Info
                        let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                        let userFileMetaData = [];
                        if (driverDocuments.length > 0) {
                            userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                        }

                        let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                        accountUser.profileImageId = profileImageId;

                        let newAccountUser = {
                            ...accountUser,
                            verifiedByUser: verifiedByUser,
                            account: account
                        };

                        dispatch(insertAccountUser(newAccountUser));

                        dispatch(addAccountUserSuccess());
                        dispatch(addAccountUserLoadingClear());
                        dispatch(addAccountUserErrorClear());
                    }
                }
            } else {
                // automatically set isVerified to true since a staff member is creating the employee
                // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
                const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, {
                    ...payload,
                    accountId: accountId,
                    isVerified: true
                });
                if (accountUsersRes && accountUsersRes.status === 201) {
                    let accountUser = accountUsersRes.data;

                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];

                    let driverDocuments = [];
                    const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                        params: {
                            page: 1,
                            size: 10000,
                            entityId: accountUser.id + '|' + accountUser.userProfile.id
                        }
                    });
                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                        driverDocuments = driverDocumentsRes.data.data;
                    }

                    let users = [];
                    const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                    if (usersRes && usersRes.status === 200) {
                        users = usersRes.data;
                    }

                    // Get Verified By User for the Account User
                    let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                    // Get driver info for the selected Account User
                    if (accountUser.isDriver === true) {
                        accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                    let userFileMetaData = [];
                    if (driverDocuments.length > 0) {
                        userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                    accountUser.profileImageId = profileImageId;

                    let newAccountUser = {
                        ...accountUser,
                        verifiedByUser: verifiedByUser,
                        account: account
                    };

                    dispatch(insertAccountUser(newAccountUser));

                    dispatch(addAccountUserSuccess());
                    dispatch(addAccountUserLoadingClear());
                    dispatch(addAccountUserErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAccountUserFail({error: error.message}));
        }
    }
};

export const addAccountUserAndAddToLoad = (payload, accountId, load) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(addAccountUserStart());

            if (payload.isDriver === true && payload.driver !== undefined && payload.driver !== null) {
                let copyOfDriverPayload = {...payload.driver};
                let driver = transformDriverRequest(copyOfDriverPayload);

                if (driver !== undefined && driver !== null) {
                    const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
                    const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
                    const driverLicenseFrontFile = driver.driverLicenseFrontFile;
                    const driverLicenseBackFile = driver.driverLicenseBackFile;
                    const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
                    const commercialLicenseBackFile = driver.commercialLicenseBackFile;
                    const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
                    const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
                    const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
                    const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
                    const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
                    const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
                    const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
                    const mshaLicenseBackFile = driver.mshaLicenseBackFile;

                    const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate;
                    const driverLicenseExpiryDate = driver.driverLicenseExpiryDate;
                    const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate;
                    const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate;
                    const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate;
                    const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate;
                    const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate;

                    const hazmatLicenseNumber = driver.hazmatLicenseNumber;
                    const driverLicenseNumber = driver.driverLicenseNumber;
                    const commercialLicenseNumber = driver.commercialLicenseNumber;
                    const pneumaticLicenseNumber = driver.pneumaticLicenseNumber;
                    const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber;
                    const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber;
                    const mshaLicenseNumber = driver.mshaLicenseNumber;

                    driver = omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
                        "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
                        "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
                        "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
                        "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
                        "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
                        "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseNumber", "driverLicenseNumber",
                        "commercialLicenseNumber", "pneumaticLicenseNumber", "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber",
                        "mshaLicenseNumber");

                    // set the driver as available by default when creating a driver
                    driver.isAvailable = true;

                    // automatically set the driver to be approved since we assume there is a person verifying the documents when the documents are being added
                    driver.isApproved = true;

                    payload.driver = driver;

                    // automatically set isVerified to true since a staff member is creating the employee
                    // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
                    const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, {
                        ...payload,
                        accountId: accountId,
                        isVerified: true
                    });
                    if (accountUsersRes && accountUsersRes.status === 201) {
                        let accountUser = accountUsersRes.data;
                        const firstName = accountUser.firstName;
                        const copyOfLoad = {...load};

                        await addDriverFilesWithApproval(accountUser.id, hazmatLicenseFrontFile, hazmatLicenseBackFile, driverLicenseFrontFile,
                            driverLicenseBackFile, commercialLicenseFrontFile, commercialLicenseBackFile, pneumaticLicenseFrontFile,
                            pneumaticLicenseBackFile, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile,
                            explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, mshaLicenseFrontFile,
                            mshaLicenseBackFile, hazmatLicenseExpiryDate, driverLicenseExpiryDate, commercialLicenseExpiryDate,
                            pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
                            explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber,
                            driverLicenseNumber, commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber,
                            explosiveCertifiedLicenseNumber, mshaLicenseNumber);

                        // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full

                        let driverIdList = copyOfLoad.driverIds !== undefined && copyOfLoad.driverIds !== null && copyOfLoad.driverIds.length > 0 ? [...copyOfLoad.driverIds] : [];

                        if (driverIdList.length > 0) {
                            if (driverIdList.find(i => i === accountUser.id) !== undefined) {
                                throw new Error("The driver you chose is already assigned to this load.");
                            }
                        }

                        let updatedDriverIds = [...driverIdList, accountUser.id];

                        // removes duplicates
                        const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                        //console.log(updatedDriverIds2);
                        let updateLoadPayload = {};
                        updateLoadPayload.driverIds = updatedDriverIds2;

                        dispatch(updateLoad(copyOfLoad.id, updateLoadPayload)); // this call re-fetches the load

                        let hasTeamDriving = copyOfLoad.driverRequirements && copyOfLoad.driverRequirements.length > 0 && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                        if ((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) {
                            // send load event for scheduled
                            dispatch(sendLoadEvent({
                                loadId: copyOfLoad.id,
                                eventType: 'LOAD_SCHEDULED'
                            }));
                        }

                        let newNotification = {
                            message: `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the Pulogix app for more details.`,
                            loadId: copyOfLoad.id,
                            loadIrisId: copyOfLoad.irisId,
                            saveOnly: false,
                            subject: `${copyOfLoad.irisId} You have been assigned to a new load`,
                            severity: "CRITICAL",
                            userIds: [accountUser.userId],
                            loadStatus: copyOfLoad.loadStatus,
                            eventType: 'DRIVER_ASSIGNED'
                        };

                        axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                            //console.log(res);
                        }).catch(err => {
                            logger.logReduxErrorEvent(err, `Error fetching Requests: ${err.message}`, true);
                        });

                        const state = getState();
                        const orchestratorState = {...state.orchestrator};
                        const accounts = [...orchestratorState.accounts];

                        let driverDocuments = [];
                        const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                            params: {
                                page: 1,
                                size: 10000,
                                entityId: accountUser.id + '|' + accountUser.userProfile.id
                            }
                        });
                        if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                            driverDocuments = driverDocumentsRes.data.data;
                        }

                        let users = [];
                        const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                        if (usersRes && usersRes.status === 200) {
                            users = usersRes.data;
                        }

                        // Get Verified By User for the Account User
                        let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                        // Get driver info for the selected Account User
                        if (accountUser.isDriver === true) {
                            accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                        }

                        // Get Account Info
                        let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                        let userFileMetaData = [];
                        if (driverDocuments.length > 0) {
                            userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                        }

                        let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                        accountUser.profileImageId = profileImageId;

                        let newAccountUser = {
                            ...accountUser,
                            verifiedByUser: verifiedByUser,
                            account: account
                        };

                        dispatch(insertAccountUser(newAccountUser));

                        dispatch(addAccountUserSuccess());
                        dispatch(addAccountUserLoadingClear());
                        dispatch(addAccountUserErrorClear());
                    }
                }
            } else {
                // automatically set isVerified to true since a staff member is creating the employee
                // TODO: once we have customer facing, isVerified shouldn't always be set to true depending on who is creating the account user
                const accountUsersRes = await axiosAuthenticated.post(accountUsersPath, {
                    ...payload,
                    accountId: accountId,
                    isVerified: true
                });
                if (accountUsersRes && accountUsersRes.status === 201) {
                    let accountUser = accountUsersRes.data;
                    const firstName = accountUser.firstName;
                    const copyOfLoad = {...load};

                    // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full

                    let driverIdList = copyOfLoad.driverIds !== undefined && copyOfLoad.driverIds !== null && copyOfLoad.driverIds.length > 0 ? [...copyOfLoad.driverIds] : [];

                    if (driverIdList.length > 0) {
                        if (driverIdList.find(i => i === accountUser.id) !== undefined) {
                            throw new Error("The driver you chose is already assigned to this load.");
                        }
                    }

                    let updatedDriverIds = [...driverIdList, accountUser.id];

                    // removes duplicates
                    const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                    //console.log(updatedDriverIds2);
                    let updateLoadPayload = {};
                    updateLoadPayload.driverIds = updatedDriverIds2;

                    dispatch(updateLoad(copyOfLoad.id, updateLoadPayload)); // this call re-fetches the load

                    let hasTeamDriving = copyOfLoad.driverRequirements && copyOfLoad.driverRequirements.length > 0 && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                    if ((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) {
                        // send load event for scheduled
                        dispatch(sendLoadEvent({
                            loadId: copyOfLoad.id,
                            eventType: 'LOAD_SCHEDULED'
                        }));
                    }

                    let newNotification = {
                        message: `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the Pulogix app for more details.`,
                        loadId: copyOfLoad.id,
                        loadIrisId: copyOfLoad.irisId,
                        saveOnly: false,
                        subject: `${copyOfLoad.irisId} You have been assigned to a new load`,
                        severity: "CRITICAL",
                        userIds: [accountUser.userId],
                        loadStatus: copyOfLoad.loadStatus,
                        eventType: 'DRIVER_ASSIGNED'
                    };

                    axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                        //console.log(res);
                    }).catch(err => {
                        logger.logReduxErrorEvent(err, `Error fetching Requests: ${err.message}`, true);
                    });

                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];

                    let driverDocuments = [];
                    const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                        params: {
                            page: 1,
                            size: 10000,
                            entityId: accountUser.id + '|' + accountUser.userProfile.id
                        }
                    });
                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                        driverDocuments = driverDocumentsRes.data.data;
                    }

                    let users = [];
                    const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                    if (usersRes && usersRes.status === 200) {
                        users = usersRes.data;
                    }

                    // Get Verified By User for the Account User
                    let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                    // Get driver info for the selected Account User
                    if (accountUser.isDriver === true) {
                        accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                    let userFileMetaData = [];
                    if (driverDocuments.length > 0) {
                        userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                    accountUser.profileImageId = profileImageId;

                    let newAccountUser = {
                        ...accountUser,
                        verifiedByUser: verifiedByUser,
                        account: account
                    };

                    dispatch(insertAccountUser(newAccountUser));

                    dispatch(addAccountUserSuccess());
                    dispatch(addAccountUserLoadingClear());
                    dispatch(addAccountUserErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAccountUserFail({error: error.message}));
        }
    }
};

export const updateAccountUser = (accountUserId, payload, isDriver) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            if (isDriver === true && payload.driver !== undefined && payload.driver !== null) {
                let copyOfDriverPayload = {...payload.driver};
                let driver = transformDriverRequest(copyOfDriverPayload);

                if (driver !== undefined && driver !== null) {
                    driver = await updateDriverFiles(driver, accountUserId);

                    payload.driver = driver;

                    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
                    if (accountUsersRes && accountUsersRes.status === 200) {
                        let accountUser = accountUsersRes.data;

                        const state = getState();
                        const orchestratorState = {...state.orchestrator};
                        const accounts = [...orchestratorState.accounts];

                        let driverDocuments = [];
                        const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                            params: {
                                page: 1,
                                size: 10000,
                                entityId: accountUser.id + '|' + accountUser.userProfile.id
                            }
                        });
                        if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                            driverDocuments = driverDocumentsRes.data.data;
                        }

                        let users = [];
                        const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                        if (usersRes && usersRes.status === 200) {
                            users = usersRes.data;
                        }

                        // Get Verified By User for the Account User
                        let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                        // Get driver info for the selected Account User
                        if (accountUser.isDriver === true) {
                            accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                        }

                        // Get Account Info
                        let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                        let userFileMetaData = [];
                        if (driverDocuments.length > 0) {
                            userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                        }

                        let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                        accountUser.profileImageId = profileImageId;

                        let updatedAccountUser = {
                            ...accountUser,
                            verifiedByUser: verifiedByUser,
                            account: account
                        };

                        dispatch(changeAccountUser(updatedAccountUser));
                        dispatch(changeSingleAccountUser(updatedAccountUser));

                        dispatch(updateAccountUserSuccess());
                        dispatch(updateAccountUserLoadingClear());
                        dispatch(updateAccountUserErrorClear());
                    }
                }
            } else {
                const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
                if (accountUsersRes && accountUsersRes.status === 200) {
                    let accountUser = accountUsersRes.data;

                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];

                    let driverDocuments = [];
                    const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                        params: {
                            page: 1,
                            size: 10000,
                            entityId: accountUser.id + '|' + accountUser.userProfile.id
                        }
                    });
                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                        driverDocuments = driverDocumentsRes.data.data;
                    }

                    let users = [];
                    const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                    if (usersRes && usersRes.status === 200) {
                        users = usersRes.data;
                    }

                    // Get Verified By User for the Account User
                    let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                    // Get driver info for the selected Account User
                    if (accountUser.isDriver === true) {
                        accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                    let userFileMetaData = [];
                    if (driverDocuments.length > 0) {
                        userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                    accountUser.profileImageId = profileImageId;

                    let updatedAccountUser = {
                        ...accountUser,
                        verifiedByUser: verifiedByUser,
                        account: account
                    };

                    dispatch(changeAccountUser(updatedAccountUser));
                    dispatch(changeSingleAccountUser(updatedAccountUser));

                    dispatch(updateAccountUserSuccess());
                    dispatch(updateAccountUserLoadingClear());
                    dispatch(updateAccountUserErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const updateAccountUserDocument = (accountUserId, payload, entityType) => {
    return async (dispatch) => {
        try {
            dispatch(updateAccountUserStart());

            let filesWereUpdated = await updateDriverFile(payload, accountUserId, entityType);

            // reset approval of driver if the entityType is not staff and the document type is commercial drivers license
            if (entityType !== 'STAFF' && payload.frontDocumentType === 'COMMERCIAL_DRIVERS_LICENSE_FRONT') {
                dispatch(unapproveDriver(accountUserId));
            } else if (filesWereUpdated === true) {
                dispatch(fetchAccountUser(accountUserId));
            }

            dispatch(updateAccountUserSuccess());
            dispatch(updateAccountUserLoadingClear());
            dispatch(updateAccountUserErrorClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const updateAccountUserAndAddToLoad = (accountUserId, payload, isDriver, load) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            if (isDriver === true && payload.driver !== undefined && payload.driver !== null) {
                let copyOfDriverPayload = {...payload.driver};
                let driver = transformDriverRequest(copyOfDriverPayload);

                if (driver !== undefined && driver !== null) {
                    driver = await updateDriverFilesWithApproval(driver, accountUserId);

                    // automatically set the driver to be approved since we assume there is a person verifying the documents when the documents are being added
                    driver.isApproved = true;

                    payload.driver = driver;

                    const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
                    if (accountUsersRes && accountUsersRes.status === 200) {
                        let accountUser = accountUsersRes.data;
                        const firstName = accountUser.firstName;
                        const copyOfLoad = {...load};

                        // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full
                        let driverIdList = copyOfLoad.driverIds !== undefined && copyOfLoad.driverIds !== null && copyOfLoad.driverIds.length > 0 ? [...copyOfLoad.driverIds] : [];

                        if (driverIdList.length > 0) {
                            if (driverIdList.find(i => i === accountUser.id) !== undefined) {
                                throw new Error("The driver you chose is already assigned to this load.");
                            }
                        }

                        let updatedDriverIds = [...driverIdList, accountUser.id];

                        // removes duplicates
                        const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                        //console.log(updatedDriverIds2);
                        let updatedLoadPayload = {};
                        updatedLoadPayload.driverIds = updatedDriverIds2;

                        dispatch(updateLoad(copyOfLoad.id, updatedLoadPayload)); // this call re-fetches the load

                        let hasTeamDriving = copyOfLoad.driverRequirements && copyOfLoad.driverRequirements.length > 0 && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                        if ((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) {
                            // send load event for scheduled
                            dispatch(sendLoadEvent({
                                loadId: copyOfLoad.id,
                                eventType: 'LOAD_SCHEDULED'
                            }));
                        }

                        let newNotification = {
                            message: `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the Pulogix app for more details.`,
                            loadId: copyOfLoad.id,
                            loadIrisId: copyOfLoad.irisId,
                            saveOnly: false,
                            subject: `${copyOfLoad.irisId} You have been assigned to a new load`,
                            severity: "CRITICAL",
                            userIds: [accountUser.userId],
                            loadStatus: copyOfLoad.loadStatus,
                            eventType: 'DRIVER_ASSIGNED'
                        };

                        axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                            //console.log(res);
                        }).catch(err => {
                            logger.logReduxErrorEvent(err, `Error fetching Requests: ${err.message}`, true);
                        });

                        const state = getState();
                        const orchestratorState = {...state.orchestrator};
                        const accounts = [...orchestratorState.accounts];

                        let driverDocuments = [];
                        const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                            params: {
                                page: 1,
                                size: 10000,
                                entityId: accountUser.id + '|' + accountUser.userProfile.id
                            }
                        });
                        if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                            driverDocuments = driverDocumentsRes.data.data;
                        }

                        let users = [];
                        const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                        if (usersRes && usersRes.status === 200) {
                            users = usersRes.data;
                        }

                        // Get Verified By User for the Account User
                        let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                        // Get driver info for the selected Account User
                        if (accountUser.isDriver === true) {
                            accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                        }

                        // Get Account Info
                        let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                        let userFileMetaData = [];
                        if (driverDocuments.length > 0) {
                            userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                        }

                        let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                        accountUser.profileImageId = profileImageId;

                        let updatedAccountUser = {
                            ...accountUser,
                            verifiedByUser: verifiedByUser,
                            account: account
                        };

                        dispatch(changeAccountUser(updatedAccountUser));
                        dispatch(changeSingleAccountUser(updatedAccountUser));

                        dispatch(updateAccountUserSuccess());
                        dispatch(updateAccountUserLoadingClear());
                        dispatch(updateAccountUserErrorClear());
                    }
                }
            } else {
                const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
                if (accountUsersRes && accountUsersRes.status === 200) {
                    let accountUser = accountUsersRes.data;
                    const firstName = accountUser.firstName;
                    const copyOfLoad = {...load};

                    // add driver to load and update status of load to SCHEDULED if number of drivers assigned is full
                    let driverIdList = copyOfLoad.driverIds !== undefined && copyOfLoad.driverIds !== null && copyOfLoad.driverIds.length > 0 ? [...copyOfLoad.driverIds] : [];

                    if (driverIdList.length > 0) {
                        if (driverIdList.find(i => i === accountUser.id) !== undefined) {
                            throw new Error("The driver you chose is already assigned to this load.");
                        }
                    }

                    let updatedDriverIds = [...driverIdList, accountUser.id];

                    // removes duplicates
                    const updatedDriverIds2 = [...new Set(updatedDriverIds)];
                    //console.log(updatedDriverIds2);
                    let updatedLoadPayload = {};
                    updatedLoadPayload.driverIds = updatedDriverIds2;

                    dispatch(updateLoad(copyOfLoad.id, updatedLoadPayload)); // this call re-fetches the load

                    let hasTeamDriving = copyOfLoad.driverRequirements && copyOfLoad.driverRequirements.length > 0 && copyOfLoad.driverRequirements.includes('TEAM_DRIVING') ? true : false;
                    if ((updatedDriverIds2.length === 1 && hasTeamDriving === false) || (updatedDriverIds2.length === 2 && hasTeamDriving === true)) {
                        // send load event for scheduled
                        dispatch(sendLoadEvent({
                            loadId: copyOfLoad.id,
                            eventType: 'LOAD_SCHEDULED'
                        }));
                    }

                    let newNotification = {
                        message: `Hi ${firstName}, you have been assigned to a new load: ${copyOfLoad.irisId}. Please open up the Pulogix app for more details.`,
                        loadId: copyOfLoad.id,
                        loadIrisId: copyOfLoad.irisId,
                        saveOnly: false,
                        subject: `${copyOfLoad.irisId} You have been assigned to a new load`,
                        severity: "CRITICAL",
                        userIds: [accountUser.userId],
                        loadStatus: copyOfLoad.loadStatus,
                        eventType: 'DRIVER_ASSIGNED'
                    };

                    axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                        //console.log(res);
                    }).catch(err => {
                        logger.logReduxErrorEvent(err, `Error fetching Requests: ${err.message}`, true);
                    });

                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];

                    let driverDocuments = [];
                    const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                        params: {
                            page: 1,
                            size: 10000,
                            entityId: accountUser.id + '|' + accountUser.userProfile.id
                        }
                    });
                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                        driverDocuments = driverDocumentsRes.data.data;
                    }

                    let users = [];
                    const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                    if (usersRes && usersRes.status === 200) {
                        users = usersRes.data;
                    }

                    // Get Verified By User for the Account User
                    let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                    // Get driver info for the selected Account User
                    if (accountUser.isDriver === true) {
                        accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                    }

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                    let userFileMetaData = [];
                    if (driverDocuments.length > 0) {
                        userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                    accountUser.profileImageId = profileImageId;

                    let updatedAccountUser = {
                        ...accountUser,
                        verifiedByUser: verifiedByUser,
                        account: account
                    };

                    dispatch(changeAccountUser(updatedAccountUser));
                    dispatch(changeSingleAccountUser(updatedAccountUser));

                    dispatch(updateAccountUserSuccess());
                    dispatch(updateAccountUserLoadingClear());
                    dispatch(updateAccountUserErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const approveDriver = (accountUserId) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            let payload = {};
            payload.driver = {};
            payload.driver.isApproved = true;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
            if (accountUsersRes && accountUsersRes.status === 200) {
                let accountUser = accountUsersRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                    params: {
                        page: 1,
                        size: 10000,
                        entityId: accountUser.id + '|' + accountUser.userProfile.id
                    }
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                if (usersRes && usersRes.status === 200) {
                    users = usersRes.data;
                }

                // Get Verified By User for the Account User
                let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                // Get driver info for the selected Account User
                if (accountUser.isDriver === true) {
                    accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                let userFileMetaData = [];
                if (driverDocuments.length > 0) {
                    userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                }

                let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                accountUser.profileImageId = profileImageId;

                let updatedAccountUser = {
                    ...accountUser,
                    verifiedByUser: verifiedByUser,
                    account: account
                };

                dispatch(changeAccountUser(updatedAccountUser));
                dispatch(changeSingleAccountUser(updatedAccountUser));

                dispatch(updateAccountUserSuccess());
                dispatch(updateAccountUserLoadingClear());
                dispatch(updateAccountUserErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const unapproveDriver = (accountUserId) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            let payload = {};
            payload.driver = {};
            payload.driver.isApproved = false;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
            if (accountUsersRes && accountUsersRes.status === 200) {
                let accountUser = accountUsersRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                    params: {
                        page: 1,
                        size: 10000,
                        entityId: accountUser.id + '|' + accountUser.userProfile.id
                    }
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                if (usersRes && usersRes.status === 200) {
                    users = usersRes.data;
                }

                // Get Verified By User for the Account User
                let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                // Get driver info for the selected Account User
                if (accountUser.isDriver === true) {
                    accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                let userFileMetaData = [];
                if (driverDocuments.length > 0) {
                    userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                }

                let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                accountUser.profileImageId = profileImageId;

                let updatedAccountUser = {
                    ...accountUser,
                    verifiedByUser: verifiedByUser,
                    account: account
                };

                dispatch(changeAccountUser(updatedAccountUser));
                dispatch(changeSingleAccountUser(updatedAccountUser));

                dispatch(updateAccountUserSuccess());
                dispatch(updateAccountUserLoadingClear());
                dispatch(updateAccountUserErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const markDriverAvailable = (accountUserId) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            let payload = {};
            payload.driver = {};
            payload.driver.isAvailable = true;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
            if (accountUsersRes && accountUsersRes.status === 200) {
                let accountUser = accountUsersRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                    params: {
                        page: 1,
                        size: 10000,
                        entityId: accountUser.id + '|' + accountUser.userProfile.id
                    }
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                if (usersRes && usersRes.status === 200) {
                    users = usersRes.data;
                }

                // Get Verified By User for the Account User
                let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                // Get driver info for the selected Account User
                if (accountUser.isDriver === true) {
                    accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                let userFileMetaData = [];
                if (driverDocuments.length > 0) {
                    userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                }

                let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                accountUser.profileImageId = profileImageId;

                let updatedAccountUser = {
                    ...accountUser,
                    verifiedByUser: verifiedByUser,
                    account: account
                };

                dispatch(changeAccountUser(updatedAccountUser));
                dispatch(changeSingleAccountUser(updatedAccountUser));

                dispatch(updateAccountUserSuccess());
                dispatch(updateAccountUserLoadingClear());
                dispatch(updateAccountUserErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

export const markDriverUnavailable = (accountUserId) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        try {
            dispatch(updateAccountUserStart());

            let payload = {};
            payload.driver = {};
            payload.driver.isAvailable = false;

            const accountUsersRes = await axiosAuthenticated.put(accountUsersPath + `/${accountUserId}`, {...payload});
            if (accountUsersRes && accountUsersRes.status === 200) {
                let accountUser = accountUsersRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                let driverDocuments = [];
                const driverDocumentsRes = await axiosAuthenticated.get('/documents', {
                    params: {
                        page: 1,
                        size: 10000,
                        entityId: accountUser.id + '|' + accountUser.userProfile.id
                    }
                });
                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                    driverDocuments = driverDocumentsRes.data.data;
                }

                let users = [];
                const usersRes = await axiosAuthenticated.post('/systemUsers/bulk/get', {userIds: [accountUser.verifiedBy]});
                if (usersRes && usersRes.status === 200) {
                    users = usersRes.data;
                }

                // Get Verified By User for the Account User
                let verifiedByUser = EntityUtils.getUserProfileInfo(accountUser.verifiedBy, users);

                // Get driver info for the selected Account User
                if (accountUser.isDriver === true) {
                    accountUser = EntityUtils.getDriverInfo(accountUser.id, accountUser, driverDocuments);
                }

                // Get Account Info
                let account = EntityUtils.getAccountInfo(accountUser.accountId, accounts);

                let userFileMetaData = [];
                if (driverDocuments.length > 0) {
                    userFileMetaData = driverDocuments.filter(document => document.entityId === accountUser.userProfile.id);
                }

                let {profileImageId} = EntityUtils.getUserFileIds(userFileMetaData);
                accountUser.profileImageId = profileImageId;

                let updatedAccountUser = {
                    ...accountUser,
                    verifiedByUser: verifiedByUser,
                    account: account
                };

                dispatch(changeAccountUser(updatedAccountUser));
                dispatch(changeSingleAccountUser(updatedAccountUser));

                dispatch(updateAccountUserSuccess());
                dispatch(updateAccountUserLoadingClear());
                dispatch(updateAccountUserErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountUserFail({error: error.message}));
        }
    }
};

const addDriverFiles = async (entityId, hazmatLicenseFrontFile, hazmatLicenseBackFile, driverLicenseFrontFile,
                              driverLicenseBackFile, commercialLicenseFrontFile, commercialLicenseBackFile, pneumaticLicenseFrontFile,
                              pneumaticLicenseBackFile, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile,
                              explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, mshaLicenseFrontFile,
                              mshaLicenseBackFile, hazmatLicenseExpiryDate, driverLicenseExpiryDate, commercialLicenseExpiryDate,
                              pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
                              explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber,
                              driverLicenseNumber, commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber,
                              explosiveCertifiedLicenseNumber, mshaLicenseNumber) => {

    if (hazmatLicenseFrontFile !== undefined && hazmatLicenseFrontFile !== null && hazmatLicenseExpiryDate !== undefined && hazmatLicenseExpiryDate !== null) {
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, null, null, hazmatLicenseNumber);
    }

    if (hazmatLicenseBackFile !== undefined && hazmatLicenseBackFile !== null && hazmatLicenseExpiryDate !== undefined && hazmatLicenseExpiryDate !== null) {
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, null, null, hazmatLicenseNumber);
    }

    if (driverLicenseFrontFile !== undefined && driverLicenseFrontFile !== null && driverLicenseExpiryDate !== undefined && driverLicenseExpiryDate !== null) {
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, null, null, driverLicenseNumber);
    }

    if (driverLicenseBackFile !== undefined && driverLicenseBackFile !== null && driverLicenseExpiryDate !== undefined && driverLicenseExpiryDate !== null) {
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, null, null, driverLicenseNumber);
    }

    if (commercialLicenseFrontFile !== undefined && commercialLicenseFrontFile !== null && commercialLicenseExpiryDate !== undefined && commercialLicenseExpiryDate !== null) {
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, null, null, commercialLicenseNumber);
    }

    if (commercialLicenseBackFile !== undefined && commercialLicenseBackFile !== null && commercialLicenseExpiryDate !== undefined && commercialLicenseExpiryDate !== null) {
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, null, null, commercialLicenseNumber);
    }

    if (pneumaticLicenseFrontFile !== undefined && pneumaticLicenseFrontFile !== null && pneumaticLicenseExpiryDate !== undefined && pneumaticLicenseExpiryDate !== null) {
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, null, null, pneumaticLicenseNumber);
    }

    if (pneumaticLicenseBackFile !== undefined && pneumaticLicenseBackFile !== null && pneumaticLicenseExpiryDate !== undefined && pneumaticLicenseExpiryDate !== null) {
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, null, null, pneumaticLicenseNumber);
    }

    if (mcCertificateLicenseFrontFile !== undefined && mcCertificateLicenseFrontFile !== null && mcCertificateLicenseExpiryDate !== undefined && mcCertificateLicenseExpiryDate !== null) {
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, null, null, mcCertificateLicenseNumber);
    }

    if (mcCertificateLicenseBackFile !== undefined && mcCertificateLicenseBackFile !== null && mcCertificateLicenseExpiryDate !== undefined && mcCertificateLicenseExpiryDate !== null) {
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, null, null, mcCertificateLicenseNumber);
    }

    if (explosiveCertifiedLicenseFrontFile !== undefined && explosiveCertifiedLicenseFrontFile !== null && explosiveCertifiedLicenseExpiryDate !== undefined && explosiveCertifiedLicenseExpiryDate !== null) {
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, null, null, explosiveCertifiedLicenseNumber);
    }

    if (explosiveCertifiedLicenseBackFile !== undefined && explosiveCertifiedLicenseBackFile !== null && explosiveCertifiedLicenseExpiryDate !== undefined && explosiveCertifiedLicenseExpiryDate !== null) {
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, null, null, explosiveCertifiedLicenseNumber);
    }

    if (mshaLicenseFrontFile !== undefined && mshaLicenseFrontFile !== null && mshaLicenseExpiryDate !== undefined && mshaLicenseExpiryDate !== null) {
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, null, null, mshaLicenseNumber);
    }

    if (mshaLicenseBackFile !== undefined && mshaLicenseBackFile !== null && mshaLicenseExpiryDate !== undefined && mshaLicenseExpiryDate !== null) {
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, null, null, mshaLicenseNumber);
    }
};

const addDriverFilesWithApproval = async (entityId, hazmatLicenseFrontFile, hazmatLicenseBackFile, driverLicenseFrontFile,
                                          driverLicenseBackFile, commercialLicenseFrontFile, commercialLicenseBackFile, pneumaticLicenseFrontFile,
                                          pneumaticLicenseBackFile, mcCertificateLicenseFrontFile, mcCertificateLicenseBackFile,
                                          explosiveCertifiedLicenseFrontFile, explosiveCertifiedLicenseBackFile, mshaLicenseFrontFile,
                                          mshaLicenseBackFile, hazmatLicenseExpiryDate, driverLicenseExpiryDate, commercialLicenseExpiryDate,
                                          pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
                                          explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber,
                                          driverLicenseNumber, commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber,
                                          explosiveCertifiedLicenseNumber, mshaLicenseNumber) => {

    if (hazmatLicenseFrontFile !== undefined && hazmatLicenseFrontFile !== null && hazmatLicenseExpiryDate !== undefined && hazmatLicenseExpiryDate !== null) {
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, true, 'APPROVED', hazmatLicenseNumber);
    }

    if (hazmatLicenseBackFile !== undefined && hazmatLicenseBackFile !== null && hazmatLicenseExpiryDate !== undefined && hazmatLicenseExpiryDate !== null) {
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, true, 'APPROVED', hazmatLicenseNumber);
    }

    if (driverLicenseFrontFile !== undefined && driverLicenseFrontFile !== null && driverLicenseExpiryDate !== undefined && driverLicenseExpiryDate !== null) {
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, true, 'APPROVED', driverLicenseNumber);
    }

    if (driverLicenseBackFile !== undefined && driverLicenseBackFile !== null && driverLicenseExpiryDate !== undefined && driverLicenseExpiryDate !== null) {
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, true, 'APPROVED', driverLicenseNumber);
    }

    if (commercialLicenseFrontFile !== undefined && commercialLicenseFrontFile !== null && commercialLicenseExpiryDate !== undefined && commercialLicenseExpiryDate !== null) {
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, true, 'APPROVED', commercialLicenseNumber);
    }

    if (commercialLicenseBackFile !== undefined && commercialLicenseBackFile !== null && commercialLicenseExpiryDate !== undefined && commercialLicenseExpiryDate !== null) {
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, true, 'APPROVED', commercialLicenseNumber);
    }

    if (pneumaticLicenseFrontFile !== undefined && pneumaticLicenseFrontFile !== null && pneumaticLicenseExpiryDate !== undefined && pneumaticLicenseExpiryDate !== null) {
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, true, 'APPROVED', pneumaticLicenseNumber);
    }

    if (pneumaticLicenseBackFile !== undefined && pneumaticLicenseBackFile !== null && pneumaticLicenseExpiryDate !== undefined && pneumaticLicenseExpiryDate !== null) {
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, true, 'APPROVED', pneumaticLicenseNumber);
    }

    if (mcCertificateLicenseFrontFile !== undefined && mcCertificateLicenseFrontFile !== null && mcCertificateLicenseExpiryDate !== undefined && mcCertificateLicenseExpiryDate !== null) {
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, true, 'APPROVED', mcCertificateLicenseNumber);
    }

    if (mcCertificateLicenseBackFile !== undefined && mcCertificateLicenseBackFile !== null && mcCertificateLicenseExpiryDate !== undefined && mcCertificateLicenseExpiryDate !== null) {
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, true, 'APPROVED', mcCertificateLicenseNumber);
    }

    if (explosiveCertifiedLicenseFrontFile !== undefined && explosiveCertifiedLicenseFrontFile !== null && explosiveCertifiedLicenseExpiryDate !== undefined && explosiveCertifiedLicenseExpiryDate !== null) {
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, true, 'APPROVED', explosiveCertifiedLicenseNumber);
    }

    if (explosiveCertifiedLicenseBackFile !== undefined && explosiveCertifiedLicenseBackFile !== null && explosiveCertifiedLicenseExpiryDate !== undefined && explosiveCertifiedLicenseExpiryDate !== null) {
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, true, 'APPROVED', explosiveCertifiedLicenseNumber);
    }

    if (mshaLicenseFrontFile !== undefined && mshaLicenseFrontFile !== null && mshaLicenseExpiryDate !== undefined && mshaLicenseExpiryDate !== null) {
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, true, 'APPROVED', mshaLicenseNumber);
    }

    if (mshaLicenseBackFile !== undefined && mshaLicenseBackFile !== null && mshaLicenseExpiryDate !== undefined && mshaLicenseExpiryDate !== null) {
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, true, 'APPROVED', mshaLicenseNumber);
    }
};

const updateDriverFiles = async (driver, entityId) => {

    const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
    const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
    const driverLicenseFrontFile = driver.driverLicenseFrontFile;
    const driverLicenseBackFile = driver.driverLicenseBackFile;
    const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
    const commercialLicenseBackFile = driver.commercialLicenseBackFile;
    const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
    const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
    const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
    const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
    const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
    const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
    const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
    const mshaLicenseBackFile = driver.mshaLicenseBackFile;

    const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate !== undefined && driver.hazmatLicenseExpiryDate !== null ? driver.hazmatLicenseExpiryDate : null;
    const driverLicenseExpiryDate = driver.driverLicenseExpiryDate !== undefined && driver.driverLicenseExpiryDate !== null ? driver.driverLicenseExpiryDate : null;
    const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate !== undefined && driver.commercialLicenseExpiryDate !== null ? driver.commercialLicenseExpiryDate : null;
    const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate !== undefined && driver.pneumaticLicenseExpiryDate !== null ? driver.pneumaticLicenseExpiryDate : null;
    const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate !== undefined && driver.mcCertificateLicenseExpiryDate !== null ? driver.mcCertificateLicenseExpiryDate : null;
    const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate !== undefined && driver.explosiveCertifiedLicenseExpiryDate !== null ? driver.explosiveCertifiedLicenseExpiryDate : null;
    const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate !== undefined && driver.mshaLicenseExpiryDate !== null ? driver.mshaLicenseExpiryDate : null;

    const hazmatLicenseNumber = driver.hazmatLicenseNumber !== undefined && driver.hazmatLicenseNumber !== null ? driver.hazmatLicenseNumber : null;
    const driverLicenseNumber = driver.driverLicenseNumber !== undefined && driver.driverLicenseNumber !== null ? driver.driverLicenseNumber : null;
    const commercialLicenseNumber = driver.commercialLicenseNumber !== undefined && driver.commercialLicenseNumber !== null ? driver.commercialLicenseNumber : null;
    const pneumaticLicenseNumber = driver.pneumaticLicenseNumber !== undefined && driver.pneumaticLicenseNumber !== null ? driver.pneumaticLicenseNumber : null;
    const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber !== undefined && driver.mcCertificateLicenseNumber !== null ? driver.mcCertificateLicenseNumber : null;
    const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber !== undefined && driver.explosiveCertifiedLicenseNumber !== null ? driver.explosiveCertifiedLicenseNumber : null;
    const mshaLicenseNumber = driver.mshaLicenseNumber !== undefined && driver.mshaLicenseNumber !== null ? driver.mshaLicenseNumber : null;

    const hazmatLicenseFrontFileId = driver.hazmatLicenseFrontFileId;
    const hazmatLicenseBackFileId = driver.hazmatLicenseBackFileId;
    const driverLicenseFrontFileId = driver.driverLicenseFrontFileId;
    const driverLicenseBackFileId = driver.driverLicenseBackFileId;
    const commercialLicenseFrontFileId = driver.commercialLicenseFrontFileId;
    const commercialLicenseBackFileId = driver.commercialLicenseBackFileId;
    const pneumaticLicenseFrontFileId = driver.pneumaticLicenseFrontFileId;
    const pneumaticLicenseBackFileId = driver.pneumaticLicenseBackFileId;
    const mcCertificateLicenseFrontFileId = driver.mcCertificateLicenseFrontFileId;
    const mcCertificateLicenseBackFileId = driver.mcCertificateLicenseBackFileId;
    const explosiveCertifiedLicenseFrontFileId = driver.explosiveCertifiedLicenseFrontFileId;
    const explosiveCertifiedLicenseBackFileId = driver.explosiveCertifiedLicenseBackFileId;
    const mshaLicenseFrontFileId = driver.mshaLicenseFrontFileId;
    const mshaLicenseBackFileId = driver.mshaLicenseBackFileId;

    if (hazmatLicenseFrontFile) {
        await deleteFile(hazmatLicenseFrontFileId);
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, null, null, hazmatLicenseNumber);
    } else if (hazmatLicenseFrontFileId) {
        if (hazmatLicenseExpiryDate !== null || hazmatLicenseNumber !== null) {
            await updateFileMetaData(hazmatLicenseFrontFileId, {
                expiryDate: hazmatLicenseExpiryDate,
                licenseNumber: hazmatLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (hazmatLicenseBackFile) {
        await deleteFile(hazmatLicenseBackFileId);
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, null, null, hazmatLicenseNumber);
    } else if (hazmatLicenseBackFileId) {
        if (hazmatLicenseExpiryDate !== null || hazmatLicenseNumber !== null) {
            await updateFileMetaData(hazmatLicenseBackFileId, {
                expiryDate: hazmatLicenseExpiryDate,
                licenseNumber: hazmatLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (driverLicenseFrontFile) {
        await deleteFile(driverLicenseFrontFileId);
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, null, null, driverLicenseNumber);
    } else if (driverLicenseFrontFileId) {
        if (driverLicenseExpiryDate !== null || driverLicenseNumber !== null) {
            await updateFileMetaData(driverLicenseFrontFileId, {
                expiryDate: driverLicenseExpiryDate,
                licenseNumber: driverLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (driverLicenseBackFile) {
        await deleteFile(driverLicenseBackFileId);
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, null, null, driverLicenseNumber);
    } else if (driverLicenseBackFileId) {
        if (driverLicenseExpiryDate !== null || driverLicenseNumber !== null) {
            await updateFileMetaData(driverLicenseBackFileId, {
                expiryDate: driverLicenseExpiryDate,
                licenseNumber: driverLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (commercialLicenseFrontFile) {
        await deleteFile(commercialLicenseFrontFileId);
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, null, null, commercialLicenseNumber);
    } else if (commercialLicenseFrontFileId) {
        if (commercialLicenseExpiryDate !== null || commercialLicenseNumber !== null) {
            await updateFileMetaData(commercialLicenseFrontFileId, {
                expiryDate: commercialLicenseExpiryDate,
                licenseNumber: commercialLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (commercialLicenseBackFile) {
        await deleteFile(commercialLicenseBackFileId);
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, null, null, commercialLicenseNumber);
    } else if (commercialLicenseBackFileId) {
        if (commercialLicenseExpiryDate !== null || commercialLicenseNumber !== null) {
            await updateFileMetaData(commercialLicenseBackFileId, {
                expiryDate: commercialLicenseExpiryDate,
                licenseNumber: commercialLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (pneumaticLicenseFrontFile) {
        await deleteFile(pneumaticLicenseFrontFileId);
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, null, null, pneumaticLicenseNumber);
    } else if (pneumaticLicenseFrontFileId) {
        if (pneumaticLicenseExpiryDate !== null || pneumaticLicenseNumber !== null) {
            await updateFileMetaData(pneumaticLicenseFrontFileId, {
                expiryDate: pneumaticLicenseExpiryDate,
                licenseNumber: pneumaticLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (pneumaticLicenseBackFile) {
        await deleteFile(pneumaticLicenseBackFileId);
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, null, null, pneumaticLicenseNumber);
    } else if (pneumaticLicenseBackFileId) {
        if (pneumaticLicenseExpiryDate !== null || pneumaticLicenseNumber !== null) {
            await updateFileMetaData(pneumaticLicenseBackFileId, {
                expiryDate: pneumaticLicenseExpiryDate,
                licenseNumber: pneumaticLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (mcCertificateLicenseFrontFile) {
        await deleteFile(mcCertificateLicenseFrontFileId);
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, null, null, mcCertificateLicenseNumber);
    } else if (mcCertificateLicenseFrontFileId) {
        if (mcCertificateLicenseExpiryDate !== null || mcCertificateLicenseNumber !== null) {
            await updateFileMetaData(mcCertificateLicenseFrontFileId, {
                expiryDate: mcCertificateLicenseExpiryDate,
                licenseNumber: mcCertificateLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (mcCertificateLicenseBackFile) {
        await deleteFile(mcCertificateLicenseBackFileId);
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, null, null, mcCertificateLicenseNumber);
    } else if (mcCertificateLicenseBackFileId) {
        if (mcCertificateLicenseExpiryDate !== null || mcCertificateLicenseNumber !== null) {
            await updateFileMetaData(mcCertificateLicenseBackFileId, {
                expiryDate: mcCertificateLicenseExpiryDate,
                licenseNumber: mcCertificateLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (explosiveCertifiedLicenseFrontFile) {
        await deleteFile(explosiveCertifiedLicenseFrontFileId);
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, null, null, explosiveCertifiedLicenseNumber);
    } else if (explosiveCertifiedLicenseFrontFileId) {
        if (explosiveCertifiedLicenseExpiryDate !== null || explosiveCertifiedLicenseNumber !== null) {
            await updateFileMetaData(explosiveCertifiedLicenseFrontFileId, {
                expiryDate: explosiveCertifiedLicenseExpiryDate,
                licenseNumber: explosiveCertifiedLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (explosiveCertifiedLicenseBackFile) {
        await deleteFile(explosiveCertifiedLicenseBackFileId);
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, null, null, explosiveCertifiedLicenseNumber);
    } else if (explosiveCertifiedLicenseBackFileId) {
        if (explosiveCertifiedLicenseExpiryDate !== null || explosiveCertifiedLicenseNumber !== null) {
            await updateFileMetaData(explosiveCertifiedLicenseBackFileId, {
                expiryDate: explosiveCertifiedLicenseExpiryDate,
                licenseNumber: explosiveCertifiedLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (mshaLicenseFrontFile) {
        await deleteFile(mshaLicenseFrontFileId);
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, null, null, mshaLicenseNumber);
    } else if (mshaLicenseFrontFileId) {
        if (mshaLicenseExpiryDate !== null || mshaLicenseNumber !== null) {
            await updateFileMetaData(mshaLicenseFrontFileId, {
                expiryDate: mshaLicenseExpiryDate,
                licenseNumber: mshaLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    if (mshaLicenseBackFile) {
        await deleteFile(mshaLicenseBackFileId);
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, null, null, mshaLicenseNumber);
    } else if (mshaLicenseBackFileId) {
        if (mshaLicenseExpiryDate !== null || mshaLicenseNumber !== null) {
            await updateFileMetaData(mshaLicenseBackFileId, {
                expiryDate: mshaLicenseExpiryDate,
                licenseNumber: mshaLicenseNumber,
                isReviewed: false,
                reviewStatus: 'PENDING'
            });
        }
    }

    return omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
        "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
        "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
        "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
        "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
        "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
        "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseFrontFileId", "hazmatLicenseBackFileId",
        "driverLicenseFrontFileId", "driverLicenseBackFileId", "commercialLicenseFrontFileId", "commercialLicenseBackFileId",
        "pneumaticLicenseFrontFileId", "pneumaticLicenseBackFileId", "mcCertificateLicenseFrontFileId", "mcCertificateLicenseBackFileId",
        "explosiveCertifiedLicenseFrontFileId", "explosiveCertifiedLicenseBackFileId", "mshaLicenseFrontFileId", "mshaLicenseBackFileId",
        "hazmatLicenseNumber", "driverLicenseNumber", "commercialLicenseNumber", "pneumaticLicenseNumber",
        "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber", "mshaLicenseNumber");
};

const updateDriverFile = async (payload, entityId, entityType) => {
    let filesWereUpdated = false;

    const frontFile = payload.frontFile;
    const backFile = payload.backFile;
    const frontDocumentType = payload.frontDocumentType;
    const backDocumentType = payload.backDocumentType;
    const expiryDate = payload.expiryDate !== undefined && payload.expiryDate !== null ? payload.expiryDate : null;
    const licenseNumber = payload.licenseNumber !== undefined && payload.licenseNumber !== null ? payload.licenseNumber : null;
    const frontFileId = payload.frontFileId;
    const backFileId = payload.backFileId;

    let frontFileData = null;
    let backFileData = null;

    if (frontFile) {
        await deleteFile(frontFileId);
        frontFileData = await addFile(frontFile, "DRIVER", entityId, frontDocumentType, enums.DriverDocumentTypes.getValueByName(frontDocumentType), "", "ALL", expiryDate, null, null, licenseNumber);
        filesWereUpdated = true;
    } else if (frontFileId) {
        if (expiryDate !== null || licenseNumber !== null) {
            let updatedData = {
                expiryDate: expiryDate,
                licenseNumber: licenseNumber
            };

            if (entityType !== 'STAFF') {
                updatedData.isReviewed = false;
                updatedData.reviewStatus = 'PENDING';
            }

            await updateFileMetaData(frontFileId, updatedData);
            filesWereUpdated = true;
        }
    }

    if (backFile) {
        await deleteFile(backFileId);
        backFileData = await addFile(backFile, "DRIVER", entityId, backDocumentType, enums.DriverDocumentTypes.getValueByName(backDocumentType), "", "ALL", expiryDate, null, null, licenseNumber);
        filesWereUpdated = true;
    } else if (backFileId) {
        if (expiryDate !== null || licenseNumber !== null) {
            let updatedData = {
                expiryDate: expiryDate,
                licenseNumber: licenseNumber
            };

            if (entityType !== 'STAFF') {
                updatedData.isReviewed = false;
                updatedData.reviewStatus = 'PENDING';
            }

            await updateFileMetaData(backFileId, updatedData);
            filesWereUpdated = true;
        }
    }

    return filesWereUpdated;
};

const updateDriverFilesWithApproval = async (driver, entityId) => {

    const hazmatLicenseFrontFile = driver.hazmatLicenseFrontFile;
    const hazmatLicenseBackFile = driver.hazmatLicenseBackFile;
    const driverLicenseFrontFile = driver.driverLicenseFrontFile;
    const driverLicenseBackFile = driver.driverLicenseBackFile;
    const commercialLicenseFrontFile = driver.commercialLicenseFrontFile;
    const commercialLicenseBackFile = driver.commercialLicenseBackFile;
    const pneumaticLicenseFrontFile = driver.pneumaticLicenseFrontFile;
    const pneumaticLicenseBackFile = driver.pneumaticLicenseBackFile;
    const mcCertificateLicenseFrontFile = driver.mcCertificateLicenseFrontFile;
    const mcCertificateLicenseBackFile = driver.mcCertificateLicenseBackFile;
    const explosiveCertifiedLicenseFrontFile = driver.explosiveCertifiedLicenseFrontFile;
    const explosiveCertifiedLicenseBackFile = driver.explosiveCertifiedLicenseBackFile;
    const mshaLicenseFrontFile = driver.mshaLicenseFrontFile;
    const mshaLicenseBackFile = driver.mshaLicenseBackFile;

    const hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate !== undefined && driver.hazmatLicenseExpiryDate !== null ? driver.hazmatLicenseExpiryDate : null;
    const driverLicenseExpiryDate = driver.driverLicenseExpiryDate !== undefined && driver.driverLicenseExpiryDate !== null ? driver.driverLicenseExpiryDate : null;
    const commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate !== undefined && driver.commercialLicenseExpiryDate !== null ? driver.commercialLicenseExpiryDate : null;
    const pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate !== undefined && driver.pneumaticLicenseExpiryDate !== null ? driver.pneumaticLicenseExpiryDate : null;
    const mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate !== undefined && driver.mcCertificateLicenseExpiryDate !== null ? driver.mcCertificateLicenseExpiryDate : null;
    const explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate !== undefined && driver.explosiveCertifiedLicenseExpiryDate !== null ? driver.explosiveCertifiedLicenseExpiryDate : null;
    const mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate !== undefined && driver.mshaLicenseExpiryDate !== null ? driver.mshaLicenseExpiryDate : null;

    const hazmatLicenseNumber = driver.hazmatLicenseNumber !== undefined && driver.hazmatLicenseNumber !== null ? driver.hazmatLicenseNumber : null;
    const driverLicenseNumber = driver.driverLicenseNumber !== undefined && driver.driverLicenseNumber !== null ? driver.driverLicenseNumber : null;
    const commercialLicenseNumber = driver.commercialLicenseNumber !== undefined && driver.commercialLicenseNumber !== null ? driver.commercialLicenseNumber : null;
    const pneumaticLicenseNumber = driver.pneumaticLicenseNumber !== undefined && driver.pneumaticLicenseNumber !== null ? driver.pneumaticLicenseNumber : null;
    const mcCertificateLicenseNumber = driver.mcCertificateLicenseNumber !== undefined && driver.mcCertificateLicenseNumber !== null ? driver.mcCertificateLicenseNumber : null;
    const explosiveCertifiedLicenseNumber = driver.explosiveCertifiedLicenseNumber !== undefined && driver.explosiveCertifiedLicenseNumber !== null ? driver.explosiveCertifiedLicenseNumber : null;
    const mshaLicenseNumber = driver.mshaLicenseNumber !== undefined && driver.mshaLicenseNumber !== null ? driver.mshaLicenseNumber : null;

    const hazmatLicenseFrontFileId = driver.hazmatLicenseFrontFileId;
    const hazmatLicenseBackFileId = driver.hazmatLicenseBackFileId;
    const driverLicenseFrontFileId = driver.driverLicenseFrontFileId;
    const driverLicenseBackFileId = driver.driverLicenseBackFileId;
    const commercialLicenseFrontFileId = driver.commercialLicenseFrontFileId;
    const commercialLicenseBackFileId = driver.commercialLicenseBackFileId;
    const pneumaticLicenseFrontFileId = driver.pneumaticLicenseFrontFileId;
    const pneumaticLicenseBackFileId = driver.pneumaticLicenseBackFileId;
    const mcCertificateLicenseFrontFileId = driver.mcCertificateLicenseFrontFileId;
    const mcCertificateLicenseBackFileId = driver.mcCertificateLicenseBackFileId;
    const explosiveCertifiedLicenseFrontFileId = driver.explosiveCertifiedLicenseFrontFileId;
    const explosiveCertifiedLicenseBackFileId = driver.explosiveCertifiedLicenseBackFileId;
    const mshaLicenseFrontFileId = driver.mshaLicenseFrontFileId;
    const mshaLicenseBackFileId = driver.mshaLicenseBackFileId;

    if (hazmatLicenseFrontFile) {
        await deleteFile(hazmatLicenseFrontFileId);
        await addFile(hazmatLicenseFrontFile, "DRIVER", entityId, "HAZMAT_LICENSE_FRONT", "Front of HazMat License", "", "ALL", hazmatLicenseExpiryDate, true, 'APPROVED', hazmatLicenseNumber);
    } else if (hazmatLicenseFrontFileId) {
        await updateFileMetaData(hazmatLicenseFrontFileId, {
            expiryDate: hazmatLicenseExpiryDate,
            licenseNumber: hazmatLicenseNumber
        });
    }

    if (hazmatLicenseBackFile) {
        await deleteFile(hazmatLicenseBackFileId);
        await addFile(hazmatLicenseBackFile, "DRIVER", entityId, "HAZMAT_LICENSE_BACK", "Back of HazMat License", "", "ALL", hazmatLicenseExpiryDate, true, 'APPROVED', hazmatLicenseNumber);
    } else if (hazmatLicenseBackFileId) {
        await updateFileMetaData(hazmatLicenseBackFileId, {
            expiryDate: hazmatLicenseExpiryDate,
            licenseNumber: hazmatLicenseNumber
        });
    }

    if (driverLicenseFrontFile) {
        await deleteFile(driverLicenseFrontFileId);
        await addFile(driverLicenseFrontFile, "DRIVER", entityId, "DRIVERS_LICENSE_FRONT", "Front of Drivers License", "", "ALL", driverLicenseExpiryDate, true, 'APPROVED', driverLicenseNumber);
    } else if (driverLicenseFrontFileId) {
        await updateFileMetaData(driverLicenseFrontFileId, {
            expiryDate: driverLicenseExpiryDate,
            licenseNumber: driverLicenseNumber
        });
    }

    if (driverLicenseBackFile) {
        await deleteFile(driverLicenseBackFileId);
        await addFile(driverLicenseBackFile, "DRIVER", entityId, "DRIVERS_LICENSE_BACK", "Back of Drivers License", "", "ALL", driverLicenseExpiryDate, true, 'APPROVED', driverLicenseNumber);
    } else if (driverLicenseBackFileId) {
        await updateFileMetaData(driverLicenseBackFileId, {
            expiryDate: driverLicenseExpiryDate,
            licenseNumber: driverLicenseNumber
        });
    }

    if (commercialLicenseFrontFile) {
        await deleteFile(commercialLicenseFrontFileId);
        await addFile(commercialLicenseFrontFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_FRONT", "Front of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, true, 'APPROVED', commercialLicenseNumber);
    } else if (commercialLicenseFrontFileId) {
        await updateFileMetaData(commercialLicenseFrontFileId, {
            expiryDate: commercialLicenseExpiryDate,
            licenseNumber: commercialLicenseNumber
        });
    }

    if (commercialLicenseBackFile) {
        await deleteFile(commercialLicenseBackFileId);
        await addFile(commercialLicenseBackFile, "DRIVER", entityId, "COMMERCIAL_DRIVERS_LICENSE_BACK", "Back of Commercial Drivers License", "", "ALL", commercialLicenseExpiryDate, true, 'APPROVED', commercialLicenseNumber);
    } else if (commercialLicenseBackFileId) {
        await updateFileMetaData(commercialLicenseBackFileId, {
            expiryDate: commercialLicenseExpiryDate,
            licenseNumber: commercialLicenseNumber
        });
    }

    if (pneumaticLicenseFrontFile) {
        await deleteFile(pneumaticLicenseFrontFileId);
        await addFile(pneumaticLicenseFrontFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_FRONT", "Front of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, true, 'APPROVED', pneumaticLicenseNumber);
    } else if (pneumaticLicenseFrontFileId) {
        await updateFileMetaData(pneumaticLicenseFrontFileId, {
            expiryDate: pneumaticLicenseExpiryDate,
            licenseNumber: pneumaticLicenseNumber
        });
    }

    if (pneumaticLicenseBackFile) {
        await deleteFile(pneumaticLicenseBackFileId);
        await addFile(pneumaticLicenseBackFile, "DRIVER", entityId, "PNEUMATIC_LICENSE_BACK", "Back of Pneumatic License", "", "ALL", pneumaticLicenseExpiryDate, true, 'APPROVED', pneumaticLicenseNumber);
    } else if (pneumaticLicenseBackFileId) {
        await updateFileMetaData(pneumaticLicenseBackFileId, {
            expiryDate: pneumaticLicenseExpiryDate,
            licenseNumber: pneumaticLicenseNumber
        });
    }

    if (mcCertificateLicenseFrontFile) {
        await deleteFile(mcCertificateLicenseFrontFileId);
        await addFile(mcCertificateLicenseFrontFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_FRONT", "Front of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, true, 'APPROVED', mcCertificateLicenseNumber);
    } else if (mcCertificateLicenseFrontFileId) {
        await updateFileMetaData(mcCertificateLicenseFrontFileId, {
            expiryDate: mcCertificateLicenseExpiryDate,
            licenseNumber: mcCertificateLicenseNumber
        });
    }

    if (mcCertificateLicenseBackFile) {
        await deleteFile(mcCertificateLicenseBackFileId);
        await addFile(mcCertificateLicenseBackFile, "DRIVER", entityId, "MC_CERTIFICATE_LICENSE_BACK", "Back of MC Certificate License", "", "ALL", mcCertificateLicenseExpiryDate, true, 'APPROVED', mcCertificateLicenseNumber);
    } else if (mcCertificateLicenseBackFileId) {
        await updateFileMetaData(mcCertificateLicenseBackFileId, {
            expiryDate: mcCertificateLicenseExpiryDate,
            licenseNumber: mcCertificateLicenseNumber
        });
    }

    if (explosiveCertifiedLicenseFrontFile) {
        await deleteFile(explosiveCertifiedLicenseFrontFileId);
        await addFile(explosiveCertifiedLicenseFrontFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_FRONT", "Front of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, true, 'APPROVED', explosiveCertifiedLicenseNumber);
    } else if (explosiveCertifiedLicenseFrontFileId) {
        await updateFileMetaData(explosiveCertifiedLicenseFrontFileId, {
            expiryDate: explosiveCertifiedLicenseExpiryDate,
            licenseNumber: explosiveCertifiedLicenseNumber
        });
    }

    if (explosiveCertifiedLicenseBackFile) {
        await deleteFile(explosiveCertifiedLicenseBackFileId);
        await addFile(explosiveCertifiedLicenseBackFile, "DRIVER", entityId, "EXPLOSIVE_CERTIFIED_LICENSE_BACK", "Back of Explosive Certified License", "", "ALL", explosiveCertifiedLicenseExpiryDate, true, 'APPROVED', explosiveCertifiedLicenseNumber);
    } else if (explosiveCertifiedLicenseBackFileId) {
        await updateFileMetaData(explosiveCertifiedLicenseBackFileId, {
            expiryDate: explosiveCertifiedLicenseExpiryDate,
            licenseNumber: explosiveCertifiedLicenseNumber
        });
    }

    if (mshaLicenseFrontFile) {
        await deleteFile(mshaLicenseFrontFileId);
        await addFile(mshaLicenseFrontFile, "DRIVER", entityId, "MSHA_LICENSE_FRONT", "Front of MSHA License", "", "ALL", mshaLicenseExpiryDate, true, 'APPROVED', mshaLicenseNumber);
    } else if (mshaLicenseFrontFileId) {
        await updateFileMetaData(mshaLicenseFrontFileId, {
            expiryDate: mshaLicenseExpiryDate,
            licenseNumber: mshaLicenseNumber
        });
    }

    if (mshaLicenseBackFile) {
        await deleteFile(mshaLicenseBackFileId);
        await addFile(mshaLicenseBackFile, "DRIVER", entityId, "MSHA_LICENSE_BACK", "Back of MSHA License", "", "ALL", mshaLicenseExpiryDate, true, 'APPROVED', mshaLicenseNumber);
    } else if (mshaLicenseBackFileId) {
        await updateFileMetaData(mshaLicenseBackFileId, {
            expiryDate: mshaLicenseExpiryDate,
            licenseNumber: mshaLicenseNumber
        });
    }

    return omit(driver, "hazmatLicenseFrontFile", "hazmatLicenseBackFile", "driverLicenseFrontFile",
        "driverLicenseBackFile", "commercialLicenseFrontFile", "commercialLicenseBackFile",
        "pneumaticLicenseFrontFile", "pneumaticLicenseBackFile", "mcCertificateLicenseFrontFile",
        "mcCertificateLicenseBackFile", "explosiveCertifiedLicenseFrontFile", "explosiveCertifiedLicenseBackFile",
        "mshaLicenseFrontFile", "mshaLicenseBackFile", "hazmatLicenseExpiryDate", "driverLicenseExpiryDate",
        "commercialLicenseExpiryDate", "pneumaticLicenseExpiryDate", "mcCertificateLicenseExpiryDate",
        "explosiveCertifiedLicenseExpiryDate", "mshaLicenseExpiryDate", "hazmatLicenseFrontFileId", "hazmatLicenseBackFileId",
        "driverLicenseFrontFileId", "driverLicenseBackFileId", "commercialLicenseFrontFileId", "commercialLicenseBackFileId",
        "pneumaticLicenseFrontFileId", "pneumaticLicenseBackFileId", "mcCertificateLicenseFrontFileId", "mcCertificateLicenseBackFileId",
        "explosiveCertifiedLicenseFrontFileId", "explosiveCertifiedLicenseBackFileId", "mshaLicenseFrontFileId", "mshaLicenseBackFileId",
        "hazmatLicenseNumber", "driverLicenseNumber", "commercialLicenseNumber", "pneumaticLicenseNumber",
        "mcCertificateLicenseNumber", "explosiveCertifiedLicenseNumber", "mshaLicenseNumber");
};

//#endregion

//#region Helper Methods

const transformDriverRequest = (driver) => {
    if (driver !== undefined && driver !== null && !isEmpty(driver)) {
        if (driver.hazmatLicenseExpiryDate !== undefined && driver.hazmatLicenseExpiryDate !== null && moment.isMoment(driver.hazmatLicenseExpiryDate)) {
            driver.hazmatLicenseExpiryDate = driver.hazmatLicenseExpiryDate.utc().toISOString();
        }

        if (driver.driverLicenseExpiryDate !== undefined && driver.driverLicenseExpiryDate !== null && moment.isMoment(driver.driverLicenseExpiryDate)) {
            driver.driverLicenseExpiryDate = driver.driverLicenseExpiryDate.utc().toISOString();
        }

        if (driver.commercialLicenseExpiryDate !== undefined && driver.commercialLicenseExpiryDate !== null && moment.isMoment(driver.commercialLicenseExpiryDate)) {
            driver.commercialLicenseExpiryDate = driver.commercialLicenseExpiryDate.utc().toISOString();
        }

        if (driver.pneumaticLicenseExpiryDate !== undefined && driver.pneumaticLicenseExpiryDate !== null && moment.isMoment(driver.pneumaticLicenseExpiryDate)) {
            driver.pneumaticLicenseExpiryDate = driver.pneumaticLicenseExpiryDate.utc().toISOString();
        }

        if (driver.mcCertificateLicenseExpiryDate !== undefined && driver.mcCertificateLicenseExpiryDate !== null && moment.isMoment(driver.mcCertificateLicenseExpiryDate)) {
            driver.mcCertificateLicenseExpiryDate = driver.mcCertificateLicenseExpiryDate.utc().toISOString();
        }

        if (driver.explosiveCertifiedLicenseExpiryDate !== undefined && driver.explosiveCertifiedLicenseExpiryDate !== null && moment.isMoment(driver.explosiveCertifiedLicenseExpiryDate)) {
            driver.explosiveCertifiedLicenseExpiryDate = driver.explosiveCertifiedLicenseExpiryDate.utc().toISOString();
        }

        if (driver.mshaLicenseExpiryDate !== undefined && driver.mshaLicenseExpiryDate !== null && moment.isMoment(driver.mshaLicenseExpiryDate)) {
            driver.mshaLicenseExpiryDate = driver.mshaLicenseExpiryDate.utc().toISOString();
        }

        return driver;
    } else {
        return null;
    }
};

//#endregion