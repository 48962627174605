import React, { useEffect, useRef, useMemo, useState } from 'react';
import PubNub from 'pubnub';
import { useSelector } from "react-redux";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { reverse } from 'lodash';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import Enums from '../../shared/enums';
import logger from '../../shared/logger';
import PickupIcon from "../../assets/img/pickUpMarker.png";
import DropOffIcon from "../../assets/img/dropOffMarker.png";

TrimbleMaps.APIKey = process.env.REACT_APP_TMS_API_KEY;

class GoToCurrentLocationControl {
    constructor(options) {
        this.options = options;
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'trimblemaps-ctrl trimblemaps-ctrl-group';
        this._goToButton = this._createButton(this.options.goToText, this.options.goToTitle, 'trimblemaps-ctrl-icon control-go-to-current-location-button', (e) => { this._goTo(e); });
        this._container.appendChild(this._goToButton);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    _goTo(e) {
        //console.log(this._map)
        //console.log(this.options.currentLocationMarker)
        if (this._map !== null && this.options.currentLocationMarker !== null && this.options.currentLocationMarker.current !== null) {
            var lngLat = this.options.currentLocationMarker.current.features[0].geometry.coordinates;
            if (lngLat !== undefined && lngLat !== null && lngLat[0] !== undefined && lngLat[0] !== null && lngLat[1] !== undefined && lngLat[1] !== null) {
                var newlngLat = new TrimbleMaps.LngLat(lngLat[0], lngLat[1]);
                this._map.fitBounds(newlngLat.toBounds(100), { maxZoom: 14 });
            }
        }
    }

    _createButton(html, title, className, fn) {
        const a = document.createElement('button');
        a.className = className
        a.innerHTML = html;
        a.title = title;
        a.type = 'button';
        a.addEventListener('click', fn);

        return a;
    }
}

class RefreshActualPathControl {
    constructor(options) {
        this.options = options;
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'trimblemaps-ctrl trimblemaps-ctrl-group';
        this._refreshButton = this._createButton(this.options.goToText, this.options.goToTitle, 'trimblemaps-ctrl-icon control-refresh-actual-path-button', (e) => { this.options.refresh(); });
        this._container.appendChild(this._refreshButton);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    _createButton(html, title, className, fn) {
        const a = document.createElement('button');
        a.className = className
        a.innerHTML = html;
        a.title = title;
        a.type = 'button';
        a.addEventListener('click', fn);

        return a;
    }
}

const LoadMap = props => {
    const loadId = props.loadId;
    const currentLatitude = props.latitude;
    const currentLongitude = props.longitude;
    const stops = props.stops !== undefined && props.stops !== null ? props.stops : [];
    const lon = -77.0365, lat = 38.8977, zoom = 8;
    const showZoomControl = props.showZoomControl !== undefined && props.showZoomControl !== null && props.showZoomControl === false ? false : true;
    const notAuthenticated = props.notAuthenticated !== undefined && props.notAuthenticated !== null && props.notAuthenticated === true ? true : false;
    const containerName = props.containerName ? props.containerName : `map-${loadId}`;

    // create map
    const mapRef = useRef(null);
    const actualPathRef = useRef(null);
    const currentLocationMarkerRef = useRef(null);
    const routingLayer = useRef(null);
    const baseLayer = useRef(null);
    const stopLayerGroupRef = useRef(null);
    const currentLocationRef = useRef(null);

    const channel = process.env.REACT_APP_LOAD_LOCATIONS_CHANNEL + '_' + loadId;
    const userId = useSelector(state => state.auth.userId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const pubNubSubKey = useSelector(state => state.keys.pubNubSubKey);
    const pubNubPubKey = useSelector(state => state.keys.pubNubPubKey);

    const [isMapReady, setIsMapReady] = useState(false);

    const getLocations = () => {
        if (isAuthenticated === true && notAuthenticated === false) {
            // comes in descending by eventTimeStamp so the array needs to be flipped
            axiosAuthenticated.get('/loadLocations?size=9999&loadId=' + loadId).then(res => {
                let locs = res.data.data;
                //console.log(locs);
                let reverseLocs = reverse(locs); // reverse mutates the array when it reverses it so now locs will be reversed
                //console.log(reverseLocs);
                let locsArray = [];
                locsArray = locs.map(item => {
                    return { latitude: item.latitude, longitude: item.longitude }
                });

                let coordinates = locs.map((item) => {
                    return [item.longitude, item.latitude];
                });

                let geoJsonActualPathData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: 'Actual Path'
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates: coordinates
                        }
                    }]
                };

                actualPathRef.current = geoJsonActualPathData;

                const actualPath = mapRef.current.getSource('actualPath');
                if (actualPath === undefined) {
                    mapRef.current.addSource('actualPath', {
                        type: 'geojson',
                        data: actualPathRef.current
                    });

                    mapRef.current.addLayer({
                        id: 'actualPathLayer',
                        type: 'line',
                        source: 'actualPath',
                        paint: {
                            'line-width': 4
                        }
                    });
                }

                if (locsArray.length > 0) {
                    const currentIndex = locsArray.length - 1;
                    updateCurrentLocation(locsArray[currentIndex].latitude, locsArray[currentIndex].longitude);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const updateCurrentLocation = (latitude, longitude) => {
        let lnglat = new TrimbleMaps.LngLat(longitude, latitude);

        if (mapRef.current !== null) {
            // mapRef.current.jumpTo({
            //     center: [longitude, latitude],
            //     //zoom: 14
            // });

            if (currentLocationRef.current !== undefined && currentLocationRef.current !== null) {
                currentLocationRef.current.features[0].geometry.coordinates = [longitude, latitude];
            } else {
                let geoJsonCurrentLocationData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: 'Current Location'
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude]
                        }
                    }]
                };

                currentLocationRef.current = geoJsonCurrentLocationData;
            }

            const currentLocation = mapRef.current.getSource('currentLocation');
            if (currentLocation === undefined) {
                mapRef.current.addSource('currentLocation', {
                    type: 'geojson',
                    data: currentLocationRef.current
                });

                mapRef.current.addLayer({
                    id: 'currentLocationLayer',
                    type: 'symbol',
                    source: 'currentLocation',
                    layout: {
                        'icon-image': 'currentLocationMarker',
                        'icon-size': 1,
                        'icon-allow-overlap': true
                    }
                });
            } else {
                currentLocation.setData(currentLocationRef.current);
            }
        }
    };

    const updateActualPath = (latitude, longitude) => {
        let lnglat = new TrimbleMaps.LngLat(longitude, latitude);

        // only show the actual path if the user is authenticated and is a staff member, otherwise only show the current location marker
        if (isAuthenticated === true && notAuthenticated === false && entityType === 'STAFF') {
            //console.log(actualPathRef.current);
            if (actualPathRef.current !== undefined && actualPathRef.current !== null) {
                actualPathRef.current.features[0].geometry.coordinates.push([longitude, latitude]);
            } else {
                let geoJsonActualPathData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: 'Actual Path'
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates: [[longitude, latitude]]
                        }
                    }]
                };

                actualPathRef.current = geoJsonActualPathData;
            }

            const actualPath = mapRef.current.getSource('actualPath');
            if (actualPath === undefined) {
                mapRef.current.addSource('actualPath', {
                    type: 'geojson',
                    data: actualPathRef.current
                });

                mapRef.current.addLayer({
                    id: 'actualPathLayer',
                    type: 'line',
                    source: 'actualPath',
                    paint: {
                        'line-width': 4
                    }
                });
            } else {
                actualPath.setData(actualPathRef.current);
            }
        }

        updateCurrentLocation(latitude, longitude);

        // if (isAuthenticated === true && notAuthenticated === false) {
        //     // zoom the map to the polyline
        //     if (mapRef.current !== null && polylineRef.current !== null) {
        //         mapRef.current.fitBounds(polylineRef.current.getBounds());
        //     }
        // }
    };

    const addStopMarker = (markerIcon, stopId, stop, latitude, longitude) => {
        let lnglat = new TrimbleMaps.LngLat(longitude, latitude);

        if (mapRef.current !== null) {
            if (markerIcon === "pickUpMarker" || markerIcon === "dropOffMarker") {
                const stopImage = markerIcon === "pickUpMarker" ? PickupIcon : markerIcon === "dropOffMarker" ? DropOffIcon : ''
                // PICKUP MARKER
                const pickup = document.createElement('div');
                pickup.style.background = "url("+stopImage+")"
                pickup.style.width = "40px"
                pickup.style.height = "40px"
                pickup.style.backgroundSize = "contain"
                pickup.id = "pickup-" + stopId;
                new TrimbleMaps.Marker({
                    element: pickup
                })
                    .setLngLat([longitude, latitude])
                    .addTo(mapRef.current);
            }

            if (stopLayerGroupRef.current !== undefined && stopLayerGroupRef.current !== null) {
                stopLayerGroupRef.current.features.push({
                    type: 'Feature',
                    properties: {
                        name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                        stopId: stopId,
                        stopType: stop.stopType,
                        iconSize: [30, 30],
                        icon: markerIcon
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude]
                    }
                });
            } else {
                let geoJsonStopData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                            stopId: stopId,
                            stopType: stop.stopType,
                            iconSize: [30, 30],
                            icon: markerIcon
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude]
                        }
                    }]
                };

                stopLayerGroupRef.current = geoJsonStopData;
            }

            const stops = mapRef.current.getSource('stops');
            if (stops === undefined) {
                mapRef.current.addSource('stops', {
                    type: 'geojson',
                    data: stopLayerGroupRef.current
                });

                mapRef.current.addLayer({
                    id: 'stopsLayer',
                    type: 'symbol',
                    source: 'stops',
                    minzoom: 0,
                    maxzoom: 24,
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 0.5,
                        'icon-allow-overlap': true
                    }
                });
            } else {
                stops.setData(stopLayerGroupRef.current);
            }
        }
    };

    useEffect(() => {
        if (isMapReady === true && mapRef.current !== null) {
            if (showZoomControl === true) {
                const scale = new TrimbleMaps.ScaleControl({
                    maxWidth: 80,
                    unit: 'imperial'
                });
                mapRef.current.addControl(scale, 'top-left');

                const nav = new TrimbleMaps.NavigationControl();
                mapRef.current.addControl(nav, 'top-left');
            }

            const currentLoc = new GoToCurrentLocationControl({
                goToText: '<b>&#8853;</b>',
                goToTitle: 'Go to Current Location',
                currentLocationMarker: currentLocationRef,
            });
            mapRef.current.addControl(currentLoc, 'bottom-left');

            if (entityType === 'STAFF' && notAuthenticated === false) {
                const refreshActualPath = new RefreshActualPathControl({
                    goToText: '<b>&#8635;</b>',
                    goToTitle: 'Refresh Actual Path',
                    refresh: getLocations,
                });
                mapRef.current.addControl(refreshActualPath, 'top-right');
            }

            if (isAuthenticated === true && notAuthenticated === false) {
                getLocations();
            }
        }

        return () => {

        };
    }, [isMapReady, isAuthenticated, notAuthenticated, mapRef, currentLocationRef, showZoomControl]);

    useEffect(() => {
        if (containerName !== undefined && containerName !== null && loadId !== undefined && loadId !== null) {
            mapRef.current = new TrimbleMaps.Map({
                container: containerName,
                center: new TrimbleMaps.LngLat(lon, lat),
                zoom: zoom,
                style: TrimbleMaps.Common.Style.TRANSPORTATION,
                region: TrimbleMaps.Common.Region.NA
            });

            mapRef.current.on('load', function () {
                mapRef.current.loadImage('https://d2lu6cvlrvxwy8.cloudfront.net/current_location_mark.png', function (error, image) {
                    // Add image to the map
                    if (error !== undefined && error !== null) {
                        logger.logErrorEvent('load_map_load_image', error, error.message, true);
                    } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                        mapRef.current.addImage('currentLocationMarker', image);
                    }
                });

                mapRef.current.loadImage('https://d2lu6cvlrvxwy8.cloudfront.net/pickUpMarker.png', function (error, image) {
                    // Add image to the map
                    if (error !== undefined && error !== null) {
                        logger.logErrorEvent('load_map_load_image', error, error.message, true);
                    } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                        mapRef.current.addImage('pickUpMarker', image);
                    }
                });

                mapRef.current.loadImage('https://d2lu6cvlrvxwy8.cloudfront.net/dropOffMarker.png', function (error, image) {
                    // Add image to the map
                    if (error !== undefined && error !== null) {
                        logger.logErrorEvent('load_map_load_image', error, error.message, true);
                    } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                        mapRef.current.addImage('dropOffMarker', image);
                    }
                });

                mapRef.current.loadImage('https://d2lu6cvlrvxwy8.cloudfront.net/restStopMarker.png', function (error, image) {
                    // Add image to the map
                    if (error !== undefined && error !== null) {
                        logger.logErrorEvent('load_map_load_image', error, error.message, true);
                    } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                        mapRef.current.addImage('restStopMarker', image);
                    }
                });

                setIsMapReady(true);

                mapRef.current.resize();
            });

            let mapDiv = document.getElementById(containerName);
            window.onresize = () => {
                //console.log('window resized');

                if (mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.resize();
                }
            };
            mapDiv.onresize = () => {
                //console.log('map container resized');

                if (mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.resize();
                }
            };

            // mapRef.current.on('click', function (e) {
            //     // Extract the lngLat object from the event
            //     const lngLat = e.lngLat;

            //     updateActualPath(lngLat.lat, lngLat.lng);
            // });
        } else {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        }

        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, [loadId, containerName]);

    useMemo(() => {
        let pubnub = null;
        if (isMapReady === true && isAuthenticated === true && notAuthenticated === false && userId !== undefined && userId !== null && pubNubSubKey !== undefined && pubNubSubKey !== null && pubNubPubKey !== undefined && pubNubPubKey !== null) {
            console.log("Setting up load location PubNub!");
            pubnub = new PubNub({
                publishKey: pubNubPubKey,
                subscribeKey: pubNubSubKey,
                ssl: true,
                uuid: userId,
                heartbeatInterval: 0
            });

            pubnub.addListener({
                status: function (statusEvent) {
                    if (statusEvent.category === "PNConnectedCategory") {
                        console.log("Connected to load location PubNub!");
                    }
                },
                message: function (msg) {
                    console.log('Message from load location PubNub:');
                    console.log(msg);
                    if (msg.message.latitude && msg.message.longitude) {
                        updateActualPath(msg.message.latitude, msg.message.longitude);
                    }
                }
            });

            //Subscribes to the channel in our state
            pubnub.subscribe({
                channels: [channel],
                triggerEvents: true,
                //withPresence: true,
                //autoload: 100
            });
        } else if (pubnub !== null) {
            console.log("Shutting down load location PubNub!");
            pubnub.unsubscribeAll();
        }

        return () => {
            if (pubnub !== null) {
                console.log("Shutting down load location PubNub!");
                pubnub.unsubscribeAll();
            }
        };
    }, [isMapReady, isAuthenticated, notAuthenticated, userId, pubNubSubKey, pubNubPubKey]);

    useEffect(() => {
        //console.log(stops);
        if (isMapReady === true && loadId !== undefined && loadId !== null && stops !== undefined && stops !== null && stops.length > 0 && mapRef.current !== null) {
            if (stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === loadId).length > 0) {
                let mapStops = stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === loadId).map((stop) => {
                    return new TrimbleMaps.LngLat(stop.longitude, stop.latitude);
                });
                let mapStopsWithStopType = stops.filter(stop => stop.latitude !== undefined && stop.longitude !== undefined && stop.loadId === loadId).map((stop) => {
                    return {
                        stop: stop,
                        longitude: stop.longitude,
                        latitude: stop.latitude,
                        stopType: stop.stopType,
                        id: stop.id,
                        loadId: stop.loadId
                    };
                });

                if (routingLayer.current === null) {
                    if (mapStops.length > 0) {
                        routingLayer.current = new TrimbleMaps.Route({
                            routeId: loadId,
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                        routingLayer.current.addTo(mapRef.current);
                    }
                } else {
                    if (mapStops.length > 0) {
                        routingLayer.current.update({
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                    }
                }

                if (mapStopsWithStopType.length > 0) {
                    mapStopsWithStopType.forEach((stop) => {
                        if (stop.stopType === 'PICK_UP') {
                            addStopMarker('pickUpMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else if (stop.stopType === 'DROP_OFF') {
                            addStopMarker('dropOffMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else {
                            addStopMarker('restStopMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        }
                    });
                }
            }
        }

        return () => {

        };
    }, [isMapReady, stops, loadId, stopLayerGroupRef, mapRef, routingLayer]);

    useEffect(() => {
        if (isMapReady === true && notAuthenticated === true && currentLatitude !== undefined && currentLatitude !== null && currentLongitude !== undefined && currentLongitude !== null) {
            updateCurrentLocation(currentLatitude, currentLongitude);
        }

        return () => {

        };
    }, [isMapReady, notAuthenticated, currentLatitude, currentLongitude]);

    return (
        <div id={containerName} style={{ width: "100%", height: props.fullScreen ? "100vh" : (props.height ? props.height : "500px") }}></div>
    );
};

export default LoadMap;
