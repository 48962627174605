import React, { useMemo, useState } from 'react';
import { Select, Alert, Spin, Button, Drawer, Empty } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import StringFormatter from '../../shared/stringFormatter';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import NewAsset from '../NewAsset/NewAsset';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const AssignLoadAsset = props => {
    const fullWidth = global.window.innerWidth;
    const load = props.load;
    const carrierId = props.carrierId;
    const assetIds = props.load.assetIds !== undefined && props.load.assetIds !== null ? props.load.assetIds : [];

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const isAssetsLoading = useSelector(state => state.assets.isLoading);
    const assets = useSelector(state => state.assets.records);

    const [showNewEntity, setShowNewEntity] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    useMemo(() => {
        if (carrierId !== null) {
            dispatch(actionCreators.fetchAssets({ page: 1, size: 100000, carrierId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    const onSubmit = data => {
        if (!isEmpty(data)) {
            // check to see if it is a duplicate and if it is found in the assetIds array already, throw an error
            if (assetIds.length > 0) {
                if (assetIds.find(i => i === data.assetId) !== undefined) {
                    methods.setError("assetId", { type: "validate", message: "The asset you chose is already assigned to this load." });
                    return;
                } else {
                    methods.clearErrors("assetId");
                }
            }

            //console.log(data);
            let updatedAssetIds = [...assetIds, data.assetId];
            //console.log(updatedAssetIds);

            // removes duplicates
            const updatedAssetIds2 = [...new Set(updatedAssetIds)];
            //console.log(updatedAssetIds2);
            let payload = {};
            payload.assetIds = updatedAssetIds2;
            //console.log(payload);
            dispatch(actionCreators.updateLoad(load.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>Choose an Asset to Assign to this Load</legend>
                            <FormItem {...formItemLayout} label="Choose an Asset" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Search and Select an Asset"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        loading={isAssetsLoading === true}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Assets for you to choose from." />}
                                    >
                                        {assets.map(a => <Option value={a.id} key={a.id}>{stringFormatter.toFormattedString("AssetType", a.assetType, null)}{a.trailerType !== undefined && a.trailerType !== null ? ' (' + a.trailerType.description + ')' : ''} ({a.licensePlateNum} {a.licensePlateState}) - Currently Available: {stringFormatter.toYesNo(a.isAvailable)}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="assetId"
                            />
                            <br />
                            <b>Asset Doesn't Exist Yet?</b>
                            <br />
                            <Button type='primary' onClick={toggleNewEntity}>Add New Asset to Carrier Account</Button>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Assign Asset to Load" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Asset'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewEntity}
                visible={showNewEntity}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewAsset cancel={toggleNewEntity} accountId={carrierId} />
            </Drawer>
        </>
    );
};

export default AssignLoadAsset;