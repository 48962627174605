import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { UploadOutlined, DownOutlined, LinkOutlined } from '@ant-design/icons';
import { Drawer, Button, Dropdown, Menu, Modal, InputNumber } from 'antd';
import NewLinkedLoadLane from '../../components/NewLinkedLoadLane/NewLinkedLoadLane';
import EditLinkedLoadLane from "../../components/EditLinkedLoadLane/EditLinkedLoadLane";
import classes from './LinkedLoadLanes.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faRoute } from "@fortawesome/free-solid-svg-icons";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const stringFormatter = new StringFormatter();

const LinkedLoadLanes = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const linkedAccountId = props.linkedAccountId;
    const singularEntityName = "Lane";
    const pluralEntityName = "Lanes";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLoadLanes.isLoading);
    const records = useSelector(state => state.linkedLoadLanes.records);
    const pagination = useSelector(state => state.linkedLoadLanes.pagination);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAddLoading = useSelector(state => state.linkedLoadLanes.isRecordAddLoading);
    const addError = useSelector(state => state.linkedLoadLanes.addRecordError);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewLoad, setShowNewLoad] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [loadLanes, setLoadLanes] = useState([]);

    const linkLoadLane = (record) => {
        //console.log(record);
        if (record !== undefined && record !== null) {
            let payload = {};
            payload.linkedAccountId = record.accountId;
            payload.accountId = accountId;
            payload.loadLaneId = record.id;

            if (!isEmpty(payload)) {
                dispatch(actionCreators.addLinkedLoadLane(payload));
            }
        }
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    const goToNewLoad = () => {
        console.log(selectedRecord);
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.linkedAccountId ? selectedRecord.linkedAccountId : null,
                loadLaneId: null,
                linkedLoadLaneId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
                duplicateLoadId: null
            }
        });

        setSelectedRecord({});
    };

    const goToLinkedLoadLane = (id) => {
        props.history.push({ pathname: `/linkedLoadLanes/${id}` }, {
            previousPageTitle: "Lanes",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const getOrigin = (loadLane) => {
        let locationDisplay = '';
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            let origin = loadLane.locations[0];
            if (origin.location !== undefined && origin.location !== null && origin.location.address !== undefined && origin.location.address !== null) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${origin.locationId}-${origin.sequence}`} title={origin.location.name} address={origin.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getDestination = (loadLane) => {
        let locationDisplay = '';
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            let destination = loadLane.locations[loadLane.locations.length - 1];
            if (destination.location !== undefined && destination.location !== null && destination.location.address !== undefined && destination.location.address !== null) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${destination.locationId}-${destination.sequence}`} title={destination.location.name} address={destination.location.address} />);
            }
        }

        return locationDisplay;
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="createLoadFromLinkedLoadLane" onClick={() => { setSelectedRecord(record); toggleNewLoad(); }}>
                    <UploadOutlined />
                    <span>Create Load(s)</span>
                </Menu.Item>
                <Menu.Item key="editEntity" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); goToLinkedLoadLane(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const getLoadLanes = async () => {
        setLoadLanes([]);
        try {
            let searchParams = {
                page: 1,
                size: 10000
            };

            let linkedLoadLanesSearchParams = {
                page: 1,
                size: 10000
            };

            let existingLoadLaneIds = [];
            if (records !== undefined && records !== null && records.length > 0) {
                existingLoadLaneIds = records.map((item) => item.loadLaneId);
                searchParams['id:not'] = existingLoadLaneIds;
            }

            console.log(existingLoadLaneIds);

            searchParams.accountId = linkedAccountId;
            linkedLoadLanesSearchParams.accountId = linkedAccountId;

            let loadLanesList = [];
            let loadLaneIds = [];
            let transformedLoadLanes = [];
            //dispatch(actionCreators.fetchLoadLanes(searchParams));
            const loadLanesResults = await axiosAuthenticated.post('/loadLanes/bulk/get', { ...searchParams });
            if (loadLanesResults && loadLanesResults.status === 200) {
                const loadLanesData = loadLanesResults.data.data;
                if (loadLanesData !== undefined && loadLanesData !== null && loadLanesData.length > 0) {

                    let locationIds = [];
                    let locations = [];
                    loadLanesData.forEach((loadLane) => {
                        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                            loadLane.locations.forEach((location) => {
                                if (!locationIds.includes(location.locationId)) {
                                    locationIds.push(location.locationId);
                                }
                            });
                        }
                    });

                    if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                        const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] });

                        if (locationsRes && locationsRes.status === 200) {
                            locations = locationsRes.data.data;
                        }
                    }

                    transformedLoadLanes = loadLanesData.map((item) => {
                        // Get Location Info
                        let loadLaneLocations = [];
                        if (item.locations !== undefined && item.locations !== null && item.locations.length > 0) {
                            loadLaneLocations = item.locations.map((location) => {
                                let locationLocation = null;
                                if (location.locationId !== undefined && location.locationId !== null) {
                                    let locationObj = locations.find(i => i.id === location.locationId);
                                    if (locationObj !== undefined && locationObj !== null) {
                                        locationLocation = { ...locationObj };
                                    }
                                }

                                return {
                                    ...location,
                                    location: locationLocation
                                };
                            });
                        }

                        return {
                            ...item,
                            key: item.id,
                            locations: loadLaneLocations
                        };
                    });

                    loadLaneIds = loadLanesData.map((item) => item.id);
                }
            }

            // also get all load lanes that are linked to the linkedAccount
            let transformedLoadLanesFromLinkedLoadLanes = [];
            linkedLoadLanesSearchParams['loadLaneId:not'] = [...existingLoadLaneIds, ...loadLaneIds];
            const linkedLoadLanesRes = await axiosAuthenticated.post('/linkedLoadLanes/bulk/get', { ...linkedLoadLanesSearchParams });
            if (linkedLoadLanesRes && linkedLoadLanesRes.status === 200) {
                const linkedLoadLanesData = linkedLoadLanesRes.data.data;
                if (linkedLoadLanesData !== undefined && linkedLoadLanesData !== null && linkedLoadLanesData.length > 0) {

                    let locationIds = [];
                    let locations = [];
                    linkedLoadLanesData.forEach((loadLane) => {
                        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
                            loadLane.locations.forEach((location) => {
                                if (!locationIds.includes(location.locationId)) {
                                    locationIds.push(location.locationId);
                                }
                            });
                        }
                    });

                    if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
                        const locationsRes = await axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] });

                        if (locationsRes && locationsRes.status === 200) {
                            locations = locationsRes.data.data;
                        }
                    }

                    transformedLoadLanesFromLinkedLoadLanes = linkedLoadLanesData.map((item) => {
                        // Get Location Info
                        let loadLaneLocations = [];
                        if (item.locations !== undefined && item.locations !== null && item.locations.length > 0) {
                            loadLaneLocations = item.locations.map((location) => {
                                let locationLocation = null;
                                if (location.locationId !== undefined && location.locationId !== null) {
                                    let locationObj = locations.find(i => i.id === location.locationId);
                                    if (locationObj !== undefined && locationObj !== null) {
                                        locationLocation = { ...locationObj };
                                    }
                                }

                                return {
                                    ...location,
                                    location: locationLocation
                                };
                            });
                        }

                        return {
                            ...item.loadLane,
                            key: item.loadLaneId,
                            locations: loadLaneLocations
                        };
                    });
                }
            }

            loadLanesList = [...transformedLoadLanes, ...transformedLoadLanesFromLinkedLoadLanes];
            setLoadLanes([...new Set(loadLanesList)]);
        } catch (error) {
            console.log(error);
            setLoadLanes([]);
        }
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            searchParams.linkedAccountId = linkedAccountId;
        }

        dispatch(actionCreators.fetchLinkedLoadLanes(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLinkedLoadLanesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            searchParams.linkedAccountId = linkedAccountId;
        }

        dispatch(actionCreators.fetchLinkedLoadLanes(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
            render: (text, record) => {
                if (text !== undefined && text !== null) {
                    return text;
                } else if (record && record.loadLane && record.loadLane.customerLaneId) {
                    return record.loadLane.customerLaneId;
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (text !== undefined && text !== null && text.length > 0) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Approx Dist',
            dataIndex: ['loadLane', 'approxDistance'],
            key: 'loadLane.approxDistance',
        },
        {
            title: 'Approx Trans Time',
            dataIndex: ['loadLane', 'approxTransitTime'],
            key: 'loadLane.approxTransitTime',
        },
        {
            title: 'Per Mile Rate',
            dataIndex: 'perMileRate',
            key: 'perMileRate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    const loadLaneColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Button icon={<LinkOutlined />} type="primary" shape="circle" onClick={(e) => { e.stopPropagation(); linkLoadLane(record); }} />
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
            render: (text, record) => {
                if (text !== undefined && text !== null) {
                    return text;
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (text !== undefined && text !== null && text.length > 0) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Approx Dist',
            dataIndex: 'approxDistance',
            key: 'approxDistance',
        },
        {
            title: 'Approx Trans Time',
            dataIndex: 'approxTransitTime',
            key: 'approxTransitTime',
        },
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLinkedLoadLane history={props.history} cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} linkedAccountId={linkedAccountId} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedLoadLane cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const newLoadComponents = (
        <Modal
            title={"Choose how many Loads you want to Create from this Lane"}
            visible={showNewLoad}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewLoad(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    useEffect(() => {
        getLoadLanes();
    }, [records]);

    return (
        <>
            <DataTable
                dataSource={records}
                columns={columns}
                hidePaging={true}
                //pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true || isAddLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
                {newLoadComponents}
            </DataTable>
            {(linkedAccountId !== undefined && linkedAccountId !== null && loadLanes !== undefined && loadLanes !== null && loadLanes.length > 0) ? (
                <DataTable
                    dataSource={loadLanes}
                    columns={loadLaneColumns}
                    hidePaging={true}
                    loading={isLoading === true || isAddLoading === true}
                    rowClassName={classes.dataTableRow}
                    title={() => (<b>This account has other Lanes. Would you like to copy them to your list of Lanes?</b>)}
                    rowKey={record => record.id}
                    style={{ backgroundColor: '#ffffff', marginTop: 24 }}
                />
            ) : null}
        </>
    );
};

export default withRouter(LinkedLoadLanes);
