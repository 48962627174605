import React from 'react';
import { DatePicker } from "antd";
import moment from 'moment';

const DatePickerAutoAccept = props => {
    const onBlur = (e) => {
        let value = moment(e.target.value, props.format)
        if (value && value.isValid() && props.onChange) {
            props.onChange(value, e.target.value)
        }
    };

    return <DatePicker {...props} onBlur={onBlur} />
};

export default DatePickerAutoAccept;

// import moment, { Moment } from 'moment';
// import { PickerProps } from 'antd/lib/date-picker/generatePicker';

// const DatePickerAutoAccept = (props: PickerProps<Moment>) => {
//     const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
//         let value = moment(elem.target.value, props.format)
//         if (value && value.isValid() && props.onChange) {
//             props.onChange(value, elem.target.value)
//         }
//     }

//     return <DatePicker { ...props } onBlur={onBlur} />
// }