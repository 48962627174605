import React from "react";
import { Col, Row, Alert, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InfoCircleOutlined } from "@ant-design/icons";

const FormItem = (props) => {
    const methods = useFormContext();

    const label = props.label;
    const labelCol = props.labelCol;
    const wrapperCol = props.wrapperCol;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';
    const required = props.required !== undefined && props.required !== null ? props.required : false;
    const noColon = props.noColon !== undefined && props.noColon !== null ? props.noColon : false;
    const as = props.as;
    const render = props.render;
    const rules = props.rules;
    const name = props.name;
    const defaultValue = props.defaultValue;
    const onChange = props.onChange;
    const disabled = props.disabled;
    const help = props.help;
    const style = props.style;
    const tooltipText = props.tooltip;

    const controllerProps = {
        name: name
    };

    if (as !== undefined) {
        controllerProps.as = as;
    }

    if (render !== undefined) {
        controllerProps.render = render;
    }

    if (rules !== undefined) {
        controllerProps.rules = rules;
    }

    if (defaultValue !== undefined) {
        controllerProps.defaultValue = defaultValue;
    }

    if (onChange !== undefined) {
        controllerProps.onChange = onChange;
    }

    if (disabled !== undefined) {
        controllerProps.disabled = disabled;
    }

    //console.log(controllerProps);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    const getTooltip = () => {
        if (tooltipText !== undefined && tooltipText !== null) {
            return (
                <Tooltip placement="top" title={tooltipText}>
                    <InfoCircleOutlined style={{ color: '#16588E', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {label &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        <Col span={24}>{required ? '* ' : ''}{label}{getTooltip()}{noColon ? '' : ':'}</Col>
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col span={24} style={style}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col span={24}>{help}</Col>
                    </Row>
                }
                {getError(methods.errors, name) !== undefined && (
                    <ErrorMessage
                        errors={methods.errors}
                        name={name}
                        render={({ message, messages }) => {
                            if (messages !== undefined && messages !== null) {
                                return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" />));
                            } else if (message !== undefined && message !== null) {
                                return <Alert message={message} type="error" />;
                            } else {
                                return null;
                            }
                        }}
                    />
                )}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]}>
                    {label && <Col {...labelCol} style={{ textAlign: 'right' }}>{required ? '* ' : ''}{label}{getTooltip()}{noColon ? '' : ':'}</Col>}
                    <Col {...wrapperCol}>
                        <Controller control={methods.control} {...controllerProps} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}>{help}</Col>
                    </Row>
                }
                {getError(methods.errors, name) !== undefined && (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol}></Col>
                        <Col {...wrapperCol}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    } else {
        return null;
    }
};

export default FormItem;
