import React, { useEffect, useState } from 'react';
import { Row, Col } from "antd";
import LoadShipperCard from "../../components/LoadShipperCard/LoadShipperCard";
import LoadCarrierCard from "../../components/LoadCarrierCard/LoadCarrierCard";
import LoadDriverCard from "../../components/LoadDriverCard/LoadDriverCard";
import LoadRateCard from "../../components/LoadRateCard/LoadRateCard";
import LoadAssetsCard from "../../components/LoadAssetsCard/LoadAssetsCard";
import LoadDetailsCard from "../../components/LoadDetailsCard/LoadDetailsCard";
import LoadMap from "../../components/LoadMap/LoadMap";
import classes from './LoadDetails.module.scss';
import LoadRepresentativeCard from '../../components/LoadRepresentativeCard/LoadRepresentativeCard';
import LoadStatusCard from '../../components/LoadStatusCard/LoadStatusCard';
import DataRow from '../../components/DataRow/DataRow';
import StringFormatter from '../../shared/stringFormatter';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import ComplexCard from '../ComplexCard/ComplexCard';
import DataDateTimeRangeRow from '../DataDateTimeRangeRow/DataDateTimeRangeRow';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import DocumentUtils from '../../shared/documentUtils';
import { useSelector } from "react-redux";
import LoadCompletionCard from '../LoadCompletionCard/LoadCompletionCard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWallet} from "@fortawesome/free-solid-svg-icons";

const stringFormatter = new StringFormatter();

const LoadDetails = props => {
    //#region props and constants

    const load = props.load !== undefined && props.load !== null ? props.load : {};
    const stops = props.stops !== undefined && props.stops !== null && props.stops.length > 0 ? props.stops : [];
    const claims = props.claims !== undefined && props.claims !== null && props.claims.length > 0 ? props.claims : [];
    const invoiceLineItems = props.invoiceLineItems !== undefined && props.invoiceLineItems !== null && props.invoiceLineItems.length > 0 ? props.invoiceLineItems : [];
    const transactions = props.transactions !== undefined && props.transactions !== null && props.transactions.length > 0 ? props.transactions : [];
    const loadCancellations = props.loadCancellations !== undefined && props.loadCancellations !== null && props.loadCancellations.length > 0 ? props.loadCancellations : [];
    const carrierRejections = props.carrierRejections !== undefined && props.carrierRejections !== null && props.carrierRejections.length > 0 ? props.carrierRejections : [];
    const isDeadHeading = props.isDeadHeading;
    const currentStatus = props.currentStatus;
    const entityType = props.entityType;
    const entityId = props.entityId;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];
    const onTabChange = props.onTabChange;
    const currentStop = props.currentStop;
    const currentStopType = props.currentStopType;
    const bols = props.bols;
    const commodities = props.commodities;
    const accounts = props.accounts;

    const carrierId = load && load.assignedCarrierId ? load.assignedCarrierId : null;
    const drivers = load && load.drivers && load.drivers.length > 0 ? load.drivers : [];
    const hasTeamDriving = load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;

    //#endregion
    //#region useSelectors

    const isAdmin = useSelector(state => state.auth.isAdmin);
    const userEntityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [pendingApprovalDocuments, setPendingApprovalDocuments] = useState([]);
    const [missingDocuments, setMissingDocuments] = useState([]);

    //#endregion
    //#region displays

    const eta = () => {
        if (load && (entityType === "STAFF" || (load.loadStatus === "IN_TRANSIT" || load.loadStatus === "AT_STOP"))) {
            return (
                <span>{`ETA: ${stringFormatter.toFormattedString('MomentDateTime', load.eta, null, load.etaTimeZone)}`}</span>
            );
        } else {
            return (
                <span>ETA: Will become available once the load starts.</span>
            );
        }
    };

    const renderStops = () => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (pickUpAndDropOffStops.length > 0) {
                const stopsDisplay = pickUpAndDropOffStops.map((stop) => {
                    const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
                    const stopLocation = `${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city + ', ' : ''}${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : ''}`;
                    const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
                    let appointmentTypeToShow = null;
                    switch (stop.apptType) {
                        case "FIRST_COME_FIRST_SERVE":
                            appointmentTypeToShow = (stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow key={`stop-appt-datetime-${stop.id}`} separateLines={true} startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null);
                            break;
                        case "HAVE_APPOINTMENT":
                            appointmentTypeToShow = (stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow key={`stop-appt-datetime-${stop.id}`} value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null);
                            break;
                        case "NEED_APPOINTMENT":
                            appointmentTypeToShow = (`${stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? 'Appt. Needed' : ''}`);
                            break;
                    }

                    return (
                        <div style={{ marginBottom: 24 }} key={`stop-details-${stop.id}`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{(stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null)}</span>
                                </div>
                            </div>
                            {appointmentTypeToShow}
                        </div>
                    );
                });

                return (
                    <div>
                        <div style={{ height: 250, overflowY: 'auto' }}>
                            {stopsDisplay}
                        </div>
                        <DataRow style={{ marginTop: 24 }} title="Total Stops" valueStyle={{ fontWeight: 'bold' }} value={pickUpAndDropOffStops.length} />
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const createMissingDocumentList = () => {
        if (missingDocuments !== undefined && missingDocuments !== null && missingDocuments.length > 0) {
            return (
                <div>
                    <b>Missing Documents ({missingDocuments.length}):</b> {missingDocuments.join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    const createPendingApprovalList = () => {
        if (pendingApprovalDocuments !== undefined && pendingApprovalDocuments !== null && pendingApprovalDocuments.length > 0) {
            return (
                <div>
                    <b>Documents Pending Approval ({pendingApprovalDocuments.length}):</b> {pendingApprovalDocuments.map(doc => doc.displayName).join(', ')}
                </div>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (documents !== undefined && documents !== null && documents.length > 0) {
            let filteredDocuments = DocumentUtils.getVisibleDocuments(documents, userEntityType, isAdmin);
            let pendingApprovalDocumentsList = [];
            let missingDocumentsList = [];
            if (load !== undefined && load !== null) {
                let loadCounts = DocumentUtils.calculateCounts(filteredDocuments.filter(document => document.entityId === load.id), load.id, 'LOAD', null, null, load.equipmentNeeds.hasCarrierWashout);
                pendingApprovalDocumentsList = [...pendingApprovalDocumentsList, ...loadCounts.pendingApprovalDocumentsList];
                missingDocumentsList = [...missingDocumentsList, ...loadCounts.missingDocumentsList];
            }
            if (stops !== undefined && stops !== null && stops.length > 0) {
                stops.forEach((stop) => {
                    let stopCounts = DocumentUtils.calculateCounts(filteredDocuments.filter(document => document.entityType === 'STOP' && document.entityId === stop.id), stop.id, 'STOP', stop.stopType);
                    pendingApprovalDocumentsList = [...pendingApprovalDocumentsList, ...stopCounts.pendingApprovalDocumentsList];
                    missingDocumentsList = [...missingDocumentsList, ...stopCounts.missingDocumentsList];
                });
            }

            setPendingApprovalDocuments(pendingApprovalDocumentsList);
            setMissingDocuments(missingDocumentsList);
        }

        return () => {
        };
    }, [documents, load, stops, isAdmin, userEntityType]);

    //#endregion

    return (
        <>
            <Row gutter={[24, 24]}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    <LoadStatusCard
                        loadId={load.id}
                        irisLoadId={load.irisId}
                        load={load}
                        stops={stops}
                        currentStop={currentStop}
                        currentStopType={currentStopType}
                        currentStatus={currentStatus}
                        invoiceLineItems={invoiceLineItems}
                        transactions={transactions}
                        claims={claims}
                        entityType={entityType}
                        entityId={entityId}
                        documents={documents}
                        accounts={accounts}
                        style={{ border: '1px solid #b7eb8f' }}
                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                    >
                        {load && load.assignedCarrier && load.assignedCarrier.carrier && load.assignedCarrier.carrier.pointOfContact ? <DataContactInfoRow separateLines={true} title="Carrier Contact" valueStyle={{ marginLeft: 16 }} name={load.assignedCarrier.carrier.pointOfContact.name} phone={load.assignedCarrier.carrier.pointOfContact.phone} phoneExt={load.assignedCarrier.carrier.pointOfContact.phoneExt} /> : null}
                        {load && load.assignedCarrier && load.assignedCarrier.afterHoursPhone ? <DataPhoneNumberRow title="Carrier After Hours Phone" phone={load.assignedCarrier.afterHoursPhone} phoneExt={load.assignedCarrier.afterHoursPhoneExt} /> : null}
                        <br />
                        {load && load.shipper && load.shipper.shipper && load.shipper.shipper.pointOfContact ? <DataContactInfoRow separateLines={true} title="Shipper Contact" valueStyle={{ marginLeft: 16 }} name={load.shipper.shipper.pointOfContact.name} phone={load.shipper.shipper.pointOfContact.phone} phoneExt={load.shipper.shipper.pointOfContact.phoneExt} /> : null}
                        {load && load.shipper && load.shipper.afterHoursPhone ? <DataPhoneNumberRow title="Shipper After Hours Phone" phone={load.shipper.afterHoursPhone} phoneExt={load.shipper.afterHoursPhoneExt} /> : null}
                        <br />
                        <LoadRepresentativeCard title="Representative" load={load} entityType={entityType} entityId={entityId} currentStatus={currentStatus} />
                        <br />
                        <div><b>Documents:</b> <a style={{ textDecoration: 'underline' }} href="#documents">view</a></div>
                        {createMissingDocumentList()}
                        {createPendingApprovalList()}
                    </LoadStatusCard>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                    <ComplexCard
                        title={eta()}
                        style={{ minHeight: 400, border: '1px solid #b7eb8f' }}
                        headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14, textAlign: 'center' }}
                    >
                        <Row>
                            <Col span={10}>
                                {renderStops()}
                            </Col>
                            <Col span={14}>
                                <LoadMap
                                    height={'300px'}
                                    key={load.id}
                                    loadId={load.id}
                                    irisLoadId={load.irisId}
                                    load={load}
                                    stops={stops}
                                    entityType={entityType}
                                    entityId={entityId}
                                    containerName={`load-details-map-${load.id}`}
                                />
                            </Col>
                        </Row>
                    </ComplexCard>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                    <LoadDetailsCard
                        title="Load Details"
                        load={load}
                        stops={stops}
                        invoiceLineItems={invoiceLineItems}
                        loadCancellations={loadCancellations}
                        carrierRejections={carrierRejections}
                        isDeadHeading={isDeadHeading}
                        currentStatus={currentStatus}
                        bols={bols}
                        commodities={commodities}
                        entityType={entityType}
                        entityId={entityId}
                        headStyle={{ fontSize: 14 }}
                    />
                </Col>
                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                    <LoadShipperCard
                        title="Shipper"
                        load={load}
                        entityType={entityType}
                        entityId={entityId}
                        documents={documents}
                        currentStatus={currentStatus}
                        headStyle={{ fontSize: 14 }}
                    />
                </Col>
                <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <LoadCarrierCard
                                title="Carrier"
                                load={load}
                                stops={stops}
                                entityType={entityType}
                                entityId={entityId}
                                documents={documents}
                                currentStatus={currentStatus}
                                invoiceLineItems={invoiceLineItems}
                                headStyle={{ fontSize: 14 }}
                                history={props.history}
                            />
                        </Col>
                    </Row>
                    {(carrierId !== undefined && carrierId !== null) ? (
                        <>
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <LoadDriverCard
                                        title="Driver 1"
                                        driver={drivers !== undefined && drivers !== null ? (drivers.length > 0 ? drivers[0] : null) : null}
                                        load={load}
                                        entityType={entityType}
                                        entityId={entityId}
                                        documents={documents}
                                        currentStatus={currentStatus}
                                        headStyle={{ fontSize: 14 }}
                                    />
                                </Col>
                            </Row>
                            {hasTeamDriving === true ? (
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <LoadDriverCard
                                            title="Driver 2"
                                            driver={drivers !== undefined && drivers !== null ? (drivers.length > 1 ? drivers[1] : null) : null}
                                            load={load}
                                            entityType={entityType}
                                            entityId={entityId}
                                            documents={documents}
                                            currentStatus={currentStatus}
                                            headStyle={{ fontSize: 14 }}
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <LoadAssetsCard
                                        title="Load Assets"
                                        load={load}
                                        entityType={entityType}
                                        entityId={entityId}
                                        documents={documents}
                                        currentStatus={currentStatus}
                                        headStyle={{ fontSize: 14 }}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : null}
                </Col>
                {(entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER") ? (
                    <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        {((entityType === 'STAFF' || (entityType === 'SHIPPER' && load.serviceType === 'TMS')) && (load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED')) ? (
                            <Row gutter={[12, 12]}>
                                <Col span={24}>
                                    <LoadCompletionCard
                                        title="Load Completion Checklist"
                                        load={load}
                                        stops={stops}
                                        invoiceLineItems={invoiceLineItems}
                                        transactions={transactions}
                                        claims={claims}
                                        documents={documents}
                                        entityType={entityType}
                                        headStyle={{ fontSize: 14 }}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <LoadRateCard
                                    title="Financials"
                                    invoiceLineItems={invoiceLineItems}
                                    transactions={transactions}
                                    onTabChange={onTabChange}
                                    load={load}
                                    claims={claims}
                                    currentStatus={currentStatus}
                                    entityType={entityType}
                                    entityId={entityId}
                                    headStyle={{ fontSize: 14 }}

                                    updatePricingBtn={props.updatePricingBtn}
                                    updatePricingIcon={props.updatePricingIcon}
                                />
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </Row>
        </>
    );
};

export default LoadDetails;