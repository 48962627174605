import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Drawer, Dropdown, Button, Menu } from 'antd';
import EditEmployee from "../../components/EditEmployee/EditEmployee";
import NewEmployee from "../../components/NewEmployee/NewEmployee";
import classes from './Employees.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';
import { DownOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

const stringFormatter = new StringFormatter();

const Employees = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const singularEntityName = "Employee";
    const pluralEntityName = "Employees";

    const columnSearchFilter = new ColumnSearchFilter();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isLoading);
    const records = useSelector(state => state.accountUsers.records);
    const pagination = useSelector(state => state.accountUsers.pagination);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [searchText, setSearchText] = useState({ email: '', irisId: '', firstName: '', lastName: '' });

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const openEmployeeView = (id) => {
        props.history.push({ pathname: `/employees/${id}` }, {
            previousPageTitle: "Employees",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openEmployeeView(record.id); }}>
                    <span>Go to Employee Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDriver: false,
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchAccountUsers(searchParams, false));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountUsersSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDriver: false, 
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (filters !== undefined && filters !== null) {
            if (filters.irisId !== undefined && filters.irisId !== null && filters.irisId.length > 0) {
                searchParams['irisId:contains'] = filters.irisId[0];
            }
            if (filters.email !== undefined && filters.email !== null && filters.email.length > 0) {
                searchParams['email:contains'] = filters.email[0];
            }
        }

        dispatch(actionCreators.fetchAccountUsers(searchParams, false));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Employee ID',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Employee Id', searchText, setSearchText)
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            ...columnSearchFilter.getColumnSearchProps('firstName', 'First Name', searchText, setSearchText)
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            ...columnSearchFilter.getColumnSearchProps('lastName', 'Last Name', searchText, setSearchText)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...columnSearchFilter.getColumnSearchProps('email', 'Email', searchText, setSearchText)
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Phone',
            dataIndex: 'workPhone',
            key: 'workPhone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        }
    ];

    if (entityType === "STAFF") {
        if (accountId === undefined || accountId === null) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewEmployee cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditEmployee cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(Employees);
