import React from 'react';

const Thumbnail = ({ height = 24, width = 24, style = null, url, alt = null, action = null }) => {
    if (url !== undefined && url !== null) {
        return (
            <img src={url} alt={alt} style={style} width={width} height={height} onClick={action} />
        );
    } else {
        return null;
    }
};

export default Thumbnail;