import React from 'react';

const DataSMSBasicRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, noColon = false, title = null, hosCompliance = null, unsafeDriving = null, vehicleMaintenance = null, controlledSA = null, driverFitness = null, avgSMSScore = null, weightedAvgSMSScore = null, smsBasic = null }) => {
    if (smsBasic !== undefined && smsBasic !== null) {
        hosCompliance = smsBasic.hosCompliance
        unsafeDriving = smsBasic.unsafeDriving;
        vehicleMaintenance = smsBasic.vehicleMaintenance;
        controlledSA = smsBasic.controlledSA;
        driverFitness = smsBasic.driverFitness;
        avgSMSScore = smsBasic.avgSMSScore;
        weightedAvgSMSScore = smsBasic.weightedAvgSMSScore;
    }

    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<div className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </div>) : null}
            {hosCompliance !== undefined && hosCompliance !== null ? (<div className={valueClassName} style={valueStyle}>HOS Compliance: {hosCompliance}</div>) : null}
            {unsafeDriving !== undefined && unsafeDriving !== null ? (<div className={valueClassName} style={valueStyle}>Unsafe Driving: {unsafeDriving}</div>) : null}
            {vehicleMaintenance !== undefined && vehicleMaintenance !== null ? (<div className={valueClassName} style={valueStyle}>Vehicle Maintenance: {vehicleMaintenance}</div>) : null}
            {controlledSA !== undefined && controlledSA !== null ? (<div className={valueClassName} style={valueStyle}>Controlled Substance Abuse: {controlledSA}</div>) : null}
            {driverFitness !== undefined && driverFitness !== null ? (<div className={valueClassName} style={valueStyle}>Driver Fitness: {driverFitness}</div>) : null}
            {avgSMSScore !== undefined && avgSMSScore !== null ? (<div className={valueClassName} style={valueStyle}>Avg: {avgSMSScore}</div>) : null}
            {weightedAvgSMSScore !== undefined && weightedAvgSMSScore !== null ? (<div className={valueClassName} style={valueStyle}>Weighted Avg: {weightedAvgSMSScore}</div>) : null}
        </div>
    );
};

export default DataSMSBasicRow;