import React, { useState } from 'react';
import { Button, Card, Modal } from "antd";
import classes from './DispatchDriverCard.module.scss';
import DispatchAddDriver from "../../components/DispatchAddDriver/DispatchAddDriver";
import DispatchChangeDriver from '../DispatchChangeDriver/DispatchChangeDriver';
import RemoveLoadDriver from "../../components/RemoveLoadDriver/RemoveLoadDriver";
import { useSelector } from "react-redux";
import ActionButton from '../../components/ActionButton/ActionButton';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faIdCard, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import ActionDoubleButton from '../ActionDoubleButton/ActionDoubleButton';

const DispatchDriverCard = props => {
    //#region props and constants

    const load = props.load !== undefined && props.load !== null ? props.load : {};
    const stops = props.stops !== undefined && props.stops !== null && props.stops.length > 0 ? props.stops : [];
    const drivers = props.drivers !== undefined && props.drivers !== null ? props.drivers : [];
    const shifts = props.shifts !== undefined && props.shifts !== null ? props.shifts : [];
    const bundledLoads = props.bundledLoads !== undefined && props.bundledLoads !== null && props.bundledLoads.length > 0 ? props.bundledLoads : [];
    const driverLoads = props.driverLoads !== undefined && props.driverLoads !== null && props.driverLoads.length > 0 ? props.driverLoads : [];
    const setAssetSideBar = props.setAssetSideBar;
    const driverSideBar = props.driverSideBar;
    const setDriverSideBar = props.setDriverSideBar;
    const displayAsText = props.displayAsText !== undefined && props.displayAsText !== null && props.displayAsText === true ? true : false;
    const setSelectedLoadToDispatchId = props.setSelectedLoadToDispatchId;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;
    const style = props.style;

    const carrierId = load !== undefined && load !== null && !isEmpty(load) && load.assignedCarrierId !== undefined && load.assignedCarrierId !== null ? load.assignedCarrierId : null;
    const hasTeamDriving = load !== undefined && load !== null && !isEmpty(load) && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false;
    const loadDriverIds = load !== undefined && load !== null && !isEmpty(load) && load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0 ? load.driverIds : [];
    const loadDriverId1 = loadDriverIds !== undefined && loadDriverIds !== null && loadDriverIds.length > 0 ? loadDriverIds[0] : null;
    const loadDriverId2 = loadDriverIds !== undefined && loadDriverIds !== null && loadDriverIds.length > 1 && hasTeamDriving === true ? loadDriverIds[1] : null;

    //#endregion
    //#region useSelectors

    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [showRemoveDriverModal, setShowRemoveDriverModal] = useState(false);

    //#endregion
    //#region Load to Dispatch Methods

    const onSelectLoadToDispatch = () => {
        if (isBundledLoad === true && load !== undefined && load !== null && !isEmpty(load)) {
            setAssetSideBar(null);
            setDriverSideBar(null);
            setSelectedLoadToDispatchId(load.id);
        }
    };

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Modal
            title={"Assign Driver to Load"}
            visible={true}
            onCancel={(e) => { setDriverSideBar(null); }}
            maskClosable={false}
            footer={null}
        >
            <DispatchAddDriver
                cancel={() => { setDriverSideBar(null); }}
                carrierId={carrierId}
                drivers={drivers}
                shifts={shifts}
                load={load}
                stops={stops}
                isBundledLoad={isBundledLoad}
                bundledLoads={bundledLoads}
                driverLoads={driverLoads}
            />
        </Modal>
    );

    const changeEntityComponents = (driver) => {
        return (
            <Modal
                title={"Change Driver"}
                visible={true}
                onCancel={(e) => { setDriverSideBar(null); }}
                maskClosable={false}
                footer={null}
            >
                <DispatchChangeDriver
                    cancel={() => { setDriverSideBar(null); }}
                    carrierId={carrierId}
                    drivers={drivers}
                    shifts={shifts}
                    load={load}
                    stops={stops}
                    driver={driver}
                    isBundledLoad={isBundledLoad}
                    bundledLoads={bundledLoads}
                    driverLoads={driverLoads}
                />
            </Modal>
        );
    };

    const removeEntityComponents = (driver) => {
        return (
            <Modal
                title={null}
                visible={showRemoveDriverModal === true}
                maskClosable={false}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
            >
                <Card
                    title={"Remove Driver from Load"}
                    style={{ border: '1px solid #D8D8D8' }}
                    bodyStyle={{ minHeight: 400, padding: 12 }}
                    headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                    extra={<Button type="link" icon={<CloseOutlined />} onClick={(e) => { setShowRemoveDriverModal(false); }}></Button>}
                >
                    <RemoveLoadDriver
                        cancel={() => { setShowRemoveDriverModal(false); }}
                        driver={driver}
                        load={load}
                        isBundledLoad={isBundledLoad}
                    />
                </Card>
            </Modal>
        );
    };

    const renderDriverDetails = (loadDriver) => {
        if (loadDriver !== undefined && loadDriver !== null && !isEmpty(loadDriver) && loadDriver.driver !== undefined && loadDriver.driver !== null) {
            return (
                <span>{loadDriver.firstName + ' ' + loadDriver.lastName}</span>
            );
        } else {
            return null
        }
    };

    //#endregion

    if (load !== undefined && load !== null && !isEmpty(load) && load.isDeleted === false && load.loadStatus !== 'CANCELLED') {
        let loadDriver1 = null;
        if (loadDriverId1 !== null) {
            loadDriver1 = drivers.find(i => i.id === loadDriverId1);
        }
        let loadDriver2 = null;
        if (loadDriverId2 !== null) {
            loadDriver2 = drivers.find(i => i.id === loadDriverId2);
        }

        if (displayAsText === true) {
            if (load.loadStatus === 'BOOKED') {
                return (
                    <>
                        {loadDriver1 !== undefined && loadDriver1 !== null && loadDriver1.driver !== undefined && loadDriver1.driver !== null ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(changeEntityComponents(loadDriver1)); setAssetSideBar(null); }}>{renderDriverDetails(loadDriver1)}</Button>
                            </div>
                        ) : (
                                <div>
                                    <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}><span>Assign {hasTeamDriving === true ? 'Driver 1' : 'Driver'}</span></Button>
                                </div>
                            )}
                        {hasTeamDriving === true && loadDriver2 !== undefined && loadDriver2 !== null && loadDriver2.driver !== undefined && loadDriver2.driver !== null ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(changeEntityComponents(loadDriver2)); setAssetSideBar(null); }}><span>{renderDriverDetails(loadDriver2)}</span></Button>
                            </div>
                        ) : (hasTeamDriving === true ? (
                            <div>
                                <Button type="link" onClick={() => { onSelectLoadToDispatch(); setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}><span>Assign Driver 2</span></Button>
                            </div>
                        ) : null)}
                    </>
                );
            } else if (load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED') {
                return (
                    <>
                        {loadDriver1 !== undefined && loadDriver1 !== null && loadDriver1.driver !== undefined && loadDriver1.driver !== null ? (
                            <div>{renderDriverDetails(loadDriver1)}</div>
                        ) : null}
                        {hasTeamDriving === true && loadDriver2 !== undefined && loadDriver2 !== null && loadDriver2.driver !== undefined && loadDriver2.driver !== null ? (
                            <div>{renderDriverDetails(loadDriver2)}</div>
                        ) : null}
                    </>
                );
            } else {
                return null;
            }
        } else {
            if (load.loadStatus === 'BOOKED') {
                return (
                    <>
                        {loadDriver1 !== undefined && loadDriver1 !== null && loadDriver1.driver !== undefined && loadDriver1.driver !== null ? (
                            <div style={style}>
                                <ActionDoubleButton
                                    iconPosition="left"
                                    buttonGroupClassName={classes.buttonGroup}
                                    buttonClassName={`${classes.buttonGroupButton} ${classes.yellowButton}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faIdCard} />}
                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faExchangeAlt} />}
                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} style={{color: "#55babb"}} icon={faTimes} />}
                                    buttonText={renderDriverDetails(loadDriver1)}
                                    onClick1={() => { setDriverSideBar(changeEntityComponents(loadDriver1)); setAssetSideBar(null); }}
                                    onClick2={() => { setShowRemoveDriverModal(true); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                    buttonGroupStyle={{ marginBottom: 12, background: "#334353" }}
                                    buttonTextStyle={{ color: "#fff", background: "#334353" }}
                                    buttonIconStyle={{ background: "#334353", color: "#ffffff" }}
                                    buttonStyle={{background: "#334353"}}
                                    button1Style={{width: '100%', background: "#334353"  }}
                                    button2Style={{ minWidth: 'unset', background: "#334353"  }}
                                    button2Icon1Style={{ background: "#334353"  }}
                                    button1Icon2Style={{ background: "#334353"  }}
                                    button1Icon1Style={{ background: "#334353"  }}
                                />
                                {removeEntityComponents(loadDriver1)}
                            </div>
                        ) : (
                                <div style={style}>
                                    <ActionButton
                                        iconPosition="left"
                                        buttonClassName={`${classes.button}`}
                                        buttonIconClassName={classes.iconContainer}
                                        buttonTextClassName={classes.textContainer}
                                        buttonIcon={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faIdCard} />}
                                        buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faPlus} />}
                                        buttonText={<span>Assign {hasTeamDriving === true ? 'Driver 1' : 'Driver'}</span>}
                                        onClick={() => { setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}
                                        disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                        buttonTextStyle={{ color: "#fff", borderRadius: "10px", background: "#334353" }}
                                        buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                        buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                    />
                                </div>
                            )}
                        {hasTeamDriving === true && loadDriver2 !== undefined && loadDriver2 !== null && loadDriver2.driver !== undefined && loadDriver2.driver !== null ? (
                            <div style={style}>
                                <ActionDoubleButton
                                    iconPosition="left"
                                    buttonGroupClassName={classes.buttonGroup}
                                    buttonClassName={`${classes.buttonGroupButton} ${classes.yellowButton}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    button1Icon1={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faIdCard} />}
                                    button1Icon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faExchangeAlt} />}
                                    button2Icon1={<FontAwesomeIcon className={classes.button2icon1} style={{color: "#55babb"}} icon={faTimes} />}
                                    buttonText={renderDriverDetails(loadDriver2)}
                                    onClick1={() => { setDriverSideBar(changeEntityComponents(loadDriver2)); setAssetSideBar(null); }}
                                    onClick2={() => { setShowRemoveDriverModal(true); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                    buttonGroupStyle={{ marginBottom: 12, background: "#334353" }}
                                    buttonTextStyle={{ color: "#fff", background: "#334353" }}
                                    buttonIconStyle={{ background: "#334353", color: "#ffffff" }}
                                    buttonStyle={{background: "#334353"}}
                                    button1Style={{width: '100%', background: "#334353"  }}
                                    button2Style={{ minWidth: 'unset', background: "#334353"  }}
                                    button2Icon1Style={{ background: "#334353"  }}
                                    button1Icon2Style={{ background: "#334353"  }}
                                    button1Icon1Style={{ background: "#334353"  }}
                                />
                                {removeEntityComponents(loadDriver2)}
                            </div>
                        ) : (hasTeamDriving === true ? (
                            <div style={style}>
                                <ActionButton
                                    iconPosition="left"
                                    buttonClassName={`${classes.button}`}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<FontAwesomeIcon className={classes.button1icon1} style={{color: "#55babb"}} icon={faIdCard} />}
                                    buttonIcon2={<FontAwesomeIcon className={classes.button1icon2} style={{color: "#55babb"}} icon={faPlus} />}
                                    buttonText={<span>Assign Driver 2</span>}
                                    onClick={() => { setDriverSideBar(newEntityComponents); setAssetSideBar(null); }}
                                    disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING')}

                                    buttonTextStyle={{ color: "#fff", borderRadius: "10px", background: "#334353" }}
                                    buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                    buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                />
                            </div>
                        ) : null)}
                    </>
                );
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

export default DispatchDriverCard;
