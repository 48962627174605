import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataDateTimeRangeRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = null, valueStyle = null, startTime, endTime, timeZone = null, separateLines = false, noColon = false }) => {
    if (separateLines === true) {
        return (
            <div className={className} style={style}>
                {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}: </span>) : null}
                <div className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", startTime, null, timeZone)} -</div>
                <div className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", endTime, null, timeZone)}</div>
            </div>
        );
    } else {
        return (
            <div className={className} style={style}>
                {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
                <span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString("MomentDateTime", startTime, null, timeZone)} - {stringFormatter.toFormattedString("MomentDateTime", endTime, null, timeZone)}</span>
            </div>
        );
    }
};

export default DataDateTimeRangeRow;