import React, { useMemo, useState } from 'react';
import { Button, Input, Select, Row, Col, Drawer, Tooltip, Checkbox, InputNumber, Alert, Switch } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from "react-redux";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import { isEmpty } from 'lodash';
import { convertToObject, removeEmpty } from '../../shared/objectUtils';
import Form from '../Form/Form';
import FormItemFile from '../FormItemFile/FormItemFile';
import Fieldset from '../FormFieldset/FormFieldset';
import Enums from '../../shared/enums';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormButtons from '../FormButtons/FormButtons';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const { Option } = Select;
const { Search } = Input;
const stringFormatter = new StringFormatter();

const NewAccountCompany = props => {
    const fullWidth = global.window.innerWidth;

    const companyData = props.data;
    const isCarrier = props.data !== undefined && props.data !== null && props.data.isCarrier !== undefined && props.data.isCarrier !== null && props.data.isCarrier === true ? true : false;
    const isProducer = props.data !== undefined && props.data !== null && props.data.isProducer !== undefined && props.data.isProducer !== null && props.data.isProducer === true ? true : false;
    const isReceiver = props.data !== undefined && props.data !== null && props.data.isReceiver !== undefined && props.data.isReceiver !== null && props.data.isReceiver === true ? true : false;
    const isShipper = props.data !== undefined && props.data !== null && props.data.isShipper !== undefined && props.data.isShipper !== null && props.data.isShipper === true ? true : false;
    const setLogoFile = props.setLogoFile;
    const logoFileList = props.logoFileList;
    const setLogoFileList = props.setLogoFileList;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const methods2 = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const states = useSelector(state => state.orchestrator.states);
    const services = useSelector(state => state.orchestrator.services);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const entityType = useSelector(state => state.auth.entityType);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const billingFrequencyOptions = Enums.BillingFrequencies.selectListOptions();

    const [accountServices, setAccountServices] = useState([]);
    const [showAddNewService, setShowAddNewService] = useState(false);
    const [showOverridePrice, setShowOverridePrice] = useState(false);
    const [showAccountSetup, setShowAccountSetup] = useState(false);
    const [searchError, setSearchError] = useState(null);

    const onSearchByEIN = (value) => {
        if (value && value !== '' && value.length > 0) {
            setShowAccountSetup(false);
            setSearchError(null);
            axiosAuthenticated.get('/accounts?ein:contains=' + value).then((res) => {
                console.log(res);
                const existingAccounts = res.data.data;
                if (existingAccounts !== undefined && existingAccounts !== null && existingAccounts.length > 0) {
                    setShowAccountSetup(false);
                    setSearchError('The EIN you provided matches another account that already exists in the system.');
                } else {
                    setShowAccountSetup(true);
                    setSearchError(null);
                }
            }).catch((error) => {
                console.log(error);
                setShowAccountSetup(false);
                setSearchError(error.message);
            });
        } else {
            setShowAccountSetup(false);
            setSearchError(null);
        }
    };

    const onSubmit = (data) => {
        let payload = removeEmpty(data);
        //console.log(cleanedData);

        payload.isShipper = isShipper;
        payload.isCarrier = isCarrier;
        payload.isReceiver = isReceiver;
        payload.isProducer = isProducer;

        if (accountServices !== undefined && accountServices !== null && accountServices.length > 0) {
            let newServices = [];
            accountServices.forEach((accountService) => {
                newServices.push({ ...accountService });
            });

            payload.accountServices = [...newServices];
        }

        props.setStepIsComplete(true);
        props.setData(payload);
        props.nextStepAction();
    };

    const saveForm = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (accountServices !== undefined && accountServices !== null && accountServices.length > 0) {
            let newServices = [];
            accountServices.forEach((accountService) => {
                newServices.push({ ...accountService });
            });

            data.accountServices = [...newServices];
        }

        props.setData(data);
    };

    const addService = (data) => {
        //console.log(data);
        let newService = {
            serviceName: data.serviceName
        };

        let service = services.find(i => i.name === data.serviceName);
        if (service !== undefined) {
            newService.service = service;
        }

        if (data.isPriceOverride === true) {
            newService.isPriceOverride = true;
            newService.priceAmount = data.priceAmount;
            newService.priceAmountUnit = data.priceAmountUnit;
            newService.billingFrequency = data.billingFrequency;
        } else {
            newService.isPriceOverride = false;
            newService.priceAmount = 0;
        }

        //console.log(newServices);
        setAccountServices(oldList => [...oldList, { ...newService }]);
        setShowAddNewService(false);
        setShowOverridePrice(false);
    };

    const removeService = (index) => {
        let updatedServices = [...accountServices];
        updatedServices.splice(index, 1);
        setAccountServices([...updatedServices]);
    };

    const toggleNewService = () => {
        if (showAddNewService === true) {
            setShowAddNewService(false);
            setShowOverridePrice(false);
        } else {
            setShowAddNewService(true);
        }
    };

    const onIsPriceOverrideChange = (value) => {
        //console.log(value);
        if (value === true) {
            setShowOverridePrice(true);
        } else {
            setShowOverridePrice(false);
        }
    };

    const copyCompanyAddressToShippingAddress = () => {
        let payload = removeEmpty(methods.getValues());
        //console.log(payload);
        let data = convertToObject(payload);

        if (data.companyAddress !== undefined && data.companyAddress !== null) {
            methods.setValue('shippingAddress.streetAddress1', data.companyAddress.streetAddress1);
            methods.setValue('shippingAddress.streetAddress2', data.companyAddress.streetAddress2);
            methods.setValue('shippingAddress.city', data.companyAddress.city);
            methods.setValue('shippingAddress.state', data.companyAddress.state);
            methods.setValue('shippingAddress.postalCode', data.companyAddress.postalCode);
            methods.setValue('shippingAddress.country', data.companyAddress.country);
        }
    };

    useMemo(() => {
        if (companyData !== undefined && companyData !== null && !isEmpty(companyData)) {
            if (companyData.ein !== undefined && companyData.ein !== null) {
                setShowAccountSetup(true);
                setSearchError(null);
            }
        }
    }, [companyData]);

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    const formItemLayout2 = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 16 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };
    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    if (companyData !== undefined && companyData !== null && !isEmpty(companyData)) {
        return (
            <>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Fieldset legend="First Search for the Company by EIN to make sure they don't Already Exist in the System">
                            <FormItem {...formItemLayout} label="Employer Identification Number (EIN)" required format="vertical"
                                render={({ onChange, onBlur, value, name }) =>
                                    <Search
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e.target.value); }}
                                        value={value}
                                        name={name}
                                        placeholder="EIN"
                                        onSearch={onSearchByEIN}
                                    />}
                                rules={{ required: "Required Field" }}
                                name="ein"
                                help={
                                    <div>
                                        <strong>Enter the EIN and click the search button to continue</strong>
                                    </div>
                                }
                                defaultValue={companyData.ein ? companyData.ein : ''}
                            />
                            {searchError && <Alert message={`${searchError}`} type="error" />}
                        </Fieldset>
                        {showAccountSetup === true ? (
                            <>
                                <Fieldset legend="Company Details (* indicates a required field)">
                                    <input type="checkbox" name="isCarrier" ref={methods.register} defaultChecked={isCarrier} style={{ display: 'none' }} />
                                    <input type="checkbox" name="isProducer" ref={methods.register} defaultChecked={isProducer} style={{ display: 'none' }} />
                                    <input type="checkbox" name="isReceiver" ref={methods.register} defaultChecked={isReceiver} style={{ display: 'none' }} />
                                    <input type="checkbox" name="isShipper" ref={methods.register} defaultChecked={isShipper} style={{ display: 'none' }} />
                                    <FormItem {...formItemLayout} label="Company Name" required format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                                        rules={{ required: 'Required Field' }}
                                        name="name"
                                        defaultValue={companyData.name ? companyData.name : ""}
                                    />
                                    <FormItemFile {...formItemLayout} label="Company Logo" name="logoFile">
                                        <UploadFile
                                            beforeUpload={(file) => {
                                                setLogoFile(file);
                                                return false;
                                            }}
                                            onRemove={(file) => {
                                                setLogoFile(null);
                                            }}
                                            message="Please upload a photo of your company's logo."
                                            setFileList={setLogoFileList}
                                            fileList={logoFileList}
                                        />
                                    </FormItemFile>
                                </Fieldset>
                                <Fieldset legend="Company Contact Information (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Company Email" required format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="email"
                                        defaultValue={companyData.email ? companyData.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Company Day Time Phone #" required1 noColon2 format="vertical"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="phone"
                                        defaultValue1={companyData.phone ? companyData.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="phoneExt"
                                        defaultValue2={companyData.phoneExt ? companyData.phoneExt : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Company After Hours Phone #" required1 noColon2 format="vertical"
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: 'Required Field',
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="afterHoursPhone"
                                        defaultValue1={companyData.afterHoursPhone ? companyData.afterHoursPhone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="afterHoursPhoneExt"
                                        defaultValue2={companyData.afterHoursPhoneExt ? companyData.afterHoursPhoneExt : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Company Fax #" format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Fax #" />}
                                        rules={{ required: false }}
                                        name="fax"
                                        defaultValue={companyData.fax ? companyData.fax : ""}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Main Point of Contact (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Name" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                        rules={{ required: "Required Field" }}
                                        name="pointOfContact.name"
                                        defaultValue={companyData.pointOfContact && companyData.pointOfContact.name ? companyData.pointOfContact.name : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Email" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                        rules={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                                message: "Please enter a valid email address"
                                            }
                                        }}
                                        name="pointOfContact.email"
                                        defaultValue={companyData.pointOfContact && companyData.pointOfContact.email ? companyData.pointOfContact.email : ""}
                                    />
                                    <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="vertical" required1 noColon2
                                        render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                        rules1={{
                                            required: "Required Field",
                                            pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digit phone number with no special characters"
                                            }
                                        }}
                                        name1="pointOfContact.phone"
                                        defaultValue1={companyData.pointOfContact && companyData.pointOfContact.phone ? companyData.pointOfContact.phone : ""}
                                        render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                        rules2={{ required: false }}
                                        name2="pointOfContact.phoneExt"
                                        defaultValue2={companyData.pointOfContact && companyData.pointOfContact.phoneExt ? companyData.pointOfContact.phoneExt : ""}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Main Physical Address (* indicates a required field)">
                                    <FormItem {...formItemLayout} label="Street Address 1" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.streetAddress1"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.streetAddress1 ? companyData.companyAddress.streetAddress1 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                        rules={{ required: false }}
                                        name="companyAddress.streetAddress2"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.streetAddress2 ? companyData.companyAddress.streetAddress2 : ""}
                                    />
                                    <FormItem {...formItemLayout} label="City" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.city"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.city ? companyData.companyAddress.city : ""}
                                    />
                                    <FormItem {...formItemLayout} label="State" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a State"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.state"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.state ? companyData.companyAddress.state : undefined}
                                    />
                                    <FormItem {...formItemLayout} label="Postal Code" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.postalCode"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.postalCode ? companyData.companyAddress.postalCode : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Country" format="vertical" required
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select a Country"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="USA" key="USA">United States</Option>
                                            </Select>
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="companyAddress.country"
                                        defaultValue={companyData.companyAddress && companyData.companyAddress.country ? companyData.companyAddress.country : undefined}
                                    />
                                </Fieldset>
                                <Fieldset legend="Company Mailing Address (if different from the Company's Physical Address)">
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Button type="primary" onClick={() => { copyCompanyAddressToShippingAddress(); }}>Copy from Company Main Physical Address</Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <FormItem {...formItemLayout} label="Street Address 1" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                                rules={{ required: false }}
                                                name="shippingAddress.streetAddress1"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.streetAddress1 ? companyData.shippingAddress.streetAddress1 : ""}
                                            />
                                            <FormItem {...formItemLayout} label="Street Address 2" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                                rules={{ required: false }}
                                                name="shippingAddress.streetAddress2"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.streetAddress2 ? companyData.shippingAddress.streetAddress2 : ""}
                                            />
                                            <FormItem {...formItemLayout} label="City" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                                rules={{ required: false }}
                                                name="shippingAddress.city"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.city ? companyData.shippingAddress.city : ""}
                                            />
                                            <FormItem {...formItemLayout} label="State" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => (
                                                    <Select
                                                        placeholder="Please Select a State"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                                    </Select>
                                                )}
                                                rules={{ required: false }}
                                                name="shippingAddress.state"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.state ? companyData.shippingAddress.state : undefined}
                                            />
                                            <FormItem {...formItemLayout} label="Postal Code" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                                rules={{ required: false }}
                                                name="shippingAddress.postalCode"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.postalCode ? companyData.shippingAddress.postalCode : ""}
                                            />
                                            <FormItem {...formItemLayout} label="Country" format="vertical"
                                                render={({ onChange, onBlur, value, name }) => (
                                                    <Select
                                                        placeholder="Please Select a Country"
                                                        allowClear={true}
                                                        style={{ width: '100%' }}
                                                        virtual={false}
                                                        onBlur={onBlur}
                                                        onChange={(selected) => { onChange(selected); }}
                                                        value={value}
                                                        name={name}
                                                        showSearch={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="USA" key="USA">United States</Option>
                                                    </Select>
                                                )}
                                                rules={{ required: false }}
                                                name="shippingAddress.country"
                                                defaultValue={companyData.shippingAddress && companyData.shippingAddress.country ? companyData.shippingAddress.country : undefined}
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                                {(entityType === "STAFF" && isAdmin === true) ? (
                                    <Fieldset legend="Services (if applicable)">
                                        {accountServices.map((accountService, index) => (
                                            <Row gutter={[16, 16]} key={`services-${index}`}>
                                                <Col span={18}>
                                                    <FormItemDisplay label={accountService.service.displayName} format="horizontal">
                                                        <DataRow title="Price" value={accountService.isPriceOverride ? accountService.priceAmount : accountService.service.priceAmount} units={accountService.isPriceOverride ? accountService.priceAmountUnit : accountService.service.priceAmountUnit} dataType="Money" />
                                                        <DataRow title="Billing Frequency" value={accountService.isPriceOverride ? accountService.billingFrequency : accountService.service.billingFrequency} dataType="BillingFrequency" />
                                                    </FormItemDisplay>
                                                </Col>
                                                <Col span={6}>
                                                    <Button icon={<DeleteOutlined />} onClick={() => { removeService(index); }} />
                                                </Col>
                                            </Row>
                                        ))}
                                        <Tooltip placement="left" title="Add Service">
                                            <Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={toggleNewService} style={{ margin: "10px", float: "right" }} />
                                        </Tooltip>
                                    </Fieldset>
                                ) : null}
                                <Fieldset legend="Send Invitation (email or text) to Account Users Upon Account Creation">
                                    <FormItem {...formItemLayout} label="Send Invitation" format="vertical"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Checkbox
                                                onBlur={onBlur}
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                                name={name}
                                            />
                                        )}
                                        name="isInvited"
                                        defaultValue={companyData.isInvited && companyData.isInvited === true ? true : false}
                                    />
                                </Fieldset>
                            </>
                        ) : null}
                        <div
                            style={{
                                position: 'relative',
                                right: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                                marginTop: '0px',
                            }}
                        >
                            {props.cancelButton}
                            <Button onClick={() => { saveForm(); }} style={{ marginRight: 8 }} disabled={showAccountSetup === false}>Save Changes</Button>
                            <Button type="primary" htmlType="submit" disabled={showAccountSetup === false}>Go to Next Step</Button>
                        </div>
                    </Form>
                </FormProvider>
                <Drawer
                    title={'Add New Service'}
                    width={fullWidth > 720 ? fullWidth / 2 : 360}
                    onClose={toggleNewService}
                    visible={showAddNewService}
                    bodyStyle={{ paddingBottom: 80 }}
                    style={{ zIndex: 1000 }}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                >
                    <FormProvider {...methods2}>
                        <Form onSubmit={methods2.handleSubmit(addService)}>
                            <FormItem {...formItemLayout} label="Service" format="vertical" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Service"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {services.map(a => <Option value={a.name} key={a.name}>{`${a.displayName} - ${stringFormatter.toFormattedMoney(a.priceAmount)}${a.billingFrequency !== 'NONE' ? ' billed ' + stringFormatter.toFormattedString('BillingFrequency', a.billingFrequency, null) : ''}`}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="serviceName"
                            />
                            <FormItem {...formItemLayout} label="Over-Ride Price?" format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onBlur={onBlur}
                                        onChange={(checked, event) => { onChange(checked); onIsPriceOverrideChange(checked); }}
                                        checked={value}
                                        name={name}
                                    />
                                )}
                                name="isPriceOverride"
                                defaultValue={false}
                            />
                            {(showOverridePrice === true) && (
                                <>
                                    <FormItemDouble {...formItemLayoutDouble} label1="Over-Ride Price" required1 required2 format="vertical"
                                        render1={({ onChange, onBlur, value, name }) => (
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                placeholder="Over-Ride Price"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={e => { onChange(e); }}
                                                value={value}
                                                name={name}
                                            />
                                        )}
                                        rules1={{ required: "Required Field" }}
                                        name1="priceAmount"
                                        render2={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Select"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                            >
                                                {currencyOptions}
                                            </Select>
                                        )}
                                        rules2={{ required: "Required Field" }}
                                        name2="priceAmountUnit"
                                    />
                                    <FormItem {...formItemLayout} label="Over-Ride Billing Frequency" required format="vertical"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please select the Over-Ride Billing Frequency"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                            >
                                                {billingFrequencyOptions}
                                            </Select>
                                        )}
                                        rules={{ required: "Required Field" }}
                                        name="billingFrequency"
                                    />
                                </>
                            )}
                            <FormButtons cancel={toggleNewService} submitText="Add Service" />
                        </Form>
                    </FormProvider>
                </Drawer>
            </>
        );
    } else {
        return null;
    }
};

export default NewAccountCompany;