import React, { useState } from 'react';
import { Card, Drawer, Button, Modal } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import classes from './LoadDetailsCard.module.scss';
import EditLoadEquipment from "../../components/EditLoadEquipment/EditLoadEquipment";
import DataListRow from '../../components/DataListRow/DataListRow';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import EditLoadStatusCancelled from "../../components/EditLoadStatusCancelled/EditLoadStatusCancelled";
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import LoadCancellationList from '../../components/LoadCancellationList/LoadCancellationList';
import LoadCarrierRejectionList from '../../components/LoadCarrierRejectionList/LoadCarrierRejectionList';

const LoadDetailsCard = props => {
    //#region constants

    const fullWidth = global.window.innerWidth;

    //#endregion
    //#region props

    const load = props.load;
    const isDeadHeading = props.isDeadHeading;
    const currentStatus = props.currentStatus;
    const stops = props.stops;
    const invoiceLineItems = props.invoiceLineItems;
    const loadCancellations = props.loadCancellations;
    const carrierRejections = props.carrierRejections;
    const entityType = props.entityType;
    const entityId = props.entityId;
    const bols = props.bols;
    const commodities = props.commodities;

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showCancellation, setShowCancellation] = useState(false);
    const [showLoadCancellationsModal, setShowLoadCancellationsModal] = useState(false);
    const [showCarrierRejectionsModal, setShowCarrierRejectionsModal] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleCancellation = () => {
        setShowCancellation(!showCancellation);
    };

    const toggleLoadCancellationsModal = () => {
        setShowLoadCancellationsModal(!showLoadCancellationsModal);
    };

    const toggleCarrierRejectionsModal = () => {
        setShowCarrierRejectionsModal(!showCarrierRejectionsModal);
    };

    //#endregion
    //#region displays

    const extra = entityType === "STAFF" ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={true} editText={"Edit Load Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    const editEntityComponents = (
        <Drawer
            title={"Edit Load Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadEquipment cancel={toggleEditEntity} load={load} />
        </Drawer>
    );

    const cancellationComponents = (
        <Drawer
            title={"Load Cancellation"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleCancellation}
            visible={showCancellation}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadStatusCancelled cancel={toggleCancellation} load={load} />
        </Drawer>
    );

    const loadCancellationListComponents = (
        <Modal
            title="Load Cancellations"
            visible={showLoadCancellationsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleLoadCancellationsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadCancellationList loadId={load.id} loadCancellations={loadCancellations} entityType={entityType} entityId={entityId} />
        </Modal>
    );

    const carrierRejectionListComponents = (
        <Modal
            title="Carrier Rejections"
            visible={showCarrierRejectionsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleCarrierRejectionsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadCarrierRejectionList loadId={load.id} carrierRejections={carrierRejections} entityType={entityType} entityId={entityId} />
        </Modal>
    );

    //#endregion

    return (
        <Card title={props.title} bordered={true} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={((currentStatus === 'CREATED' || currentStatus === 'PENDING') && entityType === "STAFF") ? (extra) : null}>
            <DataRow title="Load ID" value={load.irisId} dataType="String" />
            {entityType === "STAFF" ? (
                <DataRow title="Current Status" value={currentStatus} dataType="LoadDisplayStatus" />
            ) : null}
            {load.eta !== undefined && load.eta !== null && entityType === "STAFF" ? (<DataRow title="ETA" value={load.eta} dataType="MomentDateTime" timeZone={load.etaTimeZone} />) : null}
            {load.loadStartDateTime !== undefined && load.loadStartDateTime !== null && load.origin !== undefined && load.origin !== null ? (<DataRow title="Started At" value={load.loadStartDateTime} dataType="MomentDateTime" timeZone={load.origin.timeZone} />) : null}
            {load.loadEndDateTime !== undefined && load.loadEndDateTime !== null && load.destination !== undefined && load.destination !== null ? (<DataRow title="Completed At" value={load.loadEndDateTime} dataType="MomentDateTime" timeZone={load.destination.timeZone} />) : null}
            {load.loadWeight !== undefined && load.loadWeight !== null && load.loadWeight > 0 ? (<DataRow title="Load Weight" value={load.loadWeight} units={load.loadWeightUnits} dataType="Weight" />) : null}
            {load.totalWeight !== undefined && load.totalWeight !== null && load.totalWeight > 0 ? (<DataRow title="Total Weight" value={load.totalWeight} units={load.totalWeightUnits} dataType="Weight" />) : null}
            {load.customerOrderNumber !== undefined && load.customerOrderNumber !== null ? (<DataRow title="Customer Order Number" value={load.customerOrderNumber} dataType="String" />) : null}
            {bols !== undefined && bols !== null && bols.length > 0 ? (<DataListRow title="BOL Number(s)" data={bols} />) : null}
            {commodities !== undefined && commodities !== null && commodities.length > 0 ? (<DataListRow title="Bids" data={commodities} />) : null}

            <div style={{ marginTop: 12, marginBottom: 12, borderTop: '1px solid #D8D8D8' }}><span style={{ fontSize: 14, fontWeight: 'bold', color: '#000000' }}>Equipment Needs and Services</span>{(((currentStatus === 'CREATED' || currentStatus === 'PENDING') && entityType === "STAFF") || ((currentStatus === 'CREATED' || currentStatus === 'BOOKED') && entityType === "CARRIER") || ((currentStatus === 'CREATED' || currentStatus === 'PENDING') && entityType === "SHIPPER")) ? <Button style={{ fontWeight: 'bold' }} type="link" onClick={(e) => { e.stopPropagation(); toggleEditEntity(); }}>edit</Button> : null}</div>

            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.preCoolReefer !== null && load.equipmentNeeds.preCoolReefer !== undefined ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
            {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
            ) : null}
            {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
            ) : null}
            {((currentStatus === 'PENDING' || currentStatus === 'APPROVED' || currentStatus === 'PENDING_RATE_CONFIRMATION' || currentStatus === 'BOOKED' || currentStatus === 'SCHEDULED' || currentStatus === 'DEAD_HEADING' || ((currentStatus === 'IN_TRANSIT' || currentStatus === 'LAYOVER' || currentStatus === 'ARRIVING' || currentStatus === 'ARRIVED' || currentStatus === 'AT_STOP') && isDeadHeading === true)) && entityType === "STAFF") ? (
                <div style={{ marginTop: 12, marginBottom: 12, borderTop: '1px solid #D8D8D8' }}><Button type="primary" onClick={toggleCancellation} style={{ marginTop: 5, marginBottom: 5 }}>Cancel Load</Button></div>
            ) : null}
            {loadCancellations.length > 0 && entityType === "STAFF" ? (
                <div style={{ marginTop: 12, marginBottom: 12, borderTop: '1px solid #D8D8D8' }}><b>Cancellations: </b>{loadCancellations.length} <Button type="link" onClick={toggleLoadCancellationsModal}>view</Button></div>
            ) : null}
            {carrierRejections.length > 0 && entityType === "STAFF" ? (
                <div style={{ marginTop: 12, marginBottom: 12, borderTop: '1px solid #D8D8D8' }}><b>Carrier Rejections: </b>{carrierRejections.length} <Button type="link" onClick={toggleCarrierRejectionsModal}>view</Button></div>
            ) : null}

            {editEntityComponents}
            {cancellationComponents}
            {loadCancellationListComponents}
            {carrierRejectionListComponents}
        </Card>
    );
};

export default LoadDetailsCard;