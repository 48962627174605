import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { DownOutlined, InfoOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Typography, Modal, Spin, Dropdown, Menu } from 'antd';
import classes from './QueueCriticalLoads.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faExternalLinkAlt, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import StringFormatter from '../../shared/stringFormatter';
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../DataTable/DataTable';

const { Title } = Typography;
const stringFormatter = new StringFormatter();

const QueueCriticalLoads = props => {
    const title = 'Loads With Critical Stop Status';
    const singularEntityName = "Loads With Critical Stop Status";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.metrics.criticalLoadsIsLoading);
    const data = useSelector(state => state.metrics.criticalLoads);
    const pagination = useSelector(state => state.metrics.criticalLoadsPagination);

    const [showTableModal, setShowTableModal] = useState(false);

    const toggleTableModal = () => {
        setShowTableModal(!showTableModal);
    };

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` });
    };

    const refreshQueue = () => {
        let searchParams = {
            page: 1,
            size: 20,
            sort: 'modifiedAt',
            order: 'desc',
            isDeleted: false,
            'inTransLoad:or': true,
            'inAssetBreakDown:or': true
        };

        dispatch(actionCreators.fetchCriticalLoads(searchParams));
    };

    const handleTableChange = (pagination) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchCriticalLoadsSuccess({ criticalLoadsPagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: 'modifiedAt',
            order: 'desc',
            isDeleted: false,
            'inTransLoad:or': true,
            'inAssetBreakDown:or': true
        };

        dispatch(actionCreators.fetchCriticalLoads(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record.loadId); }}>
                    <InfoOutlined />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            sorter: (a, b) => compareByAlph(a.irisId, b.irisId),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'In Transit',
                    value: 'IN_TRANSIT',
                },
                {
                    text: 'At Stop',
                    value: 'AT_STOP',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["IN_TRANSIT", "AT_STOP"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text) => { return stringFormatter.toFormattedString("LoadStatus", text, null); },
        },
        {
            title: 'Transloading',
            dataIndex: 'inTransLoad',
            key: 'inTransLoad',
            render: (text) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Asset Breakdown',
            dataIndex: 'inAssetBreakDown',
            key: 'inAssetBreakDown',
            render: (text) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        },
        {
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        }
    ];

    const buttons = (
        <>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={refreshQueue} style={{ marginRight: 8 }}>
                    <FontAwesomeIcon icon={faRedoAlt} size="lg" />
                </Button>
            </Tooltip>
            <Tooltip placement="top" title="Open Queue">
                <Button type="primary" shape='circle' onClick={toggleTableModal}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="lg" />
                </Button>
            </Tooltip>
        </>
    );

    useEffect(() => {
        refreshQueue();
    }, []);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Card bordered={true} className={classes.card} style={props.style ? props.style : null} title={title} extra={buttons} size="small">
                {pagination !== undefined && pagination !== null ? (
                    <Typography>
                        <div><Title level={3}>{pagination.total}</Title></div>
                    </Typography>
                ) : (
                        <Typography>
                            <div><Title level={3}>0</Title></div>
                        </Typography>
                    )}
            </Card>
            <Modal
                title={title}
                visible={showTableModal}
                width="100%"
                style={{ top: 0 }}
                onCancel={toggleTableModal}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
            >
                <DataTable
                    dataSource={data}
                    columns={columns}
                    pagination={pagination}
                    loading={isLoading === true}
                    onChange={handleTableChange}
                    style={{ backgroundColor: '#ffffff' }}
                    rowKey={record => record.loadId}
                />
            </Modal>
        </Spin>
    );
};

export default QueueCriticalLoads;
