import { isEmpty } from "lodash";
import axiosAuthenticated from "./axiosAuthenticated";
import moment from 'moment';
import logger from "../shared/logger";

export const getFilesMetaData = async (entityId) => {
    if (entityId !== undefined && entityId !== null) {
        try {
            const resp = await axiosAuthenticated.get('/documents', {
                params: {
                    entityId: entityId,
                    isDeleted: false
                }
            });

            return resp.data.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);
            return [];
        }
    }

    return [];
};

export const addFile = async (file, entityType, entityId, documentType, displayName, description, visibleTo, expiryDate = null, isReviewed = false, reviewStatus = null, licenseNumber = null) => {
    if (file !== undefined && file !== null) {
        const metaData = {};

        if (displayName !== undefined && displayName !== null) {
            metaData.displayName = displayName;
        }

        if (description !== undefined && description !== null) {
            metaData.description = description;
        }

        if (entityType !== undefined && entityType !== null) {
            metaData.entityType = entityType;
        }

        if (entityId !== undefined && entityId !== null) {
            metaData.entityId = entityId;
        }

        if (documentType !== undefined && documentType !== null) {
            metaData.documentType = documentType;
        }

        if (visibleTo !== undefined && visibleTo !== null) {
            metaData.visibleTo = visibleTo;
        }

        if (expiryDate !== undefined && expiryDate !== null && moment.isMoment(expiryDate)) {
            metaData.expiryDate = expiryDate.utc().toISOString();
        } else if (expiryDate !== undefined && expiryDate !== null) {
            // expiryDate is already an iso utc string
            metaData.expiryDate = expiryDate;
        }

        if (isReviewed !== undefined && isReviewed !== null) {
            metaData.isReviewed = isReviewed;
        }

        if (reviewStatus !== undefined && reviewStatus !== null) {
            metaData.reviewStatus = reviewStatus;
        }

        if (licenseNumber !== undefined && licenseNumber !== null) {
            metaData.licenseNumber = licenseNumber;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("json", JSON.stringify(metaData));
        try {
            const resp = await axiosAuthenticated.post('/documents', formData);

            //console.log(resp.data);
            return resp.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);
            return null;
        }
    }

    return null;
};

export const deleteFile = async (documentId) => {
    if (documentId !== undefined && documentId !== null) {
        const metaData = {
            "isDeleted": true
        };
        const formData = new FormData();
        if (!isEmpty(metaData)) {
            formData.append("json", JSON.stringify(metaData));
            try {
                await axiosAuthenticated.put(`/documents/${documentId}`, formData);
    
                return true;
            } catch (err) {
                logger.logErrorEvent('file_client_error', err, err.message, true);
    
                return false;
            }
        }
    }

    return false;
};

export const updateFile = async (documentId, file) => {
    if (file !== undefined && file !== null && documentId !== undefined && documentId !== null) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const resp = await axiosAuthenticated.put(`/documents/${documentId}`, formData);

            //console.log(resp.data);
            return resp.data;
        } catch (err) {
            logger.logErrorEvent('file_client_error', err, err.message, true);
            return null;
        }
    }

    return null;
};

export const updateFileMetaData = async (documentId, payload) => {
    if (documentId !== undefined && documentId !== null) {
        const metaData = {};

        if (payload.isDeleted !== undefined && payload.isDeleted !== null) {
            metaData.isDeleted = payload.isDeleted;
        }

        if (payload.isReviewed !== undefined && payload.isReviewed !== null) {
            metaData.isReviewed = payload.isReviewed;
        }

        if (payload.displayName !== undefined && payload.displayName !== null) {
            metaData.displayName = payload.displayName;
        }

        if (payload.description !== undefined && payload.description !== null) {
            metaData.description = payload.description;
        }

        if (payload.expiryDate !== undefined && payload.expiryDate !== null && moment.isMoment(payload.expiryDate)) {
            metaData.expiryDate = payload.expiryDate.utc().toISOString();
        } else if (payload.expiryDate !== undefined && payload.expiryDate !== null) {
            // expiryDate is already an iso utc string
            metaData.expiryDate = payload.expiryDate;
        }

        if (payload.visibleTo !== undefined && payload.visibleTo !== null) {
            metaData.visibleTo = payload.visibleTo;
        }

        if (payload.reviewStatus !== undefined && payload.reviewStatus !== null) {
            metaData.reviewStatus = payload.reviewStatus;
        }

        if (payload.licenseNumber !== undefined && payload.licenseNumber !== null) {
            metaData.licenseNumber = payload.licenseNumber;
        }

        const formData = new FormData();
        if (!isEmpty(metaData)) {
            formData.append("json", JSON.stringify(metaData));
            try {
                const resp = await axiosAuthenticated.put(`/documents/${documentId}`, formData);

                //console.log(resp.data);
                return resp.data;
            } catch (err) {
                logger.logErrorEvent('file_client_error', err, err.message, true);

                return null;
            }
        }
    }

    return null;
};