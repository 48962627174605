import React from 'react';
import DataTable from '../DataTable/DataTable';

const Queue = props => {
    return (
        <DataTable
            dataSource={props.dataSource}
            columns={props.columns}
            loading={props.loading}
            pagination={props.pagination}
            onChange={props.onChange}
            rowClassName={props.rowClassName}
            expandedRowRender={props.expandedRowComponents}
            expandRowByClick={true}
            style={{ backgroundColor: '#ffffff' }}
            rowKey={record => record.id}
        >
            {props.children}
        </DataTable>
    );
};

export default Queue;