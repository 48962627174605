import React from "react";
import { Button } from "antd";
import classes from './FormButtons.module.scss';

const FormButtons = (props) => {
    const containerStyle = props.containerStyle !== undefined && props.containerStyle !== null ? props.containerStyle : null;
    const cancel = props.cancel;
    const disabled = props.disabled;
    const submitText = props.submitText;
    const cancelDisabled = props.cancelDisabled;
    const submitDisabled = props.submitDisabled;

    return (
        <div style={containerStyle} className={classes.buttonContainer}>
            <Button type="danger" onClick={cancel} style={{ marginRight: 8 }} disabled={cancelDisabled ? cancelDisabled : disabled}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={submitDisabled ? submitDisabled : disabled}>{submitText}</Button>
        </div>
    );
};

export default FormButtons;