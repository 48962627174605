import React, { useState, useMemo } from 'react';
import { Card, Drawer, Modal, Button, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import DataContactInfoRow from "../../components/DataContactInfoRow/DataContactInfoRow";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import classes from './LoadShipperCard.module.scss';
import EditShipper from "../../components/EditShipper/EditShipper";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const LoadShipperCard = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Shipper";
    const load = props.load;
    const loadId = props.load.id;
    const shipper = props.load !== undefined && props.load !== null && props.load.shipper !== undefined && props.load.shipper !== null ? props.load.shipper : null;
    const isShipperActive = props.load !== undefined && props.load !== null && props.load.shipper !== undefined && props.load.shipper !== null && props.load.shipper.isActive !== undefined && props.load.shipper.isActive !== null && props.load.shipper.isActive === true ? true : false;
    const isShipperVerified = props.load !== undefined && props.load !== null && props.load.shipper !== undefined && props.load.shipper !== null && props.load.shipper.isVerified !== undefined && props.load.shipper.isVerified !== null && props.load.shipper.isVerified === true ? true : false;
    const entityType = props.entityType;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const extra = (entityType === "STAFF") ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={load.shipperId ? true : false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditShipper cancel={toggleEditEntity} record={load.shipper} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Shipper Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="ACCOUNT" entityId={load.shipperId} account={load.shipper} documentList={documents.filter(document => document.entityId === load.shipperId)} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} loadId={loadId} />
            {isShipperVerified === false && entityType === "STAFF" ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(load.shipperId); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the carrier is updated
            toggleDocumentsModal();
            // fetch the load to refresh the load with the updated carrier information
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    }, [isLoading]);

    if (load !== undefined && load !== null && load.shipper !== undefined && load.shipper !== null && load.shipper.shipper !== undefined && load.shipper.shipper !== null) {
        return (
            <>
                <Card title={props.title} bordered={true} className={classes.card} style={shipper !== undefined && shipper !== null && isShipperActive === true && isShipperVerified === true ? props.style : { ...props.style, ...missingEntityStyle }} headStyle={props.headStyle ? props.headStyle : null} extra={extra}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <DataRow title='Shipper ID' value={load.shipper.shipper.irisId} dataType='String' />
                        <DataRow title='Name' value={load.shipper.name} dataType='String' />
                        <DataRow title='D&B #' value={load.shipper.shipper.dbNum} dataType='String' />
                        <DataRow title='PBB #' value={load.shipper.shipper.blueBookNum} dataType='String' />
                        {load.shipper.shipper.pointOfContact !== undefined && load.shipper.shipper.pointOfContact !== null && load.shipper.shipper.pointOfContact.name !== undefined && load.shipper.shipper.pointOfContact.name !== null ? <DataRow title="Operations Contact" value={load.shipper.shipper.pointOfContact.name} /> : null}
                        {load.shipper.shipper.pointOfContact !== undefined && load.shipper.shipper.pointOfContact !== null && load.shipper.shipper.pointOfContact.phone !== undefined && load.shipper.shipper.pointOfContact.phone !== null ? <DataPhoneNumberRow title="Operations Phone" phone={load.shipper.shipper.pointOfContact.phone} phoneExt={load.shipper.shipper.pointOfContact.phoneExt} /> : null}
                        {load.shipper.shipper.pointOfContact !== undefined && load.shipper.shipper.pointOfContact !== null && load.shipper.shipper.pointOfContact.email !== undefined && load.shipper.shipper.pointOfContact.email !== null ? <DataRow title="Operations Email" value={load.shipper.shipper.pointOfContact.email} /> : null}
                        {load.shipper.afterHoursPhone !== undefined && load.shipper.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone" phone={load.shipper.afterHoursPhone} phoneExt={load.shipper.afterHoursPhoneExt} /> : null}
                        {/* <DataRow title='IRIS Rating' value={props.irisRating} dataType='String' /> */}
                        {entityType === "STAFF" &&
                            <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                        }
                        {(entityType === "STAFF" || entityType === "SHIPPER") && (
                            <>
                                <DataContactInfoRow valueStyle={{ marginLeft: 16 }} title="Accounts Payable" contact={load.shipper.shipper.accountsPayable} />
                                <DataAddressRow valueStyle={{ marginLeft: 16 }} title="Accounts Payable Address" address={load.shipper.shipper.billingAddress ? load.shipper.shipper.billingAddress : load.shipper.billingAddress} />
                            </>
                        )}
                        {isShipperVerified === false && entityType === "STAFF" ? (<Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Verify Account</Button>) : null}
                    </Spin>
                </Card>
                {editEntityComponents}
                {documentsComponent}
            </>
        );
    } else {
        return null;
    }
};

export default LoadShipperCard;