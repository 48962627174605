import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Keys Functions

export const fetchKeysStart = () => {
    return {
        type: actionTypes.FETCH_KEYS_START
    }
};

export const fetchKeysSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_KEYS_SUCCESS,
        payload: payload
    }
};

export const fetchKeysFail = (payload) => {
    return {
        type: actionTypes.FETCH_KEYS_FAIL,
        payload: payload
    }
};

export const clearKeys = () => {
    return {
        type: actionTypes.CLEAR_KEYS
    }
};

//#endregion

//#region Keys Methods

export const fetchKeys = (payload) => {
    return async (dispatch) => {
        const keysPath = '/keys';
        try {
            dispatch(fetchKeysStart());

            // check local storage first and if it is there, load it into redux, otherwise fetch from server
            let keysValueString = localStorage.getItem('keys');
            if (keysValueString !== undefined && keysValueString !== null) {
                // already there so load into redux
                let keysValue = JSON.parse(keysValueString);
                dispatch(fetchKeysSuccess({...keysValue}));
            } else {
                // fetch from server
                const keysRes = await axiosAuthenticated.get(keysPath);

                if (keysRes && keysRes.status === 200) {
                    const keysData = keysRes.data;

                    const keysValue = {
                        pubNubSubKey: keysData.pubnubSubKey,
                        pubNubPubKey: keysData.pubnubPushKey,
                        trimbleAPIKey: keysData.trimbleAPIKey
                    };

                    localStorage.setItem('keys', JSON.stringify(keysValue));

                    dispatch(fetchKeysSuccess({...keysValue}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchKeysFail({error: error.message}));
        }
    }
};

//#endregion