import React, { useState, useMemo } from 'react';
import { Card, Drawer, Button, Modal, Spin } from "antd";
import DataRow from "../../components/DataRow/DataRow";
import classes from './LoadDriverCard.module.scss';
import EditDriver from "../../components/EditDriver/EditDriver";
import AssignLoadDriver from "../../components/AssignLoadDriver/AssignLoadDriver";
import ChangeLoadDriver from "../../components/ChangeLoadDriver/ChangeLoadDriver";
import RemoveLoadDriver from "../../components/RemoveLoadDriver/RemoveLoadDriver";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DocumentList from '../../components/DocumentList/DocumentList';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadDriverCard = props => {
    const fullWidth = global.window.innerWidth;
    const load = props.load;
    const loadId = props.load.id;
    const carrierId = props.load.assignedCarrierId !== undefined && props.load.assignedCarrierId !== null ? props.load.assignedCarrierId : null;
    const driver = props.driver !== undefined && props.driver !== null ? props.driver : null;
    const driverId = props.driver !== undefined && props.driver !== null && props.driver.id !== undefined && props.driver.id !== null ? props.driver.id : null;
    const isApproved = props.driver !== undefined && props.driver !== null && props.driver.driver !== undefined && props.driver.driver !== null && props.driver.driver.isApproved === true ? true : false;
    const entityType = props.entityType;
    const entityId = props.entityId;
    const documents = props.documents !== undefined && props.documents !== null ? props.documents : [];
    const currentStatus = props.currentStatus;
    const singularEntityName = "Driver";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordUpdateLoading);
    const error = useSelector(state => state.accountUsers.updateRecordError);

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const extra = (entityType === "STAFF" || entityType === "CARRIER") ? (
        <AddEditChangeRemoveButtons
            showAdd={driverId === null && carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'CREATED' && load.loadStatus !== 'PENDING' && load.loadStatus !== 'APPROVED' && load.loadStatus !== 'PENDING_RATE_CONFIRMATION' && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CANCELLED' && load.loadStatus !== 'CLOSED') ? true : false} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
            showEdit={false} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={driverId !== null && carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CANCELLED') ? true : false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); toggleChangeEntity(); }}
            showRemove={driverId !== null && carrierId !== null && (load !== undefined && load !== null && load.loadStatus !== 'COMPLETED' && load.loadStatus !== 'CLOSED' && load.loadStatus !== 'REOPENED' && load.loadStatus !== 'CANCELLED' && load.loadStatus !== 'IN_TRANSIT' && load.loadStatus !== 'AT_STOP') ? true : false} removeText={"Remove " + singularEntityName + " from Load"} removeAction={(e) => { e.stopPropagation(); toggleRemoveEntity(); }}
        />
    ) : null;

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadDriver cancel={toggleNewEntity} carrierId={carrierId} load={load} />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadDriver cancel={toggleChangeEntity} driver={driver} carrierId={carrierId} load={load} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditDriver cancel={toggleEditEntity} record={driver} />
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadDriver cancel={toggleRemoveEntity} driver={driver} carrierId={carrierId} load={load} />
        </Drawer>
    );

    const approveDriver = () => {
        dispatch(actionCreators.approveDriver(driverId));
    };

    useMemo(() => {
        if (isLoading !== null && isLoading === false) {
            // close documents modal after the driver is updated
            toggleDocumentsModal();
            // fetch the load to refresh the load with the updated driver information
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    }, [isLoading]);

    const documentsComponent = (
        <Modal
            title="Driver Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <DocumentList entityType="DRIVER" entityId={driverId} documentList={documents.filter(document => document.entityId === driverId)} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} loadId={loadId} />
            {driver !== undefined && driver !== null && driver.driver !== undefined && driver.driver !== null && driver.driver.isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={approveDriver} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Approve Driver</Button>
            ) : null}
        </Modal>
    );

    const renderDriverDetails = () => {
        if (driver !== undefined && driver !== null && driver.driver !== undefined && driver.driver !== null) {
            return (
                <>
                    <DataRow title="Driver ID" value={driver.driver.irisId} dataType="String" />
                    <DataRow title="Name" value={driver.firstName + ' ' + driver.lastName} dataType="String" />
                    <DataRow title="Email" value={driver.email} dataType="String" />
                    <DataPhoneNumberRow title="Phone" phone={driver.workPhone} phoneExt={driver.workPhoneExt} />
                    {/* <DataRow title="IRIS Rating" value={props.irisRating} dataType="String" /> */}
                    <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal}>view</Button></div>
                    {isApproved === false && (entityType === "STAFF" || entityType === "CARRIER") ? (<Button style={{ marginTop: 20 }} type='primary' onClick={toggleDocumentsModal}>Approve Driver</Button>) : null}
                </>
            );
        } else {
            return <i>No Driver Assigned.</i>
        }
    };

    if (load !== undefined && load !== null && (entityType === 'STAFF' || entityType === 'CARRIER') && (load.loadStatus === 'BOOKED' || load.loadStatus === 'SCHEDULED' || load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP' || load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED')) {
        return (
            <Card title={props.title} bordered={true} className={classes.card} style={driverId !== undefined && driverId !== null && isApproved === true ? props.style : { ...props.style, ...missingEntityStyle }} headStyle={driverId !== undefined && driverId !== null && isApproved === true ? props.headStyle : { ...props.headStyle, ...missingEntityHeadStyle }} extra={extra}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {renderDriverDetails()}
                </Spin>
                {editEntityComponents}
                {newEntityComponents}
                {changeEntityComponents}
                {removeEntityComponents}
                {documentsComponent}
            </Card>
        );
    } else {
        return null;
    }
};

export default LoadDriverCard;