import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getServices} from "./orchestrator";
import logger from "../../shared/logger";

//#region Services Functions

export const fetchServicesStart = () => {
    return {
        type: actionTypes.FETCH_SERVICES_START
    }
};

export const fetchServicesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SERVICES_SUCCESS,
        payload: payload
    }
};

export const fetchServicesFail = (payload) => {
    return {
        type: actionTypes.FETCH_SERVICES_FAIL,
        payload: payload
    }
};

export const clearServices = () => {
    return {
        type: actionTypes.CLEAR_SERVICES
    }
};

export const addServiceStart = () => {
    return {
        type: actionTypes.ADD_SERVICE_START
    }
};

export const addServiceSuccess = () => {
    return {
        type: actionTypes.ADD_SERVICE_SUCCESS
    }
};

export const addServiceFail = (payload) => {
    return {
        type: actionTypes.ADD_SERVICE_FAIL,
        payload: payload
    }
};

export const addServiceErrorClear = () => {
    return {
        type: actionTypes.ADD_SERVICE_ERROR_CLEAR
    }
};

export const addServiceLoadingClear = () => {
    return {
        type: actionTypes.ADD_SERVICE_LOADING_CLEAR
    }
};

export const updateServiceStart = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_START
    }
};

export const updateServiceSuccess = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_SUCCESS
    }
};

export const updateServiceFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SERVICE_FAIL,
        payload: payload
    }
};

export const updateServiceErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_ERROR_CLEAR
    }
};

export const updateServiceLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SERVICE_LOADING_CLEAR
    }
};

export const cancelAddService = () => {
    return {
        type: actionTypes.CANCEL_ADD_SERVICE
    }
};

export const cancelUpdateService = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_SERVICE
    }
};

export const insertService = (payload) => {
    return {
        type: actionTypes.ADD_SERVICE,
        payload: payload
    }
};

export const changeService = (payload) => {
    return {
        type: actionTypes.UPDATE_SERVICE,
        payload: payload
    }
};

export const changeSingleService = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SERVICE,
        payload: payload
    }
};

export const removeService = (payload) => {
    return {
        type: actionTypes.REMOVE_SERVICE,
        payload: payload
    }
};

//#endregion

//#region Services Methods

export const fetchServices = (payload) => {
    return async (dispatch, getState) => {
        const servicesPath = '/services';
        try {
            dispatch(fetchServicesStart());

            const state = getState();
            const servicesState = {...state.services};
            let pagination = {...servicesState.pagination};
            let searchParams = {...servicesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const servicesRes = await axiosAuthenticated.get(servicesPath, {params: {...searchParams}});

            if (servicesRes && servicesRes.status === 200) {
                const services = servicesRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const features = [...orchestratorState.features];

                const transformedServices = services.map(item => {

                    let featuresList = [];
                    if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                        item.featureIds.forEach((featureId) => {
                            let feature = features.find(i => i.id === featureId);
                            if (feature !== undefined && feature !== null) {
                                featuresList.push(feature);
                            }
                        });
                    }

                    return {
                        ...item,
                        features: featuresList
                    };
                });

                // Read total count from server
                pagination.total = servicesRes.data.totalCount;
                pagination.current = servicesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchServicesSuccess({
                    records: transformedServices,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchServicesFail({error: error.message}));
        }
    }
};

export const addService = (payload) => {
    return async (dispatch, getState) => {
        const servicesPath = '/services';
        try {
            dispatch(addServiceStart());

            if (payload.priceAmount !== undefined && payload.priceAmount !== null) {
                payload.priceAmount = Number(payload.priceAmount);
                payload.priceAmountUnit = "USD";
            } else {
                payload.priceAmount = 0;
                payload.priceAmountUnit = "USD";
            }

            const serviceRes = await axiosAuthenticated.post(servicesPath, {...payload});
            if (serviceRes && serviceRes.status === 201) {
                let newService = serviceRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const features = [...orchestratorState.features];

                let featuresList = [];
                if (newService.featureIds !== undefined && newService.featureIds !== null && newService.featureIds.length > 0) {
                    newService.featureIds.forEach((featureId) => {
                        let feature = features.find(i => i.id === featureId);
                        if (feature !== undefined && feature !== null) {
                            featuresList.push(feature);
                        }
                    });
                }
                newService.features = featuresList;

                dispatch(insertService(newService));

                // refresh services orchestrator
                dispatch(getServices(true));

                dispatch(addServiceSuccess());
                dispatch(addServiceLoadingClear());
                dispatch(addServiceErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addServiceFail({error: error.message}));
        }
    }
};

export const updateService = (serviceId, payload) => {
    return async (dispatch, getState) => {
        const servicesPath = '/services';
        try {
            dispatch(updateServiceStart());

            if (payload.priceAmount !== undefined && payload.priceAmount !== null) {
                payload.priceAmount = Number(payload.priceAmount);
                payload.priceAmountUnit = "USD";
            } else {
                payload.priceAmount = 0;
                payload.priceAmountUnit = "USD";
            }

            const serviceRes = await axiosAuthenticated.put(servicesPath + `/${serviceId}`, {...payload});
            if (serviceRes && serviceRes.status === 200) {
                let updatedService = serviceRes.data;

                if (updatedService.isDeleted === true) {
                    dispatch(removeService(updatedService));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const features = [...orchestratorState.features];

                    let featuresList = [];
                    if (updatedService.featureIds !== undefined && updatedService.featureIds !== null && updatedService.featureIds.length > 0) {
                        updatedService.featureIds.forEach((featureId) => {
                            let feature = features.find(i => i.id === featureId);
                            if (feature !== undefined && feature !== null) {
                                featuresList.push(feature);
                            }
                        });
                    }
                    updatedService.features = featuresList;

                    dispatch(changeService(updatedService));
                    dispatch(changeSingleService(updatedService));
                }

                // refresh services orchestrator
                dispatch(getServices(true));

                dispatch(updateServiceSuccess());
                dispatch(updateServiceLoadingClear());
                dispatch(updateServiceErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateServiceFail({error: error.message}));
        }
    }
};

//#endregion