import logger from '../shared/logger';
import { isObject, isEmpty } from 'lodash';

const getDriverInfo = (accountUserId, accountUser, driverDocuments) => {
    if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null && accountUser.isDriver === true) {
        let fileMetaData = [];
        if (driverDocuments.length > 0) {
            fileMetaData = driverDocuments.filter(document => document.entityId === accountUserId);
        }

        let {
            hazmatLicenseFrontFileId,
            hazmatLicenseBackFileId,
            driverLicenseFrontFileId,
            driverLicenseBackFileId,
            commercialLicenseFrontFileId,
            commercialLicenseBackFileId,
            pneumaticLicenseFrontFileId,
            pneumaticLicenseBackFileId,
            mcCertificateLicenseFrontFileId,
            mcCertificateLicenseBackFileId,
            explosiveCertifiedLicenseFrontFileId,
            explosiveCertifiedLicenseBackFileId,
            mshaLicenseFrontFileId,
            mshaLicenseBackFileId,
            hazmatLicenseExpiryDate,
            driverLicenseExpiryDate,
            commercialLicenseExpiryDate,
            pneumaticLicenseExpiryDate,
            mcCertificateLicenseExpiryDate,
            explosiveCertifiedLicenseExpiryDate,
            mshaLicenseExpiryDate,
            hazmatLicenseNumber,
            driverLicenseNumber,
            commercialLicenseNumber,
            pneumaticLicenseNumber,
            mcCertificateLicenseNumber,
            explosiveCertifiedLicenseNumber,
            mshaLicenseNumber,
            licenses
        } = getDriverFileIdsNumbersAndDates(fileMetaData);

        accountUser.driver.documents = fileMetaData;
        accountUser.driver.licenses = licenses;

        accountUser.driver.hazmatLicenseFrontFileId = hazmatLicenseFrontFileId ? hazmatLicenseFrontFileId : null;
        accountUser.driver.hazmatLicenseBackFileId = hazmatLicenseBackFileId ? hazmatLicenseBackFileId : null;
        accountUser.driver.driverLicenseFrontFileId = driverLicenseFrontFileId ? driverLicenseFrontFileId : null;
        accountUser.driver.driverLicenseBackFileId = driverLicenseBackFileId ? driverLicenseBackFileId : null;
        accountUser.driver.commercialLicenseFrontFileId = commercialLicenseFrontFileId ? commercialLicenseFrontFileId : null;
        accountUser.driver.commercialLicenseBackFileId = commercialLicenseBackFileId ? commercialLicenseBackFileId : null;
        accountUser.driver.pneumaticLicenseFrontFileId = pneumaticLicenseFrontFileId ? pneumaticLicenseFrontFileId : null;
        accountUser.driver.pneumaticLicenseBackFileId = pneumaticLicenseBackFileId ? pneumaticLicenseBackFileId : null;
        accountUser.driver.mcCertificateLicenseFrontFileId = mcCertificateLicenseFrontFileId ? mcCertificateLicenseFrontFileId : null;
        accountUser.driver.mcCertificateLicenseBackFileId = mcCertificateLicenseBackFileId ? mcCertificateLicenseBackFileId : null;
        accountUser.driver.explosiveCertifiedLicenseFrontFileId = explosiveCertifiedLicenseFrontFileId ? explosiveCertifiedLicenseFrontFileId : null;
        accountUser.driver.explosiveCertifiedLicenseBackFileId = explosiveCertifiedLicenseBackFileId ? explosiveCertifiedLicenseBackFileId : null;
        accountUser.driver.mshaLicenseFrontFileId = mshaLicenseFrontFileId ? mshaLicenseFrontFileId : null;
        accountUser.driver.mshaLicenseBackFileId = mshaLicenseBackFileId ? mshaLicenseBackFileId : null;

        accountUser.driver.hazmatLicenseExpiryDate = hazmatLicenseExpiryDate ? hazmatLicenseExpiryDate : null;
        accountUser.driver.driverLicenseExpiryDate = driverLicenseExpiryDate ? driverLicenseExpiryDate : null;
        accountUser.driver.commercialLicenseExpiryDate = commercialLicenseExpiryDate ? commercialLicenseExpiryDate : null;
        accountUser.driver.pneumaticLicenseExpiryDate = pneumaticLicenseExpiryDate ? pneumaticLicenseExpiryDate : null;
        accountUser.driver.mcCertificateLicenseExpiryDate = mcCertificateLicenseExpiryDate ? mcCertificateLicenseExpiryDate : null;
        accountUser.driver.explosiveCertifiedLicenseExpiryDate = explosiveCertifiedLicenseExpiryDate ? explosiveCertifiedLicenseExpiryDate : null;
        accountUser.driver.mshaLicenseExpiryDate = mshaLicenseExpiryDate ? mshaLicenseExpiryDate : null;

        accountUser.driver.hazmatLicenseNumber = hazmatLicenseNumber ? hazmatLicenseNumber : null;
        accountUser.driver.driverLicenseNumber = driverLicenseNumber ? driverLicenseNumber : null;
        accountUser.driver.commercialLicenseNumber = commercialLicenseNumber ? commercialLicenseNumber : null;
        accountUser.driver.pneumaticLicenseNumber = pneumaticLicenseNumber ? pneumaticLicenseNumber : null;
        accountUser.driver.mcCertificateLicenseNumber = mcCertificateLicenseNumber ? mcCertificateLicenseNumber : null;
        accountUser.driver.explosiveCertifiedLicenseNumber = explosiveCertifiedLicenseNumber ? explosiveCertifiedLicenseNumber : null;
        accountUser.driver.mshaLicenseNumber = mshaLicenseNumber ? mshaLicenseNumber : null;
    }

    return accountUser;
};

const getAccountInfo = (accountId, accounts) => {
    if (accountId !== undefined && accountId !== null) {
        let account = accounts.find(account => account.id === accountId);
        if (account !== undefined && account !== null && isObject(account) && !isEmpty(account)) {
            return { ...account };
        } else {
            return null;
        }
    } else {
        return null;
    }
};

const getUserProfileInfo = (userId, users) => {
    if (userId !== undefined && userId !== null) {
        let user = users.find(user => user.user_id === userId);
        if (user !== undefined && user !== null && isObject(user) && !isEmpty(user)) {
            return { 
                ...user, 
                user: user.isDummyEmail === true ? null : user.user 
            };
        } else {
            return null;
        }
    } else {
        return null;
    }
};

const getUserFileIds = (fileMetaData) => {
    let profileImageId = null;
    try {
        if (fileMetaData !== undefined && fileMetaData !== null && fileMetaData.length > 0) {
            fileMetaData.forEach(fdmItem => {
                if (fdmItem.documentType === "PROFILE_PHOTO") {
                    profileImageId = fdmItem.id;
                }
            });
        }
    } catch (ex) {
        logger.logErrorEvent('getUserFileIds', ex, ex.message, true);
    }

    return { profileImageId };
};

const getDriverFileIdsNumbersAndDates = (fileMetaData) => {
    let hazmatLicenseFrontFileId = null;
    let hazmatLicenseBackFileId = null;
    let hazmatLicenseExpiryDate = null;
    let hazmatLicenseNumber = null;

    let driverLicenseFrontFileId = null;
    let driverLicenseBackFileId = null;
    let driverLicenseExpiryDate = null;
    let driverLicenseNumber = null;

    let commercialLicenseFrontFileId = null;
    let commercialLicenseBackFileId = null;
    let commercialLicenseExpiryDate = null;
    let commercialLicenseNumber = null;

    let pneumaticLicenseFrontFileId = null;
    let pneumaticLicenseBackFileId = null;
    let pneumaticLicenseExpiryDate = null;
    let pneumaticLicenseNumber = null;

    let mcCertificateLicenseFrontFileId = null;
    let mcCertificateLicenseBackFileId = null;
    let mcCertificateLicenseExpiryDate = null;
    let mcCertificateLicenseNumber = null;

    let explosiveCertifiedLicenseFrontFileId = null;
    let explosiveCertifiedLicenseBackFileId = null;
    let explosiveCertifiedLicenseExpiryDate = null;
    let explosiveCertifiedLicenseNumber = null;

    let mshaLicenseFrontFileId = null;
    let mshaLicenseBackFileId = null;
    let mshaLicenseExpiryDate = null;
    let mshaLicenseNumber = null;

    let hazmatLicenseFront = null;
    let hazmatLicenseBack = null;
    let explosiveCertifiedLicenseFront = null;
    let explosiveCertifiedLicenseBack = null;
    let mcCertificateLicenseFront = null;
    let mcCertificateLicenseBack = null;
    let mshaLicenseFront = null;
    let mshaLicenseBack = null;
    let pneumaticLicenseFront = null;
    let pneumaticLicenseBack = null;
    let commercialLicenseFront = null;
    let commercialLicenseBack = null;
    let driverLicenseFront = null;
    let driverLicenseBack = null;

    let licenses = [];

    try {
        if (fileMetaData !== undefined && fileMetaData !== null && fileMetaData.length > 0) {
            fileMetaData.forEach(fdmItem => {
                switch (fdmItem.documentType) {
                    case "HAZMAT_LICENSE_FRONT":
                        hazmatLicenseFrontFileId = fdmItem.id;
                        hazmatLicenseExpiryDate = fdmItem.expiryDate;
                        hazmatLicenseNumber = fdmItem.licenseNumber;
                        hazmatLicenseFront = fdmItem;
                        break;
                    case "HAZMAT_LICENSE_BACK":
                        hazmatLicenseBackFileId = fdmItem.id;
                        hazmatLicenseBack = fdmItem;
                        break;
                    case "DRIVERS_LICENSE_FRONT":
                        driverLicenseFrontFileId = fdmItem.id;
                        driverLicenseExpiryDate = fdmItem.expiryDate;
                        driverLicenseNumber = fdmItem.licenseNumber;
                        driverLicenseFront = fdmItem;
                        break;
                    case "DRIVERS_LICENSE_BACK":
                        driverLicenseBackFileId = fdmItem.id;
                        driverLicenseBack = fdmItem;
                        break;
                    case "COMMERCIAL_DRIVERS_LICENSE_FRONT":
                        commercialLicenseFrontFileId = fdmItem.id;
                        commercialLicenseExpiryDate = fdmItem.expiryDate;
                        commercialLicenseNumber = fdmItem.licenseNumber;
                        commercialLicenseFront = fdmItem;
                        break;
                    case "COMMERCIAL_DRIVERS_LICENSE_BACK":
                        commercialLicenseBackFileId = fdmItem.id;
                        commercialLicenseBack = fdmItem;
                        break;
                    case "PNEUMATIC_LICENSE_FRONT":
                        pneumaticLicenseFrontFileId = fdmItem.id;
                        pneumaticLicenseExpiryDate = fdmItem.expiryDate;
                        pneumaticLicenseNumber = fdmItem.licenseNumber;
                        pneumaticLicenseFront = fdmItem;
                        break;
                    case "PNEUMATIC_LICENSE_BACK":
                        pneumaticLicenseBackFileId = fdmItem.id;
                        pneumaticLicenseBack = fdmItem;
                        break;
                    case "MC_CERTIFICATE_LICENSE_FRONT":
                        mcCertificateLicenseFrontFileId = fdmItem.id;
                        mcCertificateLicenseExpiryDate = fdmItem.expiryDate;
                        mcCertificateLicenseNumber = fdmItem.licenseNumber;
                        mcCertificateLicenseFront = fdmItem;
                        break;
                    case "MC_CERTIFICATE_LICENSE_BACK":
                        mcCertificateLicenseBackFileId = fdmItem.id;
                        mcCertificateLicenseBack = fdmItem;
                        break;
                    case "EXPLOSIVE_CERTIFIED_LICENSE_FRONT":
                        explosiveCertifiedLicenseFrontFileId = fdmItem.id;
                        explosiveCertifiedLicenseExpiryDate = fdmItem.expiryDate;
                        explosiveCertifiedLicenseNumber = fdmItem.licenseNumber;
                        explosiveCertifiedLicenseFront = fdmItem;
                        break;
                    case "EXPLOSIVE_CERTIFIED_LICENSE_BACK":
                        explosiveCertifiedLicenseBackFileId = fdmItem.id;
                        explosiveCertifiedLicenseBack = fdmItem;
                        break;
                    case "MSHA_LICENSE_FRONT":
                        mshaLicenseFrontFileId = fdmItem.id;
                        mshaLicenseExpiryDate = fdmItem.expiryDate;
                        mshaLicenseNumber = fdmItem.licenseNumber;
                        mshaLicenseFront = fdmItem;
                        break;
                    case "MSHA_LICENSE_BACK":
                        mshaLicenseBackFileId = fdmItem.id;
                        mshaLicenseBack = fdmItem;
                        break;
                    default:
                        break;
                }
            });
        }

        licenses.push({
            licenseType: 'COMMERCIAL_DRIVERS_LICENSE',
            licenseTypeDisplayName: 'Commercial Drivers License',
            frontImageId: commercialLicenseFront && commercialLicenseFront.id ? commercialLicenseFront.id : null,
            backImageId: commercialLicenseBack && commercialLicenseBack.id ? commercialLicenseBack.id : null,
            licenseNumber: commercialLicenseFront && commercialLicenseFront.licenseNumber ? commercialLicenseFront.licenseNumber : (commercialLicenseBack && commercialLicenseBack.licenseNumber ? commercialLicenseBack.licenseNumber : null),
            expirationDate: commercialLicenseFront && commercialLicenseFront.expiryDate ? commercialLicenseFront.expiryDate : (commercialLicenseBack && commercialLicenseBack.expiryDate ? commercialLicenseBack.expiryDate : null),
            isExpired: commercialLicenseFront && commercialLicenseFront.isExpired ? commercialLicenseFront.isExpired : (commercialLicenseBack && commercialLicenseBack.isExpired ? commercialLicenseBack.isExpired : false),
            isExpiringSoon: commercialLicenseFront && commercialLicenseFront.isExpiringSoon ? commercialLicenseFront.isExpiringSoon : (commercialLicenseBack && commercialLicenseBack.isExpiringSoon ? commercialLicenseBack.isExpiringSoon : false),
            frontImage: commercialLicenseFront,
            backImage: commercialLicenseBack,
            isRequired: true,
            frontDocumentType: 'COMMERCIAL_DRIVERS_LICENSE_FRONT',
            backDocumentType: 'COMMERCIAL_DRIVERS_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'DRIVERS_LICENSE',
            licenseTypeDisplayName: 'Personal Drivers License',
            frontImageId: driverLicenseFront && driverLicenseFront.id ? driverLicenseFront.id : null,
            backImageId: driverLicenseBack && driverLicenseBack.id ? driverLicenseBack.id : null,
            licenseNumber: driverLicenseFront && driverLicenseFront.licenseNumber ? driverLicenseFront.licenseNumber : (driverLicenseBack && driverLicenseBack.licenseNumber ? driverLicenseBack.licenseNumber : null),
            expirationDate: driverLicenseFront && driverLicenseFront.expiryDate ? driverLicenseFront.expiryDate : (driverLicenseBack && driverLicenseBack.expiryDate ? driverLicenseBack.expiryDate : null),
            isExpired: driverLicenseFront && driverLicenseFront.isExpired ? driverLicenseFront.isExpired : (driverLicenseBack && driverLicenseBack.isExpired ? driverLicenseBack.isExpired : false),
            isExpiringSoon: driverLicenseFront && driverLicenseFront.isExpiringSoon ? driverLicenseFront.isExpiringSoon : (driverLicenseBack && driverLicenseBack.isExpiringSoon ? driverLicenseBack.isExpiringSoon : false),
            frontImage: driverLicenseFront,
            backImage: driverLicenseBack,
            isRequired: false,
            frontDocumentType: 'DRIVERS_LICENSE_FRONT',
            backDocumentType: 'DRIVERS_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'HAZMAT_LICENSE',
            licenseTypeDisplayName: 'HazMat License',
            frontImageId: hazmatLicenseFront && hazmatLicenseFront.id ? hazmatLicenseFront.id : null,
            backImageId: hazmatLicenseBack && hazmatLicenseBack.id ? hazmatLicenseBack.id : null,
            licenseNumber: hazmatLicenseFront && hazmatLicenseFront.licenseNumber ? hazmatLicenseFront.licenseNumber : (hazmatLicenseBack && hazmatLicenseBack.licenseNumber ? hazmatLicenseBack.licenseNumber : null),
            expirationDate: hazmatLicenseFront && hazmatLicenseFront.expiryDate ? hazmatLicenseFront.expiryDate : (hazmatLicenseBack && hazmatLicenseBack.expiryDate ? hazmatLicenseBack.expiryDate : null),
            isExpired: hazmatLicenseFront && hazmatLicenseFront.isExpired ? hazmatLicenseFront.isExpired : (hazmatLicenseBack && hazmatLicenseBack.isExpired ? hazmatLicenseBack.isExpired : false),
            isExpiringSoon: hazmatLicenseFront && hazmatLicenseFront.isExpiringSoon ? hazmatLicenseFront.isExpiringSoon : (hazmatLicenseBack && hazmatLicenseBack.isExpiringSoon ? hazmatLicenseBack.isExpiringSoon : false),
            frontImage: hazmatLicenseFront,
            backImage: hazmatLicenseBack,
            isRequired: false,
            frontDocumentType: 'HAZMAT_LICENSE_FRONT',
            backDocumentType: 'HAZMAT_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'PNEUMATIC_LICENSE',
            licenseTypeDisplayName: 'Pneumatic License',
            frontImageId: pneumaticLicenseFront && pneumaticLicenseFront.id ? pneumaticLicenseFront.id : null,
            backImageId: pneumaticLicenseBack && pneumaticLicenseBack.id ? pneumaticLicenseBack.id : null,
            licenseNumber: pneumaticLicenseFront && pneumaticLicenseFront.licenseNumber ? pneumaticLicenseFront.licenseNumber : (pneumaticLicenseBack && pneumaticLicenseBack.licenseNumber ? pneumaticLicenseBack.licenseNumber : null),
            expirationDate: pneumaticLicenseFront && pneumaticLicenseFront.expiryDate ? pneumaticLicenseFront.expiryDate : (pneumaticLicenseBack && pneumaticLicenseBack.expiryDate ? pneumaticLicenseBack.expiryDate : null),
            isExpired: pneumaticLicenseFront && pneumaticLicenseFront.isExpired ? pneumaticLicenseFront.isExpired : (pneumaticLicenseBack && pneumaticLicenseBack.isExpired ? pneumaticLicenseBack.isExpired : false),
            isExpiringSoon: pneumaticLicenseFront && pneumaticLicenseFront.isExpiringSoon ? pneumaticLicenseFront.isExpiringSoon : (pneumaticLicenseBack && pneumaticLicenseBack.isExpiringSoon ? pneumaticLicenseBack.isExpiringSoon : false),
            frontImage: pneumaticLicenseFront,
            backImage: pneumaticLicenseBack,
            isRequired: false,
            frontDocumentType: 'PNEUMATIC_LICENSE_FRONT',
            backDocumentType: 'PNEUMATIC_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'MC_CERTIFICATE_LICENSE',
            licenseTypeDisplayName: 'MC Certificate License',
            frontImageId: mcCertificateLicenseFront && mcCertificateLicenseFront.id ? mcCertificateLicenseFront.id : null,
            backImageId: mcCertificateLicenseBack && mcCertificateLicenseBack.id ? mcCertificateLicenseBack.id : null,
            licenseNumber: mcCertificateLicenseFront && mcCertificateLicenseFront.licenseNumber ? mcCertificateLicenseFront.licenseNumber : (mcCertificateLicenseBack && mcCertificateLicenseBack.licenseNumber ? mcCertificateLicenseBack.licenseNumber : null),
            expirationDate: mcCertificateLicenseFront && mcCertificateLicenseFront.expiryDate ? mcCertificateLicenseFront.expiryDate : (mcCertificateLicenseBack && mcCertificateLicenseBack.expiryDate ? mcCertificateLicenseBack.expiryDate : null),
            isExpired: mcCertificateLicenseFront && mcCertificateLicenseFront.isExpired ? mcCertificateLicenseFront.isExpired : (mcCertificateLicenseBack && mcCertificateLicenseBack.isExpired ? mcCertificateLicenseBack.isExpired : false),
            isExpiringSoon: mcCertificateLicenseFront && mcCertificateLicenseFront.isExpiringSoon ? mcCertificateLicenseFront.isExpiringSoon : (mcCertificateLicenseBack && mcCertificateLicenseBack.isExpiringSoon ? mcCertificateLicenseBack.isExpiringSoon : false),
            frontImage: mcCertificateLicenseFront,
            backImage: mcCertificateLicenseBack,
            isRequired: false,
            frontDocumentType: 'MC_CERTIFICATE_LICENSE_FRONT',
            backDocumentType: 'MC_CERTIFICATE_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'EXPLOSIVE_CERTIFIED_LICENSE',
            licenseTypeDisplayName: 'Explosive Certified License',
            frontImageId: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.id ? explosiveCertifiedLicenseFront.id : null,
            backImageId: explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.id ? explosiveCertifiedLicenseBack.id : null,
            licenseNumber: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.licenseNumber ? explosiveCertifiedLicenseFront.licenseNumber : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.licenseNumber ? explosiveCertifiedLicenseBack.licenseNumber : null),
            expirationDate: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.expiryDate ? explosiveCertifiedLicenseFront.expiryDate : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.expiryDate ? explosiveCertifiedLicenseBack.expiryDate : null),
            isExpired: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.isExpired ? explosiveCertifiedLicenseFront.isExpired : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.isExpired ? explosiveCertifiedLicenseBack.isExpired : false),
            isExpiringSoon: explosiveCertifiedLicenseFront && explosiveCertifiedLicenseFront.isExpiringSoon ? explosiveCertifiedLicenseFront.isExpiringSoon : (explosiveCertifiedLicenseBack && explosiveCertifiedLicenseBack.isExpiringSoon ? explosiveCertifiedLicenseBack.isExpiringSoon : false),
            frontImage: explosiveCertifiedLicenseFront,
            backImage: explosiveCertifiedLicenseBack,
            isRequired: false,
            frontDocumentType: 'EXPLOSIVE_CERTIFIED_LICENSE_FRONT',
            backDocumentType: 'EXPLOSIVE_CERTIFIED_LICENSE_BACK'
        });
        licenses.push({
            licenseType: 'MSHA_LICENSE',
            licenseTypeDisplayName: 'MSHA License',
            frontImageId: mshaLicenseFront && mshaLicenseFront.id ? mshaLicenseFront.id : null,
            backImageId: mshaLicenseBack && mshaLicenseBack.id ? mshaLicenseBack.id : null,
            licenseNumber: mshaLicenseFront && mshaLicenseFront.licenseNumber ? mshaLicenseFront.licenseNumber : (mshaLicenseBack && mshaLicenseBack.licenseNumber ? mshaLicenseBack.licenseNumber : null),
            expirationDate: mshaLicenseFront && mshaLicenseFront.expiryDate ? mshaLicenseFront.expiryDate : (mshaLicenseBack && mshaLicenseBack.expiryDate ? mshaLicenseBack.expiryDate : null),
            isExpired: mshaLicenseFront && mshaLicenseFront.isExpired ? mshaLicenseFront.isExpired : (mshaLicenseBack && mshaLicenseBack.isExpired ? mshaLicenseBack.isExpired : false),
            isExpiringSoon: mshaLicenseFront && mshaLicenseFront.isExpiringSoon ? mshaLicenseFront.isExpiringSoon : (mshaLicenseBack && mshaLicenseBack.isExpiringSoon ? mshaLicenseBack.isExpiringSoon : false),
            frontImage: mshaLicenseFront,
            backImage: mshaLicenseBack,
            isRequired: false,
            frontDocumentType: 'MSHA_LICENSE_FRONT',
            backDocumentType: 'MSHA_LICENSE_BACK'
        });
    } catch (error) {
        logger.logErrorEvent('getDriverFileIdsEtc', error, error.message, true);
    }

    return {
        hazmatLicenseFrontFileId, hazmatLicenseBackFileId, driverLicenseFrontFileId, driverLicenseBackFileId,
        commercialLicenseFrontFileId, commercialLicenseBackFileId, pneumaticLicenseFrontFileId, pneumaticLicenseBackFileId,
        mcCertificateLicenseFrontFileId, mcCertificateLicenseBackFileId, explosiveCertifiedLicenseFrontFileId,
        explosiveCertifiedLicenseBackFileId, mshaLicenseFrontFileId, mshaLicenseBackFileId, hazmatLicenseExpiryDate,
        driverLicenseExpiryDate, commercialLicenseExpiryDate, pneumaticLicenseExpiryDate, mcCertificateLicenseExpiryDate,
        explosiveCertifiedLicenseExpiryDate, mshaLicenseExpiryDate, hazmatLicenseNumber, driverLicenseNumber,
        commercialLicenseNumber, pneumaticLicenseNumber, mcCertificateLicenseNumber, explosiveCertifiedLicenseNumber,
        mshaLicenseNumber, licenses
    };
};

const getAccountFileIdsAndDates = (fileMetaData) => {
    let logoFileId = null;
    let insuranceFileId = null;
    let insuranceExpiryDate = null;
    let w9ExpiryDate = null;
    let w9FileId = null;
    if (fileMetaData) {
        fileMetaData.forEach(fdmItem => {
            switch (fdmItem.documentType) {
                case "ACCOUNT_LOGO":
                    logoFileId = fdmItem.id;
                    break;
                case "CARRIER_INSURANCE":
                    insuranceFileId = fdmItem.id;
                    insuranceExpiryDate = fdmItem.expiryDate;
                    break;
                case "CARRIER_W9":
                    w9FileId = fdmItem.id;
                    w9ExpiryDate = fdmItem.expiryDate;
                    break;
                default:
                    break;
            }
        });
    }

    return { logoFileId, insuranceFileId, w9FileId, insuranceExpiryDate, w9ExpiryDate };
};

const getAssetFileIds = (fileMetaData) => {
    let photoFileId = null;
    let licensePlateFileId = null;
    let insuranceFileId = null;
    let registrationFileId = null;
    if (fileMetaData) {
        fileMetaData.forEach(fdmItem => {
            switch (fdmItem.documentType) {
                case "ASSET_PHOTO":
                    photoFileId = fdmItem.id;
                    break;
                case "ASSET_LICENSE_PLATE":
                    licensePlateFileId = fdmItem.id;
                    break;
                case "ASSET_INSURANCE":
                    insuranceFileId = fdmItem.id;
                    break;
                case "ASSET_REGISTRATION":
                    registrationFileId = fdmItem.id;
                    break;
                default:
                    break;
            }
        });
    }

    return { photoFileId, licensePlateFileId, insuranceFileId, registrationFileId };
};

export default {
    getDriverInfo,
    getAccountInfo,
    getUserProfileInfo,
    getUserFileIds,
    getDriverFileIdsNumbersAndDates,
    getAccountFileIdsAndDates,
    getAssetFileIds
};