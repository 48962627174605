import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getFeatures} from "./orchestrator";
import logger from "../../shared/logger";

//#region Features Functions

export const fetchFeaturesStart = () => {
    return {
        type: actionTypes.FETCH_FEATURES_START
    }
};

export const fetchFeaturesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_FEATURES_SUCCESS,
        payload: payload
    }
};

export const fetchFeaturesFail = (payload) => {
    return {
        type: actionTypes.FETCH_FEATURES_FAIL,
        payload: payload
    }
};

export const clearFeatures = () => {
    return {
        type: actionTypes.CLEAR_FEATURES
    }
};

export const addFeatureStart = () => {
    return {
        type: actionTypes.ADD_FEATURE_START
    }
};

export const addFeatureSuccess = () => {
    return {
        type: actionTypes.ADD_FEATURE_SUCCESS
    }
};

export const addFeatureFail = (payload) => {
    return {
        type: actionTypes.ADD_FEATURE_FAIL,
        payload: payload
    }
};

export const addFeatureErrorClear = () => {
    return {
        type: actionTypes.ADD_FEATURE_ERROR_CLEAR
    }
};

export const addFeatureLoadingClear = () => {
    return {
        type: actionTypes.ADD_FEATURE_LOADING_CLEAR
    }
};

export const updateFeatureStart = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_START
    }
};

export const updateFeatureSuccess = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_SUCCESS
    }
};

export const updateFeatureFail = (payload) => {
    return {
        type: actionTypes.UPDATE_FEATURE_FAIL,
        payload: payload
    }
};

export const updateFeatureErrorClear = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_ERROR_CLEAR
    }
};

export const updateFeatureLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_FEATURE_LOADING_CLEAR
    }
};

export const cancelAddFeature = () => {
    return {
        type: actionTypes.CANCEL_ADD_FEATURE
    }
};

export const cancelUpdateFeature = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_FEATURE
    }
};

export const insertFeature = (payload) => {
    return {
        type: actionTypes.ADD_FEATURE,
        payload: payload
    }
};

export const changeFeature = (payload) => {
    return {
        type: actionTypes.UPDATE_FEATURE,
        payload: payload
    }
};

export const changeSingleFeature = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_FEATURE,
        payload: payload
    }
};

export const removeFeature = (payload) => {
    return {
        type: actionTypes.REMOVE_FEATURE,
        payload: payload
    }
};

//#endregion

//#region Features Methods

export const fetchFeatures = (payload) => {
    return async (dispatch, getState) => {
        const featuresPath = '/features';
        try {
            dispatch(fetchFeaturesStart());

            const state = getState();
            const featuresState = {...state.features};
            let pagination = {...featuresState.pagination};
            let searchParams = {...featuresState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const featuresRes = await axiosAuthenticated.get(featuresPath, {params: {...searchParams}});
            if (featuresRes && featuresRes.status === 200) {
                const features = featuresRes.data.data;

                // Read total count from server
                pagination.total = featuresRes.data.totalCount;
                pagination.current = featuresRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchFeaturesSuccess({
                    records: features,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchFeaturesFail({error: error.message}));
        }
    }
};

export const addFeature = (payload) => {
    return async (dispatch) => {
        const featuresPath = '/features';
        try {
            dispatch(addFeatureStart());

            const featuresRes = await axiosAuthenticated.post(featuresPath, {...payload});

            if (featuresRes && featuresRes.status === 201) {
                let newFeature = featuresRes.data;

                dispatch(insertFeature(newFeature));

                // refresh the feature orchestrator
                dispatch(getFeatures(true));

                dispatch(addFeatureSuccess());
                dispatch(addFeatureLoadingClear());
                dispatch(addFeatureErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addFeatureFail({error: error.message}));
        }
    }
};

export const updateFeature = (featureId, payload) => {
    return async (dispatch) => {
        const featuresPath = '/features';
        try {
            dispatch(updateFeatureStart());

            const featuresRes = await axiosAuthenticated.put(featuresPath + `/${featureId}`, {...payload});

            if (featuresRes && featuresRes.status === 200) {
                let updatedFeature = featuresRes.data;

                if (updatedFeature.isDeleted === true) {
                    dispatch(removeFeature(updatedFeature));
                } else {
                    dispatch(changeFeature(updatedFeature));
                    dispatch(changeSingleFeature(updatedFeature));
                }

                // refresh the feature orchestrator
                dispatch(getFeatures(true));

                dispatch(updateFeatureSuccess());
                dispatch(updateFeatureLoadingClear());
                dispatch(updateFeatureErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateFeatureFail({error: error.message}));
        }
    }
};

//#endregion