import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';

const Content = props => {
    const content = props.record;

    return (
        <Row>
            <Col xs={24} md={24} lg={12} xl={12}>
                {content.irisId && <DataRow title="Content ID" value={content.irisId} dataType="String" />}
                {content.eventType && <DataRow title="Event Type" value={content.eventType} dataType="String" />}
                {content.channel && <DataRow title="Channel" value={content.channel} dataType="CMSChannel" />}
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
                {content.message && <DataRow title="Message" value={content.message} dataType="String" />}
                {content.description && <DataRow title="Description" value={content.description} dataType="String" />}
            </Col>
        </Row>
    );
};

export default Content;