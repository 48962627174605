import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined, InfoOutlined, UploadOutlined } from '@ant-design/icons';
import {Button, Checkbox, Dropdown, Empty, InputNumber, Menu, Modal, Select} from 'antd';
import classes from './OpenLoads.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { compareByAlph } from '../../shared/tableUtils';
import DataTable from '../../components/DataTable/DataTable';
import { isEmpty } from 'lodash';
import FormItem from "../../components/FormItem/FormItem";
import Fieldset from "../../components/FormFieldset/FormFieldset";
import {FormProvider, useForm} from "react-hook-form";
import Form from "../../components/Form/Form";
import FormButtons from "../../components/FormButtons/FormButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const stringFormatter = new StringFormatter();

const OpenLoads = props => {
    //#region props and constants

    let  shipperId = props.shipperId;

    const carrierId = props.carrierId;
    const driverId = props.driverId;
    const assignedAccountRepId = props.assignedAccountRepId;
    const singularEntityName = "Open Load";
    const pluralEntityName = "Open Loads";
    const filter = "open";
    const loadPrimaryKey = "id"

    //#endregion
    //#region useDispatch and useSelectors
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const loadLists = useSelector(state => state.loadLists.loadLists);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const drivers = useSelector(state => state.accountUsers.records);

    if (entityType === "SHIPPER") {
        shipperId = entityId
    }
//#endregion
    //#region useStates

    const [isCarriersLoading, setIsCarriersLoading] = useState(false);
    const [carriers, setCarriers] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showBulkAssignCarrier, setShowBulkAssignCarrier] = useState(false);
    const [showBulkAssignAdminShipper, setShowBulkAssignAdminShipper] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [repeatDaysCount, setRepeatDaysCount] = useState(1);
    const [isRecurringLoad, setIsRecurringLoad] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loads, setLoads] = useState([]);
    const isRecordUpdateLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const areAllSelected = !!loads.length && !loads.find(it => !selectedRows[it[loadPrimaryKey]])

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: "Open Loads",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: null,
                duplicateLoadId: null,
                isRecurringLoad: isRecurringLoad,
                repeatDaysCount: repeatDaysCount
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    //#endregion
    //#region toggles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region table methods

    const refreshTable = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
            let searchParams = {
                page: 1,
                size: 20,
                sort: 'pickUpDateTime',
                order: 'asc',
                isDeleted: false,
                eta: true
            };

            if (entityType === "DRIVER") {
                searchParams.driverIds = entityId;

                let defaultLoadStatusFilter = ['BOOKED'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "SHIPPER") {
                searchParams.shipperId = entityId;

                let defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "CARRIER") {
                searchParams.assignedCarrierId = entityId;

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }

                let defaultLoadStatusFilter = ['BOOKED'];
                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            } else if (entityType === "STAFF") {
                let defaultLoadStatusFilter = ['CREATED', 'PENDING', 'APPROVED', 'PENDING_RATE_CONFIRMATION'];

                if (shipperId !== undefined && shipperId !== null) {
                    searchParams.shipperId = shipperId;
                }

                if (carrierId !== undefined && carrierId !== null) {
                    searchParams.assignedCarrierId = carrierId;
                    defaultLoadStatusFilter = ['BOOKED']; // if this is just for carrier loads then open loads should just show booked loads for the carrier
                }

                if (driverId !== undefined && driverId !== null) {
                    searchParams.driverIds = driverId;
                }

                if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                    searchParams.assignedAccountRepIds = assignedAccountRepId;
                }

                searchParams.loadStatus = defaultLoadStatusFilter.join('|');
            }

            dispatch(actionCreators.fetchLoadList(filter, searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadListSuccess({ listName: filter, params: { pagination: pager } }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            isDeleted: false,
            eta: true
        };

        if (filters.loadStatus !== undefined) {
            if (filters.loadStatus.length > 0) {
                searchParams.loadStatus = filters.loadStatus.join('|');
            }
        }

        if (entityType === "DRIVER") {
            searchParams.driverIds = entityId;
        } else if (entityType === "SHIPPER") {
            searchParams.shipperId = entityId;
        } else if (entityType === "CARRIER") {
            searchParams.assignedCarrierId = entityId;

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }
        } else if (entityType === "STAFF") {
            if (shipperId !== undefined && shipperId !== null) {
                searchParams.shipperId = shipperId;
            }

            if (carrierId !== undefined && carrierId !== null) {
                searchParams.assignedCarrierId = carrierId;
            }

            if (driverId !== undefined && driverId !== null) {
                searchParams.driverIds = driverId;
            }

            if (assignedAccountRepId !== undefined && assignedAccountRepId !== null) {
                searchParams.assignedAccountRepIds = assignedAccountRepId;
            }
        }

        dispatch(actionCreators.fetchLoadList(filter, searchParams));
    };

    const handleToggleBulkSelect = (item) => {
        let selectedRowsTmp = Object.assign({}, selectedRows)
        if (selectedRowsTmp[item[loadPrimaryKey]]) {
            delete selectedRowsTmp[item[loadPrimaryKey]]
        } else {
            Object.assign(selectedRowsTmp, {[item[loadPrimaryKey]]: item})
        }
        setSelectedRows(selectedRowsTmp)
    }

    const handleToggleBulkSelectAll = () => {
        if (!areAllSelected) {
            let selectedRowsTmp = loads.reduce((memo, it) => {
                memo[it[loadPrimaryKey]] = it
                return memo
            }, {})
            setSelectedRows(Object.assign({}, selectedRows, selectedRowsTmp))
        } else {
            let selectedRowsTmp = loads.reduce((memo, it) => {
                delete memo[it[loadPrimaryKey]]
                return memo
            }, selectedRows)
            setSelectedRows(Object.assign({}, selectedRowsTmp))
        }
    }

    const handleCloseBulkAssignShipperAdmin = () => {
        setShowBulkAssignAdminShipper(false)
        methods.reset()
    }

    const handleCloseBulkAssignCarrier = () => {
        setShowBulkAssignCarrier(false)
        methods.reset()
    }

    const onSubmit = (data) => {
        dispatch(actionCreators.assignBulkLoadsToCarrier({
            load_ids: Object.keys(selectedRows),
            assignedCarrierId: data.assignedCarrierId
        }));

        handleCloseBulkAssignShipperAdmin()
        handleCloseBulkAssignCarrier()
    }

    //#endregion
    //#region table displays

    const menu = (record) => {
        return (
            <Menu>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }
                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); setSelectedRecord(record); goToLoad(record.id); }}>
                    <InfoOutlined />
                    <span>View {singularEntityName}</span>
                </Menu.Item>
            </Menu>
        );
    };

    const bulkActionsMenu = () => {
        return (
            <Menu>
                {(entityType === 'STAFF' || entityType === 'SHIPPER') && (
                    <Menu.Item key="staff" onClick={() => setShowBulkAssignAdminShipper(true)}>
                        <InfoOutlined />
                        <span>Assign Carrier</span>
                    </Menu.Item>
                )}
                {entityType === 'CARRIER' && (
                    <Menu.Item key="carrier" onClick={() => setShowBulkAssignCarrier(true)}>
                        <InfoOutlined />
                        <span>Assign Driver</span>
                    </Menu.Item>
                )}
            </Menu>
        )
    }

    const columns = [
        {
            title:
            Object.keys(selectedRows).length ?
                <Dropdown overlay={bulkActionsMenu} trigger={['click']}>
                    <Button>Actions <DownOutlined /></Button>
                </Dropdown>
                :
                <FontAwesomeIcon icon={faTools} size="lg" />
            ,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: <input
                checked={!!areAllSelected}
                        type="checkbox"
                        onChange={handleToggleBulkSelectAll}
                    />,
            dataIndex: 'irisId',
            key: 'irisId',
            render: (text, record) => {
                return (
                    <input
                        checked={!!selectedRows[record[loadPrimaryKey]]}
                        type="checkbox"
                        key={record[loadPrimaryKey]}
                        onChange={() => {
                            handleToggleBulkSelect(record)
                        }}
                    />
                );
            },
            align: 'center',
        },
        {
            title: 'Load Id',
            dataIndex: 'irisId',
            key: 'irisId',
            sorter: (a, b) => compareByAlph(a.irisId, b.irisId),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Pick-Up Date',
            dataIndex: 'pickUpDateTime',
            key: 'pickUpDateTime',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return record.origin !== undefined && record.origin !== null ? stringFormatter.toFormattedString("MomentDate", text, null, record.origin.timeZone) : ''; },
        },
        {
            title: 'Origin',
            dataIndex: ['origin', 'stopLocation', 'name'],
            key: 'origin.stopLocation.name',
        },
        {
            title: 'Destination',
            dataIndex: ['destination', 'stopLocation', 'name'],
            key: 'destination.stopLocation.name',
        },
        {
            title: 'Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Length',
            dataIndex: ['equipmentNeeds', 'trailerLength'],
            key: 'equipmentNeeds.trailerLength',
            render: (text, record) => { return stringFormatter.toFormattedString("Length", text, record.equipmentNeeds.trailerLengthUnit); },
        },
        {
            title: 'Max Weight',
            dataIndex: ['equipmentNeeds', 'trailerWeightCapacity'],
            key: 'equipmentNeeds.trailerWeightCapacity',
            render: (text, record) => { return stringFormatter.toFormattedString("Weight", text, record.equipmentNeeds.trailerWeightCapacityUnit); },
        },
        {
            title: 'ETA',
            dataIndex: 'eta',
            key: 'eta',
            render: (text, record) => {
                if (record.loadStatus === "IN_TRANSIT" || record.loadStatus === "AT_STOP") {
                    return stringFormatter.toFormattedString("MomentDateTime", text, null, record.etaTimeZone);
                } else {
                    return 'N/A';
                }
            },
        }
    ];

    if ((entityType === "STAFF" && (carrierId === null || carrierId === undefined)) || entityType === "SHIPPER") {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            filters: [
                {
                    text: 'Created',
                    value: 'CREATED',
                },
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Pending Rate Confirmation',
                    value: 'PENDING_RATE_CONFIRMATION',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["CREATED", "PENDING", "APPROVED", "PENDING_RATE_CONFIRMATION"],
            onFilter: (value, record) => record.loadStatus.indexOf(value) === 0,
            sorter: (a, b) => compareByAlph(a.loadStatus, b.loadStatus),
            sortDirections: ['descend', 'ascend'],
            render: (text) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        });
    } else if (entityType === "STAFF" && carrierId !== null && carrierId !== undefined) {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            render: (text) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        });
    } else if (entityType === "CARRIER") {
        columns.push({
            title: 'Status',
            dataIndex: 'loadStatus',
            key: 'loadStatus',
            render: (text) => { return stringFormatter.toFormattedString((entityType === "STAFF" ? "LoadStatus" : (entityType === "CARRIER" ? "CarrierLoadStatus" : "ShipperLoadStatus")), text, null); },
        });
    }

    if ((shipperId !== undefined && shipperId !== null) || entityType === "SHIPPER") {
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
    } else if ((carrierId !== undefined && carrierId !== null) || entityType === "CARRIER") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
    } else if (entityType === "STAFF") {
        columns.push({
            title: 'Shipper',
            dataIndex: ['shipper', 'name'],
            key: 'shipper.name',
        });
        columns.push({
            title: 'Carrier',
            dataIndex: ['assignedCarrier', 'name'],
            key: 'assignedCarrier.name',
        });
        columns.push({
            title: 'Account Rep',
            dataIndex: 'assignedAccountRepIds',
            key: 'assignedAccountRepIds',
            render: (text, record) => {
                if (record.assignedAccountRepUsers !== undefined && record.assignedAccountRepUsers !== null && record.assignedAccountRepUsers.length > 0) {
                    return record.assignedAccountRepUsers.map((assignedAccountRepUser) => {
                        return (
                            <div>{stringFormatter.toFirstNameLastName(assignedAccountRepUser)}</div>
                        );
                    });
                } else {
                    return '';
                }
            },
        });
    }

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (entityType === 'STAFF' || entityType === 'SHIPPER') {
            setIsCarriersLoading(true);

            if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                const carrierAccounts = accounts.filter(account => account.isCarrier === true);
                if (carrierAccounts !== undefined && carrierAccounts !== null && carrierAccounts.length > 0) {
                    const carrierAccountsTransformed = carrierAccounts.map((item) => {
                        return {
                            id: item.id,
                            name: item.name
                        };
                    });
                    setCarriers(carrierAccountsTransformed);
                }
                else {
                    setCarriers([]);
                }
            }

            setIsCarriersLoading(false);
        } else if (entityType === 'CARRIER') {

        }
    }, [entityType, entityId, accounts]);

    useMemo(() => {
        if (loadLists !== undefined && loadLists !== null && !isEmpty(loadLists) && filter !== undefined && filter !== null) {
            if (loadLists[filter] !== undefined && loadLists[filter] !== null && !isEmpty(loadLists[filter])) {
                const copyOfLoadList = { ...loadLists[filter] };
                if (copyOfLoadList.records !== undefined && copyOfLoadList.records !== null) {
                    setLoads([...copyOfLoadList.records]);
                } else {
                    setLoads([]);
                }
                setPagination(copyOfLoadList.pagination ? copyOfLoadList.pagination : {});
                setIsLoading(copyOfLoadList.isLoading === true);
            } else {
                setLoads([]);
                setPagination({});
                setIsLoading(false);
            }
        } else {
            setLoads([]);
            setPagination({});
            setIsLoading(false);
        }
    }, [loadLists, filter]);

    useEffect(() => {
        refreshTable();
    }, [shipperId, carrierId, entityType, entityId]);


    useEffect(() => {
        dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, isDriver: true, isDeleted: false }));
    }, []);

    //#endregion
    //#region displays

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 100}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>

            <Checkbox
                onChange={e => setIsRecurringLoad(e.target.checked)}
                checked={isRecurringLoad}
                name={""}
            />
            <div>* Repeat this load(s) for days:</div>
            {!!isRecurringLoad && (
                <InputNumber
                    value={repeatDaysCount}
                    min={1}
                    step={1}
                    max={entityType === 'CARRIER' ? 100 : 100}
                    precision={0}
                    style={{ width: '100%' }}
                    onChange={(count) => {
                        setRepeatDaysCount(count)
                    }}
                />
            )}

        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 1}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const bulkAssignCarrier = (
        <Modal
            title={"Assign driver to selected loads"}
            visible={showBulkAssignCarrier}
            onOk={() => setShowBulkAssignCarrier(false)}
            onCancel={() => setShowBulkAssignCarrier(false)}
            maskClosable={false}
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Fieldset legend="ASSIGN A DRIVER TO THE LOADS">
                        <FormItem
                            {...formItemLayout}
                            required
                            format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Search and Select a Driver"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    loading={isCarriersLoading === true}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Driver Accounts for you to choose from." />}
                                >
                                    {drivers.map(c => <Option value={c.id} key={c.id}>{c.firstName} {c.lastName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="assignedDriverId"
                        />
                    </Fieldset>
                    <FormButtons cancel={handleCloseBulkAssignCarrier} submitText="Save Changes" />
                </Form>
            </FormProvider>
        </Modal>
    );

    const bulkAssignShipperAdmin = (
        <Modal
            title={"Assign carrier to selected loads"}
            visible={showBulkAssignAdminShipper}
            onOk={() => setShowBulkAssignAdminShipper(false)}
            onCancel={handleCloseBulkAssignShipperAdmin}
            maskClosable={false}
        >
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Fieldset legend="ASSIGN A CARRIER TO THE LOADS">
                        <FormItem
                            {...formItemLayout}
                            required
                            format="vertical"
                              render={({ onChange, onBlur, value, name }) => (
                                  <Select
                                      placeholder="Please Search and Select a Carrier"
                                      allowClear={true}
                                      style={{ width: '100%' }}
                                      virtual={false}
                                      loading={isCarriersLoading === true}
                                      onBlur={onBlur}
                                      onChange={(selected) => { onChange(selected); }}
                                      value={value}
                                      name={name}
                                      showSearch={true}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                  >
                                      {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                  </Select>
                          )}
                          rules={{ required: "Required Field" }}
                          name="assignedCarrierId"
                        />
                    </Fieldset>
                    <FormButtons cancel={handleCloseBulkAssignShipperAdmin} submitText="Save Changes" />
                </Form>
            </FormProvider>
        </Modal>
    );

    return (
        <DataTable
            dataSource={loads}
            columns={columns}
            pagination={pagination}
            loading={(isLoading === true) || (isRecordUpdateLoading === true)}
            onChange={handleTableChange}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {duplicateEntityComponents}
            {bulkAssignCarrier}
            {bulkAssignShipperAdmin}
        </DataTable>
    );
};

export default withRouter(OpenLoads);
