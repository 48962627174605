import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from "antd";
import DocumentList from '../DocumentList/DocumentList';
import { useSelector } from "react-redux";

const LoadAsset = props => {
    const loadId = props.loadId;
    const asset = props.asset;
    const showDocuments = props.showDocuments !== null && props.showDocuments !== undefined && props.showDocuments === true ? true : false;
    const documents = props.documents;

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    if (asset !== undefined && asset !== null) {
        return (
            <>
                <Row>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {asset.irisId && <DataRow title="Asset ID" value={asset.irisId} dataType="String" />}
                        {asset.assetType && <DataRow title="Asset Type" value={asset.assetType} dataType="AssetType" />}
                        {asset.status && <DataRow title="Status" value={asset.status} dataType="AssetStatus" />}
                        {asset.isContractorAsset && <DataRow title="Contractor Asset" value={asset.isContractorAsset} dataType="String" />}
                        {asset.isCarrierAsset && <DataRow title="Carrier Asset" value={asset.isCarrierAsset} dataType="String" />}
                        {asset.truckClass && <DataRow title="Truck Class" value={asset.truckClass} dataType="String" />}
                        {asset.dotNum && <DataRow title="Dot #" value={asset.dotNum} dataType="String" />}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {asset.licensePlateNum && <DataRow title="License #" value={asset.licensePlateNum} dataType="String" />}
                        {asset.licensePlateState && <DataRow title="License State" value={asset.licensePlateState} dataType="String" />}
                        {asset.make && <DataRow title="Make" value={asset.make} dataType="String" />}
                        {asset.model && <DataRow title="Model" value={asset.model} dataType="String" />}
                        {asset.year && <DataRow title="Year" value={asset.year} dataType="String" />}
                        {asset.color && <DataRow title="Color" value={asset.color} dataType="String" />}
                        {asset.vin && <DataRow title="VIN" value={asset.vin} dataType="String" />}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {asset.axles && <DataRow title="Axles" value={asset.axles} dataType="String" />}
                        {asset.rangeCombined && <DataRow title="Range" value={asset.rangeCombined} dataType="String" />}
                        {asset.trailerType !== undefined && asset.trailerType !== null && <DataRow title="Trailer Type" value={asset.trailerType.description} dataType="String" />}
                        <DataRow title="Is Available for Loads" value={asset.isAvailable} dataType="Boolean" />
                        {asset.overallLengthCombined && <DataRow title="Overall Length" value={asset.overallLengthCombined} dataType="String" />}
                        {asset.fuelTankCapacityCombined && <DataRow title="Fuel Tank Capacity" value={asset.fuelTankCapacityCombined} dataType="String" />}
                    </Col>
                    <Col xs={24} md={24} lg={4} xl={4}>
                        {asset.heightCombined && <DataRow title="Height" value={asset.heightCombined} dataType="String" />}
                        {asset.lengthCombined && <DataRow title="Length" value={asset.lengthCombined} dataType="String" />}
                        {asset.weightCombined && <DataRow title="Weight" value={asset.weightCombined} dataType="String" />}
                        {asset.widthCombined && <DataRow title="Width" value={asset.widthCombined} dataType="String" />}
                        {asset.loadCapacityCombined && <DataRow title="Load Capacity" value={asset.loadCapacityCombined} dataType="String" />}
                        {asset.maxLoadWeightCombined && <DataRow title="Max Load Weight" value={asset.maxLoadWeightCombined} dataType="String" />}
                        {asset.truckOwnerType && <DataRow title="Truck Owner Type" value={asset.truckOwnerType} dataType="TruckOwnerType" />}
                    </Col>
                </Row>
                {isAuthenticated === true && showDocuments === true && documents !== undefined && documents !== null ? (
                    <div style={{ marginTop: 24 }}>
                        <br />
                        <DocumentList tableTitle="Asset Documents" documentList={documents.filter(document => document.entityId === asset.id)} entityType="ASSET" entityId={asset.id} loadId={loadId} />
                    </div>
                ) : null}
            </>
        );
    } else {
        return null;
    }
};

export default LoadAsset;