import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { List, Avatar, Tooltip, Button, PageHeader, Card, Row, Col, Alert } from "antd";
import classes from './Notifications.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faTimesCircle, faBell, faPallet, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { pageHeader } from '../../constants/theme';
import MomentDate from '../../shared/dateFormatter';
import InfiniteScroll from 'react-infinite-scroller';

const Notifications = props => {
    const scrollParentRef = useRef(null);

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const userId = useSelector(state => state.auth.userId);
    const isLoading = useSelector(state => state.notifications.isLoading);
    const notifications = useSelector(state => state.notifications.records);
    const currentPage = useSelector(state => state.notifications.currentPage);
    const totalPages = useSelector(state => state.notifications.totalPages);
    // const totalCount = useSelector(state => state.notifications.totalCount);
    // const unreadCount = useSelector(state => state.notifications.unreadCount);
    const error = useSelector(state => state.notifications.addRecordError);

    const markRead = (id) => {
        dispatch(actionCreators.readNotification(id, userId));
    };

    // const markAllRead = () => {
    //     // need to implement in backend so it doesn't have to be done in a loop
    //     //console.log('mark all read');

    //     let unreadNotifications = notifications.filter(n => {
    //         return !n.read.some(r => { return r.readBy === userId; });
    //     });

    //     //console.log(unreadNotifications);
    //     let unreadNotificationIds = unreadNotifications.map(item => { return item.id; });

    //     dispatch(actionCreators.readMultipleNotifications(unreadNotificationIds, userId));
    // };

    const goToLoad = (loadId) => {
        props.history.push({ pathname: "/loads/" + loadId });
    };

    const getNotifications = () => {
        if (userId !== undefined && userId !== null) {
            dispatch(actionCreators.fetchMoreNotifications(entityType, entityId, userId));
        }
    };

    const handleInfiniteOnLoad = () => {
        //console.log(`Current Page: ${currentPage}, Total Pages: ${totalPages}`);
        if (currentPage + 1 <= totalPages && isLoading === false) {
            //console.log('get more');
            dispatch(actionCreators.fetchMoreNotifications(entityType, entityId, userId));
        } else {
            //console.log('no more items to get');
        }
    };

    // const extras = (
    //     <Button type="primary" onClick={(e) => { e.stopPropagation(); markAllRead(); }}>Mark All Read</Button>
    // );

    useEffect(() => {
        if (notifications.length === 0) {
            getNotifications();
        }
    }, [entityType, entityId, userId]);

    return (
        <>
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        title={"My Notifications"}
                        //extra={extras}
                        style={pageHeader}
                    />
                </Col>
            </Row>
            <Row gutter={[4, 4]}>
                <Col span={24}>
                    <Card size="small">
                        {error && <Alert message={`${error}`} type="error" />}
                        <div className={classes.infiniteScrollContainer} ref={(ref) => scrollParentRef.current = ref}>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                hasMore={isLoading === false && currentPage + 1 <= totalPages}
                                useWindow={false}
                                getScrollParent={() => scrollParentRef.current}
                            >
                                <List
                                    itemLayout="vertical"
                                    dataSource={notifications}
                                    loading={isLoading}
                                    style={{ maxWidth: 400, margin: '0 auto' }}
                                    renderItem={(item, i) => {

                                        const itemClass = item.read.some(r => { return r.readBy === userId; }) ? classes.read : '';
                                        let leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>;
                                        let notificationColor = '#ffffff';

                                        if (item.severity) {
                                            switch (item.severity) {
                                                case 'critical':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'red', color: '#ffffff75' }}><FontAwesomeIcon icon={faTimesCircle} size="lg" /></Avatar>
                                                    notificationColor = 'red';
                                                    break;
                                                case 'high':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'orange', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationTriangle} size="lg" /></Avatar>
                                                    notificationColor = 'orange';
                                                    break;
                                                case 'medium':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'yellow', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationCircle} size="lg" /></Avatar>
                                                    notificationColor = 'yellow';
                                                    break;
                                                case 'low':
                                                    leftIcon = <Avatar style={{ backgroundColor: 'blue', color: '#ffffff75' }}><FontAwesomeIcon icon={faInfoCircle} size="lg" /></Avatar>
                                                    notificationColor = 'blue';
                                                    break;
                                                default:
                                                    leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>
                                                    notificationColor = 'gray';
                                            }
                                        }

                                        let actionButtons = [];
                                        if (!item.read.some(r => { return r.readBy === userId; })) {
                                            actionButtons.push(<Tooltip placement="top" title={'Mark Read'}><Button key="1" type="link" onClick={(e) => { e.stopPropagation(); markRead(item.id); }} style={null}><FontAwesomeIcon icon={faEnvelopeOpen} size="sm" /> <span>&nbsp;&nbsp;Mark Read</span></Button></Tooltip>);
                                        }
                                        actionButtons.push(<Tooltip placement="top" title={'Go To Load'}><Button key="2" type="link" onClick={(e) => { e.stopPropagation(); goToLoad(item.loadId); }} style={null}><FontAwesomeIcon icon={faPallet} size="sm" /> <span>&nbsp;&nbsp;Go To Load</span></Button></Tooltip>);

                                        return (
                                            <List.Item
                                                className={classes.item + ' ' + itemClass}
                                                key={item.id}
                                                actions={actionButtons}>
                                                <List.Item.Meta
                                                    avatar={leftIcon}
                                                    className={classes.meta}
                                                    title={
                                                        <div className={classes.title}>{item.subject}</div>
                                                    }
                                                    description={
                                                        <div className={classes.description}>
                                                            <div className={classes.description}>{item.message}</div>
                                                            <div className={classes.dateTime}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(item.createdAt)}</div>
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        );
                                    }}
                                />
                            </InfiniteScroll>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default withRouter(Notifications);