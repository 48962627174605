import React, { useState } from 'react';
import { Collapse, Col, Row, Drawer, Empty } from 'antd';
import classes from './LoadAssets.module.scss';
import EditAsset from "../../components/EditAsset/EditAsset";
import AssignLoadAsset from "../../components/AssignLoadAsset/AssignLoadAsset";
import ChangeLoadAsset from "../../components/ChangeLoadAsset/ChangeLoadAsset";
import RemoveLoadAsset from "../../components/RemoveLoadAsset/RemoveLoadAsset";
import LoadAsset from '../../components/LoadAsset/LoadAsset';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();

const LoadAssets = props => {
    const fullWidth = global.window.innerWidth;
    const load = props.load;
    const loadId = props.load.id;
    const carrierId = props.load.assignedCarrierId;
    const assets = props.load.assets !== undefined && props.load.assets !== null ? props.load.assets : [];
    const entityType = props.entityType;
    const entityId = props.entityId;
    const documents = props.documents;
    const currentStatus = props.currentStatus;

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showChangeEntity, setShowChangeEntity] = useState(false);
    const [showRemoveEntity, setShowRemoveEntity] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});

    const singularEntityName = "Asset";

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleChangeEntity = () => {
        setShowChangeEntity(!showChangeEntity);
    };

    const toggleRemoveEntity = () => {
        setShowRemoveEntity(!showRemoveEntity);
    };

    const newEntityComponents = (
        <Drawer
            title={"Assign " + singularEntityName + " to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadAsset cancel={toggleNewEntity} load={load} />
        </Drawer>
    );

    const changeEntityComponents = (
        <Drawer
            title={"Change " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleChangeEntity}
            visible={showChangeEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <ChangeLoadAsset cancel={toggleChangeEntity} record={selectedRecord} load={load} asset={selectedRecord}/>
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAsset cancel={toggleEditEntity} record={selectedRecord} asset={selectedRecord}/>
        </Drawer>
    );

    const removeEntityComponents = (
        <Drawer
            title={"Remove " + singularEntityName + " from Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleRemoveEntity}
            visible={showRemoveEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <RemoveLoadAsset cancel={toggleRemoveEntity} record={selectedRecord} load={load} asset={selectedRecord}/>
        </Drawer>
    );

    const actionButtons = (record) => {
        return (
            <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                showAdd={false} addText='' addAction={null}
                showEdit={entityType === 'STAFF'}
                editText={"Edit " + singularEntityName + " Details"}
                editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                showChange={false} changeText={"Change " + singularEntityName} changeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleChangeEntity(); }}
                showRemove={entityType === 'STAFF' && currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CANCELLED' && currentStatus !== 'CLOSED'}
                removeText={"Remove " + singularEntityName + " from Load"}
                removeAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleRemoveEntity(); }}
            />
        );
    };

    const assetHeader = (asset) => {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col xs={{ span: 12 }}>
                    <b>{stringFormatter.toFormattedString("AssetType", asset.assetType, null)}{asset.trailerType !== undefined && asset.trailerType !== null ? ' (' + asset.trailerType.description + ')' : ''} ({asset.licensePlateNum} {asset.licensePlateState})</b>
                </Col>
                {entityType === "STAFF" &&
                    <Col xs={{ span: 12 }} style={{ textAlign: 'right' }}>
                        {actionButtons(asset)}
                    </Col>
                }
            </Row>
        );
    };

    return (
        <>
            {assets !== undefined && assets !== null && assets.length > 0 ? (
                <Collapse defaultActiveKey={[]}>
                    {assets.map((asset, index) => (
                        <Panel header={assetHeader(asset)} key={'asset-panel-' + asset.id}>
                            <LoadAsset asset={asset} showDocuments={true} loadId={loadId} key={"asset-" + asset.id} documents={documents} />
                        </Panel>
                    ))}
                </Collapse>
            ) : (
                    <Empty style={{ backgroundColor: '#ffffff', margin: 0 }} image={<FontAwesomeIcon className="anticon" size="4x" icon={faTruck} />} description={'No Assets are Assigned to this Load'} />
                )}
            {(carrierId !== undefined && carrierId !== null && entityType === "STAFF" && currentStatus !== 'COMPLETED' && currentStatus !== 'REOPENED' && currentStatus !== 'CANCELLED' && currentStatus !== 'CLOSED') ? (
                <div className={classes.buttonContainer}>
                    <AddEditChangeRemoveButtons
                        showAdd={true} addText={"Assign " + singularEntityName + " to Load"} addAction={(e) => { e.stopPropagation(); toggleNewEntity(); }}
                        showEdit={false} editText='' editAction={null}
                        showChange={false} changeText='' changeAction={null}
                        showRemove={false} removeText='' removeAction={null}
                    />
                </div>
            ) : null}
            {editEntityComponents}
            {newEntityComponents}
            {changeEntityComponents}
            {removeEntityComponents}
        </>
    );
};

export default LoadAssets;