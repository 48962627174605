import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import Calendar from '../../components/Calendar/Calendar';
import Scheduler from '../../components/Scheduler/Scheduler';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import Agenda from '../../components/Agenda/Agenda';

const { TabPane } = Tabs;

const Shift = props => {
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.shifts.isRecordLoading);
    const error = useSelector(state => state.shifts.recordError);
    const shift = useSelector(state => state.shifts.record);

    const [tabKey, setTabKey] = useState('snapshot');

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchShift(id));
    }, [id]);


    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/shifts` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/shifts`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Shifts';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/shifts` },
        path: previousPath ? previousPath : `/shifts`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Shifts',
    },
    {
        path: `/shifts/${id ? id : null}`,
        breadcrumbName: `${shift && shift.name ? shift.name : ''}`,
    },
    ];

    if (shift && shift.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={shift.name ? shift.name : 'Loading...'}
                    icon={<FontAwesomeIcon className="anticon" icon={faCalendarCheck} />}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Row gutter={[16, 16]}>
                                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                    <ComplexDataLinkCard
                                        title="Shift Details"
                                        style={{ height: 300 }}
                                        headStyle={{ fontSize: 14 }}
                                        data={{
                                            "Start Time": { value: shift && shift.startTime ? shift.startTime : null, dataType: 'String' },
                                            "End Time": { value: shift && shift.endTime ? shift.endTime : null, dataType: 'String' },
                                            "Is End Time Next Day?": { value: shift && shift.isNextDay ? shift.isNextDay : false, dataType: 'Boolean' },
                                            "Time Zone": { value: shift && shift.timeZone ? shift.timeZone : null, dataType: 'String' },
                                            "Are Employees Available?": { value: shift && shift.isAvailable ? shift.isAvailable : null, dataType: 'Boolean' }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab="Calendar" key="calendar">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Calendar accountId={shift && shift.accountId ? shift.accountId : null} shiftId={shift && shift.id ? shift.id : null} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Scheduler" key="scheduler">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Scheduler accountId={shift && shift.accountId ? shift.accountId : null} shiftId={shift && shift.id ? shift.id : null} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                    <TabPane tab="Agenda" key="agenda">
                        <div style={{ padding: 24 }}>
                            <ComplexCard>
                                <Agenda accountId={shift && shift.accountId ? shift.accountId : null} shiftId={shift && shift.id ? shift.id : null} breadcrumbs={breadcrumbs} />
                            </ComplexCard>
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Shift);