import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, Drawer } from 'antd';
import classes from './LinkedShippers.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataTable from '../../components/DataTable/DataTable';
import NewLinkedAccount from '../../components/NewLinkedAccount/NewLinkedAccount';
import EditLinkedAccount from '../../components/EditLinkedAccount/EditLinkedAccount';

const LinkedShippers = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Shipper";
    const pluralEntityName = "Shippers";

    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isLoading = useSelector(state => state.linkedAccounts.isLoading);
    const records = useSelector(state => state.linkedAccounts.records);
    const pagination = useSelector(state => state.linkedAccounts.pagination);
    const account = useSelector(state => state.accounts.record);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const goToLinkedAccount = (id) => {
        props.history.push({ pathname: `/linkedAccounts/${id}` }, {
            previousPageTitle: pluralEntityName,
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const refreshTable = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
            let searchParams = {
                page: 1,
                size: 20,
                sort: 'createdAt',
                order: 'desc',
                isShipper: true,
                accountId: entityId,
                isDeleted: false
            };

            dispatch(actionCreators.fetchLinkedAccounts(searchParams));
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLinkedAccountsSuccess({ pagination: pager }));

        const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            sort: sorter.field,
            order: sortOrder,
            isShipper: true,
            accountId: entityId,
            isDeleted: false
        };

        dispatch(actionCreators.fetchLinkedAccounts(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="editLinkedAccount" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); goToLinkedAccount(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Customer Id',
            dataIndex: 'customerId',
            key: 'customerId'
        }
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLinkedAccount cancel={toggleNewEntity} isShipper={true} isCarrier={false} isReceiver={false} isProducer={false} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedAccount cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, [entityType, entityId]);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            loading={isLoading === true}
            onChange={handleTableChange}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(LinkedShippers);
