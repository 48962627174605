import React, { useEffect, useMemo, useState } from 'react';
import {Button, Row, Col, Radio, InputNumber, Select, Typography, Empty, Card} from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import Pricing from '../../shared/pricing';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enums from '../../shared/enums';
import classes from "../../views/Load/Load.module.scss";
import LoadBids from "../LoadBids/LoadBids";

const { Option } = Select;
const stringFormatter = new StringFormatter();

const UpdateLoadPricing = props => {
    //#region props and constants

    const load = props.load;
    const stops = props.stops;
    const invoiceLineItems = props.invoiceLineItems;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const services = useSelector(state => state.orchestrator.services);
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    //#endregion
    //#region useStates

    const [tenderOption, setTenderOption] = useState(null);
    const [showPricing, setShowPricing] = useState(false);
    const [showPerMile, setShowPerMile] = useState(false);
    const [showContractRate, setShowContractRate] = useState(false);
    const [totalFeePerMile, setTotalFeePerMile] = useState(0);
    const [shipperRatePerMile, setShipperRatePerMile] = useState(0);
    const [carrierRatePerMile, setCarrierRatePerMile] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [totalAddOnCharge, setTotalAddOnCharge] = useState(0);
    const [totalAddOnCost, setTotalAddOnCost] = useState(0);
    const [shipperAmount, setShipperAmount] = useState(0);
    const [carrierAmount, setCarrierAmount] = useState(0);
    const [IRISProfit, setIRISProfit] = useState(0);

    const [baseRatePerMileAmount, setBaseRatePerMileAmount] = useState(0);
    const [fuelSurchargePerMileAmount, setFuelSurchargePerMileAmount] = useState(0);
    const [contractRateAmount, setContractRateAmount] = useState(0);
    const [contractRateCarrierAmount, setContractRateCarrierAmount] = useState(0);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);

    //#endregion
    //#region onSubmit and cancel

    const generateTMSInvoiceLineItem = (accountEntityId, accountEntityType) => {
        let newInvoiceLineItem = null;
        // if the serviceType is TMS then add the service fees
        if (load !== undefined && load !== null && !isEmpty(load) && lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0 && services !== undefined && services !== null && services.length > 0 && accounts !== undefined && accounts !== null && accounts.length > 0 && load.practicalDistance !== undefined && load.practicalDistance !== null) {
            let lineItemType = lineItemTypes.find(j => j.name === 'TMS_SERVICE_FEE');
            let tmsService = services.find(j => j.serviceType === 'LOAD' && j.loadServiceType === 'TMS'); // get the default tms service
            let account = accounts.find(j => j.id === accountEntityId);
            if (lineItemType !== undefined && lineItemType !== null && tmsService !== undefined && tmsService !== null && account !== undefined && account !== null) {
                newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'TMS_SERVICE_FEE',
                    isIrisFee: true,
                    fromEntityType: accountEntityType,
                    fromEntityId: accountEntityId,
                    toEntityType: "STAFF",
                    status: "PENDING",
                    approvalStatus: "APPROVED",
                    isOpen: true,
                    loadId: load.id
                };

                if (tmsService.billingFrequency === 'PER_LOAD') {
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.baseAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.totalAmount = Number(tmsService.priceAmount);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else if (tmsService.billingFrequency === 'PER_MILE') {
                    let baseAmount = Number(tmsService.priceAmount);
                    let quantity = Number(load.practicalDistance);
                    let totalAmount = baseAmount * quantity;

                    newInvoiceLineItem.baseAmount = Number(baseAmount);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = quantity;
                    newInvoiceLineItem.quantityUnit = 'PER_MILE';
                    newInvoiceLineItem.totalAmount = totalAmount;
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                } else {
                    newInvoiceLineItem.baseAmount = Number(0);
                    newInvoiceLineItem.baseAmountUnit = tmsService.priceAmountUnit;
                    newInvoiceLineItem.quantity = Number(1);
                    newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                    newInvoiceLineItem.totalAmount = Number(0);
                    newInvoiceLineItem.totalAmountUnit = tmsService.priceAmountUnit;
                }

                // check to see if pricing for service should be over-riden
                let accountTMSService = null;
                if (account.accountServices !== undefined && account.accountServices !== null && account.accountServices.length > 0) {
                    accountTMSService = account.accountServices.find(j => j.serviceName === tmsService.name);
                    if (accountTMSService !== undefined && accountTMSService !== null) {
                        if (accountTMSService.isPriceOverride === true) {
                            if (accountTMSService.billingFrequency === 'PER_LOAD') {
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.baseAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.totalAmount = Number(accountTMSService.priceAmount);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else if (accountTMSService.billingFrequency === 'PER_MILE') {
                                let baseAmount = Number(accountTMSService.priceAmount);
                                let quantity = Number(load.practicalDistance);
                                let totalAmount = baseAmount * quantity;

                                newInvoiceLineItem.baseAmount = Number(baseAmount);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = quantity;
                                newInvoiceLineItem.quantityUnit = 'PER_MILE';
                                newInvoiceLineItem.totalAmount = totalAmount;
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            } else {
                                newInvoiceLineItem.baseAmount = Number(0);
                                newInvoiceLineItem.baseAmountUnit = accountTMSService.priceAmountUnit;
                                newInvoiceLineItem.quantity = Number(1);
                                newInvoiceLineItem.quantityUnit = 'PER_LOAD';
                                newInvoiceLineItem.totalAmount = Number(0);
                                newInvoiceLineItem.totalAmountUnit = accountTMSService.priceAmountUnit;
                            }
                        }
                    }
                }
            }
        }

        return newInvoiceLineItem;
    };

    const onSubmit = (data) => {
        console.log("onSubmit", data)
        let loadPayload = {};
        loadPayload.serviceType = 'BROKERAGE';

        let newInvoiceLineItems = [];
        let removeInvoiceLineItems = [];

        // find old iris fees and load fees if they exist and set them to be removed
        if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
            invoiceLineItems.forEach((existingInvoiceLineItem) => {
                if (Enums.IRISFees.doesNameExist(existingInvoiceLineItem.itemType) || Enums.LoadFees.doesNameExist(existingInvoiceLineItem.itemType)) {
                    // console.log('remove invoice line item');
                    // console.log();
                    if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                        removeInvoiceLineItems.push(existingInvoiceLineItem);
                    }
                }
            });
        }

        // add TMS service fees if service type is TMS which means this is being done by a shipper or carrier
        if (loadPayload.serviceType !== undefined && loadPayload.serviceType !== null && loadPayload.serviceType === 'TMS') {
            let tmsInvoiceLineItem = generateTMSInvoiceLineItem(entityId, entityType);
            if (tmsInvoiceLineItem !== undefined && tmsInvoiceLineItem !== null) {
                newInvoiceLineItems.push(tmsInvoiceLineItem);
            }
        }

        // Base Fee Per Mile
        if (data.baseRatePerMileAmount !== undefined && data.baseRatePerMileAmount !== null && data.baseRatePerMileAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'BASE_FEE_PER_MILE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.baseRatePerMileAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'BASE_FEE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    loadId: load.id
                };

                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                // find old fees if they exist and set them to be removed
                if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                    invoiceLineItems.forEach((existingInvoiceLineItem) => {
                        if (existingInvoiceLineItem.itemType === 'BASE_FEE_PER_MILE') {
                            // console.log('remove invoice line item');
                            // console.log();
                            if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                                removeInvoiceLineItems.push(existingInvoiceLineItem);
                            }
                        }
                    });
                }
            }
        }

        // Fuel Surcharge Per Mile
        if (data.fuelSurchargePerMileAmount !== undefined && data.fuelSurchargePerMileAmount !== null && data.fuelSurchargePerMileAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE_PER_MILE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.fuelSurchargePerMileAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    loadId: load.id
                };

                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                // find old fees if they exist and set them to be removed
                if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                    invoiceLineItems.forEach((existingInvoiceLineItem) => {
                        if (existingInvoiceLineItem.itemType === 'FUEL_SURCHARGE_PER_MILE') {
                            // console.log('remove invoice line item');
                            // console.log();
                            if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                                removeInvoiceLineItems.push(existingInvoiceLineItem);
                            }
                        }
                    });
                }
            }
        }

        // Fuel Surcharge Per Load
        if (data.fuelSurchargeAmount !== undefined && data.fuelSurchargeAmount !== null && data.fuelSurchargeAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.fuelSurchargeAmount);
                let quantity = Number(load.practicalDistance);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    loadId: load.id
                };

                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });
                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });

                // find old fees if they exist and set them to be removed
                if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                    invoiceLineItems.forEach((existingInvoiceLineItem) => {
                        if (existingInvoiceLineItem.itemType === 'FUEL_SURCHARGE') {
                            // console.log('remove invoice line item');
                            // console.log();
                            if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                                removeInvoiceLineItems.push(existingInvoiceLineItem);
                            }
                        }
                    });
                }
            }
        }

        // Contract Rate
        if (data.contractRateAmount !== undefined && data.contractRateAmount !== null && data.contractRateAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.contractRateAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    loadId: load.id
                };

                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', fromEntityId: load.shipperId, toEntityType: 'STAFF' });

                // find old fees if they exist and set them to be removed
                if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                    invoiceLineItems.forEach((existingInvoiceLineItem) => {
                        if (existingInvoiceLineItem.itemType === 'CONTRACT_RATE') {
                            // console.log('remove invoice line item');
                            // console.log();
                            if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                                removeInvoiceLineItems.push(existingInvoiceLineItem);
                            }
                        }
                    });
                }
            }
        }

        // Contract Carrier Rate
        if (data.contractRateCarrierAmount !== undefined && data.contractRateCarrierAmount !== null && data.contractRateCarrierAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.contractRateCarrierAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true,
                    loadId: load.id
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER', toEntityId: data.assignedCarrierId });
                }

                // find old fees if they exist and set them to be removed
                if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
                    invoiceLineItems.forEach((existingInvoiceLineItem) => {
                        if (existingInvoiceLineItem.itemType === 'CONTRACT_RATE') {
                            // console.log('remove invoice line item');
                            // console.log();
                            if (removeInvoiceLineItems.find(i => i.id === existingInvoiceLineItem.id) === undefined) {
                                removeInvoiceLineItems.push(existingInvoiceLineItem);
                            }
                        }
                    });
                }
            }
        }

        if (shipperRatePerMile !== undefined && shipperRatePerMile !== null) {
            loadPayload.shipperRatePerMile = shipperRatePerMile;
            loadPayload.shipperRatePerMileUnit = 'USD';
        }
        if (shipperAmount !== undefined && shipperAmount !== null) {
            loadPayload.shipperAmount = shipperAmount;
            loadPayload.shipperAmountUnit = 'USD';
            loadPayload.shipperBalance = shipperAmount;
            loadPayload.shipperBalanceUnit = 'USD';
        }
        if (carrierRatePerMile !== undefined && carrierRatePerMile !== null) {
            loadPayload.carrierRatePerMile = carrierRatePerMile;
            loadPayload.carrierRatePerMileUnit = 'USD';
        }
        if (carrierAmount !== undefined && carrierAmount !== null) {
            loadPayload.carrierAmount = carrierAmount;
            loadPayload.carrierAmountUnit = 'USD';
            loadPayload.carrierBalance = carrierAmount;
            loadPayload.carrierBalanceUnit = 'USD';
        }

        if (!isEmpty(loadPayload)) {
            if (removeInvoiceLineItems !== undefined && removeInvoiceLineItems !== null && removeInvoiceLineItems.length > 0) {
                dispatch(actionCreators.removeInvoiceLineItems(removeInvoiceLineItems.map(i => i.id), load.id));
            }
            if (newInvoiceLineItems !== undefined && newInvoiceLineItems !== null && newInvoiceLineItems.length > 0) {
                dispatch(actionCreators.addInvoiceLineItems(newInvoiceLineItems, load.id));
            }
            dispatch(actionCreators.updateLoad(load.id, loadPayload));
            props.onClose();
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    //#endregion
    //#region onChanges

    const onChangeRateOption = (value) => {
        if (value !== undefined && value !== null) {
            if (value === 'PER_MILE') {
                setShowPerMile(true);
                setShowContractRate(false);
                if (entityType === 'STAFF') {
                    methods.setValue('contractRateCarrierAmount', null);
                    setContractRateCarrierAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                }
            } else if (value === 'CONTRACT_RATE') {
                setShowPerMile(false);
                setShowContractRate(true);
                if (entityType === 'STAFF') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                }
            } else {
                setShowPerMile(false);
                setShowContractRate(false);
            }
        } else {
            setShowPerMile(false);
            setShowContractRate(false);
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        // console.log(invoiceLineItems);
        let shipperContractAmount = 0.0;
        let carrierContractAmount = 0.0;
        if (entityType === "STAFF") {

            if (baseRatePerMileAmount !== undefined && baseRatePerMileAmount !== null && baseRatePerMileAmount > 0) {
                if (load.practicalDistance > 0) {
                    carrierContractAmount = (Number(baseRatePerMileAmount) * Number(load.practicalDistance));
                    if (fuelSurchargePerMileAmount !== undefined && fuelSurchargePerMileAmount !== null && fuelSurchargePerMileAmount > 0) {
                        carrierContractAmount += (Number(fuelSurchargePerMileAmount) * Number(load.practicalDistance));
                    }
                }
            } else if (contractRateCarrierAmount !== undefined && contractRateCarrierAmount !== null && contractRateCarrierAmount > 0) {
                carrierContractAmount = contractRateCarrierAmount;
            }

            shipperContractAmount = contractRateAmount !== undefined && contractRateAmount !== null && contractRateAmount > 0 ? contractRateAmount : 0.0;
        } else if (entityType === "SHIPPER") {
            if (baseRatePerMileAmount !== undefined && baseRatePerMileAmount !== null && baseRatePerMileAmount > 0) {
                if (load.practicalDistance > 0) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(load.practicalDistance));
                    if (fuelSurchargePerMileAmount !== undefined && fuelSurchargePerMileAmount !== null && fuelSurchargePerMileAmount > 0) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(load.practicalDistance));
                    }
                }
            } else if (contractRateAmount !== undefined && contractRateAmount !== null && contractRateAmount > 0) {
                shipperContractAmount = contractRateAmount;
                if (fuelSurchargeAmount !== undefined && fuelSurchargeAmount !== null && fuelSurchargeAmount > 0) {
                    shipperContractAmount += Number(fuelSurchargeAmount);
                }
            }
            carrierContractAmount = 0;
        }
        let summary = Pricing.newLoadPricingSummary(
            0,
            0,
            shipperContractAmount,
            carrierContractAmount,
            load.practicalDistance,
            (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) ? invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)) : []
        );

        //console.log(summary);

        setTotalFeePerMile(summary.feePerMileAmount);
        setShipperRatePerMile(summary.shipperRatePerMileAmount);
        setCarrierRatePerMile(summary.carrierRatePerMileAmount);
        setCommissionPercentage(summary.IRISPercentage);
        setTotalAddOnCharge(summary.addOnChargeAmount);
        setTotalAddOnCost(summary.addOnCostAmount);
        setShipperAmount(summary.shipperAmount);
        setCarrierAmount(summary.carrierAmount);
        setIRISProfit(summary.IRISAmount);
    }, [invoiceLineItems, load.practicalDistance, baseRatePerMileAmount, fuelSurchargePerMileAmount, fuelSurchargeAmount, contractRateAmount, contractRateCarrierAmount]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
        onChangeRateOption("CONTRACT_RATE");
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Header
                    titleStyle={{ fontSize: 24 }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={"Load pricing options"}
                    title2={"Add invoice line items below to calculate all-in rate."}
                    icon={<FontAwesomeIcon className="anticon" icon={faMoneyBill} />}
                    iconStyle={{ background: 'transparent' }}
                />

                {entityType === "STAFF" ? (
                    <>
                        <>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <Fieldset legend="SUBTOTAL">
                                        {(invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) ? invoiceLineItems.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem) => {
                                            return (
                                                <DataRow title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                            );
                                        }) : null}
                                        <div><Typography.Title level={3}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</Typography.Title></div>
                                    </Fieldset>
                                </Col>
                                <Col span={12}>
                                    <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                        {load.practicalDistance !== undefined && load.practicalDistance !== null ? <DataRow title="Trip Miles" titleStyle={{ fontWeight: 'normal' }} value={load.practicalDistance} units="MI" dataType="Distance" /> : null}
                                        {stops !== undefined && stops !== null && stops.length > 0 ? <DataRow title="Total Stops" titleStyle={{ fontWeight: 'normal' }} value={stops.length} /> : null}
                                        {(load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0) ? (
                                            <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Equipment Needs and Services" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                        ) : null}
                                        {(load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0) ? (
                                            <DataListRow titleStyle={{ fontWeight: 'normal' }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                        ) : null}
                                    </Fieldset>
                                </Col>
                            </Row>
                            <Fieldset legend="RATE TO CHARGE SHIPPER">
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical"
                                              render={({ onChange, onBlur, value, name }) => (
                                                  <InputNumber
                                                      precision={2}
                                                      min={0}
                                                      placeholder="Enter the rate in USD $"
                                                      style={{ width: '100%' }}
                                                      onBlur={onBlur}
                                                      onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                      value={value}
                                                      name={name}
                                                  />
                                              )}
                                              rules={{ required: "Required Field" }}
                                              name="contractRateAmount"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div><Typography.Title level={2}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</Typography.Title></div>
                                        <div><Typography.Title level={4}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</Typography.Title></div>
                                    </Col>
                                </Row>
                            </Fieldset>
                            {/*
                            <Fieldset legend="CARRIER RATE OPTIONS">
                                <FormItem {...formItemLayout} format="vertical"
                                          render={({ onChange, onBlur, value, name }) => (
                                              <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>

                                                  <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                      <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                          <div><span style={{ fontSize: 14 }}>Calculate the cost to the carrier by the miles for this trip.</span></div>
                                                          <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                          render1={({ onChange, onBlur, value, name }) => (
                                                                              <InputNumber
                                                                                  precision={2}
                                                                                  min={0}
                                                                                  placeholder="Enter the rate per mile in USD $"
                                                                                  style={{ width: '100%' }}
                                                                                  onBlur={onBlur}
                                                                                  onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                                  value={value}
                                                                                  name={name}
                                                                                  disabled={showPerMile === false}
                                                                              />
                                                                          )}
                                                                          rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                                          name1="baseRatePerMileAmount"
                                                                          render2={({ onChange, onBlur, value, name }) => (
                                                                              <InputNumber
                                                                                  precision={2}
                                                                                  min={0}
                                                                                  placeholder="Enter the rate per mile in USD $"
                                                                                  style={{ width: '100%' }}
                                                                                  onBlur={onBlur}
                                                                                  onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                                  value={value}
                                                                                  name={name}
                                                                                  disabled={showPerMile === false}
                                                                              />
                                                                          )}
                                                                          rules2={{ required: false }}
                                                                          name2="fuelSurchargePerMileAmount"
                                                          />
                                                      </div>
                                                  </Radio>

                                                  <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                      <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                          <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                          <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCost)} plus`} required format="vertical"
                                                                    render={({ onChange, onBlur, value, name }) => (
                                                                        <InputNumber
                                                                            precision={2}
                                                                            min={0}
                                                                            placeholder="Enter the rate in USD $"
                                                                            style={{ width: '100%' }}
                                                                            onBlur={onBlur}
                                                                            onChange={e => { setContractRateCarrierAmount(e); onChange(e); }}
                                                                            value={value}
                                                                            name={name}
                                                                            disabled={showContractRate === false}
                                                                        />
                                                                    )}
                                                                    rules={{ required: showContractRate === true ? "Required Field" : false }}
                                                                    name="contractRateCarrierAmount"
                                                          />
                                                      </div>
                                                  </Radio>
                                              </Radio.Group>
                                          )}
                                          rules={{ required: 'Required Field' }}
                                          name="rateOption"
                                />
                            </Fieldset>
                            */}
                            <Fieldset legend="CARRIER RATE">
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical"
                                                  render={({ onChange, onBlur, value, name }) => (
                                                      <InputNumber
                                                          precision={2}
                                                          min={0}
                                                          placeholder="Enter the rate in USD $"
                                                          style={{ width: '100%' }}
                                                          onBlur={onBlur}
                                                          onChange={e => { setContractRateCarrierAmount(e); onChange(e); }}
                                                          value={value}
                                                          name={name}
                                                      />
                                                  )}
                                                  rules={{ required: "Required Field" }}
                                                  name="contractRateCarrierAmount"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div><Typography.Title level={2}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</Typography.Title></div>
                                        <div><Typography.Title level={4}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</Typography.Title></div>
                                    </Col>
                                </Row>
                            </Fieldset>


                        </>
                    </>
                ) : null}
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Save Changes" />
            </Form>
        </FormProvider>
    );
};

export default UpdateLoadPricing;
