import React, { useState, useEffect } from 'react';
import { Timeline, Spin } from 'antd';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import StringFormatter from '../../shared/stringFormatter';
import { useSelector } from 'react-redux';

const stringFormatter = new StringFormatter();

const LoadNotifications = props => {
    const loadId = props.loadId;

    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const userId = useSelector(state => state.auth.userId);

    const [loadEvents, setLoadEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getLoadEvents = () => {
        setLoadEvents([]);
        if (loadId !== undefined && loadId !== null) {
            setIsLoading(true);

            let searchParams = {
                sort: 'createdAt',
                order: 'desc',
                page: 1,
                size: 10000,
                loadId: loadId
            };

            if (entityType === "STAFF") {
                searchParams.toStaff = true;
            } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                searchParams.accountIds = entityId;
            } else if (entityType === "DRIVER") {
                searchParams.userIds = userId;
            }

            axiosAuthenticated.get('/loadNotifications', { params: { ...searchParams } }).then(res => {
                let msgs = res.data.data;

                //console.log(msgs);
                setLoadEvents([...msgs]);

                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            });
        }
    };

    const refreshNotifications = () => {
        if (loadId !== undefined && loadId !== null) {
            getLoadEvents();
        }
    };

    useEffect(() => {
        if (loadId !== undefined && loadId !== null) {
            getLoadEvents();
        }
    }, [loadId]);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
            <Timeline>
                {loadEvents.map((loadEvent, index) => (
                    <Timeline.Item key={`load-event-${index}`} color={loadEvent.severity === "CRITICAL" ? "red" : "green"}>
                        {stringFormatter.toFormattedString("MomentDateTime", loadEvent.createdAt, null)}<br />
                        <b>{loadEvent.subject}:</b> {loadEvent.message}
                    </Timeline.Item>
                ))}
            </Timeline>
        </Spin>
    );
};

export default LoadNotifications;