import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card } from "antd";

const ImageCard = props => {
    return (
        <Card bordered={true} style={{ height: '125px', textAlign: 'center' }} size="small">
            <Avatar style={{ width: '100px', height: '100px' }} shape="square" size={128} icon={<UserOutlined />} src={props.imageUrl} />
        </Card>
    );
};

export default ImageCard;