import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, Row, Col, Radio, InputNumber, Select, Empty } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import FormItem from '../FormItem/FormItem';
import { convertToObject } from '../../shared/objectUtils';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { useSelector } from 'react-redux';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataListRow from '../DataListRow/DataListRow';
import Pricing from '../../shared/pricing';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Enums from '../../shared/enums';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const NewLoadServices = props => {
    //#region props

    const servicesData = props.servicesData;
    const setServicesData = props.setServicesData;
    const completedSteps = props.completedSteps;
    const setCompletedSteps = props.setCompletedSteps;
    const previousStepAction = props.previousStepAction;
    const previousStepActionName = props.previousStepActionName;
    const nextStepAction = props.nextStepAction;
    const nextStepActionName = props.nextStepActionName;
    const equipmentData = props.equipmentData;
    const pickUpStops = props.pickUpStops;
    const dropOffStops = props.dropOffStops;
    const practicalRouteMiles = props.practicalRouteMiles;
    const setInvoiceLineItems = props.setInvoiceLineItems;
    const setCarrier = props.setCarrier;
    const dontRequireLoadSpecificInformation = props.dontRequireLoadSpecificInformation;
    const hideLoadSpecificInformation = props.hideLoadSpecificInformation;

    console.log("servicesData", servicesData);

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            baseRatePerMileAmount: null,
            fuelSurchargePerMileAmount: null,
            contractRateAmount: null,
            contractRateCarrierAmount: null,
            fuelSurchargeAmount: null,
            customerOrderNumber: '',
            parentName: ''
        }
    });

    //#endregion
    //#region useSelectors

    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [showCarrierOptions, setShowCarrierOptions] = useState(false);
    const [tenderOption, setTenderOption] = useState(null);
    const [showPricing, setShowPricing] = useState(false);
    const [carriers, setCarriers] = useState([]);
    const [isCarriersLoading, setIsCarriersLoading] = useState(false);
    const [showPerMile, setShowPerMile] = useState(false);
    const [showContractRate, setShowContractRate] = useState(false);
    const [invoiceLineItemList, setInvoiceLineItemList] = useState([]);
    const [totalFeePerMile, setTotalFeePerMile] = useState(0);
    const [shipperRatePerMile, setShipperRatePerMile] = useState(0);
    const [carrierRatePerMile, setCarrierRatePerMile] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [totalAddOnCharge, setTotalAddOnCharge] = useState(0);
    const [totalAddOnCost, setTotalAddOnCost] = useState(0);
    const [shipperAmount, setShipperAmount] = useState(0);
    const [carrierAmount, setCarrierAmount] = useState(0);
    const [IRISProfit, setIRISProfit] = useState(0);

    const [baseRatePerMileAmount, setBaseRatePerMileAmount] = useState(0);
    const [fuelSurchargePerMileAmount, setFuelSurchargePerMileAmount] = useState(0);
    const [contractRateAmount, setContractRateAmount] = useState(0);
    const [contractRateCarrierAmount, setContractRateCarrierAmount] = useState(0);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);

    //#endregion
    //#region validation

    const validateServices = (data) => {
        let hasErrors = false;
        if (entityType === 'CARRIER') {
            if (data.rateOption === 'PER_MILE') {
                if (data.baseRatePerMileAmount === undefined || data.baseRatePerMileAmount === null) {
                    methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
            } else if (data.rateOption = 'CONTRACT_RATE') {
                if (data.contractRateAmount === undefined || data.contractRateAmount === null) {
                    methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
            } else {
                methods.setError('rateOption', { type: 'required', message: 'Please choose a Trip Rate Option' });
                hasErrors = true;
            }
        } else if (entityType === 'STAFF') {
            if (data.tenderOption === 'ASSIGN_CARRIER') {
                if (data.assignedCarrierId === undefined || data.assignedCarrierId === null) {
                    methods.setError('assignedCarrierId', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                /*
                if ((data.customerOrderNumber === undefined || data.customerOrderNumber === null) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    methods.setError('customerOrderNumber', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                 */
                if (data.contractRateAmount === undefined || data.contractRateAmount === null) {
                    methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                if (data.rateOption === 'PER_MILE') {
                    if (data.baseRatePerMileAmount === undefined || data.baseRatePerMileAmount === null) {
                        methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else if (data.rateOption === 'CONTRACT_RATE') {
                    if (data.contractRateCarrierAmount === undefined || data.contractRateCarrierAmount === null) {
                        methods.setError('contractRateCarrierAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else {
                    methods.setError('rateOption', { type: 'required', message: 'Please choose a Carrier Rate Option' });
                    hasErrors = true;
                }
            } else if (data.tenderOption === 'DEFER') {
                // TODO: should customer order number still be required?
            } else {
                methods.setError('tenderOption', { type: 'required', message: 'Please choose a Tender Option' });
                hasErrors = true;
            }
        } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
            if (data.tenderOption === 'ASSIGN_CARRIER') {
                console.log("tenderOption", data.tenderOption);
                if (data.assignedCarrierId === undefined || data.assignedCarrierId === null) {
                    methods.setError('assignedCarrierId', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                /*
                if ((data.customerOrderNumber === undefined || data.customerOrderNumber === null) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    console.log("customerOrderNumber", data.customerOrderNumber);
                    methods.setError('customerOrderNumber', { type: 'required', message: 'Required Field' });
                    hasErrors = true;
                }
                */
                if (data.rateOption === 'PER_MILE') {
                    if (data.baseRatePerMileAmount === undefined || data.baseRatePerMileAmount === null) {
                        console.log("baseRatePerMileAmount", data.baseRatePerMileAmount);
                        methods.setError('baseRatePerMileAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else if (data.rateOption === 'CONTRACT_RATE') {
                    if (data.contractRateAmount === undefined || data.contractRateAmount === null) {
                        console.log("contractRateAmount", data.contractRateAmount);
                        methods.setError('contractRateAmount', { type: 'required', message: 'Required Field' });
                        hasErrors = true;
                    }
                } else {
                    console.log("rateOption", 'rateOption');
                    methods.setError('rateOption', { type: 'required', message: 'Please choose a Carrier Rate Option' });
                    hasErrors = true;
                }
            } else if (data.tenderOption === 'DEFER') {
                // TODO: should customer order number still be required?
            } else if (data.tenderOption === 'IRIS') {
                // TODO: should customer order number still be required?
            } else {
                methods.setError('tenderOption', { type: 'required', message: 'Please choose a Tender Option' });
                hasErrors = true;
            }
        }

        console.log("validateServices", hasErrors);
        return !hasErrors;
    };

    //#endregion
    //#region onSubmit and saveForm

    const saveForm = () => {
        const payload = methods.getValues();
        console.log("payload", payload);
        let data = convertToObject(payload);

        if (entityType === 'CARRIER') {
            data.serviceType = 'TMS';
        } else if (entityType === 'STAFF') {
            data.serviceType = 'BROKERAGE';
        } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
            data.serviceType = 'TMS';
        } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
            data.serviceType = 'BROKERAGE';
        }

        let newInvoiceLineItems = [...invoiceLineItemList];

        // Base Fee Per Mile
        if (data.baseRatePerMileAmount !== undefined && data.baseRatePerMileAmount !== null && data.baseRatePerMileAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'BASE_FEE_PER_MILE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.baseRatePerMileAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'BASE_FEE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Fuel Surcharge Per Mile
        if (data.fuelSurchargePerMileAmount !== undefined && data.fuelSurchargePerMileAmount !== null && data.fuelSurchargePerMileAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE_PER_MILE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.fuelSurchargePerMileAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE_PER_MILE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_MILE',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Fuel Surcharge Per Load
        if (data.fuelSurchargeAmount !== undefined && data.fuelSurchargeAmount !== null && data.fuelSurchargeAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'FUEL_SURCHARGE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.fuelSurchargeAmount);
                let quantity = Number(practicalRouteMiles);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'FUEL_SURCHARGE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Contract Rate
        if (data.contractRateAmount !== undefined && data.contractRateAmount !== null && data.contractRateAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.contractRateAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                } else if (entityType === 'CARRIER') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && (tenderOption === 'ASSIGN_CARRIER' || tenderOption === 'DEFER')) {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                }
            }
        }

        // Contract Carrier Rate
        if (data.contractRateCarrierAmount !== undefined && data.contractRateCarrierAmount !== null && data.contractRateCarrierAmount > 0) {
            let lineItemType = lineItemTypes.find(j => j.name === 'CONTRACT_RATE');
            if (lineItemType !== null && lineItemType !== undefined) {
                let baseAmount = Number(data.contractRateCarrierAmount);
                let quantity = Number(1);
                let totalAmount = baseAmount * quantity;
                let newInvoiceLineItem = {
                    description: lineItemType.description,
                    itemType: 'CONTRACT_RATE',
                    isIrisFee: false,
                    baseAmount: baseAmount.toFixed(2),
                    baseAmountUnit: 'USD',
                    quantity: quantity,
                    quantityUnit: 'PER_LOAD',
                    totalAmount: totalAmount.toFixed(2),
                    totalAmountUnit: 'USD',
                    status: 'PENDING',
                    approvalStatus: 'PENDING',
                    isOpen: true
                };

                if (entityType === 'STAFF') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                    newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                }
            }
        }

        // Platform usage invoice line
        const platformUsageAmount = 1;
        let newInvoiceLineItem = {
            description: 'Platform usage fee',
            itemType: 'IRIS_FEE',
            isIrisFee: false,
            baseAmount: (platformUsageAmount).toFixed(2),
            baseAmountUnit: 'USD',
            quantity: 1,
            quantityUnit: 'PER_LOAD',
            totalAmount: (platformUsageAmount).toFixed(2),
            totalAmountUnit: 'USD',
            status: 'PENDING',
            approvalStatus: 'PENDING',
            isOpen: true
        };
        newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });

        //console.log(data);
        if (!isEmpty(data)) {
            if (data.assignedCarrierId !== undefined && data.assignedCarrierId !== null) {
                let carrierAccount = accounts.find(a => a.id === data.assignedCarrierId);
                if (carrierAccount !== undefined && carrierAccount !== null) {
                    setCarrier(carrierAccount);
                } else {
                    setCarrier(null);
                }
            }

            setInvoiceLineItems([...newInvoiceLineItems]);
            setServicesData({
                ...data,
                totalFeePerMile: totalFeePerMile,
                shipperRatePerMile: shipperRatePerMile,
                carrierRatePerMile: carrierRatePerMile,
                commissionPercentage: commissionPercentage,
                totalAddOnCharge: totalAddOnCharge,
                totalAddOnCost: totalAddOnCost,
                shipperAmount: shipperAmount,
                carrierAmount: carrierAmount,
                IRISProfit: IRISProfit
            });
        }
    };

    const onSubmit = (data) => {
        console.log("onSubmit")
        if (validateServices(data)) {
            console.log("saveForm")
            saveForm();
            setCompletedSteps({ ...completedSteps, services: true });
            nextStepAction('review');
        } else {
            console.log("return")
            return;
        }
    };

    //#endregion
    //#region invoiceLineItem methods

    const generateStopFeesInvoiceLineItems = () => {
        // create invoiceLineItems
        let newInvoiceLineItems = [];
        if (lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0) {
            if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) {
                pickUpStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (lineItemType !== null && lineItemType !== undefined) {
                            let baseAmount = 0;
                            if (stop.driverAssist !== undefined && stop.driverAssist !== null && stop.driverAssist > 0) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (lineItemType !== null && lineItemType !== undefined) {
                            let baseAmount = 0;
                            if (stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            if (dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
                dropOffStops.forEach((stop, stopIndex, arr) => {
                    if (stop.hasDriverAssist === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'DRIVER_ASSIST');
                        if (lineItemType !== null && lineItemType !== undefined) {
                            let baseAmount = 0;
                            if (stop.driverAssist !== undefined && stop.driverAssist !== null && stop.driverAssist > 0) {
                                baseAmount = parseFloat(stop.driverAssist);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'DRIVER_ASSIST',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true
                            };

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }

                    if (stop.hasLumperFee === true) {
                        let lineItemType = lineItemTypes.find(j => j.name === 'LUMPER_FEE');
                        if (lineItemType !== null && lineItemType !== undefined) {
                            let baseAmount = 0;
                            if (stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0) {
                                baseAmount = parseFloat(stop.lumperFee);
                            }
                            let quantity = 1;
                            let totalAmount = baseAmount * quantity;
                            let newInvoiceLineItem = {
                                description: lineItemType.description + ' for ' + stop.stopLocation.name,
                                stopId: stop.id,
                                itemType: 'LUMPER_FEE',
                                isIrisFee: false,
                                baseAmount: baseAmount.toFixed(2),
                                baseAmountUnit: 'USD',
                                quantity: quantity,
                                quantityUnit: 'PER_STOP',
                                totalAmount: totalAmount.toFixed(2),
                                totalAmountUnit: 'USD',
                                status: 'PENDING',
                                approvalStatus: 'PENDING',
                                isOpen: true
                            }

                            if (entityType === 'STAFF') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            } else if (entityType === 'CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'ASSIGN_CARRIER') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'CARRIER' });
                            } else if (entityType === 'SHIPPER' && tenderOption === 'IRIS') {
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'SHIPPER', toEntityType: 'STAFF' });
                                newInvoiceLineItems.push({ ...newInvoiceLineItem, fromEntityType: 'STAFF', toEntityType: 'CARRIER' });
                            }
                        }
                    }
                });
            }

            setInvoiceLineItemList([...newInvoiceLineItems]);
        }
    };

    //#endregion
    //#region onChanges

    const onChangeParentName = (value) => {
        setServicesData({ ...servicesData, parentName: value });
    };

    const onChangeAssignedCarrierId = (value) => {
        if (value !== undefined && value !== null) {
            let carrierAccount = accounts.find(a => a.id === value);
            console.log(carrierAccount);

            if ((entityType === 'SHIPPER') && carrierAccount !== undefined && carrierAccount !== null) {
                let carrierAccountLinked = carriers.find(a => a.id === value);

                if (!!carrierAccountLinked.data && !!carrierAccountLinked.data.carrierRatePerMile && carrierAccountLinked.data.carrierRatePerMile > 0) {
                    setShowPerMile(true);
                    setShowContractRate(false);

                    methods.setValue('rateOption', 'PER_MILE');
                    setServicesData({ ...servicesData, rateOption: 'PER_MILE' });

                    methods.setValue('baseRatePerMileAmount', carrierAccountLinked.data.carrierRatePerMile);
                    methods.setValue('fuelSurchargePerMileAmount', 0);
                    setBaseRatePerMileAmount(carrierAccountLinked.data.carrierRatePerMile);
                    setFuelSurchargePerMileAmount(0);
                } else if (!!carrierAccountLinked.data && !!carrierAccountLinked.data.carrierRate && carrierAccountLinked.data.carrierRate > 0) {
                    setShowPerMile(false);
                    setShowContractRate(true);

                    methods.setValue('rateOption', 'CONTRACT_RATE');
                    setServicesData({ ...servicesData, rateOption: 'CONTRACT_RATE' });

                    methods.setValue('contractRateAmount', carrierAccountLinked.data.carrierRate);
                    methods.setValue('fuelSurchargeAmount', 0);
                    setContractRateAmount(carrierAccountLinked.data.carrierRate);
                    setFuelSurchargeAmount(0);
                }
            }
            if (carrierAccount !== undefined && carrierAccount !== null) {
                setCarrier(carrierAccount);
                setServicesData({ ...servicesData, assignedCarrierId: value });
            } else {
                setCarrier(null);
                setServicesData({ ...servicesData, assignedCarrierId: value });
            }
        }
    };

    const onChangeRateOption = (value) => {
        // console.log(value);
        if (value !== undefined && value !== null) {
            if (value === 'PER_MILE') {
                setShowPerMile(true);
                setShowContractRate(false);
                if (entityType === 'STAFF') {
                    methods.setValue('contractRateCarrierAmount', null);
                    setContractRateCarrierAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                } else if (entityType === 'CARRIER') {
                    methods.setValue('contractRateAmount', null);
                    methods.setValue('fuelSurchargeAmount', null);
                    setContractRateAmount(0);
                    setFuelSurchargeAmount(0);
                }
            } else if (value === 'CONTRACT_RATE') {
                setShowPerMile(false);
                setShowContractRate(true);
                if (entityType === 'STAFF') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (entityType === 'SHIPPER') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                } else if (entityType === 'CARRIER') {
                    methods.setValue('baseRatePerMileAmount', null);
                    methods.setValue('fuelSurchargePerMileAmount', null);
                    setBaseRatePerMileAmount(0);
                    setFuelSurchargePerMileAmount(0);
                }
            } else {
                setShowPerMile(false);
                setShowContractRate(false);
            }

            setServicesData({ ...servicesData, rateOption: value });
        } else {
            setShowPerMile(false);
            setShowContractRate(false);

            setServicesData({ ...servicesData, rateOption: null });
        }
    };

    const onChangeTenderOption = (value) => {
        // console.log(value);
        if (value !== undefined && value !== null) {
            setTenderOption(value);
            if (value === 'ASSIGN_CARRIER') {
                setShowCarrierOptions(true);
                setShowPricing(true);
            } else if (value === 'IRIS') {
                setShowCarrierOptions(false);
                setShowPricing(false);
            } else if (value === 'DEFER') {
                setShowCarrierOptions(false);
                setShowPricing(true);
            } else {
                setShowCarrierOptions(false);
                setShowPricing(false);
            }

            let serviceType = 'TMS';
            if (entityType === 'STAFF') {
                serviceType = 'BROKERAGE';
            } else if (entityType === 'SHIPPER' && value === 'IRIS') {
                serviceType = 'BROKERAGE';
            }

            setServicesData({ ...servicesData, tenderOption: value, serviceType: serviceType });
        } else {
            setTenderOption(null);
            setShowCarrierOptions(false);
            setShowPricing(false);

            setServicesData({ ...servicesData, tenderOption: null, serviceType: null });
        }
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (entityType === 'STAFF') {
            setIsCarriersLoading(true);

            if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                const carrierAccounts = accounts.filter(account => account.isCarrier === true);
                if (carrierAccounts !== undefined && carrierAccounts !== null && carrierAccounts.length > 0) {
                    const carrierAccountsTransformed = carrierAccounts.map((item) => {
                        return {
                            id: item.id,
                            name: item.name
                        };
                    });
                    setCarriers(carrierAccountsTransformed);
                }
                else {
                    setCarriers([]);
                }
            }

            setIsCarriersLoading(false);
        } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
            setIsCarriersLoading(true);

            axiosAuthenticated.get('/linkedAccounts', { params: { page: 1, size: 10000, isCarrier: true, isDeleted: false, accountId: entityId } }).then((linkedAccountsRes) => {
                if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                    const linkedCarriers = linkedAccountsRes.data.data;
                    if (linkedCarriers !== undefined && linkedCarriers !== null && linkedCarriers.length > 0) {
                        const linkedCarriersTransformed = linkedCarriers.map((item) => {
                            return {
                                id: item.linkedAccountId,
                                name: item.name ? item.name : (item.linkedAccount ? item.linkedAccount.name : ''),
                                data: item
                            };
                        });
                        setCarriers(linkedCarriersTransformed);
                    } else {
                        setCarriers([]);
                    }
                }

                setIsCarriersLoading(false);
            }).catch(err => {
                console.log(err);

                setIsCarriersLoading(false);
            });
        }
    }, [entityType, entityId, accounts]);

    useMemo(() => {
        if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            if (servicesData.tenderOption) {
                methods.setValue('tenderOption', servicesData.tenderOption);
                if (servicesData.tenderOption === 'ASSIGN_CARRIER') {
                    setShowCarrierOptions(true);
                    setShowPricing(true);
                } else if (servicesData.tenderOption === 'IRIS') {
                    setShowCarrierOptions(false);
                    setShowPricing(false);
                } else if (servicesData.tenderOption === 'DEFER') {
                    setShowCarrierOptions(false);
                    setShowPricing(true);
                } else {
                    setShowCarrierOptions(false);
                    setShowPricing(false);
                }
            }
            if (servicesData.parentName) {
                methods.setValue('parentName', servicesData.parentName);
            }
            if (hideLoadSpecificInformation === false) {
                if (servicesData.customerOrderNumber) {
                    methods.setValue('customerOrderNumber', servicesData.customerOrderNumber);
                }
            }
        }
    }, [servicesData, entityType, hideLoadSpecificInformation]);

    useMemo(() => {
        if (carriers !== undefined && carriers !== null && carriers.length > 0 && servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            if (servicesData.assignedCarrierId) {
                methods.setValue('assignedCarrierId', servicesData.assignedCarrierId ? servicesData.assignedCarrierId : null);
            }
        }
    }, [servicesData, carriers, entityType]);

    useMemo(() => {
        if (servicesData !== undefined && servicesData !== null && !isEmpty(servicesData)) {
            if (entityType === "CARRIER") {
                if (servicesData.rateOption) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (servicesData.baseRatePerMileAmount) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (servicesData.fuelSurchargePerMileAmount) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (servicesData.contractRateAmount) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (servicesData.fuelSurchargeAmount) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (servicesData.contractRateCarrierAmount) {
                    methods.setValue('contractRateCarrierAmount', undefined);
                    setContractRateCarrierAmount(0);
                }
            } else if (entityType === "SHIPPER" && showPricing === true) {
                if (servicesData.rateOption) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (servicesData.baseRatePerMileAmount) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (servicesData.fuelSurchargePerMileAmount) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (servicesData.contractRateAmount) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (servicesData.fuelSurchargeAmount) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (servicesData.contractRateCarrierAmount) {
                    methods.setValue('contractRateCarrierAmount', undefined);
                    setContractRateCarrierAmount(0);
                }
            } else if (entityType === "STAFF" && showPricing === true) {
                if (servicesData.rateOption) {
                    methods.setValue('rateOption', servicesData.rateOption);
                    if (servicesData.rateOption === 'PER_MILE') {
                        setShowPerMile(true);
                        setShowContractRate(false);
                    } else if (servicesData.rateOption === 'CONTRACT_RATE') {
                        setShowPerMile(false);
                        setShowContractRate(true);
                    } else {
                        setShowPerMile(false);
                        setShowContractRate(false);
                    }
                }
                if (servicesData.baseRatePerMileAmount) {
                    methods.setValue('baseRatePerMileAmount', servicesData.baseRatePerMileAmount);
                    setBaseRatePerMileAmount(servicesData.baseRatePerMileAmount);
                }
                if (servicesData.fuelSurchargePerMileAmount) {
                    methods.setValue('fuelSurchargePerMileAmount', servicesData.fuelSurchargePerMileAmount);
                    setFuelSurchargePerMileAmount(servicesData.fuelSurchargePerMileAmount);
                }
                if (servicesData.contractRateAmount) {
                    methods.setValue('contractRateAmount', servicesData.contractRateAmount);
                    setContractRateAmount(servicesData.contractRateAmount);
                }
                if (servicesData.fuelSurchargeAmount) {
                    methods.setValue('fuelSurchargeAmount', servicesData.fuelSurchargeAmount);
                    setFuelSurchargeAmount(servicesData.fuelSurchargeAmount);
                }
                if (servicesData.contractRateCarrierAmount) {
                    methods.setValue('contractRateCarrierAmount', servicesData.contractRateCarrierAmount);
                    setContractRateCarrierAmount(servicesData.contractRateCarrierAmount);
                }
            }
        }
    }, [servicesData, entityType, showPricing]);

    useEffect(() => {
        if (lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0 &&
            pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 &&
            dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0 &&
            entityType !== undefined && entityType !== null) {
            if (entityType === 'STAFF' || entityType === 'CARRIER' || (entityType === 'SHIPPER' && tenderOption !== undefined && tenderOption !== null)) {
                generateStopFeesInvoiceLineItems();
            }
        }
    }, [lineItemTypes, pickUpStops, dropOffStops, entityType, tenderOption]);

    useEffect(() => {
        // console.log(invoiceLineItemList);
        let shipperContractAmount = 0.0;
        let carrierContractAmount = 0.0;
        if (entityType === "STAFF") {

            if (baseRatePerMileAmount !== undefined && baseRatePerMileAmount !== null && baseRatePerMileAmount > 0) {
                if (practicalRouteMiles > 0) {
                    carrierContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (fuelSurchargePerMileAmount !== undefined && fuelSurchargePerMileAmount !== null && fuelSurchargePerMileAmount > 0) {
                        carrierContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (contractRateCarrierAmount !== undefined && contractRateCarrierAmount !== null && contractRateCarrierAmount > 0) {
                carrierContractAmount = contractRateCarrierAmount;
            }

            shipperContractAmount = contractRateAmount !== undefined && contractRateAmount !== null && contractRateAmount > 0 ? contractRateAmount : 0.0;
        } else if (entityType === "CARRIER") {
            if (baseRatePerMileAmount !== undefined && baseRatePerMileAmount !== null && baseRatePerMileAmount > 0) {
                if (practicalRouteMiles > 0) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (fuelSurchargePerMileAmount !== undefined && fuelSurchargePerMileAmount !== null && fuelSurchargePerMileAmount > 0) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (contractRateAmount !== undefined && contractRateAmount !== null && contractRateAmount > 0) {
                shipperContractAmount = contractRateAmount;
                if (fuelSurchargeAmount !== undefined && fuelSurchargeAmount !== null && fuelSurchargeAmount > 0) {
                    shipperContractAmount += Number(fuelSurchargeAmount);
                }
            }
            carrierContractAmount = 0;
        } else if (entityType === "SHIPPER") {
            if (baseRatePerMileAmount !== undefined && baseRatePerMileAmount !== null && baseRatePerMileAmount > 0) {
                if (practicalRouteMiles > 0) {
                    shipperContractAmount = (Number(baseRatePerMileAmount) * Number(practicalRouteMiles));
                    if (fuelSurchargePerMileAmount !== undefined && fuelSurchargePerMileAmount !== null && fuelSurchargePerMileAmount > 0) {
                        shipperContractAmount += (Number(fuelSurchargePerMileAmount) * Number(practicalRouteMiles));
                    }
                }
            } else if (contractRateAmount !== undefined && contractRateAmount !== null && contractRateAmount > 0) {
                shipperContractAmount = contractRateAmount;
                if (fuelSurchargeAmount !== undefined && fuelSurchargeAmount !== null && fuelSurchargeAmount > 0) {
                    shipperContractAmount += Number(fuelSurchargeAmount);
                }
            }
            carrierContractAmount = 0;
        }

        console.log("baseRatePerMileAmount", baseRatePerMileAmount);
        console.log("fuelSurchargeAmount", fuelSurchargeAmount);
        console.log("shipperContractAmount", shipperContractAmount);
        console.log("shipperContractAmount", shipperContractAmount);
        console.log("carrierContractAmount", carrierContractAmount);
        console.log("practicalRouteMiles", practicalRouteMiles);
        console.log("invoiceLineItemList", invoiceLineItemList);

        let summary = Pricing.newLoadPricingSummary(
            baseRatePerMileAmount,
            fuelSurchargePerMileAmount,
            shipperContractAmount,
            carrierContractAmount,
            practicalRouteMiles,
            invoiceLineItemList
        );

        console.log("summary", summary);

        setTotalFeePerMile(summary.feePerMileAmount);
        setShipperRatePerMile(summary.shipperRatePerMileAmount);
        setCarrierRatePerMile(summary.carrierRatePerMileAmount);
        setCommissionPercentage(summary.IRISPercentage);
        setTotalAddOnCharge(summary.addOnChargeAmount);
        setTotalAddOnCost(summary.addOnCostAmount);
        setShipperAmount(summary.shipperAmount);
        setCarrierAmount(summary.carrierAmount);
        setIRISProfit(summary.IRISAmount);

        setServicesData({
            ...servicesData,
            totalFeePerMile: summary.feePerMileAmount,
            shipperRatePerMile: summary.shipperRatePerMileAmount,
            carrierRatePerMile: summary.carrierRatePerMileAmount,
            commissionPercentage: summary.IRISPercentage,
            totalAddOnCharge: summary.addOnChargeAmount,
            totalAddOnCost: summary.addOnCostAmount,
            shipperAmount: summary.shipperAmount,
            carrierAmount: summary.carrierAmount,
            IRISProfit: summary.IRISAmount
        });
    }, [invoiceLineItemList, practicalRouteMiles, baseRatePerMileAmount, fuelSurchargePerMileAmount, fuelSurchargeAmount, contractRateAmount, contractRateCarrierAmount]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };
    const radioStyle = {
        display: 'block',
        minHeight: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="load-creation-step-container">
                    <div className="load-creation-step">
                        <Header
                            titleStyle={{ fontSize: 24 }}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                            title={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Carrier & Rate Options" : "Invoice"}
                            title2={(entityType === 'STAFF' || entityType === 'SHIPPER') ? "Add invoice line items below to calculate all-in rate." : "Add fees below to calculate all-in rate."}
                            icon={<FontAwesomeIcon className="anticon" icon={faDollarSign} />}
                            iconStyle={{ background: 'transparent' }}
                        />

                        {entityType === 'SHIPPER' ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={false} format="vertical"
                                                      render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} />}
                                                      //rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                      name="parentName"
                                                      help={
                                                          <div>
                                                              <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Pulogix pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Pulogix pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                          </div>
                                                      }
                                            />
                                        </Col>
                                        {/*hideLoadSpecificInformation === false ? (
                                            <Col span={12}>
                                                <FormItem {...formItemLayout} label="Customer Order Number" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChangeCustomerOrderNumber(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder="Customer Order Number" />}
                                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                                    name="customerOrderNumber"
                                                />
                                            </Col>
                                        ) : null*/}
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="TENDER OPTIONS">
                                    {hideLoadSpecificInformation === true ? (
                                        <FormItem {...formItemLayout} format="vertical"
                                                  render={({ onChange, onBlur, value, name }) => (
                                                      <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                          <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                              <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                        render={({ onChange, onBlur, value, name }) => (
                                                                            <Select
                                                                                placeholder="Please Search and Select a Carrier"
                                                                                allowClear={true}
                                                                                style={{ width: '100%' }}
                                                                                virtual={false}
                                                                                loading={isCarriersLoading === true}
                                                                                disabled={showCarrierOptions === false}
                                                                                onBlur={onBlur}
                                                                                onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                                value={value}
                                                                                name={name}
                                                                                showSearch={true}
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                notFoundContent={
                                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from.">
                                                                                        <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedCarriers/` }); }}>Setup Preferred Carriers</Button>
                                                                                    </Empty>
                                                                                }
                                                                            >
                                                                                {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                            </Select>
                                                                        )}
                                                                        rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                        name="assignedCarrierId"
                                                              />
                                                          </Radio>

                                                          <Radio style={radioStyle} key="DEFER" value="DEFER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Tender this load later</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Tender this load later from the loads page.</span>
                                                          </Radio>
                                                      </Radio.Group>
                                                  )}
                                                  rules={{ required: 'Required Field' }}
                                                  name="tenderOption"
                                        />
                                    ) : (
                                        <FormItem {...formItemLayout} format="vertical"
                                                  render={({ onChange, onBlur, value, name }) => (
                                                      <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                          <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                              <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                        render={({ onChange, onBlur, value, name }) => (
                                                                            <Select
                                                                                placeholder="Please Search and Select a Carrier"
                                                                                allowClear={true}
                                                                                style={{ width: '100%' }}
                                                                                virtual={false}
                                                                                loading={isCarriersLoading === true}
                                                                                disabled={showCarrierOptions === false}
                                                                                onBlur={onBlur}
                                                                                onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                                value={value}
                                                                                name={name}
                                                                                showSearch={true}
                                                                                optionFilterProp="children"
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                                notFoundContent={
                                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from.">
                                                                                        <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedCarriers/` }); }}>Setup Preferred Carriers</Button>
                                                                                    </Empty>
                                                                                }
                                                                            >
                                                                                {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                            </Select>
                                                                        )}
                                                                        rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                        name="assignedCarrierId"
                                                              />
                                                          </Radio>
                                                          <Radio style={radioStyle} key="DEFER" value="DEFER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Tender this load later</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Tender this load later from the loads page.</span>
                                                          </Radio>
                                                      </Radio.Group>
                                                  )}
                                                  rules={{ required: 'Required Field' }}
                                                  name="tenderOption"
                                        />
                                    )}
                                </Fieldset>
                                {showPricing === true ? (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                                    {practicalRouteMiles !== undefined && practicalRouteMiles !== null ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                                    {pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0 ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                                    {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="STOP FEES">
                                                    {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                        return (
                                                            <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                        );
                                                    })}
                                                    <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        <Fieldset legend="CARRIER RATE OPTIONS">
                                            <FormItem {...formItemLayout} format="vertical"
                                                      render={({ onChange, onBlur, value, name }) => (
                                                          <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                              <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                                  <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                      <div><span style={{ fontSize: 14 }}>Calculate the charge to your customer by the miles for this trip.</span></div>
                                                                      <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                                      render1={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate per mile in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showPerMile === false}
                                                                                          />
                                                                                      )}
                                                                                      rules1={{ required: showPerMile === true ? "Required Field" : null }}
                                                                                      name1="baseRatePerMileAmount"
                                                                                      render2={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate per mile in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showPerMile === false}
                                                                                          />
                                                                                      )}
                                                                                      rules2={{ required: false }}
                                                                                      name2="fuelSurchargePerMileAmount"
                                                                      />
                                                                  </div>
                                                              </Radio>
                                                              <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                                  <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                      <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                                      <FormItemDouble {...formItemLayoutDouble} label1={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                                      render1={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showContractRate === false}
                                                                                          />
                                                                                      )}
                                                                                      rules1={{ required: showContractRate === true ? "Required Field" : false }}
                                                                                      name1="contractRateAmount"
                                                                                      render2={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setFuelSurchargeAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showContractRate === false}
                                                                                          />
                                                                                      )}
                                                                                      rules2={{ required: false }}
                                                                                      name2="fuelSurchargeAmount"
                                                                      />
                                                                  </div>
                                                              </Radio>
                                                          </Radio.Group>
                                                      )}
                                                      rules={{ required: 'Required Field' }}
                                                      name="rateOption"
                                            />
                                        </Fieldset>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</span></div>
                                                <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</span></div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {entityType === "STAFF" ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={false} format="vertical"
                                                      render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} />}
                                                      rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                      name="parentName"
                                                      help={
                                                          <div>
                                                              <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Pulogix pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Pulogix pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                          </div>
                                                      }
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                                <Fieldset legend="TENDER OPTIONS">
                                    {hideLoadSpecificInformation === true ? (
                                        <FormItem {...formItemLayout} format="vertical"
                                                  render={({ onChange, onBlur, value, name }) => (
                                                      <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                          <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                              <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                  <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                            render={({ onChange, onBlur, value, name }) => (
                                                                                <Select
                                                                                    placeholder="Please Search and Select a Carrier"
                                                                                    allowClear={true}
                                                                                    style={{ width: '100%' }}
                                                                                    virtual={false}
                                                                                    loading={isCarriersLoading === true}
                                                                                    disabled={showCarrierOptions === false}
                                                                                    onBlur={onBlur}
                                                                                    onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                                    value={value}
                                                                                    name={name}
                                                                                    showSearch={true}
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                                                                >
                                                                                    {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                                </Select>
                                                                            )}
                                                                            rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                            name="assignedCarrierId"
                                                                  />
                                                              </div>
                                                          </Radio>
                                                      </Radio.Group>
                                                  )}
                                                  rules={{ required: 'Required Field' }}
                                                  name="tenderOption"
                                        />
                                    ) : (
                                        <FormItem {...formItemLayout} format="vertical"
                                                  render={({ onChange, onBlur, value, name }) => (
                                                      <Radio.Group onBlur={onBlur} onChange={e => { onChangeTenderOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                          <Radio style={radioStyle} key="ASSIGN_CARRIER" value="ASSIGN_CARRIER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Offer to a Preferred Carrier</span><br />
                                                              <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                  <FormItem labelCol={0} wrapperCol={24} required format="vertical"
                                                                            render={({ onChange, onBlur, value, name }) => (
                                                                                <Select
                                                                                    placeholder="Please Search and Select a Carrier"
                                                                                    allowClear={true}
                                                                                    style={{ width: '100%' }}
                                                                                    virtual={false}
                                                                                    loading={isCarriersLoading === true}
                                                                                    disabled={showCarrierOptions === false}
                                                                                    onBlur={onBlur}
                                                                                    onChange={(selected) => { onChangeAssignedCarrierId(selected); onChange(selected); }}
                                                                                    value={value}
                                                                                    name={name}
                                                                                    showSearch={true}
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                                                                >
                                                                                    {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                                                                </Select>
                                                                            )}
                                                                            rules={{ required: showCarrierOptions === true ? "Required Field" : false }}
                                                                            name="assignedCarrierId"
                                                                  />
                                                              </div>
                                                          </Radio>
                                                          <Radio style={radioStyle} key="DEFER" value="DEFER">
                                                              <span style={{ fontWeight: 'bold', fontSize: 16 }}>Tender this load later</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Tender this load later from the loads page.</span>
                                                          </Radio>
                                                      </Radio.Group>
                                                  )}
                                                  rules={{ required: 'Required Field' }}
                                                  name="tenderOption"
                                        />
                                    )}
                                </Fieldset>
                                {showPricing === true ? (
                                    <>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                                    {practicalRouteMiles !== undefined && practicalRouteMiles !== null ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                                    {pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0 ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                                    {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                                                        <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        <Row gutter={[8, 8]}>
                                            <Col span={24}>
                                                <Fieldset legend="STOP FEES">
                                                    {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                        return (
                                                            <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                        );
                                                    })}
                                                    <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                                </Fieldset>
                                            </Col>
                                        </Row>
                                        <Fieldset legend="RATE TO CHARGE SHIPPER">
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} required format="vertical"
                                                              render={({ onChange, onBlur, value, name }) => (
                                                                  <InputNumber
                                                                      precision={2}
                                                                      min={0}
                                                                      placeholder="Enter the rate in USD $"
                                                                      style={{ width: '100%' }}
                                                                      onBlur={onBlur}
                                                                      onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                      value={value}
                                                                      name={name}
                                                                  />
                                                              )}
                                                              rules={{ required: "Required Field" }}
                                                              name="contractRateAmount"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(shipperAmount)}</span></div>
                                                    <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(shipperRatePerMile)}</span></div>
                                                </Col>
                                            </Row>
                                        </Fieldset>
                                        <Fieldset legend="CARRIER RATE OPTIONS">
                                            <FormItem {...formItemLayout} format="vertical"
                                                      render={({ onChange, onBlur, value, name }) => (
                                                          <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                              <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                                  <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                      <div><span style={{ fontSize: 14 }}>Calculate the cost to the carrier by the miles for this trip.</span></div>
                                                                      <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                                      render1={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate per mile in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showPerMile === false}
                                                                                          />
                                                                                      )}
                                                                                      rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                                                      name1="baseRatePerMileAmount"
                                                                                      render2={({ onChange, onBlur, value, name }) => (
                                                                                          <InputNumber
                                                                                              precision={2}
                                                                                              min={0}
                                                                                              placeholder="Enter the rate per mile in USD $"
                                                                                              style={{ width: '100%' }}
                                                                                              onBlur={onBlur}
                                                                                              onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                                              value={value}
                                                                                              name={name}
                                                                                              disabled={showPerMile === false}
                                                                                          />
                                                                                      )}
                                                                                      rules2={{ required: false }}
                                                                                      name2="fuelSurchargePerMileAmount"
                                                                      />
                                                                  </div>
                                                              </Radio>
                                                              <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                                  <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                                      <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                                      <FormItem {...formItemLayout} label={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCost)} plus`} required format="vertical"
                                                                                render={({ onChange, onBlur, value, name }) => (
                                                                                    <InputNumber
                                                                                        precision={2}
                                                                                        min={0}
                                                                                        placeholder="Enter the rate in USD $"
                                                                                        style={{ width: '100%' }}
                                                                                        onBlur={onBlur}
                                                                                        onChange={e => { setContractRateCarrierAmount(e); onChange(e); }}
                                                                                        value={value}
                                                                                        name={name}
                                                                                        disabled={showContractRate === false}
                                                                                    />
                                                                                )}
                                                                                rules={{ required: showContractRate === true ? "Required Field" : false }}
                                                                                name="contractRateCarrierAmount"
                                                                      />
                                                                  </div>
                                                              </Radio>
                                                          </Radio.Group>
                                                      )}
                                                      rules={{ required: 'Required Field' }}
                                                      name="rateOption"
                                            />
                                        </Fieldset>
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}>
                                                <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</span></div>
                                                <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</span></div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {entityType === "CARRIER" ? (
                            <>
                                <Fieldset legend="LOAD INFORMATION">
                                    <Row gutter={[8, 8]}>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} required={false} format="vertical"
                                                      render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChangeParentName(e.target.value); onChange(e.target.value); }} value={value} name={name} placeholder={hideLoadSpecificInformation === true ? "Bundle Name" : "Load Name"} />}
                                                      rules={{ required: hideLoadSpecificInformation === true ? 'Required Field' : false }}
                                                      name="parentName"
                                                      help={
                                                          <div>
                                                              <span style={{ color: '#A9A9A9' }}>{hideLoadSpecificInformation === true ? "This field provides you the option to add an alias or nickname to display for this bundle of identical loads instead of the Pulogix pre-assigned Load References. It should be unique but doesn't have to be." : "This field provides you the option to add an alias or nickname to display for this load instead of the Pulogix pre-assigned Load Reference. It should be unique but doesn't have to be."}</span>
                                                          </div>
                                                      }
                                            />
                                        </Col>
                                    </Row>
                                </Fieldset>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Fieldset legend="ROUTE & EQUIPMENT NEEDS">
                                            {practicalRouteMiles !== undefined && practicalRouteMiles !== null ? <DataRow title="Trip Miles" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={practicalRouteMiles} units="MI" dataType="Distance" /> : null}
                                            {pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0 ? <DataRow title="Total Stops" titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} value={pickUpStops.length + dropOffStops.length} /> : null}
                                            {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                                                <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Equipment Needs and Services" data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                            ) : null}
                                            {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                                                <DataListRow titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#A9A9A9', marginLeft: 8 }} title="Driver Requirements" data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                            ) : null}
                                        </Fieldset>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Fieldset legend="STOP FEES">
                                            {invoiceLineItemList.filter(i => i.fromEntityType === 'SHIPPER' && Enums.StopFees.doesNameExist(i.itemType)).map((lineItem, index) => {
                                                return (
                                                    <DataRow key={`subtotal-line-item-${index}`} titleStyle={{ fontSize: 14, color: '#000000' }} valueStyle={{ fontSize: 14, color: '#000000' }} title={lineItem.description} value={lineItem.totalAmount} dataType="Money" />
                                                );
                                            })}
                                            <div><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Subtotal: {stringFormatter.toFormattedMoney(totalAddOnCharge)}</span></div>
                                        </Fieldset>
                                    </Col>
                                </Row>
                                <Fieldset legend="TRIP RATE OPTIONS">
                                    <FormItem {...formItemLayout} format="vertical"
                                              render={({ onChange, onBlur, value, name }) => (
                                                  <Radio.Group onBlur={onBlur} onChange={e => { onChangeRateOption(e.target.value); onChange(e.target.value); }} value={value} name={name}>
                                                      <Radio style={radioStyle} key="PER_MILE" value="PER_MILE">
                                                          <span style={{ fontWeight: 'bold', fontSize: 16 }}>Rate Per Mile</span><br />
                                                          <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                              <div><span style={{ fontSize: 14 }}>Calculate the charge to your customer by the miles for this trip.</span></div>
                                                              <FormItemDouble {...formItemLayoutDouble} label1="Rate Per Mile" label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                              render1={({ onChange, onBlur, value, name }) => (
                                                                                  <InputNumber
                                                                                      precision={2}
                                                                                      min={0}
                                                                                      placeholder="Enter the rate per mile in USD $"
                                                                                      style={{ width: '100%' }}
                                                                                      onBlur={onBlur}
                                                                                      onChange={e => { setBaseRatePerMileAmount(e); onChange(e); }}
                                                                                      value={value}
                                                                                      name={name}
                                                                                      disabled={showPerMile === false}
                                                                                  />
                                                                              )}
                                                                              rules1={{ required: showPerMile === true ? "Required Field" : false }}
                                                                              name1="baseRatePerMileAmount"
                                                                              render2={({ onChange, onBlur, value, name }) => (
                                                                                  <InputNumber
                                                                                      precision={2}
                                                                                      min={0}
                                                                                      placeholder="Enter the rate per mile in USD $"
                                                                                      style={{ width: '100%' }}
                                                                                      onBlur={onBlur}
                                                                                      onChange={e => { setFuelSurchargePerMileAmount(e); onChange(e); }}
                                                                                      value={value}
                                                                                      name={name}
                                                                                      disabled={showPerMile === false}
                                                                                  />
                                                                              )}
                                                                              rules2={{ required: false }}
                                                                              name2="fuelSurchargePerMileAmount"
                                                              />
                                                          </div>
                                                      </Radio>
                                                      <Radio style={radioStyle} key="CONTRACT_RATE" value="CONTRACT_RATE">
                                                          <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contract Rate</span><br />
                                                          <div style={{ paddingLeft: 24, paddingTop: 12, paddingBottom: 12 }}>
                                                              <div><span style={{ fontSize: 14 }}>Enter a rate to charge, in addition to fees accounted for above in Subtotal.</span></div>
                                                              <FormItemDouble {...formItemLayoutDouble} label1={`Subtotal: ${stringFormatter.toFormattedMoney(totalAddOnCharge)} plus`} label2="Fuel Surcharge (optional)" required1 format="vertical"
                                                                              render1={({ onChange, onBlur, value, name }) => (
                                                                                  <InputNumber
                                                                                      precision={2}
                                                                                      min={0}
                                                                                      placeholder="Enter the rate in USD $"
                                                                                      style={{ width: '100%' }}
                                                                                      onBlur={onBlur}
                                                                                      onChange={e => { setContractRateAmount(e); onChange(e); }}
                                                                                      value={value}
                                                                                      name={name}
                                                                                      disabled={showContractRate === false}
                                                                                  />
                                                                              )}
                                                                              rules1={{ required: showContractRate === true ? "Required Field" : false }}
                                                                              name1="contractRateAmount"
                                                                              render2={({ onChange, onBlur, value, name }) => (
                                                                                  <InputNumber
                                                                                      precision={2}
                                                                                      min={0}
                                                                                      placeholder="Enter the rate in USD $"
                                                                                      style={{ width: '100%' }}
                                                                                      onBlur={onBlur}
                                                                                      onChange={e => { setFuelSurchargeAmount(e); onChange(e); }}
                                                                                      value={value}
                                                                                      name={name}
                                                                                      disabled={showContractRate === false}
                                                                                  />
                                                                              )}
                                                                              rules2={{ required: false }}
                                                                              name2="fuelSurchargeAmount"
                                                              />
                                                          </div>
                                                      </Radio>
                                                  </Radio.Group>
                                              )}
                                              rules={{ required: 'Required Field' }}
                                              name="rateOption"
                                    />
                                </Fieldset>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div style={{ marginBottom: 12 }}><span style={{ fontWeight: 'bold', fontSize: 16, color: '#000000' }}>Total: {stringFormatter.toFormattedMoney(carrierAmount)}</span></div>
                                        <div><span style={{ fontWeight: 'bold', fontSize: 14, color: '#000000' }}>Total Rate Per Mile: {stringFormatter.toFormattedMoney(carrierRatePerMile)}</span></div>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </div>
                    <Row gutter={[12, 12]} className="load-creation-step-buttons">
                        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                            {previousStepAction !== undefined && previousStepAction !== null ? (<Button type="default" block onClick={() => { previousStepAction('stops'); }}>{previousStepActionName}</Button>) : null}
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                            <Button type="primary" block htmlType="submit">{nextStepActionName}</Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </FormProvider>
    );
};

export default NewLoadServices;