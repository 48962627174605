import React, { useMemo, useState } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Empty, Button } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import Form from '../Form/Form';

const { Option } = Select;
const { TextArea } = Input;

const NewLinkedLocation = props => {
    //#region props

    const accountId = props.accountId;
    const linkedAccountId = props.linkedAccountId;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLocations.isRecordAddLoading);
    const error = useSelector(state => state.linkedLocations.addRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const states = useSelector(state => state.orchestrator.states);
    const linkedAccounts = useSelector(state => state.linkedAccounts.records);
    const isLoadingLinkedAccounts = useSelector(state => state.linkedAccounts.isLoading);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region useStates

    const [selectedLinkedAccountId, setSelectedLinkedAccountId] = useState(null);

    //#endregion
    //#region Enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const timeZoneOptions = Enums.USATimeZones.selectListOptions();

    //#endregion
    //#region onChanges

    const onChangeLinkedAccountId = (value) => {
        //console.log(`selected ${value}`);
        setSelectedLinkedAccountId(value);
    };

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = (data) => {
        if (selectedLinkedAccountId !== undefined && selectedLinkedAccountId !== null) {
            let payload = removeEmpty(data);

            payload.linkedAccountId = selectedLinkedAccountId;
            payload.accountId = accountId;

            // console.log(payload);
            if (!isEmpty(payload)) {
                dispatch(actionCreators.addLinkedLocation(payload));
            }
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddLinkedLocation());
        props.cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            dispatch(actionCreators.fetchLinkedAccounts({ page: 1, size: 100000, accountId: accountId, isDeleted: false }));
        }
    }, [accountId]);

    useMemo(() => {
        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            setSelectedLinkedAccountId(linkedAccountId);
        }
    }, [linkedAccountId]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLinkedLocationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || isLoadingLinkedAccounts === true}>
                    {(linkedAccountId === undefined || linkedAccountId === null) &&
                        <Fieldset legend="Choose a Shipper, Producer, or Receiver to Associate this Location With">
                            <FormItem {...formItemLayout} label="Shipper, Producer, or Receiver" required format="horizontal"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Shipper, Producer, or Receiver"
                                        allowClear={true}
                                        loading={isLoadingLinkedAccounts === true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChangeLinkedAccountId(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shipper, Producer, or Receiver Accounts for you to choose from. You cannot create a location without assigning it to a Shipper, Producer, or Receiver account. Please add Shipper, Producer, and Receiver accounts first.">
                                                {entityType === 'CARRIER' ? (
                                                    <>
                                                        <div style={{ marginBottom: 12 }}>
                                                            <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedShippers/` }); }}>Setup Shipper Accounts</Button>
                                                        </div>
                                                        <div style={{ marginBottom: 12 }}>
                                                            <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedProducers/` }); }}>Setup Producer Accounts</Button>
                                                        </div>
                                                        <div style={{ marginBottom: 12 }}>
                                                            <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedReceivers/` }); }}>Setup Receiver Accounts</Button>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </Empty>
                                        }
                                        options={linkedAccounts.map(a => { return { label: `${a.name ? a.name : (a.linkedAccount && a.linkedAccount.name ? a.linkedAccount.name : 'N/A')} (${a.isShipper === true ? 'Shipper' : (a.isProducer === true ? 'Producer' : (a.isReceiver === true ? 'Receiver' : 'N/A'))})`, value: a.linkedAccountId } })}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="linkedAccountId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Location Name, Address, and Time Zone">
                        <FormItem {...formItemLayout} label="Name" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules={{ required: "Required Field" }}
                            name="name"
                        />
                        <FormItem {...formItemLayout} label="Street Address 1" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                            rules={{ required: "Required Field" }}
                            name="address.streetAddress1"
                        />
                        <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                            rules={{ required: false }}
                            name="address.streetAddress2"
                        />
                        <FormItem {...formItemLayout} label="City" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                            rules={{ required: "Required Field" }}
                            name="address.city"
                        />
                        <FormItem {...formItemLayout} label="State" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="address.state"
                        />
                        <FormItem {...formItemLayout} label="Postal Code" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                            rules={{ required: "Required Field" }}
                            name="address.postalCode"
                        />
                        <FormItem {...formItemLayout} label="Country" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Country"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="USA" key="USA">United States</Option>
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="address.country"
                        />
                        <FormItem {...formItemLayout} label="Time Zone" format="horizontal" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select the Time Zone for this Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {timeZoneOptions}
                                </Select>
                            )}
                            rules={{ required: "Required Field" }}
                            name="timeZone"
                        />
                    </Fieldset>
                    <Fieldset legend="Location Fees (optional)" isCollapsed={true} isCollapsable={true}>
                        <FormItemDouble {...formItemLayoutDouble} label1="Lumper Fee" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    placeholder="Lumper Fee"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="lumperFee"
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {currencyOptions}
                                </Select>
                            )}
                            rules2={{ required: false }}
                            name2="lumperFeeUnit"
                        />
                    </Fieldset>
                    <Fieldset legend="Special Instructions (optional)" isCollapsed={true} isCollapsable={true}>
                        <FormItem {...formItemLayout} label="Special Instructions" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                            rules={{ required: false }}
                            name="specialInstructions"
                        />
                    </Fieldset>
                    <Fieldset legend="Point of Contact (optional)" isCollapsed={true} isCollapsable={true}>
                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules={{ required: false }}
                            name="pointOfContact.name"
                        />
                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="pointOfContact.email"
                            defaultValue={''}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules1={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1="pointOfContact.phone"
                            defaultValue1={''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                            rules2={{ required: false }}
                            name2="pointOfContact.phoneExt"
                        />
                    </Fieldset>
                    <Fieldset legend="Location Bids (optional)" isCollapsable={true} isCollapsed={true}>
                        <FormItem {...formItemLayout} label="Bids" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select the Bids"
                                    mode="multiple"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="commodityIds"
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Location" />
            </Form>
        </FormProvider>
    );
};

export default NewLinkedLocation;