import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Notes Functions

export const fetchNotesStart = () => {
    return {
        type: actionTypes.FETCH_NOTES_START
    }
};

export const fetchNotesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_NOTES_SUCCESS,
        payload: payload
    }
};

export const fetchNotesFail = (payload) => {
    return {
        type: actionTypes.FETCH_NOTES_FAIL,
        payload: payload
    }
};

export const clearNotes = () => {
    return {
        type: actionTypes.CLEAR_NOTES
    }
};

export const addNoteStart = () => {
    return {
        type: actionTypes.ADD_NOTE_START
    }
};

export const insertNote = (payload) => {
    return {
        type: actionTypes.ADD_NOTE,
        payload: payload
    }
};

export const addNoteSuccess = () => {
    return {
        type: actionTypes.ADD_NOTE_SUCCESS
    }
};

export const addNoteFail = (payload) => {
    return {
        type: actionTypes.ADD_NOTE_FAIL,
        payload: payload
    }
};

export const addNoteErrorClear = () => {
    return {
        type: actionTypes.ADD_NOTE_ERROR_CLEAR
    }
};

export const addNoteLoadingClear = () => {
    return {
        type: actionTypes.ADD_NOTE_LOADING_CLEAR
    }
};

export const updateNoteStart = () => {
    return {
        type: actionTypes.UPDATE_NOTE_START
    }
};

export const changeNote = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTE,
        payload: payload
    }
};

export const changeSingleNote = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_NOTE,
        payload: payload
    }
};

export const updateNoteSuccess = () => {
    return {
        type: actionTypes.UPDATE_NOTE_SUCCESS
    }
};

export const updateNoteFail = (payload) => {
    return {
        type: actionTypes.UPDATE_NOTE_FAIL,
        payload: payload
    }
};

export const updateNoteErrorClear = () => {
    return {
        type: actionTypes.UPDATE_NOTE_ERROR_CLEAR
    }
};

export const updateNoteLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_NOTE_LOADING_CLEAR
    }
};

export const cancelAddNote = () => {
    return {
        type: actionTypes.CANCEL_ADD_NOTE
    }
};

export const cancelUpdateNote = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_NOTE
    }
};

export const removeNote = (payload) => {
    return {
        type: actionTypes.REMOVE_NOTE,
        payload: payload
    }
};

//#endregion

//#region Notes Methods

export const fetchNotes = (payload) => {
    return async (dispatch, getState) => {
        const notesPath = '/notes';
        try {
            dispatch(fetchNotesStart());

            const state = getState();
            const notesState = {...state.notes};
            let pagination = {...notesState.pagination};
            let searchParams = {...notesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const notesRes = await axiosAuthenticated.get(notesPath, {params: {...searchParams}});
            if (notesRes && notesRes.status === 200) {
                const notes = notesRes.data.data;

                // Read total count from server
                pagination.total = notesRes.data.totalCount;
                pagination.current = notesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchNotesSuccess({
                    records: notes,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchNotesFail({error: error.message}));
        }
    }
};

export const fetchBulkNotes = (payload) => {
    return async (dispatch) => {
        try {
            dispatch(fetchNotesStart());

            const notesRes = await axiosAuthenticated.post('/notes/bulk/get', {page: 1, size: 10000, ...payload});
            if (notesRes && notesRes.status === 200) {
                const notes = notesRes.data.data;

                dispatch(fetchNotesSuccess({records: notes}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchNotesFail({error: error.message}));
        }
    }
};

export const addNote = (payload) => {
    return async (dispatch) => {
        const notesPath = '/notes';
        try {
            dispatch(addNoteStart());

            const noteRes = await axiosAuthenticated.post(notesPath, {...payload});
            if (noteRes && noteRes.status === 201) {
                let newNote = noteRes.data;

                dispatch(insertNote(newNote));

                dispatch(addNoteSuccess());
                dispatch(addNoteLoadingClear());
                dispatch(addNoteErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addNoteFail({error: error.message}));
        }
    }
};

export const updateNote = (noteId, payload) => {
    return async (dispatch) => {
        const notesPath = '/notes';
        try {
            dispatch(updateNoteStart());

            const notesRes = await axiosAuthenticated.put(notesPath + `/${noteId}`, {...payload});
            if (notesRes && notesRes.status === 200) {
                let updatedNote = notesRes.data;

                if (updatedNote.isDeleted === true) {
                    dispatch(removeNote(updatedNote));
                } else {
                    dispatch(changeNote(updatedNote));
                    dispatch(changeSingleNote(updatedNote));
                }

                dispatch(updateNoteSuccess());
                dispatch(updateNoteLoadingClear());
                dispatch(updateNoteErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateNoteFail({error: error.message}));
        }
    }
};

//#endregion