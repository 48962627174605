import axios from 'axios';
import uuid from 'react-uuid';
import jwt from 'jwt-decode';
import moment from 'moment';
import store from '../store/store';
import * as actionCreators from '../store/actions/index';
import { getFilesMetaData } from './fileClient';
import EntityUtils from '../store/entityUtils';
import logger from '../shared/logger';

const instance = axios.create({});

instance.defaults.baseURL = process.env.REACT_APP_IRIS_API_ENDPOINT;
// Interceptor for Request
instance.interceptors.request.use(async request => {
    //console.log("HTTP Request:");
    //console.log(request);
    // Add headers and configurations to request here
    // Check for Token in Local Storage to add for Authorization
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    if (token !== undefined && token !== null && refreshToken !== undefined && refreshToken !== null) {
        let tokenDecoded = jwt(token);
        //console.log(JSON.stringify(tokenDecoded));
        let endDate = moment.unix(tokenDecoded.exp);
        let refreshTokenDecoded = jwt(refreshToken);
        //console.log(JSON.stringify(refreshTokenDecoded));
        let refreshEndDate = moment.unix(refreshTokenDecoded.exp);

        // If Token is present, get the expiration date
        let expirationDate = endDate.toDate();
        //console.log(expirationDate);
        let refreshExpirationDate = refreshEndDate.toDate();
        //console.log(refreshExpirationDate);
        const now = Date.now()

        // If the expiration Date is less than current datetime and the refresh token is still valid, refresh the access token
        if (expirationDate < now && refreshExpirationDate > now) {
            let newToken = await refreshAccessToken(refreshToken);
            if (newToken !== undefined && newToken !== null) {
                // console.log('new access token:');
                // console.log(newToken);
                token = newToken;
            }
        }
    }

    //console.log('axios token: ' + token);
    if (token) {
        request.headers['Authorization'] = "Bearer " + token;
    }
    request.headers['X-Correlation-Id'] = uuid();
    request.headers['Accept'] = "application/json";
    request.headers['Content-Type'] = "application/json";

    return request;
}, error => {
    //console.log("HTTP Request Error:");
    //console.log(error);

    return Promise.reject(error);
});

// Interceptor for Response
instance.interceptors.response.use(response => {
    //console.log("HTTP Response:");
    //console.log(response);
    // Add headers and configurations to request here

    return response;
}, error => {
    console.log("HTTP Response Error:");
    console.log(error);

    return Promise.reject(error);
});

const refreshAccessToken = async (refreshToken) => {
    //console.log('refreshing access token');
    try {
        let newToken = null;
        const resp = await axios.post('/api-token-refresh/', { refresh: refreshToken });
        if (resp && resp.status === 200) {
            newToken = resp.data.access;
            const newRefreshToken = resp.data.refresh;
            if (newToken !== undefined && newToken !== null) {
                let newTokenDecoded = jwt(newToken);
                let newRefreshTokenDecoded = jwt(newRefreshToken);
                // console.log(JSON.stringify(newTokenDecoded));
                // console.log(JSON.stringify(newRefreshTokenDecoded));
                let userId = newTokenDecoded.user_id;
                let userProfileId = newTokenDecoded.user_profile_id;
                let firstName = newTokenDecoded.f_name;
                let lastName = newTokenDecoded.l_name;
                let isUserActive = newTokenDecoded.is_active;
                let isStaff = newTokenDecoded.is_staff;
                let isAdmin = newTokenDecoded.is_superuser;
                let isVP = newTokenDecoded.is_vp;
                let isDirector = newTokenDecoded.is_director;
                let isManager = newTokenDecoded.is_manager;
                let email = newTokenDecoded.email;
                let userName = newTokenDecoded.username;
                let endDate = moment.unix(newTokenDecoded.exp);

                localStorage.setItem('token', newToken);
                localStorage.setItem('refreshToken', newRefreshToken);
                localStorage.setItem('userId', userId.toString());
                localStorage.setItem('userProfileId', userProfileId.toString());
                localStorage.setItem('expirationDate', endDate.toDate().toString());
                localStorage.setItem('firstName', firstName.toString());
                localStorage.setItem('lastName', lastName.toString());

                let profileImageId = localStorage.getItem('profileImageId');
                if (profileImageId === null || profileImageId === undefined) {
                    const fileMetaData = await getFilesMetaData(userProfileId);
                    let profileImageIdValue = EntityUtils.getUserFileIds(fileMetaData).profileImageId;
                    profileImageId = profileImageIdValue;
                    if (profileImageId !== undefined && profileImageId !== null) {
                        localStorage.setItem('profileImageId', profileImageId);
                    }
                }

                // check to see if the user isActive and if they aren't, don't let them login
                if (isUserActive === false) {
                    console.log('user isnt active');
                    store.dispatch(actionCreators.completeLogout());
                } else {
                    // if the user is staff then no need to get account users and accounts, just authenticate them
                    if (isStaff === true) {
                        store.dispatch(actionCreators.authSuccess({
                            token: newToken,
                            email: email,
                            userName: userName,
                            userId: userId,
                            userProfileId: userProfileId,
                            firstName: firstName,
                            lastName: lastName,
                            profileImageId: profileImageId,
                            isAuthComplete: true,
                            isAuthenticated: true,
                            entityId: userId,
                            entityIrisId: 'IRIS-STAFF',
                            entityType: 'STAFF',
                            error: null,
                            isAdmin: isAdmin,
                            isStaff: isStaff,
                            isVP: isVP,
                            isDirector: isDirector,
                            isManager: isManager
                        }));
                    } else {
                        // Get Account User Data

                        let accountUsers = [];
                        let accounts = [];
                        const yourAccountUsers = localStorage.getItem('yourAccountUsers');
                        const yourAccounts = localStorage.getItem('yourAccounts');
                        //console.log(yourAccountUsers);
                        //console.log(yourAccounts);

                        // TODO: if claims do not match the ids in yourAccounts then set to true
                        let refreshAccounts = false;

                        if (yourAccountUsers !== undefined && yourAccountUsers !== null && yourAccounts !== undefined && yourAccounts !== null && refreshAccounts === false) {
                            accountUsers = JSON.parse(yourAccountUsers);
                            accounts = JSON.parse(yourAccounts);

                            // store.dispatch Account Users Data to Your Accounts Users Store
                            store.dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers }));

                            // store.dispatch Accounts Data to Your Accounts Store
                            store.dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts }));
                        } else {
                            const accountUserRes = await axios.get('/accountUsers', {
                                params: { userId: userId },
                                headers: { Authorization: "Bearer " + newToken }
                            });

                            if (accountUserRes && accountUserRes.status === 200) {

                                // Get Account User Data then transform
                                const accountUsersData = accountUserRes.data.data;
                                if (accountUsersData !== undefined && accountUsersData !== null && accountUsersData.length > 0) {

                                    let accountIds = accountUsersData.filter(item => item.accountId !== undefined && item.accountId !== null).map(item => { return item.accountId; });
                                    let accountUserIds = accountUsersData.map(item => { return item.id; });

                                    let driverDocuments = [];
                                    const driverDocumentsRes = await axios.post('/documents/bulk/get', { page: 1, size: 10000, entityId: [...accountUserIds] }, {
                                        headers: {
                                            Authorization: "Bearer " + newToken
                                        }
                                    });
                                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                                        driverDocuments = driverDocumentsRes.data.data;
                                    }

                                    const accountsRes = await axios.post('/accounts/bulk/get', { page: 1, size: 10000, id: [...accountIds] }, {
                                        headers: {
                                            Authorization: "Bearer " + newToken
                                        }
                                    });
                                    if (accountsRes && accountsRes.status === 200) {
                                        accounts = accountsRes.data.data;
                                    }

                                    accountUsers = accountUsersData.map(item => {
                                        if (item.isDriver === true) {
                                            item = EntityUtils.getDriverInfo(item.id, item, driverDocuments);
                                        }

                                        let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                                        return {
                                            ...item,
                                            account: account
                                        };
                                    });

                                    // store.dispatch Account Users Data to Your Accounts Users Store
                                    if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
                                        localStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                                    }
                                    store.dispatch(actionCreators.fetchYourAccountUsersSuccess({ records: accountUsers }));

                                    // store.dispatch Accounts Data to Your Accounts Store
                                    if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                                        localStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                    }
                                    store.dispatch(actionCreators.fetchYourAccountsSuccess({ records: accounts }));
                                } else {
                                    console.log('accountUsersData is null')
                                    store.dispatch(actionCreators.completeLogout());
                                }
                            } else {
                                console.log('accountUserRes failed')
                                store.dispatch(actionCreators.completeLogout());
                            }
                        }

                        // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                        let selectedAccountUser = null;
                        if (accountUsers.length > 0) {
                            selectedAccountUser = accountUsers[0];
                        }

                        if (selectedAccountUser !== undefined && selectedAccountUser !== null && selectedAccountUser.account !== undefined && selectedAccountUser.account !== null) {
                            // check that account is active
                            // check that account user is verified
                            if (selectedAccountUser.isVerified === true) {
                                if (selectedAccountUser.account.isActive === true) {
                                    let entityType = null;
                                    let entityId = null;
                                    let entityIrisId = null;

                                    let servicesList = [];
                                    try {
                                        const now = Date.now()
                                        const state = store.getState();
                                        const orchestratorState = { ...state.orchestrator };
                                        const servicesLastUpdatedAt = orchestratorState.servicesLastUpdatedAt ? new Date(orchestratorState.servicesLastUpdatedAt) : null;
                                        if (orchestratorState.services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                                            const servicesRes = await axios.get('/services', {
                                                params: { page: 1, size: 100000, isDeleted: false },
                                                headers: { Authorization: "Bearer " + newToken }
                                            });
                                            if (servicesRes && servicesRes.status === 200) {
                                                const services = servicesRes.data.data;

                                                const state2 = store.getState();
                                        const orchestratorState2 = { ...state2.orchestrator };
                                                const featuresLastUpdatedAt = orchestratorState2.featuresLastUpdatedAt ? new Date(orchestratorState2.featuresLastUpdatedAt) : null;
                                                let featuresList = [...orchestratorState2.features];
                                                if (featuresList.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                                                    const featuresRes = await axios.get('/features', {
                                                        params: { page: 1, size: 100000, isDeleted: false },
                                                        headers: { Authorization: "Bearer " + newToken }
                                                    });

                                                    if (featuresRes && featuresRes.status === 200) {
                                                        const features = featuresRes.data.data;
                                                        featuresList = features;

                                                        store.dispatch(actionCreators.getOrchestratorSuccess({ features: features, featuresLastUpdatedAt: Date.now() }));
                                                    }
                                                }

                                                const transformedServices = services.map((item) => {
                                                    let serviceFeaturesList = [];
                                                    if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                                                        for (let i = 0; i < item.featureIds.length; i++) {
                                                            const featureId = item.featureIds[i];
                                                            let feature = featuresList.find(i => i.id === featureId);
                                                            if (feature !== undefined && feature !== null) {
                                                                serviceFeaturesList.push(feature);
                                                            }
                                                        }
                                                    }

                                                    return {
                                                        ...item,
                                                        features: serviceFeaturesList
                                                    };
                                                });

                                                servicesList = transformedServices;

                                                store.dispatch(actionCreators.getOrchestratorSuccess({ services: transformedServices, servicesLastUpdatedAt: Date.now() }));
                                            }
                                        } else if (orchestratorState.services.length > 0) {
                                            servicesList = [...orchestratorState.services];
                                        }
                                    } catch (error) {
                                        logger.logErrorEvent('refreshAccessToken', error, `Error fetching Requests: ${error.message}`, true);
                                    }

                                    if (selectedAccountUser.isDriver === true && selectedAccountUser.driver !== undefined && selectedAccountUser.driver !== null) {
                                        entityType = "DRIVER";
                                        entityIrisId = selectedAccountUser.driver.irisId;
                                        entityId = selectedAccountUser.id;
                                    } else if (selectedAccountUser.account.isShipper === true && selectedAccountUser.account.shipper !== undefined && selectedAccountUser.account.shipper !== null) {
                                        entityType = "SHIPPER";
                                        entityIrisId = selectedAccountUser.account.shipper.irisId;
                                        entityId = selectedAccountUser.accountId;
                                    } else if (selectedAccountUser.account.isCarrier === true && selectedAccountUser.account.carrier !== undefined && selectedAccountUser.account.carrier !== null) {
                                        entityType = "CARRIER";
                                        entityIrisId = selectedAccountUser.account.carrier.irisId;
                                        entityId = selectedAccountUser.accountId;
                                    } else if (selectedAccountUser.account.isReceiver === true && selectedAccountUser.account.receiver !== undefined && selectedAccountUser.account.receiver !== null) {
                                        entityType = "RECEIVER";
                                        entityIrisId = selectedAccountUser.account.receiver.irisId;
                                        entityId = selectedAccountUser.accountId;
                                    } else if (selectedAccountUser.account.isProducer === true && selectedAccountUser.account.producer !== undefined && selectedAccountUser.account.producer !== null) {
                                        entityType = "PRODUCER";
                                        entityIrisId = selectedAccountUser.account.producer.irisId;
                                        entityId = selectedAccountUser.accountId;
                                    }

                                    let featuresList = [];
                                    if (selectedAccountUser.account !== undefined && selectedAccountUser.account !== null && selectedAccountUser.account.accountServices !== undefined && selectedAccountUser.account.accountServices !== null && selectedAccountUser.account.accountServices.length > 0) {
                                        if (servicesList !== undefined && servicesList !== null && servicesList.length > 0) {
                                            selectedAccountUser.account.accountServices.forEach((accountService) => {
                                                let service = servicesList.find(item => item.name === accountService.serviceName);
                                                if (service !== undefined && service !== null) {
                                                    if (service.features !== undefined && service.features !== null && service.features.length > 0) {
                                                        service.features.forEach((feature) => {
                                                            if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === false) {
                                                                featuresList.push(feature.name);
                                                            } else if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === true && selectedAccountUser.account.isBeta === true) {
                                                                featuresList.push(feature.name);
                                                            }
                                                        });
                                                    }
                                                }
                                            });
                                        }
                                    }

                                    store.dispatch(actionCreators.authSuccess({
                                        token: newToken,
                                        email: email,
                                        userName: userName,
                                        userId: userId,
                                        userProfileId: userProfileId,
                                        firstName: firstName,
                                        lastName: lastName,
                                        profileImageId: profileImageId,
                                        isLoading: false,
                                        isAuthComplete: true,
                                        isAuthenticated: true,
                                        entityId: entityId,
                                        entityIrisId: entityIrisId,
                                        entityType: entityType,
                                        error: null,
                                        isAdmin: isAdmin,
                                        isStaff: isStaff,
                                        isVP: isVP,
                                        isDirector: isDirector,
                                        isManager: isManager,
                                        featuresList: featuresList
                                    }));
                                } else {
                                    console.log('account isnt active');
                                    store.dispatch(actionCreators.completeLogout());
                                }
                            } else {
                                console.log('account user isnt verified');
                                store.dispatch(actionCreators.completeLogout());
                            }
                        } else {
                            console.log('account user or account dont exist');
                            store.dispatch(actionCreators.completeLogout());
                        }
                    }
                }
            } else {
                console.log('new token is bad');
                store.dispatch(actionCreators.completeLogout());
            }
        }

        return newToken;
    } catch (error) {
        logger.logErrorEvent('refreshAccessToken', error, error.message, true);

        return null;
    }
};

export default instance