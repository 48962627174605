import * as actionTypes from "../actions/actionTypes";

//#region Your Account Users Functions

export const fetchYourAccountUsersStart = () => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNT_USERS_START
    }
};

export const fetchYourAccountUsersSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNT_USERS_SUCCESS,
        payload: payload
    }
};

export const fetchYourAccountUsersFail = (payload) => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNT_USERS_FAIL,
        payload: payload
    }
};

export const clearYourAccountUsers = () => {
    return {
        type: actionTypes.CLEAR_YOUR_ACCOUNT_USERS
    }
};

//#endregion