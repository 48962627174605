import React from 'react';
import { Card } from "antd";
import classes from './ComplexCard.module.scss';

const ComplexCard = props => {
    return (
        <Card bordered={true} title={props.title} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={props.extra}>
            {props.children}
        </Card>
    );
};

export default ComplexCard;