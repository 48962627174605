import React, {useState, useMemo, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {Layout, Row, Col, Spin} from "antd";
import classes from "./RateConfirmation.module.scss";
import InvoiceTable from '../../components/InvoiceTable/InvoiceTable';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from "../../components/DataRow/DataRow";
import DataListRow from '../../components/DataListRow/DataListRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataDateTimeRangeRow from '../../components/DataDateTimeRangeRow/DataDateTimeRangeRow';
import DataTable from '../../components/DataTable/DataTable';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const stringFormatter = new StringFormatter();

const RateConfirmation = props => {
    const loadId = props.match.params.loadId;
    const phoneNumber = '123';
    const faxNumber = '123';

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const load = useSelector(state => state.loads.record);
    const invoiceLineItems = useSelector(state => state.invoiceLineItems.records);
    const isInvoiceLineItemsLoading = useSelector(state => state.invoiceLineItems.isLoading);
    const stops = useSelector(state => state.loadStops.records);
    const isStopsLoading = useSelector(state => state.loadStops.isLoading);
    const users = useSelector(state => state.orchestrator.staff);
    const isUsersLoading = useSelector(state => state.users.isLoading);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [imageUrl, setImageUrl] = useState(null);
    const [BOLList, setBOLList] = useState([]);
    const [commodityList, setCommodityList] = useState([]);

    const user = null;

    const pageHeader = (title) => {
        return (
            <span className={classes.titleBlock}>{title}</span>
        );
    };

    const stopHeader = (title, requestedDateTime, timeZone, pickUpNumber, dropOffNumber) => {
        return (
            <div className={classes.pageHeader}>
                <div className={classes.heading}>
                    <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={8}>
                            <span className={classes.title}>{title}</span>
                        </Col>
                        <Col span={8}>
                            <span className={classes.title}>{stringFormatter.toFormattedString("MomentDate", requestedDateTime, null, timeZone)}</span>
                        </Col>
                        <Col span={8}>
                            <span className={classes.title}>{pickUpNumber !== undefined && pickUpNumber !== null ? `Pick-Up #: ${pickUpNumber}` : (dropOffNumber !== undefined && dropOffNumber !== null ? `Drop-Off #: ${dropOffNumber}` : null)}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    const invoiceColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    const commodityColumns = [
        {
            title: 'Commodity',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit of Measure/Packaging',
            dataIndex: 'unitPackagingInitCount',
            key: 'unitPackagingInitCount',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackagingCount", text, record.unitPackaging); },
        },
        {
            title: 'Bulk Packaging',
            dataIndex: 'bulkPackagingInitCount',
            key: 'bulkPackagingInitCount',
            render: (text, record) => { return stringFormatter.toFormattedString("BulkPackagingCount", text, record.bulkPackaging); },
        },
    ];

    console.log("load", load);

    useEffect(() => {
        if (load && load.shipperId) {
            axiosAuthenticated.get('/documents?entityId=' + load.shipperId + '&documentType=ACCOUNT_LOGO', {
                headers: {}
            }).then(res => {
                if (res.data && res.data.data && res.data.data.length > 0) {
                    setImageUrl(res.data.data[res.data.data.length-1].link);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [load]);

    useMemo(() => {
        dispatch(actionCreators.fetchLoadWithIncludes(loadId));
    }, [loadId]);

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    useMemo(() => {
        if (load !== undefined && load !== null) {
            let hasAccess = (entityType === 'STAFF' || (entityType === 'CARRIER' && load.assignedCarrierId === entityId));

            if (loadId === load.id && hasAccess === true) {
                dispatch(actionCreators.fetchInvoiceLineItems({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchLoadStops(load.id));
            } else if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [load, entityType, entityId]);

    useMemo(() => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let listOfBOLs = new Set();
            let listOfCommodities = new Set();
            stops.forEach(function (stop) {
                if (stop.bolNumber !== undefined && stop.bolNumber !== null && stop.bolNumber !== '') {
                    // if commas are in the bolNumber value then we assume bolNumber has more than one BOL number in it so use split to create an array of bol Numbers to add
                    // if only a single bolNumber is in it, which in that case there are no commas, a single element array will be added to the list
                    let bolNums = stop.bolNumber.split(',');
                    bolNums.forEach(function (item) {
                        listOfBOLs.add(item);
                    });
                }

                if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                    stop.commodities.forEach(function (commodity) {
                        if (commodity.unitPackagingInitCount > 0 || commodity.bulkPackagingInitCount > 0) {
                            listOfCommodities.add(commodity.name);
                        }
                    });
                }
            });
            setBOLList(Array.from(listOfBOLs));
            setCommodityList(Array.from(listOfCommodities));
        }
    }, [stops]);

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isInvoiceLineItemsLoading === true || isStopsLoading === true || isUsersLoading === true}>
            {load !== undefined && load !== null && load.id !== undefined && load.id !== null && load.id === loadId ? (
                <Layout style={{ backgroundColor: 'white', margin: 0, color: '#000000 !important' }}>
                    <Row gutter={20}>
                        <Col span={8}>
                                {(imageUrl !== undefined && imageUrl !== null) && (
                                    <div style={{ marginRight: 12 }}>
                                        <img src={imageUrl} style={{ color: '#000000', width: '250px' }} />
                                    </div>
                                )}
                        </Col>
                        <Col span={8}>
                            <DataRow style={{ textAlign: 'center' }} valueStyle={{ fontWeight: 'bold', fontSize: 22 }} value="CARRIER RATE CONFIRMATION" dataType="String" />
                            <DataRow style={{ textAlign: 'center' }} titleStyle={{ fontWeight: 'bold', fontSize: 18 }} valueStyle={{ fontWeight: 'bold', fontSize: 18 }} title="Load ID" value={load.irisId} dataType="String" />
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <div>Send signed Rate Confirmation to:</div>
                            <div><a style={{ color: 'rgba(0, 0, 0, 0.65)' }} href="mailto: carrierratecon@pulogix.com">carrierratecon@pulogix.com</a></div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <h3 style={{ textAlign: 'center' }}>Review & Accept to Confirm the load.</h3>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            {pageHeader('Customer Requirements')}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null ? (
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    {load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                    {load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                    {load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                    {load.equipmentNeeds.preCoolReefer !== undefined && load.equipmentNeeds.preCoolReefer !== null ? (<DataRow title="Pre-cool Temp*" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                    {load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null ? (
                                        <>
                                            <DataRow title="Load Temp*" value="Continuous" dataType="String" />
                                            <DataTemperatureRangeRow title="Temp Range*" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />
                                        </>
                                    ) : null}
                                    <DataRow title="Team Driving" value={load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                    {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                    ) : null}
                                    {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                    ) : null}
                                    {load.equipmentNeeds.preCoolReefer !== undefined && load.equipmentNeeds.preCoolReefer !== null ? <div style={{ textAlign: 'center' }}>* See BOL for Final TEMP</div> : null}
                                </div>
                            ) : null}
                        </Col>
                        <Col span={8}>
                            {pageHeader('Order')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <DataRow title="Load ID" value={load.irisId} dataType="String" />
                                <DataRow title="Distance" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
                                <DataListRow valueStyle={{ paddingLeft: 10 }} title="BOL(s)" data={BOLList} dataType="String" />
                            </div>
                        </Col>
                        <Col span={8}>
                            {pageHeader('Booked By')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <DataRow title="Broker" value={load.shipper.name} dataType="String" />

                                {load.assignedAccountRepUsers !== undefined && load.assignedAccountRepUsers !== null && load.assignedAccountRepUsers.length > 0 ?
                                    load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                        return (
                                            <DataContactInfoRow separateLines={true} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} valueStyle={{ marginLeft: 16 }} name={assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName} phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} email={assignedAccountRepUser.user} />
                                        );
                                    }) : null}

                                <DataPhoneNumberRow title="Fax #" phone={faxNumber} phoneExt={null} />
                                <DataRow title="Date" value={load.createdAt} dataType="MomentDateTime" />
                            </div>
                        </Col>
                    </Row>
                    {stops !== undefined && stops !== null && stops.length > 0 ? (
                        <>
                            {stops.filter(stop => stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF').map((stop, index) => (
                                <Row gutter={20} key={`Stop #${index + 1}`}>
                                    <Col span={24}>
                                        {stopHeader(`Stop #${index + 1}: ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}`, stop.requestedDateTime, stop.timeZone, stop.pickUpNumber, stop.dropOffNumber)}
                                        <Row>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.address !== undefined && stop.stopLocation.address !== null && (load.loadStatus === 'PENDING' || load.loadStatus === 'APPROVED' || load.loadStatus === 'PENDING_RATE_CONFIRMATION' || load.loadStatus === 'BOOKED') ? (
                                                    <>
                                                        <DataAddressRow valueStyle={{ paddingLeft: 10 }} title="Address**" city={stop.stopLocation.address.city} state={stop.stopLocation.address.state} />
                                                        <div style={{ textAlign: 'left', marginTop: 8, marginBottom: 16 }}>** Driver must use the  mobile app in order to see the exact address.</div>
                                                    </>
                                                ) : null}
                                                {stop.stopLocation !== undefined && stop.stopLocation !== null && (load.loadStatus !== 'PENDING' && load.loadStatus !== 'APPROVED' && load.loadStatus !== 'PENDING_RATE_CONFIRMATION' && load.loadStatus !== 'BOOKED') ? (
                                                    <>
                                                        {stop.stopLocation.name !== undefined && stop.stopLocation.name !== null ? (<DataRow title="Facility" value={stop.stopLocation.name} dataType="String" />) : null}
                                                        {stop.stopLocation.address !== undefined && stop.stopLocation.address !== null ? (<DataAddressRow valueStyle={{ paddingLeft: 10 }} title="Address" address={stop.stopLocation.address} />) : null}
                                                    </>
                                                ) : null}
                                                {stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null ? <DataContactInfoRow valueStyle={{ paddingLeft: 10 }} title="Contact" contact={stop.apptPointOfContact} /> : null}
                                            </Col>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.apptType !== undefined && stop.apptType !== null ? (
                                                    <>
                                                        <DataRow title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                                                        {stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow title="Appointment Window" startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null}
                                                        {stop.apptNumber !== undefined && stop.apptNumber !== null ? <DataRow title="Appointment #" value={stop.apptNumber} dataType="String" /> : null}
                                                        {stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                                        {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                                        {stop.apptInstructions !== undefined && stop.apptInstructions !== null ? <DataRow title="Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                                                    </>
                                                ) : null}
                                                {stop.isDriverAssistRequested !== undefined && stop.isDriverAssistRequested !== null ? <DataRow title="Driver Load/Unload" value={stop.isDriverAssistRequested} dataType="Boolean" /> : null}
                                                {stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0 ? <DataRow title="Lumper Load/Unload" value={stop.lumperFee > 0} dataType="Boolean" /> : null}
                                            </Col>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow title="Customer PO #" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                                {stop.bolNumber !== undefined && stop.bolNumber !== null ? <DataRow title="BOL Number" value={stop.bolNumber} dataType="String" /> : null}
                                                {stop.specialInstructions !== null && stop.specialInstructions !== undefined ? (<DataRow title="Facility Notes" value={stop.specialInstructions} dataType="String" />) : null}
                                            </Col>
                                        </Row>
                                        {stop.commodities !== null && stop.commodities !== null && stop.commodities.length > 0 ? (
                                            <DataTable
                                                style={{ marginTop: 16 }}
                                                dataSource={stop.commodities}
                                                columns={commodityColumns}
                                                hidePaging={true}
                                                rowKey={record => record.name}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                        </>
                    ) : null}
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Payment')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <InvoiceTable
                                    entityType="CARRIER"
                                    isRateConfirmation={true}
                                    data={invoiceLineItems.filter(item => (item.fromEntityType === 'CARRIER' || item.toEntityType === 'CARRIER') && (item.approvalStatus === 'PENDING' || item.approvalStatus === 'APPROVED'))}
                                    columns={invoiceColumns}
                                />
                            </div>
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>Please contact UPL at {stringFormatter.toFormattedPhoneNumber(phoneNumber)} if the quoted payment is incorrect.</div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Driver and Asset Information Request')}
                            {load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? (
                                <>
                                    <Row>
                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ textAlign: 'left' }}>Please fill out the drivers' names, emails, and phone numbers below or give your account representative this information as soon as possible. Thanks!</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 1's First Name:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 1's Last Name:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 1's Email:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 1's Cell Phone:</div>
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 2's First Name:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 2's Last Name:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 2's Email:</div>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver 2's Cell Phone:</div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 30, marginBottom: 10 }}>
                                        <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Tractor License Plate:</div>
                                        </Col>
                                        <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Trailer License Plate:</div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                    <>
                                        <Row>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <div style={{ textAlign: 'left' }}>Please fill out the driver's name, email, and phone number below or give your account representative this information as soon as possible. Thanks!</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver's First Name:</div>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver's Last Name:</div>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver's Email:</div>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Driver's Cell Phone:</div>
                                            </Col>
                                            <Col span={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Tractor License Plate:</div>
                                                <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 10, marginBottom: 10 }}>Trailer License Plate:</div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Agreement')}
                            {load.assignedCarrier !== undefined && load.assignedCarrier !== null ? (
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title="Carrier" value={load.assignedCarrier.name} dataType="String" />
                                            <DataRow title="MC #" value={load.assignedCarrier.carrier.mcNum} dataType="String" />
                                            <DataRow title="Email" value={load.assignedCarrier.email} dataType="String" />
                                            <DataPhoneNumberRow title="Phone #" phone={load.assignedCarrier.phone} phoneExt={load.assignedCarrier.phoneExt} />
                                            {load.assignedCarrier.fax !== undefined && load.assignedCarrier.fax !== null ? <DataPhoneNumberRow title="Fax #" phone={load.assignedCarrier.fax} /> : null}
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title="Broker" value={load.shipper.name} dataType="String" />

                                            {load.assignedAccountRepUsers !== undefined && load.assignedAccountRepUsers !== null && load.assignedAccountRepUsers.length > 0 ?
                                                load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                    return (
                                                        <DataContactInfoRow separateLines={true} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} valueStyle={{ marginLeft: 16 }} name={assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName} phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} email={assignedAccountRepUser.user} />
                                                    );
                                                }) : null}

                                            <DataAddressRow
                                                title="Address"
                                                valueStyle={{ paddingLeft: 0 }} streetAddress1={load.shipper.companyAddress.streetAddress1} city={load.shipper.companyAddress.city} state={load.shipper.companyAddress.state} postalCode={load.shipper.companyAddress.postalCode} />

                                            <DataPhoneNumberRow title="Fax #" phone={load.shipper.pointOfContact.phone} />
                                            <DataRow title="Date" value={load.createdAt} dataType="MomentDateTime" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                            <span>By signing below, {load.assignedCarrier.name} agrees to the terms and conditions set forth below and provided herewith, if any.</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 8]}>
                                        <Col span={16}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Name & Title (Print):</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Date:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Signature:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                            <span><b>PLEASE RETURN THE SIGNED COPY TO <a style={{ color: 'rgba(0, 0, 0, 0.65)' }} href="mailto: carrierratecon@pulogix.com">CARRIERRATECON@UPL.COM</a><br />OR FAX BACK TO {stringFormatter.toFormattedPhoneNumber(faxNumber)} (ATTN: Load Id: {load.irisId})</b></span>
                                        </Col>
                                    </Row>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Terms & Conditions')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <p>
                                    This Rate Confirmation is subject to the terms of the master Broker-Carrier Agreement between our companies. It amends that master Agreement as to any additional terms.
                                </p>
                                <p>
                                    THIS LOAD MUST NOT BE DOUBLE BROKERED. Except as specified above, the Rate(s) confirmed hereby are inclusive of all charges, including any fuel surcharge. No different tariff or schedule rates apply. Additional charges not listed above may not be added by the Carrier. Any additional charges must appear on a revised Rate Confirmation signed by Broker.
                                </p>
                                <p>
                                    Lumper fees will not be paid unless Broker is advised by phone before unloading.
                                </p>
                                <p>
                                    Carrier certifies that if transportation occurs in California any refrigeration unit furnished will comply with the California TRU Regulations. Carrier agrees to be responsible for any fines imposed on Broker and/or Shipper because of noncompliance.
                                </p>
                                <p>
                                    Carrier hereby confirms that it maintains applicable and valid insurance without exclusions that would prevent coverage for the items listed above.
                                </p>
                                <p>
                                    If your truck is late for pickup/delivery, call Broker immediately.
                                </p>
                                <p>
                                    Carrier acknowledges that any routing instructions from the Shipper herein or in the UPL mobile application are being provided for convenience only, and the Carrier may choose the route except as otherwise set forth herein.
                                </p>
                                <p>
                                    Driver is responsible for count & quality of the delivered product.
                                </p>
                                <p>
                                    Driver must pulp produce loads prior to loading. Daily updates via mobile app are due by 08:00 am Pacific Time.
                                </p>
                                <p>
                                    Carrier must include a signed copy of the Shipper’s Bill of Lading and other proof of delivery with its invoice to Broker.
                                </p>
                                <p>
                                    The master Broker-Carrier Agreement and this document constitute the complete agreement of the parties as to the load involved.
                                </p>
                                <p>
                                    <b>UNLESS YOU SEND THE BROKER A WRITTEN (FAX) OBJECTION TO THIS RATE CONFIRMATION WITHIN 24 HOURS OF ITS TRANSMISSION, YOU HAVE AGREED TO THESE TERMS.</b>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <h3 style={{ marginTop: 40, textAlign: 'center' }}>ALL LOADS ARE SUBJECT TO ELECTRONIC MONITORING.</h3>
                        </Col>
                    </Row>
                </Layout>
            ) : null}
        </Spin>
    );
};

export default withRouter(RateConfirmation);
