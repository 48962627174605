import * as actionTypes from "../actions/actionTypes";
import axios from 'axios';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import jwt from 'jwt-decode';
import moment from 'moment';
import {clearUser} from "./users";
import {clearYourAccountUsers, fetchYourAccountUsersSuccess} from "./yourAccountUsers";
import {clearYourAccounts, fetchYourAccountsSuccess} from "./yourAccounts";
import {getFilesMetaData} from "../../api/fileClient";
import {clearKeys} from './keys';
import {getOrchestratorSuccess} from "./orchestrator";
import {clearMessages} from "./messages";
import {clearNotifications} from "./notifications";
import logger from "../../shared/logger";
import EntityUtils from '../entityUtils';

//#region Auth Functions

// Loading State for Spinner
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (payload) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: payload
    }
};

export const authFail = (payload) => {
    return {
        type: actionTypes.AUTH_FAIL,
        payload: payload
    }
};

export const authClearError = () => {
    return {
        type: actionTypes.AUTH_CLEAR_ERROR
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

//#endregion

//#region Auth Methods

export const completeLogout = () => {
    return dispatch => {
        // Remove Token to Local Storage
        console.log('signing out');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('userProfileId');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('profileImageId');
        localStorage.removeItem('yourAccounts');
        localStorage.removeItem('yourAccountUsers');

        dispatch(logout());
        dispatch(clearYourAccountUsers());
        dispatch(clearYourAccounts());
        dispatch(clearUser());
        dispatch(clearKeys());
        dispatch(clearMessages());
        dispatch(clearNotifications());
    };
};

export const autoLogin = (refreshAccounts = false) => {
    return async (dispatch, getState) => {
        const accountUsersPath = '/accountUsers';
        const tokenRefreshPath = '/api-token-refresh/';

        try {
            //dispatch(authStart());
            //console.log('start autologin');

            // Get token from local Storage
            const token = localStorage.getItem('token');
            if (token !== undefined && token !== null) {
                dispatch(authSuccess({
                    token: token
                }));
            }

            const refreshToken = localStorage.getItem('refreshToken');

            // If token is not present, logout
            if (refreshToken === undefined || refreshToken === null) {
                //console.log('bad token');
                dispatch(completeLogout());
            } else {
                let refreshTokenDecoded = jwt(refreshToken);
                let endDate = moment.unix(refreshTokenDecoded.exp);

                // If Token is present, get the expiration date
                let refreshTokenExpirationDate = endDate.toDate();

                const today = new Date();

                if (refreshTokenExpirationDate > today) {
                    console.log('expiration is greater than today');
                    const resp = await axios.post(tokenRefreshPath, {refresh: refreshToken});
                    if (resp && resp.status === 200) {
                        const newToken = resp.data.access;
                        const newRefreshToken = resp.data.refresh;

                        if (newToken !== undefined && newToken !== null) {
                            let newTokenDecoded = jwt(newToken);
                            let userId = newTokenDecoded.user_id;
                            let userProfileId = newTokenDecoded.user_profile_id;
                            let firstName = newTokenDecoded.f_name;
                            let lastName = newTokenDecoded.l_name;
                            let isUserActive = newTokenDecoded.is_active;
                            let isStaff = newTokenDecoded.is_staff;
                            let isAdmin = newTokenDecoded.is_superuser;
                            let isVP = newTokenDecoded.is_vp;
                            let isDirector = newTokenDecoded.is_director;
                            let isManager = newTokenDecoded.is_manager;
                            let email = newTokenDecoded.email;
                            let userName = newTokenDecoded.username;
                            let newEndDate = moment.unix(newTokenDecoded.exp);

                            localStorage.setItem('token', newToken);
                            localStorage.setItem('refreshToken', newRefreshToken);
                            localStorage.setItem('userId', userId.toString());
                            localStorage.setItem('userProfileId', userProfileId.toString());
                            localStorage.setItem('expirationDate', newEndDate.toDate().toString());
                            localStorage.setItem('firstName', firstName.toString());
                            localStorage.setItem('lastName', lastName.toString());

                            let profileImageId = localStorage.getItem('profileImageId');
                            if (profileImageId === null || profileImageId === undefined) {
                                const fileMetaData = await getFilesMetaData(userProfileId);
                                let profileImageIdValue = EntityUtils.getUserFileIds(fileMetaData).profileImageId;
                                profileImageId = profileImageIdValue;
                                if (profileImageId !== undefined && profileImageId !== null) {
                                    localStorage.setItem('profileImageId', profileImageId);
                                }
                            }

                            // check to see if the user isActive and if they aren't, don't let them login
                            if (isUserActive === false) {
                                //console.log('user isnt active');
                                dispatch(completeLogout());
                            } else {
                                // if the user is staff then no need to get account users and accounts, just authenticate them
                                if (isStaff === true) {
                                    dispatch(authSuccess({
                                        token: newToken,
                                        email: email,
                                        userName: userName,
                                        userId: userId,
                                        userProfileId: userProfileId,
                                        firstName: firstName,
                                        lastName: lastName,
                                        profileImageId: profileImageId,
                                        isAuthComplete: true,
                                        isAuthenticated: true,
                                        entityId: userId,
                                        entityIrisId: 'IRIS-STAFF',
                                        entityType: 'STAFF',
                                        error: null,
                                        isAdmin: isAdmin,
                                        isStaff: isStaff,
                                        isVP: isVP,
                                        isDirector: isDirector,
                                        isManager: isManager
                                    }));
                                } else {
                                    // Get Account User Data

                                    let accountUsers = [];
                                    let accounts = [];
                                    const yourAccountUsers = localStorage.getItem('yourAccountUsers');
                                    const yourAccounts = localStorage.getItem('yourAccounts');
                                    //console.log(yourAccountUsers);
                                    //console.log(yourAccounts);
                                    if (yourAccountUsers !== undefined && yourAccountUsers !== null && yourAccounts !== undefined && yourAccounts !== null && refreshAccounts === false) {
                                        accountUsers = JSON.parse(yourAccountUsers);
                                        accounts = JSON.parse(yourAccounts);

                                        // Dispatch Account Users Data to Your Accounts Users Store
                                        dispatch(fetchYourAccountUsersSuccess({records: accountUsers}));

                                        // Dispatch Accounts Data to Your Accounts Store
                                        dispatch(fetchYourAccountsSuccess({records: accounts}));
                                    } else {
                                        const accountUserRes = await axiosAuthenticated.get(accountUsersPath, {params: {userId: userId}});

                                        if (accountUserRes && accountUserRes.status === 200) {

                                            // Get Account User Data then transform
                                            const accountUsersData = accountUserRes.data.data;
                                            if (accountUsersData !== undefined && accountUsersData !== null && accountUsersData.length > 0) {

                                                let accountIds = accountUsersData.filter(item => item.accountId !== undefined && item.accountId !== null).map(item => {
                                                    return item.accountId;
                                                });
                                                let accountUserIds = accountUsersData.map(item => {
                                                    return item.id;
                                                });

                                                let driverDocuments = [];
                                                const driverDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                                                    page: 1,
                                                    size: 10000,
                                                    entityId: [...accountUserIds]
                                                });
                                                if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                                                    driverDocuments = driverDocumentsRes.data.data;
                                                }

                                                const accountsRes = await axiosAuthenticated.post('/accounts/bulk/get', {
                                                    page: 1,
                                                    size: 10000,
                                                    id: [...accountIds]
                                                });
                                                if (accountsRes && accountsRes.status === 200) {
                                                    accounts = accountsRes.data.data;
                                                }

                                                accountUsers = accountUsersData.map(item => {
                                                    if (item.isDriver === true) {
                                                        item = EntityUtils.getDriverInfo(item.id, item, driverDocuments);
                                                    }

                                                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                                                    return {
                                                        ...item,
                                                        account: account
                                                    };
                                                });

                                                // Dispatch Account Users Data to Your Accounts Users Store
                                                if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
                                                    localStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                                                }
                                                dispatch(fetchYourAccountUsersSuccess({records: accountUsers}));

                                                // Dispatch Accounts Data to Your Accounts Store
                                                if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                                                    localStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                                }
                                                dispatch(fetchYourAccountsSuccess({records: accounts}));
                                            } else {
                                                //console.log('accountUsersData is null')
                                                dispatch(completeLogout());
                                            }
                                        } else {
                                            //console.log('accountUserRes failed')
                                            dispatch(completeLogout());
                                        }
                                    }

                                    // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                                    let selectedAccountUser = null;
                                    if (accountUsers.length > 0) {
                                        selectedAccountUser = accountUsers[0];
                                    }

                                    if (selectedAccountUser !== undefined && selectedAccountUser !== null && selectedAccountUser.account !== undefined && selectedAccountUser.account !== null) {
                                        // check that account is active
                                        // check that account user is verified
                                        if (selectedAccountUser.isVerified === true) {
                                            if (selectedAccountUser.account.isActive === true) {
                                                let entityType = null;
                                                let entityId = null;
                                                let entityIrisId = null;

                                                let servicesList = [];
                                                try {
                                                    const now = Date.now()
                                                    const state = getState();
                                                    const orchestratorState = {...state.orchestrator};
                                                    const servicesLastUpdatedAt = orchestratorState.servicesLastUpdatedAt ? new Date(orchestratorState.servicesLastUpdatedAt) : null;
                                                    if (orchestratorState.services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                                                        const servicesRes = await axiosAuthenticated.get('/services', {
                                                            params: {
                                                                page: 1,
                                                                size: 100000,
                                                                isDeleted: false
                                                            }
                                                        });
                                                        if (servicesRes && servicesRes.status === 200) {
                                                            const services = servicesRes.data.data;

                                                            const state2 = getState();
                                                            const orchestratorState2 = {...state2.orchestrator};
                                                            const featuresLastUpdatedAt = orchestratorState2.featuresLastUpdatedAt ? new Date(orchestratorState2.featuresLastUpdatedAt) : null;
                                                            let featuresList = [...orchestratorState2.features];
                                                            if (featuresList.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                                                                const featuresRes = await axiosAuthenticated.get('/features', {
                                                                    params: {
                                                                        page: 1,
                                                                        size: 100000,
                                                                        isDeleted: false
                                                                    }
                                                                });

                                                                if (featuresRes && featuresRes.status === 200) {
                                                                    const features = featuresRes.data.data;
                                                                    featuresList = features;

                                                                    dispatch(getOrchestratorSuccess({
                                                                        features: features,
                                                                        featuresLastUpdatedAt: Date.now()
                                                                    }));
                                                                }
                                                            }

                                                            const transformedServices = services.map((item) => {
                                                                let serviceFeaturesList = [];
                                                                if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                                                                    for (let i = 0; i < item.featureIds.length; i++) {
                                                                        const featureId = item.featureIds[i];
                                                                        let feature = featuresList.find(i => i.id === featureId);
                                                                        if (feature !== undefined && feature !== null) {
                                                                            serviceFeaturesList.push(feature);
                                                                        }
                                                                    }
                                                                }

                                                                return {
                                                                    ...item,
                                                                    features: serviceFeaturesList
                                                                };
                                                            });

                                                            servicesList = transformedServices;

                                                            dispatch(getOrchestratorSuccess({
                                                                services: transformedServices,
                                                                servicesLastUpdatedAt: Date.now()
                                                            }));
                                                        }
                                                    } else if (orchestratorState.services.length > 0) {
                                                        servicesList = [...orchestratorState.services];
                                                    }
                                                } catch (error) {
                                                    logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
                                                }

                                                if (selectedAccountUser.isDriver === true && selectedAccountUser.driver !== undefined && selectedAccountUser.driver !== null) {
                                                    entityType = "DRIVER";
                                                    entityIrisId = selectedAccountUser.driver.irisId;
                                                    entityId = selectedAccountUser.id;
                                                } else if (selectedAccountUser.account.isShipper === true && selectedAccountUser.account.shipper !== undefined && selectedAccountUser.account.shipper !== null) {
                                                    entityType = "SHIPPER";
                                                    entityIrisId = selectedAccountUser.account.shipper.irisId;
                                                    entityId = selectedAccountUser.accountId;
                                                } else if (selectedAccountUser.account.isCarrier === true && selectedAccountUser.account.carrier !== undefined && selectedAccountUser.account.carrier !== null) {
                                                    entityType = "CARRIER";
                                                    entityIrisId = selectedAccountUser.account.carrier.irisId;
                                                    entityId = selectedAccountUser.accountId;
                                                } else if (selectedAccountUser.account.isReceiver === true && selectedAccountUser.account.receiver !== undefined && selectedAccountUser.account.receiver !== null) {
                                                    entityType = "RECEIVER";
                                                    entityIrisId = selectedAccountUser.account.receiver.irisId;
                                                    entityId = selectedAccountUser.accountId;
                                                } else if (selectedAccountUser.account.isProducer === true && selectedAccountUser.account.producer !== undefined && selectedAccountUser.account.producer !== null) {
                                                    entityType = "PRODUCER";
                                                    entityIrisId = selectedAccountUser.account.producer.irisId;
                                                    entityId = selectedAccountUser.accountId;
                                                }

                                                let featuresList = [];
                                                if (selectedAccountUser.account !== undefined && selectedAccountUser.account !== null && selectedAccountUser.account.accountServices !== undefined && selectedAccountUser.account.accountServices !== null && selectedAccountUser.account.accountServices.length > 0) {
                                                    if (servicesList !== undefined && servicesList !== null && servicesList.length > 0) {
                                                        selectedAccountUser.account.accountServices.forEach((accountService) => {
                                                            let service = servicesList.find(item => item.name === accountService.serviceName);
                                                            if (service !== undefined && service !== null) {
                                                                if (service.features !== undefined && service.features !== null && service.features.length > 0) {
                                                                    service.features.forEach((feature) => {
                                                                        if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === false) {
                                                                            featuresList.push(feature.name);
                                                                        } else if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === true && selectedAccountUser.account.isBeta === true) {
                                                                            featuresList.push(feature.name);
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                    }
                                                }

                                                // Complete Auto-Login
                                                dispatch(authSuccess({
                                                    token: newToken,
                                                    email: email,
                                                    userName: userName,
                                                    userId: userId,
                                                    userProfileId: userProfileId,
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                    profileImageId: profileImageId,
                                                    isLoading: false,
                                                    isAuthComplete: true,
                                                    isAuthenticated: true,
                                                    entityId: entityId,
                                                    entityIrisId: entityIrisId,
                                                    entityType: entityType,
                                                    error: null,
                                                    isAdmin: isAdmin,
                                                    isStaff: isStaff,
                                                    isVP: isVP,
                                                    isDirector: isDirector,
                                                    isManager: isManager,
                                                    featuresList: featuresList
                                                }));
                                            } else {
                                                //console.log('account isnt active');
                                                dispatch(completeLogout());
                                            }
                                        } else {
                                            //console.log('account user isnt verified');
                                            dispatch(completeLogout());
                                        }
                                    } else {
                                        //console.log('account user or account dont exist');
                                        dispatch(completeLogout());
                                    }
                                }
                            }
                        } else {
                            //console.log('new token is bad');
                            dispatch(completeLogout());
                        }
                    }
                } else {
                    //console.log('expiration date expired')
                    dispatch(completeLogout());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            //console.log('auth failed with error');
            dispatch(completeLogout());
        }
    };
};

export const register = (password, cpassword, email, firstName, lastName, phoneNumber) => {
    return async (dispatch) => {
        const registerPath = '/rest-auth/registration/';
        const userProfilesPath = '/api/userProfiles/';
        try {
            // Start Registration
            dispatch(authStart());

            // Register
            const registerRes = await axios.post(registerPath, {
                username: email,
                password1: password,
                password2: cpassword,
                email: email
            });

            if (registerRes && registerRes.status === 201) {
                const token = registerRes.data.access;
                const refreshToken = registerRes.data.refresh;
                const userId = registerRes.data.user_id;
                const userProfileId = registerRes.data.user_profile_id;

                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);

                // Update User Profile Data
                const userPutRes = await axiosAuthenticated.put(userProfilesPath + `${userProfileId}/`, {
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    createdBy: userId,
                    modifiedBy: userId
                });

                if (userPutRes && userPutRes.status === 200) {
                    dispatch(authSuccess({
                        isAuthenticated: false,
                        isLoading: false,
                        isAuthComplete: true,
                        error: null
                    }));
                }
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const login = (email, password) => {
    return async (dispatch, getState) => {
        const loginPath = '/rest-auth/login/';
        const accountUsersPath = '/accountUsers';

        try {
            // Start Login In
            dispatch(authStart());

            // Log In
            const loginRes = await axios.post(loginPath, {username: email, password: password});

            if (loginRes && loginRes.status === 200) {
                const token = loginRes.data.access;
                const refreshToken = loginRes.data.refresh;

                if (token !== undefined && token !== null) {
                    const tokenDecoded = jwt(token);
                    const userId = tokenDecoded.user_id;
                    const userProfileId = tokenDecoded.user_profile_id;
                    const firstName = tokenDecoded.f_name;
                    const lastName = tokenDecoded.l_name;
                    const isUserActive = tokenDecoded.is_active;
                    const isStaff = tokenDecoded.is_staff;
                    const isAdmin = tokenDecoded.is_superuser;
                    const isVP = tokenDecoded.is_vp;
                    const isDirector = tokenDecoded.is_director;
                    const isManager = tokenDecoded.is_manager;
                    const email = tokenDecoded.email;
                    const userName = tokenDecoded.username;

                    //console.log(tokenDecoded);

                    // Get time the token will expire in seconds
                    const endDate = moment.unix(tokenDecoded.exp);

                    // need to set here so axiosAuthenticated works for getting documents
                    // Store Token to Local Storage
                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('userId', userId.toString());
                    localStorage.setItem('userProfileId', userProfileId.toString());
                    localStorage.setItem('expirationDate', endDate.toDate().toString());
                    localStorage.setItem('firstName', firstName.toString());
                    localStorage.setItem('lastName', lastName.toString());

                    const fileMetaData = await getFilesMetaData(userProfileId);
                    let {profileImageId} = EntityUtils.getUserFileIds(fileMetaData);
                    if (profileImageId !== undefined && profileImageId !== null) {
                        localStorage.setItem('profileImageId', profileImageId);
                    }

                    // check to see if the user isActive and if they aren't, don't let them login
                    if (isUserActive === false) {
                        dispatch(authFail({errorType: 'NOT_ACTIVATED', error: "Your User Account is not activated."}));
                    } else {
                        // if the user is staff then no need to get account users and accounts, just authenticate them
                        if (isStaff === true) {

                            // Complete Login
                            dispatch(authSuccess({
                                token: token,
                                email: email,
                                userName: userName,
                                userId: userId,
                                userProfileId: userProfileId,
                                firstName: firstName,
                                lastName: lastName,
                                profileImageId: profileImageId,
                                isLoading: false,
                                isAuthComplete: true,
                                isAuthenticated: true,
                                entityId: userId,
                                entityIrisId: 'IRIS-STAFF',
                                entityType: 'STAFF',
                                error: null,
                                isAdmin: isAdmin,
                                isStaff: isStaff,
                                isVP: isVP,
                                isDirector: isDirector,
                                isManager: isManager
                            }));
                        } else {
                            // Get Account Users Data
                            const accountUserRes = await axiosAuthenticated.get(accountUsersPath, {params: {userId: userId}});

                            if (accountUserRes && accountUserRes.status === 200) {
                                // Get Account User Data then transform
                                const accountUsersData = accountUserRes.data.data;
                                let accounts = [];
                                if (accountUsersData !== undefined && accountUsersData !== null && accountUsersData.length > 0) {

                                    let accountIds = accountUsersData.filter(item => item.accountId !== undefined && item.accountId !== null).map(item => {
                                        return item.accountId;
                                    });
                                    let accountUserIds = accountUsersData.map(item => {
                                        return item.id;
                                    });

                                    let driverDocuments = [];
                                    const driverDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                                        page: 1,
                                        size: 10000,
                                        entityId: [...accountUserIds]
                                    });
                                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                                        driverDocuments = driverDocumentsRes.data.data;
                                    }

                                    const accountsRes = await axiosAuthenticated.post('/accounts/bulk/get', {
                                        page: 1,
                                        size: 10000,
                                        id: [...accountIds]
                                    });
                                    if (accountsRes && accountsRes.status === 200) {
                                        accounts = accountsRes.data.data;
                                    }

                                    const accountUsers = accountUsersData.map(item => {
                                        if (item.isDriver === true) {
                                            item = EntityUtils.getDriverInfo(item.id, item, driverDocuments);
                                        }

                                        let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                                        return {
                                            ...item,
                                            account: account
                                        };
                                    });

                                    // Dispatch Account Users Data to Your Accounts Users Store
                                    if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
                                        localStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                                    }
                                    dispatch(fetchYourAccountUsersSuccess({records: accountUsers}));

                                    // Dispatch Accounts Data to Your Accounts Store
                                    if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                                        localStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                    }
                                    dispatch(fetchYourAccountsSuccess({records: accounts}));

                                    // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                                    let selectedAccountUser = null;
                                    if (accountUsers.length > 0) {
                                        selectedAccountUser = accountUsers[0];
                                    }

                                    if (selectedAccountUser !== undefined && selectedAccountUser !== null && selectedAccountUser.account !== undefined && selectedAccountUser.account !== null) {
                                        // check that account is active and verified
                                        //console.log(`selectedAccountUser.account.isActive:${selectedAccountUser.account.isActive}`);
                                        //console.log(`selectedAccountUser.account.isVerified:${selectedAccountUser.account.isVerified}`);
                                        // check that account user is verified
                                        if (selectedAccountUser.isVerified === true) {
                                            if (selectedAccountUser.account.isActive === true) {
                                                if (selectedAccountUser.account.isVerified === true) {
                                                    let entityType = null;
                                                    let entityId = null;
                                                    let entityIrisId = null;

                                                    let servicesList = [];
                                                    try {
                                                        const now = Date.now()
                                                        const state = getState();
                                                        const orchestratorState = {...state.orchestrator};
                                                        const servicesLastUpdatedAt = orchestratorState.servicesLastUpdatedAt ? new Date(orchestratorState.servicesLastUpdatedAt) : null;
                                                        if (orchestratorState.services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                                                            const servicesRes = await axiosAuthenticated.get('/services', {
                                                                params: {
                                                                    page: 1,
                                                                    size: 100000,
                                                                    isDeleted: false
                                                                }
                                                            });
                                                            if (servicesRes && servicesRes.status === 200) {
                                                                const services = servicesRes.data.data;

                                                                const state2 = getState();
                                                                const orchestratorState2 = {...state2.orchestrator};
                                                                const featuresLastUpdatedAt = orchestratorState2.featuresLastUpdatedAt ? new Date(orchestratorState2.featuresLastUpdatedAt) : null;
                                                                let featuresList = [...orchestratorState2.features];
                                                                if (featuresList.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                                                                    const featuresRes = await axiosAuthenticated.get('/features', {
                                                                        params: {
                                                                            page: 1,
                                                                            size: 100000,
                                                                            isDeleted: false
                                                                        }
                                                                    });

                                                                    if (featuresRes && featuresRes.status === 200) {
                                                                        const features = featuresRes.data.data;
                                                                        featuresList = features;

                                                                        dispatch(getOrchestratorSuccess({
                                                                            features: features,
                                                                            featuresLastUpdatedAt: Date.now()
                                                                        }));
                                                                    }
                                                                }

                                                                const transformedServices = services.map((item) => {
                                                                    let serviceFeaturesList = [];
                                                                    if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                                                                        for (let i = 0; i < item.featureIds.length; i++) {
                                                                            const featureId = item.featureIds[i];
                                                                            let feature = featuresList.find(i => i.id === featureId);
                                                                            if (feature !== undefined && feature !== null) {
                                                                                serviceFeaturesList.push(feature);
                                                                            }
                                                                        }
                                                                    }

                                                                    return {
                                                                        ...item,
                                                                        features: serviceFeaturesList
                                                                    };
                                                                });

                                                                servicesList = transformedServices;

                                                                dispatch(getOrchestratorSuccess({
                                                                    services: transformedServices,
                                                                    servicesLastUpdatedAt: Date.now()
                                                                }));
                                                            }
                                                        } else if (orchestratorState.services.length > 0) {
                                                            servicesList = [...orchestratorState.services];
                                                        }
                                                    } catch (error) {
                                                        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
                                                    }

                                                    if (selectedAccountUser.isDriver === true && selectedAccountUser.driver !== undefined && selectedAccountUser.driver !== null) {
                                                        entityType = "DRIVER";
                                                        entityIrisId = selectedAccountUser.driver.irisId;
                                                        entityId = selectedAccountUser.id;
                                                    } else if (selectedAccountUser.account.isShipper === true && selectedAccountUser.account.shipper !== undefined && selectedAccountUser.account.shipper !== null) {
                                                        entityType = "SHIPPER";
                                                        entityIrisId = selectedAccountUser.account.shipper.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isCarrier === true && selectedAccountUser.account.carrier !== undefined && selectedAccountUser.account.carrier !== null) {
                                                        entityType = "CARRIER";
                                                        entityIrisId = selectedAccountUser.account.carrier.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isReceiver === true && selectedAccountUser.account.receiver !== undefined && selectedAccountUser.account.receiver !== null) {
                                                        entityType = "RECEIVER";
                                                        entityIrisId = selectedAccountUser.account.receiver.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isProducer === true && selectedAccountUser.account.producer !== undefined && selectedAccountUser.account.producer !== null) {
                                                        entityType = "PRODUCER";
                                                        entityIrisId = selectedAccountUser.account.producer.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    }

                                                    let featuresList = [];
                                                    if (selectedAccountUser.account !== undefined && selectedAccountUser.account !== null && selectedAccountUser.account.accountServices !== undefined && selectedAccountUser.account.accountServices !== null && selectedAccountUser.account.accountServices.length > 0) {
                                                        if (servicesList !== undefined && servicesList !== null && servicesList.length > 0) {
                                                            selectedAccountUser.account.accountServices.forEach((accountService) => {
                                                                let service = servicesList.find(item => item.name === accountService.serviceName);
                                                                if (service !== undefined && service !== null) {
                                                                    if (service.features !== undefined && service.features !== null && service.features.length > 0) {
                                                                        service.features.forEach((feature) => {
                                                                            if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === false) {
                                                                                featuresList.push(feature.name);
                                                                            } else if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === true && selectedAccountUser.account.isBeta === true) {
                                                                                featuresList.push(feature.name);
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }

                                                    // Complete Auto-Login
                                                    dispatch(authSuccess({
                                                        token: token,
                                                        email: email,
                                                        userName: userName,
                                                        userId: userId,
                                                        userProfileId: userProfileId,
                                                        firstName: firstName,
                                                        lastName: lastName,
                                                        profileImageId: profileImageId,
                                                        isLoading: false,
                                                        isAuthComplete: true,
                                                        isAuthenticated: true,
                                                        entityId: entityId,
                                                        entityIrisId: entityIrisId,
                                                        entityType: entityType,
                                                        error: null,
                                                        isAdmin: isAdmin,
                                                        isStaff: isStaff,
                                                        isVP: isVP,
                                                        isDirector: isDirector,
                                                        isManager: isManager,
                                                        featuresList: featuresList
                                                    }));
                                                } else {
                                                    dispatch(authFail({
                                                        errorType: 'NOT_ACTIVATED',
                                                        error: "The Account your User Account is associated with is not verified."
                                                    }));
                                                }
                                            } else {
                                                dispatch(authFail({
                                                    errorType: 'NOT_ACTIVATED',
                                                    error: "The Account your User Account is associated with is not activated."
                                                }));
                                            }
                                        } else {
                                            dispatch(authFail({
                                                errorType: 'NOT_ACTIVATED',
                                                error: "Your User Account is not verified."
                                            }));
                                        }
                                    } else {
                                        dispatch(authFail({
                                            errorType: 'NO_ACCOUNT',
                                            error: "You don't have access to any Accounts."
                                        }));
                                    }
                                } else {
                                    dispatch(authFail({
                                        errorType: 'NO_ACCOUNT',
                                        error: "You don't have access to any Accounts."
                                    }));
                                }
                            }
                        }
                    }
                } else {
                    dispatch(authFail({errorType: 'OTHER', error: 'Cannot get token'}));
                }
            }
        } catch (error) {
            // console.log(typeof (error.message));
            // console.log(typeof (error));
            let errorMessage = '';
            if (typeof error === 'string') {
                errorMessage = error;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status === 400) {
                errorMessage = "No user account could be found with the credentials you provided. Please try again... If you need assistance signing in, please give us a call and we will gladly assist you.";
                dispatch(authFail({errorType: 'NO_ACCOUNT', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status === 403) {
                errorMessage = "Your User Account is not activated...";
                dispatch(authFail({errorType: 'NOT_ACTIVATED', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status >= 400 && error.response.status <= 500) {
                errorMessage = "Credentials Not Valid...";
                dispatch(authFail({errorType: 'INVALID_CREDENTIALS', error: errorMessage}));
            } else if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && typeof (error.response.data.message) === 'string') {
                errorMessage = error.response.data.message;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else if (error.message !== undefined && typeof (error.message) === 'string') {
                errorMessage = error.message;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const passwordlessLogin = (phoneNumber) => {
    return async (dispatch) => {
        const passwordlessLoginPath = '/rest-auth/passwordless/login/';
        try {
            // Start Passwordless Login
            dispatch(authStart());

            // Passwordless Login
            const passwordlessLoginRes = await axios.post(passwordlessLoginPath, {
                phone: phoneNumber
            });

            if (passwordlessLoginRes && passwordlessLoginRes.status === 200) {
                dispatch(authSuccess({
                    isLoading: false,
                    error: null
                }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const passwordlessLoginValidation = (phoneNumber, code) => {
    return async (dispatch, getState) => {
        const passwordlessLoginValidationPath = '/rest-auth/passwordless/validate/';
        const accountUsersPath = '/accountUsers';

        try {
            // Start Login In
            dispatch(authStart());

            // Log In
            const loginRes = await axios.post(passwordlessLoginValidationPath, {phone: phoneNumber, code: code});

            if (loginRes && loginRes.status === 200) {
                const token = loginRes.data.access;
                const refreshToken = loginRes.data.refresh;

                if (token !== undefined && token !== null) {
                    const tokenDecoded = jwt(token);
                    const userId = tokenDecoded.user_id;
                    const userProfileId = tokenDecoded.user_profile_id;
                    const firstName = tokenDecoded.f_name;
                    const lastName = tokenDecoded.l_name;
                    const isUserActive = tokenDecoded.is_active;
                    const isStaff = tokenDecoded.is_staff;
                    const isAdmin = tokenDecoded.is_superuser;
                    const isVP = tokenDecoded.is_vp;
                    const isDirector = tokenDecoded.is_director;
                    const isManager = tokenDecoded.is_manager;
                    const email = tokenDecoded.email;
                    const userName = tokenDecoded.username;

                    //console.log(tokenDecoded);

                    // Get time the token will expire in seconds
                    const endDate = moment.unix(tokenDecoded.exp);

                    // need to set here so axiosAuthenticated works for getting documents
                    // Store Token to Local Storage
                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('userId', userId.toString());
                    localStorage.setItem('userProfileId', userProfileId.toString());
                    localStorage.setItem('expirationDate', endDate.toDate().toString());
                    localStorage.setItem('firstName', firstName.toString());
                    localStorage.setItem('lastName', lastName.toString());

                    const fileMetaData = await getFilesMetaData(userProfileId);
                    let {profileImageId} = EntityUtils.getUserFileIds(fileMetaData);
                    if (profileImageId !== undefined && profileImageId !== null) {
                        localStorage.setItem('profileImageId', profileImageId);
                    }

                    // check to see if the user isActive and if they aren't, don't let them login
                    if (isUserActive === false) {
                        dispatch(authFail({errorType: 'NOT_ACTIVATED', error: "Your User Account is not activated."}));
                    } else {
                        // if the user is staff then no need to get account users and accounts, just authenticate them
                        if (isStaff === true) {

                            // Complete Login
                            dispatch(authSuccess({
                                token: token,
                                email: email,
                                userName: userName,
                                userId: userId,
                                userProfileId: userProfileId,
                                firstName: firstName,
                                lastName: lastName,
                                profileImageId: profileImageId,
                                isLoading: false,
                                isAuthComplete: true,
                                isAuthenticated: true,
                                entityId: userId,
                                entityIrisId: 'IRIS-STAFF',
                                entityType: 'STAFF',
                                error: null,
                                isAdmin: isAdmin,
                                isStaff: isStaff,
                                isVP: isVP,
                                isDirector: isDirector,
                                isManager: isManager
                            }));
                        } else {
                            // Get Account Users Data
                            const accountUserRes = await axiosAuthenticated.get(accountUsersPath, {params: {userId: userId}});

                            if (accountUserRes && accountUserRes.status === 200) {
                                // Get Account User Data then transform
                                const accountUsersData = accountUserRes.data.data;
                                let accounts = [];
                                if (accountUsersData !== undefined && accountUsersData !== null && accountUsersData.length > 0) {

                                    let accountIds = accountUsersData.filter(item => item.accountId !== undefined && item.accountId !== null).map(item => {
                                        return item.accountId;
                                    });
                                    let accountUserIds = accountUsersData.map(item => {
                                        return item.id;
                                    });

                                    let driverDocuments = [];
                                    const driverDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                                        page: 1,
                                        size: 10000,
                                        entityId: [...accountUserIds]
                                    });
                                    if (driverDocumentsRes && driverDocumentsRes.status === 200) {
                                        driverDocuments = driverDocumentsRes.data.data;
                                    }

                                    const accountsRes = await axiosAuthenticated.post('/accounts/bulk/get', {
                                        page: 1,
                                        size: 10000,
                                        id: [...accountIds]
                                    });
                                    if (accountsRes && accountsRes.status === 200) {
                                        accounts = accountsRes.data.data;
                                    }

                                    const accountUsers = accountUsersData.map(item => {
                                        if (item.isDriver === true) {
                                            item = EntityUtils.getDriverInfo(item.id, item, driverDocuments);
                                        }

                                        let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                                        return {
                                            ...item,
                                            account: account
                                        };
                                    });

                                    // Dispatch Account Users Data to Your Accounts Users Store
                                    if (accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
                                        localStorage.setItem('yourAccountUsers', JSON.stringify(accountUsers));
                                    }
                                    dispatch(fetchYourAccountUsersSuccess({records: accountUsers}));

                                    // Dispatch Accounts Data to Your Accounts Store
                                    if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                                        localStorage.setItem('yourAccounts', JSON.stringify(accounts));
                                    }
                                    dispatch(fetchYourAccountsSuccess({records: accounts}));

                                    // TODO: for mvp we are assuming drivers belong to just one account but in the future we need to add an account switcher in case a driver or user belongs to more than one account
                                    let selectedAccountUser = null;
                                    if (accountUsers.length > 0) {
                                        selectedAccountUser = accountUsers[0];
                                    }

                                    if (selectedAccountUser !== undefined && selectedAccountUser !== null && selectedAccountUser.account !== undefined && selectedAccountUser.account !== null) {
                                        // check that account is active and verified
                                        //console.log(`selectedAccountUser.account.isActive:${selectedAccountUser.account.isActive}`);
                                        //console.log(`selectedAccountUser.account.isVerified:${selectedAccountUser.account.isVerified}`);
                                        // check that account user is verified
                                        if (selectedAccountUser.isVerified === true) {
                                            if (selectedAccountUser.account.isActive === true) {
                                                if (selectedAccountUser.account.isVerified === true) {
                                                    let entityType = null;
                                                    let entityId = null;
                                                    let entityIrisId = null;

                                                    let servicesList = [];
                                                    try {
                                                        const now = Date.now()
                                                        const state = getState();
                                                        const orchestratorState = {...state.orchestrator};
                                                        const servicesLastUpdatedAt = orchestratorState.servicesLastUpdatedAt ? new Date(orchestratorState.servicesLastUpdatedAt) : null;
                                                        if (orchestratorState.services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                                                            const servicesRes = await axiosAuthenticated.get('/services', {
                                                                params: {
                                                                    page: 1,
                                                                    size: 100000,
                                                                    isDeleted: false
                                                                }
                                                            });
                                                            if (servicesRes && servicesRes.status === 200) {
                                                                const services = servicesRes.data.data;

                                                                const state2 = getState();
                                                                const orchestratorState2 = {...state2.orchestrator};
                                                                const featuresLastUpdatedAt = orchestratorState2.featuresLastUpdatedAt ? new Date(orchestratorState2.featuresLastUpdatedAt) : null;
                                                                let featuresList = [...orchestratorState2.features];
                                                                if (featuresList.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                                                                    const featuresRes = await axiosAuthenticated.get('/features', {
                                                                        params: {
                                                                            page: 1,
                                                                            size: 100000,
                                                                            isDeleted: false
                                                                        }
                                                                    });

                                                                    if (featuresRes && featuresRes.status === 200) {
                                                                        const features = featuresRes.data.data;
                                                                        featuresList = features;

                                                                        dispatch(getOrchestratorSuccess({
                                                                            features: features,
                                                                            featuresLastUpdatedAt: Date.now()
                                                                        }));
                                                                    }
                                                                }

                                                                const transformedServices = services.map((item) => {
                                                                    let serviceFeaturesList = [];
                                                                    if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                                                                        for (let i = 0; i < item.featureIds.length; i++) {
                                                                            const featureId = item.featureIds[i];
                                                                            let feature = featuresList.find(i => i.id === featureId);
                                                                            if (feature !== undefined && feature !== null) {
                                                                                serviceFeaturesList.push(feature);
                                                                            }
                                                                        }
                                                                    }

                                                                    return {
                                                                        ...item,
                                                                        features: serviceFeaturesList
                                                                    };
                                                                });

                                                                servicesList = transformedServices;

                                                                dispatch(getOrchestratorSuccess({
                                                                    services: transformedServices,
                                                                    servicesLastUpdatedAt: Date.now()
                                                                }));
                                                            }
                                                        } else if (orchestratorState.services.length > 0) {
                                                            servicesList = [...orchestratorState.services];
                                                        }
                                                    } catch (error) {
                                                        logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
                                                    }

                                                    if (selectedAccountUser.isDriver === true && selectedAccountUser.driver !== undefined && selectedAccountUser.driver !== null) {
                                                        entityType = "DRIVER";
                                                        entityIrisId = selectedAccountUser.driver.irisId;
                                                        entityId = selectedAccountUser.id;
                                                    } else if (selectedAccountUser.account.isShipper === true && selectedAccountUser.account.shipper !== undefined && selectedAccountUser.account.shipper !== null) {
                                                        entityType = "SHIPPER";
                                                        entityIrisId = selectedAccountUser.account.shipper.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isCarrier === true && selectedAccountUser.account.carrier !== undefined && selectedAccountUser.account.carrier !== null) {
                                                        entityType = "CARRIER";
                                                        entityIrisId = selectedAccountUser.account.carrier.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isReceiver === true && selectedAccountUser.account.receiver !== undefined && selectedAccountUser.account.receiver !== null) {
                                                        entityType = "RECEIVER";
                                                        entityIrisId = selectedAccountUser.account.receiver.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    } else if (selectedAccountUser.account.isProducer === true && selectedAccountUser.account.producer !== undefined && selectedAccountUser.account.producer !== null) {
                                                        entityType = "PRODUCER";
                                                        entityIrisId = selectedAccountUser.account.producer.irisId;
                                                        entityId = selectedAccountUser.accountId;
                                                    }

                                                    let featuresList = [];
                                                    if (selectedAccountUser.account !== undefined && selectedAccountUser.account !== null && selectedAccountUser.account.accountServices !== undefined && selectedAccountUser.account.accountServices !== null && selectedAccountUser.account.accountServices.length > 0) {
                                                        if (servicesList !== undefined && servicesList !== null && servicesList.length > 0) {
                                                            selectedAccountUser.account.accountServices.forEach((accountService) => {
                                                                let service = servicesList.find(item => item.name === accountService.serviceName);
                                                                if (service !== undefined && service !== null) {
                                                                    if (service.features !== undefined && service.features !== null && service.features.length > 0) {
                                                                        service.features.forEach((feature) => {
                                                                            if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === false) {
                                                                                featuresList.push(feature.name);
                                                                            } else if (feature.isAvailable && feature.entityTypes.find(i => i === entityType) !== undefined && feature.isBeta === true && selectedAccountUser.account.isBeta === true) {
                                                                                featuresList.push(feature.name);
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }

                                                    // Complete Auto-Login
                                                    dispatch(authSuccess({
                                                        token: token,
                                                        email: email,
                                                        userName: userName,
                                                        userId: userId,
                                                        userProfileId: userProfileId,
                                                        firstName: firstName,
                                                        lastName: lastName,
                                                        profileImageId: profileImageId,
                                                        isLoading: false,
                                                        isAuthComplete: true,
                                                        isAuthenticated: true,
                                                        entityId: entityId,
                                                        entityIrisId: entityIrisId,
                                                        entityType: entityType,
                                                        error: null,
                                                        isAdmin: isAdmin,
                                                        isStaff: isStaff,
                                                        isVP: isVP,
                                                        isDirector: isDirector,
                                                        isManager: isManager,
                                                        featuresList: featuresList
                                                    }));
                                                } else {
                                                    dispatch(authFail({
                                                        errorType: 'NOT_ACTIVATED',
                                                        error: "The Account your User Account is associated with is not verified."
                                                    }));
                                                }
                                            } else {
                                                dispatch(authFail({
                                                    errorType: 'NOT_ACTIVATED',
                                                    error: "The Account your User Account is associated with is not activated."
                                                }));
                                            }
                                        } else {
                                            dispatch(authFail({
                                                errorType: 'NOT_ACTIVATED',
                                                error: "Your User Account is not verified."
                                            }));
                                        }
                                    } else {
                                        dispatch(authFail({
                                            errorType: 'NO_ACCOUNT',
                                            error: "You don't have access to any Accounts."
                                        }));
                                    }
                                } else {
                                    dispatch(authFail({
                                        errorType: 'NO_ACCOUNT',
                                        error: "You don't have access to any Accounts."
                                    }));
                                }
                            }
                        }
                    }
                } else {
                    dispatch(authFail({errorType: 'OTHER', error: 'Cannot get token'}));
                }
            }
        } catch (error) {
            // console.log(typeof (error.message));
            // console.log(typeof (error));
            let errorMessage = '';
            if (typeof error === 'string') {
                errorMessage = error;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status === 400) {
                errorMessage = "No user account could be found with the credentials you provided. Please try again... If you need assistance signing in, please give us a call and we will gladly assist you.";
                dispatch(authFail({errorType: 'NO_ACCOUNT', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status === 403) {
                errorMessage = "Your User Account is not activated...";
                dispatch(authFail({errorType: 'NOT_ACTIVATED', error: errorMessage}));
            } else if (error.response !== undefined && error.response.status >= 400 && error.response.status <= 500) {
                errorMessage = "Credentials Not Valid...";
                dispatch(authFail({errorType: 'INVALID_CREDENTIALS', error: errorMessage}));
            } else if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined && typeof (error.response.data.message) === 'string') {
                errorMessage = error.response.data.message;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else if (error.message !== undefined && typeof (error.message) === 'string') {
                errorMessage = error.message;
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const forgotPassword = (emailAddress) => {
    return async (dispatch) => {
        const resetPasswordPath = '/rest-auth/password/reset/';
        try {
            // Start Forgot Password
            dispatch(authStart());

            // Forgot Password
            const forgotPasswordRes = await axios.post(resetPasswordPath, {
                email: emailAddress
            });

            if (forgotPasswordRes && forgotPasswordRes.status === 200) {
                //console.log(forgotPasswordRes);
                dispatch(authSuccess({
                    isLoading: false,
                    error: null
                }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const changePassword = (password, confirmPassword, oldPassword) => {
    return async (dispatch) => {
        const changePasswordPath = '/rest-auth/password/change/';
        try {
            // Start Change Password
            dispatch(authStart());

            // Change Password
            const changePasswordRes = await axiosAuthenticated.post(changePasswordPath, {
                new_password1: password,
                new_password2: confirmPassword,
                old_password: oldPassword
            });

            if (changePasswordRes && changePasswordRes.status === 200) {
                //console.log(changePasswordRes);
                dispatch(authSuccess({
                    isLoading: false,
                    error: null
                }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

export const resetPassword = (uuid, token, password, confirmPassword) => {
    return async (dispatch) => {
        const resetPasswordPath = '/rest-auth/password/reset/confirm/';
        try {
            // Start Reset Password
            dispatch(authStart());

            // Reset Password
            const resetPasswordRes = await axios.post(resetPasswordPath, {
                uid: uuid,
                token: token,
                new_password1: password,
                new_password2: confirmPassword
            });

            if (resetPasswordRes && resetPasswordRes.status === 200) {
                //console.log(resetPasswordRes);
                dispatch(authSuccess({
                    isLoading: false,
                    error: null
                }));
            }
        } catch (error) {
            let errorMessage = null;
            if (error.response.status === 400) {
                if (hasErrorMessage(error)) {
                    errorMessage = error.response.data.message;
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                } else {
                    errorMessage = "An unexpected error has occurred.";
                    dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
                }
            } else {
                errorMessage = "An unexpected error has occurred.";
                dispatch(authFail({errorType: 'OTHER', error: errorMessage}));
            }

            logger.logReduxErrorEvent(error, `Error fetching Requests: ${errorMessage}`, true);
        }
    }
};

//#endregion

//#region Helper Methods

const hasErrorMessage = (error) => {
    return error.response.data !== undefined && error.response.data !== null && error.response.data.message !== undefined && error.response.data.message !== null;
};

//#endregion