import React from 'react';
import NoteList from "../../components/NoteList/NoteList";
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const LoadNotes = props => {
    const loadId = props.loadId;
    const stops = props.stops;
    const notes = props.notes;

    return (
        <>
            <NoteList tableTitle="Load Notes" noteList={notes.filter(note => note.entityId === loadId)} entityId={loadId} entityType="LOAD" />
            {stops.map(stop => (
                <div key={`stop-notes-fragment-${stop.id}`} style={{ marginTop: 24 }}>
                    <NoteList tableTitle={`Notes for Stop # ${stop.sequence + 1} - ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}${(stop.stopLocation !== undefined && stop.stopLocation !== null ? ' - ' + stop.stopLocation.name : '')}${(stop.stopLocation !== undefined && stop.stopLocation !== null) ? " - " + stop.stopLocation.address.city + ", " + stop.stopLocation.address.state : ''}`} noteList={notes.filter(note => note.entityId === stop.id)} entityId={stop.id} entityType="STOP" key={`stop-notes-${stop.id}`} />
                </div>
            ))}
        </>
    );
};

export default LoadNotes;