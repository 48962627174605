import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {sendLoadEvent} from "./loads";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";

//#region Claims Functions

export const fetchClaimsStart = () => {
    return {
        type: actionTypes.FETCH_CLAIMS_START
    }
};

export const fetchClaimsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIMS_SUCCESS,
        payload: payload
    }
};

export const fetchClaimsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIMS_FAIL,
        payload: payload
    }
};

export const clearClaims = () => {
    return {
        type: actionTypes.CLEAR_CLAIMS
    }
};

export const fetchClaimStart = () => {
    return {
        type: actionTypes.FETCH_CLAIM_START
    }
};

export const fetchClaimSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_SUCCESS,
        payload: payload
    }
};

export const fetchClaimFail = (payload) => {
    return {
        type: actionTypes.FETCH_CLAIM_FAIL,
        payload: payload
    }
};

export const clearClaim = () => {
    return {
        type: actionTypes.CLEAR_CLAIM
    }
};

export const addClaimStart = () => {
    return {
        type: actionTypes.ADD_CLAIM_START
    }
};

export const addClaimSuccess = () => {
    return {
        type: actionTypes.ADD_CLAIM_SUCCESS
    }
};

export const addClaimFail = (payload) => {
    return {
        type: actionTypes.ADD_CLAIM_FAIL,
        payload: payload
    }
};

export const addClaimErrorClear = () => {
    return {
        type: actionTypes.ADD_CLAIM_ERROR_CLEAR
    }
};

export const addClaimLoadingClear = () => {
    return {
        type: actionTypes.ADD_CLAIM_LOADING_CLEAR
    }
};

export const updateClaimStart = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_START
    }
};

export const updateClaimSuccess = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_SUCCESS
    }
};

export const updateClaimFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CLAIM_FAIL,
        payload: payload
    }
};

export const updateClaimErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_ERROR_CLEAR
    }
};

export const updateClaimLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CLAIM_LOADING_CLEAR
    }
};

export const cancelAddClaim = () => {
    return {
        type: actionTypes.CANCEL_ADD_CLAIM
    }
};

export const cancelUpdateClaim = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_CLAIM
    }
};

export const insertClaim = (payload) => {
    return {
        type: actionTypes.ADD_CLAIM,
        payload: payload
    }
};

export const changeClaim = (payload) => {
    return {
        type: actionTypes.UPDATE_CLAIM,
        payload: payload
    }
};

export const changeSingleClaim = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CLAIM,
        payload: payload
    }
};

export const removeClaim = (payload) => {
    return {
        type: actionTypes.REMOVE_CLAIM,
        payload: payload
    }
};

//#endregion

//#region Claims Methods

export const fetchClaims = (payload) => {
    return async (dispatch, getState) => {
        const claimsPath = '/claims';
        try {
            dispatch(fetchClaimsStart());

            const state = getState();
            const claimsState = {...state.claims};
            let pagination = {...claimsState.pagination};
            let searchParams = {...claimsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const claimsRes = await axiosAuthenticated.get(claimsPath, {params: {...searchParams}});

            if (claimsRes && claimsRes.status === 200) {
                const claims = claimsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                const transformedClaims = claims.map(item => {
                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    return {
                        ...item,
                        account: account
                    };
                });

                // Read total count from server
                pagination.total = claimsRes.data.totalCount;
                pagination.current = claimsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchClaimsSuccess({
                    records: transformedClaims,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchClaimsFail({error: error.message}));
        }
    }
};

export const fetchClaim = (id) => {
    return async (dispatch, getState) => {
        const claimsPath = '/claims';
        try {
            dispatch(fetchClaimStart());

            const claimsRes = await axiosAuthenticated.get(claimsPath + `/${id}`);
            if (claimsRes && claimsRes.status === 200) {
                const claim = claimsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(claim.accountId, accounts);

                const transformedClaim = {
                    ...claim,
                    account: account
                };

                dispatch(fetchClaimSuccess({record: transformedClaim}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchClaimFail({error: error.message}));
        }
    }
};

export const addClaim = (payload) => {
    return async (dispatch, getState) => {
        const claimsPath = '/claims';
        try {
            dispatch(addClaimStart());

            if (payload.count !== undefined && payload.count !== null) {
                payload.count = Number(payload.count);
            }

            if (payload.adjustment !== undefined && payload.adjustment !== null) {
                payload.adjustment = Number(payload.adjustment);
            }

            const claimsRes = await axiosAuthenticated.post(claimsPath, {...payload});
            if (claimsRes && claimsRes.status === 201) {
                const claim = claimsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(claim.accountId, accounts);

                let newClaim = {
                    ...claim,
                    account: account
                };

                const loadEventsRes = await axiosAuthenticated.post('/loadEvents', {
                    loadId: claim.loadId,
                    eventType: 'LOAD_UPDATED',
                    changeType: 'LOAD_CLAIM_OPENED'
                });

                // if there is a claim adjustment amount set then call the change type for the load updated load event so the invoice line items can be updated
                if (claim.adjustment !== undefined && claim.adjustment !== null && claim.adjustment > 0) {
                    const loadEventsRes = await axiosAuthenticated.post('/loadEvents', {
                        loadId: claim.loadId,
                        eventType: 'LOAD_UPDATED',
                        changeType: 'LOAD_CLAIM_ADJUSTMENT'
                    });
                }

                dispatch(insertClaim(newClaim));

                dispatch(addClaimSuccess());
                dispatch(addClaimLoadingClear());
                dispatch(addClaimErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addClaimFail({error: error.message}));
        }
    }
};

export const updateClaim = (claimId, payload, changeType) => {
    return async (dispatch, getState) => {
        const claimsPath = '/claims';
        try {
            dispatch(updateClaimStart());

            if (payload.count !== undefined && payload.count !== null) {
                payload.count = Number(payload.count);
            }

            if (payload.adjustment !== undefined && payload.adjustment !== null) {
                payload.adjustment = Number(payload.adjustment);
            }

            const claimsRes = await axiosAuthenticated.put(claimsPath + `/${claimId}`, {...payload});
            if (claimsRes && claimsRes.status === 200) {
                const claim = claimsRes.data;

                if (claim.isDeleted === true) {
                    dispatch(removeClaim(claim));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(claim.accountId, accounts);

                    let updatedClaim = {
                        ...claim,
                        account: account
                    };

                    dispatch(changeClaim(updatedClaim));
                    dispatch(changeSingleClaim(updatedClaim));

                    if (changeType !== undefined && changeType !== null) {
                        const loadEventsRes = await axiosAuthenticated.post('/loadEvents', {
                            loadId: claim.loadId,
                            eventType: 'LOAD_UPDATED',
                            changeType: changeType
                        });

                        if (changeType === 'LOAD_CLAIM_REOPENDED') {
                            // if the claim is re-opened, re-open the load as well
                            dispatch(sendLoadEvent({
                                loadId: claim.loadId,
                                eventType: 'LOAD_REOPENED'
                            }))
                        }
                    }
                }

                dispatch(updateClaimSuccess());
                dispatch(updateClaimLoadingClear());
                dispatch(updateClaimErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateClaimFail({error: error.message}));
        }
    }
};

//#endregion