import React, { useState, useMemo, useEffect } from 'react';
import {Col, Row, Button, Spin, Select, Alert, Input, Timeline, Empty, Drawer, Radio} from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import StringFormatter from '../../shared/stringFormatter';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormItemTriple from '../FormItemTriple/FormItemTriple';
import { isEmpty } from 'lodash';
import {DeleteOutlined, DownOutlined, PlusOutlined, RightOutlined} from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { ErrorMessage } from '@hookform/error-message';
import MomentDate from '../../shared/dateFormatter';
import moment from 'moment';
import classes from './NewLoadSchedule.module.scss';
import axios from 'axios';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import DataListRow from "../DataListRow/DataListRow";
import UploadFile from "../UploadFile/UploadFile";
import FormItemFile from "../FormItemFile/FormItemFile";

const momentDate = new MomentDate();

const { Option } = Select;
const { TextArea } = Input;
const stringFormatter = new StringFormatter();

const NewLoadSchedule = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;

    const shipperData = props.shipperData;
    const setShipperData = props.setShipperData;
    const pickUpStops = props.pickUpStops;
    const setPickUpStops = props.setPickUpStops;
    const dropOffStops = props.dropOffStops;
    const setDropOffStops = props.setDropOffStops;
    const completedSteps = props.completedSteps;
    const setCompletedSteps = props.setCompletedSteps;
    const nextStepAction = props.nextStepAction;
    const nextStepActionName = props.nextStepActionName;
    const dontRequireLoadSpecificInformation = props.dontRequireLoadSpecificInformation;
    const hideLoadSpecificInformation = props.hideLoadSpecificInformation;
    const docFiles = props.docFiles;
    const setDocFiles = props.setDocFiles;

    //#endregion
    //#region enums

    const appointmentTypeOptions = Enums.AppointmentTypes.selectListOptions();

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            pickUpLocationId: null,
            dropOffLocationId: null
        }
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            apptType: null,
            name: '',
            phone: '',
            phoneExt: '',
            apptInstructions: '',
            apptWindowStartDateTime: momentDate.getCurrentDateTimeInRange(),
            apptWindowEndDateTime: momentDate.getCurrentDateTimeInRange(),
            requestedDateTime: momentDate.getCurrentDateTimeInRange(),
            apptDateTime: momentDate.getCurrentDateTimeInRange(),
            apptNumber: '',
            apptCallAheadDateTime: momentDate.getCurrentDateTimeInRange(),
            pickUpNumber: '',
            purchaseOrderNumber: '',
            dropOffNumber: ''
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            shipperId: null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const locations = useSelector(state => state.locations.records);
    const isLocationsLoading = useSelector(state => state.locations.isLoading);
    const linkedLocations = useSelector(state => state.linkedLocations.records);
    const isLinkedLocationsLoading = useSelector(state => state.linkedLocations.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region useStates

    const [locationsList, setLocationsList] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showEditStopAppointment, setShowEditStopAppointment] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showNewPickUpStopLocation, setShowNewPickUpStopLocation] = useState(false);
    const [showNewDropOffStopLocation, setShowNewDropOffStopLocation] = useState(false);
    const [showFirstComeFirstServeDetails, setShowFirstComeFirstServeDetails] = useState(false);
    const [showHaveAppointmentDetails, setShowHaveAppointmentDetails] = useState(false);
    const [showNeedAppointmentDetails, setShowNeedAppointmentDetails] = useState(false);
    const [pickUpsEnabled, setPickUpsEnabled] = useState(false);
    const [dropOffsEnabled, setDropOffsEnabled] = useState(false);
    const [showEditShipper, setShowEditShipper] = useState(false);
    const [shippers, setShippers] = useState([]);
    const [isShippersLoading, setIsShippersLoading] = useState(false);
    const [bolDocFiles, setBOLDocFiles] = useState([]);
    const [showNewBOLNumber, setShowNewBOLNumber] = useState(false);
    const [showAddStopBOL, setShowAddStopBOL] = useState(false);

    //#endregion
    //#region toggles
    const onRemoveStopBOL = (stop, bolNumber, index) => {
        if (stop !== undefined && stop !== null) {
            let stopBOLNumberList = [];
            if (stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) {
                stopBOLNumberList = [...stop.bolNumbers];
                stopBOLNumberList.splice(index, 1);

                // also remove the BOL Files for this BOL Number for this stop
                let filteredArray = docFiles.filter(d => d.stopId !== stop.id && d.bolNumber !== bolNumber);
                setDocFiles([...filteredArray]);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops(updatedRecords);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the BOL Number
                        let existingDropOffList = [...dropOffStops];
                        const updatedDropOffValues = {
                            bolNumbers: [...stopBOLNumberList]
                        };

                        const updatedDropOffRecord = {
                            ...existingDropOffList[0],
                            ...updatedDropOffValues
                        };
                        const updatedDropOffRecords = [
                            ...existingDropOffList.slice(0, 0), // everything before current obj
                            updatedDropOffRecord,
                            ...existingDropOffList.slice(1), // everything after current obj
                        ];

                        setDropOffStops([...updatedDropOffRecords]);
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        bolNumbers: [...stopBOLNumberList]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const toggleAddStopBOL = () => {
        // reset form
        setBOLDocFiles([]);
        setShowNewBOLNumber(false);
        methods2.setValue(`existingBOLNumber`, null);
        methods2.setValue(`newBOLNumber`, '');
        methods2.clearErrors();

        setShowAddStopBOL(!showAddStopBOL);
    };

    const toggleNewPickUpStopLocation = () => {
        // reset form
        methods.setValue('pickUpLocationId', null);
        methods.clearErrors();

        setShowNewPickUpStopLocation(!showNewPickUpStopLocation);
    };

    const toggleNewDropOffStopLocation = () => {
        // reset form
        methods.setValue('dropOffLocationId', null);
        methods.clearErrors();

        setShowNewDropOffStopLocation(!showNewDropOffStopLocation);
    };

    const toggleEditShipper = () => {
        // reset form
        methods4.setValue('shipperId', null);
        methods4.clearErrors();

        setShowEditShipper(!showEditShipper);
    };

    const toggleEditStopAppointment = () => {
        setShowFirstComeFirstServeDetails(false);
        setShowHaveAppointmentDetails(false);
        setShowNeedAppointmentDetails(false);

        // reset form
        methods2.setValue('apptType', null);
        methods2.setValue('name', '');
        methods2.setValue('phone', '');
        methods2.setValue('phoneExt', '');
        methods2.setValue('apptInstructions', '');
        methods2.setValue('apptWindowStartDateTime', null);
        methods2.setValue('apptWindowEndDateTime', null);
        methods2.setValue('requestedDateTime', null);
        methods2.setValue('apptDateTime', null);
        methods2.setValue('apptNumber', '');
        methods2.setValue('apptCallAheadDateTime', null);
        methods2.setValue('pickUpNumber', '');
        methods2.setValue('purchaseOrderNumber', '');
        methods2.setValue('dropOffNumber', '');
        methods2.clearErrors();

        setShowEditStopAppointment(!showEditStopAppointment);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods3.setValue('locationId', null);
        methods3.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    //#endregion
    //#region onChanges

    const onChangeAppointmentType = (value) => {
        // console.log(`selected ${value}`);
        if (value !== undefined && value !== null) {
            if (value === "FIRST_COME_FIRST_SERVE") {
                setShowFirstComeFirstServeDetails(true);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            } else if (value === "HAVE_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(true);
                setShowNeedAppointmentDetails(false);
            } else if (value === "NEED_APPOINTMENT") {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(true);
            } else {
                setShowFirstComeFirstServeDetails(false);
                setShowHaveAppointmentDetails(false);
                setShowNeedAppointmentDetails(false);
            }
        }
    };

    //#endregion
    //#region submits and validators

    const validateStops = (pickUps, dropOffs, isSubmitting = false) => {
        let hasErrors = false;
        methods.clearErrors();

        // console.log(pickUps);
        // console.log(dropOffs);

        if (pickUps !== undefined && pickUps !== null && pickUps.length > 0 && dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
            pickUps.forEach((stop, stopIndex, arr) => {
                if ((stop.apptType === "FIRST_COME_FIRST_SERVE" && (stop.apptWindowStartDateTime === undefined || stop.apptWindowStartDateTime === null))
                    ||
                    ((stop.apptType === "HAVE_APPOINTMENT") && !stop.apptDateTime)
                    ||
                    ((stop.apptType === "NEED_APPOINTMENT") && !stop.requestedDateTime)
                ) {
                    if (isSubmitting === true) {
                        methods.setError(`pickUpStops-${stopIndex}`, { type: 'requiredField', message: `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Pick-Up Date.` });
                    }
                    hasErrors = true;
                }

                if (stop.newBOLNumber === undefined || stop.newBOLNumber === null) {
                    if (isSubmitting === true) {
                        methods.setError(`pickUpStops-${stopIndex}`, { type: 'requiredField', message: `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a BOL Number.` });
                    }
                    hasErrors = true;
                }

                if ((stop.apptType === undefined || stop.apptType === null)) {
                    if (isSubmitting === true) {
                        methods.setError(`pickUpStops-${stopIndex}`, { type: 'missingApptType', message: `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing Appointment Information.` });
                    }
                    hasErrors = true;
                }

                if ((stop.pickUpNumber === undefined || stop.pickUpNumber === null) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        methods.setError(`pickUpStops-${stopIndex}`, { type: 'missingPickUpNumber', message: `Pick-Up${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Pick-Up Number.` });
                    }
                    hasErrors = true;
                }

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (stop1.apptWindowStartDateTime !== undefined && stop1.apptWindowStartDateTime !== null && stop2.apptWindowStartDateTime !== undefined && stop2.apptWindowStartDateTime !== null) {
                        if (stop2.apptWindowStartDateTime < stop1.apptWindowStartDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.apptWindowStartDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.apptWindowStartDateTime, stop2.timeZone);
                                if (moment(stop2DateString) < moment(stop1DateString)) {
                                    methods.setError(`pickUpStops-${stopIndex}`, { type: 'validate', message: `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`pickUpStops-${stopIndex}`, { type: 'validate', message: `The date for Pick-Up ${stopIndex + 1} cannot be before the date for Pick-Up ${stopIndex}.` });
                                hasErrors = true;
                            }
                        }
                    }
                }
            });

            dropOffs.forEach((stop, stopIndex, arr) => {
                if ((stop.apptType === "FIRST_COME_FIRST_SERVE" && (stop.apptWindowStartDateTime === undefined || stop.apptWindowStartDateTime === null))
                    ||
                    ((stop.apptType === "HAVE_APPOINTMENT") && !stop.apptDateTime)
                    ||
                    ((stop.apptType === "NEED_APPOINTMENT") && !stop.requestedDateTime)
                ) {
                    if (isSubmitting === true) {
                        methods.setError(`dropOffStops-${stopIndex}`, { type: 'requiredField', message: `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Drop-Off Date.` });
                    }
                    hasErrors = true;
                }

                if ((stop.apptType === undefined || stop.apptType === null)) {
                    if (isSubmitting === true) {
                        methods.setError(`dropOffStops-${stopIndex}`, { type: 'missingApptType', message: `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing Appointment Information.` });
                    }
                    hasErrors = true;
                }

                /*
                if ((stop.dropOffNumber === undefined || stop.dropOffNumber === null) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        methods.setError(`dropOffStops-${stopIndex}`, { type: 'missingDropOffNumber', message: `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Drop-Off Number.` });
                    }
                    hasErrors = true;
                }
                if ((stop.purchaseOrderNumber === undefined || stop.purchaseOrderNumber === null) && (dontRequireLoadSpecificInformation === false && hideLoadSpecificInformation === false)) {
                    if (isSubmitting === true) {
                        methods.setError(`dropOffStops-${stopIndex}`, { type: 'missingPurchaseOrderNumber', message: `Drop-Off${arr.length > 1 ? ' ' + (stopIndex + 1) : ''} is missing a Customer Purchase Order Number.` });
                    }
                    hasErrors = true;
                }
                */

                // validate date to make sure stops are in order
                if (stopIndex > 0) {
                    let stop1 = { ...arr[stopIndex - 1] };
                    let stop2 = { ...stop };
                    if (stop1.apptWindowStartDateTime !== undefined && stop1.apptWindowStartDateTime !== null && stop2.apptWindowStartDateTime !== undefined && stop2.apptWindowStartDateTime !== null) {
                        if (stop2.apptWindowStartDateTime < stop1.apptWindowStartDateTime) {
                            if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                                // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                                let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.apptWindowStartDateTime, stop1.timeZone);
                                let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.apptWindowStartDateTime, stop2.timeZone);
                                if (moment(stop2DateString).format("YYYY-MM-DD") < moment(stop1DateString).format("YYYY-MM-DD")) {
                                    methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.` });
                                    hasErrors = true;
                                }
                            } else {
                                methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off ${stopIndex + 1} cannot be before the date for Drop-Off ${stopIndex}.` });
                                hasErrors = true;
                            }
                        }
                    }
                } else if (stopIndex === 0) {
                    let stop1 = { ...pickUps[pickUps.length - 1] };
                    let stop2 = { ...stop };
                    // if (stop1.apptWindowStartDateTime !== undefined && stop1.apptWindowStartDateTime !== null && stop2.apptWindowStartDateTime !== undefined && stop2.apptWindowStartDateTime !== null) {
                    //     if (stop2.apptWindowStartDateTime < stop1.apptWindowStartDateTime) {
                    //         if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                    //             // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                    //             let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                    //             let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                    //             if (moment(stop2DateString).format("YYYY-MM-DD") < moment(stop1DateString).format("YYYY-MM-DD")) {
                    //                 methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                    //                 hasErrors = true;
                    //             }
                    //         } else if (stop1.apptType === 'FIRST_COME_FIRST_SERVE' || stop2.apptType === 'FIRST_COME_FIRST_SERVE') {
                    //             if (moment(stop2.apptWindowStartDateTime).format("YYYY-MM-DD") < moment(stop1.apptWindowStartDateTime).format("YYYY-MM-DD")) {
                    //                 methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                    //                 hasErrors = true;
                    //             }
                    //         } else if (stop1.apptType === 'HAVE_APPOINTMENT' || stop2.apptType === 'HAVE_APPOINTMENT') {
                    //             if (moment(stop2.apptDateTime).format("YYYY-MM-DD") < moment(stop1.apptDateTime).format("YYYY-MM-DD")) {
                    //                 methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                    //                 hasErrors = true;
                    //             }
                    //         }
                    //     }
                    // }
                    if (stop1.apptType === 'NEED_APPOINTMENT' || stop2.apptType === 'NEED_APPOINTMENT') {
                        // if appointment type is need appointment then just make sure the requestedDateTime is the same day or later, time doesnt matter
                        let stop1DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop1.requestedDateTime, stop1.timeZone);
                        let stop2DateString = new MomentDate().fromUtcToTimeZoneDateOnly(stop2.requestedDateTime, stop2.timeZone);
                        if (moment(stop2DateString).format("YYYY-MM-DD") < moment(stop1DateString).format("YYYY-MM-DD")) {
                            methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                            hasErrors = true;
                        }
                    } else if (stop1.apptType === 'FIRST_COME_FIRST_SERVE' || stop2.apptType === 'FIRST_COME_FIRST_SERVE') {
                        if (moment(stop2.apptWindowStartDateTime).format("YYYY-MM-DD") < moment(stop1.apptWindowStartDateTime).format("YYYY-MM-DD")) {
                            methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                            hasErrors = true;
                        }
                    } else if (stop1.apptType === 'HAVE_APPOINTMENT' || stop2.apptType === 'HAVE_APPOINTMENT') {
                        if (moment(stop2.apptDateTime).format("YYYY-MM-DD HH:mm:ss") < moment(stop1.apptDateTime).format("YYYY-MM-DD HH:mm:ss")) {
                            methods.setError(`dropOffStops-${stopIndex}`, { type: 'validate', message: `The date for Drop-Off${dropOffs.length > 1 ? ' ' + (stopIndex + 1) : ''} cannot be before the date for Pick-Up${pickUps.length > 1 ? ' ' + (pickUps.length) : ''}.` });
                            hasErrors = true;
                        }
                    }
                }
            });
        } else {
            if (pickUps === undefined || pickUps === null || pickUps.length === 0) {
                if (isSubmitting === true) {
                    methods.setError('pickUpStops', { type: 'required', message: 'There must be at least 1 Pick-Up.' });
                }
                hasErrors = true;
            }
            if (dropOffs === undefined || dropOffs === null || dropOffs.length === 0) {
                if (isSubmitting === true) {
                    methods.setError('dropOffStops', { type: 'required', message: 'There must be at least 1 Drop-Off.' });
                }
                hasErrors = true;
            }
        }

        // console.log(`hasErrors: ${hasErrors}`);

        return !hasErrors;
    };

    const saveStops = (pickUps, dropOffs) => {
        let updatedPickUps = [];
        let updatedDropOffs = [];
        if (pickUps !== undefined && pickUps !== null && pickUps.length > 0) {
            pickUps.forEach((stop, stopIndex, arr) => {
                updatedPickUps.push({
                    ...stop,
                    sequence: stopIndex,
                    id: stopIndex,
                    stopTypeSequence: stopIndex
                });
            });
        }

        if (dropOffs !== undefined && dropOffs !== null && dropOffs.length > 0) {
            dropOffs.forEach((stop, stopIndex, arr) => {
                updatedDropOffs.push({
                    ...stop,
                    sequence: stopIndex + pickUps.length,
                    id: stopIndex + pickUps.length,
                    stopTypeSequence: stopIndex
                });
            });
        }

        setPickUpStops(updatedPickUps);
        setDropOffStops(updatedDropOffs);
    };

    const onEditShipper = (data) => {
        const shipperId = data.shipperId;

        let payload = {};
        if (shipperId !== undefined && shipperId !== null) {
            payload.shipperId = shipperId;
            if (shippers !== undefined && shippers !== null && shippers.length > 0) {
                let selectedShipperAccount = shippers.find(s => s.id === shipperId);
                if (selectedShipperAccount !== null && selectedShipperAccount !== undefined) {
                    payload.shipperName = selectedShipperAccount.name;
                }
            }

            setShipperData({ ...payload });
        }

        toggleEditShipper();
    };

    const onEditStopLocation = (data) => {
        const stopLocationId = data.locationId;
        if (stopLocationId !== undefined && stopLocationId !== null) {
            let stopLocation = locationsList.find(l => l.id === stopLocationId);
            if (stopLocation !== undefined && stopLocation !== null) {
                const updatedValues = {
                    stopLocation: stopLocation,
                    stopLocationId: stopLocationId,
                    timeZone: stopLocation.timeZone
                };

                if (selectedRecord !== undefined && selectedRecord !== null) {
                    if (stopLocation.lumperFee !== undefined && stopLocation.lumperFee !== null && stopLocation.lumperFee > 0) {
                        updatedValues.lumperFee = stopLocation.lumperFee;
                        updatedValues.hasLumperFee = true;
                    } else {
                        updatedValues.lumperFee = null;
                        updatedValues.hasLumperFee = false;
                    }
                    if (stopLocation.specialInstructions !== undefined && stopLocation.specialInstructions !== null) {
                        updatedValues.specialInstructions = stopLocation.specialInstructions;
                    } else {
                        updatedValues.specialInstructions = '';
                    }
                    if (stopLocation.pointOfContact !== undefined && stopLocation.pointOfContact !== null) {
                        updatedValues.apptPointOfContact = stopLocation.pointOfContact;
                    } else {
                        updatedValues.apptPointOfContact = null;
                    }

                    updatedValues.apptType = null;

                    if (selectedRecord.stopType === 'PICK_UP') {
                        let existingList = [...pickUpStops];
                        const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                        // only update if the record exists in the list
                        if (index !== -1) {
                            const updatedRecord = {
                                ...existingList[index],
                                ...updatedValues
                            };
                            const updatedRecords = [
                                ...existingList.slice(0, index), // everything before current obj
                                updatedRecord,
                                ...existingList.slice(index + 1), // everything after current obj
                            ];

                            saveStops(updatedRecords, dropOffStops);
                        }
                    } else if (selectedRecord.stopType === 'DROP_OFF') {
                        let existingList = [...dropOffStops];
                        const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                        // only update if the record exists in the list
                        if (index !== -1) {
                            const updatedRecord = {
                                ...existingList[index],
                                ...updatedValues
                            };
                            const updatedRecords = [
                                ...existingList.slice(0, index), // everything before current obj
                                updatedRecord,
                                ...existingList.slice(index + 1), // everything after current obj
                            ];

                            saveStops(pickUpStops, updatedRecords);
                        }
                    }

                    toggleEditStopLocation();
                    setSelectedRecord(null);
                } else {
                    return;
                }
            }
        }
    };

    const onEditStopAppointment = (data) => {
        const apptType = data.apptType;
        const name = data.name;
        const phone = data.phone;
        const phoneExt = data.phoneExt;
        const apptInstructions = data.apptInstructions;
        const apptWindowStartDateTime = data.apptWindowStartDateTime;
        const apptWindowEndDateTime = data.apptWindowEndDateTime;
        const apptDateTime = data.apptDateTime;
        const apptNumber = data.apptNumber;
        const requestedDateTime = data.requestedDateTime;
        const apptCallAheadDateTime = data.apptCallAheadDateTime;
        const pickUpNumber = data.pickUpNumber;
        const purchaseOrderNumber = data.purchaseOrderNumber;
        const dropOffNumber = data.dropOffNumber;

        const apptPointOfContact = {
            name: name,
            phone: phone
        };

        if (phoneExt !== undefined && phoneExt !== null && phoneExt !== '') {
            apptPointOfContact.phoneExt = phoneExt;
        }

        const updatedValues = {
            apptType: apptType,
            apptPointOfContact: apptPointOfContact
        };

        if (apptInstructions !== undefined && apptInstructions !== null && apptInstructions !== '') {
            updatedValues.apptInstructions = apptInstructions;
        }

        if (pickUpNumber !== undefined && pickUpNumber !== null) {
            updatedValues.pickUpNumber = pickUpNumber;
        }
        if (purchaseOrderNumber !== undefined && purchaseOrderNumber !== null) {
            updatedValues.purchaseOrderNumber = purchaseOrderNumber;
        }
        if (dropOffNumber !== undefined && dropOffNumber !== null) {
            updatedValues.dropOffNumber = dropOffNumber;
        }

        if (apptType === 'FIRST_COME_FIRST_SERVE') {
            /*
            if (apptWindowEndDateTime < apptWindowStartDateTime) {
                // console.log(apptWindowEndDateTime);
                // console.log(apptWindowStartDateTime);
                methods2.setError("apptWindowEndDateTime", { type: "validate", message: "The End Date/Time cannot be before the Start Date/Time." });

                return;
            } else {
                methods2.clearErrors('apptWindowEndDateTime');
            }
             */
            updatedValues.apptWindowStartDateTime = apptWindowStartDateTime;
            // updatedValues.apptWindowEndDateTime = apptWindowEndDateTime;
            updatedValues.requestedDateTime = apptWindowStartDateTime;
        } else if (apptType === 'HAVE_APPOINTMENT') {
            updatedValues.apptDateTime = apptDateTime;
            if (apptNumber !== undefined && apptNumber !== null) {
                updatedValues.apptNumber = apptNumber;
            }
            updatedValues.requestedDateTime = apptDateTime;
        } else if (apptType === 'NEED_APPOINTMENT') {
            updatedValues.apptCallAheadDateTime = apptCallAheadDateTime;
            updatedValues.requestedDateTime = requestedDateTime;
        }

        if (selectedRecord !== undefined && selectedRecord !== null) {
            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(updatedRecords, dropOffStops);
                    validateStops(updatedRecords, dropOffStops);
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        ...updatedValues
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    saveStops(pickUpStops, updatedRecords);
                    validateStops(pickUpStops, updatedRecords);
                }
            }

            toggleEditStopAppointment();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onSubmit = () => {
        let isValid = validateStops(pickUpStops, dropOffStops, true);
        if (isValid === true) {
            saveStops(pickUpStops, dropOffStops);
            setCompletedSteps({ ...completedSteps, schedule: true });
            nextStepAction('equipment');
        } else {
            return;
        }
    };
    console.log('pickUpStops', pickUpStops)
    console.log('dropOffStops', dropOffStops)
    //#endregion
    //#region useEffects and useMemos

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopAppointment === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.apptType !== undefined && selectedRecord.apptType !== null) {
                    methods2.setValue('apptType', selectedRecord.apptType);

                    if (selectedRecord.stopType !== undefined && selectedRecord.stopType !== null) {
                        if (selectedRecord.stopType === "PICK_UP") {
                            if (selectedRecord.pickUpNumber !== undefined && selectedRecord.pickUpNumber !== null) {
                                methods2.setValue('pickUpNumber', selectedRecord.pickUpNumber);
                            }
                        } else if (selectedRecord.stopType === "DROP_OFF") {
                            if (selectedRecord.purchaseOrderNumber !== undefined && selectedRecord.purchaseOrderNumber !== null) {
                                methods2.setValue('purchaseOrderNumber', selectedRecord.purchaseOrderNumber);
                            }
                            if (selectedRecord.dropOffNumber !== undefined && selectedRecord.dropOffNumber !== null) {
                                methods2.setValue('dropOffNumber', selectedRecord.dropOffNumber);
                            }
                        }
                    }

                    if (selectedRecord.apptType === "FIRST_COME_FIRST_SERVE") {
                        setShowFirstComeFirstServeDetails(true);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    } else if (selectedRecord.apptType === "HAVE_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(true);
                        setShowNeedAppointmentDetails(false);
                    } else if (selectedRecord.apptType === "NEED_APPOINTMENT") {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(true);
                    } else {
                        setShowFirstComeFirstServeDetails(false);
                        setShowHaveAppointmentDetails(false);
                        setShowNeedAppointmentDetails(false);
                    }
                }
                if (selectedRecord.apptPointOfContact !== undefined && selectedRecord.apptPointOfContact !== null) {
                    if (selectedRecord.apptPointOfContact.name !== undefined && selectedRecord.apptPointOfContact.name !== null) {
                        methods2.setValue('name', selectedRecord.apptPointOfContact.name);
                    }
                    if (selectedRecord.apptPointOfContact.phone !== undefined && selectedRecord.apptPointOfContact.phone !== null) {
                        methods2.setValue('phone', selectedRecord.apptPointOfContact.phone);
                    }
                    if (selectedRecord.apptPointOfContact.phoneExt !== undefined && selectedRecord.apptPointOfContact.phoneExt !== null) {
                        methods2.setValue('phoneExt', selectedRecord.apptPointOfContact.phoneExt);
                    }
                }
                if (selectedRecord.apptInstructions !== undefined && selectedRecord.apptInstructions !== null) {
                    methods2.setValue('apptInstructions', selectedRecord.apptInstructions);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopAppointment, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showFirstComeFirstServeDetails === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.apptType !== undefined && selectedRecord.apptType !== null && selectedRecord.apptType === "FIRST_COME_FIRST_SERVE") {
                    if (selectedRecord.apptWindowStartDateTime !== undefined && selectedRecord.apptWindowStartDateTime !== null) {
                        methods2.setValue(`apptWindowStartDateTime`, selectedRecord.apptWindowStartDateTime);
                    }
                    /*
                    if (selectedRecord.apptWindowEndDateTime !== undefined && selectedRecord.apptWindowEndDateTime !== null) {
                        methods2.setValue(`apptWindowEndDateTime`, selectedRecord.apptWindowEndDateTime);
                    }
                     */
                }
            }
        }

        return () => isSubscribed = false;
    }, [showFirstComeFirstServeDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showHaveAppointmentDetails === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.apptType !== undefined && selectedRecord.apptType !== null && selectedRecord.apptType === "HAVE_APPOINTMENT") {
                    if (selectedRecord.apptNumber !== undefined && selectedRecord.apptNumber !== null) {
                        methods2.setValue(`apptNumber`, selectedRecord.apptNumber);
                    }
                    if (selectedRecord.apptDateTime !== undefined && selectedRecord.apptDateTime !== null) {
                        methods2.setValue(`apptDateTime`, selectedRecord.apptDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showHaveAppointmentDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showNeedAppointmentDetails === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.apptType !== undefined && selectedRecord.apptType !== null && selectedRecord.apptType === "NEED_APPOINTMENT") {
                    if (selectedRecord.apptCallAheadDateTime !== undefined && selectedRecord.apptCallAheadDateTime !== null) {
                        methods2.setValue(`apptCallAheadDateTime`, selectedRecord.apptCallAheadDateTime);
                    }
                    if (selectedRecord.requestedDateTime !== undefined && selectedRecord.requestedDateTime !== null) {
                        methods2.setValue(`requestedDateTime`, selectedRecord.requestedDateTime);
                    }
                }
            }
        }

        return () => isSubscribed = false;
    }, [showNeedAppointmentDetails, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.stopLocationId !== undefined && selectedRecord.stopLocationId !== null) {
                    methods3.setValue('locationId', selectedRecord.stopLocationId);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (shipperData !== undefined && shipperData !== null && !isEmpty(shipperData)) {
                if (shipperData.shipperId !== undefined && shipperData.shipperId !== null) {
                    setPickUpsEnabled(true);
                } else {
                    setPickUpsEnabled(false);
                }
            }
        }

        return () => isSubscribed = false;
    }, [shipperData]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditShipper === true && shipperData !== undefined && shipperData !== null && !isEmpty(shipperData)) {
                if (shipperData.shipperId !== undefined && shipperData.shipperId !== null) {
                    methods4.setValue('shipperId', shipperData.shipperId);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditShipper, shipperData]);

    useMemo(() => {
        if (shipperData !== undefined && shipperData !== null && shipperData.shipperId !== undefined && shipperData.shipperId !== null) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: shipperData.shipperId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: shipperData.shipperId, isDeleted: false }));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
            }
        }
    }, [shipperData, entityType, entityId]);

    useMemo(() => {
        if (dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            if (dropOffStops.find(s => s.stopLocationId !== undefined && s.stopLocationId !== null) !== undefined) {
                setDropOffsEnabled(true);
            }
        }
    }, []);

    useEffect(() => {
        const { cancel, token } = axios.CancelToken.source();

        if (entityType === 'STAFF') {
            if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                setIsShippersLoading(true);
                const shipperAccounts = accounts.filter(account => account.isShipper === true);
                if (shipperAccounts !== undefined && shipperAccounts !== null && shipperAccounts.length > 0) {
                    const shipperAccountsTransformed = shipperAccounts.map((item) => {
                        return {
                            id: item.id,
                            name: item.name
                        };
                    });
                    setShippers(shipperAccountsTransformed);
                    setIsShippersLoading(false);
                }
                else {
                    setShippers([]);
                    setIsShippersLoading(false);
                }
            }
        } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
            setIsShippersLoading(true);
            axiosAuthenticated.get('/linkedAccounts', { params: { page: 1, size: 10000, isShipper: true, isDeleted: false, accountId: entityId }, cancelToken: token }).then((linkedAccountsRes) => {
                if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                    const linkedShippers = linkedAccountsRes.data.data;
                    if (linkedShippers !== undefined && linkedShippers !== null && linkedShippers.length > 0) {
                        const linkedShippersTransformed = linkedShippers.map((item) => {
                            return {
                                id: item.linkedAccountId,
                                name: item.name ? item.name : (item.linkedAccount ? item.linkedAccount.name : '')
                            };
                        });
                        setShippers(linkedShippersTransformed);
                        setIsShippersLoading(false);
                    } else {
                        setShippers([]);
                        setIsShippersLoading(false);
                    }
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }

                setIsShippersLoading(false);
            });;
        }

        return () => {
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
                setIsShippersLoading(false);
            }
        };
    }, [entityType, entityId, accounts]);

    useMemo(() => {
        if (locations !== undefined && locations !== null && linkedLocations !== undefined && linkedLocations !== null) {
            let transformedLocations = [];
            if (locations.length > 0) {
                locations.forEach((item) => {
                    transformedLocations.push({
                        ...item
                    });
                });
            }
            if (linkedLocations.length > 0) {
                linkedLocations.forEach((item) => {
                    transformedLocations.push({
                        ...item.location,
                        name: item.name ? item.name : (item.location ? item.location.name : null)
                    });
                });
            }

            // if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            //     let locationIds = [];
            //     pickUpStops.forEach((item) => {
            //         if (transformedLocations.find(loc => loc.id === item.stopLocationId) === undefined) {
            //             locationIds.push(item.stopLocationId);
            //         }
            //     });
            //     dropOffStops.forEach((item) => {
            //         if (transformedLocations.find(loc => loc.id === item.stopLocationId) === undefined) {
            //             locationIds.push(item.stopLocationId);
            //         }
            //     });
            //     if (locationIds !== undefined && locationIds !== null && locationIds.length > 0) {
            //         axiosAuthenticated.post('/locations/bulk/get', { page: 1, size: 100000, isDeleted: false, id: [...locationIds] }).then((locationsRes) => {
            //             let additionalLocations = locationsRes.data.data;
            //             if (additionalLocations !== undefined && additionalLocations !== null && additionalLocations.length > 0) {
            //                 additionalLocations.forEach((item) => {
            //                     transformedLocations.push({
            //                         ...item
            //                     });
            //                 });
            //             }
            //         });

            //     }
            // }

            setLocationsList(transformedLocations);
        }
    }, [locations, linkedLocations]);

    useMemo(() => {
        methods.clearErrors();
    }, []);

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (locationsList !== undefined && locationsList !== null && locationsList.length > 0) {
            // let usedLocationIds = [];
            // if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) {
            //     pickUpStops.forEach((pickUpStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (pickUpStop.id !== stop.id) {
            //                 if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                     usedLocationIds.push(pickUpStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                 usedLocationIds.push(pickUpStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // if (dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            //     dropOffStops.forEach((dropOffStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (dropOffStop.id !== stop.id) {
            //                 if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                     usedLocationIds.push(dropOffStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                 usedLocationIds.push(dropOffStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            locationsList.forEach((item) => {
                // if (!usedLocationIds.includes(item.id)) {
                options.push(<Option value={item.id} key={item.id}>{`${item.name ? item.name : ''} ${item.address !== undefined && item.address !== null ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
                // }
            });
        }

        return options;
    };

    const getStopDateForType = (stop) => {
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            return stop.apptWindowStartDateTime;
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            return stop.apptDateTime;
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            return stop.requestedDateTime;
        }
    }

    const getStopLocationDisplay = (stop, stopIndex) => {
        console.log("stop", stopIndex, stop);

        return (
            <DataCollapsableRow
                isDisabled={(((stop && stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop && stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)}
                isCollapsable={(((stop && stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop && stop.stopType === 'PICK_UP' && pickUpsEnabled === false) || !(stop && stop.apptType !== undefined && stop.apptType !== null)) ? false : true)}
                isCollapsed={(stop && stop.stopLocationId) || !(stop && stop.apptType !== undefined && stop.apptType !== null) ? false : true}
                title={stop && stop.stopLocation && stop.stopLocation.name ? stop.stopLocation.name : (<Button style={{ paddingLeft: 0, fontSize: 18, color: '#000000', fontWeight: 500 }} type='link' disabled={(((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>Select a {stop.stopType === "PICK_UP" ? "Pick-Up" : "Drop-Off"} Location <DownOutlined /></Button>)}
                titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                titleHasAction={!(stop && stop.stopLocation && stop.stopLocation.name)}
                subtitle={stop && stop.stopLocation && stop.stopLocation.address ? (<span style={{ fontSize: 14 }}>{stringFormatter.toFormattedAddress(stop.stopLocation.address)}</span>) : null}
                header={(stop && stop.apptType !== undefined && stop.apptType !== null) ? (
                    <>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{(stop && stop.stopType ? `${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}` : '')}  </span>
                        <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>
                            {stop.apptType === "FIRST_COME_FIRST_SERVE" && stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.apptWindowStartDateTime, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                        </span>
                    </>
                ) : (stop && stop.stopType ? <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{`${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}`}</span> : null)}
                extra={(stop && stop.stopLocationId) ? (
                    <>
                        <Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button>
                        {(stop.stopType === 'PICK_UP' && pickUpStops.length > 1) ? (
                            <Button style={{ fontWeight: 'bold' }}
                                type="link"
                                onClick={() => {
                                    let existingList = [...pickUpStops];
                                    existingList.splice(stopIndex, 1);
                                    setPickUpStops([...existingList]);
                                }}
                            >
                                remove
                            </Button>
                        ) : null}
                        {(stop.stopType === 'DROP_OFF' && dropOffStops.length > 1) ? (
                            <Button style={{ fontWeight: 'bold' }}
                                type="link"
                                onClick={() => {
                                    let existingList = [...dropOffStops];
                                    existingList.splice(stopIndex, 1);
                                    setDropOffStops([...existingList]);
                                }}
                            >
                                remove
                            </Button>
                        ) : null}
                    </>
                ) : null}
            >
                <div style={{ marginTop: 12 }}>
                    {(stop.apptType !== undefined && stop.apptType !== null) ? (
                        <>
                            <Row gutter={[18, 18]}>
                                <Col span={24}>
                                    <div><span className="load-details-title">Appointment Type</span></div>
                                    <div><span className="load-details-data">{stringFormatter.toFormattedString('AppointmentType', stop.apptType, null)}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { setSelectedRecord(stop); toggleEditStopAppointment(); }}>edit</Button></div>
                                </Col>
                            </Row>
                            <Row gutter={[18, 18]}>
                                <Col span={24}>
                                    <div><span className="load-details-title">Requested {Enums.StopTypes.getValueByName(stop.stopType)} Date & Appointment</span></div>
                                    <div>
                                        <span className="load-details-data">
                                            {stop.stopType === 'PICK_UP' && (
                                                <>
                                                {((stop.apptType === 'FIRST_COME_FIRST_SERVE')) ? (
                                                        <FormItem {...formItemLayout} label={"Requested Pick-Up Date"} required format="vertical"
                                                                  render={({ onChange, onBlur, value, name }) => (
                                                                      <DatePicker
                                                                          allowClear={true}
                                                                          placeholder="Requested Date"
                                                                          format="YYYY-MM-DD"
                                                                          style={{ width: '100%' }}
                                                                          onBlur={onBlur}
                                                                          onChange={value => {
                                                                              let stops = [...pickUpStops]

                                                                              stops[stopIndex].apptWindowStartDateTime = value;

                                                                              setPickUpStops(stops)
                                                                          }}
                                                                          value={stop.apptWindowStartDateTime}
                                                                          name="apptWindowStartDateTime"
                                                                      />
                                                                  )}
                                                                  name="apptWindowStartDateTime"
                                                        />) : null
                                                    }

                                                    {((stop.apptType === 'HAVE_APPOINTMENT') && stop.stopType === "PICK_UP" ) ? (
                                                        <>
                                                            <FormItem {...formItemLayout} label={selectedRecord ? (stop.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment" : "Requested Drop-Off Date & Appointment") : "Requested Date & Appointment"} required format="vertical"
                                                                      render={({ onChange, onBlur, value, name }) => (
                                                                          <DatePicker
                                                                              allowClear={true}
                                                                              placeholder="Appointment Date/Time"
                                                                              showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                              format="YYYY-MM-DD HH:mm"
                                                                              style={{ width: '100%' }}
                                                                              onBlur={onBlur}
                                                                              onChange={value => {
                                                                                  let stops = [...pickUpStops]

                                                                                  stops[stopIndex].apptDateTime = value;

                                                                                  setPickUpStops(stops)
                                                                              }}
                                                                              value={stop.apptDateTime}
                                                                              name={name}
                                                                          />
                                                                      )}
                                                                      name="apptDateTime"
                                                            />
                                                        </>
                                                    ) : null}
                                                    {((stop.apptType === 'NEED_APPOINTMENT')) ? (
                                                        <>
                                                            {(stop.stopType === "PICK_UP" || stop.stopType === "DROP_OFF")  && (
                                                                <FormItem {...formItemLayout} label={selectedRecord ? (stop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                                                          render={({ onChange, onBlur, value, name }) => (
                                                                              <DatePicker
                                                                                  allowClear={true}
                                                                                  placeholder="Requested Date"
                                                                                  format="YYYY-MM-DD"
                                                                                  style={{ width: '100%' }}
                                                                                  onBlur={onBlur}
                                                                                  onChange={value => {
                                                                                      let stops = [...pickUpStops]

                                                                                      stops[stopIndex].requestedDateTime = value;

                                                                                      setPickUpStops(stops)
                                                                                  }}
                                                                                  value={stop.requestedDateTime}
                                                                                  name={name}
                                                                              />
                                                                          )}
                                                                          name="requestedDateTime"
                                                                />
                                                            )}
                                                            <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                                                      render={({ onChange, onBlur, value, name }) => (
                                                                          <DatePicker
                                                                              allowClear={true}
                                                                              placeholder="Call Ahead Date/Time"
                                                                              showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                              format="YYYY-MM-DD HH:mm"
                                                                              style={{ width: '100%' }}
                                                                              onBlur={onBlur}
                                                                              onChange={value => {
                                                                                  let stops = [...pickUpStops]

                                                                                  stops[stopIndex].apptCallAheadDateTime = value;

                                                                                  setPickUpStops(stops)
                                                                              }}
                                                                              value={stop.apptCallAheadDateTime}
                                                                              name={name}
                                                                          />
                                                                      )}
                                                                      rules={{ required: false }}
                                                                      name="apptCallAheadDateTime"
                                                            />
                                                        </>
                                                    ) : null}

                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Pick-Up #"
                                                        required={dontRequireLoadSpecificInformation === false}
                                                        format="vertical"
                                                        render={({ onChange, onBlur, value, name }) =>
                                                            <Input
                                                                onBlur={onBlur}
                                                                onChange={e => {
                                                                    const value = e.target.value
                                                                    let stops = [...pickUpStops]
                                                                    stops[stopIndex].pickUpNumber = value
                                                                    setPickUpStops(stops)
                                                                }}
                                                                value={pickUpStops[stopIndex].pickUpNumber}
                                                                name={name}
                                                                placeholder="Pick-Up #"
                                                            />}
                                                        name="pickUpNumber"
                                                    />
                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Customer PO #"
                                                        //required={dontRequireLoadSpecificInformation === false}
                                                        format="vertical"

                                                        render={({ onChange, onBlur, value, name }) =>
                                                            <Input
                                                                onBlur={onBlur}
                                                                onChange={e => {
                                                                    const value = e.target.value
                                                                    let stops = [...pickUpStops]
                                                                    stops[stopIndex].purchaseOrderNumber = value
                                                                    setPickUpStops(stops)
                                                                }}
                                                                value={pickUpStops[stopIndex].purchaseOrderNumber}
                                                                name={name}
                                                                placeholder="Customer PO #"
                                                            />}
                                                        name="purchaseOrderNumber"
                                                    />
                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="BOL Number"
                                                        required
                                                        format="vertical"

                                                        render={({ onChange, onBlur, value, name }) =>
                                                            <Input
                                                                onBlur={onBlur}
                                                                onChange={e => {
                                                                    const value = e.target.value
                                                                    let stops = [...pickUpStops]
                                                                    stops[stopIndex].newBOLNumber = value
                                                                    setPickUpStops(stops)
                                                                }}
                                                                value={pickUpStops[stopIndex].newBOLNumber}
                                                                name={name}
                                                                placeholder="BOL Number"
                                                            />}
                                                              name="newBOLNumber"
                                                    />
                                                    <FormItemFile {...formItemLayout} label="BOL File(s)" name="docFiles" format="vertical">
                                                        <UploadFile
                                                            beforeUpload={(file, fileList) => {
                                                                if (file !== undefined && file !== null) {
                                                                    setBOLDocFiles(oldFiles => [...oldFiles, file]);
                                                                    setDocFiles(oldFiles => [...oldFiles, file]);
                                                                }

                                                                return false;
                                                            }}
                                                            onRemove={(file) => {
                                                                let updatedBOLDocFiles = [...bolDocFiles];
                                                                let fileToRemoveIndex = updatedBOLDocFiles.findIndex(d => d.uid === file.uid);
                                                                if (fileToRemoveIndex !== -1) {
                                                                    updatedBOLDocFiles.splice(fileToRemoveIndex, 1);
                                                                }
                                                                // console.log(updatedDocFiles);
                                                                setBOLDocFiles([...updatedBOLDocFiles]);
                                                                setDocFiles([...updatedBOLDocFiles]);
                                                            }}
                                                            message="Please upload a photo or pdf of the BOL."
                                                            multiple={true}
                                                        />
                                                    </FormItemFile>
                                                </>
                                            )}
                                            {stop.stopType === 'DROP_OFF' && (
                                                <>
                                                    {((stop.apptType === 'FIRST_COME_FIRST_SERVE')) ? (
                                                        <FormItem {...formItemLayout} label={"Requested Drop-Off Date"} required format="vertical"
                                                                  render={({ onChange, onBlur, value, name }) => (
                                                                      <DatePicker
                                                                          allowClear={true}
                                                                          placeholder="Requested Date"
                                                                          format="YYYY-MM-DD"
                                                                          style={{ width: '100%' }}
                                                                          onBlur={onBlur}
                                                                          onChange={value => {
                                                                              let stops = [...dropOffStops]

                                                                              stops[stopIndex].apptWindowStartDateTime = value;

                                                                              setDropOffStops(stops)
                                                                          }}
                                                                          value={stop.apptWindowStartDateTime}
                                                                          name="apptWindowStartDateTime"
                                                                      />
                                                                  )}
                                                                  name="apptWindowStartDateTime"
                                                        />) : null
                                                    }

                                                    {((stop.apptType === 'HAVE_APPOINTMENT')) ? (
                                                        <>
                                                            <FormItem {...formItemLayout} label={selectedRecord ? (stop.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment" : "Requested Drop-Off Date & Appointment") : "Requested Date & Appointment"} required format="vertical"
                                                                      render={({ onChange, onBlur, value, name }) => (
                                                                          <DatePicker
                                                                              allowClear={true}
                                                                              placeholder="Appointment Date/Time"
                                                                              showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                              format="YYYY-MM-DD HH:mm"
                                                                              style={{ width: '100%' }}
                                                                              onBlur={onBlur}
                                                                              onChange={value => {
                                                                                  let stops = [...dropOffStops]

                                                                                  stops[stopIndex].apptDateTime = value;

                                                                                  setDropOffStops(stops)
                                                                              }}
                                                                              value={stop.apptDateTime}
                                                                              name={name}
                                                                          />
                                                                      )}
                                                                      name="apptDateTime"
                                                            />
                                                        </>
                                                    ) : null}
                                                    {((stop.apptType === 'NEED_APPOINTMENT')) ? (
                                                        <>
                                                            {(stop.stopType === "PICK_UP" || stop.stopType === "DROP_OFF") && (
                                                                <FormItem {...formItemLayout} label={selectedRecord ? (stop.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                                                          render={({ onChange, onBlur, value, name }) => (
                                                                              <DatePicker
                                                                                  allowClear={true}
                                                                                  placeholder="Requested Date"
                                                                                  format="YYYY-MM-DD"
                                                                                  style={{ width: '100%' }}
                                                                                  onBlur={onBlur}
                                                                                  onChange={value => {
                                                                                      let stops = [...dropOffStops]

                                                                                      stops[stopIndex].requestedDateTime = value;

                                                                                      setDropOffStops(stops)
                                                                                  }}
                                                                                  value={stop.requestedDateTime}
                                                                                  name={name}
                                                                              />
                                                                          )}
                                                                          name="requestedDateTime"
                                                                />
                                                            )}
                                                            <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                                                      render={({ onChange, onBlur, value, name }) => (
                                                                          <DatePicker
                                                                              allowClear={true}
                                                                              placeholder="Call Ahead Date/Time"
                                                                              showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                                              format="YYYY-MM-DD HH:mm"
                                                                              style={{ width: '100%' }}
                                                                              onBlur={onBlur}
                                                                              onChange={value => {
                                                                                  let stops = [...dropOffStops]

                                                                                  stops[stopIndex].apptCallAheadDateTime = value;

                                                                                  setDropOffStops(stops)
                                                                              }}
                                                                              value={stop.apptCallAheadDateTime}
                                                                              name={name}
                                                                          />
                                                                      )}
                                                                      rules={{ required: false }}
                                                                      name="apptCallAheadDateTime"
                                                            />
                                                        </>
                                                    ) : null}

                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Drop-Off #"
                                                        //required={dontRequireLoadSpecificInformation === false}
                                                        format="vertical"

                                                        render={({ onChange, onBlur, value, name }) =>
                                                            <Input
                                                                onBlur={onBlur}
                                                                onChange={e => {
                                                                    const value = e.target.value
                                                                    let stops = [...dropOffStops]
                                                                    stops[stopIndex].dropOffNumber = value
                                                                    setDropOffStops(stops)
                                                                }}
                                                                value={dropOffStops[stopIndex].dropOffNumber}
                                                                name={name}
                                                                placeholder="Drop-Off #"
                                                            />}
                                                              name="dropOffNumber"
                                                    />
                                                </>
                                            )}

                                            {stop.apptType === "HAVE_APPOINTMENT" && stop.apptDateTime !== undefined && stop.apptDateTime !== null ? stringFormatter.toFormattedString('MomentDateTime', stop.apptDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                            {stop.apptType === "NEED_APPOINTMENT" && stop.requestedDateTime !== undefined && stop.requestedDateTime !== null ? stringFormatter.toFormattedString('MomentDate', stop.requestedDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null) : null}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                            {stop.apptNumber && (
                                <Row gutter={[18, 18]}>
                                    <Col span={24}>
                                        <div><span className="load-details-title">Appointment Number</span></div>
                                        <div><span className="load-details-data">{stop.apptNumber}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {(((stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null) || (stop.dropOffNumber !== undefined && stop.dropOffNumber !== null)) && hideLoadSpecificInformation === false) ? (
                                <Row gutter={[18, 18]}>
                                    {(stop.dropOffNumber !== undefined && stop.dropOffNumber !== null && hideLoadSpecificInformation === false) ? (
                                        <Col span={12}>
                                            <div><span className="load-details-title">Drop-Off Number</span></div>
                                            <div><span className="load-details-data">{stop.dropOffNumber}</span></div>
                                        </Col>
                                    ) : null}
                                    {(stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null && hideLoadSpecificInformation === false) ? (
                                        <Col span={12}>
                                            <div><span className="load-details-title">Customer PO Number</span></div>
                                            <div><span className="load-details-data">{stop.purchaseOrderNumber}</span></div>
                                        </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                            {stop.apptCallAheadDateTime && (
                                <Row gutter={[18, 18]}>
                                    <Col span={24}>
                                        <div><span className="load-details-title">Appointment Call Ahead Date/Time</span></div>
                                        <div><span className="load-details-data">{stringFormatter.toFormattedString('MomentDateTime', stop.apptCallAheadDateTime, null, stop.stopLocation !== undefined && stop.stopLocation !== null && stop.stopLocation.timeZone !== undefined && stop.stopLocation.timeZone !== null ? stop.stopLocation.timeZone : null)}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {stop.apptPointOfContact && (
                                <Row gutter={[18, 18]}>
                                    <Col span={12}>
                                        <div><span className="load-details-title">{Enums.StopTypes.getValueByName(stop.stopType)} Contact</span></div>
                                        <div><span className="load-details-data">{stop.apptPointOfContact.name}</span></div>
                                    </Col>
                                    <Col span={12}>
                                        <div><span className="load-details-title">Phone Number</span></div>
                                        <div><span className="load-details-data">{stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}</span></div>
                                    </Col>
                                </Row>
                            )}
                            {stop.apptInstructions && (
                                <Row gutter={[18, 18]}>
                                    <Col span={24}>
                                        <div><span className="load-details-title">Appointment Instructions</span></div>
                                        <div><span className="load-details-data">{stop.apptInstructions}</span></div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    ) : null}
                </div>
            </DataCollapsableRow>
        );
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    // console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutTriple = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 8 },
        wrapperCol2: { span: 8 },
        labelCol3: { span: 4 },
        wrapperCol3: { span: 4 },
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit, onSubmit)}>
                    <div className="load-creation-step-container">
                        <div className="load-creation-step">
                            <Header
                                titleHasAction={(shipperData && shipperData.shipperId) ? false : true}
                                titleStyle={{ paddingLeft: 0, color: '#000000', fontWeight: 500 }}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                title={shipperData && shipperData.shipperId ? (shipperData.shipperName ? shipperData.shipperName : 'N/A') : (<Button style={{ paddingLeft: 0, fontSize: 18, color: '#000000', fontWeight: 500 }} type='link' onClick={() => { toggleEditShipper(); }}>Select a Shipper Account <DownOutlined /></Button>)}
                                icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                                iconStyle={{ background: 'transparent' }}
                            />
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLocationsLoading === true || isLinkedLocationsLoading === true}>
                                <Timeline style={{ padding: '10px 24px' }}>
                                    {(pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) ? (
                                        <>
                                            {pickUpStops.map((stop, index, arr) => {
                                                return (
                                                    <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color={pickUpsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                        <div style={{ paddingLeft: 50, marginBottom: 72 }}>
                                                            <div style={pickUpsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                                {getStopLocationDisplay(stop, index)}
                                                                {getError(methods.errors, `pickUpStops-${index}`) !== undefined && (
                                                                    <ErrorMessage
                                                                        errors={methods.errors}
                                                                        name={`pickUpStops-${index}`}
                                                                        render={({ message, messages }) => {
                                                                            if (messages !== undefined && messages !== null) {
                                                                                return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                            } else if (message !== undefined && message !== null) {
                                                                                return <Alert message={message} type="error" />;
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            {(stop.apptType === undefined || stop.apptType === null) ? (
                                                                <div style={{ marginTop: 18 }}>
                                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.stopLocationId !== undefined && stop.stopLocationId !== null)} onClick={() => { setSelectedRecord(stop); toggleEditStopAppointment(); }}>{`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details *`}</Button>
                                                                </div>
                                                            ) : null}
                                                            {(dropOffsEnabled === false && (stop.apptType !== undefined && stop.apptType !== null) && (stop.stopTypeSequence + 1 === pickUpStops.length)) ? (
                                                                <div style={{ marginTop: 18 }}>
                                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<RightOutlined />} onClick={() => { setDropOffsEnabled(true); }}>Continue to Drop-Off</Button>
                                                                </div>
                                                            ) : null}
                                                            {(stop.stopTypeSequence + 1 === pickUpStops.length) ? (
                                                                <div style={{ marginTop: 18 }}>
                                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.apptType !== undefined && stop.apptType !== null)} onClick={() => { toggleNewPickUpStopLocation(); }}>Additional Pick-Up</Button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Timeline.Item>
                                                );
                                            })}
                                        </>
                                    ) : (
                                            <Timeline.Item className="customTimelineItem" key={`pickUp`} color={pickUpsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                <div style={{ paddingLeft: 50, marginBottom: 72 }}>
                                                    <div style={{ marginTop: 18 }}>
                                                        <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} disabled={!pickUpsEnabled} type="link" icon={<PlusOutlined />} onClick={() => { toggleNewPickUpStopLocation(); }}>Pick-Up</Button>
                                                    </div>
                                                    {getError(methods.errors, `pickUpStops`) !== undefined && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={`pickUpStops`}
                                                            render={({ message, messages }) => {
                                                                if (messages !== undefined && messages !== null) {
                                                                    return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                                } else if (message !== undefined && message !== null) {
                                                                    return <Alert message={message} type="error" />;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Timeline.Item>
                                        )}
                                    {(dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) ? (
                                        <>
                                            {dropOffStops.map((stop, index, arr) => {
                                                return (
                                                    <Timeline.Item className={(stop.stopTypeSequence + 1 === dropOffStops.length) ? "customTimelineItem-last" : "customTimelineItem"} key={`dropOff-${index}`} color={dropOffsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                        <div style={{ paddingLeft: 50, marginBottom: 72 }}>
                                                            <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                                {getStopLocationDisplay(stop, index)}
                                                                {getError(methods.errors, `dropOffStops-${index}`) !== undefined && (
                                                                    <ErrorMessage
                                                                        errors={methods.errors}
                                                                        name={`dropOffStops-${index}`}
                                                                        render={({ message, messages }) => {
                                                                            if (messages !== undefined && messages !== null) {
                                                                                return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                            } else if (message !== undefined && message !== null) {
                                                                                return <Alert message={message} type="error" />;
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            {(stop.apptType === undefined || stop.apptType === null) ? (
                                                                <div style={{ marginTop: 18 }}>
                                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.stopLocationId !== undefined && stop.stopLocationId !== null)} onClick={() => { setSelectedRecord(stop); toggleEditStopAppointment(); }}>{`Date, Appointment, & ${Enums.StopTypes.getValueByName(stop.stopType)} Details *`}</Button>
                                                                </div>
                                                            ) : null}
                                                            {(stop.stopTypeSequence + 1 === dropOffStops.length) ? (
                                                                <div style={{ marginTop: 18 }}>
                                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.apptType && stop.stopLocation)} onClick={() => { toggleNewDropOffStopLocation(); }}>Additional Drop-Off</Button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </Timeline.Item>
                                                );
                                            })}
                                        </>
                                    ) : (
                                            <Timeline.Item className="customTimelineItem-last" key={`dropOff`} color={dropOffsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                <div style={{ paddingLeft: 50, marginBottom: 72 }}>
                                                    <div style={{ marginTop: 18 }}>
                                                        <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!dropOffsEnabled} onClick={() => { toggleNewDropOffStopLocation(); }}>Drop-Off</Button>
                                                    </div>
                                                    {getError(methods.errors, `dropOffStops`) !== undefined && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={`dropOffStops`}
                                                            render={({ message, messages }) => {
                                                                if (messages !== undefined && messages !== null) {
                                                                    return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                                } else if (message !== undefined && message !== null) {
                                                                    return <Alert message={message} type="error" />;
                                                                } else {
                                                                    return null;
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Timeline.Item>
                                        )}
                                </Timeline>
                            </Spin>
                        </div>
                        <Row gutter={[12, 12]} className="load-creation-step-buttons">
                            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                                {props.cancelButton !== undefined && props.cancelButton !== null ? props.cancelButton : null}
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                                <Button type="primary" block htmlType="submit">{nextStepActionName}</Button>
                            </Col>
                        </Row>
                    </div>
                    <Drawer
                        title={'New Pick-Up'}
                        visible={showNewPickUpStopLocation === true}
                        onClose={toggleNewPickUpStopLocation}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="pickUpLocationId"
                            defaultValue={null}
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleNewPickUpStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.pickUpLocationId;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = pickUpStops.length;
                                    if (stopLocationId !== undefined && stopLocationId !== null) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (stopLocation !== undefined && stopLocation !== null) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                stopLocationId: stopLocationId,
                                                stopType: 'PICK_UP',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence
                                            };

                                            if (stopLocation.lumperFee !== undefined && stopLocation.lumperFee !== null && stopLocation.lumperFee > 0) {
                                                newStop.lumperFee = stopLocation.lumperFee;
                                            }
                                            if (stopLocation.specialInstructions !== undefined && stopLocation.specialInstructions !== null) {
                                                newStop.specialInstructions = stopLocation.specialInstructions;
                                            }
                                            if (stopLocation.pointOfContact !== undefined && stopLocation.pointOfContact !== null) {
                                                newStop.apptPointOfContact = stopLocation.pointOfContact;
                                            }

                                            setPickUpStops(pickUpStops => [...pickUpStops, { ...newStop }]);
                                            toggleNewPickUpStopLocation();
                                        }
                                    }

                                    return false;
                                }}>
                                Add Pick-Up Location
                            </Button>
                        </div>
                    </Drawer>
                    <Drawer
                        title={'New Drop-Off'}
                        visible={showNewDropOffStopLocation === true}
                        onClose={toggleNewDropOffStopLocation}
                        bodyStyle={{ paddingBottom: 80 }}
                        style={{ zIndex: 1000 }}
                        width={fullWidth > 720 ? fullWidth / 2 : 360}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            name="dropOffLocationId"
                            rules={{ required: 'Required Field' }}
                            defaultValue={null}
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleNewDropOffStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.dropOffLocationId;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = dropOffStops.length;
                                    if (stopLocationId !== undefined && stopLocationId !== null) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (stopLocation !== undefined && stopLocation !== null) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                stopLocationId: stopLocationId,
                                                stopType: 'DROP_OFF',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence
                                            };

                                            if (stopLocation.lumperFee !== undefined && stopLocation.lumperFee !== null && stopLocation.lumperFee > 0) {
                                                newStop.lumperFee = stopLocation.lumperFee;
                                            }
                                            if (stopLocation.specialInstructions !== undefined && stopLocation.specialInstructions !== null) {
                                                newStop.specialInstructions = stopLocation.specialInstructions;
                                            }
                                            if (stopLocation.pointOfContact !== undefined && stopLocation.pointOfContact !== null) {
                                                newStop.apptPointOfContact = stopLocation.pointOfContact;
                                            }

                                            setDropOffStops(dropOffStops => [...dropOffStops, { ...newStop }]);
                                            toggleNewDropOffStopLocation();
                                        }
                                    }

                                    return false;
                                }}>
                                Add Drop-Off Location
                            </Button>
                        </div>
                    </Drawer>
                </Form>
            </FormProvider>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}
                visible={showEditStopLocation === true}
                onClose={(e) => { toggleEditStopLocation(); setSelectedRecord(null); }}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(selectedRecord)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                            help={selectedRecord && selectedRecord.stopLocationId ? "Changing the location will reset the Lumper Fee, Special Instructions, and Point of Contact Information if there are default values set for that location." : null}
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopLocation(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Date, Appointment, & Pick-Up Details" : "Date, Appointment, & Drop-Off Details") : 'Date, Appointment, & Stop Details'}
                onClose={(e) => { toggleEditStopAppointment(); setSelectedRecord(null); }}
                visible={showEditStopAppointment === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onEditStopAppointment)}>
                        <FormItem {...formItemLayout} label="Appointment Type" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select an Appointment Type"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChangeAppointmentType(selected); onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {appointmentTypeOptions}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="apptType"
                        />
                        {(showFirstComeFirstServeDetails === true) ? (
                            <>
                                <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                          render={({ onChange, onBlur, value, name }) => (
                                              <DatePicker
                                                  allowClear={true}
                                                  placeholder="Requested Date"
                                                  format="YYYY-MM-DD"
                                                  style={{ width: '100%' }}
                                                  onBlur={onBlur}
                                                  onChange={(date, dateString) => { onChange(date); }}
                                                  value={value ? value : momentDate.getCurrentDateTimeInRange()}
                                                  name={name}
                                              />
                                          )}
                                          rules={{ required: 'Required Field' }}
                                          name="apptWindowStartDateTime"
                                />
                            </>
                        ) : null}
                        {(showHaveAppointmentDetails === true) ? (
                            <>
                                {hideLoadSpecificInformation === false ? (
                                    <FormItem {...formItemLayout} label="Appointment #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Appointment #" />}
                                        rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                        name="apptNumber"
                                    />
                                ) : null}
                                <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date & Appointment" : "Requested Drop-Off Date & Appointment") : "Requested Date & Appointment"} required format="vertical"
                                          render={({ onChange, onBlur, value, name }) => (
                                              <DatePicker
                                                  allowClear={true}
                                                  placeholder="Appointment Date/Time"
                                                  showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                                  format="YYYY-MM-DD HH:mm"
                                                  style={{ width: '100%' }}
                                                  onBlur={onBlur}
                                                  onChange={(date, dateString) => { onChange(date); }}
                                                  value={value ? value : momentDate.getCurrentDateTimeInRange()}
                                                  name={name}
                                              />
                                          )}
                                          rules={{ required: 'Required Field' }}
                                          name="apptDateTime"
                                />
                            </>
                        ) : null}
                        {(showNeedAppointmentDetails === true) ? (
                            <>
                                {(selectedRecord.stopType === "PICK_UP" || selectedRecord.stopType === "DROP_OFF") && (
                                    <FormItem {...formItemLayout} label={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Requested Pick-Up Date" : "Requested Drop-Off Date") : 'Requested Date'} required format="vertical"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <DatePicker
                                                allowClear={true}
                                                placeholder="Requested Date"
                                                format="YYYY-MM-DD"
                                                style={{ width: '100%' }}
                                                onBlur={onBlur}
                                                onChange={(date, dateString) => { onChange(date); }}
                                                value={value ? value : momentDate.getCurrentDateTimeInRange()}
                                                name={name}
                                            />
                                        )}
                                        rules={{ required: 'Required Field' }}
                                        name="requestedDateTime"
                                    />
                                )}
                                <FormItem {...formItemLayout} label="Appointment Call Ahead Date and Time" format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            allowClear={true}
                                            placeholder="Call Ahead Date/Time"
                                            showTime={{ format: 'HH:mm', minuteStep: 15 }}
                                            format="YYYY-MM-DD HH:mm"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value ? value : momentDate.getCurrentDateTimeInRange()}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="apptCallAheadDateTime"
                                />
                            </>
                        ) : null}
                        {(selectedRecord && selectedRecord.stopType === 'PICK_UP' && hideLoadSpecificInformation === false) ? (
                            <>
                                <FormItem {...formItemLayout} label="Pick-Up #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pick-Up #" />}
                                    rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="pickUpNumber"
                                />
                                <FormItem {...formItemLayout} label="Customer PO #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                          render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer PO #" />}
                                            // rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                          name="purchaseOrderNumber"
                                />
                            </>
                        ) : null}
                        {(selectedRecord && selectedRecord.stopType === 'DROP_OFF' && hideLoadSpecificInformation === false) ? (
                            <>
                                <FormItem {...formItemLayout} label="Drop-Off #" required={dontRequireLoadSpecificInformation === false} format="vertical"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drop-Off #" />}
                                    // rules={{ required: dontRequireLoadSpecificInformation === false ? 'Required Field' : false }}
                                    name="dropOffNumber"
                                />
                            </>
                        ) : null}
                        <FormItemTriple {...formItemLayoutTriple} label1={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Contact" : "Drop-Off Contact") : 'Appointment Contact'} label2="Phone #" label3="Phone Ext" format="vertical"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules1={{ required: false }}
                            name1="name"
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules2={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name2="phone"
                            defaultValue2={''}
                            render3={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone Ext" />}
                            rules3={{ required: false }}
                            name3="phoneExt"
                        />
                        <FormItem {...formItemLayout} label="Appointment Instructions" format="vertical"
                            render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                            rules={{ required: false }}
                            name="apptInstructions"
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditStopAppointment(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">{(selectedRecord && selectedRecord.requestedDateTime) ? 'Update Date, Appointment, and Stop Details' : 'Add Date, Appointment, and Stop Details'}</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
            <Drawer
                title="Search and Select a Shipper Account"
                onClose={toggleEditShipper}
                visible={showEditShipper === true}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onEditShipper)}>
                        <FormItem {...formItemLayout} required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Search and Select a Shipper Account"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    loading={isShippersLoading === true}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    notFoundContent={
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shipper Accounts for you to choose from. You cannot create a load without assigning it to a Shipper account. Please add Shipper accounts first.">
                                            {entityType === 'CARRIER' ? (
                                                <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedShippers/` }); }}>Setup Shipper Accounts</Button>
                                            ) : null}
                                        </Empty>
                                    }
                                    options={shippers.map(s => { return { label: s.name, value: s.id } })}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="shipperId"
                            defaultValue={null}
                        />
                        <div className={classes.buttonContainer}>
                            <Button onClick={() => { toggleEditShipper(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Save Shipper</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Drawer>
        </>
    );
}

export default withRouter(NewLoadSchedule);
