import React, { useMemo } from 'react';
import { Input, Select, Alert, Spin, Divider, InputNumber } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const EditInvoiceLineItem = props => {
    const load = props.load;
    const shipperId = props.shipperId;
    const carrierId = props.carrierId;
    const invoiceLineItem = props.invoiceLineItem;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.invoiceLineItems.isRecordUpdateLoading);
    const error = useSelector(state => state.invoiceLineItems.updateRecordError);
    const entityType = useSelector(state => state.auth.entityType);

    const invoiceQuantityUnitOptions = Enums.InvoiceQuantityUnits.selectListOptions();
    const invoiceAccountTypeOptions = entityType === "CARRIER" ? Enums.InvoiceAccountTypes.selectListOptionsWithExclusions(["STAFF"]) : Enums.InvoiceAccountTypes.selectListOptions();

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            if (entityType === 'STAFF') {
                if (data.fromEntityType === 'SHIPPER' && shipperId !== undefined && shipperId !== null) {
                    data.fromEntityId = shipperId;
                } else if (data.fromEntityType === 'CARRIER' && carrierId !== undefined && carrierId !== null) {
                    data.fromEntityId = carrierId;
                }

                if (data.toEntityType === 'SHIPPER' && shipperId !== undefined && shipperId !== null) {
                    data.toEntityId = shipperId;
                } else if (data.toEntityType === 'CARRIER' && carrierId !== undefined && carrierId !== null) {
                    data.toEntityId = carrierId;
                }
            } else if (load.serviceType === 'TMS') {
                data.fromEntityType = 'SHIPPER';
                data.toEntityType = 'CARRIER';
                if (shipperId !== undefined && shipperId !== null) {
                    data.fromEntityId = shipperId;
                }

                if (carrierId !== undefined && carrierId !== null) {
                    data.toEntityId = carrierId;
                }
            }

            dispatch(actionCreators.updateInvoiceLineItem(invoiceLineItem.id, data, load.id));
        }
    };

    const baseAmountValue = methods.watch("baseAmount");
    const quantityValue = methods.watch("quantity");

    useMemo(() => {
        if (!isNaN(baseAmountValue) && !isNaN(quantityValue)) {
            methods.setValue("totalAmount", (baseAmountValue * quantityValue).toFixed(2));
        } else {
            methods.setValue("totalAmount", '');
        }
    }, [baseAmountValue, quantityValue]);

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateInvoiceLineItem());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateInvoiceLineItemErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 6 },
        wrapperCol1: { span: 6 },
        labelCol2: { span: 6 },
        wrapperCol2: { span: 6 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Edit Invoice Line Item</legend>
                        <FormItem {...formItemLayout} label="Description" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Description" />}
                            rules={{ required: 'Required Field' }}
                            name="description"
                            defaultValue={invoiceLineItem.description}
                        />
                        {entityType === 'STAFF' && (
                            <>
                                <Divider />
                                <FormItemDouble {...formItemLayoutDouble2} label1="Payment From" label2="To" required1 required2 format="horizontal"
                                    render1={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules1={{ required: 'Required Field' }}
                                    name1="fromEntityType"
                                    defaultValue1={invoiceLineItem.fromEntityType}
                                    render2={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                        >
                                            {invoiceAccountTypeOptions}
                                        </Select>
                                    )}
                                    rules2={{ required: 'Required Field' }}
                                    name2="toEntityType"
                                    defaultValue2={invoiceLineItem.toEntityType}
                                />
                            </>
                        )}
                        <FormItem {...formItemLayout} label="Base Fee" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Base Fee"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="baseAmount"
                            defaultValue={invoiceLineItem.baseAmount}
                        />
                        <FormItemDouble {...formItemLayoutDouble2} label1="Quantity" label2="Units" required1 required2 format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Quantity"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: 'Required Field' }}
                            name1="quantity"
                            defaultValue1={invoiceLineItem.quantity}
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {invoiceQuantityUnitOptions}
                                </Select>
                            )}
                            rules2={{ required: 'Required Field' }}
                            name2="quantityUnit"
                            defaultValue2={invoiceLineItem.quantityUnit}
                        />
                        <FormItem {...formItemLayout} label="Total Amount" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    precision={2}
                                    min={0}
                                    placeholder="Total Amount"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                    disabled={true}
                                />
                            )}
                            rules={{ required: 'Required Field' }}
                            name="totalAmount"
                            defaultValue={invoiceLineItem.totalAmount}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Invoice Line Item" />
            </Form>
        </FormProvider>
    );
};

export default EditInvoiceLineItem;