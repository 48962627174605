import React, { useMemo, useState } from 'react';
import { Alert, Button, Drawer, Empty, Select, Spin } from "antd";
import { isEmpty } from "lodash";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import NewDriver from '../NewDriver/NewDriver';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import StringFormatter from '../../shared/stringFormatter';
import { driverAssignedEmailTemplate } from "../../shared/emailTemplates";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const AssignLoadDriver = props => {
    const fullWidth = global.window.innerWidth;
    const load = props.load;
    const carrierId = props.carrierId;
    const driverIds = props.load.driverIds !== undefined && props.load.driverIds !== null ? props.load.driverIds : [];

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const isAccountUsersLoading = useSelector(state => state.accountUsers.isLoading);
    const accountUsers = useSelector(state => state.accountUsers.records);

    const [showNewEntity, setShowNewEntity] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    useMemo(() => {
        if (carrierId !== null) {
            dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, isDriver: true, accountId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    const sendEmailToDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                let firstName = accountUser.firstName;
                let email = accountUser.email;
                const { body, subject } = driverAssignedEmailTemplate(firstName, load.irisId)
                let newEmail = {
                    body: body,
                    subject: subject,
                    email: email
                };
                axiosAuthenticated.post('/email', newEmail).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };

    const sendNotificationToDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            let firstName = accountUser.firstName;
            let userId = accountUser.userId;

            let newNotification = {
                message: `Hi ${firstName}, you have been assigned to a new load: ${load.irisId}. Please open up the Pulogix app for more details.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been assigned to a new load`,
                severity: "CRITICAL",
                userIds: [userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_ASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            // check to see if it is a duplicate and if it is found in the driverIds array already, throw an error
            if (driverIds.length > 0) {
                if (driverIds.find(i => i === data.accountUserId) !== undefined) {
                    methods.setError("accountUserId", { type: "validate", message: "The driver you chose is already assigned to this load." });
                    return;
                } else {
                    methods.clearErrors("accountUserId");
                }
            }

            //console.log(data);
            let updatedDriverIds = [...driverIds, data.accountUserId];
            //console.log(updatedDriverIds);

            // removes duplicates
            const updatedDriverIds2 = [...new Set(updatedDriverIds)];
            //console.log(updatedDriverIds2);
            let payload = {};
            payload.driverIds = updatedDriverIds2;
            //console.log(payload);
            dispatch(actionCreators.updateLoad(load.id, payload));
            sendNotificationToDriver(data.accountUserId);
            sendEmailToDriver(data.accountUserId);
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <Fieldset>
                            <legend>Choose a Driver to Assign to this Load</legend>
                            <FormItem {...formItemLayout} label="Choose a Driver" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Search and Select a Driver"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        loading={isAccountUsersLoading === true}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Drivers for you to choose from." />}
                                    >
                                        {accountUsers.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Currently Available: {stringFormatter.toYesNo(d.driver.isAvailable)}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserId"
                            />
                            <br />
                            <b>Driver Doesn't Exist Yet?</b>
                            <br />
                            <Button type='primary' onClick={toggleNewEntity}>Add New Driver to Carrier Account</Button>
                        </Fieldset>
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Assign Driver to Load" />
                </Form>
            </FormProvider>
            <Drawer
                title={'Add New Driver'}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={toggleNewEntity}
                visible={showNewEntity}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                <NewDriver cancel={toggleNewEntity} accountId={carrierId} />
            </Drawer>
        </>
    );
};

export default AssignLoadDriver;