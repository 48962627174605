import React, { useEffect, useState } from "react";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

const DataCollapsableRow = ({ className = null, headerClassName = null, titleClassName = null, subtitleClassName = null, header = null, collapsedHeader = null, title, subtitle = null, extra = null, isCollapsed = false, isCollapsable = true, isDisabled = false, style = null, titleStyle = null, titleHasAction = false, ...props }) => {
    const [isRowCollapsed, setIsRowCollapsed] = useState(false);

    useEffect(() => {
        setIsRowCollapsed(isCollapsed);
    }, [isCollapsed]);

    const onCollapse = () => {
        setIsRowCollapsed(!isRowCollapsed);
    };

    const getCollapseIcon = (title) => {
        if (isRowCollapsed === true) {
            return (
                <Button type="link" style={titleStyle} disabled={isDisabled} onClick={onCollapse}>{title} <RightOutlined /></Button>
            );
        } else {
            if (isCollapsable === true) {
                return (
                    <Button type="link" style={titleStyle} disabled={isDisabled} onClick={onCollapse}>{title} <DownOutlined /></Button>
                );
            } else {
                return (
                    <span style={titleStyle}>{title}</span>
                );
            }
        }
    };

    if (isRowCollapsed === true) {
        return (
            <div className={className} style={style}>
                {collapsedHeader !== undefined && collapsedHeader !== null ? (<div className={headerClassName}>{collapsedHeader}</div>) : (header !== undefined && header !== null ? (<div>{header}</div>) : null)}
                {title !== undefined && title !== null ? (<div className={titleClassName}>{titleHasAction === true ? title : getCollapseIcon(title)}{extra}</div>) : null}
                {subtitle !== undefined && subtitle !== null ? (<div className={subtitleClassName}>{subtitle}</div>) : null}
            </div>
        );
    } else {
        return (
            <div style={style}>
                {header !== undefined && header !== null ? (<div>{header}</div>) : null}
                {title !== undefined && title !== null ? (<div>{titleHasAction === true ? title : getCollapseIcon(title)}{extra}</div>) : null}
                {subtitle !== undefined && subtitle !== null ? (<div>{subtitle}</div>) : null}
                {props.children}
            </div>
        );
    }
};

export default DataCollapsableRow;