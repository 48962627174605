import React, { useEffect, useState } from 'react';
import { Card, Drawer } from "antd";
import classes from './LoadCompletionCard.module.scss';
import EditLoadCompletionChecklist from "../../components/EditLoadCompletionChecklist/EditLoadCompletionChecklist";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import DataRow from '../../components/DataRow/DataRow';
import Pricing from '../../shared/pricing';

const missingEntityStyle = {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
};

const missingEntityHeadStyle = {
    borderBottom: '1px solid #ffa39e',
};

const LoadCompletionCard = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;

    const load = props.load;
    const stops = props.stops !== undefined && props.stops !== null && props.stops.length > 0 ? props.stops : [];
    const invoiceLineItems = props.invoiceLineItems !== undefined && props.invoiceLineItems !== null && props.invoiceLineItems.length > 0 ? props.invoiceLineItems : [];
    const transactions = props.transactions !== undefined && props.transactions !== null && props.transactions.length > 0 ? props.transactions : [];
    const claims = props.claims !== undefined && props.claims !== null && props.claims.length > 0 ? props.claims : [];
    const documents = props.documents !== undefined && props.documents !== null && props.documents.length > 0 ? props.documents : [];
    const entityType = props.entityType;

    let pricingSummary = Pricing.loadPricingSummary(invoiceLineItems, transactions);
    //console.log(pricingSummary);

    //#endregion
    //#region useStates

    const [showEditEntity, setShowEditEntity] = useState(false);
    const [hasInvoiceBeenSent, setHasInvoiceBeenSent] = useState(load !== undefined && load !== null && load.completionCheckList !== undefined && load.completionCheckList !== null && load.completionCheckList.hasInvoiceBeenSent !== undefined && load.completionCheckList.hasInvoiceBeenSent !== null && load.completionCheckList.hasInvoiceBeenSent === true ? true : false);
    const [hasOpenClaims, setHasOpenClaims] = useState(true);
    const [hasBalance, setHasBalance] = useState(true);
    const [hasPendingInvoiceLineItems, setHasPendingInvoiceLineItems] = useState(true);
    const [hasPendingTransactions, setHasPendingTransactions] = useState(true);
    const [hasUnreviewedBOLs, setHasUnreviewedBOLs] = useState(true);
    const [isChecklistCompleted, setIsChecklistCompleted] = useState(false);

    //#endregion
    //#region toggles

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    //#endregion
    //#region display components

    const editEntityComponents = (
        <Drawer
            title={"Update Load Completion Checklist"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditEntity}
            visible={showEditEntity === true}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadCompletionChecklist
                cancel={toggleEditEntity}
                load={load}
                hasOpenClaims={hasOpenClaims}
                hasBalance={hasBalance}
                hasPendingInvoiceLineItems={hasPendingInvoiceLineItems}
                hasPendingTransactions={hasPendingTransactions}
                hasUnreviewedBOLs={hasUnreviewedBOLs}
                hasInvoiceBeenSent={hasInvoiceBeenSent}
            />
        </Drawer>
    );

    const extra = (entityType === "STAFF" || (entityType === "SHIPPER" && load.serviceType === 'TMS')) ? (
        <AddEditChangeRemoveButtons
            showAdd={false} addText='' addAction={null}
            showEdit={(load !== undefined && load !== null && load.loadStatus === 'COMPLETED') ? true : false} editText='Update Completion Checklist' editAction={(e) => { e.stopPropagation(); toggleEditEntity(); }}
            showChange={false} changeText='' changeAction={null}
            showRemove={false} removeText='' removeAction={null}
        />
    ) : null;

    //#endregion
    //#region useEffects

    useEffect(() => {
        if (load !== undefined && load !== null && load.completionCheckList !== undefined && load.completionCheckList !== null && load.completionCheckList.hasInvoiceBeenSent !== undefined && load.completionCheckList.hasInvoiceBeenSent !== null && load.completionCheckList.hasInvoiceBeenSent === true) {
            setHasInvoiceBeenSent(true);
        } else {
            setHasInvoiceBeenSent(false);
        }
    }, [load]);

    useEffect(() => {
        if (load !== undefined && load !== null && stops !== undefined && stops !== null && stops.length > 0) {
            // make sure BOLs and other required documents are uploaded for each pick-up and drop-off stop and that all documents are reviewed

            let hasBOLsReviewed = false;
            let hasPendingApprovalDocuments = false;
            let hasMissingDocuments = false;
            stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF').forEach((stop) => {
                let stopDocuments = documents.filter(i => i.entityId === stop.id);
                if (stopDocuments !== undefined && stopDocuments !== null && stopDocuments.length > 0) {
                    stopDocuments.forEach((item) => {
                        if (!item.isReviewed) {
                            if (item.documentType === 'BOL' ||
                                item.documentType === 'LUMPER_FEE_RECEIPT' ||
                                item.documentType === 'SCALE_TICKET' ||
                                item.documentType === 'TRAILER_CONTROL_FILE' ||
                                item.documentType === 'FUEL_RECEIPT' ||
                                item.documentType === 'TOLL_RECEIPT' ||
                                item.documentType === 'RECEIPT' ||
                                item.documentType === 'OTHER') {
                                hasPendingApprovalDocuments = true;
                            }
                        }
                    });

                    // check for missing documents that are required for stops
                    if (stop.stopType === 'PICK_UP') {
                        if (stopDocuments.find(item => item.documentType === 'BOL') === undefined) {
                            hasMissingDocuments = true;
                        }
                        if (stopDocuments.find(item => item.documentType === 'SCALE_TICKET') === undefined) {
                            hasMissingDocuments = true;
                        }
                    } else if (stop.stopType === 'DROP_OFF') {
                        if (stopDocuments.find(item => item.documentType === 'BOL') === undefined) {
                            hasMissingDocuments = true;
                        }
                    }
                } else {
                    hasMissingDocuments = true;
                }
            });
            //console.log(hasPendingApprovalDocuments);
            //console.log(hasMissingDocuments);
            hasBOLsReviewed = (hasPendingApprovalDocuments === false && hasMissingDocuments === false);
            //console.log(hasBOLsReviewed);
            setHasUnreviewedBOLs(hasBOLsReviewed === false);
        } else {
            setHasUnreviewedBOLs(false);
        }
    }, [load, stops, documents]);

    useEffect(() => {
        // check to see if transactions and invoice match and the balance for shippers and carriers is zero
        if (pricingSummary !== undefined && pricingSummary !== null) {
            setHasBalance(pricingSummary.shipperBalance > 0 || pricingSummary.carrierBalance > 0 || pricingSummary.factoringBalance > 0);
        } else {
            setHasBalance(false);
        }
    }, [pricingSummary]);

    useEffect(() => {
        // check to see if there are any open claims for this load
        if (claims !== undefined && claims !== null && claims.length > 0) {
            let openClaims = claims.filter(i => i.claimStatus !== 'CLOSED');
            setHasOpenClaims(openClaims.length > 0);
        } else {
            setHasOpenClaims(false);
        }
    }, [claims]);

    useEffect(() => {
        // check to see if there are any pending invoice line items for this load
        if (invoiceLineItems !== undefined && invoiceLineItems !== null && invoiceLineItems.length > 0) {
            let pendingInvoiceLineItems = invoiceLineItems.filter(i => i.status === 'PENDING' || i.approvalStatus === 'NEW' || i.approvalStatus === 'PENDING');
            setHasPendingInvoiceLineItems(pendingInvoiceLineItems.length > 0);
        } else {
            setHasPendingInvoiceLineItems(false);
        }
    }, [invoiceLineItems]);

    useEffect(() => {
        // check to see if there are any pending transactions for this load
        if (transactions !== undefined && transactions !== null && transactions.length > 0) {
            let pendingTransactions = transactions.filter(i => i.paymentStatus === 'PENDING');
            setHasPendingTransactions(pendingTransactions.length > 0);
        } else {
            setHasPendingTransactions(false);
        }
    }, [transactions]);

    useEffect(() => {
        if (hasBalance === false && hasOpenClaims === false && hasUnreviewedBOLs === false && hasInvoiceBeenSent === true && hasPendingInvoiceLineItems === false && hasPendingTransactions === false) {
            setIsChecklistCompleted(true);
        } else {
            setIsChecklistCompleted(false);
        }
    }, [hasBalance, hasOpenClaims, hasUnreviewedBOLs, hasInvoiceBeenSent, hasPendingInvoiceLineItems, hasPendingTransactions]);

    //#endregion

    if ((entityType === 'STAFF' || (entityType === 'SHIPPER' && load.serviceType === 'TMS')) && (load.loadStatus === 'COMPLETED' || load.loadStatus === 'CLOSED' || load.loadStatus === 'REOPENED')) {
        return (
            <>
                <Card title={props.title} bordered={true} className={classes.card} style={isChecklistCompleted === true ? props.style : { ...props.style, ...missingEntityStyle }} headStyle={isChecklistCompleted === true ? props.headStyle : { ...props.headStyle, ...missingEntityHeadStyle }} extra={extra}>
                    <DataRow title="Invoice Sent" value={load !== undefined && load !== null && load.completionCheckList !== undefined && load.completionCheckList !== null && load.completionCheckList.hasInvoiceBeenSent !== undefined && load.completionCheckList.hasInvoiceBeenSent !== null ? load.completionCheckList.hasInvoiceBeenSent : false} dataType="Boolean" />
                    <DataRow title="Open Claims" value={hasOpenClaims} dataType="Boolean" />
                    <DataRow title="Pending Invoice Line Items" value={hasPendingInvoiceLineItems} dataType="Boolean" />
                    <DataRow title="Pending Transactions" value={hasPendingTransactions} dataType="Boolean" />
                    <DataRow title="Outstanding Balance" value={hasBalance} dataType="Boolean" />
                    <DataRow title="Unreviewed Documents" value={hasUnreviewedBOLs} dataType="Boolean" />
                </Card>
                {editEntityComponents}
            </>
        );
    } else {
        return null;
    }
};

export default LoadCompletionCard;