import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';

const Claim = props => {

    const claim = props.record;
    return (
        <Row>
            <Col xs={24} md={24} lg={12} xl={12}>
                {claim.irisId && <DataRow title="Claim ID" value={claim.irisId} dataType="String" />}
                <DataRow title="Is Against Carrier" value={claim.isAgainstCarrier} dataType="Boolean" />
                <DataRow title="Is Against Shipper" value={claim.isAgainstShipper} dataType="Boolean" />
                {claim.claimStatus && <DataRow title="Claim Status" value={claim.claimStatus} dataType="ClaimStatus" />}
                {claim.comments && <DataRow title="Comments" value={claim.comments} dataType="String" />}
                {claim.count && <DataRow title="Count" value={claim.count} dataType="String" />}
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
                {claim.bolStatus && <DataRow title="BOL Status" value={claim.bolStatus} dataType="BOLStatus" />}
                {claim.irisLoadId && <DataRow title="Load ID" value={claim.irisLoadId} dataType="String" />}
                {claim.adjustment && <DataRow title="Adjustment Amount" value={claim.adjustment} dataType="Money" />}
                {claim.bolNumber && <DataRow title="BOL #" value={claim.bolNumber} dataType="String" />}
            </Col>
        </Row>
    );
};

export default Claim;