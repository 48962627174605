import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Row, Col, Avatar, Button } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router';
import ComplexCard from '../../components/ComplexCard/ComplexCard';
import DataRow from '../../components/DataRow/DataRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataSMSBasicRow from '../../components/DataSMSBasicRow/DataSMSBasicRow';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { isEmpty } from 'lodash';
import ValueFormatting from '../../shared/ValueFormatting';
import DocumentList from '../../components/DocumentList/DocumentList';
import Employees from '../Employees/Employees';

const { TabPane } = Tabs;

const Account = props => {
    const id = props.match.params.id;

    const location = useLocation();
    const dispatch = useDispatch();
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.accounts.isRecordLoading);
    const error = useSelector(state => state.accounts.recordError);
    const account = useSelector(state => state.accounts.record);

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAccount(id));
    }, [id]);

    useEffect(() => {
        if (account && account.logoFileId) {
            axiosAuthenticated.get('/documents/' + account.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [account]);

    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Employees';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/accounts` },
        path: previousPath ? previousPath : `/accounts`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Accounts',
    },
    {
        path: `/accounts/${id ? id : null}`,
        breadcrumbName: `${account ? account.name : ''}`,
    },
    ];

    if (account && account.id === id) {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Header
                    title={account && account.name ? account.name : 'Loading...'}
                    title2={account && account ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                    logo={imageUrl}
                    icon={<FontAwesomeIcon className="anticon" icon={faUsers} />}
                    breadcrumbs={breadcrumbs}
                    highlights={account && account.isCarrier ? [
                        {
                            title: 'DOT #',
                            value: `${account && account.carrier ? account.carrier.dotNum : ''}`
                        },
                        {
                            title: 'MC #',
                            value: `${account && account.carrier ? account.carrier.mcNum : ''}`
                        },
                        {
                            title: 'SMS Score',
                            value: `${account && account.carrier && account.carrier.smsBasic && account.carrier.smsBasic.weightedAvgSMSScore ? account.carrier.smsBasic.weightedAvgSMSScore : 'N/A'}`
                        },
                    ] : (account && account.isShipper ? [
                        {
                            title: 'EIN #',
                            value: `${account ? account.ein : ''}`
                        },
                        {
                            title: 'D&B #',
                            value: `${account && account.shipper ? account.shipper.dbNum : ''}`
                        },
                        {
                            title: 'PBB #',
                            value: `${account && account.shipper ? account.shipper.blueBookNum : ''}`
                        },
                    ] : [])}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            {(account !== null && isLoading === false) ? (
                                <Row gutter={[16, 16]}>
                                    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                        <ComplexCard
                                            title="Account Details"
                                            headStyle={{ fontSize: 14 }}
                                        >
                                            <Row>
                                                <Col xs={24} md={24} lg={12} xl={12}>
                                                    <Avatar shape="square" size={128} icon={<UserOutlined />} src={imageUrl} />
                                                    {account.irisId !== undefined && account.irisId !== null ? <DataRow title="Account ID" value={account.irisId} dataType="String" /> : null}
                                                    {account.name !== undefined && account.name !== null ? <DataRow title="Account Name" value={account.name} dataType="String" /> : null}
                                                    {account.ein !== undefined && account.ein !== null ? <DataRow title="EIN" value={account.ein} dataType="String" /> : null}
                                                    {account.email !== undefined && account.email !== null ? <DataRow title="Email Address" value={account.email} dataType="String" /> : null}
                                                    {account.phone !== undefined && account.phone !== null ? <DataPhoneNumberRow title="Phone Number" phone={account.phone} phoneExt={account.phoneExt} /> : null}
                                                    {account.afterHoursPhone !== undefined && account.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone Number" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                                                    {account.fax !== undefined && account.fax !== null ? <DataRow title="Fax Number" value={account.fax} dataType="PhoneNumber" /> : null}
                                                    <DataRow title="Is Active" value={account.isActive} dataType="Boolean" />
                                                    <DataRow title="Is Verified" value={account.isVerified} dataType="Boolean" />
                                                    <DataRow title="Is Carrier" value={account.isCarrier} dataType="Boolean" />
                                                    <DataRow title="Is Shipper" value={account.isShipper} dataType="Boolean" />
                                                    <DataRow title="Is Receiver" value={account.isReceiver} dataType="Boolean" />
                                                    <DataRow title="Is Producer" value={account.isProducer} dataType="Boolean" />
                                                </Col>
                                                <Col xs={24} md={24} lg={12} xl={12}>
                                                    {account.pointOfContact !== undefined && account.pointOfContact !== null ? <DataContactInfoRow title="Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.pointOfContact} /> : null}
                                                    {account.companyAddress !== undefined && account.companyAddress !== null ? <DataAddressRow title="Company Address" valueStyle={{ marginLeft: 16 }} address={account.companyAddress} /> : null}
                                                    {account.shippingAddress !== undefined && account.shippingAddress !== null ? <DataAddressRow title="Mailing Address" valueStyle={{ marginLeft: 16 }} address={account.shippingAddress} /> : null}
                                                </Col>
                                            </Row>
                                        </ComplexCard>
                                    </Col>
                                    {(account.shipper !== undefined && account.shipper !== null && !isEmpty(account.shipper)) ? (
                                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Shipper Details"
                                                headStyle={{ fontSize: 14 }}
                                            >
                                                <Row>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {account.shipper.irisId !== undefined && account.shipper.irisId !== null ? <DataRow title="Shipper ID" value={account.shipper.irisId} dataType="String" /> : null}
                                                        {account.shipper.email !== undefined && account.shipper.email !== null ? <DataRow title="Email Address" value={account.shipper.email} dataType="String" /> : null}
                                                        {account.shipper.phone !== undefined && account.shipper.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={account.shipper.phone} phoneExt={account.shipper.phoneExt} /> : null}
                                                        {account.shipper.fax !== undefined && account.shipper.fax !== null ? <DataRow title="Fax Number" value={account.shipper.fax} dataType="PhoneNumber" /> : null}
                                                        {account.shipper.dbNum !== undefined && account.shipper.dbNum !== null ? <DataRow title="D&B #" value={account.shipper.dbNum} dataType="String" /> : null}
                                                        {account.shipper.blueBookNum !== undefined && account.shipper.blueBookNum !== null ? <DataRow title="Produce Blue Book #" value={account.shipper.blueBookNum} dataType="String" /> : null}
                                                    </Col>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {account.shipper.pointOfContact !== undefined && account.shipper.pointOfContact !== null ? <DataContactInfoRow title="Shipper Operations Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.shipper.pointOfContact} /> : null}
                                                        {account.shipper.accountsPayable !== undefined && account.shipper.accountsPayable !== null ? <DataContactInfoRow title="Contact Information for Accounts Payable" valueStyle={{ marginLeft: 16 }} contact={account.shipper.accountsPayable} /> : null}
                                                        {account.shipper.billingAddress !== undefined && account.shipper.billingAddress !== null ? <DataAddressRow title="Accounts Payable Address" valueStyle={{ marginLeft: 16 }} address={account.shipper.billingAddress} /> : null}
                                                    </Col>
                                                </Row>
                                            </ComplexCard>
                                        </Col>
                                    ) : null}
                                    {(account.carrier !== undefined && account.carrier !== null && !isEmpty(account.carrier)) ? (
                                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                            <ComplexCard
                                                title="Carrier Details"
                                                headStyle={{ fontSize: 14 }}
                                            >
                                                <Row>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {account.carrier.irisId !== undefined && account.carrier.irisId !== null ? <DataRow title="Carrier ID" value={account.carrier.irisId} dataType="String" /> : null}
                                                        {account.carrier.email !== undefined && account.carrier.email !== null ? <DataRow title="Email Address" value={account.carrier.email} dataType="String" /> : null}
                                                        {account.carrier.phone !== undefined && account.carrier.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={account.carrier.phone} phoneExt={account.carrier.phoneExt} /> : null}
                                                        {account.carrier.fax !== undefined && account.carrier.fax !== null ? <DataRow title="Fax Number" value={account.carrier.fax} dataType="PhoneNumber" /> : null}
                                                        {account.carrier.dotNum !== undefined && account.carrier.dotNum !== null ? <DataRow title="DOT #" value={account.carrier.dotNum} dataType="String" /> : null}
                                                        {account.carrier.mcNum !== undefined && account.carrier.mcNum !== null ? <DataRow title="MC #" value={account.carrier.mcNum} dataType="String" /> : null}
                                                        {account.carrier.preferredPaymentMethod !== undefined && account.carrier.preferredPaymentMethod !== null ? <DataRow title="Preferred Payment Method" value={account.carrier.preferredPaymentMethod} dataType="PreferredPaymentMethod" /> : null}
                                                        {account.carrier.smsBasic !== undefined && account.carrier.smsBasic !== null ? <DataSMSBasicRow title="SMS Basic Scores" valueStyle={{ marginLeft: 16 }} smsBasic={account.carrier.smsBasic} /> : null}
                                                    </Col>
                                                    <Col xs={24} md={24} lg={12} xl={12}>
                                                        {account.carrier.pointOfContact !== undefined && account.carrier.pointOfContact !== null ? <DataContactInfoRow title="Carrier Operations Point of Contact" valueStyle={{ marginLeft: 16 }} contact={account.carrier.pointOfContact} /> : null}
                                                        {account.carrier.accountsReceivable !== undefined && account.carrier.accountsReceivable !== null ? <DataContactInfoRow title="Contact Information for Accounts Receivable" valueStyle={{ marginLeft: 16 }} contact={account.carrier.accountsReceivable} /> : null}
                                                        {account.carrier.shippingAddress !== undefined && account.carrier.shippingAddress !== null ? <DataAddressRow title="Accounts Receivable Address" valueStyle={{ marginLeft: 16 }} address={account.carrier.shippingAddress} /> : null}
                                                    </Col>
                                                </Row>
                                            </ComplexCard>
                                        </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <div style={{ padding: 24 }}>
                            {account && <DocumentList entityType="ACCOUNT" account={account} entityId={account.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
                            {account !== undefined && account !== null && account.isVerified === false && entityType === 'STAFF' ? (
                                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { console.log(account); verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
                            ) : null}
                        </div>
                    </TabPane>
                    <TabPane tab="Employees" key="employees">
                        <div style={{ padding: 24 }}>
                            <Employees accountId={account.id} breadcrumbs={breadcrumbs} />
                        </div>
                    </TabPane>
                </Tabs>
            </Spin>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(Account);