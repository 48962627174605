import React from 'react';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../DataRow/DataRow';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';

const stringFormatter = new StringFormatter();

const DataContactInfoRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, title = null, titleStyle = null, valueStyle = null, name = null, email = null, phone = null, phoneExt = null, contact = null, separateLines = false, noColon = false }) => {
    if (contact !== null && contact !== undefined) {
        name = contact.name;
        email = contact.email;
        phone = contact.phone;
        phoneExt = contact.phoneExt;
    }

    if (separateLines !== undefined && separateLines !== null && separateLines === true) {
        return (
            <>
                {name !== undefined && name !== null ? <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Name`} value={name} dataType="String" /> : null}
                {phone !== undefined && phone !== null ? <DataPhoneNumberRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Phone`} phone={phone} phoneExt={phoneExt} /> : null}
                {email !== undefined && email !== null ? <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} noColon={noColon} title={`${title ? title + ' ' : ''}Email`} value={email} dataType="String" /> : null}
            </>
        );
    } else {
        return (
            <div className={className} style={style}>
                {title !== undefined && title !== null ? (<div className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </div>) : null}
                {name !== undefined && name !== null ? <div className={valueClassName} style={valueStyle}>{name}</div> : null}
                {email !== undefined && email !== null ? <div className={valueClassName} style={valueStyle}>{email}</div> : null}
                {phone !== undefined && phone !== null ? <div className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedPhoneNumber(phone, phoneExt)}</div> : null}
            </div>
        );
    }
};

export default DataContactInfoRow;