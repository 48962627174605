import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Alert, Input, Spin, Checkbox, Select } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from "lodash";
import UploadFile from "../UploadFile/UploadFile";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';
import { removeEmpty } from '../../shared/objectUtils';
import Fieldset from '../FormFieldset/FormFieldset';
import { checkEmailAddressReducer, checkPhoneNumberReducer, checkEmailAddress, checkPhoneNumber, isValidEmailAddress } from '../../shared/formUtils';
import { LoadingOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const NewDriver = props => {
    const accountId = props.accountId;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountUsers.isRecordAddLoading);
    const error = useSelector(state => state.accountUsers.addRecordError);
    const accounts = useSelector(state => state.orchestrator.accounts);

    const [{ emailAddressExists, checkEmailAddressHasError, isCheckEmailAddressLoading, checkEmailAddressError }, checkEmailAddressDispatch] = useReducer(checkEmailAddressReducer, {
        emailAddressExists: null,
        isCheckEmailAddressLoading: false,
        checkEmailAddressHasError: false,
        checkEmailAddressError: null,
    });

    const [{ phoneNumberExists, checkPhoneNumberHasError, isCheckPhoneNumberLoading, checkPhoneNumberError }, checkPhoneNumberDispatch] = useReducer(checkPhoneNumberReducer, {
        phoneNumberExists: null,
        isCheckPhoneNumberLoading: false,
        checkPhoneNumberHasError: false,
        checkPhoneNumberError: null,
    });

    const [hazmatLicenseFrontFile, setHazmatLicenseFrontFile] = useState(null);
    const [hazmatLicenseBackFile, setHazmatLicenseBackFile] = useState(null);
    const [driverLicenseFrontFile, setDriverLicenseFrontFile] = useState(null);
    const [driverLicenseBackFile, setDriverLicenseBackFile] = useState(null);
    const [commercialLicenseFrontFile, setCommercialLicenseFrontFile] = useState(null);
    const [commercialLicenseBackFile, setCommercialLicenseBackFile] = useState(null);
    const [pneumaticLicenseFrontFile, setPneumaticLicenseFrontFile] = useState(null);
    const [pneumaticLicenseBackFile, setPneumaticLicenseBackFile] = useState(null);
    const [mcCertificateLicenseFrontFile, setMcCertificateLicenseFrontFile] = useState(null);
    const [mcCertificateLicenseBackFile, setMcCertificateLicenseBackFile] = useState(null);
    const [explosiveCertifiedLicenseFrontFile, setExplosiveCertifiedLicenseFrontFile] = useState(null);
    const [explosiveCertifiedLicenseBackFile, setExplosiveCertifiedLicenseBackFile] = useState(null);
    const [mshaLicenseFrontFile, setMshaLicenseFrontFile] = useState(null);
    const [mshaLicenseBackFile, setMshaLicenseBackFile] = useState(null);

    const [carriers, setCarriers] = useState([]);
    const [carrierId, setCarrierId] = useState(null);

    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [hasValidEmailAddress, setHasValidEmailAddres] = useState(false);
    const [hasValidPhoneNumber, setHasValidPhoneNumber] = useState(false);

    useEffect(() => {
        //console.log(`emailAddress: ${emailAddress}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (emailAddress !== undefined && emailAddress !== null && emailAddress.length >= 4) {
                if (isValidEmailAddress(emailAddress)) {
                    checkEmailAddress(emailAddress, checkEmailAddressDispatch, token);
                }
            }
        }, 500);
        return () => cancel("No longer latest query") || clearTimeout(timeOutId);
    }, [emailAddress]);

    useEffect(() => {
        //console.log(`phoneNumber: ${phoneNumber}`);
        const { cancel, token } = axios.CancelToken.source();
        const timeOutId = setTimeout(() => {
            if (phoneNumber !== undefined && phoneNumber !== null && phoneNumber.length === 10) {
                checkPhoneNumber(phoneNumber, checkPhoneNumberDispatch, token);
            }
        }, 500);
        return () => cancel("No longer latest query") || clearTimeout(timeOutId);
    }, [phoneNumber]);

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            setCarrierId(accountId);
        }
        const filteredAccounts = accounts.filter(a => a.isCarrier === true);
        setCarriers(filteredAccounts);
    }, [accountId, accounts]);

    const handleAccountChange = (value) => {
        //console.log(`selected ${value}`);
        setCarrierId(value);
    };

    const onEmailAddressChange = (value) => {
        if (value !== undefined && value !== null && value.length >= 4) {
            if (methods.errors.email !== undefined && methods.errors.email !== null && !isEmpty(methods.errors.email)) {
                checkEmailAddressDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid email address' } });
            }
            //console.log(value);
            setEmailAddress(value);
        } else {
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    };

    const onPhoneNumberChange = (value) => {
        if (value !== undefined && value !== null && value.length === 10) {
            if (methods.errors.phone !== undefined && methods.errors.phone !== null && !isEmpty(methods.errors.phone)) {
                checkPhoneNumberDispatch({ type: "FETCH_FAILURE", payload: { error: 'You must first enter a valid phone number' } });
            }
            //console.log(value);
            setPhoneNumber(value);
        } else {
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    };

    useEffect(() => {
        // console.log(emailAddress);
        // console.log(methods.errors.email);
        // console.log(emailAddressExists);
        if (emailAddress !== undefined && emailAddress !== null) {
            if (methods.errors.email === undefined || methods.errors.phone === null || isEmpty(methods.errors.phone)) {
                if (emailAddressExists !== null && emailAddressExists === false) {
                    setHasValidEmailAddres(true);
                    // console.log('Has a valid email address');
                } else {
                    setHasValidEmailAddres(false);
                }
            } else {
                setHasValidEmailAddres(false);
                checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidEmailAddres(false);
            checkEmailAddressDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, emailAddress, emailAddressExists]);

    useEffect(() => {
        // console.log(phoneNumber);
        // console.log(methods.errors.phone);
        // console.log(phoneNumberExists);
        if (phoneNumber !== undefined && phoneNumber !== null) {
            if (methods.errors.phone === undefined || methods.errors.phone === null || isEmpty(methods.errors.phone)) {
                if (phoneNumberExists !== null && phoneNumberExists === false) {
                    setHasValidPhoneNumber(true);
                    // console.log('Has a valid phone number');
                } else {
                    setHasValidPhoneNumber(false);
                }
            } else {
                setHasValidPhoneNumber(false);
                checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
            }
        } else {
            setHasValidPhoneNumber(false);
            checkPhoneNumberDispatch({ type: "FETCH_CLEAR" });
        }
    }, [methods.errors, phoneNumber, phoneNumberExists]);

    const onSubmit = (data) => {
        let cleanedData = removeEmpty(data);

        let emailAndOrPhoneAreValid = false;
        if (cleanedData.email !== undefined && cleanedData.email !== null) {
            if (hasValidEmailAddress === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }
        if (cleanedData.phone !== undefined && cleanedData.phone !== null) {
            if (hasValidPhoneNumber === true) {
                emailAndOrPhoneAreValid = true;
            } else {
                emailAndOrPhoneAreValid = false;
            }
        }

        if (!isEmpty(cleanedData)) {
            if (hazmatLicenseFrontFile) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                    cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                        methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                        methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("hazmatLicenseExpiryDate");
                    methods.clearErrors("hazmatLicenseNumber");
                }
                cleanedData.hazmatLicenseFrontFile = hazmatLicenseFrontFile;
            }
            if (hazmatLicenseBackFile) {
                if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null ||
                    cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                    if (cleanedData.hazmatLicenseExpiryDate === undefined || cleanedData.hazmatLicenseExpiryDate === null) {
                        methods.setError("hazmatLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.hazmatLicenseNumber === undefined || cleanedData.hazmatLicenseNumber === null) {
                        methods.setError("hazmatLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("hazmatLicenseExpiryDate");
                    methods.clearErrors("hazmatLicenseNumber");
                }
                cleanedData.hazmatLicenseBackFile = hazmatLicenseBackFile;
            }

            if (driverLicenseFrontFile) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                    cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                        methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                        methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("driverLicenseExpiryDate");
                    methods.clearErrors("driverLicenseNumber");
                }
                cleanedData.driverLicenseFrontFile = driverLicenseFrontFile;
            }
            if (driverLicenseBackFile) {
                if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null ||
                    cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                    if (cleanedData.driverLicenseExpiryDate === undefined || cleanedData.driverLicenseExpiryDate === null) {
                        methods.setError("driverLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.driverLicenseNumber === undefined || cleanedData.driverLicenseNumber === null) {
                        methods.setError("driverLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("driverLicenseExpiryDate");
                    methods.clearErrors("driverLicenseNumber");
                }
                cleanedData.driverLicenseBackFile = driverLicenseBackFile;
            }

            if (commercialLicenseFrontFile) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                    cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                        methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                        methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("commercialLicenseExpiryDate");
                    methods.clearErrors("commercialLicenseNumber");
                }
                cleanedData.commercialLicenseFrontFile = commercialLicenseFrontFile;
            }
            if (commercialLicenseBackFile) {
                if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null ||
                    cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                    if (cleanedData.commercialLicenseExpiryDate === undefined || cleanedData.commercialLicenseExpiryDate === null) {
                        methods.setError("commercialLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.commercialLicenseNumber === undefined || cleanedData.commercialLicenseNumber === null) {
                        methods.setError("commercialLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("commercialLicenseExpiryDate");
                    methods.clearErrors("commercialLicenseNumber");
                }
                cleanedData.commercialLicenseBackFile = commercialLicenseBackFile;
            }

            if (pneumaticLicenseFrontFile) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                    cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                        methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                        methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("pneumaticLicenseExpiryDate");
                    methods.clearErrors("pneumaticLicenseNumber");
                }
                cleanedData.pneumaticLicenseFrontFile = pneumaticLicenseFrontFile;
            }
            if (pneumaticLicenseBackFile) {
                if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null ||
                    cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                    if (cleanedData.pneumaticLicenseExpiryDate === undefined || cleanedData.pneumaticLicenseExpiryDate === null) {
                        methods.setError("pneumaticLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.pneumaticLicenseNumber === undefined || cleanedData.pneumaticLicenseNumber === null) {
                        methods.setError("pneumaticLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("pneumaticLicenseExpiryDate");
                    methods.clearErrors("pneumaticLicenseNumber");
                }
                cleanedData.pneumaticLicenseBackFile = pneumaticLicenseBackFile;
            }

            if (mcCertificateLicenseFrontFile) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                    cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                        methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                        methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mcCertificateLicenseExpiryDate");
                    methods.clearErrors("mcCertificateLicenseNumber");
                }
                cleanedData.mcCertificateLicenseFrontFile = mcCertificateLicenseFrontFile;
            }
            if (mcCertificateLicenseBackFile) {
                if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null ||
                    cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                    if (cleanedData.mcCertificateLicenseExpiryDate === undefined || cleanedData.mcCertificateLicenseExpiryDate === null) {
                        methods.setError("mcCertificateLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.mcCertificateLicenseNumber === undefined || cleanedData.mcCertificateLicenseNumber === null) {
                        methods.setError("mcCertificateLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mcCertificateLicenseExpiryDate");
                    methods.clearErrors("mcCertificateLicenseNumber");
                }
                cleanedData.mcCertificateLicenseBackFile = mcCertificateLicenseBackFile;
            }

            if (explosiveCertifiedLicenseFrontFile) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                    cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                        methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                        methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                    methods.clearErrors("explosiveCertifiedLicenseNumber");
                }
                cleanedData.explosiveCertifiedLicenseFrontFile = explosiveCertifiedLicenseFrontFile;
            }
            if (explosiveCertifiedLicenseBackFile) {
                if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null ||
                    cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                    if (cleanedData.explosiveCertifiedLicenseExpiryDate === undefined || cleanedData.explosiveCertifiedLicenseExpiryDate === null) {
                        methods.setError("explosiveCertifiedLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.explosiveCertifiedLicenseNumber === undefined || cleanedData.explosiveCertifiedLicenseNumber === null) {
                        methods.setError("explosiveCertifiedLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("explosiveCertifiedLicenseExpiryDate");
                    methods.clearErrors("explosiveCertifiedLicenseNumber");
                }
                cleanedData.explosiveCertifiedLicenseBackFile = explosiveCertifiedLicenseBackFile;
            }

            if (mshaLicenseFrontFile) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                    cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                        methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                        methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mshaLicenseExpiryDate");
                    methods.clearErrors("mshaLicenseNumber");
                }
                cleanedData.mshaLicenseFrontFile = mshaLicenseFrontFile;
            }
            if (mshaLicenseBackFile) {
                if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null ||
                    cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                    if (cleanedData.mshaLicenseExpiryDate === undefined || cleanedData.mshaLicenseExpiryDate === null) {
                        methods.setError("mshaLicenseExpiryDate", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    if (cleanedData.mshaLicenseNumber === undefined || cleanedData.mshaLicenseNumber === null) {
                        methods.setError("mshaLicenseNumber", { type: "required", message: "Required if you are uploading the corresponding file." });
                    }
                    return;
                } else {
                    methods.clearErrors("mshaLicenseExpiryDate");
                    methods.clearErrors("mshaLicenseNumber");
                }
                cleanedData.mshaLicenseBackFile = mshaLicenseBackFile;
            }

            let driver = {};
            driver.hazmatLicenseFrontFile = cleanedData.hazmatLicenseFrontFile;
            driver.hazmatLicenseBackFile = cleanedData.hazmatLicenseBackFile;
            driver.driverLicenseFrontFile = cleanedData.driverLicenseFrontFile;
            driver.driverLicenseBackFile = cleanedData.driverLicenseBackFile;
            driver.commercialLicenseFrontFile = cleanedData.commercialLicenseFrontFile;
            driver.commercialLicenseBackFile = cleanedData.commercialLicenseBackFile;
            driver.pneumaticLicenseFrontFile = cleanedData.pneumaticLicenseFrontFile;
            driver.pneumaticLicenseBackFile = cleanedData.pneumaticLicenseBackFile;
            driver.mcCertificateLicenseFrontFile = cleanedData.mcCertificateLicenseFrontFile;
            driver.mcCertificateLicenseBackFile = cleanedData.mcCertificateLicenseBackFile;
            driver.explosiveCertifiedLicenseFrontFile = cleanedData.explosiveCertifiedLicenseFrontFile;
            driver.explosiveCertifiedLicenseBackFile = cleanedData.explosiveCertifiedLicenseBackFile;
            driver.mshaLicenseFrontFile = cleanedData.mshaLicenseFrontFile;
            driver.mshaLicenseBackFile = cleanedData.mshaLicenseBackFile;

            driver.hazmatLicenseExpiryDate = cleanedData.hazmatLicenseExpiryDate;
            driver.driverLicenseExpiryDate = cleanedData.driverLicenseExpiryDate;
            driver.commercialLicenseExpiryDate = cleanedData.commercialLicenseExpiryDate;
            driver.pneumaticLicenseExpiryDate = cleanedData.pneumaticLicenseExpiryDate;
            driver.mcCertificateLicenseExpiryDate = cleanedData.mcCertificateLicenseExpiryDate;
            driver.explosiveCertifiedLicenseExpiryDate = cleanedData.explosiveCertifiedLicenseExpiryDate;
            driver.mshaLicenseExpiryDate = cleanedData.mshaLicenseExpiryDate;

            driver.hazmatLicenseNumber = cleanedData.hazmatLicenseNumber;
            driver.driverLicenseNumber = cleanedData.driverLicenseNumber;
            driver.commercialLicenseNumber = cleanedData.commercialLicenseNumber;
            driver.pneumaticLicenseNumber = cleanedData.pneumaticLicenseNumber;
            driver.mcCertificateLicenseNumber = cleanedData.mcCertificateLicenseNumber;
            driver.explosiveCertifiedLicenseNumber = cleanedData.explosiveCertifiedLicenseNumber;
            driver.mshaLicenseNumber = cleanedData.mshaLicenseNumber;

            let payload = {
                email: data.email,
                phone: data.phone,
                firstName: data.firstName,
                middleName: data.middleName,
                lastName: data.lastName,
                isContractor: data.isContractor,
                isDriver: true,
                driver: driver
            };

            if (emailAndOrPhoneAreValid === true) {
                dispatch(actionCreators.addAccountUser(payload, carrierId));
            }
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddAccountUser());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addAccountUserErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    {(accountId === undefined || accountId === null) &&
                        <Fieldset>
                            <legend>Choose a Carrier</legend>
                            <FormItem {...formItemLayout} label="Carrier" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Carrier"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { handleAccountChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {carriers.map(a => <Option value={a.id} key={a.id}>{a.name}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="carrierId"
                            />
                        </Fieldset>
                    }
                    <Fieldset legend="Email or Phone Number are Required">
                        <FormItem {...formItemLayout} label="Email Address"
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    placeholder="Email Address"
                                    addonAfter={isCheckEmailAddressLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onEmailAddressChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                required: false,
                                minLength: { value: 4, message: 'Email Address must be at least 4 characters long' },
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="email"
                        />
                        {checkEmailAddressHasError && <Alert message={checkEmailAddressError} type="error" />}
                        <FormItem {...formItemLayout} label="Personal Phone #"
                            render={({ onChange, onBlur, value, name }) => (
                                <Input
                                    placeholder="Personal Phone #"
                                    addonAfter={isCheckPhoneNumberLoading ? <LoadingOutlined /> : <PhoneOutlined />}
                                    onBlur={onBlur}
                                    onChange={e => { onPhoneNumberChange(e.target.value); onChange(e.target.value); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name="phone"
                            defaultValue={''}
                        />
                        {checkPhoneNumberHasError && <Alert message={checkPhoneNumberError} type="error" />}
                    </Fieldset>
                    {(hasValidEmailAddress === true || hasValidPhoneNumber === true) && (
                        <>
                            <Fieldset>
                                <legend>User Account will be created with these details if one doesn't already exist.</legend>
                                <FormItem {...formItemLayout} label="First Name" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="First Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="firstName"
                                />
                                <FormItem {...formItemLayout} label="Middle Name"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Middle Name" />}
                                    rules={{ required: false }}
                                    name="middleName"
                                />
                                <FormItem {...formItemLayout} label="Last Name" required
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Last Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="lastName"
                                />
                                <FormItem {...formItemLayout} label="Send Invitation (email or text) to Driver"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Checkbox
                                            onBlur={onBlur}
                                            onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                            name={name}
                                        />
                                    )}
                                    name="isInvited"
                                    defaultValue={true}
                                />
                            </Fieldset>
                            <Fieldset>
                                <legend>Driver Details</legend>
                                <FormItem {...formItemLayout} label="Is Contractor"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Checkbox
                                            onBlur={onBlur}
                                            onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                            name={name}
                                        />
                                    )}
                                    name="isContractor"
                                    defaultValue={false}
                                />
                            </Fieldset>
                            <Fieldset>
                                <legend>Commercial Drivers License</legend>
                                <FormItem {...formItemLayout} label="Commercial Drivers License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Commercial Drivers License #" />}
                                    rules={{ required: false }}
                                    name="commercialLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="commercialLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="commercialLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setCommercialLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setCommercialLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="commercialLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setCommercialLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setCommercialLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>Personal Drivers License</legend>
                                <FormItem {...formItemLayout} label="Drivers License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Drivers License #" />}
                                    rules={{ required: false }}
                                    name="driverLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="driverLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="driverLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setDriverLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setDriverLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="driverLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setDriverLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setDriverLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>Hazmat License</legend>
                                <FormItem {...formItemLayout} label="HazMat License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="HazMat License #" />}
                                    rules={{ required: false }}
                                    name="hazmatLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="hazmatLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="hazmatLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setHazmatLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setHazmatLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="hazmatLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setHazmatLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setHazmatLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>Pneumatic License</legend>
                                <FormItem {...formItemLayout} label="Pneumatic License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Pneumatic License #" />}
                                    rules={{ required: false }}
                                    name="pneumaticLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="pneumaticLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="pneumaticLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setPneumaticLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setPneumaticLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="pneumaticLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setPneumaticLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setPneumaticLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>MC Certificate License</legend>
                                <FormItem {...formItemLayout} label="MC Certificate License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Certificate License #" />}
                                    rules={{ required: false }}
                                    name="mcCertificateLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="mcCertificateLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="mcCertificateLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMcCertificateLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMcCertificateLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="mcCertificateLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMcCertificateLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMcCertificateLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>Explosive Certified License</legend>
                                <FormItem {...formItemLayout} label="Explosive Certified License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Explosive Certified License #" />}
                                    rules={{ required: false }}
                                    name="explosiveCertifiedLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="explosiveCertifiedLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="explosiveCertifiedLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setExplosiveCertifiedLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setExplosiveCertifiedLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="explosiveCertifiedLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setExplosiveCertifiedLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setExplosiveCertifiedLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>MSHA License</legend>
                                <FormItem {...formItemLayout} label="MSHA License #"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MSHA License #" />}
                                    rules={{ required: false }}
                                    name="mshaLicenseNumber"
                                />
                                <FormItem {...formItemLayout} label="Expiration Date"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name="mshaLicenseExpiryDate"
                                />
                                <FormItemFile {...formItemLayout} label="Front Image" name="mshaLicenseFrontFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMshaLicenseFrontFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMshaLicenseFrontFile(null);
                                        }}
                                        message="Please upload a photo of the front of the license."
                                    />
                                </FormItemFile>
                                <FormItemFile {...formItemLayout} label="Back Image" name="mshaLicenseBackFile">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setMshaLicenseBackFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setMshaLicenseBackFile(null);
                                        }}
                                        message="Please upload a photo of the back of the license."
                                    />
                                </FormItemFile>
                            </Fieldset>
                        </>
                    )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} cancelDisabled={(isLoading === true && error === null)} submitDisabled={(isLoading === true && error === null) || (hasValidEmailAddress === false && hasValidPhoneNumber === false)} submitText="Create Driver" />
            </Form>
        </FormProvider>
    );
};

export default NewDriver;
