import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Empty, List, Drawer, Avatar, Tooltip, Button, Alert } from "antd";
import classes from './NotificationsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faTimesCircle, faBell, faPallet, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import MomentDate from '../../shared/dateFormatter';
import InfiniteScroll from 'react-infinite-scroller';

const NotificationsList = (props) => {
    const fullWidth = global.window.innerWidth;
    const scrollParentRef = useRef(null);

    const userId = useSelector(state => state.auth.userId);

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.notifications.isLoading);
    const notifications = useSelector(state => state.notifications.records);
    const currentPage = useSelector(state => state.notifications.currentPage);
    const totalPages = useSelector(state => state.notifications.totalPages);
    const totalCount = useSelector(state => state.notifications.totalCount);
    const unreadCount = useSelector(state => state.notifications.unreadCount);
    const error = useSelector(state => state.notifications.addRecordError);

    const markRead = (id) => {
        dispatch(actionCreators.readNotification(id, userId));
    }

    const markAllRead = () => {
        //console.log('mark all read');

        let unreadNotifications = notifications.filter(n => {
            return !n.read.some(r => { return r.readBy === userId; });
        });

        //console.log(unreadNotifications);

        let unreadNotificationIds = unreadNotifications.map((item) => {
            return item.id;
        });

        dispatch(actionCreators.readMultipleNotifications(unreadNotificationIds, userId));
    }

    const viewAll = () => {
        props.history.push({ pathname: "/notifications" });
        props.onClose();
    };

    const goToLoad = (loadId) => {
        props.history.push({ pathname: "/loads/" + loadId });
        props.onClose();
    };

    const getNotifications = () => {
        if (userId !== undefined && userId !== null) {
            dispatch(actionCreators.fetchMoreNotifications("STAFF", userId, userId));
        }
    };

    useEffect(() => {
        if (notifications.length === 0) {
            getNotifications();
        }
    }, []);

    const handleInfiniteOnLoad = () => {
        //console.log(`Current Page: ${currentPage}, Total Pages: ${totalPages}`);
        if (currentPage + 1 <= totalPages && isLoading === false) {
            //console.log('get more');
            dispatch(actionCreators.fetchMoreNotifications("STAFF", userId, userId));
        } else {
            //console.log('no more items to get');
        }
    };

    return (
        <Drawer
            title={'Notifications'}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={props.onClose}
            visible={props.visible}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            bodyStyle={{ overflow: 'hidden' }}
            drawerStyle={{ overflow: 'hidden' }}
            destroyOnClose={true}
            closable={true}
            maskClosable={true}
        >
            {(notifications.length === 0) ? (
                <>
                    <Empty
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <span>No New or Unread Notifications</span>
                        }
                    >
                        <Button type="primary" onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</Button>
                    </Empty>
                    <div className={classes.bottomBar}>
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
            {(notifications.length > 0) ? (
                <>
                    {error && <Alert message={`${error}`} type="error" />}

                    <div className={classes.infiniteScrollContainer} ref={(ref) => scrollParentRef.current = ref}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={isLoading === false && currentPage + 1 <= totalPages}
                            useWindow={false}
                            getScrollParent={() => scrollParentRef.current}
                        >
                            <List
                                itemLayout="vertical"
                                dataSource={notifications}
                                size="small"
                                loading={isLoading}
                                renderItem={(item, i) => {

                                    const itemClass = item.read.some(r => { return r.readBy === userId; }) ? classes.read : '';
                                    let leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>;
                                    let notificationColor = '#ffffff';

                                    if (item.severity) {
                                        switch (item.severity) {
                                            case 'critical':
                                                leftIcon = <Avatar style={{ backgroundColor: 'red', color: '#ffffff75' }}><FontAwesomeIcon icon={faTimesCircle} size="lg" /></Avatar>
                                                notificationColor = 'red';
                                                break;
                                            case 'high':
                                                leftIcon = <Avatar style={{ backgroundColor: 'orange', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationTriangle} size="lg" /></Avatar>
                                                notificationColor = 'orange';
                                                break;
                                            case 'medium':
                                                leftIcon = <Avatar style={{ backgroundColor: 'yellow', color: '#00000075' }}><FontAwesomeIcon icon={faExclamationCircle} size="lg" /></Avatar>
                                                notificationColor = 'yellow';
                                                break;
                                            case 'low':
                                                leftIcon = <Avatar style={{ backgroundColor: 'blue', color: '#ffffff75' }}><FontAwesomeIcon icon={faInfoCircle} size="lg" /></Avatar>
                                                notificationColor = 'blue';
                                                break;
                                            default:
                                                leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faBell} size="lg" /></Avatar>
                                                notificationColor = 'gray';
                                        }
                                    }

                                    let actionButtons = [];
                                    if (!item.read.some(r => { return r.readBy === userId; })) {
                                        actionButtons.push(<Tooltip placement="top" title={'Mark Read'}><Button key="1" type="link" onClick={(e) => { e.stopPropagation(); markRead(item.id); }} style={null}><FontAwesomeIcon icon={faEnvelopeOpen} size="sm" /> <span>&nbsp;&nbsp;Mark Read</span></Button></Tooltip>);
                                    }
                                    actionButtons.push(<Tooltip placement="top" title={'Go To Load'}><Button key="2" type="link" onClick={(e) => { e.stopPropagation(); goToLoad(item.loadId); }} style={null}><FontAwesomeIcon icon={faPallet} size="sm" /> <span>&nbsp;&nbsp;Go To Load</span></Button></Tooltip>);

                                    return (
                                        <List.Item
                                            className={classes.item + ' ' + itemClass}
                                            key={item.id}
                                            actions={actionButtons}>
                                            <List.Item.Meta
                                                avatar={leftIcon}
                                                className={classes.meta}
                                                title={
                                                    <>
                                                        <div className={classes.title}>{item.subject}</div>
                                                    </>
                                                }
                                                description={
                                                    <>
                                                        <div className={classes.description}>
                                                            <div className={classes.description}>{item.message}</div>
                                                            <div className={classes.dateTime}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(item.createdAt)}</div>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                    <div className={classes.bottomBar}>
                        <div onClick={(e) => { e.stopPropagation(); markAllRead(); }}>Mark All Read</div>
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
        </Drawer>
    );
};

export default withRouter(NotificationsList);