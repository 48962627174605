import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Spin, Tabs, Button, Drawer, Row, Col, Menu, Dropdown, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { useLocation } from 'react-router';
import { ControlOutlined, DownOutlined } from '@ant-design/icons';
import EditUser from "../../components/EditUser/EditUser";
import ComplexDataLinkCard from '../../components/ComplexDataLinkCard/ComplexDataLinkCard';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataRow from '../../components/DataRow/DataRow';
import axios from 'axios';
import OpenLoads from "../../views/OpenLoads/OpenLoads";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";

const { TabPane } = Tabs;

const User = props => {
    const fullWidth = global.window.innerWidth;
    const userProfileId = props.match.params.userProfileId;

    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.users.isRecordLoading);
    const error = useSelector(state => state.users.recordError);
    const user = useSelector(state => state.users.record);

    const [tabKey, setTabKey] = useState('snapshot');
    const [imageUrl, setImageUrl] = useState(null);
    const [showEditEntity, setShowEditEntity] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchUser(userProfileId));
    }, [userProfileId]);

    useEffect(() => {
        if (user && user.profileImageId) {
            axiosAuthenticated.get('/documents/' + user.profileImageId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                console.log(err);
            });
        }
    }, [user]);

    const editEntityComponents = (
        <Drawer
            title={"Edit User Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditUser cancel={toggleEditEntity} record={user} />
        </Drawer>
    );

    const resendWelcomeEmail = () => {
        if (user !== undefined && user !== null) {
            const email = user.user;
            if (email !== undefined && email !== null) {
                axios.post("/rest-auth/password/reset/", { email: email }).then(res => {
                    if (res && res.status === 200) {
                        message.success('A welcome email has been sent to this user.');
                    }
                }).catch(err => {
                    console.log(err);
                    message.error('Failed to send the welcome email has been sent to this user.');
                });
            }
        }
    };

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="welcomeUser" onClick={(e) => { e.domEvent.stopPropagation(); resendWelcomeEmail(); }}>
                    <span>Re-Send Welcome Email</span>
                </Menu.Item>
                <Menu.Item key="editUser" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUserAlt} style={{ marginRight: '10px' }} />
                    <span>Edit User Profile</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];
    const previousLocation = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/employees` };
    const previousPath = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/employees`;
    const previousBreadcrumbName = props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'Employees';

    let breadcrumbs = [...previousBreadcrumbs,
    {
        location: previousLocation ? previousLocation : { pathname: `/users` },
        path: previousPath ? previousPath : `/users`,
        breadcrumbName: previousBreadcrumbName ? previousBreadcrumbName : 'Users',
    },
    {
        path: `/user/${userProfileId ? userProfileId : null}`,
        breadcrumbName: `${user ? user.firstName + ' ' + user.lastName : ''}`,
    },
    ];

    if (user && user.id === userProfileId) {
        return (
            <>
                <Header
                    title={user ? user.firstName + ' ' + user.lastName : 'Loading...'}
                    title2={user && user.user && user.isDummyEmail === false ? user.user : ''}
                    title2Icon={<FontAwesomeIcon className="anticon" icon={faEnvelope} />}
                    title3={user && user.phone ? <ValueFormatting.PhoneNumber value={user.phone} /> : ''}
                    title3Icon={<FontAwesomeIcon className="anticon" icon={faPhoneAlt} />}
                    icon={<FontAwesomeIcon className="anticon" icon={faUserAlt} />}
                    logo={imageUrl}
                    breadcrumbs={breadcrumbs}
                />
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && !error}>
                                {(user !== null && isLoading === false) &&
                                    <Row gutter={[16, 16]}>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="User Details"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Name": { value: user && user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : null, dataType: 'String' },
                                                    "UserName": { value: user && user.user && user.isDummyEmail === false ? user.user : null, dataType: 'String' },
                                                    "Email": { value: user && user.user && user.isDummyEmail === false ? user.user : null, dataType: 'String' },
                                                    "Phone": { value: user && user.phone ? user.phone : null, dataType: 'PhoneNumber' },
                                                    "Date Joined": { value: user && user.createdAt ? user.createdAt : null, dataType: 'MomentDate' },
                                                    "Is Active": { value: user ? user.isActive : false, dataType: 'Boolean' },
                                                }}
                                                extra={extra}
                                            >
                                                {user.streetAddress1 !== undefined && user.streetAddress1 !== null && user.streetAddress1 !== '' ? <DataAddressRow title="Address" street1={user.streetAddress1} city={user.city} state={user.state} postalCode={user.postalCode} country={user.country} /> : null}
                                                {user.bio !== undefined && user.bio !== null && user.bio !== '' ? <DataRow title="Bio" value={user.bio} dataType="String" /> : null}
                                            </ComplexDataLinkCard>
                                        </Col>
                                        <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                            <ComplexDataLinkCard
                                                title="System Roles"
                                                style={{ height: 300 }}
                                                headStyle={{ fontSize: 14 }}
                                                data={{
                                                    "Is Admin": { value: user ? user.isAdmin : false, dataType: 'Boolean' },
                                                    "Is VP": { value: user ? user.isVP : false, dataType: 'Boolean' },
                                                    "Is Director": { value: user ? user.isDirector : false, dataType: 'Boolean' },
                                                    "Is Manager": { value: user ? user.isManager : false, dataType: 'Boolean' },
                                                    "Is Staff": { value: user ? user.isStaff : false, dataType: 'Boolean' },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </Spin>
                        </div>
                    </TabPane>
                    {(user.isStaff === true) ? (
                        <TabPane tab="Open Loads" key="openLoads">
                            <div style={{ padding: 24 }}>
                                <OpenLoads assignedAccountRepId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="Booked Loads" key="bookedLoads">
                            <div style={{ padding: 24 }}>
                                <BookedLoads assignedAccountRepId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="In Transit Loads" key="intransitLoads">
                            <div style={{ padding: 24 }}>
                                <InTransitLoads assignedAccountRepId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                    {(user.isStaff === true) ? (
                        <TabPane tab="Completed Loads" key="completedLoads">
                            <div style={{ padding: 24 }}>
                                <CompletedLoads assignedAccountRepId={user.id} breadcrumbs={breadcrumbs} />
                            </div>
                        </TabPane>
                    ) : null}
                </Tabs>
                {editEntityComponents}
            </>
        );
    } else {
        return (
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={true} />
        );
    }
};

export default withRouter(User);