import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Contents Functions

export const fetchContentsStart = () => {
    return {
        type: actionTypes.FETCH_CONTENTS_START
    }
};

export const fetchContentsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CONTENTS_SUCCESS,
        payload: payload
    }
};

export const fetchContentsFail = (payload) => {
    return {
        type: actionTypes.FETCH_CONTENTS_FAIL,
        payload: payload
    }
};

export const clearContents = () => {
    return {
        type: actionTypes.CLEAR_CONTENTS
    }
};

export const addContentStart = () => {
    return {
        type: actionTypes.ADD_CONTENT_START
    }
};

export const addContentSuccess = () => {
    return {
        type: actionTypes.ADD_CONTENT_SUCCESS
    }
};

export const addContentFail = (payload) => {
    return {
        type: actionTypes.ADD_CONTENT_FAIL,
        payload: payload
    }
};

export const addContentErrorClear = () => {
    return {
        type: actionTypes.ADD_CONTENT_ERROR_CLEAR
    }
};

export const addContentLoadingClear = () => {
    return {
        type: actionTypes.ADD_CONTENT_LOADING_CLEAR
    }
};

export const updateContentStart = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_START
    }
};

export const updateContentSuccess = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_SUCCESS
    }
};

export const updateContentFail = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTENT_FAIL,
        payload: payload
    }
};

export const updateContentErrorClear = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_ERROR_CLEAR
    }
};

export const updateContentLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_CONTENT_LOADING_CLEAR
    }
};

export const cancelAddContent = () => {
    return {
        type: actionTypes.CANCEL_ADD_CONTENT
    }
};

export const cancelUpdateContent = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_CONTENT
    }
};

export const insertContent = (payload) => {
    return {
        type: actionTypes.ADD_CONTENT,
        payload: payload
    }
};

export const changeContent = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTENT,
        payload: payload
    }
};

export const changeSingleContent = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_CONTENT,
        payload: payload
    }
};

export const removeContent = (payload) => {
    return {
        type: actionTypes.REMOVE_CONTENT,
        payload: payload
    }
};

//#endregion

//#region Contents Methods

export const fetchContents = (payload) => {
    return async (dispatch, getState) => {
        const contentsPath = '/contents';
        try {
            dispatch(fetchContentsStart());

            const state = getState();
            const contentsState = {...state.contents};
            let pagination = {...contentsState.pagination};
            let searchParams = {...contentsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const contentsRes = await axiosAuthenticated.get(contentsPath, {params: {...searchParams}});
            if (contentsRes && contentsRes.status === 200) {
                const contents = contentsRes.data.data;

                // Read total count from server
                pagination.total = contentsRes.data.totalCount;
                pagination.current = contentsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchContentsSuccess({
                    records: contents,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchContentsFail({error: error.message}));
        }
    }
};

export const addContent = (payload) => {
    return async (dispatch) => {
        const contentsPath = '/contents';
        try {
            dispatch(addContentStart());

            const contentsRes = await axiosAuthenticated.post(contentsPath, {...payload});
            if (contentsRes && contentsRes.status === 201) {
                const newContent = contentsRes.data;

                dispatch(insertContent(newContent));

                dispatch(addContentSuccess());
                dispatch(addContentLoadingClear());
                dispatch(addContentErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addContentFail({error: error.message}));
        }
    }
};

export const updateContent = (contentId, payload) => {
    return async (dispatch) => {
        const contentsPath = '/contents';
        try {
            dispatch(updateContentStart());

            const contentsRes = await axiosAuthenticated.put(contentsPath + `/${contentId}`, {...payload});
            if (contentsRes && contentsRes.status === 200) {
                const updatedContent = contentsRes.data;

                if (updatedContent.isDeleted === true) {
                    dispatch(removeContent(updatedContent));
                } else {
                    dispatch(changeContent(updatedContent));
                    dispatch(changeSingleContent(updatedContent));
                }

                dispatch(updateContentSuccess());
                dispatch(updateContentLoadingClear());
                dispatch(updateContentErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateContentFail({error: error.message}));
        }
    }
};

//#endregion