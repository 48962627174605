import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actionCreators from "../../store/actions/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight, faDollarSign, faPallet, faRoute} from '@fortawesome/free-solid-svg-icons';
import {
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Empty,
    Input,
    InputNumber,
    Menu,
    Modal,
    Pagination,
    Row,
    Select,
    Spin,
    Timeline,
    Tooltip
} from 'antd';
import {isEmpty} from 'lodash';
import {
    FilterFilled,
    InfoOutlined,
    MoreOutlined,
    PlusOutlined,
    SearchOutlined,
    UploadOutlined
} from '@ant-design/icons';
import TitleBlockWithIcon from '../../components/TitleBlockWithIcon/TitleBlockWithIcon';
import StringFormatter from '../../shared/stringFormatter';
import DataRow from '../../components/DataRow/DataRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataListRow from '../../components/DataListRow/DataListRow';
import DataAtByRow from '../../components/DataAtByRow/DataAtByRow';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import Enums from '../../shared/enums';
import LoadMap from "../../components/LoadMap/LoadMap";
import classes from './DispatchShipper.module.scss';
import LoadDocuments from '../../components/LoadDocuments/LoadDocuments';
import MomentDate from '../../shared/dateFormatter';
import FormItem from '../../components/FormItem/FormItem';
import Form from '../../components/Form/Form';
import {FormProvider, useForm} from 'react-hook-form';
import FormButtons from '../../components/FormButtons/FormButtons';
import ActionButton from "../../components/ActionButton/ActionButton";
import Fieldset from "../../components/FormFieldset/FormFieldset";
import FormItemDisplay from "../../components/FormItemDisplay/FormItemDisplay";

const { Option } = Select;

const momentDate = new MomentDate();
const stringFormatter = new StringFormatter();

const DispatchShipper = props => {
    //#region constants

    let shipperId = props.shipperId;

    //#endregion
    //#region useForms

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const loadLists = useSelector(state => state.loadLists.loadLists);

    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const isLoadingCarrierDispatch = useSelector(state => !!state.loadLists && !!state.loadLists.loadLists && !!state.loadLists.loadLists['shipper-dispatch'] && !!state.loadLists.loadLists['shipper-dispatch'].isLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);
    const stops = useSelector(state => state.loadStops.records);
    const isLoadingStops = useSelector(state => state.loadStops.isLoading);
    const documents = useSelector(state => state.documents.records);
    const isLoadingDocuments = useSelector(state => state.documents.isLoading);
    const accounts = useSelector(state => state.orchestrator.accounts);
    const isLoadingLoadUpdate = useSelector(state => state.loads.isRecordUpdateLoading);
    const errorLoadUpdate = useSelector(state => state.loads.updateRecordError);
    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);
    const bundledLoads = useSelector(state => state.loads.bundledLoads);
    const isBundledLoadsLoading = useSelector(state => state.loads.isBundledLoadsLoading);

    if (entityType === "SHIPPER") {
        shipperId = entityId
    }

    //#endregion
    //#region useStates
    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    const [query, setQuery] = useState('')
    const [searchParams, setSearchParams] = useState({
        page: 1,
        size: 10,
        sort: 'pickUpDateTime',
        order: 'asc',
        isParentLoad: true,
        'loadStatus:or': 'CREATED|PENDING|APPROVED',
        'childLoads.status:or': 'CREATED|PENDING|APPROVED|PENDING_RATE_CONFIRMATION',
        isDeleted: false,
        eta: false
    });
    const [selectedSubLoad, setSelectedSubLoad] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showDuplicateEntity, setShowDuplicateEntity] = useState(false);
    const [showEntity, setShowEntity] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [loadsToDispatch, setLoadsToDispatch] = useState([]);
    const [selectedLoadToDispatchId, setSelectedLoadToDispatchId] = useState(null);
    const [selectedLoadToDispatchIds, setSelectedLoadToDispatchIds] = useState(null);
    const [selectedRepeatLoadsDay, setSelectedRepeatLoadsDay] = useState(false);
    const [showDispatchLoadModal, setShowDispatchLoadModal] = useState(false);
    const [isCarriersLoading, setIsCarriersLoading] = useState(false);
    const [carriers, setCarriers] = useState(null);
    const [repeatDaysCount, setRepeatDaysCount] = useState(1);
    const [isRecurringLoad, setIsRecurringLoad] = useState(false);

    //#endregion
    //#region goTos

    const goToLoad = (id) => {
        props.history.push({ pathname: `/loads/${id}` }, {
            previousPageTitle: "Dispatch",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: shipperId ? shipperId : null,
                loadLaneId: null,
                duplicateLoadId: null,
                isRecurringLoad: isRecurringLoad,
                repeatDaysCount: repeatDaysCount
            }
        });
    };

    const goToDuplicateLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.shipperId ? selectedRecord.shipperId : null,
                loadLaneId: null,
                duplicateLoadId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
            }
        });

        setSelectedRecord({});
    };

    //#endregion
    //#region refreshes

    const refreshList = () => {
        searchParams.shipperId = entityId;
        dispatch(actionCreators.fetchLoadList('shipper-dispatch', searchParams));
    };

    const onSubmit = (data) => {
        dispatch(actionCreators.assignBulkLoadsToCarrier({
            load_ids: !!selectedLoadToDispatchIds && selectedLoadToDispatchIds.length > 0 ? selectedLoadToDispatchIds.map(it => it.id) : [selectedLoadToDispatchId],
            assignedCarrierId: data.assignedCarrierId
        }));
        setShowDispatchLoadModal(false);
    }

    const onSubmitDaily = () => {
        const loads = loadsToDispatch.reduce((memo, it) => {
            const key = stringFormatter.toFormattedString("MomentDate", it.pickUpDateTime, null, it.origin.timeZone);

            if (!memo[key]) {
                memo[key] = [];
            }
            memo[key].push(it);

            return memo;
        }, {});

        const loadValues = Object.values(loads);
        const firstDay = loadValues[0];

        let batch = {};
        loadValues.forEach((loads) => {
            loads.forEach((load, idx) => {
                if (firstDay && !!firstDay[idx] && !!firstDay[idx].assignedCarrierId) {
                    if (!batch[firstDay[idx].assignedCarrierId]) {
                        batch[firstDay[idx].assignedCarrierId] = {
                            load_ids: [],
                            assignedCarrierId: firstDay[idx].assignedCarrierId
                        }
                    }
                    batch[firstDay[idx].assignedCarrierId].load_ids.push(load.id)
                }
            })
        })

        Object.values(batch).forEach((b) => {
            dispatch(actionCreators.assignBulkLoadsToCarrier(b))
        })
        setSelectedRepeatLoadsDay(false);
    }

    //#endregion
    //#region fetch methods

    const getDocuments = () => {
        if (load !== null && load !== undefined && stops !== null && stops !== undefined && stops.length > 0) {
            let ids = [];
            ids.push(load.id);

            if (stops !== null && stops !== undefined && stops.length > 0) {
                stops.forEach(stop => {
                    ids.push(stop.id);
                });
            }

            dispatch(actionCreators.fetchBulkDocuments({ entityId: ids }));
        }
    };

    //#endregion
    //#region toggles

    const openShowEntity = () => {
        setShowEntity(true);
    };

    const closeShowEntity = () => {
        setShowEntity(false);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleDuplicateEntity = () => {
        setShowDuplicateEntity(!showDuplicateEntity);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    //#endregion
    //#region onChanges

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region submits and cancels

    const onDoneDispatchLoad = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        setShowDispatchLoadModal(false);
        setSelectedLoadToDispatchId(null);
    };

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (showDispatchLoadModal === true && isLoadingLoadUpdate !== null && isLoadingLoadUpdate === false && errorLoadUpdate === null) {
            onDoneDispatchLoad();
        }
    }, [isLoadingLoadUpdate, errorLoadUpdate, showDispatchLoadModal]);

    useEffect(() => {
        if (selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null) {
            if (selectedRecord.isParentLoad === true && selectedRecord.childLoadInitCount && selectedRecord.childLoadInitCount > 0) {
                dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
                dispatch(actionCreators.clearLoad()); // reset load
                dispatch(actionCreators.clearLoadStops()); // reset load stops
                setSelectedLoadToDispatchId(null); // reset selected load
                setSelectedLoadToDispatchIds([])
                dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
                dispatch(actionCreators.fetchBundledLoadsWithIncludes(selectedRecord.id));
                dispatch(actionCreators.fetchLoadStops(selectedRecord.id));
            } else {
                dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
                dispatch(actionCreators.clearLoad()); // reset load
                dispatch(actionCreators.clearLoadStops()); // reset load stops
                setSelectedLoadToDispatchId(selectedRecord.id); // reset selected load
                setSelectedLoadToDispatchIds([])
                dispatch(actionCreators.fetchLoadWithIncludes(selectedRecord.id));
                dispatch(actionCreators.fetchLoadStops(selectedRecord.id));
            }
        } else {
            dispatch(actionCreators.clearBundledLoads()); // reset bundled loads
            dispatch(actionCreators.clearLoad()); // reset load
            dispatch(actionCreators.clearLoadStops()); // reset load stops
            setSelectedLoadToDispatchId(null); // reset selected load
            setSelectedLoadToDispatchIds([])
        }
    }, [showEntity, selectedRecord]);

    useEffect(() => {
        if (load !== null && load !== undefined && !isEmpty(load) && load.childLoadInitCount !== undefined && load.childLoadInitCount !== null && stops !== null && stops !== undefined && stops.length > 0) {
            getDocuments();
        }
    }, [load, stops]);


    useEffect(() => {
        if (bundledLoads !== undefined && bundledLoads !== null && bundledLoads.length > 0) {
            let loadsToDispatchArray = [];

            bundledLoads.filter(i => i.isDeleted === false).forEach((bundledLoad, index) => {
                let dispatchStatus = 'PENDING';
                if (bundledLoad.loadStatus === 'SCHEDULED' || bundledLoad.loadStatus === 'IN_TRANSIT' || bundledLoad.loadStatus === 'AT_STOP' || bundledLoad.loadStatus === 'COMPLETED' || bundledLoad.loadStatus === 'CLOSED' || bundledLoad.loadStatus === 'REOPENED') {
                    dispatchStatus = 'DISPATCHED';
                } else if (bundledLoad.loadStatus === 'CANCELLED') {
                    dispatchStatus = 'CANCELLED';
                }

                let loadNumber = index + 1;

                loadsToDispatchArray.push({
                    ...bundledLoad,
                    loadNumber: loadNumber,
                    loadName: bundledLoad.parentName ? bundledLoad.parentName : (bundledLoad.name ? bundledLoad.name + ' (' + loadNumber + ')' : bundledLoad.irisId),
                    dispatchStatus: dispatchStatus,
                });
            });

            setLoadsToDispatch(loadsToDispatchArray);
        } else {
            setLoadsToDispatch([]);
        }
    }, [bundledLoads]);

    useEffect(() => {
        if (loadLists && loadLists["shipper-dispatch"] && loadLists["shipper-dispatch"].records.length) {
            const loadsToDispatchCards = loadLists && loadLists["shipper-dispatch"] && loadLists["shipper-dispatch"].records
                .filter(load => {
                    return !(!!query && !load.irisId.toLowerCase().includes(query.toLowerCase()));
                });
            let selectedIndex = loadsToDispatchCards.findIndex(load => {
                return load.id === selectedSubLoad.id
            });
            if (selectedIndex == -1) {
                selectedIndex = 0;
            }
            if (!!loadsToDispatchCards[selectedIndex] && !!loadsToDispatchCards[selectedIndex].id) {
                setSelectedSubLoad(loadsToDispatchCards[selectedIndex]);
                setSelectedLoadToDispatchId(loadsToDispatchCards[selectedIndex].id);
                setSelectedRecord(loadsToDispatchCards[selectedIndex]);
            } else {
                setSelectedSubLoad(null);
                setSelectedLoadToDispatchId(null);
                setSelectedRecord(null);
            }
        }
    }, [loadLists])

    useMemo(() => {
        if (entityType === 'STAFF' || entityType === 'SHIPPER') {
            setIsCarriersLoading(true);

            if (accounts !== undefined && accounts !== null && accounts.length > 0) {
                const carrierAccounts = accounts.filter(account => account.isCarrier === true);
                if (carrierAccounts !== undefined && carrierAccounts !== null && carrierAccounts.length > 0) {
                    const carrierAccountsTransformed = carrierAccounts.map((item) => {
                        return {
                            id: item.id,
                            name: item.name
                        };
                    });
                    setCarriers(carrierAccountsTransformed);
                }
                else {
                    setCarriers([]);
                }
            }

            setIsCarriersLoading(false);
        } else if (entityType === 'CARRIER') {

        }
    }, [entityType, entityId, accounts]);

    //#endregion
    //#region display components

    const newEntityComponents = (
        <Modal
            title={"Choose how many Loads you want to Create"}
            visible={showNewEntity}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewEntity(); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 100}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>

            <Checkbox
                onChange={e => setIsRecurringLoad(e.target.checked)}
                checked={isRecurringLoad}
                name={""}
            />
            <div>* Repeat this load(s) for days:</div>
            {!!isRecurringLoad && (
                <InputNumber
                    value={repeatDaysCount}
                    min={1}
                    step={1}
                    max={entityType === 'CARRIER' ? 100 : 100}
                    precision={0}
                    style={{ width: '100%' }}
                    onChange={(count) => {
                        setRepeatDaysCount(count)
                    }}
                />
            )}

        </Modal>
    );

    const duplicateEntityComponents = (
        <Modal
            title={"Choose how many Duplicated Loads you want to Create"}
            visible={showDuplicateEntity}
            onOk={goToDuplicateLoad}
            onCancel={(e) => { toggleDuplicateEntity(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 100}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
        </Modal>
    );

    const documentsComponent = (
        <Modal
            title="Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <LoadDocuments
                loadId={selectedSubLoad ? selectedSubLoad.id : null}
                load={selectedSubLoad}
                stops={selectedSubLoad ? selectedSubLoad.stops : []}
                entityType={entityType}
                entityId={entityId}
                documents={documents}
                currentStatus={selectedSubLoad ? selectedSubLoad.loadStatus : null}
            />
        </Modal>
    );


    const copyDayComponents = () => {
        return (
            <Modal
                title={null}
                visible={!!selectedRepeatLoadsDay}
                maskClosable={false}
                closable={false}
                footer={null}
                bodyStyle={{ padding: 0 }}
                destroyOnClose={true}
            >
                <Card
                    title={"Confirm Offer"}
                    style={{ border: '1px solid #D8D8D8' }}
                    bodyStyle={{ padding: 12, minHeight: 400 }}
                    headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                >
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmitDaily)}>
                            <Fieldset legend="OFFER A LOAD(s) TO THE CARRIER">
                                <FormItemDisplay {...formItemLayout} label="Confirm">
                                        <p>Are you sure you want to offer load(s) for the rest of the days to the same carriers?</p>
                                        <p>*This will only send offer to the loads that do not have carrier assigned yet.</p>
                                </FormItemDisplay>

                            </Fieldset>
                            <FormButtons cancel={() => setSelectedRepeatLoadsDay(false)} submitText="Save Changes" />
                        </Form>
                    </FormProvider>
                </Card>
            </Modal>
        );
    }

    const dispatchLoadComponents = () => {
        let loadObj = null
        if (selectedSubLoad) {
            let selectedLoadToDispatch = selectedSubLoad
            if (selectedLoadToDispatch !== undefined && selectedLoadToDispatch !== null && !isEmpty(selectedLoadToDispatch)) {
                loadObj = selectedLoadToDispatch;
            }
        } else if (selectedSubLoad !== undefined && !isEmpty(selectedSubLoad)) {
            loadObj = load;
        }

        if (loadObj !== undefined && loadObj !== null && !isEmpty(loadObj)) {
            return (
                <Modal
                    title={null}
                    visible={showDispatchLoadModal === true}
                    maskClosable={false}
                    closable={false}
                    footer={null}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <Card
                        title={"Confirm Assign"}
                        style={{ border: '1px solid #D8D8D8' }}
                        bodyStyle={{ padding: 12, minHeight: 400 }}
                        headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                      >
                        <FormProvider {...methods}>
                            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Fieldset legend="OFFER A LOAD(s) TO THE CARRIER">
                                    <FormItemDisplay {...formItemLayout} label="Confirm">
                                        <span>Are you sure you want to send this load(s)
                                            {!!selectedLoadToDispatchId && selectedLoadToDispatchId}
                                            {!!selectedLoadToDispatchIds && selectedLoadToDispatchIds.map((it) => {
                                            return it.irisId
                                        }).join(", ")} to a selected carrier?</span>
                                    </FormItemDisplay>
                                    <FormItem
                                        {...formItemLayout}
                                        required
                                        format="vertical"
                                        render={({ onChange, onBlur, value, name }) => (
                                            <Select
                                                placeholder="Please Search and Select a Carrier"
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                virtual={false}
                                                loading={isCarriersLoading === true}
                                                onBlur={onBlur}
                                                onChange={(selected) => { onChange(selected); }}
                                                value={value}
                                                name={name}
                                                showSearch={true}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Carrier Accounts for you to choose from." />}
                                            >
                                                {carriers.map(c => <Option value={c.id} key={c.id}>{c.name}</Option>)}
                                            </Select>
                                        )}
                                        rules={{ required: "Required Field" }}
                                        name="assignedCarrierId"
                                    />
                                </Fieldset>
                                <FormButtons cancel={() => setShowDispatchLoadModal(false)} submitText="Save Changes" />
                            </Form>
                        </FormProvider>
                    </Card>
                </Modal>
            );
        } else {
            return null;
        }
    };

    //#endregion
    //#region stop displays

    const getStopNameDisplay = (stop) => {
        let stopNameDisplay = null;
        if (stop.stopLocation && stop.stopLocation.name) {
            stopNameDisplay = `${stop.stopLocation.name}`;
        } else if (stop.stopType) {
            stopNameDisplay = `${Enums.StopTypes.getValueByName(stop.stopType)}`;
        }
        return stopNameDisplay;
    };

    const getStopLocationDisplay = (stop) => {
        let stopLocationDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address && stop.stopLocation.address.city && stop.stopLocation.address.state) {
            stopLocationDisplay = `${stop.stopLocation.address.city}, ${stop.stopLocation.address.state}`;
        } else {
            stopLocationDisplay = getStopNameDisplay(stop);
        }

        return stopLocationDisplay;
    };

    const getStopAddressDisplay = (stop) => {
        let stopAddressDisplay = null;
        if (stop.stopLocation && stop.stopLocation.address) {
            stopAddressDisplay = `${stringFormatter.toFormattedAddress(stop.stopLocation.address)}`;
        }

        return stopAddressDisplay;
    };

    const getAppointmentDetailsDisplay = (stop) => {
        let appointmentDetailsDisplay = null;
        if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
            let startDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            let endDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;

            if (startDateObj !== null && endDateObj !== null) {
                if (startDateObj.dateString === endDateObj.dateString) {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.timeString} ${startDateObj.timeZone}`;
                } else {
                    appointmentDetailsDisplay = `Appt: ${startDateObj.dateString}, ${startDateObj.timeString} - ${endDateObj.dateString}, ${endDateObj.timeString} ${startDateObj.timeZone}`;
                }
            }
        } else if (stop.apptType === "HAVE_APPOINTMENT") {
            let apptDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            if (apptDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${apptDateObj.dateString}, ${apptDateObj.timeString} ${apptDateObj.timeZone}`;
            }
        } else if (stop.apptType === "NEED_APPOINTMENT") {
            let requestedDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            if (requestedDateObj !== null) {
                appointmentDetailsDisplay = `Appt: ${requestedDateObj.dateString}, TBD`;
            }
        }

        return appointmentDetailsDisplay;
    };

    const getAppointmentContactDisplay = (stop) => {
        let appointmentContactDisplay = null;

        if (stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null && !isEmpty(stop.apptPointOfContact)) {
            appointmentContactDisplay = `Appt Contact: ${stop.apptPointOfContact.name ? stop.apptPointOfContact.name + ', ' : ''}${stringFormatter.toFormattedPhoneNumber(stop.apptPointOfContact.phone, stop.apptPointOfContact.phoneExt)}`;
        }

        return appointmentContactDisplay;
    };

    const getAppointmentCallAheadDisplay = (stop) => {
        let appointmentCallAheadDisplay = null;
        let apptCallAheadDateObj = stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptCallAheadDateTime, stop.timeZone) : null;
        if (apptCallAheadDateObj !== null) {
            appointmentCallAheadDisplay = `Appt Call Ahead: ${apptCallAheadDateObj.dateString}, ${apptCallAheadDateObj.timeString} ${apptCallAheadDateObj.timeZone}`;
        }

        return appointmentCallAheadDisplay;
    };

    const renderStopCard = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const color = (selectedSubLoad && selectedSubLoad.origin.loadId === stop.loadId) ? "#fff" : "black"
            const stopLocation = getStopLocationDisplay(stop);
            const stopAddress = getStopAddressDisplay(stop);
            const apptDetails = getAppointmentDetailsDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopLocation} style={{fontSize: "1.4em", color: color}}>{stopLocation}</div>
                    </div>
                    {stopAddress ? (<div className={classes.stopAddress} style={{fontSize: "1.4em", color: color}}>{stopAddress}</div>) : null}
                    {apptDetails ? (<div className={classes.apptDetails} style={{fontSize: "1.4em", color: color}}>{apptDetails}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStop = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);
            const stopLocation = getStopLocationDisplay(stop);
            const stopAddress = getStopAddressDisplay(stop);
            const apptDetails = getAppointmentDetailsDisplay(stop);
            const apptCallAhead = getAppointmentCallAheadDisplay(stop);
            const apptContact = getAppointmentContactDisplay(stop);

            return (
                <div className={classes.stopDetails} key={`stop-details-${stop.id}`}>
                    <div className={classes.stopLocationContainer}>
                        <div className={classes.stopIconContainer}>
                            {stopIcon ? (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} className={classes.stopIcon} />) : null}
                        </div>
                        <div className={classes.stopLocation}>{stopLocation}</div>
                    </div>
                    {stopAddress ? (<div className={classes.stopAddress}>{stopAddress}</div>) : null}
                    {apptDetails ? (<div className={classes.apptDetails}>{apptDetails}</div>) : null}
                    {apptCallAhead ? (<div className={classes.apptDetails}>{apptCallAhead}</div>) : null}
                    {apptContact ? (<div className={classes.apptDetails}>{apptContact}</div>) : null}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderStops = () => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            const pickUpAndDropOffStops = stops.filter(s => s.stopType === 'PICK_UP' || s.stopType === 'DROP_OFF');
            if (pickUpAndDropOffStops.length > 0) {
                return pickUpAndDropOffStops.map((stop) => { return renderStop(stop); });
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const getStopStartDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowStartDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, startDateTime: stopDateObj };
    };

    const getStopEndDateTime = (stop) => {
        let stopDateObj = null;
        let apptType = null;
        if (stop !== undefined && stop !== null) {
            apptType = stop.apptType;
            if (stop.apptType === "FIRST_COME_FIRST_SERVE") {
                stopDateObj = stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptWindowEndDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "HAVE_APPOINTMENT") {
                stopDateObj = stop.apptDateTime !== undefined && stop.apptDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.apptDateTime, stop.timeZone) : null;
            } else if (stop.apptType === "NEED_APPOINTMENT") {
                stopDateObj = stop.requestedDateTime !== undefined && stop.requestedDateTime !== null && stop.timeZone !== undefined && stop.timeZone !== null ? momentDate.fromUtcToTimeZoneDateObject(stop.requestedDateTime, stop.timeZone) : null;
            }
        }

        return { apptType: apptType, endDateTime: stopDateObj };
    };

    const getOriginAndDestinationTimes = (origin, destination) => {
        let timeDisplay = '';
        let originStartObj = getStopStartDateTime(origin);
        let destinationEndObj = getStopEndDateTime(destination);
        let startDateObj = originStartObj.startDateTime;
        let endDateObj = destinationEndObj.endDateTime;
        if (startDateObj !== null && endDateObj !== null) {
            let startTime = originStartObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : startDateObj.timeString;
            let endTime = destinationEndObj.apptType === 'NEED_APPOINTMENT' ? 'TBD' : endDateObj.timeString;
            if (startDateObj.dateString === endDateObj.dateString) {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endTime} ${startDateObj.timeZone}`;
            } else {
                timeDisplay = `${startDateObj.dateString}, ${startTime} - ${endDateObj.dateString}, ${endTime} ${startDateObj.timeZone}`;
            }
        }

        return timeDisplay;
    };

    //#endregion
    //#region load displays

    const getLoadStatusDisplay = (status) => {
        if (entityType === 'STAFF') {
            return stringFormatter.toFormattedString("LoadDisplayStatus", status, null);
        } else if (entityType === 'SHIPPER') {
            return stringFormatter.toFormattedString("ShipperLoadDisplayStatus", status, null);
        } else if (entityType === 'CARRIER' || entityType === 'DRIVER') {
            return stringFormatter.toFormattedString("CarrierLoadDisplayStatus", status, null);
        } else {
            return null;
        }
    };

    //#endregion
    //#region bundled loads displays

    const menu = (record) => {
        let childLoadInitCount = record.childLoadInitCount && record.childLoadInitCount > 0 ? record.childLoadInitCount : 0;
        let childLoadActualCount = record.childLoadActualCount && record.childLoadActualCount > 0 ? record.childLoadActualCount : 0;
        return (
            <Menu>
                {(entityType === 'STAFF' || (entityType === 'SHIPPER' && record.createdByEntityType === 'SHIPPER') || (entityType === 'CARRIER' && record.createdByEntityType === 'CARRIER')) &&
                    <Menu.Item key="duplicateLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); if (load && load.id === record.id) { openShowEntity(); } else { closeShowEntity(); } setSelectedRecord(record); toggleDuplicateEntity(); }}>
                        <UploadOutlined style={{ marginRight: 8 }} />
                        <span>Duplicate Load</span>
                    </Menu.Item>
                }

                <Menu.Item key="goToDashboard" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); goToLoad(record.id); }}>
                    <InfoOutlined style={{ marginRight: 8 }} />
                    <span>Go To Load Dashboard</span>
                </Menu.Item>
                {((entityType === 'STAFF' && isAdmin === true) && childLoadActualCount < childLoadInitCount) &&
                    <Menu.Item key="generateMissingChildLoad" onClick={(e) => { e.domEvent.stopPropagation(); e.domEvent.preventDefault(); dispatch(actionCreators.addMissingChildLoads(record.id)); }}>
                        <span>Generate Missing Child Loads</span>
                    </Menu.Item>
                }
            </Menu>
        );
    };

    const loadExtras = (record) => {
        return (
            <Dropdown overlay={menu(record)}>
                <Button style={{ border: 0 }} type="link" onClick={(e) => { e.stopPropagation(); e.preventDefault(); }} icon={<MoreOutlined />} />
            </Dropdown>
        );
    };

    const getBundleCount = (load) => {
        let remainingCount = 0;
        if (load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) {
             // adding one for the parent load
            remainingCount = load.childLoadInitCount;
        }

        return remainingCount;
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const getProp = (object, keys, defaultVal) => {
        if (object === undefined || object === null) {
            return defaultVal;
        }
        keys = Array.isArray(keys) ? keys : keys.split('.');
        object = object[keys[0]];
        if (object && keys.length > 1) {
            return getProp(object, keys.slice(1), defaultVal);
        }
        return (object === undefined || object === null) ? defaultVal : object;
    }

    useEffect(() => {
        refreshList()
    }, [searchParams])

    const renderLoadToDispatchCard = (load) => {
        let cardClassNames = [];
        if (!!selectedSubLoad && (load.irisId === selectedSubLoad.irisId)) {
            cardClassNames.push('selectedLoadsListCard');
        } else {
            cardClassNames.push('unselectedLoadsListCard');
        }

        const renderedCard = (
            <Card
                size="small"
                bordered={true}
                onClick={() => {
                    setSelectedLoadToDispatchId(load.id);
                    setSelectedSubLoad(load)
                    setSelectedRecord(load);
                }}
                className={cardClassNames.join(' ')}
                hoverable={true}
                style={{width: "100%", height: "auto", marginBottom: "10px"}}
            >
                <div className="title">LoadID: {load.irisId} {!!load.isRecurringLoad && (<span className=""> - Recurring</span>)}</div>
                {!!load.isRecurringLoad && (
                    <>
                        <p className="">Initial timing, repeats for the next {load.repeatCount} days.</p>
                    </>
                )}
                <Timeline className="loadsListTimeline">
                    {(load.origin !== undefined && load.origin !== null && load.origin.stopLocation !== undefined && load.origin.stopLocation !== null) ? (
                        <Timeline.Item className="loadsListTimelineItem" color='#16588E'>
                            {renderStopCard(load.origin)}
                        </Timeline.Item>
                    ) : null}
                    {(load.destination !== undefined && load.destination !== null && load.destination.stopLocation !== undefined && load.destination.stopLocation !== null) ? (
                        <Timeline.Item className="loadsListTimelineItem" >
                            {renderStopCard(load.destination)}
                        </Timeline.Item>
                    ) : null}
                </Timeline>
                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>{loadExtras(load)}</div>
            </Card>
        );

        const remainingCount = getBundleCount(load);

        return (
            <Badge count={!!remainingCount ? `x${remainingCount + 1}` : null} offset={[-20, 20]}
                   style={{
                        backgroundColor: (load.isRecurringLoad) ? '#334353' : '#FFFFFF',
                        color: (load.isRecurringLoad) ? '#FFFFFF' : '#000000',
                        border: '2px solid #16588E',
                        width: 65,
                        height: 65,
                        borderRadius: 50,
                        lineHeight: '60px',
                        fontSize: 20,
                        fontWeight: 600
                    }}
                    title="Loads Remaining to Dispatch" className={"block"}>
                {renderedCard}
            </Badge>
        );
    };

    const loadsToDispatchCards = loadLists && loadLists["shipper-dispatch"] && loadLists["shipper-dispatch"].records
        .filter(load => {
            return !(!!query && !load.irisId.toLowerCase().includes(query.toLowerCase()));
        })
        .map(load => renderLoadToDispatchCard(load))

    //#endregion

    const getBulkLoads = () => {
        const items = loadsToDispatch.map((load, loadIdx) => {
            const checked = selectedLoadToDispatchIds.findIndex(it => it.id === load.id);
            const disabled = load.loadStatus !== "APPROVED";

            return (
                <li className="table-row">
                    <div className="col col-1">
                        <Checkbox
                            disabled={disabled}
                            onChange={e => {
                                const checked = e.target.checked;
                                let selectedLoads = selectedLoadToDispatchIds.filter((it) => {
                                    return it.id !== load.id
                                });
                                if (checked) {
                                    selectedLoads.push(load)
                                    setSelectedLoadToDispatchIds(selectedLoads);
                                } else {
                                    setSelectedLoadToDispatchIds(selectedLoads)
                                }
                            }}
                            checked={checked > -1}
                            name={""}
                        />
                        {loadIdx + 1}/{loadsToDispatch.length}
                    </div>
                    <div className="col col-2">{load.irisId}</div>
                    <div className="col col-3">{!!load.assignedCarrier && load.assignedCarrier.name}</div>
                    <div className="col col-4">{getLoadStatusDisplay(load.loadStatus)} <Button
                        type={"link"}
                        onClick={(e) => {
                            goToLoad(load.id)
                        }}
                    >Go To Load</Button>
                    </div>
                </li>
            )
        });

        return (
            <span>
                <ul className="responsive-table">
                    <li className="table-row">
                        <div className="col col-1"><Button onClick={(e) => { setSelectedLoadToDispatchIds(loadsToDispatch.filter(it => it.loadStatus === "APPROVED")) }}>Select all</Button></div>
                        <div className="col col-2"><Button onClick={(e) => { setSelectedLoadToDispatchIds([]) }}>Clear selection</Button></div>
                        <div className="col col-3">
                            <Button
                                disabled={selectedLoadToDispatchIds.length === 0}
                                type={"primary"}
                                onClick={(e) => {
                                    setShowDispatchLoadModal(true);
                                }}>Offer selection to carrier</Button>
                        </div>
                        <div className="col col-4"> </div>
                    </li>
                </ul>
                <div className={""}>
                    <ul className="responsive-table">
                        {items}
                    </ul>
                </div>
            </span>
        )
    }

    const getRepeatLoads = (load) => {
        let loadsToDispatchTmp = [...loadsToDispatch];

        const loads = loadsToDispatchTmp.reduce((memo, it) => {
            const key = stringFormatter.toFormattedString("MomentDate", it.pickUpDateTime, null, it.origin.timeZone);

            if (!memo[key]) {
                memo[key] = [];
            }
            memo[key].push(it);

            return memo;
        }, {});

        return(
            <span>
                <ul className="responsive-table">
                    <li className="table-row">
                        <div className="col col-1"><Button onClick={(e) => { setSelectedLoadToDispatchIds(loadsToDispatchTmp.filter(it => it.loadStatus === "APPROVED")) }}>Select all</Button></div>
                        <div className="col col-2"><Button onClick={(e) => { setSelectedLoadToDispatchIds([]) }}>Clear selection</Button></div>
                        <div className="col col-3">
                            <Button
                                disabled={selectedLoadToDispatchIds.length === 0}
                                type={"primary"}
                                onClick={(e) => {
                                setShowDispatchLoadModal(true);
                            }}>Offer selection to carrier</Button>
                        </div>
                        <div className="col col-4"> </div>
                        <div className="col col-4"> </div>
                    </li>
                </ul>
                {Object.keys(loads).map((key, dayIdx) => {
                    const items = loads[key].map((load, loadIdx) => {
                        const checked = selectedLoadToDispatchIds.findIndex(it => it.id === load.id);
                        const disabled = load.loadStatus !== "APPROVED";

                        return (
                            <li className="table-row">
                                <div className="col col-1">
                                    <Checkbox
                                        disabled={disabled}
                                        onChange={e => {
                                            const checked = e.target.checked;
                                            let selectedLoads = selectedLoadToDispatchIds.filter((it) => {
                                                return it.id !== load.id
                                            });
                                            if (checked) {
                                                selectedLoads.push(load)
                                                setSelectedLoadToDispatchIds(selectedLoads);
                                            } else {
                                                setSelectedLoadToDispatchIds(selectedLoads)
                                            }
                                        }}
                                        checked={checked > -1}
                                        name={""}
                                    />
                                    {loadIdx + 1}/{loads[key].length}
                                </div>
                                <div className="col col-2">{load.irisId}</div>
                                <div className="col col-3">{!!load.assignedCarrier && load.assignedCarrier.name}</div>
                                <div className="col col-4">{getLoadStatusDisplay(load.loadStatus)} <Button
                                            type={"link"}
                                            onClick={(e) => {
                                                goToLoad(load.id)
                                            }}
                                        >Go To Load</Button>
                                </div>
                                <div className="col col-4"> </div>
                            </li>
                        )
                    });

                    return (
                        <div className={""}>
                            <ul className="responsive-table">
                                <li className="table-header">
                                    <div className="col col-1">{key}</div>
                                    <div className="col col-2"><Button onClick={(e) => { setSelectedLoadToDispatchIds(
                                        loads[key].filter(it =>
                                            (it.loadStatus !== "PENDING_RATE_CONFIRMATION")
                                            ||
                                            (selectedLoadToDispatchIds.findIndex(xt => xt.irisId === it.irisId) > 0)
                                        ))}
                                    }>Select all loads in this day</Button></div>
                                    <div className="col col-3">{dayIdx === 0 && (
                                        <Button type={"primary"}
                                                onClick={(e) => {
                                                    setSelectedRepeatLoadsDay(loads)
                                                }}>Copy day to others</Button>
                                        )}
                                    </div>
                                    <div className="col col-4"></div>
                                </li>
                                {items}
                            </ul>
                        </div>
                    )
                })}
            </span>
        )
    }

    return (
        <div>
            <Spin style={{ height: '100%', width: '100%' }} size="large"
                  spinning={(isLoading === true
                      || isBundledLoadsLoading === true
                      || (isLoadingLoadUpdate === true && errorLoadUpdate === null)
                      || loadEventUpdateStatus === 'PENDING')
                      || isLoadingStops === true
                      || isLoadingDocuments === true
                      || !!isLoadingCarrierDispatch}
            >
                <Row gutter={[24, 24]}>
                    <Col style={{borderRight: "1px solid #D8D8D8"}} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 6 }}>
                        <div style={{padding: "15px"}}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h1 style={{fontWeight: "bold"}}>Open Loads ({getProp(loadLists, "shipper-dispatch.totalCount", 0)})</h1>
                                <div><FilterFilled style={{fontSize: "20px"}}/></div>
                            </div>
                            <Input
                                placeholder="Search Loads"
                                addonAfter={<SearchOutlined />}
                                onChange={e => { setQuery(e.target.value) }}
                                value={query}
                                name={"query"}
                                style={{marginBottom: "10px"}}
                            />
                        </div>

                        <div style={{marginTop: "20px", height: "75vh", overflowY: "scroll", padding: "15px"}}>
                            {loadsToDispatchCards}

                            {(loadLists && loadLists["shipper-dispatch"] && loadLists["shipper-dispatch"].records.length === 0) && (
                                <span>No loads to assign/dispatch</span>
                            )}
                        </div>
                        <div style={{marginTop: "20px"}}>
                            {getProp(loadLists, "shipper-dispatch.totalCount", 0) > 0 && (
                                <Pagination
                                    size={"small"}
                                    current={searchParams.page}
                                    total={getProp(loadLists, "shipper-dispatch.totalCount", 0)}
                                    onChange={(value) => {
                                        setSearchParams(Object.assign({}, searchParams, {
                                            page: value
                                        }))
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                    <Col lg={{ span: 18 }} md={{ span: 18 }} sm={{ span: 18 }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px"
                        }}>
                            <Tooltip placement="left" title={'New Load'}><Button key="NewLoad" type="primary" icon={<PlusOutlined />} shape="circle" onClick={toggleNewEntity} /></Tooltip>
                        </div>

                        {(!!selectedSubLoad) && (!!load) && (
                            <Row gutter={[24, 24]} style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4 }}>
                                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                    <Row style={{marginBottom: "15px"}} gutter={[24, 24]}>
                                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                            <LoadMap
                                                height="40vh"
                                                width="100%"
                                                key={selectedSubLoad.id}
                                                loadId={selectedSubLoad.destination.loadId}
                                                irisLoadId={selectedSubLoad.irisId}
                                                load={selectedSubLoad}
                                                stops={selectedSubLoad.stops}
                                                entityType={entityType}
                                                entityId={entityId}
                                                containerName={`load-details-map-${selectedSubLoad.id}`}
                                            />
                                        </Col>
                                    </Row>

                                    {(load !== undefined && load !== null && !isEmpty(load) && load.id !== undefined && load.id !== null && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null && selectedRecord.id === load.id) && (
                                        <div>
                                            {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? (
                                                <Row gutter={[24, 24]}>
                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>

                                                    </Col>

                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>

                                                    </Col>

                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                                                        <ActionButton
                                                            iconPosition="right"
                                                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                                                            buttonIconClassName={classes.iconContainer}
                                                            buttonTextClassName={classes.textContainer}
                                                            buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                                            buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                                            buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight} style={{ color: "55babb" }} />}
                                                            buttonText={<span>Offer to Carrier</span>}
                                                            onClick={() => {
                                                                setShowDispatchLoadModal(true);
                                                            }}
                                                            disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING') || !selectedLoadToDispatchId}
                                                            buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row gutter={[24, 24]} style={{marginTop: "10px"}}>
                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>

                                                    </Col>

                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>

                                                    </Col>

                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                                                        <ActionButton
                                                            iconPosition="right"
                                                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                                                            buttonIconClassName={classes.iconContainer}
                                                            buttonTextClassName={classes.textContainer}
                                                            buttonTextStyle={{ color: "#fff", borderRadius: "10px", fontSize: 24, background: "#334353" }}
                                                            buttonIconStyle={{ borderRadius: "10px", background: "#334353", color: "#ffffff" }}
                                                            buttonIcon={<FontAwesomeIcon className={classes.button1icon2} icon={faArrowCircleRight} style={{ color: "55babb" }} />}
                                                            buttonText={<span>Offer to Carrier</span>}
                                                            onClick={() => {
                                                                setShowDispatchLoadModal(true);
                                                            }}
                                                            disabled={((isLoading === true && error === null) || loadEventUpdateStatus === 'PENDING') || !selectedLoadToDispatchIds || selectedLoadToDispatchIds.length === 0}
                                                            buttonStyle={{borderRadius: "10px", background: "#334353"}}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    )}

                                    <Row gutter={[24, 24]}>
                                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }}>
                                            <Card
                                                title="Route Details"
                                                style={{ border: '1px solid #D8D8D8', borderRadius: "10px" }}
                                                bodyStyle={{ minHeight: 400, padding: 15,  borderRadius: "10px" }}
                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center', borderTopLeftRadius: "10px" , borderTopRightRadius: "10px" }}
                                            >
                                                <TitleBlockWithIcon
                                                    style={{ margin: '40px auto 40px auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faRoute} />}
                                                    title={
                                                        <span>Trip Miles: {stringFormatter.toFormattedString('Distance', selectedSubLoad.practicalDistance, selectedSubLoad.practicalDistanceUnit)}<br />Number of Stops: {selectedSubLoad.numberOfStops}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 0px auto', maxWidth: 'max-content' }}>
                                                    {renderStops()}
                                                </div>
                                            </Card>
                                        </Col>

                                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }}>
                                            <Card
                                                title="Load Details"
                                                style={{ border: '1px solid #D8D8D8', borderRadius: "10px" }}
                                                bodyStyle={{ minHeight: 400, padding: 15,  borderRadius: "10px" }}
                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center', borderTopLeftRadius: "10px" , borderTopRightRadius: "10px" }}
                                            >
                                                <TitleBlockWithIcon
                                                    style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faPallet} />}
                                                    title={
                                                        <span>Load ID: {load.irisId}<br />Status: {getLoadStatusDisplay(load.loadStatus)}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                    {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                                    {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                                    {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                                    {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.preCoolReefer !== null && load.equipmentNeeds.preCoolReefer !== undefined ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                                    {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                                    {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                                        <DataListRow title="Equipment Specifics" valueStyle={{ marginLeft: 16 }} data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                                    ) : null}
                                                    {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                                        <DataListRow title="Driver Requirements" valueStyle={{ marginLeft: 16 }} data={load.driverRequirements} dataType="DriverRequirement" />
                                                    ) : null}
                                                </div>
                                            </Card>
                                        </Col>

                                        <Col lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }}>
                                            <Card
                                                title="Rate Details"
                                                style={{ border: '1px solid #D8D8D8', borderRadius: "10px" }}
                                                bodyStyle={{ minHeight: 400, padding: 15,  borderRadius: "10px" }}
                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center', borderTopLeftRadius: "10px" , borderTopRightRadius: "10px" }}
                                            >
                                                <TitleBlockWithIcon
                                                    style={{ marginTop: 40, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}
                                                    icon={<FontAwesomeIcon className={classes.icon} icon={faDollarSign} />}
                                                    title={
                                                        <span>Trip Rate: {stringFormatter.toFormattedMoney(load.shipperAmount)}<br />Rate Per Mile: {stringFormatter.toFormattedMoney(load.shipperRatePerMile)}</span>
                                                    }
                                                />
                                                <div style={{ margin: '0px auto 40px auto', maxWidth: 'max-content' }}>
                                                    {!!load.assignedCarrier && !!load.assignedCarrier.name ? <DataRow title="Carrier" value={load.assignedCarrier.name} /> : null}
                                                    {!!load.assignedCarrier && !!load.assignedCarrier.carrier && load.assignedCarrier.carrier.pointOfContact ? <DataContactInfoRow separateLines={true} title="Carrier Contact" name={load.assignedCarrier.carrier.pointOfContact.name} phone={load.assignedCarrier.carrier.pointOfContact.phone} phoneExt={load.assignedCarrier.carrier.pointOfContact.phoneExt} /> : null}
                                                    <br />
                                                    {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? (load.isRateConfirmed && load.rateConfirmedByUser && load.rateConfirmedAt ? <DataAtByRow entityType={entityType} title="Rate Confirmation" at={load.rateConfirmedAt} by={load.rateConfirmedByUser} /> : <DataRow title="Rate Confirmation" value="Pending" dataType="String" />) : null}
                                                    {(load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) === false ? <div><b>Documents:</b> <Button type="link" onClick={toggleDocumentsModal} style={{ textDecoration: 'underline' }}>view</Button></div> : null}
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {(load !== undefined && load !== null && !isEmpty(load) && load.id !== undefined && load.id !== null && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord) && selectedRecord.id !== undefined && selectedRecord.id !== null && selectedRecord.id === load.id) && (
                                        <Row gutter={[12, 12]} style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, borderTop: '4px solid #D8D8D8' }}>
                                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                                                {!load.isRecurringLoad && (load.childLoadInitCount !== undefined && load.childLoadInitCount !== null) ? (
                                                    <Row gutter={[24, 24]}>
                                                        <Col span={24}>
                                                            <Card
                                                                title={`Dispatch/Send to carrier for ${!!load.parentName ? load.parentName : load.irisId}`}
                                                                style={{ border: '1px solid #D8D8D8' }}
                                                                bodyStyle={{ minHeight: 400, padding: 0 }}
                                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                                                            >
                                                                {getBulkLoads()}
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                ) : null}

                                                {!!load.isRecurringLoad ? (
                                                    <Row gutter={[24, 24]}>
                                                        <Col span={24}>
                                                            <Card
                                                                title={`Dispatch/Send to carrier for ${!!load.parentName ? load.parentName : load.irisId}`}
                                                                style={{ border: '1px solid #D8D8D8' }}
                                                                bodyStyle={{ minHeight: 400, padding: 0 }}
                                                                headStyle={{ backgroundColor: '#334353', color: '#ffffff', borderBottom: '1px solid #D8D8D8', fontSize: 20, lineHeight: '24px', textAlign: 'center' }}
                                                            >
                                                                {getRepeatLoads(load)}
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Spin>
            {newEntityComponents}
            {duplicateEntityComponents}
            {documentsComponent}
            {dispatchLoadComponents()}
            {copyDayComponents()}
        </div>
    );
};

export default withRouter(DispatchShipper);
