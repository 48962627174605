import React, { useState, useEffect } from 'react';
import { Spin, Row, Col } from "antd";
import Img from 'react-image';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import axios from "axios";
import PDFViewer from '../../components/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';

const Document = props => {
    const documentId = props.fileId;
    const displayName = props.displayName;

    const CancelToken = axios.CancelToken;
    let cancel;

    const [fileUrl, setFileUrl] = useState(null);
    const [fileExt, setFileExt] = useState(null);

    useEffect(() => {
        setFileUrl(null);
        if (documentId !== undefined && documentId !== null) {
            axiosAuthenticated.get('/documents', {
                params: {
                    'id': documentId
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                if (res && res.status === 200) {
                    const fileMetaData = res.data.data[0];

                    setFileExt(fileMetaData.fileExt);
                    setFileUrl(fileMetaData.link);
                    //console.log(fileMetaData.link);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            console.log("Cleaning up axios calls");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [documentId]);

    if (fileExt !== undefined && fileExt !== null) {
        if (fileExt.toLowerCase() === 'jpeg' || fileExt.toLowerCase() === 'jpg' || fileExt.toLowerCase() === 'png') {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        <Img style={{ width: '100%' }}
                            src={fileUrl}
                            loader={<Spin size="large" />}
                            alt={displayName ? displayName : 'File'}
                        />
                    </Col>
                </Row>
            );
        } else if (fileExt.toLowerCase() === 'pdf') {
            return (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24}>
                        {fileUrl !== null && <PDFViewer backend={PDFJSBackend} src={fileUrl} />}
                    </Col>
                </Row>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default Document;