import React, { useMemo, useState } from 'react';
import { Alert, Spin, Select, Empty } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import moment from 'moment';

const { Option } = Select;

const NewScheduledShift = props => {
    //#region props

    const accountId = props.accountId;
    const selectedDate = props.selectedDate;
    const selectedAccountUserId = props.selectedAccountUserId;
    const selectedShiftId = props.selectedShiftId;
    const accountUsers = props.accountUsers;
    const shifts = props.shifts;

    //#endregion
    //#region useForms

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.scheduledShifts.isRecordAddLoading);
    const error = useSelector(state => state.scheduledShifts.addRecordError);

    //#endregion
    //#region useStates

    const [selectedShift, setSelectedShift] = useState(null);
    const [selectedAccountUser, setSelectedAccountUser] = useState(null);

    //#endregion
    //#region onSubmit and cancel

    const onSubmit = (data) => {
        if (accountId !== undefined && accountId !== null) {
            data.accountId = accountId;

            if (selectedAccountUserId !== undefined && selectedAccountUserId !== null) {
                let accountUserIds = [];
                accountUserIds.push(selectedAccountUserId);
                data.accountUserIds = accountUserIds;
            }

            if (selectedShiftId !== undefined && selectedShiftId !== null) {
                data.shiftId = selectedShiftId;
            }

            if (selectedDate !== undefined && selectedDate !== null) {
                if (data.shiftId !== undefined && data.shiftId !== null && shifts !== undefined && shifts !== null && shifts.length > 0) {
                    let selectedShift = shifts.find(i => i.id === data.shiftId);
                    if (selectedShift !== undefined && selectedShift !== null) {
                        // console.log(selectedShift);

                        data.isAvailable = selectedShift.isAvailable;

                        var formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

                        let startDate = `${formattedSelectedDate}T${selectedShift.startTime}`;
                        data.startDateTime = moment.tz(startDate, selectedShift.timeZone);

                        if (selectedShift.isNextDay === false) {
                            let endDate = `${formattedSelectedDate}T${selectedShift.endTime}`;
                            data.endDateTime = moment.tz(endDate, selectedShift.timeZone);
                        } else {
                            let nextDaySelectedDate = moment(selectedDate).add(1, 'days').format('YYYY-MM-DD');
                            let endDate = `${nextDaySelectedDate}T${selectedShift.endTime}`;
                            data.endDateTime = moment.tz(endDate, selectedShift.timeZone);
                        }

                        dispatch(actionCreators.addScheduledShift(data));
                    }
                }
            }
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddScheduledShift());
        props.cancel();
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        if (selectedAccountUserId !== undefined && selectedAccountUserId !== null && accountUsers !== undefined && accountUsers !== null && accountUsers.length > 0) {
            let selectedAccountUserObj = accountUsers.find(i => i.id === selectedAccountUserId);
            if (selectedAccountUserObj !== undefined && selectedAccountUserObj !== null) {
                setSelectedAccountUser({ ...selectedAccountUserObj });
            } else {
                setSelectedAccountUser(null);
            }
        }
    }, [selectedAccountUserId, accountUsers]);

    useMemo(() => {
        if (selectedShiftId !== undefined && selectedShiftId !== null && shifts !== undefined && shifts !== null && shifts.length > 0) {
            let selectedShiftObj = shifts.find(i => i.id === selectedShiftId);
            if (selectedShiftObj !== undefined && selectedShiftObj !== null) {
                setSelectedShift({ ...selectedShiftObj });
            } else {
                setSelectedShift(null);
            }
        }
    }, [selectedShiftId, shifts]);

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addScheduledShiftErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //#endregion

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset legend="Selected Date">
                        <span><strong>{selectedDate}</strong></span>
                    </Fieldset>
                    {selectedShiftId === undefined || selectedShiftId === null ? (
                        <Fieldset legend="Selected Shift">
                            <FormItem {...formItemLayout} label="Shift" required format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Shift"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shifts for you to choose from." />}
                                    >
                                        {shifts.map(a => <Option value={a.id} key={a.id}>{a.name}: {a.startTime} - {a.endTime} {a.timeZone}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="shiftId"
                            />
                        </Fieldset>
                    ) : (
                            <Fieldset legend="Selected Shift">
                                <span><strong>{selectedShift && selectedShift.name ? selectedShift.name : ''}: {selectedShift && selectedShift.startTime ? selectedShift.startTime : ''} - {selectedShift && selectedShift.endTime ? selectedShift.endTime : ''}</strong></span>
                            </Fieldset>
                        )}
                    {selectedAccountUserId === undefined || selectedAccountUserId === null ? (
                        <Fieldset legend="Selected Employee(s)">
                            <FormItem {...formItemLayout} label="Please Select the Employee(s) for this Shift" required format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        mode="multiple"
                                        placeholder="Please Select the Employee(s) for this Shift"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Employees for you to choose from." />}
                                    >
                                        {accountUsers.map(a => <Option value={a.id} key={a.id}>{a.firstName} {a.lastName}{a.title ? ' (' + a.title + ')' : ''}</Option>)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="accountUserIds"
                            />
                        </Fieldset>
                    ) : (
                            <Fieldset legend="Selected Employee">
                                <span><strong>{selectedAccountUser && selectedAccountUser.firstName ? selectedAccountUser.firstName : ''} {selectedAccountUser && selectedAccountUser.lastName ? selectedAccountUser.lastName : ''}{selectedAccountUser && selectedAccountUser.title ? " (" + selectedAccountUser.title + ")" : ''}</strong></span>
                            </Fieldset>
                        )}
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText={selectedAccountUserId ? "Add Employee to Scheduled Shift" : "Add Employee(s) to Scheduled Shift"} />
            </Form>
        </FormProvider>
    );
};

export default NewScheduledShift;