import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {authSuccess, completeLogout} from "./auth";
import {omit} from "lodash";
import {addFile, updateFile} from "../../api/fileClient";
import {getStaff} from "./orchestrator";
import logger from "../../shared/logger";
import EntityUtils from '../entityUtils';

//#region Users Functions

export const fetchUsersStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    }
};

export const fetchUsersSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        payload: payload
    }
};

export const fetchUsersFail = (payload) => {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        payload: payload
    }
};

export const clearUsers = () => {
    return {
        type: actionTypes.CLEAR_USERS
    }
};

export const fetchUserStart = () => {
    return {
        type: actionTypes.FETCH_USER_START
    }
};

export const fetchUserSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        payload: payload
    }
};

export const fetchUserFail = (payload) => {
    return {
        type: actionTypes.FETCH_USER_FAIL,
        payload: payload
    }
};

export const clearUser = () => {
    return {
        type: actionTypes.CLEAR_USER
    }
};

export const addUserStart = () => {
    return {
        type: actionTypes.ADD_USER_START
    }
};

export const addUserSuccess = () => {
    return {
        type: actionTypes.ADD_USER_SUCCESS
    }
};

export const addUserFail = (payload) => {
    return {
        type: actionTypes.ADD_USER_FAIL,
        payload: payload
    }
};

export const addUserErrorClear = () => {
    return {
        type: actionTypes.ADD_USER_ERROR_CLEAR
    }
};

export const addUserLoadingClear = () => {
    return {
        type: actionTypes.ADD_USER_LOADING_CLEAR
    }
};

export const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
};

export const updateUserSuccess = () => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS
    }
};

export const updateUserFail = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        payload: payload
    }
};

export const updateUserErrorClear = () => {
    return {
        type: actionTypes.UPDATE_USER_ERROR_CLEAR
    }
};

export const updateUserLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_USER_LOADING_CLEAR
    }
};

export const cancelAddUser = () => {
    return {
        type: actionTypes.CANCEL_ADD_USER
    }
};

export const cancelUpdateUser = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_USER
    }
};

export const insertUser = (payload) => {
    return {
        type: actionTypes.ADD_USER,
        payload: payload
    }
};

export const changeUser = (payload) => {
    return {
        type: actionTypes.UPDATE_USER,
        payload: payload
    }
};

export const changeSingleUser = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_USER,
        payload: payload
    }
};

export const removeUser = (payload) => {
    return {
        type: actionTypes.REMOVE_USER,
        payload: payload
    }
};

//#endregion

//#region Users Methods

export const fetchUsers = (payload) => {
    return async (dispatch, getState) => {
        const userProfilesPath = '/api/userProfiles/';
        try {
            dispatch(fetchUsersStart());

            const state = getState();
            const usersState = {...state.users};
            let pagination = {...usersState.pagination};
            let searchParams = {...usersState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const usersRes = await axiosAuthenticated.get(userProfilesPath, {params: {...searchParams}});
            if (usersRes && usersRes.status === 200) {
                const users = usersRes.data.results;

                let userIds = users.map(item => {
                    return item.id;
                });

                let userDocuments = [];
                const userDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [...userIds]
                });
                if (userDocumentsRes && userDocumentsRes.status === 200) {
                    userDocuments = userDocumentsRes.data.data;
                }

                const transformedUsers = users.map((item) => {
                    let fileMetaData = [];
                    if (userDocuments.length > 0) {
                        fileMetaData = userDocuments.filter(document => document.entityId === item.id);
                    }

                    let {profileImageId} = EntityUtils.getUserFileIds(fileMetaData);

                    return {
                        ...item,
                        user: item.isDummyEmail === true ? null : item.user,
                        profileImageId: profileImageId ? profileImageId : null,
                    };
                });

                // Read total count from server
                pagination.total = usersRes.data.count;
                pagination.pageSize = searchParams.limit !== undefined && searchParams.limit !== null ? searchParams.limit : pagination.pageSize;

                dispatch(fetchUsersSuccess({
                    records: transformedUsers,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchUsersFail({error: error.message}));
        }
    }
};

export const fetchUser = (userProfileId) => {
    return async (dispatch) => {
        const userProfilesPath = '/api/userProfiles/';
        try {
            dispatch(fetchUserStart());

            const userRes = await axiosAuthenticated.get(userProfilesPath + `${userProfileId}/`);
            if (userRes && userRes.status === 200) {
                let user = userRes.data;

                let userDocuments = [];
                const userDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [user.id]
                });
                if (userDocumentsRes && userDocumentsRes.status === 200) {
                    userDocuments = userDocumentsRes.data.data;
                }

                let fileMetaData = [];
                if (userDocuments.length > 0) {
                    fileMetaData = userDocuments.filter(document => document.entityId === user.id);
                }

                let {profileImageId} = EntityUtils.getUserFileIds(fileMetaData);
                user.profileImageId = profileImageId ? profileImageId : null;

                if (user.isDummyEmail === true) {
                    user.user = null;
                }

                dispatch(fetchUserSuccess({record: user}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchUserFail({error: error.message}));
        }
    }
};

export const addUser = (payload) => {
    return async (dispatch) => {
        const systemUsersPath = '/systemUsers';
        try {
            dispatch(addUserStart());

            const profileImage = payload.profileImage;
            payload = omit(payload, "profileImage");

            const usersRes = await axiosAuthenticated.post(systemUsersPath, {...payload});
            if (usersRes && usersRes.status === 201) {
                let user = usersRes.data;
                // console.log(user);
                const document = await addFile(profileImage, "USER", user.userProfileId, "PROFILE_PHOTO", "Profile Image", "", "ALL");
                // user.profileImageId = document ? document.id : null;

                // dispatch(insertUser(newUser));

                // if staff then refresh the staff orchestrator
                if (user.isStaff === true) {
                    dispatch(getStaff(true));
                }

                dispatch(addUserSuccess());
                dispatch(addUserLoadingClear());
                dispatch(addUserErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addUserFail({error: error.message}));
        }
    }
};

export const updateUser = (payload, isProfile, userProfileId, isEmailChanged = false) => {
    return async (dispatch) => {
        const systemUsersPath = '/systemUsers';
        try {
            dispatch(updateUserStart());

            let profileImageId = payload.profileImageId !== undefined && payload.profileImageId !== null ? payload.profileImageId : null;
            let profileImage = payload.profileImage !== undefined && payload.profileImage !== null ? payload.profileImage : null;

            if (profileImageId !== null && profileImage !== null) {
                const document = await updateFile(profileImageId, profileImage);
                if (isProfile === true) {
                    dispatch(authSuccess({profileImageId: null}));
                }
                profileImageId = document ? document.id : null;
            } else if (profileImage !== null && profileImageId === null) {
                const document = await addFile(profileImage, "USER", userProfileId, "PROFILE_PHOTO", "Profile Image", "", "ALL");
                if (isProfile === true) {
                    dispatch(authSuccess({profileImageId: null}));
                }
                profileImageId = document ? document.id : null;
            }

            payload = omit(payload, "profileImage", "profileImageId");

            const usersRes = await axiosAuthenticated.put(systemUsersPath + `/${userProfileId}`, {...payload});
            if (usersRes && usersRes.status === 200) {
                let updatedUser = usersRes.data;
                updatedUser.user = updatedUser.isDummyEmail === true ? null : updatedUser.user;
                updatedUser.profileImageId = profileImageId ? profileImageId : null;

                if (isEmailChanged === true) {
                    dispatch(updateUserSuccess());
                    dispatch(updateUserLoadingClear());
                    dispatch(updateUserErrorClear());

                    dispatch(completeLogout());
                } else {
                    if (isProfile === true) {
                        dispatch(fetchUserSuccess({record: updatedUser}));

                        dispatch(authSuccess({
                            firstName: updatedUser.firstName,
                            lastName: updatedUser.lastName,
                            profileImageId: profileImageId
                        }));

                        localStorage.setItem('firstName', updatedUser.firstName.toString());
                        localStorage.setItem('lastName', updatedUser.lastName.toString());
                        if (profileImageId !== undefined && profileImageId !== null) {
                            localStorage.setItem('profileImageId', profileImageId);
                        } else {
                            localStorage.removeItem('profileImageId');
                        }
                    } else {
                        if (updatedUser.isDeleted === true) {
                            dispatch(removeUser(updatedUser));
                        } else {
                            dispatch(changeUser(updatedUser));
                            dispatch(changeSingleUser(updatedUser));
                        }
                    }

                    // if staff then refresh the staff orchestrator
                    if (updatedUser.isStaff === true) {
                        dispatch(getStaff(true));
                    }

                    dispatch(updateUserSuccess());
                    dispatch(updateUserLoadingClear());
                    dispatch(updateUserErrorClear());
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateUserFail({error: error.message}));
        }
    }
};

//#endregion