import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { isEmpty } from "lodash";

const fetchAccountsPendingVerificationStart = (state) => {
    return updateObject(state, {
        accountsPendingVerificationIsLoading: true,
        accountsPendingVerificationError: null
    });
};

const fetchAccountsPendingVerificationSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        accountsPendingVerificationIsLoading: false,
        accountsPendingVerificationError: null
    });
};

const fetchAccountsPendingVerificationFail = (state, action) => {
    return updateObject(state, {
        accountsPendingVerificationError: action.error,
        accountsPendingVerificationIsLoading: false
    });
};

const clearAccountsPendingVerification = (state) => {
    return updateObject(state, {
        accountsPendingVerification: [],
        accountsPendingVerificationIsLoading: false,
        accountsPendingVerificationError: null,
        accountsPendingVerificationPagination: {},
        accountsPendingVerificationSearchParams: {}
    });
};

const fetchDriversMissingDocumentsStart = (state) => {
    return updateObject(state, {
        driversMissingDocumentsIsLoading: true,
        driversMissingDocumentsError: null
    });
};

const fetchDriversMissingDocumentsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        driversMissingDocumentsIsLoading: false,
        driversMissingDocumentsError: null
    });
};

const fetchDriversMissingDocumentsFail = (state, action) => {
    return updateObject(state, {
        driversMissingDocumentsError: action.error,
        driversMissingDocumentsIsLoading: false
    });
};

const clearDriversMissingDocuments = (state) => {
    return updateObject(state, {
        driversMissingDocuments: [],
        driversMissingDocumentsIsLoading: false,
        driversMissingDocumentsError: null,
        driversMissingDocumentsPagination: {},
        driversMissingDocumentsSearchParams: {}
    });
};

const fetchDriversPendingApprovalStart = (state) => {
    return updateObject(state, {
        driversPendingApprovalIsLoading: true,
        driversPendingApprovalError: null
    });
};

const fetchDriversPendingApprovalSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        driversPendingApprovalIsLoading: false,
        driversPendingApprovalError: null
    });
};

const fetchDriversPendingApprovalFail = (state, action) => {
    return updateObject(state, {
        driversPendingApprovalError: action.error,
        driversPendingApprovalIsLoading: false
    });
};

const clearDriversPendingApproval = (state) => {
    return updateObject(state, {
        driversPendingApproval: [],
        driversPendingApprovalIsLoading: false,
        driversPendingApprovalError: null,
        driversPendingApprovalPagination: {},
        driversPendingApprovalSearchParams: {}
    });
};

const fetchDriversWithExpiringLicensesStart = (state) => {
    return updateObject(state, {
        driversWithExpiringLicensesIsLoading: true,
        driversWithExpiringLicensesError: null
    });
};

const fetchDriversWithExpiringLicensesSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        driversWithExpiringLicensesIsLoading: false,
        driversWithExpiringLicensesError: null
    });
};

const fetchDriversWithExpiringLicensesFail = (state, action) => {
    return updateObject(state, {
        driversWithExpiringLicensesError: action.error,
        driversWithExpiringLicensesIsLoading: false
    });
};

const clearDriversWithExpiringLicenses = (state) => {
    return updateObject(state, {
        driversWithExpiringLicenses: [],
        driversWithExpiringLicensesIsLoading: false,
        driversWithExpiringLicensesError: null,
        driversWithExpiringLicensesPagination: {},
        driversWithExpiringLicensesSearchParams: {}
    });
};

const fetchInTransitLoadsWithoutLocationDataStart = (state) => {
    return updateObject(state, {
        inTransitLoadsWithoutLocationDataIsLoading: true,
        inTransitLoadsWithoutLocationDataError: null
    });
};

const fetchInTransitLoadsWithoutLocationDataSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        inTransitLoadsWithoutLocationDataIsLoading: false,
        inTransitLoadsWithoutLocationDataError: null
    });
};

const fetchInTransitLoadsWithoutLocationDataFail = (state, action) => {
    return updateObject(state, {
        inTransitLoadsWithoutLocationDataError: action.error,
        inTransitLoadsWithoutLocationDataIsLoading: false
    });
};

const clearInTransitLoadsWithoutLocationData = (state) => {
    return updateObject(state, {
        inTransitLoadsWithoutLocationData: [],
        inTransitLoadsWithoutLocationDataIsLoading: false,
        inTransitLoadsWithoutLocationDataError: null,
        inTransitLoadsWithoutLocationDataPagination: {},
        inTransitLoadsWithoutLocationDataSearchParams: {}
    });
};

const fetchCriticalLoadsStart = (state) => {
    return updateObject(state, {
        criticalLoadsIsLoading: true,
        criticalLoadsError: null
    });
};

const fetchCriticalLoadsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        criticalLoadsIsLoading: false,
        criticalLoadsError: null
    });
};

const fetchCriticalLoadsFail = (state, action) => {
    return updateObject(state, {
        criticalLoadsError: action.error,
        criticalLoadsIsLoading: false
    });
};

const clearCriticalLoads = (state) => {
    return updateObject(state, {
        criticalLoads: [],
        criticalLoadsIsLoading: false,
        criticalLoadsError: null,
        criticalLoadsPagination: {},
        criticalLoadsSearchParams: {}
    });
};

const fetchMetricsStart = (state) => {
    return updateObject(state, {
        metricsIsLoading: true,
        metricsError: null
    });
};

const fetchMetricsSuccess = (state, action) => {
    if (action.metrics !== undefined && action.metrics !== null && !isEmpty(action.metrics)) {
        //console.log(`action: ${JSON.stringify(action.metrics)}`);
        if (action.metrics.entityType === 'IRIS') {
            let newMetrics = {
                ['IRIS']: { ...action.metrics }
            };
            let existingMetrics = state.metrics;
            return updateObject(state, {
                metrics: { ...existingMetrics, ...newMetrics },
                metricsIsLoading: false,
                metricsError: null
            });
        } else if (action.metrics.entityType === 'SHIPPER' || action.metrics.entityType === 'CARRIER') {
            let newMetrics = {
                [action.metrics.entityId]: { ...action.metrics }
            };
            let existingMetrics = state.metrics;
            return updateObject(state, {
                metrics: { ...existingMetrics, ...newMetrics },
                metricsIsLoading: false,
                metricsError: null
            });
        }
    }

    return updateObject(state, {
        metricsIsLoading: false,
        metricsError: null
    });
};

const fetchMetricsFail = (state, action) => {
    return updateObject(state, {
        metricsError: action.error,
        metricsIsLoading: false
    });
};

const clearMetrics = (state) => {
    return updateObject(state, {
        metrics: {},
        metricsIsLoading: false,
        metricsError: null
    });
};

const initialState = {
    metrics: {},
    metricsIsLoading: false,
    metricsError: null,
    accountsPendingVerification: [],
    accountsPendingVerificationIsLoading: false,
    accountsPendingVerificationError: null,
    accountsPendingVerificationSearchParams: {},
    accountsPendingVerificationPagination: {},
    driversMissingDocuments: [],
    driversMissingDocumentsIsLoading: false,
    driversMissingDocumentsError: null,
    driversMissingDocumentsSearchParams: {},
    driversMissingDocumentsPagination: {},
    driversPendingApproval: [],
    driversPendingApprovalIsLoading: false,
    driversPendingApprovalError: null,
    driversPendingApprovalSearchParams: {},
    driversPendingApprovalPagination: {},
    driversWithExpiringLicenses: [],
    driversWithExpiringLicensesIsLoading: false,
    driversWithExpiringLicensesError: null,
    driversWithExpiringLicensesSearchParams: {},
    driversWithExpiringLicensesPagination: {},
    inTransitLoadsWithoutLocationData: [],
    inTransitLoadsWithoutLocationDataIsLoading: false,
    inTransitLoadsWithoutLocationDataError: null,
    inTransitLoadsWithoutLocationDataSearchParams: {},
    inTransitLoadsWithoutLocationDataPagination: {},
    criticalLoads: [],
    criticalLoadsIsLoading: false,
    criticalLoadsError: null,
    criticalLoadsSearchParams: {},
    criticalLoadsPagination: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_START: return fetchAccountsPendingVerificationStart(state);
        case actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_SUCCESS: return fetchAccountsPendingVerificationSuccess(state, action.payload);
        case actionTypes.FETCH_ACCOUNTS_PENDING_VERIFICATION_FAIL: return fetchAccountsPendingVerificationFail(state, action.payload);
        case actionTypes.CLEAR_ACCOUNTS_PENDING_VERIFICATION: return clearAccountsPendingVerification(state);
        case actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_START: return fetchDriversMissingDocumentsStart(state);
        case actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_SUCCESS: return fetchDriversMissingDocumentsSuccess(state, action.payload);
        case actionTypes.FETCH_DRIVERS_MISSING_DOCUMENTS_FAIL: return fetchDriversMissingDocumentsFail(state, action.payload);
        case actionTypes.CLEAR_DRIVERS_MISSING_DOCUMENTS: return clearDriversMissingDocuments(state);
        case actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_START: return fetchDriversPendingApprovalStart(state);
        case actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_SUCCESS: return fetchDriversPendingApprovalSuccess(state, action.payload);
        case actionTypes.FETCH_DRIVERS_PENDING_APPROVAL_FAIL: return fetchDriversPendingApprovalFail(state, action.payload);
        case actionTypes.CLEAR_DRIVERS_PENDING_APPROVAL: return clearDriversPendingApproval(state);
        case actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_START: return fetchDriversWithExpiringLicensesStart(state);
        case actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_SUCCESS: return fetchDriversWithExpiringLicensesSuccess(state, action.payload);
        case actionTypes.FETCH_DRIVERS_WITH_EXPIRING_LICENSES_FAIL: return fetchDriversWithExpiringLicensesFail(state, action.payload);
        case actionTypes.CLEAR_DRIVERS_WITH_EXPIRING_LICENSES: return clearDriversWithExpiringLicenses(state);
        case actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_START: return fetchInTransitLoadsWithoutLocationDataStart(state);
        case actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_SUCCESS: return fetchInTransitLoadsWithoutLocationDataSuccess(state, action.payload);
        case actionTypes.FETCH_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA_FAIL: return fetchInTransitLoadsWithoutLocationDataFail(state, action.payload);
        case actionTypes.CLEAR_IN_TRANSIT_LOADS_WITHOUT_LOCATION_DATA: return clearInTransitLoadsWithoutLocationData(state);
        case actionTypes.FETCH_CRITICAL_LOADS_START: return fetchCriticalLoadsStart(state);
        case actionTypes.FETCH_CRITICAL_LOADS_SUCCESS: return fetchCriticalLoadsSuccess(state, action.payload);
        case actionTypes.FETCH_CRITICAL_LOADS_FAIL: return fetchCriticalLoadsFail(state, action.payload);
        case actionTypes.CLEAR_CRITICAL_LOADS: return clearCriticalLoads(state);
        case actionTypes.FETCH_METRICS_START: return fetchMetricsStart(state);
        case actionTypes.FETCH_METRICS_SUCCESS: return fetchMetricsSuccess(state, action.payload);
        case actionTypes.FETCH_METRICS_FAIL: return fetchMetricsFail(state, action.payload);
        case actionTypes.CLEAR_METRICS: return clearMetrics(state);
        default:
            return state;
    }
};

export default reducer;