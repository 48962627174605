import React from 'react';
import DataRow from '../DataRow/DataRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import { Col, Row } from 'antd';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import Fieldset from '../FormFieldset/FormFieldset';

const AccountRegistration = props => {
    const accountRegistration = props.record;

    return (
        <>
            <Fieldset>
                <legend>Company Information</legend>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={12}>
                        {accountRegistration.name !== undefined && accountRegistration.name !== null ? <DataRow title="Company Name" value={accountRegistration.name} dataType="String" /> : null}
                        {accountRegistration.ein !== undefined && accountRegistration.ein !== null ? <DataRow title="EIN" value={accountRegistration.ein} dataType="String" /> : null}
                        {accountRegistration.email !== undefined && accountRegistration.email !== null ? <DataRow title="Email Address" value={accountRegistration.email} dataType="String" /> : null}
                        {accountRegistration.phone !== undefined && accountRegistration.phone !== null ? <DataPhoneNumberRow title="Day Time Phone #" phone={accountRegistration.phone} phoneExt={accountRegistration.phoneExt} /> : null}
                        {accountRegistration.afterHoursPhone !== undefined && accountRegistration.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone #" phone={accountRegistration.afterHoursPhone} phoneExt={accountRegistration.afterHoursPhoneExt} /> : null}
                        {accountRegistration.fax !== undefined && accountRegistration.fax !== null ? <DataRow title="Fax Number" value={accountRegistration.fax} dataType="PhoneNumber" /> : null}

                        {accountRegistration.isReviewed === true ? (
                            <>
                                <DataRow title="Is Reviewed" value={accountRegistration.isReviewed} dataType="Boolean" />
                                <DataRow title="Review Status" value={accountRegistration.reviewStatus} dataType="AccountRegistrationReviewStatus" />
                                <DataRow title="Reviewed At" value={accountRegistration.reviewedAt} dataType="MomentDateTime" />
                                <DataRow title="Reviewed By" value={accountRegistration.reviewedByName} dataType="String" />
                            </>
                        ) : null}
                        <DataRow title="Is Carrier" value={accountRegistration.isCarrier} dataType="Boolean" />
                        <DataRow title="Is Shipper" value={accountRegistration.isShipper} dataType="Boolean" />
                        <DataRow title="Is Receiver" value={accountRegistration.isReceiver} dataType="Boolean" />
                        <DataRow title="Is Producer" value={accountRegistration.isProducer} dataType="Boolean" />
                    </Col>
                    <Col xs={24} md={24} lg={12} xl={12}>
                        {accountRegistration.pointOfContact !== undefined && accountRegistration.pointOfContact !== null ? <DataContactInfoRow title="Point of Contact" contact={accountRegistration.pointOfContact} /> : null}
                        {accountRegistration.companyAddress !== undefined && accountRegistration.companyAddress !== null ? <DataAddressRow title="Company Address" address={accountRegistration.companyAddress} /> : null}
                        {accountRegistration.billingAddress !== undefined && accountRegistration.billingAddress !== null ? <DataAddressRow title="Billing Address" address={accountRegistration.billingAddress} /> : null}
                        {accountRegistration.shippingAddress !== undefined && accountRegistration.shippingAddress !== null ? <DataAddressRow title="Shipping Address" address={accountRegistration.shippingAddress} /> : null}
                    </Col>
                </Row>
            </Fieldset>
            {accountRegistration.isCarrier === true ? (
                <>
                    <Fieldset>
                        <legend>Carrier Information</legend>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <DataRow title="US Docket # (MC, FF, MX)" value={accountRegistration.carrier.mcNum} dataType="String" />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <DataRow title="US DOT #" value={accountRegistration.carrier.dotNum} dataType="String" />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Power Units" value={accountRegistration.carrier.numPowerUnits} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Trailers" value={accountRegistration.carrier.numTrailers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Drivers" value={accountRegistration.carrier.numDrivers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of HazMat Drivers" value={accountRegistration.carrier.numHazMatDrivers} dataType="Integer" />
                            </Col>
                            <Col lg={{ span: 4 }} md={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                <DataRow title="# of Teams" value={accountRegistration.carrier.numTeams} dataType="Integer" />
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset>
                        <legend>Accounts Receivable</legend>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <DataRow title="Preferred Payment Method" value={accountRegistration.carrier.preferredPaymentMethod} dataType="PreferredPaymentMethod" />
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <DataContactInfoRow contact={accountRegistration.carrier.accountsReceivable} />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <DataAddressRow address={accountRegistration.carrier.shippingAddress} />
                            </Col>
                        </Row>
                    </Fieldset>
                    <Fieldset>
                        <legend>Trailer Types</legend>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                                <DataRow title="Do you have 48' Reefers?" value={accountRegistration.carrier.has48Reefer} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num48Reefer} dataType="Integer" />
                                <DataRow title="Do you have 53' Reefers?" value={accountRegistration.carrier.has53Reefer} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num53Reefer} dataType="Integer" />
                                <DataRow title="Do you haul frozen?" value={accountRegistration.carrier.hasReeferFrozen} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                                <DataRow title="Do you have 48' Dry Vans?" value={accountRegistration.carrier.has48DryVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num48DryVan} dataType="Integer" />
                                <DataRow title="Do you have 53' Dry Vans?" value={accountRegistration.carrier.has53DryVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num53DryVan} dataType="Integer" />
                                <DataRow title="Do you have lift gates for the dry vans?" value={accountRegistration.carrier.hasDryVanLiftGates} dataType="Boolean" />
                                <DataRow title="Do you have roller beds for the dry vans?" value={accountRegistration.carrier.hasDryVanRollerBed} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                                <DataRow title="Do you have 48' Flatbeds?" value={accountRegistration.carrier.has48Flatbed} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num48Flatbed} dataType="Integer" />
                                <DataRow title="Do you have 53' Flatbeds?" value={accountRegistration.carrier.has53Flatbed} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num53Flatbed} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                                <DataRow title="Do you haul over dimensional/overweight loads?" value={accountRegistration.carrier.hasOverSized} dataType="Boolean" />
                            </Col>
                            <Col lg={{ span: 12 }} md={{ span: 24 }}>
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                                <DataRow title="Do you have Low Boys?" value={accountRegistration.carrier.hasLowBoy} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numLowBoy} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                                <DataRow title="Do you have RGNs?" value={accountRegistration.carrier.hasRGN} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numRGN} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                                <DataRow title="Do you have Step Decks with Ramps?" value={accountRegistration.carrier.hasStepDeckRamps} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numStepDeckRamps} dataType="Integer" />
                                <DataRow title="Do you have Step Decks without Ramps?" value={accountRegistration.carrier.hasStepDeckNoRamps} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numStepDeckNoRamps} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                                <DataRow title="Do you have Single Compartment Tankers?" value={accountRegistration.carrier.hasTankersSingleCompartment} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numTankersSingleCompartment} dataType="Integer" />
                                <DataRow title="Do you have Multi Compartment Tankers?" value={accountRegistration.carrier.hasTankersMultiCompartment} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numTankersMultiCompartment} dataType="Integer" />
                                <DataRow title="Do you have Fiber Glass Tankers?" value={accountRegistration.carrier.hasTankersFiberGlass} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numTankersFiberGlass} dataType="Integer" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                                <DataRow title="Do you have Cubed Straight Trucks?" value={accountRegistration.carrier.hasCubedStraightTruck} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numCubedStraightTruck} dataType="Integer" />
                                <DataRow title="Do you have 26' Straight Trucks?" value={accountRegistration.carrier.has26StraightTruck} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.num26StraightTruck} dataType="Integer" />
                                <DataRow title="Do you have lift gates for the straight trucks?" value={accountRegistration.carrier.hasStraightTruckLiftGates} dataType="Boolean" />
                                <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                                <DataRow title="Do you have Sprinter Vans?" value={accountRegistration.carrier.hasSprinterVan} dataType="Boolean" />
                                <DataRow title="If so, how many?" value={accountRegistration.carrier.numSprinterVan} dataType="Integer" />
                            </Col>
                        </Row>
                    </Fieldset>
                </>
            ) : null}
            {accountRegistration.isShipper === true ? (
                <Fieldset>
                    <legend>Equipment Needs</legend>
                    <Row gutter={[16, 16]}>
                        <Col lg={{ span: 12 }} md={{ span: 24 }}>
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Reefers</h3>
                            <DataRow title="Do you need 48' Reefers?" value={accountRegistration.shipper.has48Reefer} dataType="Boolean" />
                            <DataRow title="Do you need 53' Reefers?" value={accountRegistration.shipper.has53Reefer} dataType="Boolean" />
                            <DataRow title="Do you need to ship frozen?" value={accountRegistration.shipper.hasReeferFrozen} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Dry Vans</h3>
                            <DataRow title="Do you need 48' Dry Vans?" value={accountRegistration.shipper.has48DryVan} dataType="Boolean" />
                            <DataRow title="Do you need 53' Dry Vans?" value={accountRegistration.shipper.has53DryVan} dataType="Boolean" />
                            <DataRow title="Do you need lift gates for the dry vans?" value={accountRegistration.shipper.hasDryVanLiftGates} dataType="Boolean" />
                            <DataRow title="Do you need roller beds for the dry vans?" value={accountRegistration.shipper.hasDryVanRollerBed} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Flatbeds</h3>
                            <DataRow title="Do you need 48' Flatbeds?" value={accountRegistration.shipper.has48Flatbed} dataType="Boolean" />
                            <DataRow title="Do you need 53' Flatbeds?" value={accountRegistration.shipper.has53Flatbed} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Over-Sized Loads</h3>
                            <DataRow title="Do you need to ship over dimensional/overweight loads?" value={accountRegistration.shipper.hasOverSized} dataType="Boolean" />
                        </Col>
                        <Col lg={{ span: 12 }} md={{ span: 24 }}>
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Low Boys</h3>
                            <DataRow title="Do you need Low Boys?" value={accountRegistration.shipper.hasLowBoy} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>RGNs</h3>
                            <DataRow title="Do you need RGNs?" value={accountRegistration.shipper.hasRGN} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Step Decks</h3>
                            <DataRow title="Do you need Step Decks with Ramps?" value={accountRegistration.shipper.hasStepDeckRamps} dataType="Boolean" />
                            <DataRow title="Do you need Step Decks without Ramps?" value={accountRegistration.shipper.hasStepDeckNoRamps} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Tankers</h3>
                            <DataRow title="Do you need Single Compartment Tankers?" value={accountRegistration.shipper.hasTankersSingleCompartment} dataType="Boolean" />
                            <DataRow title="Do you need Multi Compartment Tankers?" value={accountRegistration.shipper.hasTankersMultiCompartment} dataType="Boolean" />
                            <DataRow title="Do you need Fiber Glass Tankers?" value={accountRegistration.shipper.hasTankersFiberGlass} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Straight Trucks</h3>
                            <DataRow title="Do you need Cubed Straight Trucks?" value={accountRegistration.shipper.hasCubedStraightTruck} dataType="Boolean" />
                            <DataRow title="Do you need 26' Straight Trucks?" value={accountRegistration.shipper.has26StraightTruck} dataType="Boolean" />
                            <DataRow title="Do you need lift gates for the straight trucks?" value={accountRegistration.shipper.hasStraightTruckLiftGates} dataType="Boolean" />
                            <h3 style={{ marginTop: 12, marginBottom: 12 }}>Sprinter Vans</h3>
                            <DataRow title="Do you need Sprinter Vans?" value={accountRegistration.shipper.hasSprinterVan} dataType="Boolean" />
                        </Col>
                    </Row>
                </Fieldset>
            ) : null}
        </>
    );
};

export default AccountRegistration;