export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};

export const addObjectToTop = (oldObjects, newObject) => {
    return [newObject, ...oldObjects];
};

export const addObjectToBottom = (oldObjects, newObject) => {
    return [...oldObjects, newObject];
};

export const fetchRecordsStart = (state) => {
    return updateObject(state, {
        isLoading: true,
        error: null
    });
};

export const fetchRecordsSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        isLoading: false,
        error: null
    });
};

export const clearRecords = (state) => {
    return updateObject(state, {
        records: []
    });
};

export const fetchRecordsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        isLoading: false
    });
};

export const fetchRecordStart = (state) => {
    return updateObject(state, {
        isRecordLoading: true,
        recordError: null
    });
};

export const fetchRecordSuccess = (state, action) => {
    return updateObject(state, {
        ...action,
        recordError: null,
        isRecordLoading: false
    });
};

export const clearRecord = (state) => {
    return updateObject(state, {
        record: null
    });
};

export const fetchRecordFail = (state, action) => {
    return updateObject(state, {
        recordError: action.error,
        isRecordLoading: false
    });
};

export const addRecordStart = (state) => {
    return updateObject(state, {
        isRecordAddLoading: true,
        addRecordError: null
    });
};

export const addRecordToTop = (state, newRecord) => {
    // check to see if record already exists in the state, update if so, insert if not
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === newRecord.id);
    let updatedRecords = [];
    // only update if the record exists in the list
    if (index !== -1) {
        updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            newRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];
    } else {
        updatedRecords = addObjectToTop(existingList, newRecord);
    }
    
    return updateObject(state, {
        records: updatedRecords
    });
};

export const addRecordToBottom = (state, newRecord) => {
    // check to see if record already exists in the state, update if so, insert if not
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === newRecord.id);
    let updatedRecords = [];
    // only update if the record exists in the list
    if (index !== -1) {
        updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            newRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];
    } else {
        updatedRecords = addObjectToBottom(existingList, newRecord);
    }

    return updateObject(state, {
        records: updatedRecords
    });
};

export const addRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordAddLoading: false,
        addRecordError: null
    });
};

export const addRecordFail = (state, action) => {
    return updateObject(state, {
        addRecordError: action.error,
        isRecordAddLoading: false
    });
};

export const clearAddRecordLoading = (state) => {
    return updateObject(state, {
        isRecordAddLoading: null
    });
};

export const clearAddRecordError = (state) => {
    return updateObject(state, {
        addRecordError: null
    });
};

export const cancelAddRecord = (state) => {
    return updateObject(state, {
        addRecordError: null,
        isRecordAddLoading: null,
        addRecordId: null
    });
};

export const updateRecord = (state, updatedRecord) => {
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === updatedRecord.id);

    // only update if the record exists in the list
    if (index !== -1) {
        const updatedRecords = [
            ...existingList.slice(0, index), // everything before current obj
            updatedRecord,
            ...existingList.slice(index + 1), // everything after current obj
        ];

        return updateObject(state, {
            records: updatedRecords
        });
    } else {
        return state;
    }
};

export const updateSingleRecord = (state, updatedRecord) => {
    // only update if the record matches what is in the state
    const copyOfState = { ...state };
    const existingObject = copyOfState.record !== undefined && copyOfState.record !== null && copyOfState.record !== {} ? { ...copyOfState.record } : null;
    if (existingObject !== null && existingObject.id === updatedRecord.id) {
        return updateObject(state, {
            record: updatedRecord
        });
    } else {
        return state;
    }
};

export const updateRecordStart = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: true,
        updateRecordError: null
    });
};

export const updateRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: false,
        updateRecordError: null
    });
};

export const updateRecordFail = (state, action) => {
    return updateObject(state, {
        updateRecordError: action.error,
        isRecordUpdateLoading: false
    });
};

export const clearUpdateRecordLoading = (state) => {
    return updateObject(state, {
        isRecordUpdateLoading: null
    });
};

export const clearUpdateRecordError = (state) => {
    return updateObject(state, {
        updateRecordError: null
    });
};

export const cancelUpdateRecord = (state) => {
    return updateObject(state, {
        updateRecordError: null,
        isRecordUpdateLoading: null
    });
};

export const updateRecordStatusStart = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: true,
        updateRecordStatusError: null
    });
};

export const updateRecordStatusSuccess = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: false,
        updateRecordStatusError: null
    });
};

export const updateRecordStatusFail = (state, action) => {
    return updateObject(state, {
        updateRecordStatusError: action.error,
        isRecordStatusUpdateLoading: false
    });
};

export const clearUpdateRecordStatusLoading = (state) => {
    return updateObject(state, {
        isRecordStatusUpdateLoading: null
    });
};

export const clearUpdateRecordStatusError = (state) => {
    return updateObject(state, {
        updateRecordStatusError: null
    });
};

export const cancelUpdateRecordStatus = (state) => {
    return updateObject(state, {
        updateRecordStatusError: null,
        isRecordStatusUpdateLoading: null
    });
};

export const removeRecordStart = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: true,
        removeRecordError: null
    });
};

export const removeRecord = (state, recordToRemove) => {
    const copyOfState = { ...state };
    let existingList = [...copyOfState.records];
    const index = existingList.findIndex(obj => obj.id === recordToRemove.id);

    // only update if the record exists in the list
    if (index !== -1) {
        // returns the deleted items
        existingList.splice(index, 1);

        return updateObject(state, {
            records: existingList
        });
    } else {
        return state;
    }
};

export const removeRecordSuccess = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: false,
        removeRecordError: null
    });
};

export const removeRecordFail = (state, action) => {
    return updateObject(state, {
        removeRecordError: action.error,
        isRecordRemoveLoading: false
    });
};

export const clearRemoveRecordLoading = (state) => {
    return updateObject(state, {
        isRecordRemoveLoading: null
    });
};

export const clearRemoveRecordError = (state) => {
    return updateObject(state, {
        removeRecordError: null
    });
};

export const cancelRemoveRecord = (state) => {
    return updateObject(state, {
        removeRecordError: null,
        isRecordRemoveLoading: null
    });
};