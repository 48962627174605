import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Modal, Drawer, Dropdown, Menu } from 'antd';
import classes from './Producers.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faUsers, faTractor } from '@fortawesome/free-solid-svg-icons';
import DataTable from "../../components/DataTable/DataTable";
import StringFormatter from '../../shared/stringFormatter';
import DocumentList from '../../components/DocumentList/DocumentList';
import EditProducer from '../../components/EditProducer/EditProducer';
import EditAccount from "../../components/EditAccount/EditAccount";
import ColumnSearchFilter from '../../shared/tableColumnSearchFilter';

const stringFormatter = new StringFormatter();

const Producers = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Producer";
    const pluralEntityName = "Producers";

    const columnSearchFilter = new ColumnSearchFilter();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isLoading);
    const records = useSelector(state => state.accounts.records);
    const pagination = useSelector(state => state.accounts.pagination);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showEditProducer, setShowEditProducer] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);
    const [expiringSoonDocumentsCount, setExpiringSoonDocumentsCount] = useState(0);
    const [expiredDocumentsCount, setExpiredDocumentsCount] = useState(0);
    const [searchText, setSearchText] = useState({ name: '', irisId: '', 'producer.irisId': '' });

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const updateExpiringSoonDocumentsCount = (value) => {
        //console.log(value);
        setExpiringSoonDocumentsCount(value);
    };

    const updateExpiredDocumentsCount = (value) => {
        //console.log(value);
        setExpiredDocumentsCount(value);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditProducer = () => {
        setShowEditProducer(!showEditProducer);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const openProducerView = (id) => {
        props.history.push({ pathname: "/producers/" + id });
    };

    const goToNewAccount = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['RECEIVER'],
                accountRegistrationId: null
            }
        });
    };

    const setRowClassName = (record) => {
        return record.isVerified === undefined || record.isVerified === null || record.isVerified === false || record.documentsPendingApprovalCount > 0 ? classes.unverifiedRow : classes.dataTableRow;
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isProducer: true,
            isDeleted: false
        };

        dispatch(actionCreators.fetchAccounts(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isProducer: true,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (filters !== undefined && filters !== null) {
            if (filters.name !== undefined && filters.name !== null && filters.name.length > 0) {
                searchParams['name:contains'] = filters.name[0];
            }
            if (filters.irisId !== undefined && filters.irisId !== null && filters.irisId.length > 0) {
                searchParams['irisId:contains'] = filters.irisId[0];
            }
            if (filters['producer.irisId'] !== undefined && filters['producer.irisId'] !== null && filters['producer.irisId'].length > 0) {
                searchParams['producer.irisId:contains'] = filters['producer.irisId'][0];
            }
        }

        dispatch(actionCreators.fetchAccounts(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={() => { setSelectedRecord(record); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editAccount" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: '10px' }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
                <Menu.Item key="editProducer" onClick={() => { setSelectedRecord(record); toggleEditProducer(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTractor} style={{ marginRight: '10px' }} />
                    <span>Edit Producer Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openProducerView(record.id); }}>
                    <span>Go to Producer Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...columnSearchFilter.getColumnSearchProps('name', 'Name', searchText, setSearchText)
        },
        {
            title: 'Account Id',
            dataIndex: 'irisId',
            key: 'irisId',
            ...columnSearchFilter.getColumnSearchProps('irisId', 'Account Id', searchText, setSearchText)
        },
        {
            title: 'Producer Id',
            dataIndex: ['producer', 'irisId'],
            key: 'producer.irisId',
            ...columnSearchFilter.getColumnSearchProps('producer.irisId', 'Producer Id', searchText, setSearchText)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            key: 'fax',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        },
        {
            title: 'Is Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Documents Pending Approval',
            dataIndex: 'documentsPendingApprovalCount',
            key: 'documentsPendingApprovalCount',
        },
        {
            title: 'Missing Documents',
            dataIndex: 'documentsMissingCount',
            key: 'documentsMissingCount',
        }
    ];

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const editProducerComponents = (
        <Drawer
            title={"Edit Producer Details"}
            onClose={toggleEditProducer}
            visible={showEditProducer}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditProducer cancel={toggleEditProducer} record={selectedRecord} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Producer Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {selectedRecord && <DocumentList entityType="ACCOUNT" account={selectedRecord} entityId={selectedRecord.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} updateExpiringSoonDocumentsCount={updateExpiringSoonDocumentsCount} updateExpiredDocumentsCount={updateExpiredDocumentsCount} />}
            {selectedRecord !== undefined && selectedRecord !== null && selectedRecord.isVerified === false && entityType === 'STAFF' ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(selectedRecord.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0 || expiredDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={setRowClassName}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={goToNewAccount}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {documentsComponent}
            {editEntityComponents}
            {editProducerComponents}
        </DataTable>
    );
};

export default withRouter(Producers);
