import React from "react";
import { Col, Row, Alert, Tooltip } from "antd";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InfoCircleOutlined } from "@ant-design/icons";

const FormItemTriple = (props) => {
    const methods = useFormContext();

    const label1 = props.label1;
    const labelCol1 = props.labelCol1;
    const wrapperCol1 = props.wrapperCol1;
    const required1 = props.required1 !== undefined && props.required1 !== null ? props.required1 : false;
    const noColon1 = props.noColon1 !== undefined && props.noColon1 !== null ? props.noColon1 : false;
    const name1 = props.name1;
    const as1 = props.as1;
    const render1 = props.render1;
    const rules1 = props.rules1;
    const defaultValue1 = props.defaultValue1;
    const onChange1 = props.onChange1;
    const disabled1 = props.disabled1;
    const tooltipText1 = props.tooltip1;

    const label2 = props.label2;
    const labelCol2 = props.labelCol2;
    const wrapperCol2 = props.wrapperCol2;
    const required2 = props.required2 !== undefined && props.required2 !== null ? props.required2 : false;
    const noColon2 = props.noColon2 !== undefined && props.noColon2 !== null ? props.noColon2 : false;
    const name2 = props.name2;
    const as2 = props.as2;
    const render2 = props.render2;
    const rules2 = props.rules2;
    const defaultValue2 = props.defaultValue2;
    const onChange2 = props.onChange2;
    const disabled2 = props.disabled2;
    const tooltipText2 = props.tooltip2;

    const label3 = props.label3;
    const labelCol3 = props.labelCol3;
    const wrapperCol3 = props.wrapperCol3;
    const required3 = props.required3 !== undefined && props.required3 !== null ? props.required3 : false;
    const noColon3 = props.noColon3 !== undefined && props.noColon3 !== null ? props.noColon3 : false;
    const name3 = props.name3;
    const as3 = props.as3;
    const render3 = props.render3;
    const rules3 = props.rules3;
    const defaultValue3 = props.defaultValue3;
    const onChange3 = props.onChange3;
    const disabled3 = props.disabled3;
    const tooltipText3 = props.tooltip3;


    const helpCol = props.helpCol;
    const help = props.help;
    const format = props.format !== undefined && props.format !== null ? props.format : 'vertical';

    const controller1Props = {
        name: name1
    };

    if (as1 !== undefined) {
        controller1Props.as = as1;
    }

    if (render1 !== undefined) {
        controller1Props.render = render1;
    }

    if (rules1 !== undefined) {
        controller1Props.rules = rules1;
    }

    if (defaultValue1 !== undefined) {
        controller1Props.defaultValue = defaultValue1;
    }

    if (onChange1 !== undefined) {
        controller1Props.onChange = onChange1;
    }

    if (disabled1 !== undefined) {
        controller1Props.disabled = disabled1;
    }

    //console.log(controller1Props);

    const controller2Props = {
        name: name2
    };

    if (as2 !== undefined) {
        controller2Props.as = as2;
    }

    if (render2 !== undefined) {
        controller2Props.render = render2;
    }

    if (rules2 !== undefined) {
        controller2Props.rules = rules2;
    }

    if (defaultValue2 !== undefined) {
        controller2Props.defaultValue = defaultValue2;
    }

    if (onChange2 !== undefined) {
        controller2Props.onChange = onChange2;
    }

    if (disabled2 !== undefined) {
        controller2Props.disabled = disabled2;
    }

    //console.log(controller2Props);

    const controller3Props = {
        name: name3
    };

    if (as3 !== undefined) {
        controller3Props.as = as3;
    }

    if (render3 !== undefined) {
        controller3Props.render = render3;
    }

    if (rules3 !== undefined) {
        controller3Props.rules = rules3;
    }

    if (defaultValue3 !== undefined) {
        controller3Props.defaultValue = defaultValue3;
    }

    if (onChange3 !== undefined) {
        controller3Props.onChange = onChange3;
    }

    if (disabled3 !== undefined) {
        controller3Props.disabled = disabled3;
    }

    //console.log(controller3Props);

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    const getTooltip1 = () => {
        if (tooltipText1 !== undefined && tooltipText1 !== null) {
            return (
                <Tooltip placement="top" title={tooltipText1}>
                    <InfoCircleOutlined style={{ color: '#16588E', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return undefined;
        }
    };

    const getTooltip2 = () => {
        if (tooltipText2 !== undefined && tooltipText2 !== null) {
            return (
                <Tooltip placement="top" title={tooltipText2}>
                    <InfoCircleOutlined style={{ color: '#16588E', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return undefined;
        }
    };

    const getTooltip3 = () => {
        if (tooltipText3 !== undefined && tooltipText3 !== null) {
            return (
                <Tooltip placement="top" title={tooltipText3}>
                    <InfoCircleOutlined style={{ color: '#16588E', marginLeft: 8 }} />
                </Tooltip>
            );
        } else {
            return undefined;
        }
    };

    if (format === 'vertical') {
        return (
            <>
                {(label1 || label2 || label3) &&
                    <Row gutter={[8, 8]} style={{ lineHeight: 2.0 }}>
                        {label1 && <Col {...labelCol1}>{required1 ? '* ' : ''}{label1}{getTooltip1()}{noColon1 ? '' : ':'}</Col>}
                        {label2 && <Col {...labelCol2}>{required2 ? '* ' : ''}{label2}{getTooltip2()}{noColon2 ? '' : ':'}</Col>}
                        {label3 && <Col {...labelCol3}>{required3 ? '* ' : ''}{label3}{getTooltip3()}{noColon3 ? '' : ':'}</Col>}
                    </Row>
                }
                <Row gutter={[8, 8]}>
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                    <Col {...wrapperCol3}>
                        <Controller control={methods.control} {...controller3Props} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                }
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined) && (
                    <Row gutter={[8, 8]}>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...wrapperCol3}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name3}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name3}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    } else if (format === 'horizontal') {
        return (
            <>
                <Row gutter={[8, 8]}>
                    {label1 && <Col {...labelCol1} style={{ textAlign: 'right' }}>{required1 ? '* ' : ''}{label1}{getTooltip1()}{noColon1 ? '' : ':'}</Col>}
                    <Col {...wrapperCol1}>
                        <Controller control={methods.control} {...controller1Props} />
                    </Col>
                    {label2 && <Col {...labelCol2} style={{ textAlign: 'right' }}>{required2 ? '* ' : ''}{label2}{getTooltip2()}{noColon2 ? '' : ':'}</Col>}
                    <Col {...wrapperCol2}>
                        <Controller control={methods.control} {...controller2Props} />
                    </Col>
                    {label3 && <Col {...labelCol3} style={{ textAlign: 'right' }}>{required3 ? '* ' : ''}{label3}{getTooltip3()}{noColon3 ? '' : ':'}</Col>}
                    <Col {...wrapperCol3}>
                        <Controller control={methods.control} {...controller3Props} />
                    </Col>
                </Row>
                {help &&
                    <Row gutter={[8, 8]}>
                        <Col {...helpCol}>{help}</Col>
                    </Row>
                }
                {(getError(methods.errors, name1) !== undefined || getError(methods.errors, name2) !== undefined || getError(methods.errors, name3) !== undefined) && (
                    <Row gutter={[8, 8]}>
                        <Col {...labelCol1}></Col>
                        <Col {...wrapperCol1}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name1}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name1}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol2}></Col>
                        <Col {...wrapperCol2}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name2}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name2}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                        <Col {...labelCol3}></Col>
                        <Col {...wrapperCol3}>
                            <ErrorMessage
                                errors={methods.errors}
                                name={name3}
                                render={({ message, messages }) => {
                                    if (messages !== undefined && messages !== null) {
                                        return Object.entries(messages).map(([type, message]) => (<Alert key={`${name3}-${type}`} message={message} type="error" />));
                                    } else if (message !== undefined && message !== null) {
                                        return <Alert message={message} type="error" />;
                                    } else {
                                        return null;
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    } else {
        return null;
    }
};

export default FormItemTriple;
