import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Alert, Spin, Input, Modal, Select, InputNumber, Row, Col, Radio, Checkbox, Timeline, Empty } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Enums from '../../shared/enums';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import pickUpMarker from '../../assets/img/pickUpMarker.png';
import dropOffMarker from '../../assets/img/dropOffMarker.png';
import logger from '../../shared/logger';
import StringFormatter from '../../shared/stringFormatter';
import { DownOutlined, PlusOutlined, RightOutlined, DeleteOutlined } from '@ant-design/icons';
import DataCollapsableRow from '../DataCollapsableRow/DataCollapsableRow';
import { ErrorMessage } from '@hookform/error-message';
import DataListRow from '../DataListRow/DataListRow';

TrimbleMaps.APIKey = process.env.REACT_APP_TMS_API_KEY;

const { Option } = Select;
const stringFormatter = new StringFormatter();

const lon = -77.0365, lat = 38.8977, zoom = 8;

const NewLinkedLoadLane = props => {
    //#region props

    const accountId = props.accountId;
    const linkedAccountId = props.linkedAccountId;

    //#endregion
    //#region useRefs

    const mapRef = useRef(null);
    const routingLayer = useRef(null);
    const stopLayerGroupRef = useRef(null);
    const preCoolReefer = useRef(null);
    const minRunTemp = useRef(null);
    const maxRunTemp = useRef(null);

    //#endregion
    //#region useForms

    const methods = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            pickUpLocationId: null,
            pickUpLoadingType: null,
            dropOffLocationId: null,
            dropOffLoadingType: null,
            accountId: null,
            customerLaneId: '',
            perMileRate: null,
            perMileRateUnit: null,
            approxDistance: null,
            approxTransitTime: null
        }
    });
    const methods2 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            locationId: null,
            loadingType: null
        }
    });
    const methods3 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            commodityId: null,
            unitPackagingInitCount: null,
            unitPackaging: null,
            bulkPackagingInitCount: null,
            bulkPackaging: null
        }
    });
    const methods4 = useForm({
        mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true,
        defaultValues: {
            trailerTypeId: null,
            trailerLength: null,
            trailerWeightCapacity: null,
            minRunTemp: null,
            maxRunTemp: null,
            preCoolReefer: null,
            equipmentSpecifics: null,
            driverRequirements: null
        }
    });

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLoadLanes.isRecordAddLoading);
    const error = useSelector(state => state.linkedLoadLanes.addRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);
    const lineItemTypes = useSelector(state => state.orchestrator.lineItemTypes);
    const trailerTypes = useSelector(state => state.orchestrator.trailerTypes);
    const locations = useSelector(state => state.locations.records);
    const isLoadingLocations = useSelector(state => state.locations.isLoading);
    const linkedLocations = useSelector(state => state.linkedLocations.records);
    const isLoadingLinkedLocations = useSelector(state => state.linkedLocations.isLoading);
    const linkedAccounts = useSelector(state => state.linkedAccounts.records);
    const isLoadingLinkedAccounts = useSelector(state => state.linkedAccounts.isLoading);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);

    //#endregion
    //#region Enums

    const currencyOptions = Enums.Currencies.selectListOptions();
    const unitPackagingOptions = Enums.UnitPackaging.selectListOptions();
    const bulkPackagingOptions = Enums.BulkPackaging.selectListOptions();

    //#endregion
    //#region useStates

    const [locationsList, setLocationsList] = useState([]);
    const [pickUpStops, setPickUpStops] = useState([{
        stopType: 'PICK_UP',
        id: 0,
        sequence: 0,
        stopTypeSequence: 0,
        loadingType: 'LIVE'
    }]);
    const [dropOffStops, setDropOffStops] = useState([{
        stopType: 'DROP_OFF',
        id: 1,
        sequence: 1,
        stopTypeSequence: 0,
        loadingType: 'LIVE'
    }]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showNewPickUpStopLocation, setShowNewPickUpStopLocation] = useState(false);
    const [showNewDropOffStopLocation, setShowNewDropOffStopLocation] = useState(false);
    const [showEditStopLocation, setShowEditStopLocation] = useState(false);
    const [showEditEquipmentNeedsAndServices, setShowEditEquipmentNeedsAndServices] = useState(false);
    const [showAddStopCommodity, setShowAddStopCommodity] = useState(false);
    const [equipmentData, setEquipmentData] = useState({});
    const [practicalRouteMiles, setPracticalRouteMiles] = useState(0);
    const [practicalRouteMinutes, setPracticalRouteMinutes] = useState(0);
    const [shippers, setShippers] = useState([]);
    const [shipperId, setShipperId] = useState(null);
    const [pickUpsEnabled, setPickUpsEnabled] = useState(false);
    const [dropOffsEnabled, setDropOffsEnabled] = useState(false);
    const [equipmentSpecificOptions, setEquipmentSpecificOptions] = useState([]);
    const [driverRequirementOptions, setDriverRequirementOptions] = useState([]);
    const [showReeferOptions, setShowReeferOptions] = useState(false);
    const [isMapReady, setIsMapReady] = useState(false);

    //#endregion
    //#region toggles

    const toggleNewPickUpStopLocation = () => {
        // reset form
        methods.setValue('pickUpLocationId', null);
        methods.setValue('pickUpLoadingType', null);
        methods.clearErrors();

        setShowNewPickUpStopLocation(!showNewPickUpStopLocation);
    };

    const toggleNewDropOffStopLocation = () => {
        // reset form
        methods.setValue('dropOffLocationId', null);
        methods.setValue('dropOffLoadingType', null);
        methods.clearErrors();

        setShowNewDropOffStopLocation(!showNewDropOffStopLocation);
    };

    const toggleEditStopLocation = () => {
        // reset form
        methods2.setValue('locationId', null);
        methods2.setValue('loadingType', null);
        methods2.clearErrors();

        setShowEditStopLocation(!showEditStopLocation);
    };

    const toggleAddStopCommodity = () => {
        // reset form
        methods3.setValue('commodityId', null);
        methods3.setValue('unitPackagingInitCount', null);
        methods3.setValue('unitPackaging', null);
        methods3.setValue('bulkPackagingInitCount', null);
        methods3.setValue('bulkPackaging', null);
        methods3.clearErrors();

        setShowAddStopCommodity(!showAddStopCommodity);
    };

    const toggleEditEquipmentNeedsAndServices = () => {
        // reset form
        methods4.setValue('trailerTypeId', null);
        methods4.setValue('trailerLength', null);
        methods4.setValue('trailerWeightCapacity', null);
        methods4.setValue('minRunTemp', null);
        methods4.setValue('maxRunTemp', null);
        methods4.setValue('preCoolReefer', null);
        methods4.setValue('equipmentSpecifics', null);
        methods4.setValue('driverRequirements', null);
        methods4.clearErrors();

        setShowEditEquipmentNeedsAndServices(!showEditEquipmentNeedsAndServices);
    };

    //#endregion
    //#region map functions

    const addStopMarker = (markerIcon, stopId, stop, latitude, longitude) => {
        if (mapRef.current !== null) {
            if (stopLayerGroupRef.current !== undefined && stopLayerGroupRef.current !== null) {
                stopLayerGroupRef.current.features.push({
                    type: 'Feature',
                    properties: {
                        name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                        stopId: stopId,
                        stopType: stop.stopType,
                        iconSize: [30, 30],
                        icon: markerIcon
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [longitude, latitude]
                    }
                });
            } else {
                let geoJsonStopData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: stop.stopLocation ? stop.stopLocation.name : Enums.StopTypes.getValueByName(stop.stopType),
                            stopId: stopId,
                            stopType: stop.stopType,
                            iconSize: [30, 30],
                            icon: markerIcon
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude]
                        }
                    }]
                };

                stopLayerGroupRef.current = geoJsonStopData;
            }

            const stops = mapRef.current.getSource('stops');
            if (stops === undefined) {
                mapRef.current.addSource('stops', {
                    type: 'geojson',
                    data: stopLayerGroupRef.current
                });

                mapRef.current.addLayer({
                    id: 'stopsLayer',
                    type: 'symbol',
                    source: 'stops',
                    minzoom: 0,
                    maxzoom: 24,
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 0.5,
                        'icon-allow-overlap': true
                    }
                });
            } else {
                stops.setData(stopLayerGroupRef.current);
            }
        }
    };

    //#endregion
    //#region onChanges

    const onChangeLinkedAccount = (value) => {
        //console.log(`selected ${value}`);
        setShipperId(value);
    };

    const onChangeTrailerType = (value) => {
        if (value !== undefined && value !== null) {
            setEquipmentData({ ...equipmentData, trailerTypeId: value });
            let equipmentSpecificOptionsList = [];
            let driverRequirementOptionsList = [];
            if (lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0) {
                let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'EQUIPMENT_SPECIFIC');
                let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(value) && i.itemType === 'LOAD_REQUIREMENT');

                for (let i = 0; i < equipmentSpecifics.length; i++) {
                    equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                }
                for (let i = 0; i < driverRequirements.length; i++) {
                    driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                }
            }
            setEquipmentSpecificOptions(equipmentSpecificOptionsList);
            setDriverRequirementOptions(driverRequirementOptionsList);

            if (trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0) {
                let selectedTrailerType = trailerTypes.find(s => s.id === value);
                if (selectedTrailerType !== undefined && selectedTrailerType !== null) {
                    if (selectedTrailerType.name === "REEFER") {
                        setShowReeferOptions(true);
                    } else {
                        setShowReeferOptions(false);
                    }
                }
            }
        } else {
            setEquipmentSpecificOptions([]);
            setDriverRequirementOptions([]);
            setShowReeferOptions(false);
        }
    };

    //#endregion
    //#region submits, validators, and cancels

    const onEditStopLocation = (data) => {
        const stopLocationId = data.locationId;
        const loadingType = data.loadingType;
        if (stopLocationId !== undefined && stopLocationId !== null) {
            let stopLocation = locationsList.find(l => l.id === stopLocationId);
            if (stopLocation !== undefined && stopLocation !== null) {
                const updatedValues = {
                    stopLocation: stopLocation,
                    stopLocationId: stopLocationId,
                    timeZone: stopLocation.timeZone,
                    loadingType: loadingType
                };

                if (selectedRecord !== undefined && selectedRecord !== null) {
                    if (selectedRecord.stopType === 'PICK_UP') {
                        let existingList = [...pickUpStops];
                        const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                        // only update if the record exists in the list
                        if (index !== -1) {
                            const updatedRecord = {
                                ...existingList[index],
                                ...updatedValues
                            };
                            const updatedRecords = [
                                ...existingList.slice(0, index), // everything before current obj
                                updatedRecord,
                                ...existingList.slice(index + 1), // everything after current obj
                            ];

                            setPickUpStops([...updatedRecords]);
                        }
                    } else if (selectedRecord.stopType === 'DROP_OFF') {
                        let existingList = [...dropOffStops];
                        const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                        // only update if the record exists in the list
                        if (index !== -1) {
                            const updatedRecord = {
                                ...existingList[index],
                                ...updatedValues
                            };
                            const updatedRecords = [
                                ...existingList.slice(0, index), // everything before current obj
                                updatedRecord,
                                ...existingList.slice(index + 1), // everything after current obj
                            ];

                            setDropOffStops([...updatedRecords]);
                        }
                    }

                    toggleEditStopLocation();
                    setSelectedRecord(null);
                } else {
                    return;
                }
            }
        }
    };

    const onAddStopCommodity = (data) => {
        const commodityId = data.commodityId;
        const unitPackagingInitCount = data.unitPackagingInitCount;
        const unitPackaging = data.unitPackaging;
        const bulkPackagingInitCount = data.bulkPackagingInitCount;
        const bulkPackaging = data.bulkPackaging;

        methods3.clearErrors();

        let name = null;
        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
            let commodity = commodities.find(c => c.id === commodityId);
            if (commodity !== undefined && commodity !== null) {
                name = commodity.name;
            }
        }

        let newStopCommodity = {
            commodityId: commodityId,
            name: name
        };

        if (unitPackagingInitCount !== undefined && unitPackagingInitCount !== null && unitPackagingInitCount > 0) {
            if (unitPackaging === undefined || unitPackaging === null) {
                methods3.setError('unitPackaging', { type: "validate", message: 'You must provide a Unit of Measure/Unit Packaging if you are going to provide a Unit Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.unitPackagingInitCount = unitPackagingInitCount;
                newStopCommodity.unitPackaging = unitPackaging;
            }
        } else if ((unitPackagingInitCount === undefined || unitPackagingInitCount === null || unitPackagingInitCount === 0) && (unitPackaging !== undefined && unitPackaging !== null)) {
            methods3.setError('unitPackagingInitCount', { type: "validate", message: 'You must provide a Unit Quantity greater than 0 if you are going to provide a Unit of Measure/Unit Packaging for this Commodity.' });
            return;
        }

        if (bulkPackagingInitCount !== undefined && bulkPackagingInitCount !== null && bulkPackagingInitCount > 0) {
            if (bulkPackaging === undefined || bulkPackaging === null) {
                methods3.setError('bulkPackaging', { type: "validate", message: 'You must provide Bulk Packaging if you are going to provide a Bulk Quantity for this Commodity.' });
                return;
            } else {
                newStopCommodity.bulkPackagingInitCount = bulkPackagingInitCount;
                newStopCommodity.bulkPackaging = bulkPackaging;
            }
        } else if ((bulkPackagingInitCount === undefined || bulkPackagingInitCount === null || bulkPackagingInitCount === 0) && (bulkPackaging !== undefined && bulkPackaging !== null)) {
            methods3.setError('bulkPackagingInitCount', { type: "validate", message: 'You must provide a Bulk Quantity greater than 0 if you are going to provide Bulk Packaging for this Commodity.' });
            return;
        }

        //console.log(newStopCommodity);

        if (selectedRecord !== undefined && selectedRecord !== null) {
            let stopCommodities = [];
            if (selectedRecord.commodities !== undefined && selectedRecord.commodities !== null && selectedRecord.commodities.length > 0) {
                stopCommodities = [...selectedRecord.commodities];
                if (stopCommodities.find(c => c.commodityId === commodityId && c.bulkPackaging === bulkPackaging && c.unitPackaging === unitPackaging) === undefined) {
                    stopCommodities.push(newStopCommodity);
                } else {
                    methods3.setError('commodityId', { type: "validate", message: 'This commodity has already been added to this location with the unit and bulk packaging you provided.' });
                    return;
                }
            } else {
                stopCommodities.push(newStopCommodity);
            }

            //console.log(stopCommodities);

            if (selectedRecord.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (updatedPickUpRecords !== undefined && updatedPickUpRecords !== null && updatedPickUpRecords.length > 0) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (updatedPickUpRecords[0].commodities !== undefined && updatedPickUpRecords[0].commodities !== null && updatedPickUpRecords[0].commodities.length > 0) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (pickUpStop.commodities !== undefined && pickUpStop.commodities !== null && pickUpStop.commodities.length > 0) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (selectedRecord.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === selectedRecord.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }

            toggleAddStopCommodity();
            setSelectedRecord(null);
        } else {
            return;
        }
    };

    const onRemoveStopCommodity = (stop, index) => {
        if (stop !== undefined && stop !== null) {
            let stopCommodities = [];
            if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                stopCommodities = [...stop.commodities];
                stopCommodities.splice(index, 1);
            }
            if (stop.stopType === 'PICK_UP') {
                let existingList = [...pickUpStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };

                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setPickUpStops([...updatedRecords]);

                    if (dropOffStops.length === 1) {
                        // if there is only one drop-off stop, set the value for the commodities
                        let updatedPickUpRecords = [...updatedRecords];
                        if (updatedPickUpRecords !== undefined && updatedPickUpRecords !== null && updatedPickUpRecords.length > 0) {
                            let dropOffStopCommodities = [];
                            if (updatedPickUpRecords.length === 1) {
                                if (updatedPickUpRecords[0].commodities !== undefined && updatedPickUpRecords[0].commodities !== null && updatedPickUpRecords[0].commodities.length > 0) {
                                    dropOffStopCommodities = updatedPickUpRecords[0].commodities;
                                }
                            } else if (updatedPickUpRecords.length > 1) {
                                updatedPickUpRecords.forEach((pickUpStop) => {
                                    if (pickUpStop.commodities !== undefined && pickUpStop.commodities !== null && pickUpStop.commodities.length > 0) {
                                        pickUpStop.commodities.forEach((stopCommodity) => {
                                            let existingDropOffStopCommodity = dropOffStopCommodities.find(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                            if (existingDropOffStopCommodity === undefined || existingDropOffStopCommodity === null) {
                                                dropOffStopCommodities.push(stopCommodity);
                                            } else {
                                                const existingDropOffStopCommodityIndex = dropOffStopCommodities.findIndex(c => c.commodityId === stopCommodity.commodityId && c.unitPackaging === stopCommodity.unitPackaging && c.bulkPackaging === stopCommodity.bulkPackaging);
                                                // only update if the record exists in the list
                                                if (existingDropOffStopCommodityIndex !== -1) {
                                                    const updatedExistingDropOffStopCommodity = {
                                                        ...dropOffStopCommodities[existingDropOffStopCommodityIndex]
                                                    };

                                                    if (existingDropOffStopCommodity.unitPackaging !== undefined && existingDropOffStopCommodity.unitPackaging !== null && existingDropOffStopCommodity.unitPackaging === stopCommodity.unitPackaging) {
                                                        updatedExistingDropOffStopCommodity.unitPackagingInitCount = updatedExistingDropOffStopCommodity.unitPackagingInitCount + stopCommodity.unitPackagingInitCount;
                                                    }
                                                    if (existingDropOffStopCommodity.bulkPackaging !== undefined && existingDropOffStopCommodity.bulkPackaging !== null && existingDropOffStopCommodity.bulkPackaging === stopCommodity.bulkPackaging) {
                                                        updatedExistingDropOffStopCommodity.bulkPackagingInitCount = updatedExistingDropOffStopCommodity.bulkPackagingInitCount + stopCommodity.bulkPackagingInitCount;
                                                    }

                                                    const updatedExistingDropOffStopCommodities = [
                                                        ...dropOffStopCommodities.slice(0, existingDropOffStopCommodityIndex), // everything before current obj
                                                        updatedExistingDropOffStopCommodity,
                                                        ...dropOffStopCommodities.slice(existingDropOffStopCommodityIndex + 1), // everything after current obj
                                                    ];

                                                    dropOffStopCommodities = [...updatedExistingDropOffStopCommodities];
                                                }
                                            }
                                        });
                                    }
                                });
                            }

                            let existingDropOffList = [...dropOffStops];

                            const updatedDropOffRecord = {
                                ...existingDropOffList[0],
                                commodities: [...dropOffStopCommodities]
                            };
                            const updatedDropOffRecords = [
                                ...existingDropOffList.slice(0, 0), // everything before current obj
                                updatedDropOffRecord,
                                ...existingDropOffList.slice(1), // everything after current obj
                            ];

                            setDropOffStops([...updatedDropOffRecords]);
                        }
                    }
                }
            } else if (stop.stopType === 'DROP_OFF') {
                let existingList = [...dropOffStops];
                const index = existingList.findIndex(obj => obj.id === stop.id);
                // only update if the record exists in the list
                if (index !== -1) {
                    const updatedRecord = {
                        ...existingList[index],
                        commodities: [...stopCommodities]
                    };
                    const updatedRecords = [
                        ...existingList.slice(0, index), // everything before current obj
                        updatedRecord,
                        ...existingList.slice(index + 1), // everything after current obj
                    ];

                    setDropOffStops([...updatedRecords]);
                }
            }
        } else {
            return;
        }
    };

    const onEditEquipmentNeedsAndServices = (data) => {
        let trailerTypeId = data.trailerTypeId;
        let trailerLength = data.trailerLength;
        let trailerWeightCapacity = data.trailerWeightCapacity;
        let minRunTemp = data.minRunTemp;
        let maxRunTemp = data.maxRunTemp;
        let preCoolReefer = data.preCoolReefer;
        let equipmentSpecifics = data.equipmentSpecifics;
        let driverRequirements = data.driverRequirements;

        let updatedValues = {};

        if (trailerTypeId !== undefined && trailerTypeId !== null) {
            updatedValues.trailerTypeId = trailerTypeId;
            if (trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0) {
                let selectedTrailerType = trailerTypes.find(s => s.id === trailerTypeId);
                if (selectedTrailerType !== undefined && selectedTrailerType !== null) {
                    updatedValues.trailerType = selectedTrailerType;
                }
            }
        }

        if (trailerLength !== undefined && trailerLength !== null) {
            updatedValues.trailerLength = trailerLength;
            updatedValues.trailerLengthUnit = "FT";
        } else {
            updatedValues.trailerLength = null;
            updatedValues.trailerLengthUnit = null;
        }

        if (trailerWeightCapacity !== undefined && trailerWeightCapacity !== null) {
            updatedValues.trailerWeightCapacity = trailerWeightCapacity;
            updatedValues.trailerWeightCapacityUnit = "LB";
        } else {
            updatedValues.trailerWeightCapacity = null;
            updatedValues.trailerWeightCapacityUnit = null;
        }

        if (preCoolReefer !== undefined && preCoolReefer !== null) {
            updatedValues.preCoolReefer = Number(preCoolReefer);
            updatedValues.preCoolReeferUnit = "F";
        } else {
            updatedValues.preCoolReefer = null;
            updatedValues.preCoolReeferUnit = null;
        }
        if (minRunTemp !== undefined && minRunTemp !== null) {
            updatedValues.minRunTemp = Number(minRunTemp);
            updatedValues.minRunTempUnit = "F";
        } else {
            updatedValues.minRunTemp = null;
            updatedValues.minRunTempUnit = null;
        }
        if (maxRunTemp !== undefined && maxRunTemp !== null) {
            updatedValues.maxRunTemp = Number(maxRunTemp);
            updatedValues.maxRunTempUnit = "F";
        } else {
            updatedValues.maxRunTemp = null;
            updatedValues.maxRunTempUnit = null;
        }

        if (equipmentSpecifics !== undefined && equipmentSpecifics !== null) {
            updatedValues.equipmentSpecifics = equipmentSpecifics;
        } else {
            updatedValues.equipmentSpecifics = [];
        }

        if (driverRequirements !== undefined && driverRequirements !== null) {
            updatedValues.driverRequirements = driverRequirements;
        } else {
            updatedValues.driverRequirements = [];
        }

        let updatedEquipmentData = {
            ...equipmentData,
            ...updatedValues
        };

        setEquipmentData(updatedEquipmentData);
        toggleEditEquipmentNeedsAndServices();
    };

    const onSubmit = (data) => {
        if (accountId && shipperId) {
            let payload = {
                ...data,
                accountId: accountId,
                linkedAccountId: shipperId
            };

            if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
                methods.clearErrors('pickUpStops');
                methods.clearErrors('dropOffStops');

                let loadLaneLocations = [];

                pickUpStops.forEach((stop, stopIndex, arr) => {
                    let newStop = {
                        locationId: stop.stopLocationId,
                        sequence: stopIndex,
                        stopType: 'PICK_UP',
                        loadingType: stop.loadingType
                    };

                    if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            // only want to add commodities to the stop if there are quantities provided for that commodity
                            if (commodity.unitPackagingInitCount !== undefined && commodity.unitPackagingInitCount !== null) {
                                let newCommodity = {
                                    commodityId: commodity.commodityId,
                                    name: commodity.name,
                                    unitPackaging: commodity.unitPackaging,
                                    unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                                };

                                if (commodity.bulkPackaging !== undefined && commodity.bulkPackaging !== null) {
                                    newCommodity.bulkPackaging = commodity.bulkPackaging;
                                    newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                                }
                                newCommodities.push(newCommodity);
                            }
                        });

                        if (!isEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    loadLaneLocations.push({ ...newStop });
                });

                dropOffStops.forEach((stop, stopIndex, arr) => {
                    let newStop = {
                        locationId: stop.stopLocationId,
                        sequence: stopIndex + pickUpStops.length,
                        stopType: 'DROP_OFF',
                        loadingType: stop.loadingType
                    };

                    if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                        let newCommodities = [];
                        stop.commodities.forEach((commodity) => {
                            // only want to add commodities to the stop if there are quantities provided for that commodity
                            if (commodity.unitPackagingInitCount !== undefined && commodity.unitPackagingInitCount !== null) {
                                let newCommodity = {
                                    commodityId: commodity.commodityId,
                                    name: commodity.name,
                                    unitPackaging: commodity.unitPackaging,
                                    unitPackagingInitCount: Number(commodity.unitPackagingInitCount)
                                };

                                if (commodity.bulkPackaging !== undefined && commodity.bulkPackaging !== null) {
                                    newCommodity.bulkPackaging = commodity.bulkPackaging;
                                    newCommodity.bulkPackagingInitCount = Number(commodity.bulkPackagingInitCount);
                                }
                                newCommodities.push(newCommodity);
                            }
                        });

                        if (!isEmpty(newCommodities)) {
                            newStop.commodities = newCommodities;
                        }
                    }

                    loadLaneLocations.push({ ...newStop });
                });

                payload.locations = loadLaneLocations;

                if (equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) {
                    payload.equipmentNeeds = {};

                    if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                        payload.equipmentNeeds.trailerTypeId = equipmentData.trailerTypeId;
                    }

                    if (equipmentData.trailerLength !== undefined && equipmentData.trailerLength !== null) {
                        payload.equipmentNeeds.trailerLength = Number(equipmentData.trailerLength);
                        payload.equipmentNeeds.trailerLengthUnit = "FT";
                    }

                    if (equipmentData.trailerWeightCapacity !== undefined && equipmentData.trailerWeightCapacity !== null) {
                        payload.equipmentNeeds.trailerWeightCapacity = Number(equipmentData.trailerWeightCapacity);
                        payload.equipmentNeeds.trailerWeightCapacityUnit = "LB";
                    }

                    if (equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null) {
                        payload.equipmentNeeds.preCoolReefer = Number(equipmentData.preCoolReefer);
                        payload.equipmentNeeds.preCoolReeferUnit = "F";
                    }

                    if (equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null) {
                        payload.equipmentNeeds.minRunTemp = Number(equipmentData.minRunTemp);
                        payload.equipmentNeeds.minRunTempUnit = "F";
                    }

                    if (equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null) {
                        payload.equipmentNeeds.maxRunTemp = Number(equipmentData.maxRunTemp);
                        payload.equipmentNeeds.maxRunTempUnit = "F";
                    }

                    if (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) {
                        payload.driverRequirements = equipmentData.driverRequirements;
                    }

                    // Get all the Line Item Types from Equipment Needs
                    if (equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) {
                        payload.equipmentSpecifics = equipmentData.equipmentSpecifics;
                    }
                }

                if (data.approxDistance !== undefined && data.approxDistance !== null) {
                    payload.approxDistance = data.approxDistance;
                    payload.approxDistanceUnit = 'MI';
                }

                if (data.approxTransitTime !== undefined && data.approxTransitTime !== null) {
                    payload.approxTransitTime = data.approxTransitTime;
                    payload.approxTransitTimeUnit = 'MIN';
                }

                //console.log(payload);

                if (!isEmpty(payload)) {
                    dispatch(actionCreators.addLinkedLoadLane(payload));
                }
            } else {
                if (pickUpStops === undefined || pickUpStops === null || pickUpStops.length === 0) {
                    methods.setError('pickUpStops', { type: "required", message: 'There must be at least 1 Pick-Up Stop' });
                }
                if (dropOffStops === undefined || dropOffStops === null || dropOffStops.length === 0) {
                    methods.setError('dropOffStops', { type: "required", message: 'There must be at least 1 Drop-Off Stop' });
                }

                return;
            }
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddLoadLane());
        props.cancel();
    };

    //#endregion
    //#region useEffects and useMemos

    useMemo(() => {
        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            setShipperId(linkedAccountId);
        }
        const filteredAccounts = linkedAccounts.filter(a => a.isShipper === true);
        setShippers(filteredAccounts);
    }, [linkedAccountId, linkedAccounts]);

    useMemo(() => {
        if (accountId !== undefined && accountId !== null) {
            dispatch(actionCreators.fetchLinkedAccounts({ page: 1, size: 100000, accountId: accountId, isDeleted: false }));
        }
    }, [accountId]);

    useMemo(() => {
        if (shipperId !== undefined && shipperId !== null) {
            if (entityType === 'STAFF') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: shipperId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: shipperId, isDeleted: false }));
            } else if (entityType === 'SHIPPER' || entityType === 'CARRIER' || entityType === 'PRODUCER' || entityType === 'RECEIVER') {
                dispatch(actionCreators.fetchLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
                dispatch(actionCreators.fetchLinkedLocations({ page: 1, size: 100000, accountId: entityId, isDeleted: false }));
            }
        }
    }, [shipperId, entityType, entityId]);

    useMemo(() => {
        if (locations !== undefined && locations !== null && linkedLocations !== undefined && linkedLocations !== null) {
            let transformedLocations = [];
            if (locations.length > 0) {
                locations.forEach((item) => {
                    transformedLocations.push({
                        ...item
                    });
                });
            }
            if (linkedLocations.length > 0) {
                linkedLocations.forEach((item) => {
                    transformedLocations.push({
                        ...item.location,
                        name: item.name ? item.name : (item.location ? item.location.name : null)
                    });
                });
            }

            setLocationsList(transformedLocations);
        }
    }, [locations, linkedLocations]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (shipperId !== undefined && shipperId !== null) {
                setPickUpsEnabled(true);
            } else {
                setPickUpsEnabled(false);
            }
        }

        return () => isSubscribed = false;
    }, [shipperId]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditStopLocation === true && selectedRecord !== undefined && selectedRecord !== null && !isEmpty(selectedRecord)) {
                if (selectedRecord.stopLocationId !== undefined && selectedRecord.stopLocationId !== null) {
                    methods2.setValue('locationId', selectedRecord.stopLocationId);
                }
                if (selectedRecord.loadingType !== undefined && selectedRecord.loadingType !== null) {
                    methods2.setValue('loadingType', selectedRecord.loadingType);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditStopLocation, selectedRecord]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showEditEquipmentNeedsAndServices === true && equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData) && lineItemTypes !== undefined && lineItemTypes !== null && lineItemTypes.length > 0 && trailerTypes !== undefined && trailerTypes !== null && trailerTypes.length > 0) {
                if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                    methods4.setValue('trailerTypeId', equipmentData.trailerTypeId);

                    let equipmentSpecificOptionsList = [];
                    let driverRequirementOptionsList = [];
                    let equipmentSpecifics = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'EQUIPMENT_SPECIFIC');
                    let driverRequirements = lineItemTypes.filter(i => i.trailerTypeIds.includes(equipmentData.trailerTypeId) && i.itemType === 'LOAD_REQUIREMENT');

                    for (let i = 0; i < equipmentSpecifics.length; i++) {
                        equipmentSpecificOptionsList.push({ value: equipmentSpecifics[i].name, key: equipmentSpecifics[i].name, label: equipmentSpecifics[i].description });
                    }
                    for (let i = 0; i < driverRequirements.length; i++) {
                        driverRequirementOptionsList.push({ value: driverRequirements[i].name, key: driverRequirements[i].name, label: driverRequirements[i].description });
                    }
                    setEquipmentSpecificOptions(equipmentSpecificOptionsList);
                    setDriverRequirementOptions(driverRequirementOptionsList);

                    let selectedTrailerType = trailerTypes.find(s => s.id === equipmentData.trailerTypeId);
                    if (selectedTrailerType !== undefined && selectedTrailerType !== null) {
                        if (selectedTrailerType.name === "REEFER") {
                            setShowReeferOptions(true);
                            if (equipmentData.preCoolReefer) {
                                preCoolReefer.current = equipmentData.preCoolReefer;
                                methods4.setValue('preCoolReefer', equipmentData.preCoolReefer);
                            }
                            if (equipmentData.minRunTemp) {
                                minRunTemp.current = equipmentData.minRunTemp;
                                methods4.setValue('minRunTemp', equipmentData.minRunTemp);
                            }
                            if (equipmentData.trailerLength) {
                                maxRunTemp.current = equipmentData.maxRunTemp;
                                methods4.setValue('maxRunTemp', equipmentData.maxRunTemp);
                            }
                        } else {
                            setShowReeferOptions(false);
                            methods4.setValue('minRunTemp', null);
                            methods4.setValue('maxRunTemp', null);
                            methods4.setValue('preCoolReefer', null);
                            preCoolReefer.current = null;
                            minRunTemp.current = null;
                            maxRunTemp.current = null;
                        }
                    }
                } else {
                    setEquipmentSpecificOptions([]);
                    setDriverRequirementOptions([]);
                    setShowReeferOptions(false);
                    methods4.setValue('minRunTemp', null);
                    methods4.setValue('maxRunTemp', null);
                    methods4.setValue('preCoolReefer', null);
                    preCoolReefer.current = null;
                    minRunTemp.current = null;
                    maxRunTemp.current = null;
                }
                if (equipmentData.trailerLength !== undefined && equipmentData.trailerLength !== null) {
                    methods4.setValue('trailerLength', equipmentData.trailerLength);
                }
                if (equipmentData.trailerWeightCapacity !== undefined && equipmentData.trailerWeightCapacity !== null) {
                    methods4.setValue('trailerWeightCapacity', equipmentData.trailerWeightCapacity);
                }
                if (equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null) {
                    methods4.setValue('equipmentSpecifics', equipmentData.equipmentSpecifics);
                }
                if (equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null) {
                    methods4.setValue('driverRequirements', equipmentData.driverRequirements);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showEditEquipmentNeedsAndServices, equipmentData, lineItemTypes, trailerTypes]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed === true) {
            if (showReeferOptions === true && equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) {
                if (equipmentData.preCoolReefer) {
                    preCoolReefer.current = equipmentData.preCoolReefer;
                    methods4.setValue('preCoolReefer', equipmentData.preCoolReefer);
                }
                if (equipmentData.minRunTemp) {
                    minRunTemp.current = equipmentData.minRunTemp;
                    methods4.setValue('minRunTemp', equipmentData.minRunTemp);
                }
                if (equipmentData.trailerLength) {
                    maxRunTemp.current = equipmentData.maxRunTemp;
                    methods4.setValue('maxRunTemp', equipmentData.maxRunTemp);
                }
            }
        }

        return () => isSubscribed = false;
    }, [showReeferOptions, equipmentData]);

    useEffect(() => {
        mapRef.current = new TrimbleMaps.Map({
            container: `map`,
            center: new TrimbleMaps.LngLat(lon, lat),
            zoom: zoom,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA
        });

        mapRef.current.on('load', function () {
            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//current_location_mark.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('currentLocationMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//pickUpMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('pickUpMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//dropOffMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('dropOffMarker', image);
                }
            });

            mapRef.current.loadImage('d2lu6cvlrvxwy8.cloudfront.net//restStopMarker.png', function (error, image) {
                // Add image to the map
                if (error !== undefined && error !== null) {
                    logger.logErrorEvent('load_map_load_image', error, error.message, true);
                } else if (image !== undefined && image !== null && mapRef.current !== undefined && mapRef.current !== null) {
                    mapRef.current.addImage('restStopMarker', image);
                }
            });

            setIsMapReady(true);

            mapRef.current.resize();
        });

        let mapDiv = document.getElementById('map');
        window.onresize = () => {
            //console.log('window resized');

            if (mapRef.current !== undefined && mapRef.current !== null) {
                mapRef.current.resize();
            }
        };
        mapDiv.onresize = () => {
            //console.log('map container resized');

            if (mapRef.current !== undefined && mapRef.current !== null) {
                mapRef.current.resize();
            }
        };

        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, []);

    useEffect(() => {
        let stopsArray = [];
        if (isMapReady === true && mapRef.current !== null && pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0 && dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            pickUpStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });

            dropOffStops.forEach((stop, stopIndex, arr) => {
                stopsArray.push({ ...stop });
            });

            if (stopsArray.find(stop => stop.stopLocation === undefined || stop.stopLocation.latitude === undefined || stop.stopLocation.longitude === undefined) === undefined) {
                const routePostBody = ({
                    'stops': stopsArray.map((stop) => ({ latitude: stop.stopLocation.latitude, longitude: stop.stopLocation.longitude })),
                    'options': {
                        'distanceUnit': 'MI',
                        'vehicleType': 'TRUCK',
                        'routingType': 'PRACTICAL',
                        'routeOptimization': 'NONE',
                        'isHighwayOnly': false,
                        'isTollDiscouraged': true
                    },
                });

                // get practical route miles
                axiosAuthenticated.post('/tms/route', routePostBody).then(res => {
                    //console.log(res.data);
                    if (res.data !== null && res.data !== undefined && res.data.distance !== null && res.data.distance !== undefined && res.data.minutes !== null && res.data.minutes !== undefined) {
                        setPracticalRouteMiles(res.data.distance);
                        methods.setValue("approxDistance", res.data.distance);
                        setPracticalRouteMinutes(res.data.minutes);
                        methods.setValue("approxTransitTime", res.data.minutes);
                    }
                }).catch(err => {
                    console.log(err);
                });

                let mapStops = stopsArray.filter(stop => stop.stopLocation !== undefined && stop.stopLocation.latitude !== undefined && stop.stopLocation.longitude !== undefined).map((stop) => {
                    return new TrimbleMaps.LngLat(stop.stopLocation.longitude, stop.stopLocation.latitude);
                });
                let mapStopsWithStopType = stopsArray.filter(stop => stop.stopLocation !== undefined && stop.stopLocation.latitude !== undefined && stop.stopLocation.longitude !== undefined).map((stop) => {
                    return {
                        stop: stop,
                        longitude: stop.stopLocation.longitude,
                        latitude: stop.stopLocation.latitude,
                        stopType: stop.stopType,
                        id: stop.id
                    };
                });

                if (routingLayer.current === null) {
                    if (mapStops.length > 0) {
                        routingLayer.current = new TrimbleMaps.Route({
                            routeId: 'newRoute',
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                        routingLayer.current.addTo(mapRef.current);
                    }
                } else {
                    if (mapStops.length > 0) {
                        routingLayer.current.update({
                            stops: [...mapStops],
                            vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                            routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                            routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                            highwayOnly: false,
                            distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                            tollDiscourage: true
                        });
                    }
                }

                if (mapStopsWithStopType.length > 0) {
                    mapStopsWithStopType.forEach((stop) => {
                        if (stop.stopType === 'PICK_UP') {
                            addStopMarker('pickUpMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else if (stop.stopType === 'DROP_OFF') {
                            addStopMarker('dropOffMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        } else {
                            addStopMarker('restStopMarker', stop.id, stop.stop, stop.latitude, stop.longitude);
                        }
                    });
                }
            }
        } else {
            try {
                setPracticalRouteMiles(0);
                methods.setValue("approxDistance", 0);
                setPracticalRouteMinutes(0);
                methods.setValue("approxTransitTime", 0);
                if (routingLayer.current !== null) {
                    routingLayer.current.remove();
                }
            } catch (err) {
                console.log(err);
            }
        }
    }, [isMapReady, pickUpStops, dropOffStops, stopLayerGroupRef, routingLayer, mapRef]);

    useEffect(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addLoadLaneErrorClear());

        methods.clearErrors();
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    //#endregion
    //#region displays

    const getLocationOptions = (stop = null) => {
        let options = [];
        if (locationsList !== undefined && locationsList !== null && locationsList.length > 0) {
            // let usedLocationIds = [];
            // if (pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) {
            //     pickUpStops.forEach((pickUpStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (pickUpStop.id !== stop.id) {
            //                 if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                     usedLocationIds.push(pickUpStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (pickUpStop.stopLocationId !== undefined && pickUpStop.stopLocationId !== null) {
            //                 usedLocationIds.push(pickUpStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // if (dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) {
            //     dropOffStops.forEach((dropOffStop) => {
            //         if (stop !== undefined && stop !== null && stop.id !== undefined && stop.id !== null) {
            //             if (dropOffStop.id !== stop.id) {
            //                 if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                     usedLocationIds.push(dropOffStop.stopLocationId);
            //                 }
            //             }
            //         } else {
            //             if (dropOffStop.stopLocationId !== undefined && dropOffStop.stopLocationId !== null) {
            //                 usedLocationIds.push(dropOffStop.stopLocationId);
            //             }
            //         }
            //     });
            // }

            // Don't filter out already used locations since some loads can have the same location 
            // for pick-up and drop-off in the bulk space where the sites are very large
            locationsList.forEach((item) => {
                // if (!usedLocationIds.includes(item.id)) {
                options.push(<Option value={item.id} key={item.id}>{`${item.name ? item.name : ''} ${item.address !== undefined && item.address !== null ? ' - ' + stringFormatter.toFormattedAddress(item.address) : ''}`}</Option>);
                // }
            });
        }

        return options;
    };

    const getCommodityOptions = (stop) => {
        let options = [];
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                if (stop.stopType === 'PICK_UP') {
                    // if pick-up, don't show commodities that have already been added to this stop
                    let usedCommodityIds = [];
                    if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                        stop.commodities.forEach((stopCommodity) => {
                            usedCommodityIds.push(stopCommodity.commodityId);
                        });
                    }

                    commodities.forEach((commodity) => {
                        if (!usedCommodityIds.includes(commodity.id)) {
                            options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                        }
                    });
                } else if (stop.stopType === 'DROP_OFF') {
                    // only show commodities that the pick-up stops have
                    let usedCommodityIds = [];
                    pickUpStops.forEach((pickUpStop) => {
                        if (pickUpStop.commodities !== undefined && pickUpStop.commodities !== null && pickUpStop.commodities.length > 0) {
                            pickUpStop.commodities.forEach((stopCommodity) => {
                                if (!usedCommodityIds.includes(stopCommodity.commodityId)) {
                                    usedCommodityIds.push(stopCommodity.commodityId);
                                }
                            });
                        }
                    });

                    commodities.forEach((commodity) => {
                        if (usedCommodityIds.includes(commodity.id)) {
                            options.push(<Option value={commodity.id} key={commodity.id}>{commodity.name}</Option>);
                        }
                    });
                }
            }
        }

        return options;
    };

    const getStopLocationDisplay = (stop, stopIndex) => {
        return (
            <DataCollapsableRow
                isDisabled={(((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)}
                isCollapsed={stop && stop.stopLocationId ? false : true}
                title={stop && stop.stopLocation && stop.stopLocation.name ? stop.stopLocation.name : (<Button style={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }} type='link' disabled={(((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? true : false)} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>Select a {stop.stopType === "PICK_UP" ? "Pick-Up" : "Drop-Off"} Location <DownOutlined /></Button>)}
                titleStyle={{ paddingLeft: 0, fontSize: 18, fontWeight: 500, color: '#000000' }}
                titleHasAction={!(stop && stop.stopLocation && stop.stopLocation.name)}
                subtitle={stop && stop.stopLocation && stop.stopLocation.address ? (<span style={{ fontSize: 14 }}>{stringFormatter.toFormattedAddress(stop.stopLocation.address)}</span>) : null}
                header={(stop && stop.stopType) ? (
                    <span style={{ fontSize: 14, textTransform: 'uppercase', color: (((stop.stopType === 'DROP_OFF' && dropOffsEnabled === false) || (stop.stopType === 'PICK_UP' && pickUpsEnabled === false)) ? '#000000' : '#A9A9A9') }}>{`${Enums.StopTypes.getValueByName(stop.stopType)} ${stopIndex + 1}`}</span>
                ) : null}
                extra={(stop && stop.stopLocationId) ? (
                    <>
                        <Button style={{ fontWeight: 'bold' }} type='link' onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button>
                        {(stop.stopType === 'PICK_UP' && pickUpStops.length > 1) ? (
                            <Button style={{ fontWeight: 'bold' }}
                                type="link"
                                onClick={() => {
                                    let existingList = [...pickUpStops];
                                    existingList.splice(stopIndex, 1);
                                    setPickUpStops([...existingList]);
                                }}
                            >
                                remove
                            </Button>
                        ) : null}
                        {(stop.stopType === 'DROP_OFF' && dropOffStops.length > 1) ? (
                            <Button style={{ fontWeight: 'bold' }}
                                type="link"
                                onClick={() => {
                                    let existingList = [...dropOffStops];
                                    existingList.splice(stopIndex, 1);
                                    setDropOffStops([...existingList]);
                                }}
                            >
                                remove
                            </Button>
                        ) : null}
                    </>
                ) : null}
            >
                <div>
                    {(stop.loadingType !== undefined && stop.loadingType !== null) ? (
                        <Row gutter={[18, 18]} style={{ marginBottom: 18 }}>
                            <Col span={24}>
                                <div><span style={{ fontSize: 14, color: '#000000' }}>{stop.stopType === 'PICK_UP' ? 'Loading Type' : 'Unloading Type'}</span><Button style={{ fontWeight: 'bold' }} type="link" onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>edit</Button></div>
                                <div><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString(stop.stopType === 'PICK_UP' ? 'LoadingType' : 'UnloadingType', stop.loadingType, null)}</span></div>
                            </Col>
                        </Row>
                    ) : (
                            <Row gutter={[18, 18]} style={{ marginBottom: 18 }}>
                                <Col span={24}>
                                    <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleEditStopLocation(); }}>{stop.stopType === 'PICK_UP' ? 'Loading Specifics (optional)' : (stop.stopType === 'DROP_OFF' ? 'Unloading Specifics (optional)' : 'Loading/Unloading Specifics (optional)')}</Button>
                                </Col>
                            </Row>
                        )}
                    {(stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) ? (
                        <>
                            <Row gutter={[18, 18]}>
                                <Col span={24}>
                                    <div><span style={{ fontSize: 14, color: '#000000' }}>Commodities</span></div>
                                    {stop.commodities.map((stopCommodity, index) => {
                                        //console.log(stopCommodity);

                                        return (
                                            <Row gutter={[18, 18]} key={`stop-${stopIndex}-stopCommodity-${index}`}>
                                                <Col span={24}>
                                                    <span style={{ fontSize: 14, color: '#A9A9A9', paddingLeft: 8 }}>{stopCommodity.name}: {stopCommodity.unitPackagingInitCount ? `${stopCommodity.unitPackagingInitCount} ${Enums.UnitPackaging.getValueByName(stopCommodity.unitPackaging)}` : ''}{stopCommodity.bulkPackagingInitCount ? `, ${stopCommodity.bulkPackagingInitCount} ${Enums.BulkPackaging.getValueByName(stopCommodity.bulkPackaging)}` : ''}</span>
                                                    {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                                        <Button type="default" style={{ marginLeft: 8 }} icon={<DeleteOutlined />} onClick={() => { onRemoveStopCommodity(stop, index); }} />
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>

                            {((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                                <Row gutter={[18, 18]}>
                                    <Col span={24}>
                                        <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Additional Default Commodity (optional)</Button>
                                    </Col>
                                </Row>
                            ) : null}
                        </>
                    ) : (((stop.stopType === 'PICK_UP') || (dropOffStops.length > 1)) ? (
                        <Row gutter={[18, 18]}>
                            <Col span={24}>
                                <Button style={{ marginLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { setSelectedRecord(stop); toggleAddStopCommodity(); }}>Default Commodity (optional)</Button>
                            </Col>
                        </Row>
                    ) : null)}
                </div>
            </DataCollapsableRow>
        );
    };

    const getStopDetails = (stop) => {
        if (stop !== undefined && stop !== null && !isEmpty(stop)) {
            const stopName = stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null;
            const stopLocation = `${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city + ', ' : ''}${stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : ''}`;
            const stopIcon = stop.stopType === 'PICK_UP' ? pickUpMarker : (stop.stopType === 'DROP_OFF' ? dropOffMarker : null);

            return (
                <div>
                    <Row style={{ marginTop: 36, marginBottom: 36 }}>
                        <Col span={24}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: 30, marginRight: 12 }}>
                                    {stopIcon && (<img src={stopIcon} alt={Enums.StopTypes.getValueByName(stop.stopType)} style={{ width: 30, height: 30 }} />)}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span style={{ fontWeight: 'bold' }}>{stopName}</span><br />
                                    <span>{stopLocation}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    };

    const getError = (errors, name) => {
        if (name !== undefined) {
            let obj = errors;

            name = name.split('.');
            let len = name.length;
            for (let i = 0; i < len - 1; i++) {
                if (obj[name[i]] !== undefined) {
                    //console.log(obj[name[i]]);
                    obj = obj[name[i]];
                }
            }

            return obj[name[len - 1]];
        } else {
            return undefined;
        }
    };

    //#endregion
    //#region styles

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formItemLayoutDouble = {
        labelCol1: { span: 24 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 12 },
    };

    const formItemLayoutDouble2 = {
        labelCol1: { span: 12 },
        wrapperCol1: { span: 12 },
        labelCol2: { span: 12 },
        wrapperCol2: { span: 12 },
    };

    const radioStyle = {
        display: 'block',
        height: '60px',
        lineHeight: '30px',
    };

    //#endregion

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={(isLoading === true && error === null) || (isLoadingLocations === true || isLoadingLinkedLocations === true || isLoadingLinkedAccounts === true)}>
                        {(linkedAccountId === undefined || linkedAccountId === null) &&
                            <Fieldset legend="Choose a Shipper">
                                <FormItem {...formItemLayout} label="Shipper" required
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Shipper"
                                            allowClear={true}
                                            loading={isLoadingLinkedAccounts === true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeLinkedAccount(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            notFoundContent={
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Shipper Accounts for you to choose from. You cannot create a lane without assigning it to a Shipper account. Please add Shipper accounts first.">
                                                    {entityType === 'CARRIER' ? (
                                                        <Button type="primary" onClick={() => { props.history.push({ pathname: `/linkedShippers/` }); }}>Setup Shipper Accounts</Button>
                                                    ) : null}
                                                </Empty>
                                            }
                                            options={shippers.map(s => { return { label: s.name ? s.name : (s.linkedAccount && s.linkedAccount.name ? s.linkedAccount.name : 'N/A'), value: s.linkedAccountId } })}
                                        />
                                    )}
                                    rules={{ required: 'Required Field' }}
                                    name="linkedAccountId"
                                />
                            </Fieldset>
                        }
                        <Fieldset legend="Route">
                            <div id="map" style={{ width: "100%", height: "200px", marginBottom: 20 }} />
                            <Timeline style={{ padding: '10px 24px' }}>
                                {(pickUpStops !== undefined && pickUpStops !== null && pickUpStops.length > 0) ? (
                                    <>
                                        {pickUpStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`pickUp-${index}`} color={pickUpsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        <div style={pickUpsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getStopLocationDisplay(stop, index)}
                                                            {getError(methods.errors, `pickUpStops-${index}`) !== undefined && (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`pickUpStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (messages !== undefined && messages !== null) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (message !== undefined && message !== null) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        {(dropOffsEnabled === false && (stop.stopTypeSequence + 1 === pickUpStops.length)) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<RightOutlined />} disabled={!(stop.stopLocation && pickUpsEnabled)} onClick={() => { setDropOffsEnabled(true); }}>Continue to Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                        {(stop.stopTypeSequence + 1 === pickUpStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.stopLocation && pickUpsEnabled)} onClick={() => { toggleNewPickUpStopLocation(); }}>Add Another Pick-Up</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                        <Timeline.Item className="customTimelineItem" key={`pickUp`} color={pickUpsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                            <div style={{ paddingLeft: 50 }}>
                                                <div style={{ marginTop: 18 }}>
                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} disabled={!pickUpsEnabled} type="link" icon={<PlusOutlined />} onClick={() => { toggleNewPickUpStopLocation(); }}>Add a Pick-Up</Button>
                                                </div>
                                                {getError(methods.errors, `pickUpStops`) !== undefined && (
                                                    <ErrorMessage
                                                        errors={methods.errors}
                                                        name={`pickUpStops`}
                                                        render={({ message, messages }) => {
                                                            if (messages !== undefined && messages !== null) {
                                                                return Object.entries(messages).map(([type, message]) => (<Alert key={`pickUpStops-${type}`} message={message} type="error" />));
                                                            } else if (message !== undefined && message !== null) {
                                                                return <Alert message={message} type="error" />;
                                                            } else {
                                                                return null;
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </Timeline.Item>
                                    )}
                                {(dropOffStops !== undefined && dropOffStops !== null && dropOffStops.length > 0) ? (
                                    <>
                                        {dropOffStops.map((stop, index, arr) => {
                                            return (
                                                <Timeline.Item className="customTimelineItem" key={`dropOff-${index}`} color={dropOffsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                                    <div style={{ paddingLeft: 50 }}>
                                                        <div style={dropOffsEnabled === true ? null : { zIndex: 500, backgroundColor: 'white', opacity: 0.3 }}>
                                                            {getStopLocationDisplay(stop, index)}
                                                            {getError(methods.errors, `dropOffStops-${index}`) !== undefined && (
                                                                <ErrorMessage
                                                                    errors={methods.errors}
                                                                    name={`dropOffStops-${index}`}
                                                                    render={({ message, messages }) => {
                                                                        if (messages !== undefined && messages !== null) {
                                                                            return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${index}-${type}`} message={message} type="error" />));
                                                                        } else if (message !== undefined && message !== null) {
                                                                            return <Alert message={message} type="error" />;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        {(stop.stopTypeSequence + 1 === dropOffStops.length) ? (
                                                            <div style={{ marginTop: 18 }}>
                                                                <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!(stop.stopLocation && dropOffsEnabled)} onClick={() => { toggleNewDropOffStopLocation(); }}>Add Another Drop-Off</Button>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Timeline.Item>
                                            );
                                        })}
                                    </>
                                ) : (
                                        <Timeline.Item className="customTimelineItem" key={`dropOff`} color={dropOffsEnabled === true ? '#16588E' : '#A9A9A9'}>
                                            <div style={{ paddingLeft: 50 }}>
                                                <div style={{ marginTop: 18 }}>
                                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} disabled={!dropOffsEnabled} onClick={() => { toggleNewDropOffStopLocation(); }}>Add a Drop-Off</Button>
                                                </div>
                                                {getError(methods.errors, `dropOffStops`) !== undefined && (
                                                    <ErrorMessage
                                                        errors={methods.errors}
                                                        name={`dropOffStops`}
                                                        render={({ message, messages }) => {
                                                            if (messages !== undefined && messages !== null) {
                                                                return Object.entries(messages).map(([type, message]) => (<Alert key={`dropOffStops-${type}`} message={message} type="error" />));
                                                            } else if (message !== undefined && message !== null) {
                                                                return <Alert message={message} type="error" />;
                                                            } else {
                                                                return null;
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </Timeline.Item>
                                    )}
                            </Timeline>
                        </Fieldset>
                        <Fieldset legend="Lane Details">
                            <FormItem {...formItemLayout} label="Customer Lane Id"
                                render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Lane Id" />}
                                rules={{ required: false }}
                                name="customerLaneId"
                            />
                            <FormItemDouble {...formItemLayoutDouble} label1="Per Mile Rate"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Per Mile Rate"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="perMileRate"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {currencyOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="perMileRateUnit"
                                defaultValue2={'USD'}
                            />
                            <FormItem {...formItemLayout} label="Practical Route Miles" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Practical Route Miles"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={true}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="approxDistance"
                                defaultValue={practicalRouteMiles}
                            />
                            <FormItem {...formItemLayout} label="Approximate Transit Time (in minutes)" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        placeholder="Approximate Transit Time"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        disabled={true}
                                    />
                                )}
                                rules={{ required: 'Required Field' }}
                                name="approxTransitTime"
                                defaultValue={practicalRouteMinutes}
                            />
                        </Fieldset>

                        {(equipmentData !== undefined && equipmentData !== null && !isEmpty(equipmentData)) ? (
                            <div>
                                {(equipmentData && equipmentData.trailerType && equipmentData.trailerType.description) ? (
                                    <>
                                        <div><span style={{ fontSize: 14, textTransform: 'uppercase', color: '#16588E' }}>Equipment Needs and Services</span><Button style={{ fontWeight: 'bold' }} type='link' disabled={!(equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null)} onClick={() => { toggleEditEquipmentNeedsAndServices(); }}>edit</Button></div>
                                        <Row gutter={[18, 18]}>
                                            <Col span={24}>
                                                <div><span style={{ fontSize: 14, color: '#000000' }}>Trailer Type</span></div>
                                                <div style={{ marginLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>{equipmentData.trailerType.description}</span></div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : null}
                                {((equipmentData && equipmentData.trailerLength && equipmentData.trailerLengthUnit) || (equipmentData && equipmentData.trailerWeightCapacity && equipmentData.trailerWeightCapacityUnit)) ? (
                                    <Row gutter={[18, 18]}>
                                        {(equipmentData && equipmentData.trailerLength && equipmentData.trailerLengthUnit) ? (
                                            <Col span={12}>
                                                <div><span style={{ fontSize: 14, color: '#000000' }}>Trailer Length</span></div>
                                                <div style={{ marginLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString('Length', equipmentData.trailerLength, equipmentData.trailerLengthUnit)}</span></div>
                                            </Col>
                                        ) : null}
                                        {(equipmentData && equipmentData.trailerWeightCapacity && equipmentData.trailerWeightCapacityUnit) ? (
                                            <Col span={12}>
                                                <div><span style={{ fontSize: 14, color: '#000000' }}>Load Weight</span></div>
                                                <div style={{ marginLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString('Weight', equipmentData.trailerWeightCapacity, equipmentData.trailerWeightCapacityUnit)}</span></div>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ) : null}
                                {equipmentData.preCoolReefer !== undefined && equipmentData.preCoolReefer !== null && equipmentData.minRunTemp !== undefined && equipmentData.minRunTemp !== null && equipmentData.maxRunTemp !== undefined && equipmentData.maxRunTemp !== null ? (
                                    <Row gutter={[18, 18]}>
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#000000' }}>Pre-Cool Temp</span></div>
                                            <div style={{ marginLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString('Temperature', equipmentData.preCoolReefer, equipmentData.preCoolReeferUnit)}</span></div>
                                        </Col>
                                        <Col span={12}>
                                            <div><span style={{ fontSize: 14, color: '#000000' }}>Run Temp Range</span></div>
                                            <div style={{ marginLeft: 8 }}><span style={{ fontSize: 14, color: '#A9A9A9' }}>{stringFormatter.toFormattedString('Temperature', equipmentData.minRunTemp, equipmentData.minRunTempUnit)} - {stringFormatter.toFormattedString('Temperature', equipmentData.maxRunTemp, equipmentData.maxRunTempUnit)}</span></div>
                                        </Col>
                                    </Row>
                                ) : null}
                                {(equipmentData.equipmentSpecifics !== undefined && equipmentData.equipmentSpecifics !== null && equipmentData.equipmentSpecifics.length > 0) ? (
                                    <Row gutter={[18, 18]}>
                                        <Col span={24}>
                                            <div><span style={{ fontSize: 14, color: '#000000' }}>Additional Equipment and Services</span></div>
                                            <DataListRow style={{ marginLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.equipmentSpecifics} dataType="EquipmentSpecific" />
                                        </Col>
                                    </Row>
                                ) : null}
                                {(equipmentData.driverRequirements !== undefined && equipmentData.driverRequirements !== null && equipmentData.driverRequirements.length > 0) ? (
                                    <Row gutter={[18, 18]}>
                                        <Col span={24}>
                                            <div><span style={{ fontSize: 14, color: '#000000' }}>Driver Requirements</span></div>
                                            <DataListRow style={{ marginLeft: 8 }} valueStyle={{ fontSize: 14, color: '#A9A9A9' }} data={equipmentData.driverRequirements} dataType="DriverRequirement" />
                                        </Col>
                                    </Row>
                                ) : null}
                            </div>
                        ) : (
                                <div style={{ marginTop: 18 }}>
                                    <Button style={{ paddingLeft: 0, fontWeight: 'bold' }} type="link" icon={<PlusOutlined />} onClick={() => { toggleEditEquipmentNeedsAndServices(); }}>Default Equipment Needs and Services (optional)</Button>
                                </div>
                            )}
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Lane" />
                    <Modal
                        title={'New Pick-Up'}
                        visible={showNewPickUpStopLocation}
                        width="60%"
                        style={{ top: 0 }}
                        onCancel={toggleNewPickUpStopLocation}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="pickUpLocationId"
                            defaultValue={null}
                        />
                        <Fieldset legend="Default Loading Requirements">
                            <div>How will the trailer be loaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="pickUpLoadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleNewPickUpStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.pickUpLocationId;
                                    const loadingType = values.pickUpLoadingType;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = pickUpStops.length;
                                    if (stopLocationId !== undefined && stopLocationId !== null) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (stopLocation !== undefined && stopLocation !== null) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                stopLocationId: stopLocationId,
                                                stopType: 'PICK_UP',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence
                                            };

                                            if (loadingType !== undefined && loadingType !== null) {
                                                newStop.loadingType = loadingType;
                                            }

                                            setPickUpStops(pickUpStops => [...pickUpStops, { ...newStop }]);
                                            toggleNewPickUpStopLocation();
                                        }
                                    }

                                    return false;
                                }}
                            >
                                Add Pick-Up Location
                        </Button>
                        </div>
                    </Modal>
                    <Modal
                        title={'New Drop-Off'}
                        visible={showNewDropOffStopLocation}
                        width="60%"
                        style={{ top: 0 }}
                        onCancel={toggleNewDropOffStopLocation}
                        footer={null}
                        zIndex={1000}
                        destroyOnClose={true}
                        maskClosable={false}
                    >
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(null)}
                                </Select>
                            )}
                            name="dropOffLocationId"
                            rules={{ required: 'Required Field' }}
                            defaultValue={null}
                        />
                        <Fieldset legend="Default Unloading Requirements">
                            <div>How will the trailer be unloaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be unloaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="dropOffLoadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleNewDropOffStopLocation(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary"
                                onClick={() => {
                                    const values = methods.getValues();
                                    const stopLocationId = values.dropOffLocationId;
                                    const loadingType = values.dropOffLoadingType;
                                    const id = pickUpStops.length + dropOffStops.length;
                                    const sequence = pickUpStops.length + dropOffStops.length;
                                    const stopTypeSequence = dropOffStops.length;
                                    if (stopLocationId !== undefined && stopLocationId !== null) {
                                        let stopLocation = locationsList.find(l => l.id === stopLocationId);
                                        if (stopLocation !== undefined && stopLocation !== null) {
                                            let newStop = {
                                                stopLocation: stopLocation,
                                                timeZone: stopLocation.timeZone,
                                                stopLocationId: stopLocationId,
                                                stopType: 'DROP_OFF',
                                                id: id,
                                                sequence: sequence,
                                                stopTypeSequence: stopTypeSequence
                                            };

                                            if (loadingType !== undefined && loadingType !== null) {
                                                newStop.loadingType = loadingType;
                                            }

                                            setDropOffStops(dropOffStops => [...dropOffStops, { ...newStop }]);
                                            toggleNewDropOffStopLocation();
                                        }
                                    }

                                    return false;
                                }}
                            >
                                Add Drop-Off Location
                        </Button>
                        </div>
                    </Modal>
                </Form>
            </FormProvider>
            <Modal
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}
                visible={showEditStopLocation}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleEditStopLocation(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods2}>
                    <Form onSubmit={methods2.handleSubmit(onEditStopLocation)}>
                        <FormItem {...formItemLayout} label="Location" required format="vertical"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Location"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {getLocationOptions(selectedRecord)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="locationId"
                            defaultValue={null}
                        />
                        <Fieldset legend="Default Loading/Unloading Requirements">
                            <div>How will the trailer be loaded/unloaded?</div>
                            <FormItem {...formItemLayout} format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name}>
                                        <Radio style={radioStyle} key="LIVE" value="LIVE">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Live Load/Unload Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Trailer will be loaded/unloaded upon arrival.</span>
                                        </Radio>
                                        <Radio style={radioStyle} key="DROP" value="DROP">
                                            <span style={{ fontWeight: 'bold', fontSize: 16 }}>Drop Trailer</span><br /><span style={{ fontSize: 14, paddingLeft: 24 }}>Drop and hook.</span>
                                        </Radio>
                                    </Radio.Group>
                                )}
                                rules={{ required: false }}
                                name="loadingType"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditStopLocation(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update Location</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Commodity" : "Drop-Off Commodity") : 'Stop Commodity'}
                visible={showAddStopCommodity}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleAddStopCommodity(); setSelectedRecord(null); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods3}>
                    <Form onSubmit={methods3.handleSubmit(onAddStopCommodity)}>
                        <Fieldset legend={selectedRecord ? (selectedRecord.stopType === "PICK_UP" ? "Pick-Up Location" : "Drop-Off Location") : 'Stop Location'}>
                            {getStopDetails(selectedRecord)}
                        </Fieldset>
                        <Fieldset legend="Commodity Details">
                            <FormItem {...formItemLayout} label="Name" required format="vertical"
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Search and Select a Commodity to Add"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {getCommodityOptions(selectedRecord ? selectedRecord : null)}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="commodityId"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Unit Quantity" label2="Unit of Measure/Unit Packaging" required1 required2 format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Unit Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: 'Required Field' }}
                                name1="unitPackagingInitCount"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Unit of Measure/Unit Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {unitPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: 'Required Field' }}
                                name2="unitPackaging"
                            />
                            <FormItemDouble {...formItemLayoutDouble2} label1="Bulk Quantity" label2="Bulk Packaging" format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={0}
                                        min={0}
                                        placeholder="Bulk Quantity"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="bulkPackagingInitCount"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Bulk Packaging"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {bulkPackagingOptions}
                                    </Select>
                                )}
                                rules2={{ required: false }}
                                name2="bulkPackaging"
                            />
                        </Fieldset>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleAddStopCommodity(); setSelectedRecord(null); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Add Commodity</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
            <Modal
                title="Equipment Needs and Services"
                visible={showEditEquipmentNeedsAndServices}
                width="60%"
                style={{ top: 0 }}
                onCancel={(e) => { toggleEditEquipmentNeedsAndServices(); }}
                footer={null}
                zIndex={1000}
                destroyOnClose={true}
                maskClosable={false}
                forceRender={true}
            >
                <FormProvider {...methods4}>
                    <Form onSubmit={methods4.handleSubmit(onEditEquipmentNeedsAndServices)}>
                        <Row gutter={[4, 4]}>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Trailer Type" required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Trailer Type"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChangeTrailerType(selected); onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {trailerTypes.map(i => <Option value={i.id} key={i.id}>{i.description}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name='trailerTypeId'
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Trailer Length" required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <InputNumber
                                            step={1}
                                            min={0}
                                            precision={0}
                                            placeholder="Trailer Length (ft)"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name='trailerLength'
                                />
                            </Col>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label="Load Weight" required format="vertical"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <InputNumber
                                            step={1000}
                                            min={0}
                                            precision={0}
                                            placeholder="Load Weight (lbs)"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={e => { onChange(e); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: false }}
                                    name='trailerWeightCapacity'
                                />
                            </Col>
                        </Row>
                        {showReeferOptions === true ? (
                            <>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <span style={{ fontWeight: 'bold' }}>Temperature Requirements</span>
                                    </Col>
                                </Row>
                                <Row gutter={[4, 4]}>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Pre-Cool Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    min={0}
                                                    placeholder="Pre-Cool Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { preCoolReefer.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{
                                                required: false,
                                                validate: {
                                                    gteMin: value => (minRunTemp.current ? value >= minRunTemp.current : true) || 'Pre-Cool Temp must be greater than or equal to the Min Run Temp.',
                                                    lteMax: value => (maxRunTemp.current ? value <= maxRunTemp.current : true) || 'Pre-Cool Temp must be less than or equal to Max Run Temp.'
                                                }
                                            }}
                                            name='preCoolReefer'
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Min Run Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    min={0}
                                                    placeholder="Min Run Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { minRunTemp.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{
                                                required: false,
                                                validate: {
                                                    lte: value => (preCoolReefer.current && maxRunTemp.current ? value <= preCoolReefer.current && value <= maxRunTemp.current : true) || 'Min Run Temp must be less than or equal to the Pre-Cool Temp and Max Run Temp'
                                                }
                                            }}
                                            name='minRunTemp'
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormItem {...formItemLayout} label="Max Run Temp" required format="vertical"
                                            render={({ onChange, onBlur, value, name }) => (
                                                <InputNumber
                                                    min={0}
                                                    placeholder="Max Run Temp"
                                                    style={{ width: '100%' }}
                                                    onBlur={onBlur}
                                                    onChange={e => { maxRunTemp.current = e; onChange(e); }}
                                                    value={value}
                                                    name={name}
                                                />
                                            )}
                                            rules={{
                                                required: false,
                                                validate: {
                                                    gte: value => (preCoolReefer.current && minRunTemp.current ? value >= preCoolReefer.current && value >= minRunTemp.current : true) || 'Max Run Temp must be greater than or equal to the Pre-Cool Temp and Min Run Temp'
                                                }
                                            }}
                                            name='maxRunTemp'
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                        <Row gutter={[4, 4]}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Additional Equipment and Services" format="vertical"
                                    render={({ onChange, onBlur, value, name }) => {
                                        if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                                            if (equipmentSpecificOptions !== undefined && equipmentSpecificOptions !== null && equipmentSpecificOptions.length > 0) {
                                                return (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={equipmentSpecificOptions}
                                                        onChange={(checkedValues) => { onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                );
                                            }
                                        } else {
                                            return (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                            );
                                        }
                                    }}
                                    rules={{ required: false }}
                                    name='equipmentSpecifics'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[4, 4]}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="Driver Requirements" format="vertical"
                                    render={({ onChange, onBlur, value, name }) => {
                                        if (equipmentData.trailerTypeId !== undefined && equipmentData.trailerTypeId !== null) {
                                            if (driverRequirementOptions !== undefined && driverRequirementOptions !== null && driverRequirementOptions.length > 0) {
                                                return (
                                                    <Checkbox.Group
                                                        onBlur={onBlur}
                                                        options={driverRequirementOptions}
                                                        onChange={(checkedValues) => { onChange(checkedValues); }}
                                                        value={value}
                                                        name={name}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but there are no options available for the selected trailer type." />
                                                );
                                            }
                                        } else {
                                            return (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You must first select a trailer type to see available options." />
                                            );
                                        }
                                    }}
                                    rules={{ required: false }}
                                    name='driverRequirements'
                                />
                            </Col>
                        </Row>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 0px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={() => { toggleEditEquipmentNeedsAndServices(); }} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Update Equipment Needs and Services</Button>
                        </div>
                    </Form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default NewLinkedLoadLane;
