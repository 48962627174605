import React, { useMemo, useState } from 'react';
import { Input, Spin, Radio, Select, Typography, Alert } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions";
import UploadFile from "../UploadFile/UploadFile";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import moment from 'moment';

const { Paragraph } = Typography;
const { Option } = Select;

const EditCarrier = props => {
    const account = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accounts.isRecordUpdateLoading);
    const error = useSelector(state => state.accounts.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);
    const entityType = useSelector(state => state.auth.entityType);

    const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(account.carrier && account.carrier.insuranceExpiryDate ? moment(account.carrier.insuranceExpiryDate) : null);
    const [w9ExpiryDate, setW9ExpiryDate] = useState(account.carrier && account.carrier.w9ExpiryDate ? moment(account.carrier.w9ExpiryDate) : null);
    const [insuranceFile, setInsuranceFile] = useState(null);
    const [w9File, setW9File] = useState(null);

    const onSubmit = (data) => {
        //console.log(data);
        if (w9File !== undefined && w9File !== null) {
            methods.clearErrors('w9File');
        } else if (account.carrier.w9FileId === undefined || account.carrier.w9FileId === null) {
            methods.setError('w9File', { type: 'required', message: "Required File" });
        }

        //console.log(insuranceFile);
        if (insuranceFile !== undefined && insuranceFile !== null) {
            methods.clearErrors('insuranceFile');
        } else if (account.carrier.insuranceFileId === undefined || account.carrier.insuranceFileId === null) {
            methods.setError('insuranceFile', { type: 'required', message: "Required File" });
        }

        if (((w9File === undefined || w9File === null) && (account.carrier.w9FileId === undefined || account.carrier.w9FileId === null)) || ((insuranceFile === undefined || insuranceFile === null) && (account.carrier.insuranceFileId === undefined || account.carrier.insuranceFileId === null))) {
            return;
        }

        let insuranceExpiryDateValue = null;
        let w9ExpiryDateValue = null;

        if (data.insuranceExpiryDate !== undefined && data.insuranceExpiryDate !== null) {
            insuranceExpiryDateValue = data.insuranceExpiryDate.utc().toISOString();
        }

        if (data.w9ExpiryDate !== undefined && data.w9ExpiryDate !== null) {
            w9ExpiryDateValue = data.w9ExpiryDate.utc().toISOString();
        }

        if (data.insuranceExpiryDate) {
            delete data['insuranceExpiryDate'];
        }

        if (data.w9ExpiryDate) {
            delete data['w9ExpiryDate'];
        }

        //let cleanedData = removeEmpty(data);
        //console.log(cleanedData);

        let updatedAccount = {};
        updatedAccount.carrier = { ...data };

        if (!isEmpty(updatedAccount)) {
            dispatch(actionCreators.updateAccount(account.id, updatedAccount, null, null, insuranceFile, account.carrier.insuranceFileId, insuranceExpiryDateValue, w9File, account.carrier.w9FileId, w9ExpiryDateValue, entityType));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateAccount());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <>
            {account !== undefined && account !== null && account.carrier !== undefined && account.carrier !== null ? (
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                            <Fieldset>
                                <legend>Carrier Details (* indicates a required field)</legend>
                                <FormItem {...formItemLayout} label="US Docket # (MC, FF, MX)" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="MC Number" />}
                                    rules={{ required: "Required Field" }}
                                    name="mcNum"
                                    defaultValue={account.carrier.mcNum ? account.carrier.mcNum : ''}
                                />
                                <FormItem {...formItemLayout} label="US DOT #" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="DOT #" />}
                                    rules={{ required: "Required Field" }}
                                    name="dotNum"
                                    defaultValue={account.carrier.dotNum ? account.carrier.dotNum : ''}
                                />
                            </Fieldset>
                            <Fieldset>
                                <legend>Carrier Documents (* indicates a required field or file)</legend>
                                <FormItem {...formItemLayout} label="W9 Expiration Date" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { setW9ExpiryDate(date); onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="w9ExpiryDate"
                                    defaultValue={w9ExpiryDate ? moment(w9ExpiryDate) : null}
                                />
                                <FormItemFile {...formItemLayout} label="W9 File" required name="w9File" format="horizontal">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setW9File(file);
                                            methods.clearErrors('w9File');
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setW9File(null);
                                        }}
                                        message="Please upload a photo of the document."
                                        fileId={account.carrier.w9FileId}
                                    />
                                </FormItemFile>
                                <FormItem {...formItemLayout} label="Insurance Expiration Date" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <DatePicker
                                            placeholder="Expiration Date"
                                            style={{ width: '100%' }}
                                            onBlur={onBlur}
                                            onChange={(date, dateString) => { setInsuranceExpiryDate(date); onChange(date); }}
                                            value={value}
                                            name={name}
                                        />
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="insuranceExpiryDate"
                                    defaultValue={insuranceExpiryDate ? moment(insuranceExpiryDate) : null}
                                />
                                <FormItemFile {...formItemLayout} label="Insurance File" required name="insuranceFile" format="horizontal">
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setInsuranceFile(file);
                                            methods.clearErrors('insuranceFile');
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setInsuranceFile(null);
                                        }}
                                        message="Please upload a photo of the document."
                                        fileId={account.carrier.insuranceFileId}
                                    />
                                </FormItemFile>
                            </Fieldset>
                            <Fieldset>
                                <legend>Accounts Receivable (* indicates a required field)</legend>
                                <FormItem {...formItemLayout} label="Preferred Payment Method" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Radio.Group onBlur={onBlur} onChange={e => onChange(e.target.value)} value={value} name={name} buttonStyle="solid">
                                            <Radio.Button value="DIRECT_DEPOSIT">Direct Deposit</Radio.Button>
                                            <Radio.Button value="QUICK_PAY">Quick Pay</Radio.Button>
                                            <Radio.Button value="FACTORING">Factoring</Radio.Button>
                                        </Radio.Group>
                                    )}
                                    rules={{ required: "Required Field" }}
                                    name="preferredPaymentMethod"
                                    defaultValue={account.carrier.preferredPaymentMethod ? account.carrier.preferredPaymentMethod : undefined}
                                />
                                <FormItem {...formItemLayout} label="Person or Factoring Company Name" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="accountsReceivable.name"
                                    defaultValue={account.carrier.accountsReceivable && account.carrier.accountsReceivable.name ? account.carrier.accountsReceivable.name : ""}
                                />
                                <FormItem {...formItemLayout} label="Email" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                    rules={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                            message: "Please enter a valid email address"
                                        }
                                    }}
                                    name="accountsReceivable.email"
                                    defaultValue={account.carrier.accountsReceivable && account.carrier.accountsReceivable.email ? account.carrier.accountsReceivable.email : ""}
                                />
                                <FormItemDouble {...formItemLayoutDouble} label1="Phone #" required1 format="horizontal"
                                    render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                    rules1={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                        }
                                    }}
                                    name1="accountsReceivable.phone"
                                    defaultValue1={account.carrier.accountsReceivable && account.carrier.accountsReceivable.phone ? account.carrier.accountsReceivable.phone : ""}
                                    render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                    rules2={{ required: false }}
                                    name2="accountsReceivable.phoneExt"
                                    defaultValue2={account.carrier.accountsReceivable && account.carrier.accountsReceivable.phoneExt ? account.carrier.accountsReceivable.phoneExt : ""}
                                />

                                <Paragraph style={{ marginTop: 12, marginBottom: 12 }}>If the carrier's accounts receivable department's address is different than the company's main physical address or mailing address, or if they are using a factoring company, please provide it below.</Paragraph>

                                <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                                    rules={{ required: false }}
                                    name="shippingAddress.streetAddress1"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.streetAddress1 ? account.carrier.shippingAddress.streetAddress1 : ""}
                                />
                                <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                                    rules={{ required: false }}
                                    name="shippingAddress.streetAddress2"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.streetAddress2 ? account.carrier.shippingAddress.streetAddress2 : ""}
                                />
                                <FormItem {...formItemLayout} label="City" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                                    rules={{ required: false }}
                                    name="shippingAddress.city"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.city ? account.carrier.shippingAddress.city : ""}
                                />
                                <FormItem {...formItemLayout} label="State" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a State"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="shippingAddress.state"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.state ? account.carrier.shippingAddress.state : undefined}
                                />
                                <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                                    rules={{ required: false }}
                                    name="shippingAddress.postalCode"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.postalCode ? account.carrier.shippingAddress.postalCode : ""}
                                />
                                <FormItem {...formItemLayout} label="Country" format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => (
                                        <Select
                                            placeholder="Please Select a Country"
                                            allowClear={true}
                                            style={{ width: '100%' }}
                                            virtual={false}
                                            onBlur={onBlur}
                                            onChange={(selected) => { onChange(selected); }}
                                            value={value}
                                            name={name}
                                            showSearch={true}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="USA" key="USA">United States</Option>
                                        </Select>
                                    )}
                                    rules={{ required: false }}
                                    name="shippingAddress.country"
                                    defaultValue={account.carrier.shippingAddress && account.carrier.shippingAddress.country ? account.carrier.shippingAddress.country : undefined}
                                />
                            </Fieldset>
                            <Fieldset>
                                <legend>Point of Contact for Carrier Operations Related Purposes</legend>
                                <FormItem {...formItemLayout} label="Name" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                                    rules={{ required: "Required Field" }}
                                    name="pointOfContact.name"
                                    defaultValue={account.carrier.pointOfContact && account.carrier.pointOfContact.name ? account.carrier.pointOfContact.name : ""}
                                />
                                <FormItem {...formItemLayout} label="Email" required format="horizontal"
                                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                                    rules={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                            message: "Please enter a valid email address"
                                        }
                                    }}
                                    name="pointOfContact.email"
                                    defaultValue={account.carrier.pointOfContact && account.carrier.pointOfContact.email ? account.carrier.pointOfContact.email : ""}
                                />
                                <FormItemDouble {...formItemLayoutDouble} label1="* Phone #" required format="horizontal"
                                    render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                                    rules1={{
                                        required: "Required Field",
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digit phone number with no special characters"
                                        }
                                    }}
                                    name1="pointOfContact.phone"
                                    defaultValue1={account.carrier.pointOfContact && account.carrier.pointOfContact.phone ? account.carrier.pointOfContact.phone : ""}
                                    render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                                    rules2={{ required: false }}
                                    name2="pointOfContact.phoneExt"
                                    defaultValue2={account.carrier.pointOfContact && account.carrier.pointOfContact.phoneExt ? account.carrier.pointOfContact.phoneExt : ""}
                                />
                            </Fieldset>
                            {entityType === "STAFF" && (
                                <Fieldset>
                                    <legend>SMS Basic Scores</legend>
                                    <FormItem {...formItemLayout} label="Hours of Service Compliance" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Hours of Service Compliance" />}
                                        rules={{ required: false }}
                                        name="smsBasic.hosCompliance"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.hosCompliance ? account.carrier.smsBasic.hosCompliance : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Unsafe Driving" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Unsafe Driving" />}
                                        rules={{ required: false }}
                                        name="smsBasic.unsafeDriving"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.unsafeDriving ? account.carrier.smsBasic.unsafeDriving : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Vehicle Maintenance" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Vehicle Maintenance" />}
                                        rules={{ required: false }}
                                        name="smsBasic.vehicleMaintenance"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.vehicleMaintenance ? account.carrier.smsBasic.vehicleMaintenance : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Controlled Substance Abuse" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Controlled Substance Abuse" />}
                                        rules={{ required: false }}
                                        name="smsBasic.controlledSA"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.controlledSA ? account.carrier.smsBasic.controlledSA : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Driver Fitness" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Driver Fitness" />}
                                        rules={{ required: false }}
                                        name="smsBasic.driverFitness"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.driverFitness ? account.carrier.smsBasic.driverFitness : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Average SMS Score" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Average SMS Score" />}
                                        rules={{ required: false }}
                                        name="smsBasic.avgSMSScore"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.avgSMSScore ? account.carrier.smsBasic.avgSMSScore : ""}
                                    />
                                    <FormItem {...formItemLayout} label="Weighted Average SMS Score" format="horizontal"
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Weighted Average SMS Score" />}
                                        rules={{ required: false }}
                                        name="smsBasic.weightedAvgSMSScore"
                                        defaultValue={account.carrier.smsBasic && account.carrier.smsBasic.weightedAvgSMSScore ? account.carrier.smsBasic.weightedAvgSMSScore : ""}
                                    />
                                </Fieldset>
                            )}
                            {error && <Alert message={`${error}`} type="error" />}
                        </Spin>
                        <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Carrier" />
                    </Form>
                </FormProvider>
            ) : null}
        </>
    );
};

export default EditCarrier;