import * as actionTypes from "../actions/actionTypes";
import {
    addRecordFail,
    addRecordStart,
    addRecordSuccess,
    clearAddRecordError,
    clearAddRecordLoading,
    clearRecords,
    clearUpdateRecordError,
    clearUpdateRecordLoading,
    fetchRecordsSuccess,
    fetchRecordsFail,
    fetchRecordsStart,
    updateRecordFail,
    updateRecordStart,
    updateRecord,
    updateRecordSuccess,
    addRecordToBottom,
    cancelAddRecord,
    cancelUpdateRecord,
    removeRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    searchParams: {},
    pagination: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CARRIER_REJECTIONS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_CARRIER_REJECTIONS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_CARRIER_REJECTIONS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_CARRIER_REJECTIONS: return clearRecords(state);
        case actionTypes.FETCH_CARRIER_REJECTION_START: return fetchRecordStart(state);
        case actionTypes.FETCH_CARRIER_REJECTION_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_CARRIER_REJECTION_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_CARRIER_REJECTION: return clearRecord(state);
        case actionTypes.ADD_CARRIER_REJECTION: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_CARRIER_REJECTION_START: return addRecordStart(state);
        case actionTypes.ADD_CARRIER_REJECTION_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_CARRIER_REJECTION_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_CARRIER_REJECTION_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_CARRIER_REJECTION_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.CANCEL_ADD_CARRIER_REJECTION: return cancelAddRecord(state);
        case actionTypes.UPDATE_CARRIER_REJECTION: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_CARRIER_REJECTION: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_CARRIER_REJECTION_START: return updateRecordStart(state);
        case actionTypes.UPDATE_CARRIER_REJECTION_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_CARRIER_REJECTION_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_CARRIER_REJECTION_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_CARRIER_REJECTION_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.CANCEL_UPDATE_CARRIER_REJECTION: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_CARRIER_REJECTION: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;