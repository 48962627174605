import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';
import LineItemType from "../../components/LineItemType/LineItemType";
import EditLineItemType from "../../components/EditLineItemType/EditLineItemType";
import NewLineItemType from "../../components/NewLineItemType/NewLineItemType";
import classes from './LineItemTypes.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';
import Enums from '../../shared/enums';
import { compareByAlph } from '../../shared/tableUtils';

const stringFormatter = new StringFormatter();

const LineItemTypes = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Line Item Type";
    const pluralEntityName = "Line Item Types";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.lineItemTypes.isLoading);
    const records = useSelector(state => state.lineItemTypes.records);
    const pagination = useSelector(state => state.lineItemTypes.pagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        dispatch(actionCreators.fetchLineItemTypes(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLineItemTypesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchLineItemTypes(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                        showAdd={false} addText='' addAction={null}
                        showEdit={true} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                        showChange={false} changeText='' changeAction={null}
                        showRemove={false} removeText='' removeAction={null}
                    />
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => compareByAlph(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => compareByAlph(a.description, b.description),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Type',
            dataIndex: 'itemType',
            key: 'itemType',
            render: (text, record) => { return Enums.LineItemTypes.getValueByName(text); },
            filters: Enums.LineItemTypes.getTextValueEnumArray(),
            onFilter: (value, record) => {
                // console.log(record.itemType);
                // console.log(value);
                // console.log(record.itemType.indexOf(value));
                return record.itemType.indexOf(value) === 0
            },
            filterMultiple: true,
            sorter: (a, b) => compareByAlph(a.itemType, b.itemType),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Up-Charge',
            dataIndex: 'upCharge',
            key: 'upCharge',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Up-Charge Rule',
            dataIndex: 'upChargeRule',
            key: 'upChargeRule',
            render: (text, record) => { return Enums.LineItemRules.getValueByName(text); },
            sorter: (a, b) => compareByAlph(a.upChargeRule, b.upChargeRule),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Cost Rule',
            dataIndex: 'costRule',
            key: 'costRule',
            render: (text, record) => { return Enums.LineItemRules.getValueByName(text); },
            sorter: (a, b) => compareByAlph(a.costRule, b.costRule),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Is Open',
            dataIndex: 'isOpen',
            key: 'isOpen',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
            filters: Enums.YesNo.getTextValueEnumArray(),
            onFilter: (value, record) => {
                let recordValue = record.isOpen === true ? 'true' : 'false';
                return recordValue.indexOf(value) === 0;
            },
            filterMultiple: true,
            sorter: (a, b) => compareByAlph(a.isOpen, b.isOpen),
            sortDirections: ['ascend', 'descend'],
        },
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLineItemType cancel={toggleNewEntity} existingLineItemTypes={records} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLineItemType cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            expandedRowRender={record => (<LineItemType record={record} />)}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(LineItemTypes);
