import React from 'react';
import classes from './DispatchSelectedLoadCard.module.scss';
import ActionButton from '../../components/ActionButton/ActionButton';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPallet } from '@fortawesome/free-solid-svg-icons';

const DispatchSelectedLoadCard = props => {
    //#region props and constants

    const load = props.load !== undefined && props.load !== null ? props.load : {};
    const loadsToDispatch = props.loadsToDispatch !== undefined && props.loadsToDispatch !== null && props.loadsToDispatch.length > 0 ? props.loadsToDispatch : [];
    const displayAsText = props.displayAsText !== undefined && props.displayAsText !== null && props.displayAsText === true ? true : false;
    const isBundledLoad = props.isBundledLoad !== undefined && props.isBundledLoad !== null && props.isBundledLoad === true ? true : false;
    const style = props.style;

    //#endregion

    if (displayAsText === true) {
        if (isBundledLoad === true) {
            if (load !== undefined && load !== null && !isEmpty(load)) {
                return (
                    <span>{load.loadNumber}/{loadsToDispatch.length}</span>
                );
            } else {
                return null;
            }
        } else {
            if (load !== undefined && load !== null && !isEmpty(load)) {
                return (
                    <span>{`Load ID: ${(load.parentName ? load.parentName : (load.name ? load.name : load.irisId))}`}</span>
                );
            } else {
                return null;
            }
        }
    } else {
        if (isBundledLoad === true) {
            if (load !== undefined && load !== null && !isEmpty(load)) {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.yellowButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Scheduling Load:<br />{`${load.loadNumber} of ${loadsToDispatch.length}`}</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            } else {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Select a Load to Edit/<br />Schedule Drivers and Assets</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            }
        } else {
            if (load !== undefined && load !== null && !isEmpty(load)) {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.yellowButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>{`Load ID: ${(load.parentName ? load.parentName : (load.name ? load.name : load.irisId))}`}</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            } else {
                return (
                    <div style={style}>
                        <ActionButton
                            iconPosition="left"
                            buttonClassName={`${classes.button} ${classes.blueButton}`}
                            buttonIconClassName={classes.iconContainer}
                            buttonTextClassName={classes.textContainer}
                            buttonIcon={<FontAwesomeIcon className={classes.button1icon1} icon={faPallet} />}
                            buttonText={<span>Select a Load to Edit/<br />Schedule Drivers and Assets</span>}
                            onClick={() => { return; }}
                            disabled={true}
                        />
                    </div>
                );
            }
        }
    }
};

export default DispatchSelectedLoadCard;