import React, { useState, useMemo } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Layout, Button, Card, Col, Input, Row, Spin, Typography, message } from "antd";
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import IRISFloatingFooter from '../../hoc/IRISFloatingFooter/IRISFloatingFooter';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import Form from '../../components/Form/Form';
import FormItem from '../../components/FormItem/FormItem';
import FormItemDisplay from '../../components/FormItemDisplay/FormItemDisplay';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import irisBackground from '../../assets/img/backgrounds/background-1.jpg';
import classes from "./ResetPassword.module.scss";
import Logo from "../../components/Logo/Logo";

const { Title } = Typography;

const ResetPassword = props => {
    // These are coming from backend
    const entityType = props.match.params.entityType ? props.match.params.entityType : '';
    const token = props.match.params.token;
    const uuid = props.match.params.uuid;

    const uppercaseRegex = RegExp(/[A-Z]/);
    const lowercaseRegex = RegExp(/[a-z]/);
    const numericRegex = RegExp(/\d/);
    const specialCharacterRegex = RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.auth.isLoading);
    const error = useSelector(state => state.auth.error);


    const [isResetCompleted, setIsResetCompleted] = useState(false);
    const [isResetSubmitted, setIsResetSubmitted] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 16 },
        },
    };

    const onSubmit = (data) => {
        const resetPath = "/rest-auth/password/reset/confirm/";
        const password = data.password;
        const confirmPassword = data.confirmPassword;

        setIsResetSubmitted(true);
        dispatch(actionCreators.resetPassword(uuid, token, password, confirmPassword));
    };

    useMemo(() => {
        if (isLoading === false && error === null && isResetSubmitted === true) {
            message.success('Your password has been reset successfully.');
            setIsResetCompleted(true);
            setIsResetSubmitted(false);
        } else if (error !== null && isResetSubmitted === true) {
            setIsResetCompleted(false);
            setIsResetSubmitted(false);
        }
    }, [isLoading, error, isResetSubmitted]);

    return (
        <Layout className={classes.loginBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
            <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
            {isResetCompleted === true && <Redirect to={`/resetPasswordConfirmation/${entityType}`} />}
            <Card bordered={true} className={classes.card} size="small">
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Title level={2} style={{ textAlign: 'center' }}>Reset Password</Title>
                        </Col>
                    </Row>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <FormItem {...formItemLayout} label="New Password" required
                                        render={({ onChange, onBlur, value, name }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="New Password" />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 8, message: 'Password must be at least 8 characters long.' },
                                            validate: {
                                                uppercase: value => uppercaseRegex.test(value) || 'Password must contain at least 1 uppercase letter.',
                                                lowercase: value => lowercaseRegex.test(value) || 'Password must contain at least 1 lowercase letter.',
                                                numeric: value => numericRegex.test(value) || 'Password must contain at least 1 number.',
                                                special: value => specialCharacterRegex.test(value) || 'Password must contain at least 1 special character.'
                                            }
                                        }}
                                        name="password"
                                        help={
                                            <div>
                                                <b>Password Requirements:</b>
                                                <ul>
                                                    <li>Minimum of 8 characters</li>
                                                    <li>At least 1 uppercase letter (A-Z)</li>
                                                    <li>At least 1 lowercase letter (a-z)</li>
                                                    <li>At least 1 number (0-9)</li>
                                                    <li>At least 1 special character (~`!@#$%^&*()+=_-{}[]\|:;”’?/&lt;&gt;,.)</li>
                                                </ul>
                                            </div>
                                        }
                                    />
                                    <FormItem {...formItemLayout} label="Confirm Password" required
                                        render={({ onChange, onBlur, value, name }) => <Input.Password onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />}
                                        rules={{
                                            required: 'Required Field',
                                            validate: {
                                                comparePasswords: value => value === methods.watch('password') || 'Passwords must match' // value is from confirmPassword and watch will return value from password
                                            }
                                        }}
                                        name="confirmPassword"
                                    />
                                    {error && <ErrorMessage error={error} />}
                                    <FormItemDisplay>
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Submit
                                        </Button>
                                    </FormItemDisplay>
                                    {entityType !== undefined && entityType !== null && entityType.toUpperCase() !== "DRIVER" && (
                                        <FormItemDisplay style={{ textAlign: 'right' }}>
                                            Already have a User Account? <Link to="/login"><Button type="link">Back to Login</Button></Link>
                                        </FormItemDisplay>
                                    )}
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </Spin>
                <IRISFloatingFooter />
            </Card>
        </Layout>
    );
};

export default withRouter(ResetPassword);