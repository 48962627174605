import React from 'react';
import irisLogoSmall from '../../assets/img/full-logo.png';
import irisLogoLarge from '../../assets/img/logos/iris-main-logo.png';
import classes from './Logo.module.scss';

const logo = props => {

    let irisLogo = irisLogoSmall;
    let className = classes.Logo;
    let style = { height: props.height };
    let logoStyle = null;
    if (props.size && props.size === "large") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
        style = { margin: 'auto', padding: (props.padding ? props.padding : 0), height: props.height }
    }
    else if (props.size && props.size === "custom") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
        style = props.style;
        logoStyle = { height: props.height };
    } else if (props.size && props.size === "menu") {
        irisLogo = irisLogoLarge;
        className = classes.LogoLarge;
    } else {
        irisLogo = irisLogoSmall;
        className = classes.LogoLarge;
        style = { margin: 'auto', padding: (props.padding ? props.padding : 0), height: props.height }
    }
    return (
        <div className={className} style={style}>
            <img src={irisLogo} alt="Pulogix" style={logoStyle} />
        </div>
    )
};

export default logo;
