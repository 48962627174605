import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Account Registrations Functions

export const fetchAccountRegistrationsStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_START
    }
};

export const fetchAccountRegistrationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchAccountRegistrationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_REGISTRATIONS_FAIL,
        payload: payload
    }
};

export const clearAccountRegistrations = () => {
    return {
        type: actionTypes.CLEAR_ACCOUNT_REGISTRATIONS
    }
};

export const addAccountRegistrationStart = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_START
    }
};

export const addAccountRegistrationSuccess = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_SUCCESS
    }
};

export const addAccountRegistrationFail = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_FAIL,
        payload: payload
    }
};

export const addAccountRegistrationErrorClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_ERROR_CLEAR
    }
};

export const addAccountRegistrationLoadingClear = () => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION_LOADING_CLEAR
    }
};

export const updateAccountRegistrationStart = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_START
    }
};

export const updateAccountRegistrationSuccess = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_SUCCESS
    }
};

export const updateAccountRegistrationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_FAIL,
        payload: payload
    }
};

export const updateAccountRegistrationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_ERROR_CLEAR
    }
};

export const updateAccountRegistrationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION_LOADING_CLEAR
    }
};

export const cancelAddAccountRegistration = () => {
    return {
        type: actionTypes.CANCEL_ADD_ACCOUNT_REGISTRATION
    }
};

export const cancelUpdateAccountRegistration = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_ACCOUNT_REGISTRATION
    }
};

export const insertAccountRegistration = (payload) => {
    return {
        type: actionTypes.ADD_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

export const changeAccountRegistration = (payload) => {
    return {
        type: actionTypes.UPDATE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

export const changeSingleAccountRegistration = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

export const removeAccountRegistration = (payload) => {
    return {
        type: actionTypes.REMOVE_ACCOUNT_REGISTRATION,
        payload: payload
    }
};

//#endregion

//#region Account Registrations Methods

export const fetchAccountRegistrations = (payload) => {
    return async (dispatch, getState) => {
        const accountRegistrationsPath = '/accountRegistrations';
        try {
            dispatch(fetchAccountRegistrationsStart());

            const state = getState();
            const orchestratorState = {...state.orchestrator};
            const staff = [...orchestratorState.staff];
            const accountRegistrationsState = {...state.accountRegistrations};
            let pagination = {...accountRegistrationsState.pagination};
            let searchParams = {...accountRegistrationsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const accountRegistrationsRes = await axiosAuthenticated.get(accountRegistrationsPath, {params: {...searchParams}});
            if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
                const accountRegistrations = accountRegistrationsRes.data.data;

                const transformedAccountRegistrations = accountRegistrations.map((item) => {

                    let reviewedByName = null;
                    if (item.reviewedBy !== undefined && item.reviewedBy !== null) {
                        let user = staff.find(i => i.user_id === item.reviewedBy);
                        if (user !== undefined && user !== null) {
                            if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                                reviewedByName = user.firstName + ' ' + user.lastName;
                            }
                        }
                    }

                    return {
                        ...item,
                        reviewedByName: reviewedByName
                    };
                });

                // Read total count from server
                pagination.total = accountRegistrationsRes.data.totalCount;
                pagination.current = accountRegistrationsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchAccountRegistrationsSuccess({
                    records: transformedAccountRegistrations,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchAccountRegistrationsFail({error: error.message}));
        }
    }
};

export const addAccountRegistration = (payload) => {
    return async (dispatch, getState) => {
        const accountRegistrationsPath = '/accountRegistrations';
        try {
            dispatch(addAccountRegistrationStart());

            const accountRegistrationsRes = await axiosAuthenticated.post(accountRegistrationsPath, {...payload});
            if (accountRegistrationsRes && accountRegistrationsRes.status === 201) {
                const accountRegistration = accountRegistrationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const staff = [...orchestratorState.staff];

                let reviewedByName = null;
                if (accountRegistration.reviewedBy !== undefined && accountRegistration.reviewedBy !== null) {
                    let user = staff.find(i => i.user_id === accountRegistration.reviewedBy);
                    if (user !== undefined && user !== null) {
                        if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                            reviewedByName = user.firstName + ' ' + user.lastName;
                        }
                    }
                }

                let newAccountRegistration = {
                    ...accountRegistration,
                    reviewedByName: reviewedByName
                };

                dispatch(insertAccountRegistration(newAccountRegistration));
                dispatch(addAccountRegistrationSuccess());
                dispatch(addAccountRegistrationLoadingClear());
                dispatch(addAccountRegistrationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addAccountRegistrationFail({error: error.message}));
        }
    }
};

export const updateAccountRegistration = (accountRegistrationId, payload) => {
    return async (dispatch, getState) => {
        const accountRegistrationsPath = '/accountRegistrations';
        try {
            dispatch(updateAccountRegistrationStart());

            const accountRegistrationsRes = await axiosAuthenticated.put(accountRegistrationsPath + `/${accountRegistrationId}`, {...payload});
            if (accountRegistrationsRes && accountRegistrationsRes.status === 200) {
                const accountRegistration = accountRegistrationsRes.data;

                if (accountRegistration.isDeleted === true) {
                    dispatch(removeAccountRegistration(accountRegistration));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const staff = [...orchestratorState.staff];

                    let reviewedByName = null;
                    if (accountRegistration.reviewedBy !== undefined && accountRegistration.reviewedBy !== null) {
                        let user = staff.find(i => i.user_id === accountRegistration.reviewedBy);
                        if (user !== undefined && user !== null) {
                            if (user.firstName !== undefined && user.firstName !== null && user.lastName !== undefined && user.lastName !== null) {
                                reviewedByName = user.firstName + ' ' + user.lastName;
                            }
                        }
                    }

                    let updatedAccountRegistration = {
                        ...accountRegistration,
                        reviewedByName: reviewedByName
                    };

                    dispatch(changeAccountRegistration(updatedAccountRegistration));
                    dispatch(changeSingleAccountRegistration(updatedAccountRegistration));
                }
                dispatch(updateAccountRegistrationSuccess());
                dispatch(updateAccountRegistrationLoadingClear());
                dispatch(updateAccountRegistrationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateAccountRegistrationFail({error: error.message}));
        }
    }
};

//#endregion