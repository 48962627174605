import React, { useMemo, useState, useEffect } from 'react';
import { Alert, Input, Select, Spin, InputNumber, Button } from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { isEmpty, isEqual } from "lodash";
import Enums from '../../shared/enums';
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import Fieldset from '../FormFieldset/FormFieldset';
import { removeEmpty, isEquivalent } from '../../shared/objectUtils';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';

const { Option } = Select;
const { TextArea } = Input;

const EditLinkedLocation = props => {
    const linkedLocation = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLocations.isRecordUpdateLoading);
    const error = useSelector(state => state.linkedLocations.updateRecordError);
    const commodities = useSelector(state => state.orchestrator.commodities);

    const currencyOptions = Enums.Currencies.selectListOptions();
    const locationTypeOptions = Enums.LocationTypes.selectListOptions();

    const [showNameEdit, setShowNameEdit] = useState(false);

    const showName = () => {
        setShowNameEdit(true);
    };

    useEffect(() => {
        if (showNameEdit === true) {
        methods.setValue('name', linkedLocation.location.name);
        }
    }, [showNameEdit]);

    const onSubmit = (data) => {
        let payload = {};

        let objectToUpdate = { ...linkedLocation };
        console.log(objectToUpdate);
        for (let [key, value] of Object.entries(data)) {
            let valueToCompare = value !== undefined && value !== null ? value : '';
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value && typeof value === 'object' && Object.keys(value).length > 0) {
                if (oldValue && typeof oldValue === 'object' && Object.keys(oldValue).length > 0) {
                    let cleanedValue = removeEmpty(value);
                    if (!isEquivalent(cleanedValue, oldValue)) {
                        //console.log('not equivalent')
                        console.log(`${key}: new value - ${JSON.stringify(value)}, old value - ${JSON.stringify(oldValue)}`);
                        payload[key] = value;
                    }
                } else {
                    let cleanedValue = removeEmpty(value);
                    console.log(cleanedValue);
                    if (!isEmpty(cleanedValue)) {
                        //console.log('not equivalent')
                        console.log(`${key}: new value - ${JSON.stringify(cleanedValue)}, old value - ${JSON.stringify(oldValue)}`);
                        payload[key] = cleanedValue;
                    }
                }
            } else if (!isEqual(valueToCompare, oldValue)) {
                console.log(`${key}: new value - ${JSON.stringify(valueToCompare)}, old value - ${JSON.stringify(oldValue)}`);
                payload[key] = value;
            }
        }

        console.log(payload);

        if (!isEmpty(payload)) {
            dispatch(actionCreators.updateLinkedLocation(linkedLocation.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLinkedLocation());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLinkedLocationErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Location Details</legend>
                        {(linkedLocation.name || showNameEdit === true) && (
                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules={{ required: false }}
                            name="name"
                            defaultValue={linkedLocation.name ? linkedLocation.name : ''}
                        />
                        )}
                        <FormItem {...formItemLayout} label="Location Type(s)" required format="horizontal"
                                  render={({ onChange, onBlur, value, name }) => (
                                      <Select
                                          placeholder="Please Select the Location Type"
                                          mode="multiple"
                                          allowClear={true}
                                          style={{ width: '100%' }}
                                          virtual={false}
                                          onBlur={onBlur}
                                          onChange={(selected) => { onChange(selected); }}
                                          value={value}
                                          name={name}
                                      >
                                          {locationTypeOptions}
                                      </Select>
                                  )}
                                  rules={{ required: "Required Field" }}
                                  name="locationTypes"
                                  defaultValue={linkedLocation.location.locationTypes ? linkedLocation.location.locationTypes : null}
                        />
                        {(!linkedLocation.name && linkedLocation.location && linkedLocation.location.name && showNameEdit === false) && (
                            <FormItemDisplay {...formItemLayout} label="Name" format="horizontal">
                                <span>{linkedLocation.location.name}</span> <Button type='link' onClick={showName}>(change display name)</Button>
                            </FormItemDisplay>
                        )}
                        <FormItemDouble {...formItemLayoutDouble} label1="Lumper Fee" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => (
                                <InputNumber
                                    min={0}
                                    precision={2}
                                    placeholder="Lumper Fee"
                                    style={{ width: '100%' }}
                                    onBlur={onBlur}
                                    onChange={e => { onChange(e); }}
                                    value={value}
                                    name={name}
                                />
                            )}
                            rules1={{ required: false }}
                            name1="lumperFee"
                            defaultValue1={linkedLocation.lumperFee ? linkedLocation.lumperFee : ''}
                            render2={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {currencyOptions}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name2="lumperFeeUnit"
                            defaultValue2={linkedLocation.lumperFeeUnit ? linkedLocation.lumperFeeUnit : undefined}
                        />
                        <FormItem {...formItemLayout} label="Special Instructions" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <TextArea onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} autoSize={{ minRows: 4 }} />}
                            rules={{ required: false }}
                            name="specialInstructions"
                            defaultValue={linkedLocation.specialInstructions ? linkedLocation.specialInstructions : ''}
                        />
                    </Fieldset>
                    <Fieldset>
                        <legend>Location Bids</legend>
                        <FormItem {...formItemLayout} label="Bids" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select the Bids"
                                    mode="multiple"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                >
                                    {commodities.map(i => <Option value={i.id} key={i.id}>{i.name}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            defaultValue={linkedLocation.commodityIds}
                            name="commodityIds"
                        />
                    </Fieldset>
                    <Fieldset>
                        <legend>Point of Contact</legend>
                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules={{ required: false }}
                            name="pointOfContact.name"
                            defaultValue={linkedLocation.pointOfContact && linkedLocation.pointOfContact.name ? linkedLocation.pointOfContact.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="pointOfContact.email"
                            defaultValue={linkedLocation.pointOfContact && linkedLocation.pointOfContact.email ? linkedLocation.pointOfContact.email : ''}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules1={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1="pointOfContact.phone"
                            defaultValue1={linkedLocation.pointOfContact && linkedLocation.pointOfContact.phone ? linkedLocation.pointOfContact.phone : ''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                            rules2={{ required: false }}
                            name2="pointOfContact.phoneExt"
                            defaultValue2={linkedLocation.pointOfContact && linkedLocation.pointOfContact.phoneExt ? linkedLocation.pointOfContact.phoneExt : ''}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Location" />
            </Form>
        </FormProvider>
    );
};

export default EditLinkedLocation;