import React from 'react';
import classes from './DetailsList.module.scss';

const DetailsList = props => {
    return (
        <div className={classes.detailsListContainer}>
            <div className={classes.detailsList}>
                <table style={props.style ? props.style : null}>
                    <tbody>
                        {props.children}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DetailsList;