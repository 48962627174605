import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { UploadOutlined, DownOutlined } from '@ant-design/icons';
import {Drawer, Button, Dropdown, Menu, Modal, InputNumber, Checkbox} from 'antd';
import EditLoadLane from "../../components/EditLoadLane/EditLoadLane";
import classes from './LoadLanes.module.scss';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faRoute } from "@fortawesome/free-solid-svg-icons";
import DataAddressRow from "../../components/DataAddressRow/DataAddressRow";
import NewLoadLane from '../../components/NewLoadLane/NewLoadLane';
import { withRouter } from 'react-router-dom';

const stringFormatter = new StringFormatter();

const LoadLanes = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const singularEntityName = "Lane";
    const pluralEntityName = "Lanes";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loadLanes.isLoading);
    const records = useSelector(state => state.loadLanes.records);
    const pagination = useSelector(state => state.loadLanes.pagination);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewLoad, setShowNewLoad] = useState(false);
    const [bulkCount, setBulkCount] = useState(1);
    const [repeatDaysCount, setRepeatDaysCount] = useState(1);
    const [isRecurringLoad, setIsRecurringLoad] = useState(false);

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewLoad = () => {
        setShowNewLoad(!showNewLoad);
    };

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: selectedRecord && selectedRecord.accountId ? selectedRecord.accountId : null,
                loadLaneId: selectedRecord && selectedRecord.id ? selectedRecord.id : null,
                duplicateLoadId: null,
                isRecurringLoad: isRecurringLoad,
                repeatDaysCount: repeatDaysCount
            }
        });

        setSelectedRecord({});
    };

    const goToLoadLane = (id) => {
        props.history.push({ pathname: `/loadLanes/${id}` }, {
            previousPageTitle: "Lanes",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const getOrigin = (loadLane) => {
        let locationDisplay = '';
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            let origin = loadLane.locations[0];
            if (origin.location !== undefined && origin.location !== null && origin.location.address !== undefined && origin.location.address !== null) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${origin.locationId}-${origin.sequence}`} title={origin.location.name} address={origin.location.address} />);
            }
        }

        return locationDisplay;
    };

    const getDestination = (loadLane) => {
        let locationDisplay = '';
        if (loadLane.locations !== undefined && loadLane.locations !== null && loadLane.locations.length > 0) {
            let destination = loadLane.locations[loadLane.locations.length - 1];
            if (destination.location !== undefined && destination.location !== null && destination.location.address !== undefined && destination.location.address !== null) {
                locationDisplay = (<DataAddressRow key={`address-row-load-lane-${loadLane.id}-location-${destination.locationId}-${destination.sequence}`} title={destination.location.name} address={destination.location.address} />);
            }
        }

        return locationDisplay;
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="createLoadFromLoadLane" onClick={() => { setSelectedRecord(record); toggleNewLoad(); }}>
                    <UploadOutlined />
                    <span>Create Load(s)</span>
                </Menu.Item>
                {(entityType === 'STAFF' || entityType === 'SHIPPER') && (
                    <Menu.Item key="editEntity" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: '10px' }} />
                        <span>Edit {singularEntityName} Details</span>
                    </Menu.Item>
                )}
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); goToLoadLane(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchLoadLanes(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLoadLanesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        dispatch(actionCreators.fetchLoadLanes(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Customer Lane Id',
            dataIndex: 'customerLaneId',
            key: 'customerLaneId',
        },
        {
            title: 'Origin',
            dataIndex: 'locations',
            key: 'origin',
            render: (text, record) => { return (getOrigin(record)); }
        },
        {
            title: 'Destination',
            dataIndex: 'locations',
            key: 'destination',
            render: (text, record) => { return (getDestination(record)); }
        },
        {
            title: 'Stop Count',
            dataIndex: 'locations',
            key: 'stopCount',
            render: (text, record) => {
                if (text !== undefined && text !== null && text.length > 0) {
                    return text.length;
                } else {
                    return 0;
                }
            }
        },
        {
            title: 'Trailer Type',
            dataIndex: ['equipmentNeeds', 'trailerType', 'description'],
            key: 'equipmentNeeds.trailerType.description',
        },
        {
            title: 'Approx Dist',
            dataIndex: 'approxDistance',
            key: 'approxDistance',
        },
        {
            title: 'Approx Trans Time',
            dataIndex: 'approxTransitTime',
            key: 'approxTransitTime',
        },
    ];

    if (entityType === "STAFF") {
        columns.push({
            title: 'Per Mile Rate',
            dataIndex: 'perMileRate',
            key: 'perMileRate',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        });

        if (accountId === undefined || accountId === null) {
            columns.push({
                title: 'Account Name',
                dataIndex: ['account', 'name'],
                key: 'account.name',
            });
        }
    }

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLoadLane cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLoadLane cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const newLoadComponents = (
        <Modal
            title={"Choose how many Loads you want to Create from this Lane"}
            visible={showNewLoad}
            onOk={goToNewLoad}
            onCancel={(e) => { toggleNewLoad(); setSelectedRecord({}); }}
            maskClosable={false}
        >
            <InputNumber
                value={bulkCount}
                min={1}
                step={1}
                max={entityType === 'CARRIER' ? 100 : 100}
                precision={0}
                style={{ width: '100%' }}
                onChange={onChangeLoadCount}
            />
            <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>

            <Checkbox
                onChange={e => setIsRecurringLoad(e.target.checked)}
                checked={isRecurringLoad}
                name={""}
            />
            <div>* Repeat this load(s) for days:</div>
            {!!isRecurringLoad && (
                <InputNumber
                    value={repeatDaysCount}
                    min={1}
                    step={1}
                    max={entityType === 'CARRIER' ? 100 : 100}
                    precision={0}
                    style={{ width: '100%' }}
                    onChange={(count) => {
                        setRepeatDaysCount(count)
                    }}
                />
            )}

        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={(entityType === 'STAFF' || entityType === 'SHIPPER') ? toggleNewEntity : null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {newLoadComponents}
        </DataTable>
    );
};

export default withRouter(LoadLanes);
