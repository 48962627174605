import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";
import EntityUtils from "../entityUtils";
import {addLinkedAccount} from "./linkedAccounts";

//#region LinkedLocations Functions

export const fetchLinkedLocationsStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATIONS_START
    }
};

export const fetchLinkedLocationsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATIONS_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedLocationsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATIONS_FAIL,
        payload: payload
    }
};

export const clearLinkedLocations = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOCATIONS
    }
};

export const fetchLinkedLocationStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATION_START
    }
};

export const fetchLinkedLocationSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATION_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedLocationFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_LOCATION_FAIL,
        payload: payload
    }
};

export const clearLinkedLocation = () => {
    return {
        type: actionTypes.CLEAR_LINKED_LOCATION
    }
};

export const addLinkedLocationStart = () => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION_START
    }
};

export const addLinkedLocationSuccess = () => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION_SUCCESS
    }
};

export const addLinkedLocationFail = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION_FAIL,
        payload: payload
    }
};

export const addLinkedLocationErrorClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION_ERROR_CLEAR
    }
};

export const addLinkedLocationLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION_LOADING_CLEAR
    }
};

export const updateLinkedLocationStart = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION_START
    }
};

export const updateLinkedLocationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION_SUCCESS
    }
};

export const updateLinkedLocationFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION_FAIL,
        payload: payload
    }
};

export const updateLinkedLocationErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION_ERROR_CLEAR
    }
};

export const updateLinkedLocationLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION_LOADING_CLEAR
    }
};

export const cancelAddLinkedLocation = () => {
    return {
        type: actionTypes.CANCEL_ADD_LINKED_LOCATION
    }
};

export const cancelUpdateLinkedLocation = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LINKED_LOCATION
    }
};

export const insertLinkedLocation = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_LOCATION,
        payload: payload
    }
};

export const changeLinkedLocation = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_LOCATION,
        payload: payload
    }
};

export const changeSingleLinkedLocation = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINKED_LOCATION,
        payload: payload
    }
};

export const removeLinkedLocation = (payload) => {
    return {
        type: actionTypes.REMOVE_LINKED_LOCATION,
        payload: payload
    }
};

//#endregion

//#region LinkedLocations Methods

export const fetchLinkedLocations = (payload) => {
    return async (dispatch, getState) => {
        const linkedLocationsPath = '/linkedLocations';
        try {
            dispatch(fetchLinkedLocationsStart());

            const state = getState();
            const linkedLocationsState = {...state.linkedLocations};
            let pagination = {...linkedLocationsState.pagination};
            let searchParams = {...linkedLocationsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const linkedLocationsRes = await axiosAuthenticated.get(linkedLocationsPath, {params: {...searchParams}});
            if (linkedLocationsRes && linkedLocationsRes.status === 200) {
                const linkedLocations = linkedLocationsRes.data.data;

                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                let transformedLinkedLocations = linkedLocations.map((item) => {
                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(item.accountId, accounts);

                    // Get Commodities Info
                    let commoditiesList = [];
                    if (item.commodityIds !== undefined && item.commodityIds !== null && item.commodityIds.length > 0) {
                        for (let i = 0; i < item.commodityIds.length; i++) {
                            const commodityId = item.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    commoditiesList.push(commodity.name);
                                }
                            }
                        }
                    }

                    let location = item.location;
                    let locationCommoditiesList = [];
                    if (item.location !== undefined && item.location !== null && item.location.commodityIds !== undefined && item.location.commodityIds !== null && item.location.commodityIds.length > 0) {
                        for (let i = 0; i < item.location.commodityIds.length; i++) {
                            const commodityId = item.location.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    locationCommoditiesList.push(commodity.name);
                                }
                            }
                        }
                        location.commodities = locationCommoditiesList;
                    }

                    return {
                        ...item,
                        account: account,
                        commodities: commoditiesList,
                        location: location
                    };
                });

                // Read total count from server
                pagination.total = linkedLocationsRes.data.totalCount;
                pagination.current = linkedLocationsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLinkedLocationsSuccess({
                    records: transformedLinkedLocations,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedLocationsFail({error: error.message}));
        }
    }
};

export const fetchLinkedLocation = (id) => {
    return async (dispatch, getState) => {
        const linkedLocationsPath = '/linkedLocations';
        try {
            dispatch(fetchLinkedLocationStart());

            const linkedLocationsRes = await axiosAuthenticated.get(linkedLocationsPath + `/${id}`);
            if (linkedLocationsRes && linkedLocationsRes.status === 200) {
                const linkedLocation = linkedLocationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(linkedLocation.accountId, accounts);

                // Get Commodities Info
                let commoditiesList = [];
                if (linkedLocation.commodityIds !== undefined && linkedLocation.commodityIds !== null && linkedLocation.commodityIds.length > 0) {
                    for (let i = 0; i < linkedLocation.commodityIds.length; i++) {
                        const commodityId = linkedLocation.commodityIds[i];
                        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                            const commodity = commodities.find(i => i.id === commodityId);
                            if (commodity !== undefined && commodity !== null) {
                                commoditiesList.push(commodity.name);
                            }
                        }
                    }
                }

                let location = linkedLocation.location;
                let locationCommoditiesList = [];
                if (linkedLocation.location !== undefined && linkedLocation.location !== null && linkedLocation.location.commodityIds !== undefined && linkedLocation.location.commodityIds !== null && linkedLocation.location.commodityIds.length > 0) {
                    for (let i = 0; i < linkedLocation.location.commodityIds.length; i++) {
                        const commodityId = linkedLocation.location.commodityIds[i];
                        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                            const commodity = commodities.find(i => i.id === commodityId);
                            if (commodity !== undefined && commodity !== null) {
                                locationCommoditiesList.push(commodity.name);
                            }
                        }
                    }
                    location.commodities = locationCommoditiesList;
                }

                let transformedLinkedLocation = {
                    ...linkedLocation,
                    account: account,
                    commodities: commoditiesList,
                    location: location
                };

                dispatch(fetchLinkedLocationSuccess({record: transformedLinkedLocation}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedLocationFail({error: error.message}));
        }
    }
};

export const addLinkedLocation = (payload) => {
    return async (dispatch, getState) => {
        const linkedLocationsPath = '/linkedLocations';
        try {
            dispatch(addLinkedLocationStart());

            if (payload.lumperFee !== undefined && payload.lumperFee !== null) {
                payload.lumperFee = Number(payload.lumperFee);
                if (payload.lumperFeeUnit === undefined || payload.lumperFeeUnit === null) {
                    payload.lumperFeeUnit = 'USD';
                }
            }

            const linkedLocationsRes = await axiosAuthenticated.post(linkedLocationsPath, {...payload});
            if (linkedLocationsRes && linkedLocationsRes.status === 201) {
                const linkedLocation = linkedLocationsRes.data;

                const state = getState();
                const orchestratorState = {...state.orchestrator};
                const accounts = [...orchestratorState.accounts];
                const commodities = [...orchestratorState.commodities];

                // Get Account Info
                let account = EntityUtils.getAccountInfo(linkedLocation.accountId, accounts);

                // Get Commodities Info
                let commoditiesList = [];
                if (linkedLocation.commodityIds !== undefined && linkedLocation.commodityIds !== null && linkedLocation.commodityIds.length > 0) {
                    for (let i = 0; i < linkedLocation.commodityIds.length; i++) {
                        const commodityId = linkedLocation.commodityIds[i];
                        if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                            const commodity = commodities.find(i => i.id === commodityId);
                            if (commodity !== undefined && commodity !== null) {
                                commoditiesList.push(commodity.name);
                            }
                        }
                    }
                }

                let location = null;
                const locationsRes = await axiosAuthenticated.get('/locations/' + linkedLocation.locationId);
                if (locationsRes && locationsRes.status === 200) {
                    location = locationsRes.data;

                    let locationCommoditiesList = [];
                    if (location !== undefined && location !== null && location.commodityIds !== undefined && location.commodityIds !== null && location.commodityIds.length > 0) {
                        for (let i = 0; i < location.commodityIds.length; i++) {
                            const commodityId = location.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    locationCommoditiesList.push(commodity.name);
                                }
                            }
                        }
                        location.commodities = locationCommoditiesList;
                    }
                }

                let newLinkedLocation = {
                    ...linkedLocation,
                    account: account,
                    commodities: commoditiesList,
                    location: location
                };

                dispatch(insertLinkedLocation(newLinkedLocation));

                // if linkedLocation is added to an account that doesn't have the linkedAccount yet, the linkedAccount should also be created
                // Get Account Info
                let linkedAccount = EntityUtils.getAccountInfo(linkedLocation.linkedAccountId, accounts);
                if (linkedAccount !== undefined && linkedAccount !== null) {
                    let newLinkedAccount = {
                        linkedAccountId: linkedLocation.linkedAccountId,
                        accountId: linkedLocation.accountId,
                        isShipper: linkedAccount.isShipper,
                        isCarrier: linkedAccount.isCarrier,
                        isReceiver: linkedAccount.isReceiver,
                        isProducer: linkedAccount.isProducer
                    };

                    dispatch(addLinkedAccount(newLinkedAccount));
                }

                dispatch(addLinkedLocationSuccess());
                dispatch(addLinkedLocationLoadingClear());
                dispatch(addLinkedLocationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLinkedLocationFail({error: error.message}));
        }
    }
};

export const updateLinkedLocation = (linkedLocationId, payload) => {
    return async (dispatch, getState) => {
        const linkedLocationsPath = '/linkedLocations';
        try {
            dispatch(updateLinkedLocationStart());

            if (payload.lumperFee !== undefined && payload.lumperFee !== null) {
                payload.lumperFee = Number(payload.lumperFee);
                if (payload.lumperFeeUnit === undefined || payload.lumperFeeUnit === null) {
                    payload.lumperFeeUnit = 'USD';
                }
            }

            const linkedLocationsRes = await axiosAuthenticated.put(linkedLocationsPath + `/${linkedLocationId}`, {...payload});
            if (linkedLocationsRes && linkedLocationsRes.status === 200) {
                const linkedLocation = linkedLocationsRes.data;

                if (linkedLocation.isDeleted === true) {
                    dispatch(removeLinkedLocation(linkedLocation));
                } else {
                    const state = getState();
                    const orchestratorState = {...state.orchestrator};
                    const accounts = [...orchestratorState.accounts];
                    const commodities = [...orchestratorState.commodities];

                    // Get Account Info
                    let account = EntityUtils.getAccountInfo(linkedLocation.accountId, accounts);

                    // Get Commodities Info
                    let commoditiesList = [];
                    if (linkedLocation.commodityIds !== undefined && linkedLocation.commodityIds !== null && linkedLocation.commodityIds.length > 0) {
                        for (let i = 0; i < linkedLocation.commodityIds.length; i++) {
                            const commodityId = linkedLocation.commodityIds[i];
                            if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                const commodity = commodities.find(i => i.id === commodityId);
                                if (commodity !== undefined && commodity !== null) {
                                    commoditiesList.push(commodity.name);
                                }
                            }
                        }
                    }

                    let location = null;
                    const locationsRes = await axiosAuthenticated.get('/locations/' + linkedLocation.locationId);
                    if (locationsRes && locationsRes.status === 200) {
                        location = locationsRes.data;

                        let locationCommoditiesList = [];
                        if (location !== undefined && location !== null && location.commodityIds !== undefined && location.commodityIds !== null && location.commodityIds.length > 0) {
                            for (let i = 0; i < location.commodityIds.length; i++) {
                                const commodityId = location.commodityIds[i];
                                if (commodities !== undefined && commodities !== null && commodities.length > 0) {
                                    const commodity = commodities.find(i => i.id === commodityId);
                                    if (commodity !== undefined && commodity !== null) {
                                        locationCommoditiesList.push(commodity.name);
                                    }
                                }
                            }
                            location.commodities = locationCommoditiesList;
                        }
                    }

                    let updatedLinkedLocation = {
                        ...linkedLocation,
                        account: account,
                        commodities: commoditiesList,
                        location: location
                    };

                    dispatch(changeLinkedLocation(updatedLinkedLocation));
                    dispatch(changeSingleLinkedLocation(updatedLinkedLocation));
                }

                dispatch(updateLinkedLocationSuccess());
                dispatch(updateLinkedLocationLoadingClear());
                dispatch(updateLinkedLocationErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLinkedLocationFail({error: error.message}));
        }
    }
};

//#endregion