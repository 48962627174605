import React from 'react';
import DataRow from '../DataRow/DataRow';

const DataAddressRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, title = null, noColon = false, oneLine = false, name = null, streetAddress1 = null, streetAddress2 = null, city = null, state = null, postalCode = null, country = null, address = null }) => {
    if (address !== undefined && address !== null) {
        streetAddress1 = address.streetAddress1;
        streetAddress2 = address.streetAddress2;
        city = address.city;
        state = address.state;
        postalCode = address.postalCode;
        country = address.country;
    }

    if (oneLine === true) {
        return (
            <DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} valueStyle={valueStyle} titleStyle={titleStyle} title={title} noColon={noColon} value={`${streetAddress1 ? streetAddress1 + ' ' : ''}${streetAddress2 ? streetAddress2 + ' ' : ''}${city ? city + ', ' : ''}${state ? state + ' ' : ''}${postalCode ? postalCode + ' ' : ''}${country ? country : ''}`} dataType="String" />
        );
    } else {
        return (
            <div className={className} style={style}>
                {(title !== undefined && title !== null) ? (<div className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </div>) : null}
                {(name !== undefined && name !== null && name.length > 0) ? (<div className={valueClassName} style={valueStyle}>{name}</div>) : null}
                {(streetAddress1 !== undefined && streetAddress1 !== null) ? (<div className={valueClassName} style={valueStyle}>{streetAddress1}</div>) : null}
                {(streetAddress2 !== undefined && streetAddress2 !== null && streetAddress2.length > 0) ? (<div className={valueClassName} style={valueStyle}>{streetAddress2}</div>) : null}
                {((city !== undefined && city !== null) || (state !== undefined && state !== null) || (postalCode !== undefined && postalCode !== null) || (country !== undefined && country !== null)) ? (
                    <div className={valueClassName} style={valueStyle}>{city !== undefined && city !== null ? city + ', ' : ''}{state !== undefined && state !== null ? state + ' ' : ''}{postalCode !== undefined && postalCode !== null ? postalCode + ' ' : ''}{country !== undefined && country !== null ? country : ''}</div>
                ) : null}
            </div>
        );
    }
};

export default DataAddressRow;