import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, PageHeader, Divider, Collapse, Timeline } from "antd";
import LoadMap from "../../components/LoadMap/LoadMap";
import PoweredByLogo from "../../assets/img/PoweredByDark.png";
import DataRow from "../../components/DataRow/DataRow";
import DataListRow from '../../components/DataListRow/DataListRow';
import DataInfoActionRow from '../../components/DataInfoActionRow/DataInfoActionRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import LoadStop from '../../components/LoadStop/LoadStop';
import StringFormatter from '../../shared/stringFormatter';
import axios from "axios";
import moment from "moment";

const { Panel } = Collapse;
const stringFormatter = new StringFormatter();

const Track = props => {
    const loadId = props.match.params.loadId;

    const [load, setLoad] = useState(null);

    const CancelToken = axios.CancelToken;
    let cancel;

    useEffect(() => {
        if (loadId !== undefined && loadId !== null) {
            axios.get('/shipperTracker?loadId=' + loadId, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                setLoad(res.data);
                //console.log(res.data)
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [loadId]);

    const header = (stop, stopSequence, stopType, stopDate, stopTimeZone, stopName, stopCity, stopState, stopStatus) => {
        return (
            <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col md={{ span: 18 }} xs={{ span: 12 }}>
                    <b>{`Stop # ${stopSequence + 1} - `}{stringFormatter.toFormattedString("StopType", stopType, null)} - {stringFormatter.toFormattedString("MomentDate", stopDate, null, stopTimeZone)}{stopName ? " - " + stopName : null}{stopCity && stopState ? " - " + stopCity + ", " + stopState : null} - Status: {stopStatus}</b>
                </Col>
            </Row>
        );
    };

    return (
        <>
            {load !== undefined && load !== null ? (
                <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                    <Row>
                        <Col span={24} style={{ padding: 8 }}>
                            <DataRow title="Load ID" value={load.irisId} dataType="string" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <LoadMap
                                key={`loadMapTrack-${loadId}`}
                                loadId={load.id}
                                irisLoadId={load.irisId}
                                notAuthenticated={true}
                                load={load}
                                stops={load.stops}
                                latitude={load.latitude}
                                longitude={load.longitude}
                                containerName={`track-load-map-${loadId}`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ padding: 8 }}>
                            <Row>
                                <Col span={24}>
                                    <DataRow style={{ textAlign: 'center', fontSize: '30px', fontWeight: 'bold' }} value={load.loadStatus} dataType="LoadStatus" />
                                    <DataRow style={{ textAlign: 'center' }} title="ETA" value={load.approxLoadDuration} dataType="MinutesCountdown" />
                                    <DataRow style={{ textAlign: 'center' }} title="Trip Miles" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
                                    <DataRow style={{ textAlign: 'center' }} title="Last Updated At" value={moment.utc()} dataType="MomentDateTime" />
                                </Col>
                            </Row>
                            <Divider style={{ margin: '8px 0' }} />
                            {load.loadNotifications !== undefined && load.loadNotifications !== null && load.loadNotifications.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <PageHeader ghost={false} title={'Notifications'} style={{ padding: 0 }} />
                                            <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                                                <Timeline>
                                                    {load.loadNotifications.map((loadEvent, index) => (
                                                        <Timeline.Item key={`load-event-${index}`} color={loadEvent.severity === "CRITICAL" ? "red" : "green"}>
                                                            {loadEvent.message} {stringFormatter.toFormattedString("MomentDateTime", loadEvent.createdAt, null)}
                                                        </Timeline.Item>
                                                    ))}
                                                </Timeline>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {load.drivers !== undefined && load.drivers !== null && load.drivers.length > 0 ? (
                                <>
                                    <PageHeader ghost={false} title={'Your Driver(s)'} style={{ padding: 0 }} />
                                    {load.drivers.map((accountUser, index) => (
                                        <Row key={`driver-details-${index}`}>
                                            <Col span={24}>
                                                <DataInfoActionRow title={`Driver ${index + 1}`} value={accountUser.user.firstName + ' ' + accountUser.user.lastName} dataType="String" actions={null} />
                                            </Col>
                                        </Row>
                                    ))}
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {load.stops !== undefined && load.stops !== null && load.stops.length > 0 ? (
                                <>
                                    <PageHeader ghost={false} title={'Stops'} style={{ padding: 0 }} />
                                    <Collapse>
                                        {load.stops.map(stop => (
                                            <Panel header={header(stop, stop.sequence, stop.stopType, stop.requestedDateTime, stop.timeZone, (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.name : null), (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.city : null), (stop.stopLocation !== undefined && stop.stopLocation !== null ? stop.stopLocation.address.state : null), stop.stopStatus)} key={`stop-panel-${stop.id}`}>
                                                <LoadStop stop={stop} showDocuments={false} showNotes={false} key={`stop-${stop.id}`} />
                                            </Panel>
                                        ))}
                                    </Collapse>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            <PageHeader ghost={false} title={'Equipment Details'} style={{ padding: 0 }} />
                            <Row>
                                <Col span={24}>
                                    {load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                    {load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                    {load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                    <DataRow title="Team Driving" value={load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    {load.equipmentNeeds.preCoolReefer !== null && load.equipmentNeeds.preCoolReefer !== undefined ? (<DataRow title="Pre-cool Temp" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                    {load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined ? (<DataTemperatureRangeRow title="Temp Range" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />) : null}
                                </Col>
                                <Col span={12}>
                                    {load.loadWeight !== null && load.loadWeight !== undefined && load.loadWeight > 0 ? (<DataRow title="Load Weight" value={load.loadWeight} units={load.loadWeightUnits} dataType="Weight" />) : null}
                                    {load.totalWeight !== null && load.totalWeight !== undefined && load.totalWeight > 0 ? (<DataRow title="Total Weight" value={load.totalWeight} units={load.totalWeightUnits} dataType="Weight" />) : null}
                                </Col>
                            </Row>
                            <Row>
                            {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                        <Col span={12}>
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                    </Col>
                                    ) : null}
                                    {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                        <Col span={12}>
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                    </Col>
                                    ) : null}
                            </Row>
                            <Divider style={{ margin: '8px 0' }} />
                            {load.assets !== undefined && load.assets !== null && load.assets.length > 0 ? (
                                <>
                                    <PageHeader ghost={false} title={'Asset Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ paddingTop: 10 }}>
                                                {load.assets.map((asset, index) => (
                                                    <DataInfoActionRow title={`Asset ${index + 1}`} value={`${stringFormatter.toFormattedString("AssetType", asset.assetType, null)}${asset.trailerType !== undefined && asset.trailerType !== null ? ' (' + asset.trailerType.description + ')' : ''} (${asset.licensePlateNum} ${asset.licensePlateState})`} dataType="String" actions={null} />
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {load.assignedCarrier !== null && load.assignedCarrier !== undefined ? (
                                <>
                                    <PageHeader ghost={false} title={'Carrier Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title='Carrier ID' value={load.assignedCarrier.carrier.irisId} dataType='String' />
                                            <DataRow title='Name' value={load.assignedCarrier.name} dataType='String' />
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title='DOT #' value={load.assignedCarrier.carrier.dotNum} dataType='String' />
                                            <DataRow title='MC #' value={load.assignedCarrier.carrier.mcNum} dataType='String' />
                                            {load.assignedCarrier.carrier.smsBasic && load.assignedCarrier.carrier.smsBasic.weightedAvgSMSScore && <DataRow title='SMS Basic Avg' value={load.assignedCarrier.carrier.smsBasic.weightedAvgSMSScore} dataType='String' />}
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            {load.shipper !== null && load.shipper !== undefined ? (
                                <>
                                    <PageHeader ghost={false} title={'Your Details'} style={{ padding: 0 }} />
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title='Shipper ID' value={load.shipper.shipper.irisId} dataType='String' />
                                            <DataRow title='Name' value={load.shipper.name} dataType='String' />
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title='D&B #' value={load.shipper.shipper.dbNum} dataType='String' />
                                            <DataRow title='PBB #' value={load.shipper.shipper.blueBookNum} dataType='String' />
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: '8px 0' }} />
                                </>
                            ) : null}
                            <div style={{ textAlign: 'center' }}><img src={PoweredByLogo} alt="IRIS" /></div>
                        </Col>
                    </Row>
                </Layout>
            ) : null}
        </>
    );
};

export default withRouter(Track);