import React, {useState, useEffect, useRef} from 'react';
import {Col, Row, Button, Drawer, Modal, Dropdown, Menu, Spin} from 'antd';
import StringFormatter from '../../shared/stringFormatter';
import { useSelector, useDispatch } from 'react-redux';
import * as actionCreators from "../../store/actions/index";
import { isEmpty } from 'lodash';
import DataRow from '../DataRow/DataRow';
import ComplexDataLinkCard from '../ComplexDataLinkCard/ComplexDataLinkCard';
import SimpleDataLinkCard from "../../components/SimpleDataLinkCard/SimpleDataLinkCard";
import ComplexCard from '../ComplexCard/ComplexCard';
import DataContactInfoRow from '../DataContactInfoRow/DataContactInfoRow';
import DataPhoneNumberRow from '../DataPhoneNumberRow/DataPhoneNumberRow';
import DataAddressRow from '../DataAddressRow/DataAddressRow';
import { DownOutlined, PaperClipOutlined, ControlOutlined } from '@ant-design/icons';
import EditCarrier from "../../components/EditCarrier/EditCarrier";
import DocumentList from "../../components/DocumentList/DocumentList";
import EditAccount from "../../components/EditAccount/EditAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTruck, faUsers} from '@fortawesome/free-solid-svg-icons';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import GPS_ICON from '../../assets/img/GPS_ICON@1x.png'
import PickupIcon from '../../assets/img/pickUpMarker.png'
import DropOffIcon from '../../assets/img/dropOffMarker.png'
import pickUpMarker from "../../assets/img/pickUpMarker.png";
import dropOffMarker from "../../assets/img/dropOffMarker.png";

const stringFormatter = new StringFormatter();
TrimbleMaps.APIKey = process.env.REACT_APP_TMS_API_KEY;

const CompanyCarrierSnapshot = props => {
    const fullWidth = global.window.innerWidth;
    const account = props.record;

    const [metrics, setMetrics] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [selectedStop, setSelectedStop] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [coordinates, setCoordinates] = useState({
        longitude: -94.566234,
        latitude: 40.49944
    })
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showEditCarrier, setShowEditCarrier] = useState(false);
    const [showDocumentsModal, setShowDocumentsModal] = useState(false);
    const [missingDocumentsCount, setMissingDocumentsCount] = useState(0);
    const [pendingApprovalCount, setPendingApprovalCount] = useState(0);

    const mapRef = useRef(null);

    const dispatch = useDispatch();
    const allMetrics = useSelector(state => state.metrics.metrics);
    const entityType = useSelector(state => state.auth.entityType);
    const loads = useSelector(state => state.loads.records);
    const loadsIsLoading = useSelector(state => state.loads.isLoading);

    //-- Define radius function
    if (typeof (Number.prototype.toRad) === "undefined") {
        Number.prototype.toRad = function () {
            return this * Math.PI / 180;
        }
    }

    //-- Define degrees function
    if (typeof (Number.prototype.toDeg) === "undefined") {
        Number.prototype.toDeg = function () {
            return this * (180 / Math.PI);
        }
    }

    const getMiddleOfLoadPoint = (item) => {
        let lat1 = item.origin.latitude
        let lat2 = item.destination.latitude
        let lng1 = item.origin.longitude
        let lng2 = item.destination.longitude
        //-- Longitude difference
        const dLng = (lng2 - lng1).toRad();

        //-- Convert to radians
        lat1 = lat1.toRad();
        lat2 = lat2.toRad();
        lng1 = lng1.toRad();

        const bX = Math.cos(lat2) * Math.cos(dLng);
        const bY = Math.cos(lat2) * Math.sin(dLng);
        const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
        const lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

        //-- Return result
        return {
            longitude: lng3.toDeg(),
            latitude: lat3.toDeg()
        }
    }

    const setupMap = (loads) => {
        mapRef.current = new TrimbleMaps.Map({
            container: 'myMap',
            center: new TrimbleMaps.LngLat(coordinates.longitude, coordinates.latitude),
            zoom: zoom,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA,
            displayControlsDefault: true
        });
        mapRef.current.addControl(new TrimbleMaps.NavigationControl());

        mapRef.current.on('load', function() {
            // Setting up cluster markers, filtering selected route
            loads
                .filter(elem => !(selectedRoute && elem.id == selectedRoute.id))
                .forEach((elem, i) => {
                    getCustomClusterMarker(mapRef.current, elem)
                });
            if (selectedRoute) {
                selectedRoute.stops.forEach((elem) => {
                    const div = document.createElement('div');
                    const stopIcon = elem.stopType === 'PICK_UP' ? PickupIcon : (elem.stopType === 'DROP_OFF' ? DropOffIcon : null);

                    div.style.background = "url("+ stopIcon +")"
                    div.style.width = "40px"
                    div.style.height = "40px"
                    div.style.backgroundSize = "contain"
                    div.id = elem.stopLocationId;
                    new TrimbleMaps.Marker({
                        element: div
                    })
                        .setLngLat([elem.longitude, elem.latitude])
                        .addTo(mapRef.current);
                })

                const routeId = selectedRoute.irisId;
                const myRoute = new TrimbleMaps.Route({
                    routeId: routeId,
                    stops: [
                        new TrimbleMaps.LngLat(selectedRoute.origin.longitude, selectedRoute.origin.latitude),
                        new TrimbleMaps.LngLat(selectedRoute.destination.longitude, selectedRoute.destination.latitude),
                    ],
                    vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                    routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                    routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                    highwayOnly: false,
                    distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                    tollDiscourage: true,
                })
                myRoute.addTo(mapRef.current);
            }
        });
    }

    const getCustomClusterMarker = (map, elem) => {
        if (!!elem.current_location && elem.current_location.length) {

            const {longitude, latitude} = elem.current_location[elem.current_location.length - 1]
            const center = [longitude, latitude];
            const content = "<span><image style='width: 40px; height:40px;' src='"+GPS_ICON+"'/></span>";
            // MARKER
            const el = document.createElement('div');
            el.title = elem.origin.stopLocation.name;
            el.innerHTML = content;
            el.id = elem.irisId;

            // POPOVER
            const divLabel = document.createElement('div');
            divLabel.style.display = "none"
            divLabel.style.position = "absolute"
            divLabel.style.width = "200px"
            divLabel.style.background = "#fff"
            divLabel.style.borderRadius = "10px"
            divLabel.style.padding = "10px"
            divLabel.style.bottom = "-50px"
            divLabel.style.left = "105%"

            // POPOVER HTML (LOAD INFO)
            divLabel.innerHTML = `
            <div>
                <div>${elem.irisId}</div>
                <div>Current Status: ${elem.loadStatus}</div>
                <div>Origin: ${elem.origin.stopLocation.name}</div>
                <div>Destination: ${elem.destination.stopLocation.name}</div>
                <div>Shipper: ${elem.shipper.name}</div>
                <div>Carrier: ${elem.assignedCarrier.name}</div>
            </div>
        `

            el.appendChild(divLabel);

            // EVENT LISTENERS
            el.addEventListener('click', (e) => {
                setSelectedRoute(elem)
            })
            el.addEventListener('mouseenter', (e) => {
                divLabel.style.display = "block"
            })
            el.addEventListener('mouseleave', (e) => {
                divLabel.style.display = "none"
            })

            new TrimbleMaps.Marker({
                element: el
            })
                .setLngLat(center)
                .addTo(map);
        }
    }

    const updateMissingDocumentsCount = (value) => {
        //console.log(value);
        setMissingDocumentsCount(value);
    };

    const updatePendingApprovalCount = (value) => {
        //console.log(value);
        setPendingApprovalCount(value);
    };

    const verifyAccount = (accountId) => {
        dispatch(actionCreators.verifyAccount(accountId));
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditCarrier = () => {
        setShowEditCarrier(!showEditCarrier);
    };

    const toggleDocumentsModal = () => {
        setShowDocumentsModal(!showDocumentsModal);
    };

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="manageDocuments" onClick={(e) => { e.domEvent.stopPropagation(); toggleDocumentsModal(); }}>
                    <PaperClipOutlined />
                    <span>Manage Documents</span>
                </Menu.Item>
                <Menu.Item key="editAccount" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: '10px' }} />
                    <span>Edit Account Details</span>
                </Menu.Item>
                <Menu.Item key="editCarrier" onClick={(e) => { e.domEvent.stopPropagation(); toggleEditCarrier(); }}>
                    <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: '10px' }} />
                    <span>Edit Carrier Details</span>
                </Menu.Item>
            </Menu>
        );
    };

    const extra = (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button onClick={(e) => { e.stopPropagation(); }}><ControlOutlined /> <DownOutlined /></Button>
        </Dropdown>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit Account Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccount cancel={toggleEditEntity} record={account} />
        </Drawer>
    );

    const editCarrierComponents = (
        <Drawer
            title={"Edit Carrier Details"}
            onClose={toggleEditCarrier}
            visible={showEditCarrier}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditCarrier cancel={toggleEditCarrier} record={account} />
        </Drawer>
    );

    const documentsComponent = (
        <Modal
            title="Carrier Documents"
            visible={showDocumentsModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleDocumentsModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            {account !== undefined && account !== null &&
                <DocumentList entityType="ACCOUNT" account={account} entityId={account.id} updatePendingApprovalCount={updatePendingApprovalCount} updateMissingDocumentsCount={updateMissingDocumentsCount} />
            }
            {account !== undefined && account !== null && account.isVerified === false && entityType === 'STAFF' ? (
                <Button style={{ marginTop: 20 }} type='primary' onClick={() => { verifyAccount(account.id); }} disabled={pendingApprovalCount > 0 || missingDocumentsCount > 0}>Verify Account</Button>
            ) : null}
        </Modal>
    );

    useEffect(() => {
        if (account !== undefined && account !== null) {
            if (allMetrics !== undefined && allMetrics !== null && !isEmpty(allMetrics)) {
                //console.log(allMetrics[account.id]);
                if (allMetrics[account.id] !== undefined && allMetrics[account.id] !== null && !isEmpty(allMetrics[account.id])) {
                    setMetrics(allMetrics[account.id]);
                } else {
                    dispatch(actionCreators.fetchMetrics('CARRIER', account.id));
                }
            } else {
                dispatch(actionCreators.fetchMetrics('CARRIER', account.id));
            }
        }
    }, [allMetrics, account]);

    useEffect(() => {
        fetchLoads()
    }, [])


    useEffect(() => {
        if (account && account.carrier) {
            setupMap(loads);
        }
        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, [loadsIsLoading, selectedRoute, account])

    const fetchLoads = () => {
        let searchParams = {
            page: 1,
            size: 20,
            sort: 'pickUpDateTime',
            order: 'asc',
            isDeleted: false,
            eta: true,
            loadStatus: "IN_TRANSIT"
        };
        dispatch(actionCreators.fetchLoads(searchParams, '/orch/loads'));
    }

    if (account && account.carrier) {
        return (
            <>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={!!loadsIsLoading}>
                            <div id={"myMap"} style={{height: "40vh", width: "100%", border: "1px solid black", marginBottom: "10px", position: "relative"}}>
                                {selectedRoute && (
                                    <div style={{
                                        position: "absolute",
                                        width: "225px",
                                        top: "10px",
                                        left: "10px",
                                        padding: "10px",
                                        background: "#fff",
                                        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                        zIndex: "1"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <span className="label">Load: {selectedRoute.irisId}</span>
                                            <span onClick={() => setSelectedRoute(null)}><FontAwesomeIcon style={{transform: "rotate(45deg)"}} icon={faPlusCircle}/></span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Spin>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexDataLinkCard
                            title="Carrier Details"
                            headStyle={{ fontSize: 14 }}
                            data={{
                                "Account ID": { value: account.irisId, dataType: 'String' },
                                "Carrier ID": { value: account.carrier.irisId, dataType: 'String' },
                                "Assets": { value: metrics && metrics.totalAssets ? metrics.totalAssets : 0, dataType: 'Integer', url: entityType === 'CARRIER' ? '/assets' : '#assets' },
                                "Drivers": { value: metrics && metrics.totalDrivers ? metrics.totalDrivers : 0, dataType: 'Integer', url: entityType === 'CARRIER' ? '/drivers' : '#drivers' }
                            }}
                            extra={extra}
                        />
                    </Col>
                    <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexDataLinkCard
                            title="Earnings"
                            style={{ border: '1px solid #b7eb8f' }}
                            headStyle={{ backgroundColor: '#f6ffed', borderBottom: '1px solid #b7eb8f', fontSize: 14 }}
                            data={{
                                "Total Earnings": { value: metrics && metrics.totalEarnings ? Math.abs(metrics.totalEarnings) : 0, units: metrics && metrics.totalEarnings && metrics.totalEarningsUnit ? metrics.totalEarningsUnit : null, dataType: 'Money' },
                                "Total Completed Loads": { value: metrics && metrics.totalCompletedLoads ? metrics.totalCompletedLoads : 0, dataType: 'Integer', url: entityType === 'CARRIER' ? '/completedLoads' : '#completedLoads' },
                                "Earnings This Mo": { value: metrics && metrics.totalEarningsThisMonth ? Math.abs(metrics.totalEarningsThisMonth) : 0, units: metrics && metrics.totalEarningsThisMonth && metrics.totalEarningsThisMonthUnit ? metrics.totalEarningsThisMonthUnit : null, dataType: 'Money' },
                                "Completed Loads This Mo": { value: metrics && metrics.completedLoadsThisMonth ? metrics.completedLoadsThisMonth : 0, dataType: 'Integer', url: entityType === 'CARRIER' ? '/completedLoads' : '#completedLoads' }
                            }}
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <SimpleDataLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="Load Requests"
                            dataSize={1}
                            data={metrics && metrics.totalLoadRequests ? metrics.totalLoadRequests : 0}
                            url={entityType === 'CARRIER' ? '/loadRequests' : '#loadRequests'}
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <SimpleDataLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="RFP's"
                            dataSize={3}
                            data={'Coming Soon'}
                            url="#rfps"
                        />
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <SimpleDataLinkCard
                            style={{ border: '1px solid #ffa39e' }}
                            headStyle={{ backgroundColor: '#fff1f0', borderBottom: '1px solid #ffa39e', fontSize: 14 }}
                            title="Loads to DispatchAdmin"
                            dataSize={1}
                            data={metrics && metrics.totalLoadsToDispatch ? metrics.totalLoadsToDispatch : 0}
                            url={entityType === 'CARRIER' ? '/dispatch' : '#openLoads'}
                        />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Carrier Operations"
                            headStyle={{ fontSize: 14 }}
                        >
                            {account.carrier.pointOfContact !== undefined && account.carrier.pointOfContact !== null && account.carrier.pointOfContact.name !== undefined && account.carrier.pointOfContact.name !== null ? <DataRow title="Operations Contact" value={account.carrier.pointOfContact.name} /> : null}
                            {account.carrier.pointOfContact !== undefined && account.carrier.pointOfContact !== null && account.carrier.pointOfContact.phone !== undefined && account.carrier.pointOfContact.phone !== null ? <DataPhoneNumberRow title="Operations Phone" phone={account.carrier.pointOfContact.phone} phoneExt={account.carrier.pointOfContact.phoneExt} /> : null}
                            {account.carrier.pointOfContact !== undefined && account.carrier.pointOfContact !== null && account.carrier.pointOfContact.email !== undefined && account.carrier.pointOfContact.email !== null ? <DataRow title="Operations Email" value={account.carrier.pointOfContact.email} /> : null}
                            {account.afterHoursPhone !== undefined && account.afterHoursPhone !== null ? <DataPhoneNumberRow title="After Hours Phone" phone={account.afterHoursPhone} phoneExt={account.afterHoursPhoneExt} /> : null}
                        </ComplexCard>
                    </Col>
                    <Col lg={{ span: 4 }} md={{ span: 12 }} sm={{ span: 12 }}>
                        <SimpleDataLinkCard
                            title="Active Loads"
                            headStyle={{ fontSize: 14 }}
                            dataSize={1}
                            data={metrics && metrics.totalActiveLoads ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                            url={entityType === 'CARRIER' ? '/intransitLoads' : '#intransitLoads'}
                        />
                    </Col>
                    <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="DispatchAdmin Information"
                            style={{ border: '1px solid #ffe58f' }}
                            headStyle={{ backgroundColor: '#fffbe6', borderBottom: '1px solid #ffe58f', fontSize: 14 }}
                        >
                            <div><b>Assets Available:</b> <a style={{ textDecoration: 'underline' }} href={entityType === 'CARRIER' ? '/assets' : '#assets'}>{metrics && metrics.totalAssetsAvailable ? metrics.totalAssetsAvailable : 0}</a></div>
                            <div><b>Drivers Available:</b> <a style={{ textDecoration: 'underline' }} href={entityType === 'CARRIER' ? '/drivers' : '#drivers'}>{metrics && metrics.totalDriversAvailable ? metrics.totalDriversAvailable : 0}</a></div>
                        </ComplexCard>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Carrier Accounts Receivable"
                            headStyle={{ fontSize: 14 }}
                        >
                            {account.carrier.accountsReceivable !== undefined && account.carrier.accountsReceivable !== null ? <DataContactInfoRow title="A/P" separateLines={true} contact={account.carrier.accountsReceivable} /> : null}
                            {account.carrier.shippingAddress !== undefined && account.carrier.shippingAddress !== null ? <DataAddressRow title="A/P Address" oneLine={true} address={account.carrier.shippingAddress} /> : null}
                        </ComplexCard>
                    </Col>
                    <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                        <ComplexCard
                            title="Additional Information"
                            headStyle={{ fontSize: 14 }}
                        >
                            <div><b>Insurance Policy:</b> N/A</div>
                            {entityType === "STAFF" &&
                                <div><b>Documents:</b> <a style={{ textDecoration: 'underline' }} onClick={toggleDocumentsModal}>view</a></div>
                            }
                        </ComplexCard>
                    </Col>
                </Row>
                {editEntityComponents}
                {editCarrierComponents}
                {documentsComponent}
            </>
        );
    } else {
        return null;
    }
};

export default CompanyCarrierSnapshot;
