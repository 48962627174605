import React, { useMemo, useState } from 'react';
import { Alert, Spin, Select, Drawer } from "antd";
import { isEmpty } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import UploadFile from "../../components/UploadFile/UploadFile";
import NewClaim from '../../components/NewClaim/NewClaim';
import Enums from '../../shared/enums';
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemFile from '../FormItemFile/FormItemFile';
import FormButtons from '../FormButtons/FormButtons';

const { Option } = Select;

const NewDocument = props => {
    const fullWidth = global.window.innerWidth;
    const loadId = props.loadId;
    const entityType = props.entityType;
    const entitySubType = props.entitySubType;
    const entityId = props.entityId;
    const account = props.account;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.documents.isRecordAddLoading);
    const error = useSelector(state => state.documents.addRecordError);
    const userEntityType = useSelector(state => state.auth.entityType);

    const documentVisibilityOptions = Enums.DocumentVisibilities.selectListOptions();

    const [showClaimDrawer, setShowClaimDrawer] = useState(false);
    const [file, setFile] = useState(null);

    const closeClaimDrawer = () => {
        setShowClaimDrawer(false);
    };

    const openClaimDrawer = () => {
        setShowClaimDrawer(true);
    };

    const documentType = methods.watch('documentType');

    const getReviewStatuses = () => {
        let children = [];
        if (documentType !== undefined && documentType !== null) {
            if (entitySubType === 'PICK_UP') {
                // only show approved as an option for BOLs if they belong to a pick up stop
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else if (entitySubType === 'DROP_OFF') {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            } else {
                switch (documentType) {
                    case 'BOL':
                        children.push(<Option value="CLEAN" key="CLEAN">Clean</Option>);
                        children.push(<Option value="OSDR" key="OSDR">Has OSDR</Option>);
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                        break;
                    default:
                        children.push(<Option value="APPROVED" key="APPROVED">Approved</Option>);
                }
            }
        } else {
            children.push(<Option value="APPROVED" key="APPROVED">APPROVED</Option>);
        }

        return children;
    };

    const onReviewStatusChange = (value) => {
        if (value !== null && value !== undefined && loadId !== undefined && loadId !== null) {
            if (value === 'CLEAN' || value === 'APPROVED') {
                setShowClaimDrawer(false);
            } else if (value === 'OSDR') {
                setShowClaimDrawer(true);
            }
        } else {
            setShowClaimDrawer(false);
        }
    };

    const onSubmit = (data) => {
        if (file) {
            data.file = file;
        }

        if (userEntityType === "STAFF") {
            data.isReviewed = true;
        }

        if (data.reviewStatus === undefined || data.reviewStatus === null) {
            data.reviewStatus = "PENDING";
        }

        if (data.visibleTo === undefined || data.visibleTo === null) {
            data.visibleTo = "ALL";
        }

        data.entityId = entityId;
        data.entityType = entityType;
        //console.log(file)
        //console.log(data)
        if (!isEmpty(data)) {
            dispatch(actionCreators.addDocument(data));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelAddDocument());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.addDocumentErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                        <FormItemFile {...formItemLayout} label="Document" name="file">
                            {props.entityType === 'DRIVER' ? (
                                <UploadFile
                                    beforeUpload={(file) => {
                                        setFile(file);
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                    }}
                                    entityType={props.entityType}
                                    documentTypeInputName={'documentType'}
                                    displayNameInputName={'displayName'}
                                    descriptionInputName={'description'}
                                    expirationDateInputName={'expiryDate'}
                                    message="Please upload a photo of the document."
                                    cancel={cancel}
                                />
                            ) : (
                                    <UploadFile
                                        beforeUpload={(file) => {
                                            setFile(file);
                                            return false;
                                        }}
                                        onRemove={(file) => {
                                            setFile(null);
                                        }}
                                        entityType={props.entityType}
                                        documentTypeInputName={'documentType'}
                                        displayNameInputName={'displayName'}
                                        descriptionInputName={'description'}
                                        message="Please upload a photo of the document."
                                        cancel={cancel}
                                        account={account}
                                    />
                                )}
                        </FormItemFile>
                        {(userEntityType === "STAFF" || (userEntityType === "CARRIER" || entityType === "DRIVER")) &&
                            <FormItem {...formItemLayout} label="Review Status" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please Select a Review Status"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onReviewStatusChange(selected); onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {getReviewStatuses()}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="reviewStatus"
                                disabled={documentType !== undefined && documentType !== null ? false : true}
                            />
                        }
                        {userEntityType === "STAFF" &&
                            <FormItem {...formItemLayout} label="Visibility" required
                                render={({ onChange, onBlur, value, name }) => (
                                    <Select
                                        placeholder="Please select the Visibility"
                                        allowClear={true}
                                        style={{ width: '100%' }}
                                        virtual={false}
                                        onBlur={onBlur}
                                        onChange={(selected) => { onChange(selected); }}
                                        value={value}
                                        name={name}
                                    >
                                        {documentVisibilityOptions}
                                    </Select>
                                )}
                                rules={{ required: 'Required Field' }}
                                name="visibleTo"
                            />
                        }
                        {error && <Alert message={`${error}`} type="error" />}
                    </Spin>
                    <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Create Document" />
                </Form>
            </FormProvider>
            <Drawer
                title="File Claim"
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                closable={false}
                onClose={closeClaimDrawer}
                visible={showClaimDrawer}
                destroyOnClose={true}
                maskClosable={false}
            >
                <NewClaim cancel={closeClaimDrawer} loadId={loadId} />
            </Drawer>
        </>
    );
};

export default NewDocument;