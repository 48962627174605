import React, { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Spin, Typography } from "antd";
import { Redirect } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from "react-router-dom";
import classes from './ForgotPasswordForm.module.scss';
import IRISFloatingFooter from '../../hoc/IRISFloatingFooter/IRISFloatingFooter';
import axios from "axios";
import Form from '../Form/Form';
import FormItem from '../FormItem/FormItem';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';

const { Title } = Typography;

const ForgotPasswordForm = props => {

    const [isLoading, setIsLoading] = useState(false);
    const [isResetRequestComplete, setIsResetRequestComplete] = useState(false);

    const methods = useForm();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            md: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            md: { span: 16 },
        },
    };

    const onSubmit = (data) => {
        const resetRequestPath = "/rest-auth/password/reset/";
        const email = data.email;
        setIsLoading(true);
        axios.post(resetRequestPath, {
            email: email
        }).then(res => {
            if (res && res.status === 200) {
                message.success('Your password reset request has been submitted');
                setIsLoading(false);
                setIsResetRequestComplete(true);
                methods.reset({});
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <>
            {isResetRequestComplete && <Redirect to='/login' />}
            <Card bordered={true} className={classes.card} size="small">
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Title level={2} style={{ textAlign: 'center' }}>Forgot Your Password?</Title>
                        </Col>
                    </Row>
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <FormProvider {...methods}>
                                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <FormItem {...formItemLayout} label="Email Address" required
                                        render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />}
                                        rules={{
                                            required: 'Required Field',
                                            minLength: { value: 4, message: 'Email Address must be at least 4 characters long' }
                                        }}
                                        name="email"
                                    />
                                    <FormItemDisplay>
                                        <Button block type="primary" htmlType="submit" className="login-form-button">
                                            Submit
                                        </Button>
                                    </FormItemDisplay>
                                    <FormItemDisplay style={{ textAlign: 'right' }}>
                                        Remembered your Password? <Link to="/login"><Button type="link">Back to Login</Button></Link>
                                    </FormItemDisplay>
                                </Form>
                            </FormProvider>
                        </Col>
                    </Row>
                </Spin>
                <IRISFloatingFooter />
            </Card>
        </>
    );
};

export default ForgotPasswordForm;
