import * as actionTypes from "../actions/actionTypes";

//#region Your Accounts Functions

export const fetchYourAccountsStart = () => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNTS_START
    }
};

export const fetchYourAccountsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNTS_SUCCESS,
        payload: payload
    }
};

export const fetchYourAccountsFail = (payload) => {
    return {
        type: actionTypes.FETCH_YOUR_ACCOUNTS_FAIL,
        payload: payload
    }
};

export const clearYourAccounts = () => {
    return {
        type: actionTypes.CLEAR_YOUR_ACCOUNTS
    }
};

//#endregion