import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import { Col, Row } from 'antd';

const LineItemType = props => {
    const lineItemType = props.record;

    return (
        <Row>
            <Col xs={24} md={24} lg={12} xl={12}>
                {lineItemType.name && <DataRow title="Name" value={lineItemType.name} dataType="String" />}
                {lineItemType.description && <DataRow title="Description" value={lineItemType.description} dataType="String" />}
                {lineItemType.itemType && <DataRow title="Type" value={lineItemType.itemType} dataType="LineItemType" />}
                {lineItemType.trailerTypes !== undefined && lineItemType.trailerTypes !== null && lineItemType.trailerTypes.length > 0 ? <DataRow title="Trailer Types" value={lineItemType.trailerTypes.map((item) => { return item.description; }).join(', ')} dataType="String" /> : null}
                <DataRow title="Is Required" value={lineItemType.isRequired} dataType="Boolean" />
                <DataRow title="Is Open Amount" value={lineItemType.isOpen} dataType="Boolean" />
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
                {lineItemType.upCharge && <DataRow title="Up-Charge Amount" value={lineItemType.upCharge} dataType="Money" />}
                {lineItemType.upChargeRule && <DataRow title="Up-Charge Rule" value={lineItemType.upChargeRule} dataType="LineItemRule" />}
                {lineItemType.cost && <DataRow title="Cost Amount" value={lineItemType.cost} dataType="Money" />}
                {lineItemType.costRule && <DataRow title="Cost Rule" value={lineItemType.costRule} dataType="LineItemRule" />}
            </Col>
        </Row>
    );
};

export default LineItemType;