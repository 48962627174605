import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Shifts Functions

export const fetchShiftsStart = () => {
    return {
        type: actionTypes.FETCH_SHIFTS_START
    }
};

export const fetchShiftsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFTS_SUCCESS,
        payload: payload
    }
};

export const fetchShiftsFail = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFTS_FAIL,
        payload: payload
    }
};

export const clearShifts = () => {
    return {
        type: actionTypes.CLEAR_SHIFTS
    }
};

export const fetchShiftStart = () => {
    return {
        type: actionTypes.FETCH_SHIFT_START
    }
};

export const fetchShiftSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_SUCCESS,
        payload: payload
    }
};

export const fetchShiftFail = (payload) => {
    return {
        type: actionTypes.FETCH_SHIFT_FAIL,
        payload: payload
    }
};

export const clearShift = () => {
    return {
        type: actionTypes.CLEAR_SHIFT
    }
};

export const addShiftStart = () => {
    return {
        type: actionTypes.ADD_SHIFT_START
    }
};

export const addShiftSuccess = () => {
    return {
        type: actionTypes.ADD_SHIFT_SUCCESS
    }
};

export const addShiftFail = (payload) => {
    return {
        type: actionTypes.ADD_SHIFT_FAIL,
        payload: payload
    }
};

export const addShiftErrorClear = () => {
    return {
        type: actionTypes.ADD_SHIFT_ERROR_CLEAR
    }
};

export const addShiftLoadingClear = () => {
    return {
        type: actionTypes.ADD_SHIFT_LOADING_CLEAR
    }
};

export const updateShiftStart = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_START
    }
};

export const updateShiftSuccess = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_SUCCESS
    }
};

export const updateShiftFail = (payload) => {
    return {
        type: actionTypes.UPDATE_SHIFT_FAIL,
        payload: payload
    }
};

export const updateShiftErrorClear = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_ERROR_CLEAR
    }
};

export const updateShiftLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_SHIFT_LOADING_CLEAR
    }
};

export const cancelAddShift = () => {
    return {
        type: actionTypes.CANCEL_ADD_SHIFT
    }
};

export const cancelUpdateShift = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_SHIFT
    }
};

export const insertShift = (payload) => {
    return {
        type: actionTypes.ADD_SHIFT,
        payload: payload
    }
};

export const changeShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SHIFT,
        payload: payload
    }
};

export const changeSingleShift = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_SHIFT,
        payload: payload
    }
};

export const removeShift = (payload) => {
    return {
        type: actionTypes.REMOVE_SHIFT,
        payload: payload
    }
};

//#endregion

//#region Shifts Methods

export const fetchShifts = (payload) => {
    return async (dispatch, getState) => {
        const shiftsPath = '/shifts/bulk/get';
        try {
            dispatch(fetchShiftsStart());

            const state = getState();
            const shiftsState = {...state.shifts};
            let pagination = {...shiftsState.pagination};
            let searchParams = {...shiftsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const shiftsRes = await axiosAuthenticated.post(shiftsPath, {...searchParams});
            if (shiftsRes && shiftsRes.status === 200) {
                const shifts = shiftsRes.data.data;

                // Read total count from server
                pagination.total = shiftsRes.data.totalCount;
                pagination.current = shiftsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchShiftsSuccess({
                    records: shifts,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchShiftsFail({error: error.message}));
        }
    }
};

export const fetchShift = (id) => {
    return async (dispatch) => {
        const shiftsPath = '/shifts';
        try {
            dispatch(fetchShiftStart());

            const shiftsRes = await axiosAuthenticated.get(shiftsPath + `/${id}`);
            if (shiftsRes && shiftsRes.status === 200) {
                const shift = shiftsRes.data;

                dispatch(fetchShiftSuccess({record: shift}));
            }

        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchShiftFail({error: error.message}));
        }
    }
};

export const addShift = (payload) => {
    return async (dispatch) => {
        const shiftsPath = '/shifts';
        try {
            dispatch(addShiftStart());

            const shiftsRes = await axiosAuthenticated.post(shiftsPath, {...payload});
            if (shiftsRes && shiftsRes.status === 201) {
                const newShift = shiftsRes.data;

                dispatch(insertShift(newShift));

                dispatch(addShiftSuccess());
                dispatch(addShiftLoadingClear());
                dispatch(addShiftErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addShiftFail({error: error.message}));
        }
    }
};

export const updateShift = (shiftId, payload) => {
    return async (dispatch) => {
        const shiftsPath = '/shifts';
        try {
            dispatch(updateShiftStart());

            const shiftsRes = await axiosAuthenticated.put(shiftsPath + `/${shiftId}`, {...payload});
            if (shiftsRes && shiftsRes.status === 200) {
                let updatedShift = shiftsRes.data;

                if (updatedShift.isDeleted === true) {
                    dispatch(removeShift(updatedShift));
                } else {
                    dispatch(changeShift(updatedShift));
                    dispatch(changeSingleShift(updatedShift));
                }

                dispatch(updateShiftSuccess());
                dispatch(updateShiftLoadingClear());
                dispatch(updateShiftErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateShiftFail({error: error.message}));
        }
    }
};

//#endregion