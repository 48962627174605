import React, { useMemo } from 'react';
import {Input, Select, Spin, Alert, InputNumber} from "antd";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import FormItem from '../FormItem/FormItem';
import FormItemDouble from '../FormItemDouble/FormItemDouble';
import FormButtons from '../FormButtons/FormButtons';
import Form from '../Form/Form';
import Fieldset from '../FormFieldset/FormFieldset';
import { removeEmpty, isEquivalent } from '../../shared/objectUtils';
import { isEmpty, isEqual } from 'lodash';

const { Option } = Select;

const EditLinkedAccount = props => {
    const linkedAccount = props.record;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedAccounts.isRecordUpdateLoading);
    const error = useSelector(state => state.linkedAccounts.updateRecordError);
    const states = useSelector(state => state.orchestrator.states);

    const onSubmit = (data) => {
        let payload = {};

        let objectToUpdate = { ...linkedAccount };
        console.log(objectToUpdate);
        for (let [key, value] of Object.entries(data)) {
            let valueToCompare = value !== undefined && value !== null ? value : '';
            let oldValue = objectToUpdate[key] !== undefined && objectToUpdate[key] !== null ? objectToUpdate[key] : '';
            if (value && typeof value === 'object' && Object.keys(value).length > 0) {
                if (oldValue && typeof oldValue === 'object' && Object.keys(oldValue).length > 0) {
                    let cleanedValue = removeEmpty(value);
                    if (!isEquivalent(cleanedValue, oldValue)) {
                        //console.log('not equivalent')
                        console.log(`${key}: new value - ${JSON.stringify(value)}, old value - ${JSON.stringify(oldValue)}`);
                        payload[key] = value;
                    }
                } else {
                    let cleanedValue = removeEmpty(value);
                    console.log(cleanedValue);
                    if (!isEmpty(cleanedValue)) {
                        //console.log('not equivalent')
                        console.log(`${key}: new value - ${JSON.stringify(cleanedValue)}, old value - ${JSON.stringify(oldValue)}`);
                        payload[key] = cleanedValue;
                    }
                }
            } else if (!isEqual(valueToCompare, oldValue)) {
                console.log(`${key}: new value - ${JSON.stringify(valueToCompare)}, old value - ${JSON.stringify(oldValue)}`);
                payload[key] = value;
            }
        }

        console.log(payload);

        if (!isEmpty(payload)) {
            dispatch(actionCreators.updateLinkedAccount(linkedAccount.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLinkedAccount());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLinkedAccountErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const formItemLayoutDouble = {
        labelCol1: { span: 8 },
        wrapperCol1: { span: 8 },
        labelCol2: { span: 0 },
        wrapperCol2: { span: 8 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Company Details</legend>
                        <FormItem {...formItemLayout} label="Company Name" required format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Company Name" />}
                            rules={{ required: false }}
                            name="name"
                            defaultValue={linkedAccount && linkedAccount.name ? linkedAccount.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Customer Id" tooltip="Do you have an identifier for this company outside of this system?" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Customer Id" />}
                            rules={{ required: false }}
                            name="customerId"
                            defaultValue={linkedAccount && linkedAccount.customerId ? linkedAccount.customerId : ''}
                        />
                    </Fieldset>
                    {linkedAccount && linkedAccount.isCarrier && (
                        <Fieldset>
                            <legend>Contract Rate Details</legend>

                            <FormItemDouble {...formItemLayout} label1="Rate Per Mile" label2="Fixed Rate" required1 format="vertical"
                                render1={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Enter the rate per mile in USD $"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        defaultValue={linkedAccount && linkedAccount.carrierRatePerMile ? linkedAccount.carrierRatePerMile : 0}
                                    />
                                )}
                                rules1={{ required: false }}
                                name1="carrierRatePerMile"
                                render2={({ onChange, onBlur, value, name }) => (
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        placeholder="Enter fixed rate in USD $"
                                        style={{ width: '100%' }}
                                        onBlur={onBlur}
                                        onChange={e => { onChange(e); }}
                                        value={value}
                                        name={name}
                                        defaultValue={linkedAccount && linkedAccount.carrierRate ? linkedAccount.carrierRate : 0}
                                    />
                                )}
                                rules2={{ required: false }}
                                name2="carrierRate"
                            />
                        </Fieldset>
                    )}
                    <Fieldset isCollapsable={linkedAccount && linkedAccount.pointOfContact ? false: true} legend="Point of Contact">
                        <FormItem {...formItemLayout} label="Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Name" />}
                            rules={{ required: false }}
                            name="pointOfContact.name"
                            defaultValue={linkedAccount && linkedAccount.pointOfContact && linkedAccount.pointOfContact.name ? linkedAccount.pointOfContact.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="pointOfContact.email"
                            defaultValue={linkedAccount && linkedAccount.pointOfContact && linkedAccount.pointOfContact.email ? linkedAccount.pointOfContact.email : ''}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules1={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1="pointOfContact.phone"
                            defaultValue1={linkedAccount && linkedAccount.pointOfContact && linkedAccount.pointOfContact.phone ? linkedAccount.pointOfContact.phone : ''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                            rules2={{ required: false }}
                            name2="pointOfContact.phoneExt"
                            defaultValue2={linkedAccount && linkedAccount.pointOfContact && linkedAccount.pointOfContact.phoneExt ? linkedAccount.pointOfContact.phoneExt : ''}
                        />
                    </Fieldset>
                    <Fieldset isCollapsable={linkedAccount && (linkedAccount.accountsPayable || linkedAccount.accountsPayableAddress) ? false: true} legend="Accounts Payable">
                        <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                            rules={{ required: false }}
                            name="accountsPayable.name"
                            defaultValue={linkedAccount && linkedAccount.accountsPayable && linkedAccount.accountsPayable.name ? linkedAccount.accountsPayable.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="accountsPayable.email"
                            defaultValue={linkedAccount && linkedAccount.accountsPayable && linkedAccount.accountsPayable.email ? linkedAccount.accountsPayable.email : ''}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules1={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1="accountsPayable.phone"
                            defaultValue1={linkedAccount && linkedAccount.accountsPayable && linkedAccount.accountsPayable.phone ? linkedAccount.accountsPayable.phone : ''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                            rules2={{ required: false }}
                            name2="accountsPayable.phoneExt"
                            defaultValue2={linkedAccount && linkedAccount.accountsPayable && linkedAccount.accountsPayable.phoneExt ? linkedAccount.accountsPayable.phoneExt : ''}
                        />
                        <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                            rules={{ required: false }}
                            name="accountsPayableAddress.streetAddress1"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.streetAddress1 ? linkedAccount.accountsPayableAddress.streetAddress1 : ''}
                        />
                        <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                            rules={{ required: false }}
                            name="accountsPayableAddress.streetAddress2"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.streetAddress2 ? linkedAccount.accountsPayableAddress.streetAddress2 : ''}
                        />
                        <FormItem {...formItemLayout} label="City" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                            rules={{ required: false }}
                            name="accountsPayableAddress.city"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.city ? linkedAccount.accountsPayableAddress.city : ''}
                        />
                        <FormItem {...formItemLayout} label="State" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="accountsPayableAddress.state"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.state ? linkedAccount.accountsPayableAddress.state : undefined}
                        />
                        <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                            rules={{ required: false }}
                            name="accountsPayableAddress.postalCode"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.postalCode ? linkedAccount.accountsPayableAddress.postalCode : ''}
                        />
                        <FormItem {...formItemLayout} label="Country" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Country"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="USA" key="USA">United States</Option>
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="accountsPayableAddress.country"
                            defaultValue={linkedAccount && linkedAccount.accountsPayableAddress && linkedAccount.accountsPayableAddress.country ? linkedAccount.accountsPayableAddress.country : undefined}
                        />
                    </Fieldset>
                    <Fieldset isCollapsable={linkedAccount && (linkedAccount.accountsReceivable || linkedAccount.accountsReceivableAddress) ? false: true} legend="Accounts Receivable">
                        <FormItem {...formItemLayout} label="Person or Factoring Company Name" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Person or Factoring Company Name" />}
                            rules={{ required: false }}
                            name="accountsReceivable.name"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivable && linkedAccount.accountsReceivable.name ? linkedAccount.accountsReceivable.name : ''}
                        />
                        <FormItem {...formItemLayout} label="Email" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Email" />}
                            rules={{
                                required: false,
                                pattern: {
                                    value: /^undefined|^$|^[a-zA-Z0-9._-]+@([\w-]+\.)+[\w-]+$/i,
                                    message: "Please enter a valid email address"
                                }
                            }}
                            name="accountsReceivable.email"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivable && linkedAccount.accountsReceivable.email ? linkedAccount.accountsReceivable.email : ''}
                        />
                        <FormItemDouble {...formItemLayoutDouble} label1="Phone #" format="horizontal"
                            render1={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Phone #" />}
                            rules1={{
                                required: false,
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Please enter a valid 10 digit phone number with no special characters"
                                }
                            }}
                            name1="accountsReceivable.phone"
                            defaultValue1={linkedAccount && linkedAccount.accountsReceivable && linkedAccount.accountsReceivable.phone ? linkedAccount.accountsReceivable.phone : ''}
                            render2={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Ext" />}
                            rules2={{ required: false }}
                            name2="accountsReceivable.phoneExt"
                            defaultValue2={linkedAccount && linkedAccount.accountsReceivable && linkedAccount.accountsReceivable.phoneExt ? linkedAccount.accountsReceivable.phoneExt : ''}
                        />
                        <FormItem {...formItemLayout} label="Street Address 1" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 1" />}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.streetAddress1"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.streetAddress1 ? linkedAccount.accountsReceivableAddress.streetAddress1 : ''}
                        />
                        <FormItem {...formItemLayout} label="Street Address 2" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Street Address 2" />}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.streetAddress2"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.streetAddress2 ? linkedAccount.accountsReceivableAddress.streetAddress2 : ''}
                        />
                        <FormItem {...formItemLayout} label="City" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="City" />}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.city"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.city ? linkedAccount.accountsReceivableAddress.city : ''}
                        />
                        <FormItem {...formItemLayout} label="State" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a State"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states.map(i => <Option value={i.stateAbbr} key={i.stateAbbr}>{i.stateName}</Option>)}
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.state"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.state ? linkedAccount.accountsReceivableAddress.state : undefined}
                        />
                        <FormItem {...formItemLayout} label="Postal Code" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="Postal Code" />}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.postalCode"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.postalCode ? linkedAccount.accountsReceivableAddress.postalCode : ''}
                        />
                        <FormItem {...formItemLayout} label="Country" format="horizontal"
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Select a Country"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="USA" key="USA">United States</Option>
                                </Select>
                            )}
                            rules={{ required: false }}
                            name="accountsReceivableAddress.country"
                            defaultValue={linkedAccount && linkedAccount.accountsReceivableAddress && linkedAccount.accountsReceivableAddress.country ? linkedAccount.accountsReceivableAddress.country : undefined}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Update Account" />
            </Form>
        </FormProvider>
    );
};

export default EditLinkedAccount;