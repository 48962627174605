import React, { useState, useEffect } from 'react';
import { NavLink, Redirect, withRouter, Link, useLocation } from 'react-router-dom';
import { DownOutlined, MenuOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Layout, Menu, Avatar, Badge, notification, Button, Row, Col } from 'antd';
import { Route, Switch } from "react-router";
import ScrollToTop from "../../hoc/ScrollToTop/ScrollToTop";
import classes from "./DrawerLayout.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHome, faPallet, faTractor, faTruck, faTruckLoading, faUsers, faWarehouse, faScroll, faBell, faUser, faSignOutAlt, faFileAlt, faDesktop, faBoxes, faRoute, faMapMarked, faTruckMoving, faIdCard, faIdBadge, faUserPlus, faCommentAlt, faCalendar, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import * as actionCreators from "../../store/actions/index";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import axios from 'axios';
import irisLogoSmallBlack from '../../assets/img/full-logo-black.png';
import irisLogoSmallWhite from '../../assets/img/full-logo.png';
import pulogixLogoBigWhite from '../../assets/img/BannerIrisFreight@5x-white.png'
import TermsOfUseModal from "../../components/TermsOfUseModal/TermsOfUseModal";
import NotificationsList from '../../components/NotificationsList/NotificationsList';
import MessagesList from '../../components/MessagesList/MessagesList';
import PubNub from 'pubnub';

import Dashboard from "../../views/Dashboard/Dashboard";
import Users from "../../views/Users/Users";
import TrailerTypes from "../../views/TrailerTypes/TrailerTypes";
import States from "../../views/States/States";
import Shippers from "../../views/Shippers/Shippers";
import Shipper from "../../views/Shipper/Shipper";
import Receivers from "../../views/Receivers/Receivers";
import Receiver from "../../views/Receiver/Receiver";
import RatingTypes from "../../views/RatingTypes/RatingTypes";
import Producer from "../../views/Producer/Producer";
import Producers from "../../views/Producers/Producers";
import Load from "../../views/Load/Load";
import Loads from "../../views/Loads/Loads";
import LoadRequests from "../../views/LoadRequests/LoadRequests";
import OpenLoads from "../../views/OpenLoads/OpenLoads";
import BookedLoads from "../../views/BookedLoads/BookedLoads";
import InTransitLoads from "../../views/InTransitLoads/InTransitLoads";
import CompletedLoads from "../../views/CompletedLoads/CompletedLoads";
import LineItemTypes from "../../views/LineItemTypes/LineItemTypes";
import Carriers from "../../views/Carriers/Carriers";
import Carrier from "../../views/Carrier/Carrier";
import Claims from "../../views/Claims/Claims";
import Documents from "../../views/Documents/Documents";
import Notifications from "../../views/Notifications/Notifications";
import Messages from "../../views/Messages/Messages";
import NewLoad from "../../views/NewLoad/NewLoad";
import Accounts from "../../views/Accounts/Accounts";
import NewAccount from "../../views/NewAccount/NewAccount";
import Contents from "../../views/Contents/Contents";
import Profile from "../../views/Profile/Profile";
import Configurations from "../../views/Configurations/Configurations";
import Employee from "../../views/Employee/Employee";
import Commodities from '../../views/Commodities/Commodities';
import LoadLanes from '../../views/LoadLanes/LoadLanes';
import Locations from '../../views/Locations/Locations';
import Employees from '../../views/Employees/Employees';
import Drivers from '../../views/Drivers/Drivers';
import Assets from '../../views/Assets/Assets';
import NoAccess from '../../views/NoAccess/NoAccess';
import ChangePassword from '../../views/ChangePassword/ChangePassword';
import AccountRegistrations from '../../views/AccountRegistrations/AccountRegistrations';
import User from '../../views/User/User';
import LinkedAccounts from '../../views/LinkedAccounts/LinkedAccounts';
import LinkedShippers from '../../views/LinkedShippers/LinkedShippers';
import LinkedCarriers from '../../views/LinkedCarriers/LinkedCarriers';
import LinkedReceivers from '../../views/LinkedReceivers/LinkedReceivers';
import LinkedProducers from '../../views/LinkedProducers/LinkedProducers';
import LinkedAccount from '../../views/LinkedAccount/LinkedAccount';
import LoadLane from '../../views/LoadLane/LoadLane';
import Account from '../../views/Account/Account';
import Claim from '../../views/Claim/Claim';
import Asset from '../../views/Asset/Asset';
import Features from '../../views/Features/Features';
import Services from '../../views/Services/Services';
import LoadList from '../../views/LoadList/LoadList';
import Dispatch from '../../views/Dispatch/Dispatch';
import Shifts from '../../views/Shifts/Shifts';
import Shift from '../../views/Shift/Shift';
import ScheduledShifts from '../../views/ScheduledShifts/ScheduledShifts';
import LinkedLocations from '../../views/LinkedLocations/LinkedLocations';
import LinkedLocation from '../../views/LinkedLocation/LinkedLocation';
import LocationView from '../../views/Location/Location';
import LinkedLoadLanes from '../../views/LinkedLoadLanes/LinkedLoadLanes';
import LinkedLoadLane from '../../views/LinkedLoadLane/LinkedLoadLane';
import AdminLoads from '../../views/AdminLoads/AdminLoads';
import Staff from '../../views/Staff/Staff';
import DispatchNew from "../../views/DispatchNew/DispatchNew";
import ScheduledLoads from "../../views/ScheduledLoads/ScheduledLoads";
import DispatchShipper from "../../views/DispatchShipper/DispatchShipper";
import Bids from "../../views/Bids/Bids";
import DispatchAdmin from "../../views/DispatchAdmin/DispatchAdmin";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;
const version = process.env.REACT_APP_VERSION;
const environment = process.env.REACT_APP_ENVIRONMENT;

const RoleProtectedRoute = ({ rolesCheck, isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                rolesCheck === true ? (
                    <Component {...props} />
                ) : (isAuthenticated === true ? (
                    <Redirect
                        to={{
                            pathname: "/noaccess",
                            state: { from: props.location }
                        }}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    ))
            }
        />
    );
};

const EntityProtectedRoute = ({ entitiesCheck, isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                entitiesCheck === true ? (
                    <Component {...props} />
                ) : (isAuthenticated === true ? (
                    <Redirect
                        to={{
                            pathname: "/noaccess",
                            state: { from: props.location }
                        }}
                    />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    ))
            }
        />
    );
};

const ProtectedRoute = ({ isAuthenticated, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated === true ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
};

const DrawerLayout = props => {
    const CancelToken = axios.CancelToken;
    let cancel;

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const entityType = useSelector(state => state.auth.entityType);
    const entityId = useSelector(state => state.auth.entityId);
    const isAdmin = useSelector(state => state.auth.isAdmin);
    const isStaff = useSelector(state => state.auth.isStaff);
    const isVP = useSelector(state => state.auth.isVP);
    const isDirector = useSelector(state => state.auth.isDirector);
    const isManager = useSelector(state => state.auth.isManager);
    const profileImageId = useSelector(state => state.auth.profileImageId);
    const firstName = useSelector(state => state.auth.firstName);
    const lastName = useSelector(state => state.auth.lastName);
    const location = useLocation();
    const userId = useSelector(state => state.auth.userId);
    const notifications = useSelector(state => state.notifications.records);
    const yourAccountUsers = useSelector(state => state.yourAccountUsers.records);
    const pubNubSubKey = useSelector(state => state.keys.pubNubSubKey);
    const pubNubPubKey = useSelector(state => state.keys.pubNubPubKey);

    const [imageUrl, setImageUrl] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [showNotificationsList, setShowNotificationsList] = useState(false);
    const [showMessagesList, setShowMessagesList] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPath, setCurrentPath] = useState(null);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
    const [accountName, setAccountName] = useState(null);
    const [role, setRole] = useState(null);

    const addNotification = (notification) => {
        //console.log(notification);
        dispatch(actionCreators.addNotification(notification));
    };

    const addMessage = (message) => {
        //console.log(message);
        dispatch(actionCreators.addMessage(message));

        if (message.senderId !== userId) {
            notification.open({
                message: message.subject,
                description: message.message,
                placement: 'topRight',
                key: message.id,
                duration: 5,
                onClick: () => { props.history.push({ pathname: "/loads/" + message.loadId, hash: 'messages' }); }
            });
        }
    };

    const addLoadEvent = (loadEvent, entityType) => {
        //console.log(loadEvent);
        // default case - call dispatch to re-load the load/loads
        if (loadEvent.eventStatus === "SUCCESS") {
            let loadStatus = null;
            let stopStatus = null;
            let refreshInvoiceLineItems = false;
            let refreshTransactions = false;
            let refreshLoad = false;
            let refreshLoads = false;
            let refreshStops = false;
            let refreshClaims = false;
            let refreshLoadCancellations = false;
            let refreshCarrierRejections = false;
            switch (loadEvent.eventType) {
                case 'LOAD_CREATED':
                    loadStatus = 'PENDING';
                    break;
                case 'LOAD_APPROVED':
                    loadStatus = 'APPROVED';
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_REQUEST_TO_CARRIER':
                    loadStatus = 'PENDING_RATE_CONFIRMATION';
                    break;
                case 'LOAD_REQUEST_TO_CARRIER_REJECTED':
                    loadStatus = 'APPROVED';
                    refreshLoad = true;
                    refreshLoads = true;
                    refreshCarrierRejections = true;
                    break;
                case 'LOAD_REQUEST_TO_CARRIER_APPROVED':
                    loadStatus = "BOOKED";
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_BOOKED':
                    loadStatus = "BOOKED";
                    break;
                case 'LOAD_SCHEDULED':
                    loadStatus = "SCHEDULED";
                    refreshLoads = true;
                    break;
                case 'LOAD_STARTED':
                    loadStatus = "IN_TRANSIT";
                    break;
                case 'LOAD_ARRIVING':
                    loadStatus = "IN_TRANSIT";
                    stopStatus = "ARRIVING";
                    break;
                case 'LOAD_ARRIVED':
                    loadStatus = "AT_STOP";
                    stopStatus = "ARRIVED";
                    refreshStops = true;
                    break;
                case 'LOAD_ARRIVED_LATE':
                    loadStatus = "AT_STOP";
                    stopStatus = "ARRIVED";
                    refreshStops = true;
                    break;
                case 'LOAD_PICK_UP_LOADING':
                    loadStatus = "AT_STOP";
                    stopStatus = "LOADING";
                    break;
                case 'LOAD_PICK_UP_LOADED':
                    loadStatus = "AT_STOP";
                    stopStatus = "LOADED";
                    break;
                case 'LOAD_PICK_UP_COMPLETED':
                    loadStatus = "IN_TRANSIT";
                    stopStatus = "COMPLETED";
                    refreshStops = true;
                    break;
                case 'LOAD_DROP_OFF_UNLOADING':
                    loadStatus = "AT_STOP";
                    stopStatus = "UNLOADING";
                    break;
                case 'LOAD_DROP_OFF_UNLOADED':
                    loadStatus = "AT_STOP";
                    stopStatus = "UNLOADED";
                    break;
                case 'LOAD_DROP_OFF_COMPLETED':
                    loadStatus = "IN_TRANSIT";
                    stopStatus = "COMPLETED";
                    refreshStops = true;
                    break;
                case 'LOAD_COMPLETED':
                    loadStatus = "COMPLETED";
                    refreshLoad = true;
                    refreshLoads = true;
                    break;
                case 'LOAD_CLOSED':
                    loadStatus = "CLOSED";
                    break;
                case 'LOAD_REOPENED':
                    loadStatus = "REOPENED";
                    break;
                case 'LOAD_CANCELLED':
                    refreshLoad = true;
                    refreshLoads = true;
                    refreshLoadCancellations = true;
                    //loadStatus = "CANCELLED" || "APPROVED";
                    break;
                case 'LOAD_TONU':
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_LAYOVER_STARTED':
                    loadStatus = "IN_TRANSIT";
                    stopStatus = "LAYOVER";
                    break;
                case 'REST_STOP_ADDED':
                    stopStatus = "PENDING";
                    refreshStops = true;
                    break;
                case 'REST_STOP_STARTED':
                    loadStatus = "AT_STOP";
                    stopStatus = "ARRIVED";
                    refreshStops = true;
                    break;
                case 'REST_STOP_ENDED':
                    loadStatus = "IN_TRANSIT";
                    stopStatus = "COMPLETED";
                    refreshStops = true;
                    break;
                case 'LOAD_PICK_UP_DETENTION_STARTED':
                    refreshStops = true;
                    break;
                case 'LOAD_PICK_UP_DETENTION_ENDED':
                    refreshStops = true;
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_DROP_OFF_DETENTION_STARTED':
                    refreshStops = true;
                    break;
                case 'LOAD_DROP_OFF_DETENTION_ENDED':
                    refreshStops = true;
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_BOL_CHANGED':
                    break;
                case 'LOAD_LOCATION_TURNED_OFF':
                    break;
                case 'LOAD_STOP_DRIVER_ASSIST_ADDED':
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_STOP_LUMPER_FEE_ADDED':
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_CLAIM_OPENED':
                    refreshClaims = true;
                    break;
                case 'LOAD_CLAIM_CLOSED':
                    refreshClaims = true;
                    break;
                case 'LOAD_CLAIM_INSPECTION':
                    refreshClaims = true;
                    break;
                case 'LOAD_CLAIM_ADJUSTMENT':
                    refreshClaims = true;
                    refreshInvoiceLineItems = true;
                    break;
                case 'LOAD_CLAIM_REOPENED':
                    refreshClaims = true;
                    refreshLoad = true;
                    refreshLoads = true;
                    break;
                case 'LOAD_UPDATED':
                    if (loadEvent.changeType === 'STOPS_UPDATED') {
                        refreshStops = true;
                        refreshInvoiceLineItems = true;
                        refreshLoad = true;
                        refreshLoads = true;
                    } else if (loadEvent.changeType === 'INVOICE_LINE_ITEMS_UPDATED') {
                        refreshInvoiceLineItems = true;
                    } else if (loadEvent.changeType === 'TRANSACTIONS_UPDATED') {
                        refreshTransactions = true;
                    } else if (loadEvent.changeType === 'LOAD_UPDATED') {
                        refreshLoad = true;
                        refreshLoads = true;
                        refreshInvoiceLineItems = true;
                    } else if (loadEvent.changeType === 'DRIVERS_UPDATED') {
                        refreshLoad = true;
                        refreshLoads = true;
                    } else if (loadEvent.changeType === 'ASSETS_UPDATED') {
                        refreshLoad = true;
                        refreshLoads = true;
                    } else if (loadEvent.changeType === 'CARRIER_UPDATED') {
                        refreshLoad = true;
                        refreshLoads = true;
                    } else if (loadEvent.changeType === 'CLAIMS_UPDATED') {
                        refreshClaims = true;
                    } else if (loadEvent.changeType === 'LOAD_CANCELLATIONS_UPDATED') {
                        refreshLoadCancellations = true;
                    } else if (loadEvent.changeType === 'CARRIER_REJECTIONS_UPDATED') {
                        refreshCarrierRejections = true;
                    } else if (loadEvent.changeType === 'LOAD_CLAIM_OPENED') {
                        refreshClaims = true;
                    } else if (loadEvent.changeType === 'LOAD_CLAIM_CLOSED') {
                        refreshClaims = true;
                    } else if (loadEvent.changeType === 'LOAD_CLAIM_INSPECTION') {
                        refreshClaims = true;
                    } else if (loadEvent.changeType === 'LOAD_CLAIM_REOPENDED') {
                        refreshClaims = true;
                    } else if (loadEvent.changeType === 'LOAD_CLAIM_ADJUSTMENT') {
                        refreshInvoiceLineItems = true;
                    }
                    break;
                default:
                    refreshLoad = true;
                    refreshLoads = true;
            }

            dispatch(actionCreators.updateLoadStatus(loadEvent.loadId, loadEvent.loadStatus));
            dispatch(actionCreators.updateLoadStatusAndLoadLists(loadEvent.loadId, loadEvent.stopId, loadEvent.loadStatus, loadEvent.stopStatus, loadEvent.eventType, entityType, refreshLoad, refreshLoads, refreshStops, refreshInvoiceLineItems, refreshTransactions, refreshClaims, refreshLoadCancellations, refreshCarrierRejections));
        } else {
            dispatch(actionCreators.fetchLoadWithIncludes(loadEvent.loadId));
        }
    };

    const signOut = () => {
        dispatch(actionCreators.completeLogout());
        props.history.push("/");
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleNotificationsList = () => {
        setShowNotificationsList(!showNotificationsList);
    };

    const toggleMessagesList = () => {
        setShowMessagesList(!showMessagesList);
    };

    const onCollapse = () => {
        setCollapsed(!collapsed);
    };

    const routes = (isAuthenticated, entityType, isStaff, isVP, isAdmin) => {
        return (
            <Switch>
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/carriers/:carrierId" component={Carrier} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/carriers" component={Carriers} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/shippers/:shipperId" component={Shipper} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/shippers" component={Shippers} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/producers/:producerId" component={Producer} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/producers" component={Producers} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/receivers/:receiverId" component={Receiver} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/receivers" component={Receivers} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/newAccount" component={NewAccount} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/accounts" component={Accounts} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/accounts/:id" component={Account} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/claims" component={Claims} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/claims/:id" component={Claim} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/documents" component={Documents} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && (isAdmin === true || isVP === true))} path="/contents" component={Contents} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/configurations" component={Configurations} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/lineItemTypes" component={LineItemTypes} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/ratingTypes" component={RatingTypes} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/states" component={States} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/trailerTypes" component={TrailerTypes} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && (isAdmin === true || isVP === true))} path="/users" component={Users} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && (isAdmin === true || isVP === true))} path="/users/:userProfileId" component={User} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && (isAdmin === true || isVP === true))} path="/staff" component={Staff} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && (isAdmin === true || isVP === true))} path="/staff/:userProfileId" component={User} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/accountRegistrations" component={AccountRegistrations} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isStaff === true)} path="/importAccount/:accountRegistrationId" component={NewAccount} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/features" component={Features} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/services" component={Services} />
                <RoleProtectedRoute exact isAuthenticated={isAuthenticated === true} rolesCheck={(entityType === "STAFF" && isAdmin === true)} path="/adminLoads" component={AdminLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/commodities" component={Commodities} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER")} path="/loadLanes" component={LoadLanes} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER")} path="/loadLanes/:id" component={LoadLane} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER")} path="/locations" component={Locations} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER")} path="/locations/:id" component={LocationView} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/linkedLocations" component={LinkedLocations} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/linkedLocations/:id" component={LinkedLocation} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/linkedLoadLanes" component={LinkedLoadLanes} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/linkedLoadLanes/:id" component={LinkedLoadLane} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/employees" component={Employees} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/employees/:accountUserId" component={Employee} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/drivers" component={Drivers} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/drivers/:accountUserId" component={Employee} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/assets" component={Assets} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/assets/:id" component={Asset} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/newLoad" component={NewLoad} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/loads/:loadId" component={Load} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF")} path="/loads" component={LoadList} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/loadRequests" component={LoadRequests} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/dispatch-all" component={Dispatch} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/dispatch" component={DispatchNew} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER")} path="/dispatch-shipper" component={DispatchShipper} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF")} path="/dispatch-admin" component={DispatchAdmin} />

                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "CARRIER")} path="/bids" component={Bids} />

                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/openLoads" component={OpenLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/bookedLoads" component={BookedLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/scheduledLoads" component={ScheduledLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/intransitLoads" component={InTransitLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER")} path="/completedLoads" component={CompletedLoads} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedAccounts" component={LinkedAccounts} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedAccounts/:id" component={LinkedAccount} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedShippers" component={LinkedShippers} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedCarriers" component={LinkedCarriers} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedReceivers" component={LinkedReceivers} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/linkedProducers" component={LinkedProducers} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/shifts" component={Shifts} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/shifts/:id" component={Shift} />
                <EntityProtectedRoute exact isAuthenticated={isAuthenticated === true} entitiesCheck={entityType && (entityType === "STAFF" || entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "RECEIVER" || entityType === "PRODUCER")} path="/schedule" component={ScheduledShifts} />
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/profile" component={Profile} />
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/changePassword" component={ChangePassword} />
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/notifications" component={Notifications} />
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/messages" component={Messages} />
                <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/dashboard" component={Dashboard} />
                {/* <ProtectedRoute exact isAuthenticated={isAuthenticated === true} path="/" component={Dashboard} /> */}
                <Route exact path="/">
                    {isAuthenticated === true ? <Redirect to="/dashboard" /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />}
                </Route>
                <Route exact path="/noaccess" component={NoAccess} />
                <ProtectedRoute isAuthenticated={isAuthenticated === true} render={() => <h1>Not found</h1>} />
            </Switch>
        );
    };

    useEffect(() => {
        // console.log('fetching notifications and messages...');
        if (isAuthenticated === true && entityType !== null && entityId !== null && userId !== null) {
            dispatch(actionCreators.fetchNotifications(entityType, entityId, userId));
            dispatch(actionCreators.fetchMessages(entityType, entityId, userId, 10));
        }
    }, [isAuthenticated, entityType, entityId, userId]);

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const basePath = pathParts[1];
        // console.log(basePath);
        setCurrentPath('/' + basePath);
    }, [location]);

    useEffect(() => {
        //console.log(profileImageId);
        if (profileImageId !== undefined && profileImageId !== null) {
            axiosAuthenticated.get('/documents/' + profileImageId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [profileImageId]);

    useEffect(() => {
        let pubNub = null;
        if (isAuthenticated === true && userId !== undefined && userId !== null && entityType !== undefined && entityType !== null && pubNubSubKey !== undefined && pubNubSubKey !== null && pubNubPubKey !== undefined && pubNubPubKey !== null) {
            console.log("Setting up PubNub!");

            let pubnubConfig = {
                publishKey: pubNubPubKey,
                subscribeKey: pubNubSubKey,
                ssl: true,
                uuid: userId,
                heartbeatInterval: 0
                //logVerbosity: true
            };

            //console.log(pubnubConfig);

            if (pubNub === null) {
                pubNub = new PubNub(pubnubConfig);

                pubNub.addListener({
                    status: function (statusEvent) {
                        console.log(statusEvent);
                        if (statusEvent.category === "PNConnectedCategory") {
                            if (statusEvent.affectedChannels !== undefined && statusEvent.affectedChannels !== null && statusEvent.affectedChannels.length > 0) {
                                statusEvent.affectedChannels.forEach((channelName) => {
                                    console.log(`Connected to ${channelName} PubNub!`);
                                });
                            }
                        }
                    },
                    message: function (msg) {
                        console.log('Message from PubNub:');
                        console.log(msg);
                        let channelType = msg.channel.split('_')[2];
                        if (channelType === 'events') {
                            console.log(msg.message);
                            if (msg.message) {
                                addLoadEvent(msg.message, entityType);
                            }
                        } else if (channelType === 'notifications') {
                            console.log(msg.message);
                            if (msg.message) {
                                addNotification(msg.message);
                            }
                        } else if (channelType === 'messages') {
                            console.log(msg.message);
                            if (msg.message) {
                                addMessage(msg.message);
                            }
                        }
                    }
                });

                let channelNames = [];
                if (entityType === "STAFF") {
                    channelNames.push(`${process.env.REACT_APP_LOAD_NOTIFICATIONS_CHANNEL}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_NOTIFICATIONS_CHANNEL}_${userId}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_MESSAGES_CHANNEL}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_MESSAGES_CHANNEL}_${userId}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_EVENTS_CHANNEL}`);
                } else if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER" || entityType === "DRIVER") {
                    channelNames.push(`${process.env.REACT_APP_LOAD_MESSAGES_CHANNEL}_${userId}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_MESSAGES_CHANNEL}_${userId}`);
                    channelNames.push(`${process.env.REACT_APP_LOAD_EVENTS_CHANNEL}`);
                }

                pubNub.subscribe({
                    channels: [...channelNames],
                    triggerEvents: true,
                    //withPresence: false,
                    //autoload: 100
                });
            }
        } else {
            if (pubNub !== null) {
                console.log("Shutting down PubNub!");
                pubNub.unsubscribeAll();
            }
        }

        return function cleanup() {
            if (pubNub !== null) {
                console.log("Shutting down PubNub!");
                pubNub.unsubscribeAll();
            }
        }
    }, [isAuthenticated, userId, entityType, pubNubSubKey, pubNubPubKey]);

    useEffect(() => {
        if (isAuthenticated === true) {
            if (userId !== undefined && userId !== null && notifications !== undefined && notifications !== null) {
                const localUnreadNotificationCount = notifications.filter(n => {
                    return !n.read.some(r => { return r.readBy === userId; });
                }).length;

                setUnreadNotificationCount(localUnreadNotificationCount);
            }
        }
    }, [notifications, userId, isAuthenticated]);

    useEffect(() => {
        if (entityType !== 'STAFF' && yourAccountUsers !== undefined && yourAccountUsers !== null && yourAccountUsers.length > 0) {
            let yourAccountUser = yourAccountUsers[0];
            if (yourAccountUser.account !== undefined && yourAccountUser.account !== null) {
                setAccountName(yourAccountUser.account.name);
            } else {
                setAccountName(null);
            }
        } else {
            setAccountName(null);
        }
    }, [yourAccountUsers, entityType]);

    useEffect(() => {
        if (isAdmin === true) {
            setRole("Admin");
        } else if (isVP === true) {
            setRole("VP");
        } else if (isDirector === true) {
            setRole("Director");
        } else if (isManager === true) {
            setRole("Manager");
        } else if (isStaff === true) {
            setRole("Staff");
        }
    }, [isAdmin, isVP, isDirector, isManager, isStaff]);

    if (entityType === "SHIPPER") {
        return (
            <Layout>
                <Header className={classes.header + ' ' + classes.darkMenu}>
                    <div className={classes.leftMenu + ' ' + classes.darkMenu}>
                        <Link to="/">
                            <img src={pulogixLogoBigWhite} className={classes.topLogo} alt="PULOGIX" />
                        </Link>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Menu className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="/dashboard">
                                <span>Dashboard</span>
                                <NavLink to={{ pathname: '/dashboard', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="loads" title={<span>Loads</span>}>
                                <Menu.Item key="/dispatch-shipper">
                                    <span>Open Loads</span>
                                    <NavLink to={{ pathname: '/dispatch-shipper', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/bookedLoads">
                                    <span>Booked Loads</span>
                                    <NavLink to={{ pathname: '/bookedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/scheduledLoads">
                                    <span>Scheduled Loads</span>
                                    <NavLink to={{ pathname: '/scheduledLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/intransitLoads">
                                    <span>In Transit Loads</span>
                                    <NavLink to={{ pathname: '/intransitLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/completedLoads">
                                    <span>Completed Loads</span>
                                    <NavLink to={{ pathname: '/completedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="/dispatch-shipper">
                                <span>Dispatch</span>
                                <NavLink to={{ pathname: '/dispatch-shipper', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="customers" title={<span>CRM</span>}>
                                <Menu.Item key="/linkedCarriers">
                                    <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: '10px' }} />
                                    <span>Carriers</span>
                                    <NavLink to={{ pathname: '/linkedCarriers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/linkedReceivers">
                                    <FontAwesomeIcon className="anticon" icon={faWarehouse} style={{ marginRight: '10px' }} />
                                    <span>Receivers</span>
                                    <NavLink to={{ pathname: '/linkedReceivers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/linkedProducers">
                                    <FontAwesomeIcon className="anticon" icon={faTractor} style={{ marginRight: '10px' }} />
                                    <span>Producers</span>
                                    <NavLink to={{ pathname: '/linkedProducers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu key="backOffice" title={<span>Back Office</span>}>
                                <Menu.Item key="/employees">
                                    <FontAwesomeIcon className="anticon" icon={faIdBadge} style={{ marginRight: '10px' }} />
                                    <span>Employees</span>
                                    <NavLink to={{ pathname: '/employees', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/schedule">
                                    <FontAwesomeIcon className="anticon" icon={faCalendar} style={{ marginRight: '10px' }} />
                                    <span>Schedule</span>
                                    <NavLink to={{ pathname: '/schedule', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/shifts">
                                    <FontAwesomeIcon className="anticon" icon={faCalendarCheck} style={{ marginRight: '10px' }} />
                                    <span>Shifts</span>
                                    <NavLink to={{ pathname: '/shifts', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/loadLanes">
                                    <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: '10px' }} />
                                    <span>Lanes</span>
                                    <NavLink to={{ pathname: '/loadLanes', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/locations">
                                    <FontAwesomeIcon className="anticon" icon={faMapMarked} style={{ marginRight: '10px' }} />
                                    <span>Locations</span>
                                    <NavLink to={{ pathname: '/locations', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/commodities">
                                    <FontAwesomeIcon className="anticon" icon={faBoxes} style={{ marginRight: '10px' }} />
                                    <span>Commodities</span>
                                    <NavLink to={{ pathname: '/commodities', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                        <Menu style={{display: "flex", justifyContent: "flex-end"}} className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="notifications" onClick={toggleNotificationsList} style={{ float: 'right' }}>
                                <Badge count={unreadNotificationCount} style={{ boxShadow: 'none' }}>
                                    <FontAwesomeIcon icon={faBell} size="lg" className="anticon" style={{color: '#ffffff'}} />
                                </Badge>
                            </Menu.Item>
                            <Menu.Item key="messages" onClick={toggleMessagesList} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faCommentAlt} size="lg" className="anticon" />
                            </Menu.Item>
                            <SubMenu style={{ float: 'right' }}
                                 title={
                                     <Row>
                                         <Col>{imageUrl ? <Avatar src={imageUrl} /> : <FontAwesomeIcon icon={faUser} size="lg" />}</Col>
                                         <Col className={classes.nameAndAccountName}><div><span>{firstName} {lastName}</span></div><div><span>{accountName}</span></div></Col>
                                         <Col><span><DownOutlined /></span></Col>
                                     </Row>
                                 }
                            >
                                <Menu.Item key="/profile"><span>Profile</span><NavLink to={{ pathname: '/profile', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                <Menu.Item key="/changePassword"><span>Change Password</span><NavLink to={{ pathname: '/changePassword', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="signout" onClick={signOut}>Sign Out</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key='/termsOfService' onClick={toggleModal} className={classes.termsOfService}><span>Terms of Service and Privacy Policy</span></Menu.Item>
                                <Menu.Item key="copyright" disabled={true} className={classes.copyright}>
                                    <div><span>Pulogix ©2022</span></div>
                                    <div><span>Version {version}</span></div>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </div>
                </Header>
                <Content className={classes.content}>
                    <ScrollToTop />
                    {routes(isAuthenticated, entityType, isStaff, isVP, isAdmin)}
                </Content>
                <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
                <NotificationsList onClose={toggleNotificationsList} visible={showNotificationsList} />
                <MessagesList onClose={toggleMessagesList} visible={showMessagesList} />
            </Layout>
        );
    } else if (entityType === "CARRIER") {
        return (
            <Layout>
                <Header className={classes.header + ' ' + classes.darkMenu}>
                    <div className={classes.leftMenu + ' ' + classes.darkMenu}>
                        <Link to="/">
                            <img src={pulogixLogoBigWhite} className={classes.topLogo} alt="PULOGIX" />
                        </Link>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Menu className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="/dashboard">
                                <span>Dashboard</span>
                                <NavLink to={{ pathname: '/dashboard', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="loads" title={<span>Loads</span>}>
                                <Menu.Item key="/loadRequests">
                                    <span>Load Requests</span>
                                    <NavLink to={{ pathname: '/loadRequests', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/dispatch">
                                    <span>Open Loads</span>
                                    <NavLink to={{ pathname: "/dispatch", }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/bookedLoads">
                                    <span>Booked Loads</span>
                                    <NavLink to={{ pathname: '/bookedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/intransitLoads">
                                    <span>In Transit Loads</span>
                                    <NavLink to={{ pathname: '/intransitLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/completedLoads">
                                    <span>Completed Loads</span>
                                    <NavLink to={{ pathname: '/completedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="/dispatch">
                                <span>Dispatch</span>
                                <NavLink to={{ pathname: '/dispatch', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <Menu.Item key="/bids">
                                <span>Bids</span>
                                <NavLink to={{ pathname: '/bids', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="customers" title={<span>CRM</span>}>
                                <Menu.Item key="/linkedShippers">
                                    <span>Shippers</span>
                                    <NavLink to={{ pathname: '/linkedShippers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/linkedReceivers">
                                    <span>Receivers</span>
                                    <NavLink to={{ pathname: '/linkedReceivers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/linkedProducers">
                                    <span>Producers</span>
                                    <NavLink to={{ pathname: '/linkedProducers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="/assets">
                                <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: '10px' }} />
                                <span>Assets</span>
                                <NavLink to={{ pathname: '/assets', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <Menu.Item key="/drivers">
                                <FontAwesomeIcon className="anticon" icon={faIdCard} style={{ marginRight: '10px' }} />
                                <span>Drivers</span>
                                <NavLink to={{ pathname: '/drivers', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <Menu.Item key="/linkedLoadLanes">
                                <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: '10px' }} />
                                <span>Lanes</span>
                                <NavLink to={{ pathname: '/linkedLoadLanes', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="backOffice" title={<span>Back Office</span>}>
                                <Menu.Item key="/employees">
                                    <FontAwesomeIcon className="anticon" icon={faIdBadge} style={{ marginRight: '10px' }} />
                                    <span>Employees</span>
                                    <NavLink to={{ pathname: '/employees', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/schedule">
                                    <FontAwesomeIcon className="anticon" icon={faCalendar} style={{ marginRight: '10px' }} />
                                    <span>Schedule</span>
                                    <NavLink to={{ pathname: '/schedule', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/shifts">
                                    <FontAwesomeIcon className="anticon" icon={faCalendarCheck} style={{ marginRight: '10px' }} />
                                    <span>Shifts</span>
                                    <NavLink to={{ pathname: '/shifts', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/linkedLocations">
                                    <FontAwesomeIcon className="anticon" icon={faMapMarked} style={{ marginRight: '10px' }} />
                                    <span>Locations</span>
                                    <NavLink to={{ pathname: '/linkedLocations', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/commodities">
                                    <FontAwesomeIcon className="anticon" icon={faBoxes} style={{ marginRight: '10px' }} />
                                    <span>Commodities</span>
                                    <NavLink to={{ pathname: '/commodities', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                        <Menu style={{display: "flex", justifyContent: "flex-end"}} className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="notifications" onClick={toggleNotificationsList} style={{ float: 'right' }}>
                                <Badge count={unreadNotificationCount} style={{ boxShadow: 'none' }}>
                                    <FontAwesomeIcon icon={faBell} size="lg" className="anticon" style={{color: '#ffffff'}} />
                                </Badge>
                            </Menu.Item>
                            <Menu.Item key="messages" onClick={toggleMessagesList} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faCommentAlt} size="lg" className="anticon" />
                            </Menu.Item>
                            <SubMenu style={{ float: 'right' }}
                                    title={
                                        <Row>
                                            <Col>{imageUrl ? <Avatar src={imageUrl} /> : <FontAwesomeIcon icon={faUser} size="lg" />}</Col>
                                            <Col className={classes.nameAndAccountName}><div><span>{firstName} {lastName}</span></div><div><span>{accountName}</span></div></Col>
                                            <Col><span><DownOutlined /></span></Col>
                                        </Row>
                                    }
                                >
                                    <Menu.Item key="/profile"><span>Profile</span><NavLink to={{ pathname: '/profile', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                    <Menu.Item key="/changePassword"><span>Change Password</span><NavLink to={{ pathname: '/changePassword', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item key="signout" onClick={signOut}>Sign Out</Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item key='/termsOfService' onClick={toggleModal} className={classes.termsOfService}><span>Terms of Service and Privacy Policy</span></Menu.Item>
                                    <Menu.Item key="copyright" disabled={true} className={classes.copyright}>
                                        <div><span>Pulogix ©2022</span></div>
                                        <div><span>Version {version}</span></div>
                                    </Menu.Item>
                                </SubMenu>
                        </Menu>
                    </div>
                </Header>
                <Content className={classes.content}>
                    <ScrollToTop />
                    {routes(isAuthenticated, entityType, isStaff, isVP, isAdmin)}
                </Content>
                <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
                <NotificationsList onClose={toggleNotificationsList} visible={showNotificationsList} />
                <MessagesList onClose={toggleMessagesList} visible={showMessagesList} />
            </Layout>
        );
    } else if (entityType === "STAFF") {
        return (
            <Layout>
                <Header className={classes.header + ' ' + classes.darkMenu}>
                    <div className={classes.leftMenu + ' ' + classes.darkMenu}>
                        <Link to="/">
                            <img src={pulogixLogoBigWhite} className={classes.topLogo} alt="PULOGIX" />
                        </Link>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Menu className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="/dashboard">
                                <FontAwesomeIcon className="anticon" icon={faHome} style={{ marginRight: '10px' }} />
                                <span>Dashboard</span>
                                <NavLink to={{ pathname: '/dashboard', }} style={{ textDecoration: 'none' }} />
                            </Menu.Item>
                            <SubMenu key="loads" title={<span>Loads</span>}>
                                <Menu.Item key="/loads">
                                    <FontAwesomeIcon className="anticon" icon={faPallet} style={{ marginRight: '10px' }} />
                                    <span>All Loads</span>
                                    <NavLink to={{ pathname: '/loads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/loadRequests">
                                    <FontAwesomeIcon className="anticon" icon={faDesktop} style={{ marginRight: '10px' }} />
                                    <span>Load Requests</span>
                                    <NavLink to={{ pathname: '/loadRequests', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/dispatch-admin">
                                    <FontAwesomeIcon className="anticon" icon={faDesktop} style={{ marginRight: '10px' }} />
                                    <span>Open Loads</span>
                                    <NavLink to={{ pathname: '/dispatch-admin', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/bookedLoads">
                                    <FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: '10px' }} />
                                    <span>Booked Loads</span>
                                    <NavLink to={{ pathname: '/bookedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/intransitLoads">
                                    <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: '10px' }} />
                                    <span>In Transit Loads</span>
                                    <NavLink to={{ pathname: '/intransitLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/completedLoads">
                                    <FontAwesomeIcon className="anticon" icon={faPallet} style={{ marginRight: '10px' }} />
                                    <span>Completed Loads</span>
                                    <NavLink to={{ pathname: '/completedLoads', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu key="accounts" title={<span>Accounts</span>}>
                                <Menu.Item key="/accounts">
                                    <FontAwesomeIcon className="anticon" icon={faUsers} style={{ marginRight: '10px' }} />
                                    <span>All Accounts</span>
                                    <NavLink to={{ pathname: '/accounts', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/shippers">
                                    <FontAwesomeIcon className="anticon" icon={faTruckLoading} style={{ marginRight: '10px' }} />
                                    <span>Shippers</span>
                                    <NavLink to={{ pathname: '/shippers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/carriers">
                                    <FontAwesomeIcon className="anticon" icon={faTruck} style={{ marginRight: '10px' }} />
                                    <span>Carriers</span>
                                    <NavLink to={{ pathname: '/carriers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/receivers">
                                    <FontAwesomeIcon className="anticon" icon={faWarehouse} style={{ marginRight: '10px' }} />
                                    <span>Receivers</span>
                                    <NavLink to={{ pathname: '/receivers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/producers">
                                    <FontAwesomeIcon className="anticon" icon={faTractor} style={{ marginRight: '10px' }} />
                                    <span>Producers</span>
                                    <NavLink to={{ pathname: '/producers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/accountRegistrations">
                                    <FontAwesomeIcon className="anticon" icon={faUserPlus} style={{ marginRight: '10px' }} />
                                    <span>Account Applications</span>
                                    <NavLink to={{ pathname: '/accountRegistrations', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu key="backOffice" title={<span>Back Office</span>}>
                                <Menu.Item key="/claims">
                                    <FontAwesomeIcon className="anticon" icon={faScroll} style={{ marginRight: '10px' }} />
                                    <span>Claims</span>
                                    <NavLink to={{ pathname: '/claims', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/documents">
                                    <FontAwesomeIcon className="anticon" icon={faFileAlt} style={{ marginRight: '10px' }} />
                                    <span>Documents</span>
                                    <NavLink to={{ pathname: '/documents', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/commodities">
                                    <FontAwesomeIcon className="anticon" icon={faBoxes} style={{ marginRight: '10px' }} />
                                    <span>Commodities</span>
                                    <NavLink to={{ pathname: '/commodities', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/loadLanes">
                                    <FontAwesomeIcon className="anticon" icon={faRoute} style={{ marginRight: '10px' }} />
                                    <span>Lanes</span>
                                    <NavLink to={{ pathname: '/loadLanes', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/locations">
                                    <FontAwesomeIcon className="anticon" icon={faMapMarked} style={{ marginRight: '10px' }} />
                                    <span>Locations</span>
                                    <NavLink to={{ pathname: '/locations', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/assets">
                                    <FontAwesomeIcon className="anticon" icon={faTruckMoving} style={{ marginRight: '10px' }} />
                                    <span>Assets</span>
                                    <NavLink to={{ pathname: '/assets', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/drivers">
                                    <FontAwesomeIcon className="anticon" icon={faIdCard} style={{ marginRight: '10px' }} />
                                    <span>Drivers</span>
                                    <NavLink to={{ pathname: '/drivers', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                                <Menu.Item key="/employees">
                                    <FontAwesomeIcon className="anticon" icon={faIdBadge} style={{ marginRight: '10px' }} />
                                    <span>Employees</span>
                                    <NavLink to={{ pathname: '/employees', }} style={{ textDecoration: 'none' }} />
                                </Menu.Item>
                            </SubMenu>
                            {(isAdmin === true || isVP === true) && (
                                <SubMenu key="settings" title={<span>
                                <FontAwesomeIcon className="anticon" icon={faCog} style={{ marginRight: '10px' }} />
                                <span>Settings</span> </span>}>
                                    <Menu.Item key="/users">
                                        <span>Users</span>
                                        <NavLink to={{ pathname: '/users', }} style={{ textDecoration: 'none' }} />
                                    </Menu.Item>
                                    <Menu.Item key="/staff">
                                        <span>Staff</span>
                                        <NavLink to={{ pathname: '/staff', }} style={{ textDecoration: 'none' }} />
                                    </Menu.Item>
                                    <Menu.Item key="/contents">
                                        <span>Contents</span>
                                        <NavLink to={{ pathname: '/contents', }} style={{ textDecoration: 'none' }} />
                                    </Menu.Item>
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/configurations">
                                            <span>Configurations</span>
                                            <NavLink to={{ pathname: '/configurations', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/states">
                                            <span>States</span>
                                            <NavLink to={{ pathname: '/states', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/trailerTypes">
                                            <span>Trailer Types</span>
                                            <NavLink to={{ pathname: '/trailerTypes', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/lineItemTypes">
                                            <span>Line Item Types</span>
                                            <NavLink to={{ pathname: '/lineItemTypes', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/ratingTypes">
                                            <span>Rating Types</span>
                                            <NavLink to={{ pathname: '/ratingTypes', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>)}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/features">
                                            <span>Features</span>
                                            <NavLink to={{ pathname: '/features', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/services">
                                            <span>Services</span>
                                            <NavLink to={{ pathname: '/services', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                    {(isAdmin === true) && (
                                        <Menu.Item key="/adminLoads">
                                            <span>All Loads Admin</span>
                                            <NavLink to={{ pathname: '/adminLoads', }} style={{ textDecoration: 'none' }} />
                                        </Menu.Item>
                                    )}
                                </SubMenu>
                            )}
                        </Menu>
                        <Menu style={{display: "flex", justifyContent: "flex-end"}} className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                            <Menu.Item key="notifications" onClick={toggleNotificationsList} style={{ float: 'right' }}>
                                <Badge count={unreadNotificationCount} style={{ boxShadow: 'none' }}>
                                    <FontAwesomeIcon icon={faBell} size="lg" className="anticon" style={{color: '#ffffff'}} />
                                </Badge>
                            </Menu.Item>
                            <Menu.Item key="messages" onClick={toggleMessagesList} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faCommentAlt} size="lg" className="anticon" />
                            </Menu.Item>
                            <SubMenu style={{ float: 'right' }}
                                     title={
                                         <Row>
                                             <Col>{imageUrl ? <Avatar src={imageUrl} /> : <FontAwesomeIcon icon={faUser} size="lg" />}</Col>
                                             <Col className={classes.nameAndAccountName}><div><span>{firstName} {lastName}</span></div><div><span>{accountName}</span></div></Col>
                                             <Col><span><DownOutlined /></span></Col>
                                         </Row>
                                     }
                            >
                                <Menu.Item key="/profile"><span>Profile</span><NavLink to={{ pathname: '/profile', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                <Menu.Item key="/changePassword"><span>Change Password</span><NavLink to={{ pathname: '/changePassword', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="signout" onClick={signOut}>Sign Out</Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key='/termsOfService' onClick={toggleModal} className={classes.termsOfService}><span>Terms of Service and Privacy Policy</span></Menu.Item>
                                <Menu.Item key="copyright" disabled={true} className={classes.copyright}>
                                    <div><span>Pulogix ©2022</span></div>
                                    <div><span>Version {version}</span></div>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </div>
                </Header>
                <Content className={classes.content}>
                    <ScrollToTop />
                    {routes(isAuthenticated, entityType, isStaff, isVP, isAdmin)}
                </Content>
                <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
                <NotificationsList onClose={toggleNotificationsList} visible={showNotificationsList} />
                <MessagesList onClose={toggleMessagesList} visible={showMessagesList} />
            </Layout>
        );
    } else {
        return (
            <Layout>
                <Header className={classes.header + ' ' + classes.darkMenu}>
                    <div className={classes.leftMenu + ' ' + classes.darkMenu}>
                        <Link to="/">
                            <img src={pulogixLogoBigWhite} className={classes.topLogo} alt="PULOGIX" />
                        </Link>
                    </div>
                    <Menu style={{display: "flex", justifyContent: "flex-end"}} className={classes.rightMenu} theme="dark" defaultSelectedKeys={[]} mode="horizontal" activeKey={currentPath} selectedKeys={currentPath} defaultOpenKeys={[]}>
                        <Menu.Item key="notifications" onClick={toggleNotificationsList} style={{ float: 'right' }}>
                            <Badge count={unreadNotificationCount} style={{ boxShadow: 'none' }}>
                                <FontAwesomeIcon icon={faBell} size="lg" className="anticon" style={{color: '#ffffff'}} />
                            </Badge>
                        </Menu.Item>
                        <Menu.Item key="messages" onClick={toggleMessagesList} style={{ float: 'right' }}>
                            <FontAwesomeIcon icon={faCommentAlt} size="lg" className="anticon" />
                        </Menu.Item>
                        <SubMenu style={{ float: 'right' }}
                                 title={
                                     <Row>
                                         <Col>{imageUrl ? <Avatar src={imageUrl} /> : <FontAwesomeIcon icon={faUser} size="lg" />}</Col>
                                         <Col className={classes.nameAndAccountName}><div><span>{firstName} {lastName}</span></div><div><span>{accountName}</span></div></Col>
                                         <Col><span><DownOutlined /></span></Col>
                                     </Row>
                                 }
                        >
                            <Menu.Item key="/profile"><span>Profile</span><NavLink to={{ pathname: '/profile', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                            <Menu.Item key="/changePassword"><span>Change Password</span><NavLink to={{ pathname: '/changePassword', }} style={{ textDecoration: 'none' }} /></Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="signout" onClick={signOut}>Sign Out</Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key='/termsOfService' onClick={toggleModal} className={classes.termsOfService}><span>Terms of Service and Privacy Policy</span></Menu.Item>
                            <Menu.Item key="copyright" disabled={true} className={classes.copyright}>
                                <div><span>Pulogix ©2022</span></div>
                                <div><span>Version {version}</span></div>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Header>
                <Content className={classes.content}>
                    <ScrollToTop />
                    {routes(isAuthenticated, entityType, isStaff, isVP, isAdmin)}
                </Content>
                <TermsOfUseModal showModal={showModal} toggleModal={toggleModal} />
                <NotificationsList onClose={toggleNotificationsList} visible={showNotificationsList} />
                <MessagesList onClose={toggleMessagesList} visible={showMessagesList} />
            </Layout>
        );
    }
};

export default withRouter(DrawerLayout);
