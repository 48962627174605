import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import logger from "../../shared/logger";

//#region Orchestrator Functions

export const getOrchestratorSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ORCHESTRATOR_SUCCESS,
        payload: payload
    }
};

//#endregion

//#region Orchestrator Methods

// All API Calls should use Async/Await
export const getOrchestrator = () => {
    return async (dispatch) => {
        try {
            dispatch(getTrailerTypes(false));
            dispatch(getFeatures(false));
            dispatch(getAccounts(false));
            dispatch(getStaff(false));
            dispatch(getLineItemTypes(false));
            dispatch(getStates(false));
            dispatch(getCommodities(false));
            dispatch(getServices(false));
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getAccounts = (force = false) => {
    return async (dispatch, getState) => {
        const accountsPath = '/accounts';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            const accounts = [...orchestratorState.accounts];
            const accountsLastUpdatedAt = orchestratorState.accountsLastUpdatedAt ? new Date(orchestratorState.accountsLastUpdatedAt) : null;
            if (force === true || accounts.length === 0 || accountsLastUpdatedAt === null || accountsLastUpdatedAt.setHours(accountsLastUpdatedAt.getHours() + 24) < now) {
                const accountsRes = await axiosAuthenticated.get(accountsPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        isDeleted: false
                    }
                });
                if (accountsRes && accountsRes.status === 200) {
                    const data = accountsRes.data.data;

                    dispatch(getOrchestratorSuccess({accounts: data, accountsLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getTrailerTypes = (force = false) => {
    return async (dispatch, getState) => {
        const trailerTypesPath = '/trailerTypes';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            const trailerTypes = [...orchestratorState.trailerTypes];
            const trailerTypesLastUpdatedAt = orchestratorState.trailerTypesLastUpdatedAt ? new Date(orchestratorState.trailerTypesLastUpdatedAt) : null;
            if (force === true || trailerTypes.length === 0 || trailerTypesLastUpdatedAt === null || trailerTypesLastUpdatedAt.setHours(trailerTypesLastUpdatedAt.getHours() + 24) < now) {
                const trailerTypesRes = await axiosAuthenticated.get(trailerTypesPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        isDeleted: false
                    }
                });
                if (trailerTypesRes && trailerTypesRes.status === 200) {
                    const data = trailerTypesRes.data.data;

                    dispatch(getOrchestratorSuccess({trailerTypes: data, trailerTypesLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getLineItemTypes = (force = false) => {
    return async (dispatch, getState) => {
        const lineItemTypesPath = '/lineItemTypes';
        const trailerTypesPath = '/trailerTypes';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            const lineItemTypes = [...orchestratorState.lineItemTypes];
            const lineItemTypesLastUpdatedAt = orchestratorState.lineItemTypesLastUpdatedAt ? new Date(orchestratorState.lineItemTypesLastUpdatedAt) : null;
            if (force === true || lineItemTypes.length === 0 || lineItemTypesLastUpdatedAt === null || lineItemTypesLastUpdatedAt.setHours(lineItemTypesLastUpdatedAt.getHours() + 24) < now) {
                const lineItemTypesRes = await axiosAuthenticated.get(lineItemTypesPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        isDeleted: false
                    }
                });
                if (lineItemTypesRes && lineItemTypesRes.status === 200) {
                    const lineItemTypesData = lineItemTypesRes.data.data;

                    const state2 = getState();
                    const orchestratorState2 = {...state2.orchestrator};
                    let trailerTypes = [...orchestratorState2.trailerTypes];
                    const trailerTypesLastUpdatedAt = orchestratorState2.trailerTypesLastUpdatedAt ? new Date(orchestratorState2.trailerTypesLastUpdatedAt) : null;
                    if (force === true || trailerTypes.length === 0 || trailerTypesLastUpdatedAt === null || trailerTypesLastUpdatedAt.setHours(trailerTypesLastUpdatedAt.getHours() + 24) < now) {
                        const trailerTypesRes = await axiosAuthenticated.get(trailerTypesPath, {
                            params: {
                                page: 1,
                                size: 100000,
                                isDeleted: false
                            }
                        });
                        if (trailerTypesRes && trailerTypesRes.status === 200) {
                            trailerTypes = trailerTypesRes.data.data;

                            dispatch(getOrchestratorSuccess({
                                trailerTypes: trailerTypes,
                                trailerTypesLastUpdatedAt: Date.now()
                            }));
                        }
                    }

                    const transformedLineItemTypesData = lineItemTypesData.map((item) => {
                        let trailerTypesList = [];
                        if (item.trailerTypeIds !== undefined && item.trailerTypeIds !== null && item.trailerTypeIds.length > 0) {
                            for (let i = 0; i < item.trailerTypeIds.length; i++) {
                                const trailerTypeId = item.trailerTypeIds[i];
                                let trailerType = trailerTypes.find(i => i.id === trailerTypeId);
                                if (trailerType !== undefined && trailerType !== null) {
                                    trailerTypesList.push(trailerType);
                                }
                            }
                        }

                        return {
                            ...item,
                            trailerTypes: trailerTypesList
                        };
                    });

                    dispatch(getOrchestratorSuccess({
                        lineItemTypes: transformedLineItemTypesData,
                        lineItemTypesLastUpdatedAt: Date.now()
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getStaff = (force = false) => {
    return async (dispatch, getState) => {
        const usersPath = '/api/userProfiles/';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            let staff = [...orchestratorState.staff];
            const staffLastUpdatedAt = orchestratorState.staffLastUpdatedAt ? new Date(orchestratorState.staffLastUpdatedAt) : null;
            if (force === true || staff.length === 0 || staffLastUpdatedAt === null || staffLastUpdatedAt.setHours(staffLastUpdatedAt.getHours() + 24) < now) {
                // True needs to be used when making queries to the django api
                const usersRes = await axiosAuthenticated.get(usersPath, {params: {limit: 10000, isStaff: 'True'}});
                if (usersRes && usersRes.status === 200) {
                    staff = usersRes.data.results;

                    dispatch(getOrchestratorSuccess({staff: staff, staffLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getStates = (force = false) => {
    return async (dispatch, getState) => {
        const statesPath = '/states';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            let states = [...orchestratorState.states];
            const statesLastUpdatedAt = orchestratorState.statesLastUpdatedAt ? new Date(orchestratorState.statesLastUpdatedAt) : null;
            if (force === true || states.length === 0 || statesLastUpdatedAt === null || statesLastUpdatedAt.setHours(statesLastUpdatedAt.getHours() + 24) < now) {
                const statesRes = await axiosAuthenticated.get(statesPath, {params: {page: 1, size: 10000}});
                if (statesRes && statesRes.status === 200) {
                    states = statesRes.data.data;

                    dispatch(getOrchestratorSuccess({states: states, statesLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getCommodities = (force = false) => {
    return async (dispatch, getState) => {
        const commoditiesPath = '/commodities';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            let commodities = [...orchestratorState.commodities];
            const commoditiesLastUpdatedAt = orchestratorState.commoditiesLastUpdatedAt ? new Date(orchestratorState.commoditiesLastUpdatedAt) : null;
            if (force === true || commodities.length === 0 || commoditiesLastUpdatedAt === null || commoditiesLastUpdatedAt.setHours(commoditiesLastUpdatedAt.getHours() + 24) < now) {
                const commoditiesRes = await axiosAuthenticated.get(commoditiesPath, {
                    params: {
                        page: 1,
                        size: 10000,
                        sort: 'name',
                        order: 'asc',
                        isDeleted: false
                    }
                });
                if (commoditiesRes && commoditiesRes.status === 200) {
                    commodities = commoditiesRes.data.data;

                    dispatch(getOrchestratorSuccess({commodities: commodities, commoditiesLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getFeatures = (force = false) => {
    return async (dispatch, getState) => {
        const featuresPath = '/features';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            let features = [...orchestratorState.features];
            const featuresLastUpdatedAt = orchestratorState.featuresLastUpdatedAt ? new Date(orchestratorState.featuresLastUpdatedAt) : null;
            if (force === true || features.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                const featuresRes = await axiosAuthenticated.get(featuresPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        isDeleted: false
                    }
                });
                if (featuresRes && featuresRes.status === 200) {
                    features = featuresRes.data.data;

                    dispatch(getOrchestratorSuccess({features: features, featuresLastUpdatedAt: Date.now()}));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

export const getServices = (force = false) => {
    return async (dispatch, getState) => {
        const servicesPath = '/services';
        const featuresPath = '/features';
        try {
            const now = Date.now();
            const state = getState();
            const orchestratorState = {...state.orchestrator};
            const services = [...orchestratorState.services];
            const servicesLastUpdatedAt = orchestratorState.servicesLastUpdatedAt ? new Date(orchestratorState.servicesLastUpdatedAt) : null;
            if (force === true || services.length === 0 || servicesLastUpdatedAt === null || servicesLastUpdatedAt.setHours(servicesLastUpdatedAt.getHours() + 24) < now) {
                const servicesRes = await axiosAuthenticated.get(servicesPath, {
                    params: {
                        page: 1,
                        size: 100000,
                        isDeleted: false
                    }
                });
                if (servicesRes && servicesRes.status === 200) {
                    const servicesData = servicesRes.data.data;

                    const state2 = getState();
                    const orchestratorState2 = {...state2.orchestrator};
                    const featuresLastUpdatedAt = orchestratorState2.featuresLastUpdatedAt ? new Date(orchestratorState2.featuresLastUpdatedAt) : null;
                    let features = [...orchestratorState2.features];
                    if (force === true || features.length === 0 || featuresLastUpdatedAt === null || featuresLastUpdatedAt.setHours(featuresLastUpdatedAt.getHours() + 24) < now) {
                        const featuresRes = await axiosAuthenticated.get(featuresPath, {
                            params: {
                                page: 1,
                                size: 100000,
                                isDeleted: false
                            }
                        });
                        if (featuresRes && featuresRes.status === 200) {
                            features = featuresRes.data.data;

                            dispatch(getOrchestratorSuccess({features: features, featuresLastUpdatedAt: Date.now()}));
                        }
                    }

                    const transformedServices = servicesData.map((item) => {
                        let serviceFeaturesList = [];
                        if (item.featureIds !== undefined && item.featureIds !== null && item.featureIds.length > 0) {
                            item.featureIds.forEach((featureId) => {
                                let feature = features.find(i => i.id === featureId);
                                if (feature !== undefined && feature !== null) {
                                    serviceFeaturesList.push(feature);
                                }
                            });
                        }

                        return {
                            ...item,
                            features: serviceFeaturesList
                        };
                    });

                    dispatch(getOrchestratorSuccess({
                        services: transformedServices,
                        servicesLastUpdatedAt: Date.now()
                    }));
                }
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
        }
    }
};

//#endregion