import React from 'react';
import DataRow from '../DataRow/DataRow';
import { Col, Row } from 'antd';

const Configuration = props => {
    const configuration = props.record;

    return (
        <Row>
            <Col xs={24} md={24} lg={24} xl={24}>
                {configuration.name && <DataRow title="Name" value={configuration.name} dataType="String" />}
                {configuration.companyPhoneNumber && <DataRow title="Customer Support Phone Number" value={configuration.companyPhoneNumber} dataType="PhoneNumber" />}
                {configuration.geoFenceRadius && <DataRow title="GeoFence Radius" value={configuration.geoFenceRadius} dataType="String" />}
                {configuration.geoFenceRadiusUnit && <DataRow title="GeoFence Radius Unit" value={configuration.geoFenceRadiusUnit} dataType="DistanceUnit" />}
                {configuration.locationDistanceInterval && <DataRow title="Location Distance Interval" value={configuration.locationDistanceInterval} dataType="String" />}
                {configuration.locationDistanceIntervalUnit && <DataRow title="Location Distance Interval Unit" value={configuration.locationDistanceIntervalUnit} dataType="DistanceUnit" />}
                {configuration.locationTimeInterval && <DataRow title="Location Time Interval" value={configuration.locationTimeInterval} dataType="String" />}
                {configuration.locationTimeIntervalUnit && <DataRow title="Location Time Interval Unit" value={configuration.locationTimeIntervalUnit} dataType="TimeUnit" />}
            </Col>
        </Row>
    );
};

export default Configuration;