// created by Garrett Lang on 1/31/2020

// usage:
// import MomentDate from '../../shared/dateFormatter';
// import moment from 'moment';
// let now = moment();
// console.log(new MomentDate().toUtcISOString(now));
// let losAngeles = new MomentDate().asTimeZone(now, "America/Los_Angeles");
// console.log(losAngeles);
// let losAngelesUtc = new MomentDate().asTimeZoneUtcISOString(now, "America/Los_Angeles");
// console.log(losAngelesUtc);
// console.log(new MomentDate().fromUtcToTimeZone(losAngelesUtc, "America/Los_Angeles"));
// console.log(new MomentDate().fromUtcToDeviceTimeZone(losAngelesUtc));

import moment from 'moment';
import 'moment-timezone';

export default class MomentDate {

    getCurrentDateTimeInRange = (range = 15) => {
        const start = moment();
        const remainder = range - (start.minute() % range);

        return moment(start).add(remainder, "minutes")
    }

    toUtcISOString(value) {
        return moment(value).utc().toISOString(true);
    }

    // sets timezone-less moment datetime to be that datetime in a specified timezone
    asTimeZone(value, timeZoneName) {
        var localFormat = 'YYYY-MM-DD[T]HH:mm';
        var datetime = moment(value).format(localFormat);
        return moment.tz(datetime, timeZoneName);
    }

    // sets timezone-less moment time to have the date of another datetime and then the result to be a datetime in a specified timezone
    combineDateAndTimeAsTimeZone(date, time, timeZoneName) {
        var dateFormat = 'YYYY-MM-DD';
        var timeFormat = 'HH:mm';
        var dateTimeFormat = 'YYYY-MM-DD[T]HH:mm';

        // console.log(timeZoneName);
        // console.log(moment(date).format(dateFormat));
        // console.log(moment(time).format(timeFormat));
        var formattedDateString = moment(date).format(dateFormat);
        var formattedTimeString = moment(time).format(timeFormat);
        var formattedDateTimeString = `${formattedDateString}T${formattedTimeString}`;
        // console.log(moment(formattedDateTimeString).format(dateTimeFormat));
        // console.log(moment.tz(formattedDateTimeString, timeZoneName));
        // console.log(moment.tz(formattedDateTimeString, timeZoneName).utc().toISOString(true));
        return moment.tz(formattedDateTimeString, timeZoneName);
    }

    // sets timezone-less moment time to have the date of another datetime and then the result to be a datetime in a specified timezone and returns it as a utc iso string
    combineDateAndTimeAsTimeZoneUtcISOString(date, time, timeZoneName) {
        return this.combineDateAndTimeAsTimeZone(date, time, timeZoneName).utc().toISOString(true);
    }

    // sets timezone-less moment datetime to be that datetime in a specified timezone and returns it as a utc iso string
    asTimeZoneUtcISOString(value, timeZoneName) {
        return this.asTimeZone(value, timeZoneName).utc().toISOString(true);
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as a string with format MM/DD/YYYY HH:mm z
    fromUtcToTimeZone(value, timeZoneName) {
        var displayFormat = 'MM/DD/YYYY HH:mm z';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(timeZoneName).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as a moment date as local timezone (to be used in edit forms)
    fromUtcToTimeZoneAsTimeZone(value, timeZoneName) {
        var localFormat = 'YYYY-MM-DD[T]HH:mm';
        if (value !== null && value !== undefined) {
            var timeZonedDateTime = moment.utc(value).tz(timeZoneName).format(localFormat);
            var timeZoneName = moment.tz.guess();
            return moment.tz(timeZonedDateTime, timeZoneName);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as a string with format MM/DD/YYYY
    fromUtcToTimeZoneDateOnly(value, timeZoneName) {
        var displayFormat = 'MM/DD/YYYY';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(timeZoneName).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as an object of the date parts
    fromUtcToTimeZoneDateObject(value, timeZoneName) {
        if (value !== null && value !== undefined) {
            var convertedDate = moment.utc(value).tz(timeZoneName);
            return {
                month: convertedDate.format('MM'),
                monthAbbr: convertedDate.format('MMM'),
                monthName: convertedDate.format('MMMM'),
                day: convertedDate.format('DD'),
                year: convertedDate.format('YYYY'),
                dayOfWeekShortAbbr: convertedDate.format('dd'),
                dayOfWeekAbbr: convertedDate.format('ddd'),
                dayOfWeek: convertedDate.format('dddd'),
                hours: convertedDate.format('HH'),
                minutes: convertedDate.format('mm'),
                timeZone: convertedDate.format('z'),
                timeZoneName: timeZoneName,
                utcOffset: convertedDate.format('Z'),
                date: convertedDate,
                timeStringWithTimeZone: convertedDate.format('HH:mm z'),
                timeString: convertedDate.format('HH:mm'),
                dateString: convertedDate.format('MM/DD/YYYY'),
                dateTimeString: convertedDate.format('MM/DD/YYYY HH:mm z')
            }
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as a string with format HH:mm z
    fromUtcToTimeZoneTimeOnly(value, timeZoneName) {
        var displayFormat = 'HH:mm z';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(timeZoneName).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to a specific TimeZone and return it as a string with format HH:mm
    fromUtcToTimeZoneTimeOnlyHideTimeZone(value, timeZoneName) {
        var displayFormat = 'HH:mm';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(timeZoneName).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to the TimeZone of Browser/Device and return it as a string with format MM/DD/YYYY HH:mm z
    fromUtcToDeviceTimeZone(value) {
        var displayFormat = 'MM/DD/YYYY HH:mm z';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(moment.tz.guess()).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to the TimeZone of Browser/Device and return it as a string with format MM/DD/YYYY
    fromUtcToDeviceTimeZoneDateOnly(value) {
        var displayFormat = 'MM/DD/YYYY';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(moment.tz.guess()).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to the TimeZone of Browser/Device and return it as a string with format HH:mm z
    fromUtcToDeviceTimeZoneTimeOnly(value) {
        var displayFormat = 'HH:mm z';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(moment.tz.guess()).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to the TimeZone of Browser/Device and return it as a string with format HH:mm
    fromUtcToDeviceTimeZoneTimeOnlyHideTimeZone(value) {
        var displayFormat = 'HH:mm';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(moment.tz.guess()).format(displayFormat);
        } else {
            return null;
        }
    }

    // Convert DateTime from Backend (utc time) to the TimeZone of Browser/Device and return it as a string with format HH:mm z | MM/DD/YYYY
    fromUtcToDeviceTimeZoneForMessages(value) {
        var displayFormat = 'HH:mm z | MM/DD/YYYY';
        if (value !== null && value !== undefined) {
            return moment.utc(value).tz(moment.tz.guess()).format(displayFormat);
        } else {
            return null;
        }
    }
}
