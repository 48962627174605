import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { Drawer, Button, Dropdown, Menu } from 'antd';
import EditLinkedLocation from "../../components/EditLinkedLocation/EditLinkedLocation";
import NewLinkedLocation from "../../components/NewLinkedLocation/NewLinkedLocation";
import classes from './LinkedLocations.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked, faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import { withRouter } from 'react-router-dom';
import { DownOutlined, LinkOutlined } from '@ant-design/icons';
import StringFormatter from '../../shared/stringFormatter';
import { isEmpty } from 'lodash';
import axiosAuthenticated from "../../api/axiosAuthenticated";

const stringFormatter = new StringFormatter();

const LinkedLocations = props => {
    const fullWidth = global.window.innerWidth;
    const accountId = props.accountId;
    const linkedAccountId = props.linkedAccountId;
    const singularEntityName = "Location";
    const pluralEntityName = "Locations";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.linkedLocations.isLoading);
    const records = useSelector(state => state.linkedLocations.records);
    const pagination = useSelector(state => state.linkedLocations.pagination);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAddLoading = useSelector(state => state.linkedLocations.isRecordAddLoading);
    const addError = useSelector(state => state.linkedLocations.addRecordError);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [locations, setLocations] = useState([]);

    const linkLocation = (record) => {
        //console.log(record);
        if (record !== undefined && record !== null) {
            let payload = {};
            payload.linkedAccountId = record.accountId;
            payload.accountId = accountId;
            payload.locationId = record.id;

            if (!isEmpty(payload)) {
                dispatch(actionCreators.addLinkedLocation(payload));
            }
        }
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const goToLinkedLocation = (id) => {
        props.history.push({ pathname: `/linkedLocations/${id}` }, {
            previousPageTitle: "Locations",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="editEntity" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faMapMarked} style={{ marginRight: '10px' }} />
                    <span>Edit {singularEntityName} Details</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); goToLinkedLocation(record.id); }}>
                    <span>Go to {singularEntityName} Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const getLocations = async () => {
        setLocations([]);
        try {
            let searchParams = {
                page: 1,
                size: 10000
            };

            let linkedLocationsSearchParams = {
                page: 1,
                size: 10000
            };

            let existingLocationIds = [];
            if (records !== undefined && records !== null && records.length > 0) {
                existingLocationIds = records.map((item) => item.locationId);
                searchParams['id:not'] = existingLocationIds;
            }

            console.log(existingLocationIds);

            searchParams.accountId = linkedAccountId;
            linkedLocationsSearchParams.accountId = linkedAccountId;

            let locationsList = [];
            let locationIds = [];
            let transformedLocations = [];
            //dispatch(actionCreators.fetchLocations(searchParams));
            const locationsResults = await axiosAuthenticated.post('/locations/bulk/get', { ...searchParams });
            if (locationsResults && locationsResults.status === 200) {
                const locationsData = locationsResults.data.data;
                if (locationsData !== undefined && locationsData !== null && locationsData.length > 0) {
                    transformedLocations = locationsData.map((item) => {
                        return {
                            ...item,
                            key: item.id
                        };
                    });

                    locationIds = locationsData.map((item) => item.id);
                }
            }

            // also get all locations that are linked to the linkedAccount
            let transformedLocationsFromLinkedLocations = [];
            linkedLocationsSearchParams['locationId:not'] = [...existingLocationIds, ...locationIds];
            const linkedLocationsRes = await axiosAuthenticated.post('/linkedLocations/bulk/get', { ...linkedLocationsSearchParams });
            if (linkedLocationsRes && linkedLocationsRes.status === 200) {
                const linkedLocationsData = linkedLocationsRes.data.data;
                if (linkedLocationsData !== undefined && linkedLocationsData !== null && linkedLocationsData.length > 0) {
                    transformedLocationsFromLinkedLocations = linkedLocationsData.map((item) => {
                        return {
                            ...item.location,
                            key: item.locationId
                        };
                    });
                }
            }

            locationsList = [...transformedLocations, ...transformedLocationsFromLinkedLocations];
            setLocations([...new Set(locationsList)]);
        } catch (error) {
            console.log(error);
            setLocations([]);
        }
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 10000,
            isDeleted: false
        };

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            searchParams.linkedAccountId = linkedAccountId;
        }

        dispatch(actionCreators.fetchLinkedLocations(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchLinkedLocationsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (accountId !== undefined && accountId !== null) {
            searchParams.accountId = accountId;
        } else if (entityId !== undefined && entityId !== null && entityType !== 'STAFF') {
            searchParams.accountId = entityId;
        }

        if (linkedAccountId !== undefined && linkedAccountId !== null) {
            searchParams.linkedAccountId = linkedAccountId;
        }

        dispatch(actionCreators.fetchLinkedLocations(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 100,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                if (text !== undefined && text !== null) {
                    return text;
                } else if (record && record.location && record.location.name) {
                    return record.location.name;
                } else {
                    return '';
                }
            },
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text, record) => {
                if (record && record.location && record.location.address) {
                    return stringFormatter.toFormattedAddress(record.location.address);
                } else {
                    return '';
                }
            },
        }
    ];

    const locationColumns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            className: classes.columnNoBreak,
            width: 50,
            render: (text, record) => {
                return (
                    <Button icon={<LinkOutlined />} type="primary" shape="circle" onClick={(e) => { e.stopPropagation(); linkLocation(record); }} />
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'locationName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'locationAddress',
            render: (text, record) => {
                if (record && record.address) {
                    return stringFormatter.toFormattedAddress(record.address);
                } else {
                    return '';
                }
            },
        }
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewLinkedLocation cancel={toggleNewEntity} accountId={accountId ? accountId : (entityId && entityType !== 'STAFF' ? entityId : null)} linkedAccountId={linkedAccountId} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditLinkedLocation cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, [accountId, entityId]);

    useEffect(() => {
        getLocations();
    }, [records]);

    return (
        <>
            <DataTable
                dataSource={records}
                columns={columns}
                hidePaging={true}
                //pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading === true || isAddLoading === true}
                rowClassName={classes.dataTableRow}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={toggleNewEntity}
                rowKey={record => record.id}
                style={{ backgroundColor: '#ffffff' }}
            >
                {newEntityComponents}
                {editEntityComponents}
            </DataTable>
            {(linkedAccountId !== undefined && linkedAccountId !== null && locations !== undefined && locations !== null && locations.length > 0) ? (
                <DataTable
                    dataSource={locations}
                    columns={locationColumns}
                    hidePaging={true}
                    loading={isLoading === true || isAddLoading === true}
                    rowClassName={classes.dataTableRow}
                    title={() => (<b>This account has other Locations. Would you like to copy them to your list of Locations?</b>)}
                    rowKey={record => record.id}
                    style={{ backgroundColor: '#ffffff', marginTop: 24 }}
                />
            ) : null}
        </>
    );
};

export default withRouter(LinkedLocations);