import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { DownloadOutlined, FileTextOutlined, DownOutlined } from '@ant-design/icons';
import { Checkbox, Col, Modal, Row, Drawer, Button, Menu, Dropdown } from 'antd';
import AccountRegistration from "../../components/AccountRegistration/AccountRegistration";
import EditAccountRegistration from "../../components/EditAccountRegistration/EditAccountRegistration";
import { faTruck, faTruckLoading, faWarehouse, faTractor, faTools, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from './AccountRegistrations.module.scss';
import StringFormatter from '../../shared/stringFormatter';
import EditAccountRegistrationReviewStatus from '../../components/EditAccountRegistrationReviewStatus/EditAccountRegistrationReviewStatus';
import DataTable from '../../components/DataTable/DataTable';
import { compareByAlph } from '../../shared/tableUtils';
import NoteList from '../../components/NoteList/NoteList';

const stringFormatter = new StringFormatter();

const AccountRegistrations = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Account Application";
    const pluralEntityName = "Account Applications";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.accountRegistrations.isLoading);
    const records = useSelector(state => state.accountRegistrations.records);
    const pagination = useSelector(state => state.accountRegistrations.pagination);
    const entityType = useSelector(state => state.auth.entityType);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);
    const [showEditReviewStatusEntity, setShowEditReviewStatusEntity] = useState(false);
    const [accountTypes, setAccountTypes] = useState([]);
    const [showNotesModal, setShowNotesModal] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleEditReviewStatusEntity = () => {
        setShowEditReviewStatusEntity(!showEditReviewStatusEntity);
    };

    const onChange = (checkedAccountTypes) => {
        setAccountTypes(checkedAccountTypes)
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const toggleNotesModal = () => {
        setShowNotesModal(!showNotesModal);
    };

    const goToNewAccount = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: accountTypes,
                accountRegistrationId: null
            }
        });
    };

    const setRowClassName = (record) => {
        return record.isReviewed === undefined || record.isReviewed === null || record.isReviewed === false || record.reviewStatus !== 'APPROVED' ? classes.unverifiedRow : classes.dataTableRow;
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            sort: 'createdAt',
            order: 'desc',
            isDeleted: false
        };

        let defaultReviewStatusFilter = ['PENDING', 'APPROVED', 'CONTACTED'];
        searchParams.reviewStatus = defaultReviewStatusFilter.join('|');

        dispatch(actionCreators.fetchAccountRegistrations(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchAccountRegistrationsSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        if (filters.reviewStatus !== undefined) {
            if (filters.reviewStatus.length > 0) {
                searchParams.reviewStatus = filters.reviewStatus.join('|');
            }
        }

        dispatch(actionCreators.fetchAccountRegistrations(searchParams));
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="manageNotes" onClick={() => { toggleNotesModal(); }}>
                    <FileTextOutlined />
                    <span>Open Notes</span>
                </Menu.Item>
                {(record.reviewStatus !== "IMPORTED" || record.isReviewed === false) ? (
                    <Menu.Item key="reviewAccountRegistration" onClick={() => { toggleEditReviewStatusEntity(); }}>
                        <FontAwesomeIcon className="anticon" icon={faCheck} style={{ marginRight: '10px' }} />
                        <span>Review Account Application</span>
                    </Menu.Item>
                ) : null}
                <Menu.Item key="editAccountRegistration" onClick={() => { toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faEdit} style={{ marginRight: '10px' }} />
                    <span>Edit Account Application Details</span>
                </Menu.Item>
                {record.reviewStatus === "APPROVED" || record.reviewStatus === "CONTACTED" ? (
                    <Menu.Item key="importAccoutRegistration" onClick={
                        () => {
                            let accountTypeValues = [];
                            if (record.isShipper) accountTypeValues.push('SHIPPER');
                            if (record.isCarrier) accountTypeValues.push('CARRIER');
                            if (record.isProducer) accountTypeValues.push('PRODUCER');
                            if (record.isReceiver) accountTypeValues.push('RECEIVER');
                            props.history.push({
                                pathname: `/newAccount`,
                                state: {
                                    accountTypes: accountTypeValues,
                                    accountRegistrationId: record.id
                                }
                            });
                        }
                    }>
                        <DownloadOutlined />
                        <span>Import Account</span>
                    </Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button onClick={(e) => { e.stopPropagation(); setSelectedRecord(record); }}>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
            className: classes.columnNoBreak
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Is Carrier',
            dataIndex: 'isCarrier',
            key: 'isCarrier',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Shipper',
            dataIndex: 'isShipper',
            key: 'isShipper',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Receiver',
            dataIndex: 'isReceiver',
            key: 'isReceiver',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Producer',
            dataIndex: 'isProducer',
            key: 'isProducer',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Reviewed',
            dataIndex: 'isReviewed',
            key: 'isReviewed',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Review Status',
            dataIndex: 'reviewStatus',
            key: 'reviewStatus',
            filters: [
                {
                    text: 'Pending',
                    value: 'PENDING',
                },
                {
                    text: 'Approved',
                    value: 'APPROVED',
                },
                {
                    text: 'Rejected',
                    value: 'REJECTED',
                },
                {
                    text: 'Contacted',
                    value: 'CONTACTED',
                },
                {
                    text: 'Imported',
                    value: 'IMPORTED',
                }
            ],
            filterMultiple: true,
            defaultFilteredValue: ["PENDING", "APPROVED", "CONTACTED"],
            onFilter: (value, record) => record.reviewStatus.indexOf(value) === 0,
            render: (text, record) => { return stringFormatter.toFormattedString("AccountRegistrationReviewStatus", text, null); },
        },
        {
            title: 'Submission Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => compareByAlph(a.pickUpDateTime, b.pickUpDateTime),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    const newEntityComponents = (
        <Modal
            title={"Choose " + singularEntityName + " Type"}
            visible={showNewEntity}
            onOk={goToNewAccount}
            onCancel={toggleNewEntity}>
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                <Row gutter={[4, 4]}>
                    <Col span={24}>
                        <span><strong>Select all that apply.</strong></span>
                    </Col>
                </Row>
                <Row gutter={[4, 4]}>
                    <Col span={6}>
                        <FontAwesomeIcon size={"2x"} icon={faTruckLoading} style={{ marginRight: '10px' }} />
                        <Checkbox value="CARRIER"><b>Carrier</b></Checkbox>
                    </Col>
                    <Col span={6}>
                        <FontAwesomeIcon size={"2x"} icon={faTruck} style={{ marginRight: '10px' }} />
                        <Checkbox value="SHIPPER"><b>Shipper</b></Checkbox>
                    </Col>
                    <Col span={6}>
                        <FontAwesomeIcon size={"2x"} icon={faWarehouse} style={{ marginRight: '10px' }} />
                        <Checkbox value="RECEIVER"><b>Receiver</b></Checkbox>
                    </Col>
                    <Col span={6}>
                        <FontAwesomeIcon size={"2x"} icon={faTractor} style={{ marginRight: '10px' }} />
                        <Checkbox value="PRODUCER"><b>Producer</b></Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </Modal>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccountRegistration cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    const editReviewStatusEntityComponents = (
        <Drawer
            title={"Review " + singularEntityName}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleEditReviewStatusEntity}
            visible={showEditReviewStatusEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditAccountRegistrationReviewStatus cancel={toggleEditReviewStatusEntity} accountRegistration={selectedRecord} />
        </Drawer>
    );

    const notesComponent = (
        <Modal
            title="Account Application Notes"
            visible={showNotesModal}
            width="100%"
            style={{ top: 0 }}
            onCancel={toggleNotesModal}
            footer={null}
            zIndex={1000}
            destroyOnClose={true}
            maskClosable={false}
        >
            <NoteList entityType="ACCOUNT_REGISTRATION" entityId={selectedRecord.id} />
        </Modal>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            loading={isLoading === true}
            pagination={pagination}
            onChange={handleTableChange}
            expandedRowRender={record => (<AccountRegistration record={record} />)}
            expandRowByClick={true}
            rowClassName={setRowClassName}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={entityType === "STAFF" ? toggleNewEntity : null}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
            {editReviewStatusEntityComponents}
            {notesComponent}
        </DataTable>
    );
};

export default withRouter(AccountRegistrations);
