import React from 'react';
import DataRow from '../../components/DataRow/DataRow';
import Enums from '../../shared/enums';

const DataCommodityListRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, title = null, data, noColon = false, dataType = 'String' }) => {

    const createDataItems = (items) => {
        let dataItems = [];
        const entries = Object.entries(items);

        // console.log(items);

        for (const [key, value] of entries) {
            // console.log(value);
            dataItems.push(<DataRow className={className} valueClassName={valueClassName} titleClassName={titleClassName} style={style} titleStyle={titleStyle} valueStyle={valueStyle} key={`data-row-${key}`} title={value.name} value={`${value.unitPackagingInitCount ? value.unitPackagingInitCount + ' ' + Enums.UnitPackaging.getValueByName(value.unitPackaging) : ''}${value.bulkPackagingInitCount ? ', ' + value.bulkPackagingInitCount + ' ' + Enums.BulkPackaging.getValueByName(value.bulkPackaging) : ''}`} dataType={dataType} />);
        }

        return dataItems;
    };

    return (
        <div>
            {title !== undefined && title !== null ? (<div className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </div>) : null}
            {createDataItems(data)}
        </div>
    );
};

export default DataCommodityListRow;