import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const EditStopStatusArrived = props => {
    const stop = props.stop;
    const load = props.load;

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const methods = useForm();

    const onSubmit = (e) => {
        dispatch(actionCreators.sendLoadEvent({
            loadId: load.id,
            stopId: stop.id,
            eventType: 'LOAD_ARRIVED'
        }));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Driver has Arrived</legend>
                    <b>Has the Driver arrived at the stop? Change the Status of this Stop to Arrived.</b>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Driver has Arrived at Stop" />
        </Form>
    );
};

export default EditStopStatusArrived;