import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Drawer, Dropdown, Button, Menu, message } from 'antd';
import NewUser from "../../components/NewUser/NewUser";
import EditUser from "../../components/EditUser/EditUser";
import classes from './Users.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../components/DataTable/DataTable';
import StringFormatter from '../../shared/stringFormatter';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';

const stringFormatter = new StringFormatter();

const Users = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "User";
    const pluralEntityName = "Users";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.users.isLoading);
    const records = useSelector(state => state.users.records);
    const pagination = useSelector(state => state.users.pagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const refreshTable = () => {
        let searchParams = {
            offset: 0,
            limit: 20
        };

        dispatch(actionCreators.fetchUsers(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchUsersSuccess({ pagination: pager }));

        let searchParams = {
            offset: (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize
        };

        dispatch(actionCreators.fetchUsers(searchParams));
    };

    const openUserView = (id) => {
        props.history.push({ pathname: `/users/${id}` }, {
            previousPageTitle: "Users",
            previousPageLocation: props.location,
            previousBreadcrumbs: props.breadcrumbs,
        });
    };

    const resendWelcomeEmail = (record) => {
        const email = record.email;
        if (email !== undefined && email !== null) {
            axios.post("/rest-auth/password/reset/", { email: email }).then(res => {
                if (res && res.status === 200) {
                    message.success('A welcome email has been sent to this user.');
                }
            }).catch(err => {
                console.log(err);
                message.error('Failed to send the welcome email has been sent to this user.');
            });
        }
    };

    const menu = (record) => {
        return (
            <Menu>
                <Menu.Item key="welcomeUser" onClick={() => { resendWelcomeEmail(record); }}>
                    <span>Re-Send Welcome Email</span>
                </Menu.Item>
                <Menu.Item key="editUser" onClick={() => { setSelectedRecord(record); toggleEditEntity(); }}>
                    <FontAwesomeIcon className="anticon" icon={faUserAlt} style={{ marginRight: '10px' }} />
                    <span>Edit User Profile</span>
                </Menu.Item>
                <Menu.Item key="goToDashboard" onClick={() => { setSelectedRecord(record); openUserView(record.id); }}>
                    <span>Go to User Dashboard</span>
                </Menu.Item>
            </Menu>
        );
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <Dropdown overlay={menu(record)} trigger={['click']}>
                        <Button>Actions <DownOutlined /></Button>
                    </Dropdown>
                );
            },
            align: 'center',
        },
        {
            title: 'UserName',
            dataIndex: 'user',
            key: 'userName',
        },
        {
            title: 'Email Address',
            dataIndex: 'user',
            key: 'emailAddress',
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Date Joined',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDate", text, null); },
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Admin',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Is Staff',
            dataIndex: 'isStaff',
            key: 'isStaff',
            render: (text, record) => { return stringFormatter.toFormattedString("Boolean", text, null); },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => { return stringFormatter.toFormattedString("PhoneNumber", text, null); },
        }
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewUser cancel={toggleNewEntity} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Profile"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditUser cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(Users);
