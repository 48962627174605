import React, { useState, useMemo } from 'react';
import { Card, Tooltip, Button, Drawer, Spin } from "antd";
import classes from './LoadStatusCard.module.scss';
import EditLoadStatusApproved from '../../components/EditLoadStatusApproved/EditLoadStatusApproved';
import EditLoadStatusPendingRateConfirmation from '../../components/EditLoadStatusPendingRateConfirmation/EditLoadStatusPendingRateConfirmation';
import EditLoadStatusApproveRateConfirmation from '../../components/EditLoadStatusApproveRateConfirmation/EditLoadStatusApproveRateConfirmation';
import EditLoadStatusRejectRateConfirmation from '../../components/EditLoadStatusRejectRateConfirmation/EditLoadStatusRejectRateConfirmation';
import EditLoadStatusScheduled from '../../components/EditLoadStatusScheduled/EditLoadStatusScheduled';
import EditLoadStatusInTransit from '../../components/EditLoadStatusInTransit/EditLoadStatusInTransit';
import EditLoadStatusCompleted from '../../components/EditLoadStatusCompleted/EditLoadStatusCompleted';
import EditLoadStatusClosed from '../../components/EditLoadStatusClosed/EditLoadStatusClosed';
import EditLoadStatusReOpened from '../../components/EditLoadStatusReOpened/EditLoadStatusReOpened';
import EditStopStatusArriving from '../../components/EditStopStatusArriving/EditStopStatusArriving';
import EditStopStatusArrived from '../../components/EditStopStatusArrived/EditStopStatusArrived';
import EditStopStatusLoading from '../../components/EditStopStatusLoading/EditStopStatusLoading';
import EditStopStatusLoaded from '../../components/EditStopStatusLoaded/EditStopStatusLoaded';
import EditStopStatusUnloading from '../../components/EditStopStatusUnloading/EditStopStatusUnloading';
import EditStopStatusUnloaded from '../../components/EditStopStatusUnloaded/EditStopStatusUnloaded';
import EditStopStatusLayover from '../../components/EditStopStatusLayover/EditStopStatusLayover';
import EditStopStatusCompleted from '../../components/EditStopStatusCompleted/EditStopStatusCompleted';
import { useSelector } from 'react-redux';
import EditLoadStatusPostpone from "../EditLoadStatusPostpone/EditLoadStatusPostpone";
import StringFormatter from "../../shared/stringFormatter";
import EditLoadAcceptPostpone from "../EditLoadAcceptPostpone/EditLoadAcceptPostpone";
import EditLoadRejectPostpone from "../EditLoadRejectPostpone/EditLoadRejectPostpone";

const stringFormatter = new StringFormatter();

const LoadStatusCard = props => {
    //#region props and constants

    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Load";
    const load = props.load;
    const stops = props.stops;
    const invoiceLineItems = props.invoiceLineItems;
    const transactions = props.transactions;
    const claims = props.claims;
    const entityType = props.entityType;
    const documents = props.documents;
    const currentStop = props.currentStop;
    const currentStopType = props.currentStopType;
    const currentStatus = props.currentStatus;
    const accounts = props.accounts;

    //#endregion
    //#region useSelectors

    const loadEventUpdateStatus = useSelector(state => state.loads.loadEventUpdateStatus);

    //#endregion
    //#region useStates

    const [showEditLoadStatus, setShowEditLoadStatus] = useState(false);
    const [actionViewKey, setActionViewKey] = useState('');
    const [displayStatus, setDisplayStatus] = useState('');
    const [actions, setActions] = useState(null);

    //#endregion
    //#region toggles

    const toggleEditLoadStatus = () => {
        setShowEditLoadStatus(!showEditLoadStatus);
    };

    const openEditLoadStatus = () => {
        setShowEditLoadStatus(true);
    };

    const closeEditLoadStatus = () => {
        setShowEditLoadStatus(false);
    };

    //#endregion
    //#region displays

    const actionViews = {
        proposeRateConfirmation: <EditLoadStatusPostpone cancel={toggleEditLoadStatus} load={load} />,
        approveDatePostpone: <EditLoadAcceptPostpone cancel={toggleEditLoadStatus} load={load} />,
        rejectDatePostpone: <EditLoadRejectPostpone cancel={toggleEditLoadStatus} load={load} />,

        approved: <EditLoadStatusApproved cancel={toggleEditLoadStatus} load={load} />,
        pendingRateConfirmation: <EditLoadStatusPendingRateConfirmation cancel={toggleEditLoadStatus} load={load} />,
        approveRateConfirmation: <EditLoadStatusApproveRateConfirmation cancel={toggleEditLoadStatus} load={load} />,
        rejectRateConfirmation: <EditLoadStatusRejectRateConfirmation cancel={toggleEditLoadStatus} load={load} />,
        scheduled: <EditLoadStatusScheduled cancel={toggleEditLoadStatus} load={load} accounts={accounts} />,
        inTransit: <EditLoadStatusInTransit cancel={toggleEditLoadStatus} load={load} />,
        stopArriving: <EditStopStatusArriving cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopArrived: <EditStopStatusArrived cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLoading: <EditStopStatusLoading cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLoaded: <EditStopStatusLoaded cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopUnloading: <EditStopStatusUnloading cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopUnloaded: <EditStopStatusUnloaded cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopLayover: <EditStopStatusLayover cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        stopCompleted: <EditStopStatusCompleted cancel={toggleEditLoadStatus} load={load} stop={currentStop} />,
        completed: <EditLoadStatusCompleted cancel={toggleEditLoadStatus} load={load} />,
        closed: <EditLoadStatusClosed cancel={toggleEditLoadStatus} load={load} stops={stops} invoiceLineItems={invoiceLineItems} transactions={transactions} claims={claims} documents={documents} />,
        reOpened: <EditLoadStatusReOpened cancel={toggleEditLoadStatus} load={load} />,
    };

    const getStatus = (currentStatus, load, currentStop, currentStopType, entityType) => {
        switch (currentStatus) {
            case 'PENDING':
                setDisplayStatus('Pending');
                if (entityType === "STAFF" || entityType === "SHIPPER") {
                    setActions(
                        <Tooltip placement="top" title={"Shipper Approved Price Confirmation"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('approved'); toggleEditLoadStatus(); }}>Shipper Approved Price Confirmation</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'APPROVED':
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Send Rate Confirmation"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('pendingRateConfirmation'); toggleEditLoadStatus(); }}>Send Rate Confirmation</Button></Tooltip>
                    );
                    setDisplayStatus('Approved By Shipper');
                } else if (entityType === 'CARRIER') {
                    setActions(null);
                    setDisplayStatus('Pending Rate Confirmation');
                } else {
                    setActions(
                        <Tooltip placement="top" title={"Send Rate Confirmation"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('pendingRateConfirmation'); toggleEditLoadStatus(); }}>Send Rate Confirmation</Button></Tooltip>
                    );
                    setDisplayStatus('Approved By Shipper');
                }
                break;
            case 'PENDING_RATE_CONFIRMATION':
                if (entityType === "STAFF" || entityType === "CARRIER") {
                    setActions(
                        <>
                            <Tooltip placement="top" title={"View Rate Confirmation"}><Button href={`/rateconfirmation/${load.id}`} target='_blank' type="primary" style={{ marginRight: 8 }} disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined}>View Rate Conf</Button></Tooltip>
                            <Tooltip placement="top" title={"Reject Rate Confirmation"}><Button key="2" type="danger" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('rejectRateConfirmation'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Reject</Button></Tooltip>
                            <Tooltip placement="top" title={"Approve Rate Confirmation"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('approveRateConfirmation'); toggleEditLoadStatus(); }}>Approve</Button></Tooltip>
                            {(entityType === "CARRIER") && !load.isAskingForPostpone && (
                                <Tooltip placement="top" title={"Propose different date"}><Button className={classes.ml2} key="3" type="warning" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('proposeRateConfirmation'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Propose New Date</Button></Tooltip>
                            )}
                            {(entityType === "CARRIER") && !!load.isAskingForPostpone && !load.isPostponeApproved && (
                                <span className={classes.ml2}>You have proposed new load date for: {stringFormatter.toFormattedString("MomentDateTime", load.suggestedPostponeDate, null)}</span>
                            )}
                            {(entityType === "CARRIER") && !!load.isAskingForPostpone && !!load.isPostponeApproved && (
                                <span className={classes.ml2}>Your proposed new date {load.suggestedPostponeDate} has been approved</span>
                            )}
                        </>
                    );
                    setDisplayStatus('Pending Rate Confirmation');
                } else {
                    setActions(<>
                        {!!load.isAskingForPostpone && !load.isPostponeApproved && (
                            <>
                                <Tooltip placement="top" title={"Reject Date Postpone"}><Button key="2" type="danger" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('rejectDatePostpone'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Reject</Button></Tooltip>
                                <Tooltip placement="top" title={"Approve Date Postpone"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('approveDatePostpone'); toggleEditLoadStatus(); }}>Approve</Button></Tooltip>
                            </>
                        )}
                    </>);
                    setDisplayStatus('Approved By Shipper' + (load.isAskingForPostpone ? ', Carrier is asking to postpone date' : ""));
                }
                break;
            case 'BOOKED':
                setDisplayStatus('Booked');
                if (entityType === "STAFF" || entityType === "CARRIER") {
                    setActions(
                        <Tooltip placement="top" title={"Change to Scheduled"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('scheduled'); toggleEditLoadStatus(); }}>Scheduled</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'SCHEDULED':
                if (entityType === "STAFF") {
                    setDisplayStatus('Scheduled');
                    setActions(
                        <Tooltip placement="top" title={"Start Trip"}><Button key="1" type="primary" disabled={load.assignedCarrierId === null || load.assignedCarrierId === undefined} onClick={(e) => { e.stopPropagation(); setActionViewKey('inTransit'); toggleEditLoadStatus(); }}>Start Trip</Button></Tooltip>
                    );
                } else {
                    setDisplayStatus(entityType === "SHIPPER" ? 'Scheduled' : 'Booked');
                    setActions(null);
                }
                break;
            case 'DEAD_HEADING':
                setDisplayStatus('Dead Heading');
                if (entityType === "STAFF") {
                    if (currentStopType === 'PICK_UP' || currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Arriving"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArriving'); toggleEditLoadStatus(); }}>Arriving</Button></Tooltip>
                            </>
                        );
                    } else {
                        setActions(
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        );
                    }
                } else {
                    setActions(null);
                }
                break;
            case 'LAYOVER':
                setDisplayStatus('On Layover');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'IN_TRANSIT':
                if (currentStop !== undefined && currentStop !== null) {
                    setDisplayStatus('In Transit');
                } else {
                    setDisplayStatus('Drop-Off Completed');
                }
                if (entityType === "STAFF" && currentStop !== undefined && currentStop !== null) {
                    if (currentStopType === 'PICK_UP' || currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Arriving"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArriving'); toggleEditLoadStatus(); }}>Arriving</Button></Tooltip>
                            </>
                        );
                    } else {
                        setActions(
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        );
                    }
                } else if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('completed'); openEditLoadStatus(); }}>Complete Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'ARRIVING':
                setDisplayStatus('Arriving');
                if (entityType === "STAFF") {
                    setActions(
                        <>
                            <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                            <Tooltip placement="top" title={"Arrived"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopArrived'); toggleEditLoadStatus(); }}>Arrived</Button></Tooltip>
                        </>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'ARRIVED':
                setDisplayStatus('Arrived');
                if (entityType === "STAFF") {
                    if (currentStopType === 'PICK_UP') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Loading"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLoading'); toggleEditLoadStatus(); }}>Loading</Button></Tooltip>
                            </>
                        );
                    } else if (currentStopType === 'DROP_OFF') {
                        setActions(
                            <>
                                <Tooltip placement="top" title={"Layover"}><Button key="2" type="danger" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLayover'); toggleEditLoadStatus(); }} style={{ marginRight: 8 }}>Layover</Button></Tooltip>
                                <Tooltip placement="top" title={"Unloading"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopUnloading'); toggleEditLoadStatus(); }}>Unloading</Button></Tooltip>
                            </>
                        );
                    }
                } else {
                    setActions(null);
                }
                break;
            case 'LOADING':
                setDisplayStatus('Loading');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Loaded"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopLoaded'); toggleEditLoadStatus(); }}>Loaded</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'LOADED':
                setDisplayStatus('Loaded');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'UNLOADING':
                setDisplayStatus('Unloading');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Unloaded"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopUnloaded'); toggleEditLoadStatus(); }}>Unloaded</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'UNLOADED':
                setDisplayStatus('Unloaded');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'AT_STOP':
                setDisplayStatus('At Stop');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Complete Stop"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('stopCompleted'); toggleEditLoadStatus(); }}>Complete Stop</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'COMPLETED':
                setDisplayStatus('Completed');
                if (entityType === "STAFF") {
                    setActions(
                        <Tooltip placement="top" title={"Close Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('closed'); toggleEditLoadStatus(); }}>Close Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                }
                break;
            case 'CLOSED':
                if (entityType === "STAFF") {
                    setDisplayStatus('Closed');
                    setActions(
                        <Tooltip placement="top" title={"Re-Open Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('reOpened'); toggleEditLoadStatus(); }}>Re-Open Load</Button></Tooltip>
                    );
                } else {
                    setActions(null);
                    setDisplayStatus('Completed');
                }
                break;
            case 'CANCELLED':
                setDisplayStatus('Cancelled');
                setActions(null);
                break;
            case 'REOPENED':
                if (entityType === 'STAFF') {
                    setDisplayStatus('Re-Opened');
                    setActions(
                        <Tooltip placement="top" title={"Close Load"}><Button key="1" type="primary" onClick={(e) => { e.stopPropagation(); setActionViewKey('closed'); toggleEditLoadStatus(); }}>Close Load</Button></Tooltip>
                    );
                } else {
                    setDisplayStatus('Completed');
                    setActions(null);
                }
                break;
            default:
                setDisplayStatus('');
                if (entityType === "STAFF") {
                    setActions(null);
                } else {
                    setActions(null);
                }
        }
    };

    const loadStatusComponent = (actionViewKey) => {
        return (
            <Drawer
                title={"Update " + singularEntityName + " Status"}
                width={fullWidth > 720 ? fullWidth / 2 : 360}
                onClose={closeEditLoadStatus}
                visible={showEditLoadStatus}
                bodyStyle={{ paddingBottom: 80 }}
                style={{ zIndex: 1000 }}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
            >
                {!!showEditLoadStatus && actionViews[actionViewKey]}
            </Drawer>
        );
    };

    //#endregion
    //#region useMemos

    useMemo(() => {
        getStatus(currentStatus, load, currentStop, currentStopType, entityType);
    }, [currentStatus, load, currentStop, currentStopType, entityType]);

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={loadEventUpdateStatus === 'PENDING'}>
            <Card bordered={true} title={`Current Status: ${displayStatus}`} className={classes.card} style={props.style ? props.style : null} headStyle={props.headStyle ? props.headStyle : null} extra={actions}>
                <>
                    {(entityType === "SHIPPER") && !!load.isAskingForPostpone && !!load.suggestedPostponeDate && (
                        <>
                            <span>Carrier proposed new load date for: {stringFormatter.toFormattedString("MomentDateTime", load.suggestedPostponeDate, null)} instead of {stringFormatter.toFormattedString("MomentDateTime", load.pickUpDateTime, null)}</span>
                            <p>{load.postponeNote}</p>
                        </>
                    )}
                </>

                {props.children}
            </Card>
            {loadStatusComponent(actionViewKey)}
        </Spin>
    );
};

export default LoadStatusCard;