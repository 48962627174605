import React, { useMemo } from 'react';
import { Alert, Spin } from "antd";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import FormItemDisplay from '../FormItemDisplay/FormItemDisplay';
import Fieldset from '../FormFieldset/FormFieldset';

const RemoveLoadCarrier = props => {
    const load = props.load;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);

    const onSubmit = () => {
        let data = {};
        data.assignedCarrierId = null;
        data.driverIds = [];
        data.assetIds = [];
        //console.log(data);
        // backend should: update carrier for the load, update invoicelineitems (change the accountId for the carrier lines to the new carrier), remove drivers and remove assets
        dispatch(actionCreators.changeCarrierInLoad(load.id, data, load));
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null) {
            cancel();
        }
    }, [isLoading, error]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                <Fieldset>
                    <legend>Remove Carrier from this Load</legend>
                    <FormItemDisplay {...formItemLayout} label="Remove Carrier">
                        <span>Are you sure you want to remove this Carrier from the Load?</span>
                    </FormItemDisplay>
                </Fieldset>
                {error && <Alert message={`${error}`} type="error" />}
            </Spin>
            <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Remove Carrier from Load" />
        </Form>
    );
};

export default RemoveLoadCarrier;