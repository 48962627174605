import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import ForgotPasswordForm from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import irisBackground from '../../assets/img/backgrounds/background-1.jpg';
import classes from "./ForgotPassword.module.scss";
import Logo from "../../components/Logo/Logo";

const ForgotPassword = props => {
    return (
        <Layout className={classes.loginBackground} style={{ minHeight: '100vh', backgroundImage: `url(${irisBackground})` }}>
            <Logo size={"custom"} style={{ margin: '20px auto', height: '100px', padding: 10, }} height={100} />
            <ForgotPasswordForm />
        </Layout>
    );
};

export default withRouter(ForgotPassword);