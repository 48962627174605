import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

const AddEditChangeRemoveButtons = props => {
    let buttonArray = [];

    if (props.showAdd) {
        buttonArray.push(<Tooltip key={`tooltop-1`} placement="top" title={props.addText}><Button key="1" type="primary" icon={<PlusOutlined />} shape='circle' onClick={props.addAction} style={null} /></Tooltip>);
    }
    if (props.showEdit) {
        buttonArray.push(<Tooltip key={`tooltop-2`} placement="top" title={props.editText}><Button key="2" type="primary" icon={<EditOutlined />} shape='circle' onClick={props.editAction} style={null} /></Tooltip>);
    }
    if (props.showChange) {
        buttonArray.push(<Tooltip key={`tooltop-3`} placement="top" title={props.changeText}><Button key="3" type="primary" icon={<SwapOutlined />} shape='circle' onClick={props.changeAction} style={{ marginLeft: '8px' }} /></Tooltip>);
    }
    if (props.showRemove) {
        buttonArray.push(<Tooltip key={`tooltop-4`} placement="top" title={props.removeText}><Button key="4" type="danger" icon={<DeleteOutlined />} shape="circle" onClick={props.removeAction} style={{ marginLeft: '8px' }} /></Tooltip>);
    }

    return buttonArray;
};

export default AddEditChangeRemoveButtons;