import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Tabs } from "antd";
import { useLocation } from 'react-router';
import LinkedShippers from "../../views/LinkedShippers/LinkedShippers";
import LinkedCarriers from "../../views/LinkedCarriers/LinkedCarriers";
import LinkedReceivers from "../../views/LinkedReceivers/LinkedReceivers";
import LinkedProducers from "../../views/LinkedProducers/LinkedProducers";

const { TabPane } = Tabs;

const LinkedAccounts = props => {
    const location = useLocation();
    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const account = useSelector(state => state.accounts.record);

    const [tabKey, setTabKey] = useState('shippers');

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('shippers');
            props.history.replace({ ...location, hash: `#shippers` });
        }
    }, [location]);

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    useMemo(() => {
        dispatch(actionCreators.fetchAccount(entityId));
    }, [entityId]);

    let breadcrumbs = [
        {
            path: `/linkedAccounts`,
            breadcrumbName: 'Accounts',
        },
    ];

    return (
        <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }}>
            <TabPane tab="Shippers" key="shippers">
                <div style={{ padding: 24 }}>
                    <LinkedShippers breadcrumbs={breadcrumbs} />
                </div>
            </TabPane>
            <TabPane tab="Carriers" key="carriers">
                <div style={{ padding: 24 }}>
                    <LinkedCarriers breadcrumbs={breadcrumbs} />
                </div>
            </TabPane>
            <TabPane tab="Receivers" key="receivers">
                <div style={{ padding: 24 }}>
                    <LinkedReceivers breadcrumbs={breadcrumbs} />
                </div>
            </TabPane>
            <TabPane tab="Producers" key="producers">
                <div style={{ padding: 24 }}>
                    <LinkedProducers breadcrumbs={breadcrumbs} />
                </div>
            </TabPane>
        </Tabs>
    );
};

export default withRouter(LinkedAccounts);