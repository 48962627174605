import React, {useState, useMemo, useEffect, useRef} from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Checkbox, Modal, Spin, InputNumber } from 'antd';
import SimpleDataCard from "../../components/SimpleDataCard/SimpleDataCard";
import {faTruck, faTruckLoading, faWarehouse, faTractor, faPlusCircle,} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from '../../components/ActionButton/ActionButton';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import QueueDriversPendingApproval from '../../components/QueueDriversPendingApproval/QueueDriversPendingApproval';
import QueueAccountsPendingVerification from '../../components/QueueAccountsPendingVerification/QueueAccountsPendingVerification';
import QueueDriversMissingDocuments from '../../components/QueueDriversMissingDocuments/QueueDriversMissingDocuments';
import QueueDriversWithExpiringLicenses from '../../components/QueueDriversWithExpiringLicenses/QueueDriversWithExpiringLicenses';
import QueueInTransitLoads from '../../components/QueueInTransitLoads/QueueInTransitLoads';
import QueueCriticalLoads from '../../components/QueueCriticalLoads/QueueCriticalLoads';
import CompanyShipperSnapshot from '../../components/CompanyShipperSnapshot/CompanyShipperSnapshot';
import CompanyCarrierSnapshot from '../../components/CompanyCarrierSnapshot/CompanyCarrierSnapshot';
import { PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import SimpleDataLinkCard from '../../components/SimpleDataLinkCard/SimpleDataLinkCard';
import SimpleStatsLinkCard from '../../components/SimpleStatsLinkCard/SimpleStatsLinkCard';
import Header from '../../components/Header/Header';
import ValueFormatting from '../../shared/ValueFormatting';
import axios from 'axios';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import classes from './Dashboard.module.scss';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import PickupIcon from "../../assets/img/pickUpMarker.png";
import DropOffIcon from "../../assets/img/dropOffMarker.png";
import GPS_ICON from "../../assets/img/GPS_ICON@1x.png";

const stringFormatter = new StringFormatter();

const Dashboard = props => {
    //#region axios
    const CancelToken = axios.CancelToken;
    let cancel;

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isAccountLoading = useSelector(state => state.accounts.isRecordLoading);
    const account = useSelector(state => state.accounts.record);
    const isLoading = useSelector(state => state.metrics.metricsIsLoading);
    const allMetrics = useSelector(state => state.metrics.metrics);
    const loads = useSelector(state => state.loads.records);
    const loadsIsLoading = useSelector(state => state.loads.isLoading);

    //#endregion

    const mapRef = useRef(null);

    //#region useStates
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [zoom, setZoom] = useState(3);
    const [coordinates, setCoordinates] = useState({
        longitude: -94.566234,
        latitude: 40.49944
    })
    const [showNewAccountModal, setShowNewAccountModal] = useState(false);
    const [showNewLoadModal, setShowNewLoadModal] = useState(false);
    const [accountTypes, setAccountTypes] = useState([]);
    const [metrics, setMetrics] = useState(null);
    const [bulkCount, setBulkCount] = useState(1);
    const [imageUrl, setImageUrl] = useState(null);

    //#endregion
    //#region onChanges

    const onChangeAccountTypes = (checkedAccountTypes) => {
        setAccountTypes(checkedAccountTypes)
    };

    const onChangeLoadCount = (count) => {
        setBulkCount(count);
    };

    //#endregion
    //#region toggles

    const toggleNewAccountModal = () => {
        setShowNewAccountModal(!showNewAccountModal);
    };

    const toggleNewLoadModal = () => {
        setShowNewLoadModal(!showNewLoadModal);
    };

    //#endregion
    //#region goTos

    const goToNewLoad = () => {
        props.history.push({
            pathname: "/newLoad",
            state: {
                bulkCount: bulkCount,
                shipperId: null,
                loadLaneId: null,
                duplicateLoadId: null,
            }
        });
    };

    const goToNewAccount = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: accountTypes,
                accountRegistrationId: null
            }
        });
    };

    const goToNewCarrier = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['CARRIER'],
                accountRegistrationId: null
            }
        });
    };

    const goToNewShipper = () => {
        props.history.push({
            pathname: "/newAccount",
            state: {
                accountTypes: ['SHIPPER'],
                accountRegistrationId: null
            }
        });
    };

    const fetchLoads = () => {
        let searchParams = {
            page: 1,
            size: 20,
            sort: 'pickUpDateTime',
            order: 'asc',
            isDeleted: false,
            eta: true
        };
        dispatch(actionCreators.fetchLoads(searchParams, '/orch/loads'));
    }

    //#endregion
    //#region useMemos and useEffects

    useMemo(() => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null) {
            if (entityType === "SHIPPER" || entityType === "CARRIER" || entityType === "PRODUCER" || entityType === "RECEIVER") {
                dispatch(actionCreators.fetchAccount(entityId));
            } else if (entityType === 'STAFF') {
                dispatch(actionCreators.fetchMetrics('IRIS', null));
            }
        }
    }, [entityType, entityId]);

    useEffect(() => {
        if (allMetrics !== undefined && allMetrics !== null && !isEmpty(allMetrics)) {
            if (entityType === 'STAFF') {
                //console.log(allMetrics['IRIS']);
                setMetrics(allMetrics['IRIS']);
            }
        }
    }, [allMetrics, entityType, entityId]);

    useEffect(() => {
        if (account && account.logoFileId) {
            axiosAuthenticated.get('/documents/' + account.logoFileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }).then(res => {
                const blob = new Blob([res.data], {
                    type: 'image/png',
                });
                setImageUrl(URL.createObjectURL(blob));
            }).catch(err => {
                if (axios.isCancel(err)) {
                    //console.log('Request cancelled', err.message);
                } else {
                    console.log(err);
                }
            });
        }

        return () => {
            //console.log("Cleaning up");
            if (cancel !== undefined) {
                cancel('Operation cancelled by the user.');
            }
        };
    }, [account]);

    useEffect(() => {
        fetchLoads()
    }, [])

    useEffect(() => {
        if (entityType === "STAFF" || entityType === "SHIPPER") {
            setupMap(loads);
        }
        return () => {
            if (mapRef.current !== undefined && mapRef.current !== null) {
                console.log('map is shutting down');
                mapRef.current.remove();
            }
        };
    }, [loadsIsLoading, entityType, selectedRoute])

    //#endregion

    const setupMap = (loads) => {
        mapRef.current = new TrimbleMaps.Map({
            container: 'myMap',
            center: new TrimbleMaps.LngLat(coordinates.longitude, coordinates.latitude),
            zoom: zoom,
            //style: TrimbleMaps.Common.Style.TRANSPORTATION,
            //region: TrimbleMaps.Common.Region.NA,
            //displayControlsDefault: true
        });
        // Setting up cluster markers, filtering selected route
        mapRef.current.addControl(new TrimbleMaps.NavigationControl());
        mapRef.current.on('load', function() {
            loads
                .filter(elem => !(selectedRoute && elem.id == selectedRoute.id))
                .forEach((elem, i) => {
                    getCustomClusterMarker(mapRef.current, elem)
                });
            if (selectedRoute) {
                selectedRoute.stops.forEach((elem) => {
                    const div = document.createElement('div');
                    const stopIcon = elem.stopType === 'PICK_UP' ? PickupIcon : (elem.stopType === 'DROP_OFF' ? DropOffIcon : null);

                    div.style.background = "url("+ stopIcon +")"
                    div.style.width = "40px"
                    div.style.height = "40px"
                    div.style.backgroundSize = "contain"
                    div.id = elem.stopLocationId;
                    new TrimbleMaps.Marker({
                        element: div
                    })
                        .setLngLat([elem.longitude, elem.latitude])
                        .addTo(mapRef.current);
                })

                const routeId = selectedRoute.irisId;
                const myRoute = new TrimbleMaps.Route({
                    routeId: routeId,
                    stops: [
                        new TrimbleMaps.LngLat(selectedRoute.origin.longitude, selectedRoute.origin.latitude),
                        new TrimbleMaps.LngLat(selectedRoute.destination.longitude, selectedRoute.destination.latitude),
                    ],
                    vehicleType: TrimbleMaps.Common.VehicleType.TRUCK,
                    routeType: TrimbleMaps.Common.RouteType.PRACTICAL,
                    routeOptimization: TrimbleMaps.Common.RouteOptimization.NONE,
                    highwayOnly: false,
                    distanceUnits: TrimbleMaps.Common.DistanceUnit.MILES,
                    tollDiscourage: true,
                })
                myRoute.addTo(mapRef.current);
            }
        });
    }

    const getCustomClusterMarker = (map, elem) => {
        if (!!elem.current_location && elem.current_location.length) {

            const {longitude, latitude} = elem.current_location[elem.current_location.length - 1]
            const center = [longitude, latitude];
            const content = "<span><image style='width: 40px; height:40px;' src='"+GPS_ICON+"'/></span>";
            // MARKER
            const el = document.createElement('div');
            el.title = elem.origin.stopLocation.name;
            el.innerHTML = content;
            el.id = elem.irisId;

            // POPOVER
            const divLabel = document.createElement('div');
            divLabel.style.display = "none"
            divLabel.style.position = "absolute"
            divLabel.style.width = "200px"
            divLabel.style.background = "#fff"
            divLabel.style.borderRadius = "10px"
            divLabel.style.padding = "10px"
            divLabel.style.bottom = "-50px"
            divLabel.style.left = "105%"

            // POPOVER HTML (LOAD INFO)
            divLabel.innerHTML = `
            <div>
                <div>${elem.irisId}</div>
                <div>Current Status: ${elem.loadStatus}</div>
                <div>Origin: ${elem.origin.stopLocation.name}</div>
                <div>Destination: ${elem.destination.stopLocation.name}</div>
                <div>Shipper: ${elem.shipper.name}</div>
                <div>Carrier: ${elem.assignedCarrier.name}</div>
            </div>
        `

            el.appendChild(divLabel);

            // EVENT LISTENERS
            el.addEventListener('click', (e) => {
                setSelectedRoute(elem)
            })
            el.addEventListener('mouseenter', (e) => {
                divLabel.style.display = "block"
            })
            el.addEventListener('mouseleave', (e) => {
                divLabel.style.display = "none"
            })

            new TrimbleMaps.Marker({
                element: el
            })
                .setLngLat(center)
                .addTo(map);
        }
    }

    if (entityType !== null) {
        if (entityType === "STAFF") {
            return (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true}>
                    <Header
                        title="Welcome to Pulogix"
                        highlights={[
                            {
                                title: "Shippers",
                                value: `${metrics && metrics.totalShippers ? stringFormatter.toFormattedDecimal(metrics.totalShippers, 0, null, null) : 0}`,
                                url: "/shippers"
                            },
                            {
                                title: "Carriers",
                                value: `${metrics && metrics.totalCarriers ? stringFormatter.toFormattedDecimal(metrics.totalCarriers, 0, null, null) : 0}`,
                                url: "/carriers"
                            },
                            {
                                title: "Drivers",
                                value: `${metrics && metrics.totalDrivers ? stringFormatter.toFormattedDecimal(metrics.totalDrivers, 0, null, null) : 0}`,
                                url: "/drivers"
                            },
                            {
                                title: "Assets",
                                value: `${metrics && metrics.totalAssets ? stringFormatter.toFormattedDecimal(metrics.totalAssets, 0, null, null) : 0}`,
                                url: "/assets"
                            },
                        ]}
                    />
                    <div style={{ padding: 24 }}>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={!!loadsIsLoading}>
                                    <div id={"myMap"} style={{height: "40vh", width: "100%", border: "1px solid black", marginBottom: "10px", position: "relative"}}>
                                        {selectedRoute && (
                                            <div style={{
                                                position: "absolute",
                                                width: "225px",
                                                top: "10px",
                                                left: "10px",
                                                padding: "10px",
                                                background: "#fff",
                                                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                                zIndex: "10"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                                    <span className="label">Load: {selectedRoute.irisId}</span>
                                                    <span onClick={() => setSelectedRoute(null)}><FontAwesomeIcon style={{transform: "rotate(45deg)"}} icon={faPlusCircle}/></span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Spin>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <ActionButton
                                    buttonClassName={classes.actionButton}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<PlusOutlined className={classes.icon} />}
                                    buttonText='New Load'
                                    onClick={toggleNewLoadModal}
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <ActionButton
                                    buttonClassName={classes.actionButton}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<PlusOutlined className={classes.icon} />}
                                    buttonText='New Shipper'
                                    onClick={goToNewShipper}
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <ActionButton
                                    buttonClassName={classes.actionButton}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<PlusOutlined className={classes.icon} />}
                                    buttonText='New Carrier'
                                    onClick={goToNewCarrier}
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <ActionButton
                                    buttonClassName={classes.actionButton}
                                    buttonIconClassName={classes.iconContainer}
                                    buttonTextClassName={classes.textContainer}
                                    buttonIcon={<PlusOutlined className={classes.icon} />}
                                    buttonText='New Account'
                                    onClick={toggleNewAccountModal}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleStatsLinkCard style={{ backgroundColor: 'mistyRose' }}
                                    title="Loads Needing Coverage"
                                    date="within 24 hours"
                                    amount={metrics && metrics.loadsNeedingCoverage24hrs ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage24hrs, 0, null, null) : 0}
                                    url="/openLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleStatsLinkCard
                                    title="Loads Needing Coverage"
                                    amount={metrics && metrics.loadsNeedingCoverage ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingCoverage, 0, null, null) : 0}
                                    url="/openLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleStatsLinkCard style={{ backgroundColor: 'mistyRose' }}
                                    title="Loads Needing DispatchAdmin"
                                    date="within 24 hours"
                                    amount={metrics && metrics.loadsNeedingDispatch24hrs ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingDispatch24hrs, 0, null, null) : 0}
                                    url="/bookedLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleStatsLinkCard
                                    title="Loads Needing DispatchAdmin"
                                    amount={metrics && metrics.loadsNeedingDispatch ? stringFormatter.toFormattedDecimal(metrics.loadsNeedingDispatch, 0, null, null) : 0}
                                    url="/bookedLoads"
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataLinkCard
                                    title="Total Active Loads"
                                    data={metrics && metrics.totalActiveLoads ? stringFormatter.toFormattedDecimal(metrics.totalActiveLoads, 0, null, null) : 0}
                                    url="/intransitLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataLinkCard
                                    title="Total Completed Loads"
                                    data={metrics && metrics.totalCompletedLoads ? stringFormatter.toFormattedDecimal(metrics.totalCompletedLoads, 0, null, null) : 0}
                                    url="/completedLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataLinkCard
                                    title="Total Closed Loads"
                                    data={metrics && metrics.totalClosedLoads ? stringFormatter.toFormattedDecimal(metrics.totalClosedLoads, 0, null, null) : 0}
                                    url="/completedLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataCard
                                    title="Total Earnings"
                                    data={metrics && metrics.totalEarnings ? stringFormatter.toFormattedString("Money", metrics.totalEarnings, null) + ' ' + metrics.totalEarningsUnit : ''}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataCard
                                    title="Service Fee"
                                    data={metrics && metrics.serviceFee ? stringFormatter.toFormattedString("Money", metrics.serviceFee, null) + ' ' + metrics.serviceFeeUnit : ''}
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataLinkCard
                                    title="Completed Loads This Month"
                                    data={metrics && metrics.completedLoadsThisMonth ? stringFormatter.toFormattedDecimal(metrics.completedLoadsThisMonth, 0, null, null) : 0}
                                    url="/completedLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataLinkCard
                                    title="Closed Loads This Month"
                                    data={metrics && metrics.closedLoadsThisMonth ? stringFormatter.toFormattedDecimal(metrics.closedLoadsThisMonth, 0, null, null) : 0}
                                    url="/completedLoads"
                                />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <SimpleDataCard
                                    title="Earnings This Month"
                                    data={metrics && metrics.earningsThisMonth ? stringFormatter.toFormattedString("Money", metrics.earningsThisMonth, null) + ' ' + metrics.earningsThisMonthUnit : ''}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueDriversPendingApproval />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueDriversMissingDocuments />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueDriversWithExpiringLicenses />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueAccountsPendingVerification />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueInTransitLoads />
                            </Col>
                            <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }}>
                                <QueueCriticalLoads />
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        title="Choose Account Type"
                        visible={showNewAccountModal}
                        onOk={goToNewAccount}
                        onCancel={toggleNewAccountModal}>
                        <Checkbox.Group style={{ width: '100%' }} onChange={onChangeAccountTypes}>
                            <Row gutter={[4, 4]}>
                                <Col span={24}>
                                    <span><strong>Select all that apply.</strong></span>
                                </Col>
                            </Row>
                            <Row gutter={[4, 4]}>
                                <Col span={6}>
                                    <FontAwesomeIcon size={"2x"} icon={faTruckLoading} style={{ marginRight: '10px' }} />
                                    <Checkbox value="CARRIER"><b>Carrier</b></Checkbox>
                                </Col>
                                <Col span={6}>
                                    <FontAwesomeIcon size={"2x"} icon={faTruck} style={{ marginRight: '10px' }} />
                                    <Checkbox value="SHIPPER"><b>Shipper</b></Checkbox>
                                </Col>
                                <Col span={6}>
                                    <FontAwesomeIcon size={"2x"} icon={faWarehouse} style={{ marginRight: '10px' }} />
                                    <Checkbox value="RECEIVER"><b>Receiver</b></Checkbox>
                                </Col>
                                <Col span={6}>
                                    <FontAwesomeIcon size={"2x"} icon={faTractor} style={{ marginRight: '10px' }} />
                                    <Checkbox value="PRODUCER"><b>Producer</b></Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </Modal>
                    <Modal
                        title="Choose how many Loads you want to Create"
                        visible={showNewLoadModal}
                        onOk={goToNewLoad}
                        onCancel={toggleNewLoadModal}
                    >
                        <InputNumber
                            value={bulkCount}
                            min={1}
                            step={1}
                            max={entityType === 'CARRIER' ? 100 : 1}
                            precision={0}
                            style={{ width: '100%' }}
                            onChange={onChangeLoadCount}
                        />
                        <div><span><i>A value greater than 1 will create a bundle of identical loads.</i></span></div>
                    </Modal>
                </Spin>
            );
        } else if (entityType === "SHIPPER") {
            return (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isAccountLoading === true}>
                    <Header
                        title={account ? account.name : 'Loading...'}
                        title2={account ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                        logo={imageUrl}
                        icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                        highlights={[
                            {
                                title: 'EIN #',
                                value: account ? account.ein : null
                            },
                            {
                                title: 'D&B #',
                                value: account && account.shipper ? account.shipper.dbNum : null
                            },
                            {
                                title: 'PBB #',
                                value: account && account.shipper ? account.shipper.blueBookNum : null
                            },
                        ]}
                    />
                    <Row gutter={[24, 24]}>
                        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                            <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={!!loadsIsLoading}>
                                <div id={"myMap"} style={{height: "40vh", width: "100%", border: "1px solid black", marginBottom: "10px", position: "relative"}}>
                                    {selectedRoute && (
                                        <div style={{
                                            position: "absolute",
                                            width: "225px",
                                            top: "10px",
                                            left: "10px",
                                            padding: "10px",
                                            background: "#fff",
                                            boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                            zIndex: "10"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                                <span className="label">Load: {selectedRoute.irisId}</span>
                                                <span onClick={() => setSelectedRoute(null)}><FontAwesomeIcon style={{transform: "rotate(45deg)"}} icon={faPlusCircle}/></span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Spin>
                        </Col>
                    </Row>
                    {(account !== undefined && account !== null && isLoading === false) ? (
                        <div style={{ padding: 24 }}>
                            <CompanyShipperSnapshot record={account} />
                        </div>
                    ) : null}
                </Spin>
            );
        } else if (entityType === "CARRIER") {
            return (
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isAccountLoading === true}>
                    <Header
                        title={account ? account.name : 'Loading...'}
                        title2={account ? (<ValueFormatting.Address address={account.companyAddress} />) : ''}
                        logo={imageUrl}
                        icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                        highlights={[
                            {
                                title: 'DOT #',
                                value: account && account.carrier ? account.carrier.dotNum : null
                            },
                            {
                                title: 'MC #',
                                value: account && account.carrier ? account.carrier.mcNum : null
                            },
                            {
                                title: 'SMS Score',
                                value: account && account.carrier && account.carrier.smsBasic && account.carrier.smsBasic.weightedAvgSMSScore ? account.carrier.smsBasic.weightedAvgSMSScore : 'N/A'
                            },
                        ]}
                    />
                    {(account !== undefined && account !== null && isLoading === false) ? (
                        <div style={{ padding: 24 }}>
                            <CompanyCarrierSnapshot record={account} />
                        </div>
                    ) : null}
                </Spin>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default withRouter(Dashboard);
