import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { withRouter } from 'react-router-dom';
import { Drawer } from 'antd';
import TrailerType from "../../components/TrailerType/TrailerType";
import EditTrailerType from "../../components/EditTrailerType/EditTrailerType";
import NewTrailerType from "../../components/NewTrailerType/NewTrailerType";
import classes from './TrailerTypes.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from '@fortawesome/free-solid-svg-icons';
import DataTable from "../../components/DataTable/DataTable";
import AddEditChangeRemoveButtons from '../../components/AddEditChangeRemoveButtons/AddEditChangeRemoveButtons';

const TrailerTypes = props => {
    const fullWidth = global.window.innerWidth;
    const singularEntityName = "Trailer Type";
    const pluralEntityName = "Trailer Types";

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.trailerTypes.isLoading);
    const records = useSelector(state => state.trailerTypes.records);
    const pagination = useSelector(state => state.trailerTypes.pagination);

    const [selectedRecord, setSelectedRecord] = useState({});
    const [showEditEntity, setShowEditEntity] = useState(false);
    const [showNewEntity, setShowNewEntity] = useState(false);

    const toggleEditEntity = () => {
        setShowEditEntity(!showEditEntity);
    };

    const toggleNewEntity = () => {
        setShowNewEntity(!showNewEntity);
    };

    const refreshTable = () => {
        let searchParams = {
            page: 1,
            size: 20,
            isDeleted: false
        };

        dispatch(actionCreators.fetchTrailerTypes(searchParams));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...pagination };
        pager.current = pagination.current;
        dispatch(actionCreators.fetchTrailerTypesSuccess({ pagination: pager }));

        let searchParams = {
            page: pagination.current,
            size: pagination.pageSize,
            isDeleted: false
        };

        if (sorter.order !== undefined) {
            const sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc';
            searchParams.order = sortOrder;
        }
        if (sorter.field !== undefined) {
            searchParams.sort = sorter.field;
        }

        dispatch(actionCreators.fetchTrailerTypes(searchParams));
    };

    const columns = [
        {
            title: <FontAwesomeIcon icon={faTools} size="lg" />,
            key: 'actions',
            width: 50,
            render: (text, record) => {
                return (
                    <AddEditChangeRemoveButtons key={`buttons-${record.id}`}
                        showAdd={false} addText='' addAction={null}
                        showEdit={true} editText={"Edit " + singularEntityName + " Details"} editAction={(e) => { e.stopPropagation(); setSelectedRecord(record); toggleEditEntity(); }}
                        showChange={false} changeText='' changeAction={null}
                        showRemove={false} removeText='' removeAction={null}
                    />
                );
            },
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    const newEntityComponents = (
        <Drawer
            title={"New " + singularEntityName}
            onClose={toggleNewEntity}
            visible={showNewEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <NewTrailerType cancel={toggleNewEntity} />
        </Drawer>
    );

    const editEntityComponents = (
        <Drawer
            title={"Edit " + singularEntityName + " Details"}
            onClose={toggleEditEntity}
            visible={showEditEntity}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <EditTrailerType cancel={toggleEditEntity} record={selectedRecord} />
        </Drawer>
    );

    useEffect(() => {
        refreshTable();
    }, []);

    return (
        <DataTable
            dataSource={records}
            columns={columns}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading === true}
            expandedRowRender={record => (<TrailerType record={record} />)}
            rowClassName={classes.dataTableRow}
            singularEntityName={singularEntityName}
            pluralEntityName={pluralEntityName}
            newEntityAction={toggleNewEntity}
            rowKey={record => record.id}
            style={{ backgroundColor: '#ffffff' }}
        >
            {newEntityComponents}
            {editEntityComponents}
        </DataTable>
    );
};

export default withRouter(TrailerTypes);
