import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col, Spin, Table } from "antd";
import classes from "./PriceConfirmation.module.scss";
import InvoiceTable from '../../components/InvoiceTable/InvoiceTable';
import StringFormatter from '../../shared/stringFormatter';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import DataRow from "../../components/DataRow/DataRow";
import DataListRow from '../../components/DataListRow/DataListRow';
import DataTemperatureRangeRow from '../../components/DataTemperatureRangeRow/DataTemperatureRangeRow';
import DataContactInfoRow from '../../components/DataContactInfoRow/DataContactInfoRow';
import DataAddressRow from '../../components/DataAddressRow/DataAddressRow';
import DataPhoneNumberRow from '../../components/DataPhoneNumberRow/DataPhoneNumberRow';
import irisFullLogo from '../../assets/img/upl_insignia.png';
import DataDateTimeRangeRow from '../../components/DataDateTimeRangeRow/DataDateTimeRangeRow';
import Pricing from '../../shared/pricing';
import DataTable from '../../components/DataTable/DataTable';

const stringFormatter = new StringFormatter();

// TODO: update with content, legal content
const PriceConfirmation = props => {
    const loadId = props.match.params.loadId;
    const phoneNumber = '(480) 485-5055';
    const faxNumber = '(480) 485-5055';

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const load = useSelector(state => state.loads.record);
    const invoiceLineItems = useSelector(state => state.invoiceLineItems.records);
    const isInvoiceLineItemsLoading = useSelector(state => state.invoiceLineItems.isLoading);
    const stops = useSelector(state => state.loadStops.records);
    const isStopsLoading = useSelector(state => state.loadStops.isLoading);
    const users = useSelector(state => state.orchestrator.staff);
    const isUsersLoading = useSelector(state => state.users.isLoading);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [BOLList, setBOLList] = useState([]);
    const [commodityList, setCommodityList] = useState([]);
    const [pricingSummary, setPricingSummary] = useState({});

    useMemo(() => {
        dispatch(actionCreators.fetchLoadWithIncludes(loadId));
    }, [loadId]);

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    useMemo(() => {
        if (load !== undefined && load !== null) {
            let hasAccess = true;//(entityType === 'STAFF' || (entityType === 'SHIPPER' && load.shipperId === entityId));

            if (loadId === load.id && hasAccess === true) {
                dispatch(actionCreators.fetchInvoiceLineItems({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchLoadStops(load.id));
            } else if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [load, entityType, entityId]);

    useMemo(() => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let listOfBOLs = new Set();
            let listOfCommodities = new Set();
            stops.forEach(function (stop) {
                if (stop.bolNumber !== undefined && stop.bolNumber !== null && stop.bolNumber !== '') {
                    // if commas are in the bolNumber value then we assume bolNumber has more than one BOL number in it so use split to create an array of bol Numbers to add
                    // if only a single bolNumber is in it, which in that case there are no commas, a single element array will be added to the list
                    let bolNums = stop.bolNumber.split(',');
                    bolNums.forEach(function (item) {
                        listOfBOLs.add(item);
                    });
                }

                if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                    stop.commodities.forEach(function (commodity) {
                        if (commodity.unitPackagingInitCount > 0 || commodity.bulkPackagingInitCount > 0) {
                            listOfCommodities.add(commodity.name);
                        }
                    });
                }
            });
            setBOLList(Array.from(listOfBOLs));
            setCommodityList(Array.from(listOfCommodities));
        }
    }, [stops]);

    useMemo(() => {
        const pricingSummaryValue = Pricing.loadPricingSummary(invoiceLineItems, []);
        setPricingSummary(pricingSummaryValue);
    }, [invoiceLineItems]);

    const invoiceColumns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            textAlign: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'quantity',
            key: 'quantity',
            textAlign: 'center',
            dataType: 'Decimal',
        },
        {
            title: 'Per',
            dataIndex: 'baseAmount',
            key: 'baseAmount',
            textAlign: 'center',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            textAlign: 'right',
            dataType: 'Money',
            render: (text, record) => { return stringFormatter.toFormattedString("Money", text, null); },
        },
    ];

    const commodityColumns = [
        {
            title: 'Commodity',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Unit of Measure/Packaging',
            dataIndex: 'unitPackagingInitCount',
            key: 'unitPackagingInitCount',
            render: (text, record) => { return stringFormatter.toFormattedString("UnitPackagingCount", text, record.unitPackaging); },
        },
        {
            title: 'Bulk Packaging',
            dataIndex: 'bulkPackagingInitCount',
            key: 'bulkPackagingInitCount',
            render: (text, record) => { return stringFormatter.toFormattedString("BulkPackagingCount", text, record.bulkPackaging); },
        },
    ];

    const pageHeader = (title) => {
        return (
            <div className={classes.pageHeader}>
                <div className={classes.heading}>
                    <span className={classes.title}>{title}</span>
                </div>
            </div>
        );
    };

    const stopHeader = (title, requestedDateTime, timeZone, pickUpNumber, dropOffNumber) => {
        return (
            <div className={classes.pageHeader}>
                <div className={classes.heading}>
                    <Row gutter={[4, 4]} style={{ display: 'flex', alignItems: 'center' }}>
                        <Col span={8}>
                            <span className={classes.title}>{title}</span>
                        </Col>
                        <Col span={8}>
                            <span className={classes.title}>{stringFormatter.toFormattedString("MomentDate", requestedDateTime, null, timeZone)}</span>
                        </Col>
                        <Col span={8}>
                            <span className={classes.title}>{pickUpNumber !== undefined && pickUpNumber !== null ? `Pick-Up #: ${pickUpNumber}` : (dropOffNumber !== undefined && dropOffNumber !== null ? `Drop-Off #: ${dropOffNumber}` : null)}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isInvoiceLineItemsLoading === true || isStopsLoading === true || isUsersLoading === true}>
            {load !== undefined && load !== null && load.id !== undefined && load.id !== null && load.id === loadId ? (
                <Layout style={{ minHeight: '100vh', backgroundColor: 'white', margin: 0 }}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <img src={irisFullLogo} alt="Pulogix" style={{ width: 200, marginBottom: 12 }} />
                            <DataAddressRow valueStyle={{ fontWeight: 'bold' }}
                                street1="4445 North 36th Street #312"
                                city="Phoenix"
                                state="AZ"
                                postalCode="85018"
                            />
                            <DataRow valueStyle={{ fontWeight: 'bold' }} value={phoneNumber} dataType="PhoneNumber" />
                        </Col>
                        <Col span={8}>
                            <h2 style={{ textAlign: 'center' }}>Price Confirmation</h2>
                            <h1 style={{ textAlign: 'center' }}>Your Quote is <b>{pricingSummary && pricingSummary.shipperAmountWithAddOns ? stringFormatter.toFormattedMoney(pricingSummary.shipperAmountWithAddOns) : 'N/A'}</b></h1>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <div>Send signed Price Confirmation to:</div>
                            <div><a style={{ color: 'rgba(0, 0, 0, 0.65)' }} href="mailto: priceconfirmation@pulogix.com">priceconfirmation@pulogix.com</a></div>
                            <DataPhoneNumberRow title="Fax #" phone={faxNumber} />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <h3 style={{ textAlign: 'center' }}>Review & Accept to Confirm the load.</h3>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            {pageHeader('Customer Requirements')}
                            {load.equipmentNeeds !== undefined && load.equipmentNeeds !== null ? (
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    {load.equipmentNeeds.trailerType !== undefined && load.equipmentNeeds.trailerType !== null ? (<DataRow title="Trailer Type" value={load.equipmentNeeds.trailerType.description} dataType="String" />) : null}
                                    {load.equipmentNeeds.trailerLength !== undefined && load.equipmentNeeds.trailerLength !== null ? (<DataRow title="Trailer Length" value={load.equipmentNeeds.trailerLength} units={load.equipmentNeeds.trailerLengthUnit} dataType="Length" />) : null}
                                    {load.equipmentNeeds.trailerWeightCapacity !== undefined && load.equipmentNeeds.trailerWeightCapacity !== null ? (<DataRow title="Max Load Weight Capacity" value={load.equipmentNeeds.trailerWeightCapacity} units={load.equipmentNeeds.trailerWeightCapacityUnit} dataType="Weight" />) : null}
                                    {load.equipmentNeeds.preCoolReefer !== undefined && load.equipmentNeeds.preCoolReefer !== null ? (<DataRow title="Pre-cool Temp*" value={load.equipmentNeeds.preCoolReefer} units={load.equipmentNeeds.preCoolReeferUnit} dataType="Temperature" />) : null}
                                    {load.equipmentNeeds.minRunTemp !== undefined && load.equipmentNeeds.minRunTemp !== null && load.equipmentNeeds.maxRunTemp !== undefined && load.equipmentNeeds.maxRunTemp !== null ? (
                                        <>
                                            <DataRow title="Load Temp*" value="Continuous" dataType="String" />
                                            <DataTemperatureRangeRow title="Temp Range*" min={load.equipmentNeeds.minRunTemp} minUnits={load.equipmentNeeds.minRunTempUnit} max={load.equipmentNeeds.maxRunTemp} maxUnits={load.equipmentNeeds.maxRunTempUnit} />
                                        </>
                                    ) : null}
                                    <DataRow title="Team Driving" value={load && load.driverRequirements && load.driverRequirements.length > 0 && load.driverRequirements.includes('TEAM_DRIVING') ? true : false} dataType="Boolean" />
                                    {load.equipmentSpecifics !== undefined && load.equipmentSpecifics !== null && load.equipmentSpecifics.length > 0 ? (
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Equipment Specifics" data={load.equipmentSpecifics} dataType="EquipmentSpecific" />
                                    ) : null}
                                    {load.driverRequirements !== undefined && load.driverRequirements !== null && load.driverRequirements.length > 0 ? (
                                        <DataListRow valueStyle={{ paddingLeft: 16 }} title="Driver Requirements" data={load.driverRequirements} dataType="DriverRequirement" />
                                    ) : null}
                                    {load.equipmentNeeds.preCoolReefer !== undefined && load.equipmentNeeds.preCoolReefer !== null ? <div style={{ textAlign: 'center' }}>* See BOL for Final TEMP</div> : null}
                                </div>
                            ) : null}
                        </Col>
                        <Col span={8}>
                            {pageHeader('Order')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <DataRow title="Load ID" value={load.irisId} dataType="String" />
                                {load.customerOrderNumber !== undefined && load.customerOrderNumber !== null ? <DataRow title="Order #" value={load.customerOrderNumber} dataType="String" /> : null}
                                <DataRow title="Distance" value={load.practicalDistance} units={load.practicalDistanceUnit} dataType="Distance" />
                                <DataListRow valueStyle={{ paddingLeft: 10 }} title="Bids" data={commodityList} dataType="String" />
                                <DataListRow valueStyle={{ paddingLeft: 10 }} title="BOL(s)" data={BOLList} dataType="String" />
                            </div>
                        </Col>
                        <Col span={8}>
                            {pageHeader('Booked By')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <DataRow title="Broker" value="UPL" dataType="String" />

                                {load.assignedAccountRepUsers !== undefined && load.assignedAccountRepUsers !== null && load.assignedAccountRepUsers.length > 0 ?
                                    load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                        return (
                                            <DataContactInfoRow separateLines={true} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} valueStyle={{ marginLeft: 16 }} name={assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName} phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} email={assignedAccountRepUser.user} />
                                        );
                                    }) : null}

                                <DataPhoneNumberRow title="Fax #" phone={faxNumber} />
                                <DataRow title="Date" value={load.createdAt} dataType="MomentDateTime" />
                            </div>
                        </Col>
                    </Row>
                    {stops !== undefined && stops !== null && stops.length > 0 ? (
                        <>
                            {stops.filter(stop => stop.stopType === 'PICK_UP' || stop.stopType === 'DROP_OFF').map((stop, index) => (
                                <Row gutter={20} key={`Stop #${index + 1}`}>
                                    <Col span={24}>
                                        {stopHeader(`Stop #${index + 1}: ${stringFormatter.toFormattedString("StopType", stop.stopType, null)}`, stop.requestedDateTime, stop.timeZone, stop.pickUpNumber, stop.dropOffNumber)}
                                        <Row>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.stopLocation !== undefined && stop.stopLocation !== null ? (
                                                    <>
                                                        {stop.stopLocation.name !== undefined && stop.stopLocation.name !== null ? (<DataRow title="Facility" value={stop.stopLocation.name} dataType="String" />) : null}
                                                        {stop.stopLocation.address !== undefined && stop.stopLocation.address !== null ? (<DataAddressRow valueStyle={{ paddingLeft: 10 }} title="Address" address={stop.stopLocation.address} />) : null}
                                                    </>
                                                ) : null}
                                                {stop.apptPointOfContact !== undefined && stop.apptPointOfContact !== null ? <DataContactInfoRow valueStyle={{ paddingLeft: 10 }} title="Contact" contact={stop.apptPointOfContact} /> : null}
                                            </Col>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.apptType !== undefined && stop.apptType !== null ? (
                                                    <>
                                                        <DataRow title="Appointment Type" value={stop.apptType} dataType="AppointmentType" />
                                                        {stop.apptWindowStartDateTime !== undefined && stop.apptWindowStartDateTime !== null && stop.apptWindowEndDateTime !== undefined && stop.apptWindowEndDateTime !== null ? <DataDateTimeRangeRow title="Appointment Window" startTime={stop.apptWindowStartDateTime} endTime={stop.apptWindowEndDateTime} timeZone={stop.timeZone} /> : null}
                                                        {stop.apptNumber !== undefined && stop.apptNumber !== null ? <DataRow title="Appointment #" value={stop.apptNumber} dataType="String" /> : null}
                                                        {stop.apptDateTime !== undefined && stop.apptDateTime !== null ? <DataRow title="Appointment Date/Time" value={stop.apptDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                                        {stop.apptCallAheadDateTime !== undefined && stop.apptCallAheadDateTime !== null ? <DataRow title="Appointment Call Ahead Date/Time" value={stop.apptCallAheadDateTime} dataType="MomentDateTime" timeZone={stop.timeZone} /> : null}
                                                        {stop.apptInstructions !== undefined && stop.apptInstructions !== null ? <DataRow title="Appointment Instructions" value={stop.apptInstructions} dataType="String" /> : null}
                                                    </>
                                                ) : null}
                                                {stop.isDriverAssistRequested !== undefined && stop.isDriverAssistRequested !== null ? <DataRow title="Driver Load/Unload" value={stop.isDriverAssistRequested} dataType="Boolean" /> : null}
                                                {stop.lumperFee !== undefined && stop.lumperFee !== null && stop.lumperFee > 0 ? <DataRow title="Lumper Load/Unload" value={stop.lumperFee > 0} dataType="Boolean" /> : null}
                                            </Col>
                                            <Col span={8} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {stop.purchaseOrderNumber !== undefined && stop.purchaseOrderNumber !== null ? <DataRow title="Customer PO #" value={stop.purchaseOrderNumber} dataType="String" /> : null}
                                                {stop.bolNumber !== undefined && stop.bolNumber !== null ? <DataRow title="BOL Number" value={stop.bolNumber} dataType="String" /> : null}
                                                {stop.specialInstructions !== null && stop.specialInstructions !== undefined ? (<DataRow title="Facility Notes" value={stop.specialInstructions} dataType="String" />) : null}
                                            </Col>
                                        </Row>
                                        {stop.commodities !== null && stop.commodities !== null && stop.commodities.length > 0 ? (
                                            <DataTable
                                                style={{ marginTop: 16 }}
                                                dataSource={stop.commodities}
                                                columns={commodityColumns}
                                                hidePaging={true}
                                                rowKey={record => record.name}
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            ))}
                        </>
                    ) : null}
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Freight Quote')}
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <InvoiceTable
                                    entityType="SHIPPER"
                                    isPriceConfirmation={true}
                                    data={invoiceLineItems.filter(item => (item.fromEntityType === 'SHIPPER' || item.toEntityType === 'SHIPPER') && (item.approvalStatus === 'PENDING' || item.approvalStatus === 'APPROVED'))}
                                    columns={invoiceColumns}
                                />
                            </div>
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>Please contact Pulogix at {stringFormatter.toFormattedPhoneNumber(phoneNumber)} if the quoted prices are incorrect.</div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            {pageHeader('Agreement')}
                            {load.shipper !== undefined && load.shipper !== null ? (
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <Row>
                                        <Col span={12}>
                                            <DataRow title="Shipper" value={load.shipper.name} dataType="String" />
                                            <DataRow title="Email" value={load.shipper.email} dataType="String" />
                                            <DataPhoneNumberRow title="Phone #" phone={load.shipper.phone} phoneExt={load.shipper.phoneExt} />
                                            {load.shipper.fax !== undefined && load.shipper.fax !== null ? <DataPhoneNumberRow title="Fax #" phone={load.shipper.fax} /> : null}
                                        </Col>
                                        <Col span={12}>
                                            <DataRow title="Broker" value="UPL" dataType="String" />
                                            
                                            {load.assignedAccountRepUsers !== undefined && load.assignedAccountRepUsers !== null && load.assignedAccountRepUsers.length > 0 ?
                                                load.assignedAccountRepUsers.map((assignedAccountRepUser, index, arr) => {
                                                    return (
                                                        <DataContactInfoRow separateLines={true} title={`Rep${arr.length > 1 ? ' ' + (index + 1) : ''}`} valueStyle={{ marginLeft: 16 }} name={assignedAccountRepUser.firstName + ' ' + assignedAccountRepUser.lastName} phone={assignedAccountRepUser.phone} phoneExt={assignedAccountRepUser.phoneExt} email={assignedAccountRepUser.user} />
                                                    );
                                                }) : null}

                                            <DataPhoneNumberRow title="Fax #" phone={faxNumber} />
                                            <DataRow title="Date" value={load.createdAt} dataType="MomentDateTime" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                            <span>By signing below, {load.shipper.name} agrees to the terms and conditions set forth below and provided herewith, if any.</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 8]}>
                                        <Col span={16}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Name & Title (Print):</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Date:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ width: '100%', borderBottom: '1px black solid', marginTop: 30, marginBottom: 10 }}>Signature:</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                            <span><b>PLEASE RETURN THE SIGNED COPY TO <a href="mailto: priceconfirmation@pulogix.com" style={{ color: 'rgba(0, 0, 0, 0.65)' }}>PRICECONFIRMATION@Pulogix.COM</a><br />OR FAX BACK TO {stringFormatter.toFormattedPhoneNumber(faxNumber)} (ATTN: Load Id: {load.irisId})<br />TO ACCEPT THIS RATE AND APPROVE THE LOAD.</b></span>
                                        </Col>
                                    </Row>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Layout>
            ) : null}
        </Spin>
    );
};

export default withRouter(PriceConfirmation);
