import React, { useMemo, useState } from 'react';
import { Select, Alert, Spin, Empty } from "antd";
import { isEmpty, without } from "lodash";
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import StringFormatter from '../../shared/stringFormatter';
import { driverAssignedEmailTemplate, driverUnAssignedEmailTemplate } from "../../shared/emailTemplates";
import FormItem from '../FormItem/FormItem';
import Form from '../Form/Form';
import FormButtons from '../FormButtons/FormButtons';
import Fieldset from '../FormFieldset/FormFieldset';

const { Option } = Select;
const stringFormatter = new StringFormatter();

const ChangeLoadDriver = props => {
    const load = props.load;
    const carrierId = props.carrierId;
    const driver = props.driver;

    const methods = useForm({ mode: 'all', reValidateMode: 'onChange', criteriaMode: 'all', shouldFocusError: true, shouldUnregister: true });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.loads.isRecordUpdateLoading);
    const error = useSelector(state => state.loads.updateRecordError);
    const isAccountUsersLoading = useSelector(state => state.accountUsers.isLoading);
    const accountUsers = useSelector(state => state.accountUsers.records);

    const [oldDriverAccountUserId, setOldDriverAccountUserId] = useState(null);
    const [newDriverAccountUserId, setNewDriverAccountUserId] = useState(null);

    useMemo(() => {
        if (carrierId !== null) {
            dispatch(actionCreators.fetchAccountUsers({ page: 1, size: 100000, isDriver: true, accountId: carrierId, isDeleted: false }));
        }
    }, [carrierId]);

    const sendEmailToDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                let firstName = accountUser.firstName;
                let email = accountUser.email;
                const { body, subject } = driverAssignedEmailTemplate(firstName, load.irisId)
                let newEmail = {
                    body: body,
                    subject: subject,
                    email: email
                };
                axiosAuthenticated.post('/email', newEmail).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };

    const sendEmailToOldDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            if (accountUser.driver.isApproved === undefined || accountUser.driver.isApproved === null || accountUser.driver.isApproved === false) {
                let firstName = accountUser.firstName;
                let email = accountUser.email;
                const { body, subject } = driverUnAssignedEmailTemplate(firstName, load.irisId)
                let newEmail = {
                    body: body,
                    subject: subject,
                    email: email
                };
                axiosAuthenticated.post('/email', newEmail).then(res => {
                    //console.log(res);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    };

    const sendNotificationToDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            let firstName = accountUser.firstName;
            let userId = accountUser.userId;

            let newNotification = {
                message: `Hi ${firstName}, you have been assigned to a new load: ${load.irisId}. Please open up the Pulogix app for more details.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been assigned to a new load`,
                severity: "CRITICAL",
                userIds: [userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_ASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const sendNotificationToOldDriver = (accountUserId) => {
        let accountUser = accountUsers.find(d => d.id === accountUserId);
        if (accountUser !== undefined && accountUser !== null && accountUser.driver !== undefined && accountUser.driver !== null) {
            let firstName = accountUser.firstName;
            let userId = accountUser.userId;

            let newNotification = {
                message: `Hi ${firstName}, you are no longer assigned to load: ${load.irisId}. Please open up the Pulogix app for more details.`,
                loadId: load.id,
                loadIrisId: load.irisId,
                saveOnly: false,
                subject: `${load.irisId} You have been unassigned from a load`,
                severity: "CRITICAL",
                userIds: [userId],
                loadStatus: load.loadStatus,
                eventType: 'DRIVER_UNASSIGNED'
            };

            axiosAuthenticated.post('/loadNotifications', newNotification).then(res => {
                //console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const onSubmit = (data) => {
        if (!isEmpty(data)) {
            //console.log(data);

            const updatedDriverIds = without(load.driverIds, driver.id);
            //console.log(updatedDriverIds);

            // check to see if it is a duplicate and if it is found in the updatedDriverIds array already, throw an error
            if (updatedDriverIds.length > 0) {
                if (updatedDriverIds.find(i => i === data.accountUserId) !== undefined) {
                    methods.setError("accountUserId", { type: "validate", message: "The driver you chose is already assigned to this load."});
                    return;
                } else {
                    methods.clearErrors("accountUserId");
                }
            }

            let updatedDriverIdsWithNewDriver = [...updatedDriverIds, data.accountUserId];
            //console.log(updatedDriverIdsWithNewDriver);

            // removes duplicates
            const updatedDriverIdsWithNewDriver2 = [...new Set(updatedDriverIdsWithNewDriver)];
            //console.log(updatedDriverIdsWithNewDriver2);
            let payload = {};
            payload.driverIds = updatedDriverIdsWithNewDriver2;
            //console.log(payload);
            setOldDriverAccountUserId(driver.id);
            setNewDriverAccountUserId(data.accountUserId);
            dispatch(actionCreators.updateLoad(load.id, payload));
        }
    };

    const cancel = () => {
        dispatch(actionCreators.cancelUpdateLoad());
        props.cancel();
    };

    useMemo(() => {
        // clear any previous errors if this is a new form
        dispatch(actionCreators.updateLoadErrorClear());
    }, []);

    useMemo(() => {
        if (isLoading !== null && isLoading === false && error === null && oldDriverAccountUserId !== undefined && oldDriverAccountUserId !== null && newDriverAccountUserId !== undefined && newDriverAccountUserId !== null && load !== undefined && load !== null) {
            sendNotificationToDriver(newDriverAccountUserId);
            sendEmailToDriver(newDriverAccountUserId)
            sendNotificationToOldDriver(oldDriverAccountUserId);
            sendEmailToOldDriver(oldDriverAccountUserId);

            if (load.loadStatus === 'IN_TRANSIT' || load.loadStatus === 'AT_STOP') {
                dispatch(actionCreators.markDriverAvailable(oldDriverAccountUserId));
                dispatch(actionCreators.markDriverUnavailable(newDriverAccountUserId));
            }

            cancel();
        }
    }, [isLoading, error, oldDriverAccountUserId, newDriverAccountUserId, load]);

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true && error === null}>
                    <Fieldset>
                        <legend>Change the Driver Assigned to this Load</legend>
                        <FormItem {...formItemLayout} label="Choose a Driver" required
                            render={({ onChange, onBlur, value, name }) => (
                                <Select
                                    placeholder="Please Search and Select a Driver"
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    virtual={false}
                                    loading={isAccountUsersLoading === true}
                                    onBlur={onBlur}
                                    onChange={(selected) => { onChange(selected); }}
                                    value={value}
                                    name={name}
                                    showSearch={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, but we couldn't find any Drivers for you to choose from." />}
                                >
                                    {accountUsers.map(d => <Option value={d.id} key={d.id}>{d.firstName + ' ' + d.lastName} - Currently Available: {stringFormatter.toYesNo(d.driver.isAvailable)}</Option>)}
                                </Select>
                            )}
                            rules={{ required: 'Required Field' }}
                            name="accountUserId"
                            defaultValue={driver.id}
                        />
                    </Fieldset>
                    {error && <Alert message={`${error}`} type="error" />}
                </Spin>
                <FormButtons cancel={cancel} disabled={isLoading === true && error === null} submitText="Change Driver" />
            </Form>
        </FormProvider>
    );
};

export default ChangeLoadDriver;