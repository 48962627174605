import StringFormatter from '../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const YesNo = (props) => {
    return stringFormatter.toYesNo(props.value);
};

const FirstNameLastName = (props) => {
    return stringFormatter.toFirstNameLastName(props.value);
};

const PhoneNumber = (props) => {
    return stringFormatter.toFormattedPhoneNumber(props.value);
};

const Money = (props) => {
    return stringFormatter.toFormattedMoney(props.value);
};

const ToDecimal = (props) => {
    let number = props.value ? props.value : 0;
    let decPlaces = props.decPlaces ? props.decPlaces : 2;
    let decSep = props.decSep ? props.decSep : ".";
    let thouSep = props.thouSep ? props.thouSep : ",";

    return stringFormatter.toFormattedDecimal(number, decPlaces, decSep, thouSep);
};

// takes decimal value, multiplies by 100 and adds the percentage symbol
const Percentage = (props) => {
    return stringFormatter.toFormattedPercentage(props.value);
};

const Address = (props) => {
    return stringFormatter.toFormattedAddress(props);
};

export default {
    YesNo,
    FirstNameLastName,
    PhoneNumber,
    Money,
    ToDecimal,
    Percentage,
    Address,
};