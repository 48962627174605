import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getTrailerTypes} from "./orchestrator";
import logger from "../../shared/logger";

//#region Trailer Types Functions

export const fetchTrailerTypesStart = () => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_START
    }
};

export const fetchTrailerTypesSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_SUCCESS,
        payload: payload
    }
};

export const fetchTrailerTypesFail = (payload) => {
    return {
        type: actionTypes.FETCH_TRAILER_TYPES_FAIL,
        payload: payload
    }
};

export const clearTrailerTypes = () => {
    return {
        type: actionTypes.CLEAR_TRAILER_TYPES
    }
};

export const addTrailerTypeStart = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_START
    }
};

export const addTrailerTypeSuccess = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_SUCCESS
    }
};

export const addTrailerTypeFail = (payload) => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_FAIL,
        payload: payload
    }
};

export const addTrailerTypeErrorClear = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_ERROR_CLEAR
    }
};

export const addTrailerTypeLoadingClear = () => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE_LOADING_CLEAR
    }
};

export const updateTrailerTypeStart = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_START
    }
};

export const updateTrailerTypeSuccess = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_SUCCESS
    }
};

export const updateTrailerTypeFail = (payload) => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_FAIL,
        payload: payload
    }
};

export const updateTrailerTypeErrorClear = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_ERROR_CLEAR
    }
};

export const updateTrailerTypeLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE_LOADING_CLEAR
    }
};

export const cancelAddTrailerType = () => {
    return {
        type: actionTypes.CANCEL_ADD_TRAILER_TYPE
    }
};

export const cancelUpdateTrailerType = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_TRAILER_TYPE
    }
};

export const insertTrailerType = (payload) => {
    return {
        type: actionTypes.ADD_TRAILER_TYPE,
        payload: payload
    }
};

export const changeTrailerType = (payload) => {
    return {
        type: actionTypes.UPDATE_TRAILER_TYPE,
        payload: payload
    }
};

export const changeSingleTrailerType = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_TRAILER_TYPE,
        payload: payload
    }
};

export const removeTrailerType = (payload) => {
    return {
        type: actionTypes.REMOVE_TRAILER_TYPE,
        payload: payload
    }
};

//#endregion

//#region Trailer Types Methods

export const fetchTrailerTypes = (payload) => {
    return async (dispatch, getState) => {
        const trailerTypesPath = '/trailerTypes';
        try {
            dispatch(fetchTrailerTypesStart());

            const state = getState();
            const trailerTypesState = {...state.trailerTypes};
            let pagination = {...trailerTypesState.pagination};
            let searchParams = {...trailerTypesState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const trailerTypesRes = await axiosAuthenticated.get(trailerTypesPath, {params: {...searchParams}});
            if (trailerTypesRes && trailerTypesRes.status === 200) {
                const trailerTypes = trailerTypesRes.data.data;

                // Read total count from server
                pagination.total = trailerTypesRes.data.totalCount;
                pagination.current = trailerTypesRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchTrailerTypesSuccess({
                    records: trailerTypes,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchTrailerTypesFail({error: error.message}));
        }
    }
};

export const addTrailerType = (payload) => {
    return async (dispatch) => {
        const trailerTypesPath = '/trailerTypes';
        try {
            dispatch(addTrailerTypeStart());

            const trailerTypesRes = await axiosAuthenticated.post(trailerTypesPath, {...payload});
            if (trailerTypesRes && trailerTypesRes.status === 201) {
                let newTrailerType = trailerTypesRes.data;

                dispatch(insertTrailerType(newTrailerType));

                // refresh the trailerType orchestrator
                dispatch(getTrailerTypes(true));

                dispatch(addTrailerTypeSuccess());
                dispatch(addTrailerTypeLoadingClear());
                dispatch(addTrailerTypeErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addTrailerTypeFail({error: error.message}));
        }
    }
};

export const updateTrailerType = (trailerTypeId, payload) => {
    return async (dispatch) => {
        const trailerTypesPath = '/trailerTypes';
        try {
            dispatch(updateTrailerTypeStart());

            const trailerTypesRes = await axiosAuthenticated.put(trailerTypesPath + `/${trailerTypeId}`, {...payload});
            if (trailerTypesRes && trailerTypesRes.status === 200) {
                let updatedTrailerType = trailerTypesRes.data;

                if (updatedTrailerType.isDeleted === true) {
                    dispatch(removeTrailerType(updatedTrailerType));
                } else {
                    dispatch(changeTrailerType(updatedTrailerType));
                    dispatch(changeSingleTrailerType(updatedTrailerType));
                }

                // refresh the trailerType orchestrator
                dispatch(getTrailerTypes(true));

                dispatch(updateTrailerTypeSuccess());
                dispatch(updateTrailerTypeLoadingClear());
                dispatch(updateTrailerTypeErrorClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateTrailerTypeFail({error: error.message}));
        }
    }
};

//#endregion