import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Input, Upload, Alert, Select } from "antd";
import DatePicker from '../DatePickerAutoAccept/DatePickerAutoAccept';
import uuid from 'react-uuid';
import axiosAuthenticated from "../../api/axiosAuthenticated";
import { Controller, useFormContext } from 'react-hook-form';
import Enums from '../../shared/enums';
import { useSelector } from 'react-redux';

const UploadFile = props => {
    const entityType = props.entityType;
    const account = props.account;
    const onRemove = props.onRemove;
    const beforeUpload = props.beforeUpload;
    const displayNameInputName = props.displayNameInputName;
    const multiple = props.multiple !== undefined && props.multiple !== null && props.multiple === true ? true : false;

    const methods = useFormContext();

    const userEntityType = useSelector(state => state.auth.entityType);

    // Limit only to one file
    const [fileList, setFileList] = useState([]);

    // Only if there is already a file
    const [description, setDescription] = useState(null);
    const [displayName, setDisplayName] = useState(null);

    const getDocumentTypes = () => {
        let children = [];
        if (entityType) {
            if (entityType === 'STOP') {
                children = Enums.StopDocumentTypes.selectListOptions();
            } else if (entityType === 'DRIVER') {
                children = Enums.DriverDocumentTypes.selectListOptions();
            } else if (entityType === 'ACCOUNT_SHIPPER') {
                children = Enums.ShipperDocumentTypes.selectListOptions();
            } else if (entityType === 'ACCOUNT_CARRIER') {
                children = Enums.CarrierDocumentTypes.selectListOptions();
            } else if (entityType === 'ACCOUNT_RECEIVER') {
                children = Enums.ReceiverDocumentTypes.selectListOptions();
            } else if (entityType === 'ACCOUNT_PRODUCER') {
                children = Enums.ProducerDocumentTypes.selectListOptions();
            } else if (entityType === 'ACCOUNT') {
                if (account !== undefined && account !== null) {
                    let accountChildren = [];
                    accountChildren.push(Enums.AccountDocumentTypes.selectListOptions());
                    if (account.isShipper === true) {
                        accountChildren.push(Enums.ShipperDocumentTypes.selectListOptions());
                    }
                    if (account.isCarrier === true) {
                        accountChildren.push(Enums.CarrierDocumentTypes.selectListOptions());
                    }
                    if (account.isProducer === true) {
                        accountChildren.push(Enums.ProducerDocumentTypes.selectListOptions());
                    }
                    if (account.isReceiver === true) {
                        accountChildren.push(Enums.ReceiverDocumentTypes.selectListOptions());
                    }
                    children = [... new Set(accountChildren)];
                } else {
                    children = Enums.AccountDocumentTypes.selectListOptions();
                }
            } else if (entityType === 'LOAD') {
                children = userEntityType === "STAFF" ? Enums.LoadDocumentTypes.selectListOptions() : Enums.LoadDocumentTypes.selectListOptionsWithExclusions(["PRICE_CONFIRMATION", "RATE_CONFIRMATION"]);
            } else if (entityType === 'CLAIM') {
                children = Enums.ClaimDocumentTypes.selectListOptions();
            } else if (entityType === 'ASSET') {
                children = Enums.AssetDocumentTypes.selectListOptions();
            } else if (entityType === 'USER') {
                children = Enums.UserDocumentTypes.selectListOptions();
            } else if (entityType === 'TRANSPORT_UNIT') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else if (entityType === 'LOAD_CANCEL') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else if (entityType === 'MESSAGE') {
                children = Enums.OtherDocumentTypes.selectListOptions();
            } else {
                children = Enums.OtherDocumentTypes.selectListOptions();
            }
        }

        return children;
    };

    const onDocumentTypeChange = (value) => {
        if (value !== null && value !== undefined) {
            let displayName = Enums.DocumentDisplayNames.getValueByName(value);
            if (displayName !== null && displayName !== undefined) {
                methods.setValue(displayNameInputName, displayName);
            } else {
                methods.setValue(displayNameInputName, null);
            }
        } else {
            methods.setValue(displayNameInputName, null);
        }
    };

    const handleChange = (info) => {
        let fileList = [...info.fileList];
        if (multiple === false) {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
        }

        // console.log(info);
        
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        // console.log('handleChange fileList')
        // console.log(fileList)

        methods.setValue(props.name ? props.name : 'file', info.file);

        setFileList(fileList);

        if (props.setFileList !== undefined) {
            props.setFileList(fileList);
        }
    };

    //Display current file if one is present
    useEffect(() => {
        if (props.fileId) {
            // Get File
            axiosAuthenticated.get('/documents/' + props.fileId, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'image/png,image/jpeg,image/jpg'
                }
            }).then(res => {

                if (res && res.status === 200) {
                    const blob = new Blob([res.data], {
                        type: 'image/png',
                    });
                    // Get File Metadata
                    axiosAuthenticated.get('/documents', {
                        params: {
                            'id': props.fileId
                        }
                    }).then(res => {
                        if (res && res.status === 200) {
                            const fileMetaData = res.data.data[0];

                            if (blob) {
                                const file = new File([blob], fileMetaData.fileName, { type: "image/png" });
                                // Add the file to the File List, this will allow the file to be displayed in the component
                                let fileList = [{
                                    originFileObj: file,
                                    uid: uuid(),
                                    type: "image/png",
                                    name: fileMetaData.fileName,
                                    size: fileMetaData.fileSize,
                                    percent: 0
                                }];

                                methods.setValue(props.name ? props.name : 'file', file);

                                // 2. Read from response and show file link
                                fileList = fileList.map(file => {
                                    if (file.response) {
                                        // Component will show file.url as link
                                        file.url = file.response.url;
                                    }
                                    return file;
                                });
                                setDescription(fileMetaData.description);
                                setDisplayName(fileMetaData.displayName);
                                setFileList(fileList);
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [props.fileId]);

    useEffect(() => {
        if (props.fileList !== undefined && props.fileList !== null) {
            // console.log('props.fileList');
            // console.log(props.fileList);
            setFileList(props.fileList);
        }
    }, [props.fileList]);

    const uploadProps = {
        name: props.name ? props.name : "file",
        accept: ".png,.jpg,.jpeg,.pdf",
        multiple: multiple,
        actions: "image/png,image/jpg,image/jpeg,application/pdf",
        showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: false
        },
        listType: 'picture',
        onChange: handleChange,
        onRemove: onRemove,
        fileList: fileList,
        beforeUpload: beforeUpload,
        // Disables auto sending to the server
        customRequest: ({ onSuccess, onError, file }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        }
    };

    return (
        <div style={{ display: 'block' }}>
            {props.documentTypeInputName ? (
                <>
                    <Controller control={methods.control}
                        render={({ onChange, onBlur, value, name }) => (
                            <Select
                                placeholder="Please Select a Document Type"
                                allowClear={true}
                                style={{ width: '100%' }}
                                virtual={false}
                                onBlur={onBlur}
                                onChange={(selected) => { onDocumentTypeChange(selected); onChange(selected); }}
                                value={value}
                                name={name}
                            >
                                {getDocumentTypes()}
                            </Select>
                        )}
                        rules={{ required: 'Required Field' }}
                        name={props.documentTypeInputName}
                    />
                    {methods.errors[props.documentTypeInputName] && (<Alert message={methods.errors[props.documentTypeInputName].message} type="error" />)}
                </>
            ) : null}
            {props.displayNameInputName ? (
                <Controller control={methods.control}
                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="File Display Name" />}
                    rules={{ required: false }}
                    name={props.displayNameInputName}
                    defaultValue={props.displayName ? props.displayName : (props.fileId && displayName ? displayName : null)}
                />
            ) : null}
            {props.descriptionInputName ? (
                <Controller control={methods.control}
                    render={({ onChange, onBlur, value, name }) => <Input onBlur={onBlur} onChange={e => { onChange(e.target.value); }} value={value} name={name} placeholder="File Description" />}
                    rules={{ required: false }}
                    name={props.descriptionInputName}
                    defaultValue={props.fileId && description ? description : null}
                />
            ) : null}
            {props.expirationDateInputName ? (
                <>
                    <Controller control={methods.control}
                        render={({ onChange, onBlur, value, name }) => (
                            <DatePicker
                                placeholder="Expiration Date"
                                style={{ width: '100%' }}
                                onBlur={onBlur}
                                onChange={(date, dateString) => { onChange(date); }}
                                value={value}
                                name={name}
                            />
                        )}
                        rules={{ required: 'Required Field' }}
                        name={props.expirationDateInputName}
                    />
                    {methods.errors[props.expirationDateInputName] && (<Alert message={methods.errors[props.expirationDateInputName].message} type="error" />)}
                </>
            ) : null}
            <Upload.Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload.</p>
                <p className="ant-upload-hint">Only .png, .jpg, .jpeg, or .pdf files.</p>
                <p className="ant-upload-hint">{props.message}</p>
            </Upload.Dragger>
        </div>
    );
};

export default UploadFile;