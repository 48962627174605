import React from 'react';
import classes from './DetailsListDataRow.module.scss';

const DetailsListDataRow = props => {
    return (
        <tr className={classes.dataRow}>
            <th className={classes.dataRowLabel}>{props.label}</th>
            <td className={classes.dataRowContent} colSpan={props.colSpan}>{props.children}</td>
        </tr>
    );
};

export default DetailsListDataRow;