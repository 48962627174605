import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Empty, List, Drawer, Avatar, Tooltip, Button, Alert } from "antd";
import classes from './MessagesList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPallet, faEnvelopeOpen, faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import MomentDate from '../../shared/dateFormatter';
import InfiniteScroll from 'react-infinite-scroller';
import { isEmpty } from 'lodash';

const MessagesList = (props) => {
    const fullWidth = global.window.innerWidth;
    const onClose = props.onClose;
    const visible = props.visible;
    const scrollParentRef = useRef(null);

    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.messages.isLoading);
    const allMessages = useSelector(state => state.messages.records);
    const error = useSelector(state => state.messages.addRecordError);
    const userId = useSelector(state => state.auth.userId);
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [messages, setMessages] = useState([]);

    const markRead = (id) => {
        dispatch(actionCreators.readMessage(id, userId));
    };

    // const markAllRead = () => {
    //     //console.log('mark all read');

    //     let unreadMessages = messages.filter(n => {
    //         return !n.read.some(r => { return r.readBy === userId; });
    //     });

    //     //console.log(unreadMessages);

    //     let unreadMessageIds = unreadMessages.map(item => {
    //         return item.id;
    //     });

    //     dispatch(actionCreators.readMultipleMessages(unreadMessageIds, userId));
    // };

    const viewAll = () => {
        props.history.push({ pathname: "/messages" });
        onClose();
    };

    const goToLoad = (loadId) => {
        props.history.push({ pathname: "/loads/" + loadId, hash: 'messages' });
        onClose();
    };

    const refreshList = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
            dispatch(actionCreators.fetchMessages(entityType, entityId, userId, 10));
        }
    };

    useEffect(() => {
        if (allMessages !== undefined && allMessages !== null && !isEmpty(allMessages)) {
            if (allMessages['ALL'] !== undefined && allMessages['ALL'] !== null && !isEmpty(allMessages['ALL']) && allMessages['ALL'].records !== undefined && allMessages['ALL'].records !== null) {
                const copyOfRecord = { ...allMessages['ALL'] };
                //console.log(copyOfRecord.records.length);
                setMessages([...copyOfRecord.records]);
                setTotalPages(copyOfRecord.totalPages);
                setCurrentPage(copyOfRecord.currentPage);
            }
        }
    }, [allMessages]);

    useEffect(() => {
        if (allMessages.length === 0) {
            refreshList();
        }
    }, [entityType, entityId, userId]);

    const handleInfiniteOnLoad = () => {
        if (entityType !== undefined && entityType !== null && entityId !== undefined && entityId !== null && userId !== undefined && userId !== null) {
            //console.log(`Current Page: ${currentPage}, Total Pages: ${totalPages}`);
            if (currentPage + 1 <= totalPages && isLoading === false) {
                //console.log('get more');
                dispatch(actionCreators.fetchMoreMessages(entityType, entityId, userId));
            } else {
                //console.log('no more items to get');
            }
        }
    };

    return (
        <Drawer
            title={'Messages'}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            bodyStyle={{ overflow: 'hidden' }}
            drawerStyle={{ overflow: 'hidden' }}
            destroyOnClose={true}
            closable={true}
            maskClosable={true}
        >
            {(messages.length === 0) ? (
                <>
                    <Empty
                        imageStyle={{
                            height: 60,
                        }}
                        description={
                            <span>No New or Unread Messages</span>
                        }
                    >
                        <Button type="primary" onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</Button>
                    </Empty>
                    <div className={classes.bottomBar}>
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
            {(messages.length > 0) ? (
                <>
                    {error && <Alert message={`${error}`} type="error" />}

                    <div className={classes.infiniteScrollContainer} ref={(ref) => scrollParentRef.current = ref}>
                        <InfiniteScroll
                            initialLoad={false}
                            pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={isLoading === false && currentPage + 1 <= totalPages}
                            useWindow={false}
                            getScrollParent={() => scrollParentRef.current}
                        >
                            <List
                                itemLayout="vertical"
                                dataSource={messages}
                                size="small"
                                loading={isLoading === true}
                                renderItem={(item, i) => {

                                    const itemClass = item.read.some(r => { return r.readBy === userId; }) ? classes.read : '';
                                    let leftIcon = <Avatar style={{ backgroundColor: 'gray', color: '#00000075' }}><FontAwesomeIcon icon={faCommentAlt} size="lg" /></Avatar>;

                                    let actionButtons = [];
                                    if (!item.read.some(r => { return r.readBy === userId; })) {
                                        actionButtons.push(<Tooltip placement="top" title={'Mark Read'}><Button key="1" type="link" onClick={(e) => { e.stopPropagation(); markRead(item.id); }} style={null}><FontAwesomeIcon icon={faEnvelopeOpen} size="sm" /> <span>&nbsp;&nbsp;Mark Read</span></Button></Tooltip>);
                                    }
                                    actionButtons.push(<Tooltip placement="top" title={'Go To Load'}><Button key="2" type="link" onClick={(e) => { e.stopPropagation(); goToLoad(item.loadId); }} style={null}><FontAwesomeIcon icon={faPallet} size="sm" /> <span>{`  Go To Load ${item.loadIrisId || ''}`}</span></Button></Tooltip>);

                                    return (
                                        <List.Item
                                            className={classes.item + ' ' + itemClass}
                                            key={item.id}
                                            actions={actionButtons}>
                                            <List.Item.Meta
                                                avatar={leftIcon}
                                                className={classes.meta}
                                                title={
                                                    <>
                                                        <div className={classes.title}>{`${item.senderFirstName} ${item.senderLastName}${item.loadIrisId ? ' to ' + item.loadIrisId : ''}:`}</div>
                                                    </>
                                                }
                                                description={
                                                    <>
                                                        <div className={classes.description}>
                                                            <div className={classes.description}>{item.message}</div>
                                                            <div className={classes.dateTime}>{new MomentDate().fromUtcToDeviceTimeZoneForMessages(item.createdAt)}</div>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                    <div className={classes.bottomBar}>
                        {/* <div onClick={(e) => { e.stopPropagation(); markAllRead(); }}>Mark All Read</div> */}
                        <div onClick={(e) => { e.stopPropagation(); viewAll(); }}>View All</div>
                    </div>
                </>
            ) : null}
        </Drawer>
    );
};

export default withRouter(MessagesList);