import * as actionTypes from "../actions/actionTypes";
import {
    fetchRecordsStart,
    fetchRecordsSuccess,
    fetchRecordsFail,
    clearRecords,
    addRecordStart,
    addRecordSuccess,
    addRecordFail,
    clearAddRecordError,
    clearAddRecordLoading,
    updateRecordFail,
    updateRecordStart,
    updateRecordSuccess,
    clearUpdateRecordError,
    clearUpdateRecordLoading,
    cancelAddRecord,
    cancelUpdateRecord,
    addRecordToBottom,
    updateRecord,
    removeRecord,
    fetchRecordStart,
    fetchRecordSuccess,
    fetchRecordFail,
    clearRecord,
    updateSingleRecord
} from "../utility";

const initialState = {
    records: [],
    record: null,
    isLoading: false,
    isRecordLoading: false,
    isRecordAddLoading: null,
    isRecordUpdateLoading: null,
    error: null,
    recordError: null,
    addRecordError: null,
    updateRecordError: null,
    searchParams: {},
    pagination: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SCHEDULED_SHIFTS_START: return fetchRecordsStart(state);
        case actionTypes.FETCH_SCHEDULED_SHIFTS_SUCCESS: return fetchRecordsSuccess(state, action.payload);
        case actionTypes.FETCH_SCHEDULED_SHIFTS_FAIL: return fetchRecordsFail(state, action.payload);
        case actionTypes.CLEAR_SCHEDULED_SHIFTS: return clearRecords(state);
        case actionTypes.FETCH_SCHEDULED_SHIFT_START: return fetchRecordStart(state);
        case actionTypes.FETCH_SCHEDULED_SHIFT_SUCCESS: return fetchRecordSuccess(state, action.payload);
        case actionTypes.FETCH_SCHEDULED_SHIFT_FAIL: return fetchRecordFail(state, action.payload);
        case actionTypes.CLEAR_SCHEDULED_SHIFT: return clearRecord(state);
        case actionTypes.ADD_SCHEDULED_SHIFT: return addRecordToBottom(state, action.payload);
        case actionTypes.ADD_SCHEDULED_SHIFT_START: return addRecordStart(state);
        case actionTypes.ADD_SCHEDULED_SHIFT_SUCCESS: return addRecordSuccess(state);
        case actionTypes.ADD_SCHEDULED_SHIFT_FAIL: return addRecordFail(state, action.payload);
        case actionTypes.ADD_SCHEDULED_SHIFT_ERROR_CLEAR: return clearAddRecordError(state);
        case actionTypes.ADD_SCHEDULED_SHIFT_LOADING_CLEAR: return clearAddRecordLoading(state);
        case actionTypes.CANCEL_ADD_SCHEDULED_SHIFT: return cancelAddRecord(state);
        case actionTypes.UPDATE_SCHEDULED_SHIFT: return updateRecord(state, action.payload);
        case actionTypes.UPDATE_SINGLE_SCHEDULED_SHIFT: return updateSingleRecord(state, action.payload);
        case actionTypes.UPDATE_SCHEDULED_SHIFT_START: return updateRecordStart(state);
        case actionTypes.UPDATE_SCHEDULED_SHIFT_SUCCESS: return updateRecordSuccess(state);
        case actionTypes.UPDATE_SCHEDULED_SHIFT_FAIL: return updateRecordFail(state, action.payload);
        case actionTypes.UPDATE_SCHEDULED_SHIFT_ERROR_CLEAR: return clearUpdateRecordError(state);
        case actionTypes.UPDATE_SCHEDULED_SHIFT_LOADING_CLEAR: return clearUpdateRecordLoading(state);
        case actionTypes.CANCEL_UPDATE_SCHEDULED_SHIFT: return cancelUpdateRecord(state);
        case actionTypes.REMOVE_SCHEDULED_SHIFT: return removeRecord(state, action.payload);
        default:
            return state;
    }
};

export default reducer;