import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {getFilesMetaData} from "../../api/fileClient";
import {getAccounts} from "./orchestrator";
import logger from "../../shared/logger";
import EntityUtils from '../entityUtils';

//#region LinkedAccounts Functions

export const fetchLinkedAccountsStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNTS_START
    }
};

export const fetchLinkedAccountsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNTS_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedAccountsFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNTS_FAIL,
        payload: payload
    }
};

export const clearLinkedAccounts = () => {
    return {
        type: actionTypes.CLEAR_LINKED_ACCOUNTS
    }
};

export const fetchLinkedAccountStart = () => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_START
    }
};

export const fetchLinkedAccountSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_SUCCESS,
        payload: payload
    }
};

export const fetchLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.FETCH_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const clearLinkedAccount = () => {
    return {
        type: actionTypes.CLEAR_LINKED_ACCOUNT
    }
};

export const addLinkedAccountStart = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_START
    }
};

export const addLinkedAccountSuccess = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_SUCCESS
    }
};

export const addLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const addLinkedAccountErrorClear = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_ERROR_CLEAR
    }
};

export const addLinkedAccountLoadingClear = () => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT_LOADING_CLEAR
    }
};

export const updateLinkedAccountStart = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_START
    }
};

export const updateLinkedAccountSuccess = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_SUCCESS
    }
};

export const updateLinkedAccountFail = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_FAIL,
        payload: payload
    }
};

export const updateLinkedAccountErrorClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_ERROR_CLEAR
    }
};

export const updateLinkedAccountLoadingClear = () => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT_LOADING_CLEAR
    }
};

export const cancelAddLinkedAccount = () => {
    return {
        type: actionTypes.CANCEL_ADD_LINKED_ACCOUNT
    }
};

export const cancelUpdateLinkedAccount = () => {
    return {
        type: actionTypes.CANCEL_UPDATE_LINKED_ACCOUNT
    }
};

export const insertLinkedAccount = (payload) => {
    return {
        type: actionTypes.ADD_LINKED_ACCOUNT,
        payload: payload
    }
};

export const changeLinkedAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_LINKED_ACCOUNT,
        payload: payload
    }
};

export const changeSingleLinkedAccount = (payload) => {
    return {
        type: actionTypes.UPDATE_SINGLE_LINKED_ACCOUNT,
        payload: payload
    }
};

export const removeLinkedAccount = (payload) => {
    return {
        type: actionTypes.REMOVE_LINKED_ACCOUNT,
        payload: payload
    }
};

//#endregion

//#region LinkedAccounts Methods

export const fetchLinkedAccounts = (payload) => {
    return async (dispatch, getState) => {
        const linkedAccountsPath = '/linkedAccounts';
        try {
            dispatch(fetchLinkedAccountsStart());

            const state = getState();
            const linkedAccountsState = {...state.linkedAccounts};
            let pagination = {...linkedAccountsState.pagination};
            let searchParams = {...linkedAccountsState.searchParams};

            if (payload !== null) {
                searchParams = {...payload};
            }

            const linkedAccountsRes = await axiosAuthenticated.get(linkedAccountsPath, {params: {...searchParams}});
            if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                const linkedAccounts = linkedAccountsRes.data.data;

                let linkedAccountIds = linkedAccounts.map(item => {
                    return item.linkedAccountId;
                });

                let linkedAccountDocuments = [];
                const linkedAccountDocumentsRes = await axiosAuthenticated.post('/documents/bulk/get', {
                    page: 1,
                    size: 10000,
                    entityId: [...linkedAccountIds]
                });
                if (linkedAccountDocumentsRes && linkedAccountDocumentsRes.status === 200) {
                    linkedAccountDocuments = linkedAccountDocumentsRes.data.data;
                }

                const transformedLinkedAccounts = linkedAccounts.map((item) => {
                    let fileMetaData = [];
                    if (linkedAccountDocuments.length > 0) {
                        fileMetaData = linkedAccountDocuments.filter(document => document.entityId === item.linkedAccountId);
                    }

                    let {logoFileId} = EntityUtils.getAccountFileIdsAndDates(fileMetaData);

                    return {
                        ...item,
                        logoFileId: logoFileId ? logoFileId : null
                    };
                });

                // Read total count from server
                pagination.total = linkedAccountsRes.data.totalCount;
                pagination.current = linkedAccountsRes.data.currentPage;
                pagination.pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;

                dispatch(fetchLinkedAccountsSuccess({
                    records: transformedLinkedAccounts,
                    searchParams: searchParams,
                    pagination: pagination
                }));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedAccountsFail({error: error.message}));
        }
    }
};

export const fetchLinkedAccount = (id) => {
    return async (dispatch) => {
        const linkedAccountsPath = '/linkedAccounts';
        try {
            dispatch(fetchLinkedAccountStart());

            const linkedAccountsRes = await axiosAuthenticated.get(linkedAccountsPath + `/${id}`);
            if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                let linkedAccount = linkedAccountsRes.data;

                const fileMetaData = await getFilesMetaData(linkedAccount.linkedAccountId);

                let {logoFileId} = EntityUtils.getAccountFileIdsAndDates(fileMetaData);
                if (logoFileId) {
                    linkedAccount.logoFileId = logoFileId ? logoFileId : null;
                }

                dispatch(fetchLinkedAccountSuccess({record: linkedAccount}));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(fetchLinkedAccountFail({error: error.message}));
        }
    }
};

export const addLinkedAccount = (payload) => {
    return async (dispatch) => {
        const linkedAccountsPath = '/linkedAccounts';
        try {
            dispatch(addLinkedAccountStart());

            const linkedAccountsRes = await axiosAuthenticated.post(linkedAccountsPath, {...payload});
            if (linkedAccountsRes && linkedAccountsRes.status === 201) {
                let newLinkedAccount = linkedAccountsRes.data;

                dispatch(insertLinkedAccount(newLinkedAccount));

                // refresh accounts orchestrator
                dispatch(getAccounts(true));

                dispatch(addLinkedAccountSuccess());
                dispatch(addLinkedAccountErrorClear());
                dispatch(addLinkedAccountLoadingClear());
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(addLinkedAccountFail({error: error.message}));
        }
    }
};

export const updateLinkedAccount = (id, payload) => {
    return async (dispatch, getState) => {
        const linkedAccountsPath = '/linkedAccounts';
        try {
            dispatch(updateLinkedAccountStart());

            const linkedAccountsRes = await axiosAuthenticated.put(linkedAccountsPath + `/${id}`, {...payload});
            if (linkedAccountsRes && linkedAccountsRes.status === 200) {
                let updatedLinkedAccount = linkedAccountsRes.data;

                if (updatedLinkedAccount.isDeleted === true) {
                    dispatch(removeLinkedAccount(updatedLinkedAccount));
                } else {

                    const fileMetaData = await getFilesMetaData(updatedLinkedAccount.linkedAccountId);

                    let {logoFileId} = EntityUtils.getAccountFileIdsAndDates(fileMetaData);
                    if (logoFileId) {
                        updatedLinkedAccount.logoFileId = logoFileId ? logoFileId : null;
                    }

                    dispatch(changeLinkedAccount(updatedLinkedAccount));
                    dispatch(changeSingleLinkedAccount(updatedLinkedAccount));
                }

                dispatch(updateLinkedAccountSuccess());
                dispatch(updateLinkedAccountLoadingClear());
                dispatch(updateLinkedAccountErrorClear());
                dispatch(getAccounts(true));
            }
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLinkedAccountFail({error: error.message}));
        }
    }
};

//#endregion