import React, { useState, useMemo, useEffect } from 'react';
import LoadMap from "../../components/LoadMap/LoadMap";
import LoadDetails from "../../components/LoadDetails/LoadDetails";
import LoadDocuments from "../../components/LoadDocuments/LoadDocuments";
import LoadMessages from "../../components/LoadMessages/LoadMessages";
import LoadNotifications from "../../components/LoadNotifications/LoadNotifications";
import LoadInvoice from "../../components/LoadInvoice/LoadInvoice";
import LoadStops from "../../components/LoadStops/LoadStops";
import LoadAssets from "../../components/LoadAssets/LoadAssets";
import LoadClaims from "../../components/LoadClaims/LoadClaims";
import LoadNotes from "../../components/LoadNotes/LoadNotes";
import { Tabs, Spin, Card, Drawer } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import StringFormatter from '../../shared/stringFormatter';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTruckLoading,
    faTruck,
    faPallet,
    faRedoAlt,
    faExternalLinkAlt,
    faWallet
} from '@fortawesome/free-solid-svg-icons';
import Pricing from '../../shared/pricing';
import Enums from '../../shared/enums';
import classes from './Load.module.scss';
import AssignLoadCarrier from '../../components/AssignLoadCarrier/AssignLoadCarrier';
import LoadBids from "../../components/LoadBids/LoadBids";
import UpdateLoadPricing from "../../components/UpdateLoadPricing/UpdateLoadPricing";

const { TabPane } = Tabs;
const stringFormatter = new StringFormatter();

const Load = props => {
    //#region props and constants

    const loadId = props.match.params.loadId;
    const fullWidth = global.window.innerWidth;
    const previousBreadcrumbs = props.history && props.history.location && props.history.location.state && props.history.location.state.previousBreadcrumbs !== undefined && props.history.location.state.previousBreadcrumbs !== null && props.history.location.state.previousBreadcrumbs.length > 0 ? props.history.location.state.previousBreadcrumbs : [];

    //#endregion
    //#region useLocation

    const location = useLocation();

    //#endregion
    //#region useDispatch and useSelectors

    const dispatch = useDispatch();
    const entityId = useSelector(state => state.auth.entityId);
    const entityType = useSelector(state => state.auth.entityType);
    const isLoading = useSelector(state => state.loads.isRecordLoading);
    const error = useSelector(state => state.loads.recordError);
    const load = useSelector(state => state.loads.record);
    const claims = useSelector(state => state.claims.records);
    const isClaimsLoading = useSelector(state => state.claims.isLoading);
    const transactions = useSelector(state => state.transactions.records);
    const isTransactionsLoading = useSelector(state => state.transactions.isLoading);
    const invoiceLineItems = useSelector(state => state.invoiceLineItems.records);
    const isInvoiceLineItemsLoading = useSelector(state => state.invoiceLineItems.isLoading);
    const stops = useSelector(state => state.loadStops.records);
    const isStopsLoading = useSelector(state => state.loadStops.isLoading);
    const loadCancellations = useSelector(state => state.loadCancellations.records);
    const isLoadCancellationsLoading = useSelector(state => state.loadCancellations.isLoading);
    const carrierRejections = useSelector(state => state.carrierRejections.records);
    const isCarrierRejectionsLoading = useSelector(state => state.carrierRejections.isLoading);
    const userId = useSelector(state => state.auth.userId);
    const documents = useSelector(state => state.documents.records);
    const notes = useSelector(state => state.notes.records);
    const bids = useSelector(state => state.bids.records);
    const accounts = useSelector(state => state.orchestrator.accounts);

    //#endregion
    //#region useStates

    const [tabKey, setTabKey] = useState('snapshot');
    const [currentStop, setCurrentStop] = useState(null);
    const [currentStopType, setCurrentStopType] = useState('Stop');
    const [isDeadHeading, setIsDeadHeading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('PENDING');
    const [pricingSummary, setPricingSummary] = useState({});
    const [showAssignCarrier, setShowAssignCarrier] = useState(false);
    const [BOLList, setBOLList] = useState([]);
    const [commodityList, setCommodityList] = useState([]);
    const [showUpdatePricing, setShowUpdatePricing] = useState(false);

    //#endregion
    //#region goTos

    const goToNoAccess = () => {
        props.history.replace({ pathname: "/noaccess/" });
    };

    //#endregion
    //#region toggles

    const toggleAssignCarrier = () => {
        setShowAssignCarrier(!showAssignCarrier);
    };

    const toggleUpdatePricing = () => {
        setShowUpdatePricing(!showUpdatePricing);
    }

    //#endregion
    //#region onChanges

    const onChangeCurrentStop = (stop) => {
        setCurrentStop(stop);
    };

    //#endregion
    //#region methods

    const getStatus = (load, stops, loadId) => {
        if (load !== null && load !== undefined && load.id !== undefined && load.id !== null) {
            if (load.id === loadId) {
                if (stops !== null && stops !== undefined && stops.length > 0) {
                    let isDeadHeadingValue = false;
                    let currentStopValue = null;
                    let currentStopTypeValue = "STOP";
                    let currentStopStatusValue = "PENDING";

                    let firstPickUpStop = stops.find(stop => stop.stopType === 'PICK_UP');
                    let currentStopVal = stops.find(stop => stop.stopStatus !== 'COMPLETED');
                    if (currentStopVal !== undefined) {
                        currentStopValue = currentStopVal;
                        onChangeCurrentStop(currentStopValue);
                    } else {
                        currentStopValue = null;
                        onChangeCurrentStop(null);
                    }

                    if (firstPickUpStop !== undefined) {
                        if (firstPickUpStop.stopStatus !== 'COMPLETED') {
                            isDeadHeadingValue = true;
                            setIsDeadHeading(isDeadHeadingValue);
                        } else {
                            isDeadHeadingValue = false;
                            setIsDeadHeading(isDeadHeadingValue);
                        }
                    } else {
                        isDeadHeadingValue = false;
                        setIsDeadHeading(isDeadHeadingValue);
                    }

                    if (currentStopValue !== null) {
                        currentStopTypeValue = currentStopValue.stopType;
                        setCurrentStopType(currentStopTypeValue);
                        currentStopStatusValue = currentStopValue.stopStatus;
                    }

                    switch (load.loadStatus) {
                        case 'CREATED':
                            setCurrentStatus('PENDING');
                            break;
                        case 'PENDING':
                            setCurrentStatus('PENDING');
                            break;
                        case 'APPROVED':
                            setCurrentStatus('APPROVED');
                            break;
                        case 'PENDING_RATE_CONFIRMATION':
                            setCurrentStatus('PENDING_RATE_CONFIRMATION');
                            break;
                        case 'BOOKED':
                            setCurrentStatus('BOOKED');
                            break;
                        case 'SCHEDULED':
                            setCurrentStatus('SCHEDULED');
                            break;
                        case 'IN_TRANSIT':
                            if (isDeadHeadingValue === true && currentStopStatusValue === 'PENDING') {
                                setCurrentStatus('DEAD_HEADING');
                            } else if (currentStopStatusValue === 'ARRIVING') {
                                setCurrentStatus('ARRIVING');
                            } else if (currentStopStatusValue === 'LAYOVER') {
                                setCurrentStatus('LAYOVER');
                            } else if (currentStopStatusValue !== 'PENDING' && currentStopStatusValue !== 'COMPLETED') {
                                setCurrentStatus(currentStopStatusValue); // should never happen based on state machine
                            } else {
                                setCurrentStatus('IN_TRANSIT');
                            }
                            break;
                        case 'AT_STOP':
                            if (currentStopTypeValue === 'PICK_UP' || currentStopTypeValue === 'DROP_OFF') {
                                if (currentStopStatusValue === 'PENDING') {
                                    setCurrentStatus('IN_TRANSIT'); // should never happen based on state machine
                                } else if (currentStopStatusValue === 'LAYOVER') {
                                    setCurrentStatus('LAYOVER');
                                } else {
                                    setCurrentStatus(currentStopStatusValue);
                                }
                            } else {
                                setCurrentStatus('AT_STOP');
                            }
                            break;
                        case 'COMPLETED':
                            setCurrentStatus('COMPLETED');
                            break;
                        case 'CLOSED':
                            setCurrentStatus('CLOSED');
                            break;
                        case 'CANCELLED':
                            setCurrentStatus('CANCELLED');
                            break;
                        case 'REOPENED':
                            setCurrentStatus('REOPENED');
                            break;
                        default:
                            setCurrentStatus('PENDING');
                    }
                }
            }
        }
    };

    const getDocuments = () => {
        if (load !== null && load !== undefined && stops !== null && stops !== undefined && stops.length > 0) {
            let ids = [];
            ids.push(load.id);

            if (load.shipperId !== undefined && load.shipperId !== null) {
                ids.push(load.shipperId);
            }

            if (load.assignedCarrierId !== undefined && load.assignedCarrierId !== null) {
                ids.push(load.assignedCarrierId);
            }

            if (load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0) {
                load.driverIds.forEach(driverId => {
                    ids.push(driverId);
                });
            }

            if (load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0) {
                load.assetIds.forEach(assetId => {
                    ids.push(assetId);
                });
            }

            if (stops !== null && stops !== undefined && stops.length > 0) {
                stops.forEach(stop => {
                    ids.push(stop.id);
                });
            }

            if (claims !== null && claims !== undefined && claims.length > 0) {
                claims.forEach(claim => {
                    ids.push(claim.id);
                });
            }

            dispatch(actionCreators.fetchBulkDocuments({ entityId: ids }));
        }
    };

    const getNotes = () => {
        if (load !== null && load !== undefined && stops !== null && stops !== undefined && stops.length > 0) {
            let ids = [];
            ids.push(load.id);

            if (load.shipperId !== undefined && load.shipperId !== null) {
                ids.push(load.shipperId);
            }

            if (load.assignedCarrierId !== undefined && load.assignedCarrierId !== null) {
                ids.push(load.assignedCarrierId);
            }

            if (load.driverIds !== undefined && load.driverIds !== null && load.driverIds.length > 0) {
                load.driverIds.forEach(driverId => {
                    ids.push(driverId);
                });
            }

            if (load.assetIds !== undefined && load.assetIds !== null && load.assetIds.length > 0) {
                load.assetIds.forEach(assetId => {
                    ids.push(assetId);
                });
            }

            if (stops !== null && stops !== undefined && stops.length > 0) {
                stops.forEach(stop => {
                    ids.push(stop.id);
                });
            }

            if (claims !== null && claims !== undefined && claims.length > 0) {
                claims.forEach(claim => {
                    ids.push(claim.id);
                });
            }

            dispatch(actionCreators.fetchBulkNotes({ entityId: ids }));
        }
    };

    const refreshLoad = () => {
        if (loadId !== undefined && loadId !== null) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    };

    //#endregion
    //#region onChanges

    const onTabChange = (key) => {
        props.history.replace({ ...props.location, hash: `#${key}` });
    };

    //#endregion
    //#region useMemos and useEffects

    useEffect(() => {
        if (load !== null && load !== undefined && load.id !== undefined && load.id !== null && stops !== null && stops !== undefined && stops.length > 0) {
            getDocuments();
            getNotes();
        }
    }, [load, stops, claims]);

    useMemo(() => {
        if (loadId !== undefined && loadId !== null) {
            dispatch(actionCreators.fetchLoadWithIncludes(loadId));
        }
    }, [loadId]);

    useMemo(() => {
        if (load !== undefined && load !== null) {
            let hasAccess = (entityType === 'STAFF' || (entityType === 'CARRIER' && load.assignedCarrierId === entityId) || (entityType === 'SHIPPER' && load.shipperId === entityId));

            if (loadId === load.id && hasAccess === true) {
                if (entityType === 'STAFF' || load.serviceType === 'BROKERAGE') {
                    dispatch(actionCreators.fetchClaims({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                }
                dispatch(actionCreators.fetchInvoiceLineItems({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchTransactions({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchLoadStops(load.id));
                dispatch(actionCreators.fetchLoadCancellations({ page: 1, size: 100000, loadId: load.id, isDeleted: false }));
                dispatch(actionCreators.fetchCarrierRejections({ page: 1, size: 100000, loadId: load.id }));
            } else if (hasAccess === false) {
                goToNoAccess();
            }
        }
    }, [load, entityType, entityId]);

    useEffect(() => {
        getStatus(load, stops, loadId);
    }, [load, currentStop, stops, loadId]);

    useMemo(() => {
        //console.log('location changed')
        //console.log(location)
        if (location.hash !== undefined && location.hash !== null && location.hash !== "") {
            //console.log(location.hash.replace('#', ''))
            setTabKey(location.hash.replace('#', ''));
        } else {
            setTabKey('snapshot');
            props.history.replace({ ...location, hash: `#snapshot` });
        }
    }, [location]);

    useEffect(() => {
        const pricingSummaryValue = Pricing.loadPricingSummary(invoiceLineItems, transactions);
        setPricingSummary(pricingSummaryValue);
    }, [invoiceLineItems, transactions]);

    useMemo(() => {
        if (stops !== undefined && stops !== null && stops.length > 0) {
            let listOfBOLs = [];
            let listOfCommodities = [];
            stops.forEach(function (stop) {
                if (stop.bolNumbers !== undefined && stop.bolNumbers !== null && stop.bolNumbers.length > 0) {
                    stop.bolNumbers.forEach((bolNum) => {
                        if (!listOfBOLs.includes(bolNum)) {
                            listOfBOLs.push(bolNum);
                        }
                    });
                }

                if (stop.commodities !== undefined && stop.commodities !== null && stop.commodities.length > 0) {
                    stop.commodities.forEach((commodity) => {
                        if (!listOfCommodities.includes(commodity.name)) {
                            listOfCommodities.push(commodity.name);
                        }
                    });
                }
            });
            setBOLList([...listOfBOLs]);
            setCommodityList([...listOfCommodities]);
        }
    }, [stops]);

    //#endregion
    //#region displays

    const actions = () => {
        if (entityType === 'STAFF') {
            return (
                <>
                    {load && load.id && <a href={`/track/${load.id}`} target='_blank' style={{ textDecoration: 'underline', marginRight: 12 }}>Shipper Tracker <FontAwesomeIcon className="anticon" icon={faExternalLinkAlt} /></a>}
                    <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
                </>
            );
        } else {
            return (
                <a onClick={refreshLoad}><FontAwesomeIcon className="anticon" icon={faRedoAlt} /></a>
            );
        }
    };

    const assignCarrierComponent = (
        <Drawer
            title={"Assign Carrier to Load"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleAssignCarrier}
            visible={showAssignCarrier}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <AssignLoadCarrier cancel={toggleAssignCarrier} load={load} stops={stops} onClose={toggleAssignCarrier} invoiceLineItems={invoiceLineItems}/>
        </Drawer>
    );

    const updatePricingComponent = (
        <Drawer
            title={"Update pricing"}
            width={fullWidth > 720 ? fullWidth / 2 : 360}
            onClose={toggleUpdatePricing}
            visible={showUpdatePricing}
            bodyStyle={{ paddingBottom: 80 }}
            style={{ zIndex: 1000 }}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
        >
            <UpdateLoadPricing cancel={toggleUpdatePricing} load={load} stops={stops} onClose={toggleUpdatePricing} invoiceLineItems={invoiceLineItems}/>
        </Drawer>
    );

    //#endregion

    return (
        <Spin style={{ height: '100%', width: '100%' }} size="large" spinning={isLoading === true || isClaimsLoading === true || isTransactionsLoading === true || isInvoiceLineItemsLoading === true || isStopsLoading === true || isLoadCancellationsLoading === true || isCarrierRejectionsLoading === true}>
            <Header
                title={load ? load.irisId : 'Loading...'}
                subtitle={load && load.equipmentNeeds && load.equipmentNeeds.trailerType && load.equipmentNeeds.trailerType.description ? load.equipmentNeeds.trailerType.description : null}
                subtitle2={load && load.equipmentNeeds && load.equipmentNeeds.minRunTemp && load.equipmentNeeds.maxRunTemp ? <span>Temp Range: {load.equipmentNeeds.minRunTemp}&deg;{Enums.TemperatureUnits.getValueByName(load.equipmentNeeds.minRunTempUnit)} - {load.equipmentNeeds.maxRunTemp}&deg;{Enums.TemperatureUnits.getValueByName(load.equipmentNeeds.maxRunTempUnit)}</span> : null}
                title2={load && load.shipper ? load.shipper.name : ''}
                title2Icon={<FontAwesomeIcon className="anticon" icon={faTruckLoading} />}
                title3={load && load.assignedCarrier ? load.assignedCarrier.name : (currentStatus !== 'CANCELLED' ? (<a onClick={(e) => { e.stopPropagation(); toggleAssignCarrier(); }}>Assign</a>) : null)}
                title3Icon={<FontAwesomeIcon className="anticon" icon={faTruck} />}
                icon={<FontAwesomeIcon className="anticon" icon={faPallet} />}
                breadcrumbs={[...previousBreadcrumbs,
                {
                    location: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : { pathname: `/loads` },
                    path: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageLocation ? props.history.location.state.previousPageLocation : `/loads`,
                    breadcrumbName: props.history && props.history.location && props.history.location.state && props.history.location.state.previousPageTitle ? props.history.location.state.previousPageTitle : 'All Loads',
                },
                {
                    path: `/loads/${loadId ? loadId : null}`,
                    breadcrumbName: `${load ? load.irisId : ''}`,
                },
                ]}
                highlights={[
                    {
                        title: 'Length',
                        value: `${load && load.equipmentNeeds && load.equipmentNeeds.trailerLength ? load.equipmentNeeds.trailerLength : ''}`
                    },
                    {
                        title: 'Trip Miles',
                        value: `${load && load.practicalDistance ? load.practicalDistance : ''}`
                    },
                    {
                        title: 'Rate',
                        value: `${pricingSummary && pricingSummary.shipperAmountWithAddOns && (entityType === "STAFF" || entityType === "SHIPPER") ? stringFormatter.toFormattedMoney(Math.abs(pricingSummary.shipperAmountWithAddOns)) : (pricingSummary && pricingSummary.carrierAmountWithAddOns && entityType === "CARRIER" ? stringFormatter.toFormattedMoney(Math.abs(pricingSummary.carrierAmountWithAddOns)) : '')}`
                    },
                ]}
            />
            {load !== undefined && load !== null && load.id !== undefined && load.id !== null && load.id === loadId ? (
                <Tabs activeKey={tabKey} onChange={onTabChange} destroyInactiveTabPane={true} tabBarStyle={{ backgroundColor: '#ffffff', paddingRight: 24, paddingLeft: 24, margin: 0 }} tabBarExtraContent={actions()}>
                    <TabPane tab="Snapshot" key="snapshot">
                        <div style={{ padding: 24 }}>
                            <LoadDetails
                                loadId={loadId}
                                load={load}
                                stops={stops}
                                onTabChange={onTabChange}
                                invoiceLineItems={invoiceLineItems}
                                transactions={transactions}
                                claims={claims}
                                loadCancellations={loadCancellations}
                                carrierRejections={carrierRejections}
                                isDeadHeading={isDeadHeading}
                                currentStatus={currentStatus}
                                currentStop={currentStop}
                                currentStopType={currentStopType}
                                entityType={entityType}
                                entityId={entityId}
                                documents={documents}
                                bols={BOLList}
                                commodities={commodityList}
                                accounts={accounts}


                                updatePricingBtn={(entityType === "STAFF") ? <a onClick={(e) => { e.stopPropagation(); toggleUpdatePricing(); }}>Update pricing</a> : null}
                                updatePricingIcon={(entityType === "STAFF") ? <FontAwesomeIcon className="anticon" icon={faWallet} /> : null}
                            />
                        </div>
                    </TabPane>
                    <TabPane tab="Map" key="map">
                        <div style={{ padding: 24 }}>
                            <Card bordered={true} className={classes.card}>
                                <LoadMap
                                    height={'500px'}
                                    key={`loadMap-${loadId}`}
                                    loadId={loadId}
                                    irisLoadId={load.irisId}
                                    load={load}
                                    stops={stops}
                                    entityType={entityType}
                                    entityId={entityId}
                                    containerName={`load-map-tab-map-${loadId}`}
                                />
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tab="Stops" key="stops">
                        <div style={{ padding: 24 }}>
                            <Card bordered={true} className={classes.card}>
                                <LoadStops
                                    loadId={loadId}
                                    load={load}
                                    stops={stops}
                                    currentStatus={currentStatus}
                                    entityType={entityType}
                                    entityId={entityId}
                                    documents={documents}
                                    notes={notes}
                                    bols={BOLList}
                                    commodities={commodityList}
                                />
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tab="Assets" key="assets">
                        <div style={{ padding: 24 }}>
                            <LoadAssets loadId={loadId} load={load} currentStatus={currentStatus} entityType={entityType} entityId={entityId} documents={documents} />
                        </div>
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <div style={{ padding: 24 }}>
                            <LoadDocuments loadId={loadId} load={load} stops={stops} entityType={entityType} entityId={entityId} documents={documents} currentStatus={currentStatus} />
                        </div>
                    </TabPane>
                    <TabPane tab="Messages" key="messages">
                        <div style={{ padding: 24 }}>
                            <Card bordered={true} className={classes.card}>
                                <LoadMessages loadId={loadId} load={load} entityType={entityType} entityId={entityId} currentStatus={currentStatus} />
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tab="Load Events" key="loadEvents">
                        <div style={{ padding: 24 }}>
                            <Card bordered={true} className={classes.card}>
                                <LoadNotifications loadId={loadId} load={load} entityType={entityType} entityId={entityId} currentStatus={currentStatus} />
                            </Card>
                        </div>
                    </TabPane>
                    {(entityType === 'STAFF' || (load && load.serviceType === 'BROKERAGE')) && (
                        <TabPane tab="Claims" key="claims">
                            <div style={{ padding: 24 }}>
                                <LoadClaims loadId={loadId} load={load} claims={claims} entityType={entityType} entityId={entityId} documents={documents} notes={notes} currentStatus={currentStatus} />
                            </div>
                        </TabPane>
                    )}
                    {(entityType === 'SHIPPER') && (
                        <TabPane tab="Bids" key="bids">
                            <div style={{ padding: 24 }}>
                                <Card bordered={true} className={classes.card}>
                                    <LoadBids bids={bids} loadId={loadId} load={load} stops={stops} entityType={entityType} entityId={entityId} notes={notes} currentStatus={currentStatus} />
                                </Card>
                            </div>
                        </TabPane>
                    )}
                    <TabPane tab="Notes" key="notes">
                        <div style={{ padding: 24 }}>
                            <LoadNotes loadId={loadId} load={load} stops={stops} entityType={entityType} entityId={entityId} notes={notes} currentStatus={currentStatus} />
                        </div>
                    </TabPane>
                    {/*entityType === 'STAFF' && (<TabPane tab="Invoice" key="invoice">
                        <div style={{ padding: 24 }}>
                            <LoadInvoice loadId={loadId} load={load} stops={stops} invoiceLineItems={invoiceLineItems} transactions={transactions} currentStatus={currentStatus} entityType={entityType} entityId={entityId} />
                        </div>
                    </TabPane>)*/}
                </Tabs>
            ) : null}
            {assignCarrierComponent}
            {updatePricingComponent}
        </Spin>
    );
};

export default withRouter(Load);
