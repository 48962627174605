import * as actionTypes from "../actions/actionTypes";
import axiosAuthenticated from "../../api/axiosAuthenticated";
import {
    changeSingleLoad,
    fetchLoadWithIncludes,
    updateLoadFail,
    updateLoadLoadingClear,
    updateLoadStart,
    updateLoadSuccess
} from "./loads";
import {fetchInvoiceLineItems} from "./invoiceLineItems";
import {fetchClaims} from "./claims";
import {fetchCarrierRejections} from "./carrierRejections";
import {fetchLoadCancellations} from "./loadCancellations";
import {fetchLoadStops, fetchLoadStopsSuccess} from "./loadStops";
import {fetchTransactions} from "./transactions";
import logger from "../../shared/logger";
import {isEmpty} from "lodash";

//#region Load Lists Functions

export const fetchLoadListStart = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_START,
        payload: payload
    }
};

export const fetchLoadListSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_SUCCESS,
        payload: payload
    }
};

export const fetchLoadListFail = (payload) => {
    return {
        type: actionTypes.FETCH_LOAD_LIST_FAIL,
        payload: payload
    }
};

export const clearLoadList = (payload) => {
    return {
        type: actionTypes.CLEAR_LOAD_LIST,
        payload: payload
    }
};

export const clearLoadLists = () => {
    return {
        type: actionTypes.CLEAR_LOAD_LISTS
    }
};

export const addLoadToLoadList = (listName, payload) => {
    return {
        type: actionTypes.ADD_TO_LOAD_LIST,
        payload: {listName: listName, newRecord: payload}
    }
};

export const updateLoadInLoadList = (listName, payload) => {
    return {
        type: actionTypes.UPDATE_IN_LOAD_LIST,
        payload: {listName: listName, updatedRecord: payload}
    }
};

export const removeLoadFromLoadList = (listName, payload) => {
    return {
        type: actionTypes.REMOVE_FROM_LOAD_LIST,
        payload: {listName: listName, recordToRemove: payload}
    }
};

//#endregion

//#region Load Lists Methods

export const fetchLoadListPubNub = (listName, payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/orch/loads';
        if (listName !== undefined && listName !== null) {
            try {
                const state = getState();
                const loadListsState = {...state.loadLists};
                const existingLoadLists = {...loadListsState.loadLists};

                let pagination = {};
                let searchParams = {};
                if (existingLoadLists[listName] !== undefined && existingLoadLists[listName] !== null && !isEmpty(existingLoadLists[listName])) {
                    pagination = {...existingLoadLists[listName].pagination};
                    searchParams = {...existingLoadLists[listName].searchParams};
                }

                if (payload !== null) {
                    searchParams = {...payload};
                }

                dispatch(fetchLoadListStart({listName: listName}));

                const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
                if (loadsRes && loadsRes.status === 200) {
                    const loadsData = loadsRes.data.data;
                    const otherData = loadsRes.data;

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;
                    let currentPage = otherData.currentPage;
                    let pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;
                    pagination.total = totalCount;
                    pagination.current = currentPage;
                    pagination.pageSize = pageSize;

                    dispatch(fetchLoadListSuccess({
                        records: loadsData,
                        listName: listName,
                        params: {
                            currentPage: currentPage,
                            totalPages: totalPages,
                            totalCount: totalCount,
                            pageSize: pageSize,
                            pagination: pagination,
                            searchParams: searchParams
                        }
                    }));
                }
            } catch (error) {
                dispatch(fetchLoadListFail({listName: listName, error: error.message}));
                logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            }
        }
    }
};

export const fetchLoadList = (listName, payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/orch/loads';
        if (listName !== undefined && listName !== null) {
            try {
                const state = getState();
                const loadListsState = {...state.loadLists};
                const existingLoadLists = {...loadListsState.loadLists};

                let pagination = {};
                let searchParams = {};
                if (existingLoadLists[listName] !== undefined && existingLoadLists[listName] !== null && !isEmpty(existingLoadLists[listName])) {
                    pagination = {...existingLoadLists[listName].pagination};
                    searchParams = {...existingLoadLists[listName].searchParams};
                }

                if (payload !== null) {
                    searchParams = {...payload};
                }

                dispatch(clearLoadList({listName: listName}));
                dispatch(fetchLoadListStart({listName: listName}));

                const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
                if (loadsRes && loadsRes.status === 200) {
                    const loadsData = loadsRes.data.data;
                    const otherData = loadsRes.data;

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;
                    let currentPage = otherData.currentPage;
                    let pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;
                    pagination.total = totalCount;
                    pagination.current = currentPage;
                    pagination.pageSize = pageSize;

                    dispatch(fetchLoadListSuccess({
                        records: loadsData,
                        listName: listName,
                        params: {
                            currentPage: currentPage,
                            totalPages: totalPages,
                            totalCount: totalCount,
                            pageSize: pageSize,
                            pagination: pagination,
                            searchParams: searchParams
                        }
                    }));
                }
            } catch (error) {
                dispatch(fetchLoadListFail({listName: listName, error: error.message}));
                logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            }
        }
    }
};

export const fetchMoreLoadList = (listName, payload) => {
    return async (dispatch, getState) => {
        const loadsPath = '/orch/loads';
        if (listName !== undefined && listName !== null) {
            try {
                const state = getState();
                const loadListsState = {...state.loadLists};
                const existingLoadLists = {...loadListsState.loadLists};

                let pagination = {};
                let searchParams = {};
                let updatedRecords = [];
                if (existingLoadLists[listName] !== undefined && existingLoadLists[listName] !== null && !isEmpty(existingLoadLists[listName])) {
                    pagination = {...existingLoadLists[listName].pagination};
                    searchParams = {...existingLoadLists[listName].searchParams};
                    updatedRecords = [...existingLoadLists[listName].records];
                }

                if (payload !== null) {
                    searchParams = {...payload};
                }

                dispatch(fetchLoadListStart({listName: listName}));

                const loadsRes = await axiosAuthenticated.get(loadsPath, {params: {...searchParams}});
                if (loadsRes && loadsRes.status === 200) {
                    const loadsData = loadsRes.data.data;
                    const otherData = loadsRes.data;

                    loadsData.forEach((item) => {
                        if (updatedRecords.find(obj => obj.id === item.id) === undefined) {
                            updatedRecords.push(item);
                        }
                    });

                    let totalPages = otherData.totalPages;
                    let totalCount = otherData.totalCount;
                    let currentPage = otherData.currentPage;
                    let pageSize = searchParams.size !== undefined && searchParams.size !== null ? searchParams.size : pagination.pageSize;
                    pagination.total = totalCount;
                    pagination.current = currentPage;
                    pagination.pageSize = pageSize;

                    dispatch(fetchLoadListSuccess({
                        records: [...updatedRecords],
                        listName: listName,
                        params: {
                            currentPage: currentPage,
                            totalPages: totalPages,
                            totalCount: totalCount,
                            pageSize: pageSize,
                            pagination: pagination,
                            searchParams: searchParams
                        }
                    }));
                }
            } catch (error) {
                dispatch(fetchLoadListFail({listName: listName, error: error.message}));
                logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            }
        }
    }
};

// All API Calls should use Async/Await
export const updateLoadStatusAndLoadLists = (loadId, stopId, loadStatus, stopStatus, eventType, entityType, refreshLoad = false, refreshLoads = false, refreshStops = false, refreshInvoiceLineItems = false, refreshTransactions = false, refreshClaims = false, refreshLoadCancellations = false, refreshCarrierRejections = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateLoadStart());


            const state = getState();
            const loadsState = {...state.loads};

            console.log("updateLoadStatusAndLoadLists", entityType, loadId, eventType, refreshLoad, refreshLoads)

            if (loadId !== null && loadId !== undefined) {

                if ((entityType === "SHIPPER" || entityType === "STAFF") && (eventType === 'LOAD_REQUEST_TO_CARRIER')) {
                    if (!!state.loadLists && !!state.loadLists.loadLists && !!state.loadLists.loadLists['shipper-dispatch'] && !!state.loadLists.loadLists['shipper-dispatch'].records) {
                        const loadLists = state.loadLists.loadLists['shipper-dispatch'].records;
                        console.log("loadLists", loadLists)
                        dispatch(fetchLoadListPubNub('shipper-dispatch', state.loadLists.loadLists['shipper-dispatch'].searchParams));
                    }
                }

                if (refreshLoads === true
                    &&
                    ((eventType === 'LOAD_UPDATED') || (eventType === 'LOAD_SCHEDULED'))
                ) {
                    if (entityType === "CARRIER") {
                        if (!!state.loadLists && !!state.loadLists.loadLists && !!state.loadLists.loadLists['carrier-dispatch'] && !!state.loadLists.loadLists['carrier-dispatch'].records) {
                            const loadLists = state.loadLists.loadLists['carrier-dispatch'].records;
                            const loadToUpdateIndexDispatch = loadLists.findIndex(load => {
                                return (load.id === loadId) || (load.childLoads.findIndex(subLoad => subLoad.childLoadId === loadId) !== -1)
                            });
                            if ((loadToUpdateIndexDispatch !== -1) && (
                                eventType === 'LOAD_SCHEDULED'
                                ||
                                eventType === 'LOAD_UPDATED'
                            )) {
                                dispatch(fetchLoadList('carrier-dispatch', state.loadLists.loadLists['carrier-dispatch'].searchParams));
                            }
                        }
                    }

                    if (entityType === "SHIPPER") {
                        if (!!state.loadLists && !!state.loadLists.loadLists && !!state.loadLists.loadLists['open'] && !!state.loadLists.loadLists['open'].records) {
                            const loadLists = state.loadLists.loadLists['open'].records;
                            console.log("loadLists", loadLists)
                            dispatch(fetchLoadList('open', state.loadLists.loadLists['open'].searchParams));
                        }
                    }
                }

                if (loadsState.record !== null) {
                    const oldLoad = {...loadsState.record};
                    if (oldLoad.id === loadId) {
                        if (refreshLoad === true) {
                            await dispatch(fetchLoadWithIncludes(loadId));
                        }
                        if (refreshStops === true) {
                            await dispatch(fetchLoadStops(loadId));
                        }
                        if (refreshInvoiceLineItems === true) {
                            await dispatch(fetchInvoiceLineItems({
                                page: 1,
                                size: 100000,
                                loadId: loadId,
                                isDeleted: false
                            }));
                        }
                        if (refreshTransactions === true) {
                            await dispatch(fetchTransactions({
                                page: 1,
                                size: 100000,
                                loadId: loadId,
                                isDeleted: false
                            }));
                        }
                        if (refreshClaims === true) {
                            await dispatch(fetchClaims({page: 1, size: 100000, loadId: loadId, isDeleted: false}));
                        }
                        if (refreshLoadCancellations === true) {
                            await dispatch(fetchLoadCancellations({
                                page: 1,
                                size: 100000,
                                loadId: loadId,
                                isDeleted: false
                            }));
                        }
                        if (refreshCarrierRejections === true) {
                            await dispatch(fetchCarrierRejections({page: 1, size: 100000, loadId: loadId}));
                        }
                    }
                }

                if (loadStatus !== null && loadStatus !== undefined) {
                    if (entityType === "STAFF" || entityType === "SHIPPER") {
                        if (loadStatus === 'PENDING' || loadStatus === 'APPROVED' || loadStatus === 'PENDING_RATE_CONFIRMATION') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...openLoadsList[openLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('open', loadToUpdate));
                            }
                        } else if (loadStatus === 'BOOKED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...openLoadsList[openLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(addLoadToLoadList('booked', loadToUpdate));
                            } else {
                                const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                                if (bookedLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('booked', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'SCHEDULED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('booked', loadToUpdate));
                            }
                        } else if (loadStatus === 'IN_TRANSIT') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('intransit', loadToUpdate));
                            } else {
                                const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                                if (intransitLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'AT_STOP') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                            }
                        } else if (loadStatus === 'COMPLETED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            } else {
                                const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                                if (completedLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...completedLoadsList[completedLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('completed', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'CLOSED' || loadStatus === 'REOPENED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...completedLoadsList[completedLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        } else if (loadStatus === 'CANCELLED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...openLoadsList[openLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            }
                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...completedLoadsList[completedLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        }
                    } else if (entityType === "CARRIER") {
                        if (loadStatus === 'PENDING_RATE_CONFIRMATION') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const loadRequestsList = loadLists['loadrequest'] && loadLists['loadrequest'].records ? [...loadLists['loadrequest'].records] : [];

                            const index = loadRequestsList.findIndex(load => load.id === loadId);
                            if (index !== -1) {
                                let loadToUpdate = {...loadRequestsList[index], loadStatus: loadStatus};
                                dispatch(updateLoadInLoadList('loadrequest', loadToUpdate));
                            }
                        } else if (loadStatus === 'BOOKED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const loadRequestsList = loadLists['loadrequest'] && loadLists['loadrequest'].records ? [...loadLists['loadrequest'].records] : [];
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];

                            const loadRequestsLoadToUpdateIndex = loadRequestsList.findIndex(load => load.id === loadId);
                            if (loadRequestsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...loadRequestsList[loadRequestsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('loadrequest', loadToUpdate));
                                dispatch(addLoadToLoadList('open', loadToUpdate));
                            } else {
                                const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                                if (openLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...openLoadsList[openLoadsLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('open', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'SCHEDULED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];

                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...openLoadsList[openLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                                dispatch(addLoadToLoadList('booked', loadToUpdate));
                            } else {
                                const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                                if (bookedLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('booked', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'IN_TRANSIT') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];

                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                                dispatch(addLoadToLoadList('intransit', loadToUpdate));
                            } else {
                                const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                                if (intransitLoadsLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'AT_STOP') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('intransit', loadToUpdate));
                            }
                        } else if (loadStatus === 'COMPLETED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                                dispatch(addLoadToLoadList('completed', loadToUpdate));
                            } else {
                                const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                                if (completedLoadToUpdateIndex !== -1) {
                                    let loadToUpdate = {
                                        ...completedLoadsList[completedLoadToUpdateIndex],
                                        loadStatus: loadStatus
                                    };
                                    dispatch(updateLoadInLoadList('completed', loadToUpdate));
                                }
                            }
                        } else if (loadStatus === 'CLOSED' || loadStatus === 'REOPENED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...completedLoadsList[completedLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        } else if (loadStatus === 'CANCELLED') {
                            const state2 = getState();
                            const loadListsState = {...state2.loadLists};
                            const loadLists = {...loadListsState.loadLists};
                            const loadRequestsList = loadLists['loadrequest'] && loadLists['loadrequest'].records ? [...loadLists['loadrequest'].records] : [];
                            const openLoadsList = loadLists['open'] && loadLists['open'].records ? [...loadLists['open'].records] : [];
                            const bookedLoadsList = loadLists['booked'] && loadLists['booked'].records ? [...loadLists['booked'].records] : [];
                            const intransitLoadsList = loadLists['intransit'] && loadLists['intransit'].records ? [...loadLists['intransit'].records] : [];
                            const completedLoadsList = loadLists['completed'] && loadLists['completed'].records ? [...loadLists['completed'].records] : [];

                            const loadRequestsLoadToUpdateIndex = loadRequestsList.findIndex(load => load.id === loadId);
                            if (loadRequestsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...loadRequestsList[loadRequestsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('loadrequest', loadToUpdate));
                            }
                            const openLoadsLoadToUpdateIndex = openLoadsList.findIndex(load => load.id === loadId);
                            if (openLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...openLoadsList[openLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('open', loadToUpdate));
                            }
                            const bookedLoadsLoadToUpdateIndex = bookedLoadsList.findIndex(load => load.id === loadId);
                            if (bookedLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...bookedLoadsList[bookedLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('booked', loadToUpdate));
                            }
                            const intransitLoadsLoadToUpdateIndex = intransitLoadsList.findIndex(load => load.id === loadId);
                            if (intransitLoadsLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...intransitLoadsList[intransitLoadsLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(removeLoadFromLoadList('intransit', loadToUpdate));
                            }
                            const completedLoadToUpdateIndex = completedLoadsList.findIndex(load => load.id === loadId);
                            if (completedLoadToUpdateIndex !== -1) {
                                let loadToUpdate = {
                                    ...completedLoadsList[completedLoadToUpdateIndex],
                                    loadStatus: loadStatus
                                };
                                dispatch(updateLoadInLoadList('completed', loadToUpdate));
                            }
                        }
                    }

                    const state3 = getState();
                    const loadsState2 = {...state3.loads};
                    // check if the load that is in the current state matches the loadId and if so, update the status of it
                    if (loadsState2.record !== null) {
                        const oldLoad = {...loadsState2.record};
                        if (oldLoad.id === loadId) {
                            if (stopStatus !== null && stopStatus !== undefined && stopId !== null && stopId !== undefined) {

                                const stopsState = {...state3.loadStops};
                                const stops = [...stopsState.records];
                                const stopToUpdateIndex = stops.findIndex(stop => stop.id === stopId);
                                if (stopToUpdateIndex !== -1) {
                                    let updatedStop = {...stops[stopToUpdateIndex], stopStatus: stopStatus};
                                    const updatedStops = [
                                        ...stops.slice(0, stopToUpdateIndex), // everything before current obj
                                        updatedStop,
                                        ...stops.slice(stopToUpdateIndex + 1), // everything after current obj
                                    ];

                                    let singleLoadToUpdate = {...oldLoad, loadStatus: loadStatus};
                                    dispatch(changeSingleLoad(singleLoadToUpdate));
                                    dispatch(fetchLoadStopsSuccess({records: updatedStops}));
                                } else {
                                    // can't find the stop so just update the load status - this should never happen but it's a fail safe
                                    let singleLoadToUpdate = {...oldLoad, loadStatus: loadStatus};
                                    dispatch(changeSingleLoad(singleLoadToUpdate));
                                }
                            } else {
                                // no stop status to update so just update the load status
                                let singleLoadToUpdate = {...oldLoad, loadStatus: loadStatus};
                                dispatch(changeSingleLoad(singleLoadToUpdate));
                            }
                        }
                    }
                } else if (stopStatus !== null && stopStatus !== undefined && stopId !== null && stopId !== undefined) {
                    const state3 = getState();
                    const loadsState2 = {...state3.loads};
                    if (loadsState2.record !== null) {
                        const oldLoad = {...loadsState2.record};
                        if (oldLoad.id === loadId) {
                            const stopsState = {...state3.loadStops};
                            const stops = [...stopsState.records];
                            const stopToUpdateIndex = stops.findIndex(stop => stop.id === stopId);
                            if (stopToUpdateIndex !== -1) {
                                let updatedStop = {...stops[stopToUpdateIndex], stopStatus: stopStatus};
                                const updatedStops = [
                                    ...stops.slice(0, stopToUpdateIndex), // everything before current obj
                                    updatedStop,
                                    ...stops.slice(stopToUpdateIndex + 1), // everything after current obj
                                ];

                                // update just the stop status and not the load status
                                dispatch(fetchLoadStopsSuccess({records: updatedStops}));
                            }
                        }
                    }
                }
            }

            dispatch(updateLoadSuccess());
            dispatch(updateLoadLoadingClear());
        } catch (error) {
            logger.logReduxErrorEvent(error, `Error fetching Requests: ${error.message}`, true);
            dispatch(updateLoadFail({error: error.message}));
        }
    }
};

//#endregion