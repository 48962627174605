import { isEmpty } from "lodash";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const fetchLoadListStart = (state, action) => {
    if (action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        let newLoadList = {
            records: [],
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            pageSize: 10,
            searchParams: {},
            pagination: {},
            isLoading: false,
            error: null
        };

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            newLoadLists[action.listName] = {
                ...existingLoadLists[action.listName],
                isLoading: true,
                error: null
            };
        } else {
            newLoadLists[action.listName] = {
                ...newLoadList,
                isLoading: true,
                error: null
            };
        }

        return updateObject(state, {
            loadLists: { ...existingLoadLists, ...newLoadLists }
        });
    }

    return state;
};

const fetchLoadListSuccess = (state, action) => {
    if (action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        console.log("fetchLoadListSuccess", action);

        let newLoadList = {
            records: [],
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            pageSize: 10,
            searchParams: {},
            pagination: {},
            isLoading: false,
            error: null
        };

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            if (action.params !== undefined && action.params !== null && !isEmpty(action.params)) {
                newLoadLists[action.listName] = {
                    ...existingLoadLists[action.listName],
                    ...action.params,
                    isLoading: false,
                    error: null
                };

                if (action.records !== undefined && action.records !== null) {
                    newLoadLists[action.listName].records = [...action.records]
                }
            } else {
                newLoadLists[action.listName] = {
                    ...existingLoadLists[action.listName],
                    isLoading: false,
                    error: null
                };

                if (action.records !== undefined && action.records !== null) {
                    newLoadLists[action.listName].records = [...action.records]
                }
            }
        } else {
            if (action.params !== undefined && action.params !== null && !isEmpty(action.params)) {
                newLoadLists[action.listName] = {
                    ...newLoadList,
                    ...action.params,
                    isLoading: false,
                    error: null
                };

                if (action.records !== undefined && action.records !== null) {
                    newLoadLists[action.listName].records = [...action.records]
                }
            } else {
                newLoadLists[action.listName] = {
                    ...newLoadList,
                    isLoading: false,
                    error: null
                };

                if (action.records !== undefined && action.records !== null) {
                    newLoadLists[action.listName].records = [...action.records]
                }
            }
        }

        return updateObject(state, {
            loadLists: { ...existingLoadLists, ...newLoadLists }
        });
    }

    return state;
};

const fetchLoadListFail = (state, action) => {
    if (action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        let newLoadList = {
            records: [],
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            pageSize: 10,
            searchParams: {},
            pagination: {},
            isLoading: false,
            error: null
        };

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            newLoadLists[action.listName] = {
                ...existingLoadLists[action.listName],
                isLoading: false,
                error: null
            };

            if (action.error !== undefined && action.error !== null) {
                newLoadLists[action.listName].error = action.error;
            }
        } else {
            newLoadLists[action.listName] = {
                ...newLoadList,
                isLoading: false,
                error: null
            };

            if (action.error !== undefined && action.error !== null) {
                newLoadLists[action.listName].error = action.error;
            }
        }

        return updateObject(state, {
            loadLists: { ...existingLoadLists, ...newLoadLists }
        });
    }

    return state;
};

const addLoadToLoadList = (state, action) => {
    if (action.newRecord !== undefined && action.newRecord !== null && action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        let newLoadList = {
            records: [],
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            pageSize: 10,
            searchParams: {},
            pagination: {},
            isLoading: false,
            error: null
        };

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            let existingList = [...existingLoadLists[action.listName].records];
            const index = existingList.findIndex(obj => obj.id === action.newRecord.id);

            // only add the record if it doesn't exist in the list already
            if (index === -1) {
                newLoadLists[action.listName] = {
                    ...existingLoadLists[action.listName],
                    records: [...existingLoadLists[action.listName].records, action.newRecord],
                    totalCount: existingLoadLists[action.listName].totalCount + 1
                };
            }
        } else {
            newLoadLists[action.listName] = {
                ...newLoadList,
                records: [action.newRecord],
                totalCount: 1
            };
        }

        return updateObject(state, {
            loadLists: { ...existingLoadLists, ...newLoadLists }
        });
    }

    return state;
};

const updateLoadInLoadList = (state, action) => {
    if (action.updatedRecord !== undefined && action.updatedRecord !== null && action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            let existingList = [...existingLoadLists[action.listName].records];
            const index = existingList.findIndex(obj => obj.id === action.updatedRecord.id);

            // only update if the record exists in the list
            if (index !== -1) {
                const updatedRecords = [
                    ...existingList.slice(0, index), // everything before current obj
                    action.updatedRecord,
                    ...existingList.slice(index + 1), // everything after current obj
                ];

                newLoadLists[action.listName] = {
                    ...existingLoadLists[action.listName],
                    records: [...updatedRecords]
                };
            }
        }

        if (!isEmpty(newLoadLists)) {
            return updateObject(state, {
                loadLists: { ...existingLoadLists, ...newLoadLists }
            });
        }
    }

    return state;
};

const removeLoadFromLoadList = (state, action) => {
    if (action.recordToRemove !== undefined && action.recordToRemove !== null && action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};

        if (existingLoadLists[action.listName] !== undefined && existingLoadLists[action.listName] !== null && !isEmpty(existingLoadLists[action.listName])) {
            let existingList = [...existingLoadLists[action.listName].records];
            const index = existingList.findIndex(obj => obj.id === action.recordToRemove.id);

            // only update if the record exists in the list
            if (index !== -1) {
                existingList.splice(index, 1);

                newLoadLists[action.listName] = {
                    ...existingLoadLists[action.listName],
                    records: [...existingList],
                    totalCount: existingLoadLists[action.listName].totalCount > 0 ? existingLoadLists[action.listName].totalCount - 1 : 0
                };
            }
        }

        if (!isEmpty(newLoadLists)) {
            return updateObject(state, {
                loadLists: { ...existingLoadLists, ...newLoadLists }
            });
        }
    }

    return state;
};

const clearLoadList = (state, action) => {
    if (action.listName !== undefined && action.listName !== null) {
        const copyOfState = { ...state };
        let existingLoadLists = { ...copyOfState.loadLists };
        let newLoadLists = {};
        let newLoadList = {
            records: [],
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            pageSize: 10,
            searchParams: {},
            pagination: {},
            isLoading: false,
            error: null
        };

        newLoadLists[action.listName] = {
            ...newLoadList
        };

        return updateObject(state, {
            loadLists: { ...existingLoadLists, ...newLoadLists }
        });
    }

    return state;
};

const clearAllLoadLists = (state) => {
    return updateObject(state, {
        loadLists: {}
    });
};

const initialState = {
    loadLists: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOAD_LIST_START: return fetchLoadListStart(state, action.payload);
        case actionTypes.FETCH_LOAD_LIST_SUCCESS: return fetchLoadListSuccess(state, action.payload);
        case actionTypes.FETCH_LOAD_LIST_FAIL: return fetchLoadListFail(state, action.payload);
        case actionTypes.CLEAR_LOAD_LIST: return clearLoadList(state, action.payload);
        case actionTypes.CLEAR_LOAD_LISTS: return clearAllLoadLists(state);
        case actionTypes.ADD_TO_LOAD_LIST: return addLoadToLoadList(state, action.payload);
        case actionTypes.UPDATE_IN_LOAD_LIST: return updateLoadInLoadList(state, action.payload);
        case actionTypes.REMOVE_FROM_LOAD_LIST: return removeLoadFromLoadList(state, action.payload);
        default:
            return state;
    }
};

export default reducer;