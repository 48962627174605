import React from 'react';
import StringFormatter from '../../shared/stringFormatter';
import DataTable from '../DataTable/DataTable';

const stringFormatter = new StringFormatter();

const LoadCancellationList = props => {
    const loadCancellations = props.loadCancellations;
    const entityType = props.entityType;

    const singularEntityName = "Load Cancellation";
    const pluralEntityName = "Load Cancellations";

    const columns = [
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (text, record) => { return stringFormatter.toFormattedString("CancellationReason", text, null); },
        },
        {
            title: 'Details',
            dataIndex: 'reasonDetail',
            key: 'reasonDetail',
        },
        {
            title: 'Cancelled By',
            dataIndex: 'entityType',
            key: 'entityType',
            render: (text, record) => { return stringFormatter.toFormattedString("CancellationEntityType", text, null); },
        },
        {
            title: 'Cancelled By',
            dataIndex: 'cancelledByName',
            key: 'cancelledByName',
        },
        {
            title: 'Cancelled At',
            dataIndex: 'cancelledAt',
            key: 'cancelledAt',
            render: (text, record) => { return stringFormatter.toFormattedString("MomentDateTime", text, null); },
        },
    ];

    if (entityType === "STAFF") {
        return (
            <DataTable
                dataSource={loadCancellations}
                columns={columns}
                hidePaging={true}
                footer={false}
                singularEntityName={singularEntityName}
                pluralEntityName={pluralEntityName}
                newEntityAction={null}
                rowKey={record => record.id}
            />
        );
    } else {
        return null;
    }
};

export default LoadCancellationList;