import React from 'react';
import classes from './InvoiceCell.module.scss';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const InvoiceCell = props => {

    let value = stringFormatter.toFormattedString(props.dataType, props.value, null);

    // need to implement column
    let classNames = [];
    if (props.textAlign === 'left') {
        classNames.push(classes.textLeft);
    }
    if (props.textAlign === 'center') {
        classNames.push(classes.textCenter);
    }
    if (props.textAlign === 'right') {
        classNames.push(classes.textRight);
    }

    return (
        <td colSpan={props.colSpan ? props.colSpan : 1} className={classNames.join(' ')}>{value}</td>
    );
};

export default InvoiceCell;