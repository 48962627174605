import React from 'react';
import StringFormatter from '../../shared/stringFormatter';

const stringFormatter = new StringFormatter();

const DataRow = ({ className = null, valueClassName = null, titleClassName = null, style = null, valueStyle = null, titleStyle = null, noColon = false, title = null, value, units = null, timeZone = null, dataType = 'String', extra = null }) => {
    return (
        <div className={className} style={style}>
            {title !== undefined && title !== null ? (<span className={titleClassName} style={titleStyle ? { ...titleStyle } : { fontWeight: 'bold' }}>{title}{noColon === false ? ':' : ''} </span>) : null}
            {value !== undefined && value !== null ? (<span className={valueClassName} style={valueStyle}>{stringFormatter.toFormattedString(dataType, value, units, timeZone)}{extra}</span>) : null}
        </div>
    );
};

export default DataRow;